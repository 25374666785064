import { ExportDataBlType, IExportDataBl } from '../../ExportData.bl';
import { ExportDataStore, ExportDataStoreType } from '../../ExportData.store';
import { as, injectProps } from '../../../../../../__legacy__/helpers/react.helpers';

import { ExportDataFilter } from '../../models/ExportDataFilter';
import { ProjectExportDropdown } from './ProjectExportDropdown';
import React from 'react';
import { observer } from 'mobx-react';

interface IProps {
  projectExportId: string;
  hasAllFilesGenerated: boolean;
}

interface InjectedProps {
  store: ExportDataStore;
  bl: IExportDataBl;
}

@injectProps({
  store: ExportDataStoreType,
  bl: ExportDataBlType,
})
@observer
class ProjectExportDropdownContainerPure extends React.Component<IProps & InjectedProps> {
  handleAllDownloadClick = () => this.props.bl.showDownloadModal(this.props.projectExportId, ExportDataFilter.ALL);
  handleAcceptedDownloadClick = () => this.props.bl.showDownloadModal(this.props.projectExportId, ExportDataFilter.ACCEPTED);
  handleAnnotatedDownloadClick = () => this.props.bl.showDownloadModal(this.props.projectExportId, ExportDataFilter.ANNOTATED);
  handleConfigDownloadClick = () => this.props.bl.showDownloadModal(this.props.projectExportId, ExportDataFilter.CONFIG_ONLY);

  render() {
    return (
      <ProjectExportDropdown
        hasAllFilesGenerated={this.props.hasAllFilesGenerated}
        onAllDownloadClick={this.handleAllDownloadClick}
        onAcceptedDownloadClick={this.handleAcceptedDownloadClick}
        onAnnotatedDownloadClick={this.handleAnnotatedDownloadClick}
        onConfigDownloadClick={this.handleConfigDownloadClick}
      />
    );
  }
}

export const ProjectExportDropdownContainer = as<React.ComponentClass<IProps>>(ProjectExportDropdownContainerPure);
