import * as React from 'react';

import { CreateApiKeyFormContainer } from '../../modules/integration/containers/CreateApiKeyForm.container';
import { DashboardContainer } from '../../containers/DashboardContainer';
import { IntegrationsHeaderContainer } from '../../modules/integration/containers/IntegrationsHeader.container';
import { IntegrationsListContainer } from '../../modules/integration/containers/integrationsList.container';

const Header = () => <IntegrationsHeaderContainer />;

export const IntegrationsView = () => (
  <DashboardContainer header={Header}>
    <CreateApiKeyFormContainer />
    <IntegrationsListContainer />
  </DashboardContainer>
);
