import { computed, observable } from 'mobx';

import { IUndoRedoCommand } from './undoRedoCommands/undoRedoCommand';
import { injectable } from 'inversify';
import { negate } from 'lodash/fp';

export interface IUndoRedoHistoryQueue {
  canUndo: boolean;
  canRedo: boolean;
  addCommand(command: IUndoRedoCommand): void;
  getLastUndoCommand(): IUndoRedoCommand | undefined;
  getNextRedoCommand(): IUndoRedoCommand | undefined;
  removeCommands(condition: (command: IUndoRedoCommand) => boolean): void;
  changeCommands(change: (command: IUndoRedoCommand) => void): void;
  undo(): void;
  redo(): void;
}

export const UndoRedoHistoryQueueType = Symbol('UNDO_REDO_HISTORY_QUEUE_TYPE');

@injectable()
export class UndoRedoHistoryQueue implements IUndoRedoHistoryQueue {
  // For Debugging undo-redo
  // constructor() {
  //   reaction(
  //     () => this.undoCommands.map(v => v),
  //     v => console.log(v),
  //   );
  // }

  @observable
  private undoCommands: IUndoRedoCommand[] = [];

  @observable
  private redoCommands: IUndoRedoCommand[] = [];

  addCommand(command: IUndoRedoCommand): void {
    if (this.redoCommands.length > 0) {
      this.redoCommands = [];
    }

    this.undoCommands.push(command);
  }

  getLastUndoCommand(): IUndoRedoCommand | undefined {
    if (this.undoCommands.length === 0) return undefined;
    return this.undoCommands[this.undoCommands.length - 1];
  }

  getNextRedoCommand(): IUndoRedoCommand | undefined {
    if (this.redoCommands.length === 0) return undefined;
    return this.redoCommands[this.redoCommands.length - 1];
  }

  removeCommands(condition: (command: IUndoRedoCommand) => boolean): void {
    this.undoCommands = this.undoCommands.filter(negate(condition));
    this.redoCommands = this.redoCommands.filter(negate(condition));
  }

  changeCommands(change: (command: IUndoRedoCommand) => void) {
    this.undoCommands.forEach(change);
    this.redoCommands.forEach(change);
  }

  undo(): void {
    if (this.undoCommands.length === 0) return;
    const lastCommand = this.undoCommands[this.undoCommands.length - 1];
    lastCommand.executeUndo();
    const commandIndex = this.undoCommands.findIndex(u => u === lastCommand);
    if (commandIndex !== -1) {
      this.undoCommands.splice(commandIndex);
      this.redoCommands.push(lastCommand);
    }
  }

  redo(): void {
    if (this.redoCommands.length === 0) return;
    const lastCommand = this.redoCommands[this.redoCommands.length - 1];
    lastCommand.executeRedo();
    this.redoCommands.splice(this.redoCommands.length - 1);
    this.undoCommands.push(lastCommand);
  }

  @computed
  get canUndo() {
    return this.undoCommands.length > 0;
  }

  @computed
  get canRedo() {
    return this.redoCommands.length > 0;
  }
}
