import './ProjectExportDropdown.scss';

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import React, { useState } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
  hasAllFilesGenerated: boolean;
  onAllDownloadClick(): void;
  onAcceptedDownloadClick(): void;
  onAnnotatedDownloadClick(): void;
  onConfigDownloadClick(): void;
}

export const ProjectExportDropdownPure: React.FC<IProps & WithNamespaces> = (props: IProps & WithNamespaces) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(prevState => !prevState);

  const { t, onAllDownloadClick, onAcceptedDownloadClick, onAnnotatedDownloadClick, onConfigDownloadClick, hasAllFilesGenerated } = props;
  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle className="btn-icon project-export-dropdown-toggle" title={t('new.projects.details.export_data.action_tooltip')}>
        <FontAwesomeIcon icon={'download'} />
      </DropdownToggle>
      <DropdownMenu style={{ marginLeft: '-220px' }}>
        <DropdownItem onClick={onAllDownloadClick} className="project-export-dropdown-item">
          {t('new.projects.details.export_data.everything')}
        </DropdownItem>
        {hasAllFilesGenerated && (
          <>
            <DropdownItem onClick={onAcceptedDownloadClick} className="project-export-dropdown-item">
              {t('new.projects.details.export_data.all_accepted_data')}
            </DropdownItem>
            <DropdownItem onClick={onAnnotatedDownloadClick} className="project-export-dropdown-item">
              {t('new.projects.details.export_data.all_annotated_data')}
            </DropdownItem>
            <DropdownItem onClick={onConfigDownloadClick} className="project-export-dropdown-item">
              {t('new.projects.details.export_data.project_configuration_only')}
            </DropdownItem>
          </>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export const ProjectExportDropdown = withNamespaces('common')(ProjectExportDropdownPure);
