import { ISegmentationOrderBl, SegmentationOrderBlType } from '../../segmentationsOrder.Bl';
import { as, injectProps } from '../../../../../../../helpers/react.helpers';

import { ISegmentationContextMenuChildrenProps } from '../../components/SegmentationContextMenu';
import React from 'react';
import { SegmentationContextMenuItem } from '../../components/SegmentationContextMenuItem';

interface IInjectProps {
  segmentationOrderBl: ISegmentationOrderBl;
}

@injectProps({
  segmentationOrderBl: SegmentationOrderBlType,
})
class SendToBackContextMenuItemContainerPure extends React.Component<ISegmentationContextMenuChildrenProps & IInjectProps> {

  handleAction = () => {
    this.props.segmentationOrderBl.sendSegmentationToBack(this.props.segmentationId);
  }

  render() {
    return <SegmentationContextMenuItem {...this.props} text="send_to_back" icon="send-back-icon" action={this.handleAction} />;
  }
}

export const SendToBackContextMenuItemContainer = as<React.ComponentClass<ISegmentationContextMenuChildrenProps>>(SendToBackContextMenuItemContainerPure);
