import { ImportAnnotationsBl, ImportAnnotationsBlType } from '../../../importAnnotations.bl';
import { ImportAnnotationsStore, ImportAnnotationsStoreType } from '../../../importAnnotations.store';

import { HowToResolveConflictsStepModal } from './HowToResolveConflictsStepModal';
import { ImportAnnotationsStep } from '../../../models/ImportAnnotationsSteps';
import React from 'react';
import { ResolveConflictsStrategy } from '../../../models/ResolveConflictsStrategy';
import { as } from '../../../../../../../../common/dependencyInjection/as';
import { injectProps } from '../../../../../../../../__legacy__/helpers/react.helpers';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: ImportAnnotationsBl;
  store: ImportAnnotationsStore;
}

@injectProps({
  bl: ImportAnnotationsBlType,
  store: ImportAnnotationsStoreType,
})
@observer
class HowToResolveConflictsStepContainerPure extends React.Component<InjectedProps> {
  handleHide = () => {
    this.props.bl.cancelImport();
  };

  handleSubmit = async () => {
    this.props.bl.resolveConflicts();

    if (this.props.store.resolveConflictsStrategy !== ResolveConflictsStrategy.Manually) {
      await this.props.bl.importAnnotationsAsync();
    }
  };

  handleChangeStrategy = (strategy: ResolveConflictsStrategy) => {
    this.props.bl.changeResolveConflictsStrategy(strategy);
  };

  get isCurrentStep() {
    return this.props.store.currentStep === ImportAnnotationsStep.HowToResolveConflicts;
  }

  render() {
    return (
      <HowToResolveConflictsStepModal
        onHide={this.handleHide}
        onSubmit={this.handleSubmit}
        show={this.isCurrentStep}
        resolveConflictsStrategy={this.props.store.resolveConflictsStrategy}
        onChangeStrategy={this.handleChangeStrategy}
        conflictsCount={this.props.store.conflicts.length}
      />
    );
  }
}

export const HowToResolveConflictsStepContainer = as<React.ComponentClass>(HowToResolveConflictsStepContainerPure);
