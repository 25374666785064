import { inject, injectable } from 'inversify';
import { StickerError } from '../../../../../../__legacy__/models/error.model';
import { ApiServiceType } from '../../../../../../__legacy__/services/api.service';
import { IApiService } from '../../../../../../__legacy__/services/api.service.base';
import { ImportAnnotationConflict } from '../models/ImportAnnotationsConflicts';

export const ImportAnnotationsServiceType = Symbol('IMPORT_ANNOTATIONS_SERVICE');

export class ImportAnnotationsRequest {
  file: File;
  resolvedConflicts: ImportAnnotationConflict[];
  projectId: string;
  constructor(projectId: string, file: File, resolvedConflicts: ImportAnnotationConflict[]) {
    this.file = file;
    this.projectId = projectId;
    this.resolvedConflicts = resolvedConflicts;
  }

  toFormData(): FormData {
    const formData: FormData = new FormData();
    formData.append('file', this.file);
    formData.append('resolvedConflicts', JSON.stringify(this.resolvedConflicts));
    formData.append('projectId', this.projectId);
    return formData;
  }
}

export interface IImportAnnotationsService {
  importAnnotations(request: ImportAnnotationsRequest): Promise<void | StickerError>;
}

@injectable()
export class ImportAnnotationsService implements IImportAnnotationsService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService) {}

  async importAnnotations(request: ImportAnnotationsRequest): Promise<void | StickerError> {
    const url = '/Import/ImportAnnotationsFromFile';
    const result = await this.apiService.postAsync<FormData, void>(url, request.toFormData());

    if (result instanceof StickerError) {
      throw result;
    }

    return result;
  }
}
