import { IUpdateWorkspaceTimeZoneBl, UpdateWorkspaceTimeZoneBlType } from '../UpdateWorkspaceTimeZone.bl';
import { IUpdateWorkspaceTimeZoneStore, UpdateWorkspaceTimeZoneStoreType } from '../UpdateWorkspaceTimeZone.store';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import React from 'react';
import { WorkspaceSubmitButton } from '../../../containers/WorkspaceSubmitButton';
import { observer } from 'mobx-react';

interface IInjectedProps {
  store: IUpdateWorkspaceTimeZoneStore;
  bl: IUpdateWorkspaceTimeZoneBl;
}
@injectProps({
  store: UpdateWorkspaceTimeZoneStoreType,
  bl: UpdateWorkspaceTimeZoneBlType,
})
@observer
class UpdateWorkspaceTimeZoneSubmitButtonContainerPure extends React.Component<IInjectedProps> {
  private handleSubmit = async () => {
    await this.props.bl.submitFormAsync();
  };

  render() {
    return <WorkspaceSubmitButton disabled={false} onSubmit={this.handleSubmit} label="update_timezone" />;
  }
}

export const UpdateWorkspaceTimeZoneSubmitButtonContainer = as<React.ComponentClass>(UpdateWorkspaceTimeZoneSubmitButtonContainerPure);
