import './SelectFileStepModal.scss';

import { WithNamespaces, withNamespaces } from 'react-i18next';

import { DropFileEventHandler } from 'react-dropzone';
import React from 'react';
import { S_Dropzone } from '../../../../../../../../design/inputs/dropzone/S_Dropzone';
import { S_TwoButtonModal } from '../../../../../../../../design/modals/twoButtonModal/S_TwoButtonModal';

interface Props {
  canProceed: boolean;
  fileValidationError: string | undefined;
  show: boolean;
  onHide(): void;
  onSubmit(): void;
  onFileDropped: DropFileEventHandler;
  fileName?: string;
}

const SelectFileStepModalPure: React.FC<Props & WithNamespaces> = (props: Props & WithNamespaces) => {
  const { t, canProceed, fileName, fileValidationError, show, onHide, onSubmit, onFileDropped } = props;
  return (
    <S_TwoButtonModal
      cancelText={t('new.projects.details.import_annotations.cancel')}
      headerText={`${t('new.projects.details.import_annotations.header')} - ${t('new.projects.details.import_annotations.steps.select_file.header')}`}
      submitText={t('new.projects.details.import_annotations.next')}
      onCancel={onHide}
      onHide={onHide}
      onSubmit={onSubmit}
      show={show}
      disableSubmitButton={!canProceed}
    >
      <div>
        <p>
          {fileName ? (
            <span>
              {t('selected_file')}: <b>{fileName}</b>
            </span>
          ) : (
            <strong>{t('please_select_your_file')}</strong>
          )}
        </p>
        <p>
          {t('our_json_format_documentation_can_be_found')}
          <a className="styled_link_bold" href="https://api.zillin.io/export-json-format.html" target="blank">
            {` ${t('here')}`}
          </a>
          .
        </p>
        <p>{t('max_file_size')}</p>
        <S_Dropzone
          multiple={false}
          onDropAccepted={onFileDropped}
          onDropRejected={onFileDropped}
          bodyText={t('drop_your_json_file_here_or_select_it_manually')}
          invalid={fileValidationError !== undefined}
        />
        {fileValidationError && <p className="invalid-feedback">{t(`new.projects.details.import_annotations.steps.select_file.errors.${fileValidationError}`)}</p>}
      </div>
    </S_TwoButtonModal>
  );
};

export const SelectFileStepModal = withNamespaces('common')(SelectFileStepModalPure);
