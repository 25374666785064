import * as React from 'react';

import { Trans, withNamespaces } from 'react-i18next';

import { ConfirmationModalWithTextBox } from '../../../components/ConfirmationModalWithTextBox';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { as } from '../../../helpers/react.helpers';
import autobind from 'autobind-decorator';

export interface IDeleteDatasetConfirmationModalProps {
  showDatasetDeleteConfirmationModal: boolean;
  datasetDeleteConfirmationText: string;
  onDatasetDeleteConfirm(): void;
  onDeleteDatasetCancel(datasetId: string): void;
}

class DeleteDatasetConfirmationModalPure extends React.Component<IDeleteDatasetConfirmationModalProps & ITranslatable> {
  @autobind
  onCancel() {
    this.props.onDeleteDatasetCancel('');
  }

  render() {
    const question = (
      <Trans
        i18nKey="dataset:delete_dataset_confirmation_header"
        values={{ name: this.props.datasetDeleteConfirmationText }}
        // tslint:disable-next-line:jsx-key
        components={[<strong>0</strong>]}
      />);

    return (
      <ConfirmationModalWithTextBox
        confirmationHeader={this.props.t('delete_dataset_header')}
        confirmationQuestion={this.props.t('delete_dataset_confirmation_body')}
        confirmationTextHeader={question}
        confirmationText={this.props.datasetDeleteConfirmationText}
        onCancel={this.onCancel}
        onConfirm={this.props.onDatasetDeleteConfirm}
        showModal={this.props.showDatasetDeleteConfirmationModal}
        confirmationYes={this.props.t('delete')}
        loaderKey="delete"
      />);
  }
}

export const DeleteDatasetConfirmationModal =
  as<React.StatelessComponent<IDeleteDatasetConfirmationModalProps>>(withNamespaces('common', { wait: true })(DeleteDatasetConfirmationModalPure));
