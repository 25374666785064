﻿export namespace MouseClickLimiter {
  const clickTimeLimit: number = 200;
  let mouseDownMoment: number = 0;

  export function timeSinceMouseDown(): number {
    return Date.now() - mouseDownMoment;
  }

  export function markMoment(): void {
    mouseDownMoment = Date.now();
  }

  export function distinguishClick(): boolean {
    return timeSinceMouseDown() < clickTimeLimit;
  }

  export function cancelClick(): boolean {
    return timeSinceMouseDown() >= clickTimeLimit;
  }
}
