﻿import * as React from 'react';

import { GTable, IGColumnProps } from '../../../../../components/table/GTable';
import { IAnnotationType, IQuestion } from '../projectDetailsTools.models';
import { faBars, faEdit, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { COMMA_SEPARATOR } from '../../../../../helpers/global.constants';
import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { EditQuestionContainer } from '../containers/EditQuestion.container';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { IconedButton } from '../../../../../components/IconedButton';
import { ImageScopeName } from '../../../../annotation/question.model';
import { Loader } from '../../../../../components/Loader';
import { ProjectAttributeFormContainer } from '../containers/CreateAttribute.container';
import { QuestionDeleteContainer } from '../containers/QuestionDeleteContainer';
import { as } from '../../../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { handleClickAndPassData } from '../../../../../helpers/formHelpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faTrashAlt, faEdit, faEye, faBars);

type IQuestionWithScopes = IQuestion & { scopesIds: string[] };

interface IAttributesListProps {
  questions: IQuestion[];
  usedTools: string[];
  markingTools: IAnnotationType[];
  canEdit: boolean;
  isLoading: boolean;
  onOrderChange(questionId: string, to: number, isAttribute: boolean): void;
}

interface IState {
  showDeleteModal: boolean;
  questionId?: string;
  showEditModal: boolean;
  isReadonly: boolean;
}

@observer
class AttributesListPure extends React.Component<IAttributesListProps & ITranslatable, IState> {
  constructor(props: IAttributesListProps & ITranslatable) {
    super(props);

    this.state = {
      showEditModal: false,
      showDeleteModal: false,
      questionId: '',
      isReadonly: true,
    };
  }

  getColumns() {
    const columns: IGColumnProps<IQuestionWithScopes>[] = [
      {
        field: 'questionText',
        headerName: this.props.t('name'),
      },
      {
        field: 'type',
        headerName: this.props.t('attribute_type'),
        renderer: this.typeRenderer,
        width: 150,
      },
      {
        field: 'answers',
        headerName: this.props.t('answers'),
        renderer: this.answersRenderer,
        cellClass: 'hide-cell-overflow',
      },
      {
        field: 'scopes',
        headerName: this.props.t('scope'),
        renderer: this.scopesRenderer,
        cellClass: 'hide-cell-overflow',
      },
      {
        field: 'isRequired',
        headerName: this.props.t('is_required'),
        renderer: this.requiredRenderer,
        width: 100,
      },
      {
        field: '',
        headerName: this.props.t('actions'),
        cellClass: 'g-actions',
        renderer: this.optionsRenderer,
        width: 100,
      },
    ];

    if (this.props.canEdit) {
      columns.unshift({
        field: '',
        headerName: '',
        cellClass: '',
        width: 60,
        isDragHandle: true,
        renderer: () => <FontAwesomeIcon icon={faBars} />,
      });
    }

    return columns;
  }

  typeRenderer = (data: IQuestion) => <span>{this.props.t(`${data.type.toLocaleLowerCase()}_question_type`)}</span>;
  requiredRenderer = (data: IQuestion) => <span>{this.props.t(data.isRequired ? 'yes' : 'no')}</span>;
  answersRenderer = (data: IQuestion) => {
    const text = data.answers.map(x => x.text).join(COMMA_SEPARATOR);
    return <span title={text}>{text}</span>;
  };
  scopesRenderer = (data: IQuestion) => <span title={data.scopes.join(COMMA_SEPARATOR)}>{data.scopes.join(COMMA_SEPARATOR)}</span>;
  optionsRenderer = (data: IQuestionWithScopes) => {
    return (
      <>
        <DisabledWhenWorkspaceOwnerPolicyExceeded>
          {this.props.canEdit ? (
            <IconedButton onClick={handleClickAndPassData(this.showEditModal)({ id: data.id, isReadonly: false })} icon={faEdit} title={this.props.t('edit_attribute')} />
          ) : (
            <IconedButton onClick={handleClickAndPassData(this.showEditModal)({ id: data.id, isReadonly: true })} icon={faEye} title={this.props.t('view_attribute')} />
          )}
        </DisabledWhenWorkspaceOwnerPolicyExceeded>
        <QuestionDeleteContainer canEdit={this.props.canEdit} question={data} isAttribute={true} />
      </>
    );
  };

  @autobind
  showEditModal(data: { id?: string; isReadonly: boolean }) {
    this.setState({
      showEditModal: true,
      questionId: data.id,
      isReadonly: data.isReadonly,
    });
  }

  hideEditModal = () => {
    this.setState({ showEditModal: false });
  };

  handleRowDrag = (cb: any) => (from: number, to: number) => cb(this.props.questions[from].id, to, true);

  render() {
    const questions = this.props.questions.map(q => ({
      ...q,
      scopes: q.scopes.map(s => (s.toLocaleUpperCase() === ImageScopeName.toUpperCase() ? s : this.props.markingTools.find(mt => mt.id.toUpperCase() === s.toUpperCase())?.name)),
      scopesIds: q.scopes,
    }));

    return (
      <div className="annotation-tools-list">
        <div className="d-flex flex-row align-items-center">
          <h4>{this.props.t('attributes')}</h4>
          <div className="ml-2">{this.props.canEdit && <ProjectAttributeFormContainer />}</div>
        </div>
        <div className="table-container">
          <Loader isLoading={this.props.isLoading}>
            <GTable
              noItemsPlaceholder={this.props.t('no_attributes_have_been_added_yet')}
              columns={this.getColumns()}
              items={questions}
              enableDrag={this.props.canEdit}
              onRowDragEnd={this.handleRowDrag(this.props.onOrderChange)}
            />
          </Loader>
        </div>
        {this.state.questionId && (
          <EditQuestionContainer
            questionId={this.state.questionId}
            onSubmit={this.hideEditModal}
            onCancel={this.hideEditModal}
            isReadonly={this.state.isReadonly}
            isAttribute={true}
            showEditModal={this.state.showEditModal}
            modalHeader={this.state.isReadonly ? this.props.t('view_attribute') : this.props.t('edit_attribute')}
          />
        )}
      </div>
    );
  }
}
export const AttributesList = as<React.ComponentClass<IAttributesListProps>>(withNamespaces('common')(AttributesListPure));
