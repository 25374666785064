import { ExportDataBlType, IExportDataBl } from '../../ExportData.bl';
import { ExportDataStore, ExportDataStoreType } from '../../ExportData.store';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { as, injectProps } from '../../../../../../__legacy__/helpers/react.helpers';

import { InformationModal } from './InformationModal';
import React from 'react';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: IExportDataBl;
  store: ExportDataStore;
}

@injectProps({
  bl: ExportDataBlType,
  store: ExportDataStoreType,
})
@observer
class InformationModalContainerPure extends React.Component<InjectedProps & RouteComponentProps<{ projectId: string }>> {

  handleHide = () => {
    this.props.bl.hideExportModal();
  }

  render() {
    return <InformationModal show={this.props.store.showExportModal} onHide={this.handleHide} />
  }
}

export const InformationModalContainer = as<React.ComponentClass>(withRouter(InformationModalContainerPure));
