import { AnnotationConfirmationModal, AnnotationConfirmationModalMode } from '../../../components/AnnotationConfirmationModal';
import { AnnotationsStoreType, IAnnotationsStore } from '../../annotation/annotations.store';
import { FreeAccessServiceType, IFreeAccessService } from '../freeAccess.service';
import { FreeAccessStoreType, IFreeAccessStore } from '../freeAccess.store';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { IReviewRejectReasonsStore, ReviewRejectReasonsStoreType } from '../../annotation/submodules/reviewRejectReasones/reviewRejectReasons.store';
import { IWorkspaceService, WorkspaceServiceType } from '../../workspaces/workspaces.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { EditorMode } from '../../../../modules/editor/models/EditorModes';
import { GlobalHotKeys } from 'react-hotkeys';
import React from 'react';
import { ReviewNavigation } from '../../annotation/components/ReviewNavigation';
import { SaveBeforeRejectConfirmationModal } from '../../../components/SaveBeforeRejectConfirmationModal';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ projectId: string }> {
  freeAccessService: IFreeAccessService;
  freeAccessStore: IFreeAccessStore;
  workspaceService: IWorkspaceService;
  annotationsStore: IAnnotationsStore;
  reviewRejectReasonsStore: IReviewRejectReasonsStore;
}

interface IState extends ILoaderState {
  showAnnotationConfirmationModal: boolean;
  annotationConfirmationModalMode: AnnotationConfirmationModalMode;
  showDiscardConfirmationModal: boolean;
  showSaveBeforeRejectConfirmationModal: boolean;
}

@injectProps({
  freeAccessService: FreeAccessServiceType,
  freeAccessStore: FreeAccessStoreType,
  workspaceService: WorkspaceServiceType,
  annotationsStore: AnnotationsStoreType,
  reviewRejectReasonsStore: ReviewRejectReasonsStoreType,
})
@observer
class FreeAccessReviewControlsContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  state: IState = {
    showAnnotationConfirmationModal: false,
    annotationConfirmationModalMode: AnnotationConfirmationModalMode.ACCEPT,
    showDiscardConfirmationModal: false,
    showSaveBeforeRejectConfirmationModal: false,
    isLoading: false,
  };

  @autobind
  async handleAccept() {
    this.setState({ showAnnotationConfirmationModal: false });
    await this.withLoaderAsync(async () => await this.props.freeAccessService.acceptAnnotationAsync(), 'accept-button');
  }

  @autobind
  handleReject() {
    this.setState({ showSaveBeforeRejectConfirmationModal: false });
    this.toggleAnnotationConfirmationModal(AnnotationConfirmationModalMode.REJECT);
  }

  @autobind
  async handleConfirmReject(reason?: string) {
    this.setState({ showAnnotationConfirmationModal: false });
    if (reason) {
      await this.handleRejectConfirmed(reason);
    } else {
      this.setState({ showSaveBeforeRejectConfirmationModal: true });
    }
  }

  @autobind
  async handleRejectConfirmed(reason?: string) {
    this.setState({ showSaveBeforeRejectConfirmationModal: false });
    this.props.freeAccessStore.rejectionReason = reason;
    await this.withLoaderAsync(async () => await this.props.freeAccessService.rejectAnnotationAsync(reason), 'reject-button');
  }

  @autobind
  handleSaveBeforeRejectConfirmed() {
    this.handleRejectConfirmed();
  }

  @autobind
  handleRejectCanceled() {
    this.setState({ showSaveBeforeRejectConfirmationModal: false, showAnnotationConfirmationModal: false });
  }

  @autobind
  handleEdit() {
    this.props.freeAccessService.changeModeToEdit();
  }

  @autobind
  toggleAnnotationConfirmationModal(mode?: AnnotationConfirmationModalMode) {
    if (mode !== undefined) this.setState({ annotationConfirmationModalMode: mode });
    this.setState((prevState: IState) => ({ ...prevState, showAnnotationConfirmationModal: !prevState.showAnnotationConfirmationModal }));
  }

  @autobind
  async handleDiscardConfirmed() {
    if (this.state.isLoading) return;

    this.setState({ showAnnotationConfirmationModal: false });
    await this.withLoaderAsync(async () => this.props.freeAccessService.discardAnnotationAsync(EditorMode.FREEACCESS_REVIEW), 'discard-annotation-button');
  }

  @autobind
  async handleDiscardCanceled() {
    this.setState({ showDiscardConfirmationModal: false });
  }

  @autobind
  async handleDiscard() {
    this.setState({ showDiscardConfirmationModal: true });
  }

  @autobind
  async handleCancel() {
    await this.withLoaderAsync(async () => this.props.freeAccessService.cancelReviewAnnotationAsync(), 'cancel-annotation-button');
  }

  render() {
    const { uiStore } = this.props.freeAccessService;
    const disabled = this.state.isLoading || uiStore.isImageLoading || this.props.annotationsStore.image === undefined;

    // TODO: Create IPermission for free access
    const canDiscard = this.props.workspaceService.isInRole([WorkspaceRole.Owner, WorkspaceRole.Manager]);

    return (
      <>
        {!disabled && (
          <GlobalHotKeys
            keyMap={{
              ACCEPT: { sequence: 'ctrl+shift+enter', action: 'keydown' },
              REJECT: { sequence: 'ctrl+shift+backspace', action: 'keydown' },
              EDIT: ['e', 'E'],
            }}
            handlers={{
              ACCEPT: () => {
                this.setState({ annotationConfirmationModalMode: AnnotationConfirmationModalMode.ACCEPT });
                this.toggleAnnotationConfirmationModal();
              },
              REJECT: () => {
                this.setState({ annotationConfirmationModalMode: AnnotationConfirmationModalMode.REJECT });
                this.toggleAnnotationConfirmationModal();
              },
              EDIT: this.handleEdit,
            }}
          />
        )}
        <ReviewNavigation
          projectId={this.props.match.params.projectId}
          disabled={disabled}
          canDiscard={canDiscard}
          editorMode={EditorMode.FREEACCESS_REVIEW}
          onAccept={this.handleAccept}
          onReject={this.handleReject}
          onEdit={this.handleEdit}
          canCancel={true}
          onCancel={this.handleCancel}
          showDiscardModal={this.state.showDiscardConfirmationModal}
          onDiscardCanceled={this.handleDiscardCanceled}
          onDiscardConfirmed={this.handleDiscardConfirmed}
          onDiscard={this.handleDiscard}
        />
        <SaveBeforeRejectConfirmationModal
          onSaveBeforeRejectCancel={this.handleRejectCanceled}
          onSaveBeforeRejectConfirm={this.handleSaveBeforeRejectConfirmed}
          onSaveBeforeRejectDecline={this.handleReject}
          showSaveBeforeRejectModal={this.state.showSaveBeforeRejectConfirmationModal}
        />
        <AnnotationConfirmationModal
          showAnnotationConfirmationModal={this.state.showAnnotationConfirmationModal}
          annotationConfirmationModalMode={this.state.annotationConfirmationModalMode}
          rejectAutoCompleteOptions={this.props.reviewRejectReasonsStore.rejectionReasons}
          onAnnotationConfirm={this.state.annotationConfirmationModalMode === AnnotationConfirmationModalMode.ACCEPT ? this.handleAccept : this.handleConfirmReject}
          onAnnotationCancel={this.toggleAnnotationConfirmationModal}
        />
      </>
    );
  }
}

export const FreeAccessReviewControlsContainer = as<React.ComponentClass>(withRouter(FreeAccessReviewControlsContainerPure));
