import './AddMarkingToolButton.scss';

import { WithNamespaces, withNamespaces } from 'react-i18next';

import React from 'react';
import { S_PrimaryButton } from '../../../../../../../../design/buttons/textButton/primaryButton/S_PrimaryButton';

interface Props {
  onShowModal(): void;
}

const AddMarkingToolButtonPure: React.FC<Props & WithNamespaces> = props =>
  <S_PrimaryButton
    onClick={props.onShowModal}
    text={props.t('add')}
    className="add-marking-tool-button"
  />;

export const AddMarkingToolButton = withNamespaces()(AddMarkingToolButtonPure);