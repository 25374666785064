import { AnnotationTypeBlType, IAnnotationTypeBl } from '../annotationType.bl';
import { AnnotationTypeStoreType, IAnnotationTypeStore } from '../annotationType.store';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { GlobalHotKeys } from 'react-hotkeys';
import React from 'react';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps {
  annotationTypeStore: IAnnotationTypeStore;
  annotationTypeBl: IAnnotationTypeBl;
}

@injectProps({
  annotationTypeStore: AnnotationTypeStoreType,
  annotationTypeBl: AnnotationTypeBlType,
})
@observer
class AnnotationTypeHotKeysContainer extends React.Component<IInjectedProps> {
  componentWillUnmount() {
    this.props.annotationTypeBl.handleWillUnmountInAnnotationTypeHotKeyContainer();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);
  }

  @autobind
  handleKeyPress(e: any) {
    const isInput = e.target && (e.target['tagName'] === 'INPUT' || e.target['tagName'] === 'TEXTAREA');
    const escapePressed = e.key === 'Escape';

    if (isInput && escapePressed) e.target.blur();
  }

  render() {
    return (
      <GlobalHotKeys
        allowChanges={true}
        keyMap={this.props.annotationTypeStore.annotationTypeKeyMap}
        handlers={this.props.annotationTypeStore.annotationTypeKeyHandlers}
      />
    );
  }
}

export default as<React.ComponentClass>(AnnotationTypeHotKeysContainer);
