import { ImportAnnotationsButtonContainer } from './buttons/ImportAnnotationsButton.container';
import { ImportAnnotationsStepsContainer } from './steps/ImportAnnotationsSteps.container';
import React from 'react';

export const ImportAnnotationsView: React.FC = () => (
  <>
    <ImportAnnotationsButtonContainer />
    <ImportAnnotationsStepsContainer />
  </>
);
