import { AnnotationPermissionsType, IAnnotationPermissions } from '../annotation.permissions';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { as, injectProps } from '../../../helpers/react.helpers';

import { DropdownItem } from 'reactstrap';
import { DropdownLinkItem } from '../../../components/DropdownLinkItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Home } from '../../../routes/config/Home';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import { t } from 'i18next';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  annotationPermissions: IAnnotationPermissions;
}

@injectProps({
  annotationPermissions: AnnotationPermissionsType,
})

@observer
class BackToProjectContainerPure extends React.Component<IInjectedProps & ITranslatable & RouteComponentProps<{ projectId: string; workspaceId: string }>> {
  render() {

    const { workspaceId, projectId } = this.props.match.params;

    return this.props.annotationPermissions.canGoBackToProject() ? (
      <>
        <DropdownLinkItem
          linkTo={Home.Projects.Details.Overview.withParams({
            workspaceId,
            projectId,
          })}
        >
          <FontAwesomeIcon icon={faArrowLeft} /> {t('back_to_project')}
        </DropdownLinkItem>
        <DropdownItem divider />
      </>
    ) : null;
  }
}

export const BackToProjectContainer = as<React.ComponentClass>(withRouter(withNamespaces('common')(BackToProjectContainerPure)));
