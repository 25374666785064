import { injectable, inject } from 'inversify';
import { StickerError } from '../../../../../models/error.model';
import { ApiServiceType } from '../../../../../services/api.service';
import { IApiService } from '../../../../../services/api.service.base';
import { IAnnotationType } from '../models/annotationType';
import { AnnotationToolType } from '../models/annotationToolType';

export const GetAnnotationTypesServiceType = Symbol('GET_ANNOTATION_TYPES_SERVICE');

export interface IGetAnnotationTypesService {
  requestAnnotationTypesAsync(request: GetAnnotationTypesRequest): Promise<GetAnnotationTypesResponse | StickerError>;
}

export class GetAnnotationTypesRequest {
  projectId: string;
  constructor(projectId: string) {
    this.projectId = projectId;
  }

  get query(): string {
    return `/${this.projectId}`;
  }
}
export class GetAnnotationTypesResponse {
  annotationTypes: IAnnotationType[];

  constructor(response: IAnnotationTypesResponse) {
    this.annotationTypes = response.annotationTypes.map<IAnnotationType>(m => ({
      ...m,
      selectorType: (AnnotationToolType as any)[m.selectorType.toUpperCase()] as AnnotationToolType,
    }));
  }
}

interface IAnnotationTypesResponse {
  annotationTypes: IAnnotationTypeResponse[];
}
export interface IAnnotationTypeResponse {
  id: string;
  projectId: string;
  color: string;
  name: string;
  order: number;
  isUsed: boolean;
  selectorType: string;
}

@injectable()
export class GetAnnotationTypesService implements IGetAnnotationTypesService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService) { }

  async requestAnnotationTypesAsync(request: GetAnnotationTypesRequest): Promise<GetAnnotationTypesResponse | StickerError> {
    const response = await this.apiService.getAsync<IAnnotationTypesResponse>(`/annotationTypes${request.query}`);
    if (response instanceof StickerError) return response;
    return new GetAnnotationTypesResponse(response);
  }
}
