import * as React from 'react';

import { AnnotationToolFilterType, IAnnotationToolFilter, IImageFiltersData } from '../../../imageFilters.model';
import { FormGroup, Input, Label } from 'reactstrap';
import { ISelectOption, handleChange, handleMultiSelectChange, handleSelectChange } from '../../../../../../../helpers/formHelpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../../../../../helpers/translations.helpers';
import InputNumber from 'rc-input-number';
import { QuestionType } from '../../../../../../annotation/question.model';
import { SelectWithHax } from '../../../../../../../components/SelectWithHax';
import { as } from '../../../../../../../helpers/react.helpers';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IProps {
  availableImageFilters: IImageFiltersData;
  toolFilter: IAnnotationToolFilter;
  showDelete: boolean;
  onDelete(): void;
  onL1FilterChanged(value: string): void;
  onL2FilterChanged(value: string): void;
  onL3MultiselectFilterChanged(value: string[]): void;
  onL3SelectFilterChanged(value: string): void;
  onL3TextFilterChanged(value: string): void;
  onL3MinNumberFilterChanged(value: number | string): void;
  onL3MaxNumberFilterChanged(value: number | string): void;
}

@observer
class AnnotationToolRowViewPure extends React.Component<IProps & ITranslatable> {
  render() {
    const selectFilterTypes = [AnnotationToolFilterType.AttributeAnswer, AnnotationToolFilterType.QuestionAnswer];

    let markingToolOptions: ISelectOption<string>[] = [];
    let attributesOptions: ISelectOption<string>[] = [];
    let answerOptions: ISelectOption<string>[] = [];
    let questionType!: QuestionType;

    markingToolOptions = [
      ...this.props.availableImageFilters.annotationTypes.slice().sort((x: any, y: any) => x.order - y.order)
        .map((x) => { return { value: x.id, label: x.name }; }),
      ...this.props.availableImageFilters.questionFilters.slice().sort((x: any, y: any) => x.order - y.order)
        .map((x) => { return { value: x.id, label: x.text }; }),
    ];

    if (this.props.toolFilter.l1FilterType === AnnotationToolFilterType.AnnotationTool) {
      const annotationType = this.props.availableImageFilters.annotationTypes.find((x: any) => x.id === this.props.toolFilter.l1Filter);
      if (annotationType) {
        attributesOptions = annotationType.attributes.slice().sort((x: any, y: any) => x.order - y.order)
          .map((x) => { return { value: x.id, label: x.text }; }) || [];

        const attribute = annotationType.attributes.find((x: any) => x.id === this.props.toolFilter.l2Filter);
        if (attribute) {
          questionType = attribute.answerType;
          if (selectFilterTypes.some(x => x === this.props.toolFilter.l3FilterType)) {
            answerOptions = attribute.possibleAnswers.slice().sort((x: any, y: any) => x.order - y.order).map((x) => { return { value: x.id, label: x.text }; });
          }
        }
      }
    } else if (this.props.toolFilter.l1FilterType === AnnotationToolFilterType.Question) {
      const question = this.props.availableImageFilters.questionFilters.find((x: any) => x.id === this.props.toolFilter.l1Filter);
      if (question) {
        questionType = question.answerType;
        if (selectFilterTypes.some(x => x === this.props.toolFilter.l3FilterType)) {
          answerOptions = question.possibleAnswers.slice().sort((x: any, y: any) => x.order - y.order).map(x => ({ value: x.id, label: x.text }));
        }
      }
    }

    return (
      <div className="annotation-tool-filter">
        <FormGroup>
          <Label>{this.props.t('annotation_tool')}</Label>
          <SelectWithHax
            isClearable
            id="select-l1"
            value={markingToolOptions.find(x => x.value === this.props.toolFilter.l1Filter) || null}
            onChange={handleSelectChange((value: string) => this.props.onL1FilterChanged(value))}
            options={markingToolOptions}
          />
        </FormGroup>

        {this.props.toolFilter.l1FilterType === AnnotationToolFilterType.AnnotationTool && attributesOptions.length > 0 &&
          <FormGroup>
            <Label>{this.props.t('attribute')}</Label>
            <SelectWithHax
              isClearable
              id="select-l2"
              value={attributesOptions.find(x => x.value === this.props.toolFilter.l2Filter) || null}
              onChange={handleSelectChange((value: string) => this.props.onL2FilterChanged(value))}
              options={attributesOptions}
            />
          </FormGroup>}

        {questionType === QuestionType.MULTISELECT && answerOptions.length > 0 &&
          <FormGroup>
            <Label>{this.props.t('values')}</Label>
            <SelectWithHax
              isClearable
              isMulti={true}
              id="select3-multi"
              onChange={handleMultiSelectChange((value: string[]) => this.props.onL3MultiselectFilterChanged(value))}
              value={answerOptions.filter(x => this.props.toolFilter.l3Filters?.some(y => y === x.value))}
              options={answerOptions}
            />
          </FormGroup>}

        {[QuestionType.ALTERNATIVE, QuestionType.SET_TRAIN_TEST, QuestionType.STATUS_PASS_FAIL, QuestionType.TRUE_FALSE].some(x => x === questionType) &&
          answerOptions.length > 0 &&
          <FormGroup>
            <Label>{this.props.t('value')}</Label>
            <SelectWithHax
              isClearable
              id="select3-single"
              onChange={handleSelectChange((value: string) => this.props.onL3SelectFilterChanged(value))}
              value={answerOptions.filter(x => this.props.toolFilter.l3Filters?.some(y => y === x.value))}
              options={answerOptions}
            />
          </FormGroup>}

        {questionType === QuestionType.OPEN &&
          <FormGroup>
            <Label>{this.props.t('value')}</Label>
            <Input
              value={this.props.toolFilter.l3Filters.slice()[0] || ''}
              type={'text'}
              placeholder={this.props.t('answer')}
              onChange={handleChange(this.props.onL3TextFilterChanged)}
            />
          </FormGroup>}

        {questionType === QuestionType.NUMBER &&
          <>
            <FormGroup className="number">
              <Label>{this.props.t('min')}</Label>
              <InputNumber
                className={'form-control'}
                value={this.props.toolFilter.l3Filters.slice()[0] as any}
                onChange={this.props.onL3MinNumberFilterChanged}
              />
            </FormGroup>
            <FormGroup className="number">
              <Label>{this.props.t('max')}</Label>
              <InputNumber
                className={'form-control'}
                value={this.props.toolFilter.l3Filters.slice()[1] as any}
                onChange={this.props.onL3MaxNumberFilterChanged}
              />
            </FormGroup>
          </>}
        {this.props.showDelete && <div className="trash" title={this.props.t('remove')}>
          <div className="trash-icon" onClick={this.props.onDelete}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </div>
        </div>}
      </div>
    );
  }
}

export const AnnotationToolRowView = as<React.ComponentClass<IProps>>(withNamespaces('common', { wait: true })(AnnotationToolRowViewPure));
