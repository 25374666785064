import { ImageAnnotationStatus, ImageSetType } from './projectDetailsImages.model';

import { DatasetStatus } from '../../../datesets/datasetStatus.model';
import { QuestionType } from '../../../annotation/question.model';
import { SortingDirection } from '../../../../models/sortingDirection.model';

export enum SortingBy {
  None = 'None',
  Name = 'Name',
  DatasetName = 'DatasetName',
  Status = 'Status',
  AnnotatedBy = 'AnnotatedBy',
  ReviewedBy = 'ReviewedBy',
  WasImageCorrected = 'WasImageCorrected',
  DateOfMarking = 'DateOfMarking',
  DateOfApproval = 'DateOfApproval',
  ImageSet = 'ImageSet',
  WorkTime = 'WorkTime'
}

export interface ISorting {
  by: SortingBy | undefined;
  direction: SortingDirection;
  order: number;
}

export enum FilterTabType {
  InDatasets = 'InDatasets',
  AnnotatedBy = 'AnnotatedBy',
  ReviewedBy = 'ReviewedBy',
  Status = 'Status',
  ImageSet = 'ImageSet',
  Corrected = 'Corrected',
  AnnotationTools = 'AnnotationTools',
  Sorting = 'Sorting',
  FilterSave = 'FilterSave',
}

export enum AnnotationToolFilterType {
  AnnotationTool = 'AnnotationTool',
  Question = 'Question',
  Attribute = 'Attribute',
  AttributeAnswer = 'AttributeAnswer',
  AttributeAnswerText = 'AttributeAnswerText',
  AttributeAnswerNumber = 'AttributeAnswerNumber',
  QuestionAnswer = 'QuestionAnswer',
  QuestionAnswerText = 'QuestionAnswerText',
  QuestionAnswerNumber = 'QuestionAnswerNumber',
}

export const EmptyAnnotationToolFilter: IAnnotationToolFilter = {
  order: 0,
  l1FilterType: undefined,
  l1Filter: undefined,
  l2FilterType: undefined,
  l2Filter: undefined,
  l3FilterType: undefined,
  l3Filters: [],
};

export const EmptySorting: ISorting = {
  order: 0,
  by: SortingBy.Name,
  direction: SortingDirection.ASC,
};

export const ImageDefaultFilters: IImageFilters = {
  imageName: '',
  datasets: [],
  annotatedBy: [],
  reviewedBy: [],
  annotationStatuses: [],
  annotationTools: [{ ...EmptyAnnotationToolFilter }],
  unclear: [],
  corrected: [],
  imageSets: [],
  sorting: [{ by: SortingBy.Name, direction: SortingDirection.ASC, order: 0 }],
};

export interface IAnnotationToolFilter {
  order: number;
  l1FilterType: AnnotationToolFilterType | undefined;
  l1Filter: string | undefined;
  l2FilterType: AnnotationToolFilterType | undefined;
  l2Filter: string | undefined;
  l3FilterType: AnnotationToolFilterType | undefined;
  l3Filters: string[];
}

export interface IImageFilters {
  imageName: string;
  datasets: string[];
  annotatedBy: string[];
  reviewedBy: string[];
  annotationStatuses: ImageAnnotationStatus[];
  annotationTools: IAnnotationToolFilter[];
  unclear: boolean[];
  corrected: boolean[];
  imageSets: ImageSetType[];
  sorting: ISorting[];
}

export interface IActiveFilterDto {
  availableFilters: IImageFiltersData;
  activeFilter: IImageFilters;
  savedFilters: ISavedFilter[];
}

export interface IImageFiltersData {
  annotationTypes: IAnnotationTypeFilterOption[];
  questionFilters: IQuestionFilterOption[];
  datasets: IDatasetFilterOption[];
  annotatedBy: IUserFilterOption[];
  reviewedBy: IUserFilterOption[];
}

export interface IDatasetFilterOption {
  id: string;
  name: string;
  status: DatasetStatus;
}

export interface ISavedFilter {
  id: string;
  name: string;
  filters: IImageFilters;
}

export interface IUserFilterOption {
  id: string;
  email: string;
}

export interface IAnnotationTypeFilterOption {
  id: string;
  name: string;
  order: number;
  attributes: IQuestionFilterOption[];
}

export interface IQuestionFilterOption {
  id: string;
  text: string;
  order: number;
  answerType: QuestionType;
  possibleAnswers: IAnswerFilterOption[];
}

export interface IAnswerFilterOption {
  id: string;
  order: number;
  text: string;
}
