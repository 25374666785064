import 'leaflet-draw';
import 'leaflet-editable';
import 'leaflet.path.drag';

import * as React from 'react';

import { MOUSE_EVENTS } from '../annotations.interface';
import { Map } from 'leaflet';
import { as } from '../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { withLeaflet } from 'react-leaflet';

interface IProps {
  leaflet: { map: Map };
}

class MapContextMenuSuppresorPure extends React.Component<IProps> {
  private map!: Map;

  componentDidMount() {
    if (super.componentDidMount) super.componentDidMount();
    this.map = this.props.leaflet.map;
    this.map.on(MOUSE_EVENTS.CONTEXT_MENU, this.handleContextMenu);
  }

  componentWillUnmount() {
    this.map.off(MOUSE_EVENTS.CONTEXT_MENU, this.handleContextMenu);
  }

  @autobind
  handleContextMenu() {
    // do nothing. Prevent browser context menu
  }

  render() {
    return <>{this.props.children}</>;
  }
}

export const MapContextMenuSuppresor = as<React.ComponentClass>(withLeaflet(MapContextMenuSuppresorPure));
