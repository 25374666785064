import * as React from 'react';

import { ILoaderState, WithLoaderComponentBase } from '../../../../../helpers/loader.helpers';
import { IProjectDetailsToolsBl, IProjectDetailsToolsBlType } from '../projectDetailsTools.bl';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { IQuestion } from '../projectDetailsTools.models';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { QuestionDelete } from '../components/QuestionDelete';
import { ToolDeleteStrategy } from '../../../projectDetails.models';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps {
  projectDetailsToolsBl: IProjectDetailsToolsBl;
}

interface IOuterProps {
  canEdit: boolean;
  question: IQuestion;
  isAttribute: boolean;
}

interface IState extends ILoaderState {
  showModal: boolean;
  strategy: ToolDeleteStrategy;
  showDiscardConfirmModal: boolean;
}

@injectProps({ projectDetailsToolsBl: IProjectDetailsToolsBlType })
@observer
class QuestionDeleteContainerPure extends WithLoaderComponentBase<IInjectedProps & IOuterProps, IState> {
  constructor(props: IInjectedProps & IOuterProps & ITranslatable) {
    super(props);

    this.state = {
      strategy: ToolDeleteStrategy.None,
      showDiscardConfirmModal: false,
      showModal: false,
      isLoading: false,
    };
  }

  @autobind
  async handleDeleteConfirmedAsync() {
    if (this.state.strategy === ToolDeleteStrategy.Discard) {
      this.withLoaderAsync(async () => {
        await this.props.projectDetailsToolsBl.getQuestionAffectAsync(this.props.question.id);
        this.setState({ showDiscardConfirmModal: true });
      }, 'tool-delete-confirm-button');
    } else {
      await this.handleDeleteQuestionAsync();
    }
  }

  @autobind
  async handleDeleteQuestionAsync() {
    this.withLoaderAsync(async () => {
      await this.props.projectDetailsToolsBl.deleteQuestionAsync(this.props.question.id, this.state.strategy);
      this.setState({ showDiscardConfirmModal: false });
    }, 'tool-delete-confirm-button');
  }

  @autobind
  async handleCancelAsync() {
    if (!this.state.isLoading) {
      this.setState({ showModal: false });
    }
  }

  @autobind
  async handleDeleteAsync() {
    this.withLoaderAsync(async () => {
      await this.props.projectDetailsToolsBl.getQuestionUsageAsync(this.props.question.id);
      this.setState({ showModal: true });
    });
  }

  @autobind
  async handleStrategyChangeAsync(strategy: ToolDeleteStrategy) {
    this.setState({ strategy });
  }

  @autobind
  async handleCancelDiscardAsync() {
    if (!this.state.isLoading) {
      this.setState({ showDiscardConfirmModal: false });
    }
  }

  render() {
    const toolUsage = this.props.projectDetailsToolsBl.store.toolsUsage;
    const isAttribute = this.props.isAttribute;
    const toolAffect = this.props.projectDetailsToolsBl.store.toolAffect;

    return (
      <QuestionDelete
        isAttribute={isAttribute}
        canEdit={this.props.canEdit}
        isLoading={this.state.isLoading}
        showModal={this.state.showModal}
        showDiscardConfirmModal={this.state.showDiscardConfirmModal}
        strategy={this.state.strategy}
        toolUsage={toolUsage}
        onCancel={this.handleCancelAsync}
        onCancelDiscard={this.handleCancelDiscardAsync}
        onDelete={this.handleDeleteAsync}
        onConfirmDiscard={this.handleDeleteQuestionAsync}
        onDeleteConfirmed={this.handleDeleteConfirmedAsync}
        onStrategyChange={this.handleStrategyChangeAsync}
        toolAffect={toolAffect}
      />
    );
  }
}

export const QuestionDeleteContainer = as<React.ComponentClass<IOuterProps>>(QuestionDeleteContainerPure);
