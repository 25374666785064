import { Card, CardBody, Col, Row } from 'reactstrap';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../../../../modules/common/OverlayLoader.store';
import { IProjectDetailsStatisticsBl, ProjectDetailsStatisticsBlType } from '../projectDetailsStatistics.bl';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { ButtonWithLoader } from '../../../../../components/ButtonWithLoader';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import React from 'react';
import { WithLoaderComponentBase } from '../../../../../helpers/loader.helpers';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { toLocaleDateTimeString } from '../../../../../helpers/date.helpers';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  projectDetailsStatisticsBl: IProjectDetailsStatisticsBl;
  overlayLoader: IOverlayLoaderStore;
}

type Props = IInjectedProps & RouteComponentProps<{ projectId: string }> & ITranslatable;

@injectProps({
  projectDetailsStatisticsBl: ProjectDetailsStatisticsBlType,
  overlayLoader: OverlayLoaderStoreType,
})
@observer
class ToolStatisticsRefreshButtonContainerPure extends WithLoaderComponentBase<Props> {

  @autobind
  async handleRefresh() {
    await this.withLoaderAsync(
      async () => {
        await this.props.projectDetailsStatisticsBl.refreshToolStatistics(this.props.match.params.projectId);
      },
      'tool-statistics-loading');
  }

  render() {
    const generatedAt = toLocaleDateTimeString(this.props.projectDetailsStatisticsBl.store.toolStatistics.generatedAt);
    return (
      <Card className="flex-fill thin-border statistics-refresh-info">
        <CardBody>
          <Row>
            <Col className="align-self-center">
              <span>{this.props.t('these_statistics_are_automatically_refreshed_once_per_hour')}</span>
              <ButtonWithLoader color="primary" onClick={this.handleRefresh} loaderKey={'tool-statistics-loading'}>{this.props.t('refresh_now')}</ButtonWithLoader>
            </Col>
            <Col className="align-self-center text-right">
              {this.props.t('last_updated_at')}: {generatedAt}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export const ToolStatisticsRefreshButtonContainer = as<React.ComponentClass>(withRouter(withNamespaces('common', { wait: true })(ToolStatisticsRefreshButtonContainerPure)));
