import { Feature, Polygon } from 'geojson';
import { ISegmentation, ISegmentationFeature } from '../annotations.interface';

import { AnnotationToolType } from '../submodules/annotationTypes/models/annotationToolType';
import { DRAW_CONTROL_MODE } from '../containers/Drawing.container';
import DrawControl from './DrawControl';
import { FeatureGroup } from 'react-leaflet';
import { IAnnotationService } from '../annotation.service';
import { IAnnotationTypeOptions } from '../submodules/annotationTypes/models/annotationTypeOptions';
import { IFreeDrawSegmentationService } from '../freeDrawSegmentation.service';
import { IUndoRedoHistory } from '../undoRedoHistory.service';
import { LatLng } from 'leaflet';
import React from 'react';

export interface IDrawingComponentProps {
  activeAnnotationTypeId: string | undefined;
  activeAnnotationTypeOptions: IAnnotationTypeOptions | undefined;
  areCursorGuidesEnabled: boolean;
  selectorType: AnnotationToolType | null;
  fillOpacity: number;
  color: string;
  controlMode: DRAW_CONTROL_MODE;
  stopAction: boolean;
  undoRedoHistory: IUndoRedoHistory;
  annotationService: IAnnotationService;
  freeDrawSegmentationService: IFreeDrawSegmentationService;
  selectedSegmentation?: ISegmentation;
  freeDrawInProgress: boolean;
  freeDrawSegmentationFeature?: ISegmentationFeature;
  currentCursorPosition?: LatLng;
  isImprovedVisibilityCursorEnabled: boolean;
  onAnnotationCreated(annotation: Feature<Polygon>): void;
  onAnnotationCopied(id: string): void;
  onAnnotationPasted(annotation: Feature<Polygon>, id: string): void;
  onAnnotationEdited(id: string, annotation: Feature<Polygon>): void;
  onAnnotationRemove(id: string): void;
  onMouseMove(latlng?: LatLng): void;
  onEditEnabled(id: string): void;
  onEditDisabled(): void;
  onFreeDrawStarted(annotation: Feature): void;
  onFreeDrawCancel(): Promise<boolean>;
  onFreeDrawDelete(): void;
}

export class DrawingComponent extends React.Component<IDrawingComponentProps> {
  render() {
    return (
      <FeatureGroup>
        <DrawControl
          activeAnnotationTypeId={this.props.activeAnnotationTypeId}
          activeAnnotationTypeOptions={this.props.activeAnnotationTypeOptions}
          selectorType={this.props.selectorType!}
          color={this.props.color}
          fillOpacity={this.props.fillOpacity}
          stopActions={this.props.stopAction}
          onDrawCreated={this.props.onAnnotationCreated}
          onDrawCopied={this.props.onAnnotationCopied}
          onDrawPasted={this.props.onAnnotationPasted}
          onDrawEdited={this.props.onAnnotationEdited}
          onDrawingRemove={this.props.onAnnotationRemove}
          onMouseMove={this.props.onMouseMove}
          controlMode={this.props.controlMode}
          undoRedoHistory={this.props.undoRedoHistory}
          freeDrawSegmentationService={this.props.freeDrawSegmentationService}
          onEditEnabled={this.props.onEditEnabled}
          onEditDisabled={this.props.onEditDisabled}
          selectedSegmentation={this.props.selectedSegmentation}
          freeDrawFeature={this.props.freeDrawSegmentationFeature}
          freeDrawInProgress={this.props.freeDrawInProgress}
          onFreeDrawStarted={this.props.onFreeDrawStarted}
          onFreeDrawCancel={this.props.onFreeDrawCancel}
          onFreeDrawDelete={this.props.onFreeDrawDelete}
          cursorPosition={this.props.currentCursorPosition}
          annotationService={this.props.annotationService}
          isImprovedVisibilityCursorEnabled={this.props.isImprovedVisibilityCursorEnabled}
          areCursorGuidesEnabled={this.props.areCursorGuidesEnabled}
        />
      </FeatureGroup>
    );
  }
}
