import { ClarificationsBlType, IClarificationsBl } from '../services/Clarifications.bl';
import { ClarificationsStoreType, IClarificationsStore } from '../Clarifications.store';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { Button } from 'reactstrap';
import { ClarificationsListModal } from '../components/ClarificationsListModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IClarification } from '../models/Clarifications.models';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { SortingDirection } from '../../../../../models/sortingDirection.model';
import autobind from 'autobind-decorator';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { nameOf } from '../../../../../helpers/object.helpers';
import { observer } from 'mobx-react';
import { sortBy } from '../../../../../helpers/collections.helper';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps extends RouteComponentProps<{ projectId: string, imageId?: string }> {
  clarificationsStore: IClarificationsStore;
  clarificationsBl: IClarificationsBl;
}

interface IState {
  showClarificationsListModal: boolean;
}

@injectProps({
  clarificationsStore: ClarificationsStoreType,
  clarificationsBl: ClarificationsBlType,
})
@observer
class ClarificationsArchiveListContainer extends React.Component<IInjectedProps & ITranslatable, IState> {
  state: IState = {
    showClarificationsListModal: false,
  };

  @autobind
  handleToggleChange(): void {
    this.setState((prevState: IState) => ({ ...prevState, showClarificationsListModal: !prevState.showClarificationsListModal }));
  }

  @autobind
  handleSortDirectionChange(): void {
    const currentSortingDirection = this.props.clarificationsStore.clarificationsListSortDirection;

    this.props.clarificationsBl.setClarificationsListSortDirection(currentSortingDirection === SortingDirection.ASC ? SortingDirection.DESC: SortingDirection.ASC);
  }

  render() {
    const clarifications = this.props.clarificationsStore.archiveClarifications.filter(c => c.answer);
    const sortedClarifications = sortBy(
      clarifications,
      nameOf<IClarification>('askedDate'),
      this.props.clarificationsStore.clarificationsListSortDirection);

    return (
      clarifications.length > 0 &&
       <div className="image-clarifications-list">
        <Button color="link" onClick={this.handleToggleChange}>
          <FontAwesomeIcon icon={faComments} />{this.props.t('clarifications.show_all_button')} ({clarifications.length})
        </Button>

        <ClarificationsListModal
          showModal={this.state.showClarificationsListModal}
          sortDirection={this.props.clarificationsStore.clarificationsListSortDirection}
          clarificationsList={sortedClarifications}
          onToggleChange={this.handleToggleChange}
          onSortDirectionChange={this.handleSortDirectionChange}
        />
      </div>
    );
  }
}

export default as<React.ComponentClass>(withNamespaces('common', { wait: true })(ClarificationsArchiveListContainer));
