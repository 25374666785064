﻿import * as React from 'react';

import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import { faBullseye, faDrawPolygon, faLongArrowAltRight, faMousePointer, faPaintBrush, faSlash, faVectorSquare } from '@fortawesome/free-solid-svg-icons';

import { AnnotationToolType } from '../modules/annotation/submodules/annotationTypes/models/annotationToolType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(
  faBullseye,
  faDrawPolygon,
  faVectorSquare,
  faLongArrowAltRight,
  faSlash,
  faMousePointer);

const ICONS = {
  Select: faMousePointer,
  Point: faBullseye,
  Rectangle: faVectorSquare,
  Polygon: faDrawPolygon,
  Vector: faLongArrowAltRight,
  Polyline: faSlash,
  RotatedRectangle: faVectorSquare,
  Brush: faPaintBrush,
};

interface IProps {
  toolType?: AnnotationToolType;
}

export const ToolTypeIcon = (props: IProps) => {
  if (props.toolType === AnnotationToolType.ROTATEDRECTANGLE) {
    return <FontAwesomeIcon icon={ICONS[props.toolType!] as IconDefinition} className="icon rotate30" />;
  }
  return <FontAwesomeIcon icon={ICONS[props.toolType!] as IconDefinition} className="icon" />;
};
