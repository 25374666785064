import { CacheManager } from '../../../services/cacheManager';
import { IDatasetPreviewImage as IDatasetPreviewImageInfo } from '../datasetsDetails.store';

export class ImagePreviewCache {
  private requestKey: string;

  constructor(workspaceId: string, datasetId: string, imageId: string) {
    this.requestKey = `/home/${workspaceId}/datasets/${datasetId}/preview/${imageId}`;
  }

  public async setNewImageNameAsync(newName: string) {
    const cacheAvailable = await CacheManager.checkCacheAvailabilityAsync();
    if (!cacheAvailable) {
      return;
    }

    const request = new Request(this.requestKey);
    const imagePreviewCache = await caches.open('image-preview-cache');
    const imagePreviewMatch = await imagePreviewCache.match(request);

    if (imagePreviewMatch) {
      const cachedPreviewImageData = await imagePreviewMatch.json();

      const cacheResult = {} as IDatasetPreviewImageInfo;
      Object.assign(cacheResult, cachedPreviewImageData) as IDatasetPreviewImageInfo;
      cacheResult.name = newName;
      imagePreviewCache.put(request, new Response(JSON.stringify(cacheResult)));
    }
  }

  async getImageInfoAsync(): Promise<IDatasetPreviewImageInfo | undefined> {
    const cacheAvailable = await CacheManager.checkCacheAvailabilityAsync();
    if (!cacheAvailable) {
      return undefined;
    }

    const request = new Request(this.requestKey);
    const imagePreviewCache = await caches.open('image-preview-cache');
    const imagePreviewMatch = await imagePreviewCache.match(request);

    if (imagePreviewMatch) {
      const cachedPreviewImageData = await imagePreviewMatch.json();
      const result = {} as IDatasetPreviewImageInfo;
      Object.assign(result, cachedPreviewImageData) as IDatasetPreviewImageInfo;
      return result;
    }
    
    return undefined;
  }

  async setImageInfoAsync(imageInfo: IDatasetPreviewImageInfo) {
    const request = new Request(this.requestKey);
    const imagePreviewCache = await caches.open('image-preview-cache');

    const previewImageInfo = JSON.stringify(imageInfo);
    imagePreviewCache.put(request, new Response(previewImageInfo));
  }
}
