import * as React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { ITranslatable } from '../../../helpers/translations.helpers';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IProps {
  onClose(): void;
}

class BrowserCompatibilityWarningPure extends React.Component<IProps & ITranslatable> {
  render() {
    const { t, onClose } = this.props;
    return (
      <Modal isOpen={true} toggle={onClose}>
        <ModalHeader>{t('browser_compatibility_warning_header')}</ModalHeader>
        <ModalBody>
          <p>{t('browser_compatibility_warning_body.0')}</p>
          <p dangerouslySetInnerHTML={{ __html: t('browser_compatibility_warning_body.1') }} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>{t('ok')}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default as<React.ComponentClass<IProps>>(withNamespaces('auth', { wait: true })(BrowserCompatibilityWarningPure));
