import * as React from 'react';

import { Button, ButtonProps } from 'reactstrap';
import { IRouterStore, RouterStoreType } from '../stores/router.store';
import { as, injectProps } from '../helpers/react.helpers';

interface IOuterProps extends ButtonProps {
  id?: string;
  to: string;
  text?: string;
  title?: string;
  disabled?: boolean;
  callback?(): void;
}
interface IInjectedProps {
  router: IRouterStore;
}
interface IProps extends IInjectedProps, IOuterProps { }

@injectProps({ router: RouterStoreType })
class LinkedButtonPure extends React.Component<IProps> {
  handleLinkClick = (e: any) => {
    e.preventDefault();
    if (this.props.callback) this.props.callback();
    this.props.router.push(this.props.to);
  };

  render() {
    const { text, callback, ...props } = this.props;
    return (
      <Button onClick={this.handleLinkClick} {...props}>
        {text}
        {this.props.children}
      </Button>
    );
  }
}

export const LinkedButton = as<React.ComponentClass<IOuterProps>>(LinkedButtonPure);
