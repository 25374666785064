import * as React from 'react';

import { Scrollbars } from 'react-custom-scrollbars';

export interface ICustomScrollbarProps {
  autoHide: boolean;
}

export class CustomScrollbar extends React.Component<ICustomScrollbarProps> {

  renderThumb({ style, ...props }: any) {
    return <div className="thumb-vertical" style={{ ...style }} {...props} />;
  }

  renderTrack({ style, ...props }: any) {
    return <div className="track-vertical" style={{ ...style }} {...props} />;
  }

  render() {
    return (
      <Scrollbars
        autoHide={this.props.autoHide}
        autoHideTimeout={0}
        autoHideDuration={0}
        renderThumbVertical={this.renderThumb}
        renderTrackVertical={this.renderTrack}
      >
        {this.props.children}
      </Scrollbars>
    );
  }
}
