import { injectable } from 'inversify';
import { observable } from 'mobx';

export const IntegrationsStoreType = Symbol('INTEGRATIONS_STORE');

export interface IApiKey {
  id: string;
  name: string;
  value: string;
  isActive: boolean;
  isActiveText: string;
}

export interface IIntegrationsStore {
  apiKeys: IApiKey[];
}

@injectable()
export class IntegrationsStore implements IIntegrationsStore {
  @observable
  apiKeys: IApiKey[] = [];
}
