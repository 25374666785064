import './S_LinkInTable.scss';

import React from 'react';
import { S_DisabledProps } from '../../CommonProps';
import classNames from 'classnames';

interface S_LinkInTableProps extends S_DisabledProps {
  title: string;
  onClick: () => void;
}

export const S_LinkInTable: React.FC<S_LinkInTableProps> = props => {
  const handleClick = () => {
    if (props.disabled) return;
    props.onClick();
  };

  return (
    <span className={classNames('s-link-in-table', { disabled: props.disabled })} title={props.title} onClick={handleClick}>
      {props.children}
    </span>
  );
};
