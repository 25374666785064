import * as React from 'react';

import { ModelDatasetsList } from '../components/ModelDatasetsList';
import { as, injectProps } from '../../../../__legacy__/helpers/react.helpers';
import { ITranslatable } from '../../../../__legacy__/helpers/translations.helpers';
import { observer } from 'mobx-react';
import { IModelDetailsService, ModelDetailsServiceType } from '../../modelDetails.service';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../common/OverlayLoader.store';
import { IWorkspaceService, WorkspaceServiceType } from '../../../../__legacy__/modules/workspaces/workspaces.service';
import { ModelPermissions } from '../../../../__legacy__/modules/workspaces/workspaces.store';

interface IInjectedProps extends ITranslatable {
  modelDetailsService: IModelDetailsService;
  overlayLoader: IOverlayLoaderStore;
  workspaceService: IWorkspaceService;
}

@injectProps({
  modelDetailsService: ModelDetailsServiceType,
  overlayLoader: OverlayLoaderStoreType,
  workspaceService: WorkspaceServiceType,
})
@observer
class ModelDatasetsContainerPure extends React.Component<IInjectedProps> {
  changeDatasetSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.modelDetailsService.changeDatasetSelection(e.target.value);
  };

  render() {
    const { datasets, isOwner, status, datasetIds } = this.props.modelDetailsService.store;
    const workspaceRole = this.props.workspaceService.getUserRoleInCurrentWorkspace()

    return (
      <ModelDatasetsList
        isFormDisabled={isOwner ? !ModelPermissions.updateModelWhenOwner.includes(workspaceRole) : !ModelPermissions.updateModel.includes(workspaceRole)}
        datasets={datasets}
        datasetIds={datasetIds}
        status={status}
        isLoading={!!this.props.overlayLoader.isSpinnerVisible('model-datasets-list')}
        changeDatasetSelection={this.changeDatasetSelection}
        changeAllDatasetsSelection={this.props.modelDetailsService.changeAllDatasetsSelection}
      />
    );
  }
}

export const ModelDatasetsContainer = as<React.ComponentClass>(ModelDatasetsContainerPure);
