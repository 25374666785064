import { DrawRectangle } from '../components/DrawControl.models/Draw.rectangle';
import { IUndoRedoCommand } from './undoRedoCommand';
import { LatLng } from 'leaflet';

export class AddRectangleVertexCommand implements IUndoRedoCommand {
  private rectangle: DrawRectangle;
  private latlng: LatLng;

  constructor(
    rectangle: DrawRectangle,
    latlng: LatLng,
  ) {
    this.rectangle = rectangle;
    this.latlng = latlng;
  }

  executeUndo() {
    this.rectangle.disable();
    this.rectangle.enable();
  }

  executeRedo(): void {
    this.rectangle.addVertex(this.latlng);
    this.rectangle._onMouseMove(this.rectangle.lastMouseMoveEvent);
  }
}
