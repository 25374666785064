import { inject, injectable } from 'inversify';
import { set } from 'mobx';
import { ForbiddenError, StickerError } from '../../../../__legacy__/models/error.model';
import { NotificationLevel, ToastNotification } from '../../../../__legacy__/modules/notifications/models/notification.model';
import { INotificationsService, NotificationsServiceType } from '../../../../__legacy__/modules/notifications/services/notifications.service';
import { IImageFilters, ImageDefaultFilters } from '../../../../__legacy__/modules/projectDetails/sub/images/imageFilters.model';
import { ImageAnnotationStatus } from '../../../../__legacy__/modules/projectDetails/sub/images/projectDetailsImages.model';
import { IImageFilterService, ImageFilterServiceType } from '../../../../__legacy__/modules/projectDetails/sub/images/services/imageFilters.service';
import { IProjectOverviewStore, ProjectOverviewStoreType } from './projectOverview.store';
import { IProjectOverviewApiService, ProjectOverviewApiServiceType } from './projectOverviewApi.service';

const getProjectDetailsFailedMessage = 'project:get_project_details_failed';

export const ProjectOverviewBlType = Symbol('PROJECT_OVERVIEW_SERVICE');

export interface IProjectOverviewBl {
  getProjectDetailsAnnotatingAsync(projectId: string): Promise<void | StickerError>;
  getUnclearImagesFilter(projectId: string): Promise<void | StickerError>;

  store: IProjectOverviewStore;
}

@injectable()
export class ProjectOverviewBl implements IProjectOverviewBl {
  constructor(
    @inject(ProjectOverviewApiServiceType) private readonly api: IProjectOverviewApiService,
    @inject(ProjectOverviewStoreType) public readonly store: IProjectOverviewStore,
    @inject(NotificationsServiceType) private readonly notifications: INotificationsService,
    @inject(ImageFilterServiceType) private readonly imageFilter: IImageFilterService,
  ) {}

  async getProjectDetailsAnnotatingAsync(projectId: string) {
    const result = await this.api.getProjectOverviewAsync(projectId);
    this.notifyAboutApiError(result, getProjectDetailsFailedMessage);
    if (result instanceof StickerError) return;

    set(this.store, result);
  }

  async getUnclearImagesFilter(projectId: string) {
    const clarificationFilter: IImageFilters = { ...ImageDefaultFilters, annotationStatuses: [ImageAnnotationStatus.CLARIFICATION] };
    const unclearImagesFilterId = await this.imageFilter.upsertFiltersAsync(projectId, clarificationFilter);

    set(this.store, { unclearImagesFilterId });
  }

  notifyAboutApiError(result: any, errorCode: string, dontShowBadRequest: boolean = false) {
    if (result instanceof StickerError && !(result instanceof ForbiddenError) && (!dontShowBadRequest || !result.isBadRequest())) {
      this.notifications.push(new ToastNotification(NotificationLevel.ERROR, errorCode));
    }
  }
}
