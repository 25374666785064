import { BaseAnnotationControl, IBaseAnnotationControl } from './baseAnnotationControl';
import { IAnnotationDto, ISegmentation, IImage, IQuestionModelDto, AnnotationStatus } from './annotations.interface';
import { ProjectHubServiceType, IProjectHubService } from '../../services/projectHub.service';
import { AnnotationUiStoreType, IAnnotationUiStore } from './annotationUi.store';
import { AnnotationServiceType, IAnnotationService } from './annotation.service';
import { AnnotationApiServiceType, IAnnotationApiService } from './annotationApi.service';
import { IImagesQueueService } from './imagesQueueServiceBase';
import { NotificationsServiceType, INotificationsService } from '../notifications/services/notifications.service';

import { StickerError } from '../../models/error.model';
import { action } from 'mobx';
import { injectable, inject } from 'inversify';

import uuid from 'uuid';
import { ToastNotification, NotificationLevel } from '../notifications/models/notification.model';
import { IRouterStore, RouterStoreType } from '../../stores/router.store';
import { WorkspaceRole } from '../workspaces/workspaces.store';
import { IAnswerModel, ImageScopeName, IQuestionModel, QuestionModel, QuestionType } from './question.model';
import { IFreeDrawSegmentationService, FreeDrawSegmentationServiceType } from './freeDrawSegmentation.service';
import { IUndoRedoHistory, UndoRedoHistoryType } from './undoRedoHistory.service';
import { LatLngBounds } from 'leaflet';
import { AnnotationTypeBlType, IAnnotationTypeBl } from './submodules/annotationTypes/annotationType.bl';
import { getLatLngsForGeojson } from '../../helpers/geometry/polygon.helpers';
import { TimerServiceType, ITimerService } from '../../services/timer.service';
import { AnnotationsStoreType, IAnnotationsStore } from './annotations.store';
import { EventBusType, EventListeningDisposer, IEventBus } from '../../services/eventBus.service';
import { ClarificationAddedEvent, ClarificationAddedEventType } from './submodules/clarifications/events/ClarificationAddedEvent';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { BatchAnnotationApiServiceType, IBatchAnnotationApiService } from '../../../modules/editor/services/BatchAnnotationApi.service';
import { ISaveAnnotationDraftRequest, ISubmitAnnotationForReviewRequest } from '../../../modules/editor/models/Requests';

export const AnnotationCreationServiceType = Symbol('ANNOTATION_CREATION_SERVICE');

export interface IAnnotationCreationService extends IBaseAnnotationControl {
  setupAsync(projectId: string, imagesQueueService: IImagesQueueService): Promise<void>;
  safelyLeaveAnnotationAsync(): Promise<boolean>;
  unlockImagesAsync(): void;
  skipAnnotationsAsync(): void;

  batchCorrectAnnotationAsync(): Promise<boolean>;
  batchCorrectAndSubmitAnnotationForReviewAsync(): Promise<boolean>;
  batchSaveAnnotationDraftAsync(): Promise<boolean>;
  batchAbandonAnnotationDraftAsync(saveData: boolean): Promise<boolean>;
  batchSubmitAnnotationForReviewAsync(): Promise<boolean>;
  batchDiscardAnnotationDuringAnnotationAsync(): Promise<boolean>;
}

@injectable()
export class AnnotationCreationService extends BaseAnnotationControl implements IAnnotationCreationService {
  private eventListenersDisposer: EventListeningDisposer[] = [];

  constructor(
    @inject(FreeDrawSegmentationServiceType) private readonly freeDrawSegmentationService: IFreeDrawSegmentationService,
    @inject(ProjectHubServiceType) private readonly projectHubService: IProjectHubService,
    @inject(AnnotationApiServiceType) private readonly annotationApiService: IAnnotationApiService,
    @inject(AnnotationServiceType) private readonly annotationService: IAnnotationService,
    @inject(AnnotationTypeBlType) private readonly annotationTypeBl: IAnnotationTypeBl,
    @inject(RouterStoreType) private readonly routerStore: IRouterStore,
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore,
    @inject(UndoRedoHistoryType) private readonly undoRedoService: IUndoRedoHistory,
    @inject(NotificationsServiceType) notificationsService: INotificationsService,
    @inject(AnnotationUiStoreType) uiStore: IAnnotationUiStore,
    @inject(AnnotationsStoreType) annotationsStore: IAnnotationsStore,
    @inject(TimerServiceType) public readonly timer: ITimerService,
    @inject(EventBusType) public readonly eventBus: IEventBus,
    @inject(BatchAnnotationApiServiceType) private readonly batchAnnotationApiService: IBatchAnnotationApiService,
  ) {
    super(uiStore, notificationsService, annotationsStore);
  }

  handleImageDisplayed = (image: IImage) => this.loadAnnotations(image);

  @action
  async setupAsync(projectId: string, imagesQueueService: IImagesQueueService) {
    this.annotationsStore.projectId = projectId;
    this.imagesQueueService = imagesQueueService;
    this.projectHubService.initializeAsync();
    this.initReactions();
    this.imagesQueueService.startAsync(true);
    await Promise.all([this.annotationTypeBl.creationStarted(projectId), this.requestQuestionsAsync(), this.updateAnnotationProgressAsync()]);
    await this.displayNextImageAsync();

    this.addEventListeners();
  }

  async displayNextImageAsync() {
    await this.displayImageFromQueueAsync();
    this.timer.startTimer();
  }

  private addEventListeners() {
    this.eventListenersDisposer.push(this.eventBus.addListener<ClarificationAddedEvent>(this.clarificationAddedListenerAsync, ClarificationAddedEventType));
  }

  private clearEventListeners() {
    this.eventListenersDisposer.forEach(d => d());
    this.eventListenersDisposer = [];
  }

  @action
  async batchSubmitAnnotationForReviewAsync(): Promise<boolean> {
    this.uiStore.isInValidation = true;
    if (!this.annotationService.validateAnnotations()) {
      this.annotationTypeBl.handleSubmitAnnotationInCreation();
      return false;
    }

    const task = this.batchAnnotationApiService.submitForReview(this.mapToSubmitAnnotationRequest());

    this.freeDrawSegmentationService.clear();
    this.annotationService.fixRectangles();

    this.cleanupAfterSave();

    await this.displayImageFromQueueAsync();

    const result = await task;

    if (result instanceof StickerError) {
      this.handleStickerErrors(result);
      return false;
    }

    this.updateAnnotationProgressAsync();
    this.refreshQuestionsIfNeeded();

    return true;
  }

  safelyLeaveAnnotationAsync(): Promise<boolean> {
    return this.annotationsStore.annotationStatus === AnnotationStatus.REJECTED ? this.batchCorrectAnnotationAsync() : this.batchSaveAnnotationDraftAsync();
  }

  @action
  async batchCorrectAnnotationAsync(): Promise<boolean> {
    this.uiStore.isInValidation = true;
    if (!this.annotationService.validateAnnotations()) {
      this.annotationTypeBl.handleSaveAnnotationsInCreation();
      return false;
    }

    this.freeDrawSegmentationService.clear();
    this.annotationService.fixRectangles();

    const task = this.batchAnnotationApiService.correctAsync({
      id: this.annotationsStore.id!,
      duration: this.timer.duration,
      annotation: this.mapToAnnotationDto().annotation,
    });

    return await this.finishWorkWithImage(task);
  }

  @action
  async batchCorrectAndSubmitAnnotationForReviewAsync(): Promise<boolean> {
    this.uiStore.isInValidation = true;
    if (!this.annotationService.validateAnnotations()) {
      this.annotationTypeBl.handleSaveAnnotationsInCreation();
      return false;
    }

    this.freeDrawSegmentationService.clear();
    this.annotationService.fixRectangles();

    const task = this.batchAnnotationApiService.correctAndSubmitForReviewAsync({
      id: this.annotationsStore.id!,
      duration: this.timer.duration,
      annotation: this.mapToAnnotationDto().annotation,
    });

    return await this.finishWorkWithImage(task);
  }

  @action
  async batchSaveAnnotationDraftAsync(): Promise<boolean> {
    this.freeDrawSegmentationService.clear();
    this.annotationService.fixRectangles();

    const createAnnotationTask = this.batchAnnotationApiService.saveDraft(this.mapToAnnotationDto());

    this.cleanupAfterSave();

    const result = await createAnnotationTask;

    if (result instanceof StickerError) this.handleStickerErrors(result);

    return result instanceof StickerError;
  }

  @action
  async batchAbandonAnnotationDraftAsync(saveData: boolean): Promise<boolean> {
    this.freeDrawSegmentationService.clear();
    this.annotationService.fixRectangles();

    const data = saveData ? this.mapToAnnotationDto().annotation : null;
    const task = this.batchAnnotationApiService.abandonDraft({ id: this.annotationsStore.id!, annotation: data, duration: this.timer.duration });

    return await this.finishWorkWithImage(task);
  }

  @action
  async batchDiscardAnnotationDuringAnnotationAsync(): Promise<boolean> {
    this.freeDrawSegmentationService.clear();
    this.annotationService.fixRectangles();

    const request = { id: this.annotationsStore.id!, duration: this.timer.duration };
    const task = this.batchAnnotationApiService.discardDuringAnnotation(request);

    return await this.finishWorkWithImage(task);
  }

  @action.bound
  async skipAnnotationsAsync() {
    if (!(await this.freeDrawSegmentationService.clearAsync())) return;

    this.annotationService.fixRectangles();

    const task = this.annotationApiService.unlockImageAsync(this.annotationsStore.image!.id, this.annotationsStore.projectId, true);

    return await this.finishWorkWithImage(task);
  }

  private async finishWorkWithImage(task: Promise<void | StickerError>): Promise<boolean> {
    this.cleanupAfterSave();

    await this.displayImageFromQueueAsync();

    const result = await task;

    if (result instanceof StickerError) {
      this.handleStickerErrors(result);
      return false;
    }

    this.updateAnnotationProgressAsync();
    this.refreshQuestionsIfNeeded();
    this.annotationTypeBl.handleFinishWorkWithImageInCreation();

    return true;
  }

  @action.bound
  async unlockImagesAsync() {
    if (!!this.annotationsStore.projectId) {
      await this.annotationApiService.unlockUserImagesForProjectAsync(this.annotationsStore.projectId);
    }
  }

  @action
  async requestQuestionsAsync() {
    const result = await this.annotationApiService.requestQuestionsAsync(this.annotationsStore.projectId);
    if (result instanceof Error) throw result;
    this.annotationsStore.questions = [...result];
    this.annotationService.setImageQuestionsAsCurrent();
  }

  @action.bound
  refreshQuestionsIfNeeded() {
    this.annotationService.setImageQuestionsAsCurrent();
    if (this.projectHubService.didQuestionsChangedFor(this.annotationsStore.projectId)) {
      const result = this.projectHubService.popNewQuestionsFor(this.annotationsStore.projectId);
      this.annotationsStore.questions = [...result];
      this.annotationService.setImageQuestionsAsCurrent();
    }
  }

  @action.bound
  loadAnnotations(image: IImage): void {
    const annotationInfoResult = image.annotations;

    this.annotationsStore.annotationStatus = annotationInfoResult?.status;

    if (!annotationInfoResult) {
      return;
    }

    this.annotationsStore.annotationStatus = annotationInfoResult.status;

    const annotations = annotationInfoResult.annotations;
    if (annotations === undefined) throw new StickerError('No annotations found', undefined);

    this.annotationsStore.id = annotationInfoResult.id;
    const segmentations: ISegmentation[] = annotations.segmentations.map(s => {
      const feature = {
        ...s.feature,
        id: uuid.v4(),
        color: this.annotationService.getAnnotationTypeColor(s.feature.properties!.annotationTypeId),
        featureType: s.feature.properties!.featureType,
      };

      const latlngs = getLatLngsForGeojson(feature);

      return {
        feature,
        latlngs,
        id: s.id,
        questions: this.mapQuestions(s.feature.properties!.annotationTypeId, s.questions),
        bbox: new LatLngBounds(latlngs),
        priority: s.priority,
      } as ISegmentation;
    });

    this.annotationsStore.setSegmentations(segmentations.filter(s => s.feature.color !== 'black'));
    this.annotationsStore.questions = this.mapQuestions(undefined, annotationInfoResult.annotations.questions);
    this.annotationService.setImageQuestionsAsCurrent();
  }

  dispose() {
    super.dispose();
    this.imagesQueueService.clear();
    this.annotationService.clear();
    this.freeDrawSegmentationService.clear();
    this.annotationTypeBl.creationFinished();
    this.clearEventListeners();
  }

  private async updateAnnotationProgressAsync() {
    const result = await this.annotationApiService.getAnnotationProgressAsync(this.annotationsStore.projectId);
    if (result instanceof StickerError) return;
    this.annotationsStore.annotationProgress = result;
  }

  private mapQuestions(annotationTypeId: string | undefined, questionsDtos: IQuestionModelDto[]): IQuestionModel[] {
    const currentQuestions = annotationTypeId ? this.annotationsStore.questions.filter(q => q.scopes.includes(annotationTypeId)) : this.annotationsStore.questions;

    const models: IQuestionModel[] = [];
    for (const currentQuestion of currentQuestions) {
      const existingAnswer = questionsDtos.find(x => x.id === currentQuestion.id);

      const questionModel = new QuestionModel(
        currentQuestion.projectId,
        currentQuestion.id,
        currentQuestion.type as QuestionType,
        currentQuestion.isRequired,
        currentQuestion.text,
        currentQuestion.answers.map((x: IAnswerModel) => {
          return { id: x.id, selected: x.selected, text: x.text };
        }),
        currentQuestion.scopes,
        currentQuestion.answer,
      );

      questionModel.answers.forEach((x: IAnswerModel) => {
        const existing = existingAnswer?.answers.find(y => y.id === x.id);
        x.selected = existing?.selected || false;
      });
      questionModel.answer = existingAnswer?.answer;

      models.push(questionModel);
    }

    return models;
  }

  private cleanupAfterSave() {
    this.undoRedoService.clearDrawingHistory();
    this.undoRedoService.clearHistory();
    this.freeDrawSegmentationService.clear();
    this.annotationService.clearAnnotations();
    this.annotationService.clearAnswers();
    this.uiStore.isInValidation = false;
  }

  private handleStickerErrors(e: StickerError) {
    if (e.apiErrorResponse!.errorCodes.includes('NOT_ENOUGH_CREDITS')) {
      const currentWorkspace = this.currentWorkspaceStore.currentWorkspace;

      const message = currentWorkspace?.role === WorkspaceRole.Owner ? 'no_more_credits_description_owner' : 'no_more_credits_description';

      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, '', `common:${message}`));
      this.routerStore.push('/');
    } else if (e.isBadRequestWithCode(['PROJECT_NOT_FOUND'])) {
      this.annotationsStore.image = undefined;
      this.imagesQueueService.areAnyImagesToLoad = false;
    } else if (e.isBadRequestWithCode(['IMAGE_ALREADY_LOCKED'])) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, '', 'image_is_locked_by_another_user'));
    } else {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, '', 'something_went_wrong'));
    }

    return;
  }

  private mapToSubmitAnnotationRequest(): ISubmitAnnotationForReviewRequest {
    const id = this.annotationsStore.id || uuid.v4();
    return {
      id,
      projectId: this.annotationsStore.projectId,
      imageId: this.annotationsStore.image!.id,
      duration: this.timer.duration,
      annotation: {
        id,
        questions: this.annotationsStore.questions
          .filter(q => q.scopes.includes(ImageScopeName))
          .map(x => ({ id: x.id, answer: x.answer, answers: x.answers.map(a => ({ id: a.id, selected: a.selected })) })),
        segmentations: this.annotationsStore.segmentations.map(s => ({
          id: s.id,
          questions: s.questions,
          feature: {
            type: s.feature.type,
            properties: s.feature.properties,
            geometry: s.feature.geometry,
          },
          priority: 0,
        })),
      },
    };
  }

  private mapToAnnotationDto(): ISaveAnnotationDraftRequest {
    const id = this.annotationsStore.id || uuid.v4();
    return {
      id,
      projectId: this.annotationsStore.projectId,
      imageId: this.annotationsStore.image!.id,
      duration: this.timer.duration,
      annotation: {
        id,
        questions: this.annotationsStore.questions
          .filter(q => q.scopes.includes(ImageScopeName))
          .map(q => ({ id: q.id, answer: q.answer, answers: q.answers.map(a => ({ id: a.id, selected: a.selected })) })),
        segmentations: this.annotationsStore.segmentations.map(s => ({
          id: s.id || uuid.v4(),
          questions: s.questions,
          feature: {
            type: s.feature.type,
            properties: s.feature.properties,
            geometry: s.feature.geometry,
          },
          priority: s.priority,
        })),
      } as IAnnotationDto,
    };
  }

  @action.bound
  private async clarificationAddedListenerAsync(event: ClarificationAddedEvent) {
    if (this.undoRedoService.canUndo) {
      this.freeDrawSegmentationService.clear();
      this.annotationService.fixRectangles();
    }

    this.cleanupAfterSave();
    await this.displayNextImageAsync();
  }
}
