import { Col, Form, Row } from 'reactstrap';
import { faCheckSquare, faEdit, faTrashAlt, faUser } from '@fortawesome/free-solid-svg-icons';

import { DownloadAsCsvButtonContainer } from '../containers/DownloadAsCsvButton.container';
import { NoStatisticsMessageContainer } from '../containers/NoStatisticsMessage.container';
import React from 'react';
import { StatisticsListContainer } from '../containers/StatisticsList.container';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';

library.add(faTrashAlt, faEdit, faUser, faCheckSquare);

@observer
export class OverallStatistics extends React.Component {
  render() {
    return (
      <Row>
        <Col>
          <Row>
            <Col>
              <Form>
                <Row>
                  <Col>
                    <NoStatisticsMessageContainer />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <DownloadAsCsvButtonContainer />
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              <StatisticsListContainer />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
