import { AttachmentUploadServiceType, IAttachmentUploadService } from '../attachmentUploadService';
import { AttachmentsServiceType, IAttachmentsService } from '../attachments.service';
import { AttachmentsStoreType, IAttachmentsStore } from '../attachments.store';
import { BillingServiceType, IBillingService } from '../../billing/billing.service';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { IUserStore, UserStoreType } from '../../user/user.store';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';
import { faCircleNotch, faDownload } from '@fortawesome/free-solid-svg-icons';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import autobind from 'autobind-decorator';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

library.add(faCircleNotch, faDownload);

interface IInjectedProps extends RouteComponentProps<{ attachmentId: string }> {
  uploadService: IAttachmentUploadService;
  currentWorkspaceStore: ICurrentWorkspaceStore;
  attachmentStore: IAttachmentsStore;
  billingService: IBillingService;
  userStore: IUserStore;
  attachmentsService: IAttachmentsService;
}

@injectProps({
  uploadService: AttachmentUploadServiceType,
  attachmentsService: AttachmentsServiceType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  attachmentStore: AttachmentsStoreType,
  billingService: BillingServiceType,
  userStore: UserStoreType,
})
@observer
class AttachmentDownloadContainerPure extends React.Component<IInjectedProps & ITranslatable> {
  constructor(props: IInjectedProps & ITranslatable) {
    super(props);
  }

  @autobind
  async onDownload() {
    const store = this.props.attachmentStore;
    await this.props.uploadService.downloadAttachmentsAsync(
      store.selectedAttachments.isAllSelected,
      store.selectedAttachments.ids,
      this.props.match.params.attachmentId);
  }

  render() {
    const isLocked = this.props.currentWorkspaceStore.currentWorkspace?.locked || this.props.billingService.billing.isAnyPolicyLimitExceeded;
    const isDisabled = !this.props.attachmentStore.selectedAttachments.isAllSelected &&
      this.props.attachmentStore.selectedAttachments.ids && !this.props.attachmentStore.selectedAttachments.ids.length ||
      this.props.uploadService.data.currentDownloadAttachment.some(a => a.isBatch) ||
      isLocked ||
      !this.props.attachmentStore.attachments.length;
    const isBatch = this.props.uploadService.data.currentDownloadAttachment.some(a => a.isBatch);
    const canView = this.props.attachmentsService.canUseAttachments();
    const title = `${canView ? isLocked ? this.props.t('common:policy_limit_exceeded_block') : '' : this.props.t('you_do_not_have_permission_to_perform_this_operation')}`;

    return (
      <Button
        className={'attachments-button download'}
        color={'primary'}
        onClick={this.onDownload}
        disabled={!canView || isDisabled}
        title={title}
      >
        <FontAwesomeIcon icon={faDownload} /> {this.props.t('download_attachments')} {isBatch ? <FontAwesomeIcon icon={faCircleNotch} className={'fa-spin'} /> : null}
      </Button>
    );
  }
}

export const AttachmentDownloadContainer = as<React.ComponentClass>(withRouter(withNamespaces('attachment', { wait: true })(AttachmentDownloadContainerPure)));
