import ClipperLib, { ClipType } from 'clipper-lib';
import L, { LatLng } from 'leaflet';
import { distance, multiply, subtract, sum, toUnitVector } from '../../../../../helpers/geometry/vector.helpers';

import { createPolygonFromBounds } from '../../../../../helpers/geometry/polygon.helpers';
import { getImageBounds } from '../../../../../helpers/geometry/mapExtensions.helpers';
import uuid from 'uuid';

const CLIPPER_MULTIPLIER = 1000;

export interface IBBox {
  left: number;
  top: number;
  right: number;
  bottom: number;
}

export interface IGeometry {
  id: string;
  isNew: boolean;
  shape: Shape;
}

export interface IPoint {
  X: number;
  Y: number;
}

export type Path = IPoint[];

export type Shape = Path[];

declare global {
  interface Array<T> {
    getBBox(): IBBox;
    getOrientation(): boolean;
    bbox?: IBBox;
    orientation?: boolean;
  }
}

Array.prototype.getBBox = function () {
  if (this.bbox) {
    return this.bbox;
  }

  let shape: Shape = [];
  if (!(Array.isArray(this) && Array.isArray(this[0]))) {
    shape = [this as Path];
  } else {
    shape = this as Shape;
  }

  this.bbox = ClipperLib.Clipper.GetBounds(shape);

  return this.bbox;
};

Array.prototype.getOrientation = function () {
  if (this.orientation) {
    return this.orientation;
  }

  this.orientation = ClipperLib.Clipper.Orientation(this as Path);

  return this.orientation;
};

export function interpolateLineToShape(axisStartPoint: LatLng, axisEndPoint: LatLng, radius: number): Shape | null {
  if (!axisStartPoint || !axisEndPoint) return null;

  const offset = distance(axisStartPoint, axisEndPoint);

  if (offset > radius / 2) {

    const axisVector = subtract(axisEndPoint, axisStartPoint);
    const rotated90DegVector = new LatLng(axisVector.lng, -axisVector.lat);
    const scaleVector = toUnitVector(rotated90DegVector);
    const halfEdgeVector = multiply(scaleVector, radius);

    const a = sum(axisStartPoint, halfEdgeVector);
    const b = sum(axisEndPoint, halfEdgeVector);
    const c = subtract(axisStartPoint, halfEdgeVector);
    const d = subtract(axisEndPoint, halfEdgeVector);

    return latlngToShape([[a, b, d, c, a]]);
  }

  return null;
}

export function getCircleAsShape(latlng: LatLng, radius: number): Shape {
  const numberOfEdges = 16;
  const vertices: LatLng[] = [];

  const angleShift = (2 * Math.PI) / numberOfEdges;
  let phi = 0;

  for (let i = 0; i < numberOfEdges; i += 1) {
    phi += angleShift;
    vertices.push(new LatLng(latlng.lat + radius * Math.cos(phi), latlng.lng + radius * Math.sin(phi)));
  }

  return latlngToShape([vertices]);
}

export function clipShapes(first: Shape, second: Shape, type: ClipperLib.ClipType): Shape {
  const solution = ClipperLib.Paths();
  const cpr = new ClipperLib.Clipper();

  cpr.AddPaths(first, ClipperLib.PolyType.ptSubject, true);
  cpr.AddPaths(second, ClipperLib.PolyType.ptClip, true);

  cpr.Execute(type, solution);

  return ClipperLib.JS.Lighten(solution, 1);
}

export function normalizeMultipolygon(shapes: Shape[]): IGeometry[] {
  const solution = ClipperLib.Paths();

  const cpr = new ClipperLib.Clipper();
  for (let s = 0, slen = shapes.length; s < slen; s += 1) {
    cpr.AddPaths(shapes[s], ClipperLib.PolyType.ptSubject, true);
  }
  for (let c = 0, clen = shapes.length; c < clen; c += 1) {
    cpr.AddPaths(shapes[c], ClipperLib.PolyType.ptClip, true);
  }

  cpr.Execute(ClipperLib.ClipType.ctUnion, solution);

  const light = ClipperLib.JS.Lighten(solution, 1);

  return normalizeShape(light);
}

export function normalizeShape(shape: Shape) {
  const borders: Path[] = [];
  const holes: Path[] = [];

  shape.forEach((path) => {
    if (path.getOrientation()) {
      borders.push(path);
    } else {
      holes.push(path);
    }
  });

  const results: IGeometry[] = borders.map(border => ({ shape: [border], id: uuid.v4(), isNew: true }));

  for (const hole of holes) {
    const holeBBox = hole.getBBox();
    // find potential borders
    const potentialBorders = [];
    for (const border of results) {
      const bbox = border.shape.getBBox();
      if (holeBBox.left >= bbox.left &&
        holeBBox.right <= bbox.right &&
        holeBBox.bottom <= bbox.bottom &&
        holeBBox.top >= bbox.top) {
        potentialBorders.push(border);
      }
    }

    if (potentialBorders.length) {
      if (potentialBorders.length === 1) {
        // single hit
        potentialBorders[0].shape.push(hole);
      } else {
        // mutliple hits - find closest
        const bordersWithDistance = potentialBorders
          .map((b) => {
            const bbox = b.shape.getBBox();
            return ({
              border: b,
              closestDistance: Math.min(holeBBox.left - bbox.left, bbox.right - holeBBox.right, holeBBox.top - bbox.top, bbox.bottom - holeBBox.bottom),
              isInside: isInside(b.shape, [hole]),
            });
          });
        const closest = bordersWithDistance.filter(x => x.isInside).reduce((prev, current) => (prev.closestDistance <= current.closestDistance) ? prev : current);
        closest.border.shape.push(hole);
      }
    }
  }

  return results;
}

function isInside(outer: Shape, inner: Shape) {
  for (const path of inner) {
    for (const point of path) {
      if (!isPointInPolygon(point, outer)) {
        return false;
      }
    }
  }
  return true;
}

export default function isPointInPolygon(point: IPoint, shape: Shape) {
  let insideShape = false;
  // check if it is in the outer ring first
  if (inPath(point, shape[0], false)) {
    let inHole = false;
    let iterator = 1;
    // check for the point in any of the holes
    while (iterator < shape.length && !inHole) {
      if (inPath(point, shape[iterator], true)) {
        inHole = true;
      }
      iterator += 1;
    }
    if (!inHole) {
      insideShape = true;
    }
  }

  return insideShape;
}

function inPath(point: IPoint, path: Path, ignoreBoundary?: boolean) {
  const pt = point;
  let isInside = false;
  for (let i = 0, j = path.length - 1; i < path.length; j = i, i += 1) {
    const xi = path[i].Y;
    const yi = path[i].X;
    const xj = path[j].Y;
    const yj = path[j].X;
    const onBoundary =
      pt.X * (xi - xj) + yi * (xj - pt.Y) + yj * (pt.Y - xi) === 0 &&
      (xi - pt.Y) * (xj - pt.Y) <= 0 &&
      (yi - pt.X) * (yj - pt.X) <= 0;
    if (onBoundary) {
      return !ignoreBoundary;
    }
    const intersect =
      yi > pt.X !== yj > pt.X &&
      pt.Y < ((xj - xi) * (pt.X - yi)) / (yj - yi) + xi;
    if (intersect) {
      isInside = !isInside;
    }
  }
  return isInside;
}

export function intersectShapeWithImagebounds(map: L.Map, shape: Shape): Shape {
  const imageBounds = getImageBounds(map);

  const cpr = new ClipperLib.Clipper();
  const solution = ClipperLib.Paths();
  const imageBoundsPath = latlngToShape(createPolygonFromBounds(imageBounds).getLatLngs());

  cpr.AddPaths(shape, ClipperLib.PolyType.ptSubject, true);
  cpr.AddPaths(imageBoundsPath, ClipperLib.PolyType.ptClip, true);

  cpr.Execute(ClipType.ctIntersection, solution);

  return ClipperLib.JS.Lighten(solution, 1);
}

export function latlngToShape(polygonsInput: LatLng[] | LatLng[][] | LatLng[][][]): Shape {
  let polygons: LatLng[][][];

  if (!(Array.isArray(polygonsInput) && Array.isArray(polygonsInput[0]))) {
    polygons = [[polygonsInput as LatLng[]]];
  } else if (!(Array.isArray(polygonsInput) && Array.isArray(polygonsInput[0]) && Array.isArray(polygonsInput[0][0]))) {
    polygons = [polygonsInput as LatLng[][]];
  } else {
    polygons = polygonsInput as LatLng[][][];
  }

  const points: Shape = [];

  for (let i = 0, ilen = polygons.length; i < ilen; i += 1) {
    points.push([]);
    for (let j = 0, jlen = polygons[i].length; j < jlen; j += 1) {
      const rings = polygons[i][j];
      for (let k = 0, klen = rings.length; k < klen; k += 1) {
        const coords = rings[k];
        if (Array.isArray(coords)) {
          for (let m = 0, klen = coords.length; m < klen; m += 1) {
            const coord = coords[m];
            points[j].push({ X: coord.lng * CLIPPER_MULTIPLIER, Y: coord.lat * CLIPPER_MULTIPLIER });
          }
        } else {
          points[j].push({ X: coords.lng * CLIPPER_MULTIPLIER, Y: coords.lat * CLIPPER_MULTIPLIER });
        }
      }
    }
  }
  return points;
}

export function shapeToCoords(shape: Shape): number[][][] {
  return shape.map(path => path.map(point => [point.Y / CLIPPER_MULTIPLIER, point.X / CLIPPER_MULTIPLIER]));
}

export function multipoligonToPoints(polygonsInput: number[][][][]): Shape[] {
  return polygonsInput.map(i => i.map(j => j.map(k => ({ X: k[0] * CLIPPER_MULTIPLIER, Y: k[1] * CLIPPER_MULTIPLIER }))));
}
