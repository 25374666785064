import * as React from 'react';

import { Col, CustomInput, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Trans, WithNamespaces, withNamespaces } from 'react-i18next';

import { Bold } from '../../../components/BoldText';
import { ButtonWithLoader } from '../../../components/ButtonWithLoader';
import { ProjectPublishStrategy } from '../projectDetails.models';
import { as } from '../../../helpers/react.helpers';

interface IPublishDatasetConfirmationModalProps {
  showModal: boolean;
  draftsCount: number;
  hasImportedAnnotations: boolean;
  strategy: ProjectPublishStrategy;
  onConfirm(): void;
  onCancel(): void;
  onStrategyChange(strategy: ProjectPublishStrategy): void;
  projectId?: string;
  disablePublishButton?: boolean;
}

const ProjectPublishConfirmationModalPure: React.FC<IPublishDatasetConfirmationModalProps & WithNamespaces> = props => {
  const onStrategyChange = (data: ProjectPublishStrategy) => () => {
    props.onStrategyChange(data);
  };

  const loaderKey = 'publish-button-loader';

  return (
    <Modal isOpen={props.showModal} toggle={props.onCancel}>
      <ModalHeader toggle={props.onCancel}>{props.t('confirm_project_publish')}</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <p className="mb-2 mt-3">{props.t('publishing_the_project_will_limit_editing')}</p>
          </Col>
        </Row>
        {props.draftsCount > 0 && (
          <Row>
            <Col>
              <p className="mt-3">
                { 
                  <Trans
                    i18nKey={props.draftsCount > 1 ? 'project_publish_drafts_warning_plural' : 'project_publish_drafts_warning'}
                    count={props.draftsCount}
                    values={{ draftsCount: props.draftsCount.toString(), count: props.draftsCount }}
                    components={[Bold]}
                  />
                }
                {props.hasImportedAnnotations && <span> {props.t('imported_annotations_remain_unchanged')}</span>}
              </p>
              <FormGroup check>
                <CustomInput
                  type="radio"
                  id="option-review"
                  onChange={onStrategyChange(ProjectPublishStrategy.DiscardDrafts)}
                  checked={props.strategy === ProjectPublishStrategy.DiscardDrafts}
                  label={props.t('publish_project_strategy_discard')}
                  className="mt-2"
                />
              </FormGroup>

              <FormGroup check>
                <CustomInput
                  type="radio"
                  id="option-leave"
                  onChange={onStrategyChange(ProjectPublishStrategy.KeepDrafts)}
                  checked={props.strategy === ProjectPublishStrategy.KeepDrafts}
                  label={props.t('publish_project_strategy_keep')}
                  className="mt-2"
                />
              </FormGroup>
            </Col>
          </Row>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonWithLoader
          className="mr-2"
          color="success"
          onClick={props.onConfirm}
          loaderKey={props.projectId ? `${loaderKey}-${props.projectId}` : loaderKey}
          disabled={props.disablePublishButton}
        >
          {props.t('publish')}
        </ButtonWithLoader>
        <ButtonWithLoader outline id="project-cancel-button" color="primary" onClick={props.onCancel} disabled={props.disablePublishButton}>
          {props.t('cancel')}
        </ButtonWithLoader>
      </ModalFooter>
    </Modal>
  );
};

export const ProjectPublishConfirmationModal = as<React.ComponentClass<IPublishDatasetConfirmationModalProps>>(
  withNamespaces('common', { wait: true })(ProjectPublishConfirmationModalPure),
);
