import * as React from 'react';

import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';

import { ITranslatable } from '../helpers/translations.helpers';
import { InputType } from 'reactstrap/lib/Input';
import { ShowHidePassword } from './ShowHidePasswordIcon';
import { as } from '../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { handleChange } from '../helpers/formHelpers';
import { withNamespaces } from 'react-i18next';

interface IValidatedTextInputProps {
  id?: string;
  labelText?: string;
  value?: string | number;
  type?: InputType;
  maxLength?: number;
  min?: number;
  max?: number;
  step?: number;
  placeholder?: string;
  onChange?(value: string): void;
  onBlur?(value: string): void;
  onPressEnter?(): void;
  isValid?: boolean;
  feedbacks?: string[];
  feedbacksParams?: any;
  isRequired?: boolean;
  styleClassName?: string;
  readOnly?: boolean;
  enablePreview?: boolean;
  disabled?: boolean;
  autofocus?: boolean;
  counter?: boolean;
  onPressEscape?(): void;
}

interface IState {
  isPasswordPreviewVisible: boolean;
  type?: InputType;
}

type IProps = IValidatedTextInputProps & ITranslatable;

class ValidatedTextInputPure extends React.Component<IProps, IState> {
  inputRef: any;

  constructor(props: IProps) {
    super(props);

    this.state = {
      isPasswordPreviewVisible: false,
      type: props.type,
    };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.autofocus) {
      setTimeout(() => this.inputRef.current?.focus(), 0);
    }
  }

  renderFeedbacks(): JSX.Element[] {
    const uniqueFeedbacks = this.props.feedbacks ? Array.from(new Set(this.props.feedbacks)) : [];
    return uniqueFeedbacks.map((feedback, i) => {
      return (
        <li className="input-feedback" key={i}>
          {this.props.t(feedback, this.props.feedbacksParams || {})}
        </li>
      );
    });
  }

  @autobind
  handleKeyPress(target: any) {
    if (target.charCode === 13 && this.props.onPressEnter) {
      target.preventDefault();
      this.props.onPressEnter();
    }
  }

  @autobind
  handleKeyDown(target: any) {
    if (target.key === 'Escape' && this.props.onPressEscape) {
      target.preventDefault();
      this.props.onPressEscape();
    }
  }

  @autobind
  togglePasswordVisibility() {
    this.setState(prevState => ({
      type: prevState.type === 'password' ? 'text' : 'password',
    }));
  }

  render() {
    const maxLength = this.props.maxLength === undefined ? 100 : this.props.maxLength;

    return (
      <FormGroup className="form-group-layout relative-form-group">
        {this.props.labelText && <Label>{this.props.labelText}</Label>}
        <Input
          id={this.props.id}
          value={this.props.value !== null ? this.props.value : ''}
          valid={this.props.isValid === true}
          invalid={this.props.isValid === false}
          type={this.state.type}
          placeholder={this.props.placeholder}
          onChange={handleChange(this.props.onChange)}
          onBlur={handleChange(this.props.onBlur)}
          maxLength={maxLength}
          min={this.props.min}
          max={this.props.max}
          step={this.props.step}
          required={this.props.isRequired}
          className={this.props.styleClassName}
          readOnly={this.props.readOnly}
          onKeyPress={this.handleKeyPress}
          onKeyDown={this.handleKeyDown}
          disabled={this.props.disabled}
          innerRef={this.inputRef}
        />
        {this.props.enablePreview && <ShowHidePassword isPasswordVisible={this.state.type === 'password'} onClick={this.togglePasswordVisibility} className="password-show-hide" />}
        {this.props.counter && (
          <p className="text-right">
            <small>
              {(this.props.value || '').toString().length}/{maxLength}
            </small>
          </p>
        )}
        <FormFeedback>
          <ul>{this.renderFeedbacks()}</ul>
        </FormFeedback>
      </FormGroup>
    );
  }
}

export const ValidatedTextInput = as<React.StatelessComponent<IValidatedTextInputProps>>(withNamespaces('common', { wait: true })(ValidatedTextInputPure));
