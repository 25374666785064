import { injectable, inject } from 'inversify';
import { ConfigurationType, IConfiguration } from '../../configuration';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { IPagingInfo } from '../models/paginationInfo.model';
import { AuthStoreSetterType, IAuthStoreSetter } from '../modules/auth/auth.store';
import { ApiPolicyProviderType, IApiPolicyProvider } from './api.policy';

import { ApiServiceBase } from './api.service.base';
import { ICacheManager, CacheManagerType } from './cacheManager';
import { CryptoServiceType, ICryptoService } from './crypto.service';

export const ApiServiceType = Symbol('API_SERVICE');

export interface IPagedResult<T> extends IPagingInfo {
  data: T[];
}

@injectable()
export class ApiService extends ApiServiceBase {
  constructor(
    @inject(ConfigurationType) public readonly config: IConfiguration,
    @inject(AuthStoreSetterType) protected readonly authStore: IAuthStoreSetter,
    @inject(CurrentWorkspaceStoreType) protected readonly currentWorkspaceStore: ICurrentWorkspaceStore,
    @inject(ApiPolicyProviderType) protected readonly apiPolicy: IApiPolicyProvider,
    @inject(CacheManagerType) protected readonly cacheManager: ICacheManager,
    @inject(CryptoServiceType) protected readonly cryptoService: ICryptoService,
  ) {
    super(config, authStore, currentWorkspaceStore, apiPolicy, cacheManager, cryptoService);
  }

  public getUrl = (urlSuffix: string): string => this.config.baseUrl + urlSuffix;
}
