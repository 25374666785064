import { FormGroup, Label } from 'reactstrap';

import { ITranslatable } from '../../../helpers/translations.helpers';
import { ImageAssignmentPolicy } from '../projectDetails.models';
import React from 'react';
import Select from 'react-select';
import { as } from '../../../helpers/react.helpers';
import { handleSelectChange } from '../../../helpers/formHelpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  onPolicyChange(policy: ImageAssignmentPolicy): void;
  selectedPolicy: ImageAssignmentPolicy;
  canUpdateImageAssignmentPolicy: boolean;
}

class ProjectImageAssignmentPolicyFormPure extends React.Component<IProps & ITranslatable> {
  getOptions = () => {
    return [
      { label: this.props.t('image_assignment_policy_sequential'), value: ImageAssignmentPolicy.SEQUENTIAL },
      { label: this.props.t('image_assignment_policy_random'), value: ImageAssignmentPolicy.RANDOM },
      { label: this.props.t('image_assignment_policy_parallel'), value: ImageAssignmentPolicy.PARALLEL },
    ];
  };

  render() {
    return (
      <FormGroup>
        <Label>{this.props.t('image_assignment_policy')}</Label>
        <Select
          id="policy-dropdown"
          name="select"
          value={this.getOptions().find(o => o.value === this.props.selectedPolicy)}
          onChange={handleSelectChange(this.props.onPolicyChange)}
          options={this.getOptions()}
          classNamePrefix="custom-select"
          isDisabled={!this.props.canUpdateImageAssignmentPolicy}
        />
      </FormGroup>
    );
  }
}

export const ProjectImageAssignmentPolicyForm = as<React.ComponentClass<IProps>>(withNamespaces('common')(ProjectImageAssignmentPolicyFormPure));
