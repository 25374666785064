import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../../modules/common/OverlayLoader.store';
import { as, injectProps } from '../../../helpers/react.helpers';

import { Button } from 'reactstrap';
import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

export interface INavigationButtonPureProps {
  id?: string;
  loaderKey: string;
  disabled: boolean;
  caption: string;
  title: string;
  onClick?(): void;
}

interface IInjectedProps extends ITranslatable {
  overlayLoaderStore: IOverlayLoaderStore;
}

@injectProps({
  overlayLoaderStore: OverlayLoaderStoreType,
})
@observer
class NavigationButtonPure extends React.Component<INavigationButtonPureProps & IInjectedProps> {
  render() {
    const isLoading = !!this.props.loaderKey && this.props.overlayLoaderStore.isSpinnerVisible(this.props.loaderKey);

    return (
      <DisabledWhenWorkspaceOwnerPolicyExceeded>
        <Button
          id={this.props.id}
          className="button-with-icon"
          color="link"
          disabled={this.props.disabled || isLoading}
          title={this.props.t(this.props.title)}
          onClick={this.props.onClick}
          tabIndex={-1}
        >
          {isLoading ? (
            <span>
              <FontAwesomeIcon icon={faCircleNotch} spin={true} />
            </span>
          ) : (
            this.props.children
          )}
          <span className="caption">{this.props.t(this.props.caption)}</span>
        </Button>
      </DisabledWhenWorkspaceOwnerPolicyExceeded>
    );
  }
}

export const NavigationButton = as<React.ComponentClass<INavigationButtonPureProps>>(withNamespaces('annotation', { wait: true })(NavigationButtonPure));
