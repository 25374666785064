import * as React from 'react';

import { DatasetDetailsServiceType, IDatasetDetailsService } from './datasetDetails.service';
import { IRouterStore, RouterStoreType } from '../../stores/router.store';
import { IUpdateDatasetService, UpdateDatasetServiceType } from './updateDataset.service';

import { IDatasetsDetailsStore } from './datasetsDetails.store';
import { RouteComponentProps } from 'react-router';
import { container } from '../../diContainer';

export interface IDatasetDetailsRouteParams {
  datasetId: string;
}

export interface IDatasetDetailsContext {
  service: IDatasetDetailsService;
  store: IDatasetsDetailsStore;
  route: RouteComponentProps<IDatasetDetailsRouteParams>;
}

const service: IDatasetDetailsService = container.get(DatasetDetailsServiceType);
export const DatasetDetails = React.createContext<IDatasetDetailsContext>({
  service,
  store: service.store,
  route: {
    match: {
      params: { datasetId: '' },
      isExact: false,
      path: '',
      url: '',
    },
    ...container.get<IRouterStore>(RouterStoreType),
  },
});

export function makeDatasetDetailsContext(route: RouteComponentProps<IDatasetDetailsRouteParams>): IDatasetDetailsContext {
  const service: IDatasetDetailsService = container.get(DatasetDetailsServiceType);
  const ctx: IDatasetDetailsContext = {
    route,
    service,
    store: service.store,
  };

  // TODO: This service might be replaced with a generic event bus
  const updater: IUpdateDatasetService = container.get(UpdateDatasetServiceType);
  updater.datasetsDetailsStore = ctx.store;

  return ctx;
}
