import * as React from 'react';

import { DashboardContainer } from '../../../containers/DashboardContainer';
import { ModelDetailsCreateRoute, ModelDetailsRoute } from '../../../routes/models/modelDetails.route';
import { ModelDetailsHeaderContainer } from '../../../../modules/modelDetails/containers/ModelDetailsHeader.container';

export const ModelDetailsView = () => {
  return (
    <DashboardContainer header={ModelDetailsHeaderContainer}>
      <ModelDetailsRoute />
    </DashboardContainer>
  );
};

export const ModelDetailsCreateView = () => {
  return (
    <DashboardContainer header={ModelDetailsHeaderContainer}>
      <ModelDetailsCreateRoute />
    </DashboardContainer>
  );
};
