import * as React from 'react';

import { Col, Container, Row } from 'reactstrap';

import { Auth } from '../routes/config/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../helpers/translations.helpers';
import { withNamespaces } from 'react-i18next';

export const NotFoundView = withNamespaces('common')((props: ITranslatable) => (
  <Container className="not-found-page">
    <Row className="h-100 w-100">
      <Col md="6" className="align-self-center">
        {/* here lies Major Tom */}
      </Col>
      <Col md="6" className="align-self-center">
        <h1>404</h1>
        <p>{props.t('404_body')} <br /> {props.t('404_body2')}</p>
        <a href={Auth.Login.Path} className={'btn btn-primary'} rel="noreferrer">
          <FontAwesomeIcon icon="home" /> <span className="description">{props.t('projects')}</span>
        </a>
      </Col>
    </Row >
  </Container>
));
