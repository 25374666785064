import { injectable, inject } from 'inversify';
import { IDatasetDraftsStore, DatasetDraftStoreType, IDatasetDraft } from './datasetDrafts.store';
import { observable, action } from 'mobx';
import { v4 } from 'uuid';
import { IImageFile, ImagesUploaderServiceType, IImagesUploaderService } from './imagesUpload.service';
import Papa, { NODE_STREAM_INPUT } from 'papaparse';
import { isAllowedSize, isAllowedType, toMBytes } from './helpers/image.helpers';
import validator from 'validator';
import { NotificationsServiceType, INotificationsService } from '../notifications/services/notifications.service';
import { NotificationLevel, ToastNotification } from '../notifications/models/notification.model';
import { IRejectedFile } from './models/IRejectedFile';
import { IImageUrl, ImagesFromUrlsServiceType, IImagesFromUrlsService } from './imagesFromUrlsUpload.service';
import { StickerError } from '../../models/error.model';
import { RejectStatus } from './models/RejectStatus';
import { MAX_FILE_NAME_LENGTH } from '../../helpers/global.constants';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';

export const CreateDatasetFilesServiceType = Symbol('CREATE_DATASET_FILES_SERVICE');

export interface ICreateDatasetFilesService {
  addImagesToDraft(images: File[]): Promise<void>;
  getUrlsFromFileAsync(file: typeof NODE_STREAM_INPUT): void;
  rejectUrlsFile(): void;
  cleanImagesFromFile(): void;
  cleanImagesFromUrl(): void;
  isProcessingImages: boolean;
}

@injectable()
export class CreateDatasetFilesService implements ICreateDatasetFilesService {
  private imageProcessingCount: number = 0;
  private acceptedImagesPlaceholder: IImageFile[] = [];
  private rejectedImagesPlaceholder: IRejectedFile[] = [];

  constructor(
    @inject(DatasetDraftStoreType) private readonly datasetsDraftStore: IDatasetDraftsStore,
    @inject(NotificationsServiceType) private readonly notificationsService: INotificationsService,
    @inject(ImagesUploaderServiceType) private readonly imagesUploaderService: IImagesUploaderService,
    @inject(ImagesFromUrlsServiceType) private readonly imagesFromUrlsService: IImagesFromUrlsService,
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore,
  ) {
  }

  @observable
  isProcessingImages: boolean = false;

  @action.bound
  async addImagesToDraft(images: File[]): Promise<void> {
    const draft = this.datasetsDraftStore.draft;
    this.startProcessingImagesFiles(draft);
    const acceptedImages: IImageFile[] = [];
    const rejectedImages: IRejectedFile[] = [];

    const duplicateNames = await this.imagesUploaderService.getDuplicatedImageNamesAsync(
      draft.id,
      images.map(x => x.name).concat(this.acceptedImagesPlaceholder.map(x => x.file.name)),
    );

    for (const file of images) {
      if (duplicateNames.includes(file.name) || this.acceptedImagesPlaceholder.concat(acceptedImages).some(x => x.file.name === file.name)) {
        rejectedImages.push({ file, status: RejectStatus.DUPLICATED_NAME });
        continue;
      }
      if (!isAllowedType(file)) {
        rejectedImages.push({ file, status: RejectStatus.TYPE });
        continue;
      }
      if (!isAllowedSize(file)) {
        rejectedImages.push({ file, status: RejectStatus.SIZE });
        continue;
      }
      acceptedImages.push({ file });
    }

    await this.endProcessingImagesFilesAsync(acceptedImages, rejectedImages, draft);
  }

  @action.bound
  async getUrlsFromFileAsync(stream: typeof NODE_STREAM_INPUT) {
    const draft = this.datasetsDraftStore.draft;
    this.isProcessingImages = true;
    Papa.parse(stream, {
      complete: async (res: any) => await this.handleFileParseFinish(draft.id, res),
      error: this.handleFileParseError,
      skipEmptyLines: true,
    } as any);
  }

  @action.bound
  rejectUrlsFile() {
    const draft = this.datasetsDraftStore.draft;
    draft.imagesToUploadFromUrl = [];
    this.notificationsService.push(new ToastNotification(NotificationLevel.INFO, 'Wrong file type'));
  }

  @action.bound
  async handleFileParseFinish(draftId: string, result: any) {
    const draft = this.datasetsDraftStore.draft;
    draft.imagesToUploadFromUrl = [];

    const urls = result.data.map((x: any) => x[0] as string) as string[];
    const duplicateUrls = await this.imagesUploaderService.getDuplicatedUrlsAsync(
      draftId,
      urls,
    );

    const data: IImageUrl[] = [];

    for (const x of result.data) {
      const url = x[0];
      const isUrlValid = validator.isURL(url);
      const isDuplicated = duplicateUrls.includes(url) || data.some(x => x.url === url);
      const isTooLong = url.length > MAX_FILE_NAME_LENGTH;

      let status: RejectStatus | undefined;

      if (!isUrlValid) {
        status = RejectStatus.INVALID_URL;
      } else if (isDuplicated) {
        status = RejectStatus.DUPLICATED_URL;
      } else if (isTooLong) {
        status = RejectStatus.URL_TOO_LONG;
      }

      data.push({
        url,
        isValid: status === undefined,
        id: v4(),
        rejectStatus: status,
      });
    }

    draft.imagesToUploadFromUrl = data;

    const willExceeds = await this.imagesFromUrlsService.willExceedsImageLimit(
      this.currentWorkspaceStore.currentWorkspace!.id,
      data.filter(x => x.isValid).map(x => x.url),
    );

    if ((willExceeds instanceof StickerError)) {
      draft.imagePreValidationWasSuccessful = false;
    } else {
      draft.willExceedsUploadImageSizeLimit = willExceeds.willExceedsAvailableSpace;
      draft.imagePreValidationWasSuccessful = willExceeds.areAllSuccessful;
      draft.ownerPlan = willExceeds.ownerPlan;
    }
    this.isProcessingImages = false;
  }

  @action.bound
  handleFileParseError(error: any) {
    this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, error, 'File parse error'));
  }

  @action.bound
  cleanImagesFromFile() {
    const draft = this.datasetsDraftStore.draft;
    draft.imagesToUploadFromFile = [];
    draft.imagesRejectedFromFile = [];
    draft.imagePreValidationWasSuccessful = true;
    this.imageProcessingCount = 0;
    this.acceptedImagesPlaceholder = [];
    this.rejectedImagesPlaceholder = [];
    this.isProcessingImages = false;
  }

  @action.bound
  cleanImagesFromUrl() {
    const draft = this.datasetsDraftStore.draft;
    draft.imagesToUploadFromUrl = [];
    draft.imagePreValidationWasSuccessful = true;
  }

  @action.bound
  startProcessingImagesFiles(draft: IDatasetDraft) {
    this.imageProcessingCount += 1;
    this.isProcessingImages = this.imageProcessingCount > 0;
    if (this.imageProcessingCount <= 1) {
      this.acceptedImagesPlaceholder = [...draft.imagesToUploadFromFile];
      this.rejectedImagesPlaceholder = [...draft.imagesRejectedFromFile];
    }
  }

  @action.bound
  async endProcessingImagesFilesAsync(acceptedImages: IImageFile[], rejectedImages: IRejectedFile[], draft: IDatasetDraft) {
    this.imageProcessingCount = this.imageProcessingCount > 0 ? this.imageProcessingCount - 1 : 0;
    this.acceptedImagesPlaceholder.push(...acceptedImages);
    this.rejectedImagesPlaceholder.push(...rejectedImages);

    const willExceeds = await this.imagesUploaderService.willExceedsImageLimit(
      this.currentWorkspaceStore.currentWorkspace!.id,
      this.acceptedImagesPlaceholder.length,
      toMBytes(this.acceptedImagesPlaceholder.reduce((sum, current) => sum + current.file.size, 0)),
    );

    if (this.imageProcessingCount <= 0) {
      draft.imagesToUploadFromFile = this.acceptedImagesPlaceholder;
      draft.imagesRejectedFromFile = this.rejectedImagesPlaceholder;
    }

    draft.willExceedsUploadImageSizeLimit = willExceeds.willExceedsAvailableSpace;
    draft.imagePreValidationWasSuccessful = willExceeds.areAllSuccessful;
    draft.ownerPlan = willExceeds.ownerPlan;
    this.isProcessingImages = false;
  }
}
