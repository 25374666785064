import * as React from 'react';

import { GTable, IGColumnProps } from '../../../components/table/GTable';
import { faEdit, faUnlink } from '@fortawesome/free-solid-svg-icons';

import { Home } from '../../../routes/config/Home';
import { IDatasetDetailsProjects } from '../datasetsDetails.store';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { Link } from 'react-router-dom';
import { Loader } from '../../../components/Loader';
import { ProjectStatus } from '../../projects/projects.model';
import { S_PaginationProps } from '../../../../design/pagination/S_Pagination';
import { as } from '../../../helpers/react.helpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { nameOf } from '../../../helpers/object.helpers';
import { toLocaleDateTimeString } from '../../../helpers/date.helpers';
import { withNamespaces } from 'react-i18next';

library.add(faUnlink, faEdit);

export interface IDatasetDetailsProjectsProps {
  datasetProjects: IDatasetDetailsProjects[];
  workspaceId: string;
  isLoading: boolean;
  sorting: {
    orderBy: string;
    orderType: string;
  };
  pagination: S_PaginationProps;
  onSortingChange(orderBy: string, orderType: string): Promise<void>;
}

class DatasetDetailsProjectsListPure extends React.Component<IDatasetDetailsProjectsProps & ITranslatable> {
  get gColumns(): IGColumnProps<IDatasetDetailsProjects>[] {
    return [
      {
        field: nameOf<IDatasetDetailsProjects>('name'),
        headerName: this.props.t('name'),
        sortable: true,
        minWidth: 400,
        renderer: this.renderProjectName,
      },
      {
        field: nameOf<IDatasetDetailsProjects>('description'),
        headerName: this.props.t('description'),
      },
      {
        field: nameOf<IDatasetDetailsProjects>('assignedToProjectDate'),
        headerName: this.props.t('assigned_to_project'),
        renderer: (project: IDatasetDetailsProjects) => <>{toLocaleDateTimeString(project.assignedToProjectDate.toString())}</>,
        width: 200,
      },
      {
        field: nameOf<IDatasetDetailsProjects>('reviewsDonePercentage'),
        headerName: this.props.t('percent_done'),
        renderer: (project: IDatasetDetailsProjects) => <>{project.reviewsDonePercentage}%</>,
        width: 200,
      },
    ];
  }

  renderProjectName = (projectItem: IDatasetDetailsProjects) => {
    const name = !!projectItem.name ? projectItem.name : `(${this.props.t('untitled')})`;

    return (
      <Link
        to={
          projectItem.status === ProjectStatus.Published
            ? Home.Projects.Details.Overview.withParams({ projectId: projectItem.id, workspaceId: this.props.workspaceId })
            : Home.Projects.Details.AnnotationView.withParams({ projectId: projectItem.id, workspaceId: this.props.workspaceId })
        }
      >
        <span className={'text-link-in-table'}>{name}</span>
      </Link>
    );
  };

  render() {
    return (
      <div className="table-container">
        <Loader isLoading={this.props.isLoading}>
          <GTable<IDatasetDetailsProjects>
            columns={this.gColumns}
            items={this.props.datasetProjects}
            paginationProps={this.props.pagination}
            sortingModel={[this.props.sorting]}
            onSortChanged={this.props.onSortingChange}
          />
        </Loader>
      </div>
    );
  }
}

export const DatasetDetailsProjectsList = as<React.ComponentClass<IDatasetDetailsProjectsProps>>(withNamespaces('datasets', { wait: true })(DatasetDetailsProjectsListPure));
