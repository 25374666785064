import { IObservableArray, action, observable, set } from 'mobx';
import { IPagingInfoWithOrder, PaginationInfoDefault } from '../../models/paginationInfo.model';

import { DatasetDownloadProhibitionReason } from './models/DatasetDownloadProhibitionReason';
import { DatasetStatus } from './datasetStatus.model';
import { IImageFile } from './imagesUpload.service';
import { IImageUrl } from './imagesFromUrlsUpload.service';
import { IRejectedFile } from './models/IRejectedFile';
import { ProjectStatus } from '../projects/projects.model';
import { injectable } from 'inversify';

export const DatasetsDetailsStoreType = Symbol('DATASETS_DETAILS_STORE');

export const DefaultPaging = {
  ...PaginationInfoDefault,
  orderBy: 'name',
  orderType: 'asc',
  search: '',
};

export const emptyDetails: IDatasetDetails = {
  id: '',
  name: '',
  workspaceId: '',
  description: '',
  createdDate: '',
  lastModified: '',
  imagesCount: 0,
  imagesSize: 0,
  projectsCount: 0,
  status: DatasetStatus.DRAFT,
  canDownload: false,
  downloadProhibitionReason: DatasetDownloadProhibitionReason.Unknown,
  author: '',
  termsOfUse: '',
  createdBy: '',
  createdById: '',
};

export const emptyImagePreviewInfo: IDatasetPreviewImage = {
  height: 0,
  width: 0,
  id: '',
  name: '',
  size: 0,
  createdDate: '',
  hasAnnotations: false,
  hasLock: false,
  hasUrl: false,
};

export interface ISelectedImage {
  id: string;
  hasLock: boolean;
  hasAnnotation: boolean;
}

export interface IPreviewImageAttributes {
  channels: number;
  isCached: boolean;
  downloadSpeed?: number;
}

export interface IDatasetDetails {
  id: string;
  workspaceId: string;
  name: string;
  description: string;
  createdDate: string;
  lastModified: string;
  imagesCount: number;
  imagesSize: number;
  projectsCount: number;
  canDownload: boolean;
  downloadProhibitionReason: DatasetDownloadProhibitionReason;
  status: DatasetStatus;
  author: string;
  termsOfUse: string;
  createdBy: string;
  createdById: string;
}

export interface IDatasetDetailsImage {
  id: string;
  name: string;
  width: number;
  height: number;
  size: number;
  createdDate: string;
  crs?: string;
  hasAnnotations: boolean;
  hasLocks: boolean;
  url: string;
  acceptedAnnotationsCount: number;
  annotationsCount: number;
  hasFileUrl: boolean;
}

export interface IImageUsageInfo {
  hasLock: boolean;
  hasAnnotation: boolean;
}

export interface IDatasetPreviewImage {
  id: string;
  name: string;
  width: number;
  height: number;
  size: number;
  createdDate: string;
  hasAnnotations: boolean;
  hasLock: boolean;
  hasUrl: boolean;
}

export interface IDatasetDetailsProjects {
  id: string;
  name: string;
  status: ProjectStatus;
  description: string;
  assignedToProjectDate: string;
  reviewsDonePercentage: number;
}

export interface IPreviewPaging {
  wasChanged: boolean;
  checkDate: string;
  items: IPreviewPagingItem[];
}

export interface IPreviewPagingItem {
  imageId: string;
  index: number;
  size: number;
}

export interface IDatasetsDetailsStore {
  markAsUploaded(datasetId: string): void;
  markAsUploading(datasetId: string): void;
  incrementProgress(datasetId: string, imageSize: number): void;
  setImagesPaging(imagesPaging: IPagingInfoWithOrder): void;
  setImages(images: IDatasetDetailsImage[], imagesPaging: IPagingInfoWithOrder, imagesCount: number): void;
  setDatasetDetails(details: IDatasetDetails): void;
  setDatasetDetailsProjects(detailsProjects: IDatasetDetailsProjects[], projectsPaging: IPagingInfoWithOrder): void;
  setDatasetDetailsProjectsPaging(projectsPaging: IPagingInfoWithOrder): void;

  activePreloads: string[];
  readonly details: IDatasetDetails;
  readonly detailsImages: IObservableArray<IDatasetDetailsImage>;
  readonly detailsImagesPaging: IPagingInfoWithOrder;
  readonly detailsProjects: IObservableArray<IDatasetDetailsProjects>;
  readonly detailsProjectsPaging: IPagingInfoWithOrder;
  imagesToUploadFromUrl: IObservableArray<IImageUrl>;
  imagesToUploadFromFile: IObservableArray<IImageFile>;
  imagesRejectedFromFile: IObservableArray<IRejectedFile>;
  willExceedsUploadImageSizeLimit: boolean;
  ownerPlan: string;
  imagePreValidationWasSuccessful: boolean;
  selectedImages: IObservableArray<ISelectedImage>;
  isImageCountExceeded: boolean;
  availableImageCount: number;
  imagePreviewInfo: IDatasetPreviewImage;
  imagePreviewUrl: string;
  lowQualityImagePreviewUrl: string;
  imageAttributes: IPreviewImageAttributes;
  isPreviewImageLoading: boolean;
  previewPaging: IPreviewPaging;
}

@injectable()
export class DatasetsDetailsStore implements IDatasetsDetailsStore {
  activePreloads: string[] = [];

  @observable
  details: IDatasetDetails = emptyDetails;

  detailsImages: IObservableArray<IDatasetDetailsImage> = observable.array([]);

  @observable
  detailsImagesPaging: IPagingInfoWithOrder = DefaultPaging;

  detailsProjects: IObservableArray<IDatasetDetailsProjects> = observable.array([]);

  imagesToUploadFromUrl: IObservableArray<IImageUrl> = observable.array([]);

  imagesToUploadFromFile: IObservableArray<IImageFile> = observable.array([]);

  imagesRejectedFromFile: IObservableArray<IRejectedFile> = observable.array([]);

  @observable
  willExceedsUploadImageSizeLimit: boolean = false;

  @observable
  ownerPlan: string = '';

  @observable
  imagePreValidationWasSuccessful: boolean = true;

  selectedImages: IObservableArray<ISelectedImage> = observable.array([]);

  @observable
  isImageCountExceeded: boolean = false;

  @observable
  availableImageCount: number = 0;

  @observable
  imagePreviewInfo: IDatasetPreviewImage = emptyImagePreviewInfo;

  @observable
  imagePreviewUrl: string = '';

  @observable
  lowQualityImagePreviewUrl: string = '';

  @observable
  imageAttributes: IPreviewImageAttributes = { channels: 0, downloadSpeed: 0, isCached: false };

  @observable
  isPreviewImageLoading: boolean = false;

  @observable
  previewPaging: IPreviewPaging = { checkDate: '', items: [], wasChanged: false };

  @observable
  detailsProjectsPaging: IPagingInfoWithOrder = DefaultPaging;

  @action.bound
  markAsUploaded(datasetId: string): void {
    if (datasetId === this.details.id) {
      this.details.status = this.details.status === DatasetStatus.PUBLISHEDUPLOADING ? DatasetStatus.PUBLISHED : DatasetStatus.DRAFT;
    }
  }

  @action.bound
  markAsUploading(datasetId: string): void {
    if (datasetId === this.details.id) {
      this.details.status = this.details.status === DatasetStatus.DRAFT ? DatasetStatus.UPLOADING : DatasetStatus.PUBLISHEDUPLOADING;
    }
  }

  @action.bound
  incrementProgress(datasetId: string, imageSize: number): void {
    if (datasetId === this.details.id) {
      this.details.imagesCount += 1;
      this.details.imagesSize += imageSize;
    }
  }

  @action
  setImagesPaging(imagesPaging: IPagingInfoWithOrder): void {
    set(this.detailsImagesPaging, imagesPaging);
  }

  @action
  setImages(images: IDatasetDetailsImage[], imagesPaging: IPagingInfoWithOrder, imagesCount: number): void {
    this.detailsImages.replace(images);
    set(this.detailsImagesPaging, imagesPaging);
    this.details.imagesCount = imagesCount;
  }

  @action
  setDatasetDetails(details: IDatasetDetails): void {
    set(this.details, details);
    this.imagesRejectedFromFile.clear();
    this.imagesToUploadFromFile.clear();
    this.imagesToUploadFromUrl.clear();
    this.willExceedsUploadImageSizeLimit = false;
    this.ownerPlan = '';
    this.imagePreValidationWasSuccessful = true;
    this.isImageCountExceeded = false;
    this.availableImageCount = 0;
    set(this.previewPaging, { checkDate: '', items: [], wasChanged: false });
    set(this.imagePreviewInfo, emptyImagePreviewInfo);
    set(this.imageAttributes, { channels: 0, isCached: false, downloadSpeed: 0 });
  }

  @action
  setDatasetDetailsProjects(detailsProjects: IDatasetDetailsProjects[], projectsPaging: IPagingInfoWithOrder): void {
    this.detailsProjects.replace(detailsProjects);
    set(this.detailsProjectsPaging, projectsPaging);
  }

  @action
  setDatasetDetailsProjectsPaging(projectsPaging: IPagingInfoWithOrder): void {
    set(this.detailsProjectsPaging, projectsPaging);
  }
}
