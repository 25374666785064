import {
  IEvaluationDetailsDto,
  IEvaluationImagePredictionDto,
} from './../../../modules/evaluationDetails/evaluationDetails.model';
import { injectable } from 'inversify';
import { observable } from 'mobx';
import { IGetAllImages } from './predictionImagesPreviewApi.service';
import { IDatasetDetails } from '../../modules/datesets/datasetsDetails.store';
import { IModelDetailsDto, IModelImagePredictionDto } from '../../../modules/modelDetails/modelDetails.model';

export const PredictionImagesPreviewStoreType = Symbol('PREDICTION_IMAGES_PREVIEW_STORE');
export type IImageWithDataset = IGetAllImages[number] & {
  datasetId: string;
};

const DEFAULT_VALUES = {
  imageId: null,
  jobType: null,
  jobInfo: null,
  images: null,
  evaluationImageDetails: null,
  datasets: new Map(),
};

@injectable()
export class PredictionImagesPreviewStore {
  @observable imageId: string | null = DEFAULT_VALUES.imageId;
  @observable jobType: 'TRAINING' | 'EVALUATION' | null = DEFAULT_VALUES.jobType;
  @observable jobInfo: IModelDetailsDto | IEvaluationDetailsDto | null = DEFAULT_VALUES.jobInfo;
  @observable images: IImageWithDataset[] | null = DEFAULT_VALUES.images;
  @observable datasets: Map<string, IDatasetDetails> = DEFAULT_VALUES.datasets;
  @observable evaluationImageDetails: IEvaluationImagePredictionDto | IModelImagePredictionDto | null = DEFAULT_VALUES.evaluationImageDetails;

  clearStore() {
    this.imageId = DEFAULT_VALUES.imageId;
    this.jobType = DEFAULT_VALUES.jobType;
    this.jobInfo = DEFAULT_VALUES.jobInfo;
    this.images = DEFAULT_VALUES.images;
    this.datasets = DEFAULT_VALUES.datasets;
    this.evaluationImageDetails = DEFAULT_VALUES.evaluationImageDetails;
  }

  get image() {
    if (this.imageId === null || this.images === null) return null;
    return this.images[this.indexOf];
  }

  get indexOf() {
    if (this.imageId === null || this.images === null) return -1;

    return this.images.findIndex(image => image.id === this.imageId);
  }

  get total() {
    if (this.imageId === null || this.images === null) return -1;

    return this.images.length;
  }

  get nextImage() {
    if (this.imageId === null || this.images === null) return '';

    return this.images[this.indexOf + 1].id;
  }

  get previousImage() {
    if (this.imageId === null || this.images === null) return '';

    return this.images[this.indexOf - 1].id;
  }
}
