import { inject, injectable } from 'inversify';
import { computed } from 'mobx';
import { EventBusType, IEventBus } from '../../services/eventBus.service';
import { AnnotationServiceType, IAnnotationService } from './annotation.service';
import { ISegmentation } from './annotations.interface';
import { AnnotationsStoreType, IAnnotationsStore } from './annotations.store';
import { DrawBrush } from './components/DrawControl.models/Brush/Draw.Brush';
import { FreeDrawSegmentationServiceType, IFreeDrawSegmentationService } from './freeDrawSegmentation.service';
import { FreeDrawCanceledEventType } from './submodules/freeDraw/events/freeDrawCanceledEvent';
import { AddBrushBlobCommand } from './undoRedoCommands/addBrushBlobCommand';
import { AnnotationCommand } from './undoRedoCommands/annotationCommand';
import { ChangeSegmentationWithAnswersCommand } from './undoRedoCommands/changeSegmentationWithAnswersCommand';
import { AddVerticesCommandBase, ChangeStateCommand, IUndoRedoCommand } from './undoRedoCommands/undoRedoCommands';
import { IUndoRedoHistoryQueue, UndoRedoHistoryQueueType } from './undoRedoHistoryQueue.service';

export const UndoRedoHistoryType = Symbol('UNDO_REDO_HISTORY_TYPE');

export interface IUndoRedoHistory {
  lastUndoCommand: IUndoRedoCommand | undefined;
  canUndo: boolean;
  canRedo: boolean;
  addCommand(command: IUndoRedoCommand): void;
  addNewChangeStateCommand(segmentations: ISegmentation[]): void;
  getNewChangeStateCommand(): ChangeStateCommand;
  clearDrawingHistory(): void;
  clearHistory(): void;
  refreshCommandsBindings(activeAnnotationTypeId: string | undefined, drawer: IDrawer): void;
  undo(): void;
  redo(): void;
}

@injectable()
export class UndoRedoHistory implements IUndoRedoHistory {
  private lastSegmentations?: ISegmentation[];

  constructor(
    @inject(AnnotationServiceType) private readonly annotationService: IAnnotationService,
    @inject(AnnotationsStoreType) private readonly annotationsStore: IAnnotationsStore,
    @inject(FreeDrawSegmentationServiceType) private readonly freeDrawSegmentationService: IFreeDrawSegmentationService,
    @inject(UndoRedoHistoryQueueType) private readonly undoRedoHistoryQueue: IUndoRedoHistoryQueue,
    @inject(EventBusType) eventBus: IEventBus,
  ) {
    eventBus.addListener(this.freeDrawCanceledListener, FreeDrawCanceledEventType);
    this.saveState();
  }

  freeDrawCanceledListener = () => this.clearDrawingHistory();

  @computed
  get canRedo(): boolean {
    return this.undoRedoHistoryQueue.canRedo;
  }

  @computed
  get canUndo(): boolean {
    return this.undoRedoHistoryQueue.canUndo;
  }

  get lastUndoCommand() {
    return this.undoRedoHistoryQueue.getLastUndoCommand();
  }

  get nextRedoCommand() {
    return this.undoRedoHistoryQueue.getNextRedoCommand();
  }

  addCommand(command: IUndoRedoCommand): void {
    const lastUndoCommand = this.undoRedoHistoryQueue.getLastUndoCommand();
    if (command instanceof AddVerticesCommandBase && lastUndoCommand instanceof AddVerticesCommandBase) {
      lastUndoCommand.addVertex(command.latlng[0]);
      return;
    }

    if (command instanceof ChangeSegmentationWithAnswersCommand) {
      this.clearDrawingHistory();
    }

    this.undoRedoHistoryQueue.addCommand(command);
    this.saveState();
  }

  addNewChangeStateCommand(segmentations: ISegmentation[]) {
    const command = new ChangeStateCommand(
      this.annotationService,
      this.annotationsStore,
      this.freeDrawSegmentationService,
      this.annotationService.getSegmentations(),
      segmentations,
      (segmentations: ISegmentation[]) => {
        this.annotationService.setSegmentations(segmentations);
      },
    );
    this.undoRedoHistoryQueue.addCommand(command);
    this.saveState();
  }

  getNewChangeStateCommand(): ChangeStateCommand {
    return new ChangeStateCommand(
      this.annotationService,
      this.annotationsStore,
      this.freeDrawSegmentationService,
      this.lastSegmentations!,
      this.annotationService.getSegmentations(),
      (segmentations: ISegmentation[]) => {
        this.annotationService.setSegmentations(segmentations);
      }
    );
  }

  clearDrawingHistory() {
    this.undoRedoHistoryQueue.removeCommands(this.isDrawingCommand);
  }

  clearHistory(): void {
    this.lastSegmentations = undefined;
    this.undoRedoHistoryQueue.removeCommands(() => true);
    this.saveState();
  }

  refreshCommandsBindings(activeAnnotationTypeId: string | undefined, drawer: IDrawer) {
    this.undoRedoHistoryQueue.changeCommands((command: IUndoRedoCommand) => {
      if (command instanceof AddBrushBlobCommand && command.brush.activeAnnotationTypeId === activeAnnotationTypeId) {
        command.brush = drawer as DrawBrush;
      }
    });
  }

  undo(): void {
    if (!this.undoRedoHistoryQueue.canUndo) return;
    this.undoRedoHistoryQueue.undo();
    this.saveState();
  }

  redo(): void {
    if (!this.undoRedoHistoryQueue.canRedo) return;
    this.undoRedoHistoryQueue.redo();
    this.saveState();
  }

  private saveState(): void {
    this.lastSegmentations = this.annotationService.getSegmentations();
  }

  private isDrawingCommand(command: IUndoRedoCommand | undefined) {
    return command !== undefined && !(command instanceof AnnotationCommand);
  }
}
