import * as React from 'react';

import { AuthServiceType, IAuthService } from '../../../auth/auth.service';
import { ConfigurationType, IConfiguration } from '../../../../../configuration';
import { ILoaderState, WithLoaderComponentBase } from '../../../../helpers/loader.helpers';
import { INotificationsService, NotificationsServiceType } from '../../../notifications/services/notifications.service';
import { IUserService, UserServiceType } from '../../user.service';
import { IWorkspaceService, WorkspaceServiceType } from '../../../workspaces/workspaces.service';
import { InputStatus, StickerError } from '../../../../models/error.model';
import { NotificationLevel, ToastNotification } from '../../../notifications/models/notification.model';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../../helpers/react.helpers';

import { ConsentType } from '../../user.store';
import { IPersonalInformationWidgetProps } from '../../components/personalInformation/PersonalInformationWidget';
import { ITranslatable } from '../../../../helpers/translations.helpers';
import { PasswordWithScoreInputsContainer } from '../../../auth/containers/PasswordWithScoreInputsContainer';
import autobind from 'autobind-decorator';
import { getEmailStatus } from '../../../../helpers/email.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IProps extends RouteComponentProps {
  userService: IUserService;
  notificationService: INotificationsService;
  authService: IAuthService;
  workspaceService: IWorkspaceService;
  config: IConfiguration;
}

interface IState extends ILoaderState {
  fullName: string;
  company: string;
  showPasswordModal: boolean;
  showDeleteAccountModal: boolean;
  isDeleteInProgress: boolean;
  newPassword: string;
  oldPassword: string;
  oldPasswordStatus: InputStatus;
  newPasswordStatus: InputStatus;
  newPasswordControlRef: React.RefObject<PasswordWithScoreInputsContainer>;
  isNewsletterConsentChecked: boolean;
  showChangeEmailModal: boolean;
  email?: string;
  emailStatus: InputStatus;
  password?: string;
  passwordStatus: InputStatus;
}

@injectProps({
  userService: UserServiceType,
  notificationService: NotificationsServiceType,
  authService: AuthServiceType,
  workspaceService: WorkspaceServiceType,
  config: ConfigurationType,
})
@observer
class PersonalInformationWidgetContainer extends WithLoaderComponentBase<IProps & ITranslatable, IState> {
  constructor(props: IProps & ITranslatable) {
    super(props);

    this.state = {
      isLoading: false,
      fullName: props.userService.data.userInfo.fullName,
      company: props.userService.data.userInfo.company,
      showPasswordModal: false,
      showDeleteAccountModal: false,
      isDeleteInProgress: false,
      isNewsletterConsentChecked: props.userService.data.userInfo.consents.some(x => x.type === ConsentType.Newsletter && x.isGiven),
      showChangeEmailModal: false,
      ...this.resetChangeEmailState(),
      ...this.resetPasswordState(),
    };
  }
  
  @autobind
  handleFullNameChange(value: string) {
    this.setState({ fullName: value });
  }

  @autobind
  handleCompanyChange(value: string) {
    this.setState({ company: value });
  }

  @autobind
  handleFullNameBlur(value: string) {
    if (this.props.userService.data.userInfo.fullName !== value) {
      this.props.userService.updateUserDetails(this.state.fullName, this.state.company, this.props.userService.data.userInfo.country);
    }
  }

  @autobind
  handleCompanyBlur(value: string) {
    if (this.props.userService.data.userInfo.company !== value) {
      this.props.userService.updateUserDetails(this.state.fullName, this.state.company, this.props.userService.data.userInfo.country);
    }
  }

  @autobind
  handleShowChangePassword() {
    this.setState({ showPasswordModal: true, ...this.resetPasswordState() });
  }

  @autobind
  handleHideChangePassword() {
    this.setState({ showPasswordModal: false, ...this.resetPasswordState() });
  }

  resetPasswordState() {
    return {
      newPassword: '',
      oldPassword: '',
      oldPasswordStatus: InputStatus.empty(),
      newPasswordStatus: InputStatus.empty(),
      newPasswordControlRef: React.createRef<PasswordWithScoreInputsContainer>(),
    };
  }

  @autobind
  handleNewPasswordChange(newPassword: string) {
    this.setState({ newPassword });
  }

  @autobind
  handleOldPasswordChange(oldPassword: string) {
    this.setState({ oldPassword });
  }

  @autobind
  handleNewPasswordStatusChange(newPasswordState: InputStatus) {
    this.setState({ newPasswordStatus: newPasswordState });
  }

  @autobind
  validateOldPassword() {
    if (this.state.oldPassword === '') {
      this.setState({ oldPasswordStatus: new InputStatus(false, ['common:field_cant_be_empty']) });
      return false;
    }

    this.setState({ oldPasswordStatus: InputStatus.valid() });
    return true;
  }

  @autobind
  validateNewPassword() {
    return this.state.newPasswordControlRef.current !== null && this.state.newPasswordControlRef.current.validate();
  }

  @autobind
  async handleChangePassword() {
    const isNewPasswordValid = this.validateNewPassword();
    const isOldPasswordValid = this.validateOldPassword();
    if (!isNewPasswordValid || !isOldPasswordValid) return;
    const result = await this.withLoaderAsync(async () => this.props.userService.changeUserPasswordAsync(this.state.oldPassword, this.state.newPassword), 'change_password_loader');
    if (result instanceof StickerError) {
      if (result.apiErrorResponse!.errorCodes.includes('password_mismatch')) {
        this.setState({ oldPasswordStatus: new InputStatus(false, [this.props.t('password_mismatch')]) });
      } else {
        this.props.notificationService.push(new ToastNotification(NotificationLevel.ERROR, 'user:change_user_password_failed'));
      }
      return;
    }
    this.props.notificationService.push(new ToastNotification(NotificationLevel.SUCCESS, this.props.t('password_changed_successfully')));
    this.handleHideChangePassword();
  }

  @autobind
  handleShowDeleteAccountModal() {
    this.setState({ showDeleteAccountModal: true });
  }

  @autobind
  handleHideDeleteAccountModal() {
    this.setState({ showDeleteAccountModal: false });
  }

  @autobind
  async handleDeleteAccount() {
    const result = await this.withLoaderAsync(() => this.props.userService.deleteUserAsync(), 'confirm-delete');
    if (result instanceof StickerError) {
      return;
    }
    this.handleHideDeleteAccountModal();
    this.props.authService.logout();
  }

  handleAcceptNewsletterChange = () => {
    this.setState(
      (oldState: IState) => {
        return {
          isNewsletterConsentChecked: !oldState.isNewsletterConsentChecked,
        };
      },
      async () => {
        await this.props.userService.changeNewsletterConsent(this.props.userService.data.userInfo.id, this.state.isNewsletterConsentChecked);
      },
    );
  };

  @autobind
  handleShowChangeEmailModal() {
    this.setState({ showChangeEmailModal: true, ...this.resetChangeEmailState() });
  }

  @autobind
  handleHideChangeEmailModal() {
    this.setState({ showChangeEmailModal: false, ...this.resetChangeEmailState() });
  }

  resetChangeEmailState() {
    return {
      email: '',
      password: '',
      emailStatus: InputStatus.empty(),
      passwordStatus: InputStatus.empty(),
    };
  }

  @autobind
  handlePasswordChange(password: string) {
    this.setState({ password,  passwordStatus: password ? InputStatus.valid() : new InputStatus(false, ['common:field_cant_be_empty']) });
  }

  @autobind
  handlePasswordBlur() {
    this.setState({ passwordStatus: this.state.password ? InputStatus.valid() : new InputStatus(false, ['common:field_cant_be_empty']) });
  }

  @autobind
  handleEmailChange(email: string) {
    this.setState({ email, emailStatus: getEmailStatus(email || '', [], true) });
  }

  @autobind
  handleEmailBlur() {
    this.setState({ emailStatus: getEmailStatus(this.state.email || '', [], true) });
  }

  @autobind
  async handleChangeEmailConfirmation() {
    const result = await this.withLoaderAsync(() => this.props.userService.changeEmail(this.state.password!, this.state.email!), 'change_email_loader');
    if (result instanceof StickerError) {
      if (result.isBadRequestWithCode(['EMPTY_EMAIL'])) this.setState({ emailStatus: new InputStatus(false, ['common:field_cant_be_empty']) });
      else if (result.isBadRequestWithCode(['PASSWORD_MISMATCH'])) this.setState({ passwordStatus: new InputStatus(false, ['password_mismatch']) });
      else if (result.isBadRequestWithCode(['EMAIL_TAKEN'])) this.setState({ emailStatus: new InputStatus(false, ['email_taken'])});
    } else {
      this.setState({ showChangeEmailModal: false });
    }
  }

  render() {
    const userLinkExtension = !!this.props.userService.data.userInfo.queryString ? `?${this.props.userService.data.userInfo.queryString}` : '';
    return React.Children.map(this.props.children, (child: any) =>
      React.cloneElement(child, {
        userLinkExtension,
        email: this.props.userService.data.userInfo.email,
        emailChangePending: this.props.userService.data.userInfo.emailChangePending,
        fullName: this.state.fullName,
        company: this.state.company,
        onFullNameChange: this.handleFullNameChange,
        onCompanyChange: this.handleCompanyChange,
        onFullNameBlur: this.handleFullNameBlur,
        onCompanyBlur: this.handleCompanyBlur,
        onShowChangePasswordModal: this.handleShowChangePassword,
        onHideChangePasswordModal: this.handleHideChangePassword,
        onNewPasswordChange: this.handleNewPasswordChange,
        onOldPasswordChange: this.handleOldPasswordChange,
        onOldPasswordBlur: this.validateOldPassword,
        onNewPasswordStatusChange: this.handleNewPasswordStatusChange,
        showPasswordModal: this.state.showPasswordModal,
        oldPasswordStatus: this.state.oldPasswordStatus,
        newPasswordControlRef: this.state.newPasswordControlRef,
        passwordFormIsValid: this.state.oldPasswordStatus.isValid !== false && this.state.newPasswordStatus.isValid !== false,
        onResetPassword: this.handleChangePassword,
        showDeleteAccountModal: this.state.showDeleteAccountModal,
        onShowDeleteAccountModal: this.handleShowDeleteAccountModal,
        onHideDeleteAccountModal: this.handleHideDeleteAccountModal,
        onDeleteAccount: this.handleDeleteAccount,
        isDeleteInProgress: this.state.isDeleteInProgress,
        isNewsletterConsentChecked: this.state.isNewsletterConsentChecked,
        onNewsletterConsentChange: this.handleAcceptNewsletterChange,
        showNewsletter: !this.props.config.appConfig.runLocally,
        showTCAndPP: !this.props.config.appConfig.runLocally,
        onShowChangeEmailModal: this.handleShowChangeEmailModal,
        onHideChangeEmailModal: this.handleHideChangeEmailModal,
        showChangeEmailModal: this.state.showChangeEmailModal,
        emailStatus: this.state.emailStatus,
        onChangeEmailConfirmation: this.handleChangeEmailConfirmation,
        onEmailBlur: this.handleEmailBlur,
        onEmailChange: this.handleEmailChange,
        onPasswordBlur: this.handlePasswordBlur,
        onPasswordChange: this.handlePasswordChange,
        passwordStatus: this.state.passwordStatus,
      } as IPersonalInformationWidgetProps),
    );
  }
}
export default as<React.ComponentClass>(withRouter(withNamespaces('user')(PersonalInformationWidgetContainer)));
