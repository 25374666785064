import * as React from 'react';

import { IToolAffect, IToolUsage } from '../projectDetailsTools.models';
import { ToolType, mapToolType } from '../helpers/tools.helpers';
import { faCircleNotch, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { AnnotationDiscardConfirmModal } from './AnnotationDiscardConfirmModal';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { IconedButton } from '../../../../../components/IconedButton';
import { ToolDeleteModal } from './ToolDeleteModal';
import { ToolDeleteStrategy } from '../../../projectDetails.models';
import { as } from '../../../../../helpers/react.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface QuestionDeleteProps {
  canEdit: boolean;
  isAttribute: boolean;
  isLoading: boolean;
  showModal: boolean;
  showDiscardConfirmModal: boolean;
  toolUsage?: IToolUsage;
  strategy: ToolDeleteStrategy;
  onDelete(): void;
  onCancel(): void;
  onDeleteConfirmed(): void;
  onStrategyChange(strategy: ToolDeleteStrategy): void;
  onCancelDiscard(): void;
  onConfirmDiscard(): void;
  toolAffect? : IToolAffect;
}

@observer
class QuestionDeletePure extends React.Component<QuestionDeleteProps & ITranslatable> {
  render() {
    return (
      <>
        <DisabledWhenWorkspaceOwnerPolicyExceeded>
          <IconedButton
            onClick={this.props.onDelete}
            icon={this.props.isLoading ? faCircleNotch : faTrashAlt}
            color="red"
            className={this.props.isLoading ? 'fa-spin' : ''}
            disabled={!this.props.canEdit || this.props.isLoading}
            title={this.props.t(this.props.isAttribute ? 'remove_attribute' : 'remove_question')}
          />
        </DisabledWhenWorkspaceOwnerPolicyExceeded>
        {this.props.toolUsage && this.props.toolUsage.occurrenceCount > 0 &&
          <ToolDeleteModal
            strategy={this.props.strategy}
            showModal={this.props.showModal}
            onConfirm={this.props.onDeleteConfirmed}
            onCancel={this.props.onCancel}
            onStrategyChange={this.props.onStrategyChange}
            toolType={mapToolType(this.props.isAttribute ? ToolType.Attribute : ToolType.Question)}
            annotationsCount={this.props.toolUsage?.imageCount || 0}
            objectsCount={this.props.toolUsage?.occurrenceCount || 0}
          />
        }
        {this.props.toolUsage && this.props.toolUsage.occurrenceCount === 0 &&
          <ConfirmationModal
            confirmationHeader={this.props.t('confirm_delete')}
            confirmationQuestion={this.props.t(this.props.isAttribute ? 'are_you_sure_you_want_to_delete_attribute' : 'are_you_sure_you_want_to_delete')}
            onConfirm={this.props.onDeleteConfirmed}
            onCancel={this.props.onCancel}
            showModal={this.props.showModal}
            showSpinner={this.props.isLoading}
            confirmationYes={this.props.t('delete')}
            loaderKey="tool-delete-confirm-button"
          />
        }
        <AnnotationDiscardConfirmModal
          onCancel={this.props.onCancelDiscard}
          onConfirm={this.props.onConfirmDiscard}
          showModal={this.props.showDiscardConfirmModal}
          annotationsCount={this.props.toolUsage?.imageCount || 0}
          objectsCount={this.props.toolAffect?.objectsCount || 0}
          questionsCount={this.props.toolAffect?.questionsCount || 0}
          attributesCount={this.props.toolAffect?.attributesCount || 0}
        />
      </>
    );
  }
}

export const QuestionDelete = as<React.ComponentClass<QuestionDeleteProps>>(withNamespaces('project', { wait: true })(QuestionDeletePure));
