import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { DatasetsPermissionsType, IDatasetsPermissions } from '../datasets.permissions';
import { as, injectProps } from '../../../helpers/react.helpers';

import { Datasets } from '../datasets.context';
import { DatasetsHeader } from '../components/DatasetsHeader';
import React from 'react';
import { observer } from 'mobx-react';

export interface IInjectedProps {
  datasetsPermissions: IDatasetsPermissions;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

@injectProps({
  datasetsPermissions: DatasetsPermissionsType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
@observer
class DatasetsHeaderContainerPure extends React.Component<IInjectedProps> {
  static contextType = Datasets;
  declare context: React.ContextType<typeof Datasets>;

  render() {
    return (
      <DatasetsHeader
        datasetsCount={this.context.store.datasetsPaging.totalCount}
        workspaceId={this.props.currentWorkspaceStore.currentWorkspace?.id!}
        canCreateDataset={this.props.datasetsPermissions.canCreateDataset()}
      />
    );
  }
}

export const DatasetsHeaderContainer = as<React.ComponentClass>(DatasetsHeaderContainerPure);
