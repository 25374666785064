import { StickerEvent } from '../../../../../../__legacy__/services/eventBus.service';

export const ImportAnnotationsFinishedType = Symbol('IMPORT_ANNOTATIONS_FINISHED');

export class ImportAnnotationsFinished extends StickerEvent {
  constructor(public readonly projectId: string) {
    super();
  }

  get type(): Symbol {
    return ImportAnnotationsFinishedType;
  }
}
