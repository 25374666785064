import * as React from 'react';

import { Button, Col, CustomInput, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IProjectImportReport, ImportStage } from '../projects.model';
import { faCaretDown, faCaretUp, faEye, faUnlink, faUpload } from '@fortawesome/free-solid-svg-icons';

import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import Dropzone from 'react-dropzone';
import { EnableForRole } from '../../../containers/EnableForRole';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { ImportAnnotationToolsSummary } from './ImportAnnotationToolsSummary';
import { ImportAnnotationsSummary } from './ImportAnnotationsSummary';
import { ImportAttributesSummary } from './ImportAttributesSummary';
import { ImportImageSetsSummary } from './ImportImageSetsSummary';
import { ImportQuestionsSummary } from './ImportQuestionsSummary';
import { ProjectRole } from '../../../models/userRole.model';
import { S_BarLoader } from '../../../../design/loaders/barLoader/S_BarLoader';
import { UnregisteredUsersSummaryPane } from '../../../../modules/projects/details/images/importAnnotations/ui/steps/summary/unregisteredUsersSummaryPane/UnregisteredUsersSummaryPane';
import { WizardModalBaseContent } from '../../../components/wizardModal/contents/WizardModalBaseContent';
import { WizardModalDropZoneContent } from '../../../components/wizardModal/contents/WizardModalDropZoneContent';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import { as } from '../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

library.add(faUnlink, faEye);

interface IImportModalProps {
  modalsShown(): void;
  modalHidden(): void;
  warningAccepted(): void;
  filePicked(): void;
  uploadStarted(): void;
  filesDropped(files: File[]): void;
  importAnnotationsChanged(): void;
  importImageSetsChanged(): void;
  goToProject(): void;
  stage: ImportStage;
  canUpload: boolean;
  isShown: boolean;
  importAnnotations: boolean;
  importImageSets: boolean;
  canCloseModal: boolean;
  validationError?: string;
  report?: IProjectImportReport;
  errorCodes?: string[];
  fileName?: string;
}

interface IState {
  isImageSetsPanelOpen: boolean;
  isUserPanelOpen: boolean;
  isToolsPanelOpen: boolean;
  isAttributesPanelOpen: boolean;
  isQuestionsPanelOpen: boolean;
}

library.add(faCaretDown, faCaretUp);

@observer
class ImportModalPure extends React.Component<IImportModalProps & ITranslatable, IState> {
  constructor(props: IImportModalProps & ITranslatable) {
    super(props);
    this.state = { isImageSetsPanelOpen: false, isUserPanelOpen: false, isToolsPanelOpen: false, isAttributesPanelOpen: false, isQuestionsPanelOpen: false };
  }

  @autobind
  handleImageSetsPanelToggle() {
    this.setState((old: IState) => {
      return { isImageSetsPanelOpen: !old.isImageSetsPanelOpen };
    });
  }

  @autobind
  handleToolsPanelToggle() {
    this.setState((old: IState) => {
      return { isToolsPanelOpen: !old.isToolsPanelOpen };
    });
  }

  @autobind
  handleUserPanelToggle() {
    this.setState((old: IState) => {
      return { isUserPanelOpen: !old.isUserPanelOpen };
    });
  }

  @autobind
  handleAttributesPanelToggle() {
    this.setState((old: IState) => {
      return { isAttributesPanelOpen: !old.isAttributesPanelOpen };
    });
  }

  @autobind
  handleQuestionsPanelToggle() {
    this.setState((old: IState) => {
      return { isQuestionsPanelOpen: !old.isQuestionsPanelOpen };
    });
  }

  @autobind
  mapUserRoles(roles: string[]): string {
    let result = roles.includes(ProjectRole.Annotator) ? ProjectRole.Annotator : '';
    result += [ProjectRole.Annotator, ProjectRole.Reviewer].every(x => roles.includes(x)) ? ` ${this.props.t('and ')}` : '';
    result += roles.includes(ProjectRole.Reviewer) ? ProjectRole.Reviewer : '';

    return result;
  }

  stepOneBody() {
    return (
      <WizardModalBaseContent
        title={`${this.props.t('import_settings')} - ${this.props.t('information')}`}
        modalHidden={this.props.modalHidden}
        nextPressed={this.props.warningAccepted}
      >
        <p>{this.props.t('you_are_about_to_start_import_process')}</p>
        <p>{this.props.t('import_note')}</p>
        <p>{this.props.t('import_users_note')}</p>
        <p>{this.props.t('do_you_want_to_continue')}</p>
      </WizardModalBaseContent>
    );
  }

  stepTwoBody() {
    return (
      <WizardModalDropZoneContent
        title={`${this.props.t('import_settings')} - ${this.props.t('select_file')}`}
        canConfirm={this.props.canUpload}
        fileName={this.props.fileName}
        filesDropped={this.props.filesDropped}
        modalHidden={this.props.modalHidden}
        nextPressed={this.props.filePicked}
        fileValidationError={this.props.validationError}
      />
    );
  }

  stepThreeBody() {
    return (
      <>
        <ModalHeader toggle={this.props.modalHidden}>
          {this.props.t('import_settings')} - {this.props.t('configure_import')}
        </ModalHeader>
        <ModalBody>
          <div>
            <p>{this.props.t('pick_which_elements_should_be_imported')}:</p>
            <Row className="import-checkboxes">
              <Col>
                <FormGroup className="checkbox">
                  <CustomInput type="checkbox" id="tools-checkbox" checked={true} disabled={true} label={this.props.t('annotation_tools_and_questions')} />
                  <CustomInput
                    type="checkbox"
                    id="annotations-checkbox"
                    checked={this.props.importAnnotations}
                    onChange={this.props.importAnnotationsChanged}
                    label={this.props.t('annotations')}
                  />
                  <CustomInput
                    type="checkbox"
                    id="image-set-checkbox"
                    checked={this.props.importImageSets}
                    onChange={this.props.importImageSetsChanged}
                    label={this.props.t('image_set_configuration')}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" className="mr-2" onClick={this.props.uploadStarted}>
            {this.props.t('finish')}
          </Button>
          <Button outline color="primary" onClick={this.props.modalHidden}>
            {this.props.t('cancel')}
          </Button>
        </ModalFooter>
      </>
    );
  }

  processingBody() {
    return (
      <>
        <ModalHeader>
          {this.props.t('import_settings')} - {this.props.t('processing')}
        </ModalHeader>
        <ModalBody>
          <div>
            <p>{this.props.t('we_are_processing_your_file_please_wait')}</p>
          </div>
          <S_BarLoader />
        </ModalBody>
      </>
    );
  }

  finishedBody() {
    const importedImageSetsCount = this.props.report?.importedImageSetsCount || 0;
    const importedToolsCount = this.props.report?.importedToolsCount || 0;
    const importedAttributesCount = this.props.report?.importedAttributesCount || 0;
    const importedQuestionsCount = this.props.report?.importedQuestionsCount || 0;

    return (
      <>
        <ModalHeader toggle={this.props.modalHidden}>
          {this.props.t('import_settings')} - {this.props.t('finished')}
        </ModalHeader>
        <ModalBody>
          <div>
            <p>{this.props.t('your_settings_have_been_successfully_imported')}</p>
            {this.props.report && (
              <div className="import-summary">
                <span className="import-summary-header">{this.props.t('summary')}:</span>

                <ImportAnnotationToolsSummary
                  isPanelOpen={this.state.isToolsPanelOpen}
                  handlePanelToggle={this.handleToolsPanelToggle}
                  importedToolsCount={importedToolsCount}
                  rejectedTools={this.props.report.rejectedTools}
                  skippedToolsCount={this.props.report.skippedToolsCount}
                />

                <ImportAttributesSummary
                  isPanelOpen={this.state.isAttributesPanelOpen}
                  handlePanelToggle={this.handleAttributesPanelToggle}
                  importedAttributesCount={importedAttributesCount}
                  rejectedAttributes={this.props.report.rejectedAttributes}
                  skippedAttributesCount={this.props.report.skippedAttributesCount}
                />

                <ImportQuestionsSummary
                  isPanelOpen={this.state.isQuestionsPanelOpen}
                  handlePanelToggle={this.handleQuestionsPanelToggle}
                  importedQuestionsCount={importedQuestionsCount}
                  rejectedQuestions={this.props.report.rejectedQuestions}
                  skippedQuestionsCount={this.props.report.skippedQuestionsCount}
                />

                {this.props.importAnnotations && <ImportAnnotationsSummary {...this.props.report} />}

                <ImportImageSetsSummary
                  isPanelOpen={this.state.isImageSetsPanelOpen}
                  handlePanelToggle={this.handleImageSetsPanelToggle}
                  importedImageSetsCount={importedImageSetsCount}
                  rejectedImageSets={this.props.report.rejectedImageSets}
                  skippedImageSetsCount={this.props.report.skippedImageSetsCount}
                />

                <div className="datasets-summary">
                  {this.props.report.assignedDatasetNames.length > 0 && (
                    <div>
                      <span className="import-summary-header">{this.props.t('datasets_from_file', { count: this.props.report.assignedDatasetNames.length })}:</span>
                      <ul>
                        {this.props.report.assignedDatasetNames.map(x => (
                          <li key={x}>
                            {x} - {this.props.t('successfully_matched')}
                          </li>
                        ))}
                        {this.props.report.rejectedDatasetNames.map(x => (
                          <li key={x}>
                            {x} - {this.props.t('not_found_in_the_current_workspace')}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                {this.props.report.unregisteredUsers.length > 0 && <UnregisteredUsersSummaryPane unregisteredUsers={this.props.report.unregisteredUsers} />}
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.props.goToProject}>
            {this.props.t('Go to project')}
          </Button>
        </ModalFooter>
      </>
    );
  }

  errorBody() {
    let errorMessage = this.props.t('there_was_something_wrong_with_the_json_file_please_try_again');

    if (this.props.errorCodes?.length || 0 > 0) {
      if (this.props.errorCodes?.some(x => x === 'IMPORT_DESERIALIZATION_FAILED')) {
        errorMessage = this.props.t('your_json_file_is_invalid_please_check_your_file_and_try_again');
      }
    }

    return (
      <>
        <ModalHeader toggle={this.props.modalHidden}>
          {this.props.t('import_settings')} - <span className="fail">{this.props.t('error')}</span>
        </ModalHeader>
        <ModalBody>
          <div>
            <p>
              {this.props.fileName ? (
                <span>
                  {this.props.t('selected_file')}: <b>{this.props.fileName}</b>
                </span>
              ) : (
                <span>{errorMessage}</span>
              )}
            </p>
            <Dropzone
              className="dropzone-generic"
              activeClassName="active"
              multiple={false}
              onDropRejected={this.props.filesDropped}
              onDropAccepted={this.props.filesDropped}
              accept={'application/JSON'}
            >
              <FontAwesomeIcon icon={faUpload} />
              {this.props.t('drop_your_json_file_here_or_select_it_manually')}
            </Dropzone>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button disabled={!this.props.canUpload} color="primary" onClick={this.props.filePicked} className="mr-2">
            {this.props.t('continue')}
          </Button>

          <Button outline color="primary" onClick={this.props.modalHidden}>
            {this.props.t('cancel')}
          </Button>
        </ModalFooter>
      </>
    );
  }

  @autobind
  bodyFactory() {
    switch (this.props.stage) {
      case ImportStage.PROCESSING:
        return this.processingBody();
      case ImportStage.ERROR:
        return this.errorBody();
      case ImportStage.FINISHED:
        return this.finishedBody();
      case ImportStage.STEP3:
        return this.stepThreeBody();
      case ImportStage.STEP2:
        return this.stepTwoBody();
      case ImportStage.STEP1:
      default:
        return this.stepOneBody();
    }
  }

  @autobind
  handleClick(event: any) {
    event.preventDefault();
    this.props.modalsShown();
  }

  render() {
    return (
      <EnableForRole workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Trainer]} projectRoles={[]}>
        <DisabledWhenWorkspaceOwnerPolicyExceeded>
          <Button color="primary" className="create-button" onClick={this.handleClick}>
            {this.props.t('import_from_a_file')}
          </Button>
          <Modal isOpen={this.props.isShown} toggle={this.props.modalHidden} className="import-modal modal-width-600">
            {!this.props.canCloseModal && <div className="modal-overlay" />}
            {this.bodyFactory()}
          </Modal>
        </DisabledWhenWorkspaceOwnerPolicyExceeded>
      </EnableForRole>
    );
  }
}

export const ImportModal = as<React.ComponentClass<IImportModalProps>>(withNamespaces('common', { wait: true })(ImportModalPure));
