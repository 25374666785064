import * as React from 'react';

import { AddCreditsModal } from '../../../modules/admin/components/AddCreditsModal';
import { AdminHeader } from '../../../modules/admin/components/AdminHeader';
import { AdminUsersList } from '../../../modules/admin/components/AdminUsersList';
import ChangeUserPlanModal from '../../../modules/admin/components/ChangeUserPlanModal';
import ChangeUserRoleModal from '../../../modules/admin/components/ChangeUserRoleModal';
import { DashboardContainer } from '../../../containers/DashboardContainer';
import LockUserModal from '../../../modules/admin/components/LockUserModal';
import UsersContainer from '../../../modules/admin/containers/UsersList.container';

export const UsersView = () => (
    <DashboardContainer header={AdminHeader}>
      <UsersContainer>
        <AdminUsersList />
        <ChangeUserRoleModal />
        <ChangeUserPlanModal />
        <AddCreditsModal />
        <LockUserModal />
      </UsersContainer>
    </DashboardContainer>
);
