import { IImageFilters, IImageFiltersData } from '../../../imageFilters.model';

import { FiltersDropdownRow } from '../FiltersDropdownRow';
import { ISelectOption } from '../../../../../../../helpers/formHelpers';
import { ITranslatable } from '../../../../../../../helpers/translations.helpers';
import { ImageSetType } from '../../../projectDetailsImages.model';
import React from 'react';
import { as } from '../../../../../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IImageSetFilterInfoProps {
  availableFilters: IImageFiltersData;
  imageFilters: IImageFilters;
}

class ImageSetFilterInfoPure extends React.Component<IImageSetFilterInfoProps & ITranslatable> {
  render() {
    const options: ISelectOption<ImageSetType>[] =
      Object.values(ImageSetType).map(t => ({ label: this.props.t(`image_set_options.${t}`), value: t }));

    const selectedOptions = options
      .filter(d => this.props.imageFilters.imageSets.includes(d.value));

    return (
      <FiltersDropdownRow label={this.props.t('image_filters.imageSet')} count={selectedOptions.length}>
        <>{selectedOptions.map(d => <div key={d.value}>{d.label}</div>)}</>
      </FiltersDropdownRow>);
  }
}

export const ImageSetFilterInfo = as<React.ComponentClass<IImageSetFilterInfoProps>>(withNamespaces('project')(ImageSetFilterInfoPure));
