import './DownloadExportModal.scss';

import React, { useEffect } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import { S_OneButtonModal } from '../../../../../../design/modals/oneButtonModal/S_OneButtonModal';

interface Props {
  show: boolean;
  downloadLink: string;
  onHide(): void;
}

export const DownloadExportModalPure: React.FC<Props & WithNamespaces> = (props: Props & WithNamespaces) => {
  const { t, show, onHide, downloadLink } = props;

  const handleDownload = () => window.open(downloadLink, '_blank');

  useEffect(() => {
    if (show) {
      window.open(downloadLink, '_self');
    }
  }, [show]);

  return (
    <S_OneButtonModal buttonText={t('ok')} onHide={onHide} onClick={onHide} headerText={t('new.projects.details.export_data.download_modal.modal_header')} show={show}>
      <p>{t('new.projects.details.export_data.download_modal.modal_body1')}</p>
      <div className="direct-download-link-container">
        <p>{t('new.projects.details.export_data.download_modal.modal_body2')}</p>
        <span className="text-link" onClick={handleDownload}>
          {t('new.projects.details.export_data.download_modal.direct_download_link')}
        </span>
      </div>
    </S_OneButtonModal>
  );
};

export const DownloadExportModal = withNamespaces('common')(DownloadExportModalPure);
