import { camelize } from './string.helper';

export function objectKeysToLowerCase(origObj: any): any {
  const reduceFun = (newObj: any, key: string) => {
    const val = origObj[key];
    let newVal;
    if (val instanceof Array) {
      newVal = val.map((o: any) => {
        return typeof o === 'object' ? objectKeysToLowerCase(o) : o;
      });
    } else if (typeof val === 'object') {
      newVal = objectKeysToLowerCase(val);
    } else {
      newVal = val;
    }
    return { ...newObj, [camelize(key)]: newVal };
  };
  return Object.keys(origObj).reduce(reduceFun, {});
}

export function nameOf<T>(key: keyof T, _instance?: T): keyof T {
  return key;
}
