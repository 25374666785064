import * as React from 'react';

import { container } from '../diContainer';

interface IInjectProps {
  [typeName: string]: string | symbol;
}

interface ICallbackResult {
  [objName: string]: any;
}

export function injectProps(props: IInjectProps, callback?: (s: any) => ICallbackResult) {
  return function (Component: React.ComponentType<any>): any {
    let newProps = Object.keys(props)
      .map(k => ({ [k]: container.get(props[k]) }))
      .reduce((c, a) => ({ ...a, ...c }), {});

    if (callback) newProps = callback({ ...newProps });

    return (props: any) => <Component {...newProps} {...props} />;
  };
}

/**
 * Casts object to type T.
 * Helps in runtime with react components, which have injected props.
 * @param input object
 */
export const as = <T extends {}>(input: any): T => input;

export function scrollTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export function disableTextMarkingsForClass(className: string) {
  // this will disable marking of texts in Chrome and Edge
  document.onselectstart = (e: any) => {
    if (e.target && e.target.classList && e.target.classList.value === className) return false;
  };
}
