import { AlertBarStoreSetterType, IAlertBarStoreSetter } from './AlertBar.store';
import { inject, injectable } from 'inversify';
import { Alert } from './models/Alert';
import { EventBusType, EventListeningDisposer, IEventBus } from '../../__legacy__/services/eventBus.service';
import { AlertsUpdatedEvent, AlertsUpdatedEventType } from './models/AlertsUpdatedEvent';
import { IProjectHubService, ProjectHubServiceType } from '../../__legacy__/services/projectHub.service';
import autobind from 'autobind-decorator';

export const AlertBartBlType = Symbol('ALERT_BAR_BL');

export interface IAlertBarBl {
  cleanup(): void;
  clearOutdatedAlert(alert: Alert): void;
  initializeAsync(): Promise<void>;
  setAlerts(alerts: Alert[]): void;
}

@injectable()
export class AlertBarBl implements IAlertBarBl {
  private alertsUpdatedDisposer?: EventListeningDisposer;
  private userInfoLoadedDisposer?: EventListeningDisposer;

  constructor(
    @inject(AlertBarStoreSetterType) private readonly alertBarStoreSetter: IAlertBarStoreSetter,
    @inject(ProjectHubServiceType) private readonly projectHubService: IProjectHubService,
    @inject(EventBusType) private readonly eventBus: IEventBus,
  ) {}

  async initializeAsync(): Promise<void> {
    await this.projectHubService.initializeAsync();
    this.alertsUpdatedDisposer = this.eventBus.addListener(this.handleAlertsUpdated, AlertsUpdatedEventType);
  }

  cleanup(): void {
    this.alertsUpdatedDisposer?.();
    this.userInfoLoadedDisposer?.();
  }

  clearOutdatedAlert(alert: Alert): void {
    this.alertBarStoreSetter.setAlerts(this.alertBarStoreSetter.alerts.filter(a => a.id !== alert.id));
  }

  @autobind
  handleAlertsUpdated(event: AlertsUpdatedEvent): void {
    this.setAlerts(event.alerts);
  }

  @autobind
  setAlerts(alerts: Alert[]): void {
    this.alertBarStoreSetter.setAlerts(alerts);
  }
}
