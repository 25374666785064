import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { faDrawPolygon, faGlobe, faInfoCircle, faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { DropdownLinkItem } from '../../../../components/DropdownLinkItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Home } from '../../../../routes/config/Home';
import { ITranslatable } from '../../../../helpers/translations.helpers';
import { Link } from 'react-router-dom';
import { LinkedButton } from '../../../../components/LinkedButton';
import { NoAccessTooltip } from '../../../../containers/NoAccessTooltip';
import { PublishButton } from '../../../projectDetails/components/PublishButton';
import { PublishButtonContainer } from '../../../projectDetails/containers/PublishButton.container';
import React from 'react';
import { RestrictAccessTo2 } from '../../../../containers/RestrictAccessTo2';
import { as } from '../../../../helpers/react.helpers';
import { handleClickAndPassData } from '../../../../helpers/formHelpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';

library.add(faInfoCircle, faTrashAlt, faPen, faGlobe, faDrawPolygon);

interface IProps {
  id: string;
  name: string;
  description: string;
  publishReady: boolean;
  workspaceId: string;
  deleteDraft(draftId: string): void;
  canEdit: boolean;
  canView: boolean;
  canDelete: boolean;
}

export const DraftProjectThumbItem = as<React.SFC<IProps>>(
  withNamespaces('project')((props: IProps & ITranslatable) => (
    <Col md="6" lg="6" xl="4" className="d-flex">
      <Card className="flex-fill project-card draft with-dropdown">
        <div className="top-options">
          <Link
            className="top-badge draft"
            title={props.t('draft')}
            to={Home.Projects.Details.AnnotationView.withParams({ projectId: props.id, workspaceId: props.workspaceId })}
          >
            <FontAwesomeIcon icon={faPen} />
          </Link>
          <PublishButtonContainer
            projectId={props.id}
            disabled={!props.publishReady}
          >
            {publishProps =>
              <UncontrolledDropdown className="card-dropdown">
                <DropdownToggle className="width-50">
                  <FontAwesomeIcon icon="ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu right>
                  <RestrictAccessTo2 hasAccess={props.canEdit}>
                    <DropdownLinkItem linkTo={Home.Projects.Details.AnnotationView.withParams({ projectId: props.id, workspaceId: props.workspaceId })}>
                      {props.t('edit')}
                    </DropdownLinkItem>
                  </RestrictAccessTo2>
                  <RestrictAccessTo2 hasAccess={!props.canEdit && props.canView}>
                    <DropdownLinkItem linkTo={Home.Projects.Details.AnnotationView.withParams({ projectId: props.id, workspaceId: props.workspaceId })}>
                      {props.t('project_details')}
                    </DropdownLinkItem>
                  </RestrictAccessTo2>
                  <RestrictAccessTo2 hasAccess={props.canEdit}>
                    <DisabledWhenWorkspaceOwnerPolicyExceeded>
                      <DropdownItem
                        toggle={false}
                        onClick={publishProps.onClick}
                        disabled={publishProps.disabled}
                      >
                        {props.t('publish')}
                      </DropdownItem>
                    </DisabledWhenWorkspaceOwnerPolicyExceeded>
                  </RestrictAccessTo2>
                  <RestrictAccessTo2 hasAccess={props.canDelete}>
                    <DropdownItem divider />
                    <DropdownItem className="red" onClick={handleClickAndPassData(props.deleteDraft)(props.id)}>
                      <FontAwesomeIcon icon={faTrashAlt} /> {props.t('delete_project')}
                    </DropdownItem>
                  </RestrictAccessTo2>
                </DropdownMenu>
              </UncontrolledDropdown>}
          </PublishButtonContainer>
        </div>
        <CardBody>
          <div className="info">
            <CardTitle tag="h5" className="text-muted name">
              <Link className="text-muted" to={Home.Projects.Details.AnnotationView.withParams({ projectId: props.id, workspaceId: props.workspaceId })}>
                {' '}
                {props.name}
              </Link>
            </CardTitle>
            <CardText className="text-muted description">{props.description}</CardText>
          </div>
          <div className="bottom">
            <div className="alerts">
              {props.publishReady ? (
                <p className="alerts-info">
                  <FontAwesomeIcon icon={faInfoCircle} />
                  {props.t('this_project_has_not_been_published')}
                </p>
              ) : (
                <p className="alerts-info">
                  <FontAwesomeIcon icon={faInfoCircle} />
                  {props.t('draft_configuration_is_not_finished')}
                </p>
              )}
            </div>
            <NoAccessTooltip hasAccess={props.canEdit}>
              <DisabledWhenWorkspaceOwnerPolicyExceeded>
                <LinkedButton
                  to={Home.Projects.Details.AnnotationView.withParams({ projectId: props.id, workspaceId: props.workspaceId })}
                  color="primary"
                  className="float-left"
                >
                  {props.t('edit')}
                </LinkedButton>
                <PublishButtonContainer
                  projectId={props.id}
                  disabled={!props.publishReady}
                >
                  {childrenProps =>
                    <PublishButton
                      {...childrenProps}
                      color="success"
                      className="float-right"
                    />}
                </PublishButtonContainer>
              </DisabledWhenWorkspaceOwnerPolicyExceeded>
            </NoAccessTooltip>
          </div>
        </CardBody>
      </Card>
    </Col>
  )),
);
