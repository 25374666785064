import { AuthServiceType, IAuthService } from '../auth.service';
import { AuthStoreType, IAuthStore } from '../auth.store';
import { IUserService, UserServiceType } from '../../user/user.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import React from 'react';
import { StickerError } from '../../../models/error.model';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import qs from 'qs';

export type ResendStatus = 'NotSent' | 'Sending' | 'Sent';
export type ConfirmationStatus = 'InProgress' | 'Success' | 'Failed';

interface IConfirmNewEmailContainerProps {
  onGoToLoginClick(): void;
}

interface IInjectedProps {
  authService: IAuthService;
  authStore: IAuthStore;
  userService: IUserService;
}

interface IState {
  confirmationStatus: ConfirmationStatus;
}

type Props = IConfirmNewEmailContainerProps & IInjectedProps & RouteComponentProps;

@observer
@injectProps({
  authService: AuthServiceType,
  authStore: AuthStoreType,
  userService: UserServiceType,
})
class ConfirmEmailContainerPure extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      confirmationStatus: 'InProgress',
    };
  }

  @autobind
  async confirmNewEmail() {
    this.setState((prevState: IState): IState => ({ ...prevState, confirmationStatus: 'InProgress' }));
    const parsedSearch = qs.parse(this.props.location.search.slice(1));

    const result = await this.props.authService.confirmNewEmail({ email: parsedSearch.email, token: parsedSearch.token });

    if (result instanceof StickerError) {
      this.setState({ confirmationStatus: 'Failed' });
    } else {
      this.setState({ confirmationStatus: 'Success' });

      setTimeout(() => {
        this.props.authService.logout();
      }, 10000);
    }

    this.setState(
      (prevState: IState): IState => ({
        ...prevState,
        confirmationStatus: result instanceof StickerError ? 'Failed' : 'Success',
      }),
    );
  }

  @autobind
  handleGoToLoginClick() {
    this.props.authService.logout();
  }

  render() {
    return React.Children.map(this.props.children, (child: any) =>
      React.cloneElement(child, {
        confirmationStatus: this.state.confirmationStatus,
        onActivateAccount: this.confirmNewEmail,
        onGoToLoginClick: this.handleGoToLoginClick,
      } as IConfirmNewEmailContainerProps),
    );
  }
}

export const ConfirmNewEmailContainer = as<React.ComponentClass>(withRouter(ConfirmEmailContainerPure));
