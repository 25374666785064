import { Button, ButtonProps } from 'reactstrap';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../modules/common/OverlayLoader.store';
import { as, injectProps } from '../helpers/react.helpers';

import { ClipLoader } from 'react-spinners';
import React from 'react';
import { observer } from 'mobx-react';

export interface IButtonWithLoaderProps extends ButtonProps {
  smallSpinner?: boolean;
  loaderKey?: string;
  isLoading?: boolean;
}

interface IInjectedProps {
  overlayLoaderStore: IOverlayLoaderStore;
}

@injectProps({
  overlayLoaderStore: OverlayLoaderStoreType,
})
@observer
class ButtonWithLoaderPure extends React.Component<IButtonWithLoaderProps & IInjectedProps>{
  render() {
    const spinnerClass = this.props.smallSpinner ? 'button-spinner small' : 'button-spinner';
    const spinnerSize = this.props.smallSpinner ? 12 : 18;
    // isLoading is here only to take it away from `rest` which would cause error when passed to Button, because button has no `isLoading` prop
    const { isLoading, loaderKey, smallSpinner, overlayLoaderStore, className, ...rest } = this.props;
    const isReallyLoading = this.props.isLoading || (this.props.loaderKey && this.props.overlayLoaderStore.isSpinnerVisible(this.props.loaderKey));

    return (
      <Button
        {...rest}
        disabled={this.props.disabled || isReallyLoading === true}
        className={isReallyLoading ? `${this.props.className} button-with-loader-is-loading` : this.props.className}
      >
        {this.props.children}
        {
          isReallyLoading &&
          (<span className={spinnerClass}>
            <ClipLoader color="#fff" size={spinnerSize} />
          </span>)
        }
      </Button >
    );
  }
}

export const ButtonWithLoader = as<React.ComponentClass<IButtonWithLoaderProps>>(ButtonWithLoaderPure);
