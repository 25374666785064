import { AnnotateStage, FreeAccessMode, FreeAccessStoreType, IFreeAccessStore } from '../freeAccess.store';
import { AnnotationTypeStoreType, IAnnotationTypeStore } from '../../annotation/submodules/annotationTypes/annotationType.store';
import { AnnotationUiStoreType, IAnnotationUiStore } from '../../annotation/annotationUi.store';
import { ClarificationsStoreType, IClarificationsStore } from '../../annotation/submodules/clarifications/Clarifications.store';
import { Col, Container, Row } from 'reactstrap';
import { FreeAccessApiServiceType, IFreeAccessApiService } from '../freeAccessApi.service';
import { FreeAccessPermissionsType, IFreeAccessPermissions } from '../freeAccess.permissions';
import { FreeAccessServiceType, IFreeAccessService } from '../freeAccess.service';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { IWorkspaceService, WorkspaceServiceType } from '../../workspaces/workspaces.service';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { as, injectProps } from '../../../helpers/react.helpers';

import { AnnotationStatusInfo } from '../../annotation/components/AnnotationStatusInfo';
import { ButtonWithLoader } from '../../../components/ButtonWithLoader';
import ClarificationsContainer from '../../annotation/submodules/clarifications/containers/Clarifications.container';
import ClarifyButtonContainer from '../../annotation/submodules/clarifications/containers/ClarifyButton.container';
import { GlobalHotKeys } from 'react-hotkeys';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { Loader } from '../../../components/Loader';
import { NoAccessTooltip } from '../../../containers/NoAccessTooltip';
import { ProjectStatus } from '../../projects/projects.model';
import React from 'react';
import { StickerError } from '../../../models/error.model';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps extends RouteComponentProps<{ projectId: string }> {
  freeAccessStore: IFreeAccessStore;
  freeAccessService: IFreeAccessService;
  freeAccessApiService: IFreeAccessApiService;
  uiData: IAnnotationUiStore;
  workspaceService: IWorkspaceService;
  freeAccessPermissions: IFreeAccessPermissions;
  clarificationStore: IClarificationsStore;
  annotationTypeStore: IAnnotationTypeStore;
}

@injectProps({
  annotationTypeStore: AnnotationTypeStoreType,
  freeAccessStore: FreeAccessStoreType,
  freeAccessApiService: FreeAccessApiServiceType,
  freeAccessService: FreeAccessServiceType,
  uiData: AnnotationUiStoreType,
  workspaceService: WorkspaceServiceType,
  freeAccessPermissions: FreeAccessPermissionsType,
  clarificationStore: ClarificationsStoreType,
})
@observer
class FreeAccessActionsContainerPure extends WithLoaderComponentBase<IInjectedProps & ITranslatable, ILoaderState> {
  async componentDidMount() {
    await this.props.freeAccessService.getProjectStatusAsync(this.props.match.params.projectId);
  }

  async changeModeAsync(mode: FreeAccessMode) {
    if (this.state.isLoading) return;

    this.withLoaderAsync(async () => {
      const { imageId, projectId } = this.props.freeAccessStore;
      const result = await this.props.freeAccessApiService.lockImageAsync(imageId, projectId, mode);

      if (result instanceof StickerError) {
        await this.props.freeAccessService.updateLockImageInfoAsync();
      } else {
        await this.props.freeAccessService.changeModeAsync(mode);
      }
    }, 'free-access-action-button');
  }

  onEditHotKeyPressed = async () => {
    if (this.props.freeAccessStore.projectStatus === ProjectStatus.Draft && this.props.freeAccessStore.isImported) return;

    if (this.props.freeAccessStore.projectStatus !== ProjectStatus.Draft && this.props.freeAccessStore.annotateStage !== AnnotateStage.NOTANNOTATED) {
      await this.changeModeAsync(FreeAccessMode.ANNOTATIONRESET);
    }
  };

  onAnnotateHotKeyPressed = async () => {
    if (this.props.freeAccessStore.projectStatus === ProjectStatus.Draft && this.props.freeAccessStore.isImported) return;
    await this.changeModeAsync(this.props.freeAccessStore.annotateStage !== AnnotateStage.REJECTED ? FreeAccessMode.ANNOTATION : FreeAccessMode.CORRECT);
  };

  onReviewHotKeyPressed = async () => {
    if (this.props.freeAccessStore.projectStatus === ProjectStatus.Draft && this.props.freeAccessStore.isImported) return;
    if ([AnnotateStage.ANNOTATED, AnnotateStage.REVIEWED].includes(this.props.freeAccessStore.annotateStage)) await this.changeModeAsync(FreeAccessMode.REVIEW);
  };

  render() {
    const {
      annotatedByName,
      reviewedByName,
      lockedByName,
      canAddAnnotations,
      annotateStage,
      rejectionReason,
      isAssigned,
      freeAccessMode,
      status,
      isImported,
      isWaitingForReviewAfterReject
    } = this.props.freeAccessStore;
    const isLoading = this.props.uiData.isImageLoading;
    const isImportedAnnotationInDraft = this.props.freeAccessStore.projectStatus === ProjectStatus.Draft && isImported;
    const isImportedAnnotationInDraftTooltip = this.props.t('cant_do_anything_on_import_in_draft');

    let startButtonTooltip;
    let startButtonText;
    let mode: FreeAccessMode;

    if (annotateStage === AnnotateStage.NOTANNOTATED) {
      startButtonTooltip = this.props.t('start_annotation_tooltip');
      startButtonText = this.props.t('start_annotation');
      mode = FreeAccessMode.ANNOTATION;
    } else if (annotateStage === AnnotateStage.ANNOTATED) {
      startButtonTooltip = this.props.t('start_review_tooltip');
      startButtonText = this.props.t('start_review');
      mode = FreeAccessMode.REVIEW;
    } else if (annotateStage === AnnotateStage.DRAFT) {
      startButtonTooltip = this.props.t('continue_annotation_tooltip');
      startButtonText = this.props.t('continue_annotation');
      mode = FreeAccessMode.ANNOTATION;
    } else if (annotateStage === AnnotateStage.REJECTED) {
      startButtonTooltip = this.props.t('correct_annotation_tooltip');
      startButtonText = this.props.t('correct_annotation');
      mode = FreeAccessMode.CORRECT;
    } else {
      startButtonTooltip = this.props.t('review_again_tooltip');
      startButtonText = this.props.t('review_again');
      mode = FreeAccessMode.REVIEW;
    }

    const changeMode = async () => await this.changeModeAsync(mode);
    const onEdit = async () => await this.changeModeAsync(FreeAccessMode.ANNOTATIONRESET);
    const onReAnnotate = async () => await this.changeModeAsync(FreeAccessMode.ANNOTATION);

    const hasUnansweredClarification = this.props.clarificationStore.hasUnansweredClarification;

    const hasNoMarkingToolOrQuestionSpecified = !this.props.freeAccessStore.questions.length && !this.props.annotationTypeStore.annotationTypes.length;
    return (
      <Loader isLoading={isLoading} hideSpinner>
        <Container>
          {lockedByName && (
            <Row className="text-elipsis free-access-actions-alert">
              <Col>
                <span>{this.props.t('someone_is_working_on_image', { name: this.props.t(lockedByName) })}</span>
              </Col>
            </Row>
          )}
          {!canAddAnnotations && annotateStage === AnnotateStage.NOTANNOTATED && (
            <Row className="free-access-actions-alert">
              <Col>
                <span>{this.props.t('not_enough_annotation_credits_buy_more')}</span>
              </Col>
            </Row>
          )}

          <AnnotationStatusInfo
            isImageLoaded={true}
            annotationStatus={status}
            annotatedByName={annotatedByName}
            reviewedByName={reviewedByName}
            isAssigned={isAssigned}
            reviewRejectionReason={rejectionReason}
            hasUnansweredClarification={hasUnansweredClarification}
            isWaitingForReviewAfterReject={isWaitingForReviewAfterReject}
          />
          <ClarificationsContainer />

          {freeAccessMode === FreeAccessMode.VIEW && !hasNoMarkingToolOrQuestionSpecified && (
            <Row className="free-access-actions">
              {this.props.freeAccessPermissions.canUseKeyboardShortcuts() && (
                <GlobalHotKeys
                  keyMap={{
                    EDIT: ['e', 'E'],
                    ANNOTATE: ['a', 'A'],
                    REVIEW: ['r', 'R'],
                  }}
                  handlers={{
                    EDIT: async () => await this.onEditHotKeyPressed(),
                    ANNOTATE: async () => await this.onAnnotateHotKeyPressed(),
                    REVIEW: async () => await this.onReviewHotKeyPressed(),
                  }}
                />
              )}
              <Col>
                {annotateStage !== AnnotateStage.NOTANNOTATED && (
                  <NoAccessTooltip hasAccess={this.props.freeAccessPermissions.canEditAnnotation()}>
                    <ButtonWithLoader
                      loaderKey="free-access-action-button"
                      id="edit-annotation-button"
                      color="primary"
                      disabled={!!lockedByName || !canAddAnnotations || isImportedAnnotationInDraft}
                      onClick={onEdit}
                      title={isImportedAnnotationInDraft ? isImportedAnnotationInDraftTooltip : this.props.t('edit_tooltip')}
                    >
                      {this.props.t('edit')}
                    </ButtonWithLoader>
                  </NoAccessTooltip>
                )}
                {(annotateStage === AnnotateStage.ANNOTATED || annotateStage === AnnotateStage.REVIEWED) && !hasUnansweredClarification && (
                  <NoAccessTooltip hasAccess={this.props.freeAccessPermissions.canReAnnotate()}>
                    <ButtonWithLoader
                      loaderKey="free-access-action-button"
                      id="re-annotate-button"
                      color="primary"
                      disabled={!!lockedByName || !canAddAnnotations || isImportedAnnotationInDraft}
                      onClick={onReAnnotate}
                      title={isImportedAnnotationInDraft ? isImportedAnnotationInDraftTooltip : this.props.t('annotate_again_tooltip')}
                    >
                      {this.props.t('annotate_again')}
                    </ButtonWithLoader>
                  </NoAccessTooltip>
                )}
                {!hasUnansweredClarification && (
                  <NoAccessTooltip hasAccess={this.props.freeAccessPermissions.canChangeMode()}>
                    <ButtonWithLoader
                      loaderKey="free-access-action-button"
                      id="change-mode-button"
                      color="primary"
                      disabled={!!lockedByName || (annotateStage === AnnotateStage.NOTANNOTATED && !canAddAnnotations) || isImportedAnnotationInDraft}
                      onClick={changeMode}
                      title={isImportedAnnotationInDraft ? isImportedAnnotationInDraftTooltip : startButtonTooltip}
                    >
                      {startButtonText}
                    </ButtonWithLoader>
                  </NoAccessTooltip>
                )}
                <ClarifyButtonContainer />
              </Col>
            </Row>
          )}
        </Container>
      </Loader>
    );
  }
}

export const FreeAccessActionsContainer = as<React.ComponentClass>(withRouter(withNamespaces('annotation')(FreeAccessActionsContainerPure)));
