import * as React from 'react';

import { Card, CardBody, NavLink } from 'reactstrap';

import { ExternalLinks } from '../../../routes/config/ExternalLinks';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

const AuthLinkCardTranslated = withNamespaces('auth')((props: ITranslatable) => (
  <div className="auth-privacy-policy-card italic">
    <Card>
      <CardBody>
        <p>{props.t('admin_info')}</p>
        <p>
          {props.t('more_info')}
          <NavLink className="styled_link" href={ExternalLinks.Regulations.PrivacyPolicy.Path} target="_blank">
            {props.t(props.t('privacy_policy'))}
          </NavLink>
        </p>
      </CardBody>
    </Card>
  </div>
));

export const AuthPrivacyPolicyCard = as<React.SFC>(AuthLinkCardTranslated);
