import * as React from 'react';

import { Button, CustomInput, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Trans, withNamespaces } from 'react-i18next';

import { Bold } from '../../../../../components/BoldText';
import { ButtonWithLoader } from '../../../../../components/ButtonWithLoader';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { ToolDeleteStrategy } from '../../../projectDetails.models';
import { as } from '../../../../../helpers/react.helpers';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faCircleNotch);

interface IToolDeleteModalProps {
  showModal: boolean;
  strategy: ToolDeleteStrategy;
  onConfirm(): void;
  onCancel(): void;
  onStrategyChange(strategy: ToolDeleteStrategy): void;
  toolType: string;
  annotationsCount: number;
  objectsCount: number;
}

class ToolDeleteModalPure extends React.Component<IToolDeleteModalProps & ITranslatable> {
  onStrategyChange = (data: ToolDeleteStrategy) => () => {
    this.props.onStrategyChange(data);
  };

  render() {
    return (
      <Modal className="modal-width-600" isOpen={this.props.showModal} toggle={this.props.onCancel}>
        <ModalHeader toggle={this.props.onCancel}>{this.props.t('confirm_delete')}</ModalHeader>
        <ModalBody>
          <div>
            <p className="mt-2">
              {
                <Trans
                  i18nKey={'tool_usage'}
                  count={this.props.annotationsCount}
                  values={{ toolType: this.props.toolType, annotationsCount: this.props.annotationsCount.toString(), objectsCount: this.props.objectsCount.toString() }}
                  components={[Bold]}
                />
              }
            </p>
            <p className="mt-2">
              {
                <Trans
                  i18nKey={'tool_usage_warning'}
                  count={this.props.objectsCount}
                  values={{ toolType: this.props.toolType, annotationsCount: this.props.annotationsCount.toString(), objectsCount: this.props.objectsCount.toString() }}
                  components={[Bold]}
                />
              }
            </p>
            <div className="delete-tool-annotation-options">
              <FormGroup check>
                <CustomInput
                  type="radio"
                  id="option-leave"
                  onChange={this.onStrategyChange(ToolDeleteStrategy.Leave)}
                  checked={this.props.strategy === ToolDeleteStrategy.Leave}
                  label={this.props.t('tool_delete_strategy_leave')}
                  className="mt-2"
                />
              </FormGroup>
              <FormGroup check>
                <CustomInput
                  type="radio"
                  id="option-review"
                  onChange={this.onStrategyChange(ToolDeleteStrategy.MoveToReview)}
                  checked={this.props.strategy === ToolDeleteStrategy.MoveToReview}
                  label={this.props.t('tool_delete_strategy_review')}
                  className="mt-2"
                />
              </FormGroup>
              <FormGroup check>
                <CustomInput
                  type="radio"
                  id="option-draft"
                  onChange={this.onStrategyChange(ToolDeleteStrategy.MoveToDraft)}
                  checked={this.props.strategy === ToolDeleteStrategy.MoveToDraft}
                  label={this.props.t('tool_delete_strategy_draft')}
                  className="mt-2"
                />
              </FormGroup>
              <FormGroup check>
                <CustomInput
                  type="radio"
                  id="option-discard"
                  onChange={this.onStrategyChange(ToolDeleteStrategy.Discard)}
                  checked={this.props.strategy === ToolDeleteStrategy.Discard}
                  label={this.props.t('tool_delete_strategy_discard')}
                  className="mt-2"
                />
              </FormGroup>
            </div>
            <p>{this.props.t('tool_delete_continue')}</p>
          </div>
        </ModalBody>
        <ModalFooter>
          <ButtonWithLoader
            className="mr-2"
            color="warning"
            onClick={this.props.onConfirm}
            loaderKey="tool-delete-confirm-button"
            disabled={this.props.strategy === ToolDeleteStrategy.None}
          >
            {this.props.t('delete')}
          </ButtonWithLoader>
          <Button outline color="primary" onClick={this.props.onCancel}>
            {this.props.t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export const ToolDeleteModal = as<React.StatelessComponent<IToolDeleteModalProps>>(withNamespaces('project', { wait: true })(ToolDeleteModalPure));
