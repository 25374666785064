import { DrawPolygon } from '../components/DrawControl.models/Draw.polygon';
import { IUndoRedoCommand } from './undoRedoCommand';
import { LatLng } from 'leaflet';

export class AddPolygonVertexCommand implements IUndoRedoCommand {
  private readonly polygon: DrawPolygon;
  private readonly latlng: LatLng;

  constructor(
    polygon: DrawPolygon,
    latlng: LatLng,
  ) {
    this.polygon = polygon;
    this.latlng = latlng;
  }

  executeUndo() {
    if (this.polygon.getMarkersCount() > 1) {
      this.polygon.deleteLastVertex();
    } else {
      this.polygon.disable();
      this.polygon.enable();
    }
  }

  executeRedo(): void {
    this.polygon.addVertex(this.latlng);
  }
}
