import * as React from 'react';

import { Home } from '../config/Home';
import { NotFoundView } from '../../views/NotFound.view';
import { ProjectDetailsActivityLogView } from '../../views/home/projectDetails/ProjectDetailsActivityLog.view';
import { ProjectDetailsAnnotationToolsView } from '../../views/home/projectDetails/ProjectDetailsAnnotationTools.view';
import { ProjectDetailsCollaboratorsView } from '../../views/home/projectDetails/ProjectDetailsCollaborators.view';
import { ProjectDetailsDatasetsView } from '../../views/home/projectDetails/ProjectDetailsDatasets.view';
import { ProjectDetailsExportDataView } from '../../views/home/projectDetails/ProjectDetailsExportData.view';
import { ProjectDetailsImagesView } from '../../views/home/projectDetails/ProjectDetailsImages.view';
import { ProjectDetailsOverviewView } from '../../views/home/projectDetails/ProjectDetailsOverview.view';
import { ProjectDetailsStatisticsView } from '../../views/home/projectDetails/ProjectDetailsStatistics.view';
import { Route } from 'react-router';
import { SwitchWithNotFound } from '../../containers/route.containers';

export const ProjectDetailsRoute = () => (
  <SwitchWithNotFound notFound={NotFoundView}>
    <Route path={Home.Projects.Details.Overview.Path} component={ProjectDetailsOverviewView} />
    <Route path={Home.Projects.Details.Datasets.Path} component={ProjectDetailsDatasetsView} />
    <Route path={Home.Projects.Details.Images.Path} component={ProjectDetailsImagesView} />
    <Route path={Home.Projects.Details.TeamMembers.Path} component={ProjectDetailsCollaboratorsView} />
    <Route path={Home.Projects.Details.Statistics.Path} component={ProjectDetailsStatisticsView} />
    <Route path={Home.Projects.Details.ExportData.Path} component={ProjectDetailsExportDataView} />
    <Route path={Home.Projects.Details.AnnotationView.Path} component={ProjectDetailsAnnotationToolsView} />
    <Route path={Home.Projects.Details.ActivityLog.Path} component={ProjectDetailsActivityLogView} />
  </SwitchWithNotFound>
);
