import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
  onClick?(e: any): void;
  icon: any;
  color?: string;
  float?: string;
  title?: string;
  disabled?: boolean;
  className?: string;
  tabIndex?: number;
}

export const IconedButton = (props: IProps) => (
  <button
    className={`btn btn-icon ${props.className} ${props.color} ${props.float} ${props.disabled ? 'icon-disabled' : ''}`}
    onClick={props.onClick}
    title={props.title}
    disabled={props.disabled}
    tabIndex={props.tabIndex || 0}
  >
    <FontAwesomeIcon icon={props.icon} />
  </button>
);
