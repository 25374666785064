import { Col, Row } from 'reactstrap';

import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IUrlsProcessingSummaryProps {
  failedFiles: string[];
  uploadedCount: number;
}

const AttachmentFailedFilesSummary = (props: IUrlsProcessingSummaryProps & ITranslatable) => (
  <div>
    {props.uploadedCount > 0 && <p>{props.t('upload_of_x_files_completed', { count: props.uploadedCount })}</p>}
    {props.t('failed_files')}
    <div>
      <Row>
        <Col sm={12}>
          <ul>
            {props.failedFiles.map((u, i) => (
              <li key={i}>{u}</li>
            ))}
          </ul>
        </Col>
      </Row>
    </div>
  </div>
);

export default as<React.SFC<IUrlsProcessingSummaryProps>>(withNamespaces('common')(AttachmentFailedFilesSummary));
