import { IImageFilters, IImageFiltersData } from '../../../imageFilters.model';

import { FiltersDropdownRow } from '../FiltersDropdownRow';
import { ITranslatable } from '../../../../../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../../../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IReviewedByFilterInfoProps {
  availableFilters: IImageFiltersData;
  imageFilters: IImageFilters;
}

class ReviewedByFilterInfoPure extends React.Component<IReviewedByFilterInfoProps & ITranslatable> {
  render() {
    const selectedOptions = this.props.availableFilters.reviewedBy
      .slice()
      .sort((a, b) => (a.email > b.email ? 1 : -1))
      .filter(d => this.props.imageFilters.reviewedBy.includes(d.id));

    return (
      <FiltersDropdownRow label={this.props.t('image_filters.reviewedBy')} count={selectedOptions.length}>
        <>{selectedOptions.map(d => <div key={d.id}>{d.email}</div>)}</>
      </FiltersDropdownRow>);
  }
}

export const ReviewedByFilterInfo = as<React.ComponentClass<IReviewedByFilterInfoProps>>(withNamespaces('project')(ReviewedByFilterInfoPure));
