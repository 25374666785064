import { Home } from '../config/Home';
import { NotFoundView } from '../../views/NotFound.view';
import React from 'react';
import { Route } from 'react-router';
import { SwitchWithNotFound } from '../../containers/route.containers';
import { EvaluationDetailsCreateView, EvaluationDetailsView } from '../../views/home/evaluationDetails/evaluationDetails.view';
import { EvaluationsView } from '../../views/home/Evaluations.view';
import { predictionImagesPreviewEvaluationsView } from '../../components/predictionImages/predictionImagesPreview.view';

export const EvaluationsRoute = () => (
  <SwitchWithNotFound notFound={NotFoundView}>
    <Route path={Home.Evaluations.List.Path} component={EvaluationsView} />
    <Route path={Home.Evaluations.Create.Path} component={EvaluationDetailsCreateView} />
    <Route path={Home.Evaluations.Details.ImageDetails.Path} component={predictionImagesPreviewEvaluationsView} />
    <Route path={Home.Evaluations.Details.Path} component={EvaluationDetailsView} />
  </SwitchWithNotFound>
);
