import leaflet, { DomUtil, LatLng } from 'leaflet';

import { AnnotationToolType } from '../../submodules/annotationTypes/models/annotationToolType';

export class EditVertexMarker extends leaflet.Editable.VertexMarker {

  initialize(latlng: LatLng, latlngs: LatLng[], editor: any, options: any) {
    const opt = options || {};
    opt.className = 'leaflet-div-icon leaflet-vertex-icon custom-vertex';
    opt.keyboard = false;
    super.initialize(latlng, latlngs, editor, opt);
  }

  onDragStart(e: any) {
    DomUtil.addClass(this._icon, 'active');
    super.onDragStart(e);
  }

  onDrag(e: any) {
    this.setLatLng(e.latlng);

    if (this.isRotatedRectangle()) {
      e.vertex = this;
      this.editor.onVertexMarkerDrag(e);
      this.latlng = e.vertex.latlng;
      leaflet.DomUtil.setPosition(this._icon, this._map.latLngToLayerPoint(this.latlng));
      this._latlng = this.latlng;
      this.editor.refresh();
    } else {
      leaflet.DomUtil.setPosition(this._icon, this._map.latLngToLayerPoint(e.latlng));
      super.onDrag(e);
    }
  }

  isRotatedRectangle() {
    return this.editor.feature.options.type === AnnotationToolType.ROTATEDRECTANGLE;
  }
}
