import { injectable, inject } from 'inversify';
import { StickerError } from '../../../__legacy__/models/error.model';

import { ApiServiceType } from '../../../__legacy__/services/api.service';
import { IApiService } from '../../../__legacy__/services/api.service.base';
import {
  EditAndAcceptAnnotationRequest,
  EditAndRejectAnnotationRequest,
  IAcceptAnnotationRequest,
  ICorrectAndSubmitAnnotationForReviewRequest,
  ICorrectAnnotationRequest,
  ISaveAnnotationDraftRequest,
  IEditAnnotationRequest,
  IRejectAnnotationRequest,
  ISubmitAnnotationForReviewRequest,
  IRequestClarificationDuringAnnotationRequest,
  IRequestClarificationDuringFreeAccessEditRequest,
  IRequestClarificationDuringReviewRequest,
  IDiscardAnnotationDuringAnnotationRequest,
  IDiscardAnnotationDuringFreeAccessEditRequest,
  IDiscardAnnotationDuringReviewRequest,
  IRequestClarificationDuringReviewEditRequest,
  IDiscardAnnotationDuringReviewEditRequest,
} from '../models/Requests';

export const FreeAccessAnnotationApiServiceType = Symbol('FREE_ACCESS_ANNOTATION_API_SERVICE');

export interface IFreeAccessAnnotationApiService {
  acceptAsync(request: IAcceptAnnotationRequest): Promise<void | StickerError>;
  rejectAsync(request: IRejectAnnotationRequest): Promise<void | StickerError>;

  editAndAcceptAsync(request: EditAndAcceptAnnotationRequest): Promise<void | StickerError>;
  editAndRejectAsync(request: EditAndRejectAnnotationRequest): Promise<void | StickerError>;

  discardDuringAnnotationAsync(request: IDiscardAnnotationDuringAnnotationRequest): Promise<void | StickerError>;
  discardDuringReviewAsync(request: IDiscardAnnotationDuringReviewRequest): Promise<void | StickerError>;
  discardDuringReviewEditAsync(request: IDiscardAnnotationDuringReviewEditRequest): Promise<void | StickerError>;
  discardDuringFreeAccessEditAsync(request: IDiscardAnnotationDuringFreeAccessEditRequest): Promise<void | StickerError>;

  submitForReviewAsync(request: ISubmitAnnotationForReviewRequest): Promise<void | StickerError>;
  editAsync(request: IEditAnnotationRequest): Promise<void | StickerError>;
  correctAsync(request: ICorrectAnnotationRequest): Promise<void | StickerError>;
  correctAndSubmitForReviewAsync(request: ICorrectAndSubmitAnnotationForReviewRequest): Promise<void | StickerError>;
  saveDraftAsync(request: ISaveAnnotationDraftRequest): Promise<void | StickerError>;

  requestClarificationDuringAnnotationAsync(request: IRequestClarificationDuringAnnotationRequest): Promise<void | StickerError>;
  requestClarificationDuringReviewAsync(request: IRequestClarificationDuringReviewRequest): Promise<void | StickerError>;
  requestClarificationDuringReviewEditAsync(request: IRequestClarificationDuringReviewEditRequest): Promise<void | StickerError>;
  requestClarificationDuringEditAsync(request: IRequestClarificationDuringFreeAccessEditRequest): Promise<void | StickerError>;
}

@injectable()
export class FreeAccessAnnotationApiService implements IFreeAccessAnnotationApiService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService) {}

  acceptAsync = async (request: IAcceptAnnotationRequest): Promise<void | StickerError> => await this.apiService.postAsync('/annotation/freeAccess/Accept', request);

  rejectAsync = async (request: IRejectAnnotationRequest): Promise<void | StickerError> => await this.apiService.postAsync('/annotation/freeAccess/Reject', request);

  editAndAcceptAsync = async (request: EditAndAcceptAnnotationRequest): Promise<void | StickerError> =>
    await this.apiService.postAsync('/annotation/freeAccess/EditAndAccept', request);

  editAndRejectAsync = async (request: EditAndRejectAnnotationRequest): Promise<void | StickerError> =>
    await this.apiService.postAsync('/annotation/freeAccess/EditAndReject', request);

  discardDuringAnnotationAsync = async (request: IDiscardAnnotationDuringAnnotationRequest): Promise<void | StickerError> =>
    await this.apiService.postAsync('/annotation/freeAccess/DiscardDuringAnnotation', request);

  discardDuringReviewAsync = async (request: IDiscardAnnotationDuringReviewRequest): Promise<void | StickerError> =>
    await this.apiService.postAsync('/annotation/freeAccess/DiscardDuringReview', request);

    discardDuringReviewEditAsync = async (request: IDiscardAnnotationDuringReviewEditRequest): Promise<void | StickerError> =>
    await this.apiService.postAsync('/annotation/freeAccess/DiscardDuringReviewEdit', request);

  discardDuringFreeAccessEditAsync = async (request: IDiscardAnnotationDuringFreeAccessEditRequest): Promise<void | StickerError> =>
    await this.apiService.postAsync('/annotation/freeAccess/DiscardDuringFreeAccessEdit', request);

  submitForReviewAsync = async (request: ISubmitAnnotationForReviewRequest): Promise<void | StickerError> =>
    await this.apiService.postAsync('/annotation/freeAccess/SubmitForReview', request);

  saveDraftAsync = async (request: ISaveAnnotationDraftRequest): Promise<void | StickerError> => await this.apiService.postAsync('/annotation/freeAccess/SaveDraft', request);

  editAsync = async (request: IEditAnnotationRequest): Promise<void | StickerError> => await this.apiService.postAsync('/annotation/freeAccess/Edit', request);

  correctAsync = async (request: ICorrectAnnotationRequest): Promise<void | StickerError> => await this.apiService.postAsync('/annotation/freeAccess/Correct', request);

  correctAndSubmitForReviewAsync = async (request: ICorrectAndSubmitAnnotationForReviewRequest): Promise<void | StickerError> =>
    await this.apiService.postAsync('/annotation/freeAccess/CorrectAndSubmitForReview', request);

  requestClarificationDuringAnnotationAsync = async (request: IRequestClarificationDuringAnnotationRequest): Promise<void | StickerError> =>
    await this.apiService.postAsync('/annotation/freeAccess/RequestClarificationDuringAnnotation', request);

  requestClarificationDuringReviewAsync = async (request: IRequestClarificationDuringReviewRequest): Promise<void | StickerError> =>
    await this.apiService.postAsync('/annotation/freeAccess/RequestClarificationDuringReview', request);

    requestClarificationDuringReviewEditAsync = async (request: IRequestClarificationDuringReviewEditRequest): Promise<void | StickerError> =>
    await this.apiService.postAsync('/annotation/freeAccess/RequestClarificationDuringReviewEdit', request);

  requestClarificationDuringEditAsync = async (request: IRequestClarificationDuringFreeAccessEditRequest): Promise<void | StickerError> =>
    await this.apiService.postAsync('/annotation/freeAccess/RequestClarificationDuringFreeAccessEdit', request);
}
