import { action, observable } from 'mobx';

import { InputStatus } from '../../models/error.model';

export const ImageScopeName = 'Image';

export enum QuestionType {
  TRUE_FALSE = 'TrueFalse',
  ALTERNATIVE = 'Alternative',
  MULTISELECT = 'MultiSelect',
  OPEN = 'Open',
  NUMBER = 'Number',
  STATUS_PASS_FAIL = 'StatusPassFail',
  SET_TRAIN_TEST = 'SetTrainTest',
}

export interface IAnswerModel {
  id: string;
  text: string;
  selected: boolean;
}

export interface IAnswerProps {
  name: string;
  answer: IAnswerModel;
  disabled: boolean;
  onAnswered(answerId: string): void;
}

export interface ITextAnswerProps {
  answer: string;
  disabled: boolean;
  className: string;
  onAnswered(answer: string): void;
}

export interface IQuestionModel {
  projectId: string;
  id: string;
  type: QuestionType;
  isRequired: boolean;
  text: string;
  answer?: string;
  answers: IAnswerModel[];
  scopes: string[];
  status: InputStatus;
  onAnswered(answerId: string): void;
  validate(): boolean;
  resetStatus(): void;
  isAttribute(): boolean;
}

export class QuestionModel implements IQuestionModel {
  @observable
  projectId: string;
  @observable
  id: string;
  @observable
  type: QuestionType;
  @observable
  isRequired: boolean;
  @observable
  text: string = '';
  @observable
  answer?: string;
  @observable
  answers: IAnswerModel[] = [];
  @observable
  status: InputStatus;
  @observable
  scopes: string[];

  constructor(projectId: string, id: string, type: QuestionType, isRequired: boolean, text: string, answers: IAnswerModel[], scopes: string[], answer?: string) {
    this.projectId = projectId;
    this.id = id;
    this.type = type;
    this.isRequired = isRequired;
    this.text = text;
    this.answer = answer;
    this.answers = answers;
    this.status = InputStatus.empty();
    this.scopes = scopes;
  }

  isAttribute(): boolean {
    return this.scopes[0] !== 'Image';
  }

  @action
  onAnswered(answer: string) {
    this.status = InputStatus.empty();

    switch (this.type) {
      case QuestionType.TRUE_FALSE:
      case QuestionType.ALTERNATIVE:
      case QuestionType.STATUS_PASS_FAIL:
      case QuestionType.SET_TRAIN_TEST:
        const selectedAlternativeAnswer = this.findAnswer(answer);
        this.answers.filter(x => x.id !== selectedAlternativeAnswer.id).forEach(x => (x.selected = false));
        selectedAlternativeAnswer.selected = !selectedAlternativeAnswer.selected;
        break;
      case QuestionType.MULTISELECT:
        const selectedAnswer = this.findAnswer(answer);
        selectedAnswer.selected = !selectedAnswer.selected;
        break;
      case QuestionType.OPEN:
      case QuestionType.NUMBER:
        this.answer = answer;
        break;
      default:
        throw new Error(`Question type not found: ${this.type}`);
    }
  }

  private findAnswer(answerId: string) {
    const answer = this.answers.find(a => a.id === answerId);
    if (!answer) throw new Error(`Answer not found: ${answerId}`);
    return answer;
  }

  @action
  validate(): boolean {
    this.status = InputStatus.buildFrom(this.isAnswered ? [] : ['annotation:required_answer_error']);

    return this.status.isValid as boolean;
  }

  @action
  resetStatus() {
    this.status = InputStatus.buildFrom([]);
  }

  get isAnswered(): boolean {
    return !this.isRequired || this.answers.some(a => a.selected) || (isTextQuestion(this.type) && this.answer !== undefined && this.answer !== null && this.answer.trim() !== '');
  }
}

export function isTextQuestion(questionType: QuestionType): boolean {
  return questionType === QuestionType.OPEN || questionType === QuestionType.NUMBER;
}
