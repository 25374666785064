import * as React from 'react';

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { as, injectProps } from '../helpers/react.helpers';

import { DecryptionScriptSampleModal } from '../components/DecryptionScriptSampleModal';
import { ITranslatable } from '../helpers/translations.helpers';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

interface IState {
  showModal: boolean;
}

type Props = IInjectedProps & ITranslatable;

@injectProps({
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
@observer
class DecryptionScriptSampleContainerPure extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
  }

  state = {
    showModal: false,
  };

  @autobind
  toggleModal() {
    this.setState((prevState: IState) => ({
      ...prevState,
      showModal: !prevState.showModal,
    }));
  }

  render() {
    const isEncrypted = this.props.currentWorkspaceStore.currentWorkspace?.encryption.encrypted || false;

    return (
      <>
        {isEncrypted &&
          <>
            <a className="styled_link" onClick={this.toggleModal} href="javascript:void(0)">{this.props.t('how_to_decrypt_files')}</a>
            <DecryptionScriptSampleModal
              showDecryptionScriptSampleModal={this.state.showModal}
              onDecryptionScriptSampleModalCancel={this.toggleModal}
            />
          </>}
      </>
    );
  }
}

export const DecryptionScriptSampleContainer = as<React.ComponentClass>(withNamespaces('common', { wait: true })(DecryptionScriptSampleContainerPure));
