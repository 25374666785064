import { ConfigurationType, IConfiguration } from '../../../configuration';
import { as, injectProps } from '../../helpers/react.helpers';

import { ITranslatable } from '../../helpers/translations.helpers';
import React from 'react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  configuration: IConfiguration;
}

@injectProps({
  configuration: ConfigurationType,
})
class LockedContainer extends React.Component<IInjectedProps & ITranslatable> {
  render() {
    const supportEmail = this.props.configuration.appConfig!.supportEmailAddress;

    return (
      <div>
        <h3>{this.props.t('locked_account')}<a href={`mailto:${supportEmail}`}>{supportEmail}</a>!</h3>
      </div>
    );
  }
}

export default as<React.ComponentClass>(withNamespaces('common')(LockedContainer));
