import { AttachmentsPermissionsType, IAttachmentsPermissions } from '../../../Attachments.permissions';
import { IRenameAttachmentStore, RenameAttachmentStoreType } from '../../RenameAttachment.store';
import { RenameAttachmentBl, RenameAttachmentBlType } from '../../RenameAttachment.bl';
import { as, injectProps } from '../../../../../__legacy__/helpers/react.helpers';

import React from 'react';
import { RenameAttachmentDropdownItem } from './RenameAttachmentDropdownItem';
import { observer } from 'mobx-react';

interface IProps {
  id: string;
  name: string;
  parentId: string | undefined;
}

interface InjectedProps {
  bl: RenameAttachmentBl;
  store: IRenameAttachmentStore;
  permissions: IAttachmentsPermissions;
}
@injectProps({
  bl: RenameAttachmentBlType,
  store: RenameAttachmentStoreType,
  permissions: AttachmentsPermissionsType,
})
@observer
class RenameAttachmentDropdownItemContainerPure extends React.Component<IProps & InjectedProps> {
  handleClick = () => {
    this.props.bl.initialize(this.props.id, this.props.name, this.props.parentId);
    this.props.bl.showModal();
  };

  render(): React.ReactNode {
    return <RenameAttachmentDropdownItem onClick={this.handleClick} disabled={!this.props.permissions.canRenameAttachment()} />;
  }
}

export const RenameAttachmentDropdownItemContainer = as<React.ComponentClass<IProps>>(RenameAttachmentDropdownItemContainerPure);
