import { AnnotationCommand } from './annotationCommand';
import { IAnnotationService } from '../annotation.service';
import { IAnnotationsStore } from '../annotations.store';
import { IFreeDrawSegmentationService } from '../freeDrawSegmentation.service';
import { ISegmentation } from '../annotations.interface';
import { action } from 'mobx';
import { cloneDeep } from 'lodash';

export class ChangeStateCommand extends AnnotationCommand {
  private readonly stateBefore: ISegmentation[];
  private readonly stateAfter: ISegmentation[];
  private readonly applyState: (segmentations: ISegmentation[]) => void;

  constructor(
    annotationService: IAnnotationService,
    annotationsStore: IAnnotationsStore,
    freeDrawSegmentationService: IFreeDrawSegmentationService,
    stateBefore: ISegmentation[],
    stateAfter: ISegmentation[],
    applyState: (segmentations: ISegmentation[]) => void,
  ) {
    super(annotationService, annotationsStore, freeDrawSegmentationService);
    this.stateBefore = cloneDeep(stateBefore);
    this.stateAfter = cloneDeep(stateAfter);
    this.applyState = applyState;
  }

  @action
  executeUndo(): void {
    super.executeUndo();
    this.applyState(cloneDeep(this.stateBefore));
  }

  @action
  executeRedo(): void {
    super.executeRedo();
    this.applyState(cloneDeep(this.stateAfter));
  }
}
