import * as React from 'react';

import { CustomCRS } from './DrawControl.models/CustomCRS';
import CustomImageOverlay from './CustomImageOverlay';
import L, { Point } from 'leaflet';
import { Loader } from '../../../components/Loader';
import { MOUSE_BUTTONS } from '../annotations.interface';
import { MapEx } from './DrawControl.models/MapEx';
import { ProgressBar } from '../../../components/ProgressBar';
import { as } from '../../../helpers/react.helpers';
import is from 'is_js';
import { observer } from 'mobx-react';
import { scaleToZoom, zoomToScale } from '../../../helpers/geometry/mapExtensions.helpers';

interface ExtendedLayerEvnt extends L.LayerEvent {
  sourceTarget: any;
}

export interface IImageComponentProps {
  height: number;
  width: number;
  url: string;
  lowQualityUrl: string;
  isLoading: boolean;
  isHighResImageLoading: boolean;
  zoomLevel: number;
  minZoomLevel: number;
  maxZoomLevel: number;
  isLeftMenuOpened: boolean;
  disableZoom?: boolean;
  onZoomChanged(zoom: number): void;
  onLowResImageLoaded(): void;
  showHeatmap?: boolean;
  heatmapUrl?: string;
}

@observer
export class ImagePure extends React.Component<IImageComponentProps> {
  constructor(props: IImageComponentProps) {
    super(props);
  }

  handleLowResOverlayLoad = (event: ExtendedLayerEvnt): void => {
    const scale = event.target._map._zoom as number;
    this.props.onZoomChanged(scaleToZoom(scale));
    this.props.onLowResImageLoaded();
  };

  handleMapZoom = (event: L.LeafletEvent): void => {
    const scale = event.target._zoom as number;
    this.props.onZoomChanged(scaleToZoom(scale));
  };

  render() {
    let imageClassName = '';
    if (this.props.zoomLevel > 100) {
      if (is.firefox()) {
        imageClassName = 'crisp-edges';
      } else {
        imageClassName = 'pixelated';
      }
    }

    return (
      <div className="map-wrapper">
        <Loader isLoading={this.props.isLoading} solid>
          <MapEx
            imageCenterPaddingTopLeft={this.props.isLeftMenuOpened ? new Point(324, 0) : undefined}
            keyboard={false}
            drawControlTooltips={false}
            editable={true}
            draggingButtons={MOUSE_BUTTONS.MIDDLE}
            zoomAnimation={false}
            boxZoom={false}
            fadeAnimation={false}
            animate={false}
            duration={0}
            crs={CustomCRS}
            center={[0, 0]}
            zoomControl={false}
            zoom={zoomToScale(this.props.zoomLevel)}
            minZoom={zoomToScale(this.props.minZoomLevel)}
            maxZoom={zoomToScale(this.props.maxZoomLevel)}
            zoomSnap={0.001}
            onzoomend={this.handleMapZoom}
            bounds={[
              [-18, -18],
              [this.props.height + 18, this.props.width + 18],
            ]}
            maxBounds={[
              [-this.props.height, -this.props.width],
              [this.props.height * 2, this.props.width * 2],
            ]}
            doubleClickZoom={false}
            scrollWheelZoom={!this.props.disableZoom}
            touchZoom={!this.props.disableZoom}
            attributionControl={false}
          >
            {!this.props.isLoading && this.props.isHighResImageLoading && <ProgressBar enableStripesAnimation value={100} />}
            {this.props.lowQualityUrl !== '' && (
              <CustomImageOverlay
                url={this.props.lowQualityUrl}
                zIndex={2}
                bounds={[
                  [0, 0],
                  [this.props.height, this.props.width],
                ]}
                onload={this.handleLowResOverlayLoad}
              />
            )}
            {this.props.url !== '' && (
              <CustomImageOverlay
                className={imageClassName}
                url={this.props.url}
                zIndex={3}
                bounds={[
                  [0, 0],
                  [this.props.height, this.props.width],
                ]}
                onload={this.handleLowResOverlayLoad}
              />
            )}
            {this.props.showHeatmap && this.props.heatmapUrl !== '' && (
              <CustomImageOverlay
                className={imageClassName}
                url={this.props.heatmapUrl}
                zIndex={4}
                bounds={[
                  [0, 0],
                  [this.props.height, this.props.width],
                ]}
                onload={this.handleLowResOverlayLoad}
              />
            )}
            {this.props.children}
          </MapEx>
        </Loader>
      </div>
    );
  }
}

export default as<React.StatelessComponent<{ removeDamnedPanAnim?: boolean }>>(ImagePure);

export const Image = as<React.StatelessComponent<{ removeDamnedPanAnim?: boolean } & IImageComponentProps>>(ImagePure);
