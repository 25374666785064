import { IUndoRedoCommand } from './undoRedoCommand';
import { LatLng } from 'leaflet';

export interface IAddVerticesCommand extends IUndoRedoCommand {
  latlng: LatLng[];
  addVertex(latlng: LatLng): void;
  undoAction(): void;
  redoAction(latlng: LatLng): void;
}

export abstract class AddVerticesCommandBase implements IAddVerticesCommand {
  public latlng: LatLng[] = [];

  constructor(
    latlng: LatLng,
  ) {
    this.latlng.push(latlng);
  }

  addVertex(latlng: LatLng) {
    this.latlng.push(latlng);
  }

  executeUndo(): void {
    this.latlng.forEach(() => {
      this.undoAction();
    });
  }

  executeRedo(): void {
    this.latlng.forEach((value: LatLng) => {
      this.redoAction(value);
    });
  }

  abstract undoAction(): void;

  abstract redoAction(latlng: LatLng): void;
}
