import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { UpdateWorkspaceTimeZoneDropdownItemContainer } from '../sub/UpdateWorkspaceTimeZone/containers/UpdateWorkspaceTimeZoneDropdownItem.container';
import { as } from '../../../helpers/react.helpers';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { withNamespaces } from 'react-i18next';

interface IProps {
  moreOptionsDropdownOpened: boolean;
  isEncrypted: boolean;
  encryptionKeyUrl: string;
  workspaceName: string;
  toggleMoreOptions(): void;
  onRenameClicked(): void;
  onChangeOwnerClicked(): void;
  onDeleteClicked(): void;
}

const MoreWorkspaceOptionsPure = (props: IProps & ITranslatable) => {
  const { t } = props;
  return (
    <div className="workspace-more-options">
      <Dropdown isOpen={props.moreOptionsDropdownOpened} toggle={props.toggleMoreOptions}>
        <DropdownToggle className="width-50 more-options-button">
          <FontAwesomeIcon className="" icon="ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={props.onRenameClicked}>{t('rename_workspace')}</DropdownItem>
          <DropdownItem onClick={props.onChangeOwnerClicked}>{t('change_workspace_owner')}</DropdownItem>
          <UpdateWorkspaceTimeZoneDropdownItemContainer/>
          {props.isEncrypted && (
            <DropdownItem href={props.encryptionKeyUrl} download={`${props.workspaceName}.key`}>
              {t('download_key')}
            </DropdownItem>
          )}
          <DropdownItem divider />
          <DropdownItem onClick={props.onDeleteClicked} className="red">
            <FontAwesomeIcon icon={faTrashAlt} /> {props.t('delete_workspace')}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export const MoreWorkspaceOptions = as<React.SFC<IProps>>(withNamespaces('workspace')(MoreWorkspaceOptionsPure));
