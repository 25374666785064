import { ExportDataBlType, IExportDataBl } from '../../ExportData.bl';
import { ExportDataStore, ExportDataStoreType } from '../../ExportData.store';
import { as, injectProps } from '../../../../../../__legacy__/helpers/react.helpers';

import { ExportDataFilter } from '../../models/ExportDataFilter';
import { ExportDataForm } from './ExportDataForm';
import React from 'react';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: IExportDataBl;
  store: ExportDataStore;
}

@injectProps({
  bl: ExportDataBlType,
  store: ExportDataStoreType,
})
@observer
class ExportDataFormContainerPure extends React.Component<InjectedProps> {
  handleDataRangeChange = (dataFilter: ExportDataFilter) => {
    this.props.bl.selectDataFilter(dataFilter);
  };

  render() {
    return (
      <ExportDataForm selectedFilter={this.props.store.selectedDataFilter} onChangeDataRange={this.handleDataRangeChange} annotationsLastModifiedDate={this.props.store.lastAnnotationChange.toString()} />
    );
  }
}

export const ExportDataFormContainer = as<React.ComponentClass>(ExportDataFormContainerPure);
