import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router';
import { ModelDetailsHeader } from '../components/ModelDetailsHeader';
import { as, injectProps } from '../../../__legacy__/helpers/react.helpers';
import { observer } from 'mobx-react';
import { IModelDetailsService, ModelDetailsServiceType } from '../modelDetails.service';
import { ValueType } from 'react-select/lib/types';
import { SelectOption, VariantOption } from '../modelDetails.model';
import { WithLoaderComponentBase } from '../../../__legacy__/helpers/loader.helpers';
import { Loader } from '../../../__legacy__/components/Loader';
import { MODEL_STATUS } from '../../models/models.model';
import { withPolling } from '../../../__legacy__/helpers/polling.helpers';
import { ModelPermissions } from '../../../__legacy__/modules/workspaces/workspaces.store';
import { IWorkspaceService, WorkspaceServiceType } from '../../../__legacy__/modules/workspaces/workspaces.service';
import { observe } from 'mobx';

interface IInjectedProps extends RouteComponentProps<{ workspaceId: string; jobId?: string }> {
  modelDetailsService: IModelDetailsService;
  workspaceService: IWorkspaceService;
}

@injectProps({
  modelDetailsService: ModelDetailsServiceType,
  workspaceService: WorkspaceServiceType,
})
@observer
class ModelDetailsHeaderContainerPure extends WithLoaderComponentBase<IInjectedProps> {
  clearPolling?: () => void;
  disposeStatusObserver: () => void;

  constructor(props: IInjectedProps) {
    super(props);
    // Reset service to initial state to avoid stale data
    this.props.modelDetailsService.store.resetStore(this.isCreate());
    this.disposeStatusObserver = observe(this.props.modelDetailsService.store, 'status', ({ oldValue, newValue }) => {
      if (oldValue !== newValue && oldValue !== MODEL_STATUS.UNKNOWN && newValue === MODEL_STATUS.FAILED) {
        this.props.modelDetailsService.getModelDetailsAsync(this.props.match.params.jobId!);
      }
    });
    this.state = {
      isLoading: false,
    };
  }

  isCreate() {
    return this.props.match.params.jobId === undefined;
  }

  shouldRefresh(status: MODEL_STATUS) {
    return status === MODEL_STATUS.STARTING || status === MODEL_STATUS.RUNNING || status === MODEL_STATUS.STOPPING || status === MODEL_STATUS.FAILING;
  }

  async componentDidMount() {
    await this.withLoaderAsync(async () => {
      const isCreate = this.isCreate();
      const promises: Promise<any>[] = [];

      if (!isCreate) {
        promises.push(this.props.modelDetailsService.getModelDetailsAsync(this.props.match.params.jobId!));
      } else {
        promises.push(this.props.modelDetailsService.getModelProjectsAsync(), this.props.modelDetailsService.getModelSettingsSchemeAsync(isCreate));
      }

      await Promise.all(promises);
    });
  }

  async componentDidUpdate() {
    const status = this.props.modelDetailsService.store.status;
    // Initialize the retrieval of model information at the top of the tree so that other components can update accordingly.
    if (this.shouldRefresh(status) && this.clearPolling === undefined && !this.isCreate()) {
      this.clearPolling = withPolling(async () => {
        await this.props.modelDetailsService.getModelStatusAsync(this.props.match.params.jobId!);
      }, 10000);
    } else if (!this.shouldRefresh(status) && this.clearPolling !== undefined) {
      this.clearPolling();
      this.clearPolling = undefined;
    }
  }

  componentWillUnmount(): void {
    if (this.clearPolling !== undefined) this.clearPolling();
    this.disposeStatusObserver();
  }

  handleChangeProject = (value: ValueType<SelectOption>) => {
    this.props.modelDetailsService.handleChangeProject((value as SelectOption).value);
  };

  handleChangeVariant = (value: ValueType<SelectOption>) => {
    this.props.modelDetailsService.handleChangeVariant((value as VariantOption).value);
  };

  render() {
    const {
      store,
      handleChangeName,
      handleChangeDescription,
      startTrainingAsync,
      stopTrainingAsync,
      downloadModelAsync,
      handleBlurName,
      handleBlurProject,
      handleBlurDescription,
      handleBlurVariant,
    } = this.props.modelDetailsService;

    const currentWorkspace = this.props.match.params.workspaceId;
    const isCreate = this.isCreate();
    const { id, isOwner, name, description, projectId, variant, projects, status, startingProgress, modelDetailsValidationErrors, createdAt, failureReason } = store;
    const workspaceRole = this.props.workspaceService.getUserRoleInCurrentWorkspace();
    // If we are creating new training check for train permissions, otherwise check update permissions
    const isFormDisabled = isCreate
      ? !ModelPermissions.trainModel.includes(workspaceRole)
      : isOwner
      ? !ModelPermissions.updateModelWhenOwner.includes(workspaceRole)
      : !ModelPermissions.updateModel.includes(workspaceRole);

    return (
      <Loader isLoading={this.state.isLoading}>
        <ModelDetailsHeader
          isCreate={isCreate}
          isFormDisabled={isFormDisabled}
          isOwner={isOwner}
          name={name}
          description={description}
          projectId={projectId}
          status={status}
          startingProgress={startingProgress}
          failureReason={failureReason}
          variant={variant}
          projects={projects}
          onChangeName={handleChangeName}
          onChangeDescription={handleChangeDescription}
          onChangeProject={this.handleChangeProject}
          startTrainingAsync={startTrainingAsync}
          handleBlurName={handleBlurName}
          handleBlurProject={handleBlurProject}
          handleBlurDescription={handleBlurDescription}
          errors={modelDetailsValidationErrors}
          workspaceId={currentWorkspace}
          id={id}
          createdAt={createdAt}
          downloadModelAsync={downloadModelAsync}
          stopTrainingAsync={stopTrainingAsync}
          handleBlurVariant={handleBlurVariant}
          handleChangeVariant={this.handleChangeVariant}
        />
      </Loader>
    );
  }
}

export const ModelDetailsHeaderContainer = as<React.ComponentClass>(withRouter(ModelDetailsHeaderContainerPure));
