import { IApiService } from '../../../../../../../__legacy__/services/api.service.base';
import { StickerError } from '../../../../../../../__legacy__/models/error.model';
import { inject, injectable } from 'inversify';
import { ApiServiceType } from '../../../../../../../__legacy__/services/api.service';

export const EditMarkingToolApiServiceType = Symbol('EDIT_MARKING_TOOL_API_SERVICE');

export interface EditMarkingToolApiService  {
  editMarkingToolAsync(request: EditMarkingToolRequest): Promise<void>;
}

export class EditMarkingToolRequest {
  id: string;
  name: string;
  color: string;
  selectorType: string;

  constructor(name: string, id: string, color: string, selectorType: string) {
    this.id = id;
    this.name = name;
    this.color = color;
    this.selectorType = selectorType;
  }
}

@injectable()
export class EditMarkingToolApiServiceImpl implements EditMarkingToolApiService {
  constructor(@inject(ApiServiceType) readonly apiService: IApiService) {
  }

  async editMarkingToolAsync(request: EditMarkingToolRequest): Promise<void> {
    const result = await this.apiService.postAsync<EditMarkingToolRequest, void>('/AnnotationTypes/Update', request);

    if (result instanceof StickerError) {
      throw result;
    }
  }
}
