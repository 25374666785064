import { injectable, inject } from 'inversify';
import { IDatasetDraftsStore, DatasetDraftStoreType, IDatasetDraft } from './datasetDrafts.store';
import { action } from 'mobx';
import { v4 } from 'uuid';
import { ApiServiceType } from '../../services/api.service';
import { StickerError, InputStatus } from '../../models/error.model';
import { ImagesUploaderServiceType, IImagesUploaderService } from './imagesUpload.service';
import { ImagesFromUrlsServiceType, IImagesFromUrlsService } from './imagesFromUrlsUpload.service';
import { IApiService } from '../../services/api.service.base';
import { ICurrentWorkspaceStore, CurrentWorkspaceStoreType } from '../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';

export const CreateDatasetServiceType = Symbol('CREATE_DATASET_SERVICE');

export interface ICreateDatasetService {
  initNewDraft(): string;
  changeDraftNameAsync(name: string): void;
  changeDraftDescription(name: string): void;
  createDatasetFromDraftAsync(): Promise<void | StickerError>;
  datasetsDraftStore: IDatasetDraftsStore;
  changeDraftAuthor(name: string): void;
  changeDraftTermsOfUse(name: string): void;
}

@injectable()
export class CreateDatasetService implements ICreateDatasetService {
  constructor(
    @inject(DatasetDraftStoreType) readonly datasetsDraftStore: IDatasetDraftsStore,
    @inject(ApiServiceType) private readonly apiService: IApiService,
    @inject(ImagesUploaderServiceType) private readonly imagesService: IImagesUploaderService,
    @inject(ImagesFromUrlsServiceType) private readonly urlsService: IImagesFromUrlsService,
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore,
  ) {}

  @action
  initNewDraft(): string {
    const newDraft: IDatasetDraft = {
      ownerPlan: '',
      workspaceId: this.currentWorkspaceStore.currentWorkspace!.id,
      nameStatus: InputStatus.empty(),
      externalStatus: InputStatus.empty(),
      name: `Dataset ${new Date().toLocaleString()}`,
      id: v4(),
      description: '',
      images: [],
      imagesToUploadFromFile: [],
      imagesRejectedFromFile: [],
      imagesToUploadFromUrl: [],
      willExceedsUploadImageSizeLimit: false,
      imagePreValidationWasSuccessful: true,
      author: '',
      termsOfUse: '',
    };

    this.datasetsDraftStore.draft = newDraft;
    return newDraft.id;
  }

  @action.bound
  async changeDraftNameAsync(name: string) {
    const draft = this.datasetsDraftStore.draft;
    draft.name = name;
    draft.nameStatus = InputStatus.empty();
    if (name.trim() === '') {
      draft.nameStatus = InputStatus.buildFrom(['field_cant_be_empty']);
    } else {
      const url = '/datasets/CheckDatasetNameUniqueness';
      const params = {
        datasetId: draft.id,
        workspaceId: draft.workspaceId,
        name: draft.name,
      };
      const result = await this.apiService.getAsync<boolean | StickerError>(url, { params });
      if (result instanceof StickerError) {
        const errorCodes: string[] = !!result.apiErrorResponse ? result.apiErrorResponse.errorCodes : ['something_went_wrong'];
        draft.externalStatus = InputStatus.buildFrom(errorCodes);
      } else if (!result) {
        draft.nameStatus = InputStatus.buildFrom(['duplicate_dataset_name']);
      }
    }
  }

  @action.bound
  changeDraftDescription(description: string) {
    const draft = this.datasetsDraftStore.draft;
    draft.description = description;
  }

  @action.bound
  changeDraftAuthor(author: string) {
    const draft = this.datasetsDraftStore.draft;
    draft.author = author;
  }

  @action.bound
  changeDraftTermsOfUse(termsOfUse: string) {
    const draft = this.datasetsDraftStore.draft;
    draft.termsOfUse = termsOfUse;
  }

  @action.bound
  async createDatasetFromDraftAsync() {
    const url = '/datasets/create';
    const draft = this.datasetsDraftStore.draft;
    const result = await this.apiService.postAsync<any>(url, draft);
    if (result instanceof StickerError) {
      if (result.isBadRequestWithCode(['DUPLICATE_DATASET_NAME'])) {
        draft.nameStatus = InputStatus.buildFrom(['duplicate_dataset_name']);
      } else {
        const errorCodes: string[] = !!result.apiErrorResponse ? result.apiErrorResponse.errorCodes : ['something_went_wrong'];
        draft.externalStatus = InputStatus.buildFrom(errorCodes);
      }
      return result;
    }

    await this.uploadImagesToDraftAsync(draft.id);
  }

  @action
  async uploadImagesToDraftAsync(draftId: string) {
    const draft = this.datasetsDraftStore.draft;
    const validImagesFromUrl = draft.imagesToUploadFromUrl.filter(i => i.isValid);
    if (draft.imagesToUploadFromFile.length > 0) {
      await this.imagesService.addDatasetToQueueAsync(draft.imagesToUploadFromFile, draftId, draft.name);
    }
    if (validImagesFromUrl.length > 0) {
      await this.urlsService.addDatasetToQueueAsync(validImagesFromUrl, draftId, draft.name);
    }

    draft.imagesToUploadFromFile = [];
    draft.imagesToUploadFromUrl = [];
    draft.imagesRejectedFromFile = [];
  }
}
