import leaflet, { DomUtil } from 'leaflet';

import { DraggableEx } from './DraggableEx';
import { MOUSE_BUTTONS } from '../../annotations.interface';

export class DragEx extends leaflet.Map.Drag {

  addHooks() {
    if (this._draggable) {
      this._draggable.disable();
      this._draggable = null;
    }

    const map = this._map;
    this._draggable = new DraggableEx(map.options.draggingButtons, map._mapPane, map._container);
    this._draggable.on(
      {
        dragstart: this._onDragStart,
        drag: this._onDrag,
        dragend: this._onDragEnd,
      },
      this);
    this._draggable.on('predrag', this._onPreDragLimit, this);

    if (map.options.worldCopyJump) {
      this._draggable.on('predrag', this._onPreDragWrap, this);
      map.on('zoomend', this._onZoomEnd, this);
      map.whenReady(this._onZoomEnd, this);
    }

    if (map.options.draggingButtons & MOUSE_BUTTONS.LEFT) {
      DomUtil.addClass(this._map._container, 'leaflet-grab leaflet-touch-drag');
    }

    this._draggable.enable();
    this._positions = [];
    this._times = [];
  }
}
