import { AttachmentUploadService, AttachmentUploadServiceType, IAttachmentUploadService } from './attachmentUploadService';
import { AttachmentUploadStore, AttachmentUploadStoreType, IAttachmentUploadStore } from './attachmentUploadStore';
import { AttachmentsPreviewService, AttachmentsPreviewServiceType, IAttachmentsPreviewService } from './attachmentsPreview.service';
import { AttachmentsPreviewStore, AttachmentsPreviewStoreType, IAttachmentsPreviewStore } from './attachmentsPreview.store';
import { AttachmentsService, AttachmentsServiceType, IAttachmentsService } from './attachments.service';
import { AttachmentsStore, AttachmentsStoreType, IAttachmentsStore } from './attachments.store';
import { ContainerModule, interfaces } from 'inversify';

export const attachmentsModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IAttachmentUploadStore>(AttachmentUploadStoreType)
    .to(AttachmentUploadStore)
    .inSingletonScope();
  bind<IAttachmentUploadService>(AttachmentUploadServiceType)
    .to(AttachmentUploadService)
    .inSingletonScope();
  bind<IAttachmentsStore>(AttachmentsStoreType)
    .to(AttachmentsStore)
    .inSingletonScope();
  bind<IAttachmentsService>(AttachmentsServiceType)
    .to(AttachmentsService)
    .inSingletonScope();
  bind<IAttachmentsPreviewStore>(AttachmentsPreviewStoreType)
    .to(AttachmentsPreviewStore)
    .inSingletonScope();
  bind<IAttachmentsPreviewService>(AttachmentsPreviewServiceType)
    .to(AttachmentsPreviewService)
    .inSingletonScope();
});
