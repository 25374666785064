import { IImageFilters, IImageFiltersData } from '../../../imageFilters.model';

import { FiltersDropdownRow } from '../FiltersDropdownRow';
import { ISelectOption } from '../../../../../../../helpers/formHelpers';
import { ITranslatable } from '../../../../../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../../../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface ICorrectedFilterInfoProps {
  availableFilters: IImageFiltersData;
  imageFilters: IImageFilters;
}

class CorrectedFilterInfoPure extends React.Component<ICorrectedFilterInfoProps & ITranslatable> {
  render() {
    const options: ISelectOption<boolean>[] = [
      { label: this.props.t('yes'), value: true },
      { label: this.props.t('no'), value: false },
    ];
    const selectedOptions = options
      .filter(d => this.props.imageFilters.corrected.includes(d.value));

    return (
      <FiltersDropdownRow label={this.props.t('image_filters.corrected')} count={selectedOptions.length}>
        <>{selectedOptions.map(d => <div key={d.value.toString()}>{d.label}</div>)}</>
      </FiltersDropdownRow>);
  }
}

export const CorrectedFilterInfo = as<React.ComponentClass<ICorrectedFilterInfoProps>>(withNamespaces('project')(CorrectedFilterInfoPure));
