import autobind from 'autobind-decorator';
import { Location } from 'history';
import { inject, injectable } from 'inversify';
import { reaction } from 'mobx';
import { matchPath } from 'react-router-dom';
import { ErrorsServiceType, IErrorsService } from '../../../__legacy__/modules/errors/errors.service';
import { UserInfoLoadedEventType } from '../../../__legacy__/modules/user/events/userInfoLoadedEvent';
import { UserSettingsLoadedEventType } from '../../../__legacy__/modules/user/events/userSettingsLoadedEvent';
import { WorkspaceCreatedEvent, WorkspaceCreatedEventType } from '../../../__legacy__/modules/workspaces/events/workspaceCreatedEvent';
import { WorkspaceDecryptionFailedEventType } from '../../../__legacy__/modules/workspaces/events/workspaceDecryptionFailedEvent';
import { WorkspaceDeletedEventType } from '../../../__legacy__/modules/workspaces/events/workspaceDeletedEvent';
import { IWorkspace, IWorkspacesStore, WorkspacesStoreType } from '../../../__legacy__/modules/workspaces/workspaces.store';
import { Home } from '../../../__legacy__/routes/config/Home';
import { EventBusType, IEventBus } from '../../../__legacy__/services/eventBus.service';
import { RouterStoreType, IRouterStore } from '../../../__legacy__/stores/router.store';
import { ICurrentWorkspaceStoreSetter, CurrentWorkspaceStoreSetterType } from './CurrentWorkspace.store';
import { CurrentWorkspaceChangedEvent } from './events/CurrentWorkspaceChangedEvent';
import { CurrentWorkspaceNavigator, CurrentWorkspaceNavigatorType } from './services/navigator/CurrentWorkspaceNavigator';

export const CurrentWorkspaceBlType = Symbol('CURRENT_WORKSPACE_BL');

export interface CurrentWorkspaceBl {
  selectWorkspace: (id: string) => void;
  // This method is called when url is provided with workspace id for the first time.
  // I cannot test it with mobx reaction - that's why I'm making it public.
  // This should be changed to event approach in the future.
  workspaceProvidedInUrl: (id: string) => void;
}

@injectable()
@autobind
export class CurrentWorkspaceBlImpl implements CurrentWorkspaceBl {
  private workspaceIdFromUrl: string | undefined;

  public constructor(
    @inject(WorkspacesStoreType) private readonly workspacesStore: IWorkspacesStore,
    @inject(CurrentWorkspaceStoreSetterType) private readonly store: ICurrentWorkspaceStoreSetter,
    @inject(EventBusType) private readonly eventBus: IEventBus,
    @inject(RouterStoreType) private readonly routerStore: IRouterStore,
    @inject(ErrorsServiceType) private readonly errorsService: IErrorsService,
    @inject(CurrentWorkspaceNavigatorType) private readonly navigator: CurrentWorkspaceNavigator,
  ) {
    reaction(() => this.routerStore.location, this.locationChangedListener);
    eventBus.addListener(this.userInfoLoadedListener, UserInfoLoadedEventType);
    eventBus.addListener(this.userSettingsLoadedListener, UserSettingsLoadedEventType);
    eventBus.addListener(this.workspaceDeletedListener, WorkspaceDeletedEventType);
    eventBus.addListener(this.workspaceCreatedListener, WorkspaceCreatedEventType);
    eventBus.addListener(this.workspaceDecryptionFailedListener, WorkspaceDecryptionFailedEventType);
  }

  public workspaceProvidedInUrl(id: string): void {
    if (this.workspacesStore.workspaces.length === 0) {
      this.workspaceIdFromUrl = id;
      return;
    }

    if (this.store.currentWorkspace?.id === id) return;
    this.changeCurrentWorkspaceToGivenOrFirstAvailable(id);
  }

  public selectWorkspace(id: string): void {
    if (this.store.currentWorkspace?.id === id) return;
    const selectedWorkspace = this.workspacesStore.workspaces.find(w => w.id === id)!;
    if (selectedWorkspace.locked) return;
    this.setCurrentWorkspace(selectedWorkspace);
  }

  private locationChangedListener = (location: Location) => {
    const workspaceId = matchPath<{ workspaceId: string }>(location.pathname, Home.Path)?.params.workspaceId;
    if (!workspaceId) return;
    this.workspaceProvidedInUrl(workspaceId);
  };

  private userInfoLoadedListener = () => {
    this.updateCurrentWorkspace();

    if (this.workspaceIdFromUrl && !this.store.currentWorkspace) {
      const selectedWorkspace = this.workspacesStore.workspaces.find(w => w.id === this.workspaceIdFromUrl);
      if (selectedWorkspace) {
        this.setCurrentWorkspace(selectedWorkspace);
        return;
      }
      this.errorsService.setUnauthorized();
    }

    if (this.isCurrentWorkspaceAvailable) return;
    if (this.shouldWaitForUserSettingsToBeSet) return;

    this.changeCurrentWorkspaceToPreferredOrFirstAvailable();
  };

  private userSettingsLoadedListener = () => {
    if (this.workspacesStore.workspaces.length === 0 || this.workspaceIdFromUrl || this.store.currentWorkspace) return;
    this.changeCurrentWorkspaceToPreferredOrFirstAvailable();
  };

  private workspaceDeletedListener = () => {
    if (this.isCurrentWorkspaceAvailable) return;
    this.setCurrentWorkspaceToFirstAvailable();
  };

  private workspaceCreatedListener = (event: WorkspaceCreatedEvent) => {
    this.changeCurrentWorkspaceToGivenOrFirstAvailable(event.newWorkspaceId);
  };

  private workspaceDecryptionFailedListener = () => {
    const firstAvailableWithoutEncryption = this.workspacesStore.workspaces.find(w => !w.encryption.encrypted);
    if (firstAvailableWithoutEncryption) {
      this.setCurrentWorkspace(firstAvailableWithoutEncryption);
    }
  };

  private get isCurrentWorkspaceAvailable(): boolean {
    return !!this.workspacesStore.workspaces.find(w => w.id === this.store.currentWorkspace?.id);
  }

  private get isCurrentWorkspaceSet(): boolean {
    return !!this.store.currentWorkspace;
  }

  private get isPreferredWorkspaceSet(): boolean {
    return !!this.workspacesStore.preferredWorkspaceId;
  }

  private get shouldWaitForUserSettingsToBeSet(): boolean {
    return !this.isCurrentWorkspaceSet && !this.isPreferredWorkspaceSet;
  }

  private changeCurrentWorkspaceToPreferredOrFirstAvailable(): void {
    this.changeCurrentWorkspaceToGivenOrFirstAvailable(this.workspacesStore.preferredWorkspaceId);
  }

  private changeCurrentWorkspaceToGivenOrFirstAvailable(workspaceId: string | undefined): void {
    if (workspaceId) {
      const selectedWorkspace = this.workspacesStore.workspaces.find(w => w.id === workspaceId);
      if (selectedWorkspace) {
        this.setCurrentWorkspace(selectedWorkspace);
        return;
      }
    }

    this.setCurrentWorkspaceToFirstAvailable();
  }

  private setCurrentWorkspaceToFirstAvailable(): void {
    const firstAvailableWorkspace = this.workspacesStore.workspaces[0];
    this.setCurrentWorkspace(firstAvailableWorkspace);
  }

  private setCurrentWorkspace(workspace: IWorkspace): void {
    const isInitialWorkspaceSet = !this.store.currentWorkspace;
    this.store.setCurrentWorkspace(workspace);

    if (isInitialWorkspaceSet) {
      this.eventBus.sendEvent(new CurrentWorkspaceChangedEvent(false));
    } else {
      const thereWillBeNavigation = this.navigator.navigateOrReplaceWith(workspace.id);
      this.eventBus.sendEvent(new CurrentWorkspaceChangedEvent(thereWillBeNavigation));
    }
  }

  private updateCurrentWorkspace() {
    if(!this.isCurrentWorkspaceAvailable) return;
    const updatedCurrentWorkspace = this.workspacesStore.workspaces.find(w => w.id === this.store.currentWorkspace!.id);
    this.store.setCurrentWorkspace(updatedCurrentWorkspace!);
  }
}
