(function leafletPatching() {
  const leaflet = require('leaflet');
  require('leaflet-draw');
  require('leaflet-editable');
  require('leaflet.path.drag');
  require('react-leaflet');
  const { CircleMarkerEditorEx } = require('./modules/annotation/components/DrawControl.models/CircleMarkerEditorEx');
  const { PolygonEditorEx } = require('./modules/annotation/components/DrawControl.models/PolygonEditorEx');
  const { PolylineEditorEx } = require('./modules/annotation/components/DrawControl.models/PolylineEditorEx');
  const { DrawPathDrag } = require('./modules/annotation/components/DrawControl.models/Draw.PathDrag');
  require('leaflet-path-transform');
  leaflet.Editable.CircleMarkerEditor = CircleMarkerEditorEx;
  leaflet.Editable.PolygonEditor = PolygonEditorEx;
  leaflet.Editable.PolylineEditor = PolylineEditorEx;
  leaflet.Handler.PathDrag = DrawPathDrag;
})();

import 'reflect-metadata';
import 'babel-polyfill';

import * as React from 'react';

import { AuthStoreType, IAuthStore } from './modules/auth/auth.store';
import { BillingServiceType, IBillingService } from './modules/billing/billing.service';
import { BrowserServiceType, IBrowserService } from './services/browser.service';
import { CacheManagerType, ICacheManager } from './services/cacheManager';
import { ConfigurationType, IAppConfig, IConfiguration } from '../configuration';
import { ILoaderState, WithLoaderComponentBase } from './helpers/loader.helpers';
import { IStorageService, StorageServiceType } from './services/storage.service';
import { IUndoRedoHistory, UndoRedoHistoryType } from './modules/annotation/undoRedoHistory.service';
import { IUserService, UserServiceType } from './modules/user/user.service';
import { IWorkspaceService, WorkspaceServiceType } from './modules/workspaces/workspaces.service';
import { PrivateRoute, SwitchWithNotFound } from './containers/route.containers';
import { Redirect, RouteComponentProps, Router } from 'react-router';
import { as, injectProps } from './helpers/react.helpers';

import { AlertBarContainer } from '../modules/alertBar/AlertBar.container';
import { ApiServiceType } from './services/api.service';
import { Auth } from './routes/config/Auth';
import { ConfirmEmailView } from './views/auth/ConfirmEmail.view';
import { ConfirmNewEmailView } from './views/auth/ConfirmNewEmail.view';
import ErrorsContainer from './modules/errors/containers/Errors.container';
import { ForgotPasswordView } from './views/auth/ForgotPassword.view';
import { Home } from './routes/config/Home';
import { HomeRoute } from './routes/home.route';
import { IApiService } from './services/api.service.base';
import { LastLocationProvider } from 'react-router-last-location';
import { Loader } from './components/Loader';
import { LoginView } from './views/auth/Login.view';
import { NotFoundView } from './views/NotFound.view';
import { OverlayLoaderContainer } from './containers/OverlayLoader.container';
import { RegisterView } from './views/auth/Register.view';
import { ResendConfirmationEmailView } from './views/auth/ResendConfirmationEmail.view';
import { ResetPasswordView } from './views/auth/ResetPassword.view';
import { Route } from 'react-router-dom';
import { RouterStoreType } from './stores/router.store';
import { SettingsContextProvider } from '../modules/settings/SettingsContext';
import { StickerError } from './models/error.model';
import { configure } from 'react-hotkeys';
import { container } from './diContainer';
import createBrowserHistory from './services/history.service';
import { enableDevTools } from './helpers/devtools';
import { syncHistoryWithStore } from 'mobx-react-router';

enableDevTools();

const history = syncHistoryWithStore(createBrowserHistory, container.get(RouterStoreType));

interface IProps extends RouteComponentProps<{ workspaceId: string }> {
  apiService: IApiService;
  apiServiceImageUpload: IApiService;
  configuration: IConfiguration;
  billingService: IBillingService;
  cacheManager: ICacheManager;
  undoRedoHistory: IUndoRedoHistory;
  browserService: IBrowserService;
  userService: IUserService;
  authStore: IAuthStore;
  workspaceService: IWorkspaceService;
  storageService: IStorageService;
}

@injectProps({
  apiService: ApiServiceType,
  configuration: ConfigurationType,
  billingService: BillingServiceType,
  cacheManager: CacheManagerType,
  undoRedoHistory: UndoRedoHistoryType,
  browserService: BrowserServiceType,
  userService: UserServiceType,
  authStore: AuthStoreType,
  workspaceService: WorkspaceServiceType,
  storageService: StorageServiceType,
})
class AppPure extends WithLoaderComponentBase<IProps, ILoaderState> {
  state: ILoaderState = {
    isLoading: true,
  };

  async componentDidMount() {
    const url = '/Config';

    this.props.storageService.initializeLocalStorage();

    configure({
      ignoreEventsCondition: (e: KeyboardEvent) => {
        if (e.target && (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement)) {
          return !(e.target.type === 'radio' || e.target.type === 'checkbox');
        }
        return false;
      },
    });

    this.props.browserService.addOnBeforeUnload(() => this.props.undoRedoHistory.canUndo);

    await this.withLoaderAsync(async () => {
      await this.props.cacheManager.cleanupOldEntriesAsync();

      const result = await this.props.apiService.getAsync<IAppConfig>(url);

      if (result instanceof StickerError) throw result;

      this.props.configuration.setupAppConfig(result);

      if (this.props.authStore.isAuthenticated) {
        await Promise.all([this.props.userService.getUserInfoAsync(), this.props.userService.getUserSettingsAsync()]);
      }

      this.props.storageService.initializeCryptoLocalStorage();

      if (!this.props.configuration.appConfig.runLocally) {
        await this.props.billingService.initAsync();
      }
    });
  }

  render() {
    if (this.state.isLoading) return <Loader isLoading={true} />;
    return (
      <SettingsContextProvider>
        <OverlayLoaderContainer />
        <Router history={history}>
          <LastLocationProvider>
            <ErrorsContainer>
              <AlertBarContainer />
              <SwitchWithNotFound notFound={NotFoundView}>
                <Route exact path={Auth.Login.Path} component={LoginView} />
                <Route exact path={Auth.Register.Path} component={RegisterView} />
                <Route exact path={Auth.ConfirmEmail.Path} component={ConfirmEmailView} />
                <Route exact path={Auth.ConfirmNewEmail.Path} component={ConfirmNewEmailView} />
                <Route exact path={Auth.ResendConfirmationEmail.Path} component={ResendConfirmationEmailView} />
                <Route exact path={Auth.ForgotPassword.Path} component={ForgotPasswordView} />
                <Route exact path={Auth.ResetPassword.Path} component={ResetPasswordView} />
                <PrivateRoute path={Home.Path} component={HomeRoute} />
                <Redirect exact from="/" to={Auth.Login.Path} />
              </SwitchWithNotFound>
            </ErrorsContainer>
          </LastLocationProvider>
        </Router>
      </SettingsContextProvider>
    );
  }
}

export const App = as<React.ComponentClass>(AppPure);

declare var module: {
  hot: {
    accept(callback: () => void): void;
  };
};

if (module.hot) {
  module.hot.accept(() => window.location.reload());
}
