import { BaseRouteConfig, StickerRoute } from './BaseRoute';

class AuthConfig extends BaseRouteConfig {
  public get Index(): string {
    return '';
  }

  public readonly Login = new StickerRoute<{}>(`${this.Path}/login`);
  public readonly Register = new StickerRoute<{}>(`${this.Path}/register`);
  public readonly ForgotPassword = new StickerRoute<{}>(`${this.Path}/forgotpassword`);
  public readonly ResendConfirmationEmail = new StickerRoute<{}>(`${this.Path}/resendConfirmationEmail`);
  public readonly ConfirmEmail = new StickerRoute<{}>(`${this.Path}/confirmEmail`);
  public readonly ConfirmNewEmail = new StickerRoute<{}>(`${this.Path}/confirmEmailChange`);
  public readonly ResetPassword = new StickerRoute<{ token: string }>(`${this.Path}/resetpassword/:token`);
}

export const Auth = new AuthConfig('');
