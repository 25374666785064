import * as React from 'react';

import { AuthStoreType, IAuthStore } from '../../../../auth/auth.store';
import { BillingServiceType, IBillingService } from '../../../../billing/billing.service';
import { FreeAccessStoreType, IFreeAccessStore } from '../../../../freeAccess/freeAccess.store';
import { IImageFilterService, ImageFilterServiceType } from '../services/imageFilters.service';
import { ILoaderState, WithLoaderComponentBase } from '../../../../../helpers/loader.helpers';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../../../../modules/common/OverlayLoader.store';
import { IProjectDetailsImagesBl, ProjectDetailsImagesBlType } from '../projectDetailsImages.bl';
import { IProjectDetailsImagesStore, ProjectDetailsImagesStoreType } from '../projectDetailsImages.store';
import { IProjectDetailsPermissions, ProjectDetailsPermissionsType } from '../../../projectDetails.permissions';
import { IProjectDetailsStore, ProjectDetailsStoreType } from '../../../projectDetails.store';
import { IProjectHubService, ProjectHubServiceType } from '../../../../../services/projectHub.service';
import { IUserStore, UserStoreType } from '../../../../user/user.store';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { ImageSetType } from '../projectDetailsImages.model';
import { ProjectDetailsImagesList } from '../components/ProjectDetailsImagesList';
import { Settings } from '../../../../../../modules/settings/SettingsContext';
import { SortingBy } from '../imageFilters.model';
import { SortingDirection } from '../../../../../models/sortingDirection.model';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ workspaceId: string; projectId: string }> {
  projectDetailsImagesBl: IProjectDetailsImagesBl;
  billingService: IBillingService;
  authStore: IAuthStore;
  userStore: IUserStore;
  overlayLoader: IOverlayLoaderStore;
  projectHubService: IProjectHubService;
  freeAccessStore: IFreeAccessStore;
  imagesStore: IProjectDetailsImagesStore;
  permissions: IProjectDetailsPermissions;
  imageFilterService: IImageFilterService;
  projectDetailsStore: IProjectDetailsStore;
}

@injectProps({
  projectDetailsImagesBl: ProjectDetailsImagesBlType,
  billingService: BillingServiceType,
  authStore: AuthStoreType,
  userStore: UserStoreType,
  overlayLoader: OverlayLoaderStoreType,
  projectHubService: ProjectHubServiceType,
  freeAccessStore: FreeAccessStoreType,
  imagesStore: ProjectDetailsImagesStoreType,
  permissions: ProjectDetailsPermissionsType,
  imageFilterService: ImageFilterServiceType,
  projectDetailsStore: ProjectDetailsStoreType,
})
@observer
class ProjectDetailsImagesContainerPure extends WithLoaderComponentBase<IInjectedProps, ILoaderState> {
  static contextType = Settings;
  declare context: React.ContextType<typeof Settings>;

  constructor(props: IInjectedProps) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  async componentDidMount() {
    const { projectId, filterId } = this.props.imagesStore;
    await this.props.projectDetailsImagesBl.getImageTabDataAsync(this.props.match.params.projectId, filterId);
    await this.props.projectHubService.initializeAsync();

    if (this.props.match.params.projectId !== projectId) {
      this.props.freeAccessStore.paging.checkDate = '';
    }

    await this.withLoaderAsync(async () => await this.props.projectDetailsImagesBl.getProjectImagesAsync(), 'details-images-loading');
  }

  @autobind
  async handlePaginationChange(pageNumber: number, pageSize: number) {
    await this.withLoaderAsync(async () => {
      await this.props.imageFilterService.paginationChange(pageNumber, pageSize), 'details-images-loading';
      this.context.setProjectDetailsImagesPageSize(pageSize);
    });
  }

  @autobind
  async handleOrderingChangeAsync(by: SortingBy, direction: SortingDirection | undefined) {
    this.props.freeAccessStore.paging.checkDate = '';
    await this.withLoaderAsync(async () => await this.props.imageFilterService.changeSortingAsync(by, direction), 'details-images-loading');
  }

  handleNonBatchCheck(id: string) {
    let newList = this.props.imagesStore.toggledImages.slice();
    if (newList.some(imageId => imageId === id)) {
      newList = newList.filter(imageId => imageId !== id);
    } else {
      newList.push(id);
    }

    this.props.projectDetailsImagesBl.setToggledImages(newList, id);
  }

  @autobind
  handleBatchCheck(id: string) {
    const { detailsImages, lastSelectedImageId } = this.props.imagesStore;
    const lastIndex = detailsImages.findIndex(i => i.id === lastSelectedImageId);
    const markUpToIndex = detailsImages.findIndex(i => i.id === id);

    if (lastIndex === undefined || markUpToIndex === undefined || lastIndex === -1 || markUpToIndex === -1) {
      this.handleNonBatchCheck(id);
      return;
    }

    const changeDirection = lastIndex < markUpToIndex ? 1 : -1;
    const comparison = lastIndex < markUpToIndex ? (k: number, n: number) => k <= n : (k: number, n: number) => k >= n;
    let toggledImages = this.props.imagesStore.toggledImages.slice();

    for (let i = lastIndex; comparison(i, markUpToIndex); i += changeDirection) {
      const image = detailsImages[i];

      if (image.isLocked) continue;

      const idToToggle = image.id;

      if (this.props.imagesStore.selectionMode === 'Select' && !toggledImages.includes(idToToggle)) {
        toggledImages.push(idToToggle);
      } else if (this.props.imagesStore.selectionMode === 'Deselect' && toggledImages.includes(idToToggle)) {
        toggledImages = toggledImages.filter(id => id !== idToToggle);
      }
    }

    this.props.projectDetailsImagesBl.setToggledImages(toggledImages, lastSelectedImageId);
  }

  @autobind
  handleThumbCheck(id: string, isBatch: boolean) {
    const { lastSelectedImageId } = this.props.imagesStore;
    if (isBatch && lastSelectedImageId) this.handleBatchCheck(id);
    else this.handleNonBatchCheck(id);
  }

  @autobind
  handleImageSetChange(id: string, type: ImageSetType) {
    this.props.projectDetailsImagesBl.updateProjectImageSetAsync(id, type);
  }

  onCheckAll = () => this.props.projectDetailsImagesBl.changeSelectionMode();

  render() {
    const { filterId, imagesPaging, detailsImages, selectionMode, toggledImages, imageFilters } = this.props.imagesStore;
    imagesPaging.pageSize = this.context.store.projectDetailsImagesPageSize;

    const isLoading = this.props.overlayLoader.isSpinnerVisible('details-images-loading');
    const canSeeUsers = this.props.permissions.canSeeAnnotationAuthors();
    const canUpdateImageSet = this.props.permissions.canUpdateImageSet();
    const canBatchAnswerQuestions = this.props.permissions.canBatchAnswer();

    let orderBy: string = '';
    let orderType: string = '';

    if (imageFilters.sorting.length === 1) {
      orderBy = imageFilters.sorting[0].by?.toString() || '';
      orderType = imageFilters.sorting[0].direction?.toString() || '';
    }

    return (
      <ProjectDetailsImagesList
        isLoading={isLoading}
        filterId={filterId}
        canSeeUsers={canSeeUsers}
        canBatchAnswerQuestions={canBatchAnswerQuestions}
        canUpdateImageSet={canUpdateImageSet}
        projectId={this.props.match.params.projectId}
        workspaceId={this.props.match.params.workspaceId}
        detailsImages={detailsImages}
        pagination={{
          pageNumber: imagesPaging.pageNumber,
          pageSize: this.context.store.projectDetailsImagesPageSize,
          totalCount: imagesPaging.totalCount,
          onChange: this.handlePaginationChange,
        }}
        disabled={this.props.billingService.billing.isAnyPolicyLimitExceeded}
        viewMode={this.props.userStore.projectImageListViewMode}
        authToken={this.props.authStore.token}
        canSelectImages={!this.props.billingService.billing.isAnyPolicyLimitExceeded || canBatchAnswerQuestions}
        selectionMode={selectionMode}
        toggledImages={toggledImages}
        orderBy={orderBy}
        orderType={orderType}
        onCheck={this.handleThumbCheck}
        onOrderingChange={this.handleOrderingChangeAsync}
        onCheckAll={this.onCheckAll}
        onImageSetChange={this.handleImageSetChange}
        isProjectCreatedBeforeStatsRelease={this.props.projectDetailsStore.isCreatedBeforeStatsRelease}
      />
    );
  }
}

export const ProjectDetailsImagesContainer = as<React.ComponentClass>(withRouter(ProjectDetailsImagesContainerPure));
