import * as React from 'react';

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ILocalizable, NotificationLevel } from '../models/notification.model';
import { INotificationModel, INotificationsHubProps } from '../containers/NotificationsHub.container';
import { faBell, faCheckCircle, faExclamationTriangle, faInfoCircle, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { DownloadExportContainer } from '../containers/DownloadExport.container';
import { DownloadExportModalContainer } from '../../../../modules/projects/details/exportData/ui/downloadExportModal/DownloadExportModal.container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import TimeAgo from 'react-timeago';
import { TranslationFunction } from 'i18next';
import { as } from '../../../helpers/react.helpers';
import { handleClickAndPassData } from '../../../helpers/formHelpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import parse from 'html-react-parser';
import sanitize from 'sanitize-html';
import { withNamespaces } from 'react-i18next';

library.add(faBell, faTimes, faCheckCircle, faTimesCircle, faExclamationTriangle, faInfoCircle);
const NotificationsHubPure = (props: INotificationsHubProps & ITranslatable) => {
  const notReadNotifications = props.notifications.filter(n => !n.wasRead);

  const getNotificationIcon = (level: NotificationLevel) => {
    switch (level) {
      case NotificationLevel.SUCCESS:
        return <FontAwesomeIcon icon={faCheckCircle} className="success-icon" />;
      case NotificationLevel.ERROR:
        return <FontAwesomeIcon icon={faTimesCircle} className="error-icon" />;
      case NotificationLevel.WARNING:
        return <FontAwesomeIcon icon={faExclamationTriangle} className="warning-icon" />;
      default:
        return <FontAwesomeIcon icon={faInfoCircle} className="info-icon" />;
    }
  };

  const getLocalizedContent = (t: TranslationFunction<any, object, string>, content?: string | ILocalizable | React.ReactElement<any>) => {
    if (!content) return content;

    if (typeof content === 'string') return parse(sanitize(content));

    const localizable = content as ILocalizable;
    if (localizable.template) return t(localizable.template, localizable.data || {});

    return content;
  };

  const renderNotification = (notification: INotificationModel, t: TranslationFunction<any, object, string>) => (
    <DropdownItem tag="div" toggle={false} key={notification.id} className="notification-hub-single-item">
      <div className="top">
        <div className="name elipsis">
          {getNotificationIcon(notification.level)}
          {getLocalizedContent(t, notification.title)}
        </div>
        <div style={{ float: 'right' }}>
          <div className="ago">
            <TimeAgo date={notification.date} />
            <a onClick={handleClickAndPassData(notification.onRemove)(notification.id)}>
              <FontAwesomeIcon icon={faTimes} />
            </a>
          </div>
        </div>
      </div>
      <div className="body">
        {getLocalizedContent(t, notification.body)}
        {notification.projectExportId && renderDownloadLink(notification)}
      </div>
    </DropdownItem>
  );

  const renderDownloadLink = (notification: INotificationModel): React.ReactNode => (
    <>
      <br />
      <DownloadExportContainer projectExportId={notification.projectExportId!} exportDataFilter={notification.exportDataFilter!} />
    </>
  );

  return (
    <>
      <div className="top-nav-item">
        <Dropdown isOpen={props.isOpen} toggle={props.onToggle} className="top-nav-dropdown notification-hub">
          <DropdownToggle caret>
            <FontAwesomeIcon icon={faBell} className={notReadNotifications.length > 0 ? 'active' : 'not-active'} />
            {notReadNotifications.length > 0 && <span className="badge badge-pill notify-badge">{notReadNotifications.length > 9 ? '9+' : notReadNotifications.length}</span>}
          </DropdownToggle>
          <DropdownMenu right className="notification-hub-dropdown">
            <DropdownItem header>
              <span>
                {props.t('notifications')} ({props.notifications.length})
              </span>
              {props.notifications.length > 0 && (
                <a className="clear-link" onClick={props.onClearAll}>
                  {props.t('clear_all')}
                </a>
              )}
            </DropdownItem>
            {props.notifications.map(notification => renderNotification(notification, props.t))}
          </DropdownMenu>
        </Dropdown>
      </div>
      <DownloadExportModalContainer />
    </>
  );
};

export const NotificationsHub = as<React.ComponentClass>(withNamespaces()(observer(NotificationsHubPure)));
