import * as React from 'react';

import { BillingServiceType, IBillingService } from '../billing.service';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { IUserService, UserServiceType } from '../../user/user.service';
import { IUserStore, UserStoreType } from '../../user/user.store';
import { as, injectProps } from '../../../helpers/react.helpers';

import { Home } from '../../../routes/config/Home';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { Link } from '../../../containers/Link';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IProps {
  billingService: IBillingService;
  currentWorkspaceStore: ICurrentWorkspaceStore;
  userService: IUserService;
  userStore: IUserStore;
}

@injectProps({
  billingService: BillingServiceType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  userService: UserServiceType,
  userStore: UserStoreType,
})
@observer
class PolicyLimitsExceededWarningPure extends React.Component<IProps & ITranslatable> {
  render() {
    const isAnyPolicyLimitExceeded = this.props.billingService.billing.isAnyPolicyLimitExceeded;
    const workspace = this.props.currentWorkspaceStore.currentWorkspace;
    const workspaceId = workspace!.id;
    const encryption = workspace?.encryption;
    const isEncryptionDisabled = encryption?.encrypted && !encryption?.encryptionAllowed;
    const workspaceLimitsExceeded = workspace?.locked;
    return (
      <div className="top-nav-item policy-limits-warning">
        {isAnyPolicyLimitExceeded && (
          <Link to={Home.User.Account.withParams({ workspaceId })}>
            {this.props.t('policy_plan_exceeded_warning', { plan: this.props.t(this.props.userStore?.planInfo.plan || 'plan') })}
          </Link>
        )}
        {isEncryptionDisabled && <Link to={Home.User.Account.withParams({ workspaceId })}>{this.props.t('encryption_unavailable_warning')}</Link>}
        {workspaceLimitsExceeded && <p>{this.props.t('workspace_limits_exceeded')}</p>}
      </div>
    );
  }
}

export const PolicyLimitsExceededWarningContainer = as<React.SFC>(withNamespaces('common', { wait: true })(PolicyLimitsExceededWarningPure));
