import { inject, injectable } from 'inversify';
import { action } from 'mobx';
import { ForbiddenError, StickerError } from '../../__legacy__/models/error.model';
import { INotificationsService, NotificationsServiceType } from '../../__legacy__/modules/notifications/services/notifications.service';
import { NotificationLevel, ToastNotification } from '../../__legacy__/modules/notifications/models/notification.model';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../common/OverlayLoader.store';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../workspaces/currentWorkspace/CurrentWorkspace.store';
import { EvaluationOwnership, EvaluationsStoreType, IEvaluationsStore } from './evaluations.store';
import { EvaluationsApiServiceType, IEvaluationsApiService } from './services/evaluationsApi.service';
import { EVALUATION_STATUS } from './evaluations.model';

export const EvaluationsServiceType = Symbol('EVALUATIONS_SERVICE');

export interface IEvaluationsService {
  store: IEvaluationsStore;
  changeOrder(orderBy: string | undefined, orderType: string | undefined): Promise<void>;
  changeOwnershipFilter(ownership: EvaluationOwnership): Promise<void>;
  changePagination(pageNumber: number, pageSize: number): Promise<void>;
  downloadModelAsync(modelId: string): Promise<void | StickerError>;
  restartEvaluationAsync(evaluationId: string): Promise<void | StickerError>;
  stopEvaluationAsync(evaluationId: string): Promise<void | StickerError>;
  deleteEvaluationAsync(evaluationId: string): Promise<void | StickerError>;
  getEvaluationsAsync(workspaceId: string): Promise<void | StickerError>;
  refreshAsync(): Promise<void | StickerError>;
}

@injectable()
export class EvaluationsService implements IEvaluationsService {
  constructor(
    @inject(EvaluationsStoreType) public readonly store: IEvaluationsStore,
    @inject(NotificationsServiceType) private readonly notificationsService: INotificationsService,
    @inject(OverlayLoaderStoreType) private readonly overlayLoaderStore: IOverlayLoaderStore,
    @inject(EvaluationsApiServiceType) private readonly evaluationsApiService: IEvaluationsApiService,
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore,
  ) {}

  @action
  async changeOwnershipFilter(ownership: EvaluationOwnership): Promise<void> {
    if (ownership !== this.store.evaluationOwnership) {
      this.store.evaluationOwnership = ownership;
      this.store.evaluationsPaging.pageNumber = 1;
      await this.refreshAsync();
    }
  }

  @action
  async changeOrder(orderBy: string | undefined, orderType: string | undefined): Promise<void> {
    this.store.evaluationsPaging.orderBy = orderBy || '';
    this.store.evaluationsPaging.orderType = orderType || '';
    await this.refreshAsync();
  }

  @action
  async changePagination(pageNumber: number, pageSize: number): Promise<void> {
    this.store.evaluationsPaging.pageNumber = pageNumber;
    this.store.evaluationsPaging.pageSize = pageSize;
    await this.refreshAsync();
  }

  @action
  async refreshAsync() {
    const { currentWorkspace } = this.currentWorkspaceStore;
    if (!currentWorkspace) return;
    this.overlayLoaderStore.enableLoader('evaluations-list');
    await this.getEvaluationsAsync(currentWorkspace.id);
    this.overlayLoaderStore.disableLoader('evaluations-list');
  }

  @action
  async getEvaluationsAsync(workspaceId: string) {
    const { pageNumber, pageSize } = this.store.evaluationsPaging;
    const { orderBy, orderType } = this.store.evaluationsPaging;

    const request = {
      pageNumber,
      pageSize,
      orderBy,
      orderType,
      ownership: this.store.evaluationOwnership,
    };

    const result = await this.evaluationsApiService.getEvaluationsAsync(workspaceId, request);

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'evaluations:get_evaluations_failed');
      this.store.evaluationsList = [];
      return;
    }

    if (result.pagesCount < result.pageNumber) {
      if (result.pagesCount === 0) {
        this.store.evaluationsList = [];
        return;
      }
      this.store.evaluationsPaging.pageNumber = result.pagesCount;
      await this.getEvaluationsAsync(workspaceId);
    } else {
      this.store.evaluationsList = result.data.map(evaluation => ({
        id: evaluation.inference_job_id,
        name: evaluation.inference_name,
        modelName: evaluation.model_name,
        modelVariant: evaluation.model_variant,
        projectName: evaluation.project_name,
        predictionDate: `${evaluation.inference_at}Z`, // Adding Z to mark this time as UTC (backend sends time without it)
        modelId: evaluation.training_job_id,
        isOwner: evaluation.is_owner,
        isTrainingOwner: evaluation.is_training_owner,
        score: evaluation.score,
        status: evaluation.status === EVALUATION_STATUS.FAILING ? EVALUATION_STATUS.STOPPING : evaluation.status,
        startingProgress: evaluation.starting_progress,
        failureReason: evaluation.failure_reason,
      }));

      this.store.evaluationsPaging = {
        ...this.store.evaluationsPaging,
        orderBy,
        orderType,
        pageNumber: result.pageNumber,
        pagesCount: result.pagesCount,
        totalCount: result.totalCount,
      };
    }
  }

  @action.bound
  async downloadModelAsync(modelId: string) {
    const { currentWorkspace } = this.currentWorkspaceStore;

    if (!currentWorkspace) return;

    const result = await this.evaluationsApiService.getDownloadModelAsync(currentWorkspace.id, modelId);

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'models:generate_download_url.failed');
      return;
    }

    // Using a element to prevent Firefox from blocking the download
    const a = document.createElement('a');
    a.href = result.url;
    a.download = '';
    a.click();
  }

  @action.bound
  async restartEvaluationAsync(evaluationId: string): Promise<void | StickerError> {
    const { currentWorkspace } = this.currentWorkspaceStore;
    if (!currentWorkspace) return;

    const result = await this.evaluationsApiService.postEvaluationRestartAsync(currentWorkspace.id, evaluationId);

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'evaluations:restart_evaluation.failed');
      return;
    }

    this.notificationsService.push(new ToastNotification(NotificationLevel.SUCCESS, 'evaluations:restart_evaluation.success'));

    await this.refreshAsync();
  }

  @action.bound
  async stopEvaluationAsync(evaluationId: string): Promise<void | StickerError> {
    const { currentWorkspace } = this.currentWorkspaceStore;
    if (!currentWorkspace) return;

    const result = await this.evaluationsApiService.postEvaluationStopAsync(currentWorkspace.id, evaluationId);

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'evaluations:stop_evaluation.failed');
      return;
    }

    this.notificationsService.push(new ToastNotification(NotificationLevel.SUCCESS, 'evaluations:stop_evaluation.success'));

    await this.refreshAsync();
  }

  @action.bound
  async deleteEvaluationAsync(evaluationId: string): Promise<void | StickerError> {
    const { currentWorkspace } = this.currentWorkspaceStore;
    if (!currentWorkspace) return;

    const result = await this.evaluationsApiService.deleteEvaluationAsync(currentWorkspace.id, evaluationId);

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'evaluations:delete_evaluation.failed');
      return;
    }

    this.notificationsService.push(new ToastNotification(NotificationLevel.SUCCESS, 'evaluations:delete_evaluation.success'));

    await this.refreshAsync();
  }

  notifyAboutApiError(result: any, errorCode: string, dontShowBadRequest: boolean = false) {
    if (result instanceof StickerError && !(result instanceof ForbiddenError) && (!dontShowBadRequest || !result.isBadRequest())) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, errorCode));
    }
  }
}
