import { ISegmentation } from '../../../annotations.interface';
import { StickerEvent } from '../../../../../services/eventBus.service';

export const SegmentationSelectedEventType = Symbol('SEGMENTATION_SELECTED_EVENT');

export class SegmentationSelectedEvent extends StickerEvent {
  private _selectedSegmentationFeatureId: string;
  get selectedSegmentationFeatureId(): string {
    return this._selectedSegmentationFeatureId;
  }

  constructor(segmentation: ISegmentation) {
    super();
    this._selectedSegmentationFeatureId = segmentation.feature.id;
  }

  get type(): Symbol {
    return SegmentationSelectedEventType;
  }
}
