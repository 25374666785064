import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Dropzone, { DropFileEventHandler } from 'react-dropzone';

import ClassNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { IWizardModalNavigationProps } from './WizardModalBaseContent';
import React from 'react';
import { as } from '../../../helpers/react.helpers';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { withNamespaces } from 'react-i18next';

interface IProps extends IWizardModalNavigationProps {
  title: string;
  fileName?: string;
  canConfirm: boolean;
  fileValidationError?: string;
  filesDropped: DropFileEventHandler;
}

class WizardModalDropZoneContentPure extends React.Component<IProps & ITranslatable> {
  render() {
    return (
      <>
        <ModalHeader toggle={this.props.modalHidden}>{this.props.title}</ModalHeader>
        <ModalBody>
          <div>
            <p>
              {this.props.fileName ? (
                <span>
                  {this.props.t('selected_file')}: <b>{this.props.fileName}</b>
                </span>
              ) : (
                <strong>{this.props.t('please_select_your_file')}</strong>
              )}
            </p>
            <p>
              {this.props.t('our_json_format_documentation_can_be_found')}
              <a className="styled_link_bold" href="https://api.zillin.io/export-json-format.html" target="blank">
                {' '}
                {this.props.t('here')}
              </a>
              .
            </p>
            <p>{this.props.t('max_file_size')}</p>
            <Dropzone
              className={ClassNames({ 'dropzone-generic': true, 'is-invalid': this.props.fileValidationError !== undefined })}
              activeClassName="active"
              multiple={false}
              onDropRejected={this.props.filesDropped}
              onDropAccepted={this.props.filesDropped}
              accept={'application/JSON'}
            >
              <FontAwesomeIcon icon={faUpload} />
              {this.props.t('drop_your_json_file_here_or_select_it_manually')}
            </Dropzone>
            {this.props.fileValidationError && <p className="invalid-feedback">{this.props.t(this.props.fileValidationError)}</p>}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button disabled={!this.props.canConfirm} color="primary" onClick={this.props.nextPressed} className="mr-2">
            {this.props.t('next')}
          </Button>
          <Button outline color="primary" onClick={this.props.modalHidden}>
            {this.props.t('cancel')}
          </Button>
        </ModalFooter>
      </>
    );
  }
}

export const WizardModalDropZoneContent = as<React.ComponentClass<IProps>>(withNamespaces()(WizardModalDropZoneContentPure));
