import './AnnotationToolsStatsTable.scss';

import { AnnotationToolIcon, IconType } from './AnnotationToolIcon';
import { ExpandableTable, IExpandableColumnProps, IExpandableRowModel, IExpandableSubColumnProps } from '../../../../../../__legacy__/components/table/ExpandableTable';
import { IAnnotationToolsStats, MarkingToolType } from '../models/AnnotationToolsStats';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { enumDirectionToStringOrder, stringOrderToEnumDirection } from '../../../../../../__legacy__/components/table/GTable';

import React from 'react';
import { SortingDirection } from '../../../../../../__legacy__/models/sortingDirection.model';
import _ from 'lodash';
import { nameOf } from '../../../../../../__legacy__/helpers/object.helpers';

interface IAddedModifiedDeleted {
  added: number;
  modified: number;
  deleted: number;
}

interface IMainRow extends IAddedModifiedDeleted {
  email: string;
  annotationPoints: number;
  reviewPoints: number;
  editPoints: number;
  totalPoints: number;
  velocity: number;
}

interface ISubRow extends IAddedModifiedDeleted {
  type: IconType;
  text: string;
  tooltip: string;
  color?: string;
  annotationPoints: number;
  reviewPoints: number;
  editPoints: number;
  totalPoints: number;
}

type MainRowModel = IMainRow & IExpandableRowModel<SubRowModel>;
type SubRowModel = ISubRow & IExpandableRowModel;

interface Props {
  items: IAnnotationToolsStats[];
  orderBy: string;
  orderDirection: SortingDirection | undefined;
  onSortChange(orderBy: string, orderDirection: SortingDirection | undefined): void;
}

const AnnotationToolsStatsTablePure: React.FC<Props & WithNamespaces> = ({ items, t, orderBy, orderDirection, onSortChange }) => {
  const firstColumnSize = 400;
  const smallColumnSize = 100;

  const columns: IExpandableColumnProps<MainRowModel>[] = [
    {
      field: nameOf<IMainRow>('email'),
      headerName: t('projects.details.stats.annotation_tools.headers.email'),
      width: firstColumnSize,
      cellClass: 'stats-cell-bold',
      sortable: true,
    },
    {
      field: nameOf<IMainRow>('added'),
      headerName: t('projects.details.stats.annotation_tools.headers.added'),
      headerTooltip: t('projects.details.stats.annotation_tools.tooltips.added'),
      width: smallColumnSize,
      cellClass: 'stats-cell-bold',
      sortable: true,
    },
    {
      field: nameOf<IMainRow>('modified'),
      headerName: t('projects.details.stats.annotation_tools.headers.modified'),
      headerTooltip: t('projects.details.stats.annotation_tools.tooltips.modified'),
      width: smallColumnSize,
      cellClass: 'stats-cell-bold',
      sortable: true,
    },
    {
      field: nameOf<IMainRow>('deleted'),
      headerName: t('projects.details.stats.annotation_tools.headers.deleted'),
      headerTooltip: t('projects.details.stats.annotation_tools.tooltips.deleted'),
      width: smallColumnSize,
      cellClass: 'stats-cell-bold',
      sortable: true,
    },
    {
      field: nameOf<IMainRow>('annotationPoints'),
      headerName: t('projects.details.stats.annotation_tools.headers.annotationPoints'),
      headerTooltip: t('projects.details.stats.annotation_tools.tooltips.annotationPoints'),
      width: 160,
      sortable: true,
      renderer: (item: IMainRow) => <span>{item.annotationPoints.toFixed(1)}</span>,
    },
    {
      field: nameOf<IMainRow>('reviewPoints'),
      headerName: t('projects.details.stats.annotation_tools.headers.reviewPoints'),
      headerTooltip: t('projects.details.stats.annotation_tools.tooltips.reviewPoints'),
      width: 140,
      sortable: true,
      renderer: (item: IMainRow) => <span>{item.reviewPoints.toFixed(1)}</span>,
    },
    {
      field: nameOf<IMainRow>('editPoints'),
      headerName: t('projects.details.stats.annotation_tools.headers.editPoints'),
      headerTooltip: t('projects.details.stats.annotation_tools.tooltips.editPoints'),
      width: smallColumnSize,
      sortable: true,
      renderer: (item: IMainRow) => <span>{item.editPoints.toFixed(1)}</span>,
    },
    {
      field: nameOf<IMainRow>('totalPoints'),
      headerName: t('projects.details.stats.annotation_tools.headers.totalPoints'),
      headerTooltip: t('projects.details.stats.annotation_tools.tooltips.totalPoints'),
      width: 140,
      sortable: true,
      renderer: (stats: IMainRow) => <b>{stats.totalPoints.toFixed(1)}</b>,
    },
    {
      field: nameOf<IMainRow>('velocity'),
      headerName: t('projects.details.stats.annotation_tools.headers.velocity'),
      headerTooltip: t('projects.details.stats.annotation_tools.tooltips.velocity'),
      width: smallColumnSize,
      sortable: true,
      renderer: (stats: IMainRow) => <>{stats.velocity.toFixed(1)}</>,
    },
  ];

  const renderAnnotationToolIconWithName = (item: ISubRow) => (
    <div className="annotation-tools-table-icon-cell">
      <span>
        <AnnotationToolIcon iconType={item.type} color={item.color} tooltip={item.tooltip} />
      </span>
      <span className="ellipsis-tool-description" title={item.text}>
        {item.text}
      </span>
    </div>
  );

  const subColumns: IExpandableSubColumnProps<SubRowModel>[] = [
    {
      field: nameOf<ISubRow>('text'),
      width: firstColumnSize,
      renderer: renderAnnotationToolIconWithName,
    },
    { field: nameOf<ISubRow>('added'), width: smallColumnSize },
    { field: nameOf<ISubRow>('modified'), width: smallColumnSize },
    { field: nameOf<ISubRow>('deleted'), width: smallColumnSize },
    { field: nameOf<ISubRow>('annotationPoints'), renderer: (stats: ISubRow) => <>{stats.annotationPoints.toFixed(1)}</>, width: 160 },
    { field: nameOf<ISubRow>('reviewPoints'), renderer: (stats: ISubRow) => <>{stats.reviewPoints.toFixed(1)}</>, width: 140 },
    { field: nameOf<ISubRow>('editPoints'), renderer: (stats: ISubRow) => <>{stats.editPoints.toFixed(1)}</>, width: smallColumnSize },
    { field: nameOf<ISubRow>('totalPoints'), renderer: (stats: ISubRow) => <>{stats.totalPoints.toFixed(1)}</>, width: 140 },
    { field: '', renderer: () => <>&mdash;</>, width: smallColumnSize },
    { field: '', width: 60 },
  ];

  const mapMarkingToolTypeToIconType = (markingToolType: MarkingToolType): IconType => {
    switch (markingToolType) {
      case MarkingToolType.Brush:
        return IconType.MarkingToolBrush;

      case MarkingToolType.Point:
        return IconType.MarkingToolPoint;

      case MarkingToolType.Polygon:
        return IconType.MarkingToolPolygon;

      case MarkingToolType.Polyline:
        return IconType.MarkingToolPolyline;

      case MarkingToolType.Rectangle:
        return IconType.MarkingToolRectangle;

      case MarkingToolType.RotatedRectangle:
        return IconType.MarkingToolRotatedRectangle;

      case MarkingToolType.Vector:
        return IconType.MarkingToolVector;
    }

    return IconType.MarkingToolPolygon;
  };

  const mapAnnotationToolStatsToFlatSubRows = (item: IAnnotationToolsStats): SubRowModel[] => {
    return _.flatten(
      item.markingToolsStats.map(at =>
        [
          {
            id: at.id,
            type: mapMarkingToolTypeToIconType(at.markingToolType),
            color: at.color,
            text: at.name,
            added: at.added,
            modified: at.modified,
            deleted: at.deleted,
            annotationPoints: at.annotationPoints,
            reviewPoints: at.reviewPoints,
            editPoints: at.editPoints,
            totalPoints: at.totalPoints,
            tooltip: t(`projects.details.stats.annotation_tools.tooltips.${at.markingToolType}`),
          },
        ].concat(
          at.attributesStats?.map(a => ({
            id: a.id,
            type: IconType.Attribute,
            text: a.text,
            added: a.added,
            modified: a.modified,
            deleted: a.deleted,
            color: '',
            annotationPoints: a.annotationPoints,
            reviewPoints: a.reviewPoints,
            editPoints: a.editPoints,
            totalPoints: a.totalPoints,
            tooltip: t(`projects.details.stats.annotation_tools.tooltips.${a.questionType}`),
          })),
        ),
      ),
    ).concat(
      item.questionsStats.map(q => ({
        id: q.id,
        type: IconType.Question,
        text: q.text,
        added: q.added,
        modified: q.modified,
        deleted: q.deleted,
        color: '',
        annotationPoints: q.annotationPoints,
        reviewPoints: q.reviewPoints,
        editPoints: q.editPoints,
        totalPoints: q.totalPoints,
        tooltip: t(`projects.details.stats.annotation_tools.tooltips.${q.questionType}`),
      })),
    );
  };

  const mappedItems = items.map(i => ({
    id: i.id,
    email: i.email,
    added: i.added,
    modified: i.modified,
    deleted: i.deleted,
    annotationPoints: i.annotationPoints,
    reviewPoints: i.reviewPoints,
    editPoints: i.editPoints,
    totalPoints: i.totalPoints,
    velocity: i.velocity,
    subRows: mapAnnotationToolStatsToFlatSubRows(i),
  }));

  const handleSortChange = (orderBy: string, orderDirection: string) => onSortChange(orderBy, stringOrderToEnumDirection(orderDirection));

  return (
    <div className="annotation-tools-stats-table">
      <ExpandableTable
        columns={columns}
        items={mappedItems}
        subColumns={subColumns}
        noItemsPlaceholder={t('projects.details.stats.no_statistics_have_been_generated_yet')}
        onSortChanged={handleSortChange}
        sortingModel={[{ orderBy, orderType: enumDirectionToStringOrder(orderDirection) }]}
      />
    </div>
  );
};

export const AnnotationToolsStatsTable = withNamespaces('new')(AnnotationToolsStatsTablePure);
