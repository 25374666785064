import * as React from 'react';

import { Button, CustomInput, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Trans, withNamespaces } from 'react-i18next';

import { ButtonWithLoader } from '../../../components/ButtonWithLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { InputStatus } from '../../../models/error.model';
import { ValidatedTextInput } from '../../../components/ValidatedTextInput';
import { as } from '../../../helpers/react.helpers';
import autobind from 'autobind-decorator';

interface IConfirmationModalProps {
  showModal: boolean;
  projectDatasetsToDelete: string[];
  onConfirm(deleteDatasets: boolean): void;
  onCancel(): void;
}

interface IState {
  deleteDatasets: boolean;
  showDatasetList: boolean;
  confirmationStatus: InputStatus;
  confirmationText: string;
}

class DeleteProjectConfirmationModal extends React.Component<IConfirmationModalProps & ITranslatable, IState> {
  state: IState = {
    deleteDatasets: false,
    showDatasetList: false,
    confirmationText: '',
    confirmationStatus: InputStatus.empty(),
  };

  @autobind
  onChange(data: any) {
    this.setState({ deleteDatasets: data.target.checked });
  }

  @autobind
  onConfirm(e: any) {
    e.preventDefault();
    this.props.onConfirm(this.state.deleteDatasets);
    this.setState({ deleteDatasets: false, showDatasetList: false, confirmationText: '', confirmationStatus: InputStatus.empty() });
  }

  @autobind
  onCancel() {
    this.setState({ deleteDatasets: false, showDatasetList: false, confirmationText: '', confirmationStatus: InputStatus.empty() });
    this.props.onCancel();
  }

  @autobind
  onConfirmationTextChange(confirmationText: string) {
    this.setState({ confirmationText });
    this.validateConfirmationText(confirmationText);
  }

  @autobind
  validateConfirmationText(value: string) {
    const confirmationStatus = InputStatus.valid();
    confirmationStatus.isValid = this.props.t('delete_datasets_confirmation', { count: this.props.projectDatasetsToDelete.length }) === value;

    this.setState({ confirmationStatus });

    return confirmationStatus.isValid;
  }

  showDatasetList = () => this.setState({ showDatasetList: true });

  renderDatasetsList() {
    const datasetsToDelete = this.props.projectDatasetsToDelete;

    if (datasetsToDelete.length > 5 && !this.state.showDatasetList) {
      return this.buildDatasetRow(
        <span onClick={this.showDatasetList} className="text-link">
          {this.props.t('delete_datasets_list_show_all')} ({datasetsToDelete.length})
        </span>,
      );
    }

    return this.buildDatasetRow(
      datasetsToDelete.map(d => (
        <li key={d}>
          <span>{d}</span>
        </li>
      )),
    );
  }

  buildDatasetRow(row: any) {
    return <div>{row}</div>;
  }

  render() {
    const datasetsToDeleteCount = this.props.projectDatasetsToDelete.length;
    const isConfirmDisabled = this.state.deleteDatasets && !this.state.confirmationStatus.isValid;
    const isDeleteDatasetsDisabled = this.props.projectDatasetsToDelete.length === 0;

    return (
      <Modal className="responsive-modal" isOpen={this.props.showModal} toggle={this.onCancel}>
        <ModalHeader toggle={this.onCancel}>{this.props.t('delete_confirmation_project_header')}</ModalHeader>
        <Form onSubmit={this.onConfirm}>
          <ModalBody>
            <p className="mt-2">{this.props.t('delete_confirmation_project_message')}</p>
            <FormGroup className="checkbox">
              <div title={isDeleteDatasetsDisabled ? this.props.t('delete_datasets_disabled') : ''}>
                <CustomInput onChange={this.onChange} type="checkbox" id={'cascade-dataset-delete'} label={this.props.t('delete_datasets')} disabled={isDeleteDatasetsDisabled} />
              </div>
              <div hidden={!this.state.deleteDatasets}>
                <div className="alerts">
                  <p className="alerts-yellow">
                    <FontAwesomeIcon icon="exclamation-triangle" />
                    {this.props.t('delete_datasets_info')}:
                  </p>
                </div>
                <div className="list">{this.renderDatasetsList()}</div>
                <div>
                  <br />
                  <p>
                    <Trans
                      i18nKey="delete_datasets_confirmation_header"
                      count={datasetsToDeleteCount}
                      values={{ count: datasetsToDeleteCount }}
                      // tslint:disable-next-line:jsx-key
                      components={[<strong>0</strong>]}
                    />
                  </p>
                  <ValidatedTextInput
                    id="confirmation-input"
                    type={'text'}
                    maxLength={200}
                    value={this.state.confirmationText}
                    placeholder={this.props.t('delete_datasets_confirmation', { count: datasetsToDeleteCount })}
                    onChange={this.onConfirmationTextChange}
                    isValid={this.state.confirmationStatus.isValid}
                    feedbacks={this.state.confirmationStatus.errorCodes.map(this.props.t)}
                  />
                </div>
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <ButtonWithLoader className="mr-2" color="warning" onClick={this.onConfirm} disabled={isConfirmDisabled} loaderKey="delete-project-button">
              {this.props.t('delete')}
            </ButtonWithLoader>
            <Button outline color="primary" onClick={this.onCancel}>
              {this.props.t('cancel')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default as<React.StatelessComponent<IConfirmationModalProps>>(withNamespaces(['project', 'common'] as any, { wait: true })(DeleteProjectConfirmationModal));
