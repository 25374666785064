import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { FileDropZone } from './FileDropZone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IImageUrl } from '../imagesFromUrlsUpload.service';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { NODE_STREAM_INPUT } from 'papaparse';
import React from 'react';
import { action } from 'mobx';
import { as } from '../../../helpers/react.helpers';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

export interface IImagesUrlUploaderProps {
  datasetId: string;
  isProcessingImages: boolean;
  imagesToUploadFromUrl: IImageUrl[];
  cleanImagesFromFile(datasetId: string): void;
  rejectUrlsFile(draftId: string): void;
  getUrlsFromFileAsync(file: typeof NODE_STREAM_INPUT): void;
}

@observer
class ImagesUrlUploaderPure extends React.Component<IImagesUrlUploaderProps & ITranslatable> {
  componentDidMount() {
    this.props.cleanImagesFromFile(this.props.datasetId);
  }

  @action.bound
  handleAcceptedFileDrop = (files: File[]) => {
    if (files.length === 0) return;
    this.props.getUrlsFromFileAsync(files[0] as any);
  };

  @action.bound
  handleRejectedFileDrop = () => {
    this.props.rejectUrlsFile(this.props.datasetId);
  };

  render() {
    return (
      <>
        <DisabledWhenWorkspaceOwnerPolicyExceeded>
          <FileDropZone
            imageUrls={this.props.imagesToUploadFromUrl}
            onAcceptedFileDrop={this.handleAcceptedFileDrop}
            onRejectedFileDrop={this.handleRejectedFileDrop}
            isProcessingImages={this.props.isProcessingImages}
          >
            <FontAwesomeIcon icon={faUpload} />
            {this.props.t('drop_your_csv')}
          </FileDropZone>
        </DisabledWhenWorkspaceOwnerPolicyExceeded>
      </>
    );
  }
}

export const ImagesUrlUploader = as<React.StatelessComponent<IImagesUrlUploaderProps>>(withNamespaces('datasets')(ImagesUrlUploaderPure));
