import * as React from 'react';

import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { ButtonWithLoader } from '../../../components/ButtonWithLoader';
import { ITranslatable } from '../../../helpers/translations.helpers';
import Select from 'react-select';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import { as } from '../../../helpers/react.helpers';
import { handleSelectChange } from '../../../helpers/formHelpers';
import { rolesPossibleToAssign } from './AddTeamMember';
import { withNamespaces } from 'react-i18next';

interface IChangeUserRoleModalProps {
  userNameSelectedToChange: string;
  changeUserRoleModalShown: boolean;
  userSelectedToChange: string;
  selectedChangeRole: WorkspaceRole;
  changeRoleError: string;
  onChange(): void;
  onToggleChangeUserRoleModal(email: string): void;
  onChangeRoleSelected(role: WorkspaceRole): void;
  currentPlan: string;
}

class ChangeUserRoleModalPure extends React.Component<IChangeUserRoleModalProps & ITranslatable> {
  render() {
    const toggleChangeUserRoleModal = () => this.props.onToggleChangeUserRoleModal('');

    return (
      <Modal className="modal-width-600" isOpen={this.props.changeUserRoleModalShown} toggle={toggleChangeUserRoleModal}>
        <ModalHeader toggle={toggleChangeUserRoleModal}>{this.props.t('reassign_user_role')}</ModalHeader>
        <Form className="custom-inline-form">
          <ModalBody>
            <div>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label>{this.props.t('name')}</Label>
                    <Input value={this.props.userNameSelectedToChange} disabled={true} />
                  </FormGroup>
                </Col>
                <Col className="mt-4" md={12}>
                  <FormGroup>
                    <Label>{this.props.t('email')}</Label>
                    <Input value={this.props.userSelectedToChange} disabled={true} />
                  </FormGroup>
                </Col>
                <Col className="mt-4" md={12}>
                  <FormGroup>
                    <Label>{this.props.t('role')}</Label>
                    <Select
                      id="user-change-role-dropdown"
                      value={{ label: this.props.t(this.props.selectedChangeRole), value: this.props.selectedChangeRole }}
                      onChange={handleSelectChange((value: WorkspaceRole) => this.props.onChangeRoleSelected(value))}
                      options={rolesPossibleToAssign.map(r => ({ label: this.props.t(r), value: r }))}
                      classNamePrefix="custom-select"
                    />
                    <div className="change-role-error">
                      {this.props.changeRoleError && <span className="error-feedback">{this.props.t(this.props.changeRoleError, { plan: this.props.t(this.props.currentPlan) })}</span>}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <ButtonWithLoader id="change-button" color="success" className="mr-2" onClick={this.props.onChange} loaderKey="change-user-role-button">
              {this.props.t('save')}
            </ButtonWithLoader>
            <Button id="change-button-cancel" color="primary" className="btn-outline-primary" onClick={toggleChangeUserRoleModal}>
              {this.props.t('cancel')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export const ChangeUserRoleModal = as<React.ComponentClass<IChangeUserRoleModalProps>>(withNamespaces('common', { wait: true })(ChangeUserRoleModalPure));
