import { action, observable } from 'mobx';

import { Feature } from 'geojson';
import { ISegmentationFeature } from './annotations.interface';
import { cloneDeep } from 'lodash/fp';
import { inject, injectable } from 'inversify';
import { EventBusType, IEventBus } from '../../services/eventBus.service';
import { SegmentationDeletedEvent, SegmentationDeletedEventType } from './submodules/segmentations/events/sementationDeletedEvent';
import { FreeDrawCanceledEvent } from './submodules/freeDraw/events/freeDrawCanceledEvent';

export const FreeDrawSegmentationServiceType = Symbol('FREE_DRAW_SEGMENTATION_SERVICE');

export interface IFreeDrawSegmentationService {
  freeDrawFeature?: ISegmentationFeature;
  freeDrawSelected: boolean;
  freeDrawInProgress: boolean;
  showClearConfirmation: boolean;
  setFreeDrawFeature(segmentation: ISegmentationFeature): void;
  updateFreeDrawFeature(feature: Feature<any>): void;
  startFreeDraw(): void;
  selectFeature(): void;
  clearAsync(): Promise<boolean>;
  clear(isDeleteAction?: boolean): void;
  cancelClear(): void;
}

@injectable()
export class FreeDrawSegmentationService implements IFreeDrawSegmentationService {
  constructor(@inject(EventBusType) private readonly eventBus: IEventBus) {
    this.eventBus.addListener<SegmentationDeletedEvent>(this.segmentationDeletedListener, SegmentationDeletedEventType);
  }

  @observable
  freeDrawSelected: boolean = false;

  @observable
  freeDrawInProgress: boolean = false;

  @observable
  showClearConfirmation: boolean = false;

  freeDrawFeature?: ISegmentationFeature;

  clearPromise?: Promise<boolean>;
  clearResolver?(value: boolean): void;

  setFreeDrawFeature(segmentation: ISegmentationFeature): void {
    this.freeDrawFeature = cloneDeep(segmentation);
  }

  updateFreeDrawFeature(feature: Feature<any>): void {
    if (this.freeDrawFeature) {
      this.freeDrawFeature.geometry = feature.geometry;
    }
  }

  @action.bound
  selectFeature(): void {
    this.freeDrawSelected = true;
  }

  @action.bound
  startFreeDraw(): void {
    this.freeDrawInProgress = true;
    this.freeDrawSelected = false;
  }

  clearAsync(): Promise<boolean> {
    if (!this.clearResolver) {
      this.clearPromise = new Promise((resolve, _) => {
        this.clearResolver = resolve.bind(this);
      });
      if (this.maybeClear()) this.resolveClearPromise(true);
    }
    return this.clearPromise!;
  }

  @action.bound
  private maybeClear(): boolean {
    if (this.freeDrawInProgress && this.freeDrawFeature && this.freeDrawFeature.geometry.coordinates.length > 0) {
      this.showClearConfirmation = true;
      return false;
    }

    this.clear();
    return true;
  }

  @action.bound
  clear(isDeleteAction: boolean = false): void {
    if (this.freeDrawInProgress || this.freeDrawSelected || isDeleteAction) {
      this.showClearConfirmation = false;
      this.freeDrawFeature = undefined;
      this.freeDrawInProgress = false;
      this.freeDrawSelected = false;
      this.eventBus.sendEvent(new FreeDrawCanceledEvent());
      this.resolveClearPromise(true);
    }
  }

  @action.bound
  cancelClear(): void {
    this.showClearConfirmation = false;
    this.resolveClearPromise(false);
  }

  private resolveClearPromise(result: boolean) {
    if (this.clearResolver) {
      this.clearResolver(result);
      this.clearResolver = undefined;
    }
  }

  private segmentationDeletedListener = (event: SegmentationDeletedEvent) => {
    if (this.freeDrawFeature?.id === event.segmentationId) {
      this.clear(true);
    }
  };
}
