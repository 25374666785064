import { action, computed, observable } from 'mobx';

import { TimePeriod } from '../../../../../design/selects/timePeriodSelector/S_TimePeriodSelector';
import { injectable } from 'inversify';
import moment from 'moment-timezone';

export const ProjectStatsFiltersStoreType = Symbol('PROJECT_STATS_FILTERS_STORE');
export const ProjectStatsFiltersStoreSetterType = Symbol('PROJECT_STATS_FILTERS_STORE_SETTER');

export interface IProjectStatsFiltersStore {
  availableWorkers: IWorker[];
  endDate: moment.Moment;
  hiddenWorkers: IWorker[];
  projectCreatedDate: moment.Moment;
  projectId: string;
  startDate: moment.Moment;
  timePeriod: TimePeriod;
  visibleWorkers: IWorker[];
}

export interface IWorker {
  id: string;
  email: string;
}

export interface IProjectStatsFiltersStoreSetter extends IProjectStatsFiltersStore {
  load(projectId: string, projectCreatedDate: Date, workers: IWorker[], startDate: moment.Moment, endDate: moment.Moment): void;
  setAvailableWorkers(availableWorkers: IWorker[]): void;
  setDates(startDate: moment.Moment, endDate: moment.Moment): void;
  setHiddenWorkers(hiddenWorkers: IWorker[]): void;
  setTimePeriod(dateRangeType: TimePeriod): void;
}

@injectable()
export class ProjectStatsFiltersStore implements IProjectStatsFiltersStoreSetter {
  @observable
  availableWorkers: IWorker[] = [];

  @observable
  endDate: moment.Moment = moment();

  @observable
  hiddenWorkers: IWorker[] = [];

  @observable
  projectCreatedDate: moment.Moment = moment();

  @observable
  projectId: string = '';

  @observable
  startDate: moment.Moment = moment();

  @observable
  timePeriod: TimePeriod = TimePeriod.Month;

  @action
  setHiddenWorkers(hiddenWorkers: IWorker[]): void {
    this.hiddenWorkers = hiddenWorkers;
  }

  @action
  setAvailableWorkers(availableWorkers: IWorker[]): void {
    this.availableWorkers = availableWorkers;
  }

  @action
  setTimePeriod(timePeriod: TimePeriod): void {
    this.timePeriod = timePeriod;
  }

  @action
  setDates(startDate: moment.Moment, endDate: moment.Moment): void {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  @action
  load(projectId: string, projectCreatedDate: Date, workers: IWorker[], startDate: moment.Moment, endDate: moment.Moment) {
    this.projectId = projectId;
    this.availableWorkers = workers;
    this.hiddenWorkers = [];
    this.projectCreatedDate = moment(projectCreatedDate).utcOffset(0, false);
    this.timePeriod = TimePeriod.Month;
    this.startDate = startDate;
    this.endDate = endDate;
  }

  @computed
  get visibleWorkers() {
    return this.availableWorkers.filter(w => !this.hiddenWorkers.map(hd => hd.id).includes(w.id));
  }
}
