import { WithNamespaces, withNamespaces } from 'react-i18next';

import React from 'react';
import { S_PrimaryButton } from '../../../../../../design/buttons/textButton/primaryButton/S_PrimaryButton';

interface Props {
  onClick(e?: any): void;
  disabled: boolean;
}

const ExportButtonPure: React.FC<Props & WithNamespaces> = (props: Props & WithNamespaces) => {
  const { t, onClick, disabled } = props;
  const buttonText = disabled ? t('new.projects.details.export_data.generating_export_file') : t('new.projects.details.export_data.export');

  return <S_PrimaryButton className='export-button' text={buttonText} disabled={disabled} onClick={onClick} />;
};

export const ExportButton = withNamespaces('common')(ExportButtonPure);