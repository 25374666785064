import * as React from 'react';

import { AttachmentsHeader } from '../../../modules/attachments/components/AttachmentsHeader';
import AttachmentsListContainer from '../../../modules/attachments/containers/AttachmentsList.container';
import { DashboardContainer } from '../../../containers/DashboardContainer';

export const AttachmentsView = () => (
  <DashboardContainer header={AttachmentsHeader}>
    <AttachmentsListContainer canAddFolder={true} />
  </DashboardContainer>
);

export const AttachmentsFolderView = () => (
    <DashboardContainer header={AttachmentsHeader}>
      <AttachmentsListContainer canAddFolder={false} />
    </DashboardContainer>
);
