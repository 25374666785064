import 'leaflet-draw';
import 'leaflet-editable';
import 'leaflet.path.drag';

import * as React from 'react';

import { FREE_DRAW_TOOLS, IBrushTypeOptions, ICON_MARKER_SIZE, ISegmentation, ISegmentationFeature, MOUSE_BUTTONS, MOUSE_EVENTS } from '../annotations.interface';
import { MapControl, withLeaflet } from 'react-leaflet';
import leaflet, { ControlOptions, DivIcon, LatLng, Map, PolylineOptions } from 'leaflet';

import { AnnotationToolType } from '../submodules/annotationTypes/models/annotationToolType';
import { DRAW_CONTROL_MODE } from '../containers/Drawing.container';
import { DragEx } from './DrawControl.models/DragEx';
import { DrawBrush } from './DrawControl.models/Brush/Draw.Brush';
import { DrawerFactory } from './DrawerFactory';
import { DrawingEditor } from './DrawingEditor';
import { EditVertexMarker } from './DrawControl.models/Edit.VertexMarker';
import { Feature } from 'geojson';
import { IAnnotationService } from '../annotation.service';
import { IAnnotationTypeOptions } from '../submodules/annotationTypes/models/annotationTypeOptions';
import { IFreeDrawSegmentationService } from '../freeDrawSegmentation.service';
import { IUndoRedoHistory } from '../undoRedoHistory.service';
import { action } from 'mobx';
import { as } from '../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { calculateCurrentPosition } from '../../../helpers/geometry/mapExtensions.helpers';
import { keyboardShortcutsServiceInstance } from '../../../services/keyboardShortcuts.service';

interface IOuterProps {
  activeAnnotationTypeId: string | undefined;
  annotationService: IAnnotationService;
  areCursorGuidesEnabled: boolean;
  color: string;
  controlMode: DRAW_CONTROL_MODE;
  fillOpacity: number;
  freeDrawFeature?: ISegmentationFeature;
  freeDrawInProgress: boolean;
  freeDrawSegmentationService: IFreeDrawSegmentationService;
  isImprovedVisibilityCursorEnabled: boolean;
  selectorType: AnnotationToolType;
  stopActions: boolean;
  undoRedoHistory: IUndoRedoHistory;
  activeAnnotationTypeOptions?: IAnnotationTypeOptions;
  cursorPosition?: LatLng;
  selectedSegmentation?: ISegmentation;
  onDrawCopied(id: string): void;
  onDrawCreated(geojson: Feature): void;
  onDrawEdited(index: string | undefined, geojson: Feature): void;
  onDrawPasted(geojson: Feature, id: string): void;
  onDrawingRemove(id: string): void;
  onEditDisabled(): void;
  onEditEnabled(id: string): void;
  onFreeDrawCancel(): Promise<boolean>;
  onFreeDrawDelete(): void;
  onFreeDrawStarted(geojson: Feature): void;
  onMouseMove(latlng?: LatLng): void;
}

interface IProps extends IOuterProps, ControlOptions {
  leaflet: { map: Map };
}

class DrawControl extends MapControl<IProps> {
  private drawControl!: leaflet.Control.Draw;
  private drawer!: IDrawer | null;
  private map!: Map;
  private drawingEditor!: DrawingEditor;
  private frontVerticalGuide: L.Polyline | undefined = undefined;
  private backgroundVerticalGuide: L.Polyline | undefined = undefined;
  private frontHorizontalGuide: L.Polyline | undefined = undefined;
  private backgroundHorizontalGuide: L.Polyline | undefined = undefined;
  private currentCursorPosition: LatLng | undefined;

  get frontGuidesOptions(): PolylineOptions {
    return {
      className: 'cursor-guide',
      color: '#888888',
      dashArray: '4, 4',
      dashOffset: '4',
      opacity: 1.0,
      weight: 1,
      pane: 'markerPane',
    };
  }

  get backwardGuidesOptions(): PolylineOptions {
    return {
      className: 'cursor-guide',
      color: '#ffffff',
      dashArray: '4, 4',
      dashOffset: '0',
      opacity: 1.0,
      weight: 1,
      pane: 'markerPane',
    };
  }

  @autobind
  createLeafletElement() {
    leaflet.Editable.VertexIcon = (DivIcon.extend({
      options: {
        iconSize: new leaflet.Point(ICON_MARKER_SIZE, ICON_MARKER_SIZE),
        className: 'custom-edit-icon',
      },
    }) as any) as DivIcon;

    this.drawControl = new leaflet.Control.Draw({
      draw: false,
      editable: true,
      draggable: true,
    } as any);
    return this.drawControl;
  }

  componentDidMount() {
    if (super.componentDidMount) super.componentDidMount();
    this.map = this.props.leaflet.map;
    this.map.keyboard.disable();

    this.setDrawer(this.props);
    this.setDrawingEditor(this.props);

    this.addHandlers();

    if (this.props.controlMode === DRAW_CONTROL_MODE.CREATE) {
      this.disableEdit();
      this.enableDrawing();
    } else {
      this.disableDrawing();
    }

    this.map.editTools.options.vertexMarkerClass = EditVertexMarker;
    this.setDragging(this.props.selectorType);
  }

  // tslint:disable-next-line:function-name
  UNSAFE_componentWillUpdate(newProps: IProps) {
    this.setDrawer(newProps, false);

    if (!newProps.selectedSegmentation) {
      this.disableEdit();
    }

    if (newProps.stopActions && !this.props.stopActions) {
      this.disableEdit();
      this.disableDrawing();
    }

    if (newProps.controlMode === DRAW_CONTROL_MODE.CREATE) {
      this.disableEdit();
      this.enableDrawing();
    } else {
      this.disableDrawing();
    }

    this.setDragging(newProps.selectorType);
  }

  componentWillUnmount() {
    this.disableDrawing();
    this.setDragging(null);
    if (this.drawer) this.drawer.removeHandlers();
    if (this.drawingEditor) this.drawingEditor.removeHandlers();
    this.removeHandlers();
    this.setDefaultCursor();
  }

  @autobind
  setDrawingEditor(props: IProps) {
    if (this.drawingEditor) this.drawingEditor.removeHandlers();

    this.drawingEditor = new DrawingEditor(
      props.leaflet.map,
      {
        onDrawCopied: props.onDrawCopied,
        onDrawPasted: props.onDrawPasted,
        onDrawEdited: props.onDrawEdited,
        onDrawingRemove: props.onDrawingRemove,
        onEditEnabled: props.onEditEnabled,
        onEditDisabling: this.handleEditDisabling,
        onEditDisabled: this.handleEditDisabled,
      },
      this.props.undoRedoHistory,
    );
    this.drawingEditor.addHandlers();
  }

  @autobind
  setDrawer(props: IProps, reset: boolean = true) {
    if (
      reset ||
      this.props.activeAnnotationTypeId !== props.activeAnnotationTypeId ||
      (props.freeDrawFeature?.id !== this.props.freeDrawFeature?.id && !props.freeDrawInProgress)
    ) {
      if (this.drawer) {
        this.drawer.disable();
        this.drawer.removeHandlers();
        this.drawer = null;
      }

      if (props.selectorType) {
        this.drawer = DrawerFactory.createDrawer(
          this.map,
          props.selectorType,
          props.color,
          props.fillOpacity,
          props.activeAnnotationTypeId,
          props.cursorPosition,
          this.handleDrawCreated,
          props.onFreeDrawStarted,
          props.undoRedoHistory,
          props.annotationService,
          props.freeDrawSegmentationService,
          props.activeAnnotationTypeOptions,
          props.freeDrawFeature,
        );
      }

      if (this.drawer) {
        this.drawer.disableMouseMarkerFocus();
        this.drawer.addHandlers();
        this.drawer.enable();
      }
    } else if (this.drawer) {
      switch (props.selectorType) {
        case AnnotationToolType.BRUSH:
          const brush = this.drawer as DrawBrush;
          const options = props.activeAnnotationTypeOptions as IBrushTypeOptions;
          brush.updateOptions(options.brushDiameter, options.brushMode, props.fillOpacity);
          break;
        default:
          break;
      }
    }

    this.setCursorType(props.isImprovedVisibilityCursorEnabled, props.selectorType);

    if (!this.currentCursorPosition) this.currentCursorPosition = props.cursorPosition;

    this.clearGuides();
    const shouldDrawGuides = props.selectorType !== null && props.selectorType !== AnnotationToolType.SELECT && this.props.areCursorGuidesEnabled;
    if (shouldDrawGuides) this.drawGuides();
  }

  @autobind
  setCursorType(isImprovedVisibilityCursorEnabled: boolean, selectorType: AnnotationToolType) {
    this.setDefaultCursor();

    if (!selectorType) return;

    if (selectorType === AnnotationToolType.BRUSH) {
      leaflet.DomUtil.addClass(this.map._container, 'no-cursor-enabled');
      return;
    }

    const cursorClass = isImprovedVisibilityCursorEnabled ? 'custom-cursor-enabled' : 'crosshair-cursor-enabled';
    leaflet.DomUtil.addClass(this.map._container, cursorClass);
  }

  @autobind
  setDefaultCursor() {
    leaflet.DomUtil.removeClass(this.map._container, 'crosshair-cursor-enabled');
    leaflet.DomUtil.removeClass(this.map._container, 'custom-cursor-enabled');
    leaflet.DomUtil.removeClass(this.map._container, 'no-cursor-enabled');
  }

  @autobind
  setDragging(selectorType: AnnotationToolType | null) {
    this.map.dragging.removeHooks!();
    this.map.addHandler('dragging', DragEx);
    this.map.options.draggingButtons = MOUSE_BUTTONS.MIDDLE;
    if (selectorType === null) {
      this.map.options.draggingButtons = this.map.options.draggingButtons | MOUSE_BUTTONS.LEFT;
    }
    this.map.dragging.addHooks!();
    this.map.dragging.enable();
  }

  @autobind
  disableDrawing() {
    if (this.drawer) this.drawer.disable();
  }

  @autobind
  enableDrawing() {
    if (this.drawer) this.drawer.enable();
  }

  @autobind
  disableEdit() {
    if (this.drawingEditor) this.drawingEditor.disableEdit();
    this.drawingEditor.currentlyEditing = { polygon: undefined, edited: false };
  }

  addHandlers() {
    document.addEventListener('keyup', this.handleKeyboardUpEvents);
    document.addEventListener(MOUSE_EVENTS.MOUSE_MOVE, this.handleDocumentMouseMove as any);

    keyboardShortcutsServiceInstance.registerKeyUp('z', this.handleUndo);
    keyboardShortcutsServiceInstance.registerKeyUp('y', this.handleRedo);
    keyboardShortcutsServiceInstance.registerKeyDown('delete', this.handleDelete);
  }

  @autobind
  removeHandlers() {
    document.removeEventListener('keyup', this.handleKeyboardUpEvents);
    document.removeEventListener(MOUSE_EVENTS.MOUSE_MOVE, this.handleDocumentMouseMove as any);

    keyboardShortcutsServiceInstance.unregisterKeyUp('z', this.handleUndo);
    keyboardShortcutsServiceInstance.unregisterKeyUp('y', this.handleRedo);
    keyboardShortcutsServiceInstance.unregisterKeyDown('delete', this.handleDelete);
  }

  @autobind
  handleDocumentMouseMove(e: MouseEvent) {
    this.currentCursorPosition = calculateCurrentPosition(e, this.map);
    this.props.onMouseMove(this.currentCursorPosition);
    this.refreshGuides();
  }

  @autobind
  refreshGuides(): void {
    this.clearGuides();
    const shouldDrawGuides = this.props.selectorType !== null && this.props.selectorType !== AnnotationToolType.SELECT && this.props.areCursorGuidesEnabled;
    if (shouldDrawGuides) this.drawGuides();
  }

  @autobind
  drawGuides(): void {
    if (!this.currentCursorPosition) return;

    this.backgroundVerticalGuide = new leaflet.Polyline(
      [new leaflet.LatLng(-30000, this.currentCursorPosition.lng), new leaflet.LatLng(30000, this.currentCursorPosition.lng)],
      this.backwardGuidesOptions,
    );
    this.backgroundVerticalGuide.addTo(this.map);

    this.frontVerticalGuide = new leaflet.Polyline(
      [new leaflet.LatLng(-30000, this.currentCursorPosition.lng), new leaflet.LatLng(30000, this.currentCursorPosition.lng)],
      this.frontGuidesOptions,
    );
    this.frontVerticalGuide.addTo(this.map);

    this.backgroundHorizontalGuide = new leaflet.Polyline(
      [new leaflet.LatLng(this.currentCursorPosition.lat, -30000), new leaflet.LatLng(this.currentCursorPosition.lat, 30000)],
      this.backwardGuidesOptions,
    );
    this.backgroundHorizontalGuide.addTo(this.map);

    this.frontHorizontalGuide = new leaflet.Polyline(
      [new leaflet.LatLng(this.currentCursorPosition.lat, -30000), new leaflet.LatLng(this.currentCursorPosition.lat, 30000)],
      this.frontGuidesOptions,
    );
    this.frontHorizontalGuide.addTo(this.map);
  }

  @autobind
  private clearGuides(): void {
    this.frontVerticalGuide?.removeFrom(this.map);
    this.backgroundVerticalGuide?.removeFrom(this.map);
    this.frontHorizontalGuide?.removeFrom(this.map);
    this.backgroundHorizontalGuide?.removeFrom(this.map);
  }

  @autobind
  handleDrawCreated(geojson: Feature): void {
    this.props.onDrawCreated(geojson);

    if (FREE_DRAW_TOOLS.includes(this.props.selectorType)) {
      return;
    }

    this.setDrawer(this.props);
    this.setDragging(this.props.selectorType);
  }

  @autobind
  async handleEditDisabling(): Promise<boolean> {
    return FREE_DRAW_TOOLS.includes(this.props.selectorType) && !(await this.props.onFreeDrawCancel());
  }

  @autobind
  handleEditDisabled() {
    this.props.onEditDisabled();
  }

  @action.bound
  handleUndo(e: KeyboardEvent) {
    if (e.ctrlKey || e.metaKey) this.props.undoRedoHistory.undo();
  }

  @action.bound
  handleRedo(e: KeyboardEvent) {
    if (e.ctrlKey || e.metaKey) this.props.undoRedoHistory.redo();
  }

  @autobind
  handleKeyboardUpEvents(e: KeyboardEvent) {
    switch (e.key) {
      case 'Escape':
        this.setDrawer(this.props);
        setTimeout(() => this.enableDrawing(), 200);
        break;
    }
  }

  @autobind
  handleDelete(e: KeyboardEvent) {
    if (FREE_DRAW_TOOLS.includes(this.props.selectorType) && this.props.selectedSegmentation && this.props.freeDrawFeature) {
      this.props.onFreeDrawDelete();
      this.props.onDrawingRemove(this.props.selectedSegmentation.feature.id);
      this.props.undoRedoHistory.addCommand(this.props.undoRedoHistory.getNewChangeStateCommand());
    }
  }
}

export default as<React.ComponentClass<IOuterProps>>(withLeaflet(DrawControl));
