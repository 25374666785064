import {
  IAccountRechargedNotification,
  IAttachmentsUploadNotification,
  IBatchAnswerQuestionsFailedNotification,
  IBatchAnswerQuestionsFinishedNotification,
  IClarifiedNotification,
  IDatasetUploadAbortedNotification,
  IDatasetUploadCompletedNotification,
  IDatasetUploadCompletedWithInfoNotification,
  IDatasetUploadFailedNotification,
  IHubNotification,
  IPaymentFailedNotification,
  IPaymentPendingNotification,
  IProjectExportFinishedNotification,
  NotificationLevel,
  NotificationType,
} from '../models/notification.model';

import AttachmentFailedFilesSummary from '../components/AttachmentFailedFilesSummary';
import { INotificationMapper } from '../containers/NotificationsHub.container';
import React from 'react';
import UrlsProcessingSummary from '../components/UrlsProcessingSummary';

export class HubNotificationMapper {
  static toNotification(hubNotification: IHubNotification): INotificationMapper | undefined {
    switch (hubNotification.type) {
      case NotificationType.DatasetUploadCompleted:
        return this.getDatasetUploaded(hubNotification as IDatasetUploadCompletedNotification);

      case NotificationType.DatasetCompletedWithInfo:
        return this.getDatasetUploadedWithInfo(hubNotification as IDatasetUploadCompletedWithInfoNotification);

      case NotificationType.DatasetUploadFailed:
        return this.getDatasetUploadFailed(hubNotification as IDatasetUploadFailedNotification);

      case NotificationType.DatasetUploadAborted:
        return this.getDatasetUploadAborted(hubNotification as IDatasetUploadAbortedNotification);

      case NotificationType.PaymentPending:
        return this.getPaymentPending(hubNotification as IPaymentPendingNotification);

      case NotificationType.PaymentFailed:
        return this.getPaymentFailed(hubNotification as IPaymentFailedNotification);

      case NotificationType.AccountRecharged:
        return this.getAccountRecharged(hubNotification as IAccountRechargedNotification);

      case NotificationType.AttachmentsUploadCompleted:
        return this.getAttachmentsUploaded(hubNotification as IAttachmentsUploadNotification);

      case NotificationType.AttachmentsUploadFailed:
        return this.getAttachmentsUploadFailed(hubNotification as IAttachmentsUploadNotification);

      case NotificationType.SomeAttachmentsUploadFailed:
        return this.getSomeAttachmentsUploadFailed(hubNotification as IAttachmentsUploadNotification);

      case NotificationType.BatchAnswerQuestionsFinished:
        return this.getBatchAnswerQuestionsFinished(hubNotification as IBatchAnswerQuestionsFinishedNotification);

      case NotificationType.BatchAnswerQuestionsFailed:
        return this.getBatchAnswerQuestionsFailed(hubNotification as IBatchAnswerQuestionsFailedNotification);

      case NotificationType.Clarified:
        return this.getClarified(hubNotification as IClarifiedNotification);

      case NotificationType.ProjectExportFinished:
        return this.getProjectExportFinished(hubNotification as IProjectExportFinishedNotification);
    }
  }

  static getDatasetUploaded(notification: IDatasetUploadCompletedNotification) {
    return {
      title: { template: 'datasets:dataset_upload_completed' },
      body: { template: 'datasets:upload_of_x_completed', data: { datasetName: notification.datasetName } },
      level: NotificationLevel.SUCCESS,
      wasRead: notification.wasRead,
    };
  }

  static getAttachmentsUploaded(notification: IAttachmentsUploadNotification) {
    return {
      title: { template: 'attachment_uploads_have_finished' },
      body: { template: 'upload_of_x_files_completed', data: { count: notification.uploadedCount } },
      level: NotificationLevel.SUCCESS,
      wasRead: notification.wasRead,
    };
  }

  static getAttachmentsUploadFailed(notification: IAttachmentsUploadNotification) {
    return {
      title: { template: 'attachment_uploads_have_failed' },
      body: React.createElement(AttachmentFailedFilesSummary, {
        failedFiles: notification.failedFiles,
        uploadedCount: notification.uploadedCount,
      }),
      level: NotificationLevel.ERROR,
      wasRead: notification.wasRead,
    };
  }

  static getSomeAttachmentsUploadFailed(notification: IAttachmentsUploadNotification) {
    return {
      title: { template: 'some_attachments_upload_failed' },
      body: React.createElement(AttachmentFailedFilesSummary, {
        failedFiles: notification.failedFiles,
        uploadedCount: notification.uploadedCount,
      }),
      level: NotificationLevel.WARNING,
      wasRead: notification.wasRead,
    };
  }

  static getDatasetUploadedWithInfo(notification: IDatasetUploadCompletedWithInfoNotification) {
    return {
      level: NotificationLevel.WARNING,
      title: { template: 'datasets:dataset_upload_completed' },
      body: React.createElement(UrlsProcessingSummary, {
        datasetName: notification.datasetName,
        rejectedUrls: notification.rejectedUrls,
      }),
      wasRead: notification.wasRead,
    };
  }

  static getDatasetUploadAborted(notification: IDatasetUploadAbortedNotification) {
    return {
      title: { template: 'datasets:dataset_upload_was_aborted' },
      body: { template: 'datasets:upload_of_x_aborted', data: { datasetName: notification.datasetName } },
      level: NotificationLevel.WARNING,
      wasRead: notification.wasRead,
    };
  }

  static getDatasetUploadFailed(notification: IDatasetUploadFailedNotification) {
    return {
      title: { template: 'datasets:dataset_upload_failed' },
      body: { template: 'datasets:upload_of_x_failed', data: { datasetName: notification.datasetName } },
      level: NotificationLevel.ERROR,
      wasRead: notification.wasRead,
    };
  }

  static getPaymentPending(notification: IPaymentPendingNotification) {
    return {
      title: { template: 'billing:payment_pending' },
      body: {
        template: 'billing:payment_pending_body',
        data: {},
      },
      level: NotificationLevel.WARNING,
      wasRead: notification.wasRead,
    };
  }

  static getPaymentFailed(notification: IPaymentFailedNotification) {
    return {
      title: { template: 'billing:payment_failed' },
      body: {
        template: 'billing:payment_failed_body',
        data: {},
      },
      level: NotificationLevel.ERROR,
      wasRead: notification.wasRead,
    };
  }

  static getAccountRecharged(notification: IAccountRechargedNotification) {
    return {
      title: { template: 'billing:account_recharged' },
      body: {
        template: 'billing:account_recharged_body',
        data: { credits: notification.credits, newLimit: notification.newLimit },
      },
      level: NotificationLevel.SUCCESS,
      wasRead: notification.wasRead,
    };
  }

  static getBatchAnswerQuestionsFinished(notification: IBatchAnswerQuestionsFinishedNotification) {
    return {
      title: { template: 'common:batch_answer_questions_completed_notification_title' },
      body: {
        template: 'common:batch_answer_questions_completed_notification_body',
        data: {},
      },
      level: NotificationLevel.SUCCESS,
      wasRead: notification.wasRead,
    };
  }

  static getBatchAnswerQuestionsFailed(notification: IBatchAnswerQuestionsFailedNotification) {
    return {
      title: { template: 'common:batch_answer_questions_failed_notification_title' },
      body: {
        template: 'common:batch_answer_questions_failed_notification_body',
        data: {},
      },
      level: NotificationLevel.ERROR,
      wasRead: notification.wasRead,
    };
  }

  static getClarified(notification: IClarifiedNotification) {
    return {
      title: { template: 'common:clarifications.notifications.clarification_answered.title' },
      body: {
        template: 'common:clarifications.notifications.clarification_answered.body',
        data: { projectName: notification.projectName, imageName: notification.imageName },
      },
      level: NotificationLevel.SUCCESS,
      wasRead: notification.wasRead,
    };
  }

  static getProjectExportFinished(notification: IProjectExportFinishedNotification) {
    return {
      title: { template: 'common:new.projects.details.export_data.notification_header' },
      body: {
        template: 'common:new.projects.details.export_data.notification_body',
        data: { projectName: notification.projectName },
      },
      level: NotificationLevel.SUCCESS,
      wasRead: notification.wasRead,
      exportDataFilter: notification.dataFilterType,
      projectExportId: notification.projectExportId,
    };
  }
}
