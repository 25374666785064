﻿import * as React from 'react';

import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IImageFile } from '../imagesUpload.service';
import { IRejectedFile } from '../models/IRejectedFile';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { ImagesDropZone } from './ImagesDropZone';
import { as } from '../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IProps {
  datasetId: string;
  isProcessingImages: boolean;
  imagesToUploadFromFile: IImageFile[];
  imagesRejectedFromFile: IRejectedFile[];
  cleanImagesFromUrl(datasetId: string): void;
  addImagesToDataset(images: File[]): void;
}

@observer
class ImageUploaderPure extends React.Component<IProps & ITranslatable> {

  componentDidMount() {
    this.props.cleanImagesFromUrl(this.props.datasetId);
  }

  @autobind
  async handleImageDrop(images: File[]) {
    await this.props.addImagesToDataset(images);
  }

  render() {
    return (
      <DisabledWhenWorkspaceOwnerPolicyExceeded>
        <ImagesDropZone
          acceptedFileTypes={'.jpg, .jpeg, .png, .bmp'}
          acceptedFiles={this.props.imagesToUploadFromFile.map(i => i.file)}
          rejectedFiles={this.props.imagesRejectedFromFile}
          isProcessingImages={this.props.isProcessingImages}
          onFilesDrop={this.handleImageDrop}
        >
          <FontAwesomeIcon icon={faUpload} />
          {this.props.t('drop_your_files')}
        </ImagesDropZone>
      </DisabledWhenWorkspaceOwnerPolicyExceeded>
    );
  }
}

export const ImageUploader = as<React.ComponentClass<IProps>>(withNamespaces('datasets')(ImageUploaderPure));
