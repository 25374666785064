import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { EvaluationSettingsFormContainer } from '../containers/EvaluationSettingsForm.container';

export const EvaluationSettings = () => {
  return (
    <div>
      <Row>
        <Col xs={6}>
          <EvaluationSettingsFormContainer />
        </Col>
      </Row>
    </div>
  );
};