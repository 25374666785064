import { Col, Row } from 'reactstrap';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { ITeamPermissions, TeamPermissionsType } from '../team.permissions';
import { ITeamService, TeamServiceType } from '../team.bl';
import { ITeamStore, TeamStoreType } from '../team.store';
import { IUserStore, UserStoreType } from '../../user/user.store';
import { InputStatus, StickerError } from '../../../models/error.model';
import { as, injectProps } from '../../../helpers/react.helpers';

import { AddTeamMember } from '../components/AddTeamMember';
import { ChangeUserRoleModal } from '../components/ChangeUserRoleModal';
import { Home } from '../../../routes/config/Home';
import { Loader } from '../../../components/Loader';
import { NewStatisticsWarningRowContainer } from '../../../../modules/common/NewStatisticsWarningRow.container';
import React from 'react';
import { Redirect } from 'react-router';
import { RemoveUserModal } from '../components/RemoveUserModal';
import { Settings } from '../../../../modules/settings/SettingsContext';
import { SortingDirection } from '../../../models/sortingDirection.model';
import { StatisticsTimeFrameSwitchContainer } from './StatisticsTimeFrameSwitch.container';
import { Team } from '../components/TeamList';
import { WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import autobind from 'autobind-decorator';
import { getEmailStatus } from '../../../helpers/email.helpers';
import { observer } from 'mobx-react';

interface IState {
  email: string;
  emailStatus: InputStatus;
  selectedRole: WorkspaceRole;
  selectedChangeRole: WorkspaceRole;
  removeUserModalShown: boolean;
  userSelectedToRemove: string;
  changeUserRoleModalShown: boolean;
  userSelectedToChange: string;
  userNameSelectedToChange: string;
  changeRoleError: string;
  errorContext: object;
  isLoading: boolean;
}

interface IInjectedProps {
  teamService: ITeamService;
  userStore: IUserStore;
  teamStore: ITeamStore;
  teamPermissions: ITeamPermissions;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

@injectProps({
  userStore: UserStoreType,
  teamService: TeamServiceType,
  teamStore: TeamStoreType,
  teamPermissions: TeamPermissionsType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
@observer
class TeamContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  static contextType = Settings;
  declare context: React.ContextType<typeof Settings>;

  constructor(props: IInjectedProps) {
    super(props);

    this.state = {
      email: '',
      emailStatus: InputStatus.empty(),
      selectedRole: WorkspaceRole.Collaborator,
      selectedChangeRole: WorkspaceRole.Collaborator,
      removeUserModalShown: false,
      userSelectedToRemove: '',
      changeUserRoleModalShown: false,
      userSelectedToChange: '',
      userNameSelectedToChange: '',
      changeRoleError: '',
      errorContext: {},
      isLoading: false,
    };
  }

  async componentDidMount() {
    await this.props.teamService.initializeAsync();
  }

  componentWillUnmount() {
    this.props.teamService.dispose();
  }

  @autobind
  handleEmailChange(email: string) {
    this.setState({ email });
    const emailStatus = getEmailStatus(email, [], true);
    this.setState({ emailStatus });
  }

  @autobind
  handleRoleSelected(role: WorkspaceRole): void {
    this.setState({ selectedRole: role });
  }

  @autobind
  handleChangeRoleSelected(role: WorkspaceRole): void {
    this.setState({ selectedChangeRole: role, changeRoleError: '' });
  }

  @autobind
  async handleAddNewUser() {
    if (!this.state.emailStatus.isValid || this.state.isLoading) return;

    await this.withLoaderAsync(async () => {
      const result = await this.props.teamService.addUserToWorkspaceAsync(this.state.email, this.state.selectedRole);

      if (result instanceof StickerError) {
        this.setState({
          errorContext: result.apiErrorResponse?.errorContext || {},
          emailStatus: InputStatus.buildFrom([this.getUniqueLimitError(result!.apiErrorResponse!.errorCodes[0])]),
        });
      } else {
        this.setState({ email: '', emailStatus: InputStatus.empty() });
      }
    }, 'add-user-button');
  }

  @autobind
  getUniqueLimitError(errorCode: string): string {
    const canSeeOwnerErrors = this.props.teamPermissions.canSeeOwnerErrors();

    return canSeeOwnerErrors && (errorCode === 'UNIQUE_WORKERS_LIMIT_REACHED' || errorCode === 'UNIQUE_USERS_LIMIT_REACHED') ? `${errorCode}_OWNER` : errorCode;
  }

  @autobind
  toggleRemoveUserModal(email: string) {
    this.setState(
      (prevState: IState): IState => ({
        ...prevState,
        removeUserModalShown: !prevState.removeUserModalShown,
        userSelectedToRemove: email,
      }),
    );
  }

  @autobind
  async handleRemoveUser() {
    if (this.state.isLoading) return;

    await this.withLoaderAsync(async () => {
      await this.props.teamService.removeUserFromWorkspaceAsync(this.state.userSelectedToRemove);
      this.setState({ userSelectedToRemove: '', removeUserModalShown: false });
      this.clearErrors();
    }, 'remove-user-button');
  }

  @autobind
  toggleChangeUserRoleModal(email: string) {
    const user = this.props.teamStore.currentPageTeamMembers.find(m => m.email === email);

    this.setState(
      (prevState: IState): IState => ({
        ...prevState,
        changeUserRoleModalShown: !prevState.changeUserRoleModalShown,
        userSelectedToChange: email,
        userNameSelectedToChange: user && user.name ? user.name : '',
        selectedChangeRole: user ? user.role : WorkspaceRole.Collaborator,
        changeRoleError: '',
      }),
    );
  }

  @autobind
  async handleChangeUserRole() {
    if (this.state.isLoading) return;

    await this.withLoaderAsync(async () => {
      const result = await this.props.teamService.changeUserRoleAsync(this.state.userSelectedToChange, this.state.selectedChangeRole);

      if (result instanceof StickerError) {
        this.setState({ changeRoleError: this.getUniqueLimitError(result!.apiErrorResponse!.errorCodes[0]) });
      } else {
        this.setState({ userSelectedToChange: '', changeUserRoleModalShown: false });
        this.clearErrors();
      }
    }, 'change-user-role-button');
  }

  @autobind
  handleChangeSorting(orderBy: string, orderDirection: SortingDirection) {
    this.props.teamService.changeSorting(orderBy, orderDirection);
  }

  @autobind
  clearErrors() {
    const canManageTeamMembers = this.props.teamPermissions.canManageTeamMembers();

    if (!canManageTeamMembers) {
      this.setState({ email: '', emailStatus: InputStatus.empty() });
    } else if (this.state.email !== '') {
      this.setState({ emailStatus: getEmailStatus(this.state.email, [], true) });
    }
  }

  @autobind
  private handlePaginationChange(pageNumber: number, pageSize: number) {
    this.props.teamService.changePagination(pageNumber, pageSize);
    this.context.setTeamPageSize(pageSize);
  }

  render() {
    const canViewTeamMembers = this.props.teamPermissions.canViewTeamMembers();
    const canManageTeamMembers = this.props.teamPermissions.canManageTeamMembers();

    if (!this.props.currentWorkspaceStore.currentWorkspace?.id) return null;

    return !canViewTeamMembers ? (
      <Redirect to={Home.Projects.List.All.withParams({ workspaceId: this.props.currentWorkspaceStore.currentWorkspace?.id })} />
    ) : (
      <>
        {this.props.currentWorkspaceStore.currentWorkspace.isCreatedBeforeStatsRelease && (
          <Row>
            <Col>
              <NewStatisticsWarningRowContainer />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <AddTeamMember
              email={this.state.email}
              emailStatus={this.state.emailStatus}
              selectedRole={this.state.selectedRole}
              currentWorkspaceId={this.props.currentWorkspaceStore.currentWorkspace?.id}
              onRoleSelected={this.handleRoleSelected}
              onAddNewUser={this.handleAddNewUser}
              onEmailChange={this.handleEmailChange}
              clearErrors={this.clearErrors}
              errorContext={this.state.errorContext}
              canAddUsers={canManageTeamMembers}
            />
          </Col>
          <Col md="auto" className="right-buttons">
            <Row>
              <StatisticsTimeFrameSwitchContainer />
            </Row>
          </Col>
        </Row>
        <Loader isLoading={this.props.teamStore.isLoading}>
          <Team
            currentWorkspaceId={this.props.currentWorkspaceStore.currentWorkspace.id}
            currentUserEmail={this.props.userStore.userInfo.email}
            teamMembers={this.props.teamStore.currentPageTeamMembers.slice()}
            canEditUsers={canManageTeamMembers}
            onToggleRemoveUserModal={this.toggleRemoveUserModal}
            onToggleChangeUserRoleModal={this.toggleChangeUserRoleModal}
            onSortChange={this.handleChangeSorting}
            orderBy={this.props.teamStore.orderBy}
            orderDirection={this.props.teamStore.orderDirection}
            pagination={{
              ...this.props.teamStore.pagination,
              pageSize: this.context.store.teamPageSize,
              onChange: this.handlePaginationChange,
            }}
          />
        </Loader>
        <RemoveUserModal removeUserModalShown={this.state.removeUserModalShown} onRemoveUser={this.handleRemoveUser} onToggleRemoveUserModal={this.toggleRemoveUserModal} />
        <ChangeUserRoleModal
          changeUserRoleModalShown={this.state.changeUserRoleModalShown}
          selectedChangeRole={this.state.selectedChangeRole}
          onChangeRoleSelected={this.handleChangeRoleSelected}
          onChange={this.handleChangeUserRole}
          onToggleChangeUserRoleModal={this.toggleChangeUserRoleModal}
          userSelectedToChange={this.state.userSelectedToChange}
          userNameSelectedToChange={this.state.userNameSelectedToChange}
          changeRoleError={this.state.changeRoleError}
          currentPlan={this.props.userStore.planInfo.plan}
        />
      </>
    );
  }
}

export const TeamContainer = as<React.ComponentClass>(TeamContainerPure);
