import * as React from 'react';

import { DashboardContainer } from '../containers/DashboardContainer';
import LockedContainer from '../modules/locked/Locked.container';

export const LockedView = () => (
  <DashboardContainer header={undefined}>
    <LockedContainer />
  </DashboardContainer>
);
