import React, { useEffect } from 'react';
import { dateToUTC, getCurrentUTCTime } from '../../../../../__legacy__/helpers/date.helpers';

import { Alert } from '../../../models/Alert';

interface IProps {
  alert: Alert;
  onAlertOutdated(alert: Alert): void;
}

export const TextAlert: React.FC<IProps> = ({ alert, onAlertOutdated }: IProps) => {
  const checkForOutdate = () => {
    setTimeout(() => {
      const countdownDate = dateToUTC(alert.validUntil).getTime();
      const countDown = countdownDate - getCurrentUTCTime().getTime();

      if (countDown > 0) {
        checkForOutdate();
      } else {
        onAlertOutdated(alert);
      }
    }, 60000); // once per minute
  };

  useEffect(checkForOutdate, []);

  return (
    <div className="alerts-bar-alert" key={alert.id}>
      {alert.text}
    </div>
  );
};
