import * as React from 'react';

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { ISettingsContext, Settings } from '../../../../modules/settings/SettingsContext';
import { as, injectProps } from '../../../helpers/react.helpers';

import { DatasetDetails } from '../datasetDetails.context';
import { DatasetDetailsProjectsList } from '../components/DatasetDetailsProjectsList';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps {
  workspaceStore: ICurrentWorkspaceStore;
}

@injectProps({
  workspaceStore: CurrentWorkspaceStoreType,
})
@observer
class DatasetDetailsProjectsTabContainerPure extends WithLoaderComponentBase<IInjectedProps, ILoaderState> {
  static contextType = DatasetDetails;
  declare context: React.ContextType<typeof DatasetDetails>;

  private get datasetId() {
    return this.context.route.match.params.datasetId;
  }

  componentDidMount() {
    this.withLoaderAsync(() => this.context.service.getDatasetProjectsAsync(this.datasetId));
  }

  @autobind
  async handleOrderingChange(orderBy: string, orderType: string) {
    await this.withLoaderAsync(() => this.context.service.changeDatasetProjectsOrder(this.datasetId, orderBy, orderType));
  }

  @autobind
  getHandlePaginationChange(settingsContext: ISettingsContext) {
    return async (pageNumber: number, pageSize: number) => {
      await this.withLoaderAsync(() => {
        settingsContext.setDatasetDetailsProjectsPageSize(pageSize);
        this.context.service.changeDatasetProjectsPagination(this.datasetId, pageNumber, pageSize);
      });
    };
  }

  render() {
    const { detailsProjects, detailsProjectsPaging } = this.context.store;

    return (
      <Settings.Consumer>
        {settings => {
          const handlePaginationChange = this.getHandlePaginationChange(settings);

          return (
            <DatasetDetailsProjectsList
              datasetProjects={detailsProjects.slice()}
              workspaceId={this.props.workspaceStore.currentWorkspace!.id}
              isLoading={this.state.isLoading}
              onSortingChange={this.handleOrderingChange}
              pagination={{
                pageNumber: detailsProjectsPaging.pageNumber,
                pageSize: settings.store.datasetDetailsProjectsPageSize,
                totalCount: detailsProjectsPaging.totalCount,
                onChange: handlePaginationChange,
              }}
              sorting={{
                orderBy: detailsProjectsPaging.orderBy || '',
                orderType: detailsProjectsPaging.orderType || '',
              }}
            />
          );
        }}
      </Settings.Consumer>
    );
  }
}

export const DatasetDetailsProjectsTabContainer = as<React.ComponentClass>(DatasetDetailsProjectsTabContainerPure);
