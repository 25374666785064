import { ImportAnnotationsBl, ImportAnnotationsBlType } from '../../../importAnnotations.bl';
import { ImportAnnotationsStore, ImportAnnotationsStoreType } from '../../../importAnnotations.store';

import { ImportAnnotationsStep } from '../../../models/ImportAnnotationsSteps';
import React from 'react';
import { SummaryStepModal } from './SummaryStepModal';
import { as } from '../../../../../../../../common/dependencyInjection/as';
import { injectProps } from '../../../../../../../../__legacy__/helpers/react.helpers';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: ImportAnnotationsBl;
  store: ImportAnnotationsStore;
}

@injectProps({
  bl: ImportAnnotationsBlType,
  store: ImportAnnotationsStoreType,
})
@observer
export class SummaryStepContainerPure extends React.Component<InjectedProps> {
  constructor(props: InjectedProps) {
    super(props);
  }

  handleHide = () => {
    this.props.bl.finishImport();
  };

  get isCurrentStep() {
    return this.props.store.currentStep === ImportAnnotationsStep.Summary;
  }

  render() {
    return (
      <SummaryStepModal
        onHide={this.props.bl.finishImport}
        show={this.isCurrentStep}
      />
    );
  }
}

export const SummaryStepContainer = as<React.ComponentClass>(SummaryStepContainerPure);
