import { ConfigurationType, IConfiguration } from '../../configuration';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import React from 'react';
import { S_WarningRow } from '../../design/warningRow/S_WarningRow';
import { as } from '../../common/dependencyInjection/as';
import { injectProps } from '../../__legacy__/helpers/react.helpers';
import { toLocaleDateString } from '../../__legacy__/helpers/date.helpers';

interface IInjectedProps {
  configuration: IConfiguration;
}

@injectProps({
  configuration: ConfigurationType,
})
class NewStatisticsWarningRowContainerPure extends React.Component<IInjectedProps & WithNamespaces> {
  render() {
    const date = toLocaleDateString(this.props.configuration.appConfig.newStatisticsDate);
    const text = `${this.props.t('common.new_statistics_warning_row.info')} ${this.props.t('common.new_statistics_warning_row.message', { date })}`;
    return <S_WarningRow text={text} />;
  }
}

export const NewStatisticsWarningRowContainer = as<React.ComponentClass>(withNamespaces('new')(NewStatisticsWarningRowContainerPure));
