import { ApiServiceImageUploadType } from '../../services/api.service.imageUpload';
import { IApiService } from '../../services/api.service.base';
import { Failed, ForbiddenError, LockedUserError, OperationResult, StickerError, UnauthorizedError, UploadSuccess } from '../../models/error.model';
import { inject, injectable } from 'inversify';
import { IUploadedImage } from '../datesets/uploadServiceBase';
import { queue } from 'async';
import autobind from 'autobind-decorator';
import { action } from 'mobx';
import uuid from 'uuid';
import { INotificationsService, NotificationsServiceType } from '../notifications/services/notifications.service';
import { AttachmentsUploadNotification, NotificationLevel, ToastNotification } from '../notifications/models/notification.model';
import { ConfigurationType, IConfiguration } from '../../../configuration';
import axios, { AxiosRequestConfig } from 'axios';
import { AttachmentUploadStoreType, IAttachmentUploadData, IAttachmentUploadStore, IWillAttachmentUploadExceedsLimitDto, UploadStatus } from './attachmentUploadStore';
import { AuthStoreType, IAuthStore } from '../auth/auth.store';
import { generateThumbnail, isAllowedImageType, stripExifTags, toMBytes } from '../datesets/helpers/image.helpers';
import { CryptoServiceType, ICryptoService } from '../../services/crypto.service';
import { AttachmentsStoreType, IAttachmentsStore } from './attachments.store';
import { decodeImageAsync } from '../../helpers/imageDecoder.helpers';
import { ICurrentWorkspaceStore, CurrentWorkspaceStoreType } from '../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';

export const AttachmentUploadServiceType = Symbol('ATTACHMENT_UPLOAD_SERVICE');

interface IDownloadAttachmentsPayload {
  workspaceId: string;
  allAttachments: boolean;
  parentId?: string;
  attachmentIds: string[];
}

export interface IAttachmentUploadService {
  startUpload(onAllUploadedCallback: () => void): void;
  prepareFilesAsync(files: File[], parentId: string | undefined): Promise<void>;
  cancelUpload(attachmentId: string): void;
  downloadAttachmentsAsync(allAttachments: boolean, attachmentIds: string[], parentId?: string): Promise<void>;
  data: IAttachmentUploadStore;
}

@injectable()
export class AttachmentUploadService implements IAttachmentUploadService {
  constructor(
    @inject(AttachmentUploadStoreType) public readonly data: IAttachmentUploadStore,
    @inject(AttachmentsStoreType) public readonly attachmentsStore: IAttachmentsStore,
    @inject(ApiServiceImageUploadType) protected readonly apiServiceImageUpload: IApiService,
    @inject(NotificationsServiceType) protected readonly notificationService: INotificationsService,
    @inject(ConfigurationType) protected readonly configuration: IConfiguration,
    @inject(AuthStoreType) protected readonly authStore: IAuthStore,
    @inject(CryptoServiceType) protected readonly cryptoService: ICryptoService,
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore,
  ) {}
  async downloadAttachmentsAsync(allAttachments: boolean, attachmentIds: string[], parentId?: string): Promise<void> {
    const workspaceId = this.currentWorkspaceStore.currentWorkspace!.id;
    const result = await this.apiServiceImageUpload.postAsync<IDownloadAttachmentsPayload, string | StickerError>('/Attachments/generateDownloadAttachmentId', {
      workspaceId,
      allAttachments,
      parentId,
      attachmentIds,
    });

    if (result instanceof StickerError) {
      if (result instanceof UnauthorizedError || result instanceof ForbiddenError || result instanceof LockedUserError) {
        throw result;
      }

      this.notificationService.push(new ToastNotification(NotificationLevel.ERROR, 'generating_attachments_url_failed'));
      return;
    }

    this.attachmentsStore.clearSelectedAttachments();

    const downloadUrl = this.apiServiceImageUpload.getUrl(`/Attachments/downloadAttachment?downloadId=${result}&access_token=${this.authStore.token}`);
    window.open(downloadUrl, '_self');
  }

  @action.bound
  public startUpload(onAllUploadedCallback: () => void): void {
    this.setupQueue(onAllUploadedCallback);
    for (const preparedFile of this.data.preparedFiles.filter(x => x.status === UploadStatus.Valid)) {
      const id = uuid.v4();
      this.data.uploadQueue.push({ id, file: preparedFile.file, workspaceId: preparedFile.workspaceId, parentId: preparedFile.parentId }, () => {});
      this.data.progressData.push({ id, name: preparedFile.file.name, progress: 0, isSuccess: true });
    }
  }

  @action.bound
  public async prepareFilesAsync(files: File[], parentId: string | undefined): Promise<void> {
    const workspaceId = this.currentWorkspaceStore.currentWorkspace!.id;

    const preparedFilesSize = this.data.preparedFiles.filter(x => x.status === UploadStatus.Valid).reduce((sum, current) => sum + current.file.size, 0);
    const newFilesSize = files.reduce((sum, current) => sum + current.size, 0);

    this.data.willExceedsLimit = await this.willUploadExceedLimitsAsync(workspaceId, toMBytes(preparedFilesSize + newFilesSize));

    const duplicateNames = [
      ...(await this.getDuplicatedAttachmentNamesAsync(
        workspaceId,
        parentId,
        files.map(x => x.name),
      )),
      ...this.data.preparedFiles.filter(x => x.status === UploadStatus.Valid).map(x => x.file.name),
    ];

    for (const file of files) {
      let status = UploadStatus.Valid;
      if (duplicateNames.includes(file.name)) {
        status = UploadStatus.DuplicatedName;
      } else if (toMBytes(file.size) > 1024) {
        status = UploadStatus.InvalidSize;
      }
      this.data.preparedFiles.push({ file, status, workspaceId, parentId });
    }
  }

  @action.bound
  public cancelUpload(attachmentId: string): void {
    this.data.uploadQueue.remove(x => x.data.id === attachmentId);

    const currentUploadIndex = this.data.currentUploads.findIndex(x => x.id === attachmentId);
    if (currentUploadIndex !== -1) {
      this.data.currentUploads[currentUploadIndex].cancelToken.cancel();
      this.data.currentUploads.splice(currentUploadIndex, 1);
    }

    const progressIndex = this.data.progressData.findIndex(x => x.id === attachmentId);

    this.notificationService.push(
      new ToastNotification(
        NotificationLevel.INFO,
        { template: 'attachment_upload_canceled' },
        { template: 'upload_of_attachment_x_canceled', data: { fileName: this.data.progressData[progressIndex].name } },
      ),
    );

    this.data.progressData.splice(progressIndex, 1);
  }

  // dumb inversify construct all services on start, so the configuration object is not initialized at that point
  private setupQueue(onAllUploadedCallback: () => void) {
    if (!this.data.uploadQueue) {
      this.data.uploadQueue = queue(this.uploadFileAsync, this.configuration.appConfig.concurrentAttachmentUploadsCount);
    }
    this.data.uploadQueue.drain = () => this.onAllItemsUploadedAsync(onAllUploadedCallback);
  }

  @action.bound
  private updateProgress(id: string, progress: number) {
    const progressData = this.data.progressData.find(x => x.id === id)!;
    progressData.progress = progress;
  }

  @autobind
  private async uploadFileAsync(data: IAttachmentUploadData, onUploadItemFinished: () => void): Promise<void> {
    const result = await this.performUploadAsync(data);

    const progressData = this.data.progressData.find(x => x.id === data.id);
    if (progressData) {
      progressData.isSuccess = result.isSuccess;
      progressData.progress = 100;
    }

    onUploadItemFinished();
  }

  @autobind
  private async performUploadAsync(data: IAttachmentUploadData): Promise<OperationResult> {
    try {
      const encrypt = this.cryptoService.hasKey(this.currentWorkspaceStore.currentWorkspace!.id);

      const formData = new FormData();

      const params: { [k: string]: any } = {
        workspaceId: data.workspaceId,
        parentId: data.parentId,
        isEncrypted: encrypt,
        fileName: data.file.name,
      };

      if (isAllowedImageType(data.file.name)) {
        try {
          const strippedImage = await stripExifTags(data.file);
          const strippedImageBuffer = await strippedImage.arrayBuffer();
          formData.append('file', encrypt ? await this.cryptoService.encrypt(this.currentWorkspaceStore.currentWorkspace!.id, strippedImageBuffer) : strippedImage);
          const thumbnail = await generateThumbnail(strippedImage);
          formData.append('thumbnail', encrypt ? await this.cryptoService.encrypt(this.currentWorkspaceStore.currentWorkspace!.id, await thumbnail.arrayBuffer()) : thumbnail);
          const metadata = await decodeImageAsync(strippedImageBuffer);
          params['width'] = metadata.width;
          params['height'] = metadata.height;
        } catch (e) {
          this.notificationService.push(
            new ToastNotification(
              NotificationLevel.ERROR,
              { template: 'x_is_not_an_image_file', data: { fileName: data.file.name } },
              { template: 'attachment_is_not_an_image_file' },
            ),
          );
          throw e;
        }
      } else {
        formData.append('file', encrypt ? await this.cryptoService.encrypt(this.currentWorkspaceStore.currentWorkspace!.id, await data.file.arrayBuffer()) : data.file);
      }

      const cancelTokenSource = axios.CancelToken.source();
      this.data.currentUploads.push({ id: data.id, cancelToken: cancelTokenSource });

      const config: AxiosRequestConfig = {
        params,
        cancelToken: cancelTokenSource.token,
        onUploadProgress: (progressEvent: any) => {
          const progress = Math.max(Math.round((progressEvent.loaded * 100) / progressEvent.total) - 1, 0);
          this.updateProgress(data.id, progress);
        },
      };

      const url = '/attachments/addAttachment';

      const result = await this.apiServiceImageUpload.postAsync<FormData, IUploadedImage>(url, formData, config);
      if (result instanceof StickerError) {
        return new Failed(result);
      }
      return new UploadSuccess(result.imageSize);
    } catch {
      return new Failed();
    } finally {
      const index = this.data.currentUploads.findIndex(x => x.id === data.id);
      this.data.currentUploads.splice(index, 1);
    }
  }

  readFileAsync(file: File): Promise<ArrayBuffer | null> {
    return new Promise<ArrayBuffer | null>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result as any);
      };

      reader.onerror = reject;

      reader.readAsArrayBuffer(file);
    });
  }

  private async getDuplicatedAttachmentNamesAsync(workspaceId: string, parentId: string | undefined, fileNames: string[]): Promise<string[]> {
    const distinct = fileNames.filter((v, i, a) => a.indexOf(v) === i);

    const url = '/attachments/getDuplicatedFileNames';
    const result = await this.apiServiceImageUpload.postAsync<{ workspaceId: string; parentId: string | undefined; fileNames: string[] }, string[]>(url, {
      workspaceId,
      parentId,
      fileNames: distinct,
    });

    if (result instanceof StickerError) throw result;

    return result;
  }

  private async willUploadExceedLimitsAsync(workspaceId: string, newFilesSize: number): Promise<IWillAttachmentUploadExceedsLimitDto> {
    const url = '/attachments/willAttachmentUploadExceedsLimit';
    const result = await this.apiServiceImageUpload.postAsync<{ workspaceId: string; newFilesSize: number }, IWillAttachmentUploadExceedsLimitDto>(url, {
      workspaceId,
      newFilesSize,
    });

    if (result instanceof StickerError) throw result;

    return result;
  }

  @action.bound
  private async onAllItemsUploadedAsync(onAllUploadedCallback: () => void) {
    if (this.data.progressData.length > 0) {
      this.notificationService.push(
        new AttachmentsUploadNotification(
          this.data.progressData.filter(x => !x.isSuccess).map(x => x.name),
          this.data.progressData.filter(x => x.isSuccess).length,
        ),
      );

      this.data.progressData = [];
      onAllUploadedCallback();
    }
  }
}
