const ServerErrorCodes: { [serverErrorCode: string]: string } = {
  duplicate_user_name: 'user_name_is_duplicated',
};

export const TranslateServerErrorCode = (serverErrorCode: string) => {
  return ServerErrorCodes[serverErrorCode] || serverErrorCode;
};

export const TranslateServerErrorCodes = (serverErrorCodes: string[]) => {
  const translatedErrorCodes: string[] = [];
  serverErrorCodes.forEach(e => translatedErrorCodes.push(TranslateServerErrorCode(e)));
  return translatedErrorCodes;
};
