import * as React from 'react';

import { Button, Card, CardBody, CardTitle } from 'reactstrap';

import { Home } from '../../../../__legacy__/routes/config/Home';
import { ITranslatable } from '../../../../__legacy__/helpers/translations.helpers';
import { Link } from 'react-router-dom';
import { Loader } from '../../../../__legacy__/components/Loader';
import { ProjectRole } from '../../../../__legacy__/models/userRole.model';
import { RestrictAccessTo } from '../../../../__legacy__/containers/RestrictAccessTo';
import { as } from '../../../../__legacy__/helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IAnnotatingOverviewWidgetProps {
  isLoading: boolean;
  waitingForAnnotationCount: number;
  draftsCount: number;
  toReviewCount: number;
  acceptedCount: number;
  totalImageCount: number;
  projectId: string;
  workspaceId: string;
}

const AnnotatingOverviewWidgetPure = (props: IAnnotatingOverviewWidgetProps & ITranslatable) => {
  const annotated = props.toReviewCount + props.acceptedCount;
  const percentage = props.totalImageCount === 0 ? 0 : ((annotated / props.totalImageCount) * 100).toFixed(2);

  return (
    <Card className="flex-fill card-with-list slim-margin">
      <CardBody>
        <Loader isLoading={props.isLoading}>
          <div className="info">
            <CardTitle tag="h5" className="name">
              {props.t('annotation_progress')} {annotated}/{props.totalImageCount} ({percentage}%)
            </CardTitle>
            <div className="list-row first">
              <div className="left">{props.t('waiting_for_annotation')}</div>
              <div className="right">{props.waitingForAnnotationCount}</div>
            </div>
            <div className="list-row">
              <div className="left">{props.t('waiting_for_submission')}</div>
              <div className="right">{props.draftsCount}</div>
            </div>
          </div>
          <RestrictAccessTo projectRoles={[ProjectRole.Annotator, ProjectRole.Reviewer]} workspaceRoles={[]} inProject={props.projectId}>
            <div className="bottom">
              <Link to={Home.Annotations.Create.withParams({ projectId: props.projectId, workspaceId: props.workspaceId })} className="float-left">
                <Button color="primary">{props.t('start_annotation')}</Button>
              </Link>
            </div>
          </RestrictAccessTo>
        </Loader>
      </CardBody>
    </Card>
  );
};

export const AnnotatingOverviewWidget = as<React.ComponentClass<IAnnotatingOverviewWidgetProps>>(withNamespaces('project', { wait: true })(AnnotatingOverviewWidgetPure));
