﻿import { IReactionDisposer, action, autorun, when } from 'mobx';

import { DatasetStatus } from '../datesets/datasetStatus.model';
import { IAnnotationUiStore } from './annotationUi.store';
import { IAnnotationsStore } from './annotations.store';
import { IImage } from './annotations.interface';
import { IImagesQueueService } from './imagesQueueServiceBase';
import { INotificationsService } from '../notifications/services/notifications.service';
import autobind from 'autobind-decorator';
import { injectable } from 'inversify';

export interface IBaseAnnotationControl {
  setupAsync(projectId: string, imagesQueueService: IImagesQueueService): Promise<void>;
  displayImageFromQueueAsync(): void;
  dispose(): void;
  uiStore: IAnnotationUiStore;
  imagesQueueService: IImagesQueueService;
  notificationsService: INotificationsService;
  annotationsStore: IAnnotationsStore;
}

@injectable()
export abstract class BaseAnnotationControl implements IBaseAnnotationControl {

  uiStore: IAnnotationUiStore;
  imagesQueueService!: IImagesQueueService;
  notificationsService: INotificationsService;
  annotationsStore: IAnnotationsStore;

  private disposeNoMoreImagesAutorun?: IReactionDisposer;
  private disposeNoMoreImagesReaction?: IReactionDisposer;
  private disposeImageLoadedReaction?: IReactionDisposer;

  constructor(
    uiStore: IAnnotationUiStore,
    notificationsService: INotificationsService,
    annotationsStore: IAnnotationsStore) {
    this.uiStore = uiStore;
    this.notificationsService = notificationsService;
    this.annotationsStore = annotationsStore;
  }

  abstract setupAsync(projectId: string, imagesQueueService: IImagesQueueService): Promise<void>;

  abstract handleImageDisplayed: (image: IImage) => void;

  protected initReactions(): void {
    this.dispose();
    this.disposeNoMoreImagesAutorun = autorun(() => {
      this.uiStore.changeIsImageLoading(
        this.imagesQueueService.areAnyImagesToLoad && this.annotationsStore.image === undefined,
      );
    });
  }

  async displayImageFromQueueAsync() {
    this.annotationsStore.annotationStatus = undefined;
    this.uiStore.changeIsImageLoading(true);
    this.annotationsStore.setImageName('', '', DatasetStatus.None);
    setTimeout(() => this.displayImage(), 0);
    return;
  }

  @autobind
  displayCurrentImage() {
    this.annotationsStore.annotationStatus = undefined;
    this.uiStore.changeIsImageLoading(false);
    const image = this.annotationsStore.image;
    if (this.handleImageDisplayed && image) {
      this.annotationsStore.setImageName(image.name, image.dataSetName, image.datasetStatus);
      this.handleImageDisplayed(image);
    }
  }

  @autobind
  displayImage() {
    this.annotationsStore.annotationStatus = undefined;
    this.annotationsStore.image = undefined;
    const image = this.imagesQueueService.takeImageFromQueue();
    if (!image) this.handleUndefinedImage();
    this.annotationsStore.image = image;
    if (this.handleImageDisplayed && image) {
      this.annotationsStore.setImageName(image.name, image.dataSetName, image.datasetStatus);
      this.handleImageDisplayed(image);
    }
  }

  @action
  protected async handleUndefinedImage() {
    this.annotationsStore.annotationStatus = undefined;
    this.annotationsStore.image = undefined;
    this.annotationsStore.setImageName('', '', DatasetStatus.None);

    if (!this.imagesQueueService.areAnyImagesToLoad) {
      this.uiStore.changeIsImageLoading(false);
      this.disposeNoMoreImagesReaction = when(
        () => !this.imagesQueueService.isAnythingToDisplay && !this.imagesQueueService.areAnyImagesToLoad,
        () => {
          this.resetZoom();
          this.assignNoImageReason();
        },
      );

    } else {
      this.disposeNoMoreImagesReaction = when(
        () => !this.imagesQueueService.areAnyImagesToLoad,
        () => {
          this.resetZoom();
          this.assignNoImageReason();
        },
      );
    }

    this.disposeImageLoadedReaction = when(
      () => this.imagesQueueService.isAnythingToDisplay,
      () => {
        this.disposeNoMoreImagesReaction!();
        this.displayImageFromQueueAsync();
      },
    );
  }

  @action.bound
  resetZoom() {
    this.uiStore.changeZoomLevel(100);
  }

  @action
  assignNoImageReason() {
    this.annotationsStore.noImageReason = this.imagesQueueService.noImageReason;
  }

  dispose() {
    this.disposeNoMoreImagesAutorun && this.disposeNoMoreImagesAutorun();
    this.disposeImageLoadedReaction && this.disposeImageLoadedReaction();
    this.disposeNoMoreImagesReaction && this.disposeNoMoreImagesReaction();
  }
}
