﻿import * as React from 'react';

import { GTable, IGColumnProps } from '../../../../../components/table/GTable';
import { faBars, faEdit, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { COMMA_SEPARATOR } from '../../../../../helpers/global.constants';
import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { EditQuestionContainer } from '../containers/EditQuestion.container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IQuestion } from '../projectDetailsTools.models';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { IconedButton } from '../../../../../components/IconedButton';
import { Loader } from '../../../../../components/Loader';
import { ProjectQuestionFormContainer } from '../containers/CreateQuestion.container';
import { QuestionDeleteContainer } from '../containers/QuestionDeleteContainer';
import { as } from '../../../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { handleClickAndPassData } from '../../../../../helpers/formHelpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

library.add(faTrashAlt, faEdit, faEye);

interface IQuestionsListProps {
  onOrderChange(questionId: string, to: number, isAttribute: boolean): void;
  questions: IQuestion[];
  canEdit: boolean;
  isLoading: boolean;
}

interface IState {
  questionId?: string;
  showEditModal: boolean;
  isReadonly: boolean;
}

@observer
class QuestionsListPure extends React.Component<IQuestionsListProps & ITranslatable, IState> {
  constructor(props: IQuestionsListProps & ITranslatable) {
    super(props);

    this.state = {
      showEditModal: false,
      questionId: '',
      isReadonly: true,
    };
  }

  getColumns() {
    const columns: IGColumnProps<IQuestion>[] = [
      {
        field: 'questionText',
        headerName: this.props.t('name'),
      },
      {
        field: 'type',
        headerName: this.props.t('question_type'),
        renderer: this.typeRenderer,
      },
      {
        field: 'answers',
        headerName: this.props.t('answers'),
        renderer: this.answersRenderer,
        cellClass: 'hide-cell-overflow',
      },
      {
        field: 'isRequired',
        headerName: this.props.t('is_required'),
        renderer: this.requiredRenderer,
        width: 100,
      },
      {
        field: '',
        headerName: this.props.t('actions'),
        cellClass: 'g-actions',
        renderer: this.optionsRenderer,
        width: 100,
      },
    ];

    if (this.props.canEdit) {
      columns.unshift({
        field: '',
        headerName: '',
        cellClass: '',
        width: 60,
        isDragHandle: true,
        renderer: () => <FontAwesomeIcon icon={faBars} />,
      });
    }

    return columns;
  }

  typeRenderer = (data: IQuestion) => <span>{this.props.t(`${data.type.toLocaleLowerCase()}_question_type`)}</span>;

  requiredRenderer = (data: IQuestion) => <span>{this.props.t(data.isRequired ? 'yes' : 'no')}</span>;

  answersRenderer = (data: IQuestion) => {
    const text = data.answers.map(x => x.text).join(COMMA_SEPARATOR);
    return <span title={text}>{text}</span>;
  };

  optionsRenderer = (data: IQuestion) => {
    return (
      <>
        <DisabledWhenWorkspaceOwnerPolicyExceeded>
          {this.props.canEdit ? (
            <IconedButton onClick={handleClickAndPassData(this.showEditModal)({ id: data.id, isReadonly: false })} icon={faEdit} title={this.props.t('edit_question')} />
          ) : (
            <IconedButton onClick={handleClickAndPassData(this.showEditModal)({ id: data.id, isReadonly: true })} icon={faEye} title={this.props.t('view_question')} />
          )}
        </DisabledWhenWorkspaceOwnerPolicyExceeded>
        <QuestionDeleteContainer canEdit={this.props.canEdit} question={data} isAttribute={false} />
      </>
    );
  };

  @autobind
  showEditModal(data: { id?: string; isReadonly: boolean }) {
    this.setState({
      showEditModal: true,
      questionId: data.id,
      isReadonly: data.isReadonly,
    });
  }

  hideEditModal = () => {
    this.setState({ showEditModal: false });
  };

  handleRowDrag = (cb: any) => (from: number, to: number) => cb(this.props.questions[from].id, to, false);

  render() {
    return (
      <div className="annotation-tools-list">
        <div className="d-flex flex-row align-items-center">
          <h4>{this.props.t('questions')}</h4>
          <div className="ml-2">{this.props.canEdit && <ProjectQuestionFormContainer />}</div>
        </div>
        <div className="table-container">
          <Loader isLoading={this.props.isLoading}>
            <GTable
              columns={this.getColumns()}
              items={this.props.questions}
              enableDrag={this.props.canEdit}
              noItemsPlaceholder={this.props.t('no_questions_have_been_added_yet')}
              onRowDragEnd={this.handleRowDrag(this.props.onOrderChange)}
            />
          </Loader>
        </div>
        {this.state.questionId && (
          <EditQuestionContainer
            questionId={this.state.questionId}
            onSubmit={this.hideEditModal}
            onCancel={this.hideEditModal}
            isReadonly={this.state.isReadonly}
            isAttribute={false}
            showEditModal={this.state.showEditModal}
            modalHeader={this.state.isReadonly ? this.props.t('view_question') : this.props.t('edit_question')}
          />
        )}
      </div>
    );
  }
}
export const QuestionsList = as<React.ComponentClass<IQuestionsListProps>>(withNamespaces('project')(QuestionsListPure));
