import { injectable } from 'inversify';
import { action, observable } from 'mobx';

export const ErrorsStoreType = Symbol('ERRORS_STORE');
export const ErrorsStoreSetterType = Symbol('ERRORS_STORE_SETTER');

export interface IErrorsStore {
  isAuthorized: boolean;
}

export interface IErrorsStoreSetter extends IErrorsStore {
  setAuthorization(isAuthorized: boolean): void;
}

@injectable()
export class ErrorsStore implements IErrorsStoreSetter {

  @observable
  isAuthorized: boolean = true;

  @action
  setAuthorization(isAuthorized: boolean): void {
    this.isAuthorized = isAuthorized;
  }
}
