import * as React from 'react';

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { DatasetsPermissionsType, IDatasetsPermissions } from '../datasets.permissions';
import { IUpdateDatasetFilesService, UpdateDatasetFilesServiceType } from '../updateDatasetFiles.service';
import { IUpdateDatasetService, UpdateDatasetServiceType } from '../updateDataset.service';
import { as, injectProps } from '../../../helpers/react.helpers';

import { DatasetDetails } from '../datasetDetails.context';
import { ImageUploadModal } from '../components/ImageUploadModal';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps {
  updateDatasetService: IUpdateDatasetService;
  updateDatasetFilesService: IUpdateDatasetFilesService;
  currentWorkspaceStore: ICurrentWorkspaceStore;
  datasetsPermissions: IDatasetsPermissions
}

interface IState {
  isConfirmationModalOpen: boolean;
  isModalOpen: boolean;
  showSpinner: boolean;
}

@injectProps({
  updateDatasetService: UpdateDatasetServiceType,
  updateDatasetFilesService: UpdateDatasetFilesServiceType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  datasetsPermissions: DatasetsPermissionsType,
})
@observer
class UpdateDatasetContainerPure extends React.Component<IInjectedProps, IState> {
  static contextType = DatasetDetails;
  declare context: React.ContextType<typeof DatasetDetails>;

  state: IState = {
    isConfirmationModalOpen: false,
    isModalOpen: false,
    showSpinner: false,
  };

  // tslint:disable-next-line:function-name
  UNSAFE_componentWillMount() {
    this.props.updateDatasetFilesService.setup(this.context.store);
  }

  @autobind
  async handleUploadImagesAsync() {
    this.setState({ isConfirmationModalOpen: false, isModalOpen: false });
    await this.props.updateDatasetService.uploadImages(this.context.route.match.params.datasetId);
  }

  @autobind
  handleToggleUploadConfirmationModal() {
    this.setState((prevState: IState) => ({
      ...prevState,
      isConfirmationModalOpen: !prevState.isConfirmationModalOpen,
    }));
  }

  @autobind
  handleToggleUploadModal() {
    this.setState((prevState: IState) => ({
      ...prevState,
      isModalOpen: !prevState.isModalOpen,
    }));
  }

  render() {
    const datasetId = this.context.route.match.params.datasetId;
    const store = this.context.store;

    const imagesRejectedFromFile = store.imagesRejectedFromFile;
    const imagesToUploadFromFile = store.imagesToUploadFromFile;
    const imagesToUploadFromUrl = store.imagesToUploadFromUrl;

    return (
      <ImageUploadModal
        datasetId={datasetId}
        imagesRejectedFromFile={imagesRejectedFromFile}
        imagesToUploadFromFile={imagesToUploadFromFile}
        imagesToUploadFromUrl={imagesToUploadFromUrl}
        datasetStatus={store.details.status}
        isCreationEnabled={(imagesToUploadFromFile.length > 0 || imagesToUploadFromUrl.filter(x => x.isValid).length > 0) && !store.willExceedsUploadImageSizeLimit}
        onFormSubmit={this.handleUploadImagesAsync}
        isProcessingImages={this.props.updateDatasetFilesService.isProcessingImages}
        addImagesToDataset={this.props.updateDatasetFilesService.addImagesToDataset}
        cleanImagesFromUrl={this.props.updateDatasetFilesService.cleanImagesFromUrl}
        getUrlsFromFileAsync={this.props.updateDatasetFilesService.getUrlsFromFileAsync}
        cleanImagesFromFile={this.props.updateDatasetFilesService.cleanImagesFromFile}
        rejectUrlsFile={this.props.updateDatasetFilesService.rejectUrlsFile}
        willExceedsUploadImageSizeLimit={store.willExceedsUploadImageSizeLimit}
        imagePreValidationWasSuccessful={store.imagePreValidationWasSuccessful}
        ownerPlan={store.ownerPlan}
        toggleUploadConfirmationModal={this.handleToggleUploadConfirmationModal}
        isConfirmationModalOpen={this.state.isConfirmationModalOpen}
        showModal={this.state.isModalOpen}
        toggleUploadModal={this.handleToggleUploadModal}
        isEncryptionEnabled={this.props.currentWorkspaceStore.currentWorkspace?.encryption.encrypted || false}
        canUploadImages={this.props.datasetsPermissions.canUploadImages(store.details.status, store.details.createdById)}
      />
    );
  }
}

export const UpdateDatasetContainer = as<React.ComponentClass>(UpdateDatasetContainerPure);
