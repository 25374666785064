import { WithNamespaces, withNamespaces } from 'react-i18next';

import React from 'react';
import { S_ColorField } from '../../../../../../../../design/fields/colorField/S_ColorField';

interface Props {
  color: string;
  onChangeColor(color: string): void;
  disabled?: boolean;
}

const ColorFieldPure: React.FunctionComponent<Props & WithNamespaces> = (props: Props & WithNamespaces) => (
  <S_ColorField labelText={props.t('projects.details.annotation_tools.marking_tools.color')} onChange={props.onChangeColor} value={props.color} />
);
export const ColorField = withNamespaces('new')(ColorFieldPure);
