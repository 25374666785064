import * as React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { IZliderProps, Zlider } from '../Slider';
import classNames from 'classnames';

export interface ISliderWithLabelProps {
  label: string;
  fullWidth?: boolean
  mbNone?: boolean
}

export const SliderWithLabel = ({ label, fullWidth, mbNone, ...props }: ISliderWithLabelProps & IZliderProps) => {
  const labelText = props.disabled ? label : `${label}: ${props.value.toFixed(2)}`;

  return (
    <FormGroup className={classNames('slider-wrapper', {'full-width': fullWidth, 'mb-none': mbNone})}>
      <Label>{labelText}</Label>
      <Zlider {...props} fullWidth={fullWidth}/>
    </FormGroup>
  );
};
