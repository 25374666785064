import * as React from 'react';

import { InputStatus } from '../../../models/error.model';
import { PasswordWithScoreInputs } from '../components/PasswordWithScoreInputs';
import Zxcvbn from 'zxcvbn';
import autobind from 'autobind-decorator';

const ERROR_CODES = {
  PASSWORD_EMPTY: 'field_cant_be_empty',
  PASSWORD_ANY_NON_WHITE_CHAR: 'password_any_nonwhite_char',
  PASSWORD_TOO_WEAK: 'password_too_weak',
  REPEAT_PASSWORD_EMPTY: 'field_cant_be_empty',
  REPEAT_PASSWORD_DONT_MATCH: 'repeat_password_dont_match',
};

const MIN_PASSWORD_SCORE: number = 2;

export interface PasswordWithScoreInputsOuterProps {
  onPasswordChange(password: string): void;
  onPasswordStatusChange(passwordStatus: InputStatus): void;
  labelText: string;
}

interface IState {
  password: string;
  passwordScore: number | null;
  passwordStatus: InputStatus;
}

@autobind
export class PasswordWithScoreInputsContainer extends React.Component<PasswordWithScoreInputsOuterProps, IState> {
  state: IState = {
    password: '',
    passwordScore: null,
    passwordStatus: InputStatus.empty(),
  };

  handlePasswordChange = (value: string) => {
    const score = value ? Zxcvbn(value).score : null;

    this.setState({
      password: value,
      passwordScore: score,
      passwordStatus: InputStatus.empty(),
    });

    this.props.onPasswordChange(value);
    this.props.onPasswordStatusChange(InputStatus.empty());
  };

  handleRepeatPasswordChange = (value: string) => {
    const passwordErrorCodes = this.state.passwordStatus.errorCodes.filter(
      c => c !== ERROR_CODES.REPEAT_PASSWORD_DONT_MATCH,
    );

    const passwordStatus = this.state.passwordStatus.isEmpty
      ? InputStatus.empty()
      : InputStatus.buildFrom(passwordErrorCodes);

    this.setState({
      passwordStatus,
    });

    this.props.onPasswordStatusChange(InputStatus.empty());
  };

  validate = () => this.validatePassword(this.state.password);

  validatePassword(value: string) {
    const errorCodes: string[] = [];

    if (!value) {
      errorCodes.push(ERROR_CODES.PASSWORD_EMPTY);
    }

    if (value && value.trim() === '') {
      errorCodes.push(ERROR_CODES.PASSWORD_ANY_NON_WHITE_CHAR);
    }

    if (this.state.passwordScore !== null && this.state.passwordScore < MIN_PASSWORD_SCORE) {
      errorCodes.push(ERROR_CODES.PASSWORD_TOO_WEAK);
    }

    const status = InputStatus.buildFrom(errorCodes);

    this.setState({ passwordStatus: status });
    this.props.onPasswordStatusChange(status);

    return status.isValid;
  }

  render() {
    return (
      <PasswordWithScoreInputs
        onPasswordChange={this.handlePasswordChange}
        validatePassword={this.validatePassword}
        passwordStatus={this.state.passwordStatus}
        passwordScore={this.state.passwordScore}
        labelText={this.props.labelText}
      />);
  }
}
