import { ImageAssignmentPolicy, ToolDeleteStrategy } from '../../projectDetails.models';

export interface IProjectActivity<T> {
  id: string;
  actor: string;
  activity: ProjectActivity;
  createdDate: string;
  values: T;
}

export interface IProjectActivityRename {
  name: string;
}

export interface IProjectActivityDescriptionChange {
  description: string;
}

export interface IProjectActivityAddDataset {
  name: string;
}

export interface IProjectActivityRemoveDataset {
  name: string;
}

export interface IProjectActivityAddTool {
  name: string;
}

export interface IProjectActivityUpdateTool {
  name: string;
  color: string;
  selectorType: string;
  order: number;
  originalName: string;
  originalColor: string;
  originalSelectorType: string;
  originalOrder: number;
}

export interface IProjectActivityRemoveTool {
  name: string;
  updatedAnnotationsCount: number;
  strategy: ToolDeleteStrategy;
}

export interface IProjectActivityAddQuestion {
  question: string;
}

export interface IProjectActivityUpdateQuestion {
  question: string;
  answerType: string;
  order: number;
  isRequired: boolean;
  originalQuestion: string;
  originalAnswerType: string;
  originalOrder: number;
  originalIsRequired: boolean;
}

export interface IProjectActivityRemoveQuestion {
  question: string;
  updatedAnnotationsCount: number;
  strategy: ToolDeleteStrategy;
}

export interface IProjectActivityAddUser {
  email: string;
  role: string;
}

export interface IProjectActivityRemoveUser {
  email: string;
}

export interface IProjectActivityPublish { }

export interface IProjectActivityExport {
  backupId: string;
  configurationVersion: number;
  annotationVersion: number;
  setVersion: number;
  accepted: number;
  annotated: number;
  exportFileSchemeVersion: string;
  isGeneratedDuringImport: boolean;
  isExportReady: boolean;
}

export interface IProjectActivityDownloadExport {
  configurationVersion: number;
  annotationVersion: number;
  setVersion: number;
  dataFilterType: string;
}

export interface IProjectActivityImport {
  fileName: string;
  wasAnnotationsImported: boolean;
}

export interface IProjectActivityImportAnnotations {
  importedAnnotations: number;
  skippedAnnotations: number;
}

export interface IProjectActivityAddAttribute {
  attribute: string;
}

export interface IProjectActivityUpdateAttribute {
  attribute: string;
  answerType: string;
  order: number;
  isRequired: boolean;
  originalAttribute: string;
  originalAnswerType: string;
  originalOrder: number;
  originalIsRequired: boolean;
}

export interface IProjectActivityRemoveAttribute {
  attribute: string;
  updatedAnnotationsCount: number;
  strategy: ToolDeleteStrategy;
}

export interface IProjectActivityAddAttributeAnswer {
  attribute: string;
  answer: string;
}

export interface IProjectActivityUpdateAttributeAnswer {
  attribute: string;
  answer: string;
  originalAnswer: string;
}

export interface IProjectActivityRemoveAttributeAnswer {
  attribute: string;
  answer: string;
  updatedAnnotationsCount: number;
  strategy: ToolDeleteStrategy;
}

export interface IProjectActivityAddAttributeScope {
  attribute: string;
  scope: string;
}

export interface IProjectActivityRemoveAttributeScope {
  attribute: string;
  scope: string;
  updatedAnnotationsCount: number;
  strategy: ToolDeleteStrategy;
}

export interface IProjectActivityAddQuestionAnswer {
  question: string;
  answer: string;
}

export interface IProjectActivityUpdateQuestionAnswer {
  question: string;
  answer: string;
  originalAnswer: string;
}

export interface IProjectActivityRemoveQuestionAnswer {
  question: string;
  answer: string;
  updatedAnnotationsCount: number;
  strategy: ToolDeleteStrategy;
}

export interface IProjectActivityImageAssignmentPolicyChange {
  policy: ImageAssignmentPolicy;
}

export enum ProjectActivity {
  DraftCreated = 'DraftCreated',
  Rename = 'Rename',
  DescriptionChange = 'DescriptionChange',
  AddDataset = 'AddDataset',
  RemoveDataset = 'RemoveDataset',
  AddTool = 'AddTool',
  UpdateTool = 'UpdateTool',
  RemoveTool = 'RemoveTool',
  AddQuestion = 'AddQuestion',
  UpdateQuestion = 'UpdateQuestion',
  RemoveQuestion = 'RemoveQuestion',
  AddUser = 'AddUser',
  RemoveUser = 'RemoveUser',
  Publish = 'Publish',
  Export = 'Export',
  Import = 'Import',
  ImportAnnotations = 'ImportAnnotations',
  AddAttribute = 'AddAttribute',
  UpdateAttribute = 'UpdateAttribute',
  RemoveAttribute = 'RemoveAttribute',
  AddAttributeAnswer = 'AddAttributeAnswer',
  UpdateAttributeAnswer = 'UpdateAttributeAnswer',
  RemoveAttributeAnswer = 'RemoveAttributeAnswer',
  AddQuestionAnswer = 'AddQuestionAnswer',
  UpdateQuestionAnswer = 'UpdateQuestionAnswer',
  RemoveQuestionAnswer = 'RemoveQuestionAnswer',
  AddAttributeScope = 'AddAttributeScope',
  RemoveAttributeScope = 'RemoveAttributeScope',
  ChangeImageAssignmentPolicy = 'ChangeImageAssignmentPolicy',
  DownloadExport = 'DownloadExport'
}

export const allProjectActivities = [
  ProjectActivity.DraftCreated, ProjectActivity.Rename, ProjectActivity.DescriptionChange, ProjectActivity.AddDataset, ProjectActivity.RemoveDataset,
  ProjectActivity.AddTool, ProjectActivity.UpdateTool, ProjectActivity.RemoveTool, ProjectActivity.AddAttribute, ProjectActivity.UpdateAttribute, ProjectActivity.RemoveAttribute,
  ProjectActivity.AddAttributeAnswer, ProjectActivity.UpdateAttributeAnswer, ProjectActivity.RemoveAttributeAnswer, ProjectActivity.RemoveQuestionAnswer,
  ProjectActivity.AddAttributeScope, ProjectActivity.RemoveAttributeScope, ProjectActivity.AddQuestion, ProjectActivity.UpdateQuestion,
  ProjectActivity.RemoveQuestion, ProjectActivity.AddQuestionAnswer, ProjectActivity.UpdateQuestionAnswer, ProjectActivity.AddUser, ProjectActivity.RemoveUser,
  ProjectActivity.Publish, ProjectActivity.Export, ProjectActivity.Import, ProjectActivity.ImportAnnotations, ProjectActivity.ChangeImageAssignmentPolicy, ProjectActivity.DownloadExport
];
