import { IImageFilters, IImageFiltersData } from '../../../imageFilters.model';

import { FiltersDropdownRow } from '../FiltersDropdownRow';
import { ISelectOption } from '../../../../../../../helpers/formHelpers';
import { ITranslatable } from '../../../../../../../helpers/translations.helpers';
import { ImageAnnotationStatus } from '../../../projectDetailsImages.model';
import React from 'react';
import { as } from '../../../../../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IStatusFilterInfoProps {
  availableFilters: IImageFiltersData;
  imageFilters: IImageFilters;
}

class StatusFilterInfoPure extends React.Component<IStatusFilterInfoProps & ITranslatable> {
  render() {
    const options: ISelectOption<ImageAnnotationStatus>[] = [
      { value: ImageAnnotationStatus.NONE, label: this.props.t('waiting_for_annotation') },
      { value: ImageAnnotationStatus.CLARIFICATION, label: this.props.t('waiting_for_clarification') },
      { value: ImageAnnotationStatus.DRAFT, label: this.props.t('waiting_for_submission') },
      { value: ImageAnnotationStatus.REJECTED, label: this.props.t('waiting_for_correction') },
      { value: ImageAnnotationStatus.TOREVIEW, label: this.props.t('waiting_for_approval') },
      { value: ImageAnnotationStatus.ACCEPTED, label: this.props.t('finished') },
    ];

    const selectedOptions = options
      .filter(d => this.props.imageFilters.annotationStatuses.includes(d.value));

    return (
      <FiltersDropdownRow label={this.props.t('image_filters.status')} count={selectedOptions.length}>
        <>{selectedOptions.map(d => <div key={d.value}>{d.label}</div>)}</>
      </FiltersDropdownRow>);
  }
}

export const StatusFilterInfo = as<React.ComponentClass<IStatusFilterInfoProps>>(withNamespaces('project')(StatusFilterInfoPure));
