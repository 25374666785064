import { IImportQuestionRejection, ImportQuestionRejectionReason } from '../projects.model';

import { CollapsibleImportSummary } from '../../../components/CollapsibleSummary';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IProps {
  isLastSummaryItem?: boolean;
  isPanelOpen: boolean;
  handlePanelToggle(): void;
  rejectedQuestions: IImportQuestionRejection[];
  importedQuestionsCount: number;
  skippedQuestionsCount: number;
}

export function mapQuestionRejectionReason(reason: ImportQuestionRejectionReason) {
  switch (reason) {
    case ImportQuestionRejectionReason.DUPLICATED_TEXT:
      return 'duplicated_text';
    case ImportQuestionRejectionReason.EMPTY_SCOPE_LIST:
      return 'empty_scope_list';
    case ImportQuestionRejectionReason.INVALID_ANSWER_TYPE:
      return 'invalid_answer_type';
    case ImportQuestionRejectionReason.INVALID_SCOPE:
      return 'invalid_scope';
    default:
      return 'unknown';
  }
}

class ImportQuestionsSummaryPure extends React.Component<IProps & ITranslatable> {
  getRejectionReasons = (): string[] => {
    return this.props.rejectedQuestions.map(x => {
      return `${x.text} - ${this.props.t(mapQuestionRejectionReason(x.reason))}`;
    });
  };

  render() {
    return (
      <>
        <CollapsibleImportSummary
          importedItemsCount={this.props.importedQuestionsCount}
          skippedItemsCount={this.props.skippedQuestionsCount}
          rejectionReasons={this.getRejectionReasons()}
          isPanelOpen={this.props.isPanelOpen}
          togglePanel={this.props.handlePanelToggle}
          isLastSummaryItem={this.props.isLastSummaryItem}
          importedMessage={this.props.t('questions_imported', { count: this.props.importedQuestionsCount })}
          skippedMessage={this.props.t('questions_skipped', { count: this.props.skippedQuestionsCount })}
        />
      </>
    );
  }
}

export const ImportQuestionsSummary = as<React.ComponentClass<IProps>>(withNamespaces('project')(ImportQuestionsSummaryPure));
