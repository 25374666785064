import * as React from 'react';

import { Col, Container, Row } from 'reactstrap';

import { DraftButtonsContainer } from '../containers/DraftButtons.container';
import { GlobalValidationErrorsContainer } from '../containers/GlobalValidationErrors.container';
import { Home } from '../../../routes/config/Home';
import { IHeaderPageProps } from '../../../components/EditablePageHeader';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { ImportAnnotationsView } from '../../../../modules/projects/details/images/importAnnotations/ui/ImportAnnotations.view';
import { ProjectDetailsTabsContainer } from '../containers/ProjectDetailsTabs.container';
import { ProjectHeaderForm } from './ProjectHeaderForm';
import { ProjectStatus } from '../../projects/projects.model';
import { RestrictAccessTo } from '../../../containers/RestrictAccessTo';
import { Route } from 'react-router-dom';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IHeaderProps extends IHeaderPageProps {
  id: string;
  workspaceId: string;
  projectStatus: ProjectStatus;
  canImportAnnotations: boolean;
}

class ProjectDetailsHeaderPure extends React.Component<IHeaderProps & ITranslatable> {
  ImportAnnotation = () => {
    return (
      <>
        {this.props.canImportAnnotations && (
          <Col sm="4" className="text-right d-flex align-items-end flex-column">
            <Route path={Home.Projects.Details.Images.Path} component={ImportAnnotationsView} />
          </Col>
        )}
      </>
    );
  };

  render() {
    return (
      <Container fluid>
        <RestrictAccessTo workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Trainer]} projectRoles={[]} inProject={this.props.id}>
          <Row>
            <Col sm="8">
              <ProjectHeaderForm
                name={this.props.name}
                onNameChange={this.props.onNameChange}
                onNameBlur={this.props.onNameBlur}
                nameMaxLength={this.props.nameMaxLength}
                nameStatus={this.props.nameStatus}
                description={this.props.description}
                onDescriptionChange={this.props.onDescriptionChange}
                onDescriptionBlur={this.props.onDescriptionBlur}
                descriptionMaxLength={this.props.descriptionMaxLength}
                descriptionStatus={this.props.descriptionStatus}
              />
              <Row>
                <Col>
                  <GlobalValidationErrorsContainer />
                </Col>
              </Row>
            </Col>
            <DraftButtonsContainer />
            {this.ImportAnnotation()}
          </Row>
        </RestrictAccessTo>
        <RestrictAccessTo
          workspaceRoles={[WorkspaceRole.Guest, WorkspaceRole.Worker, WorkspaceRole.Collaborator, WorkspaceRole.Assistant]}
          projectRoles={[]}
          inProject={this.props.id}
        >
          <Row>
            <Col>
              <h1>{this.props.name}</h1>
              <p>{this.props.description}</p>
            </Col>
            {this.ImportAnnotation()}
          </Row>
        </RestrictAccessTo>
        <Row>
          <Col>
            <ProjectDetailsTabsContainer />
          </Col>
        </Row>
      </Container>
    );
  }
}

export const ProjectDetailsHeader = as<React.ComponentClass<IHeaderProps>>(withNamespaces('project', { wait: true })(ProjectDetailsHeaderPure));
