import { ImportAnnotationsBl, ImportAnnotationsBlType } from '../../../importAnnotations.bl';
import { ImportAnnotationsStore, ImportAnnotationsStoreType } from '../../../importAnnotations.store';

import { DropFileEventHandler } from 'react-dropzone';
import { ImportAnnotationsStep } from '../../../models/ImportAnnotationsSteps';
import React from 'react';
import { SelectFileStepModal } from './SelectFileStepModal';
import { as } from '../../../../../../../../common/dependencyInjection/as';
import { injectProps } from '../../../../../../../../__legacy__/helpers/react.helpers';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: ImportAnnotationsBl;
  store: ImportAnnotationsStore;
}

@injectProps({
  bl: ImportAnnotationsBlType,
  store: ImportAnnotationsStoreType,
})
@observer
class SelectFileStepContainerPure extends React.Component<InjectedProps> {
  handleHide = () => {
    this.props.bl.cancelImport();
  };

  handleSubmit = async () => {
    await this.props.bl.goToImportAnnotationsAsync();
  };

  handleFileDropped: DropFileEventHandler = files => {
    this.props.bl.validateFileAsync(files[0]);
  };

  get isCurrentStep() {
    return this.props.store.currentStep === ImportAnnotationsStep.SelectFile;
  }

  get canProceed() {
    return !!this.props.store.file && !this.props.store.fileValidationError;
  }

  render() {
    return (
      <SelectFileStepModal
        show={this.isCurrentStep}
        onFileDropped={this.handleFileDropped}
        onHide={this.handleHide}
        onSubmit={this.handleSubmit}
        canProceed={this.canProceed}
        fileValidationError={this.props.store.fileValidationError}
        fileName={this.props.store.fileName}
      />
    );
  }
}

export const SelectFileStepContainer = as<React.ComponentClass>(SelectFileStepContainerPure);
