import { injectable, inject } from 'inversify';
import { ApiServiceType } from '../../services/api.service';
import { StickerError } from '../../models/error.model';
import { IAnnotationProgress, IImage, IReviewProgress } from './annotations.interface';
import { QuestionModel } from './question.model';
import { IApiService } from '../../services/api.service.base';
import Semaphore from 'semaphore-async-await';
import { delay, randomIntFromInterval } from '../../helpers/function.helpers';
import { ProjectStatus } from '../projects/projects.model';

export const AnnotationApiServiceType = Symbol('ANNOTATION_API_SERVICE');

export interface IAnnotationApiService {
  BaseUrl: string;
  getImageForAnnotationAsync(projectId: string, unlockOldImages: boolean): Promise<IImage | StickerError>;
  requestQuestionsAsync(projectId: string): Promise<QuestionModel[] | StickerError>;
  unlockImageAsync(imageId: string, projectId: string, skip: boolean): Promise<void | StickerError>;
  unlockUserImagesForProjectAsync(projectId: string): Promise<void | StickerError>;
  requestImageToReviewAsync(projectId: string, unlockOldImages: boolean): Promise<IImage | StickerError>;
  reLockImageForAnnotationAsync(imageId: string, projectId: string): Promise<void | StickerError>;
  reLockImageForReviewAsync(imageId: string, projectId: string): Promise<void | StickerError>;
  getAnnotationProgressAsync(projectId: string): Promise<IAnnotationProgress | StickerError>;
  getReviewProgressAsync(projectId: string): Promise<IReviewProgress | StickerError>;
  getProjectStatusAsync(projectId: string): Promise<IGetProjectStatusResponse>;
}

interface IGetProjectStatusResponse {
  projectStatus: ProjectStatus;
}

@injectable()
export class AnnotationApiService implements IAnnotationApiService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService) {}

  get BaseUrl() {
    return this.apiService.config.baseUrl;
  }

  private annotationLock = new Semaphore(1);
  private reviewLock = new Semaphore(1);

  async requestQuestionsAsync(projectId: string): Promise<QuestionModel[] | StickerError> {
    const result = await this.apiService.getAsync<any[]>(`/questions?projectId=${projectId}`);
    if (result instanceof StickerError) return result;
    return result.map(q => new QuestionModel(projectId, q.id, q.type, q.isRequired, q.text, q.answers, q.scopes));
  }

  async getImageForAnnotationAsync(projectId: string, RemoveOldLocks: boolean): Promise<IImage | StickerError> {
    await this.annotationLock.acquire();
    let isConcurrencyError = false;
    let circuitBreaker = 50;
    let result: IImage | StickerError | undefined = undefined;

    do {
      isConcurrencyError = false;
      result = await this.apiService.postAsync<{}, IImage | StickerError>('/Images/GetImageForAnnotation', { RemoveOldLocks, projectId });
      if (result instanceof StickerError) {
        isConcurrencyError = result.apiErrorResponse?.statusCode === 409;
        circuitBreaker -= 1;
        if (circuitBreaker && isConcurrencyError) {
          await delay(randomIntFromInterval(100, 500));
        }
      }
    } while (isConcurrencyError && circuitBreaker);

    this.annotationLock.release();
    return result;
  }

  unlockImageAsync = (imageId: string, projectId: string, skip: boolean): Promise<void | StickerError> =>
    this.apiService.postAsync('/Images/UnlockImageForProject', { imageId, projectId, skip });

  unlockUserImagesForProjectAsync = (projectId: string): Promise<void | StickerError> => this.apiService.postAsync('/Images/UnlockUserImagesForProject', { projectId });

  async requestImageToReviewAsync(projectId: string, removeOldLocks: boolean): Promise<IImage | StickerError> {
    await this.reviewLock.acquire();
    let isConcurrencyError = false;
    let circuitBreaker = 50;
    let result: IImage | StickerError | undefined = undefined;

    do {
      isConcurrencyError = false;
      result = await this.apiService.postAsync<{}, IImage | StickerError>('/Images/GetImageToReview', { projectId, removeOldLocks });
      if (result instanceof StickerError) {
        isConcurrencyError = result.apiErrorResponse?.statusCode === 409;
        circuitBreaker -= 1;
        if (circuitBreaker && isConcurrencyError) {
          await delay(randomIntFromInterval(100, 500));
        }
      }
    } while (isConcurrencyError && circuitBreaker);

    this.reviewLock.release();
    return result;
  }

  reLockImageForAnnotationAsync = (imageId: string, projectId: string): Promise<void | StickerError> =>
    this.apiService.postAsync('/images/ReLockImageForAnnotation', { imageId, projectId });

  reLockImageForReviewAsync = (imageId: string, projectId: string): Promise<void | StickerError> =>
    this.apiService.postAsync('/images/ReLockImageForReview', { imageId, projectId });

  getAnnotationProgressAsync = (projectId: string): Promise<IAnnotationProgress | StickerError> =>
    this.apiService.getAsync<IAnnotationProgress>(`/projects/getAnnotationProgress?projectId=${projectId}`);

  getReviewProgressAsync = (projectId: string): Promise<IReviewProgress | StickerError> =>
    this.apiService.getAsync<IReviewProgress>(`/projects/getReviewProgress?projectId=${projectId}`);

  getProjectStatusAsync = async (projectId: string): Promise<IGetProjectStatusResponse> => {
    const result = await this.apiService.getAsync<IGetProjectStatusResponse>(`/projects/getProjectStatus?projectId=${projectId}`);
    if (result instanceof StickerError) {
      throw result;
    }

    return result;
  };
}
