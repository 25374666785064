import { IProjectOverviewBl, ProjectOverviewBlType } from '../../../../modules/projects/project/overview/projectOverview.bl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { as, injectProps } from '../../../helpers/react.helpers';

import { Alert } from 'reactstrap';
import { Home } from '../../../routes/config/Home';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  bl: IProjectOverviewBl;
}

@injectProps({ bl: ProjectOverviewBlType })
@observer
class ProjectDetailsGlobalErrorsContainerPure extends React.Component<IInjectedProps & RouteComponentProps<{ workspaceId: string, projectId: string }> & ITranslatable> {
  render() {
    return (
      <>
        {this.props.bl.store.publishMoreDatasets && (
          <Alert color="danger">
            <Link to={Home.Projects.Details.Datasets.withParams(this.props.match.params)}>{this.props.t('publish_more_datasets')}</Link>
          </Alert>
        )}
      </>
    );
  }
}

export const ProjectDetailsGlobalErrorsContainer = as<React.ComponentClass>(withRouter(withNamespaces('project')(ProjectDetailsGlobalErrorsContainerPure)));
