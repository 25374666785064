import { injectable, inject } from 'inversify';

import { IApiService } from '../../../__legacy__/services/api.service.base';
import { IPagedResult } from '../../../__legacy__/services/api.service';
import { StickerError } from '../../../__legacy__/models/error.model';
import { EvaluationOwnership } from '../evaluations.store';
import { IEvaluationListDto } from '../evaluations.model';
import { ApiServiceTrainingType } from '../../../__legacy__/services/api.service.training';
import { IPostRunTrainingDTo } from '../../modelDetails/services/modelDetailsApi.service';

export interface IGetEvaluationsRequest {
  pageNumber: number;
  pageSize: number;
  orderBy: string;
  orderType: string;
  ownership: EvaluationOwnership;
}

export interface IGetModelDownloadUrlDto {
  url: string;
}

export const EvaluationsApiServiceType = Symbol('EVALUATIONS_API_SERVICE');

export interface IEvaluationsApiService {
  getEvaluationsAsync(workspaceId: string, request: IGetEvaluationsRequest): Promise<IPagedResult<IEvaluationListDto> | StickerError>;
  postEvaluationRestartAsync(workspaceId: string, evaluationId: string): Promise<IPostRunTrainingDTo | StickerError>;
  postEvaluationStopAsync(workspaceId: string, evaluationId: string): Promise<string | StickerError>;
  deleteEvaluationAsync(workspaceId: string, evaluationId: string): Promise<string | StickerError>;
  getDownloadModelAsync(workspaceId: string, modelId: string): Promise<IGetModelDownloadUrlDto | StickerError>;
}

@injectable()
export class EvaluationsApiService implements IEvaluationsApiService {
  constructor(@inject(ApiServiceTrainingType) private readonly apiTrainingService: IApiService) {}

  async getEvaluationsAsync(workspaceId: string, request: IGetEvaluationsRequest): Promise<IPagedResult<IEvaluationListDto> | StickerError> {
    return this.apiTrainingService.getAsync<IPagedResult<IEvaluationListDto>>(`/workspaces/${workspaceId}/inference-jobs`, { params: request });
  }

  async postEvaluationRestartAsync(workspaceId: string, evaluationId: string): Promise<IPostRunTrainingDTo | StickerError> {
    return await this.apiTrainingService.postAsync(`/workspaces/${workspaceId}/inference-jobs/${evaluationId}/start`);
  }

  async postEvaluationStopAsync(workspaceId: string, evaluationId: string): Promise<string | StickerError> {
    return await this.apiTrainingService.postAsync(`/workspaces/${workspaceId}/inference-jobs/${evaluationId}/stop`);
  }

  async deleteEvaluationAsync(workspaceId: string, evaluationId: string): Promise<string | StickerError> {
    return await this.apiTrainingService.deleteAsync(`/workspaces/${workspaceId}/inference-jobs/${evaluationId}`);
  }

  async getDownloadModelAsync(workspaceId: string, modelId: string): Promise<IGetModelDownloadUrlDto | StickerError> {
    return this.apiTrainingService.getAsync(`/workspaces/${workspaceId}/training-jobs/${modelId}/model`);
  }
}
