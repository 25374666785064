import { StickerEvent } from '../../../../../services/eventBus.service';

export const SegmentationDeletedEventType = Symbol('SEGMENTATION_DELETED_EVENT');

export class SegmentationDeletedEvent extends StickerEvent {
  constructor(public readonly segmentationId: string) {
    super();
  }

  get type(): Symbol {
    return SegmentationDeletedEventType;
  }
}
