import {
  DatasetUploadCompletedWithInfoNotification,
  NotificationLevel,
  ToastNotification,
} from '../notifications/models/notification.model';
import { Failed, OperationResult, StickerError, UploadSuccess } from '../../models/error.model';
import { IDatasetUpload, IDatasetUploadService, IImageToLoad, IUploadedImage, UploadServiceBase, WillImageUploadExceedsLimitResult } from './uploadServiceBase';

import { RejectStatus } from './models/RejectStatus';
import { action } from 'mobx';
import axios from 'axios';
import { injectable } from 'inversify';

export const ImagesFromUrlsServiceType = Symbol('IMAGES_FROM_URLS_SERVICE');

export interface IImageUrl extends IImageToLoad {
  id: string;
  url: string;
  isValid: boolean;
  rejectStatus?: RejectStatus;
}

interface IImageFromUrlPayload {
  datasetId: string;
  url: string;
}

export interface IImagesFromUrlsService extends IDatasetUploadService {
  willExceedsImageLimit(workspaceId: string, urls: string[]): Promise<WillImageUploadExceedsLimitResult | StickerError>;
}

@injectable()
export class ImagesFromUrlsUploadService extends UploadServiceBase<IImageUrl> implements IImagesFromUrlsService {
  @action
  async upload(item: IImageUrl, datasetId: string): Promise<OperationResult> {
    const url = '/datasets/addImageFromUrl';
    if (this.isDatasetUploadAborted(datasetId)) return new Failed();
    const result = await this.apiServiceImageUpload.postAsync<IImageFromUrlPayload, IUploadedImage>(url, {
      datasetId,
      url: item.url,
    } as IImageFromUrlPayload);

    if (result instanceof StickerError) {
      return new Failed(result);
    }
    return new UploadSuccess(result.imageSize);
  }

  async handleUploadError(datasetId: string, result: Failed, item: IImageUrl) {
    if (result.Error!.isBadRequestWithCode(['INVALID_URL', 'IMAGE_GET_ERROR'])) {
      this.notificationService.push(
        new ToastNotification(NotificationLevel.WARNING, { template: 'datasets:rejected_url', data: { url: item.url } }),
      );
      result.isHandled = true;
    }

    super.handleUploadError(datasetId, result, item);
  }

  datasetUploadCompletedWithInfoNotification(dataset: IDatasetUpload): void {
    this.notificationService.push(
      new DatasetUploadCompletedWithInfoNotification(
        dataset.datasetName,
        dataset.rejectedItems.map(it => (it as IImageUrl).url),
      ),
    );
  }

  async willExceedsImageLimit(workspaceId: string, urls: string[]): Promise<WillImageUploadExceedsLimitResult | StickerError> {
    const source = axios.CancelToken.source();

    setTimeout(() => { source.cancel(); }, 1000 * 60); // set 1min timeout

    const url = '/Datasets/WillUrlImageUploadExceedsLimit';
    const result = await this.apiServiceImageUpload.postAsync<any, WillImageUploadExceedsLimitResult>(
      url,
      { workspaceId, urls },
      { cancelToken: source.token });

    return result;
  }
}
