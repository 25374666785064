import * as React from 'react';

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../workspaces/currentWorkspace/CurrentWorkspace.store';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../common/OverlayLoader.store';
import { IProjectOverviewBl, ProjectOverviewBlType } from './projectOverview.bl';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../../__legacy__/helpers/react.helpers';

import { ReviewOverview } from './ReviewOverview';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ projectId: string }> {
  projectOverviewBl: IProjectOverviewBl;
  overlayLoaderStore: IOverlayLoaderStore;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

@injectProps({
  projectOverviewBl: ProjectOverviewBlType,
  overlayLoaderStore: OverlayLoaderStoreType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
@observer
class ReviewOverviewContainerPure extends React.Component<IInjectedProps> {
  render() {

    const store = this.props.projectOverviewBl.store;

    return (
      <ReviewOverview
        isLoading={this.props.overlayLoaderStore.isSpinnerVisible('project-overview')}
        toReviewCount={store.toReviewCount}
        acceptedCount={store.acceptedCount}
        rejectedCount={store.rejectedCount}
        projectId={this.props.match.params.projectId}
        workspaceId={this.props.currentWorkspaceStore.currentWorkspace!.id}
      />);
  }
}

export const ReviewOverviewWidgetContainer = as<React.ComponentClass>(withRouter(ReviewOverviewContainerPure));
