import { injectable, inject } from 'inversify';

import { IApiService } from '../../../__legacy__/services/api.service.base';
import { IPagedResult } from '../../../__legacy__/services/api.service';
import { StickerError } from '../../../__legacy__/models/error.model';
import { ModelOwnership } from '../models.store';
import { IModelListDto } from '../models.model';
import { ApiServiceTrainingType } from '../../../__legacy__/services/api.service.training';
import { IModelDetailsDto } from '../../modelDetails/modelDetails.model';
import { IPostRunTrainingDTo } from '../../modelDetails/services/modelDetailsApi.service';

export interface IGetModelsRequest {
  pageNumber: number;
  pageSize: number;
  orderBy: string;
  orderType: string;
  ownership: ModelOwnership;
}

interface IPostModelImportRequest {
  'job-id': string;
}

export interface IGetModelDownloadUrlDto {
  url: string;
}

export const ModelsApiServiceType = Symbol('MODELS_API_SERVICE');

export interface IModelsApiService {
  getModelsAsync(workspaceId: string, request: IGetModelsRequest): Promise<IPagedResult<IModelListDto> | StickerError>;
  getModelDetailsAsync(workspaceId: string, modelId: string): Promise<IModelDetailsDto | StickerError>;
  getDownloadModelAsync(workspaceId: string, modelId: string): Promise<IGetModelDownloadUrlDto | StickerError>;
  postModelTrainingRestartAsync(workspaceId: string, modelId: string): Promise<IPostRunTrainingDTo | StickerError>;
  postModelTrainingStopAsync(workspaceId: string, modelId: string): Promise<string | StickerError>;
  deleteModelTrainingAsync(workspaceId: string, modelId: string): Promise<string | StickerError>;
  postModelImportAsync(workspaceId: string, formData: FormData): Promise<IPostModelImportRequest | StickerError>;
}

@injectable()
export class ModelsApiService implements IModelsApiService {
  constructor(@inject(ApiServiceTrainingType) private readonly apiTrainingService: IApiService) {}

  async getModelsAsync(workspaceId: string, request: IGetModelsRequest): Promise<IPagedResult<IModelListDto> | StickerError> {
    return this.apiTrainingService.getAsync<IPagedResult<IModelListDto>>(`/workspaces/${workspaceId}/training-jobs`, { params: request });
  }

  async getModelDetailsAsync(workspaceId: string, modelId: string): Promise<IModelDetailsDto | StickerError> {
    return await this.apiTrainingService.getAsync(`/workspaces/${workspaceId}/training-jobs/${modelId}`);
  }

  async getDownloadModelAsync(workspaceId: string, modelId: string): Promise<IGetModelDownloadUrlDto | StickerError> {
    return this.apiTrainingService.getAsync(`/workspaces/${workspaceId}/training-jobs/${modelId}/model`);
  }

  async postModelTrainingRestartAsync(workspaceId: string, modelId: string): Promise<IPostRunTrainingDTo | StickerError> {
    return await this.apiTrainingService.postAsync(`/workspaces/${workspaceId}/training-jobs/${modelId}/start`);
  }

  async postModelImportAsync(workspaceId: string, formData: FormData): Promise<IPostModelImportRequest | StickerError> {
    return await this.apiTrainingService.postAsync(`/workspaces/${workspaceId}/training-jobs/model`, formData);
  }

  async postModelTrainingStopAsync(workspaceId: string, modelId: string): Promise<string | StickerError> {
    return await this.apiTrainingService.postAsync(`/workspaces/${workspaceId}/training-jobs/${modelId}/stop`);
  }

  async deleteModelTrainingAsync(workspaceId: string, modelId: string): Promise<string | StickerError> {
    return await this.apiTrainingService.deleteAsync(`/workspaces/${workspaceId}/training-jobs/${modelId}`);
  }
}
