import { DropdownItem } from 'reactstrap';
import React from 'react';

interface IDropdownItemProps {
  id?: string;
  linkTo: string;
  target?: string;
  className?: string;
}

export class DropdownExternalLinkItem extends React.PureComponent<IDropdownItemProps> {
  render() {
    return (
      <a href={this.props.linkTo} target={this.props.target || '_blank'} className={this.props.className} rel="noopener noreferrer">
        <DropdownItem id={this.props.id}>{this.props.children}</DropdownItem>
      </a>
    );
  }
}
