import { pageSizeOptions } from '../../design/pagination/paginationPageSize/S_PaginationPageSizeOptions';

export interface IPagingInfo {
  pageNumber: number;
  pagesCount: number;
  pageSize: number;
  totalCount: number;
}

export const PaginationInfoDefault: IPagingInfo = {
  pageNumber: 1,
  pagesCount: 1,
  pageSize: pageSizeOptions[0],
  totalCount: 0,
};

export interface IPagingInfoWithOrder extends IPagingInfo {
  orderBy: string;
  orderType: string;
  search?: string;
}
