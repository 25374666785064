import * as React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { Line } from 'react-chartjs-2';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { IChartPoint } from '../../../models/metrics.model';

interface IProps {
  train: IChartPoint[];
  validation: IChartPoint[];
}

const LossMetricsPure = ({ train, validation, t }: IProps & ITranslatable) => {
  const roundDecimal = (points: IChartPoint[]) => points.map(({ x, y }) => ({ x: x.toFixed(3), y: y.toFixed(3) }));

  const options = {
    scales: {
      xAxes: [
        {
          type: 'linear',
          position: 'bottom',
          scaleLabel: {
            fontSize: 18,
            display: true,
            labelString: t('loss_chart.xAxis'),
          },
          ticks: {
            min: 1,
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            fontSize: 18,
            display: true,
            labelString: t('loss_chart.yAxis'),
          },
          ticks: {
            min: 0,
          },
        },
      ],
    },
  };

  const data = {
    datasets: [
      {
        label: t('loss_chart.train'),
        data: roundDecimal(train),
        fill: false,
        borderColor: '#4bc0c0',
        smoothed: true,
      },
      {
        label: t('loss_chart.validation'),
        data: roundDecimal(validation),
        fill: false,
        borderColor: '#565656',
        steppedLine: true,
      },
    ],
  };

  return (
    <Card className="flex-fill slim-margin bg-transparent shadow-none">
      <CardBody>
        <CardTitle tag="h4">{t('loss_chart.title')}</CardTitle>
        <p>{t('available_real_time')}</p>
        <div style={{ height: '250px' }}>
          <Line data={data} options={{ ...options, maintainAspectRatio: false }} />
        </div>
      </CardBody>
    </Card>
  );
};

export const lossMetricsWithTranslation = (ns: 'models' | 'evaluations') => as<React.FC<IProps>>(withNamespaces(ns)(LossMetricsPure));
