import './S_TimePeriodSelector.scss';

import { FormGroup, Label } from 'reactstrap';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { faCalendar, faCalendarDay, faCalendarWeek } from '@fortawesome/free-solid-svg-icons';

import React from 'react';
import { S_IconSelect } from '../iconSelect/S_IconSelect';
import { S_IconSelectOptionProps } from '../iconSelect/option/S_IconSelectOption';
import { compact } from 'lodash';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';

export enum TimePeriod {
  All = 10,
  Day = 20,
  Week = 25,
  Month = 30,
  DateRange = 40,
}

interface S_TimePeriodSelectorProps {
  selectedTimePeriod: TimePeriod;
  customLabels?: { [key: string]: string };
  includeCustomRangeOption?: boolean;
  onPeriodChange(period: TimePeriod): void;
}

const S_TimePeriodSelectorPure: React.FC<S_TimePeriodSelectorProps & WithNamespaces> = (props: S_TimePeriodSelectorProps & WithNamespaces) => {
  const { t, onPeriodChange, selectedTimePeriod } = props;
  const includeCustomRangeOption = props.includeCustomRangeOption || false;

  const options: S_IconSelectOptionProps[] = compact([
    { label: (props.customLabels !== undefined && props.customLabels[TimePeriod.All.toString()]) || t('selects.timePeriodSelector.all'), value: TimePeriod.All, icon: faCalendar },
    {
      label: (props.customLabels !== undefined && props.customLabels[TimePeriod.Day.toString()]) || t('selects.timePeriodSelector.day'),
      value: TimePeriod.Day,
      icon: faCalendarDay,
    },
    {
      label: (props.customLabels !== undefined && props.customLabels[TimePeriod.Week.toString()]) || t('selects.timePeriodSelector.week'),
      value: TimePeriod.Week,
      icon: faCalendarWeek,
    },
    {
      label: (props.customLabels !== undefined && props.customLabels[TimePeriod.Month.toString()]) || t('selects.timePeriodSelector.month'),
      value: TimePeriod.Month,
      icon: faCalendarAlt,
    },
    includeCustomRangeOption && {
      label: (props.customLabels !== undefined && props.customLabels[TimePeriod.DateRange.toString()]) || t('selects.timePeriodSelector.date_range'),
      value: TimePeriod.DateRange,
      icon: faCalendarAlt,
    },
  ]);

  const handleTimePeriodChange = (value: S_IconSelectOptionProps) => {
    if (selectedTimePeriod !== value.value) onPeriodChange(value.value);
  };

  return (
    <FormGroup className="s-time-period-selector">
      <Label>{t('selects.timePeriodSelector.select_filters')}</Label>
      <S_IconSelect options={options} value={options.find(o => o.value === selectedTimePeriod)!} isSearchable={false} onChange={handleTimePeriodChange} />
    </FormGroup>
  );
};

export const S_TimePeriodSelector = withNamespaces('design')(S_TimePeriodSelectorPure);
