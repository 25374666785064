import { AddMarkingToolApiService, AddMarkingToolApiServiceImpl, AddMarkingToolApiServiceType } from './services/AddMarkingToolApi.service';
import { AddMarkingToolBl, AddMarkingToolBlImpl, AddMarkingToolBlType } from './AddMarkingTool.bl';
import { AddMarkingToolStore, AddMarkingToolStoreImpl, AddMarkingToolStoreSetter, AddMarkingToolStoreSetterType, AddMarkingToolStoreType } from './AddMarkingTool.store';
import { ContainerModule, interfaces } from 'inversify';

export const addMarkingToolModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<AddMarkingToolStore>(AddMarkingToolStoreType).to(AddMarkingToolStoreImpl).inSingletonScope();
  bind<AddMarkingToolStoreSetter>(AddMarkingToolStoreSetterType).toService(AddMarkingToolStoreType);

  bind<AddMarkingToolBl>(AddMarkingToolBlType).to(AddMarkingToolBlImpl).inSingletonScope();

  bind<AddMarkingToolApiService>(AddMarkingToolApiServiceType).to(AddMarkingToolApiServiceImpl).inSingletonScope();
});
