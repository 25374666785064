import * as React from 'react';
import { Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { as } from '../../../__legacy__/helpers/react.helpers';
import { ITranslatable } from '../../../__legacy__/helpers/translations.helpers';
import Dropzone from 'react-dropzone';
import { ValidatedTextInput } from '../../../__legacy__/components/ValidatedTextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonWithLoader } from '../../../__legacy__/components/ButtonWithLoader';
import classNames from 'classnames';

library.add(faUpload);

export interface IModelImportModalProps {
  showModal: boolean;
  data: {
    name: string;
    description: string;
    file: File | null;
    errors: {
      showStatus: boolean;
      name?: string;
    };
    acceptedFiles: File[];
    rejectedFiles: File[];
  };
  isLoading: boolean;

  toggleUploadModal(): void;
  handleImageDrop(files: File[]): void;
  handleChangeTextField(key: string): (value: string) => void;
  handleSubmit(): void;
}

const ModelImportModalPure = (props: IModelImportModalProps & ITranslatable) => {
  const isReadyToSubmit = React.useMemo(() => !!props.data.name && !!props.data.file, [props.data]);
  const hasValidFiles = props.data.acceptedFiles.length > 0;
  const hasInvalidFiles = props.data.rejectedFiles.length > 0;

  return (
    <Modal isOpen={props.showModal} toggle={props.toggleUploadModal} size="lg" className="modal-lg" backdrop={true}>
      <ModalHeader toggle={props.toggleUploadModal}>{props.t('import_new_model')}</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <p>Note: only models that were trained in Aurora Vision Studio are compatible to import.</p>
            <FormGroup>
              <Label>{props.t('name')}</Label>
              <ValidatedTextInput
                id="model-name-textbox"
                type="text"
                placeholder={props.t('type_your_model_name_here')}
                value={props.data.name}
                onChange={props.handleChangeTextField('name')}
                isValid={props.data.errors.showStatus && props.data.errors.name !== undefined ? props.data.errors.name.length === 0 : undefined}
                feedbacks={[props.data.errors.name ?? '']}
              />
            </FormGroup>
            <FormGroup>
              <Label>{props.t('description')}</Label>
              <ValidatedTextInput
                id="model-name-textbox"
                type="textarea"
                placeholder={props.t('type_your_model_description_here')}
                value={props.data.description}
                onChange={props.handleChangeTextField('description')}
              />
            </FormGroup>
            <FormGroup>
              <Label>{props.t('common:select_file')}</Label>
              {hasValidFiles ? (
                <p>
                  {props.t('common:selected_file')}: <strong>{props.data.acceptedFiles[0].name}</strong>
                </p>
              ) : (
                <strong>
                  <p>{props.t('select_zip')}</p>
                </strong>
              )}
              <Dropzone
                multiple={false}
                disabled={false}
                className={classNames({ 'dropzone-generic': true, 'is-invalid': hasInvalidFiles })}
                activeClassName="active"
                onDropRejected={props.handleImageDrop}
                onDropAccepted={props.handleImageDrop}
                accept={'.zip'}
              >
                <FontAwesomeIcon icon={faUpload} />
                {props.t('drop_your_model_file')}
              </Dropzone>
              {hasInvalidFiles && <p className="invalid-feedback">{props.t('invalid_file_type')}</p>}
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <ButtonWithLoader disabled={!isReadyToSubmit} isLoading={props.isLoading} color="success" onClick={props.handleSubmit}>
          {props.t('import')}
        </ButtonWithLoader>{' '}
        <ButtonWithLoader isLoading={props.isLoading} color="primary" id="cancel-button" onClick={props.toggleUploadModal}>
          {props.t('cancel')}
        </ButtonWithLoader>
      </ModalFooter>
    </Modal>
  );
};

export const ModelImportModal = as<React.FunctionComponent<IModelImportModalProps>>(withNamespaces('models', { wait: true })(ModelImportModalPure));
