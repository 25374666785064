import * as React from 'react';

import { Button, Col, CustomInput, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { IPlanInfo } from '../../user/user.store';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface ILockUserModalProps {
  lockAccountModalShown: boolean;
  planInfo: IPlanInfo;
  onLockAccountClicked(id: string): void;
  onLockAccountChange(locked: boolean): void;
  onLockAccountSave(): void;
}

const LockUserModal = (props: ILockUserModalProps & ITranslatable) => {
  if (!props.planInfo) return null;

  const handleToggle = () => props.onLockAccountClicked('');
  const handleChange = () => props.onLockAccountChange(props.planInfo.isActive);

  return (
    <Modal className="modal-width-600" isOpen={props.lockAccountModalShown} toggle={handleToggle}>
      <ModalHeader>{props.t('lock_account_modal_header')}</ModalHeader>
      <Form className="custom-inline-form">
        <ModalBody>
          <div>
            <Row>
              <Col md={11}>
                <FormGroup className="checkbox">
                  <CustomInput type="checkbox" id="lock-user-modal-checkbox" checked={!props.planInfo.isActive} onChange={handleChange} label={props.t('lock_account_label')} />
                </FormGroup>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button id="change-button" color="success" className="mr-2" onClick={props.onLockAccountSave}>
            {props.t('save')}
          </Button>
          <Button id="change-button-cancel" color="primary" className="btn-outline-primary" onClick={handleToggle}>
            {props.t('cancel')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default as<React.SFC>(withNamespaces('common', { wait: true })(LockUserModal));
