import * as React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IImageUploadProps, ImageUploadForm } from './ImageUploadForm';
import { faCloud, faExclamationTriangle, faUpload } from '@fortawesome/free-solid-svg-icons';

import { DatasetStatus } from '../datasetStatus.model';
import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { NoAccessTooltip } from '../../../containers/NoAccessTooltip';
import { as } from '../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';

library.add(faUpload, faCloud, faExclamationTriangle);

export enum UploadOption {
  None,
  Drive,
  Url,
}

interface IState {
  selectedUploadOption: UploadOption;
}

export interface IImageUploadModalProps extends IImageUploadProps {
  showModal: boolean;
  toggleUploadModal(): void;
  canUploadImages: boolean;
}

class ImageUploadModalPure extends React.Component<IImageUploadModalProps & ITranslatable, IState> {
  constructor(props: IImageUploadModalProps & ITranslatable) {
    super(props);

    this.state = {
      selectedUploadOption: UploadOption.None,
    };
  }

  @autobind
  onSelectDriveOption() {
    this.setState({ selectedUploadOption: UploadOption.Drive });
  }

  @autobind
  onSelectUrlOption() {
    this.setState({ selectedUploadOption: UploadOption.Url });
  }

  @autobind
  onCancel() {
    this.props.cleanImagesFromUrl(this.props.datasetId);
    this.props.cleanImagesFromFile(this.props.datasetId);
    this.setState({ selectedUploadOption: UploadOption.None });
  }

  @autobind
  toggleUploadModal() {
    this.props.toggleUploadModal();
    this.setState({ selectedUploadOption: UploadOption.None });
  }

  render() {
    return (
      <>
        <NoAccessTooltip hasAccess={this.props.canUploadImages}>
          <DisabledWhenWorkspaceOwnerPolicyExceeded>
            <Button id="publish-dataset-button" color="primary" onClick={this.toggleUploadModal} title={this.props.t('add_images')}>
              {this.props.t('add_images')}
            </Button>
          </DisabledWhenWorkspaceOwnerPolicyExceeded>
        </NoAccessTooltip>
        <Modal isOpen={this.props.showModal} toggle={this.toggleUploadModal} className="modal-lg upload-modal">
          <ModalHeader toggle={this.props.toggleUploadModal}>{this.props.t('add_images')}</ModalHeader>
          <ModalBody>
            <ImageUploadForm
              {...this.props}
              onCancel={this.onCancel}
              onSelectDriveOption={this.onSelectDriveOption}
              onSelectUrlOption={this.onSelectUrlOption}
              selectedUploadOption={this.state.selectedUploadOption}
              showSubmitButtons={false}
              canUploadImages={this.props.canUploadImages}
            />
          </ModalBody>
          {this.state.selectedUploadOption !== UploadOption.None && (
            <ModalFooter>
              <DisabledWhenWorkspaceOwnerPolicyExceeded>
                <Button
                  color={'primary'}
                  disabled={!this.props.isCreationEnabled}
                  onClick={this.props.datasetStatus === DatasetStatus.DRAFT ? this.props.onFormSubmit : this.props.toggleUploadConfirmationModal}
                  id="upload-button"
                  className="mr-2"
                >
                  {this.props.t('upload')}
                </Button>
              </DisabledWhenWorkspaceOwnerPolicyExceeded>
              <Button color={'primary'} outline onClick={this.onCancel} id="cancel-button">
                {this.props.t('cancel')}
              </Button>
            </ModalFooter>
          )}
        </Modal>
      </>
    );
  }
}

export const ImageUploadModal = as<React.StatelessComponent<IImageUploadModalProps>>(withNamespaces('datasets', { wait: true })(ImageUploadModalPure));
