import { IPagingInfo, PaginationInfoDefault } from '../../models/paginationInfo.model';
import {
  IProjectAvailableDatasetDto,
  IProjectDataset,
  IUserDetails,
  ImageAssignmentPolicy,
} from './projectDetails.models';
import { action, computed, observable } from 'mobx';

import { InputStatus } from '../../models/error.model';
import { ProjectStatus } from '../projects/projects.model';
import { SortingDirection } from '../../models/sortingDirection.model';
import { applyPagination } from '../../../common/pagination/applyPagination';
import { injectable } from 'inversify';
import { nameOf } from '../../helpers/object.helpers';
import { reorderArray } from '../../helpers/array.helpers';
import { sortBy } from '../../helpers/collections.helper';

export const ProjectDetailsStoreType = Symbol('PROJECT_DETAILS_STORE');

export interface IProjectDetailsStore {
  id: string;
  name: string;
  description: string;
  lastAnnotationDate?: string;
  status: ProjectStatus;
  imageAssignmentPolicy: ImageAssignmentPolicy;
  hasImportedAnnotations: boolean;

  nameStatus: InputStatus;
  descriptionStatus: InputStatus;
  datasetsStatus: InputStatus;
  usersStatus: InputStatus;
  imagesStatus: InputStatus;

  users: IUserDetails[];
  usersList: IUserDetails[];
  usersPagination: IPagingInfo;
  usersOrderBy: string;
  usersOrderDirection: SortingDirection;

  datasets: IProjectDataset[];
  availableDatasets: IProjectAvailableDatasetDto[];

  isReadyForPublish: boolean;

  createdDate: Date;
  isCreatedBeforeStatsRelease: boolean;
}

export interface IProjectDetailsStoreSetter extends IProjectDetailsStore {
  reorderDatasets(datasetId: string, index: number): void;
}

const defaultUsersSortingBy: string = nameOf<IUserDetails>('email');
const defaultUsersSortingDirection: SortingDirection = SortingDirection.ASC;

@injectable()
export class ProjectDetailsStore implements IProjectDetailsStoreSetter {
  @observable
  id: string = '';

  @observable
  name: string = '';

  @observable
  lastAnnotationDate?: string;

  @observable
  description: string = '';

  @observable
  status: ProjectStatus = ProjectStatus.Draft;

  @observable
  hasImportedAnnotations: boolean = false;

  @observable
  users: IUserDetails[] = [];

  // https://github.com/kulshekhar/ts-jest/pull/308/files
  @observable
  imageAssignmentPolicy: ImageAssignmentPolicy = 'Random' as ImageAssignmentPolicy;

  @observable
  datasets: IProjectDataset[] = [];

  @observable
  usersPagination: IPagingInfo = PaginationInfoDefault;

  @observable
  availableDatasets: IProjectAvailableDatasetDto[] = [];

  @observable
  nameStatus: InputStatus = InputStatus.empty();

  @observable
  descriptionStatus: InputStatus = InputStatus.empty();

  @observable
  datasetsStatus: InputStatus = InputStatus.empty();

  @observable
  usersStatus: InputStatus = InputStatus.empty();

  @observable
  imagesStatus: InputStatus = InputStatus.empty();

  @observable
  usersOrderBy: string = defaultUsersSortingBy;

  @observable
  usersOrderDirection: SortingDirection = defaultUsersSortingDirection;

  @observable
  createdDate: Date = new Date();

  @observable
  isCreatedBeforeStatsRelease: boolean = false;

  isReadyForPublish: boolean = true;

  @action
  reorderDatasets(datasetId: string, index: number): void {
    const order = this.datasets[index].order;
    const ordered = reorderArray(this.datasets, datasetId, order);

    this.datasets = [...ordered];
  }

  @computed
  get usersList(): IUserDetails[] {
    const sorted = sortBy(
      this.users,
      this.usersOrderBy,
      this.usersOrderDirection,
      defaultUsersSortingBy,
      defaultUsersSortingDirection,
    );
    return applyPagination(sorted, this.usersPagination);
  }
}
