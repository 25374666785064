import { inject, injectable } from 'inversify';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { ProjectRole } from '../../models/userRole.model';
import { WorkspaceRole, NonWorkerRoles, ProjectManagementRoles } from '../workspaces/workspaces.store';

export const ProjectsPermissionsType = Symbol('PROJECTS_PERMISSIONS');

export interface IProjectsPermissions {
  canAnnotate(projectRole: ProjectRole): boolean;
  canReview(projectRole: ProjectRole): boolean;
  canView(): boolean;
  canDelete(): boolean;
  canEdit(): boolean;
  canExport(): boolean;
}

@injectable()
export class ProjectsPermissions implements IProjectsPermissions {
  constructor(@inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore) {}

  canAnnotate(projectRole: ProjectRole) {
    return [ProjectRole.Annotator, ProjectRole.Reviewer].includes(projectRole);
  }

  canReview(projectRole: ProjectRole) {
    return [ProjectRole.Reviewer].includes(projectRole);
  }

  canDelete() {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    return ProjectManagementRoles.includes(currentWorkspaceRole);
  }

  canEdit(): boolean {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    return ProjectManagementRoles.includes(currentWorkspaceRole);
  }

  canExport(): boolean {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    return ProjectManagementRoles.includes(currentWorkspaceRole);
  }

  canView(): boolean {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    return NonWorkerRoles.includes(currentWorkspaceRole);
  }
}
