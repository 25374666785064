import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

interface IProps {
  label: string;
  title: string;
}

export class WarningIconBadge extends React.Component<IProps> {
  render() {
    return (
      <span className="icon-badge" title={this.props.title}>
        <FontAwesomeIcon icon={faExclamationTriangle} />
        {this.props.label}
      </span>
    );
  }
}
