import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IWizardModalNavigationProps {
  modalHidden: () => void;
  nextPressed: () => void;
}

interface IProps extends IWizardModalNavigationProps {
  title: string;
  bodyClassName?: string;
  isLastStep?: boolean;
}

class WizardModalBaseContentPure extends React.Component<IProps & ITranslatable> {
  static defaultProps = {
    isLastStep: false,
  };

  render() {
    return (
      <>
        <ModalHeader toggle={this.props.modalHidden}>{this.props.title}</ModalHeader>
        <ModalBody className={this.props.bodyClassName}>{this.props.children}</ModalBody>
        <ModalFooter>
          <Button onClick={this.props.nextPressed} color="primary" className={!this.props.isLastStep ? 'mr-2' : ''}>
            {this.props.isLastStep ? this.props.t('close') : this.props.t('next')}
          </Button>
          {!this.props.isLastStep && (
            <Button outline color="primary" onClick={this.props.modalHidden}>
              {this.props.t('cancel')}
            </Button>
          )}
        </ModalFooter>
      </>
    );
  }
}

export const WizardModalBaseContent = as<React.ComponentClass<IProps>>(withNamespaces()(WizardModalBaseContentPure));
