import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IProps {
  onConfirm(): void;
  onCancel(): void;
  isOpen: boolean;
}

class QuitAnnotationsModalPure extends React.PureComponent<IProps & ITranslatable> {
  render() {
    return (
      <ConfirmationModal
        showModal={this.props.isOpen}
        onCancel={this.props.onCancel}
        onConfirm={this.props.onConfirm}
        confirmationHeader="warning"
        confirmationQuestion="are_you_sure_you_want_to_quit"
        confirmationYes={this.props.t('quit')}
      />
    );
  }
}

export const QuitAnnotationsModal = as<React.ComponentClass<IProps>>(withNamespaces('annotation', { wait: true })(QuitAnnotationsModalPure));
