import { AnnotationReviewStoreType, IAnnotationReviewStore } from '../annotationReview.store';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import React from 'react';
import { ReviewMode } from '../models/reviewMode';
import { observer } from 'mobx-react';

interface IProps {
  children: (model: ReviewMode) => JSX.Element;
}

interface IInjectProps {
  reviewStore: IAnnotationReviewStore;
}

@injectProps({
  reviewStore: AnnotationReviewStoreType,
})
@observer
class ReviewModeBuilderContainerPure extends React.Component<IInjectProps & IProps> {
  render() {
    return this.props.children!(this.props.reviewStore.currentMode);
  }
}

export const ReviewModeBuilderContainer = as<React.ComponentClass>(ReviewModeBuilderContainerPure);
