import { AuthService, AuthServiceType, IAuthService } from './auth.service';
import { AuthStore, AuthStoreSetterType, AuthStoreType, IAuthStore, IAuthStoreSetter } from './auth.store';
import { ContainerModule, interfaces } from 'inversify';

export const authModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IAuthStore>(AuthStoreType)
    .to(AuthStore)
    .inSingletonScope();
  bind<IAuthStoreSetter>(AuthStoreSetterType)
    .toService(AuthStoreType);
  bind<IAuthService>(AuthServiceType)
    .to(AuthService)
    .inSingletonScope();
});
