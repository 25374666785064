import { Home } from './config/Home';
import { NotFoundView } from '../views/NotFound.view';
import React from 'react';
import { Route } from 'react-router';
import { SwitchWithNotFound } from '../containers/route.containers';
import { freeAccessViewImageView } from '../views/home/freeAccessAnnotations/freeAccessViewImage.view';

export const FreeAccessRoute = () => (
  <SwitchWithNotFound notFound={NotFoundView}>
    <Route path={Home.FreeAccess.View.Path} component={freeAccessViewImageView} />
  </SwitchWithNotFound>
);
