import { IImageFilters, IImageFiltersData } from '../../imageFilters.model';
import { ListGroup, Modal, ModalBody, ModalHeader } from 'reactstrap';

import { AnnotatedByFilterInfo } from './annotatedBy/AnnotatedByFilterInfo';
import { AnnotationToolsFilterInfo } from './annotationTools/AnnotationToolsFilterInfo';
import { CorrectedFilterInfo } from './corrected/CorrectedFilterInfo';
import { ITranslatable } from '../../../../../../helpers/translations.helpers';
import { ImageNameFilterInfo } from './ImageNameFilterInfo';
import { ImageSetFilterInfo } from './imageSet/ImageSetFilterInfo';
import { InDatasetsFilterInfo } from './inDatasets/InDatasetsFilterInfo';
import React from 'react';
import { ReviewedByFilterInfo } from './reviewdBy/ReviewedByFilterInfo';
import { SortingFilterInfo } from './sorting/SortingFilterInfo';
import { StatusFilterInfo } from './status/StatusFilterInfo';
import { as } from '../../../../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IFiltersIconProps {
  availableFilters: IImageFiltersData;
  imageFilters: IImageFilters;
  isOpen: boolean;
  toggle(): void;
}

class FiltersModalPure extends React.Component<IFiltersIconProps & ITranslatable> {
  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="filter-modal">
        <ModalHeader toggle={this.props.toggle}>{this.props.t('image_filters.active_filters')}</ModalHeader>
        <ModalBody>
          <ListGroup>
            <ImageNameFilterInfo {...this.props} />
            <InDatasetsFilterInfo {...this.props} />
            <AnnotatedByFilterInfo {...this.props} />
            <ReviewedByFilterInfo {...this.props} />
            <StatusFilterInfo {...this.props} />
            <AnnotationToolsFilterInfo {...this.props} />
            <ImageSetFilterInfo {...this.props} />
            <CorrectedFilterInfo {...this.props} />
            <SortingFilterInfo {...this.props} />
          </ListGroup>
        </ModalBody>
      </Modal>
    );
  }
}

export const FiltersModal = as<React.ComponentClass<IFiltersIconProps>>(withNamespaces('project')(FiltersModalPure));
