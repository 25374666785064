import { QuestionType } from '../../../annotation/question.model';

export interface IProjectToolStatisticsDto {
  projectId: string;
  generatedAt: string;
  headers: IToolStatisticHeaders;
  allAnnotationTypeStatistics: IAnnotationStatusStatisticsDto[];
  allQuestionsStatistics: IAnnotationStatusStatisticsDto[];
  allImageSetsStatistics: IAnnotationStatusStatisticsDto[];
  annotationTypesStatistics: IToolStatisticsDto[];
  questionStatistics: IToolStatisticsDto[];
  imageSetsStatistics: IToolStatisticsDto[];
}

export interface IToolStatisticHeaders {
  annotationToolsHeaders: IToolStatisticHeader[];
  annotationQuestionHeaders: IToolStatisticHeader[];
  imageSetsHeaders: IImageSetHeader[];
  isUpToDate: boolean;
}

export interface IToolStatisticHeader {
  id: string;
  name: string;
  type: string;
  usageCount: number;
  order: number;
}

export interface IImageSetHeader {
  id: string;
  name: string;
  count: number;
}

export interface IToolStatisticsDto {
  annotationStatusStatistics: IAnnotationStatusStatisticsDto;
  answerStatistics: IAnswerStatisticDto[];
}

export interface IAnnotationStatusStatisticsDto {
  name: string;
  id: string;
  toReview: number;
  accepted: number;
  rejected: number;
}

export interface IAnswerStatisticDto {
  text: string;
  id: string;
  answers: IQuestionAnswerStatisticDto[];
  order: number;
  questionType: QuestionType;
}

export interface IQuestionAnswerStatisticDto {
  text: string;
  id: string;
  order: number;
  annotated: number;
  approved: number;
  rejected: number;
}

export interface IStatisticsListItem {
  id: string;
  date: string;
  markedObjects: number;
  answeredAttributes: number;
  answeredQuestions: number;
  imagesAnnotated: number;
  annotationDuration: string;
  annotatingUsersCount: number;
  imagesReviewed: number;
  reviewDuration: string;
  reviewersCount: number;
  cumulativeAnnotatedImages: number;
  cumulativeReviewedImages: number;
  averageObjectsPerImage: number;
}

export enum CurrentStatisticsView {
  OVERALL,
  TOOLS,
}

export enum ToolStatisticsViewMode {
  NONE,
  All_TOOLS,
  ALL_QUESTIONS,
  TOOL,
  QUESTION,
  ALL_IMAGE_SETS,
  IMAGE_SET,
}
