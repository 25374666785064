import * as React from 'react';

import { IIntegrationsService, IntegrationsServiceType } from '../integrations.service';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { IWorkspaceService, WorkspaceServiceType } from '../../workspaces/workspaces.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { IntegrationsList } from '../components/integrationsList';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

export interface IInjectedProps extends RouteComponentProps, ITranslatable {
  integrationsService: IIntegrationsService;
  workspaceService: IWorkspaceService;
}

interface IState extends ILoaderState {
  isDeleteModalShow: boolean;
  apiKeyId?: string;
}

@injectProps({
  integrationsService: IntegrationsServiceType,
  workspaceService: WorkspaceServiceType,
})
@observer
class IntegrationsListContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  constructor(props: IInjectedProps) {
    super(props);
    this.state = { isDeleteModalShow: false, apiKeyId: undefined, isLoading: false };
  }

  // tslint:disable-next-line:function-name
  UNSAFE_componentWillMount() {
    this.withLoaderAsync(() => this.props.integrationsService.loadApiKeys());
  }
  
  handleDeleteApiKey = () => {
    this.withLoaderAsync(() => {
      if (this.state.apiKeyId !== undefined) this.props.integrationsService.deleteApiKey(this.state.apiKeyId);

      this.hideModal();
    });
  };

  hideModal = () => this.setState({ isDeleteModalShow: false });

  showModal = (apiKeyId: string) =>
    this.setState({
      apiKeyId,
      isDeleteModalShow: true,
    });

  render() {
    return (
      <>
        <IntegrationsList apiKeys={this.props.integrationsService.store.apiKeys} isLoading={this.state.isLoading} onDeleteApiKey={this.showModal} />
        <ConfirmationModal
          showModal={this.state.isDeleteModalShow}
          confirmationHeader={this.props.t('delete_confirmation_api_key_header')}
          confirmationQuestion={this.props.t('delete_confirmation_api_key_message')}
          onCancel={this.hideModal}
          onConfirm={this.handleDeleteApiKey}
          confirmationYes={this.props.t('delete')}
        />
      </>
    );
  }
}

export const IntegrationsListContainer = as<React.ComponentClass>(withRouter(withNamespaces('common')(IntegrationsListContainerPure)));
