import './MonthPicker.scss';

import React, { useState } from 'react';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormGroup } from 'reactstrap';
import { IDatePickerProps } from '../DayPicker';
import { NavigationButton } from '../../buttons/NavigationButton';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';

export const MonthPicker: React.FC<IDatePickerProps> = (props: IDatePickerProps) => {
  const { selectedDate, onSetDate, minDate, maxDate, onClickNext, onClickPrevious, canClickNext, canClickPrevious } = props;

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(prevState => !prevState);
  };

  const onChange = (date: Date) => {
    onSetDate(date);
    toggle();
  };

  const renderLabel = (): React.ReactNode => (
    <div className="date-label-container" onClick={toggle}>
      <div>
        <label onClick={toggle}>{moment(selectedDate).format('MMMM yyyy')}</label>
      </div>
      <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} onClick={toggle} />
    </div>
  );

  return (
    <FormGroup className="date-range-field month-picker">
      <NavigationButton onClick={onClickPrevious} disabled={!canClickPrevious} left />
      <ReactDatePicker
        onClickOutside={toggle}
        selected={selectedDate}
        onChange={onChange}
        dateFormat="MMMM yyyy"
        showMonthYearPicker
        open={isOpen}
        customInput={renderLabel()}
        minDate={minDate}
        maxDate={maxDate}
        calendarStartDay={1}
        disabledKeyboardNavigation
      />
      <NavigationButton onClick={onClickNext} disabled={!canClickNext} />
    </FormGroup>
  );
};
