import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../modules/common/OverlayLoader.store';
import { as, injectProps } from '../helpers/react.helpers';

import { OverlayLoader } from '../components/OverlayLoader';
import React from 'react';
import { observer } from 'mobx-react';

interface IInjectedProps {
  overlayLoaderStore: IOverlayLoaderStore;
}

@injectProps({
  overlayLoaderStore: OverlayLoaderStoreType,
})
@observer
class OverlayLoaderContainerPure extends React.Component<IInjectedProps> {

  render() {
    return <OverlayLoader isVisible={this.props.overlayLoaderStore.isOverlayVisible()} />;
  }
}

export const OverlayLoaderContainer = as<React.ComponentClass>(OverlayLoaderContainerPure);
