import * as React from 'react';
import { Trans, withNamespaces } from 'react-i18next';
import { ITranslatable } from '../../../../__legacy__/helpers/translations.helpers';
import { as } from '../../../../__legacy__/helpers/react.helpers';
import { ModelDatasetsListTable } from './ModelDatasetsListTable';
import { IModelDataset } from '../../modelDetails.store';
import { MODEL_STATUS } from '../../../models/models.model';
import { Loader } from '../../../../__legacy__/components/Loader';

interface IProps {
  datasets: IModelDataset[];
  datasetIds: string[];
  status: MODEL_STATUS;
  changeDatasetSelection(e: React.ChangeEvent<HTMLInputElement>): void;
  changeAllDatasetsSelection(): void;
  isLoading: boolean;
  isFormDisabled: boolean;
}

export const ModelDatasetsListPure = (props: IProps & ITranslatable) => {
  const { t, datasets,isFormDisabled, datasetIds, status, changeDatasetSelection, changeAllDatasetsSelection, isLoading } = props;

  return (
    <div>
      <div className="mb-4">
        <h1>{t('select_from_published_datasets')}</h1>
        <Trans
          i18nKey="select_from_published_datasets_description"
          // tslint:disable-next-line:jsx-key
          components={[<strong>0</strong>]}
        />
      </div>
      <div>
        <Loader isLoading={isLoading} class="model-datasets-list">
          <ModelDatasetsListTable
            isFormDisabled={isFormDisabled}
            datasets={datasets}
            datasetIds={datasetIds}
            status={status}
            changeDatasetSelection={changeDatasetSelection}
            changeAllDatasetsSelection={changeAllDatasetsSelection}
          />
        </Loader>
      </div>
    </div>
  );
};

export const ModelDatasetsList = as<React.FC<IProps>>(withNamespaces('models')(ModelDatasetsListPure));
