import { ITeamMember } from '../models/TeamMember';
import { inject, injectable } from 'inversify';
import { ApiServiceType } from '../../../services/api.service';
import { IApiService } from '../../../services/api.service.base';
import { StickerError } from '../../../models/error.model';
import { TimePeriod } from '../../../../design/selects/timePeriodSelector/S_TimePeriodSelector';

export const GetWorkspaceTeamMembersServiceType = Symbol('GET_WORKSPACE_TEAM_MEMBERS_SERVICE');

export class GetTeamMembersRequest {
  constructor(public readonly workspaceId: string, public readonly timePeriod: TimePeriod) {}
}

class GetTeamMembersResponse {
  private _teamMembers: ITeamMember[];

  constructor(workspaceMembers: ITeamMember[]) {
    this._teamMembers = workspaceMembers;
  }
  public get teamMembers(): ITeamMember[] {
    return this._teamMembers;
  }

  public get statisticsUpdateTime(): Date {
    return new Date();
  }
}

export interface IGetTeamMembersService {
  getTeamMembersAsync: (request: GetTeamMembersRequest) => Promise<GetTeamMembersResponse>;
}

@injectable()
export class GetTeamMembersService implements IGetTeamMembersService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService) {}

  public async getTeamMembersAsync(request: GetTeamMembersRequest) {
    const result = await this.apiService.getAsync<ITeamMember[]>('/Workspace/getTeamMembers', { params: request });

    if (result instanceof StickerError) {
      throw result;
    }

    return new GetTeamMembersResponse(result);
  }
}
