import { action, IReactionDisposer, reaction } from 'mobx';
import { injectable, inject } from 'inversify';
import { AnnotationTypeStoreSetterType, IAnnotationTypeStoreSetter } from './annotationType.store';
import { clamp } from 'lodash/fp';
import { FreeDrawSegmentationService, FreeDrawSegmentationServiceType } from '../../freeDrawSegmentation.service';
import { BRUSH_MODE, IBrushTypeOptions, IImage } from '../../annotations.interface';
import { IAnnotationTypeOptions } from './models/annotationTypeOptions';
import { AnnotationToolType } from './models/annotationToolType';
import { IAnnotationType } from './models/annotationType';
import { DefaultConfiguration } from '../../components/DrawControl.models/Brush/BrushConfig';
import { AnnotationUiStoreType, IAnnotationUiStore } from '../../annotationUi.store';
import { GetAnnotationTypesRequest, GetAnnotationTypesService, GetAnnotationTypesServiceType } from './services/getAnnotationTypesService';
import { StickerError } from '../../../../models/error.model';
import { IProjectHubService, ProjectHubServiceType } from '../../../../services/projectHub.service';
import { IAnnotationsStore, AnnotationsStoreType } from '../../annotations.store';

export const AnnotationTypeBlType = Symbol('ANNOTATION_TYPE_SERVICE');

const clampDiameter = clamp(DefaultConfiguration.minRadius * 2, DefaultConfiguration.maxRadius * 2);

export interface IAnnotationTypeBl {
  // Interacting with annotationService to avoid cross reference;
  onDeselectSegmentationInKeyBinding: (() => void) | undefined;
  whenImageIsLoaded: (() => Promise<IImage>) | undefined;

  // SetUp
  creationStarted(projectId: string): Promise<void>;
  reviewStarted(projectId: string): Promise<void>;
  freeAccessStarted(projectId: string, resetSelection: boolean): Promise<void>;
  previewStarted(annotationTypes: IAnnotationType[]): void;

  // Replacing TearDown
  creationFinished(): void;
  reviewFinished(): void;
  previewFinished(): void;
  freeAccessFinished(): void;

  // Refresh annotationTypesIfNeeded
  handleSubmitAnnotationInFreeAccess(): void;
  handleFinishWorkWithImageInCreation(): void;
  handleDisplayNextImageInReview(): void;

  // Replacing annotationTypeStore.activeAnnotationTypeId =
  handleMoveToNextSegmentation(): void;
  handleSegmentationAnswerAddedCommandRedo(): void;

  // Replacing annotationService.setActiveAnnotationType(annotationTypeId?:string): Promise<boolean>
  handleSubmitReview(): void;
  handleSubmitFixInFreeAccess(): void;
  handleSaveAnnotationsInCreation(): void;
  handleSubmitAnnotationInCreation(): void;
  handleInvalidSubmitAnnotationInFreeAccess(): void;
  handleAnnotationRemoveInDrawingContainer(): void;
  handleSegmentationTapInSegmetationContainer(): void;
  handleWillUnmountInAnnotationTypeHotKeyContainer(): void;
  handleSelectedAnnotationDeleteInAnnotationTypeContainer(): void;
  handleSegmentationDeselectedInSegmentationContainer(): Promise<boolean>;
  handleAnnotationTypeSelectedInAnnotationTypeContainer(annotationTypeId: string): void;
  handleSegmentationClickedInSegmentationContainer(annotationTypeId?: string): Promise<boolean>;
  handleReject(): void;

  getShortcutKey(order: number): string | undefined;

  setAnnotationTypeOptions(projectId: string, annotationTypeOptions: IAnnotationTypeOptions): void;
  unhideAllAnnotationTypeOptions(projectId: string): void;
  setDefaultAnnotationTypeOptions(projectId: string, annotationTypes: IAnnotationType[], image?: IImage): void;
}

@injectable()
export class AnnotationTypeBl implements IAnnotationTypeBl {
  eraserReactionDispose: IReactionDisposer;
  private projectId?: string;

  constructor(
    @inject(AnnotationsStoreType) private readonly annotationsStore: IAnnotationsStore,
    @inject(AnnotationUiStoreType) private readonly uiStore: IAnnotationUiStore,
    @inject(ProjectHubServiceType) private readonly projectHubService: IProjectHubService,
    @inject(AnnotationTypeStoreSetterType) private readonly store: IAnnotationTypeStoreSetter,
    @inject(GetAnnotationTypesServiceType) private readonly getAnnotationTypesService: GetAnnotationTypesService,
    @inject(FreeDrawSegmentationServiceType) private readonly freeDrawSegmentationService: FreeDrawSegmentationService,
  ) {
    this.eraserReactionDispose = reaction(r => this.freeDrawSegmentationService.freeDrawInProgress || this.freeDrawSegmentationService.freeDrawSelected, this.toggleEraser);
  }

  // Interacting with annotationService so that there is no cross reference.
  onDeselectSegmentationInKeyBinding: (() => void) | undefined = undefined;
  whenImageIsLoaded: (() => Promise<IImage>) | undefined = undefined;

  async reviewStarted(projectId: string): Promise<void> {
    await this.start(projectId);
  }

  async creationStarted(projectId: string): Promise<void> {
    await this.start(projectId);
  }

  async freeAccessStarted(projectId: string, resetSelection: boolean): Promise<void> {
    await this.start(projectId, resetSelection);
  }

  previewStarted(annotationTypes: IAnnotationType[]): void {
    this.setAnnotationTypes(annotationTypes);
    this.setActiveAnnotationType(AnnotationToolType.SELECT);
  }

  private async start(projectId: string, resetSelection: boolean = true): Promise<void> {
    if (this.projectId === projectId) {
      this.refreshAnnotationTypes();
    } else {
      this.projectId = projectId;
      await this.downloadAnnotationTypes(projectId);
    }
    if (resetSelection) {
      this.setActiveAnnotationType(AnnotationToolType.SELECT);
    }
  }

  creationFinished(): void {
    this.finish();
  }

  reviewFinished(): void {
    this.finish();
  }

  freeAccessFinished(): void {
    this.finish();
  }

  previewFinished(): void {
    this.finish();
  }

  private finish() {
    this.store.clean();
    this.projectId = undefined;
  }

  handleDisplayNextImageInReview(): void {
    this.refreshAnnotationTypes();
  }

  handleFinishWorkWithImageInCreation(): void {
    this.refreshAnnotationTypes();
  }

  handleSubmitAnnotationInFreeAccess() {
    this.refreshAnnotationTypes();
  }

  dispose(): void {
    this.eraserReactionDispose();
  }

  handleAnnotationRemoveInDrawingContainer(): void {
    this.setActiveAnnotationType(AnnotationToolType.SELECT);
  }

  handleSegmentationTapInSegmetationContainer(): void {
    this.setActiveAnnotationType(AnnotationToolType.SELECT);
  }

  handleWillUnmountInAnnotationTypeHotKeyContainer(): void {
    this.setActiveAnnotationType(AnnotationToolType.SELECT);
  }

  handleAnnotationTypeSelectedInAnnotationTypeContainer(annotationTypeId: string): void {
    if (this.store.activeAnnotationTypeId !== annotationTypeId) {
      this.setActiveAnnotationType(annotationTypeId);
    }
  }

  handleSelectedAnnotationDeleteInAnnotationTypeContainer(): void {
    this.setActiveAnnotationType(AnnotationToolType.SELECT);
  }

  handleSaveAnnotationsInCreation(): void {
    this.setActiveAnnotationType(AnnotationToolType.SELECT);
  }

  handleMoveToNextSegmentation(): void {
    this.store.setActiveAnnotationTypeId(AnnotationToolType.SELECT);
  }

  handleSegmentationAnswerAddedCommandRedo(): void {
    this.store.setActiveAnnotationTypeId();
  }

  handleSubmitFixInFreeAccess(): void {
    this.setActiveAnnotationType(AnnotationToolType.SELECT);
  }

  handleSubmitReview(): void {
    this.setActiveAnnotationType(AnnotationToolType.SELECT);
  }

  handleReject(): void {
    this.setActiveAnnotationType(AnnotationToolType.SELECT);
  }

  handleInvalidSubmitAnnotationInFreeAccess(): void {
    this.setActiveAnnotationType(AnnotationToolType.SELECT);
  }

  handleSubmitAnnotationInCreation(): void {
    this.setActiveAnnotationType(AnnotationToolType.SELECT);
  }

  async handleKeyBindingAnnotationTypeSelected(annotationTypeId: string): Promise<boolean> {
    return await this.setActiveAnnotationType(annotationTypeId);
  }

  async handleSegmentationClickedInSegmentationContainer(annotationTypeId?: string): Promise<boolean> {
    return await this.setActiveAnnotationType(annotationTypeId);
  }

  async handleSegmentationDeselectedInSegmentationContainer(): Promise<boolean> {
    return await this.setActiveAnnotationType(AnnotationToolType.SELECT);
  }

  private async setActiveAnnotationType(annotationTypeId?: string): Promise<boolean> {
    if (!(await this.freeDrawSegmentationService.clearAsync())) return false;

    let activeAnnotationTypeId = annotationTypeId;
    if (this.store.activeAnnotationTypeId === annotationTypeId || annotationTypeId === AnnotationToolType.SELECT || annotationTypeId === undefined) {
      activeAnnotationTypeId = undefined;
    }
    this.store.setActiveAnnotationTypeId(activeAnnotationTypeId);
    return true;
  }

  private refreshAnnotationTypes(): void {
    if (this.projectHubService.didAnnotationTypesChangedFor(this.projectId!)) {
      const result = this.projectHubService.popNewAnnotationTypesFor(this.projectId!);
      this.setAnnotationTypes(result);
    }
  }

  private async downloadAnnotationTypes(projectId: string): Promise<void> {
    const request = new GetAnnotationTypesRequest(projectId);
    const result = await this.getAnnotationTypesService.requestAnnotationTypesAsync(request);

    if (result instanceof StickerError) throw result;
    this.setAnnotationTypes(result.annotationTypes);
  }

  private setAnnotationTypes(annotationTypes: IAnnotationType[]): void {
    this.store.setAnnotationTypes(annotationTypes);
    this.buildKeyBinding(this.store.annotationTypes, () => {
      this.freeDrawSegmentationService.clear();
      this.onDeselectSegmentationInKeyBinding?.();
    });

    this.whenImageIsLoaded?.().then((image: IImage) => this.setDefaultAnnotationTypeOptions(this.annotationsStore.projectId, this.store.annotationTypes, image));
  }

  setDefaultAnnotationTypeOptions(projectId: string, annotationTypes: IAnnotationType[], image?: IImage): void {
    const annotationTypesOptions: IAnnotationTypeOptions[] = [];

    annotationTypes.forEach((annotationType: IAnnotationType) => {
      let annotationToolOptions: IAnnotationTypeOptions = {
        id: annotationType.id,
        toolType: annotationType.selectorType,
        isVisible: !(this.store.hiddenAnnotationTypes.find(x => x.projectId === projectId)?.annotationTypes.some(x => x === annotationType.id) || false),
      };

      switch (annotationType.selectorType) {
        case AnnotationToolType.BRUSH:
          annotationToolOptions = {
            ...annotationToolOptions,
            brushDiameter: !!image ? clampDiameter(Math.round(0.05 * Math.min(image.width, image.height))) : DefaultConfiguration.radius * 2,
            brushMode: BRUSH_MODE.DRAW,
            eraserAvailable: this.freeDrawSegmentationService.freeDrawInProgress,
            onDiameterChange: (diameter: number) => this.setAnnotationTypeDiameter(annotationType.id, diameter),
          } as IBrushTypeOptions;
          break;
      }
      annotationTypesOptions.push(annotationToolOptions);
    });

    this.store.setAnnotationTypeOptions(annotationTypesOptions);
  }

  setAnnotationTypeOptions(projectId: string, annotationTypeOptions: IAnnotationTypeOptions): void {
    const index = this.store.annotationTypesOptions.findIndex(a => a.id === annotationTypeOptions.id);
    if (index === -1) return;

    const annotationTypesOptions = this.store.annotationTypesOptions;
    Object.assign(annotationTypesOptions[index], annotationTypeOptions);

    this.store.setAnnotationTypeOptions([...annotationTypesOptions]);

    const storeIndex = this.store.hiddenAnnotationTypes.findIndex(x => x.projectId === projectId);
    const annotationTypes = this.store.annotationTypesOptions.filter(x => !x.isVisible).map(x => x.id);

    if (storeIndex !== -1) {
      this.store.hiddenAnnotationTypes.splice(storeIndex, 1);
    }
    this.store.setHiddenAnnotationTypes([...this.store.hiddenAnnotationTypes, { projectId, annotationTypes }]);
  }

  @action.bound
  unhideAllAnnotationTypeOptions(projectId: string): void {
    const storeIndex = this.store.hiddenAnnotationTypes.findIndex(x => x.projectId === projectId);
    if (storeIndex !== -1) {
      this.store.hiddenAnnotationTypes.splice(storeIndex, 1);
    }
    this.store.setHiddenAnnotationTypes([...this.store.hiddenAnnotationTypes]);

    const annotationTypesOptions = this.store.annotationTypesOptions;
    this.store.annotationTypesOptions.forEach(x => (x.isVisible = true));
    this.store.setAnnotationTypeOptions([...annotationTypesOptions]);
  }

  @action.bound
  setAnnotationTypeDiameter(id: string, diameter: number) {
    const annotationTypeOptions = this.store.annotationTypesOptions.find(o => o.id === id) as IBrushTypeOptions;
    if (!annotationTypeOptions) return;

    annotationTypeOptions.brushDiameter = diameter;
    this.store.setAnnotationTypeOptions([...this.store.annotationTypesOptions, annotationTypeOptions]);
  }

  @action.bound
  toggleEraser(value: boolean) {
    this.store.setAnnotationTypeOptions(
      this.store.annotationTypesOptions.map((options: IAnnotationTypeOptions) => {
        switch (options.toolType) {
          case AnnotationToolType.BRUSH:
            const newOptions = options as IBrushTypeOptions;
            newOptions.eraserAvailable = value;
            if (!value) newOptions.brushMode = BRUSH_MODE.DRAW;
            return newOptions;
          default:
            return options;
        }
      }),
    );
  }

  buildKeyBinding(annotationTypes: IAnnotationType[], cb: () => void) {
    let annotationTypeKeyMap = {
      '`': '`',
    };

    let annotationTypeKeyHandlers = {
      '`': async () => {
        if (await this.handleKeyBindingAnnotationTypeSelected(AnnotationToolType.SELECT)) cb();
      },
    };

    const sortedAnnotationTypes = annotationTypes.slice().sort((a: IAnnotationType, b: IAnnotationType) => a.order - b.order);

    sortedAnnotationTypes.forEach((m: IAnnotationType, i: number) => {
      const key = this.getShortcutKey(i);
      if (key) {
        annotationTypeKeyMap = { ...annotationTypeKeyMap, [key]: key };
        annotationTypeKeyHandlers = {
          ...annotationTypeKeyHandlers,
          [key]: async () => {
            this.uiStore.isInValidation = false;
            if (await this.handleKeyBindingAnnotationTypeSelected(m.id)) cb();
          },
        };
      }
    });

    this.store.setAnnotationTypeKeyMap(annotationTypeKeyMap, annotationTypeKeyHandlers);
  }

  getShortcutKey = (order: number) => (order < 10 ? (order === 9 ? '0' : String(order + 1)) : undefined);
}
