import { AttachmentsPermissionsType, IAttachmentsPermissions } from '../../../Attachments.permissions';
import { IRenameAttachmentStore, RenameAttachmentStoreType } from '../../RenameAttachment.store';
import { RenameAttachmentBl, RenameAttachmentBlType } from '../../RenameAttachment.bl';
import { as, injectProps } from '../../../../../__legacy__/helpers/react.helpers';

import { EditableImagePreviewHeader } from '../../../../../__legacy__/modules/datesets/components/EditableImagePreviewHeader';
import React from 'react';
import { observer } from 'mobx-react';

interface IProps {
  name: string;
  id: string;
  parentId: string;
}

interface InjectedProps {
  bl: RenameAttachmentBl;
  store: IRenameAttachmentStore;
  permissions: IAttachmentsPermissions;
}
@injectProps({
  bl: RenameAttachmentBlType,
  store: RenameAttachmentStoreType,
  permissions: AttachmentsPermissionsType,
})
@observer
class EditableAttachmentPreviewHeaderContainerPure extends React.Component<IProps & InjectedProps> {
  handleBlur = () => this.props.bl.renameAttachmentAsync();
  handleNameChange = (name: string) => this.props.bl.changeName(name);
  handleCancel = () => {
    this.props.bl.changeName(this.props.name);
  };

  componentDidMount = () => this.props.bl.initialize(this.props.id, this.props.name, this.props.parentId);
  handleStartEdit = () => this.props.bl.initialize(this.props.id, this.props.name, this.props.parentId);

  componentDidUpdate(prevProps: IProps) {
    if (this.props.id !== prevProps.id) {
      this.props.bl.initialize(this.props.id, this.props.name, this.props.parentId);
    }
  }

  render(): React.ReactNode {
    return (
      <EditableImagePreviewHeader
        name={this.props.name}
        onImageNameChange={this.handleNameChange}
        onImageNameBlur={this.handleBlur}
        nameStatus={this.props.store.nameStatus}
        canRenameImages={this.props.permissions.canRenameAttachment()}
        newName={this.props.store.name}
        onCancelRenameImage={this.handleCancel}
        onStartEdit={this.handleStartEdit}
      />
    );
  }
}

export const EditableAttachmentPreviewHeaderContainer = as<React.ComponentClass<IProps>>(EditableAttachmentPreviewHeaderContainerPure);
