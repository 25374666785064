import * as React from 'react';

import { Trans, withNamespaces } from 'react-i18next';

import { Bold } from '../../../../../components/BoldText';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { as } from '../../../../../helpers/react.helpers';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faCircleNotch);

interface IToolDeleteModalProps {
  annotationsCount: number;
  showModal: boolean;
  onConfirm(): void;
  onCancel(): void;
  objectsCount: number;
  questionsCount: number;
  attributesCount: number;
}

class AnnotationDiscardConfirmModalPure extends React.Component<IToolDeleteModalProps & ITranslatable> {
  render() {
    return (
      <ConfirmationModal
        confirmationHeader={this.props.t('confirm_discard')}
        onCancel={this.props.onCancel}
        onConfirm={this.props.onConfirm}
        showModal={this.props.showModal}
        confirmationYes={this.props.t('remove')}
        loaderKey="tool-delete-confirm-button"
      >
        <p>{this.props.t('discard_annotations_confirm')}</p>
        <p className="mb-0">
          <Trans
            i18nKey={'project:discard_annotations_related_annotations'}
            count={this.props.annotationsCount}
            values={{ annotationsCount: this.props.annotationsCount }}
            components={[Bold]}
          />
        </p>

        <ul>
          <li className="pt-2">
            {`${this.props.t('objects')}: `} <strong>{this.props.objectsCount}</strong>,
          </li>
          <li className="pt-2">
            {`${this.props.t('question_answers')}: `}
            <strong>{this.props.questionsCount}</strong>,
          </li>
          <li className="pt-2">
            {`${this.props.t('attributes')}: `}
            <strong>{this.props.attributesCount}</strong>
          </li>
        </ul>
        <p>{this.props.t('discard_annotations_confirm_tip')}</p>
      </ConfirmationModal>
    );
  }
}

export const AnnotationDiscardConfirmModal = as<React.StatelessComponent<IToolDeleteModalProps>>(withNamespaces('project', { wait: true })(AnnotationDiscardConfirmModalPure));
