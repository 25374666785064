import { faFileArchive, faFileExcel, faFilePdf, faFilePowerpoint, faFileVideo, faFileWord } from '@fortawesome/free-regular-svg-icons';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export enum FileExtensionType {
  Word,
  Video ,
  PowerPoint,
  Pdf,
  Excel,
  Archive,
}

export interface FileExtensionDefinition {
  type: FileExtensionType;
  extensions: string[];
  icon: IconDefinition;
}

export const ExtensionDefinitions: FileExtensionDefinition[] = [
  { type: FileExtensionType.Word, extensions: ['doc', 'docx', 'rtf'], icon: faFileWord },
  { type: FileExtensionType.Video, extensions: ['avi', 'mp4', 'mpg', 'mpeg', 'mov', 'mkv'], icon: faFileVideo },
  { type: FileExtensionType.PowerPoint, extensions: ['pptx', 'ppt'], icon: faFilePowerpoint },
  { type: FileExtensionType.Pdf, extensions: ['pdf'], icon: faFilePdf },
  { type: FileExtensionType.Excel, extensions: ['xls', 'xlsx'], icon: faFileExcel },
  { type: FileExtensionType.Archive, extensions: ['rar', 'zip', '7z', 'gzip'], icon: faFileArchive },
];

  // doc, docx, rtf - https://fontawesome.com/icons/file-word?style=regular
  // avi, mp4, mpg, mpeg, mov, mkv - https://fontawesome.com/icons/file-video?style=regular
  // pptx - https://fontawesome.com/icons/file-powerpoint?style=regular
  // pdf - https://fontawesome.com/icons/file-pdf?style=regular
  // xls, xlsx - https://fontawesome.com/icons/file-excel?style=regular
  // rar, zip, 7z, gzip - https://fontawesome.com/icons/file-archive?style=regular
