import { AnnotationTypeBlType, IAnnotationTypeBl } from '../../annotation/submodules/annotationTypes/annotationType.bl';
import { AnnotationUiStoreType, IAnnotationUiStore } from '../../annotation/annotationUi.store';
import { AnnotationsStoreType, IAnnotationsStore } from '../../annotation/annotations.store';
import { FreeAccessMode, FreeAccessStoreType, IFreeAccessStore } from '../freeAccess.store';
import { FreeAccessServiceType, IFreeAccessService } from '../freeAccess.service';
import { IReactionDisposer, reaction } from 'mobx';
import { IRouterStore, RouterStoreType } from '../../../stores/router.store';
import { as, injectProps } from '../../../helpers/react.helpers';

import { DrawingContainer } from '../../annotation/containers/Drawing.container';
import { HiddenAnnotationsToastContainer } from '../../../containers/HiddenAnnotationsToast.container';
import { Home } from '../../../routes/config/Home';
import Image from '../../annotation/components/Image';
import ImageContainer from '../../annotation/containers/Image.container';
import { ImageDeletedMessage } from './ImageDeletedMessage.container';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { SegmentationsContainer } from '../../annotation/containers/Segmentations.container';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ projectId: string; workspaceId: string }> {
  annotationTypeBl: IAnnotationTypeBl;
  annotationUiStore: IAnnotationUiStore;
  freeAccessStore: IFreeAccessStore;
  freeAccessService: IFreeAccessService;
  routerStore: IRouterStore;
  annotationsStore: IAnnotationsStore;
}

interface IProps {
  imageId: string;
  projectId: string;
  filterId: string;
  workspaceId: string;
  isReadOnly: boolean;
}

@injectProps({
  annotationTypeBl: AnnotationTypeBlType,
  annotationUiStore: AnnotationUiStoreType,
  freeAccessStore: FreeAccessStoreType,
  freeAccessService: FreeAccessServiceType,
  routerStore: RouterStoreType,
  annotationsStore: AnnotationsStoreType,
})
@observer
class FreeAccessImageSetupContainerPure extends React.Component<IInjectedProps & IProps> {
  imageChangeReactionDisposer: IReactionDisposer;

  constructor(props: IInjectedProps & IProps) {
    super(props);

    this.imageChangeReactionDisposer = reaction(
      () => this.props.imageId,
      () => this.setupService(),
    );
  }

  get shouldRender() {
    return this.props.annotationsStore.image !== undefined || this.props.annotationUiStore.isImageLoading;
  }

  async componentDidMount() {
    this.props.freeAccessStore.paging = {
      checkDate: '',
      wasChanged: true,
      items: [],
    };
    await this.setupService();
  }

  componentWillUnmount() {
    this.props.annotationsStore.questions = [];
    this.props.annotationsStore.currentQuestions = [];
    this.props.freeAccessService.dispose();
    this.imageChangeReactionDisposer();
  }

  @autobind
  async handleRefresh() {
    this.props.routerStore.push(Home.Projects.Details.Images.withParams({ projectId: this.props.projectId, workspaceId: this.props.workspaceId }));
  }

  private async setupService() {
    await this.props.freeAccessService.initializeAsync(this.props.workspaceId, this.props.projectId, this.props.imageId, this.props.filterId, FreeAccessMode.VIEW);
  }

  render() {
    return this.props.freeAccessStore.showImageDeletedMessage ? (
      <ImageDeletedMessage onRefresh={this.handleRefresh} />
    ) : (
      <>
        <HiddenAnnotationsToastContainer />
        <ImageContainer>
          <Image>
            <SegmentationsContainer readonly={this.props.isReadOnly} />
            {!this.props.isReadOnly && <DrawingContainer disableCursorGuides={false} />}
          </Image>
        </ImageContainer>
      </>
    );
  }
}

export const FreeAccessImageSetupContainer = as<React.ComponentClass<IProps>>(FreeAccessImageSetupContainerPure);
