import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { IProjectDetailsStore, ProjectDetailsStoreType } from '../projectDetails.store';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { DraftButtons } from '../components/DraftButtons';
import React from 'react';
import { observer } from 'mobx-react';

interface IInjectProps extends RouteComponentProps<{ workspaceId: string, projectId: string }> {
  projectDetailsStore: IProjectDetailsStore;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

@injectProps({
  projectDetailsStore: ProjectDetailsStoreType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
@observer
class DraftButtonsContainerPure extends React.Component<IInjectProps> {
  tabs = [
    'datasets',
    'images',
    'members',
    'annotation-view',
    'activity-log',
  ];

  render() {
    const currentTab = this.tabs.indexOf(this.tabs.find(x => window.location.pathname.includes(x)) || '');

    const nextLink = this.tabs[currentTab + 1] || '';

    return (
      <DraftButtons
        projectId={this.props.projectDetailsStore.id}
        workspaceId={this.props.currentWorkspaceStore.currentWorkspace!.id}
        projectStatus={this.props.projectDetailsStore.status}
        nextStepLink={nextLink}
      />
    );
  }
}

export const DraftButtonsContainer = as<React.ComponentClass>(withRouter(DraftButtonsContainerPure));
