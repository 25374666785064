import React, { FormEvent } from 'react';

import { FocusLoop } from '../../../../components/FocusLoop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'reactstrap';
import { IQuestionModel } from '../../question.model';
import { ITranslatable } from '../../../../helpers/translations.helpers';
import QuestionWithAnswers from './QuestionWithAnswers';
import { as } from '../../../../helpers/react.helpers';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

export interface IQuestionsListProps {
  questions: IQuestionModel[];
  readonly: boolean;
  segmentationId: string;
  onAnswered(questionId: string, answer: string): void;
}

library.add(faClipboardCheck);

@observer
class QuestionsList extends React.Component<IQuestionsListProps & ITranslatable> {
  constructor(props: IQuestionsListProps & ITranslatable) {
    super(props);
  }

  preventSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
  }

  render() {
    const isAttribute = this.props.questions.length > 0 && this.props.questions[0].isAttribute();
    return this.props.questions.length > 0 ? (
      <div className={`question-list ${this.props.readonly ? 'annotation-tools-readonly-overlay' : ''}`}>
        <Form onSubmit={this.preventSubmit}>
          <div className="header">
            <FontAwesomeIcon icon="clipboard-check" /> {isAttribute ? this.props.t('set_attributes') : this.props.t('answer_on_questions')}
          </div>
          <FocusLoop selectors={['input', 'textarea']} parentElement="question-list-single-item">
            {this.props.questions.map(q => (
              <QuestionWithAnswers
                key={q.id + this.props.segmentationId}
                question={q}
                disabled={this.props.readonly}
                isAttribute={isAttribute}
                onAnswered={this.props.onAnswered}
              />
            ))}
          </FocusLoop>
        </Form>
      </div>
    ) : (
      <></>
    );
  }
}

export default  as<React.ComponentClass>(withNamespaces('annotation', { wait: true })(QuestionsList));
