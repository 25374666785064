import { IImportAnnotationsReport } from '../models/ImportAnnotationsReport';
import { StickerEvent } from '../../../../../../__legacy__/services/eventBus.service';

export const ImportAnnotationsSuccessResponseEventType = Symbol('IMPORT_ANNOTATION_SUCCESS_RESPONSE_EVENT');

export class ImportAnnotationsSuccessResponseEvent extends StickerEvent {
  constructor(public readonly report: IImportAnnotationsReport) {
    super();
  }

  get type(): Symbol {
    return ImportAnnotationsSuccessResponseEventType;
  }
}
