import * as React from 'react';

import { Col, Row } from 'reactstrap';
import { ExportRoles, ProjectManagementRoles, WorkspaceRole } from '../../workspaces/workspaces.store';
import { TabsSingleItem, TabsSingleItemState } from '../../../components/TabsSingleItem';

import { Home } from '../../../routes/config/Home';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { InputStatus } from '../../../models/error.model';
import { ProjectDetailsImageListControlsContainer } from '../sub/images/containers/ProjectDetailsImageListControls.container';
import { ProjectStatus } from '../../projects/projects.model';
import { RestrictAccessTo } from '../../../containers/RestrictAccessTo';
import { TabsContainer } from '../../../containers/TabsContainer';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  projectId: string;
  projectStatus: ProjectStatus;
  workspaceId: string;
  imageClickCallback(): void;

  datasetsStatus: InputStatus;
  usersStatus: InputStatus;
  toolsStatus: InputStatus;
  activityLogStatus: InputStatus;
  imagesStatus: InputStatus;
}

const ProjectDetailsTabsPure = (props: IProps & ITranslatable) => {
  const getClass = (link: string, status: InputStatus | undefined): TabsSingleItemState => {
    const pathname = window.location.pathname.split('/');
    const tabName = pathname[pathname.length - 1];
    let className = tabName === link ? 'active' : 'passive';
    if (status && status.isValid !== undefined) {
      className += status.isValid ? ' validated' : ' error';
    }
    return className as TabsSingleItemState;
  };

  const buildTab = (link: string, label: string, publishedOnly: boolean, status: InputStatus | undefined, callback?: () => void): JSX.Element => (
    <>
      {publishedOnly && props.projectStatus === ProjectStatus.Draft ? (
        <></>
      ) : (
        <TabsSingleItem
          text={props.t(label)}
          link={Home.Projects.Details.Paths.withParams({ projectId: props.projectId, tab: link, workspaceId: props.workspaceId })}
          state={getClass(link, status)}
          callback={callback}
        />
      )}
    </>
  );

  return (
    <Row>
      <Col>
        <TabsContainer variant="default">
          {buildTab('overview', 'overview', true, undefined)}
          {buildTab('datasets', 'datasets', false, props.datasetsStatus)}
          {buildTab('images', 'images', false, props.imagesStatus, props.imageClickCallback)}
          <RestrictAccessTo
            inProject={props.projectId}
            workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Trainer]}
            projectRoles={[]}
          >
            {buildTab('members', 'collaborators', false, props.usersStatus)}
          </RestrictAccessTo>
          {buildTab('annotation-view', 'annotation_tools', false, props.toolsStatus)}
          <RestrictAccessTo inProject={props.projectId} workspaceRoles={ExportRoles} projectRoles={[]}>
            {buildTab('export-data', 'export_data', true, undefined)}
          </RestrictAccessTo>
          <RestrictAccessTo inProject={props.projectId} workspaceRoles={ProjectManagementRoles} projectRoles={[]}>
            {buildTab('statistics', 'statistics', true, undefined)}
          </RestrictAccessTo>
          <RestrictAccessTo inProject={props.projectId} workspaceRoles={ProjectManagementRoles} projectRoles={[]}>
            {buildTab('activity-log', 'activity_log', false, props.activityLogStatus)}
          </RestrictAccessTo>
        </TabsContainer>
      </Col>
      <Col sm="2">{window.location.pathname.includes('images') && <ProjectDetailsImageListControlsContainer />}</Col>
    </Row>
  );
};

export const ProjectDetailsTabs = as<React.StatelessComponent<IProps>>(withNamespaces('project')(ProjectDetailsTabsPure));
