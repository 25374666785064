import { S_Modal, S_ModalPropsBase } from '../modal/S_Modal';

import React from 'react';
import { S_ClassNameProps } from '../../CommonProps';
import { S_PrimaryButton } from '../../buttons/textButton/primaryButton/S_PrimaryButton';

interface S_OneButtonModalProps extends S_ModalPropsBase, S_ClassNameProps {
  buttonText: string;
  className?: string;
  onHide(): void;
  onClick(): void;
}

export const S_OneButtonModal: React.FC<S_OneButtonModalProps> = (props: S_OneButtonModalProps) => {
  const { buttonText, className, onHide, onClick } = props;
  return <S_Modal {...props} buttons={[<S_PrimaryButton onClick={onClick} text={buttonText} key={'oneButtonModal-primary'} />]} onHide={onHide} className={className} />;
};
