import * as React from 'react';

import { ExternalLinks } from '../../../routes/config/ExternalLinks';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { NavLink } from 'reactstrap';
import { PageHeader } from '../../../components/PageHeader';
import { as } from '../../../helpers/react.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

export interface IIntegrationsHeaderProps {
  integrationsCount: number;
}

@observer
class IntegrationsHeaderPure extends React.Component<IIntegrationsHeaderProps & ITranslatable> {
  headerDescription() {
    return (
      <span>
        {this.props.t('full_zillins_api_documentation_you_can_find_on')}
        <NavLink className="styled_link" href={ExternalLinks.ZillinPublicApi.Documentation.Path} target="_blank">
          {this.props.t('api_documentation')}
        </NavLink>
        {this.props.t('page')}
      </span>

    );
  }

  render() {
    return (
      <PageHeader
        headerText={`${this.props.t('your_api_keys')}`}
        headerNumber={this.props.integrationsCount}
        tabNavPresent="clean"
        headerDescription={this.headerDescription()}
      />
    );
  }
}

export const IntegrationsHeader = as<React.ComponentClass<IIntegrationsHeaderProps>>(withNamespaces('integrations')(IntegrationsHeaderPure));
