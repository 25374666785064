import * as React from 'react';
import { ModelImportModal } from '../components/ModelImportModal';
import { as } from '../../../__legacy__/helpers/react.helpers';
import { isAllowedModelType } from '../../../__legacy__/modules/datesets/helpers/image.helpers';

export interface IModelImportModalProps {
  showModal: boolean;

  toggleUploadModal(): void;
  importModelAsync(name: string, description: string, file: File): Promise<boolean>;
}

interface IState {
  name: string;
  description: string;
  file: File | null;
  errors: {
    showStatus: boolean;
    name?: string;
  };
  rejectedFiles: File[];
  acceptedFiles: File[];
}

const DEFAULT_MODAL_STATE = {
  name: '',
  description: '',
  errors: {
    showStatus: false,
    name: undefined,
  },
  file: null,
  rejectedFiles: [],
  acceptedFiles: [],
};

const ModelImportModalContainerPure = (props: IModelImportModalProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState<IState>({ ...DEFAULT_MODAL_STATE });

  const validateRequiredTextField = (value: string) => {
    return value.length === 0 ? 'common:field_cant_be_empty' : '';
  };

  const handleChangeTextField = (key: string) => (value: string) => {
    const errors: IState['errors'] = {
      ...data.errors,
      showStatus: true,
    };

    if (key === 'name') {
      errors[key] = validateRequiredTextField(value);
    }

    setData({
      ...data,
      errors,
      [key]: value,
    });
  };

  const handleImageDrop = (images: File[]) => {
    if (isAllowedModelType(images[0])) {
      setData({
        ...data,
        file: images[0],
        acceptedFiles: [images[0]],
        rejectedFiles: [],
      });
    } else {
      setData({
        ...data,
        file: null,
        rejectedFiles: [images[0]],
        acceptedFiles: [],
      });
    }
  };

  const handleSubmit = async () => {
    if (!data.file) return;

    setIsLoading(true);
    const result = await props.importModelAsync(data.name, data.description, data.file);

    if (result) {
      props.toggleUploadModal();
    }

    setIsLoading(false);
  };

  const toggleUploadModal = () => {
    if (props.showModal) {
      setData({ ...DEFAULT_MODAL_STATE });
    }

    props.toggleUploadModal();
  };

  return (
    <ModelImportModal
      showModal={props.showModal}
      toggleUploadModal={toggleUploadModal}
      data={data}
      handleChangeTextField={handleChangeTextField}
      handleImageDrop={handleImageDrop}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
    />
  );
};
export const ModelImportModalContainer = as<React.ComponentClass<IModelImportModalProps>>(ModelImportModalContainerPure);
