import { AnnotationReviewStoreType, IAnnotationReviewStore } from '../annotationReview.store';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import React from 'react';
import { ReviewMode } from '../models/reviewMode';
import { observer } from 'mobx-react';

interface IProps {
  correctBuilder(): JSX.Element;
  reviewBuilder(): JSX.Element;
}

interface IInjectProps {
  annotationReviewStore: IAnnotationReviewStore;
}

@injectProps({ annotationReviewStore: AnnotationReviewStoreType })
@observer
class ReviewModeSwitchContainerPure extends React.Component<IProps & IInjectProps> {
  render() {
    switch (this.props.annotationReviewStore.currentMode) {
      case ReviewMode.Correct:
        return this.props.correctBuilder();
      case ReviewMode.Review:
        return this.props.reviewBuilder();
      default:
        throw new Error('This review mode is unknown in this area');
    }
  }
}

export const ReviewModeSwitchContainer = as<React.ComponentClass<IProps>>(ReviewModeSwitchContainerPure);
