﻿import { Point, lineString } from '@turf/helpers';
import leaflet, { LatLng, Map } from 'leaflet';

import { AnnotationToolType } from '../../modules/annotation/submodules/annotationTypes/models/annotationToolType';
import { Feature } from 'geojson';
import { ISegmentationFeature } from '../../modules/annotation/annotations.interface';
import { getImageBounds } from './mapExtensions.helpers';
import lineIntersect from '@turf/line-intersect';
import simplify from 'simplify-geojson';

const cloneLayer = require('leaflet-clonelayer');

export const restrictToBoundingBoxInterpolated = (current: LatLng, previous: LatLng, map: Map): LatLng => {
  const imageBounds = getImageBounds(map);
  const pointBounds = current.toBounds(0);

  if (imageBounds.contains(pointBounds)) {
    return current;
  }
  if (previous) {
    const pendingLine = lineString([
      [previous.lat, previous.lng],
      [current.lat, current.lng],
    ]);

    const north = lineString([
      [imageBounds.getNorth(), imageBounds.getWest()],
      [imageBounds.getNorth(), imageBounds.getEast()],
    ]);
    const west = lineString([
      [imageBounds.getNorth(), imageBounds.getWest()],
      [imageBounds.getSouth(), imageBounds.getWest()],
    ]);
    const east = lineString([
      [imageBounds.getNorth(), imageBounds.getEast()],
      [imageBounds.getSouth(), imageBounds.getEast()],
    ]);
    const south = lineString([
      [imageBounds.getSouth(), imageBounds.getWest()],
      [imageBounds.getSouth(), imageBounds.getEast()],
    ]);

    const intersections = lineIntersect(north, pendingLine)
      .features.concat(lineIntersect(west, pendingLine).features)
      .concat(lineIntersect(east, pendingLine).features)
      .concat(lineIntersect(south, pendingLine).features);

    const intersectionPoint = intersections.map((x: any) => x.geometry as Point).find((x: any) => x.coordinates[0] !== previous.lat || x.coordinates[1] !== previous.lng);

    if (intersectionPoint !== undefined) {
      return new LatLng(intersectionPoint.coordinates[0], intersectionPoint.coordinates[1]);
    }
  }

  return previous;
};

export const createPolygonFromBounds = (latLngBounds: L.LatLngBounds): L.Polygon => {
  const latlngs = [];

  latlngs.push(latLngBounds.getSouthWest()); // bottom left
  latlngs.push(latLngBounds.getSouthEast()); // bottom right
  latlngs.push(latLngBounds.getNorthEast()); // top right
  latlngs.push(latLngBounds.getNorthWest()); // top left
  latlngs.push(latLngBounds.getSouthWest()); // bottom left

  return new leaflet.Polygon(latlngs);
};

export const pointCoordsToLatLng = (coords: number[]) => new LatLng(coords[1], coords[0]);

export const getLatLngsForGeojson = (feature: ISegmentationFeature) => {
  if (!feature.geometry.coordinates.length) return [];

  switch (feature.featureType) {
    case AnnotationToolType.POINT:
      return [pointCoordsToLatLng(feature.geometry.coordinates)];
    case AnnotationToolType.RECTANGLE:
    case AnnotationToolType.POLYGON:
    case AnnotationToolType.ROTATEDRECTANGLE:
      return feature.geometry.coordinates[0].map(pointCoordsToLatLng);
    case AnnotationToolType.VECTOR:
    case AnnotationToolType.POLYLINE:
      return feature.geometry.coordinates.map(pointCoordsToLatLng);
    case AnnotationToolType.BRUSH:
      return feature.geometry.coordinates.map((c: any) => c.map((pc: any) => pc.map(pointCoordsToLatLng)));
  }
};

export function copyAndTranslatePolygon(polygon: leaflet.Polyline, latOffset: number, lngOffset: number, map: Map) {
  let restrictedLatOffset = latOffset;
  let restrictedLngOffset = lngOffset;

  const polygonBounds = polygon.getBounds();
  const imageBounds = getImageBounds(map);

  if (polygonBounds.getNorth() + latOffset > imageBounds.getNorth()) {
    restrictedLatOffset = imageBounds.getNorth() - polygonBounds.getNorth();
  }
  if (polygonBounds.getSouth() + latOffset < imageBounds.getSouth()) {
    restrictedLatOffset = imageBounds.getSouth() - polygonBounds.getSouth();
  }

  if (polygonBounds.getEast() + lngOffset > imageBounds.getEast()) {
    restrictedLngOffset = imageBounds.getEast() - polygonBounds.getEast();
  }
  if (polygonBounds.getWest() + lngOffset < imageBounds.getWest()) {
    restrictedLngOffset = imageBounds.getWest() - polygonBounds.getWest();
  }

  const latlng: LatLng[] | LatLng[][] | LatLng[][][] = polygon.getLatLngs();

  if (Array.isArray(latlng[0])) {
    const points = (latlng[0] as LatLng[]).map((p: leaflet.LatLng) => ({
      lat: p.lat + restrictedLatOffset,
      lng: p.lng + restrictedLngOffset,
    }));
    const translatedDrawing = cloneLayer(polygon);
    translatedDrawing.setLatLngs([points]);
    return translatedDrawing;
  }

  const points = (latlng as LatLng[]).map((p: leaflet.LatLng) => ({
    lat: p.lat + restrictedLatOffset,
    lng: p.lng + restrictedLngOffset,
  }));
  const translatedDrawing = cloneLayer(polygon);
  translatedDrawing.setLatLngs(points);
  return translatedDrawing;
}

export function getSimplifiedGeoJSON(layer: any, level: number = 0.1) {
  const geojson = layer.toGeoJSON();
  return simplify(geojson, level) as Feature;
}
