import { IntegrationsService, IntegrationsServiceType } from '../integrations.service';
import { as, injectProps } from '../../../helpers/react.helpers';

import { IntegrationsHeader } from '../components/IntegrationsHeader';
import React from 'react';
import { observer } from 'mobx-react';

export interface IInjectedProps {
  integrationService: IntegrationsService;
}

@injectProps({ integrationService: IntegrationsServiceType })
@observer
class IntegrationsHeaderContainerPure extends React.Component<IInjectedProps> {
  render() {
    return (
      <IntegrationsHeader
        integrationsCount={this.props.integrationService.store.apiKeys.length}
      />
    );
  }
}

export const IntegrationsHeaderContainer = as<React.ComponentClass>(IntegrationsHeaderContainerPure);
