import './ProjectExportsTable.scss';

import { GTable, IGColumnProps } from '../../../../../../__legacy__/components/table/GTable';
import { IProjectExport, ProjectExportStatus } from '../../models/ProjectExport';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import { IPagingInfoWithOrder } from '../../../../../../__legacy__/models/paginationInfo.model';
import { ProjectExportDropdownContainer } from '../dropdown/ProjectExportDropdown.container';
import React from 'react';
import { S_ClipLoader } from '../../../../../../design/loaders/clipLoader/S_ClipLoader';
import { nameOf } from '../../../../../../__legacy__/helpers/object.helpers';
import { toLocaleDateTimeString } from '../../../../../../__legacy__/helpers/date.helpers';

interface Props {
  items: IProjectExport[];
  paging: IPagingInfoWithOrder;
  onOrderingChange(orderBy: string, orderType: string): void;
  onPaginationChange(pageNumber: number, pageSize: number): void;
}

const ProjectExportsTablePure: React.FC<Props & WithNamespaces> = (props: Props & WithNamespaces) => {
  const { t, items, paging, onOrderingChange, onPaginationChange } = props;

  const renderDropdown = (projectExport: IProjectExport) => {
    return (
      <div className="g-actions">
        {projectExport.status === ProjectExportStatus.Finished ? <ProjectExportDropdownContainer projectExportId={projectExport.id} hasAllFilesGenerated={projectExport.hasAllFilesGenerated} /> : <S_ClipLoader />}
      </div>
    );
  };

  const renderCreatedDate = (projectExport: IProjectExport) => <>{toLocaleDateTimeString(projectExport.createdDate)}</>;
  const renderLastDatasetsModifiedDate = (projectExport: IProjectExport) => <>{toLocaleDateTimeString(projectExport.lastDatasetsModifiedDate)}</>;

  const columns: IGColumnProps<IProjectExport>[] = [
    {
      field: nameOf<IProjectExport>('createdDate'),
      headerName: props.t('new.projects.details.export_data.created_date'),
      renderer: renderCreatedDate,
      sortable: true,
    },
    {
      field: nameOf<IProjectExport>('fileSchemeVersion'),
      headerName: props.t('new.projects.details.export_data.file_scheme_version'),
      sortable: true,
    },
    {
      field: nameOf<IProjectExport>('configurationVersion'),
      headerName: props.t('new.projects.details.export_data.configuration_version'),
      sortable: true,
    },
    {
      field: nameOf<IProjectExport>('annotationsVersion'),
      headerName: props.t('new.projects.details.export_data.annotations_version'),
      sortable: true,
    },
    {
      field: nameOf<IProjectExport>('setVersion'),
      headerName: props.t('new.projects.details.export_data.set_version'),
      sortable: true,
    },
    {
      field: nameOf<IProjectExport>('lastDatasetsModifiedDate'),
      headerName: props.t('new.projects.details.export_data.lastDatasetsModifiedDate'),
      renderer: renderLastDatasetsModifiedDate,
      sortable: true,
    },

    {
      field: '',
      headerName: t('new.projects.details.export_data.actions'),
      renderer: renderDropdown,
      sortable: false,
      cellStyle: () => ({ overflow: 'inherit' }),
      width: 100,
    },
  ];

  return (
      <GTable<IProjectExport>
        columns={columns}
        items={items}
        noItemsPlaceholder={t('new.projects.details.export_data.no_exports_have_been_generated_yet')}
        paginationProps={{
          pageNumber: paging.pageNumber,
          pageSize: paging.pageSize,
          totalCount: paging.totalCount,
          onChange: onPaginationChange,
        }}
        onSortChanged={onOrderingChange}
        sortingModel={[
          {
            orderBy: paging.orderBy || '',
            orderType: paging.orderType || '',
          },
        ]}
      />
  );
};

export const ProjectExportsTable = withNamespaces('common')(ProjectExportsTablePure);
