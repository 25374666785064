import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { IRouterStore, RouterStoreType } from '../../../stores/router.store';
import { as, injectProps } from '../../../helpers/react.helpers';

import { Button } from 'reactstrap';
import { DisabledWhenPolicyExceeded } from '../../../containers/DisabledWhenPolicyExceeded';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Home } from '../../../routes/config/Home';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import autobind from 'autobind-decorator';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';

library.add(faList);

interface IInjectedProps {
  router: IRouterStore;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

@injectProps({
  router: RouterStoreType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
class GoToWorkspacesButtonContainerPure extends React.PureComponent<IInjectedProps & ITranslatable> {
  @autobind
  goToWorkspaces() {
    this.props.router.push(Home.Workspaces.List.withParams({ workspaceId: this.props.currentWorkspaceStore.currentWorkspace!.id }));
  }

  render() {
    return (
      <div className="create-workspace">
        <DisabledWhenPolicyExceeded>
          <Button
            color="primary"
            onClick={this.goToWorkspaces}
            title={this.props.t('go_to_workspaces')}
          >
            <FontAwesomeIcon icon={faList} />
          </Button>
        </DisabledWhenPolicyExceeded>
      </div>
    );
  }
}

export const GoToWorkspacesButtonContainer = as<React.ComponentClass>(withNamespaces('common')(GoToWorkspacesButtonContainerPure));
