import React from 'react';

export const PencilWithRuler = require('../static/images/pencil_with_ruler.png');

export const LogoWhiteText = require('../static/images/logo_full_white_text.png');

export const LogoSignet = require('../static/images/logo_signet.png');

export const PreviewDefaultImage = require('../static/images/preview-min.jpg');

export const ArrowGradient = require('../static/images/svg_arrow_gradient.png');

export const CheckFilledGradient = require('../static/images/svg_check_filled_gradient.png');

export const ZebraWhiteLogo: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      d="m366.6 263.27-36.06-36.07v102.06h36.06v-65.99zM222.33 154.6h87.83l-35.9-35.91-.16-.17h-46.19A59.39 59.39 0 0 0 222.33 0v118.69L123.64 20.3q-7.83 4.07-15.2 8.87a180.51 180.51 0 0 0-15.57 11.4l93.41 93.05v204.3L258.4 410v-51l-36.07-36Z"
      fill="#FFFFFF"
    />
    <path
      d="M258.4 154.82 258.22 329h36.04l.19-137.93-36.05-36.25zM382.14 227.16l-36.06-36.04h-51.63l36.02 36.04h51.67zM150.21 304V148.86L67.37 66a180.16 180.16 0 0 0-20.31 30.71l67.09 67.09v51l-81.41-81.43a179.15 179.15 0 0 0-6.12 44.89S146.17 299.81 150.21 304ZM27.24 229.87v50.99L258.4 512v-50.99L27.24 229.87zM438.73 283.68h-36.08v45.58h-36a59.39 59.39 0 1 0 118.78 0Z"
      fill="#FFFFFF"
    />
  </svg>
);
