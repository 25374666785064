import { ExportDataBlType, IExportDataBl } from './ExportData.bl';
import { ExportDataStore, ExportDataStoreType } from './ExportData.store';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ExportDataFormContainer } from './ui/form/ExportDataForm.container';
import { InformationModalContainer } from './ui/exportModal/InformationModal.container';
import { ProjectExportsTableContainer } from './ui/table/ProjectExportsTable.container';
import React from 'react';
import { as } from '../../../../common/dependencyInjection/as';
import { injectProps } from '../../../../__legacy__/helpers/react.helpers';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: IExportDataBl;
  store: ExportDataStore;
}

@injectProps({
  bl: ExportDataBlType,
  store: ExportDataStoreType,
})
@observer
class ExportDataContainerPure extends React.Component<InjectedProps & RouteComponentProps<{ projectId: string }>> {
  async componentDidMount() {
    const projectId = this.props.match.params.projectId;
    await this.props.bl.initializeAsync(projectId);
  }

  componentWillUnmount() {
    this.props.bl.cleanup();
  }

  render() {
    return (
      <>
        <ExportDataFormContainer />
        <ProjectExportsTableContainer />
        <InformationModalContainer />
      </>
    );
  }
}

export const ExportDataContainer = as<React.ComponentClass>(withRouter(ExportDataContainerPure));
