import '../CursorControl.scss';

import { WithNamespaces, withNamespaces } from 'react-i18next';

import { CustomInput } from 'reactstrap';
import React from 'react';
import { as } from '../../../../__legacy__/helpers/react.helpers';

interface IProps {
  checked: boolean;
  disabled: boolean;
  onChange(): void;
}

export const CursorVisibilityCheckboxPure: React.FC<IProps & WithNamespaces> = (props: IProps & WithNamespaces) => {
  const { t, checked, onChange, disabled } = props;
  return (
    <div className={`cursor-control-checkbox${disabled && '-disabled'}`}>
      <CustomInput
        id="cursor-visibility-checkbox"
        type="checkbox"
        checked={checked}
        onChange={onChange}
        label={t('editor.cursor_control.enable_improved_visibility_cursor')}
        disabled={disabled}
      />
    </div>
  );
};

export const CursorVisibilityCheckbox = as<React.ComponentClass<IProps>>(withNamespaces('new')(CursorVisibilityCheckboxPure));
