import * as React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { drawingScreenDisables, keyboardShortcutsServiceInstance } from '../services/keyboardShortcuts.service';

import { GlobalHotKeys } from 'react-hotkeys';
import { IRejectionReason } from '../modules/annotation/submodules/reviewRejectReasones/models/rejectionReason';
import { ITranslatable } from '../helpers/translations.helpers';
import { TextAreaWithAutocomplete } from './TextAreaWithAutocomplete';
import { as } from '../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { withNamespaces } from 'react-i18next';

export enum AnnotationConfirmationModalMode {
  SUBMIT = 'Submit',
  ACCEPT = 'Accept',
  SUBMITANDACCEPT = 'SubmitAndAccept',
  REJECT = 'Reject',
}

export interface IAnnotationConfirmationModalProps {
  showAnnotationConfirmationModal: boolean;
  annotationConfirmationModalMode: AnnotationConfirmationModalMode;
  rejectAutoCompleteOptions: IRejectionReason[];
  onAnnotationConfirm(reason?: string): void;
  onAnnotationCancel(): void;
}

interface IState {
  reason?: string;
}
class AnnotationConfirmationModalPure extends React.Component<IAnnotationConfirmationModalProps & ITranslatable, IState> {
  constructor(props: IAnnotationConfirmationModalProps & ITranslatable) {
    super(props);
    this.state = { reason: undefined };
  }

  componentDidUpdate(props: IAnnotationConfirmationModalProps) {
    if (this.props.showAnnotationConfirmationModal !== props.showAnnotationConfirmationModal) {
      if (this.props.showAnnotationConfirmationModal) {
        keyboardShortcutsServiceInstance.disableShortcuts(drawingScreenDisables);
      } else {
        keyboardShortcutsServiceInstance.enableShortcuts(drawingScreenDisables);
      }
    }
  }

  getBody() {
    switch (this.props.annotationConfirmationModalMode) {
      case AnnotationConfirmationModalMode.ACCEPT:
        return <p>{this.props.t('accept_annotation_confirmation_body')}</p>;
      case AnnotationConfirmationModalMode.SUBMITANDACCEPT:
        return <p>{this.props.t('Enter # to start autocomplete')}</p>;
      case AnnotationConfirmationModalMode.REJECT:
        const autocompleteOptions = this.props.rejectAutoCompleteOptions.sort((a, b) => a.order - b.order).map(x => x.reason);
        return (
          <>
            <p>{this.props.t('reject_annotation_confirmation_body')}<br />
              <span>({this.props.t('enter_sharp_to_start_autocomplete')})</span>
            </p>
            <TextAreaWithAutocomplete
              maxLength={210}
              value={this.state.reason}
              onChange={this.handleReasonChange}
              autocompleteOptions={autocompleteOptions}
              autofocus
              counter
            />
          </>
        );
      default:
        return <p>{this.props.t('submit_annotation_confirmation_body')}</p>;
    }
  }

  @autobind
  handleReasonChange(value: string) {
    this.setState({ reason: value });
  }

  @autobind
  handleConfirm() {
    this.props.onAnnotationConfirm(this.state.reason);
    this.setState({ reason: '' });
  }

  @autobind
  handleCancel() {
    this.setState({ reason: '' });
    this.props.onAnnotationCancel();
  }

  render() {
    return (
      <div>
        {this.props.showAnnotationConfirmationModal && <GlobalHotKeys keyMap={{ CONFIRM: { sequence: 'enter', action: 'keydown' } }} handlers={{ CONFIRM: this.handleConfirm }} />}
        <Modal className="modal-width-600" isOpen={this.props.showAnnotationConfirmationModal} toggle={this.handleCancel}>
          <ModalHeader toggle={this.handleCancel}>
            {this.props.t(this.props.annotationConfirmationModalMode === AnnotationConfirmationModalMode.REJECT ? 'warning' : 'confirmation')}
          </ModalHeader>
          <ModalBody>
            {this.getBody()}
          </ModalBody>
          <ModalFooter>
            <Button
              color={this.props.annotationConfirmationModalMode === AnnotationConfirmationModalMode.REJECT ? 'warning' : 'success'}
              onClick={this.handleConfirm}
              className="mr-2"
            >
              {this.props.t(this.props.annotationConfirmationModalMode === AnnotationConfirmationModalMode.REJECT ? 'reject' : 'submit')}
            </Button>
            <Button outline color="primary" onClick={this.handleCancel}>
              {this.props.t('cancel')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export const AnnotationConfirmationModal = as<React.StatelessComponent<IAnnotationConfirmationModalProps>>(
  withNamespaces('annotation', { wait: true })(AnnotationConfirmationModalPure),
);
