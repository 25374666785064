import { CustomInput, FormGroup, Label } from 'reactstrap';

import React from 'react';
import { handleClickAndPassData } from '../helpers/formHelpers';
import { observer } from 'mobx-react';

export interface ICheckboxMultiSelectProps<T> {
  label?: string;
  className?: string;
  options: T[];
  values: T[];
  valueField: keyof T;
  labelField: keyof T;
  onSelectChange(selected: T[]): void;
}

@observer
export class CheckboxMultiSelect<T> extends React.Component<ICheckboxMultiSelectProps<T>>{

  handleClick = (selected: T) => {
    const { valueField, values, onSelectChange } = this.props;

    const output = values.slice();
    const element = output.find(o => o[valueField] === selected[valueField]);
    const index = element ? output.indexOf(element) : -1;
    if (index < 0) {
      output.push(selected);
    } else {
      output.splice(index, 1);
    }
    onSelectChange(output);
  }

  render() {
    const { valueField, labelField, className, options, values, label } = this.props;

    return (
      <FormGroup className={`toogle-buttons ${className}`}>
        {label && <Label>{label}</Label>}

        <div>
          {options.map(o =>
            <CustomInput
              id={`${o[valueField]}-custom-input`}
              className="checkbox-multiselect-item"
              key={o[valueField] as any}
              type="checkbox"
              checked={values.some(x => x[valueField] === o[valueField])}
              label={o[labelField]}
              disabled={false}
              readOnly={true}
              onClick={handleClickAndPassData(this.handleClick)(o)}
            />)}
        </div>
      </FormGroup>
    );
  }
}
