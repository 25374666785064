import { MODEL_STATUS } from '../models/models.model';
import { IModelConfig } from './modelDetails.store';
import { ImageSetType } from '../../__legacy__/modules/projectDetails/sub/images/projectDetailsImages.model';
import { GROUND_TRUTH, IConfusionMatrix, Point2D } from '../../__legacy__/models/predictions.model';
import { IParameterDto } from '../../__legacy__/helpers/parameters.helpers';
import { MODEL_VARIANTS } from '../../__legacy__/models/metrics.model';

export interface IModelDetailsDto {
  id: string;
  model_name: string;
  model_description: string;
  model_variant: MODEL_VARIANTS;
  type: string;
  date_created: string;
  status: MODEL_STATUS;
  failure_reason: string | null;
  datasets: {
    project_id: string;
    datasets_ids: string[];
  };
  model_config_: IModelConfig;
  is_owner: boolean;
}

export interface IModelImageDto {
  id: string;
  name: string;
  imageSet: ImageSetType;
  url: string;
}

export interface IModelImageMetricsDto {
  confusion_matrix: IConfusionMatrix[] | null;
  recall: number | null;
  precision: number | null;
  f1: number | null;
  auc: number | null;
  count: number | null;
  precision_recall_curve: Point2D[] | null;
  score: number | null;
}

export interface IModelImagePredictionDto {
  ground_truth: GROUND_TRUTH;
  heatmap_url: string;
  metrics: IModelImageMetricsDto;
}

export interface IModelImagePredictionsDto {
  [k: string]: IModelImagePredictionDto;
}

export interface IModelSettingsParamsDto {
  params: IParameterDto[];
}

export type SelectOption = {
  label: string;
  value: string;
};

export type VariantOption = {
  label: string;
  value: MODEL_VARIANTS;
};

export type BooleanOption = {
  label: string;
  value: boolean;
};

export const MODEL_RESULTS_INTEGERS = ['count'];

export interface IResult {
  name: string;
  train: number;
  validation: number;
  test: number;
}

export interface IModelMetricsDto {
  train_loss: Point2D[] | null;
  validation_loss: Point2D[] | null;
  metrics: {
    confusion_matrix: IConfusionMatrix[];
    precision_recall_curve: Point2D[];
    results: IResult[];
    auc: number;
  };
}

export interface IModelStatusDto {
  status: MODEL_STATUS;
  starting_progress: '1/4' | '2/4' | '3/4' | null;
}
