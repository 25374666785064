import * as React from 'react';

import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { ButtonWithLoader } from '../../../components/ButtonWithLoader';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { InputStatus } from '../../../models/error.model';
import { PasswordWithScoreInputsContainer } from '../../auth/containers/PasswordWithScoreInputsContainer';
import { ValidatedTextInput } from '../../../components/ValidatedTextInput';
import { as } from '../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { withNamespaces } from 'react-i18next';

interface IChangePasswordModal {
  showPasswordModal: boolean;
  oldPasswordStatus: InputStatus;
  passwordControlRef: React.RefObject<PasswordWithScoreInputsContainer>;
  passwordFormIsValid: boolean;
  onConfirm(): void;
  onCancel(): void;
  onNewPasswordChange(password: string): void;
  onNewPasswordStatusChange(passwordStatus: InputStatus): void;
  onOldPasswordChange(password: string): void;
  onOldPasswordBlur(): void;
}

class ChangePasswordModal extends React.Component<IChangePasswordModal & ITranslatable> {
  @autobind
  handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    this.props.onConfirm();
  }

  render() {
    return (
      <Modal className="modal-width-600" isOpen={this.props.showPasswordModal} toggle={this.props.onCancel}>
        <ModalHeader toggle={this.props.onCancel}>{this.props.t('change_password')}</ModalHeader>
        <Form onSubmit={this.handleSubmit}>
          <ModalBody>
            <Row>
              <Col>
                <ValidatedTextInput
                  labelText={`${this.props.t('old_password')} *`}
                  type="password"
                  placeholder={this.props.t('enter_old_password')}
                  enablePreview={true}
                  isValid={this.props.oldPasswordStatus.isValid}
                  feedbacks={this.props.oldPasswordStatus.errorCodes}
                  onChange={this.props.onOldPasswordChange}
                  onBlur={this.props.onOldPasswordBlur}
                />
                <PasswordWithScoreInputsContainer
                  labelText={`${this.props.t('new_password')} *`}
                  ref={this.props.passwordControlRef}
                  onPasswordChange={this.props.onNewPasswordChange}
                  onPasswordStatusChange={this.props.onNewPasswordStatusChange}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <ButtonWithLoader className="mr-2" color="success" disabled={!this.props.passwordFormIsValid} loaderKey="change_password_loader">
              {this.props.t('change')}
            </ButtonWithLoader>
            <Button color="primary" className="btn-outline-primary" onClick={this.props.onCancel}>
              {this.props.t('cancel')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default as<React.ComponentClass<IChangePasswordModal>>(withNamespaces('user', { wait: true })(ChangePasswordModal));
