import { Collapse, ListGroupItem } from 'reactstrap';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

import ClassNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export interface IFiltersDropdownRowProps {
  label: string;
  count: number;
}

interface IFilterDropdownRowState {
  isOpen: boolean;
}

export class FiltersDropdownRow extends React.Component<IFiltersDropdownRowProps, IFilterDropdownRowState> {
  constructor(props: IFiltersDropdownRowProps) {
    super(props);

    this.state = { isOpen: false };
  }

  toggle = () => this.setState(prev => ({ isOpen: !prev.isOpen }));

  render() {
    if (this.props.count === 0) return <></>;

    return (
      <ListGroupItem>
        <div className="filter-modal-line">
          <div className="label">
            {this.props.label}:
          </div>
          <div className="value">
            {
              this.props.count > 4 ?
                <>
                  <div onClick={this.toggle} className="toggle">{this.state.isOpen ? 'Hide' : 'Show'} {this.props.count} values
                    <div className={ClassNames({ 'order-controls': true, down: !this.state.isOpen })} >
                      <FontAwesomeIcon icon={!this.state.isOpen ? faSortDown : faSortUp} />
                    </div>
                  </div>
                  <Collapse isOpen={this.state.isOpen} >
                    {this.props.children}
                  </Collapse>
                </> :
                <>{this.props.children}</>

            }
          </div>
        </div>

      </ListGroupItem>
    );
  }
}
