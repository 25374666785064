import * as React from 'react';

import { FreeAccessStoreType, IFreeAccessStore } from '../../../../freeAccess/freeAccess.store';
import { ILoaderState, WithLoaderComponentBase } from '../../../../../helpers/loader.helpers';
import { IProjectDetailsImagesBl, ProjectDetailsImagesBlType } from '../projectDetailsImages.bl';
import { IProjectDetailsImagesStore, ProjectDetailsImagesStoreType } from '../projectDetailsImages.store';
import { IProjectDetailsPermissions, ProjectDetailsPermissionsType } from '../../../projectDetails.permissions';
import { IUserService, UserServiceType } from '../../../../user/user.service';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { ListViewMode } from '../../../../user/user.store';
import { ProjectDetailsImageListControls } from '../components/ProjectDetailsImageListControls';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps {
  permissions: IProjectDetailsPermissions;
  projectDetailsImagesBl: IProjectDetailsImagesBl;
  userService: IUserService;
  freeAccessStore: IFreeAccessStore;
  imagesStore: IProjectDetailsImagesStore;
}

@injectProps({
  permissions: ProjectDetailsPermissionsType,
  projectDetailsImagesBl: ProjectDetailsImagesBlType,
  userService: UserServiceType,
  freeAccessStore: FreeAccessStoreType,
  imagesStore: ProjectDetailsImagesStoreType,
})
@observer
class ProjectDetailsImageListControlsContainerPure extends WithLoaderComponentBase<IInjectedProps, ILoaderState> {
  @autobind
  handleViewModeChange(mode: ListViewMode) {
    this.props.userService.setProjectImageListViewMode(mode);
  }

  render() {
    const showAnnotatedReviedColumns = this.props.permissions.canSeeAnnotationAuthors();

    return (
      <ProjectDetailsImageListControls
        showAnnotatedReviedColumns={showAnnotatedReviedColumns}
        viewMode={this.props.userService.data.projectImageListViewMode}
        onViewModeChange={this.handleViewModeChange}
      />);
  }
}

export const ProjectDetailsImageListControlsContainer = as<React.ComponentClass>(ProjectDetailsImageListControlsContainerPure);
