import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { PersonalInformationRow } from './PersonalInformationRow';
import React from 'react';
import Select from 'react-select';
import { as } from '../../../../../helpers/react.helpers';
import { handleSelectChange } from '../../../../../helpers/formHelpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  countryOptions: any;
  country: any;
  onChange(country: string): void;
}

class CountryRowPure extends React.Component<IProps & ITranslatable> {
  render() {
    return (
      <PersonalInformationRow label="country" overflow={true}>
        <Select
          id="country-dropdown"
          placeholder={this.props.t('enter_country')}
          defaultValue={null}
          value={this.props.countryOptions.find((o: any) => o.value === this.props.country)}
          onChange={handleSelectChange(this.props.onChange)}
          options={this.props.countryOptions}
          classNamePrefix="custom-select"
        />
      </PersonalInformationRow>
    );
  }
}

export const CountryRow = as<React.ComponentClass<IProps>>(withNamespaces('auth')(CountryRowPure))