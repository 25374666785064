import { IUserService, UserServiceType } from '../../user/user.service';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { ProjectRole } from '../../../models/userRole.model';
import React from 'react';

interface IProps {
  allowedRoles: ProjectRole[];
}

interface IInjectedProps {
  userService: IUserService;
}

type Props = IProps & RouteComponentProps<{ projectId: string }> & IInjectedProps;

@injectProps({
  userService: UserServiceType,
})
class RedirectFromAnnotationContainer extends React.Component<Props> {
  render() {
    const redirect = !this.props.userService.isInRole(this.props.match.params.projectId, this.props.allowedRoles);

    return (
      <>
        {redirect && <Redirect to="/" />}
      </>
    );
  }
}

export default as<React.StatelessComponent<IProps>>(withRouter(RedirectFromAnnotationContainer));
