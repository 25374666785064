import { getVectorEndCoords, getVectorStartCoords } from '../../../../helpers/geometry/vectorTool.helpers';
import leaflet, { LatLng, Map } from 'leaflet';

import { AnnotationToolType } from '../../submodules/annotationTypes/models/annotationToolType';
import { scaleToZoom } from '../../../../helpers/geometry/mapExtensions.helpers';

export class PolylineEditorEx extends leaflet.Editable.PolylineEditor {
  private start!: any;
  private end!: any;

  initialize(map: Map, feature: any, options: any) {
    super.initialize(map, feature, options);
    if (feature.options.storeKey) {
      map.eachLayer((layer: any) => {
        if (layer.options.storeKey === `${feature.options.storeKey}-start`) this.start = layer;
        if (layer.options.storeKey === `${feature.options.storeKey}-end`) this.end = layer;
      });
    }
  }

  isVector() {
    return this.feature.options.type === AnnotationToolType.VECTOR;
  }

  hasMiddleMarkers() {
    return !this.isVector();
  }

  extendBounds(e: leaflet.Editable.VertexMouseEvent) {
    super.extendBounds(e);
    if (this.isVector()) this.moveOrientationMarkers(e.vertex.latlngs);
  }

  onDrag(e: PointerEvent) {
    super.onDrag(e);
    if (this.isVector()) this.moveOrientationMarkers(this.feature._latlngs);
  }

  moveOrientationMarkers([start, end]: LatLng[]) {
    if (!this.start || !this.end) return;
    const zoom = scaleToZoom(this.map.getZoom());
    this.start._latlngs = getVectorStartCoords(start, zoom);
    this.end._latlngs = getVectorEndCoords([start, end], zoom);
    this.start.removeFrom(this.map);
    this.start.addTo(this.map);
    this.end.removeFrom(this.map);
    this.end.addTo(this.map);
  }
}
