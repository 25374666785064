import './S_WarningRow.scss';

import React from 'react';
import { S_ClassNameProps } from '../CommonProps';
import classNames from 'classnames';

interface S_WarningRowProps extends S_ClassNameProps {
  text: string;
  onClick?(): void;
}

export const S_WarningRow: React.FC<S_WarningRowProps> = ({ className, text, onClick }) => <div className={classNames("s-warning-row alert alert-info", className)} onClick={onClick} >{text}</div>;
