import 'leaflet-draw';
import 'leaflet-editable';

import * as React from 'react';

import L, { LatLngBounds, LatLngBoundsLiteral, Map } from 'leaflet';

import { ISegmentation } from '../annotations.interface';
import { as } from '../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { toJS } from 'mobx';
import { withLeaflet } from 'react-leaflet';

interface IProps {
  leaflet: { map: Map };
}

interface IOuterProps {
  invalidSegmentation?: ISegmentation;
}

class SegmentationFocusControlPure extends React.Component<IProps & IOuterProps> {
  private map!: Map;

  private readonly FRAME_PADDING: number = 5;
  private readonly FRAME_PADDING_POINT: number = 10;

  componentDidMount() {
    if (super.componentDidMount) super.componentDidMount();
    this.map = this.props.leaflet.map;
  }

  // tslint:disable-next-line:function-name
  UNSAFE_componentWillUpdate(newProps: IOuterProps) {
    if (newProps.invalidSegmentation !== undefined && newProps.invalidSegmentation?.feature?.id !== this.props.invalidSegmentation?.feature?.id) {
      this.centerOnSegmentation(newProps.invalidSegmentation);
    }
  }

  getBounds(segmentation: ISegmentation): LatLngBounds {
    const bounds = L.geoJSON(segmentation.feature.geometry).getBounds();
    const framePadding = segmentation.feature.geometry.type === 'Point' || segmentation.feature.geometry.type === 'LineString' ? this.FRAME_PADDING_POINT : this.FRAME_PADDING;

    const extended: LatLngBoundsLiteral = [
      [bounds.getSouth() - framePadding, bounds.getWest() - framePadding],
      [bounds.getNorth() + framePadding, bounds.getEast() + framePadding],
    ];

    return new LatLngBounds(extended);
  }

  @autobind
  centerOnSegmentation(segmentation: ISegmentation) {
    if (!segmentation) return;
    const bounds = this.getBounds(toJS(segmentation));
    this.map.panTo(bounds.getCenter(), { animate: false });
  }

  render() {
    return <>{this.props.children}</>;
  }
}

export const SegmentationFocusControl = as<React.ComponentClass<IOuterProps>>(withLeaflet(SegmentationFocusControlPure));
