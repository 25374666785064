import * as React from 'react';

import { AnnotationConfirmationModal, AnnotationConfirmationModalMode } from '../../../components/AnnotationConfirmationModal';
import { AnnotationCreationServiceType, IAnnotationCreationService } from '../annotationCreation.service';
import { AnnotationsStoreType, IAnnotationsStore } from '../annotations.store';
import { FreeDrawSegmentationServiceType, IFreeDrawSegmentationService } from '../freeDrawSegmentation.service';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../../modules/common/OverlayLoader.store';
import { IRouterStore, RouterStoreType } from '../../../stores/router.store';
import { IUndoRedoHistory, UndoRedoHistoryType } from '../undoRedoHistory.service';
import { IWorkspaceService, WorkspaceServiceType } from '../../workspaces/workspaces.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { AbandonDraftConfirmationModal } from '../../../components/AbandonDraftConfirmationModal';
import { AnnotationNavigation } from '../components/AnnotationNavigation';
import { AnnotationStatus } from '../annotations.interface';
import { EditorMode } from '../../../../modules/editor/models/EditorModes';
import { GlobalHotKeys } from 'react-hotkeys';
import { Home } from '../../../routes/config/Home';
import { SaveBeforeAbandonDraftConfirmationModal } from '../../../components/SaveBeforeAbandonDraftConfirmationModal';
import { SaveBeforeSkipConfirmationModal } from '../../../components/SaveBeforeSkipConfirmationModal';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import autobind from 'autobind-decorator';
import { dinduNuffin } from '../../../helpers/function.helpers';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ workspaceId: string }> {
  routerStore: IRouterStore;
  annotationCreationService: IAnnotationCreationService;
  freeDrawSegmentationService: IFreeDrawSegmentationService;
  historyService: IUndoRedoHistory;
  workspaceService: IWorkspaceService;
  overlayLoaderStore: IOverlayLoaderStore;
  annotationsStore: IAnnotationsStore;
}

interface IState extends ILoaderState {
  showAnnotationConfirmationModal: boolean;
  showAbandonConfirmationModal: boolean;
  showSaveBeforeAbandonConfirmationModal: boolean;
  showDiscardConfirmationModal: boolean;
  showSaveBeforeSkipModal: boolean;
}

@injectProps({
  routerStore: RouterStoreType,
  annotationCreationService: AnnotationCreationServiceType,
  freeDrawSegmentationService: FreeDrawSegmentationServiceType,
  historyService: UndoRedoHistoryType,
  workspaceService: WorkspaceServiceType,
  overlayLoaderStore: OverlayLoaderStoreType,
  annotationsStore: AnnotationsStoreType,
})
@observer
class CreateControlsContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  state: IState = {
    showAnnotationConfirmationModal: false,
    showAbandonConfirmationModal: false,
    showSaveBeforeAbandonConfirmationModal: false,
    showDiscardConfirmationModal: false,
    showSaveBeforeSkipModal: false,
    isLoading: false,
  };
  
  @autobind
  async handleSubmit() {
    if (this.props.annotationsStore.annotationStatus === AnnotationStatus.REJECTED) {
      await this.withLoaderAsync<boolean>(() => this.props.annotationCreationService.batchCorrectAndSubmitAnnotationForReviewAsync(), 'submit-annotation-button');
    } else {
      await this.withLoaderAsync<boolean>(() => this.props.annotationCreationService.batchSubmitAnnotationForReviewAsync(), 'submit-annotation-button');
    }
    this.setState({ showAnnotationConfirmationModal: false });
  }

  @autobind
  async handleSave() {
    this.setState({ showAnnotationConfirmationModal: false });
    await this.withLoaderAsync(() => this.props.annotationCreationService.batchCorrectAnnotationAsync(), 'save-annotation-button');
    this.props.routerStore.push(Home.Projects.List.All.withParams({ workspaceId: this.props.match.params.workspaceId }));
  }

  @autobind
  async handleSaveAsDraftAsync() {
    this.setState({ showAnnotationConfirmationModal: false });
    await this.withLoaderAsync(() => this.props.annotationCreationService.batchSaveAnnotationDraftAsync(), 'save-draft-button');
    this.props.routerStore.push(Home.Projects.List.All.withParams({ workspaceId: this.props.match.params.workspaceId }));
  }

  @autobind
  async handleAbandonDraftConfirmedAsync() {
    this.setState({ showAbandonConfirmationModal: false });

    if (this.props.annotationsStore.image === undefined || !this.props.historyService.canUndo) {
      await this.withLoaderAsync(() => this.props.annotationCreationService.batchAbandonAnnotationDraftAsync(false), 'abandon-annotation-button');
    } else {
      this.setState({ showSaveBeforeAbandonConfirmationModal: true });
    }
  }

  @autobind
  async handleSaveBeforeAbandonConfirmedAsync() {
    this.setState({ showSaveBeforeAbandonConfirmationModal: false });
    await this.withLoaderAsync(() => this.props.annotationCreationService.batchAbandonAnnotationDraftAsync(true), 'abandon-annotation-button');
  }

  @autobind
  handleAbandonDraftCanceledAsync() {
    this.setState({ showAbandonConfirmationModal: false });
  }

  @autobind
  async handleSaveBeforeAbandonDraftDeclinedAsync() {
    this.setState({ showSaveBeforeAbandonConfirmationModal: false });
    await this.withLoaderAsync(() => this.props.annotationCreationService.batchAbandonAnnotationDraftAsync(false), 'abandon-annotation-button');
    this.props.routerStore.push(Home.Projects.List.All.withParams({ workspaceId: this.props.match.params.workspaceId }));
  }

  @autobind
  handleSaveBeforeAbandonDraftCanceled() {
    this.setState({ showSaveBeforeAbandonConfirmationModal: false });
  }

  @autobind
  handleConfirmAbandonDraft() {
    this.setState({ showAbandonConfirmationModal: true });
  }

  @autobind
  async handleDiscardConfirmed() {
    if (this.state.isLoading) return;

    this.setState({ showDiscardConfirmationModal: false });
    await this.withLoaderAsync(() => this.props.annotationCreationService.batchDiscardAnnotationDuringAnnotationAsync(), 'discard-annotation-button');
  }

  @autobind
  async handleDiscardCanceled() {
    this.setState({ showDiscardConfirmationModal: false });
  }

  @autobind
  async handleDiscard() {
    this.setState({ showDiscardConfirmationModal: true });
  }

  componentWillUnmount() {
    this.props.annotationCreationService.unlockImagesAsync();
  }

  @autobind
  toggleAnnotationConfirmationModal() {
    this.setState((prevState: IState) => ({ ...prevState, showAnnotationConfirmationModal: !prevState.showAnnotationConfirmationModal }));
  }

  @autobind
  async handleSkip() {
    if (this.props.annotationsStore.image === undefined || !this.props.historyService.canUndo) {
      await this.withLoaderAsync(() => this.props.annotationCreationService.skipAnnotationsAsync(), 'skip-annotation-button');
    } else {
      this.setState({ showSaveBeforeSkipModal: true });
    }
  }

  @autobind
  async handleSaveBeforeSkipConfirmed() {
    this.setState({ showSaveBeforeSkipModal: false });
    await this.withLoaderAsync(async () => {
      await this.props.annotationCreationService.batchSaveAnnotationDraftAsync();
      await this.props.annotationCreationService.skipAnnotationsAsync();
    }, 'skip-annotation-button');
  }

  @autobind
  async handleSaveBeforeSkipDecline() {
    this.setState({ showSaveBeforeSkipModal: false });
    await this.withLoaderAsync(() => this.props.annotationCreationService.skipAnnotationsAsync(), 'skip-annotation-button');
  }

  @autobind
  async handleSaveBeforeSkipCancel() {
    this.setState({ showSaveBeforeSkipModal: false });
  }

  render() {
    const disabled =
      this.props.annotationsStore.image === undefined ||
      this.props.freeDrawSegmentationService.freeDrawInProgress ||
      this.props.freeDrawSegmentationService.freeDrawFeature !== undefined ||
      this.props.overlayLoaderStore.isOverlayVisible();

    const isAssignedToCurrentUser = this.props.annotationsStore.image?.annotations?.isAssignedToCurrentUser || false;
    const status = this.props.annotationsStore.annotationStatus;
    const isRejected = status === AnnotationStatus.REJECTED;

    // TODO: Create IPermission for annotations
    const canDiscard = (status === AnnotationStatus.DRAFT || isRejected) && this.props.workspaceService.isInRole([WorkspaceRole.Owner, WorkspaceRole.Manager]);

    return (
      <>
        {!disabled && <GlobalHotKeys keyMap={{ SUBMIT: { sequence: 'ctrl+shift+enter', action: 'keydown' } }} handlers={{ SUBMIT: this.toggleAnnotationConfirmationModal }} />}{' '}
        <AnnotationNavigation
          canSave={isRejected}
          canSubmit={true}
          canDiscard={canDiscard}
          hideDraft={isRejected}
          disabled={disabled}
          editorMode={EditorMode.BATCH_ANNOTATION}
          onSubmit={this.handleSubmit}
          onSave={this.handleSave}
          onSaveAsDraft={this.handleSaveAsDraftAsync}
          onSkip={this.handleSkip}
          hideSkip={isAssignedToCurrentUser || isRejected}
          hideAbandonDraft={!isAssignedToCurrentUser || isRejected}
          onAbandonDraft={this.handleConfirmAbandonDraft}
          showDiscardModal={this.state.showDiscardConfirmationModal}
          onDiscardCanceled={this.handleDiscardCanceled}
          onDiscardConfirmed={this.handleDiscardConfirmed}
          onDiscard={this.handleDiscard}
          canCancel={false}
          showCancelModal={false}
          onCancel={dinduNuffin}
          onCancelCancel={dinduNuffin}
          onConfirmCancel={dinduNuffin}
        />
        <AbandonDraftConfirmationModal
          showAbandonModal={this.state.showAbandonConfirmationModal}
          onAbandonConfirm={this.handleAbandonDraftConfirmedAsync}
          onAbandonCancel={this.handleAbandonDraftCanceledAsync}
        />
        <SaveBeforeAbandonDraftConfirmationModal
          showSaveBeforeAbandonModal={this.state.showSaveBeforeAbandonConfirmationModal}
          onSaveBeforeAbandonCancel={this.handleSaveBeforeAbandonDraftCanceled}
          onSaveBeforeAbandonConfirm={this.handleSaveBeforeAbandonConfirmedAsync}
          onSaveBeforeAbandonDecline={this.handleSaveBeforeAbandonDraftDeclinedAsync}
        />
        <SaveBeforeSkipConfirmationModal
          showSaveBeforeSkipModal={this.state.showSaveBeforeSkipModal}
          onSaveBeforeSkipDecline={this.handleSaveBeforeSkipDecline}
          onSaveBeforeSkipConfirm={this.handleSaveBeforeSkipConfirmed}
          onSaveBeforeSkipCancel={this.handleSaveBeforeSkipCancel}
        />
        <AnnotationConfirmationModal
          showAnnotationConfirmationModal={this.state.showAnnotationConfirmationModal}
          annotationConfirmationModalMode={AnnotationConfirmationModalMode.SUBMIT}
          onAnnotationConfirm={this.handleSubmit}
          onAnnotationCancel={this.toggleAnnotationConfirmationModal}
          rejectAutoCompleteOptions={[]}
        />
      </>
    );
  }
}

export const CreateControlsContainer = as<React.ComponentClass>(withRouter(CreateControlsContainerPure));
