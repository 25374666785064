import { ContainerModule, interfaces } from 'inversify';
import { FreeAccessApiService, FreeAccessApiServiceType, IFreeAccessApiService } from './freeAccessApi.service';
import { FreeAccessImagesQueueService, FreeAccessImagesQueueServiceType } from './freeAccessImagesQueue.service';
import { FreeAccessPermissions, FreeAccessPermissionsType, IFreeAccessPermissions } from './freeAccess.permissions';
import { FreeAccessService, FreeAccessServiceType, IFreeAccessService } from './freeAccess.service';
import { FreeAccessStore, FreeAccessStoreType, IFreeAccessStore } from './freeAccess.store';

import { IImagesQueueService } from '../annotation/imagesQueueServiceBase';

export const freeAccessModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IFreeAccessStore>(FreeAccessStoreType).to(FreeAccessStore).inSingletonScope();

  bind<IFreeAccessApiService>(FreeAccessApiServiceType).to(FreeAccessApiService).inTransientScope();

  bind<IFreeAccessService>(FreeAccessServiceType).to(FreeAccessService).inSingletonScope();

  bind<IImagesQueueService>(FreeAccessImagesQueueServiceType).to(FreeAccessImagesQueueService).inTransientScope();

  bind<IFreeAccessPermissions>(FreeAccessPermissionsType).to(FreeAccessPermissions).inSingletonScope();
});
