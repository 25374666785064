import { ContainerModule, interfaces } from 'inversify';
import { CreateDatasetFilesService, CreateDatasetFilesServiceType, ICreateDatasetFilesService } from './createDatasetFiles.service';
import { CreateDatasetService, CreateDatasetServiceType, ICreateDatasetService } from './createDataset.service';
import { DatasetDetailsService, DatasetDetailsServiceType, IDatasetDetailsService } from './datasetDetails.service';
import { DatasetDraftStoreType, DatasetDraftsStore, IDatasetDraftsStore } from './datasetDrafts.store';
import { DatasetsDetailsStore, DatasetsDetailsStoreType, IDatasetsDetailsStore } from './datasetsDetails.store';
import { DatasetsPermissions, DatasetsPermissionsType, IDatasetsPermissions } from './datasets.permissions';
import { DatasetsService, DatasetsServiceType, IDatasetsService } from './datasets.service';
import { DatasetsStore, DatasetsStoreType, IDatasetsStore } from './datasets.store';
import { IImagesFromUrlsService, ImagesFromUrlsServiceType, ImagesFromUrlsUploadService } from './imagesFromUrlsUpload.service';
import { IImagesUploaderService, ImagesUploadService, ImagesUploaderServiceType } from './imagesUpload.service';
import { IUpdateDatasetFilesService, UpdateDatasetFilesService, UpdateDatasetFilesServiceType } from './updateDatasetFiles.service';
import { IUpdateDatasetService, UpdateDatasetService, UpdateDatasetServiceType } from './updateDataset.service';

export const datasetsModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IImagesUploaderService>(ImagesUploaderServiceType).to(ImagesUploadService).inSingletonScope();

  bind<IImagesFromUrlsService>(ImagesFromUrlsServiceType).to(ImagesFromUrlsUploadService).inSingletonScope();

  bind<IDatasetsService>(DatasetsServiceType).to(DatasetsService).inRequestScope();

  bind<ICreateDatasetService>(CreateDatasetServiceType).to(CreateDatasetService).inSingletonScope();

  bind<ICreateDatasetFilesService>(CreateDatasetFilesServiceType).to(CreateDatasetFilesService).inSingletonScope();

  bind<IDatasetDetailsService>(DatasetDetailsServiceType).to(DatasetDetailsService).inRequestScope();

  bind<IUpdateDatasetService>(UpdateDatasetServiceType).to(UpdateDatasetService).inSingletonScope();

  bind<IUpdateDatasetFilesService>(UpdateDatasetFilesServiceType).to(UpdateDatasetFilesService).inSingletonScope();

  bind<IDatasetsDetailsStore>(DatasetsDetailsStoreType).to(DatasetsDetailsStore).inRequestScope();

  bind<IDatasetsStore>(DatasetsStoreType).to(DatasetsStore).inSingletonScope();

  bind<IDatasetDraftsStore>(DatasetDraftStoreType).to(DatasetDraftsStore).inSingletonScope();

  bind<IDatasetsPermissions>(DatasetsPermissionsType).to(DatasetsPermissions).inSingletonScope();
});
