import { EditMarkingToolBl, EditMarkingToolBlType } from '../../EditMarkingTool.bl';
import { EditMarkingToolStore, EditMarkingToolStoreType } from '../../EditMarkingTool.store';
import { as, injectProps } from '../../../../../../../../__legacy__/helpers/react.helpers';

import { ColorFieldContainer } from '../fields/ColorField.container';
import { MarkingToolForm } from '../../../commons/ui/markingToolForm/MarkingToolForm';
import { MarkingToolModal } from '../../../commons/MarkingToolModal';
import { NameFieldContainer } from '../fields/NameField.container';
import React from 'react';
import { ToolTypeFieldContainer } from '../fields/ToolTypeField.container';
import { WithLoaderComponentBase } from '../../../../../../../../__legacy__/helpers/loader.helpers';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: EditMarkingToolBl;
  store: EditMarkingToolStore;
}

@injectProps({
  bl: EditMarkingToolBlType,
  store: EditMarkingToolStoreType,
})
@observer
class ModalContainerPure extends WithLoaderComponentBase<InjectedProps> {
  handleSubmit = async () => await this.withLoaderAsync(this.props.bl.editMarkingToolAsync, 'add-or-edit-marking-tool-key');
  handleHide = () => this.props.bl.hideModal();

  render() {
    return (
      <MarkingToolModal
        headerKey="projects.details.annotation_tools.marking_tools.edit.header"
        onHide={this.handleHide}
        onSubmit={this.handleSubmit}
        show={this.props.store.showModal}
      >
        <MarkingToolForm>
          <NameFieldContainer />
          <ToolTypeFieldContainer />
          <ColorFieldContainer />
        </MarkingToolForm>
      </MarkingToolModal>
    );
  }
}

export const EditMarkingToolModalContainer = as<React.ComponentClass>(ModalContainerPure);
