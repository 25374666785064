import * as React from 'react';

import { faAngleLeft, faAngleRight, faDownload, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { DatasetStatus } from '../datasetStatus.model';
import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { EditableImagePreviewHeader } from './EditableImagePreviewHeader';
import { EnableForRole } from '../../../containers/EnableForRole';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { IconedButton } from '../../../components/IconedButton';
import { ImageNavigation } from '../../../components/ImageNavigation';
import { InputStatus } from '../../../models/error.model';
import { LinkedButton } from '../../../components/LinkedButton';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import { as } from '../../../helpers/react.helpers';
import { dinduNuffin } from '../../../helpers/function.helpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { toLocaleDateTimeString } from '../../../helpers/date.helpers';
import { withNamespaces } from 'react-i18next';

library.add(faAngleLeft, faAngleRight, faTimes, faDownload, faTrashAlt);

export interface IDatasetDetailsImageInfoProps {
  total: number;
  name: string;
  current: number;
  isLoading: boolean;
  isHighResImageLoading: boolean;
  imageSize: number;
  imageWidth: number;
  channels: number;
  imageHeight: number;
  isCached: boolean;
  createdDate: string;
  downloadSpeed?: number;
  nextLink: string;
  previousLink: string;
  closeButtonLink: string;
  datasetName: string;
  datasetStatus: DatasetStatus;
  isDeleteModalShown: boolean;
  planAllowsDownload: boolean;
  canDelete: boolean;
  hasAnnotations: boolean;
  isLocked: boolean;
  onToggleDeleteModal(): void;
  onDeleteImage(): void;
  onDownloadImage(): void;
  onImageNameChange(imageName: string): void;
  onImageNameBlur(): Promise<void>;
  nameStatus: InputStatus;
  canRenameImages: boolean;
  newName: string;
  onCancelImageRename(): void;
}

class DatasetDetailsPreviewHeaderPure extends React.Component<IDatasetDetailsImageInfoProps & ITranslatable> {
  render() {
    const { total, current, t, nextLink, previousLink, closeButtonLink } = this.props;
    let confirmationQuestion: string;

    if (this.props.hasAnnotations) {
      confirmationQuestion = 'delete_used_images_confirmation_body';
    } else if (this.props.isLocked) {
      confirmationQuestion = 'delete_locked_images_confirmation_body';
    } else {
      confirmationQuestion = 'delete_image_confirmation_body';
    }

    const attributes = [
      { name: t('image_attribute_size'), value: `${this.props.imageSize.toFixed(3)} MB` },
      { name: t('image_attribute_resolution'), value: `${this.props.imageWidth} x ${this.props.imageHeight}` },
      { name: t('image_attribute_type'), value: `${this.props.channels} ${t('image_attribute_channels')}` },
      { name: t('image_attribute_status'), value: this.props.isCached ? t('image_attribute_status_cached') : t('image_attribute_status_not_cached') },
      { name: t('image_attribute_created'), value: toLocaleDateTimeString(this.props.createdDate) },
      { name: t('image_attribute_load_speed'), value: this.props.downloadSpeed ? `${this.props.downloadSpeed.toFixed(3)} Mbps` : t('image_attribute_load_speed_cached') },
    ];

    return (
      <div className="dataset-image-preview-header">
        <div className="image-name">
          <div className="image-input-body">
            <EditableImagePreviewHeader
              name={this.props.name}
              datasetName={this.props.datasetName}
              datasetStatus={this.props.datasetStatus}
              onImageNameChange={this.props.onImageNameChange}
              onImageNameBlur={this.props.onImageNameBlur}
              nameStatus={this.props.nameStatus}
              canRenameImages={this.props.canRenameImages}
              newName={this.props.newName}
              onCancelRenameImage={this.props.onCancelImageRename}
              onStartEdit={dinduNuffin}
            />
          </div>

          {!this.props.isHighResImageLoading && <span className="info">{attributes.map(x => `${x.name}: ${x.value}`).join(' | ')}</span>}
        </div>
        <div className="utility-buttons">
          <EnableForRole
            workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Trainer]}
            projectRoles={[]}
            title={this.props.planAllowsDownload ? this.props.t('disabled_due_to_insufficient_rights') : this.props.t('cant_download_with_annotate_and_go')}
          >
            <DisabledWhenWorkspaceOwnerPolicyExceeded>
              <IconedButton
                color="primary"
                className="download-button"
                icon={faDownload}
                title={this.props.planAllowsDownload ? this.props.t('download_image') : this.props.t('cant_download_with_annotate_and_go')}
                disabled={!this.props.planAllowsDownload}
                onClick={this.props.onDownloadImage}
              />
            </DisabledWhenWorkspaceOwnerPolicyExceeded>
          </EnableForRole>
          <EnableForRole
            workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Trainer, WorkspaceRole.Collaborator]}
            projectRoles={[]}
            title={this.props.t('delete_disabled_due_to_insufficient_rights')}
          >
            <IconedButton
              color="warning"
              className="delete-button"
              icon={faTrashAlt}
              title={this.props.canDelete ? this.props.t('delete_image') : this.props.t('developer_cant_delete')}
              onClick={this.props.onToggleDeleteModal}
              disabled={!this.props.canDelete}
            />
          </EnableForRole>
        </div>
        <div className="navigation">
          <ImageNavigation
            disabled={this.props.nameStatus.errorCodes.length > 0}
            current={current}
            total={total}
            nextLink={nextLink}
            previousLink={previousLink}
            isLoading={this.props.isLoading}
          />
          <LinkedButton disabled={this.props.nameStatus.errorCodes.length > 0} to={closeButtonLink} color="primary" className="p-2 ml-2 button-close">
            <FontAwesomeIcon icon={faTimes} className="mr-0" title={t('close')} />
          </LinkedButton>
        </div>
        <ConfirmationModal
          confirmationHeader={this.props.t('delete_image_confirmation')}
          confirmationQuestion={confirmationQuestion}
          onCancel={this.props.onToggleDeleteModal}
          onConfirm={this.props.onDeleteImage}
          showModal={this.props.isDeleteModalShown}
          confirmationYes={this.props.t('delete')}
        />
      </div>
    );
  }
}

export const DatasetDetailsPreviewHeader = as<React.StatelessComponent<IDatasetDetailsImageInfoProps>>(withNamespaces('datasets', { wait: true })(DatasetDetailsPreviewHeaderPure));
