import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { IUpdateWorkspaceTimeZoneBl, UpdateWorkspaceTimeZoneBlType } from '../UpdateWorkspaceTimeZone.bl';
import { IUpdateWorkspaceTimeZoneStore, UpdateWorkspaceTimeZoneStoreType } from '../UpdateWorkspaceTimeZone.store';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import React from 'react';
import { UpdateWorkspaceTimeZoneDropdownItem } from '../components/UpdateWorkspaceTimeZoneDropdownItem';
import autobind from 'autobind-decorator';

interface IInjectedProps {
  bl: IUpdateWorkspaceTimeZoneBl;
  store: IUpdateWorkspaceTimeZoneStore;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

@injectProps({
  bl: UpdateWorkspaceTimeZoneBlType,
  store: UpdateWorkspaceTimeZoneStoreType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
class UpdateWorkspaceTimeZoneDropdownItemContainerPure extends React.Component<IInjectedProps> {
  @autobind
  private handleClick() {
    this.props.bl.showForm(this.props.currentWorkspaceStore.currentWorkspace!.id, this.props.currentWorkspaceStore.currentWorkspace!.timeZoneOffset);
  }

  render() {
    return <UpdateWorkspaceTimeZoneDropdownItem onClick={this.handleClick} />;
  }
}

export const UpdateWorkspaceTimeZoneDropdownItemContainer = as<React.ComponentClass>(UpdateWorkspaceTimeZoneDropdownItemContainerPure);
