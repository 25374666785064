import * as React from 'react';

import { AnnotationToolFilterType, EmptyAnnotationToolFilter, IAnnotationToolFilter, IImageFiltersData } from '../../../imageFilters.model';

import { AnnotationToolRowView } from './AnnotationToolRowView';
import { QuestionType } from '../../../../../../annotation/question.model';

interface IProps {
  availableImageFilters: IImageFiltersData;
  toolFilter: IAnnotationToolFilter;
  showDelete: boolean;
  onDelete(order: number): void;
  onToolFilterChanged(toolFilter: IAnnotationToolFilter): void;
}

export class AnnotationToolRowForm extends React.Component<IProps> {

  handleL1FilterChanged = (id: string) => {

    if (this.props.toolFilter.l1Filter === id) return;

    if (id === undefined) {
      this.props.onToolFilterChanged({ ...EmptyAnnotationToolFilter, order: this.props.toolFilter.order });
      return;
    }

    const { annotationTypes, questionFilters } = this.props.availableImageFilters;

    const annotationType = annotationTypes.find(x => x.id === id);
    if (annotationType) {
      this.props.onToolFilterChanged({
        ...EmptyAnnotationToolFilter,
        order: this.props.toolFilter.order,
        l1Filter: id,
        l1FilterType: AnnotationToolFilterType.AnnotationTool,
      });
    } else {
      const question = questionFilters.find(x => x.id === id)!;
      let type: AnnotationToolFilterType;
      switch (question.answerType) {
        case QuestionType.OPEN:
          type = AnnotationToolFilterType.QuestionAnswerText;
          break;
        case QuestionType.NUMBER:
          type = AnnotationToolFilterType.QuestionAnswerNumber;
          break;
        default:
          type = AnnotationToolFilterType.QuestionAnswer;
          break;
      }
      this.props.onToolFilterChanged({
        ...EmptyAnnotationToolFilter,
        order: this.props.toolFilter.order,
        l1Filter: id,
        l1FilterType: AnnotationToolFilterType.Question,
        l3FilterType: type,
      });
    }
  }

  handleL2FilterChanged = (id: string) => {
    if (this.props.toolFilter.l2Filter === id) return;

    if (id === undefined) {
      this.props.onToolFilterChanged({
        ...EmptyAnnotationToolFilter,
        order: this.props.toolFilter.order,
        l1FilterType: this.props.toolFilter.l1FilterType,
        l1Filter: this.props.toolFilter.l1Filter,
      });
      return;
    }

    const { annotationTypes } = this.props.availableImageFilters;
    const annotationType = annotationTypes.find(x => x.id === this.props.toolFilter.l1Filter);
    if (annotationType) {
      const attribute = annotationType.attributes.find(x => x.id === id);
      if (attribute) {
        let l3FilterType: AnnotationToolFilterType;
        switch (attribute.answerType) {
          case QuestionType.OPEN:
            l3FilterType = AnnotationToolFilterType.AttributeAnswerText;
            break;
          case QuestionType.NUMBER:
            l3FilterType = AnnotationToolFilterType.AttributeAnswerNumber;
            break;
          default:
            l3FilterType = AnnotationToolFilterType.AttributeAnswer;
            break;
        }
        this.props.onToolFilterChanged({
          ... this.props.toolFilter,
          l3FilterType,
          l2Filter: id,
          l2FilterType: AnnotationToolFilterType.Attribute,
          l3Filters: [],
        });
        return;
      }
    }
  }

  handleL3MultiselectFilterChanged = (ids: string[]) => {
    const { annotationTypes, questionFilters } = this.props.availableImageFilters;
    const annotationType = annotationTypes.find(x => x.id === this.props.toolFilter.l1Filter);
    if (annotationType) {
      const attribute = annotationType.attributes.find(x => x.id === this.props.toolFilter.l2Filter);
      if (attribute) {
        const answers = attribute.possibleAnswers.filter(x => ids.some(y => y === x.id)).map(x => x.id);
        this.props.onToolFilterChanged({ ... this.props.toolFilter, l3Filters: answers });
      }
    } else {
      const question = questionFilters.find(x => x.id === this.props.toolFilter.l1Filter);
      if (question) {
        const answers = question.possibleAnswers.filter(x => ids.some(y => y === x.id)).map(x => x.id);
        this.props.onToolFilterChanged({ ... this.props.toolFilter, l3Filters: answers });
      }
    }
  }

  handleL3SelectFilterChanged = (id: string) => {

    if (id === undefined) {
      this.props.onToolFilterChanged({
        ...this.props.toolFilter,
        l3Filters: [],
      });
      return;
    }

    const { annotationTypes, questionFilters } = this.props.availableImageFilters;
    const annotationType = annotationTypes.find(x => x.id === this.props.toolFilter.l1Filter);
    if (annotationType) {
      const attribute = annotationType.attributes.find(x => x.id === this.props.toolFilter.l2Filter);
      if (attribute) {
        const answer = attribute.possibleAnswers.find(x => x.id === id);
        if (answer) {
          this.props.onToolFilterChanged({ ... this.props.toolFilter, l3Filters: [id] });
          return;
        }
      }
    } else {
      const question = questionFilters.find(x => x.id === this.props.toolFilter.l1Filter);
      if (question) {
        const answer = question.possibleAnswers.find(x => x.id === id);
        if (answer) {
          this.props.onToolFilterChanged({ ... this.props.toolFilter, l3Filters: [id] });
          return;
        }
      }
    }
  }

  handleL3TextFilterChanged = (text: string) => {
    this.props.onToolFilterChanged({ ... this.props.toolFilter, l3Filters: [text] });
  }

  handleL3MinNumberFilterChanged = (value: number | string) => {
    if (this.props.toolFilter.l3Filters.length !== 2) {
      this.props.onToolFilterChanged({ ... this.props.toolFilter, l3Filters: ['', ''] });
    }
    this.props.onToolFilterChanged({ ... this.props.toolFilter, l3Filters: [value?.toString() || '', this.props.toolFilter.l3Filters[1]] });
  }

  handleL3MaxNumberFilterChanged = (value: number | string) => {
    if (this.props.toolFilter.l3Filters.length !== 2) {
      this.props.onToolFilterChanged({ ... this.props.toolFilter, l3Filters: ['', ''] });
    }
    this.props.onToolFilterChanged({ ... this.props.toolFilter, l3Filters: [this.props.toolFilter.l3Filters[0], value?.toString() || ''] });
  }

  handleDelete = () => this.props.onDelete(this.props.toolFilter.order);

  render() {
    return (
      <AnnotationToolRowView
        availableImageFilters={this.props.availableImageFilters}
        toolFilter={this.props.toolFilter}
        showDelete={this.props.showDelete}
        onDelete={this.handleDelete}
        onL1FilterChanged={this.handleL1FilterChanged}
        onL2FilterChanged={this.handleL2FilterChanged}
        onL3MultiselectFilterChanged={this.handleL3MultiselectFilterChanged}
        onL3MaxNumberFilterChanged={this.handleL3MaxNumberFilterChanged}
        onL3MinNumberFilterChanged={this.handleL3MinNumberFilterChanged}
        onL3SelectFilterChanged={this.handleL3SelectFilterChanged}
        onL3TextFilterChanged={this.handleL3TextFilterChanged}
      />
    );
  }
}
