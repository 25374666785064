import { IPagingInfoWithOrder, PaginationInfoDefault } from '../../__legacy__/models/paginationInfo.model';

import { injectable } from 'inversify';
import { observable } from 'mobx';
import { EVALUATION_STATUS, IEvaluationListDto } from './evaluations.model';
import { MODEL_VARIANTS } from '../../__legacy__/models/metrics.model';

export const EvaluationsStoreType = Symbol('EVALUATIONS_STORE');

export interface IEvaluationListItem {
  id: string;
  name: string;
  modelName: string;
  modelVariant: MODEL_VARIANTS;
  projectName: string;
  predictionDate: string;
  score: number | null;
  startingProgress: IEvaluationListDto['starting_progress'];
  modelId: string;
  isOwner: boolean;
  isTrainingOwner: boolean;
  status: EVALUATION_STATUS;
  failureReason: string | null;
}

export interface IEvaluationsPaging extends IPagingInfoWithOrder {}

export const defaultEvaluationsPaging: IEvaluationsPaging = {
  ...PaginationInfoDefault,
  orderBy: '',
  orderType: '',
};

export enum EvaluationOwnership {
  All = 10,
  My = 20,
  Others = 30,
}

export interface IEvaluationsStore {
  evaluationsList: IEvaluationListItem[];
  evaluationsPaging: IEvaluationsPaging;
  evaluationOwnership: EvaluationOwnership;
}

@injectable()
export class EvaluationsStore implements IEvaluationsStore {
  @observable
  evaluationsList: IEvaluationListItem[] = [];

  @observable
  evaluationsPaging: IEvaluationsPaging = defaultEvaluationsPaging;

  @observable
  evaluationOwnership: EvaluationOwnership = EvaluationOwnership.All;
}
