import { IOverlayLoaderStore, OverlayLoaderStoreType } from './OverlayLoader.store';
import { as, injectProps } from '../../__legacy__/helpers/react.helpers';

import React from 'react';
import { S_PrimaryButton } from '../../design/buttons/textButton/primaryButton/S_PrimaryButton';
import { S_TextButtonProps } from '../../design/buttons/textButton/S_TextButtonProps';
import { observer } from 'mobx-react';

export interface IButtonWithLoaderProps extends S_TextButtonProps {
  loaderKey?: string;
}

interface IInjectedProps {
  overlayLoaderStore: IOverlayLoaderStore;
}

@injectProps({
  overlayLoaderStore: OverlayLoaderStoreType,
})
@observer
class PrimaryButtonWithLoaderContainerPure extends React.Component<IButtonWithLoaderProps & IInjectedProps> {
  render() {
    const { loaderKey, overlayLoaderStore, ...rest } = this.props;
    const isLoading: boolean = loaderKey !== undefined && overlayLoaderStore.isSpinnerVisible(loaderKey);
    return <S_PrimaryButton {...rest} isLoading={isLoading} />;
  }
}

export const PrimaryButtonWithLoaderContainer = as<React.ComponentClass<IButtonWithLoaderProps>>(PrimaryButtonWithLoaderContainerPure);
