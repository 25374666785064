import { GTable, IGColumnProps } from '../../../../../components/table/GTable';
import { faCheckSquare, faEdit, faTrashAlt, faUser } from '@fortawesome/free-solid-svg-icons';

import { IPagingInfoWithOrder } from '../../../../../models/paginationInfo.model';
import { IStatisticsListItem } from '../projectDetailsStatistics.model';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { Loader } from '../../../../../components/Loader';
import React from 'react';
import { as } from '../../../../../helpers/react.helpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { formatTimeSpan, toLocaleDateString } from '../../../../../helpers/date.helpers';
import { withNamespaces } from 'react-i18next';

library.add(faTrashAlt, faEdit, faUser, faCheckSquare);

interface IStatisticsListProps {
  statistics: IStatisticsListItem[];
  paging: IPagingInfoWithOrder;
  showLoader: boolean;
  onOrderingChange(orderBy: string, orderType: string): void;
  onPaginationChange(pageNumber: number, pageSize: number): void;
}

@observer
class StatisticsListPure extends React.Component<IStatisticsListProps & ITranslatable> {
  renderCreatedDate = (statistic: IStatisticsListItem) => <>{toLocaleDateString(statistic.date)}</>;
  renderAnnotationDuration = (statistic: IStatisticsListItem) => <>{formatTimeSpan(statistic.annotationDuration)}</>;
  renderReviewDuration = (statistic: IStatisticsListItem) => <>{formatTimeSpan(statistic.reviewDuration)}</>;
  renderAverageObjectsPerImage = (statistic: IStatisticsListItem) => (
    <>{this.formatDouble(statistic.averageObjectsPerImage)}</>
  );

  formatDouble = (value: number) => {
    return value.toFixed(2);
  };

  get gColumns(): IGColumnProps<IStatisticsListItem>[] {
    const columns: IGColumnProps<IStatisticsListItem>[] = [
      {
        field: 'date',
        headerName: this.props.t('date'),
        renderer: this.renderCreatedDate,
        width: 110,
      },
      {
        field: 'markedObjects',
        headerName: this.props.t('marked_objects'),
        width: 150,
      },
      {
        field: 'answeredAttributes',
        headerName: this.props.t('answered_attributes'),
        width: 190,
      },
      {
        field: 'answeredQuestions',
        headerName: this.props.t('answered_questions'),
        width: 190,
      },
      {
        field: 'imagesAnnotated',
        headerName: this.props.t('images_annotated'),
        width: 170,
      },
      {
        field: 'annotationDuration',
        headerName: this.props.t('annotation_duration'),
        renderer: this.renderAnnotationDuration,
        width: 190,
      },
      {
        field: 'annotatingUsersCount',
        headerName: this.props.t('annotating_users_count'),
        width: 170,
      },
      {
        field: 'imagesReviewed',
        headerName: this.props.t('images_reviewed'),
        width: 160,
      },
      {
        field: 'reviewDuration',
        headerName: this.props.t('review_duration'),
        renderer: this.renderReviewDuration,
        width: 160,
      },
      {
        field: 'reviewersCount',
        headerName: this.props.t('reviewers_count'),
        width: 160,
      },
      {
        field: 'cumulativeAnnotatedImages',
        headerName: this.props.t('cumulative_annotated_images'),
        width: 250,
      },

      {
        field: 'cumulativeReviewedImages',
        headerName: this.props.t('cumulative_reviewed_images'),
        width: 240,
      },
      {
        field: 'averageObjectsPerImage',
        headerName: this.props.t('average_objects_per_image'),
        renderer: this.renderAverageObjectsPerImage,
        width: 240,
      },
    ];

    return columns;
  }

  onSortChanged = (orderBy: string, orderType: string) => this.props.onOrderingChange(orderBy, orderType);

  render() {
    return (
      <div className="table-container">
        <Loader isLoading={this.props.showLoader}>
          <GTable<IStatisticsListItem>
            columns={this.gColumns}
            items={this.props.statistics}
            onSortChanged={this.onSortChanged}
            paginationProps={{
              pageNumber: this.props.paging.pageNumber,
              pageSize: this.props.paging.pageSize,
              totalCount: this.props.paging.totalCount,
              onChange: this.props.onPaginationChange,
            }}
            sortingModel={
              this.props.paging.orderBy && this.props.paging.orderType
                ? [{ orderBy: this.props.paging.orderBy, orderType: this.props.paging.orderType }]
                : []
            }
            noItemsPlaceholder={this.props.t('no_statistics_have_been_generated_yet')}
          />
        </Loader>
      </div>
    );
  }
}

export const StatisticsList = as<React.ComponentClass<IStatisticsListProps>>(
  withNamespaces('common', { wait: true })(StatisticsListPure),
);
