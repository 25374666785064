import { IModelDetailsDto, IModelStatusDto } from './modelDetails.model';
import { injectable } from 'inversify';
import { observable } from 'mobx';
import { IPagingInfoWithOrder, PaginationInfoDefault } from '../../__legacy__/models/paginationInfo.model';
import { IPredictionImage } from '../../__legacy__/models/predictions.model';
import { MODEL_STATUS } from '../models/models.model';
import { Parameters } from '../../__legacy__/helpers/parameters.helpers';
import { IChartPoint, ITrainingMatrix, ITrainingMetrics, ITrainingResult, MODEL_VARIANTS } from '../../__legacy__/models/metrics.model';

export const ModelDetailsStoreType = Symbol('MODEL_DETAILS_STORE');

export interface IModelProject {
  value: string;
  label: string;
}

export interface IModelDataset {
  id: string;
  name: string;
  isSelected: boolean;
  testImagesCount: number;
  trainImagesCount: number;
  validationImagesCount: number;
  createdDate: string;
}

export interface IModelImagesPaging extends IPagingInfoWithOrder {
  fetchPredictions: boolean;
}

export const defaultModelImagesPaging: IModelImagesPaging = {
  ...PaginationInfoDefault,
  orderBy: '',
  orderType: '',
  search: '',
  fetchPredictions: false,
};

export interface IModelConfig {
  [k: string]: number | boolean | string;
}

/**
 * Place to store validation errors regarding model details, e.g. parameters, model name, description etc.
 * Allows highlighting of tabs with errors by using tab's link as a key.
 */
export interface IModelDetailsValidationErrors {
  showAll: boolean;
  // No key set -> not validated yet
  // null -> valid
  // object -> invalid
  settings: Map<
    string,
    {
      message: string;
      messageParameters: Record<string, any>;
    } | null
  >;
  header: Map<
    string,
    {
      message: string;
    } | null
  >;
  // undefined -> not validated yet
  // true/false -> invalid/valid
  datasets: boolean | undefined;
  images: boolean | undefined;
}

export type IModelDetailsStore = ModelDetailsStore;
export const MODEL_DETAILS_STORE_INITIAL_STATE: Omit<IModelDetailsStore, 'resetStore'> = {
  id: '',
  isOwner: true,
  name: '',
  description: '',
  status: MODEL_STATUS.UNKNOWN,
  startingProgress: null,
  failureReason: null,
  variant: MODEL_VARIANTS.ANOMALY_DETECTION_4,
  projectId: '',
  datasetIds: [],
  modelConfig: {},
  createdAt: '',
  projects: [],
  datasets: [],
  images: [],
  modelImagesPaging: defaultModelImagesPaging,
  params: [],
  results: [],
  matrix: [],
  metrics: {
    auc: null,
  },
  train: [],
  validation: [],
  curve: [],
  modelDetailsValidationErrors: {
    showAll: false,
    settings: new Map(),
    header: new Map(),
    datasets: undefined,
    images: undefined,
  },
};

@injectable()
export class ModelDetailsStore {
  @observable id: string;
  @observable isOwner: boolean;
  @observable name: string;
  @observable description: string;
  @observable status: MODEL_STATUS;
  @observable startingProgress: IModelStatusDto['starting_progress'];
  @observable failureReason: IModelDetailsDto['failure_reason'];
  @observable variant: MODEL_VARIANTS;
  @observable projectId: string;
  @observable datasetIds: string[];
  @observable modelConfig: IModelConfig;
  @observable createdAt: string;
  @observable projects: IModelProject[];
  @observable datasets: IModelDataset[];
  @observable images: IPredictionImage[];
  @observable modelImagesPaging: IModelImagesPaging;
  @observable params: Parameters;
  @observable metrics: ITrainingMetrics;
  @observable results: ITrainingResult[];
  @observable matrix: ITrainingMatrix[];
  @observable train: IChartPoint[];
  @observable validation: IChartPoint[];
  @observable curve: IChartPoint[];
  @observable modelDetailsValidationErrors: IModelDetailsValidationErrors;

  constructor() {
    this.id = MODEL_DETAILS_STORE_INITIAL_STATE.id;
    this.name = MODEL_DETAILS_STORE_INITIAL_STATE.name;
    this.description = MODEL_DETAILS_STORE_INITIAL_STATE.description;
    this.isOwner = MODEL_DETAILS_STORE_INITIAL_STATE.isOwner;
    this.status = MODEL_DETAILS_STORE_INITIAL_STATE.status;
    this.startingProgress = MODEL_DETAILS_STORE_INITIAL_STATE.startingProgress;
    this.failureReason = MODEL_DETAILS_STORE_INITIAL_STATE.failureReason;
    this.variant = MODEL_DETAILS_STORE_INITIAL_STATE.variant;
    this.projectId = MODEL_DETAILS_STORE_INITIAL_STATE.projectId;
    this.datasetIds = MODEL_DETAILS_STORE_INITIAL_STATE.datasetIds;
    this.modelConfig = MODEL_DETAILS_STORE_INITIAL_STATE.modelConfig;
    this.createdAt = MODEL_DETAILS_STORE_INITIAL_STATE.createdAt;
    this.projects = MODEL_DETAILS_STORE_INITIAL_STATE.projects;
    this.datasets = MODEL_DETAILS_STORE_INITIAL_STATE.datasets;
    this.images = MODEL_DETAILS_STORE_INITIAL_STATE.images;
    this.modelImagesPaging = MODEL_DETAILS_STORE_INITIAL_STATE.modelImagesPaging;
    this.params = MODEL_DETAILS_STORE_INITIAL_STATE.params;
    this.metrics = MODEL_DETAILS_STORE_INITIAL_STATE.metrics;
    this.results = MODEL_DETAILS_STORE_INITIAL_STATE.results;
    this.matrix = MODEL_DETAILS_STORE_INITIAL_STATE.matrix;
    this.train = MODEL_DETAILS_STORE_INITIAL_STATE.train;
    this.validation = MODEL_DETAILS_STORE_INITIAL_STATE.validation;
    this.curve = MODEL_DETAILS_STORE_INITIAL_STATE.curve;
    this.modelDetailsValidationErrors = MODEL_DETAILS_STORE_INITIAL_STATE.modelDetailsValidationErrors;
  }

  resetStore(isDraft: boolean) {
    Object.assign<IModelDetailsStore, Omit<IModelDetailsStore, 'resetStore'>>(this, MODEL_DETAILS_STORE_INITIAL_STATE);
    this.status = isDraft ? MODEL_STATUS.DRAFT : MODEL_DETAILS_STORE_INITIAL_STATE.status;
    this.name = isDraft ? `Model ${new Date().toLocaleString()}` : '';
  }
}
