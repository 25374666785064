import { StickerEvent } from "../../../../../__legacy__/services/eventBus.service";

export const ProjectExportStartedEventType = Symbol('PROJECT_EXPORT_STARTED_EVENT');

export class ProjectExportStartedEvent extends StickerEvent {
  constructor(public readonly projectId: string) {
    super();
  }

  get type(): Symbol {
    return ProjectExportStartedEventType;
  }
}
