import React from 'react';
import { as } from '../../helpers/react.helpers';

export const ValidSaveIcon = as<React.ComponentClass>(() => (
  <svg aria-hidden="true" data-prefix="fas" data-icon="save" className="svg-inline--fa fa-save fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="-5 25 463 463">
    <path
      fill="currentColor"
      d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0
        48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM224 416c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64s64 28.654 64
        64c0 35.346-28.654 64-64 64zm96-304.52V212c0 6.627-5.373 12-12 12H76c-6.627 0-12-5.373-12-12V108c0-6.627 5.373-12 12-12h228.52c3.183
        0 6.235 1.264 8.485 3.515l3.48 3.48A11.996 11.996 0 0 1 320 111.48z"
    />
  </svg>
));
