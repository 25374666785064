import './ProjectStatsFilters.scss';

import React from 'react';
import { TimePeriodSelectorContainer } from './timePeriodSelector/TimePeriodSelector.container';
import { WorkerSelectorContainer } from './workerSelector/WorkerSelector.container';
import { WorkspaceTimezoneContainer } from './workspaceTimezone/WorkspaceTimezone.container';

export const ProjectStatsFilters: React.FC = () => (
  <div className="project-stats-filters">
    <WorkerSelectorContainer />
    <TimePeriodSelectorContainer />
    <WorkspaceTimezoneContainer />
  </div>
);
