import * as React from 'react';

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { Datasets, IDatasetsContext } from '../datasets.context';
import { DatasetsPermissionsType, IDatasetsPermissions } from '../datasets.permissions';
import { IImagesFromUrlsService, ImagesFromUrlsServiceType } from '../imagesFromUrlsUpload.service';
import { IImagesUploaderService, ImagesUploaderServiceType } from '../imagesUpload.service';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { as, injectProps } from '../../../helpers/react.helpers';

import { Col } from 'reactstrap';
import { DatasetDetails } from '../datasetDetails.context';
import { DatasetDetailsActions } from '../components/DatasetDetailsActions';
import { DatasetDetailsInformation } from '../components/DatasetDetailsInformation';
import { DatasetStatus } from '../datasetStatus.model';
import { DeleteDatasetConfirmationModal } from '../components/DeleteDatasetConfirmationModal';
import { Home } from '../../../routes/config/Home';
import { Observer } from 'mobx-react';
import { PublishDatasetConfirmationModal } from '../components/PublishDatasetConfirmationModal';
import { StickerError } from '../../../models/error.model';
import autobind from 'autobind-decorator';
import { runInAction } from 'mobx';

export interface IInjectedProps {
  imagesFromUrlsService: IImagesFromUrlsService;
  imagesUploaderService: IImagesUploaderService;
  datasetsPermissions: IDatasetsPermissions;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

interface IState extends ILoaderState {
  showDatasetDecryptionScriptSampleModal: boolean;
  showDatasetDeleteConfirmationModal: boolean;
  showPublishDatasetConfirmationModal: boolean;
}

@injectProps({
  imagesFromUrlsService: ImagesFromUrlsServiceType,
  imagesUploaderService: ImagesUploaderServiceType,
  datasetsPermissions: DatasetsPermissionsType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
class DatasetDetailsOverviewTabContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  static contextType = DatasetDetails;
  declare context: React.ContextType<typeof DatasetDetails>;

  constructor(props: IInjectedProps) {
    super(props);

    this.state = {
      showDatasetDecryptionScriptSampleModal: false,
      showDatasetDeleteConfirmationModal: false,
      showPublishDatasetConfirmationModal: false,
      isLoading: false,
    };
  }

  handleDeleteDatasetAsync = (datasets: IDatasetsContext) => async () => {
    const datasetId = this.context.route.match.params.datasetId;
    datasets.service.markStale();
    await this.withLoaderAsync(() => this.context.service.deleteDatasetAsync(datasetId), 'delete');
    await Promise.all([
      this.props.imagesFromUrlsService.cancelUploadAsync(datasetId),
      this.props.imagesUploaderService.cancelUploadAsync(datasetId),
      datasets.service.loadUserDatasetAsync(),
    ]);
    this.setState({ showDatasetDeleteConfirmationModal: false });
    this.context.route.history.push(Home.Datasets.List.withParams({ workspaceId: this.props.currentWorkspaceStore.currentWorkspace!.id}));
  };

  handlePublishAsync = (datasets: IDatasetsContext) => async () => {
    const datasetId = this.context.route.match.params.datasetId;
    datasets.service.markStale();
    const result = await this.withLoaderAsync<void | StickerError>(async () => await this.context.service.publishDatasetAsync(datasetId), 'publish');

    if (result instanceof StickerError && result.isBadRequestWithCode(['CAN_NOT_PUBLISH_LIMIT_EXCEEDED'])) {
      const availableImageCount = (result.apiErrorResponse?.errorContext.availableImageCount || 0) as number;
      const ownerPlan = (result.apiErrorResponse?.errorContext.ownerPlan || '') as string;
      runInAction(() => {
        this.context.store.isImageCountExceeded = true;
        this.context.store.availableImageCount = availableImageCount;
        this.context.store.ownerPlan = ownerPlan;
      });
    }

    this.setState({ showPublishDatasetConfirmationModal: false });

    datasets.store.refreshFromDetails(this.context.store.details);
  };

  @autobind
  toggleDatasetDecryptionScriptSampleModal() {
    this.setState((prevState: IState) => ({
      ...prevState,
      showDatasetDecryptionScriptSampleModal: !prevState.showDatasetDecryptionScriptSampleModal,
    }));
  }

  @autobind
  toggleDatasetDeleteConfirmationModal() {
    this.setState((prevState: IState) => ({
      ...prevState,
      showDatasetDeleteConfirmationModal: !prevState.showDatasetDeleteConfirmationModal,
    }));
  }

  @autobind
  togglePublishDatasetConfirmationModal() {
    this.setState((prevState: IState) => ({
      ...prevState,
      showPublishDatasetConfirmationModal: !prevState.showPublishDatasetConfirmationModal,
    }));
  }

  render() {
    const { details, isImageCountExceeded, availableImageCount, ownerPlan } = this.context.store;
    return (
      <Datasets.Consumer>
        {datasets => (
          <Observer>
            {() => (
              <>
                <Col xs="3">
                  <DatasetDetailsInformation details={details} isLoading={this.state.isLoading} canSeeCreatedBy={this.props.datasetsPermissions.canSeeDatasetCreatedBy()} />
                </Col>
                <Col xs="2">
                  <DatasetDetailsActions
                    isImageCountExceeded={isImageCountExceeded}
                    availableImageCount={availableImageCount}
                    ownerPlan={ownerPlan}
                    details={details}
                    isDatasetPublishable={details.status === DatasetStatus.DRAFT && details.imagesCount > 0 && !isImageCountExceeded}
                    isDeleteDisabled={details.projectsCount > 0}
                    downloadLink={this.context.service.downloadDatasetUrl(this.context.route.match.params.datasetId)}
                    onShowDatasetDeleteConfirmationModal={this.toggleDatasetDeleteConfirmationModal}
                    onShowPublishDatasetConfirmationModal={this.togglePublishDatasetConfirmationModal}
                    canDeleteDataset={this.props.datasetsPermissions.canDeleteDataset(details.status, details.createdById)}
                    canDownloadDataset={this.props.datasetsPermissions.canDownloadDataset()}
                    canPublishDataset={this.props.datasetsPermissions.canPublishDataset()}
                  />
                  <DeleteDatasetConfirmationModal
                    onDeleteDatasetCancel={this.toggleDatasetDeleteConfirmationModal}
                    onDatasetDeleteConfirm={this.handleDeleteDatasetAsync(datasets)}
                    datasetDeleteConfirmationText={details.name}
                    showDatasetDeleteConfirmationModal={this.state.showDatasetDeleteConfirmationModal}
                  />
                  <PublishDatasetConfirmationModal
                    onPublishDatasetCancel={this.togglePublishDatasetConfirmationModal}
                    showPublishDatasetConfirmationModal={this.state.showPublishDatasetConfirmationModal}
                    onDatasetPublishConfirm={this.handlePublishAsync(datasets)}
                  />
                </Col>
              </>
            )}
          </Observer>
        )}
      </Datasets.Consumer>
    );
  }
}

export const DatasetDetailsOverviewTabContainer = as<React.ComponentClass>(DatasetDetailsOverviewTabContainerPure);
