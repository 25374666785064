import React from 'react';
import { S_ActionSelect } from '../../../../../design/actions/S_ActionSelect';

interface Props {
  workspaceId: string;
  isLocked: boolean;
  onClick: (workspaceId: string) => void;
}

export const CurrentWorkspaceSelectAction: React.FC<Props> = props => {
  const handleClick = () => props.onClick(props.workspaceId);
  return <S_ActionSelect onClick={handleClick} disabled={props.isLocked} />;
};
