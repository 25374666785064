import { IAnnotationType, IQuestion, IToolAffect, IToolUsage } from './projectDetailsTools.models';
import { action, observable } from 'mobx';

import { ImageScopeName } from '../../../annotation/question.model';
import { InputStatus } from '../../../../models/error.model';
import { ProjectStatus } from '../../../projects/projects.model';
import { injectable } from 'inversify';
import { reorderArray } from '../../../../helpers/array.helpers';

export const ProjectDetailsToolsStoreType = Symbol('PROJECT_DETAILS_TOOLS_STORE');
export const ProjectDetailsToolsStoreSetterType = Symbol('PROJECT_DETAILS_TOOLS_STORE_SETTER');

export interface IProjectDetailsToolsStore {
  readonly id: string;
  readonly status: ProjectStatus;
  readonly annotationTypes: IAnnotationType[];
  readonly questions: IQuestion[];
  readonly toolsUsage: IToolUsage | undefined;
  readonly toolsStatus: InputStatus;
  readonly wasValidated: boolean;
  readonly toolAffect: IToolAffect | undefined;
}

export interface IProjectDetailsToolsStoreSetter extends IProjectDetailsToolsStore {
  setRetrievedData(id: string, status: ProjectStatus, annotationTypes: IAnnotationType[], questions: IQuestion[]): void;
  setToolUsage(toolsUsage: IToolUsage): void;
  setValidationStatus(toolsStatus: InputStatus): void;
  reorderQuestions(questionId: string, index: number, isAttribute: boolean): void;
  reorderAnnotationTypes(annotationTypeId: string, index: number): void;
  resetStore(): void;
  setToolAffect(toolsAffect: IToolAffect): void;
}

@injectable()
export class ProjectDetailsToolsStore implements IProjectDetailsToolsStoreSetter {
  @observable
  id: string = '';

  @observable
  status: ProjectStatus = ProjectStatus.Draft;

  @observable
  annotationTypes: IAnnotationType[] = [];

  @observable
  questions: IQuestion[] = [];

  @observable
  toolsUsage: IToolUsage | undefined = undefined;

  @observable
  toolsStatus: InputStatus = InputStatus.empty();

  @observable
  wasValidated: boolean = false;

  @observable
  toolAffect: IToolAffect | undefined = undefined;

  @action
  setRetrievedData(id: string, status: ProjectStatus, annotationTypes: IAnnotationType[], questions: IQuestion[]): void {
    this.id = id;
    this.status = status;
    this.annotationTypes = annotationTypes;
    this.questions = questions;
  }

  @action
  setToolUsage(toolsUsage: IToolUsage): void {
    this.toolsUsage = toolsUsage;
  }

  @action
  reorderAnnotationTypes(annotationTypeId: string, index: number): void {
    const order = this.annotationTypes[index].order;

    this.annotationTypes = reorderArray(this.annotationTypes, annotationTypeId, order);
  }

  @action
  reorderQuestions(questionId: string, index: number, isAttribute: boolean): void {
    const toReorder = this.questions.filter(q => (isAttribute ? !q.scopes.includes(ImageScopeName) : q.scopes.includes(ImageScopeName))).slice();
    const others = this.questions.filter(q => (isAttribute ? q.scopes.includes(ImageScopeName) : !q.scopes.includes(ImageScopeName))).slice();

    const order = toReorder[index].order;

    const ordered = reorderArray(toReorder, questionId, order);

    this.questions = [...ordered, ...others];
  }

  @action
  setValidationStatus(toolsStatus: InputStatus): void {
    this.toolsStatus = toolsStatus;
    this.wasValidated = !toolsStatus.isEmpty;
  }

  @action
  resetStore(): void {
    this.id = '';
    this.status = ProjectStatus.Draft;
    this.annotationTypes = [];
    this.questions = [];
    this.toolsUsage = undefined;
    this.toolsStatus = InputStatus.empty();
    this.wasValidated = false;
  }

  @action
  setToolAffect(toolsAffect: IToolAffect): void {
    this.toolAffect = toolsAffect;
  }
}
