import * as React from 'react';

import { IImagesFromUrlsService, ImagesFromUrlsServiceType } from '../modules/datesets/imagesFromUrlsUpload.service';
import { IImagesUploaderService, ImagesUploaderServiceType } from '../modules/datesets/imagesUpload.service';
import { as, injectProps } from '../helpers/react.helpers';

import autobind from 'autobind-decorator';
import { computed } from 'mobx';
import { handleClickAndPassData } from '../helpers/formHelpers';
import { observer } from 'mobx-react';

export interface IDatasetUploadProgressData {
  uploadedCount: number;
  uploadedSize: number;
  allCount: number;
  dataSetId: string;
  dataSetName: string;
  hasAnyError: boolean;
  onShowConfirmationModal(): void;
}

export interface IDatasetsUploadDropdownProps {
  queue: IDatasetUploadProgressData[];
  showModal: boolean;
  onConfirmCancel(): void;
  onResume(): void;
}

interface IInjectedProps {
  imageUploaderService: IImagesUploaderService;
  imagesFromUrlsService: IImagesFromUrlsService;
}

interface IState {
  showModal: boolean;
  datasetId?: string;
  cancelUploadAsync?(id: string): Promise<void>;
  restoreUpload?(id: string): void;
}

@injectProps({
  imageUploaderService: ImagesUploaderServiceType,
  imagesFromUrlsService: ImagesFromUrlsServiceType,
})
@observer
class DatasetsUploadDropdownContainerPure extends React.Component<IInjectedProps, IState> {
  state: IState = {
    showModal: false,
  };

  @computed
  get progressData(): IDatasetUploadProgressData[] {
    const imageUploaderProgressData: IDatasetUploadProgressData[] = this.props.imageUploaderService.datasetsQueue.map(
      x =>
      ({
        uploadedCount: x.uploadedItemsCount,
        uploadedSize: x.uploadedItemsSize,
        allCount: x.allItemsCount,
        dataSetId: x.datasetId,
        dataSetName: x.datasetName,
        hasAnyError: x.hasAnyError,
        onShowConfirmationModal: handleClickAndPassData(this.showConfirmationModalForFiles)(x.datasetId),
      } as IDatasetUploadProgressData),
    );

    const imageFromUrlProgressData: IDatasetUploadProgressData[] = this.props.imagesFromUrlsService.datasetsQueue.map(
      x =>
      ({
        uploadedCount: x.uploadedItemsCount,
        uploadedSize: x.uploadedItemsSize,
        allCount: x.allItemsCount,
        dataSetId: x.datasetId,
        dataSetName: x.datasetName,
        hasAnyError: x.hasAnyError,
        onShowConfirmationModal: handleClickAndPassData(this.showConfirmationModalForUrls)(x.datasetId),
      } as IDatasetUploadProgressData),
    );

    return imageUploaderProgressData.concat(imageFromUrlProgressData);
  }

  @autobind
  showConfirmationModalForUrls(id: string) {
    this.props.imagesFromUrlsService.pauseUpload(id);
    this.setState({
      showModal: true,
      datasetId: id,
      restoreUpload: this.props.imagesFromUrlsService.restoreUploadAsync,
      cancelUploadAsync: this.props.imagesFromUrlsService.cancelUploadAsync,
    });
  }

  @autobind
  showConfirmationModalForFiles(id: string) {
    this.props.imageUploaderService.pauseUpload(id);
    this.setState({
      showModal: true,
      datasetId: id,
      restoreUpload: this.props.imageUploaderService.restoreUploadAsync,
      cancelUploadAsync: this.props.imageUploaderService.cancelUploadAsync,
    });
  }

  @autobind
  async handleConfirmCancel() {
    await this.state.cancelUploadAsync!(this.state.datasetId!);
    this.hideModal();
  }

  @autobind
  handleResome() {
    this.state.restoreUpload!(this.state.datasetId!);
    this.hideModal();
  }

  hideModal(): any {
    this.setState({
      showModal: false,
      datasetId: undefined,
      restoreUpload: undefined,
      cancelUploadAsync: undefined,
    });
  }

  render() {
    return React.Children.map(this.props.children, (child: any) =>
      React.cloneElement(child, {
        queue: this.progressData,
        showModal: this.state.showModal,
        onResume: this.handleResome,
        onConfirmCancel: this.handleConfirmCancel,
      } as IDatasetsUploadDropdownProps),
    );
  }
}

export const DatasetsUploadDropdownContainer = as<React.ComponentClass>(DatasetsUploadDropdownContainerPure);
