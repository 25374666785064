export class FieldStatus {
  constructor(public isValid: boolean | undefined = undefined, public errors: string[] = []) { }

  static empty(): FieldStatus {
    return new FieldStatus(undefined, []);
  }

  static valid(): FieldStatus {
    return new FieldStatus(true, []);
  }

  static invalid(): FieldStatus {
    return new FieldStatus(false, []);
  }

  static buildFrom(errors: string[]) {
    return new FieldStatus(errors.length === 0, errors);
  }

  addErrorCode(code: string) {
    this.errors.push(code);
    this.isValid = false;
  }

  get isEmpty(): boolean {
    return this.isValid === undefined;
  }
}