import './S_Pagination.scss';

import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { first, last, range } from 'lodash/fp';

import React from 'react';
import { S_PaginationFirstPageButton } from './paginationControls/paginationButtons/S_PaginationFirstPageButton';
import { S_PaginationGoTo } from './paginationGoto/S_PaginationGoto';
import { S_PaginationLastPageButton } from './paginationControls/paginationButtons/S_PaginationLastPageButton';
import { S_PaginationPageButton } from './paginationControls/paginationButtons/S_PaginationPageButton';
import { S_PaginationPageSize } from './paginationPageSize/S_PaginationPageSize';
import { S_PaginationResults } from './paginationResults/S_PaginationResults';

export interface S_PaginationProps {
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  onChange(pageNumber: number, pageSize: number): void;
}

export const S_Pagination: React.FC<S_PaginationProps> = ({ pageNumber, pageSize, totalCount, onChange }) => {
  const firstPage: number = 1;
  const pageRangeOffset: number = 3;

  // TODO: S20-2632 This is needed because there are wrong usages of this control.
  const getNormalizedFirstPage = () => Math.max(pageNumber, firstPage);
  const getLastPage = () => Math.ceil(totalCount / pageSize);
  const getPageRange = () => {
    const firstVisiblePage = Math.max(firstPage, pageNumber - pageRangeOffset);
    const lastVisiblePage = Math.min(getLastPage(), pageNumber + pageRangeOffset);
    return range(firstVisiblePage, lastVisiblePage + 1);
  };

  const navigate = (page: number) => {
    if (pageNumber !== page) {
      onChange(page, pageSize);
    }
  };

  const handleClick = (page: number) => navigate(page);
  const handleNextClick = () => navigate(getNormalizedFirstPage() + 1);
  const handlePreviousClick = () => navigate(getNormalizedFirstPage() - 1);
  const handleFirstClick = () => navigate(firstPage);
  const handleLastClick = () => navigate(getLastPage());
  const handleGoToChange = (page: number) => navigate(page);
  const handlePageSizeChange = (newPageSize: number) => {
    if (newPageSize !== pageSize) {
      let newPageNumber = 1;
      if (pageNumber !== 1) {
        const lastVisibleElementIndex = Math.min(pageNumber * pageSize, totalCount);
        newPageNumber = Math.ceil(lastVisibleElementIndex / newPageSize);
      }
      onChange(newPageNumber, newPageSize);
    }
  };

  if (totalCount === 0) return <></>;

  const pageCount = getLastPage();
  const pageRange = getPageRange();

  const disableForwardNavigation = getNormalizedFirstPage() === pageCount;
  const disableBackwardNavigation = getNormalizedFirstPage() === firstPage;

  return (
    <>
      <Pagination id="sticker-pagination">
        <S_PaginationResults totalCount={totalCount} pageNumber={getNormalizedFirstPage()} pageSize={pageSize} />
        <S_PaginationPageSize onChange={handlePageSizeChange} pageSize={pageSize} />

        <PaginationItem disabled={disableBackwardNavigation}>
          <S_PaginationFirstPageButton onClick={handleFirstClick} />
        </PaginationItem>
        <PaginationItem disabled={disableBackwardNavigation}>
          <PaginationLink previous onClick={handlePreviousClick} />
        </PaginationItem>

        {first(pageRange) !== firstPage && (
          <PaginationItem disabled>
            <PaginationLink>...</PaginationLink>
          </PaginationItem>
        )}
        {pageRange.map(p => {
          return (
            <PaginationItem key={p} active={getNormalizedFirstPage() === p}>
              <S_PaginationPageButton page={p} onClick={handleClick} />
            </PaginationItem>
          );
        })}
        {last(pageRange) !== pageCount && (
          <PaginationItem disabled>
            <PaginationLink>...</PaginationLink>
          </PaginationItem>
        )}

        <PaginationItem disabled={disableForwardNavigation}>
          <PaginationLink next onClick={handleNextClick} />
        </PaginationItem>
        <PaginationItem disabled={disableForwardNavigation}>
          <S_PaginationLastPageButton onClick={handleLastClick} />
        </PaginationItem>

        <S_PaginationGoTo onChange={handleGoToChange} lastPage={pageCount} />
      </Pagination>
    </>
  );
};
