import { injectable, inject } from 'inversify';
import { ProjectsStoreType, IProjectsStore, ProjectOwnership } from './projects.store';
import { action } from 'mobx';
import { StickerError, ForbiddenError } from '../../models/error.model';
import { NotificationsServiceType, INotificationsService } from '../notifications/services/notifications.service';
import { NotificationLevel, ToastNotification } from '../notifications/models/notification.model';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../modules/common/OverlayLoader.store';
import { IExportModel, ImportStage, IProjectImportReport } from './projects.model';
import { IProjectsApiService, ProjectsApiServiceType } from './services/projectApi.service';
import { IProjectsPermissions, ProjectsPermissionsType } from './projects.permissions';
import { AuthStoreType, IAuthStore } from '../auth/auth.store';
import { ApiServiceType } from '../../services/api.service';
import { IApiService } from '../../services/api.service.base';
import { ICurrentWorkspaceStore, CurrentWorkspaceStoreType } from '../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';

export const ProjectsServiceType = Symbol('PROJECTS_SERVICE');

export interface IProjectsService {
  store: IProjectsStore;
  changeOrder(orderBy: string | undefined, orderType: string | undefined): Promise<void>;
  changeOwnershipFilter(ownership: ProjectOwnership): Promise<void>;
  changePagination(pageNumber: number, pageSize: number): Promise<void>;
  deleteProjectAsync(projectId: string, deleteDatasets: boolean): any;
  failProjectImportAsync(errorCodes: string[]): Promise<void>;
  finishProjectImportAsync(report: IProjectImportReport): Promise<void>;
  getProjectExclusiveDatasetsAsync(projectId: string): Promise<string[]>;
  getProjectExportUrl(model: IExportModel): string;
  importProjectAsync(projectDraftId: string, importAnnotations: boolean, importImageSets: boolean, file: File): Promise<boolean>;
  refreshAsync(): Promise<void | StickerError>;
}

@injectable()
export class ProjectsService implements IProjectsService {
  constructor(
    @inject(ProjectsStoreType) public readonly store: IProjectsStore,
    @inject(NotificationsServiceType) private readonly notificationsService: INotificationsService,
    @inject(OverlayLoaderStoreType) private readonly overlayLoaderStore: IOverlayLoaderStore,
    @inject(ProjectsApiServiceType) private readonly projectsApiService: IProjectsApiService,
    @inject(ProjectsPermissionsType) private readonly projectsPermissions: IProjectsPermissions,
    @inject(AuthStoreType) private readonly authStore: IAuthStore,
    @inject(ApiServiceType) private readonly apiService: IApiService,
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore,
  ) {}

  @action
  async changeOwnershipFilter(ownership: ProjectOwnership): Promise<void> {
    if (ownership !== this.store.projectOwnership) {
      this.store.projectOwnership = ownership;
      this.store.projectsPaging.pageNumber = 1;
      await this.refreshAsync();
    }
  }

  @action
  async changeOrder(orderBy: string | undefined, orderType: string | undefined): Promise<void> {
    this.store.projectsPaging.orderBy = orderBy || '';
    this.store.projectsPaging.orderType = orderType || '';
    await this.refreshAsync();
  }

  @action
  async changePagination(pageNumber: number, pageSize: number): Promise<void> {
    this.store.projectsPaging.pageNumber = pageNumber;
    this.store.projectsPaging.pageSize = pageSize;
    await this.refreshAsync();
  }

  @action
  async refreshAsync() {
    const { currentWorkspace } = this.currentWorkspaceStore;
    if (!currentWorkspace) return;
    this.overlayLoaderStore.enableLoader('project-list');
    await this.getProjectsAsync(currentWorkspace.id);
    this.overlayLoaderStore.disableLoader('project-list');
  }

  @action
  async getProjectsAsync(workspaceId: string) {
    const { pageNumber, pageSize } = this.store.projectsPaging;
    const { orderBy, orderType } = this.store.projectsPaging;

    const request = {
      workspaceId,
      pageNumber,
      pageSize,
      orderBy,
      orderType,
      ownership: this.store.projectOwnership,
    };

    const result = await this.projectsApiService.getProjectsAsync(request);

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'project:get_projects_failed');
      throw result;
    }

    if (result.pagesCount < result.pageNumber) {
      this.store.projectsPaging.pageNumber = result.pagesCount;
      await this.getProjectsAsync(workspaceId);
    } else {
      this.store.projectsList = result.data.map(x => ({
        ...x,
        canAnnotate: this.projectsPermissions.canAnnotate(x.role),
        canDelete: this.projectsPermissions.canDelete(),
        canEdit: this.projectsPermissions.canEdit(),
        canReview: this.projectsPermissions.canReview(x.role),
        canView: this.projectsPermissions.canView(),
        canExport: this.projectsPermissions.canExport(),
      }));

      this.store.projectsPaging = {
        ...this.store.projectsPaging,
        orderBy,
        orderType,
        pageNumber: result.pageNumber,
        pagesCount: result.pagesCount,
        totalCount: result.totalCount,
      };
    }
  }

  async getProjectExclusiveDatasetsAsync(projectId: string): Promise<string[]> {
    const result = await this.projectsApiService.getProjectExclusiveDatasetsAsync({ projectId });
    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'project:get_exclusive_datasets_failed');
      throw result;
    }

    return result;
  }

  async deleteProjectAsync(projectId: string, deleteDatasets: boolean) {
    const result = await this.projectsApiService.deleteProjectAsync({
      projectId,
      deleteDatasets,
    });

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'project:project_deleting_failed');
      throw result;
    }

    await this.refreshAsync();
  }

  @action
  async importProjectAsync(projectId: string, importAnnotations: boolean, importImageSets: boolean, file: File): Promise<boolean> {
    const { currentWorkspace } = this.currentWorkspaceStore;
    if (!currentWorkspace) return false;

    const result = await this.projectsApiService.importProjectAsync({
      projectId,
      importAnnotations,
      importImageSets,
      file,
      workspaceId: currentWorkspace.id,
    });

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'project:project_import_failed');
      return false;
    }
    return true;
  }

  @action
  async finishProjectImportAsync(report: IProjectImportReport): Promise<void> {
    this.store.importStage = ImportStage.FINISHED;
    this.store.importReport = report;
  }

  @action
  async failProjectImportAsync(errorCodes: string[]): Promise<void> {
    this.store.importStage = ImportStage.ERROR;
    this.store.importErrorCodes = errorCodes;
  }

  notifyAboutApiError(result: any, errorCode: string, dontShowBadRequest: boolean = false) {
    if (result instanceof StickerError && !(result instanceof ForbiddenError) && (!dontShowBadRequest || !result.isBadRequest())) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, errorCode));
    }
  }

  getProjectExportUrl(model: IExportModel): string {
    const url = `/export?ProjectId=${model.projectId}&dataFilterType=${model.dataFilter}&exportOutputType=${model.fileFormat}&access_token=${this.authStore.token}`;

    return this.apiService.getUrl(url);
  }
}
