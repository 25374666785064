import './MarkingToolIcon.scss';

import { faBullseye, faDrawPolygon, faLongArrowAltRight, faPaintBrush, faSlash, faVectorSquare } from '@fortawesome/free-solid-svg-icons';

import { AnnotationToolType } from '../../../annotationTypes/models/annotationToolType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const MarkingToolIcon: React.FC<{ toolType: AnnotationToolType; color?: string }> = ({ toolType, color }) => (
  <>
    {toolType === AnnotationToolType.POINT && <FontAwesomeIcon className="marking-tool-icon" icon={faBullseye} style={{ color: `${color}` }} />}
    {toolType === AnnotationToolType.RECTANGLE && <FontAwesomeIcon className="marking-tool-icon" icon={faVectorSquare} style={{ color }} />}
    {toolType === AnnotationToolType.VECTOR && <FontAwesomeIcon className="marking-tool-icon" icon={faLongArrowAltRight} style={{ color }} />}
    {toolType === AnnotationToolType.POLYLINE && <FontAwesomeIcon className="marking-tool-icon" icon={faSlash} style={{ color }} />}
    {toolType === AnnotationToolType.POLYGON && <FontAwesomeIcon className="marking-tool-icon" icon={faDrawPolygon} style={{ color }} />}
    {toolType === AnnotationToolType.ROTATEDRECTANGLE && (
      <FontAwesomeIcon className="marking-tool-icon rotated-rectangle" icon={faVectorSquare} style={{ color }} />
    )}
    {toolType === AnnotationToolType.BRUSH && <FontAwesomeIcon className="marking-tool-icon" icon={faPaintBrush} style={{ color }} />}
  </>
);
