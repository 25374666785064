import { ContainerModule, interfaces } from 'inversify';
import { EditMarkingToolApiService, EditMarkingToolApiServiceImpl, EditMarkingToolApiServiceType } from './services/EditMarkingToolApi.service';
import { EditMarkingToolBl, EditMarkingToolBlImpl, EditMarkingToolBlType } from './EditMarkingTool.bl';
import { EditMarkingToolPermissions, EditMarkingToolPermissionsImpl, EditMarkingToolPermissionsType } from './EditMarkingTool.permissions';
import { EditMarkingToolStore, EditMarkingToolStoreImpl, EditMarkingToolStoreSetter, EditMarkingToolStoreSetterType, EditMarkingToolStoreType } from './EditMarkingTool.store';
import { GetMarkingToolService, GetMarkingToolServiceImpl, GetMarkingToolServiceType } from './services/GetMarkingTool.service';

export const editMarkingToolModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<EditMarkingToolStore>(EditMarkingToolStoreType).to(EditMarkingToolStoreImpl).inSingletonScope();
  bind<EditMarkingToolStoreSetter>(EditMarkingToolStoreSetterType).toService(EditMarkingToolStoreType);

  bind<EditMarkingToolBl>(EditMarkingToolBlType).to(EditMarkingToolBlImpl).inSingletonScope();

  bind<EditMarkingToolPermissions>(EditMarkingToolPermissionsType).to(EditMarkingToolPermissionsImpl).inSingletonScope();

  bind<EditMarkingToolApiService>(EditMarkingToolApiServiceType).to(EditMarkingToolApiServiceImpl).inSingletonScope();
  bind<GetMarkingToolService>(GetMarkingToolServiceType).to(GetMarkingToolServiceImpl).inSingletonScope();
});
