import { action, computed, observable } from 'mobx';

import { injectable } from 'inversify';

export const AuthStoreType = Symbol('AUTH_STORE');

export const AuthStoreSetterType = Symbol('AUTH_STORE_SETTER');

export interface IAuthStore {
  readonly token: string;
  readonly isAuthenticated: boolean;
  readonly routeReferrer: string;
  readonly lockoutDate?: string;
  readonly versionChanged: boolean;
}

export interface IAuthStoreSetter extends IAuthStore {
  updateToken(token: string): void;
  clearToken(): void;
  setVersionChanged(changed?: boolean): void;
  setRouteReferrer(routeReferrer: string): void;
  setLockoutDate(lockoutDate: string | undefined): void;
}

@injectable()
export class AuthStore implements IAuthStoreSetter {

  @observable
  token: string = '';

  @observable
  routeReferrer: string = '';

  @observable
  lockoutDate?: string = undefined;

  @observable
  versionChanged: boolean = false;

  @action
  updateToken(token: string) {
    this.token = token;
  }

  @action
  clearToken() {
    this.token = '';
  }

  @action
  setVersionChanged(changed: boolean = true): void {
    this.versionChanged = changed;
  }

  @action
  setRouteReferrer(routeReferrer: string) {
    this.routeReferrer = routeReferrer;
  }

  @action
  setLockoutDate(lockoutDate: string) {
    this.lockoutDate = lockoutDate;
  }

  @computed
  get isAuthenticated(): boolean {
    return this.token !== '';
  }
}
