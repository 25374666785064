import { CircleMarker, CircleMarkerProps } from 'react-leaflet';

import { ISegmentationPolygonProps } from '../SegmentationLayerFeatures';
import React from 'react';

interface IProps {
  isActive: boolean;
}

export class PointFeature extends React.Component<ISegmentationPolygonProps & CircleMarkerProps & IProps> {
  handleRef = (element: any) => {
    if (this.props.isActive && element) {
      element.leafletElement.enableEdit();
    }
  }

  render() {
    return <CircleMarker<ISegmentationPolygonProps & CircleMarkerProps> {...this.props} ref={this.handleRef} />;
  }
}
