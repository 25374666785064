import * as React from 'react';

import { Card, CardBody, CardTitle } from 'reactstrap';

import { IDatasetDetails } from '../datasetsDetails.store';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { Loader } from '../../../components/Loader';
import { as } from '../../../helpers/react.helpers';
import { observer } from 'mobx-react';
import { toLocaleDateTimeString } from '../../../helpers/date.helpers';
import { withNamespaces } from 'react-i18next';

export interface IDatasetDetailsInformationProps {
  details: IDatasetDetails;
  isLoading: boolean;
  canSeeCreatedBy: boolean;
}

@observer
class DatasetDetailsInformationPure extends React.Component<IDatasetDetailsInformationProps & ITranslatable> {
  render() {
    const { details, isLoading } = this.props;
    return (
      <Card className="flex-fill card-with-list no-shadow dataset-details">
        <CardBody>
          <div className="info">
            <CardTitle tag="h5" className="name">{this.props.t('informations')}</CardTitle>
            <Loader isLoading={isLoading}>
              <div className="list-row first">
                <div className="left">
                  {this.props.t('status')}
                </div>
                <div className="right" id="images-count-cell">{this.props.t(`dataset_status_${(details.status || '').toLocaleLowerCase()}`)}</div>
              </div>
              <div className="list-row">
                <div className="left">
                  {this.props.t('createdDate')}
                </div>
                <div className="right" id="created-date-cell">{toLocaleDateTimeString(details.createdDate)}</div>
              </div>
              <div className="list-row">
                <div className="left">
                  {this.props.t('number_of_images')}
                </div>
                <div className="right" id="images-count-cell">{details.imagesCount}</div>
              </div>
              <div className="list-row">
                <div className="left">
                  {this.props.t('size_of_images')}
                </div>
                <div className="right" id="images-count-cell">{(details.imagesSize || 0).toFixed(2)}</div>
              </div>
              {this.props.canSeeCreatedBy && <div className="list-row">
                <div className="left">
                  {this.props.t('created_by')}
                </div>
                <div className="right" id="images-count-cell">{(details.createdBy)}</div>
              </div>}
            </Loader>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export const DatasetDetailsInformation = as<React.ComponentClass<IDatasetDetailsInformationProps>>(withNamespaces('datasets')(DatasetDetailsInformationPure));
