import { GTable, IGColumnProps } from '../../../components/table/GTable';
import { as, disableTextMarkingsForClass } from '../../../helpers/react.helpers';
import { faArrowAltCircleUp, faDownload, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { Button } from 'reactstrap';
import { Home } from '../../../routes/config/Home';
import { IProjectListItem } from '../projects.store';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { IconedButton } from '../../../components/IconedButton';
import { Link } from 'react-router-dom';
import { NoAccessTooltip } from '../../../containers/NoAccessTooltip';
import { ProjectRole } from '../../../models/userRole.model';
import { ProjectStatus } from '../projects.model';
import { PublishButtonContainer } from '../../projectDetails/containers/PublishButton.container';
import React from 'react';
import { S_PaginationProps } from '../../../../design/pagination/S_Pagination';
import { Settings } from '../../../../modules/settings/SettingsContext';
import { handleClickAndPassData } from '../../../helpers/formHelpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { toLocaleDateTimeString } from '../../../helpers/date.helpers';
import { withNamespaces } from 'react-i18next';

library.add(faDownload, faArrowAltCircleUp, faTrashAlt);

export interface IProjectsListTableProps {
  workspaceId: string;
  projects: IProjectListItem[];
  sorting: {
    orderBy: string;
    orderType: string;
  };
  pagination: S_PaginationProps;
  onDelete(id: string): void;
  onAnnotate(id: string): void;
  onReview(id: string): void;
  onOrderingChange(orderBy: string, orderType: string): void;
  onGoToClarifications?(id: string): void;
}

class ProjectsListTable extends React.Component<IProjectsListTableProps & ITranslatable> {
  static contextType = Settings;
  declare context: React.ContextType<typeof Settings>;

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    disableTextMarkingsForClass('custom-control-label');
  }

  get gColumns() {
    const gColumns: IGColumnProps<IProjectListItem>[] = [];

    gColumns.push(
      {
        field: 'name',
        headerName: this.props.t('name'),
        renderer: this.renderName,
        sortable: true,
        minWidth: 300,
      },
      {
        field: 'modifiedDate',
        headerName: this.props.t('modified'),
        renderer: this.renderModifiedDate,
        sortable: true,
        width: 180,
      },
      {
        field: 'status',
        headerName: this.props.t('status'),
        renderer: this.renderStatus,
        sortable: true,
        width: 110,
      },
      {
        field: 'imagesCount',
        headerName: this.props.t('images'),
        width: 120,
        sortable: true,
      },
      {
        field: 'awaitingCount',
        headerName: this.props.t('awaiting'),
        sortable: true,
        width: 110,
        renderer: this.renderAwaiting,
      },
      {
        field: 'annotatedCount',
        headerName: this.props.t('annotated'),
        sortable: true,
        width: 110,
      },
      {
        field: 'reviewedCount',
        headerName: this.props.t('reviewed'),
        sortable: true,
        width: 110,
      },
      {
        field: 'unansweredClarificationsCount',
        headerName: this.props.t('clarifications.header'),
        renderer: this.renderUnansweredClarifications,
        sortable: true,
        width: 140,
      },
      {
        field: 'progress',
        headerName: this.props.t('progress'),
        renderer: this.renderProgress,
        sortable: true,
        width: 110,
      },
      {
        field: 'usersCount',
        headerName: this.props.t('workers'),
        sortable: true,
        width: 110,
      },
      {
        field: '',
        headerName: this.props.t('actions'),
        renderer: this.renderActions,
        sortable: false,
        width: 170,
      },
    );

    return gColumns;
  }

  stopPropagation(e: { stopPropagation(): void }) {
    e.stopPropagation();
  }

  renderName = (projectItem: IProjectListItem) => {
    const name = !!projectItem.name ? projectItem.name : `(${this.props.t('untitled')})`;

    return !projectItem.canView ? (
      <span>{name}</span>
    ) : (
      <Link
        to={
          projectItem.status === ProjectStatus.Published
            ? Home.Projects.Details.Overview.withParams({ projectId: projectItem.id, workspaceId: this.props.workspaceId })
            : Home.Projects.Details.AnnotationView.withParams({ projectId: projectItem.id, workspaceId: this.props.workspaceId })
        }
      >
        <span className={'text-link-in-table'}>{name}</span>
      </Link>
    );
  };

  renderAwaiting = (projectItem: IProjectListItem) => {
    return (
      <>
        {projectItem.awaitingCount}
        {projectItem.myRejectedCount > 0 && <span className="red"> ({projectItem.myRejectedCount})</span>}
      </>
    );
  };

  renderModifiedDate = (projectItem: IProjectListItem) => <>{toLocaleDateTimeString(projectItem.modifiedDate)}</>;

  renderStatus = (projectItem: IProjectListItem) => <>{projectItem.status}</>;

  renderUnansweredClarifications = (projectItem: IProjectListItem) => {
    const count = projectItem.unansweredClarificationsCount;
    const className = `${count > 0 ? 'red' : ''} ${count > 0 && this.props.onGoToClarifications ? 'text-link-in-table' : ''}`;
    const handleOnClick = count > 0 && this.props.onGoToClarifications ? handleClickAndPassData(this.props.onGoToClarifications)(projectItem.id) : undefined;
    return (
      <span className={className} onClick={handleOnClick}>
        {count}
      </span>
    );
  };

  renderProgress = (projectItem: IProjectListItem) => <>{projectItem.progress}%</>;

  renderActions = (projectItem: IProjectListItem) => {
    const { t } = this.props;

    return (
      <div className="g-actions">
        {projectItem.status === 'Draft' && (
          <NoAccessTooltip hasAccess={projectItem.canEdit}>
            <PublishButtonContainer projectId={projectItem.id} disabled={!projectItem.canBePublished}>
              {childrenProps => <IconedButton onClick={childrenProps.onClick} disabled={childrenProps.disabled} title={this.props.t('publish')} icon={'arrow-alt-circle-up'} />}
            </PublishButtonContainer>
          </NoAccessTooltip>
        )}
        <NoAccessTooltip hasAccess={projectItem.canEdit}>
          <IconedButton onClick={handleClickAndPassData(this.props.onDelete)(projectItem.id)} title={this.props.t('delete')} icon={'trash-alt'} color="red" />
        </NoAccessTooltip>
        {projectItem.status === 'Published' && (
          <>
            {projectItem.role === ProjectRole.Annotator && (
              <Button color="primary" className="table-button" onClick={handleClickAndPassData(this.props.onAnnotate)(projectItem.id)}>
                {t('annotate')}
              </Button>
            )}
            {projectItem.role === ProjectRole.Reviewer && (
              <Button color="primary" className="table-button" onClick={handleClickAndPassData(this.props.onReview)(projectItem.id)}>
                {t('review')}
              </Button>
            )}
          </>
        )}
      </div>
    );
  };

  onSortChanged = (orderBy: string, orderType: string) => this.props.onOrderingChange(orderBy, orderType);

  handleChange = (pageNumber: number, pageSize: number) => {
    this.context.setProjectsPageSize(pageSize);
    this.props.pagination.onChange(pageNumber, pageSize);
  };

  render() {
    return (
      <div className="projects-table">
        <GTable
          columns={this.gColumns}
          items={this.props.projects}
          paginationProps={{ ...this.props.pagination, pageSize: this.context.store.projectsPageSize, onChange: this.handleChange }}
          onSortChanged={this.onSortChanged}
          sortingModel={[this.props.sorting]}
          noItemsPlaceholder={this.props.t('no_projects_have_been_added_yet')}
        />
      </div>
    );
  }
}

export default as<React.ComponentClass<IProjectsListTableProps>>(withNamespaces('project', { wait: true })(ProjectsListTable));
