import { BaseRouteConfig, StickerRoute } from './BaseRoute';

export class EvaluationsPath extends BaseRouteConfig {
  public get Index(): string {
    return 'evaluations';
  }

  public readonly List = {
    Path: `${this.Path}/list`,
    All: new StickerRoute<{ workspaceId: string }>(`${this.Path}/list/all`),
    Owned: new StickerRoute<{ workspaceId: string }>(`${this.Path}/list/owned`),
    Others: new StickerRoute<{ workspaceId: string }>(`${this.Path}/list/others`),
  };

  public readonly Details = {
    Path: `${this.Path}/:jobId`,
    Paths: new StickerRoute<{ workspaceId: string; jobId: string; tab: string }>(`${this.Path}/:jobId/:tab`),
    Settings: new StickerRoute<{ workspaceId: string; jobId: string }>(`${this.Path}/:jobId/settings`),
    Datasets: new StickerRoute<{ workspaceId: string; jobId: string }>(`${this.Path}/:jobId/datasets`),
    Images: new StickerRoute<{ workspaceId: string; jobId: string }>(`${this.Path}/:jobId/images`),
    ImageDetails: new StickerRoute<{ workspaceId: string; jobId: string; imageId: string }>(`${this.Path}/:jobId/images/:imageId`),
    Metrics: new StickerRoute<{ workspaceId: string; jobId: string }>(`${this.Path}/:jobId/metrics`),
  };

  public readonly Create = {
    Path: `${this.Path}/create`,
    Paths: new StickerRoute<{ workspaceId: string; tab: string }>(`${this.Path}/create/:tab`),
    Settings: new StickerRoute<{ workspaceId: string }>(`${this.Path}/create/settings`),
    Datasets: new StickerRoute<{ workspaceId: string }>(`${this.Path}/create/datasets`),
    Images: new StickerRoute<{ workspaceId: string }>(`${this.Path}/create/images`),
  };
}
