import * as React from 'react';

import Dropzone from 'react-dropzone';
import { FileList } from '../../../components/FileList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IImageUrl } from '../imagesFromUrlsUpload.service';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { PacmanLoader } from 'react-spinners';
import { RejectStatus } from '../models/RejectStatus';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IFileUploaderComponentProps {
  onAcceptedFileDrop(files: File[]): void;
  onRejectedFileDrop(): void;
  imageUrls: IImageUrl[];
  isProcessingImages: boolean;
  children: React.ReactNode | React.ReactNode[];
  disabled?: boolean;
}

export const FileDropZone = as<React.SFC<IFileUploaderComponentProps>>(
  withNamespaces('datasets', { wait: true })((props: IFileUploaderComponentProps & ITranslatable) => {
    const acceptedUrls = props.imageUrls.filter(i => i.isValid);
    const rejectedUrls = props.imageUrls.filter(i => !i.isValid);

    function getRejectedDescription(rejectedStatus?: RejectStatus) {
      if (rejectedStatus === undefined) {
        return '';
      }
      return props.t(rejectedStatus);
    }

    return (
      <>
        <Dropzone
          className="dropzone-generic"
          activeClassName="active"
          multiple={false}
          onDropAccepted={props.onAcceptedFileDrop}
          onDropRejected={props.onRejectedFileDrop}
          accept={'.csv, .txt'}
          disabled={props.disabled}
        >
          {props.children}
        </Dropzone>
        {props.isProcessingImages && (
          <div className="pacman-loader">
            <span className="pacman-loader-caption">{props.t('processing_your_images')}</span>
            <PacmanLoader color="#1F2046" />
          </div>
        )}
        {(props.imageUrls.length > 0 && !props.isProcessingImages) && (
          <div className="file-list-container">
            <p className="valid">
              <FontAwesomeIcon icon="check" /> <strong>{acceptedUrls.length}</strong>{' '}
              {props.t('accepted_urls')}
            </p>
            <p className="invalid">
              <FontAwesomeIcon icon="times" /> <strong>{rejectedUrls.length} </strong> {props.t('rejected_urls')}
              {rejectedUrls.length > 0 && <span>:</span>}
            </p>
            <FileList
              items={rejectedUrls.map(f => ({
                name: f.url,
                description: getRejectedDescription(f.rejectStatus),
              }))}
            />
          </div>
        )}
      </>
    );
  }),
);
