import * as React from 'react';

import { Button, Card, CardBody, Col, Row } from 'reactstrap';

import { ITranslatable } from '../../../helpers/translations.helpers';
import { as } from '../../../helpers/react.helpers';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';

library.add(faCheckCircle, faCircle);

export interface ImageDeletedMessageProps {
  onRefresh(): void;
}

const ImageDeletedMessagePure = (props: ImageDeletedMessageProps & ITranslatable) => {
  return (
    <div className="no-image-ph">
      <div className="center">
        <Col xl="12">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h5>{props.t('this_image_has_been_removed_from_project')}</h5>
                </Col>
              </Row>
              <Row className="btn-content">
                <Col>
                  <Button color="primary" onClick={props.onRefresh}>
                    {props.t('back_to_image_list')}
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </div>
    </div>
  );
};

export const ImageDeletedMessage = as<React.ComponentClass<ImageDeletedMessageProps>>(withNamespaces('annotation', { wait: true })(ImageDeletedMessagePure));
