import * as React from 'react';

import { SwitchWithNotFound } from '../../containers/route.containers';
import { NotFoundView } from '../../views/NotFound.view';
import { Route } from 'react-router';
import { Home } from '../config/Home';
import { EvaluationDetailsSettingsView } from '../../views/home/evaluationDetails/EvaluationDetailsSettings.view';
import { EvaluationDetailsMetricsView } from '../../views/home/evaluationDetails/EvaluationDetailsMetrics.view';
import { EvaluationDetailsImagesView } from '../../views/home/evaluationDetails/EvaluationDetailsImages.view';
import { EvaluationDetailsDatasetsView } from '../../views/home/evaluationDetails/EvaluationDetailsDatasets.view';

export const EvaluationDetailsRoute = () => (
  <SwitchWithNotFound notFound={NotFoundView}>
    <Route path={Home.Evaluations.Details.Settings.Path} component={EvaluationDetailsSettingsView} />
    <Route path={Home.Evaluations.Details.Datasets.Path} component={EvaluationDetailsDatasetsView} />
    <Route path={Home.Evaluations.Details.Images.Path} component={EvaluationDetailsImagesView} />
    <Route path={Home.Evaluations.Details.Metrics.Path} component={EvaluationDetailsMetricsView} />
  </SwitchWithNotFound>
);

export const EvaluationDetailsCreateRoute = () => (
  <SwitchWithNotFound notFound={NotFoundView}>
    <Route path={Home.Evaluations.Create.Settings.Path} component={EvaluationDetailsSettingsView} />
    <Route path={Home.Evaluations.Create.Datasets.Path} component={EvaluationDetailsDatasetsView} />
    <Route path={Home.Evaluations.Create.Images.Path} component={EvaluationDetailsImagesView} />
  </SwitchWithNotFound>
);