import * as React from 'react';

import { IProjectsService, ProjectsServiceType } from '../projects.service';
import { IUserService, UserServiceType } from '../../user/user.service';
import { IWorkspaceService, WorkspaceServiceType } from '../../workspaces/workspaces.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { ListViewMode } from '../../user/user.store';
import { ProjectsListControls } from '../components/ProjectsListControls';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IProps extends RouteComponentProps<{ attachmentId: string }> {
  workspaceService: IWorkspaceService;
  projectsService: IProjectsService;
  userService: IUserService;
}

@injectProps({
  workspaceService: WorkspaceServiceType,
  projectsService: ProjectsServiceType,
  userService: UserServiceType,
})
@observer
class ProjectsListControlsContainerPure extends React.Component<IProps> {

  @autobind
  handleOrderByChange(orderBy: string) {
    this.props.projectsService.changeOrder(orderBy, this.props.projectsService.store.projectsPaging.orderType || 'asc');
  }

  @autobind
  handleOrderDirectionChange(orderType: string) {
    this.props.projectsService.changeOrder(this.props.projectsService.store.projectsPaging.orderBy || 'name', orderType);
  }

  @autobind
  handleViewModeChange(mode: ListViewMode) {
    this.props.userService.setProjectsListViewMode(mode);
  }

  render() {
    const projectsStore = this.props.projectsService.store;

    return (
      <ProjectsListControls
        viewMode={this.props.userService.data.projectListViewMode}
        orderBy={projectsStore.projectsPaging.orderBy!}
        orderDirection={projectsStore.projectsPaging.orderType!}
        onViewModeChange={this.handleViewModeChange}
        onOrderByChange={this.handleOrderByChange}
        onOrderDirectionChange={this.handleOrderDirectionChange}
      />
    );
  }
}

export const ProjectsListControlsContainer = as<React.ComponentClass>(withRouter(ProjectsListControlsContainerPure));
