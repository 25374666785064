import { EmptyAnnotationToolFilter, FilterTabType, IAnnotationToolFilter, IImageFilters, ISavedFilter, ISorting, ImageDefaultFilters } from '../../imageFilters.model';
import { IImageFilterService, ImageFilterServiceType } from '../../services/imageFilters.service';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../../../../../modules/common/OverlayLoader.store';
import { IProjectDetailsImagesStore, ProjectDetailsImagesStoreType } from '../../projectDetailsImages.store';
import { IProjectDetailsPermissions, ProjectDetailsPermissionsType } from '../../../../projectDetails.permissions';
import { ImageAnnotationStatus, ImageSetType } from '../../projectDetailsImages.model';
import { as, injectProps } from '../../../../../../helpers/react.helpers';

import { ImageFiltersForm } from '../../components/filters/ImageFiltersForm';
import React from 'react';
import { cloneDeep } from 'lodash';
import { getActiveFiltersCount } from '../../services/imageFilterHelper';
import { observer } from 'mobx-react';

interface IState {
  selectedFilterTab: FilterTabType;
  filtersDraft: IImageFilters;
  showClearAllConfirmed: boolean;
}

interface IOuterProps {
  onAfterFilter(): void;
  isModalOpen: boolean;
}

interface IInjectedProps {
  imageFilterService: IImageFilterService;
  imagesStore: IProjectDetailsImagesStore;
  overlayLoaderStore: IOverlayLoaderStore;
  permissions: IProjectDetailsPermissions;
}

@injectProps({
  imageFilterService: ImageFilterServiceType,
  imagesStore: ProjectDetailsImagesStoreType,
  overlayLoaderStore: OverlayLoaderStoreType,
  permissions: ProjectDetailsPermissionsType,
})
@observer
class ImageFiltersFormContainerPure extends React.Component<IOuterProps & IInjectedProps, IState> {
  constructor(props: IInjectedProps & IOuterProps) {
    super(props);

    this.state = {
      showClearAllConfirmed: false,
      selectedFilterTab: FilterTabType.InDatasets,
      filtersDraft: cloneDeep(this.props.imagesStore.imageFilters),
    };
  }

  handleFilterTabSelected = (selectedFilterTab: FilterTabType): void => {
    this.setState({ selectedFilterTab });
  };

  handleSetInDatasets = (datasets: string[]): void => {
    const filtersDraft = {
      ...this.state.filtersDraft,
      datasets,
    };

    this.setState({ filtersDraft });
  };

  handleSetAnnotatedBy = (annotatedBy: string[]): void => {
    const filtersDraft = {
      ...this.state.filtersDraft,
      annotatedBy,
    };

    this.setState({ filtersDraft });
  };

  handleSetReviewedBy = (reviewedBy: string[]): void => {
    const filtersDraft = {
      ...this.state.filtersDraft,
      reviewedBy,
    };

    this.setState({ filtersDraft });
  };

  handleSetStatuses = (annotationStatuses: ImageAnnotationStatus[]): void => {
    const filtersDraft = {
      ...this.state.filtersDraft,
      annotationStatuses,
    };

    this.setState({ filtersDraft });
  };

  handleSetImageSets = (imageSets: ImageSetType[]): void => {
    const filtersDraft = {
      ...this.state.filtersDraft,
      imageSets,
    };

    this.setState({ filtersDraft });
  };

  handleSetCorrected = (corrected: boolean[]): void => {
    const filtersDraft = {
      ...this.state.filtersDraft,
      corrected,
    };

    this.setState({ filtersDraft });
  };

  handleSetAnnotationTools = (annotationTools: IAnnotationToolFilter[]): void => {
    const filtersDraft = {
      ...this.state.filtersDraft,
      annotationTools,
    };

    this.setState({ filtersDraft });
  };

  handleSetSorting = (sorting: ISorting[]): void => {
    const filtersDraft = {
      ...this.state.filtersDraft,
      sorting,
    };

    this.setState({ filtersDraft });
  };

  handleClearAll = () => {
    this.setState({ showClearAllConfirmed: true });
  };

  handleClearAllDeclined = () => {
    this.setState({ showClearAllConfirmed: false });
  };

  handleClearAllConfirmedAsync = async () => {
    this.setState({ filtersDraft: ImageDefaultFilters, showClearAllConfirmed: false });
    await this.props.imageFilterService.clearAllAsync();
    this.props.onAfterFilter();
  };

  handleApplyAsync = async () => {
    this.state.filtersDraft.sorting = this.state.filtersDraft.sorting.filter(x => x.by !== undefined);
    await this.props.imageFilterService.applyAsync(this.state.filtersDraft);
    this.props.onAfterFilter();
  };

  handleSavedFilterLoad = (savedFilter: ISavedFilter) => {
    this.setState({
      filtersDraft: cloneDeep({
        ...savedFilter.filters,
        ...(!savedFilter.filters.annotationTools.length && { annotationTools: [{ ...EmptyAnnotationToolFilter }] }),
      }),
    });
  };

  handleSavedFilterAsync = async (name: string) => {
    this.props.imageFilterService.saveFilterAsync(name, this.state.filtersDraft);
  };

  handleFilterDeleteAsync = async (id: string) => {
    await this.props.imageFilterService.deleteFilterAsync(id);
  };

  handleModalOpen = () => {
    this.setState({ selectedFilterTab: FilterTabType.InDatasets, filtersDraft: cloneDeep(this.props.imagesStore.imageFilters) });
  };

  render() {
    const activeFiltersCount = getActiveFiltersCount(this.state.filtersDraft);
    const isClearAllEnabled = activeFiltersCount > 0 || this.state.filtersDraft.sorting.some(x => x.by !== undefined);
    const canSeeUsers = this.props.permissions.canSeeAnnotationAuthors();

    return (
      <ImageFiltersForm
        canSeeUsers={canSeeUsers}
        activeFiltersCount={activeFiltersCount}
        isClearAllEnabled={isClearAllEnabled}
        showClearAllConfirmation={this.state.showClearAllConfirmed}
        savedFilters={this.props.imagesStore.savedFilters}
        isLoading={this.props.overlayLoaderStore.isSpinnerVisible('details-images-loading')}
        activeFilterType={this.state.selectedFilterTab}
        imageFilters={this.state.filtersDraft}
        availableFilters={this.props.imagesStore.availableFilters}
        onFilterTabSelect={this.handleFilterTabSelected}
        onSetAnnotatedBy={this.handleSetAnnotatedBy}
        onSetCorrected={this.handleSetCorrected}
        onSetImageSets={this.handleSetImageSets}
        onSetInDatasets={this.handleSetInDatasets}
        onSetStatuses={this.handleSetStatuses}
        onSetReviewedBy={this.handleSetReviewedBy}
        onSetAnnotationTools={this.handleSetAnnotationTools}
        onSetSorting={this.handleSetSorting}
        onClearAll={this.handleClearAll}
        onClearAllDeclined={this.handleClearAllDeclined}
        onClearAllConfirmedAsync={this.handleClearAllConfirmedAsync}
        onApplyAsync={this.handleApplyAsync}
        onSavedFilterLoad={this.handleSavedFilterLoad}
        onSavedFilterAsync={this.handleSavedFilterAsync}
        onFilterDeleteAsync={this.handleFilterDeleteAsync}
        onModalClose={this.props.onAfterFilter}
        onModalOpen={this.handleModalOpen}
        isModalOpen={this.props.isModalOpen}
      />
    );
  }
}

export const ImageFiltersFormContainer = as<React.ComponentClass<IOuterProps>>(ImageFiltersFormContainerPure);
