// tslint:disable: function-name

import 'leaflet-draw';

import { BRUSH_MODE, MOUSE_BUTTON, MOUSE_BUTTONS, MOUSE_EVENTS, MouseLeafletEvent } from '../../../annotations.interface';
import { Feature, MultiPolygon } from 'geojson';
import { IGeometry, getCircleAsShape, interpolateLineToShape, multipoligonToPoints, normalizeMultipolygon, shapeToCoords } from './BrushGeometry';
import L, { CircleMarker, DomEvent, LatLng, LatLngTuple, LeafletEventHandlerFn, Map, PolylineOptions } from 'leaflet';
import { calculateCurrentPosition, getImageBounds } from '../../../../../helpers/geometry/mapExtensions.helpers';

import { AddBrushBlobCommand } from '../../../undoRedoCommands/addBrushBlobCommand';
import { BrushStack } from './BrushStack';
import { DefaultConfiguration } from './BrushConfig';
import { IAnnotationService } from '../../../annotation.service';
import { IFreeDrawSegmentationService } from '../../../freeDrawSegmentation.service';
import { IUndoRedoHistory } from '../../../undoRedoHistory.service';
import autobind from 'autobind-decorator';
import { keyboardShortcutsServiceInstance } from '../../../../../services/keyboardShortcuts.service';
import { restrictToBoundingBoxInterpolated } from '../../../../../helpers/geometry/polygon.helpers';

export interface DrawBrushOptions {
  data?: Feature;
  diameter: number;
  mode: BRUSH_MODE;
  color: string;
  opacity: number;
  fillOpacity: number;
}

const TargetFrontLineOptions: PolylineOptions = {
  weight: 3,
  dashArray: '4 8',
  opacity: 1.0,
  fill: false,
  color: '#000000',
};

const TargetBackgroundLineOptions: PolylineOptions = {
  weight: 3,
  opacity: 1.0,
  fill: false,
  color: '#ffffff',
};

@autobind
export class DrawBrush extends L.Draw.Feature implements IDrawer {
  private readonly options: DrawBrushOptions = {
    diameter: DefaultConfiguration.radius * 2,
    mode: DefaultConfiguration.mode,
    color: DefaultConfiguration.color,
    fillOpacity: 0.3,
    opacity: 1,
  };

  private lastDrawingPosition: LatLng | undefined = undefined;
  private showTargetLine: boolean = false;
  private targetFrontLine: L.Polyline | undefined = undefined;
  private targetBackgroundLine: L.Polyline | undefined = undefined;
  private isDrawingStarted = false;

  private readonly onDrawCreated: (geojson: Feature) => void;
  private readonly onDrawStarted: (geojson: Feature) => void;
  private readonly onRadiusChanged: (radius: number) => void;

  private _brush: CircleMarker;
  private _brushMode: BRUSH_MODE = BRUSH_MODE.DRAW;
  private _brushRadius: number = 30;

  private _currentMousePosition?: LatLng = undefined;
  private _lastMousePosition?: L.LatLng = undefined;
  private _isAnimationRunning: boolean = true;

  // input tracking
  private _dragStartedInCanvas = false;
  private _currentButtons: MOUSE_BUTTONS = MOUSE_BUTTONS.NONE;
  private _isControlDown: boolean = false;

  private _brushStack: BrushStack;

  private get _brushCircleOptions() {
    return { color: this.options.color, fillOpacity: this.options.fillOpacity, opacity: 1, attribution: 'brush' };
  }

  constructor(
    map: Map,
    cursorPosition: LatLng | undefined,
    public readonly activeAnnotationTypeId: string | undefined,
    public readonly undoRedoHistory: IUndoRedoHistory,
    public readonly annotationService: IAnnotationService,
    public readonly freeDrawSegmentationService: IFreeDrawSegmentationService,
    options: DrawBrushOptions,
    onDrawCreated: (geojson: Feature) => void,
    onDrawStarted: (geojson: Feature) => void,
    onRadiusChanged: (radius: number) => void,
  ) {
    super(map);
    this._map = map;
    this._brushMode = options.mode;
    this.options = { ...this.options, ...options };

    TargetFrontLineOptions.color = this.options.color;
    this.onDrawCreated = onDrawCreated;
    this.onDrawStarted = onDrawStarted;
    this.onRadiusChanged = onRadiusChanged;

    this._brush = new L.Circle(this._currentMousePosition || [0, 0], this._brushCircleOptions);
    this._currentMousePosition = cursorPosition;
    this._brushStack = new BrushStack(this._map, options.data, this.options.color, this.options.fillOpacity);

    this._setBrushDiameter(options.diameter);
    this.redrawTargetLines();
    this.startAnimation();

    this.undoRedoHistory.refreshCommandsBindings(this.activeAnnotationTypeId, this);
  }

  public enable() {
    if (!this.enabled()) {
      super.enable();
      this._brushMode = this.options.mode;
    }

    return this;
  }

  public disable() {
    if (this.enabled()) {
      super.disable();
    }
    return this;
  }

  public disableMouseMarkerFocus() {}

  public addHandlers() {
    this._map.on(MOUSE_EVENTS.MOUSE_DOWN, this.handleMouseDown as LeafletEventHandlerFn);
    keyboardShortcutsServiceInstance.registerKeyDown('Shift', this.handleShiftDown);
    keyboardShortcutsServiceInstance.registerKeyUp('Shift', this.handleShiftUp);
    keyboardShortcutsServiceInstance.registerKeyDown('Control', this.handleCtrlDown);
    keyboardShortcutsServiceInstance.registerKeyUp('Control', this.handleCtrlUp);

    document.addEventListener(MOUSE_EVENTS.MOUSE_MOVE, this.handleDocumentMouseMove as any);
    document.addEventListener(MOUSE_EVENTS.MOUSE_UP, this.handleDocumentMouseUp as any);
    document.body.addEventListener(MOUSE_EVENTS.WHEEL, this.handleDocumentMouseWheel as any, { passive: false });

    DomEvent.on(this._map._container, MOUSE_EVENTS.WHEEL, this.handleMouseWheel as any);
  }

  public removeHandlers() {
    this.handleDrawStop();
    this._map.off(MOUSE_EVENTS.MOUSE_DOWN, this.handleMouseDown as LeafletEventHandlerFn);
    keyboardShortcutsServiceInstance.unregisterKeyDown('Shift', this.handleShiftDown);
    keyboardShortcutsServiceInstance.unregisterKeyUp('Shift', this.handleShiftUp);
    keyboardShortcutsServiceInstance.unregisterKeyDown('Control', this.handleCtrlDown);
    keyboardShortcutsServiceInstance.unregisterKeyUp('Control', this.handleCtrlUp);

    document.removeEventListener(MOUSE_EVENTS.MOUSE_MOVE, this.handleDocumentMouseMove as any);
    document.removeEventListener(MOUSE_EVENTS.MOUSE_UP, this.handleDocumentMouseUp as any);
    document.body.removeEventListener(MOUSE_EVENTS.WHEEL, this.handleDocumentMouseWheel as any);

    DomEvent.off(this._map._container, MOUSE_EVENTS.WHEEL, this.handleMouseWheel as any);
  }

  public updateOptions(diameter: number, mode: BRUSH_MODE, fillOpacity: number) {
    this._setBrushDiameter(diameter);
    this.options.mode = mode;
    this.options.fillOpacity = fillOpacity;
    this._brushStack.updateFillOpacity(fillOpacity);
  }

  private startAnimation() {
    if (this._isAnimationRunning) {
      this.redrawTargetLines();
      this.paintBrush();
      this._brushStack.drawCurrentGeometry();
      requestAnimationFrame(this.startAnimation);
    }
  }

  private stopAnimation() {
    this._isAnimationRunning = false;
  }

  private handleDrawStop() {
    this.stopAnimation();
    this._brushStack.clearCurrentPolygons();
    this._brush.removeFrom(this._map);
    this.clearTargetLines();
  }

  private handleShiftDown() {
    this.showTargetLine = true;
  }

  private handleShiftUp() {
    this.showTargetLine = false;
  }

  private handleCtrlDown() {
    this._isControlDown = true;
    this.setBrushMode();
  }

  private handleCtrlUp() {
    this._isControlDown = false;
    this.setBrushMode();
  }

  private setMouseButtons(event: MouseEvent) {
    this._currentButtons = event.buttons & (MOUSE_BUTTONS.LEFT | MOUSE_BUTTONS.RIGHT);
  }

  private setBrushMode() {
    if (this._isControlDown || (this._currentButtons & MOUSE_BUTTONS.RIGHT) === MOUSE_BUTTONS.RIGHT) {
      this._brushMode = BRUSH_MODE.ERASE;
    } else {
      this._brushMode = this.options.mode;
    }
  }

  private handleMouseWheel(e: MouseEvent) {
    const event = e as WheelEvent;
    if (event.ctrlKey) {
      e.preventDefault();
      this._map.scrollWheelZoom.disable();
      this._calculateRadiusChange(event);
      this._map.scrollWheelZoom.enable();
    }
  }

  private handleDocumentMouseWheel(e: MouseEvent) {
    const event = e as WheelEvent;
    if (event.ctrlKey) {
      const position = calculateCurrentPosition(e, this._map);
      if (!position) {
        e.preventDefault();
        this._calculateRadiusChange(event);
      }
    }
  }

  private handleMouseDown(e: MouseLeafletEvent) {
    this._dragStartedInCanvas = true;

    if (e.originalEvent.button !== MOUSE_BUTTON.LEFT && e.originalEvent.button !== MOUSE_BUTTON.RIGHT) return;
    this.setMouseButtons(e.originalEvent);
    this.setBrushMode();

    if (this.lastDrawingPosition && this._currentMousePosition && e.originalEvent.shiftKey) {
      this._brushStack.stackAction(interpolateLineToShape(this._currentMousePosition, this.lastDrawingPosition, this._brushRadius), this._brushMode);
      this._brushStack.stackAction(getCircleAsShape(this.lastDrawingPosition, this._brushRadius), this._brushMode);
    }

    this._map.dragging.disable();
    this.handleDocumentMouseMove(e.originalEvent);
  }

  private handleDocumentMouseUp(e: any) {
    if (!this._dragStartedInCanvas || e.defaultPrevented) return;

    this.setMouseButtons(e);
    this.setBrushMode();

    this._assignCurrentPositionToLastBrushDrawingPosition();

    this._brushStack.drawingPromise.then(this.addHistoryAfterDrawingIsFinished);

    this._map.dragging.enable();
    this._dragStartedInCanvas = false;
  }

  public replaceGeometry(data: IGeometry[]) {
    this._brushStack.replaceGeometry(data);
    this.onDrawCreated(this._brushStack.currentFeature);
  }

  public initGeometry(data: IGeometry[]) {
    this._brushStack.replaceGeometry(data);
    this.onDrawStarted(this._brushStack.currentFeature);
  }

  private handleDocumentMouseMove(e: MouseEvent) {
    this._currentMousePosition = calculateCurrentPosition(e, this._map);
    this.setMouseButtons(e);
    this.setBrushMode();

    if (!e.shiftKey) this.showTargetLine = false;

    const isMouseDown = this._currentButtons !== MOUSE_BUTTONS.NONE;
    if (this._currentMousePosition && isMouseDown) {
      this._brushStack.stackAction(interpolateLineToShape(this._lastMousePosition!, this._currentMousePosition, this._brushRadius), this._brushMode);
      this._brushStack.stackAction(getCircleAsShape(this._currentMousePosition, this._brushRadius), this._brushMode);

      if (!this.isDrawingStarted && (this._brushMode !== BRUSH_MODE.ERASE || this._brushStack.currentGeometry.length)) {
        this.isDrawingStarted = true;

        const latlngs = ([] as number[][][]).concat(...this._brushStack.currentGeometry.map(x => shapeToCoords(x.shape)));
        const feature = L.polygon([latlngs] as LatLngTuple[][][]).toGeoJSON();
        if (!(feature.geometry.coordinates[0] && feature.geometry.coordinates[0][0])) {
          feature.geometry.coordinates = []; // leaflet returns [[undefined]] for empty polygon;
        }
        this.onDrawStarted(feature);
      }
    }
    this._lastMousePosition = this._currentMousePosition;
    this.redrawTargetLines();
  }

  private paintBrush() {
    if (this._currentMousePosition) {
      this._brush.setRadius(this._brushRadius).setLatLng(this._currentMousePosition).setStyle(this._brushCircleOptions).addTo(this._map);

      const fillOpacity = this._brushMode === BRUSH_MODE.DRAW ? this._brushCircleOptions.fillOpacity : 0;
      this._brush?.setStyle({ fillOpacity });
    } else {
      this._brush.removeFrom(this._map);
    }
  }

  private redrawTargetLines() {
    this.clearTargetLines();

    if (
      !this.showTargetLine ||
      this._currentButtons ||
      !this._currentMousePosition ||
      !this.lastDrawingPosition ||
      (this._currentMousePosition.lat === this.lastDrawingPosition.lat && this._currentMousePosition.lng === this.lastDrawingPosition.lng)
    ) {
      return;
    }

    this.targetFrontLine = new L.Polyline([this.lastDrawingPosition, this._currentMousePosition], TargetFrontLineOptions);
    this.targetBackgroundLine = new L.Polyline([this._currentMousePosition, this.lastDrawingPosition], TargetBackgroundLineOptions);
    this.targetBackgroundLine.addTo(this._map); // background line has to be first, so the front line is drawn on top of it
    this.targetFrontLine.addTo(this._map);
  }

  private clearTargetLines() {
    if (this.targetFrontLine) this.targetFrontLine.removeFrom(this._map);
    if (this.targetBackgroundLine) this.targetBackgroundLine.removeFrom(this._map);
  }

  private _setBrushDiameter(diameter?: number) {
    if (diameter !== undefined) {
      const radius = diameter / 2;
      if (radius < DefaultConfiguration.minRadius) {
        this._brushRadius = DefaultConfiguration.minRadius;
      } else if (radius > DefaultConfiguration.maxRadius) {
        this._brushRadius = DefaultConfiguration.maxRadius;
      } else {
        this._brushRadius = radius;
      }
    }
  }

  private _calculateRadiusChange(event: WheelEvent) {
    const step = 0.5;
    if (event.deltaY < 0) {
      const newRadius = this._brushRadius + step;
      if (newRadius <= DefaultConfiguration.maxRadius) this.onRadiusChanged(newRadius * 2);
    } else if (event.deltaY > 0) {
      const newRadius = this._brushRadius - step;
      if (newRadius >= DefaultConfiguration.minRadius) this.onRadiusChanged(newRadius * 2);
    }
  }

  private _assignCurrentPositionToLastBrushDrawingPosition() {
    // if end point of brush line is out of the bounding box we need to trim it so it belongs in the bounding box
    // we trim it by intersecting drawing line (note that brush usually has a radius and draws rectangle with circles on both ends
    // and we are intersecting drawing line) with bounding box
    if (!this._currentMousePosition) return;

    const lastPosition = this.lastDrawingPosition ? this.lastDrawingPosition : this._lastMousePosition;

    const bounds = getImageBounds(this._map);

    this.lastDrawingPosition = bounds.contains(this._currentMousePosition)
      ? this._currentMousePosition
      : restrictToBoundingBoxInterpolated(this._currentMousePosition, lastPosition!, this._map);
  }

  private addHistoryAfterDrawingIsFinished = () => {
    if (this.isDrawingStarted || this._brushStack.currentGeometry.length) {
      const previousCommand = this.undoRedoHistory.lastUndoCommand as AddBrushBlobCommand | undefined;
      const previousState =
        previousCommand?.state || (this.options.data ? normalizeMultipolygon(multipoligonToPoints((this.options.data!.geometry as MultiPolygon).coordinates)) : []);
      this.undoRedoHistory.addCommand(new AddBrushBlobCommand(this, previousState, this._brushStack.currentGeometry));

      this.onDrawCreated(this._brushStack.currentFeature);
    }
  };

  // FIXES some bugs with contextmenu click or different click behavior in browsers
  // See S20-120, S20-133 bugs on JIRA
  _onTouch() {}
  _onMouseDown() {}
  _onMouseUp() {}
}
