import * as React from 'react';

import { faAngleLeft, faAngleRight, faTimes } from '@fortawesome/free-solid-svg-icons';

import { EditableAttachmentPreviewHeaderContainer } from '../../../../modules/attachments/rename/ui/header/EditableAttachmentPreviewHeader.container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { ImageNavigation } from '../../../components/ImageNavigation';
import { LinkedButton } from '../../../components/LinkedButton';
import { as } from '../../../helpers/react.helpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { toLocaleDateTimeString } from '../../../helpers/date.helpers';
import { withNamespaces } from 'react-i18next';

library.add(faAngleLeft, faAngleRight, faTimes);

export interface IAttachmentDetailsImageInfoProps {
  channels: number;
  closeButtonLink: string;
  createdDate: string;
  current: number;
  downloadSpeed?: number;
  id: string;
  imageHeight: number;
  imageSize: number;
  imageWidth: number;
  isCached: boolean;
  isHighResImageLoading: boolean;
  isLoading: boolean;
  name: string;
  nextLink: string;
  parentId: string;
  previousLink: string;
  total: number;
}

class AttachmentDetailsPreviewHeaderPure extends React.Component<IAttachmentDetailsImageInfoProps & ITranslatable> {
  render() {
    const { total, current, name, id, parentId, t, nextLink, previousLink, closeButtonLink } = this.props;

    const attributes = [
      { name: t('image_attribute_size'), value: `${this.props.imageSize.toFixed(3)} MB` },
      { name: t('image_attribute_resolution'), value: `${this.props.imageWidth} x ${this.props.imageHeight}` },
      { name: t('image_attribute_type'), value: `${this.props.channels} ${t('image_attribute_channels')}` },
      { name: t('image_attribute_status'), value: this.props.isCached ? t('image_attribute_status_cached') : t('image_attribute_status_not_cached') },
      { name: t('image_attribute_created'), value: toLocaleDateTimeString(this.props.createdDate) },
      { name: t('image_attribute_load_speed'), value: this.props.downloadSpeed ? `${this.props.downloadSpeed.toFixed(3)} Mbps` : t('image_attribute_load_speed_cached') },
    ];
    return (
      <div className="dataset-image-preview-header">
        <div className="image-name">
          <div className="image-input-body">
            <EditableAttachmentPreviewHeaderContainer name={name} id={id} parentId={parentId} />
          </div>

          {!this.props.isHighResImageLoading && <span className="info">{attributes.map(a => `${a.name}: ${a.value}`).join(' | ')}</span>}
        </div>
        <div className="navigation">
          <ImageNavigation current={current} total={total} nextLink={nextLink} previousLink={previousLink} isLoading={this.props.isLoading} disabled={false} />
          <LinkedButton to={closeButtonLink} color="primary" className="p-2 ml-2 button-close">
            <FontAwesomeIcon icon={faTimes} className="mr-0" title={t('close')} />
          </LinkedButton>
        </div>
      </div>
    );
  }
}

export const AttachmentDetailsPreviewHeader = as<React.StatelessComponent<IAttachmentDetailsImageInfoProps>>(
  withNamespaces('datasets', { wait: true })(AttachmentDetailsPreviewHeaderPure),
);
