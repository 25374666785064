import * as React from 'react';

import { AnnotationConfirmationModal, AnnotationConfirmationModalMode, IAnnotationConfirmationModalProps } from '../../../components/AnnotationConfirmationModal';
import { AnnotationsStoreType, IAnnotationsStore } from '../../annotation/annotations.store';
import { FreeAccessMode, FreeAccessStoreType, IFreeAccessStore } from '../freeAccess.store';
import { FreeAccessPermissionsType, IFreeAccessPermissions } from '../freeAccess.permissions';
import { FreeAccessServiceType, IFreeAccessService } from '../freeAccess.service';
import { FreeDrawSegmentationServiceType, IFreeDrawSegmentationService } from '../../annotation/freeDrawSegmentation.service';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../../modules/common/OverlayLoader.store';
import { IUndoRedoHistory, UndoRedoHistoryType } from '../../annotation/undoRedoHistory.service';
import { IWorkspaceService, WorkspaceServiceType } from '../../workspaces/workspaces.service';
import { as, injectProps } from '../../../helpers/react.helpers';

import { AnnotationNavigation } from '../../annotation/components/AnnotationNavigation';
import { EditorMode } from '../../../../modules/editor/models/EditorModes';
import { GlobalHotKeys } from 'react-hotkeys';
import { SubmitHotkeyContainer } from './SubmitHotkey.container';
import autobind from 'autobind-decorator';
import { dinduNuffin } from '../../../helpers/function.helpers';
import { observer } from 'mobx-react';

interface IInjectedProps {
  freeAccessService: IFreeAccessService;
  freeAccessStore: IFreeAccessStore;
  freeDrawSegmentationService: IFreeDrawSegmentationService;
  historyService: IUndoRedoHistory;
  workspaceService: IWorkspaceService;
  freeAccessPermissions: IFreeAccessPermissions;
  annotationsStore: IAnnotationsStore;
  overlayLoaderStore: IOverlayLoaderStore;
}

interface IState extends ILoaderState {
  showAnnotationConfirmationModal: boolean;
  showDiscardConfirmationModal: boolean;
  showCancelModal: boolean;
}

@injectProps({
  freeAccessService: FreeAccessServiceType,
  freeAccessStore: FreeAccessStoreType,
  freeDrawSegmentationService: FreeDrawSegmentationServiceType,
  historyService: UndoRedoHistoryType,
  workspaceService: WorkspaceServiceType,
  freeAccessPermissions: FreeAccessPermissionsType,
  annotationsStore: AnnotationsStoreType,
  overlayLoaderStore: OverlayLoaderStoreType,
})
@observer
class FreeAccessAnnotateControlsContainerPure extends WithLoaderComponentBase<IInjectedProps & IAnnotationConfirmationModalProps, IState> {
  state: IState = {
    showAnnotationConfirmationModal: false,
    showDiscardConfirmationModal: false,
    showCancelModal: false,
    isLoading: false,
  };

  @autobind
  async handleSubmit() {
    if (this.props.freeAccessStore.freeAccessMode === FreeAccessMode.CORRECT) {
      await this.withLoaderAsync<boolean>(() => this.props.freeAccessService.correctAndSubmitAnnotationForReviewAsync(), 'submit-annotation-button');
    } else {
      await this.withLoaderAsync<boolean>(() => this.props.freeAccessService.submitAnnotationsForReviewAsync(), 'submit-annotation-button');
    }
    this.setState({ showAnnotationConfirmationModal: false });
  }

  @autobind
  async handleSave() {
    if (this.props.freeAccessStore.freeAccessMode === FreeAccessMode.CORRECT) {
      await this.withLoaderAsync<boolean>(() => this.props.freeAccessService.correctAnnotationAsync(), 'save-annotation-button');
    } else {
      await this.withLoaderAsync<boolean>(() => this.props.freeAccessService.editAnnotationAsync(), 'save-annotation-button');
    }
    this.setState({ showAnnotationConfirmationModal: false });
  }

  @autobind
  async handleSaveAsDraft() {
    this.setState({ showAnnotationConfirmationModal: false });
    await this.withLoaderAsync<boolean>(() => this.props.freeAccessService.saveAnnotationDraftAsync(), 'save-draft-button');
  }

  @autobind
  toggleAnnotationConfirmationModal() {
    this.setState((prevState: IState) => ({
      ...prevState,
      showAnnotationConfirmationModal: !prevState.showAnnotationConfirmationModal,
    }));
  }

  @autobind
  async handleDiscardConfirmed() {
    if (this.state.isLoading) return;

    this.setState({ showAnnotationConfirmationModal: false });
    await this.withLoaderAsync(async () => this.props.freeAccessService.discardAnnotationAsync(this.getEditorMode()), 'discard-annotation-button');
  }

  @autobind
  async handleDiscardCanceled() {
    this.setState({ showDiscardConfirmationModal: false });
  }

  @autobind
  async handleDiscard() {
    this.setState({ showDiscardConfirmationModal: true });
  }

  @autobind
  async handleShowCancelAnnotation() {
    if (this.props.historyService.canUndo) {
      this.setState({ showCancelModal: true });
    } else {
      this.handleCancelAnnotation();
    }
  }

  @autobind
  async handleHideCancelAnnotation() {
    this.setState({ showCancelModal: false });
  }

  @autobind
  async handleCancelAnnotation() {
    this.setState({ showCancelModal: false });
    this.props.historyService.clearHistory();
    await this.withLoaderAsync(async () => this.props.freeAccessService.cancelCreateAnnotationAsync(), 'cancel-annotation-button');
  }

  @autobind
  getEditorMode(): EditorMode {
    if (this.props.freeAccessStore.freeAccessMode === FreeAccessMode.ANNOTATIONRESET) return EditorMode.FREEACCESS_EDIT;

    return EditorMode.FREEACCESS_ANNOTATION;
  }

  render() {
    const disabled =
      this.props.annotationsStore.image === undefined ||
      this.props.freeDrawSegmentationService.freeDrawInProgress ||
      this.props.freeDrawSegmentationService.freeDrawFeature !== undefined ||
      this.props.overlayLoaderStore.isOverlayVisible();

    const stage = this.props.freeAccessStore.annotateStage;
    const mode = this.props.freeAccessStore.freeAccessMode;

    const hideDraft = !this.props.freeAccessPermissions.canSaveDraft(stage, mode);
    const canSave = this.props.freeAccessPermissions.canSave(mode);
    const canSubmit = this.props.freeAccessPermissions.canSubmit(mode);
    const canDiscard = this.props.freeAccessPermissions.canDiscard(stage);

    const editorMode = this.getEditorMode();

    return (
      <>
        {!disabled && canSubmit && <SubmitHotkeyContainer onSubmit={this.toggleAnnotationConfirmationModal} />}
        {!disabled && canSave && <GlobalHotKeys keyMap={{ SUBMIT: { sequence: 'ctrl+enter', action: 'keydown' } }} handlers={{ SUBMIT: this.handleSave }} />}

        <AnnotationNavigation
          canSave={canSave}
          canSubmit={canSubmit}
          canDiscard={canDiscard}
          hideDraft={hideDraft}
          disabled={disabled}
          editorMode={editorMode}
          onSubmit={this.handleSubmit}
          onSave={this.handleSave}
          onSaveAsDraft={this.handleSaveAsDraft}
          onSkip={dinduNuffin}
          hideSkip={true}
          hideAbandonDraft={true}
          onAbandonDraft={dinduNuffin}
          showDiscardModal={this.state.showDiscardConfirmationModal}
          onDiscardCanceled={this.handleDiscardCanceled}
          onDiscardConfirmed={this.handleDiscardConfirmed}
          onDiscard={this.handleDiscard}
          onCancel={this.handleShowCancelAnnotation}
          canCancel={true}
          showCancelModal={this.state.showCancelModal}
          onCancelCancel={this.handleHideCancelAnnotation}
          onConfirmCancel={this.handleCancelAnnotation}
        />
        <AnnotationConfirmationModal
          showAnnotationConfirmationModal={this.state.showAnnotationConfirmationModal}
          annotationConfirmationModalMode={AnnotationConfirmationModalMode.SUBMIT}
          onAnnotationConfirm={this.handleSubmit}
          onAnnotationCancel={this.toggleAnnotationConfirmationModal}
          rejectAutoCompleteOptions={[]}
        />
      </>
    );
  }
}

export const FreeAccessAnnotateControlsContainer = as<React.ComponentClass>(FreeAccessAnnotateControlsContainerPure);
