import { IImageFilterService, ImageFilterServiceType } from '../../services/imageFilters.service';
import { IProjectDetailsImagesStore, ProjectDetailsImagesStoreType } from '../../projectDetailsImages.store';
import { as, injectProps } from '../../../../../../helpers/react.helpers';

import { ImageNameSearch } from '../../components/filters/ImageNameSearch';
import React from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';

interface IInjectedProps {
  imageFilterService: IImageFilterService;
  imagesStore: IProjectDetailsImagesStore;
}

@injectProps({
  imageFilterService: ImageFilterServiceType,
  imagesStore: ProjectDetailsImagesStoreType,
})
@observer
class ImageNameSearchContainerPure extends React.Component<IInjectedProps> {
  handleImageNameChangeAsync = async (imageName: string) => {
    await this.props.imageFilterService.setImageNameAsync(imageName);
  }

  render() {
    const imageFilters = this.props.imagesStore.imageFilters;
    return (
      <ImageNameSearch
        searchText={imageFilters.imageName}
        onSearchChange={this.handleImageNameChangeAsync}
      />
    );
  }
}

export const ImageNameSearchContainer = as<React.ComponentClass>(ImageNameSearchContainerPure);
