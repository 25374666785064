import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { S_Pagination, S_PaginationProps } from '../../design/pagination/S_Pagination';

import { ITranslatable } from '../helpers/translations.helpers';
import { Input } from 'reactstrap';
import React from 'react';
import { as } from '../helpers/react.helpers';
import { handleChange } from '../helpers/formHelpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  onEmptyPlaceholder?: string;
  paginationProps?: S_PaginationProps;
  onSearchChange?: (search: string) => void;
}

class TablePure extends React.Component<IProps & AgGridReactProps & ITranslatable> {
  sizeColumnsToFit(params: any) {
    params.api.sizeColumnsToFit();
  }

  render() {
    const gridOptions: any = { containerStyle: { height: 'auto' } };

    return (
      <div>
        {this.props.onSearchChange &&
          <div className="search-input-container">
            <p className="search-input-content">
              {this.props.t('search')}: <Input className="search-input" onChange={handleChange(this.props.onSearchChange)} />
            </p>
          </div>}
        <AgGridReact
          {...gridOptions}
          accentedSort={true}
          onGridSizeChanged={this.sizeColumnsToFit}
          overlayNoRowsTemplate={`<span class="no-records-message">${this.props.onEmptyPlaceholder || this.props.t('no_records_to_show')}</span>`}
          {...this.props}
        />
        {this.props.paginationProps && <S_Pagination {...this.props.paginationProps} />}
      </div>
    );
  }
}

export const Table = as<React.FC<IProps & AgGridReactProps>>(withNamespaces('common', { wait: true })(TablePure));
