import { inject, injectable } from 'inversify';
import { StickerError } from '../../../../__legacy__/models/error.model';
import { ApiServiceType } from '../../../../__legacy__/services/api.service';
import { IApiService } from '../../../../__legacy__/services/api.service.base';

export const ProjectOverviewApiServiceType = Symbol('PROJECT_OVERVIEW_API_SERVICE');

interface IProjectOverviewResponse {
  waitingForAnnotationCount: number;
  draftsCount: number;
  toReviewCount: number;
  rejectedCount: number;
  acceptedCount: number;
  unclearCount: number;
  totalImageCount: number;
  publishMoreDatasets: boolean;
  unclearImageDate?: string,
  unclearImageId?: string,
}

export interface IProjectOverviewApiService {
  getProjectOverviewAsync(projectId: string): Promise<IProjectOverviewResponse | StickerError>;
}

@injectable()
export class ProjectOverviewApiService implements IProjectOverviewApiService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService) {}

  async getProjectOverviewAsync(projectId: string) {
    return this.apiService.getAsync<IProjectOverviewResponse>('/Projects/Overview', { params: { projectId } });
  }
}
