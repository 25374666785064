import { injectable, inject } from 'inversify';
import { action } from 'mobx';
import { OverlayLoaderStoreType, IOverlayLoaderStore } from '../../../modules/common/OverlayLoader.store';
import { ErrorsStoreSetterType, IErrorsStoreSetter } from './errors.store';

export const ErrorsServiceType = Symbol('ERRORS_SERVICE');

export interface IErrorsService {
  setUnauthorized(): void;
  clearUnauthorized(): void;
}

@injectable()
export class ErrorsService implements IErrorsService {
  constructor(
    @inject(ErrorsStoreSetterType) private readonly store: IErrorsStoreSetter,
    @inject(OverlayLoaderStoreType) private readonly overlayLoaderStore: IOverlayLoaderStore,
  ) { }

  @action
  setUnauthorized() {
    if (this.store.isAuthorized) {
      this.store.setAuthorization(false);
      this.overlayLoaderStore.hideOverlay();
    }
  }

  @action
  clearUnauthorized() {
    this.store.setAuthorization(true);
  }
}
