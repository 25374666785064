import * as React from 'react';

import { FormGroup, Label } from 'reactstrap';

import { Feedback } from './Feedbacks';
import { ITranslatable } from '../helpers/translations.helpers';
import { Props } from 'react-select/lib/Select';
import Select from 'react-select';
import { as } from '../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IValidatedSelectInputProps extends Props {
  isValid?: boolean;
  feedbacks?: string[];
  label?: string;
  disabled?: boolean;
  feedbackParams?: any;
}

class ValidatedSelectInputPure extends React.Component<IValidatedSelectInputProps & ITranslatable> {
  isDisabled = (option: any) => option.disabled;

  render() {
    let validationClass = '';
    if (this.props.isValid !== undefined) validationClass = this.props.isValid ? 'is-valid' : 'is-invalid';
    return (
      <div className="validated-select-input">
        <FormGroup>
          {this.props.label && <Label>{this.props.label}</Label>}
          <Select
            classNamePrefix={`${validationClass} custom-select`}
            isOptionDisabled={this.isDisabled}
            {...this.props}
            isDisabled={this.props.disabled}
            noOptionsMessage={this.props.noOptionsMessage}
          />
          <Feedback feedbacks={this.props.feedbacks} feedbackParams={this.props.feedbackParams}/>
        </FormGroup>
      </div>
    );
  }
}

export const ValidatedSelectInput = as<React.StatelessComponent<IValidatedSelectInputProps>>(
  withNamespaces('common', { wait: true })(ValidatedSelectInputPure),
);


