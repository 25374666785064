import * as React from 'react';

import { AnnotationTypeStoreType, IAnnotationTypeStore } from '../submodules/annotationTypes/annotationType.store';
import { AnnotationsStoreType, IAnnotationsStore } from '../annotations.store';
import { as, injectProps } from '../../../helpers/react.helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  annotationTypeStore: IAnnotationTypeStore;
  annotationsStore: IAnnotationsStore;
}

@injectProps({
  annotationsStore: AnnotationsStoreType,
  annotationTypeStore: AnnotationTypeStoreType,
})
@observer
class NoAnnotationTypesWarningContainerPure extends React.Component<IInjectedProps & ITranslatable> {
  render() {
    const hasNoToolsOrQuestionsSpecified = !this.props.annotationsStore.questions.length && !this.props.annotationTypeStore.annotationTypes.length;

    return hasNoToolsOrQuestionsSpecified ? (
      <>
        <div className="free-access-actions-warning">
          <p className="warning">
            <FontAwesomeIcon className="mr-1" icon={faExclamationTriangle} />
            {this.props.t('warning')}
          </p>
          <p>
            <span className="text-center">{this.props.t('one_marking_tool_or_question_has_to_be_added')}</span>
          </p>
        </div>
      </>
    ) : <></>;
  }
}

export const NoAnnotationTypesWarningContainer = as<React.ComponentClass>(withNamespaces('annotation')(NoAnnotationTypesWarningContainerPure));
