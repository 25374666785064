import * as React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { ITranslatable } from '../helpers/translations.helpers';
import { as } from '../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface ISaveBeforeAbandonDraftConfirmationModal {
  showSaveBeforeAbandonModal: boolean;
  onSaveBeforeAbandonConfirm(): void;
  onSaveBeforeAbandonCancel(): void;
  onSaveBeforeAbandonDecline(): void;
}

class SaveBeforeAbandonDraftConfirmationModalPure extends React.Component<ISaveBeforeAbandonDraftConfirmationModal & ITranslatable> {
  render() {
    return (
      <Modal isOpen={this.props.showSaveBeforeAbandonModal} toggle={this.props.onSaveBeforeAbandonCancel}>
        <ModalHeader toggle={this.props.onSaveBeforeAbandonCancel}>{this.props.t('annotation:save_changes')}</ModalHeader>
        <ModalBody>
          <p className="mt-3">{this.props.t('draft_abandon_confirm_message')}</p>
        </ModalBody>
        <ModalFooter>
          <Button className="mr-2" color="success" onClick={this.props.onSaveBeforeAbandonConfirm}>
            {this.props.t('save')}
          </Button>
          <Button className="mr-2" color="warning" onClick={this.props.onSaveBeforeAbandonDecline}>
            {this.props.t('do_not_save')}
          </Button>
          <Button outline color="primary" onClick={this.props.onSaveBeforeAbandonCancel}>
            {this.props.t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export const SaveBeforeAbandonDraftConfirmationModal = as<React.StatelessComponent<ISaveBeforeAbandonDraftConfirmationModal>>(
  withNamespaces('annotation', { wait: true })(SaveBeforeAbandonDraftConfirmationModalPure),
);
