import { ImportAnnotationsStore, ImportAnnotationsStoreType } from '../../../../importAnnotations.store';

import { ImportSummaryPane } from './ImportSummaryPane';
import React from 'react';
import { as } from '../../../../../../../../../common/dependencyInjection/as';
import { injectProps } from '../../../../../../../../../__legacy__/helpers/react.helpers';
import { observer } from 'mobx-react';

interface InjectedProps {
  store: ImportAnnotationsStore;
}

@injectProps({
  store: ImportAnnotationsStoreType,
})
@observer
class ImportSummaryPaneContainerPure extends React.Component<InjectedProps> {
  render() {
    if (!this.props.store.report) return null;

    return (
      <ImportSummaryPane
        importedItemsCount={this.props.store.report.importedAnnotationsCount}
        skippedItemsCount={this.props.store.report.skippedAnnotationsCount}
        rejectedAnnotations={this.props.store.report.rejectedAnnotations}
      />
    );
  }
}

export const ImportSummaryPaneContainer = as<React.ComponentClass>(ImportSummaryPaneContainerPure);
