import { GTable, IGColumnProps } from '../../../components/table/GTable';
import { faCheck, faClock, faEdit, faTrashAlt, faUser } from '@fortawesome/free-solid-svg-icons';

import { CurrentWorkspaceSelectActionContainer } from '../../../../modules/workspaces/currentWorkspace/ui/selectAction/CurrentWorkspaceSelectAction.container';
import { CurrentWorkspaceSelectLinkTableContainer } from '../../../../modules/workspaces/currentWorkspace/ui/selectLinkInTable/CurrentWorkspaceSelectLinkInTable.container';
import { GetTimeZoneForOffset } from '../../../models/timeZones/timeZones.model';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { IWorkspaceListItem } from '../workspaces.store';
import { IconedButton } from '../../../components/IconedButton';
import { Loader } from '../../../components/Loader';
import React from 'react';
import { as } from '../../../helpers/react.helpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { toLocaleDateString } from '../../../helpers/date.helpers';
import { withNamespaces } from 'react-i18next';

library.add(faTrashAlt, faEdit, faUser, faCheck, faClock);

export interface IWorkspaceListProps {
  isLoading: boolean;
  currentWorkspaceId: string;
  workspaces: IWorkspaceListItem[];
  sorting: {
    orderBy: string;
    orderType: string;
  };
  pagination: {
    pageNumber: number;
    pageSize: number;
    totalCount: number;
  };
  onPaginationChange(pageNumber: number, pageSize: number): void;
  toggleRenameWorkspaceOnList(workspaceId: string): void;
  toggleChangeOwnerOnList(workspaceId: string): void;
  toggleDeleteWorkspaceOnList(workspaceId: string): void;
  toggleUpdateWorkspaceTimeZoneOnList(workspaceId: string, timeZoneOffset: number): void;
  onOrderingChange(orderBy: string, orderType: string): void;
}

class WorkspacesListPure extends React.Component<IWorkspaceListProps & ITranslatable> {
  get gColumns(): IGColumnProps<IWorkspaceListItem>[] {
    return [
      {
        field: 'name',
        headerName: this.props.t('name'),
        renderer: this.nameRenderer,
        sortable: true,
        minWidth: 400,
      },
      {
        field: 'role',
        headerName: this.props.t('my_role'),
        renderer: this.roleRenderer,
        sortable: true,
        width: 150,
      },
      {
        field: 'createdDate',
        headerName: this.props.t('created_date'),
        renderer: this.createdDateRenderer,
        width: 130,
        sortable: true,
      },
      {
        field: 'owner',
        headerName: this.props.t('owner'),
        renderer: this.ownerRenderer,
        sortable: true,
        width: 360,
      },
      {
        field: 'usersAndWorkersCount',
        headerName: this.props.t('workers_users_count'),
        renderer: this.usersCountRenderer,
        width: 100,
        sortable: true,
      },
      {
        field: 'datasetsCount',
        headerName: this.props.t('datasets_count'),
        renderer: this.datasetsCountRenderer,
        width: 110,
        sortable: true,
      },
      {
        field: 'imagesCount',
        headerName: this.props.t('images_count'),
        renderer: this.imagesCountRenderer,
        width: 90,
        sortable: true,
      },
      {
        field: 'projectsCount',
        headerName: this.props.t('projects_count'),
        renderer: this.projectsCountRenderer,
        width: 110,
        sortable: true,
      },
      {
        field: 'timeZoneOffset',
        headerName: this.props.t('time_zone'),
        renderer: this.timeZoneRenderer,
        width: 150,
        sortable: true,
      },
      {
        field: '',
        headerName: this.props.t('actions'),
        renderer: this.optionsRenderer,
        sortable: false,
        width: 150,
      },
    ];
  }

  getBoldRenderer(selector: (d: IWorkspaceListItem) => any): (data: IWorkspaceListItem) => JSX.Element {
    return (data: IWorkspaceListItem) => {
      const isBold = data.id === this.props.currentWorkspaceId;
      const value = selector(data);
      const content = value !== null ? value : ' - ';
      return <span className={isBold ? 'bold-text' : ''}>{content}</span>;
    };
  }

  onSortChanged = (orderBy: string, orderType: string) => this.props.onOrderingChange(orderBy, orderType);

  getOnClick = (id: string, callback: (id: string) => void) => () => callback(id);

  getOnToggleTimeZoneUpdateCallback = (id: string, timeZoneOffset: number, callback: (id: string, timeZoneOffset: number) => void) => () => callback(id, timeZoneOffset);

  nameRenderer = this.getBoldRenderer((workspace: IWorkspaceListItem) => (
    <CurrentWorkspaceSelectLinkTableContainer
      workspaceId={workspace.id}
      name={workspace.name}
      isEncrypted={workspace.isEncrypted}
      isLocked={workspace.isLocked}
      isMy={workspace.isOwner}
    />
  ));
  roleRenderer = this.getBoldRenderer((workspace: IWorkspaceListItem) => <span>{workspace.role}</span>);
  createdDateRenderer = this.getBoldRenderer((workspace: IWorkspaceListItem) => toLocaleDateString(workspace.createdDate.toString()));
  ownerRenderer = this.getBoldRenderer((workspace: IWorkspaceListItem) => workspace.owner);
  usersCountRenderer = this.getBoldRenderer((workspace: IWorkspaceListItem) =>
    workspace.role === 'Worker' ? (
      <span> - </span>
    ) : (
      <span title={this.props.t('workers_users_count_tooltip', { workersCount: workspace.usersAndWorkersCount.workersCount, usersCount: workspace.usersAndWorkersCount.usersCount })}>
        {workspace.usersAndWorkersCount.usersCount} + {workspace.usersAndWorkersCount.workersCount}
      </span>
    ),
  );
  datasetsCountRenderer = this.getBoldRenderer((workspace: IWorkspaceListItem) => workspace.datasetsCount);
  imagesCountRenderer = this.getBoldRenderer((workspace: IWorkspaceListItem) => workspace.imagesCount);
  projectsCountRenderer = this.getBoldRenderer((workspace: IWorkspaceListItem) => workspace.projectsCount);
  timeZoneRenderer = this.getBoldRenderer((workspace: IWorkspaceListItem) => GetTimeZoneForOffset(workspace.timeZoneOffset));
  optionsRenderer = (workspace: IWorkspaceListItem) => {
    return (
      <div className="g-actions">
        <CurrentWorkspaceSelectActionContainer workspaceId={workspace.id} isLocked={workspace.isLocked} />
        {workspace.isOwner && (
          <span>
            <IconedButton onClick={this.getOnClick(workspace.id, this.props.toggleRenameWorkspaceOnList)} icon={'edit'} title={this.props.t('rename')} />
            <IconedButton onClick={this.getOnClick(workspace.id, this.props.toggleChangeOwnerOnList)} icon={'user'} title={this.props.t('change_owner')} />
            <IconedButton
              onClick={this.getOnToggleTimeZoneUpdateCallback(workspace.id, workspace.timeZoneOffset, this.props.toggleUpdateWorkspaceTimeZoneOnList)}
              icon={'clock'}
              title={this.props.t('update_workspace_time_zone')}
            />
            <IconedButton onClick={this.getOnClick(workspace.id, this.props.toggleDeleteWorkspaceOnList)} icon={'trash-alt'} title={this.props.t('delete')} color="red" />
          </span>
        )}
      </div>
    );
  };

  render() {
    return (
      <div>
        <Loader isLoading={this.props.isLoading}>
          <div className="workspaces-table">
            <GTable<IWorkspaceListItem>
              columns={this.gColumns}
              items={this.props.workspaces}
              paginationProps={{
                pageNumber: this.props.pagination.pageNumber,
                pageSize: this.props.pagination.pageSize,
                totalCount: this.props.pagination.totalCount,
                onChange: this.props.onPaginationChange,
              }}
              sortingModel={[this.props.sorting]}
              onSortChanged={this.onSortChanged}
            />
          </div>
        </Loader>
      </div>
    );
  }
}

export const WorkspacesList = as<React.ComponentClass<IWorkspaceListProps>>(withNamespaces('common', { wait: true })(WorkspacesListPure));
