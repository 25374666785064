import * as React from 'react';

import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { ITranslatable } from '../helpers/translations.helpers';
import { InputStatus } from '../models/error.model';
import { ValidatedTextInput } from './ValidatedTextInput';
import { as } from '../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { withNamespaces } from 'react-i18next';
import { ButtonWithLoader } from './ButtonWithLoader';

interface IOuterProps {
  confirmationDescription?: any;
  confirmationQuestion: any;
  confirmationHeader: any;
  confirmationTextHeader?: any;
  confirmationText: string;
  confirmationYes?: string;
  confirmationNo?: string;
  showSpinner?: boolean;
  showModal: boolean;
  loaderKey?: string;
  onConfirm(): void;
  onCancel(): void;
}

interface IProps extends IOuterProps, ITranslatable {}

interface IState {
  confirmationStatus: InputStatus;
  confirmationText: string;
}

class ConfirmationModalWithTextBoxPure extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      confirmationStatus: InputStatus.empty(),
      confirmationText: '',
    };
  }

  @autobind
  handleOnConfirm(e: any) {
    e.preventDefault();
    if (!this.validateConfirmationText(this.state.confirmationText)) return;

    this.setState({ confirmationStatus: InputStatus.empty(), confirmationText: '' });
    this.props.onConfirm();
  }

  @autobind
  handleOnCancel() {
    this.setState({ confirmationStatus: InputStatus.empty(), confirmationText: '' });
    this.props.onCancel();
  }

  @autobind
  handleChange(confirmationText: string) {
    this.setState({ confirmationText });
    this.validateConfirmationText(confirmationText);
  }

  @autobind
  validateConfirmationText(value: string) {
    const confirmationStatus = InputStatus.valid();
    confirmationStatus.isValid = this.props.confirmationText === value;

    this.setState({ confirmationStatus });

    return confirmationStatus.isValid;
  }

  render() {
    const isConfirmDisabled = !this.state.confirmationText || !this.state.confirmationStatus.isValid;
    return (
      <Modal className="modal-width-600" isOpen={this.props.showModal} toggle={this.handleOnCancel}>
        <ModalHeader toggle={this.handleOnCancel}>{this.props.confirmationHeader}</ModalHeader>
        <Form onSubmit={this.handleOnConfirm}>
          <ModalBody>
            <div>
              {this.props.confirmationDescription && <p>{this.props.confirmationDescription}</p>}
              <p>{this.props.confirmationQuestion}</p>
              {this.props.confirmationTextHeader && this.props.confirmationTextHeader}
              <Row>
                <Col>
                  <ValidatedTextInput
                    id="confirmation-input"
                    type={'text'}
                    maxLength={200}
                    value={this.state.confirmationText}
                    placeholder={this.props.confirmationText}
                    onChange={this.handleChange}
                    isValid={this.state.confirmationStatus.isValid}
                    feedbacks={this.state.confirmationStatus.errorCodes.map(this.props.t)}
                  />
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <ButtonWithLoader
              className="mr-2"
              color="warning"
              onClick={this.handleOnConfirm}
              disabled={isConfirmDisabled}
              isLoading={this.props.showSpinner}
              loaderKey={this.props.loaderKey}
            >
              {this.props.confirmationYes || 'yes'}
            </ButtonWithLoader>
            <Button outline color="primary" onClick={this.handleOnCancel}>
              {this.props.confirmationNo || 'cancel'}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export const ConfirmationModalWithTextBox = as<React.StatelessComponent<IOuterProps>>(withNamespaces('common', { wait: true })(ConfirmationModalWithTextBoxPure));
