import * as React from 'react';

import { as, scrollTop } from '../../../../../helpers/react.helpers';
import { faEdit, faUnlink } from '@fortawesome/free-solid-svg-icons';

import { IProjectDetailsImage } from '../projectDetailsImages.model';
import { IProjectDetailsImagesListProps } from './ProjectDetailsImagesList';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { ImagesListThumbnail } from './ImagesListThumbnail';
import { Loader } from '../../../../../components/Loader';
import { S_Pagination } from '../../../../../../design/pagination/S_Pagination';
import autobind from 'autobind-decorator';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

library.add(faUnlink, faEdit);

@observer
class ProjectDetailsImagesListThumbsPure extends React.Component<IProjectDetailsImagesListProps & ITranslatable> {
  @autobind
  handleChange(pageNumber: number, pageSize: number) {
    scrollTop();
    this.props.pagination.onChange(pageNumber, pageSize);
  }

  render() {
    return (
      <div className="project-details-images">
        <Loader isLoading={this.props.isLoading} />
        {this.props.detailsImages.length === 0 && <div className="no-image-message">{this.props.t('no_images')}</div>}
        {this.props.detailsImages.length > 0 && (
          <div>
            <div className="thumbnails">
              {this.props.detailsImages.map((image: IProjectDetailsImage) => {
                const isInList = this.props.toggledImages.some(id => id === image.id);
                return (
                  <ImagesListThumbnail
                    key={image.id}
                    filterId={this.props.filterId}
                    image={image}
                    t={this.props.t}
                    disabled={this.props.disabled}
                    projectId={this.props.projectId}
                    authToken={this.props.authToken}
                    showCheckbox={this.props.canBatchAnswerQuestions}
                    canSelectImages={this.props.canSelectImages}
                    canUpdateImageSet={this.props.canUpdateImageSet}
                    onCheck={this.props.onCheck}
                    isChecked={!image.isLocked && ((isInList && this.props.selectionMode === 'Select') || (!isInList && this.props.selectionMode === 'Deselect'))}
                    workspaceId={this.props.workspaceId}
                    onImageSetChange={this.props.onImageSetChange}
                  />
                );
              })}
            </div>
            <div className="thumbnails-paging">
              <S_Pagination {...this.props.pagination} onChange={this.handleChange} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export const ProjectDetailsImagesListThumbs = as<React.ComponentClass<IProjectDetailsImagesListProps>>(
  withNamespaces('project', { wait: true })(ProjectDetailsImagesListThumbsPure),
);
