import { ClarificationsBlType, IClarificationsBl } from '../services/Clarifications.bl';
import { ClarificationsStoreType, IClarificationsStore } from '../Clarifications.store';
import { ILoaderState, WithLoaderComponentBase } from '../../../../../helpers/loader.helpers';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { EditorMode } from '../../../../../../modules/editor/models/EditorModes';
import React from 'react';
import { RequestClarificationModal } from '../components/RequestClarificationModal';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

export interface IOuterProps {
  showModal: boolean;
  editorMode: EditorMode;
  onToggle(): void;
}

interface IInjectedProps extends RouteComponentProps<{ projectId: string }> {
  clarificationStore: IClarificationsStore;
  clarificationsBl: IClarificationsBl;
}

@injectProps({
  clarificationStore: ClarificationsStoreType,
  clarificationsBl: ClarificationsBlType,
})
@observer
class RequestClarificationModalContainer extends WithLoaderComponentBase<IInjectedProps & IOuterProps, ILoaderState> {
  state: ILoaderState = {
    isLoading: false,
  };

  @autobind
  getRequestClarificationCall() {
    switch (this.props.editorMode) {
      case EditorMode.BATCH_ANNOTATION:
        return async () => await this.props.clarificationsBl.batchRequestClarificationDuringAnnotationAsync();
      case EditorMode.BATCH_REVIEW:
        return async () => await this.props.clarificationsBl.batchRequestClarificationDuringReviewAsync();
        case EditorMode.BATCH_REVIEWEDIT:
          return async () => await this.props.clarificationsBl.batchRequestClarificationDuringReviewEditAsync();
      case EditorMode.FREEACCESS_ANNOTATION:
        return async () => await this.props.clarificationsBl.freeAccessRequestClarificationDuringAnnotationAsync();
      case EditorMode.FREEACCESS_REVIEW:
        return async () => await this.props.clarificationsBl.freeAccessRequestClarificationDuringReviewAsync();
      case EditorMode.FREEACCESS_REVIEWEDIT:
        return async () => await this.props.clarificationsBl.freeAccessRequestClarificationDuringReviewEditAsync();
      case EditorMode.FREEACCESS_EDIT:
        return async () => await this.props.clarificationsBl.freeAccessRequestClarificationDuringEditAsync();
    }
  }

  @autobind
  async handleSaveClarification() {
    await this.withLoaderAsync(this.getRequestClarificationCall(), 'request-clarification-button');
    this.props.onToggle();
  }

  @autobind
  handleQuestionChange(value: string) {
    this.props.clarificationsBl.setClarificationQuestion(value);
  }

  render() {
    return (
      this.props.clarificationStore.canShowRequestClarification && (
        <RequestClarificationModal
          showModal={this.props.showModal}
          question={this.props.clarificationStore.question}
          onToggleChange={this.props.onToggle}
          onQuestionChange={this.handleQuestionChange}
          onSaveClarification={this.handleSaveClarification}
        />
      )
    );
  }
}

export default as<React.ComponentClass<IOuterProps>>(withRouter(RequestClarificationModalContainer));
