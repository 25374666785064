import { inject, injectable } from 'inversify';
import { StickerError } from '../../../../__legacy__/models/error.model';
import { ApiService } from '../../../../__legacy__/services/api.service';
import { ApiServiceImageUploadType } from '../../../../__legacy__/services/api.service.imageUpload';

export const RenameAttachmentServiceType = Symbol('RENAME_ATTACHMENT_SERVICE');

export interface IRenameAttachmentRequest {
  attachmentId: string;
  name: string;
}

export interface IRenameAttachmentService {
  renameAttachmentAsync(request: IRenameAttachmentRequest): Promise<void>;
}

@injectable()
export class RenameAttachmentService implements IRenameAttachmentService {
  constructor(@inject(ApiServiceImageUploadType) private readonly apiServiceImageUpload: ApiService) {}

  async renameAttachmentAsync(request: IRenameAttachmentRequest): Promise<void> {
    const result = await this.apiServiceImageUpload.postAsync<IRenameAttachmentRequest, void>('/Attachments/RenameAttachment', request);

    if (result instanceof StickerError) {
      throw result;
    }
  }
}
