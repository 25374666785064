import { AddMarkingToolBl, AddMarkingToolBlType } from '../../AddMarkingTool.bl';
import { AddMarkingToolStore, AddMarkingToolStoreType } from '../../AddMarkingTool.store';
import { as, injectProps } from '../../../../../../../../__legacy__/helpers/react.helpers';

import React from 'react';
import { ToolTypeField } from '../../../commons/ui/fields/ToolTypeField';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: AddMarkingToolBl;
  store: AddMarkingToolStore;
}

@injectProps({
  bl: AddMarkingToolBlType,
  store: AddMarkingToolStoreType,
})
@observer
class ToolTypeFieldContainerPure extends React.Component<InjectedProps> {
  render() {
    return <ToolTypeField onChangeToolType={this.props.bl.changeToolType} toolType={this.props.store.toolType} />;
  }
}

export const ToolTypeFieldContainer = as<React.ComponentClass>(ToolTypeFieldContainerPure);
