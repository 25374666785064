import * as React from 'react';

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { as, injectProps } from '../../../helpers/react.helpers';

import { Home } from '../../../routes/config/Home';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { TabsContainer } from '../../../containers/TabsContainer';
import { TabsSingleItem } from '../../../components/TabsSingleItem';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

@injectProps({
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
class ProjectsTabsPure extends React.Component<IInjectedProps & ITranslatable>
{
  render() {
    const path = window.location.pathname;
    let filterIndex = ['all', 'owned', 'others'].findIndex(s => path.includes(s));
    if (filterIndex < 0) {
      filterIndex = 0;
    }

    const workspaceId = this.props.currentWorkspaceStore.currentWorkspace!.id;

    return (
      <TabsContainer variant="default" className="projects-tabs">
        <TabsSingleItem
          text={this.props.t('all_projects')}
          link={Home.Projects.List.All.withParams({ workspaceId })}
          state={filterIndex === 0 ? 'active' : 'passive'}
        />
        <TabsSingleItem
          text={this.props.t('my_projects')}
          link={Home.Projects.List.Owned.withParams({ workspaceId })}
          state={filterIndex === 1 ? 'active' : 'passive'}
        />
        <TabsSingleItem
          text={this.props.t('other_projects')}
          link={Home.Projects.List.Others.withParams({ workspaceId })}
          state={filterIndex === 2 ? 'active' : 'passive'}
        />
      </TabsContainer>
    );
  }
}

export const ProjectsTabs = as<React.StatelessComponent>(withNamespaces('project', { wait: true })(ProjectsTabsPure));
