import './S_CancelButton.scss';

import React from 'react';
import { S_TextButton } from '../S_TextButton';
import { S_TextButtonProps } from '../S_TextButtonProps';

export const S_CancelButton: React.FC<S_TextButtonProps> = (props: S_TextButtonProps) => (
  <S_TextButton {...props} className="s-cancel-button">
    {props.text}
  </S_TextButton>
);
