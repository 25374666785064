import { IObservableArray, action, observable } from 'mobx';

import { Alert } from './models/Alert';
import { injectable } from 'inversify';

export const AlertBarStoreType = Symbol('ALERT_BAR_STORE');
export const AlertBarStoreSetterType = Symbol('ALERT_BAR_STORE_SETTER');

export interface IAlertBarStore {
  alerts: Alert[];
}

export interface IAlertBarStoreSetter extends IAlertBarStore {
  setAlerts(alerts: Alert[]): void;
}

@injectable()
export class AlertBarStore implements IAlertBarStore, IAlertBarStoreSetter {
  @observable
  alerts: IObservableArray<Alert> = observable.array([]);

  @action
  setAlerts(alerts: Alert[]) {
    this.alerts.replace(alerts);
  }
}
