import { IPagingInfoWithOrder, PaginationInfoDefault } from '../../__legacy__/models/paginationInfo.model';

import { injectable } from 'inversify';
import { observable } from 'mobx';
import { IModelListDto, MODEL_STATUS } from './models.model';
import { MODEL_VARIANTS } from '../../__legacy__/models/metrics.model';

export const ModelsStoreType = Symbol('MODELS_STORE');

export interface IModelListItem {
  id: string;
  modelName: string;
  modelVariant: MODEL_VARIANTS;
  projectName: string;
  createdAt: string;
  trainingTime: number;
  progress: string;
  score: number;
  status: MODEL_STATUS;
  isOwner: boolean;
  startingProgress: IModelListDto['starting_progress'];
  failureReason: string | null;
}

export interface IModelsPaging extends IPagingInfoWithOrder {}

export const defaultModelsPaging: IModelsPaging = {
  ...PaginationInfoDefault,
  orderBy: '',
  orderType: '',
};

export enum ModelOwnership {
  All = 10,
  My = 20,
  Others = 30,
}

export interface IModelsStore {
  modelsList: IModelListItem[];
  modelsPaging: IModelsPaging;
  modelOwnership: ModelOwnership;
}

@injectable()
export class ModelsStore implements IModelsStore {
  @observable
  modelsList: IModelListItem[] = [];

  @observable
  modelsPaging: IModelsPaging = defaultModelsPaging;

  @observable
  modelOwnership: ModelOwnership = ModelOwnership.All;
}
