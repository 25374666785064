import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../../../../modules/common/OverlayLoader.store';
import { IProjectDetailsActivitiesBl, ProjectDetailsActivitiesBlType } from '../projectDetailsActivities.bl';
import { IRouterStore, RouterStoreType } from '../../../../../stores/router.store';
import { IUserService, UserServiceType } from '../../../../user/user.service';
import { IWorkspaceService, WorkspaceServiceType } from '../../../../workspaces/workspaces.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { ActivityLog } from '../components/ActivityLog';
import { ExportDataFilter } from '../../../../projects/projects.model';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { ProjectActivity } from '../projectDetailsActivities.models';
import React from 'react';
import { Settings } from '../../../../../../modules/settings/SettingsContext';
import _ from 'lodash';
import { action } from 'mobx';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  workspaceService: IWorkspaceService;
  routerStore: IRouterStore;
  projectDetailsActivitiesBl: IProjectDetailsActivitiesBl;
  userService: IUserService;
  overlayLoader: IOverlayLoaderStore;
}

interface IState {
  actor: string;
}

interface IOuterProps {
  projectIdKey: string;
}

type Props = IInjectedProps & IOuterProps & ITranslatable & RouteComponentProps<any>;

@injectProps({
  workspaceService: WorkspaceServiceType,
  routerStore: RouterStoreType,
  projectDetailsActivitiesBl: ProjectDetailsActivitiesBlType,
  userService: UserServiceType,
  overlayLoader: OverlayLoaderStoreType,
})
@observer
class ActivityLogContainerPure extends React.Component<Props, IState> {
  static contextType = Settings;
  declare context: React.ContextType<typeof Settings>;

  constructor(props: Props) {
    super(props);

    this.state = {
      actor: '',
    };
  }

  mapProjectActivitiesToSelectFormat(activitiesTypes: ProjectActivity[]) {
    return activitiesTypes.map(o => ({ label: this.props.t(`activity_type_${o}`), value: o.toString() }));
  }

  mapProjectActivitiesToStrings = (activitiesTypes: ProjectActivity[]) => activitiesTypes.map(a => a.toString());

  componentDidMount() {
    this.props.projectDetailsActivitiesBl.getProjectActivitiesAsync(
      this.props.match.params[this.props.projectIdKey],
      this.mapProjectActivitiesToStrings(this.props.projectDetailsActivitiesBl.store.selectedProjectActivities),
      this.state.actor,
      this.props.projectDetailsActivitiesBl.store.projectActivitiesPaging,
    );
  }

  @autobind
  async handleActorChange(actor: string) {
    this.setState({ actor });
    await this.handleSearchChangeDebounced();
  }

  @autobind
  @action
  async handleActivitiesChange(selectedActivities: { label: string; value: string }[]) {
    this.props.projectDetailsActivitiesBl.store.selectedProjectActivities = selectedActivities.map(sa => ProjectActivity[sa.value as keyof typeof ProjectActivity]);
    await this.props.projectDetailsActivitiesBl.getProjectActivitiesAsync(
      this.props.match.params[this.props.projectIdKey],
      selectedActivities.map(a => a.value),
      this.state.actor,
      { ...this.props.projectDetailsActivitiesBl.store.projectActivitiesPaging, pageNumber: 1 },
    );
  }

  @autobind
  async handleSearchChange() {
    await this.props.projectDetailsActivitiesBl.getProjectActivitiesAsync(
      this.props.match.params[this.props.projectIdKey],
      this.mapProjectActivitiesToStrings(this.props.projectDetailsActivitiesBl.store.selectedProjectActivities),
      this.state.actor,
      { ...this.props.projectDetailsActivitiesBl.store.projectActivitiesPaging, pageNumber: 1 },
    );
  }

  handleSearchChangeDebounced = _.debounce(this.handleSearchChange, 1000, {
    leading: false,
    trailing: true,
  });

  @autobind
  async handlePaginationChange(pageNumber: number, pageSize: number) {
    this.props.projectDetailsActivitiesBl.store.projectActivitiesPaging.pageNumber = pageNumber;
    this.props.projectDetailsActivitiesBl.store.projectActivitiesPaging.pageSize = pageSize;
    this.context.setActivityLogPageSize(pageSize);

    await this.props.projectDetailsActivitiesBl.getProjectActivitiesAsync(
      this.props.match.params[this.props.projectIdKey],
      this.mapProjectActivitiesToStrings(this.props.projectDetailsActivitiesBl.store.selectedProjectActivities),
      this.state.actor,
      this.props.projectDetailsActivitiesBl.store.projectActivitiesPaging,
    );
  }

  @autobind
  async handleOrderingChange(orderBy: string, orderType: string) {
    this.props.projectDetailsActivitiesBl.store.projectActivitiesPaging.orderBy = orderBy;
    this.props.projectDetailsActivitiesBl.store.projectActivitiesPaging.orderType = orderType || 'asc';
    await this.props.projectDetailsActivitiesBl.getProjectActivitiesAsync(
      this.props.match.params[this.props.projectIdKey],
      this.mapProjectActivitiesToStrings(this.props.projectDetailsActivitiesBl.store.selectedProjectActivities),
      this.state.actor,
      this.props.projectDetailsActivitiesBl.store.projectActivitiesPaging,
    );
  }

  @autobind
  async handleExportDownload(backupId: string, schemaVersion: string, dataFilter?: ExportDataFilter) {
    this.props.overlayLoader.enableLoader('project-export-button');
    await this.props.projectDetailsActivitiesBl.downloadExportAsync(this.props.match.params[this.props.projectIdKey], backupId, dataFilter);
    this.props.overlayLoader.disableLoader('project-export-button');
  }

  render() {
    return (
      <ActivityLog
        activities={this.props.projectDetailsActivitiesBl.store.projectActivities}
        isLoading={this.props.projectDetailsActivitiesBl.store.showLoader}
        searchValue={this.state.actor}
        selectedActivities={this.mapProjectActivitiesToSelectFormat(this.props.projectDetailsActivitiesBl.store.selectedProjectActivities)}
        paging={{ ...this.props.projectDetailsActivitiesBl.store.projectActivitiesPaging, pageSize: this.context.store.activityLogPageSize }}
        onActorChange={this.handleActorChange}
        onActivitiesChange={this.handleActivitiesChange}
        onPaginationChange={this.handlePaginationChange}
        onOrderingChange={this.handleOrderingChange}
        onExportDownload={this.handleExportDownload}
      />
    );
  }
}

export const ActivityLogContainer = as<React.ComponentClass<IOuterProps>>(withRouter(withNamespaces('common')(ActivityLogContainerPure)));
