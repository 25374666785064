import { IImagesQueueService, IQueueItem } from '../annotation/imagesQueueServiceBase';
import { inject, injectable } from 'inversify';

import { ApiServiceType } from '../../services/api.service';
import { IApiService } from '../../services/api.service.base';
import { IFreeAccessApiService, FreeAccessApiServiceType, IFreeAccessPagingItem, IGetImagesPagingPayload } from './freeAccessApi.service';
import { StickerError } from '../../models/error.model';
import { AnnotationStatus, IImage, LOW_QUALITY_PRELOAD_MIN_IMAGE_SIZE, NoImageReason } from '../annotation/annotations.interface';
import { IFreeAccessStore, FreeAccessStoreType, AnnotateStage, FreeAccessMode } from './freeAccess.store';
import { RouterStoreType, IRouterStore } from '../../stores/router.store';
import { Home } from '../../routes/config/Home';
import { action, observable, runInAction } from 'mobx';
import { IConfiguration, ConfigurationType } from '../../../configuration';
import { CacheManager } from '../../services/cacheManager';
import { INotificationsService, NotificationsServiceType } from '../notifications/services/notifications.service';
import { NotificationLevel, ToastNotification } from '../notifications/models/notification.model';

export const FreeAccessImagesQueueServiceType = Symbol('FREE_ACCESS_IMAGES_QUEUE_SERVICE');

declare global {
  interface Window {
    activeIntervals: number[];
  }
}

@injectable()
export class FreeAccessImagesQueueService implements IImagesQueueService {
  refreshImageLock?: (imageId: string) => Promise<void | StickerError>;

  constructor(
    @inject(ApiServiceType) private apiService: IApiService,
    @inject(FreeAccessApiServiceType) private freeAccessApiService: IFreeAccessApiService,
    @inject(FreeAccessStoreType) private freeAccessStore: IFreeAccessStore,
    @inject(RouterStoreType) private routerStore: IRouterStore,
    @inject(ConfigurationType) private config: IConfiguration,
    @inject(NotificationsServiceType) private notificationService: INotificationsService,
  ) {
    this.freeAccessApiService = freeAccessApiService;
    this.freeAccessStore = freeAccessStore;
    this.routerStore = routerStore;
    this.config = config;
  }

  projectId!: string;

  @observable
  image?: IQueueItem;

  @observable
  areAnyImagesToLoad: boolean = true;

  @observable
  isAnythingToDisplay: boolean = true;

  @observable
  noImageReason: NoImageReason = NoImageReason.Unknown;

  startAsync(): Promise<void> {
    return Promise.resolve();
  }

  dispose(): void {}

  public clear() {
    this.removeLockRefresh();
    this.areAnyImagesToLoad = true;
    this.isAnythingToDisplay = false;
  }

  public async setupAsync(workspaceId: string, projectId: string, imageId: string, filterId: string, mode: FreeAccessMode) {
    this.projectId = projectId;
    this.refreshImageLock = (imageId: string) => {
      if (mode === FreeAccessMode.VIEW) return Promise.resolve();
      return this.freeAccessApiService.reLockImageAsync(imageId, projectId, mode);
    };

    const [gotPaging, imageInfoResult] = await Promise.all([
      this.getPaging(projectId, workspaceId, filterId),
      this.freeAccessApiService.getImageInfoToFreeAccess(projectId, imageId),
    ]);

    if (!gotPaging) return;

    let imageInfo: IImage | undefined = undefined;

    this.freeAccessStore.showImageDeletedMessage = false;
    if (imageInfoResult instanceof StickerError) {
      if (imageInfoResult.isBadRequestWithCode(['IMAGE_ALREADY_LOCKED'])) {
        this.routerStore.push(Home.FreeAccess.View.withParams({ workspaceId, projectId, imageId, filterId }));
      }
      if (imageInfoResult.isBadRequestWithCode(['LOCKING_IMAGE_NOT_ALLOWED_NOT_ENOGH_FUNDS'])) {
        this.freeAccessStore.canAddAnnotations = false;
        this.routerStore.push(Home.FreeAccess.View.withParams({ workspaceId, projectId, imageId, filterId }));
      }
      if (imageInfoResult.withCode(['IMAGE_NOT_AVAILABLE'])) {
        this.freeAccessStore.showImageDeletedMessage = true;
      }

      imageInfo = undefined;
    } else {
      imageInfo = imageInfoResult;
    }

    if (!imageInfo) {
      this.image = undefined;
    } else if (this.image === undefined || (mode === FreeAccessMode.VIEW && this.image.image.id !== imageId)) {
      this.image = observable({
        image: observable({ ...imageInfo, url: '', lowQualityUrl: '' }),
      });
    } else {
      this.image.image.annotations = imageInfo?.annotations;
    }

    if (this.image && mode !== FreeAccessMode.VIEW) {
      this.addLockRefresh(this.image);
    }

    let annotateStage: AnnotateStage;
    if (!imageInfo?.annotations) {
      annotateStage = AnnotateStage.NOTANNOTATED;
    } else if (imageInfo.annotations.status === AnnotationStatus.TOREVIEW) {
      annotateStage = AnnotateStage.ANNOTATED;
    } else if (imageInfo.annotations.status === AnnotationStatus.DRAFT) {
      annotateStage = AnnotateStage.DRAFT;
    } else if (imageInfo.annotations.status === AnnotationStatus.REJECTED) {
      annotateStage = AnnotateStage.REJECTED;
    } else {
      annotateStage = AnnotateStage.REVIEWED;
    }

    runInAction(() => {
      this.freeAccessStore.status = imageInfo?.annotations?.status;
      this.freeAccessStore.annotateStage = annotateStage;
      this.freeAccessStore.rejectionReason = imageInfo?.annotations?.reviewRejectionReason;
      this.freeAccessStore.imageId = imageInfo?.id || '';
      this.freeAccessStore.projectId = projectId;
    });

    const currentIndex = this.freeAccessStore.paging.items.find(x => x.imageId === imageId)?.index || 0;

    if (!currentIndex) {
      this.routerStore.push(Home.Projects.Details.Images.withParams({ projectId, workspaceId }));
    }

    runInAction(() => {
      this.freeAccessStore.index = this.freeAccessStore.paging.items.find(x => x.imageId === imageId)?.index || 0;
      this.freeAccessStore.total = this.freeAccessStore.paging.items.length;
      const next = this.freeAccessStore.paging.items.find(x => x.index === currentIndex + 1);
      this.freeAccessStore.nextId = next ? next.imageId : '';
      const previous = this.freeAccessStore.paging.items.find(x => x.index === currentIndex - 1);
      this.freeAccessStore.previousId = previous ? previous.imageId : '';
      this.freeAccessStore.annotatedByName = imageInfo?.annotations?.author || '';
      this.freeAccessStore.reviewedByName = imageInfo?.annotations?.reviewedByName || '';
      this.freeAccessStore.lockedByName = imageInfo?.lockedByName || '';
      this.freeAccessStore.canAddAnnotations = imageInfo?.canAddAnnotations || true;
      this.isAnythingToDisplay = true;
      this.freeAccessStore.isAssignedToCurrentUser = imageInfo?.annotations?.isAssignedToCurrentUser || false;
      this.freeAccessStore.isAssigned = imageInfo?.annotations?.isAssigned || false;
      this.freeAccessStore.isImported = imageInfo?.annotations?.isImported || false;
      this.freeAccessStore.isWaitingForReviewAfterReject = imageInfo?.annotations?.isWaitingForReviewAfterReject || false;
    });

    if (imageInfo) this.startLoading(currentIndex);
  }

  @action
  public takeImageFromQueue(): IImage | undefined {
    return this.image?.image;
  }

  async getPaging(projectId: string, workspaceId: string, filterId: string): Promise<boolean> {
    const payload: IGetImagesPagingPayload = {
      projectId,
      filterId,
      lastUpdate: this.freeAccessStore.paging ? this.freeAccessStore.paging.checkDate : undefined,
      orderBy: '',
      orderType: '',
    };

    if (this.freeAccessStore.paging.items.length === 0) {
      const paging = await this.freeAccessApiService.getFreeAccessPaging(payload);
      if (paging instanceof StickerError || paging === null) {
        this.routerStore.push(Home.Projects.Details.Images.withParams({ projectId, workspaceId }));
        return false;
      }
      this.freeAccessStore.paging = paging;
    }
    return true;
  }

  async startLoading(currentIndex: number) {
    await Promise.all([this.loadLowQualityImage(currentIndex), this.loadImage(currentIndex)]);
  }

  async loadLowQualityImage(currentIndex: number) {
    if (!this.image) return;
    if (this.image.image.size >= LOW_QUALITY_PRELOAD_MIN_IMAGE_SIZE) {
      const imageId = this.image.image.id;
      const imageFile = await this.apiService.getImageAsync(this.apiService.getUrl(`/Images/GetImageThumbnail/${imageId}`));
      if (!(imageFile instanceof StickerError) && this.image && this.image.image.id === imageId && this.image.image.lowQualityUrl === '') {
        this.image.image.lowQualityUrl = URL.createObjectURL(imageFile.blob);
      }
    }

    this.preLoadNextLowQualityImages(currentIndex);
  }

  async loadImage(currentIndex: number) {
    const imageId = this.image && this.image.image.id;

    const hasActivePreload = this.freeAccessStore.activePreloads.some(x => x === imageId);

    if (!hasActivePreload) {
      const imageFile = await this.apiService.getImageAsync(this.apiService.getUrl(`/Images/GetImage/${imageId}`));
      if (this.image && this.image.image.id === imageId && this.image.image.url === '' && !(imageFile instanceof StickerError)) {
        this.image.image.url = URL.createObjectURL(imageFile.blob);
      }
    }

    this.preLoadNextImages(currentIndex);
  }

  async preLoadNextImages(currentIndex: number) {
    const cacheAvailable = await CacheManager.checkCacheAvailabilityAsync();
    if (!cacheAvailable) return;

    const range = this.config.appConfig.imagePreloadRange;
    const imagesToPreLoadIds = this.freeAccessStore
      .paging!.items.filter(x => x.index > currentIndex - range && x.index < currentIndex + range && x.index !== currentIndex)
      .sort((n1, n2) => Math.abs(n1.index - currentIndex) - Math.abs(n2.index - currentIndex));

    await imagesToPreLoadIds.forEach(async (item: IFreeAccessPagingItem) => {
      if (!this.freeAccessStore.activePreloads.some(x => x === item.imageId)) {
        this.freeAccessStore.activePreloads.push(item.imageId);
        const imageFile = await this.apiService.getImageAsync(this.apiService.getUrl(`/Images/GetImage/${item.imageId}`));
        if (this.image && this.image.image.id === item.imageId && !(imageFile instanceof StickerError)) {
          this.image.image.url = URL.createObjectURL(imageFile.blob);
        }
        const index = this.freeAccessStore.activePreloads.findIndex(x => x === item.imageId);
        if (index > -1) {
          this.freeAccessStore.activePreloads.splice(index, 1);
        }
      }
    });
  }

  async preLoadNextLowQualityImages(currentIndex: number) {
    const cacheAvailable = await CacheManager.checkCacheAvailabilityAsync();
    if (!cacheAvailable) return;

    const range = this.config.appConfig.imagePreloadRange;
    const imagesToPreLoadIds = this.freeAccessStore
      .paging!.items.filter(x => x.index > currentIndex - range && x.index < currentIndex + range && x.index !== currentIndex)
      .sort((n1, n2) => Math.abs(n1.index - currentIndex) - Math.abs(n2.index - currentIndex));

    await imagesToPreLoadIds.forEach(async (item: IFreeAccessPagingItem) => {
      if (item.size >= LOW_QUALITY_PRELOAD_MIN_IMAGE_SIZE && !this.freeAccessStore.activePreloads.some(x => x === item.imageId)) {
        this.freeAccessStore.activePreloads.push(item.imageId);
        const imageFile = await this.apiService.getImageAsync(this.apiService.getUrl(`/Images/GetImageThumbnail/${item.imageId}`));
        if (this.image && this.image.image.id === item.imageId && !(imageFile instanceof StickerError)) {
          this.image.image.lowQualityUrl = URL.createObjectURL(imageFile.blob);
        }
        const index = this.freeAccessStore.activePreloads.findIndex(x => x === item.imageId);
        if (index > -1) {
          this.freeAccessStore.activePreloads.splice(index, 1);
        }
      }
    });
  }

  private addLockRefresh(item: IQueueItem): void {
    this.removeLockRefresh();

    if (this.refreshImageLock) {
      item.reLockIntervalId = window.setInterval(() => this.refreshImage(item.image.id), this.config.appConfig.reLockInterval * 60 * 1000);
      window.activeIntervals.push(item.reLockIntervalId);
    }
  }

  private async refreshImage(imageId: string) {
    const result = await this.refreshImageLock!(imageId);
    if (result && result.isBadRequestWithCode(['IMAGE_ALREADY_LOCKED'])) {
      this.notificationService.push(new ToastNotification(NotificationLevel.ERROR, 'image_is_locked_by_another_user'));
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }
  }

  private removeLockRefresh(): void {
    window.activeIntervals = window.activeIntervals || [];
    window.activeIntervals.forEach(x => window.clearInterval(x));
  }
}
