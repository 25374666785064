import { ImportAnnotationsStore, ImportAnnotationsStoreType } from '../../../../importAnnotations.store';

import React from 'react';
import { UnregisteredUsersSummaryPane } from './UnregisteredUsersSummaryPane';
import { as } from '../../../../../../../../../common/dependencyInjection/as';
import { injectProps } from '../../../../../../../../../__legacy__/helpers/react.helpers';
import { observer } from 'mobx-react';

interface InjectedProps {
  store: ImportAnnotationsStore;
}

@injectProps({
  store: ImportAnnotationsStoreType,
})
@observer
class UnregisteredUsersSummaryPaneContainerPure extends React.Component<InjectedProps> {
  render() {
    if (!this.props.store.report || this.props.store.report.unregisteredUsers.length === 0) return null;

    return <UnregisteredUsersSummaryPane unregisteredUsers={this.props.store.report!.unregisteredUsers} />;
  }
}

export const UnregisteredUsersSummaryPaneContainer = as<React.ComponentClass>(UnregisteredUsersSummaryPaneContainerPure);
