import { inject, injectable } from 'inversify';
import { ApiServiceType } from '../../services/api.service';
import { IApiService } from '../../services/api.service.base';
import { ApiServiceTrainingType } from '../../services/api.service.training';
import { IModelDetailsDto, IModelImagePredictionsDto } from '../../../modules/modelDetails/modelDetails.model';
import { StickerError } from '../../models/error.model';
import {
  IEvaluationDetailsDto,
  IEvaluationImagePredictionsDto,
} from '../../../modules/evaluationDetails/evaluationDetails.model';
import { IDatasetDetails, IDatasetPreviewImage } from '../../modules/datesets/datasetsDetails.store';
import { IImageAnnotationsResponse } from '../../modules/annotation/submodules/segmentations/segmentationContextMenu/segmentationsApi.service';

export type IGetAllImages = Array<{
  fileStorageId: string;
  id: string;
  name: string;
}>;

export const PredictionImagesPreviewApiServiceType = Symbol('PREDICTION_IMAGES_PREVIEW_API_SERVICE');

export interface IPredictionImagesPreviewApiService {
  getModelDetailsAsync(workspaceId: string, modelId: string): Promise<IModelDetailsDto | StickerError>;
  getEvaluationDetailsAsync(workspaceId: string, evaluationId: string): Promise<IEvaluationDetailsDto | StickerError>;
  getEvaluationImageDetailsAsync(workspaceId: string, evaluationId: string, imagesIds: string[]): Promise<IEvaluationImagePredictionsDto | StickerError>;
  getTrainingImageDetailsAsync(workspaceId: string, trainingId: string, imagesIds: string[]): Promise<IModelImagePredictionsDto | StickerError>;
  getDatasetImages(datasetId: string): Promise<IGetAllImages | StickerError>;
  getDatasetDetails(datasetId: string): Promise<IDatasetDetails | StickerError>;
  getImageDetails(datasetId: string, imageId: string): Promise<IDatasetPreviewImage | StickerError>;
  peekImageAnnotations(imageId: string): Promise<IImageAnnotationsResponse | StickerError>;
}

@injectable()
export class PredictionImagesPreviewApiService implements IPredictionImagesPreviewApiService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService, @inject(ApiServiceTrainingType) private readonly apiTrainingService: IApiService) {}

  async getModelDetailsAsync(workspaceId: string, modelId: string): Promise<IModelDetailsDto | StickerError> {
    return await this.apiTrainingService.getAsync(`/workspaces/${workspaceId}/training-jobs/${modelId}`);
  }

  async getEvaluationDetailsAsync(workspaceId: string, evaluationId: string): Promise<IEvaluationDetailsDto | StickerError> {
    return await this.apiTrainingService.getAsync(`/workspaces/${workspaceId}/inference-jobs/${evaluationId}`);
  }

  async getDatasetImages(datasetId: string): Promise<IGetAllImages | StickerError> {
    return await this.apiService.getAsync(`/Models/GetAllImages`, {
      params: {
        datasetId,
      },
    });
  }

  async getDatasetDetails(datasetId: string): Promise<IDatasetDetails | StickerError> {
    return await this.apiService.getAsync(`/datasets/details`, {
      params: {
        DatasetId: datasetId,
      },
    });
  }

  async getImageDetails(datasetId: string, imageId: string): Promise<IDatasetPreviewImage | StickerError> {
    return await this.apiService.getAsync(`/datasets/${datasetId}/imageInfo/${imageId}`);
  }

  async peekImageAnnotations(imageId: string): Promise<IImageAnnotationsResponse | StickerError> {
    return await this.apiService.getAsync(`/Images/PeekImageAnnotations?imageId=${imageId}`);
  }

  async getEvaluationImageDetailsAsync(workspaceId: string, evaluationId: string, imagesIds: string[]): Promise<IEvaluationImagePredictionsDto | StickerError> {
    return await this.apiTrainingService.postAsync(`/workspaces/${workspaceId}/inference-jobs/${evaluationId}/image-metrics`, imagesIds);
  }

  async getTrainingImageDetailsAsync(workspaceId: string, trainingId: string, imagesIds: string[]): Promise<IModelImagePredictionsDto | StickerError> {
    return await this.apiTrainingService.postAsync(`/workspaces/${workspaceId}/training-jobs/${trainingId}/image-metrics`, imagesIds);
  }
}
