import { action, observable } from 'mobx';

import { AnnotationToolType } from '../../../../../../__legacy__/modules/annotation/submodules/annotationTypes/models/annotationToolType';
import { InputStatus } from '../../../../../../__legacy__/models/error.model';
import { MarkingTool } from '../commons/models/MarkingTool';
import { ProjectStatus } from '../../../../../../__legacy__/modules/projects/projects.model';
import { injectable } from 'inversify';

export const EditMarkingToolStoreType = Symbol('EDIT_MARKING_TOOL_STORE');
export const EditMarkingToolStoreSetterType = Symbol('EDIT_MARKING_TOOL_STORE_SETTER');

export interface EditMarkingToolStore {
  readonly markingToolId: string;
  readonly name: string;
  readonly nameStatus: InputStatus;
  readonly projectId: string;
  readonly projectStatus: ProjectStatus;
  readonly showModal: boolean;
  readonly toolType: AnnotationToolType;
  readonly color: string;
  readonly isUsed: boolean;
}

export interface EditMarkingToolStoreSetter extends EditMarkingToolStore {
  setName(name: string): void;
  setNameStatus(nameStatus: InputStatus): void;
  setShowModal(showCreateForm: boolean): void;
  setToolType(toolType: AnnotationToolType): void;
  setColor(color: string): void;
  setMarkingTool(annotationType: MarkingTool): void;
  setProjectData(projectId: string, projectStatus: ProjectStatus): void;
  setProjectId(projectId: string): void;
  setupStore(projectId: string, markingTool: MarkingTool): void;
}

@injectable()
export class EditMarkingToolStoreImpl implements EditMarkingToolStoreSetter {
  markingToolId: string = '';

  @observable
  name: string = '';
  
  @observable
  nameStatus: InputStatus = InputStatus.valid();
  
  @observable
  showModal: boolean = false;
  
  @observable
  toolType: AnnotationToolType = AnnotationToolType.RECTANGLE;
  
  @observable
  color: string = '';
  
  @observable
  order: number = 0;
  
  @observable
  isUsed: boolean = false;

  projectId: string = '';
  projectStatus: ProjectStatus = ProjectStatus.Draft;

  setProjectId(projectId: string): void {
    this.projectId = projectId;
  }

  @action
  setProjectData(projectId: string, projectStatus: ProjectStatus): void {
    this.projectId = projectId;
    this.projectStatus = projectStatus;
  }

  setName(name: string): void {
    this.name = name;
  }

  setNameStatus(nameStatus: InputStatus) {
    this.nameStatus = nameStatus;
  }

  setShowModal(showModal: boolean) {
    this.showModal = showModal;
  }

  setToolType(toolType: AnnotationToolType): void {
    this.toolType = toolType;
  }

  setColor(color: string): void {
    this.color = color;
  }

  @action
  setMarkingTool(markingTool: MarkingTool): void {
    this.markingToolId = markingTool.id;
    this.name = markingTool.name;
    this.color = markingTool.color;
    this.order = markingTool.order;
    this.toolType = markingTool.selectorType;
    this.isUsed = markingTool.isUsed;
  }

  @action
  setupStore(projectId: string, markingTool: MarkingTool): void {
      this.setMarkingTool(markingTool);
      this.projectId = projectId;
      this.nameStatus = InputStatus.empty();
      this.showModal = true;
  }
}
