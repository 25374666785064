import leaflet, { LatLngBounds, LatLng, ImageOverlayOptions } from 'leaflet';
import { ImageOverlay } from 'react-leaflet';

export class RotableImageOverlay extends leaflet.ImageOverlay {
  IsPortrait: boolean;
  Width: number;
  Height: number;

  constructor(instance: ImageOverlay) {
    const expandedInstance = (instance as unknown) as ExpandedImageOverlay;
    super(expandedInstance._url, expandedInstance._bounds, expandedInstance.options);

    this.IsPortrait = expandedInstance._image.naturalHeight > expandedInstance._image.naturalWidth;

    this.Width = expandedInstance._image.naturalWidth;
    this.Height = expandedInstance._image.naturalHeight;
  }

  getBounds(): LatLngBounds {
    const bounds: LatLngBounds = super.getBounds();

    const aspect = this.shouldRotate(bounds) && this.Height !== 0 ? this.Width / this.Height : 1;

    const northEast =
      !this.IsPortrait && this.shouldRotate(bounds)
        ? new LatLng(this.Height, this.Width)
        : new LatLng(this.Height * aspect, this.Width * aspect);

    const newBounds = this.shouldRotate(bounds)
      ? new LatLngBounds(new LatLng(0, 0), new LatLng(this.Width, this.Width * aspect))
      : new LatLngBounds(new LatLng(0, 0), northEast);

    this.setBounds(newBounds);

    return newBounds;
  }

  private shouldRotate(bounds: LatLngBounds) {
    return (
      (this.IsPortrait && bounds.getNorthEast().lng > bounds.getNorthEast().lat) ||
      (!this.IsPortrait && bounds.getNorthEast().lng < bounds.getNorthEast().lat)
    );
  }
}

interface ExpandedImageOverlay extends leaflet.ImageOverlay {
  _image: HTMLImageElement;
  _url: string;
  _bounds: LatLngBounds;
  options: ImageOverlayOptions;
}
