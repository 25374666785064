import * as React from 'react';

import { Button } from 'reactstrap';
import { DatasetStatus } from '../datasetStatus.model';
import { DecryptionScriptSampleContainer } from '../../../containers/DecryptionScriptSample.container';
import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { ExternalLinkedButton } from '../../../components/ExternalLinkedButton';
import { IDatasetDetails } from '../datasetsDetails.store';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { NoAccessTooltip } from '../../../containers/NoAccessTooltip';
import { UpdateDatasetContainer } from '../containers/UpdateDataset.container';
import { as } from '../../../helpers/react.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

export interface IDatasetDetailsActionsProps {
  details: IDatasetDetails;
  isDeleteDisabled: boolean;
  isDatasetPublishable: boolean;
  downloadLink: string;
  isImageCountExceeded: boolean;
  availableImageCount: number;
  ownerPlan: string;
  onShowDatasetDeleteConfirmationModal(datasetId: string): void;
  onShowPublishDatasetConfirmationModal(): void;
  canPublishDataset: boolean;
  canDownloadDataset: boolean;
  canDeleteDataset: boolean;
}

const DatasetDetailsActionsPure = (props: IDatasetDetailsActionsProps & ITranslatable) => {
  const getPublishButtonTitle = function () {
    if (props.details.status === DatasetStatus.UPLOADING) {
      return props.t('during_upload_publish_is_not_possible');
    }
    if (props.details.imagesCount === 0) {
      return props.t('publishing_empty_dataset_is_not_possible');
    }
    return undefined;
  };

  const showDeleteModal = () => props.onShowDatasetDeleteConfirmationModal('');

  return (
    <div className="dataset-actions">
      <UpdateDatasetContainer />
      {props.details.status === DatasetStatus.DRAFT || props.details.status === DatasetStatus.UPLOADING ? (
        <NoAccessTooltip hasAccess={props.canPublishDataset} title={props.t('you_do_not_have_permission_to_publish_dataset')}>
          <DisabledWhenWorkspaceOwnerPolicyExceeded>
            <Button id="publish-dataset-button" color="success" disabled={!props.isDatasetPublishable} onClick={props.onShowPublishDatasetConfirmationModal} title={getPublishButtonTitle()}>
              {props.t('publish_dataset')}
            </Button>
          </DisabledWhenWorkspaceOwnerPolicyExceeded>
        </NoAccessTooltip>
      ) : (
        <></>
      )}
      <NoAccessTooltip hasAccess={props.canDownloadDataset} title={props.t(`cannot_download_${props.details.downloadProhibitionReason}`)}>
        <DisabledWhenWorkspaceOwnerPolicyExceeded>
          <ExternalLinkedButton
            id="download-dataset-button"
            to={props.details.canDownload ? props.downloadLink : ''}
            color="primary"
            target="_self"
            disabled={!props.details.canDownload}
            title={!props.details.canDownload ? props.t(`cannot_download_${props.details.downloadProhibitionReason}`) : undefined}
          >
            {props.t('download_dataset')}
          </ExternalLinkedButton>
        </DisabledWhenWorkspaceOwnerPolicyExceeded>
      </NoAccessTooltip>
      <DecryptionScriptSampleContainer />
      <NoAccessTooltip hasAccess={props.canDeleteDataset}>
        <Button
          id="delete-dataset-button"
          disabled={props.isDeleteDisabled}
          color="warning"
          onClick={showDeleteModal}
          title={props.isDeleteDisabled ? props.t('delete_error_dataset_assigned_to_project') : ''}
        >
          {props.t('delete_dataset')}
        </Button>
      </NoAccessTooltip>
      {props.isImageCountExceeded && (
        <p className="error-feedback">
          {props.t('publishing_dataset_will_exceed_workspace_owners_total_files_limit', { availableImageCount: props.availableImageCount, plan: props.t(props.ownerPlan) })}
        </p>
      )}
    </div>
  );
};

export const DatasetDetailsActions = as<React.ComponentClass<IDatasetDetailsActionsProps>>(withNamespaces('datasets', { wait: true })(observer(DatasetDetailsActionsPure)));
