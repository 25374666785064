import { injectable, inject } from 'inversify';
import { StickerError } from '../../../__legacy__/models/error.model';
import { ApiServiceType } from '../../../__legacy__/services/api.service';
import { IApiService } from '../../../__legacy__/services/api.service.base';
import {
  EditAndAcceptAnnotationRequest,
  EditAndRejectAnnotationRequest,
  IAcceptAnnotationRequest,
  ICorrectAndSubmitAnnotationForReviewRequest,
  ICorrectAnnotationRequest,
  ISaveAnnotationDraftRequest,
  IRejectAnnotationRequest,
  ISubmitAnnotationForReviewRequest,
  IRequestClarificationDuringAnnotationRequest,
  IRequestClarificationDuringReviewRequest,
  IAbandonDraftRequest,
  IDiscardAnnotationDuringAnnotationRequest,
  IDiscardAnnotationDuringReviewRequest,
  IRequestClarificationDuringReviewEditRequest,
  IDiscardAnnotationDuringReviewEditRequest,
} from '../models/Requests';

export const BatchAnnotationApiServiceType = Symbol('BATCH_ANNOTATION_API_SERVICE');

export interface IBatchAnnotationApiService {
  submitForReview(annotation: ISubmitAnnotationForReviewRequest): Promise<void | StickerError>;
  discardDuringAnnotation(request: IDiscardAnnotationDuringAnnotationRequest): Promise<void | StickerError>;
  saveDraft(request: ISaveAnnotationDraftRequest): Promise<void | StickerError>;
  abandonDraft(request: IAbandonDraftRequest): Promise<void | StickerError>;

  editAndAcceptAsync(request: EditAndAcceptAnnotationRequest): Promise<void | StickerError>;
  editAndRejectAsync(request: EditAndRejectAnnotationRequest): Promise<void | StickerError>;

  acceptAsync(request: IAcceptAnnotationRequest): Promise<void | StickerError>;
  rejectAsync(request: IRejectAnnotationRequest): Promise<void | StickerError>;

  discardDuringReviewAsync(request: IDiscardAnnotationDuringReviewRequest): Promise<void | StickerError>;
  discardDuringReviewEditAsync(request: IDiscardAnnotationDuringReviewEditRequest): Promise<void | StickerError>;

  correctAsync(request: ICorrectAnnotationRequest): Promise<void | StickerError>;
  correctAndSubmitForReviewAsync(request: ICorrectAndSubmitAnnotationForReviewRequest): Promise<void | StickerError>;

  requestClarificationDuringAnnotationAsync(request: IRequestClarificationDuringAnnotationRequest): Promise<void | StickerError>;
  requestClarificationDuringReviewAsync(request: IRequestClarificationDuringReviewRequest): Promise<void | StickerError>;
  requestClarificationDuringReviewEditAsync(request: IRequestClarificationDuringReviewEditRequest): Promise<void | StickerError>;
}

@injectable()
export class BatchAnnotationApiService implements IBatchAnnotationApiService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService) {}

  acceptAsync = async (request: IAcceptAnnotationRequest): Promise<void | StickerError> => await this.apiService.postAsync('/annotation/batch/Accept', request);

  rejectAsync = async (request: IRejectAnnotationRequest): Promise<void | StickerError> => await this.apiService.postAsync('/annotation/batch/Reject', request);

  editAndAcceptAsync = async (request: EditAndAcceptAnnotationRequest): Promise<void | StickerError> => await this.apiService.postAsync('/annotation/batch/EditAndAccept', request);

  editAndRejectAsync = async (request: EditAndRejectAnnotationRequest): Promise<void | StickerError> => await this.apiService.postAsync('/annotation/batch/EditAndReject', request);

  submitForReview = (request: ISubmitAnnotationForReviewRequest): Promise<void | StickerError> => this.apiService.postAsync('/annotation/batch/SubmitForReview', request);

  discardDuringAnnotation = (request: IDiscardAnnotationDuringAnnotationRequest): Promise<void | StickerError> =>
    this.apiService.postAsync('/annotation/batch/DiscardDuringAnnotation', request);

  discardDuringReviewAsync = async (request: IDiscardAnnotationDuringReviewRequest): Promise<void | StickerError> =>
    await this.apiService.postAsync('/annotation/batch/DiscardDuringReview', request);

    discardDuringReviewEditAsync = async (request: IDiscardAnnotationDuringReviewEditRequest): Promise<void | StickerError> =>
    await this.apiService.postAsync('/annotation/batch/DiscardDuringReviewEdit', request);

  saveDraft = (request: ISaveAnnotationDraftRequest): Promise<void | StickerError> => this.apiService.postAsync('/annotation/batch/SaveDraft', request);

  correctAsync = (request: ICorrectAnnotationRequest): Promise<void | StickerError> => this.apiService.postAsync('/annotation/batch/Correct', request);

  correctAndSubmitForReviewAsync = (request: ICorrectAndSubmitAnnotationForReviewRequest): Promise<void | StickerError> =>
    this.apiService.postAsync('/annotation/batch/CorrectAndSubmitForReview', request);

  abandonDraft = (request: IAbandonDraftRequest): Promise<void | StickerError> => this.apiService.postAsync('/annotation/batch/AbandonDraft', request);

  requestClarificationDuringAnnotationAsync = async (request: IRequestClarificationDuringAnnotationRequest): Promise<void | StickerError> =>
    await this.apiService.postAsync('/annotation/batch/RequestClarificationDuringAnnotation', request);

  requestClarificationDuringReviewAsync = async (request: IRequestClarificationDuringReviewRequest): Promise<void | StickerError> =>
    await this.apiService.postAsync('/annotation/batch/RequestClarificationDuringReview', request);

    requestClarificationDuringReviewEditAsync = async (request: IRequestClarificationDuringReviewEditRequest): Promise<void | StickerError> =>
    await this.apiService.postAsync('/annotation/batch/RequestClarificationDuringReviewEdit', request);
}
