import * as React from 'react';

import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { as } from '../../../helpers/react.helpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

library.add(faEye, faEyeSlash);

export interface IViewAttributesButtonProps {
  isShown: boolean;
  handleClick(): void;
}

@observer
class ViewAttributesButton extends React.Component<IViewAttributesButtonProps & ITranslatable> {
  render() {
    return (
      <Button onClick={this.props.handleClick} className="btn primary" tabIndex={-1}>
        <FontAwesomeIcon icon={this.props.isShown ? faEye : faEyeSlash} />
        <p>{this.props.t('attributes')}</p>
      </Button>
    );
  }
}
export default as<React.StatelessComponent>(withNamespaces('common')(ViewAttributesButton));
