import * as React from 'react';

import { DatasetDetailsPreviewHeaderContainer } from '../../modules/datesets/containers/DatasetDetailsPreviewHeader.container';
import { DatasetDetailsPreviewView } from './datasetsDetails/DatasetDetailsPreview.view';
import { ViewContainer } from '../../containers/ViewContainer';

export const PreviewView = () => (
  <ViewContainer header={DatasetDetailsPreviewHeaderContainer} className={'no-padding'}>
    <DatasetDetailsPreviewView />
  </ViewContainer>
);
