import { HowToResolveConflictsStepContainer } from './howToResolveConflicts/HowToResolveConflictsStep.container';
import { InformationStepContainer } from './information/InformationStep.container';
import { ProcessingStepContainer } from './processing/ProcessingStep.container';
import React from 'react';
import { ResolveConflictsStepContainer } from './resolveConflicts/ResolveConflictsStep.container';
import { SelectFileStepContainer } from './selectFile/SelectFileStep.container';
import { SummaryStepContainer } from './summary/SummaryStep.container';

export const ImportAnnotationsStepsContainer: React.FC = () => (
  <>
    <InformationStepContainer />
    <SelectFileStepContainer />
    <ProcessingStepContainer />
    <HowToResolveConflictsStepContainer />
    <ResolveConflictsStepContainer />
    <SummaryStepContainer />
  </>
);
