import * as React from 'react';

import { Col, Row } from 'reactstrap';

import { ITranslatable } from '../../../helpers/translations.helpers';
import { InputStatus } from '../../../models/error.model';
import { ValidatedTextInput } from '../../../components/ValidatedTextInput';
import { as } from '../../../helpers/react.helpers';
import { formAlertWithTranslations } from '../../../components/FormAlert';
import { withNamespaces } from 'react-i18next';

const Errors = formAlertWithTranslations('datasets');

export interface IDatasetNameFormProps {
  onNameChange(name: string): void;
  onDescriptionChange(name: string): void;
  name: string;
  description: string;
  nameStatus: InputStatus;
  isCreationEnabled: boolean;
  externalStatus: InputStatus;
  author: string;
  onAuthorChange(name: string): void;
  termsOfUse: string;
  onTermsOfUseChange(name: string): void;
}

const DatasetNameFormPure = (props: IDatasetNameFormProps & ITranslatable) => (
  <Row>
    <Col sm="8">
      <Row>
        <Col>
          <Errors errors={props.externalStatus} />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="image-input-body" >
            <ValidatedTextInput
              id="dataset-name-input"
              type={'text'}
              maxLength={200}
              value={props.name}
              placeholder={props.t('type_dataset_name')}
              onChange={props.onNameChange}
              isValid={props.nameStatus.isValid}
              feedbacks={props.nameStatus.errorCodes.map(props.t)}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <ValidatedTextInput
            id="dataset-description-input"
            type={'text'}
            maxLength={500}
            value={props.description}
            placeholder={props.t('type_dataset_description')}
            onChange={props.onDescriptionChange}
            isValid={undefined}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ValidatedTextInput
            id="dataset-author-input"
            type={'text'}
            maxLength={500}
            value={props.author}
            placeholder={props.t('type_dataset_author')}
            onChange={props.onAuthorChange}
            isValid={undefined}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ValidatedTextInput
            id="dataset-termsOfUse-input"
            type={'text'}
            maxLength={500}
            value={props.termsOfUse}
            placeholder={props.t('type_dataset_terms_of_use')}
            onChange={props.onTermsOfUseChange}
            isValid={undefined}
          />
        </Col>
      </Row>
    </Col>
  </Row>
);

export const DatasetNameForm = as<React.SFC<IDatasetNameFormProps>>(withNamespaces('datasets')(DatasetNameFormPure));
