import leaflet, { LatLng } from 'leaflet';

import { AddPolylineVertexCommand } from './addPolylineVertexCommand';
import { AddVerticesCommandBase } from './addVerticesCommandBase';
import { DrawPolyLine } from '../components/DrawControl.models/Draw.polyline';

export class AddPolylineVerticesCommand extends AddVerticesCommandBase {
  public polyline: DrawPolyLine;
  private readonly markers: leaflet.Marker<any>[];

  constructor(
    polyline: DrawPolyLine,
    latlng: LatLng,
    markers: leaflet.Marker<any>[],
  ) {
    super(latlng);
    this.polyline = polyline;
    this.markers = markers;
  }

  undoAction() {
    new AddPolylineVertexCommand(this.polyline, this.latlng[0], this.markers).executeUndo();
  }

  redoAction(latlng: LatLng): void {
    new AddPolylineVertexCommand(this.polyline, latlng, this.markers).executeRedo();
  }
}
