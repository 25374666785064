import { WithNamespaces, withNamespaces } from 'react-i18next';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { as } from '../../../../../../__legacy__/helpers/react.helpers';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

interface IProps {
  onClickExportButton(): void;
}

export const ExportProjectStatsButtonPure: React.FC<IProps & WithNamespaces> = (props: IProps & WithNamespaces) => {
  const { onClickExportButton } = props;
  return (
    <Button color="primary" className="float-right" onClick={onClickExportButton}>
      <FontAwesomeIcon className="mr-2" icon={faDownload} />
      {props.t('projects.details.stats.export')}
    </Button>
  );
};

export const ExportProjectStatsButton = as<React.ComponentClass<IProps>>(withNamespaces('new')(ExportProjectStatsButtonPure));
