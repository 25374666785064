import { inject, injectable } from 'inversify';
import { StickerError } from '../../../../../__legacy__/models/error.model';
import { ApiServiceType } from '../../../../../__legacy__/services/api.service';
import { IApiService } from '../../../../../__legacy__/services/api.service.base';

export const ExportProjectServiceType = Symbol('EXPORT_PROJECT_SERVICE');

interface ExportProjectRequest {
  projectId: string;
  dataFilterType: string;
}

export interface IExportProjectService {
  exportProjectAsync(request: ExportProjectRequest): Promise<void>;
}

@injectable()
export class ExportProjectService implements IExportProjectService {
  constructor(@inject(ApiServiceType) readonly apiService: IApiService) {}

  async exportProjectAsync(request: ExportProjectRequest): Promise<void> {
    const url = '/Export/exportProject';
    const result = await this.apiService.postAsync(url, request);

    if (result instanceof StickerError) {
      throw result;
    }
  }
}
