import { GTable, IGColumnProps, IGRowModel, enumDirectionToStringOrder, stringOrderToEnumDirection } from '../../../../../../__legacy__/components/table/GTable';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import { IWorkPerformanceStats } from '../models/WorkPerformance';
import React from 'react';
import { SortingDirection } from '../../../../../../__legacy__/models/sortingDirection.model';
import { nameOf } from '../../../../../../__legacy__/helpers/object.helpers';

interface Props {
  items: IWorkPerformanceStats[];
  orderBy: string;
  orderDirection: SortingDirection | undefined;
  onSortChange(orderBy: string, orderDirection: SortingDirection | undefined): void;
}

type IWorkPerformanceStatsRowModel = IWorkPerformanceStats & IGRowModel;

const WorkPerformanceStatsTablePure: React.FC<Props & WithNamespaces> = props => {
  const { orderBy, orderDirection, onSortChange, items } = props;

  const firstColumnSize = 320;

  const renderAccuracy = (item: IWorkPerformanceStatsRowModel) => (
    <span className="accuracy-cell text-gradient">{item.totalAdditionsAndModifications !== 0 ? <>{getAccuracyPercentage(item.accuracy)}</> : <>&mdash;</>}</span>
  );

  const getAccuracyPercentage = (accuracy: number) => `${(Math.min(Math.max(accuracy, 0), 1) * 100).toFixed(2)} %`;

  const columns: IGColumnProps<IWorkPerformanceStatsRowModel>[] = [
    {
      field: nameOf<IWorkPerformanceStats>('email'),
      headerName: props.t('projects.details.stats.work_performance.headers.email'),
      width: firstColumnSize,
      sortable: true,
    },
    {
      field: nameOf<IWorkPerformanceStats>('totalAdditionsAndModifications'),
      headerName: props.t('projects.details.stats.work_performance.headers.totalAdditionsAndModifications'),
      headerTooltip: props.t('projects.details.stats.work_performance.tooltips.totalAdditionsAndModifications'),
      width: firstColumnSize,
      sortable: true,
    },
    {
      field: nameOf<IWorkPerformanceStats>('awaitingReview'),
      headerName: props.t('projects.details.stats.work_performance.headers.awaitingReview'),
      headerTooltip: props.t('projects.details.stats.work_performance.tooltips.awaitingReview'),
      width: firstColumnSize,
      sortable: true,
    },
    {
      field: nameOf<IWorkPerformanceStats>('accepted'),
      headerName: props.t('projects.details.stats.work_performance.headers.accepted'),
      headerTooltip: props.t('projects.details.stats.work_performance.tooltips.accepted'),
      width: firstColumnSize,
      sortable: true,
    },
    {
      field: nameOf<IWorkPerformanceStats>('accuracy'),
      headerName: props.t('projects.details.stats.work_performance.headers.accuracy'),
      headerTooltip: props.t('projects.details.stats.work_performance.tooltips.accuracy'),
      renderer: renderAccuracy,
      width: firstColumnSize,
      sortable: true,
    },
  ];

  const handleSortChange = (orderBy: string, orderDirection: string) => onSortChange(orderBy, stringOrderToEnumDirection(orderDirection));

  return (
    <div className="work-performance-stats-container">
      <GTable<IWorkPerformanceStatsRowModel>
        columns={columns}
        items={items}
        noItemsPlaceholder={props.t('projects.details.stats.no_statistics_have_been_generated_yet')}
        onSortChanged={handleSortChange}
        sortingModel={[{ orderBy, orderType: enumDirectionToStringOrder(orderDirection) }]}
      />
    </div>
  );
};

export const WorkPerformanceStatsTable = withNamespaces('new')(WorkPerformanceStatsTablePure);
