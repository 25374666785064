import { FilterHeader } from '../FilterHeader';
import { FilterTabType } from '../../../imageFilters.model';
import { ITranslatable } from '../../../../../../../helpers/translations.helpers';
import { ImageFiltersFormProps } from '../ImageFiltersForm';
import React from 'react';
import { as } from '../../../../../../../helpers/react.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

@observer
class CorrectedHeaderPure extends React.Component<ImageFiltersFormProps & ITranslatable> {
  handleClear = () => this.props.onSetCorrected([]);

  render() {
    const isSelected = this.props.activeFilterType === FilterTabType.Corrected;
    const count = this.props.imageFilters.corrected.length;

    return (
      <FilterHeader
        filterType={FilterTabType.Corrected}
        isSelected={isSelected}
        labelKey={'corrected'}
        count={count}
        showClear={count > 0}
        onClear={this.handleClear}
        onSelect={this.props.onFilterTabSelect}
      />
    );
  }
}

export const CorrectedHeader = as<React.ComponentClass<ImageFiltersFormProps>>(withNamespaces('common')(CorrectedHeaderPure));
