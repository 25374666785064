﻿import * as React from 'react';

import { Button, Col, CustomInput, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IIntegrationsService, IntegrationsServiceType } from '../integrations.service';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { as, injectProps } from '../../../helpers/react.helpers';

import { ButtonWithLoader } from '../../../components/ButtonWithLoader';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { InputStatus } from '../../../models/error.model';
import { ValidatedTextInput } from '../../../components/ValidatedTextInput';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

export interface IInjectedProps {
  service: IIntegrationsService;
}

export interface IOuterProps {
  apiKeyId: string;
  onSubmit(): void;
  onCancel(): void;
  showModal: boolean;
}

interface IState extends ILoaderState {
  id: string;
  name: string;
  nameStatus: InputStatus;
  isActive: boolean;
  requestInProgress: boolean;
}

@injectProps({ service: IntegrationsServiceType })
@observer
class EditApiKeyModalContainerPure extends WithLoaderComponentBase<IInjectedProps & IOuterProps & ITranslatable, IState> {
  state = {
    id: '',
    name: '',
    nameStatus: InputStatus.empty(),
    isActive: true,
    requestInProgress: false,
    isLoading: false,
  };

  componentDidMount() {
    const apiKey = this.props.service.store.apiKeys.find(x => x.id === this.props.apiKeyId)!;

    this.setState({
      id: apiKey?.id,
      name: apiKey?.name,
      isActive: apiKey?.isActive,
      requestInProgress: false,
      nameStatus: InputStatus.empty(),
    });
  }

  @autobind
  handleNameChange(name: string) {
    this.setState({ name });
    this.validateName(name);
  }

  toggleIsActiveStatus = () => this.setState((prev: { isActive: any }) => ({ isActive: !prev.isActive }));

  @autobind
  validateName(value: string) {
    let isValid: boolean = true;
    const errorCodes: string[] = [];
    if (value.trim() === '') {
      isValid = false;
      errorCodes.push('common:field_cant_be_empty');
    }
    if (!this.props.service.isNameUnique(value, this.props.apiKeyId)) {
      isValid = false;
      errorCodes.push('integrations:api_key_name_must_be_unique');
    }
    this.setState({ nameStatus: new InputStatus(isValid, errorCodes) });
  }

  @autobind
  async handleSubmitAsync() {
    this.setState({ requestInProgress: true });
    await this.props.service.editApiKey({
      id: this.state.id,
      name: this.state.name.trim(),
      isActive: this.state.isActive,
    });
    this.setState({ requestInProgress: false });
    this.props.onSubmit();
  }

  @autobind
  async handleSubmitClickedAsync() {
    await this.withLoaderAsync(() => this.handleSubmitAsync(), 'api-key-add-button');
  }

  @autobind
  handleCancel() {
    this.props.onCancel();
  }

  @autobind
  reloadApiKey() {
    const apiKey = this.props.service.store.apiKeys.find(x => x.id === this.props.apiKeyId)!;

    this.setState({
      id: apiKey?.id,
      name: apiKey?.name,
      isActive: apiKey?.isActive,
      requestInProgress: false,
      nameStatus: InputStatus.empty(),
    });
  }

  componentDidUpdate(prevProps: IOuterProps) {
    if (this.props.showModal && !prevProps.showModal) this.reloadApiKey();
  }

  render() {
    return (
      <Modal className="modal-width-600" isOpen={this.props.showModal} toggle={this.props.onCancel}>
        <ModalHeader toggle={this.props.onCancel}>{this.props.t('edit_api_key')}</ModalHeader>
        <Form className="custom-inline-form">
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <ValidatedTextInput
                    id="api-key-name-input"
                    labelText={this.props.t('api_key')}
                    placeholder={this.props.t('api_key_name')}
                    value={this.state.name}
                    onChange={this.handleNameChange}
                    feedbacks={this.state.nameStatus.errorCodes.map(x => this.props.t(x))}
                    isValid={this.state.nameStatus.isValid}
                  />

                  <CustomInput
                    onChange={this.toggleIsActiveStatus}
                    className="mt-2"
                    checked={this.state.isActive}
                    type="checkbox"
                    label={this.props.t('active')}
                    id="api-key-is-active-input"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <ButtonWithLoader
              id="api-key-add-button"
              loaderKey="api-key-add-button"
              className="mr-2"
              color="success"
              onClick={this.handleSubmitClickedAsync}
              disabled={(!this.state.nameStatus.isValid && !this.state.nameStatus.isEmpty) || this.state.requestInProgress}
            >
              {this.props.t('save')}
            </ButtonWithLoader>
            <Button color="primary" className="btn-outline-primary" onClick={this.handleCancel}>
              {this.props.t('cancel')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export const EditApiKeyModalContainer = as<React.ComponentClass<IOuterProps>>(withNamespaces('integrations', { wait: true })(EditApiKeyModalContainerPure));
