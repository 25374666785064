const INFINITY = 9999999;
const INFINITY_FLOAT = 9999999.0;

export const BOOLEAN_OPTIONS = [
  { label: 'False', value: false },
  { label: 'True', value: true },
];

export enum PARAMETER_TYPE {
  BOOL = 'bool',
  FLOAT = 'float',
  DOUBLE = 'double',
  INT = 'int',
  ENUM = 'enum',
}

export enum PARAMETER_TYPE_INTERNAL {
  BOOL = 'bool',
  INT = 'int',
  FLOAT = 'float',
  ENUM = 'enum',
}

export interface IParameterBase {
  name: string;
  label: string;
  type: PARAMETER_TYPE_INTERNAL;
}

export interface IParameterNumeric extends IParameterBase {
  config: {
    max: number;
    min: number;
    step: number;
    validator: (value: string) => boolean;
  };
}

export interface IParameterFloat extends IParameterNumeric {
  type: PARAMETER_TYPE_INTERNAL.FLOAT;
}

export interface IParameterInt extends IParameterNumeric {
  type: PARAMETER_TYPE_INTERNAL.INT;
}

export interface IParameterBoolean extends IParameterBase {
  type: PARAMETER_TYPE_INTERNAL.BOOL;
}

export type Parameter = IParameterFloat | IParameterInt | IParameterBoolean;
export type Parameters = Parameter[];

export interface IParameterDto {
  name: string;
  label: string;
  type: PARAMETER_TYPE;
  config: {
    def?: number | string;
    max?: number | string;
    min?: number | string;
    step?: number | string;
    inTrainContinuation?: boolean;
  };
}

function createIntParameter(name: string, label: string, config: IParameterDto['config']): IParameterInt {
  const paramConfig = {
    min: Number(config?.min) || 0,
    max: Number(config?.max) || INFINITY,
    step: Number(config?.step) || 1,
  };

  return {
    name,
    label,
    type: PARAMETER_TYPE_INTERNAL.INT,
    config: {
      ...paramConfig,
      validator(value: string) {
        const asInt = parseInt(value, 10);

        return !isNaN(asInt) && asInt >= paramConfig.min && asInt <= paramConfig.max;
      },
    },
  };
}

function createFloatParameter(name: string, label: string, config: IParameterDto['config']): IParameterFloat {
  const paramConfig = {
    min: config?.min ? parseFloat(config.min.toString()) : 0.0,
    max: config?.max ? parseFloat(config.max.toString()) : INFINITY_FLOAT,
    step: config?.step ? parseFloat(config.step.toString()) : 0.1,
  };

  return {
    name,
    label,
    type: PARAMETER_TYPE_INTERNAL.FLOAT,
    config: {
      ...paramConfig,
      validator(value: string) {
        const asFloat = parseFloat(value);

        return !isNaN(asFloat) && asFloat >= paramConfig.min && asFloat <= paramConfig.max;
      },
    },
  };
}

function createBoolParameter(name: string, label: string): IParameterBoolean {
  return {
    name,
    label,
    type: PARAMETER_TYPE_INTERNAL.BOOL,
  };
}

export function parseParameters(parametersToParse: IParameterDto[]): { parsedParameters: Parameters; preselectedValues: Record<string, any> } {
  const preselectedValues: Record<string, any> = {};

  const parsedParameters = parametersToParse.reduce<Parameters>((acc, { type, name, label, config }) => {
    switch (type) {
      case PARAMETER_TYPE.INT:
        acc.push(createIntParameter(name, label, config));

        preselectedValues[label] = Number(config?.def) || 0;
        break;
      case PARAMETER_TYPE.FLOAT:
      case PARAMETER_TYPE.DOUBLE:
        acc.push(createFloatParameter(name, label, config));

        preselectedValues[label] = config?.def ? parseFloat(config.def.toString()) : 0.0;
        break;
      case PARAMETER_TYPE.BOOL: {
        acc.push(createBoolParameter(name, label));
        preselectedValues[label] = false;
        break;
      }
    }

    return acc;
  }, []);

  return {
    parsedParameters,
    preselectedValues,
  };
}
