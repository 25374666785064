import { Button } from 'reactstrap';
import { ITranslatable } from '../../../../../../helpers/translations.helpers';
import { ImageFiltersFormContainer } from '../../containers/filters/ImageFiltersForm.container';
import { ImageNameSearchContainer } from '../../containers/filters/ImageNameSearchContainer';
import { PillsContainer } from '../../containers/filters/Pills.container';
import React from 'react';
import { as } from '../../../../../../helpers/react.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IProps {
  activeFiltersCount: number;
  isModalOpen: boolean;
  onModalShow(): void;
  onModalClose(): void;
}

@observer
class ImageFiltersPure extends React.Component<IProps & ITranslatable> {
  disableBodyScrollWhenModalVisible() {
    if (this.props.isModalOpen) {
      if (document.body.scrollHeight > document.body.clientHeight) {
        document.body.style.overflowY = 'scroll';
      }
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.scrollY}px`;
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.overflowY = 'auto';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    }
  }

  render() {
    this.disableBodyScrollWhenModalVisible();
    return (
      <>
        <div className="image-filters">
          <ImageNameSearchContainer />
          <div>
            <Button color="primary" onClick={this.props.onModalShow}>
              {this.props.t('filters_and_sorting')} {this.props.activeFiltersCount > 0 && <span>({this.props.activeFiltersCount})</span>}
            </Button>
          </div>
          <PillsContainer />
        </div>
        <ImageFiltersFormContainer onAfterFilter={this.props.onModalClose} isModalOpen={this.props.isModalOpen} />
      </>
    );
  }
}

export const ImageFilters = as<React.ComponentClass<IProps>>(withNamespaces('common')(ImageFiltersPure));
