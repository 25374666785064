import { AnnotationPreview } from '../../views/home/projectDetails/AnnotationPreview.view';
import { Home } from '../config/Home';
import { NotFoundView } from '../../views/NotFound.view';
import { ProjectDetailsView } from '../../views/home/projectDetails/ProjectDetails.view';
import { ProjectsView } from '../../views/home/Projects.view';
import React from 'react';
import { Route } from 'react-router';
import { SwitchWithNotFound } from '../../containers/route.containers';

export const ProjectsRoute = () => (
  <SwitchWithNotFound notFound={NotFoundView}>
    <Route path={Home.Projects.List.Path} component={ProjectsView} />
    <Route path={Home.Projects.Details.Preview.Path} component={AnnotationPreview} />
    <Route path={Home.Projects.Details.Path} component={ProjectDetailsView} />
  </SwitchWithNotFound>
);
