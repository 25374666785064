export enum MODEL_VARIANTS {
  ANOMALY_DETECTION_4 = 'anomaly_detection.detect_anomalies_4',
  ANOMALY_DETECTION_8 = 'anomaly_detection.detect_anomalies_8',
  OBJECT_CLASSIFICATION = 'object_classification.classify_object_1',
}

export const MODEL_VARIANTS_LIST = Object.values(MODEL_VARIANTS);

export type IChartPoint = {
  x: number;
  y: number;
};

export type ITrainingResult = {
  id: string;
  name: string;
  train: number | string | null;
  test: number | string | null;
  validation: number | string | null;
};

export type ITrainingMatrix = {
  id: string;
  name: string;
  good: number;
  bad: number;
  weak: number;
};

export type ITrainingMetrics = {
  auc: number | string | null;
};
