import * as React from 'react';

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../workspaces/currentWorkspace/CurrentWorkspace.store';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../common/OverlayLoader.store';
import { IProjectOverviewBl, ProjectOverviewBlType } from './projectOverview.bl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { as, injectProps } from '../../../../__legacy__/helpers/react.helpers';

import { ClarificationsOverview } from './ClarificationsOverview';
import { Col } from 'reactstrap';
import { RestrictAccessTo } from '../../../../__legacy__/containers/RestrictAccessTo';
import { WithLoaderComponentBase } from '../../../../__legacy__/helpers/loader.helpers';
import { WorkspaceRole } from '../../../../__legacy__/modules/workspaces/workspaces.store';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ projectId: string }> {
  projectOverviewBl: IProjectOverviewBl;
  currentWorkspaceStore: ICurrentWorkspaceStore;
  overlayLoaderStore: IOverlayLoaderStore;
}

@injectProps({
  projectOverviewBl: ProjectOverviewBlType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  overlayLoaderStore: OverlayLoaderStoreType,
})
@observer
class ClarificationsOverviewContainerPure extends WithLoaderComponentBase<IInjectedProps> {
  async componentDidMount() {
    this.withLoaderAsync(() => this.props.projectOverviewBl.getUnclearImagesFilter(this.props.match.params.projectId));
  }

  render() {
    const { store } = this.props.projectOverviewBl;
    return (
      <RestrictAccessTo workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager]} projectRoles={[]}>
        <Col className="d-flex pl-1">
          <ClarificationsOverview
            workspaceId={this.props.currentWorkspaceStore.currentWorkspace!.id}
            projectId={this.props.match.params.projectId}
            isLoading={this.state.isLoading && this.props.overlayLoaderStore.isSpinnerVisible('project-overview')}
            toClarify={store.unclearCount}
            oldestWaitingSince={store.unclearImageDate}
            unclearImageId={store.unclearImageId}
            unclearImagesFilterId={store.unclearImagesFilterId}
          />
        </Col>
      </RestrictAccessTo>
    );
  }
}

export const ClarificationsOverviewContainer = as<React.ComponentClass>(withRouter(ClarificationsOverviewContainerPure));
