import * as EmailValidator from 'email-validator';

import { InputStatus } from '../models/error.model';

export const getEmailStatus =
  function (value: string, existingEmails: string[] = [], validateFormat: boolean = true): InputStatus {
    const email = value.trim().toLocaleLowerCase();

    const result = InputStatus.valid();

    if (email === '') {
      result.addErrorCode('field_cant_be_empty');
    } else if (validateFormat) {
      if (!EmailValidator.validate(email)) {
        result.addErrorCode('email_invalid_format');
      }
    }

    if (existingEmails.length > 0 && existingEmails.map(e => e.trim().toLocaleLowerCase()).includes(email)) {
      result.addErrorCode('common:user_already_added');
    }

    return result;
  };
