import { IProjectStatsBl, ProjectStatsBlType } from '../../ProjectStats.bl';
import { IProjectStatsFiltersStore, ProjectStatsFiltersStoreType } from '../../filters/ProjectStatsFilters.store';
import { IProjectStatsStore, ProjectStatsStoreType } from '../../ProjectStats.store';

import { AnnotationToolsStatsTable } from './AnnotationToolsStatsTable';
import React from 'react';
import { SortingDirection } from '../../../../../../__legacy__/models/sortingDirection.model';
import { as } from '../../../../../../common/dependencyInjection/as';
import autobind from 'autobind-decorator';
import { injectProps } from '../../../../../../__legacy__/helpers/react.helpers';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: IProjectStatsBl;
  filtersStore: IProjectStatsFiltersStore;
  store: IProjectStatsStore;
}

@injectProps({
  bl: ProjectStatsBlType,
  filtersStore: ProjectStatsFiltersStoreType,
  store: ProjectStatsStoreType,
})
@observer
class AnnotationToolsStatsTableContainerPure extends React.Component<InjectedProps> {
  get items() {
    const { visibleWorkers } = this.props.filtersStore;
    const { annotationToolsStats } = this.props.store;

    return annotationToolsStats.filter(s => visibleWorkers.map(w => w.id).includes(s.id));
  }

  @autobind
  handleOnSortChange(orderBy: string, orderDirection: SortingDirection) {
    this.props.bl.changeAnnotationToolsStatsSorting(orderBy, orderDirection);
  }

  render(): React.ReactNode {
    return (
      <AnnotationToolsStatsTable
        items={this.items}
        orderBy={this.props.store.annotationToolsStatsOrderBy}
        orderDirection={this.props.store.annotationToolsStatsOrderDirection}
        onSortChange={this.handleOnSortChange}
      />
    );
  }
}

export const AnnotationToolsStatsTableContainer = as<React.ComponentClass>(AnnotationToolsStatsTableContainerPure);
