import { WithNamespaces, withNamespaces } from 'react-i18next';

import { PaginationLink } from 'reactstrap';
import React from 'react';

interface Props {
  onClick(): void;
}

const S_PaginationLastPageButtonPure: React.FC<Props & WithNamespaces> = ({ onClick, t }) => <PaginationLink onClick={onClick}>{t('pagination.last_page')}</PaginationLink>;

export const S_PaginationLastPageButton = withNamespaces('design')(S_PaginationLastPageButtonPure);
