import { ClarificationsBlType, IClarificationsBl } from '../services/Clarifications.bl';
import { ClarificationsPermissionsType, IClarificationsPermissions } from '../Clarifications.permissions';
import { ClarificationsStoreType, IClarificationsStore } from '../Clarifications.store';
import { ILoaderState, WithLoaderComponentBase } from '../../../../../helpers/loader.helpers';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { ClarifyModal } from '../components/ClarifyModal';
import React from 'react';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

export interface IOuterProps {
  showModal: boolean;
  onToggle(): void;
}

interface IInjectedProps extends RouteComponentProps<{ projectId: string }> {
  clarificationsPermissions: IClarificationsPermissions;
  clarificationsStore: IClarificationsStore;
  clarificationsBl: IClarificationsBl;
}

interface IState extends ILoaderState {
  answer: string;
}

@injectProps({
  clarificationsPermissions: ClarificationsPermissionsType,
  clarificationsStore: ClarificationsStoreType,
  clarificationsBl: ClarificationsBlType,
})
@observer
class ClarifyModalContainer extends WithLoaderComponentBase<IInjectedProps & IOuterProps, IState> {
  state: IState = {
    answer: '',
    isLoading: false,
  };

  @autobind
  handleToggle() {
    this.setState({ answer: '' });
    this.props.onToggle();
  }

  @autobind
  async handleSaveClarificationAnswer() {
    this.handleToggle();

    await this.withLoaderAsync(() =>
      this.props.clarificationsBl.clarifyAsync(
        this.props.match.params.projectId,
        this.props.clarificationsStore.clarification!.clarificationId,
        this.state.answer), 'answer-clarification-button');
  }

  @autobind
  handleAnswerChange(value: string) {
    this.setState({ answer: value });
  }

  render() {
    const showModal = this.props.clarificationsStore.canShowClarify &&
      this.props.clarificationsPermissions.canClarify();

    return (
      showModal && <ClarifyModal
        showModal={this.props.showModal}
        clarification={this.props.clarificationsStore.clarification!}
        answer={this.state.answer}
        onToggleChange={this.handleToggle}
        onAnswerChange={this.handleAnswerChange}
        onSaveClarificationAnswer={this.handleSaveClarificationAnswer}
      />

    );
  }
}

export default as<React.ComponentClass<IOuterProps>>(withRouter(ClarifyModalContainer));
