import { AnnotationConfirmationModal, AnnotationConfirmationModalMode } from '../../../components/AnnotationConfirmationModal';
import { AnnotationReviewServiceType, IAnnotationReviewService } from '../annotationReview.service';
import { AnnotationServiceType, IAnnotationService } from '../annotation.service';
import { AnnotationTypeBlType, IAnnotationTypeBl } from '../submodules/annotationTypes/annotationType.bl';
import { FreeDrawSegmentationServiceType, IFreeDrawSegmentationService } from '../freeDrawSegmentation.service';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { IReviewRejectReasonsStore, ReviewRejectReasonsStoreType } from '../submodules/reviewRejectReasones/reviewRejectReasons.store';
import { IUndoRedoHistory, UndoRedoHistoryType } from '../undoRedoHistory.service';
import { IWorkspaceService, WorkspaceServiceType } from '../../workspaces/workspaces.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { CorrectNavigation } from '../components/CorrectNavigation';
import { EditorMode } from '../../../../modules/editor/models/EditorModes';
import { GlobalHotKeys } from 'react-hotkeys';
import React from 'react';
import { SaveBeforeRejectConfirmationModal } from '../../../components/SaveBeforeRejectConfirmationModal';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ projectId: string; workspaceId: string }> {
  annotationReviewService: IAnnotationReviewService;
  freeDrawSegmentationService: IFreeDrawSegmentationService;
  workspaceService: IWorkspaceService;
  historyService: IUndoRedoHistory;
  reviewRejectReasonsStore: IReviewRejectReasonsStore;
  annotationService: IAnnotationService;
  annotationTypeBl: IAnnotationTypeBl;
}

interface IState extends ILoaderState {
  showCancelModal: boolean;
  showAnnotationConfirmationModal: boolean;
  showDiscardConfirmationModal: boolean;
  annotationConfirmationModalMode: AnnotationConfirmationModalMode;
  showSaveBeforeRejectConfirmationModal: boolean;
}

@injectProps({
  annotationReviewService: AnnotationReviewServiceType,
  freeDrawSegmentationService: FreeDrawSegmentationServiceType,
  workspaceService: WorkspaceServiceType,
  historyService: UndoRedoHistoryType,
  reviewRejectReasonsStore: ReviewRejectReasonsStoreType,
  annotationService: AnnotationServiceType,
  annotationTypeBl: AnnotationTypeBlType,
})
@observer
class CorrectControlsContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  state: IState = {
    showCancelModal: false,
    showAnnotationConfirmationModal: false,
    annotationConfirmationModalMode: AnnotationConfirmationModalMode.SUBMIT,
    showDiscardConfirmationModal: false,
    isLoading: false,
    showSaveBeforeRejectConfirmationModal: false,
  };

  @autobind
  async handleSubmitAndAcceptAsync() {
    this.setState({ showAnnotationConfirmationModal: false });
    this.withLoaderAsync<Boolean>(() => this.props.annotationReviewService.correctAcceptAnnotationAsync(), 'submit-and-accept-button');
  }

  @autobind
  handleReject() {
    if (!this.props.annotationService.validateAnnotations()) {
      this.props.annotationTypeBl.handleReject();
      return;
    }

    this.setState({ showSaveBeforeRejectConfirmationModal: false });
    this.toggleAnnotationConfirmationModal(AnnotationConfirmationModalMode.REJECT);
  }

  @autobind
  handleConfirmReject(reason?: string) {
    this.setState({ showAnnotationConfirmationModal: false });
    if (reason) {
      this.handleRejectConfirmed(reason);
    } else {
      this.setState({ showSaveBeforeRejectConfirmationModal: true });
    }
  }

  @autobind
  async handleRejectConfirmed(reason?: string) {
    this.setState({ showSaveBeforeRejectConfirmationModal: false });
    await this.withLoaderAsync(() => this.props.annotationReviewService.correctRejectAnnotation(reason), 'reject-button');
  }

  @autobind
  handleRejectCanceled() {
    this.setState({ showSaveBeforeRejectConfirmationModal: false, showAnnotationConfirmationModal: false });
  }

  @autobind
  handleSaveBeforeRejectConfirmed() {
    this.handleRejectConfirmed();
  }

  @autobind
  handleCancelAnnotationConfirm() {
    this.props.annotationReviewService.cancelCorrectAsync();
    this.setState({ showCancelModal: false });
  }

  @autobind
  async handleCancelAnnotationCancel() {
    this.setState({ showCancelModal: false });
  }

  @autobind
  async handleCancelAnnotation() {
    if (!this.props.historyService.canUndo) {
      this.props.annotationReviewService.cancelCorrectAsync();
    } else {
      this.setState({ showCancelModal: true });
    }
  }

  @autobind
  async handleDiscardConfirmed() {
    if (this.state.isLoading) return;

    await this.withLoaderAsync(() => this.props.annotationReviewService.correctDiscardAnnotationAsync(), 'discard-annotation-button');
  }

  @autobind
  async handleDiscardCanceled() {
    this.setState({ showDiscardConfirmationModal: false });
  }

  @autobind
  async handleDiscard() {
    this.setState({ showDiscardConfirmationModal: true });
  }

  @autobind
  toggleAnnotationConfirmationModal(mode?: AnnotationConfirmationModalMode) {
    if (mode !== undefined) this.setState({ annotationConfirmationModalMode: mode });
    this.setState((prevState: IState) => ({ ...prevState, showAnnotationConfirmationModal: !prevState.showAnnotationConfirmationModal }));
  }

  render() {
    const disabled = this.state.isLoading || this.props.freeDrawSegmentationService.freeDrawInProgress || this.props.freeDrawSegmentationService.freeDrawFeature !== undefined;

    // TODO: Create IPermission for annotations
    const canDiscard = this.props.workspaceService.isInRole([WorkspaceRole.Owner, WorkspaceRole.Manager]);

    return (
      <>
        {!disabled && (
          <GlobalHotKeys
            keyMap={{
              SUBMIT: { sequence: 'ctrl+shift+enter', action: 'keydown' },
              REJECT: { sequence: 'ctrl+shift+backspace', action: 'keydown' },
            }}
            handlers={{
              SUBMIT: () => {
                this.setState({ annotationConfirmationModalMode: AnnotationConfirmationModalMode.SUBMITANDACCEPT });
                this.toggleAnnotationConfirmationModal();
              },
              REJECT: () => {
                this.setState({ annotationConfirmationModalMode: AnnotationConfirmationModalMode.REJECT });
                this.toggleAnnotationConfirmationModal();
              },
            }}
          />
        )}
        <CorrectNavigation
          disabled={disabled}
          canDiscard={canDiscard}
          editorMode={EditorMode.BATCH_REVIEWEDIT}
          onSubmitAndAccept={this.handleSubmitAndAcceptAsync}
          onReject={this.handleReject}
          onCancel={this.handleCancelAnnotation}
          onConfirmCancel={this.handleCancelAnnotationConfirm}
          onCancelCancel={this.handleCancelAnnotationCancel}
          showCancelModal={this.state.showCancelModal}
          showDiscardModal={this.state.showDiscardConfirmationModal}
          onDiscardCanceled={this.handleDiscardCanceled}
          onDiscardConfirmed={this.handleDiscardConfirmed}
          onDiscard={this.handleDiscard}
        />
        <SaveBeforeRejectConfirmationModal
          onSaveBeforeRejectCancel={this.handleRejectCanceled}
          onSaveBeforeRejectConfirm={this.handleSaveBeforeRejectConfirmed}
          onSaveBeforeRejectDecline={this.handleReject}
          showSaveBeforeRejectModal={this.state.showSaveBeforeRejectConfirmationModal}
        />
        <AnnotationConfirmationModal
          showAnnotationConfirmationModal={this.state.showAnnotationConfirmationModal}
          annotationConfirmationModalMode={this.state.annotationConfirmationModalMode}
          rejectAutoCompleteOptions={this.props.reviewRejectReasonsStore.rejectionReasons.slice()}
          onAnnotationConfirm={
            this.state.annotationConfirmationModalMode === AnnotationConfirmationModalMode.SUBMITANDACCEPT ? this.handleSubmitAndAcceptAsync : this.handleConfirmReject
          }
          onAnnotationCancel={this.toggleAnnotationConfirmationModal}
        />
      </>
    );
  }
}

export const CorrectControlsContainer = as<React.ComponentClass>(withRouter(CorrectControlsContainerPure));
