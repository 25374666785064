import './GeneralStatsTable.scss'

import { ExpandableTable, IExpandableColumnProps, IExpandableRowModel, IExpandableSubColumnProps } from '../../../../../../__legacy__/components/table/ExpandableTable';
import { IDailyGeneralStats, IGeneralStats } from '../models/GeneralStats';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { enumDirectionToStringOrder, stringOrderToEnumDirection } from '../../../../../../__legacy__/components/table/GTable';
import { formatTimeSpan, toLocaleDateString } from '../../../../../../__legacy__/helpers/date.helpers';

import React from 'react';
import { SortingDirection } from '../../../../../../__legacy__/models/sortingDirection.model';
import { nameOf } from '../../../../../../__legacy__/helpers/object.helpers';

type GeneralStatsRowModel = IGeneralStats & IExpandableRowModel<DailyGeneralStatsRowModel>;
type DailyGeneralStatsRowModel = IDailyGeneralStats & IExpandableRowModel;

interface Props {
  items: IGeneralStats[];
  orderBy: string;
  orderDirection: SortingDirection | undefined;
  onSortChange(orderBy: string, orderDirection: SortingDirection | undefined): void;
}

const GeneralStatsTablePure: React.FC<Props & WithNamespaces> = props => {
  const { orderBy, orderDirection, onSortChange } = props;

  const smallColumnSize = 110;
  const firstColumnSize = 320;
  const renderTimeSpan = (timeSpan: string) => <span>{formatTimeSpan(timeSpan)}</span>;

  const columns: IExpandableColumnProps<GeneralStatsRowModel>[] = [
    {
      field: nameOf<IGeneralStats>('email'),
      headerName: props.t('projects.details.stats.general.headers.email'),
      sortable: true,
      cellClass: 'stats-cell-bold',
      width: firstColumnSize,
    },
    {
      field: nameOf<IGeneralStats>('submitted'),
      headerName: props.t('projects.details.stats.general.headers.annotated'),
      headerTooltip: props.t('projects.details.stats.general.tooltips.annotated'),
      width: smallColumnSize,
      sortable: true,
      cellClass: 'stats-cell-bold',
    },
    {
      field: nameOf<IGeneralStats>('reviewed'),
      headerName: props.t('projects.details.stats.general.headers.reviewed'),
      headerTooltip: props.t('projects.details.stats.general.tooltips.reviewed'),
      width: smallColumnSize,
      sortable: true,
      cellClass: 'stats-cell-bold',
    },
    {
      field: nameOf<IGeneralStats>('edited'),
      headerName: props.t('projects.details.stats.general.headers.edited'),
      headerTooltip: props.t('projects.details.stats.general.tooltips.edited'),
      width: smallColumnSize,
      sortable: true,
      cellClass: 'stats-cell-bold',
    },
    {
      field: nameOf<IGeneralStats>('annotationWorkTime'),
      headerName: props.t('projects.details.stats.general.headers.annotation_work_time'),
      headerTooltip: props.t('projects.details.stats.general.tooltips.annotation_work_time'),
      renderer: ({ annotationWorkTime }) => renderTimeSpan(annotationWorkTime),
      sortable: true,
      cellClass: 'stats-cell-bold',
      width: 200,
    },
    {
      field: nameOf<IGeneralStats>('reviewWorkTime'),
      headerName: props.t('projects.details.stats.general.headers.review_work_time'),
      headerTooltip: props.t('projects.details.stats.general.tooltips.review_work_time'),
      renderer: ({ reviewWorkTime }) => renderTimeSpan(reviewWorkTime),
      sortable: true,
      cellClass: 'stats-cell-bold',
      width: 160,
    },
    {
      field: nameOf<IGeneralStats>('editedWorkTime'),
      headerName: props.t('projects.details.stats.general.headers.edit_work_time'),
      headerTooltip: props.t('projects.details.stats.general.tooltips.edit_work_time'),
      renderer: ({ editedWorkTime }) => renderTimeSpan(editedWorkTime),
      sortable: true,
      cellClass: 'stats-cell-bold',
      width: 140,
    },
    {
      field: nameOf<IGeneralStats>('workTime'),
      headerName: props.t('projects.details.stats.general.headers.total_work_time'),
      headerTooltip: props.t('projects.details.stats.general.tooltips.total_work_time'),
      renderer: ({ workTime }) => renderTimeSpan(workTime),
      sortable: true,
      cellClass: 'stats-cell-bold',
      width: 150,
    },
  ];

  const subColumns: IExpandableSubColumnProps<DailyGeneralStatsRowModel>[] = [
    {
      field: nameOf<IDailyGeneralStats>('day'),
      renderer: item => <>{toLocaleDateString(item.day)}</>,
      cellClass: 'stats-cell-bold',
      width: firstColumnSize,
    },
    { field: nameOf<IDailyGeneralStats>('submitted'), width: smallColumnSize },
    { field: nameOf<IDailyGeneralStats>('reviewed'), width: smallColumnSize },
    { field: nameOf<IDailyGeneralStats>('edited'), width: smallColumnSize },

    {
      field: nameOf<IDailyGeneralStats>('annotationWorkTime'),
      renderer: ({ annotationWorkTime }) => renderTimeSpan(annotationWorkTime),
      width: 200,
    },
    {
      field: nameOf<IDailyGeneralStats>('reviewWorkTime'),
      renderer: ({ reviewWorkTime }) => renderTimeSpan(reviewWorkTime),
      width: 160,
    },
    {
      field: nameOf<IDailyGeneralStats>('editedWorkTime'),
      renderer: ({ editedWorkTime }) => renderTimeSpan(editedWorkTime),
      width: 140,
    },
    {
      field: nameOf<IDailyGeneralStats>('workTime'),
      renderer: ({ workTime }) => renderTimeSpan(workTime),
      width: 150,
    },
  ];

  const items = (): GeneralStatsRowModel[] =>
    props.items.map(i => ({
      ...i,
      subRows: i.dailyGeneralStats.map(d => ({ ...d, id: d.day })),
    }));

  const handleSortChange = (orderBy: string, orderDirection: string) => onSortChange(orderBy, stringOrderToEnumDirection(orderDirection));

  return (
    <div className="general-table-container">
      <ExpandableTable
        columns={columns}
        items={items()}
        subColumns={subColumns}
        noItemsPlaceholder={props.t('projects.details.stats.no_statistics_have_been_generated_yet')}
        onSortChanged={handleSortChange}
        sortingModel={[{ orderBy, orderType: enumDirectionToStringOrder(orderDirection) }]}
      />
    </div>
  );
};

export const GeneralStatsTable = withNamespaces('new')(GeneralStatsTablePure);
