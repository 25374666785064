import { inject, injectable } from 'inversify';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { WorkspaceRole } from '../workspaces/workspaces.store';

export const AnnotationPermissionsType = Symbol('ANNOTATION_PERMISSIONS');

export interface IAnnotationPermissions {
  canGoBackToProject(): boolean;
}

@injectable()
export class AnnotationPermissions implements IAnnotationPermissions {
  constructor(@inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore) {}

  canGoBackToProject() {
    const userRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;

    return userRole !== WorkspaceRole.Worker;
  }
}
