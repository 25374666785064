import { ExportDataBlType, IExportDataBl } from '../../ExportData.bl';
import { ExportDataStore, ExportDataStoreType } from '../../ExportData.store';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { as, injectProps } from '../../../../../../__legacy__/helpers/react.helpers';

import { Loader } from '../../../../../../__legacy__/components/Loader';
import { ProjectExportsTable } from './ProjectExportsTable';
import React from 'react';
import { Settings } from '../../../../../settings/SettingsContext';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface InjectedProps {
  store: ExportDataStore;
  bl: IExportDataBl;
}

@injectProps({
  store: ExportDataStoreType,
  bl: ExportDataBlType,
})
@observer
class ProjectExportsTableContainerPure extends React.Component<InjectedProps & RouteComponentProps<{ projectId: string }>> {
  static contextType = Settings;
  declare context: React.ContextType<typeof Settings>;

  @autobind
  async handleOrderingChange(orderBy: string, orderType: string) {
    await this.props.bl.changeOrderAsync(orderBy, orderType);
  }

  @autobind
  async handlePaginationChange(pageNumber: number, pageSize: number) {
    this.context.setProjectsExportsPageSize(pageSize);
    await this.props.bl.changePaginationAsync(pageNumber, pageSize);
  }

  render() {
    return (
      <Loader isLoading={this.props.store.showLoader}>
        <ProjectExportsTable
          items={this.props.store.projectExports}
          onOrderingChange={this.handleOrderingChange}
          onPaginationChange={this.handlePaginationChange}
          paging={{ ...this.props.store.paging, pageSize: this.context.store.projectsExportsPageSize }}
        />
      </Loader>
    );
  }
}

export const ProjectExportsTableContainer = as<React.ComponentClass>(withRouter(ProjectExportsTableContainerPure));
