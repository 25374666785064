import { Col, Row } from 'reactstrap';

import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IUrlsProcessingSummaryProps {
  datasetName: string;
  rejectedUrls: string[];
}

const UrlsProcessingSummary = (props: IUrlsProcessingSummaryProps & ITranslatable) => (
  <div>
    {props.t('datasets:upload_finished_with_rejected', { datasetName: props.datasetName })}
    <div>
      <Row>
        <Col sm={12}>
          <ul>
            {props.rejectedUrls.map((u, i) => (
              <li key={i}>{u}</li>
            ))}
          </ul>
        </Col>
      </Row>
    </div>
  </div>
);

export default as<React.SFC<IUrlsProcessingSummaryProps>>(withNamespaces('datasets')(UrlsProcessingSummary));
