import { inject, injectable } from 'inversify';
import { ForbiddenError, InputStatus, StickerError } from '../../__legacy__/models/error.model';
import { INotificationsService, NotificationsServiceType } from '../../__legacy__/modules/notifications/services/notifications.service';
import { NotificationLevel, ToastNotification } from '../../__legacy__/modules/notifications/models/notification.model';
import { EVALUATION_DETAILS_STORE_INITIAL_STATE, EvaluationDetailsStoreType, IEvaluationDetailsStore } from './evaluationDetails.store';
import { EvaluationDetailsApiServiceType, IEvaluationDetailsApiService, IGetEvaluationImagesRequest, IPostEvaluationStartRequest } from './services/evaluationDetailsApi.service';
import { action } from 'mobx';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../workspaces/currentWorkspace/CurrentWorkspace.store';
import { IRouterStore, RouterStoreType } from '../../__legacy__/stores/router.store';
import { Home } from '../../__legacy__/routes/config/Home';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../common/OverlayLoader.store';
import { PaginationInfoDefault } from '../../__legacy__/models/paginationInfo.model';
import { EVALUATION_RESULTS_INTEGERS } from './evaluationDetails.model';
import { EVALUATION_STATUS } from '../evaluations/evaluations.model';
import { PARAMETER_TYPE_INTERNAL, parseParameters } from '../../__legacy__/helpers/parameters.helpers';
import { downloadFile } from '../../__legacy__/helpers/fileDownload.helpers';
import { MODEL_VARIANTS } from '../../__legacy__/models/metrics.model';

export const EvaluationDetailsServiceType = Symbol('EVALUATION_DETAILS_SERVICE');

export interface IEvaluationDetailsService {
  store: IEvaluationDetailsStore;

  validateInitialEmptyFields(): void;
  hasHeaderErrors(): boolean;
  notifyAboutApiError(result: any, errorCode: string, dontShowBadRequest?: boolean): void;

  // overview
  getEvaluationDetailsAsync(evaluationId: string): Promise<void | StickerError>;
  getEvaluationModelsAsync(): Promise<void | StickerError>;
  getEvaluationProjectsAsync(): Promise<void | StickerError>;
  getEvaluationDatasetsAsync(): Promise<void | StickerError>;
  getEvaluationSettingsSchemeAsync(setValues?: boolean): Promise<void | StickerError>;
  getEvaluationImagesAsync(): Promise<void | StickerError>;
  getEvaluationImagesPredictionsAsync(): Promise<void | StickerError>;
  getEvaluationStatusAsync(evaluationId: string): Promise<EVALUATION_STATUS>;
  getEvaluationProgressAsync(evaluationId: string): Promise<void | StickerError>;

  // actions
  changeDatasetSelection(datasetId: string): void;
  changeAllDatasetsSelection(): void;
  deselectAllDatasets(): void;
  selectAllDatasets(): void;
  handleChangeNumericParam(label: string, value: string): void;
  handleBlurNumericParam(label: string, value: string): void;
  handleChangeBooleanParam(label: string, value: boolean): void;
  handleBlurBooleanParam(label: string, value: boolean): void;
  handleChangeName(name: string): void;
  handleChangeDescription(description: string): void;
  handleChangeProject(projectId: string): void;
  handleChangeModel(modelId: string): void;
  downloadModelAsync(): Promise<void | StickerError>;
  startEvaluationAsync(): Promise<void | StickerError>;
  stopEvaluationAsync(): Promise<void | StickerError>;
  changePagination(pageNumber: number, pageSize: number): Promise<void>;
  getTabsValidationErrors(): Map<string, InputStatus>;
  handleBlurName(name: string): void;
  handleBlurProject(projectId: string): void;
  handleBlurModel(modelId: string): void;
  togglePredictions(): Promise<void>;
  handleBlurDescription(description: string): void;
  handleChangeModelVariant(modelVariant: MODEL_VARIANTS): void;
  handleBlurModelVariant(modelVariant: MODEL_VARIANTS): void;
}

@injectable()
export class EvaluationDetailsService implements IEvaluationDetailsService {
  constructor(
    @inject(EvaluationDetailsStoreType) public readonly store: IEvaluationDetailsStore,
    @inject(NotificationsServiceType) private readonly notificationsService: INotificationsService,
    @inject(EvaluationDetailsApiServiceType) private readonly evaluationDetailsApiService: IEvaluationDetailsApiService,
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore,
    @inject(RouterStoreType) private readonly routerStore: IRouterStore,
    @inject(OverlayLoaderStoreType) private readonly overlayLoaderStore: IOverlayLoaderStore,
  ) {}

  @action
  async getEvaluationDetailsAsync(evaluationId: string): Promise<void | StickerError> {
    const { currentWorkspace } = this.currentWorkspaceStore;

    if (!currentWorkspace) return;

    const result = await this.evaluationDetailsApiService.getEvaluationDetailsAsync(currentWorkspace.id, evaluationId);

    if (result instanceof StickerError) return;

    this.store.id = evaluationId;
    this.store.name = result.inference_name;
    this.store.description = result.inference_description || '';
    this.store.modelVariant = result.model_variant;
    this.store.createdAt = `${result.date_inference}Z`; // Adding Z to mark this time as UTC (backend sends time without it)
    this.store.status = result.status === EVALUATION_STATUS.FAILING ? EVALUATION_STATUS.STOPPING : result.status;
    this.store.failureReason = result.failure_reason;
    this.store.projectId = result.datasets.project_id;
    this.store.datasetIds = result.datasets.datasets_ids;
    this.store.modelId = result.training_job_id;
    this.store.isOwner = result.is_owner;
    this.store.isTrainingOwner = result.is_training_owner;
    this.store.evaluationConfig = { ...result.inference_config_ };

    await this.getEvaluationProjectsAsync();
    await this.getEvaluationModelsAsync();
    await this.getEvaluationSettingsSchemeAsync();
    await this.getEvaluationDatasetsAsync();
    await this.getEvaluationImagesAsync();
  }

  @action
  async getEvaluationModelsAsync(): Promise<void | StickerError> {
    const { currentWorkspace } = this.currentWorkspaceStore;

    if (!currentWorkspace) return;

    const { modelVariant } = this.store;

    const result = await this.evaluationDetailsApiService.getEvaluationModelsAsync(currentWorkspace.id, modelVariant);

    if (result instanceof StickerError) return;

    this.store.models = result.jobs.map(model => ({
      value: model.training_job_id,
      label: model.model_name,
    }));
  }

  @action
  async getEvaluationProjectsAsync(): Promise<void | StickerError> {
    const { currentWorkspace } = this.currentWorkspaceStore;

    if (!currentWorkspace) return;

    const result = await this.evaluationDetailsApiService.getProjectsInWorkspaceAsync({ workspaceId: currentWorkspace.id });

    if (result instanceof StickerError) return;

    this.store.projects = result.map(project => ({
      value: project.id,
      label: project.name,
    }));
  }

  @action
  async getEvaluationDatasetsAsync(): Promise<void | StickerError> {
    const { projectId } = this.store;

    await this.overlayLoaderStore.withLoaderAsync('evaluation-datasets-list', async () => {
      const result = await this.evaluationDetailsApiService.getEvaluationDatasetsAsync({ projectId });

      if (result instanceof StickerError) {
        return;
      }

      this.store.datasets = result;
    });
  }

  @action
  async getEvaluationImagesAsync(): Promise<void | StickerError> {
    const { pageNumber, pageSize, orderBy, orderType, search } = this.store.evaluationImagesPaging;
    const { projectId, datasetIds } = this.store;

    const request: IGetEvaluationImagesRequest = {
      pageNumber,
      pageSize,
      orderBy,
      orderType,
      search,
      projectId,
      datasetsIds: datasetIds,
    };

    await this.overlayLoaderStore.withLoaderAsync('evaluation-images-loader', async () => {
      const result = await this.evaluationDetailsApiService.getEvaluationImagesAsync(request);

      if (result instanceof StickerError) return;

      if (result.pagesCount < result.pageNumber && result.pagesCount !== 0) {
        this.store.evaluationImagesPaging.pageNumber = result.pagesCount;
        await this.getEvaluationImagesAsync();
      } else {
        this.store.images = result.data;
        this.store.evaluationImagesPaging = {
          ...this.store.evaluationImagesPaging,
          orderType,
          orderBy,
          pageNumber: result.pageNumber,
          pagesCount: result.pagesCount,
          totalCount: result.totalCount,
        };
        if (this.store.evaluationImagesPaging.fetchPredictions) {
          this.getEvaluationImagesPredictionsAsync();
        }
      }
    });
  }

  @action
  async getEvaluationImagesPredictionsAsync(): Promise<void | StickerError> {
    const { id, images } = this.store;
    const { currentWorkspace } = this.currentWorkspaceStore;

    if (!currentWorkspace) return;

    await this.overlayLoaderStore.withLoaderAsync('evaluation-images-predictions-loader', async () => {
      const imagesIds = images.map(image => image.id);
      const result = await this.evaluationDetailsApiService.getEvaluationImagesPredictionsAsync(currentWorkspace.id, id, imagesIds);

      if (result instanceof StickerError) {
        this.store.evaluationImagesPaging.fetchPredictions = false;
        this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'evaluations:predictions_fetch_error'));
        return;
      }

      this.store.images = images.map(image => ({
        ...image,
        prediction: {
          confusion_matrix: null,
          recall: null,
          precision: null,
          f1: null,
          auc: null,
          count: null,
          precision_recall_curve: null,
          score: null,
          ground_truth: result[image.id]?.ground_truth || null,
          ...(result[image.id]?.metrics || {}),
        },
      }));
    });
  }

  @action
  async getEvaluationSettingsSchemeAsync(setValues?: boolean): Promise<void | StickerError> {
    const { currentWorkspace } = this.currentWorkspaceStore;

    if (!currentWorkspace?.id) return;

    const { modelVariant } = this.store;

    const result = await this.evaluationDetailsApiService.getEvaluationSettingsParamsAsync(currentWorkspace.id, modelVariant);

    if (result instanceof StickerError) return;

    const { parsedParameters, preselectedValues } = parseParameters(result.params);
    this.store.params = parsedParameters;
    if (setValues) {
      this.store.evaluationConfig = preselectedValues;
    }
  }

  @action.bound
  async downloadModelAsync() {
    const { currentWorkspace } = this.currentWorkspaceStore;

    if (!currentWorkspace) return;

    const { modelId } = this.store;

    const result = await this.evaluationDetailsApiService.getDownloadModelAsync(currentWorkspace.id, modelId);

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'evaluations:generate_download_url.failed');
      return;
    }

    downloadFile(result.url);
  }

  @action.bound
  async startEvaluationAsync() {
    const { currentWorkspace } = this.currentWorkspaceStore;

    if (!currentWorkspace) return;

    this.validateInitialEmptyFields();
    const validationErrors = this.getTabsValidationErrors();

    const { id, name, description, datasetIds, evaluationConfig, modelId, projectId, evaluationDetailsValidationErrors } = this.store;
    this.store.evaluationDetailsValidationErrors = { ...evaluationDetailsValidationErrors, showAll: true };

    if (validationErrors.size > 0 || this.hasHeaderErrors()) return;

    const request: IPostEvaluationStartRequest = {
      inference_name: name,
      inference_description: description,
      inference_config_: evaluationConfig,
      datasets: {
        project_id: projectId,
        datasets_ids: datasetIds,
      },
    };

    if (id) {
      const result = await this.evaluationDetailsApiService.postEvaluationRestartAsync(currentWorkspace.id, id, request);

      if (result instanceof StickerError) {
        this.notifyAboutApiError(result, 'evaluation:restart_evaluation.failed');
        return;
      }
      this.store.status = EVALUATION_STATUS.STARTING;

      this.routerStore.push(Home.Evaluations.Details.Metrics.withParams({ workspaceId: currentWorkspace.id, jobId: result['job-id'] }));

      this.notificationsService.push(new ToastNotification(NotificationLevel.SUCCESS, 'evaluations:restart_evaluation.success'));
    } else {
      const result = await this.evaluationDetailsApiService.postEvaluationStartAsync(currentWorkspace.id, modelId, request);
      if (result instanceof StickerError) {
        this.notifyAboutApiError(result, 'evaluation:start_evaluation.failed');
        return;
      }
      this.store.status = EVALUATION_STATUS.STARTING;

      this.routerStore.push(Home.Evaluations.Details.Metrics.withParams({ workspaceId: currentWorkspace.id, jobId: result['inference-id'] }));

      this.notificationsService.push(new ToastNotification(NotificationLevel.SUCCESS, 'evaluations:start_evaluation.success'));
    }

    // Reset validation errors after success
    this.store.evaluationDetailsValidationErrors = EVALUATION_DETAILS_STORE_INITIAL_STATE.evaluationDetailsValidationErrors;
  }

  @action.bound
  async stopEvaluationAsync() {
    const { currentWorkspace } = this.currentWorkspaceStore;
    const { id } = this.store;

    if (!currentWorkspace) return;

    const result = await this.evaluationDetailsApiService.postEvaluationStopAsync(currentWorkspace.id, id);

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'evaluations:stop_evaluation.failed');
      return;
    }

    this.notificationsService.push(new ToastNotification(NotificationLevel.SUCCESS, 'evaluations:stop_evaluation.success'));

    this.store.status = EVALUATION_STATUS.STOPPING;
  }

  @action
  async getEvaluationProgressAsync(evaluationId: string): Promise<void> {
    const { currentWorkspace } = this.currentWorkspaceStore;

    if (!currentWorkspace) return;

    const result = await this.evaluationDetailsApiService.getEvaluationProgressAsync(currentWorkspace.id, evaluationId);

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'evaluations:get_evaluation_progress_failed');
      return;
    }

    if (!result.metrics) return;

    this.store.results = result.metrics.results.map((result, idx) =>
      EVALUATION_RESULTS_INTEGERS.includes(result.name)
        ? { ...result, id: idx.toString() }
        : {
            ...result,
            id: idx.toString(),
            // Using != to check for null and undefined
            train: result.train != null ? result.train.toFixed(3) : null,
            test: result.test != null ? result.test.toFixed(3) : null,
            validation: result.validation != null ? result.validation.toFixed(3) : null,
          },
    );

    if (result.metrics['confusion_matrix'].length) {
      this.store.matrix = result.metrics['confusion_matrix'].map((item, idx) => ({ ...item, id: idx.toString() }));
    }

    this.store.metrics = {
      // Using != to check for null and undefined
      auc: result.metrics.auc != null ? result.metrics.auc.toFixed(3) : 0,
    };

    this.store.curve = result.metrics['precision_recall_curve'];
  }

  @action
  async getEvaluationStatusAsync(evaluationId: string): Promise<EVALUATION_STATUS> {
    const { currentWorkspace } = this.currentWorkspaceStore;

    if (!currentWorkspace) return EVALUATION_STATUS.STARTING;

    const result = await this.evaluationDetailsApiService.getEvaluationStatusAsync(currentWorkspace.id, evaluationId);

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'evaluations:get_evaluation_status_failed');
      return EVALUATION_STATUS.STARTING;
    }

    const { status, starting_progress } = result;
    this.store.status = status === EVALUATION_STATUS.FAILING ? EVALUATION_STATUS.STOPPING : status;
    this.store.startingProgress = starting_progress;

    return status;
  }

  @action.bound
  changeDatasetSelection(datasetId: string) {
    const { datasetIds, evaluationDetailsValidationErrors } = this.store;

    if (datasetIds.includes(datasetId)) {
      this.store.datasetIds = datasetIds.filter(id => id !== datasetId);
    } else {
      this.store.datasetIds = [...datasetIds, datasetId];
    }

    evaluationDetailsValidationErrors.datasets = this.store.datasetIds.length === 0;
    evaluationDetailsValidationErrors.images = this.store.datasetIds.length === 0;
    this.store.evaluationDetailsValidationErrors = { ...evaluationDetailsValidationErrors };

    this.changePagination(PaginationInfoDefault.pageNumber, this.store.evaluationImagesPaging.pageSize);
  }

  @action.bound
  changeAllDatasetsSelection() {
    if (this.store.datasets.length === this.store.datasetIds.length) {
      this.deselectAllDatasets();
    } else {
      this.selectAllDatasets();
    }

    const { evaluationDetailsValidationErrors } = this.store;
    evaluationDetailsValidationErrors.datasets = this.store.datasetIds.length === 0;
    evaluationDetailsValidationErrors.images = this.store.datasetIds.length === 0;
    this.store.evaluationDetailsValidationErrors = { ...evaluationDetailsValidationErrors };
  }

  @action.bound
  deselectAllDatasets() {
    this.store.datasetIds = [];
    this.changePagination(PaginationInfoDefault.pageNumber, this.store.evaluationImagesPaging.pageSize);
  }

  @action.bound
  selectAllDatasets() {
    this.store.datasetIds = this.store.datasets.map(dataset => dataset.id);
    this.changePagination(PaginationInfoDefault.pageNumber, this.store.evaluationImagesPaging.pageSize);
  }

  @action.bound
  handleChangeNumericParam(label: string, value: string) {
    const { evaluationConfig, params } = this.store;

    const param = params.find(param => param.label === label);

    if (!param) return;

    const { type } = param;
    const asInt = parseInt(value, 10);
    const asFloat = parseFloat(value);

    if (isNaN(asInt) || isNaN(asFloat)) {
      this.store.evaluationConfig = { ...evaluationConfig, [label]: 0 };
      return;
    }

    if (type === PARAMETER_TYPE_INTERNAL.INT) {
      this.store.evaluationConfig = { ...evaluationConfig, [label]: asInt };
    } else if (type === PARAMETER_TYPE_INTERNAL.FLOAT) {
      this.store.evaluationConfig = { ...evaluationConfig, [label]: asFloat };
    }
  }

  @action.bound
  handleChangeBooleanParam(label: string, value: boolean) {
    const { evaluationConfig } = this.store;

    this.store.evaluationConfig = { ...evaluationConfig, [label]: value };
  }

  @action.bound
  handleBlurBooleanParam(label: string, _: boolean) {
    const { evaluationDetailsValidationErrors } = this.store;

    evaluationDetailsValidationErrors.settings.set(label, null);

    this.store.evaluationDetailsValidationErrors = { ...evaluationDetailsValidationErrors };
  }

  @action.bound
  handleChangeName(name: string) {
    this.store.name = name;
  }

  @action.bound
  handleChangeDescription(description: string) {
    this.store.description = description;
  }

  @action
  async togglePredictions(): Promise<void> {
    this.store.evaluationImagesPaging.fetchPredictions = !this.store.evaluationImagesPaging.fetchPredictions;

    const arePredictionsFetched = this.store.images.some(image => image.prediction !== undefined);

    if (this.store.evaluationImagesPaging.fetchPredictions && !arePredictionsFetched) {
      await this.getEvaluationImagesPredictionsAsync();
    }
  }

  @action
  async changePagination(pageNumber: number, pageSize: number): Promise<void> {
    this.store.evaluationImagesPaging.pageNumber = pageNumber;
    this.store.evaluationImagesPaging.pageSize = pageSize;

    await this.getEvaluationImagesAsync();
  }

  @action.bound
  async handleChangeProject(projectId: string) {
    this.store.projectId = projectId;

    await this.getEvaluationDatasetsAsync();
    this.selectAllDatasets();
    // Revalidate datasets after data is fetched
    this.handleBlurProject(projectId);
  }

  @action.bound
  async handleChangeModelVariant(modelVariant: MODEL_VARIANTS) {
    this.store.modelVariant = modelVariant;

    await this.overlayLoaderStore.withLoaderAsync('evaluation-header', async () => {
      await this.getEvaluationModelsAsync();
    });

    await this.overlayLoaderStore.withLoaderAsync('evaluation-settings-list', async () => {
      await this.getEvaluationSettingsSchemeAsync(true);

      const { evaluationDetailsValidationErrors } = this.store;

      evaluationDetailsValidationErrors.settings.clear();
    });

    // Revalidate datasets after data is fetched
    this.handleBlurModelVariant(modelVariant);
  }

  @action.bound
  async handleChangeModel(modelId: string) {
    this.store.modelId = modelId;
  }

  @action.bound
  handleBlurNumericParam(label: string, value: string) {
    const { evaluationDetailsValidationErrors, params } = this.store;
    const param = params.find(param => param.label === label);
    if (!param || (param.type !== PARAMETER_TYPE_INTERNAL.FLOAT && param.type !== PARAMETER_TYPE_INTERNAL.INT)) return;

    const validationResult = param.config.validator(value);
    if (validationResult) {
      evaluationDetailsValidationErrors.settings.set(param.label, null);
    } else {
      evaluationDetailsValidationErrors.settings.set(param.label, {
        message: 'evaluations:params.numeric_validation_error',
        messageParameters: {
          rangeStart: param.config.min,
          rangeEnd: param.config.max,
        },
      });
    }

    this.store.evaluationDetailsValidationErrors = { ...evaluationDetailsValidationErrors };
  }

  @action.bound
  handleBlurName(name: string) {
    const { evaluationDetailsValidationErrors } = this.store;

    if (!name) {
      evaluationDetailsValidationErrors.header.set('name', {
        message: 'evaluations:fields.text_validation_error',
      });
    } else {
      evaluationDetailsValidationErrors.header.set('name', null);
    }

    this.store.evaluationDetailsValidationErrors = { ...evaluationDetailsValidationErrors };
  }

  @action.bound
  handleBlurProject(projectId: string) {
    const { datasetIds, evaluationDetailsValidationErrors } = this.store;

    if (!projectId) {
      evaluationDetailsValidationErrors.header.set('project', {
        message: 'evaluations:fields.text_validation_error',
      });
      evaluationDetailsValidationErrors.datasets = true;
    } else {
      evaluationDetailsValidationErrors.header.set('project', null);
    }

    if (datasetIds.length) {
      evaluationDetailsValidationErrors.datasets = false;
      evaluationDetailsValidationErrors.images = false;
    }

    this.store.evaluationDetailsValidationErrors = { ...evaluationDetailsValidationErrors };
  }

  @action.bound
  handleBlurModelVariant(modelVariant: MODEL_VARIANTS) {
    const { evaluationDetailsValidationErrors } = this.store;

    if (!modelVariant) {
      evaluationDetailsValidationErrors.header.set('modelVariant', {
        message: 'evaluations:fields.text_validation_error',
      });
    } else {
      evaluationDetailsValidationErrors.header.set('modelVariant', null);
    }

    this.store.evaluationDetailsValidationErrors = { ...evaluationDetailsValidationErrors };
  }

  @action.bound
  handleBlurDescription(_: string) {
    const { evaluationDetailsValidationErrors } = this.store;

    evaluationDetailsValidationErrors.header.set('description', null);

    this.store.evaluationDetailsValidationErrors = { ...evaluationDetailsValidationErrors };
  }

  @action.bound
  handleBlurModel(modelId: string) {
    const { evaluationDetailsValidationErrors } = this.store;

    if (!modelId) {
      evaluationDetailsValidationErrors.header.set('model', {
        message: 'evaluations:fields.text_validation_error',
      });
    } else {
      evaluationDetailsValidationErrors.header.set('model', null);
    }

    this.store.evaluationDetailsValidationErrors = { ...evaluationDetailsValidationErrors };
  }

  notifyAboutApiError(result: any, errorCode: string, dontShowBadRequest: boolean = false) {
    if (result instanceof StickerError && !(result instanceof ForbiddenError) && (!dontShowBadRequest || !result.isBadRequest())) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, errorCode));
    }
  }

  @action.bound
  getTabsValidationErrors() {
    const { datasetIds, evaluationDetailsValidationErrors } = this.store;
    const errors: Map<string, InputStatus> = new Map();

    // Add errors based on the state
    if (evaluationDetailsValidationErrors.settings.size > 0 && Array.from(evaluationDetailsValidationErrors.settings.values()).some(error => error !== null)) {
      errors.set('settings', InputStatus.buildFrom(['start_evaluation.validation_error']));
    }
    if (datasetIds.length === 0) {
      errors.set('datasets', InputStatus.buildFrom(['start_evaluation.no_chosen_datasets']));
    }

    return errors;
  }

  validateInitialEmptyFields() {
    const { datasetIds, projectId, evaluationDetailsValidationErrors, modelId } = this.store;

    if (datasetIds.length === 0) {
      evaluationDetailsValidationErrors.datasets = true;
      evaluationDetailsValidationErrors.images = true;
    }
    if (!projectId) {
      evaluationDetailsValidationErrors.header.set('project', {
        message: 'evaluations:fields.text_validation_error',
      });
    }

    if (!modelId) {
      evaluationDetailsValidationErrors.header.set('model', {
        message: 'evaluations:fields.text_validation_error',
      });
    }
  }

  hasHeaderErrors() {
    const { evaluationDetailsValidationErrors } = this.store;

    return evaluationDetailsValidationErrors.header.size > 0 && Array.from(evaluationDetailsValidationErrors.header.values()).some(error => error !== null);
  }
}
