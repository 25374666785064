import { WithNamespaces, withNamespaces } from 'react-i18next';

import { Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader } from '../../../../../../components/Loader';
import { ProjectPickerOption } from './ProjectPickerOption';
import { ProjectPickerSelectAllOption } from './ProjectPickerSelectAllOption';
import { QuestionModel } from '../../../../question.model';
import React from 'react';
import { as } from '../../../../../../helpers/react.helpers';
import { faLink } from '@fortawesome/free-solid-svg-icons';

export interface IProject {
  id: string;
  name: string;
  count: number;
  questions: QuestionModel[];
}

export interface IProjectPickerProps {
  options: IProject[];
  selected: Set<string>;
  opened: Set<string>;
  onChange(projectIds: string[]): void;
  onOpen(projectIds: string[]): void;
  onClose(): void;
  isLoaded: boolean;
}

class ProjectPickerPure extends React.Component<IProjectPickerProps & WithNamespaces> {
  get areAllSelected() {
    return this.props.options.every(o => this.props.selected.has(o.id));
  }

  handleChange = (id: string, value: boolean) => {
    const newValues = new Set(this.props.selected);
    if (value) {
      newValues.add(id);
    } else {
      newValues.delete(id);
    }
    this.props.onChange(Array.from(newValues));
  };

  handleOpen = (id: string, value: boolean) => {
    const newValues = new Set(this.props.opened);
    if (value) {
      newValues.add(id);
    } else {
      newValues.delete(id);
    }
    this.props.onOpen(Array.from(newValues));
  };

  handleSelectAll = (value: boolean) => this.props.onChange(value ? this.props.options.map(o => o.id) : []);

  render() {
    const { t } = this.props;
    return (
      <Col xs={2} className="project-picker">
        <div>
          <h5 className="project-picker-title">
            <FontAwesomeIcon icon={faLink} /> {t('other_projects')}
            {this.props.onClose && (
              <button onClick={this.props.onClose} className="close">
                <span>&times;</span>
              </button>
            )}
          </h5>
          {this.props.isLoaded && <p className="description">{t(this.props.options.length ? 'other_projects_description' : 'other_projects_description_zero')}</p>}
          <div className="projects">
            <Loader isLoading={!this.props.isLoaded}>
              {this.props.options.length > 0 && (
                <div className="select-all">
                  <ProjectPickerSelectAllOption onChange={this.handleSelectAll} checked={this.areAllSelected} />
                </div>
              )}
              {this.props.options.map(o => (
                <ProjectPickerOption
                  key={o.id}
                  {...o}
                  name={o.name !== '' ? o.name : `(${this.props.t('untitled')})`}
                  questions={o.questions}
                  checked={this.props.selected.has(o.id)}
                  opened={this.props.opened.has(o.id)}
                  onChange={this.handleChange}
                  onOpen={this.handleOpen}
                />
              ))}
            </Loader>
          </div>
        </div>
      </Col>
    );
  }
}

export const ProjectPicker = as<React.ComponentClass<IProjectPickerProps>>(withNamespaces('common')(ProjectPickerPure));
