import { AttachmentsServiceType, IAttachmentsService } from '../attachments.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { Trans, withNamespaces } from 'react-i18next';
import { as, injectProps } from '../../../helpers/react.helpers';

import { ConfirmationModalWithTextBox } from '../../../components/ConfirmationModalWithTextBox';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { StickerError } from '../../../models/error.model';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ attachmentId: string; }> {
  attachmentService: IAttachmentsService;
}

interface IState {
  attachmentsCount?: number;
}

@injectProps({
  attachmentService: AttachmentsServiceType,
})
@observer
class AttachmentConfirmDeleteModalContainer extends React.Component<IInjectedProps & ITranslatable, IState> {
  state: IState = {
    attachmentsCount: undefined,
  };

  @autobind
  toggleModal() {
    this.props.attachmentService.toggleDeleteAttachmentModal();
    this.setState({ attachmentsCount: undefined });
  }

  @autobind
  async onConfirmAsync() {
    const store = this.props.attachmentService.store;
    const parentId = this.props.match.params.attachmentId;

    await this.props.attachmentService.deleteAttachmentsAsync(
      store.selectedAttachments.isAllSelected,
      store.selectedAttachments.ids,
      parentId);

    this.setState({ attachmentsCount: undefined });
  }

  @autobind
  async getAttachmentsCount() {
    if (this.state.attachmentsCount !== undefined) return;

    const store = this.props.attachmentService.store;
    const parentId = this.props.match.params.attachmentId;

    const result = await this.props.attachmentService.getSelectedAttachments(
      store.selectedAttachments.isAllSelected,
      store.selectedAttachments.ids,
      parentId);

    if (!(result instanceof StickerError)) {
      this.setState({ attachmentsCount: result });
    }
  }

  render() {
    if (this.props.attachmentService.store.showDeleteConfirmationModal) this.getAttachmentsCount();

    const question = (
      <Trans
        i18nKey="attachment:delete_attachment_confirmation_multi"
        count={this.state.attachmentsCount}
        values={{ count: this.state.attachmentsCount }}
        // tslint:disable-next-line:jsx-key
        components={[<strong>0</strong>]}
      />);

    return (
      <ConfirmationModalWithTextBox
        confirmationHeader={this.props.t('delete_attachments_header')}
        confirmationQuestion={this.props.t('delete_attachment_text_multi', { count: this.state.attachmentsCount })}
        confirmationTextHeader={question}
        confirmationText={this.props.t('delete_attachment_confirmation', { count: this.state.attachmentsCount })}
        onCancel={this.toggleModal}
        onConfirm={this.onConfirmAsync}
        showModal={this.props.attachmentService.store.showDeleteConfirmationModal}
        showSpinner={this.props.attachmentService.store.isDeleteRequestInProgress}
        confirmationYes={this.props.t('delete')}
      />
    );
  }
}

export default as<React.ComponentClass>(withRouter(withNamespaces('attachment', { wait: true })(AttachmentConfirmDeleteModalContainer)));
