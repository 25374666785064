import L from 'leaflet';
import { Map, MapProps, Point } from 'react-leaflet';

export interface CustomMapProps {
  imageCenterPaddingTopLeft?: Point;
}

export class MapEx<P extends MapProps = MapProps, E extends L.Map = L.Map> extends Map<P & CustomMapProps, E> {
  componentDidUpdate(prevProps: Readonly<P> & CustomMapProps, prevState: Readonly<{}>, snapshot?: any) {
    const shouldSuppressAnimation = this.shouldUpdateBounds(this.props.bounds!, prevProps.bounds!);
    super.componentDidUpdate!(prevProps, prevState);

    if (shouldSuppressAnimation) {
      this.leafletElement.off('moveend', (this.leafletElement as any)._panInsideMaxBounds);
      this.leafletElement.panInsideBounds(this.props.maxBounds!, { animate: false });
      const options = (this as any).getFitBoundsOptions(this.props);

      this.leafletElement.fitBounds(this.props.bounds!, { ...options, paddingTopLeft: this.props.imageCenterPaddingTopLeft });
      this.leafletElement.on('moveend', (this.leafletElement as any)._panInsideMaxBounds);
    }
  }
}
