import { Card, CardBody } from 'reactstrap';

import { ITranslatable } from '../../../../helpers/translations.helpers';
import React from 'react';
import ResendEmail from './ResendEmail';
import { ResendStatus } from '../../containers/ConfirmEmailContainer';
import { as } from '../../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  resendStatus: ResendStatus;
  onResendActivationEmailClick(): void;
}

type Props = ITranslatable & IProps;

const ResendConfirmationEmail = (props: Props) => {
  const { t } = props;

  return (
    <div className="account-activation">
      <Card>
        <CardBody>
          <p className="header">
            {t('verification_link_title')}
          </p>
          <hr />
          <p className="content">
            {t('verify_your_email')}
          </p>
          <br />
          <ResendEmail
            onResendActivationEmailClick={props.onResendActivationEmailClick}
            resendStatus={props.resendStatus}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default as<React.SFC>(withNamespaces('auth')(ResendConfirmationEmail));
