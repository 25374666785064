import { DragDropContext, DropResult, Droppable } from '@qzsoftware/react-beautiful-dnd';
import { ISorting, SortingBy } from '../../../imageFilters.model';

import { Button } from 'reactstrap';
import { FilterContent } from '../FilterContent';
import { ISelectOption } from '../../../../../../../helpers/formHelpers';
import { ITranslatable } from '../../../../../../../helpers/translations.helpers';
import { ImageFiltersFormProps } from '../ImageFiltersForm';
import React from 'react';
import { SortingDirection } from '../../../../../../../models/sortingDirection.model';
import { SortingRowForm } from './SortingRowForm';
import { as } from '../../../../../../../helpers/react.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

@observer
class SortingBodyPure extends React.Component<ImageFiltersFormProps & ITranslatable> {

  constructor(props: ImageFiltersFormProps & ITranslatable) {
    super(props);
  }

  handleSortingChanged = (sorting: ISorting) => {
    const newArray = this.props.imageFilters.sorting.slice();
    const index = newArray.findIndex(x => x.order === sorting.order);
    if (index > -1) {
      newArray.splice(index, 1, sorting);
      this.props.onSetSorting(newArray);
    }
  }

  handleAddAnother = () => {
    const topOrder = this.props.imageFilters.sorting.map(x => x.order).reduce((x, y) => x > y ? x : y, 0);
    const nesSorting = this.props.imageFilters.sorting.concat({
      by: undefined,
      direction: SortingDirection.ASC,
      order: topOrder + 1,
    });
    this.props.onSetSorting(nesSorting);
  }

  handleDelete = (order: number) => {
    const newSoring = this.props.imageFilters.sorting
      .filter(x => x.order !== order)
      .sort((a, b) => a.order - b.order)
      .map((a, i) => ({ ...a, order: i }));

    this.props.onSetSorting(newSoring);
  }

  handleDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const [removed] = this.props.imageFilters.sorting.splice(result.source.index, 1);
    this.props.imageFilters.sorting.splice(result.destination.index, 0, removed);
    const newSorting = this.props.imageFilters.sorting.map((a, i) => ({ ...a, order: i }));

    this.props.onSetSorting(newSorting);
  }

  render() {
    const allOptions: ISelectOption<SortingBy>[] = Object.values(SortingBy).filter(x => x !== SortingBy.None).map(t => ({ label: this.props.t(`sorting_by.${t}`), value: t }));

    return (
      <DragDropContext onDragEnd={this.handleDragEnd}>
        <FilterContent labelKey={'sorting'}>
          <Droppable droppableId="droppable">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps} >
                {this.props.imageFilters.sorting.map((x) => {
                  const options = allOptions.filter(a => !this.props.imageFilters.sorting.filter(s => s.order !== x.order).some(x => x.by === a.value));
                  return (
                    <SortingRowForm
                      key={x.order}
                      order={x.order}
                      sorting={x}
                      options={options}
                      onChange={this.handleSortingChanged}
                      onDelete={this.handleDelete}
                    />
                  );
                })}
                {provided.placeholder}
              </div>)}
          </Droppable>
          <Button color="Primary" onClick={this.handleAddAnother} >+ {this.props.t('add_another')} </Button>
        </FilterContent>
      </DragDropContext>
    );
  }
}

export const SortingBody = as<React.ComponentClass<ImageFiltersFormProps>>(withNamespaces('common')(SortingBodyPure));
