import { IProjectDetailsStatisticsBl, ProjectDetailsStatisticsBlType } from '../projectDetailsStatistics.bl';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { ITranslatable } from '../../../../../helpers/translations.helpers';
import React from 'react';
import { observer } from 'mobx-react';
import { timeZones } from '../../../../../models/timeZones/timeZones.model';
import { toLocaleDateTimeString } from '../../../../../helpers/date.helpers';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  projectDetailsStatisticsBl: IProjectDetailsStatisticsBl;
}

@injectProps({
  projectDetailsStatisticsBl: ProjectDetailsStatisticsBlType,
})
@observer
class NoStatisticsMessageContainerPure extends React.Component<ITranslatable & IInjectedProps> {
  render() {
    const timezone = timeZones.find(tz => tz.value === this.props.projectDetailsStatisticsBl.store.offset);

    if (this.props.projectDetailsStatisticsBl.store.offset === undefined) return null;

    return (
      <div>
        {this.props.t('statistics_generated_for')}
        <strong>{timezone ? timezone.label : this.props.t('unknown')}</strong>
        {this.props.projectDetailsStatisticsBl.store.statisticsGeneratedOn && (
          <>
            {' '}
            {this.props.t('on')}
            <strong>{toLocaleDateTimeString(this.props.projectDetailsStatisticsBl.store.statisticsGeneratedOn!.toString())}</strong>
          </>
        )}
        .
      </div>
    );
  }
}

export const NoStatisticsMessageContainer = as<React.ComponentClass>(withNamespaces('common')(NoStatisticsMessageContainerPure));
