import * as React from 'react';

import { DashboardContainer } from '../../containers/DashboardContainer';
import { PageHeader } from '../../components/PageHeader';
import { TeamContainer } from '../../modules/team/containers/Team.container';

const Header = () => (
  <PageHeader headerText="Team" headerDescription="" tabNavPresent="clean" />
);

export const TeamView = () => (
    <DashboardContainer header={Header}>
      <TeamContainer />
    </DashboardContainer>
);
