import * as React from 'react';

import { AnnotationServiceType, IAnnotationService } from '../annotation.service';
import { AnnotationUiStoreType, IAnnotationUiStore } from '../annotationUi.store';
import { AnnotationsStoreType, IAnnotationsStore } from '../annotations.store';
import { FreeDrawSegmentationServiceType, IFreeDrawSegmentationService } from '../freeDrawSegmentation.service';
import { IUndoRedoHistory, UndoRedoHistoryType } from '../undoRedoHistory.service';
import { action, toJS } from 'mobx';
import { as, injectProps } from '../../../helpers/react.helpers';

import { AnswerQuestionCommand } from '../undoRedoCommands/answerQuestionCommand';
import { IQuestionsListProps } from '../components/annotations/QuestionsList';
import { ImageScopeName } from '../question.model';
import { observer } from 'mobx-react';

interface IInjectedProps {
  annotationService: IAnnotationService;
  freeDrawSegmentationService: IFreeDrawSegmentationService;
  uiStore: IAnnotationUiStore;
  undoRedoService: IUndoRedoHistory;
  annotationsStore: IAnnotationsStore;
}

export interface IQuestionsContainerProps {
  readonly?: boolean;
}

@injectProps({
  annotationService: AnnotationServiceType,
  freeDrawSegmentationService: FreeDrawSegmentationServiceType,
  uiStore: AnnotationUiStoreType,
  undoRedoService: UndoRedoHistoryType,
  annotationsStore: AnnotationsStoreType,
})
@observer
class QuestionsContainer extends React.Component<IInjectedProps & IQuestionsContainerProps> {
  @action.bound
  handleQuestionAnswered(questionId: string, answer: string) {
    const question = this.props.annotationsStore.currentQuestions.find(x => x.id === questionId);

    if (question) {
      if (question.scopes.some(x => x === ImageScopeName)) {
        const questionBeforeChange = toJS(question);
        question.onAnswered(answer);
        this.props.undoRedoService.addCommand(
          new AnswerQuestionCommand(this.props.annotationService, this.props.annotationsStore, this.props.freeDrawSegmentationService, questionBeforeChange, toJS(question)),
        );
      } else {
        question.onAnswered(answer);
      }
    }
  }

  render() {
    const shouldLock = this.props.readonly !== undefined ? this.props.readonly : this.props.annotationsStore.image === undefined && !this.props.uiStore.isImageLoading;

    return React.Children.map(this.props.children, (child: any) =>
      React.cloneElement(child, {
        readonly: shouldLock,
        questions: this.props.annotationsStore.currentQuestions,
        segmentationId: this.props.annotationsStore.selectedSegmentation?.feature.id,
        onAnswered: this.handleQuestionAnswered,
      } as IQuestionsListProps),
    );
  }
}

export default as<React.ComponentClass<IQuestionsContainerProps>>(QuestionsContainer);
