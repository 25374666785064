export async function timesAsync(n: number, fun: Function): Promise<void> {
  for (let i = 0; i < n; i += 1) await fun();
}

export const dinduNuffin = () => { };

export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
