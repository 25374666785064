import * as React from 'react';

interface IProps {
  children: React.ReactElement<any>[] | React.ReactElement<any>;
  variation?: string;
}

export const AnnotationTopBarItem = (props: IProps) => (
  <div className={`element ${props.variation}`}>{props.children}</div>
);
