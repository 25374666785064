import * as React from 'react';

import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IPublishDatasetConfirmationModalProps {
  showPublishDatasetConfirmationModal: boolean;
  onDatasetPublishConfirm(): void;
  onPublishDatasetCancel(): void;
}

class PublishDatasetConfirmationModalPure extends React.Component<IPublishDatasetConfirmationModalProps & ITranslatable> {
  render() {
    return (
      <ConfirmationModal
        confirmationHeader="publish_dataset_confirmation"
        confirmationQuestion="publish_dataset_confirmation_body"
        confirmationYes={this.props.t('publish')}
        onCancel={this.props.onPublishDatasetCancel}
        onConfirm={this.props.onDatasetPublishConfirm}
        showModal={this.props.showPublishDatasetConfirmationModal}
        confirmColor="success"
        loaderKey="publish"
      />
    );
  }
}

export const PublishDatasetConfirmationModal = as<React.StatelessComponent<IPublishDatasetConfirmationModalProps>>(
  withNamespaces('common', { wait: true })(PublishDatasetConfirmationModalPure),
);
