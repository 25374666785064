import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../__legacy__/helpers/react.helpers';
import { observer } from 'mobx-react';
import { EvaluationDetailsTabs } from '../components/EvaluationDetailsTabs';
import { EvaluationDetailsServiceType, IEvaluationDetailsService } from '../evaluationDetails.service';

interface IInjectedProps extends RouteComponentProps<{ workspaceId: string; jobId?: string }> {
  evaluationDetailsService: IEvaluationDetailsService;
}

@injectProps({
  evaluationDetailsService: EvaluationDetailsServiceType,
})
@observer
class EvaluationDetailsTabsContainerPure extends React.Component<IInjectedProps> {
  render() {
    return (
      <EvaluationDetailsTabs
        evaluationId={this.props.match.params.jobId}
        workspaceId={this.props.match.params.workspaceId}
        evaluationStatus={this.props.evaluationDetailsService.store.status}
        evaluationDetailsValidationErrors={this.props.evaluationDetailsService.store.evaluationDetailsValidationErrors}
      />
    );
  }
}

export const EvaluationDetailsTabsContainer = as<React.ComponentClass>(withRouter(EvaluationDetailsTabsContainerPure));
