import * as React from 'react';

import { Button, Card, CardBody, CardText, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import { faDrawPolygon, faEllipsisV, faExclamationTriangle, faGlasses, faGlobe, faImages, faTrashAlt, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';

import { ButtonWithLoader } from '../../../../components/ButtonWithLoader';
import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { DropdownLinkItem } from '../../../../components/DropdownLinkItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Home } from '../../../../routes/config/Home';
import { ITranslatable } from '../../../../helpers/translations.helpers';
import { Link } from 'react-router-dom';
import { PencilWithRuler } from '../../../../components/StaticImages';
import { RestrictAccessTo } from '../../../../containers/RestrictAccessTo';
import { RestrictAccessTo2 } from '../../../../containers/RestrictAccessTo2';
import { WorkspaceRole } from '../../../workspaces/workspaces.store';
import { as } from '../../../../helpers/react.helpers';
import { handleClickAndPassData } from '../../../../helpers/formHelpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';

library.add(faEllipsisV, faUser, faTrashAlt, faImages, faUsers, faGlobe, faDrawPolygon, faGlasses, faExclamationTriangle);

interface IProjectListItemProps {
  id: string;
  name: string;
  description: string;
  imageCount: number;
  usersCount: number;
  myRejectedCount: number;
  workspaceId: string;
  unansweredClarificationsCount: number;
  onProjectDelete(projectId: string): void;
  onGoToClarifications?(projectId: string): void;
  canAnnotate: boolean;
  canReview: boolean;
  canEdit: boolean;
  canView: boolean;
  canDelete: boolean;
  canExport: boolean;
}

export const ProjectThumbItem = as<React.SFC<IProjectListItemProps>>(
  withNamespaces('project', { wait: true })((props: IProjectListItemProps & ITranslatable) => (
    <Col md="6" lg="6" xl="4" className="d-flex">
      <Card className="flex-fill project-card with-dropdown">
        <div className="top-options">
          {/* top left corner */}
          <RestrictAccessTo2 hasAccess={props.canAnnotate && !props.canReview}>
            <span className="top-badge non-owner">
              <img src={PencilWithRuler} className="small-icon" />
            </span>
          </RestrictAccessTo2>
          <RestrictAccessTo2 hasAccess={props.canReview}>
            <span className="top-badge non-owner">
              <FontAwesomeIcon icon={faGlasses} color="#FFFFFF" />
            </span>
          </RestrictAccessTo2>
          {/* top right corner */}
          <UncontrolledDropdown className="card-dropdown">
            <DropdownToggle className="width-50">
              <FontAwesomeIcon icon={faEllipsisV} />
            </DropdownToggle>
            <DropdownMenu right>
              <RestrictAccessTo2 hasAccess={props.canView}>
                <DropdownLinkItem linkTo={Home.Projects.Details.Overview.withParams({ projectId: props.id, workspaceId: props.workspaceId })}>
                  {props.t('project_details')}
                </DropdownLinkItem>
              </RestrictAccessTo2>
              <RestrictAccessTo2 hasAccess={props.canView && props.canAnnotate}>
                <DropdownItem divider />
              </RestrictAccessTo2>
              <RestrictAccessTo2 hasAccess={props.canAnnotate}>
                <DisabledWhenWorkspaceOwnerPolicyExceeded>
                  <DropdownLinkItem linkTo={Home.Annotations.Create.withParams({ projectId: props.id, workspaceId: props.workspaceId })}>
                    {props.t('start_annotation')}
                  </DropdownLinkItem>
                </DisabledWhenWorkspaceOwnerPolicyExceeded>
              </RestrictAccessTo2>
              <RestrictAccessTo2 hasAccess={props.canReview}>
                <DisabledWhenWorkspaceOwnerPolicyExceeded>
                  <DropdownLinkItem linkTo={Home.Annotations.Review.withParams({ projectId: props.id, workspaceId: props.workspaceId })}>
                    {props.t('start_review')}
                  </DropdownLinkItem>
                </DisabledWhenWorkspaceOwnerPolicyExceeded>
              </RestrictAccessTo2>
              <RestrictAccessTo2 hasAccess={props.canEdit}>
                <DropdownItem divider />
                <DropdownItem onClick={handleClickAndPassData(props.onProjectDelete)(props.id)} className="red">
                  <FontAwesomeIcon icon={faTrashAlt} /> {props.t('delete_project')}
                </DropdownItem>
              </RestrictAccessTo2>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <CardBody>
          <div className="info">
            <CardTitle tag="h5" className="name">
              <RestrictAccessTo2 hasAccess={!props.canView}>{props.name}</RestrictAccessTo2>
              <RestrictAccessTo2 hasAccess={props.canView}>
                <Link to={Home.Projects.Details.Overview.withParams({ projectId: props.id, workspaceId: props.workspaceId })}> {props.name}</Link>
              </RestrictAccessTo2>
            </CardTitle>
            <Row>
              <Col>
                <CardText>
                  <FontAwesomeIcon icon={faImages} /> <span>{props.imageCount}</span> {props.t('images')}
                </CardText>
              </Col>
              <Col className="text-right">
                <RestrictAccessTo2 hasAccess={props.canView}>
                  <CardText>
                    <FontAwesomeIcon icon={faUsers} /> <span>{props.usersCount}</span> {props.t('users')}
                  </CardText>
                </RestrictAccessTo2>
              </Col>
            </Row>
            {props.myRejectedCount > 0 && (
              <CardText className="warning">
                <FontAwesomeIcon icon={faExclamationTriangle} />
                <strong>{props.myRejectedCount}</strong>
                &nbsp;
                {props.t('rejected_annotations_warning', { count: props.myRejectedCount })}
              </CardText>
            )}
            <RestrictAccessTo workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager]} projectRoles={[]}>
              {props.unansweredClarificationsCount > 0 && (
                <CardText
                  className={`warning ${props.onGoToClarifications ? 'link' : ''}`}
                  onClick={props.onGoToClarifications ? handleClickAndPassData(props.onGoToClarifications)(props.id) : undefined}
                >
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  <strong>{props.unansweredClarificationsCount}</strong>
                  &nbsp;
                  {props.t('clarifications.unanswered_clarifications_warning', { count: props.unansweredClarificationsCount })}
                </CardText>
              )}
            </RestrictAccessTo>
            <CardText className="description">{props.description}</CardText>
          </div>
          <div className="bottom">
            <RestrictAccessTo2 hasAccess={props.canAnnotate && !props.canReview}>
              <Link to={Home.Annotations.Create.withParams({ projectId: props.id, workspaceId: props.workspaceId })} className="float-left">
                <DisabledWhenWorkspaceOwnerPolicyExceeded>
                  <Button className="start-annotation" color="primary">
                    {props.t('start_annotation')}
                  </Button>
                </DisabledWhenWorkspaceOwnerPolicyExceeded>
              </Link>
            </RestrictAccessTo2>
            <RestrictAccessTo2 hasAccess={props.canReview}>
              <Link to={Home.Annotations.Review.withParams({ projectId: props.id, workspaceId: props.workspaceId })} className="float-left">
                <DisabledWhenWorkspaceOwnerPolicyExceeded>
                  <ButtonWithLoader className="start-review" color="primary">
                    {props.t('start_review')}
                  </ButtonWithLoader>
                </DisabledWhenWorkspaceOwnerPolicyExceeded>
              </Link>
            </RestrictAccessTo2>
          </div>
        </CardBody>
      </Card>
    </Col>
  )),
);
