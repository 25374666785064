import { action, observable } from 'mobx';

import { injectable } from 'inversify';

export const UpdateWorkspaceTimeZoneStoreType = Symbol('UPDATE_WORKSPACE_TIMEZONE_STORE');
export const UpdateWorkspaceTimeZoneStoreSetterType = Symbol('UPDATE_WORKSPACE_TIMEZONE_STORE_SETTER');

export interface IUpdateWorkspaceTimeZoneStore {
  readonly selectedTimeZoneOffset: number;
  readonly showModal: boolean;
  readonly workspaceId: string;
}

export interface IUpdateWorkspaceTimeZoneStoreSetter extends IUpdateWorkspaceTimeZoneStore {
  hideForm(): void;
  setSelectedTimeZoneOffset(timeZoneOffset: number): void;
  showForm(workspaceId: string, currentTimeZone: number): void;
}

@injectable()
export class UpdateWorkspaceTimeZoneStore implements IUpdateWorkspaceTimeZoneStoreSetter {
  @observable
  showModal: boolean = false;

  @observable
  selectedTimeZoneOffset: number = 0;

  @observable
  workspaceId: string = '';

  @action
  hideForm(): void {
    this.showModal = false;
  }

  @action
  showForm(workspaceId: string, timeZoneOffset: number): void {
    this.showModal = true;
    this.workspaceId = workspaceId;
    this.selectedTimeZoneOffset = timeZoneOffset;
  }

  @action
  setSelectedTimeZoneOffset(timeZoneOffset: number): void {
    this.selectedTimeZoneOffset = timeZoneOffset;
  }
}
