import { FilterHeader } from '../FilterHeader';
import { FilterTabType } from '../../../imageFilters.model';
import { ITranslatable } from '../../../../../../../helpers/translations.helpers';
import { ImageFiltersFormProps } from '../ImageFiltersForm';
import React from 'react';
import { as } from '../../../../../../../helpers/react.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

@observer
class ReviewedByHeaderPure extends React.Component<ImageFiltersFormProps & ITranslatable> {
  handleClear = () => this.props.onSetReviewedBy([]);

  render() {
    const isSelected = this.props.activeFilterType === FilterTabType.ReviewedBy;
    const count = this.props.imageFilters.reviewedBy.length;

    return (
      <FilterHeader
        disabled={!this.props.canSeeUsers}
        filterType={FilterTabType.ReviewedBy}
        isSelected={isSelected}
        labelKey={'reviewed_by'}
        count={count}
        showClear={count > 0}
        onClear={this.handleClear}
        onSelect={this.props.onFilterTabSelect}
      />
    );
  }
}

export const ReviewedByHeader = as<React.ComponentClass<ImageFiltersFormProps>>(withNamespaces('common')(ReviewedByHeaderPure));
