import { IProjectStatsFiltersStore, ProjectStatsFiltersStoreType } from '../filters/ProjectStatsFilters.store';
import { as, injectProps } from '../../../../../__legacy__/helpers/react.helpers';

import { AnnotationToolsSection } from './AnnotationToolsSection';
import React from 'react';
import { WithLoaderComponentBase } from '../../../../../__legacy__/helpers/loader.helpers';
import { observer } from 'mobx-react';

interface InjectedProps {
  filtersStore: IProjectStatsFiltersStore;
}

@injectProps({
  filtersStore: ProjectStatsFiltersStoreType,
})
@observer
class AnnotationToolsSectionContainerPure extends WithLoaderComponentBase<InjectedProps> {
  render(): React.ReactNode {
    return <AnnotationToolsSection endDate={this.props.filtersStore.endDate} startDate={this.props.filtersStore.startDate}/>;
  }
}

export const AnnotationToolsSectionContainer = as<React.ComponentClass>(AnnotationToolsSectionContainerPure);
