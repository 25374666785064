import * as React from 'react';

import { AnnotationServiceType, IAnnotationService } from '../../../annotation.service';
import { AnnotationTypeBlType, IAnnotationTypeBl } from '../annotationType.bl';
import { AnnotationTypeStoreType, IAnnotationTypeStore } from '../annotationType.store';
import { AnnotationUiStoreType, IAnnotationUiStore } from '../../../annotationUi.store';
import { AnnotationsStoreType, IAnnotationsStore } from '../../../annotations.store';
import { FreeDrawSegmentationServiceType, IFreeDrawSegmentationService } from '../../../freeDrawSegmentation.service';
import { IUndoRedoHistory, UndoRedoHistoryType } from '../../../undoRedoHistory.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import AnnotationTypeHotKeysContainer from './AnnotationTypeHotKeys.container';
import { AnnotationTypeSelected } from '../components/AnnotationTypeSelected';
import { AnnotationTypesList } from '../components/AnnotationTypesList';
import { ChangeSegmentationWithAnswersCommand } from '../../../undoRedoCommands/changeSegmentationWithAnswersCommand';
import { IAnnotationTypeOptions } from '../models/annotationTypeOptions';
import autobind from 'autobind-decorator';
import { cloneDeep } from 'lodash/fp';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps {
  annotationTypeStore: IAnnotationTypeStore;
  annotationService: IAnnotationService;
  annotationTypeBl: IAnnotationTypeBl;
  freeDrawSegmentationService: IFreeDrawSegmentationService;
  undoRedoHistoryService: IUndoRedoHistory;
  uiStore: IAnnotationUiStore;
  annotationsStore: IAnnotationsStore;
}

export interface IAnnotationTypesContainerProps {
  readonly?: boolean;
}

interface IState {
  annotationTypeIdToDelete: string;
}

@injectProps({
  annotationService: AnnotationServiceType,
  annotationTypeBl: AnnotationTypeBlType,
  freeDrawSegmentationService: FreeDrawSegmentationServiceType,
  undoRedoHistoryService: UndoRedoHistoryType,
  uiStore: AnnotationUiStoreType,
  annotationTypeStore: AnnotationTypeStoreType,
  annotationsStore: AnnotationsStoreType,
})
@observer
class AnnotationTypesContainerPure extends React.Component<IInjectedProps & IAnnotationTypesContainerProps, IState> {
  state = {
    showAnnotationTypeDeleteConfirmationModal: false,
    annotationTypeIdToDelete: '',
  };

  get shouldLock() {
    if (this.props.readonly !== undefined) return this.props.readonly;

    return this.props.annotationsStore.image === undefined && !this.props.uiStore.isImageLoading;
  }

  @autobind
  async handleAnnotationTypeSelected(annotationTypeId: string) {
    if (await this.props.freeDrawSegmentationService.clearAsync()) {
      this.props.annotationService.deselectSegmentation();
      await this.props.annotationTypeBl.handleAnnotationTypeSelectedInAnnotationTypeContainer(annotationTypeId);
    }
  }

  @autobind
  handleSelectedAnnotationDelete() {
    if (this.props.freeDrawSegmentationService.freeDrawFeature) {
      const segmentation = cloneDeep(this.props.annotationsStore.segmentations.find(s => s.feature.id === this.props.freeDrawSegmentationService.freeDrawFeature?.id));

      if (segmentation?.feature) this.props.annotationService.deleteSegmentation(segmentation.feature.id);
      this.props.annotationService.deselectSegmentation();
      this.props.freeDrawSegmentationService.clear();
      this.props.annotationTypeBl.handleSelectedAnnotationDeleteInAnnotationTypeContainer();

      this.props.undoRedoHistoryService.addCommand(
        new ChangeSegmentationWithAnswersCommand(this.props.annotationService, this.props.annotationsStore, this.props.freeDrawSegmentationService, segmentation, undefined),
      );
    }
  }

  handleAnnotationTypeOptionChanged = (projectId: string, annotationTypesOptions: IAnnotationTypeOptions, handleSegmentationVisibility?: boolean) => {
    this.props.annotationTypeBl.setAnnotationTypeOptions(projectId, annotationTypesOptions);

    if (handleSegmentationVisibility) {
      const featureIdsToShow = this.props.annotationsStore.segmentations
        .filter(s => annotationTypesOptions.id === s.feature.properties!['annotationTypeId'])
        .map(s => ({ segmentationId: s.id, featureId: s.feature.id }));
      featureIdsToShow.forEach(s => this.props.annotationService.showSegmentation(s.featureId, s.segmentationId));
    }
  };

  render() {
    const showList =
      (this.props.readonly && !this.props.annotationsStore.isSelectedSegWithQuestions) ||
      (!this.props.readonly &&
        !this.props.freeDrawSegmentationService.freeDrawInProgress &&
        !this.props.annotationsStore.isSelectedSegWithQuestions &&
        !this.props.annotationsStore.isSelectedSegOfFreeDrawType);

    return (
      <>
        {this.props.children}
        <AnnotationTypesList
          showList={showList}
          projectId={this.props.annotationsStore.projectId}
          annotationTypes={this.props.annotationTypeStore.annotationTypes.slice().sort((a, b) => a.order - b.order)}
          annotationTypesOptions={this.props.annotationTypeStore.annotationTypesOptions}
          readonly={this.shouldLock}
          activeAnnotationTypeId={this.props.annotationTypeStore.activeAnnotationTypeId}
          onAnnotationTypeSelected={this.handleAnnotationTypeSelected}
          onAnnotationTypeOptionsChanged={this.handleAnnotationTypeOptionChanged}
          onSelectedAnnotationDelete={this.handleSelectedAnnotationDelete}
          getShortcutKey={this.props.annotationTypeBl.getShortcutKey}
        />
        <AnnotationTypeSelected
          showList={showList}
          projectId={this.props.annotationsStore.projectId}
          annotationTypes={this.props.annotationTypeStore.annotationTypes.slice().sort((a, b) => a.order - b.order)}
          annotationTypesOptions={this.props.annotationTypeStore.annotationTypesOptions}
          readonly={this.shouldLock}
          activeAnnotationTypeId={this.props.annotationTypeStore.activeAnnotationTypeId}
          onAnnotationTypeOptionsChanged={this.handleAnnotationTypeOptionChanged}
          onSelectedAnnotationDelete={this.handleSelectedAnnotationDelete}
          getShortcutKey={this.props.annotationTypeBl.getShortcutKey}
        />
        {!this.props.readonly && <AnnotationTypeHotKeysContainer />}
      </>
    );
  }
}

export const AnnotationTypesContainer = as<React.ComponentClass<IAnnotationTypesContainerProps>>(withRouter(AnnotationTypesContainerPure));
