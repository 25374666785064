import { ILimitInfo, ITakeOverWorkspaceLimits } from '../workspaces.store';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../helpers/react.helpers';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { withNamespaces } from 'react-i18next';

const LimitsValidationPure = function (props: ITakeOverWorkspaceLimits & ITranslatable) {
  const limitIsExceeded = (limit: ILimitInfo) => limit.targetLimit !== -1 && limit.targetLimitUsage + limit.workspaceLimitUsage > limit.targetLimit;

  const getExceededLimits = () => {
    const surpassedLimits: string[] = [];

    if (limitIsExceeded(props.images)) surpassedLimits.push('limit_published_images');
    if (limitIsExceeded(props.storage)) surpassedLimits.push('limit_storage_space');
    if (limitIsExceeded(props.users)) surpassedLimits.push('limit_unique_users');
    if (limitIsExceeded(props.workers)) surpassedLimits.push('limit_unique_workers');
    if (limitIsExceeded(props.workspaces)) surpassedLimits.push('limit_workspaces');
    if (!props.canEncrypt) surpassedLimits.push('limit_encryption');

    return `${surpassedLimits.map(limit => props.t(limit)).join(', ')}.`;
  };

  return (
    <>
      {props.isAllowed && (
        <div className="change-owner-allowed">
          <FontAwesomeIcon icon={faInfoCircle} />{' '}{props.t('change_workspace_owner_allowed')}
        </div>)}
      {!props.isAllowed && (
        <div className="change-owner-denied">
          <FontAwesomeIcon icon={faInfoCircle} />{' '}{props.t('change_workspace_owner_denied')}{getExceededLimits()}
        </div>)}
    </>
  );
};

export const LimitsValidation = as<React.SFC<ITakeOverWorkspaceLimits>>(withNamespaces('common', { wait: true })(LimitsValidationPure));
