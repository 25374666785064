﻿import * as React from 'react';

import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../../../../modules/common/OverlayLoader.store';
import { IProjectDetailsPermissions, ProjectDetailsPermissionsType } from '../../../projectDetails.permissions';
import { IProjectDetailsStore, ProjectDetailsStoreType } from '../../../projectDetails.store';
import { IProjectDetailsToolsBl, IProjectDetailsToolsBlType } from '../projectDetailsTools.bl';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { ImageScopeName } from '../../../../annotation/question.model';
import { QuestionsList } from '../components/QuestionsList';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps {
  projectDetailsToolsBl: IProjectDetailsToolsBl;
  projectDetailsPermissions: IProjectDetailsPermissions;
  projectDetailsStore: IProjectDetailsStore;
  overlayLoaderStore: IOverlayLoaderStore;
}

@injectProps({
  projectDetailsToolsBl: IProjectDetailsToolsBlType,
  projectDetailsPermissions: ProjectDetailsPermissionsType,
  projectDetailsStore: ProjectDetailsStoreType,
  overlayLoaderStore: OverlayLoaderStoreType,
})
@observer
class QuestionsListContainerPure extends React.Component<IInjectedProps> {

  @autobind
  async handleOrderChangeAsync(questionId: string, index: number, isAttribute: boolean) {
    await this.props.projectDetailsToolsBl.changeQuestionOrderAsync(questionId, index, isAttribute);
  }

  render() {
    const canEdit = this.props.projectDetailsPermissions.canEditProject(this.props.projectDetailsStore.status);

    return (
      <QuestionsList
        questions={this.props.projectDetailsToolsBl.store.questions.filter(q => q.scopes.includes(ImageScopeName)).slice()}
        canEdit={canEdit}
        onOrderChange={this.handleOrderChangeAsync}
        isLoading={this.props.overlayLoaderStore.isSpinnerVisible('getProjectDetailsAnnotation')}
      />);
  }
}

export const QuestionsListContainer = as<React.ComponentClass>(QuestionsListContainerPure);
