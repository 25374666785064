import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  newWorkspaceName: string;
  showKeyDownload: boolean;
  encryptionKeyUrl: string;
  toggleKeyDownload(): void;
}

const KeyDownloadModalPure = (props: IProps & ITranslatable) => {
  return (
    <Modal isOpen={props.showKeyDownload} toggle={props.toggleKeyDownload}>
      <ModalHeader toggle={props.toggleKeyDownload}>{props.t('key_download')}</ModalHeader>
        <Form className="custom-inline-form">
      <ModalBody>
        <p>{props.t('key_instruction')}</p>
        <p><span className="encrypted-warning">{props.t('warning')}!</span> {props.t('key_warning')}</p>
      </ModalBody>
      <ModalFooter>
              <a className="btn btn-success mr-2" color="success" href={props.encryptionKeyUrl} download={`${props.newWorkspaceName}.key`}>
                {props.t('download_key')}
              </a>

              <Button color="primary" onClick={props.toggleKeyDownload}>
                {props.t('workspace_ok')}
              </Button>
      </ModalFooter>
        </Form>
    </Modal>
  );
};

export const KeyDownloadModal = as<React.ComponentClass<IProps>>(withNamespaces('workspace')(KeyDownloadModalPure));
