import * as React from 'react';

import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { ButtonWithLoader } from '../../../../../components/ButtonWithLoader';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { TextAreaWithAutocomplete } from '../../../../../components/TextAreaWithAutocomplete';
import { as } from '../../../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface RequestClarificationModalProps {
  showModal: boolean;
  question: string;
  onToggleChange(): void;
  onQuestionChange(value: string): void;
  onSaveClarification(): void;
}

class RequestClarificationModalPure extends React.Component<RequestClarificationModalProps & ITranslatable> {
  render() {
    return (
      <Modal className="modal-width-600 image-clairifications-modal" isOpen={this.props.showModal} toggle={this.props.onToggleChange}>
        <ModalHeader toggle={this.props.onToggleChange}>{this.props.t('clarifications.request_modal.header')}</ModalHeader>
        <Form className="custom-inline-form">
          <ModalBody>
            <div>
              <Row>
                <Col>
                  <p>{this.props.t('clarifications.request_modal.caption')}:</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextAreaWithAutocomplete
                    maxLength={210}
                    value={this.props.question}
                    onChange={this.props.onQuestionChange}
                    autocompleteOptions={[]}
                    autofocus
                    counter
                  />
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <ButtonWithLoader
              loaderKey="request-clarification-button"
              id="add-clarification"
              color="success"
              className="mr-2"
              onClick={this.props.onSaveClarification}
              disabled={!this.props.question}
            >
              {this.props.t('clarifications.request_modal.save')}
            </ButtonWithLoader>
            <Button id="button-cancel" color="primary" className="btn-outline-primary" onClick={this.props.onToggleChange}>
              {this.props.t('cancel')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export const RequestClarificationModal = as<React.FunctionComponent<RequestClarificationModalProps>>(
  withNamespaces('common', { wait: true })(RequestClarificationModalPure),
);
