import { PaginationLink } from 'reactstrap';
import React from 'react';

interface Props {
  page: number;
  onClick(page: number): void;
}

export const S_PaginationPageButton: React.FC<Props> = ({ page, onClick }) => {
  const handleClick = () => onClick(page);

  return <PaginationLink onClick={handleClick}>{page}</PaginationLink>;
};
