import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { ITakeOverWorkspaceLimits, IWorkspaceMember } from '../workspaces.store';

import { ButtonWithLoader } from '../../../components/ButtonWithLoader';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { InputStatus } from '../../../models/error.model';
import { LimitsValidation } from './LimitsValidation';
import React from 'react';
import { ValidatedSelectInput } from '../../../components/ValidatedSelectInput';
import { as } from '../../../helpers/react.helpers';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { handleSelectChange } from '../../../helpers/formHelpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';

library.add(faInfoCircle);

export interface IChangeWorkspaceOwnerModalProps {
  showChangeOwner: boolean;
  users: IWorkspaceMember[];
  ownerEmail: string;
  ownerEmailStatus: InputStatus;
  takeoverWorkspaceLimits?: ITakeOverWorkspaceLimits;
  onOwnerEmailChange(email: string): void;
  toggleOwnerModal(): void;
  onOwnerSave(): void;
}

const ChangeWorkspaceOwnerModalPure = (props: IChangeWorkspaceOwnerModalProps & ITranslatable) => {
  const options = props.users.map(u => ({ label: u.email, value: u.email, disabled: !u.hasAccount }));
  const selected = options.find(u => u.value === props.ownerEmail);

  const formatOptionLabel = ({ label, disabled }: any) => (
    <div style={{ display: 'flex' }} title={disabled ? props.t('this_user_has_not_registered_yet') : ''}>
      <div>{label}</div>
    </div>
  );

  return (
    <Modal className="modal-width-600" isOpen={props.showChangeOwner} toggle={props.toggleOwnerModal}>
      <ModalHeader toggle={props.toggleOwnerModal}>{props.t('change_workspace_owner')}</ModalHeader>
      <Form className="custom-inline-form">
        <ModalBody>
          <Row>
            <Col>
              <ValidatedSelectInput
                id="user-dropdown"
                name="select"
                value={selected || null}
                placeholder={props.t('email_address')}
                onChange={handleSelectChange(props.onOwnerEmailChange)}
                options={options}
                label={props.t('user')}
                isValid={props.ownerEmailStatus.isValid}
                feedbacks={props.ownerEmailStatus.errorCodes}
                formatOptionLabel={formatOptionLabel}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="alerts mt-2">
                <p className="alerts-info">{props.t('owner_change_info')}</p>
              </div>
            </Col>
          </Row>
          {props.takeoverWorkspaceLimits !== undefined && (
            <Row>
              <Col>{<LimitsValidation {...props.takeoverWorkspaceLimits} />}</Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <ButtonWithLoader
            loaderKey="change-workspace-owner-button"
            disabled={props.ownerEmailStatus.isValid !== true || props.ownerEmail === '' || props.takeoverWorkspaceLimits === undefined || !props.takeoverWorkspaceLimits.isAllowed}
            color="success"
            className="mr-2"
            onClick={props.onOwnerSave}
          >
            {props.t('change_owner')}
          </ButtonWithLoader>
          <Button color="primary" className="btn-outline-primary" onClick={props.toggleOwnerModal}>
            {props.t('workspace_cancel')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export const ChangeWorkspaceOwnerModal = as<React.ComponentClass<IChangeWorkspaceOwnerModalProps>>(withNamespaces('workspace')(ChangeWorkspaceOwnerModalPure));
