class KeyboardShortcutsService {
  private keyDownRegistrations: any;
  private keyUpRegistrations: any;
  private disabledShortcuts: string[];

  constructor() {
    this.keyDownRegistrations = {};
    this.keyUpRegistrations = {};
    this.disabledShortcuts = [];

    document.addEventListener('keydown', (e: KeyboardEvent) => this.handleKeyDown(e));
    document.addEventListener('keyup', (e: KeyboardEvent) => this.handleKeyUp(e));
  }

  registerKeyDown = (key: string, callback: (e: KeyboardEvent) => void) => this.register(this.keyDownRegistrations, key, callback);
  registerKeyUp = (key: string, callback: (e: KeyboardEvent) => void) => this.register(this.keyUpRegistrations, key, callback);
  unregisterKeyDown = (key: string, callback: (e: KeyboardEvent) => void) => this.unregister(this.keyDownRegistrations, key, callback);
  unregisterKeyUp = (key: string, callback: (e: KeyboardEvent) => void) => this.unregister(this.keyUpRegistrations, key, callback);

  disableShortcuts = (shortcuts: string[]) => {
    const shortcutsToAdd = shortcuts.map(s => s.toLocaleUpperCase()).filter(s => !this.disabledShortcuts.includes(s));
    this.disabledShortcuts = this.disabledShortcuts.concat(shortcutsToAdd);
  }

  enableShortcuts = (shortcuts: string[]) => {
    const shortcutsToRemove = shortcuts.map(s => s.toLocaleUpperCase());
    this.disabledShortcuts = this.disabledShortcuts.filter(s => !shortcutsToRemove.includes(s));
  }

  private register = (registry: any, key: string, callback: (e: KeyboardEvent) => void) => {
    const upperKey = key.toLocaleUpperCase();
    const registration: ((e: KeyboardEvent) => void)[] | undefined = registry[upperKey];

    if (registration === undefined) {
      registry[upperKey] = [callback];
    } else {
      registry[upperKey].push(callback);
    }
  }

  private unregister = (registry: any, key: string, callback: (e: KeyboardEvent) => void) => {
    const upperKey = key.toLocaleUpperCase();

    const registration: ((e: KeyboardEvent) => void)[] | undefined = registry[upperKey];
    if (registration === undefined) return;

    registry[upperKey] = registration.filter(h => h !== callback);
  }

  private handleKeyDown = (e: KeyboardEvent) => this.handleKey(this.keyDownRegistrations, e);
  private handleKeyUp = (e: KeyboardEvent) => this.handleKey(this.keyUpRegistrations, e);

  private handleKey = (register: any, e: KeyboardEvent) => {
    if (!e.key) return; // for some reason some events does not have key property;
    const upperKey = e.key.toLocaleUpperCase();

    if (this.disabledShortcuts.includes(upperKey)) return;

    const handlersToRun: ((e: KeyboardEvent) => void)[] | undefined = register[upperKey];
    if (handlersToRun === undefined) return;

    handlersToRun.forEach(h => h(e));
  }
}

export const keyboardShortcutsServiceInstance = new KeyboardShortcutsService();

export const drawingScreenDisables = ['delete', 'z', 'y', '`', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'c', 'v', 'e', 'ARROWDOWN', 'ARROWUP', 'ARROWRIGHT', 'ARROWLEFT'];
