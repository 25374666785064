import { action, observable } from 'mobx';

import { PaginationInfoDefault } from '../../models/paginationInfo.model';
import { injectable } from 'inversify';

export const AttachmentsStoreType = Symbol('ATTACHMENTS_STORE');

export enum AttachmentType {
  NONE = 0,
  FOLDER = 10,
  FILE = 20,
  IMAGE_FILE = 30,
}

export interface IAttachment {
  id: string;
  name: string;
  createdDate: string;
  modifiedDate: string;
  author: string;
  parentId: string;
  type: AttachmentType;
  items: number;
  size: number;
}

export interface IAttachmentBreadcrumb {
  id: string;
  name: string;
}

export interface ISelectedAttachments {
  ids: string[];
  isAllSelected: boolean;
}

export interface IAttachmentSorting {
  orderBy?: string;
  orderType?: string;
  forId: string;
}

export interface IAttachmentPaging {
  forId: string;
  pageNumber: number;
  pagesCount: number;
  totalCount: number;
  pageSize: number;
}

export interface IAttachmentsPagingWithSorting extends IAttachmentPaging, IAttachmentSorting {
}

export interface IAttachmentsStore {
  attachments: IAttachment[];
  attachmentsPaging: IAttachmentPaging;
  attachmentsSorting: IAttachmentSorting[];
  breadcrumbs: IAttachmentBreadcrumb[];
  selectedAttachments: ISelectedAttachments;
  showDeleteConfirmationModal: boolean;
  isDeleteRequestInProgress: boolean;
  clearSelectedAttachments(): void;
  getPaging(workspaceId: string, attachmentId: string | undefined): IAttachmentsPagingWithSorting;
  updatePaging(paging: IAttachmentsPagingWithSorting): void;
}

export const defaultAttachmentsPaging: IAttachmentPaging = {
  ...PaginationInfoDefault,
  forId: '',
};

export const defaultSorting: IAttachmentSorting = {
  orderBy: 'name',
  orderType: 'asc',
  forId: '',
};

@injectable()
export class AttachmentsStore implements IAttachmentsStore {

  @observable
  attachments: IAttachment[] = [];

  @observable
  attachmentsPaging: IAttachmentPaging = defaultAttachmentsPaging;

  @observable
  attachmentsSorting: IAttachmentSorting[] = [];

  @observable
  breadcrumbs: IAttachmentBreadcrumb[] = [];

  @observable
  selectedAttachments: ISelectedAttachments = {
    ids: [],
    isAllSelected: false,
  };

  @observable
  showDeleteConfirmationModal: boolean = false;

  @observable
  isDeleteRequestInProgress: boolean = false;

  clearSelectedAttachments(): void {
    this.selectedAttachments.isAllSelected = false;
    this.selectedAttachments.ids = [];
  }

  getPaging(workspaceId: string, attachmentId: string | undefined): IAttachmentsPagingWithSorting {
    const id = attachmentId ?? workspaceId;

    const paging = this.attachmentsPaging.forId === id ? this.attachmentsPaging : defaultAttachmentsPaging;
    let sorting = this.attachmentsSorting.find(x => x.forId === id);
    if (!sorting) {
      sorting = { ...defaultSorting };
    }

    return { ...paging, ...sorting, forId: id };
  }

  @action
  updatePaging(paging: IAttachmentsPagingWithSorting): void {
    this.attachmentsPaging = paging;

    const index = this.attachmentsSorting.findIndex(x => x.forId === paging.forId);
    if (index > -1) this.attachmentsSorting.splice(index, 1);
    this.attachmentsSorting.push({ forId: paging.forId, orderBy: paging.orderBy, orderType: paging.orderType });
  }
}
