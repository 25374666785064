import { LatLng, Point } from 'leaflet';
import { applyToPoint, compose, rotate, scale, translate } from 'transformation-matrix';

export const projection = (vectorA: LatLng, vectorB: LatLng) => {
  const vectorBUnit = toUnitVector(vectorB);

  const dotProduct = vectorA.lat * vectorBUnit.lat + vectorA.lng * vectorBUnit.lng;
  return multiply(vectorBUnit, dotProduct);
};

export const toUnitVector = (vector: LatLng) => {
  const length = Math.sqrt(Math.pow(vector.lat, 2) + Math.pow(vector.lng, 2));
  return new LatLng(vector.lat / length, vector.lng / length);
};

export const subtract = (firstPoint: LatLng, secondPoint: LatLng) => {
  return new LatLng(firstPoint.lat - secondPoint.lat, firstPoint.lng - secondPoint.lng);
};

export const multiply = (vector: LatLng, length: number) => {
  return new LatLng(vector.lat * length, vector.lng * length);
};

export const divideBy = (vector: LatLng, length: number) => {
  return new LatLng(vector.lat / length, vector.lng / length);
};

export const sum = (vector: LatLng, secondVector: LatLng) => {
  return new LatLng(vector.lat + secondVector.lat, vector.lng + secondVector.lng);
};

export const equals = (vector: LatLng, secondVector: LatLng, tolerance: number = 1) => {
  return Math.abs(vector.lat - secondVector.lat) < tolerance && Math.abs(vector.lng - secondVector.lng) < tolerance;
};

export const distance = (point: LatLng, point2: LatLng) => {
  const a = point.lat - point2.lat;
  const b = point.lng - point2.lng;
  return Math.hypot(a, b);
};

export const getAngleBetweenTwoVectors = (vectorAStart: LatLng, vectorAEnd: LatLng, vectorBStart: LatLng, vectorBEnd: LatLng): number => {
  const vectorA = subtract(vectorAEnd, vectorAStart);
  const vectorB = subtract(vectorBEnd, vectorBStart);
  const theta = -1 * (Math.atan2(vectorA.lat, vectorA.lng) - Math.atan2(vectorB.lat, vectorB.lng) + 2 * Math.PI);
  return theta;
};

export const rotatePoint = (pointToRotate: LatLng, angle: number, rotationAboutPoint: LatLng): LatLng => {
  const matrix = compose(translate(0, 0), rotate(angle, rotationAboutPoint.lng, rotationAboutPoint.lat), scale(1, 1));
  const rotatedPoint = applyToPoint(matrix, new Point(pointToRotate.lng, pointToRotate.lat));
  const rotatedPointLatLng = new LatLng(rotatedPoint.y, rotatedPoint.x);
  return rotatedPointLatLng;
};
