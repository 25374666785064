import { ContainerModule, interfaces } from 'inversify';
import { IEvaluationDetailsStore, EvaluationDetailsStore, EvaluationDetailsStoreType } from './evaluationDetails.store';
import { IEvaluationDetailsService, EvaluationDetailsService, EvaluationDetailsServiceType } from './evaluationDetails.service';
import {
  IEvaluationDetailsApiService,
  EvaluationDetailsApiService,
  EvaluationDetailsApiServiceType,
} from './services/evaluationDetailsApi.service';

export const evaluationDetailsModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IEvaluationDetailsApiService>(EvaluationDetailsApiServiceType)
    .to(EvaluationDetailsApiService)
    .inSingletonScope();
  bind<IEvaluationDetailsStore>(EvaluationDetailsStoreType)
    .to(EvaluationDetailsStore)
    .inSingletonScope();
  bind<IEvaluationDetailsService>(EvaluationDetailsServiceType)
    .to(EvaluationDetailsService)
    .inSingletonScope();
});