import { injectable, inject } from 'inversify';
import { action } from 'mobx';
import { ApiServiceType } from '../../services/api.service';
import { StickerError } from '../../models/error.model';
import { IApiService } from '../../services/api.service.base';
import { IIntegrationsStore, IntegrationsStoreType, IApiKey } from './integrations.store';

export const IntegrationsServiceType = Symbol('INTEGRATIONS_SERVICE');

export interface IIntegrationsService {
  loadApiKeys(): Promise<void | StickerError>;
  createApiKey(name: string): Promise<void | StickerError>;
  editApiKey(data: { id: string, name: string, isActive: boolean }): Promise<void | StickerError>;
  deleteApiKey(apiKeyId: string): Promise<void | StickerError>;
  isNameUnique(name: string, id?: string): Boolean;
  store: IIntegrationsStore;
}

@injectable()
export class IntegrationsService implements IIntegrationsService {
  constructor(
    @inject(IntegrationsStoreType) public readonly store: IIntegrationsStore,
    @inject(ApiServiceType) private readonly apiService: IApiService,
  ) { }

  @action
  async loadApiKeys(): Promise<void | StickerError> {
    const response = await this.apiService.getAsync<IApiKey[]>('/apiKeys/GetUsersApiKeys');
    if (response instanceof StickerError) {
      return response;
    }
    this.store.apiKeys = response;
  }

  @action
  async createApiKey(name: string): Promise<void | StickerError> {
    const response = await this.apiService.postAsync('/apiKeys/Create', { name });
    await this.loadApiKeys();
    if (response instanceof StickerError) {
      return response;
    }
  }

  @action
  async editApiKey(data: { id: string, name: string, isActive: boolean }): Promise<void | StickerError> {
    const response = await this.apiService.postAsync('/apiKeys/Edit', data);
    await this.loadApiKeys();
    if (response instanceof StickerError) {
      return response;
    }
  }

  @action
  async deleteApiKey(apiKeyId: string): Promise<void | StickerError> {
    const url = '/apiKeys/Delete';
    const response = await this.apiService.postAsync(url, { id: apiKeyId });
    await this.loadApiKeys();
    if (response instanceof StickerError) {
      return response;
    }
  }

  isNameUnique(name: string, id?: string): boolean {
    return this.store.apiKeys.every(s => (id && s.id === id) || s.name !== name.trim());
  }
}
