import * as React from 'react';

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { Datasets, IDatasetsContext } from '../datasets.context';
import { DatasetsPermissionsType, IDatasetsPermissions } from '../datasets.permissions';
import { IImagesFromUrlsService, ImagesFromUrlsServiceType } from '../imagesFromUrlsUpload.service';
import { IImagesUploaderService, ImagesUploaderServiceType } from '../imagesUpload.service';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { ISettingsContext, Settings } from '../../../../modules/settings/SettingsContext';
import { Observer, observer } from 'mobx-react';
import { as, injectProps } from '../../../helpers/react.helpers';

import { DatasetsList } from '../components/DatasetsList';
import { DeleteDatasetConfirmationModal } from '../components/DeleteDatasetConfirmationModal';
import autobind from 'autobind-decorator';

export interface IInjectedProps {
  context: IDatasetsContext;
  imagesFromUrlsService: IImagesFromUrlsService;
  imagesUploaderService: IImagesUploaderService;
  datasetsPermissions: IDatasetsPermissions;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

interface IState extends ILoaderState {
  showDatasetDeleteConfirmationModal: boolean;
  datasetIdToDelete: string;
}

@injectProps({
  imagesFromUrlsService: ImagesFromUrlsServiceType,
  imagesUploaderService: ImagesUploaderServiceType,
  datasetsPermissions: DatasetsPermissionsType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
@observer
class DatasetsListContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  static contextType = Datasets;
  declare context: React.ContextType<typeof Datasets>;

  constructor(props: IInjectedProps) {
    super(props);

    this.state = {
      showDatasetDeleteConfirmationModal: false,
      isLoading: false,
      datasetIdToDelete: '',
    };
  }

  @autobind
  handleDeleteDataset() {
    this.withLoaderAsync(() => {
      this.props.imagesFromUrlsService.cancelUploadAsync(this.state.datasetIdToDelete);
      this.props.imagesUploaderService.cancelUploadAsync(this.state.datasetIdToDelete);
      this.context.service.deleteDatasetAsync(this.state.datasetIdToDelete);
      this.setState({ datasetIdToDelete: '', showDatasetDeleteConfirmationModal: false });
    });
  }

  @autobind
  toggleDatasetDeleteConfirmationModal(datasetId: string) {
    this.setState((prevState: IState) => ({
      ...prevState,
      showDatasetDeleteConfirmationModal: !prevState.showDatasetDeleteConfirmationModal,
      datasetIdToDelete: datasetId,
    }));
  }

  @autobind
  async handleOrderChange(orderBy: string, orderType: string) {
    this.withLoaderAsync(async () => {
      await this.context.service.changeOrder(orderBy, orderType);
    });
  }

  @autobind
  getHandlePaginationChange(settingsContext: ISettingsContext) {
    return async (pageNumber: number, pageSize: number) => {
      await this.withLoaderAsync(async () => {
        settingsContext.setDatasetsPageSize(pageSize);
        await this.context.service.paginationChange(pageNumber, pageSize);
      });
    };
  }

  render() {
    const { store } = this.context;
    return (
      <Settings.Consumer>
        {settings => {
          const handlePaginationChange = this.getHandlePaginationChange(settings);
          return (
            <Observer>
              {() => {
                const paging = { ...store.datasetsPaging, pageSize: settings.store.datasetsPageSize };
                return (
                  <>
                    <DatasetsList
                      datasetsList={store.datasets}
                      workspaceId={this.props.currentWorkspaceStore.currentWorkspace?.id!}
                      isLoading={store.stale}
                      onDeleteDataset={this.handleDeleteDataset}
                      onShowDatasetDeleteConfirmationModal={this.toggleDatasetDeleteConfirmationModal}
                      datasetsPaging={paging}
                      onOrderingChange={this.handleOrderChange}
                      onPaginationChange={handlePaginationChange}
                      canSeeDatasetCreatedBy={this.props.datasetsPermissions.canSeeDatasetCreatedBy()}
                    />
                    <DeleteDatasetConfirmationModal
                      showDatasetDeleteConfirmationModal={this.state.showDatasetDeleteConfirmationModal}
                      onDeleteDatasetCancel={this.toggleDatasetDeleteConfirmationModal}
                      onDatasetDeleteConfirm={this.handleDeleteDataset}
                      datasetDeleteConfirmationText={store.datasets.find(d => d.id === this.state.datasetIdToDelete)?.name || ''}
                    />
                  </>
                );
              }}
            </Observer>
          );
        }}
      </Settings.Consumer>
    );
  }
}

export const DatasetsListContainer = as<React.ComponentClass>(DatasetsListContainerPure);
