import React from 'react';
import { observer } from 'mobx-react';
import { as, injectProps } from '../../../__legacy__/helpers/react.helpers';
import { formAlertWithTranslations } from '../../../__legacy__/components/FormAlert';
import { EvaluationDetailsServiceType, IEvaluationDetailsService } from '../evaluationDetails.service';

interface IInjectedProps {
  evaluationDetailsService: IEvaluationDetailsService;
}

const Errors = formAlertWithTranslations('evaluations');

@injectProps({
  evaluationDetailsService: EvaluationDetailsServiceType,
})
@observer
class GlobalValidationErrorsContainerPure extends React.Component<IInjectedProps> {
  render() {
    const {
      getTabsValidationErrors,
      store: { evaluationDetailsValidationErrors },
    } = this.props.evaluationDetailsService;
    const errors = getTabsValidationErrors();

    return <>{evaluationDetailsValidationErrors.showAll && Array.from(errors.entries()).map(([tab, error]) => <Errors key={tab} errors={error} />)}</>;
  }
}

export const GlobalValidationErrorsContainer = as<React.ComponentClass>(GlobalValidationErrorsContainerPure);
