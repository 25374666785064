import * as React from 'react';

import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IRemoveUserProps {
  removeUserModalShown: boolean;
  onRemoveUser(): void;
  onToggleRemoveUserModal(email: string): void;
}

const RemoveUserModalPure = (props: IRemoveUserProps & ITranslatable) => {
  const handleRemoveUserToggle = () => props.onToggleRemoveUserModal('');

  return (
    <ConfirmationModal
      showModal={props.removeUserModalShown}
      confirmationHeader={props.t('confirm_user_remove')}
      confirmationQuestion={props.t('remove_user_from_workspace')}
      onCancel={handleRemoveUserToggle}
      onConfirm={props.onRemoveUser}
      confirmationYes={props.t('remove_user')}
      loaderKey="remove-user-button"
    />
  );
};

export const RemoveUserModal = as<React.SFC<IRemoveUserProps>>(withNamespaces('common', { wait: true })(RemoveUserModalPure));
