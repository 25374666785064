import * as React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { ITranslatable } from '../helpers/translations.helpers';
import { as } from '../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface ISaveBeforeSkipConfirmationModalProps {
  showSaveBeforeSkipModal: boolean;
  onSaveBeforeSkipConfirm(): void;
  onSaveBeforeSkipDecline(): void;
  onSaveBeforeSkipCancel(): void;
}

class SaveBeforeSkipConfirmationModalPure extends React.Component<ISaveBeforeSkipConfirmationModalProps & ITranslatable> {
  render() {
    return (
      <Modal isOpen={this.props.showSaveBeforeSkipModal} toggle={this.props.onSaveBeforeSkipCancel}>
        <ModalHeader toggle={this.props.onSaveBeforeSkipCancel}>{this.props.t('save_changes')}</ModalHeader>
        <ModalBody>
          <p className="mt-3">{this.props.t('skip_confirm_message')}</p>
        </ModalBody>
        <ModalFooter>
          <Button className="mr-2" color="success" onClick={this.props.onSaveBeforeSkipConfirm}>
            {this.props.t('save')}
          </Button>
          <Button className="mr-2" color="warning" onClick={this.props.onSaveBeforeSkipDecline}>
            {this.props.t('do_not_save')}
          </Button>
          <Button outline color="primary" onClick={this.props.onSaveBeforeSkipCancel}>
            {this.props.t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export const SaveBeforeSkipConfirmationModal = as<React.StatelessComponent<ISaveBeforeSkipConfirmationModalProps>>(
  withNamespaces('annotation', { wait: true })(SaveBeforeSkipConfirmationModalPure),
);
