import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { ButtonWithLoader } from '../../../components/ButtonWithLoader';
import { DisabledWhenPolicyExceeded } from '../../../containers/DisabledWhenPolicyExceeded';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { InputStatus } from '../../../models/error.model';
import React from 'react';
import { ValidatedTextInput } from '../../../components/ValidatedTextInput';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IRenameWorkspaceModalProps {
  showRenameWorkspace: boolean;
  renameWorkspaceName: string;
  renameWorkspaceNameStatus: InputStatus;
  toggleRenameWorkspace(): void;
  onNameChange(value: string): void;
  onRenameSave(): void;
}

const RenameWorkspaceModalPure = (props: IRenameWorkspaceModalProps & ITranslatable) => {
  const preventSubmit = (e: any) => e.preventDefault();

  return (
    <Modal className="modal-width-600" isOpen={props.showRenameWorkspace} toggle={props.toggleRenameWorkspace}>
      <ModalHeader toggle={props.toggleRenameWorkspace}>{props.t('rename_workspace')}</ModalHeader>
      <Form className="custom-inline-form" onSubmit={preventSubmit}>
        <ModalBody>
          <Row>
            <Col>
              <ValidatedTextInput
                onPressEnter={props.onRenameSave}
                id="rename-workspace-input"
                labelText={props.t('new_name_for_workspace')}
                placeholder={props.t('workspace_type_new_workspace_name_here')}
                value={props.renameWorkspaceName}
                onChange={props.onNameChange}
                isValid={props.renameWorkspaceNameStatus.isValid}
                maxLength={200}
                feedbacks={props.renameWorkspaceNameStatus.errorCodes.map(ec => props.t(ec))}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <DisabledWhenPolicyExceeded>
            <ButtonWithLoader
              loaderKey="rename-workspace-button"
              disabled={props.renameWorkspaceNameStatus.isValid !== true}
              color="success"
              className="mr-2"
              onClick={props.onRenameSave}
            >
              {props.t('rename_workspace')}
            </ButtonWithLoader>
          </DisabledWhenPolicyExceeded>

          <Button color="primary" className="btn-outline-primary" onClick={props.toggleRenameWorkspace}>
            {props.t('cancel')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export const RenameWorkspaceModal = as<React.ComponentClass<IRenameWorkspaceModalProps>>(withNamespaces('workspace')(RenameWorkspaceModalPure));
