import * as React from 'react';

import { ITranslatable } from '../../../helpers/translations.helpers';
import { SliderInput } from './SliderInput';
import { as } from '../../../helpers/react.helpers';
import { faFillDrip } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

library.add(faFillDrip);

export interface IOpacityControlProps {
  onOpacityLevelChange(opacityLevel: number): void;
  opacityLevel: number;
  minOpacityLevel: number;
  maxOpacityLevel: number;
}

@observer
class OpacityControlPure extends React.Component<IOpacityControlProps & ITranslatable> {
  handleChangeEvent = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.props.onOpacityLevelChange(Number(e.target.value));
  };

  renderValue = (opacityLevel: number) => <>{opacityLevel}%</>;

  render() {
    return (
      <SliderInput
        icon={faFillDrip}
        label={this.props.t('opacity')}
        min={this.props.minOpacityLevel}
        max={this.props.maxOpacityLevel}
        step={5}
        value={this.props.opacityLevel}
        onChange={this.handleChangeEvent}
        renderValue={this.renderValue}
      />
    );
  }
}
export const OpacityControl = as<React.ComponentClass<IOpacityControlProps>>(withNamespaces('annotation')(OpacityControlPure));
