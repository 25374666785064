import { injectable, inject } from 'inversify';
import { action } from 'mobx';
import { StickerError, ForbiddenError } from '../../__legacy__/models/error.model';
import { NotificationsServiceType, INotificationsService } from '../../__legacy__/modules/notifications/services/notifications.service';
import { NotificationLevel, ToastNotification } from '../../__legacy__/modules/notifications/models/notification.model';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../common/OverlayLoader.store';
import { ICurrentWorkspaceStore, CurrentWorkspaceStoreType } from '../workspaces/currentWorkspace/CurrentWorkspace.store';
import { IModelsStore, ModelOwnership, ModelsStoreType } from './models.store';
import { IModelsApiService, ModelsApiServiceType } from './services/modelsApi.service';
import { MODEL_STATUS } from './models.model';

export const ModelsServiceType = Symbol('MODELS_SERVICE');

export interface IModelsService {
  store: IModelsStore;
  changeOrder(orderBy: string | undefined, orderType: string | undefined): Promise<void>;
  changeOwnershipFilter(ownership: ModelOwnership): Promise<void>;
  changePagination(pageNumber: number, pageSize: number): Promise<void>;
  downloadModelAsync(modelId: string): Promise<void | StickerError>;
  restartTrainingAsync(modelId: string): Promise<void | StickerError>;
  stopTrainingAsync(modelId: string): Promise<void | StickerError>;
  deleteModelAsync(modelId: string): Promise<void | StickerError>;
  getModelsAsync(workspaceId: string): Promise<void | StickerError>;
  refreshAsync(): Promise<void | StickerError>;
  importModelsAsync(name: string, description: string, file: File): Promise<boolean>;
}

@injectable()
export class ModelsService implements IModelsService {
  constructor(
    @inject(ModelsStoreType) public readonly store: IModelsStore,
    @inject(NotificationsServiceType) private readonly notificationsService: INotificationsService,
    @inject(OverlayLoaderStoreType) private readonly overlayLoaderStore: IOverlayLoaderStore,
    @inject(ModelsApiServiceType) private readonly modelsApiService: IModelsApiService,
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore,
  ) {}

  @action
  async changeOwnershipFilter(ownership: ModelOwnership): Promise<void> {
    if (ownership !== this.store.modelOwnership) {
      this.store.modelOwnership = ownership;
      this.store.modelsPaging.pageNumber = 1;
      await this.refreshAsync();
    }
  }

  @action
  async changeOrder(orderBy: string | undefined, orderType: string | undefined): Promise<void> {
    this.store.modelsPaging.orderBy = orderBy || '';
    this.store.modelsPaging.orderType = orderType || '';
    await this.refreshAsync();
  }

  @action
  async changePagination(pageNumber: number, pageSize: number): Promise<void> {
    this.store.modelsPaging.pageNumber = pageNumber;
    this.store.modelsPaging.pageSize = pageSize;
    await this.refreshAsync();
  }

  @action.bound
  async importModelsAsync(name: string, description: string, file: File): Promise<boolean> {
    const { currentWorkspace } = this.currentWorkspaceStore;

    if (!currentWorkspace) return false;

    const formData = new FormData();

    formData.append('model_name', name);
    formData.append('model_description', description);
    formData.append('model_file', file);

    const result = await this.modelsApiService.postModelImportAsync(currentWorkspace.id, formData);

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'models:import_model.failed');
      return false;
    }

    await this.refreshAsync();

    return true;
  }

  @action
  async refreshAsync() {
    const { currentWorkspace } = this.currentWorkspaceStore;
    if (!currentWorkspace) return;
    this.overlayLoaderStore.enableLoader('models-list');
    await this.getModelsAsync(currentWorkspace.id);
    this.overlayLoaderStore.disableLoader('models-list');
  }

  @action
  async getModelsAsync(workspaceId: string) {
    const { pageNumber, pageSize } = this.store.modelsPaging;
    const { orderBy, orderType } = this.store.modelsPaging;

    const request = {
      pageNumber,
      pageSize,
      orderBy,
      orderType,
      ownership: this.store.modelOwnership,
    };

    const result = await this.modelsApiService.getModelsAsync(workspaceId, request);

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'models:get_models_failed');
      this.store.modelsList = [];
      return;
    }

    if (result.pagesCount < result.pageNumber) {
      if (result.pagesCount === 0) {
        this.store.modelsList = [];
        return;
      }
      this.store.modelsPaging.pageNumber = result.pagesCount;
      await this.getModelsAsync(workspaceId);
    } else {
      this.store.modelsList = result.data.map(model => ({
        id: model.job_id,
        modelName: model.model_name,
        modelVariant: model.model_variant,
        projectName: model.project_name,
        createdAt: `${model.created_at}Z`, // Adding Z to mark this time as UTC (backend sends time without it)
        trainingTime: model.training_time,
        progress: model.progress,
        score: model.score,
        status: model.status === MODEL_STATUS.FAILING ? MODEL_STATUS.STOPPING : model.status,
        startingProgress: model.starting_progress,
        isOwner: model.is_owner,
        failureReason: model.failure_reason,
      }));
      this.store.modelsPaging = {
        ...this.store.modelsPaging,
        orderBy,
        orderType,
        pageNumber: result.pageNumber,
        pagesCount: result.pagesCount,
        totalCount: result.totalCount,
      };
    }
  }

  @action.bound
  async downloadModelAsync(modelId: string) {
    const { currentWorkspace } = this.currentWorkspaceStore;

    if (!currentWorkspace) return;

    const result = await this.modelsApiService.getDownloadModelAsync(currentWorkspace.id, modelId);

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'models:generate_download_url.failed');
      return;
    }

    // Using a element to prevent Firefox from blocking the download
    const a = document.createElement('a');
    a.href = result.url;
    a.download = '';
    a.click();
  }

  @action.bound
  async restartTrainingAsync(modelId: string): Promise<void | StickerError> {
    const { currentWorkspace } = this.currentWorkspaceStore;
    if (!currentWorkspace) return;

    const result = await this.modelsApiService.postModelTrainingRestartAsync(currentWorkspace.id, modelId);

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'models:restart_training.failed');
      return;
    }

    this.notificationsService.push(new ToastNotification(NotificationLevel.SUCCESS, 'models:restart_training.success'));

    await this.refreshAsync();
  }

  @action.bound
  async stopTrainingAsync(modelId: string): Promise<void | StickerError> {
    const { currentWorkspace } = this.currentWorkspaceStore;
    if (!currentWorkspace) return;

    const result = await this.modelsApiService.postModelTrainingStopAsync(currentWorkspace.id, modelId);

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'models:stop_training.failed');
      return;
    }

    this.notificationsService.push(new ToastNotification(NotificationLevel.SUCCESS, 'models:stop_training.success'));

    await this.refreshAsync();
  }

  @action.bound
  async deleteModelAsync(modelId: string): Promise<void | StickerError> {
    const { currentWorkspace } = this.currentWorkspaceStore;
    if (!currentWorkspace) return;

    const result = await this.modelsApiService.deleteModelTrainingAsync(currentWorkspace.id, modelId);

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'models:delete_model.failed');
      return;
    }

    this.notificationsService.push(new ToastNotification(NotificationLevel.SUCCESS, 'models:delete_model.success'));

    await this.refreshAsync();
  }

  notifyAboutApiError(result: any, errorCode: string, dontShowBadRequest: boolean = false) {
    if (result instanceof StickerError && !(result instanceof ForbiddenError) && (!dontShowBadRequest || !result.isBadRequest())) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, errorCode));
    }
  }
}
