import { inject, injectable } from 'inversify';
import { StickerError } from '../../../../../../__legacy__/models/error.model';
import { ApiServiceType } from '../../../../../../__legacy__/services/api.service';
import { IApiService } from '../../../../../../__legacy__/services/api.service.base';
import { ImportAnnotationConflict } from '../models/ImportAnnotationsConflicts';

export const CheckForAnnotationsConflictsServiceType = Symbol('CHECK_FOR_ANNOTATIONS_CONFLICTS_SERVICE');

export interface CheckForAnnotationsConflictsService {
  checkForAnnotationsConflictsAsync(request: CheckForAnnotationsConflictsRequest): Promise<CheckForConflictsConflictsResponse>;
}

export class CheckForAnnotationsConflictsRequest {
  dataSets: ImportAnnotationDataSet[];
  projectId: string;

  constructor(fileContent: string, projectId: string) {
    this.projectId = projectId;
    this.dataSets = JSON.parse(fileContent).DataSets.map((ds: any) => ({
      dataSetName: ds.DataSetName,
      images: ds.Images.map(
        (i: any) =>
          ({
            imageName: i.ImageName ? i.ImageName : i.ImageURL,
          } as ImportAnnotationsImage),
      ),
    }));
  }
}

interface ImportAnnotationDataSet {
  dataSetName: string;
  images: ImportAnnotationsImage[];
}

interface ImportAnnotationsImage {
  imageName: string;
}

export type CheckForConflictsConflictsResponse = ImportAnnotationConflict[];

@injectable()
export class CheckForAnnotationsConflictsServiceImpl implements CheckForAnnotationsConflictsService {
  constructor(@inject(ApiServiceType) readonly apiService: IApiService) {}

  async checkForAnnotationsConflictsAsync(request: CheckForAnnotationsConflictsRequest): Promise<CheckForConflictsConflictsResponse> {
    const url = '/Import/CheckForAnnotationsConflicts';
    const result = await this.apiService.postAsync<CheckForAnnotationsConflictsRequest, ImportAnnotationConflict[]>(url, request);

    if (result instanceof StickerError) {
      throw result;
    }

    return result;
  }
}
