import { Draggable, DraggableProvided, DraggableStateSnapshot } from '@qzsoftware/react-beautiful-dnd';
import { FormGroup, Label } from 'reactstrap';
import { ISelectOption, handleClickAndPassData } from '../../../../../../../helpers/formHelpers';
import { ISorting, SortingBy } from '../../../imageFilters.model';
import { faBars, faSortDown, faSortUp, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import ClassNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../../../../../helpers/translations.helpers';
import React from 'react';
import ReactDOM from 'react-dom';
import { SelectWithHax } from '../../../../../../../components/SelectWithHax';
import { SortingDirection } from '../../../../../../../models/sortingDirection.model';
import { as } from '../../../../../../../helpers/react.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IProps {
  order: number;
  sorting: ISorting;
  options: ISelectOption<SortingBy>[];
  onChange(sorting: ISorting): void;
  onDelete(order: number): void;
}

@observer
class SortingRowFormPure extends React.Component<IProps & ITranslatable> {
  handleByChange = (selected: any) => {
    this.props.onChange({ ...this.props.sorting, by: selected.value });
  }

  handleDelete = () => {
    this.props.onDelete(this.props.sorting.order);
  }

  handleDirectonChange = (direction: SortingDirection) => {
    if (this.props.sorting.direction === direction) return;
    this.props.onChange({ ...this.props.sorting, direction });
  }

  renderItem(provided: DraggableProvided, snapshot: DraggableStateSnapshot): any {
    const usePortal: boolean = snapshot.isDragging;
    const selectedOption = this.props.options.filter(o => this.props.sorting.by === o.value);

    const item = (
      <div className="image-filter-sorting" {...provided.draggableProps} ref={provided.innerRef}>
        <FormGroup>
          <Label>{this.props.t(this.props.order === 0 ? 'sort_by' : 'then_by')}</Label>
          <div className="input-wrapper">
            <div
              className="drag-handle"
              {...provided.dragHandleProps}
            >
              <FontAwesomeIcon icon={faBars} />
            </div>
            <SelectWithHax<ISelectOption<SortingBy>>
              value={selectedOption}
              onChange={this.handleByChange}
              options={this.props.options}
            />
            <div className="order-controls">
              <div
                title={this.props.t('ascending')}
                className={ClassNames({ 'order-control': true, up: true, active: this.props.sorting.direction === SortingDirection.ASC })}
                onClick={handleClickAndPassData(this.handleDirectonChange)(SortingDirection.ASC)}
              >
                <FontAwesomeIcon icon={faSortUp} />
              </div>
              <div
                title={this.props.t('descending')}
                className={ClassNames({ 'order-control': true, down: true, active: this.props.sorting.direction === SortingDirection.DESC })}
                onClick={handleClickAndPassData(this.handleDirectonChange)(SortingDirection.DESC)}
              ><FontAwesomeIcon icon={faSortDown} />
              </div>
            </div>
            {this.props.order > 0 && <div className="trash" title={this.props.t('remove')}>
              <div className="trash-icon" onClick={this.handleDelete}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </div>
            </div>}
          </div>
        </FormGroup>
      </div>
    );

    if (!usePortal) {
      return item;
    }

    // if dragging - put the item in a portal
    return ReactDOM.createPortal(item, document.body);
  }

  render() {
    return (
      <Draggable key={`sorting-key-${this.props.sorting.order}`} draggableId={`sorting-key-${this.props.sorting.order}`} index={this.props.sorting.order}>
        {(provided, draggableSnapshot) => (
          this.renderItem(provided, draggableSnapshot)
        )}
      </Draggable>
    );
  }
}

export const SortingRowForm = as<React.ComponentClass<IProps>>(withNamespaces('common')(SortingRowFormPure));
