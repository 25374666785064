import { inject, injectable } from 'inversify';
import { StickerError } from '../../../../../models/error.model';
import { ApiServiceType } from '../../../../../services/api.service';
import { IApiService } from '../../../../../services/api.service.base';

export const UpdateWorkspaceTimeZoneServiceType = Symbol('UPDATE_WORKSPACE_TIMEZONE_SERVICE');

export class UpdateWorkspaceTimeZoneRequest {
  workspaceId: string;
  timeZoneOffset: number;

  constructor(workspaceId: string, timeZoneOffset: number) {
    this.workspaceId = workspaceId;
    this.timeZoneOffset = timeZoneOffset;
  }
}

export interface IUpdateWorkspaceTimeZoneService {
  updateWorkspaceTimeZone(request: UpdateWorkspaceTimeZoneRequest): Promise<void>;
}

@injectable()
export class UpdateWorkspaceTimeZoneService implements IUpdateWorkspaceTimeZoneService {
  private get url(): string {
    return '/workspace/updateTimeZone';
  }

  constructor(@inject(ApiServiceType) private readonly apiService: IApiService) {}

  async updateWorkspaceTimeZone(request: UpdateWorkspaceTimeZoneRequest): Promise<void> {
    const result = await this.apiService.postAsync<UpdateWorkspaceTimeZoneRequest>(this.url, request);
    if (result instanceof StickerError) {
      throw result;
    }
  }
}
