import { IPagingInfoWithOrder, PaginationInfoDefault } from '../../../../__legacy__/models/paginationInfo.model';
import { action, observable } from 'mobx';

import { ExportDataFilter } from '../../../../__legacy__/modules/projects/projects.model';
import { GetProjectExportsResponse } from './services/GetProjectExports.service';
import { IProjectExport } from './models/ProjectExport';
import { injectable } from 'inversify';

export const ExportDataStoreType = Symbol('EXPORT_DATA_STORE');
export const ExportDataStoreSetterType = Symbol('EXPORT_DATA_STORE_SETTER');

export interface ExportDataStore {
  readonly paging: IPagingInfoWithOrder;
  readonly isCurrentExportGenerated: boolean;
  readonly isCurrentlyGenerating: boolean;
  readonly lastAnnotationChange: Date;
  readonly latestExportId: string;
  readonly projectExports: IProjectExport[];
  readonly projectId: string;
  readonly selectedDataFilter: ExportDataFilter;
  readonly showExportModal: boolean;
  readonly showDownloadModal: boolean;
  readonly downloadLink: string;
  readonly showLoader: boolean;
}

export interface ExportDataStoreSetter extends ExportDataStore {
  load(projectId: string, response: GetProjectExportsResponse): void;
  setShowExportModal(showModal: boolean): void;
  setShowDownloadModal(showModal: boolean): void;
  setSelectedDataFilter(dataFilter: ExportDataFilter): void;
  setIsCurrentlyGenerating(isCurrentlyGenerating: boolean): void;
  setDownloadLink(downloadLink: string): void;
  setShowLoader(showLoader: boolean): void;
}

export const ProjectExportsDefaultPaging = {
  ...PaginationInfoDefault,
  orderBy: 'createdDate',
  orderType: 'desc',
  search: '',
};

@injectable()
export class ExportDataStoreImpl implements ExportDataStoreSetter {
  @observable
  lastAnnotationChange: Date = new Date();

  @observable
  isCurrentExportGenerated: boolean = false;

  @observable
  isCurrentlyGenerating: boolean = false;

  @observable
  projectExports: IProjectExport[] = [];

  @observable
  showExportModal: boolean = false;

  @observable
  showDownloadModal: boolean = false;

  @observable
  selectedDataFilter: ExportDataFilter = ExportDataFilter.ALL;

  @observable
  latestExportId: string = '';

  @observable
  projectId: string = '';

  @observable
  paging: IPagingInfoWithOrder = ProjectExportsDefaultPaging;

  @observable
  downloadLink: string = '';

  @observable
  showLoader: boolean = false;

  @action
  load(projectId: string, response: GetProjectExportsResponse): void {
    this.projectId = projectId;
    this.isCurrentExportGenerated = response.isCurrentExportGenerated;
    this.isCurrentlyGenerating = response.isCurrentlyGenerating;
    this.lastAnnotationChange = response.lastAnnotationChange;
    this.latestExportId = response.latestExportId;
    this.projectExports = response.projectExports.data;

    this.paging = {
      ...this.paging,
      pageNumber: response.projectExports.pageNumber,
      pagesCount: response.projectExports.pagesCount,
      totalCount: response.projectExports.totalCount,
    };

    this.showLoader = false;
  }

  @action
  setSelectedDataFilter(dataFilter: ExportDataFilter) {
    this.selectedDataFilter = dataFilter;
  }

  @action
  setShowExportModal(showModal: boolean): void {
    this.showExportModal = showModal;
  }

  @action
  setShowDownloadModal(showModal: boolean): void {
    this.showDownloadModal = showModal;
  }

  @action
  setSelectedDataFilteR(dataFilter: ExportDataFilter): void {
    this.selectedDataFilter = dataFilter;
  }

  @action
  setIsCurrentlyGenerating(isCurrentlyGenerating: boolean): void {
    this.isCurrentlyGenerating = isCurrentlyGenerating;
  }

  @action
  setDownloadLink(downloadLink: string): void {
    this.downloadLink = downloadLink;
  }

  @action
  setShowLoader(showLoader: boolean): void {
    this.showLoader = showLoader;
  }
}
