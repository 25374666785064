import { DashboardContainer } from '../../containers/DashboardContainer';
import { ModelsHeader } from '../../../modules/models/containers/ModelsHeader';
import ModelsListContainer from '../../../modules/models/containers/ModelsList.container';
import React from 'react';

export const ModelsView = () => {
  return (
    <div>
      <DashboardContainer header={ModelsHeader}>
        <ModelsListContainer />
      </DashboardContainer>
    </div>
  );
};