import { ImportAnnotationConflict, ResolveConflictDecision } from '../../../../models/ImportAnnotationsConflicts';
import { ImportAnnotationsBl, ImportAnnotationsBlType } from '../../../../importAnnotations.bl';
import { ImportAnnotationsStore, ImportAnnotationsStoreType } from '../../../../importAnnotations.store';
import { as, injectProps } from '../../../../../../../../../__legacy__/helpers/react.helpers';

import React from 'react';
import { ResolveConflictTile } from './ResolveConflictTile';
import { observer } from 'mobx-react';

interface IProps {
  conflict: ImportAnnotationConflict;
}

interface InjectedProps {
  bl: ImportAnnotationsBl;
  store: ImportAnnotationsStore;
}

@injectProps({
  bl: ImportAnnotationsBlType,
  store: ImportAnnotationsStoreType,
})
@observer
class ResolveConflictTileContainerPure extends React.Component<IProps & InjectedProps> {
  handleKeepOriginal = () => this.changeDecision(ResolveConflictDecision.keepOriginal);
  handleReplaceAnnotations = () => this.changeDecision(ResolveConflictDecision.replaceAnnotation);

  changeDecision = (decision: ResolveConflictDecision) => this.props.bl.changeConflictDecision({ ...this.props.conflict, decision });

  render() {
    return (
      <ResolveConflictTile
        decision={this.props.conflict.decision}
        imageName={this.props.conflict.imageName}
        onKeepOriginal={this.handleKeepOriginal}
        onReplaceAnnotation={this.handleReplaceAnnotations}
      />
    );
  }
}

export const ResolveConflictTileContainer = as<React.ComponentClass<IProps>>(ResolveConflictTileContainerPure);
