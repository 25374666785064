﻿import * as React from 'react';

import { GTable, IGColumnProps } from '../../../../../components/table/GTable';

import { AddMarkingToolButtonContainer } from '../../../../../../modules/projects/details/annotationTools/markingTools/add/ui/button/AddMarkingToolButton.container';
import { AddMarkingToolModalContainer } from '../../../../../../modules/projects/details/annotationTools/markingTools/add/ui/modal/AddMarkingToolModal.container';
import { AnnotationTypeDeleteContainer } from '../containers/AnnotationTypeDeleteContainer';
import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { EditMarkingToolButtonContainer } from '../../../../../../modules/projects/details/annotationTools/markingTools/edit/ui/button/EditMarkingToolButton.container';
import { EditMarkingToolModalContainer } from '../../../../../../modules/projects/details/annotationTools/markingTools/edit/ui/modal/EditMarkingToolModal.container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IAnnotationType } from '../projectDetailsTools.models';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { Loader } from '../../../../../components/Loader';
import { as } from '../../../../../helpers/react.helpers';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { getContrastingColor } from '../../../../../helpers/color.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

export type AnnotationTypeWithScopeUsage = IAnnotationType & { isUsedAsScope: boolean };

interface IAnnotationTypesListProps {
  list: AnnotationTypeWithScopeUsage[];
  canEdit: boolean;
  isLoading: boolean;
  onOrderChange(annotationTypeId: string, to: number): void;
}

interface IState {
  showEditModal: boolean;
  annotationTypeId?: string;
}

@observer
class AnnotationTypesListPure extends React.Component<IAnnotationTypesListProps & ITranslatable, IState> {
  constructor(props: IAnnotationTypesListProps & ITranslatable) {
    super(props);
    this.state = {
      showEditModal: false,
      annotationTypeId: '',
    };
  }

  get columns(): IGColumnProps<AnnotationTypeWithScopeUsage>[] {
    const columns: IGColumnProps<AnnotationTypeWithScopeUsage>[] = [
      {
        field: 'name',
        headerName: this.props.t('name'),
      },
      {
        field: 'selectorType',
        headerName: this.props.t('tool_type'),
        renderer: this.typeRenderer,
      },
      {
        field: 'color',
        headerName: this.props.t('color'),
        cellClass: 'cell-with-bg-color',
        cellStyle: (value: AnnotationTypeWithScopeUsage) => ({ backgroundColor: value.color, color: getContrastingColor(value.color) }),
      },
      {
        field: '',
        headerName: this.props.t('actions'),
        cellClass: 'g-actions',
        width: 100,
        renderer: this.actionsRenderer,
      },
    ];

    if (this.props.canEdit) {
      columns.unshift({
        field: '',
        headerName: '',
        cellClass: '',
        width: 60,
        isDragHandle: true,
        renderer: () => <FontAwesomeIcon icon={faBars} />,
      });
    }

    return columns;
  }

  typeRenderer = (data: AnnotationTypeWithScopeUsage) => <span>{this.props.t(data.selectorType.toLowerCase())}</span>;

  actionsRenderer = (data: AnnotationTypeWithScopeUsage) => {
    return (
      <>
        <DisabledWhenWorkspaceOwnerPolicyExceeded>
          <EditMarkingToolButtonContainer markingToolId={data.id} />
        </DisabledWhenWorkspaceOwnerPolicyExceeded>
        <AnnotationTypeDeleteContainer canEdit={this.props.canEdit} annotationType={data} isUsedAsScope={data.isUsedAsScope} />
      </>
    );
  };

  handleRowDrag = (cb: any) => (from: number, to: number) => cb(this.props.list[from].id, to);

  render() {
    return (
      <div className="annotation-tools-list">
        <div className="d-flex flex-row">
          <h4>{this.props.t('segmentation')}</h4>
          <div className="ml-2">
            {this.props.canEdit && (
              <>
                <AddMarkingToolButtonContainer />
                <AddMarkingToolModalContainer />
              </>
            )}
          </div>
        </div>
        <div className="table-container">
          <Loader isLoading={this.props.isLoading}>
            <GTable
              columns={this.columns}
              items={this.props.list}
              enableDrag={this.props.canEdit}
              onRowDragEnd={this.handleRowDrag(this.props.onOrderChange)}
              noItemsPlaceholder={this.props.t('no_tools_have_been_added_yet')}
            />
          </Loader>
        </div>
        <EditMarkingToolModalContainer />
      </div>
    );
  }
}

export const AnnotationTypesList = as<React.ComponentClass<IAnnotationTypesListProps>>(withNamespaces('project', { wait: true })(AnnotationTypesListPure));
