import * as React from 'react';

import { WithNamespaces, withNamespaces } from 'react-i18next';

import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';

export interface IDeleteSegmentationConfirmationModalProps {
  showSegmentationDeleteConfirmationModal: boolean;
  onSegmentationDeleteConfirm(): void;
  onSegmentationDeleteCancel(): void;
}

const DeleteSegmentationConfirmationModalPure: React.FC<IDeleteSegmentationConfirmationModalProps & WithNamespaces> = props => (
  <ConfirmationModal
    confirmationHeader={props.t('delete_segmentation_confirmation')}
    confirmationQuestion={props.t('delete_segmentation_confirmation_body')}
    onCancel={props.onSegmentationDeleteCancel}
    onConfirm={props.onSegmentationDeleteConfirm}
    showModal={props.showSegmentationDeleteConfirmationModal}
    confirmationYes={props.t('delete')}
  />
);

export const DeleteSegmentationConfirmationModal = withNamespaces('annotation', { wait: true })(DeleteSegmentationConfirmationModalPure);
