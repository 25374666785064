import * as React from 'react';

import { ITranslatable } from '../../../helpers/translations.helpers';
import { IZoomControlProps } from '../containers/ZoomContainer';
import { SliderInput } from './SliderInput';
import { as } from '../../../helpers/react.helpers';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

library.add(faSearch);

@observer
class ZoomControlPure extends React.Component<IZoomControlProps & ITranslatable> {
  handleChangeEvent = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.props.onZoomLevelChange(Number(e.target.value));
  };

  renderValue = (value: number) => <>{value}%</>;

  render() {
    return (
      <SliderInput
        icon={faSearch}
        label={this.props.t('zoom')}
        min={this.props.minZoomLevel}
        max={this.props.maxZoomLevel}
        step={5}
        value={this.props.zoomValue}
        onChange={this.handleChangeEvent}
        renderValue={this.renderValue}
      />
    );
  }
}
export const ZoomControl = as<React.ComponentClass<IZoomControlProps>>(withNamespaces('annotation')(ZoomControlPure));
