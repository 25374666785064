import leaflet, { LatLng } from 'leaflet';

import { DrawVector } from '../components/DrawControl.models/Draw.vector';
import { IUndoRedoCommand } from './undoRedoCommand';

export class AddVectorVertexCommand implements IUndoRedoCommand {
  private readonly vector: DrawVector;
  private readonly latlng: LatLng;
  private readonly markers: leaflet.Marker<any>[];

  constructor(
    polyline: DrawVector,
    latlng: LatLng,
    markers: leaflet.Marker<any>[],
  ) {
    this.vector = polyline;
    this.latlng = latlng;
    this.markers = markers;
  }

  executeUndo() {
    this.markers.forEach(m => m.remove());
    this.vector.disable();
    this.vector.enable();
  }

  executeRedo(): void {
    this.vector.addVertex(this.latlng);
  }
}
