import { AnnotationStatus, FREE_DRAW_TOOLS, IAnnotationProgress, IImage, IReviewProgress, ISegmentation, NoImageReason } from './annotations.interface';
import { IObservableArray, action, computed, observable } from 'mobx';

import { DatasetStatus } from '../datesets/datasetStatus.model';
import { IQuestionModel } from './question.model';
import { ProjectStatus } from '../projects/projects.model';
import { injectable } from 'inversify';

export const AnnotationsStoreType = Symbol('ANNOTATIONS_STORE');

export interface IHiddenSegmentation {
  segmentationId?: string;
  featureId: string;
}

export interface IAnnotationsStore {
  id?: string;
  image?: IImage;
  projectId: string;
  imageName: string;
  datasetName: string;
  datasetStatus: DatasetStatus;
  projectStatus: ProjectStatus;

  readonly segmentations: ReadonlyArray<ISegmentation>;
  readonly hiddenSegmentations: ReadonlyArray<IHiddenSegmentation>;
  selectedSegmentation?: ISegmentation;
  drawFocusFrameForId?: string;
  hoveredSegmentation?: string;
  invalidSegmentation?: ISegmentation;
  copiedSegmentation?: ISegmentation;

  questions: IQuestionModel[];
  currentQuestions: IQuestionModel[];

  isSelectedSegWithQuestions: boolean;
  isSelectedSegOfFreeDrawType: boolean;
  invalidQuestionsCount: number;
  allQuestionsValid: boolean;

  noImageReason: NoImageReason;

  annotationProgress: IAnnotationProgress;
  reviewProgress: IReviewProgress;
  annotationStatus?: AnnotationStatus;

  setImageName(imageName: string, datasetName: string, datasetStatus: DatasetStatus): void;
  setSegmentations(segmentations: ISegmentation[]): void;
  setProjectStatus(projectStatus: ProjectStatus): void;

  setHiddenSegmentations(hiddenFeatures: IHiddenSegmentation[]): void;
}

@injectable()
export class AnnotationsStore implements IAnnotationsStore {
  @action
  setSegmentations(segmentations: ISegmentation[]): void {
    this.segmentations.replace(segmentations);
  }

  @action
  setHiddenSegmentations(hiddenFeatures: IHiddenSegmentation[]): void {
    this.hiddenSegmentations.replace(hiddenFeatures);
  }

  @observable
  id?: string;

  @observable
  projectId: string = '';

  @observable
  imageName: string = '';

  @observable
  datasetName: string = '';

  @observable
  datasetStatus: DatasetStatus = DatasetStatus.None;

  @observable
  image?: IImage;

  @observable
  segmentations: IObservableArray<ISegmentation> = observable.array([]);

  @observable
  hiddenSegmentations: IObservableArray<IHiddenSegmentation> = observable.array([]);

  @observable
  hoveredSegmentation?: string;

  copiedSegmentation?: ISegmentation;

  @observable
  invalidSegmentation?: ISegmentation;

  @observable
  currentQuestions: IQuestionModel[] = [];

  @observable
  questions: IQuestionModel[] = [];

  @observable
  drawFocusFrameForId?: string = undefined;

  @observable
  noImageReason: NoImageReason = NoImageReason.Unknown;

  @observable
  annotationProgress: IAnnotationProgress = {
    all: 0,
    totalImages: 0,
    you: 0,
  };

  @observable
  reviewProgress: IReviewProgress = {
    allAccepted: 0,
    allRejected: 0,
    totalImages: 0,
    youAccepted: 0,
    youRejected: 0,
    awaitingReview: 0,
  };

  @observable
  annotationStatus?: AnnotationStatus = undefined;

  @observable
  projectStatus: ProjectStatus = ProjectStatus.Draft;

  @computed
  get invalidQuestionsCount(): number {
    return this.segmentations.filter(s => s.questions.some(q => q.status.isValid === false)).length;
  }

  @computed
  get isSelectedSegWithQuestions(): boolean {
    return this.selectedSegmentation !== undefined && this.selectedSegmentation.questions.length > 0;
  }

  @computed
  get isSelectedSegOfFreeDrawType(): boolean {
    return this.selectedSegmentation !== undefined && FREE_DRAW_TOOLS.includes(this.selectedSegmentation.feature.featureType);
  }

  get allQuestionsValid(): boolean {
    this.questions.forEach(q => q.validate());
    return this.questions.every(q => q.status.isValid !== false);
  }

  @observable
  private _selectedSegmentation: ISegmentation | undefined;
  public get selectedSegmentation(): ISegmentation | undefined {
    return this._selectedSegmentation;
  }

  public set selectedSegmentation(v: ISegmentation | undefined) {
    this._selectedSegmentation = v;
    if (this.drawFocusFrameForId !== this._selectedSegmentation?.feature.id) {
      this.drawFocusFrameForId = undefined;
    }
  }

  @action
  setImageName(imageName: string, datasetName: string, datasetStatus: DatasetStatus) {
    this.imageName = imageName;
    this.datasetName = datasetName;
    this.datasetStatus = datasetStatus;
  }

  @action
  setProjectStatus(projectStatus: ProjectStatus) {
    this.projectStatus = projectStatus;
  }
}
