import { BaseRouteConfig, StickerRoute } from './BaseRoute';

export class ProjectsPath extends BaseRouteConfig {
  public get Index(): string {
    return 'projects';
  }

  public readonly List = {
    Path: `${this.Path}/list`,
    All: new StickerRoute<{ workspaceId: string }>(`${this.Path}/list/all`),
    Owned: new StickerRoute<{ workspaceId: string }>(`${this.Path}/list/owned`),
    Others: new StickerRoute<{ workspaceId: string }>(`${this.Path}/list/others`),
  };

  public readonly Details = {
    Path: `${this.Path}/:projectId`,
    Paths: new StickerRoute<{ workspaceId: string, projectId: string; tab: string }>(`${this.Path}/:projectId/:tab`),
    Overview: new StickerRoute<{ workspaceId: string, projectId: string }>(`${this.Path}/:projectId/overview`),
    Datasets: new StickerRoute<{ workspaceId: string, projectId: string }>(`${this.Path}/:projectId/datasets`),
    Images: new StickerRoute<{ workspaceId: string, projectId: string }>(`${this.Path}/:projectId/images`),
    TeamMembers: new StickerRoute<{ workspaceId: string, projectId: string }>(`${this.Path}/:projectId/members`),
    Statistics: new StickerRoute<{ workspaceId: string, projectId: string }>(`${this.Path}/:projectId/statistics`),
    NewStatistics: new StickerRoute<{ workspaceId: string, projectId: string }>(`${this.Path}/:projectId/new-statistics`),
    ExportData: new StickerRoute<{ workspaceId: string, projectId: string }>(`${this.Path}/:projectId/export-data`),
    AnnotationView: new StickerRoute<{ workspaceId: string, projectId: string }>(`${this.Path}/:projectId/annotation-view`),
    ActivityLog: new StickerRoute<{ workspaceId: string, projectId: string }>(`${this.Path}/:projectId/activity-log`),
    Preview: new StickerRoute<{ workspaceId: string, projectId: string }>(`${this.Path}/:projectId?/preview`),
  };
}
