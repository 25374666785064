import { injectable } from 'inversify';

export const TimerServiceType = Symbol('TIMER_SERVICE');

const INACTIVITY_TRIGGER = 60000;

export interface ITimerService {
  duration: number;
  startTimer(): void;
}

@injectable()
export class TimerService implements ITimerService {
  private _duration = 0;
  private _interval: number = 0;
  private _lastTick?: number = undefined;

  constructor() {
    window.addEventListener('mousemove', () => this.move());
    window.addEventListener('keyup', () => this.move());
    window.addEventListener('visibilitychange', () => (document.visibilityState === 'visible' ? this.move() : this.freeze()));
  }

  get duration(): number {
    return this._duration;
  }

  startTimer() {
    window.clearInterval(this._interval);
    this._duration = 0;
    this._lastTick = Date.now();
    this._interval = window.setInterval(this.freeze.bind(this), INACTIVITY_TRIGGER);
  }

  private move() {
    const tick = Date.now();
    this._duration += tick - (this._lastTick || tick);
    this._lastTick = tick;
  }

  private freeze() {
    if (!this._lastTick) return;
    this._lastTick = undefined;
  }
}
