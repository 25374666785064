import { lossMetricsWithTranslation } from './contents/LossMetrics';
import * as React from 'react';
import { as } from '../../helpers/react.helpers';
import { IChartPoint, ITrainingMatrix, ITrainingMetrics, ITrainingResult } from '../../models/metrics.model';
import { resultMetricsWithoutRecallWithTranslation } from './contents/ResultMetricsWithoutRecall';

interface IProps {
  ns: 'models' | 'evaluations';
  train?: IChartPoint[];
  validation?: IChartPoint[];
  curve: IChartPoint[];
  matrix: ITrainingMatrix[];
  metrics: ITrainingMetrics;
  results: ITrainingResult[];
}

const ObjectClassificationPure = (props: IProps) => {
  const LossMetrics = lossMetricsWithTranslation(props.ns);
  const ResultMetrics = resultMetricsWithoutRecallWithTranslation(props.ns);

  const { train, validation, matrix, results } = props;

  return (
    <>
      {train && validation && <LossMetrics train={train} validation={validation} />}
      <ResultMetrics matrix={matrix} results={results} />
    </>
  );
};

export const ObjectClassification = as<React.FC<IProps>>(ObjectClassificationPure);
