import * as React from 'react';

import { drawingScreenDisables, keyboardShortcutsServiceInstance } from '../../../../services/keyboardShortcuts.service';

import { FormGroup } from 'reactstrap';
import { ITextAnswerProps } from '../../question.model';
import { ITranslatable } from '../../../../helpers/translations.helpers';
import InputNumber from 'rc-input-number';
import { as } from '../../../../helpers/react.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IState {
  answer?: string;
}

@observer
class NumericAnswerPure extends React.Component<ITextAnswerProps & ITranslatable, IState> {
  constructor(props: ITextAnswerProps & ITranslatable) {
    super(props);
    this.state = { answer: undefined };
  }

  componentDidMount() {
    this.setState({ answer: this.props.answer });
  }

  // tslint:disable-next-line:function-name
  UNSAFE_componentWillUpdate(newProps: ITextAnswerProps) {
    if (this.props.answer !== newProps.answer) {
      this.setState({ answer: newProps.answer });
    }
  }

  handleFocus = () => keyboardShortcutsServiceInstance.disableShortcuts(drawingScreenDisables);

  handleBlur = () => {
    keyboardShortcutsServiceInstance.enableShortcuts(drawingScreenDisables);
    this.props.onAnswered(this.state.answer?.toString() || '');
  }

  handleChange = (value: number | string | undefined) => {
    const parsed = Number(value);
    if (!Number.isNaN(parsed)) {
      this.setState({ answer: value?.toString() });
    }
  }

  render() {
    const answer = this.state.answer ? Number(this.state.answer) : undefined;

    return (
      <FormGroup>
        <InputNumber
          className={`form-control ${this.props.className}`}
          value={Number.isNaN(answer) ? undefined : answer}
          placeholder={this.props.t('open_answer_placeholder')}
          onChange={this.handleChange}
          disabled={this.props.disabled}
          defaultValue={undefined}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        />
      </FormGroup>
    );
  }
}

export const NumericAnswer = as<React.ComponentClass<ITextAnswerProps>>(withNamespaces('annotation', { wait: true })(NumericAnswerPure));
