import { injectable, inject } from 'inversify';
import { RejectionReasonServiceType, IRejectionReasonService, GetRejectionReasonRequest } from './services/rejectionReason.service';
import { IReviewRejectReasonsStoreSetter, ReviewRejectReasonsStoreType } from './reviewRejectReasons.store';

export const ReviewRejectionReasonBlType = Symbol('REVIEW_REJECTION_REASON_BL');

export interface IReviewRejectionReasonBl {
  getReasons(projectId: string): Promise<void>;
  handleRejection(reason: string): void;
}

const _listSize = 10;

@injectable()
export class ReviewRejectionReasonBl implements IReviewRejectionReasonBl {

  constructor(
    @inject(ReviewRejectReasonsStoreType) private readonly reviewStore: IReviewRejectReasonsStoreSetter,
    @inject(RejectionReasonServiceType) private readonly rejectionReasonService: IRejectionReasonService,
  ) {
  }

  async getReasons(projectId: string) {
    const result = await this.rejectionReasonService.getRejectionReasons(new GetRejectionReasonRequest(projectId));
    if (result instanceof Error) throw result;
    this.reviewStore.setRejectionReasons(result);
  }

  handleRejection(reason: string): void {
    const trimmed = reason.trim();
    if (trimmed.length === 0) return;

    const rejectionReasons = this.reviewStore.rejectionReasons.slice();
    const index = rejectionReasons.findIndex(x => x.reason.toLowerCase() === trimmed.toLowerCase());

    if (index > -1) {
      const rejectionReason = rejectionReasons[index];
      rejectionReasons.splice(index, 1);
      rejectionReasons.unshift(rejectionReason);
    } else {
      if (rejectionReasons.length >= _listSize) {
        rejectionReasons.pop();
      }
      rejectionReasons.unshift({ reason: trimmed, order: 0 });
    }
    const sorted = rejectionReasons.map((x, i) => ({ reason: x.reason, order: i }));
    this.reviewStore.setRejectionReasons(sorted);
  }
}
