import leaflet, { LatLng } from 'leaflet';

import { DrawPolyLine } from '../components/DrawControl.models/Draw.polyline';
import { IUndoRedoCommand } from './undoRedoCommand';

export class AddPolylineVertexCommand implements IUndoRedoCommand {
  private readonly polyline: DrawPolyLine;
  private readonly latlng: LatLng;
  private readonly markers: leaflet.Marker<any>[];

  constructor(
    polyline: DrawPolyLine,
    latlng: LatLng,
    markers: leaflet.Marker<any>[],
  ) {
    this.polyline = polyline;
    this.latlng = latlng;
    this.markers = markers;
  }

  executeUndo() {
    this.markers.slice(0, -1).forEach(m => this.polyline.addLayer(m));

    if (this.polyline.getMarkersCount() > 1) {
      this.polyline.deleteLastVertex();
    } else {
      this.polyline.disable();
      this.polyline.enable();
    }
  }

  executeRedo(): void {
    this.polyline.addVertex(this.latlng);
  }
}
