﻿import * as React from 'react';

import { Alert } from 'reactstrap';
import { GLOBAL_ERROR_CODES } from '../helpers/global.constants';
import { ITranslatable } from '../helpers/translations.helpers';
import { InputStatus } from '../models/error.model';
import { as } from '../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  id?: string;
  errors: InputStatus;
  useDefaultMessageWhenCodeNotFound?: boolean;
}

const FormAlertPure = (props: IProps & ITranslatable) => {
  if (!props.errors.errorCodes.length) return null;
  const errorMessages = props.errors.errorCodes.map((errorMessage, i) => {
    let translatedMessage = props.t(errorMessage);

    const codeNotFound = errorMessage === translatedMessage;
    if (props.useDefaultMessageWhenCodeNotFound && codeNotFound) {
      translatedMessage = props.t(GLOBAL_ERROR_CODES.FATAL_EXCEPTION);
    }

    return (
      <li id={props.id} key={i}>
        {translatedMessage}
      </li>
    );
  });

  return (
    <Alert color="danger">
      <ul>{errorMessages}</ul>
    </Alert>
  );
};

export const formAlertWithTranslations = (ns: string) => as<any>(withNamespaces(ns)(FormAlertPure as any));
