import React, { ReactNode } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import { S_TwoButtonModal } from '../../../../../../design/modals/twoButtonModal/S_TwoButtonModal';

interface Props {
  headerKey: string;
  show: boolean;
  onHide(): void;
  onSubmit(): void;
  children?: ReactNode;
}

const MarkingToolModalPure: React.FC<Props & WithNamespaces> = (props: Props & WithNamespaces) => {
  const { t } = props;
  return (
    <S_TwoButtonModal
      cancelText={t('projects.details.annotation_tools.marking_tools.cancel')}
      headerText={t(props.headerKey)}
      submitText={t('projects.details.annotation_tools.marking_tools.submit')}
      onCancel={props.onHide}
      onHide={props.onHide}
      onSubmit={props.onSubmit}
      show={props.show}
      loaderKey="add-or-edit-marking-tool-key"
    >
      {props.children}
    </S_TwoButtonModal>
  );
};

export const MarkingToolModal = withNamespaces('new')(MarkingToolModalPure);
