import * as React from 'react';

import { Col, Row } from 'reactstrap';
import { IProjectDetailsImage, ImageSelectionMode, ImageSetType } from '../projectDetailsImages.model';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { faEdit, faUnlink } from '@fortawesome/free-solid-svg-icons';

import { BatchAnswerQuestionsContainer } from '../containers/BatchAnswerQuestions.container';
import { FilteredImagesCounterContainer } from '../containers/filters/FilteredImagesCounter.container';
import { ImageFiltersContainer } from '../containers/filters/ImageFilters.container';
import { ListViewMode } from '../../../../user/user.store';
import { NewStatisticsWarningRowContainer } from '../../../../../../modules/common/NewStatisticsWarningRow.container';
import { ProjectDetailsImageSetTypeContainer } from '../containers/ProjectDetailsImageSetType.container';
import { ProjectDetailsImagesListTable } from './ProjectDetailsImagesListTable';
import { ProjectDetailsImagesListThumbs } from './ProjectDetailsImagesListThumbs';
import { S_PaginationProps } from '../../../../../../design/pagination/S_Pagination';
import { SelectAllButtonContainer } from '../containers/SelectAllButton.container';
import { SelectedImagesCounterContainer } from '../containers/SelectedImagesCounter.container';
import { SortingBy } from '../imageFilters.model';
import { SortingDirection } from '../../../../../models/sortingDirection.model';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faUnlink, faEdit);

export interface IProjectDetailsImagesListProps {
  workspaceId: string;
  projectId: string;
  filterId: string;
  detailsImages: ReadonlyArray<IProjectDetailsImage>;
  isLoading: boolean;
  pagination: S_PaginationProps;
  disabled: boolean;
  viewMode: ListViewMode;
  authToken: string;
  canSelectImages: boolean;
  selectionMode: ImageSelectionMode;
  toggledImages: string[];
  canSeeUsers: boolean;
  canBatchAnswerQuestions: boolean;
  canUpdateImageSet: boolean;
  orderBy: string;
  orderType: string;
  onOrderingChange(by: SortingBy, direction: SortingDirection | undefined): void;
  onCheck(id: string, isBatch: boolean): void;
  onCheckAll(): void;
  onImageSetChange(id: string, type: ImageSetType): void;
  isProjectCreatedBeforeStatsRelease: boolean;
}

class ProjectDetailsImagesListPure extends React.Component<IProjectDetailsImagesListProps & WithNamespaces> {
  render() {
    return (
      <div className="image-list-controls">
        {this.props.isProjectCreatedBeforeStatsRelease && (
          <Row>
            <Col>
              <NewStatisticsWarningRowContainer />
            </Col>
          </Row>
        )}
        <Row>
          <Col md={12}>
            <Row>
              <Col>
                <ImageFiltersContainer />
              </Col>
              <Col md={'auto'} className="right-buttons">
                <BatchAnswerQuestionsContainer />
                <SelectAllButtonContainer />
                <ProjectDetailsImageSetTypeContainer />
              </Col>
            </Row>
            <div className="image-counters">
              <FilteredImagesCounterContainer />
              <SelectedImagesCounterContainer />
            </div>
          </Col>
        </Row>
        <div className="details-images-container">
          {this.props.viewMode === ListViewMode.List && <ProjectDetailsImagesListTable {...this.props} />}
          {this.props.viewMode === ListViewMode.Tiles && <ProjectDetailsImagesListThumbs {...this.props} />}
        </div>
      </div>
    );
  }
}

export const ProjectDetailsImagesList = withNamespaces('project', { wait: true })(ProjectDetailsImagesListPure);
