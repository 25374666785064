import { DatasetDetailsImagesView } from '../../views/home/datasetsDetails/DatasetDetailsImages.view';
import { DatasetDetailsProjectsView } from '../../views/home/datasetsDetails/DatasetDetailsProjects.view';
import { Home } from '../config/Home';
import { NotFoundView } from '../../views/NotFound.view';
import React from 'react';
import { Route } from 'react-router';
import { SwitchWithNotFound } from '../../containers/route.containers';

export const DatasetDetailsRoute = () => (
  <SwitchWithNotFound notFound={NotFoundView}>
    <Route path={Home.Datasets.Details.Images.Path} component={DatasetDetailsImagesView} />
    <Route path={Home.Datasets.Details.Projects.Path} component={DatasetDetailsProjectsView} />
  </SwitchWithNotFound>
);
