import * as React from 'react';

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { ConfirmationModal } from './ConfirmationModal';
import { DropdownExternalLinkItem } from './DropdownExternalLinkItem';
import { DropdownLinkItem } from './DropdownLinkItem';
import { ExternalLinks } from '../routes/config/ExternalLinks';
import { Home } from '../routes/config/Home';
import { ITranslatable } from '../helpers/translations.helpers';
import IncreaseLimitContainer from '../modules/billing/containers/IncreaseLimit.container';
import { UserAvatar } from './UserAvatar';
import { as } from '../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export type UsernameVisibilityState = 'username-show' | 'username-hide';

export interface IUserDropdownProps {
  showIncreaseLimit: boolean;
  increaseLimitEnabled: boolean;
  usernameVisibility: UsernameVisibilityState;
  shortName: string;
  email: string;
  showModal: boolean;
  queryString: string;
  workspaceId: string;
  onLogout(): void;
  onCancel(): void;
  onConfirm(): void;
  onIncreaseLimit(): void;
}

interface IState {
  isOpen: boolean;
  isLimitModalOpen: boolean;
}

class UserDropdownPure extends React.PureComponent<IUserDropdownProps & ITranslatable, IState> {
  state = { isOpen: false, isLimitModalOpen: false };

  toggle = () => {
    if (this.state.isOpen && document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  toggleLimitModal = () => {
    this.setState(prevState => ({ isLimitModalOpen: !prevState.isLimitModalOpen }));
  };

  render() {
    const externalQueryString = !!this.props.queryString ? `?${this.props.queryString}` : '';
    return (
      <div className="top-nav-item">
        <Dropdown isOpen={this.state.isOpen} toggle={this.toggle} id="user-dropdown" className={`top-nav-dropdown ${this.props.usernameVisibility}`}>
          <DropdownToggle caret>
            <UserAvatar shortName={this.props.shortName} variation="in-top-nav" /> <span className="user-name">{this.props.email}</span>
          </DropdownToggle>
          <DropdownMenu id="top-menu-options" right>
            <DropdownLinkItem id="user-dropdown-account-button" linkTo={Home.User.Account.withParams({ workspaceId: this.props.workspaceId })}>
              {this.props.t('account')}
            </DropdownLinkItem>
            {this.props.showIncreaseLimit && (
              <DropdownItem
                id="user-dropdown-increase-limit-button"
                onClick={this.toggleLimitModal}
                disabled={!this.props.increaseLimitEnabled}
                title={this.props.increaseLimitEnabled ? '' : this.props.t('shop_disabled_message')}
              >
                {this.props.t('increase_limit')}
              </DropdownItem>
            )}
            <DropdownItem divider />
            <DropdownExternalLinkItem id="user-dropdown-tutorials-button" linkTo={`${ExternalLinks.ZillinIo.Tutorials.Path}${externalQueryString}`}>
              {this.props.t('tutorials')}
            </DropdownExternalLinkItem>
            <DropdownExternalLinkItem id="user-dropdown-faq-button" linkTo={`${ExternalLinks.ZillinIo.Faq.Path}${externalQueryString}`}>
              {this.props.t('faq')}
            </DropdownExternalLinkItem>
            <DropdownItem divider />
            <DropdownItem id="user-dropdown-logout-button" onClick={this.props.onLogout}>
              {this.props.t('log_out')}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <ConfirmationModal
          showModal={this.props.showModal}
          onCancel={this.props.onCancel}
          onConfirm={this.props.onConfirm}
          confirmationHeader="user:user-logout-confirmation-header"
          confirmationQuestion="user:user-logout-confirmation-question"
        />
        <IncreaseLimitContainer showModal={this.state.isLimitModalOpen} onClosed={this.toggleLimitModal} />
      </div>
    );
  }
}

export const UserDropdown = as<React.ComponentClass<IUserDropdownProps>>(withNamespaces('auth')(UserDropdownPure));
