import '../CursorControl.scss';

import { WithNamespaces, withNamespaces } from 'react-i18next';

import { CustomInput } from 'reactstrap';
import React from 'react';
import { as } from '../../../../__legacy__/helpers/react.helpers';

interface IProps {
  checked: boolean;
  disabled: boolean;
  onChange(): void;
}

export const CursorGuidesCheckboxPure: React.FC<IProps & WithNamespaces> = (props: IProps & WithNamespaces) => {
  const { t, checked, onChange, disabled } = props;
  return (
    <div className={`cursor-control-checkbox${disabled && '-disabled'}`}>
      <CustomInput id="cursor-guides-checkbox" disabled={disabled} type="checkbox" checked={checked} onChange={onChange} label={t('editor.cursor_control.enable_cursor_guides')} />
    </div>
  );
};

export const CursorGuidesCheckbox = as<React.ComponentClass<IProps>>(withNamespaces('new')(CursorGuidesCheckboxPure));
