import { IProjectDetailsApiService, ProjectDetailsApiServiceType } from './services/projectDetailsApi.service';
import { INotificationsService, NotificationsServiceType } from '../notifications/services/notifications.service';
import { IProjectDetailsStore, IProjectDetailsStoreSetter, ProjectDetailsStoreType } from './projectDetails.store';
import { NotificationLevel, ToastNotification } from '../notifications/models/notification.model';
import { inject, injectable, multiInject } from 'inversify';

import { StickerError, ForbiddenError, InputStatus } from '../../models/error.model';
import { ProjectRole } from '../../models/userRole.model';
import { action } from 'mobx';
import { Home } from '../../routes/config/Home';
import { IRouterStore, RouterStoreType } from '../../stores/router.store';
import _ from 'lodash';
import { IProjectDetailsStatisticsStore, ProjectDetailsStatisticsStoreType } from './sub/statistics/projectDetailsStatistics.store';
import { BillingServiceType, IBillingService } from '../billing/billing.service';
import { ImageAssignmentPolicy, ICanPublishProjectResponse, IDatasetsTabDto } from './projectDetails.models';
import { ProjectStatus } from '../projects/projects.model';
import { IProjectDetailsSubmodule, ProjectDetailsSubmoduleType } from './services/IProjectDetailsSubmodule';
import { delay } from '../../helpers/function.helpers';
import { UserRemovedFromProject } from './events/userRemovedFromProject';
import { EventBusType, IEventBus } from '../../services/eventBus.service';
import { timeZones } from '../../models/timeZones/timeZones.model';
import { SortingDirection } from '../../models/sortingDirection.model';
import { ICurrentWorkspaceStore, CurrentWorkspaceStoreType } from '../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';

const getProjectDetailsFailedMessage = 'project:get_project_details_failed';
const updateProjectDetailsFailedMessage = 'project:update_project_details_failed';

export const ProjectDetailsBlType = Symbol('PROJECT_DETAILS_SERVICE');

const ERROR_CODES = {
  PROJECT_NAME_EMPTY: 'field_cant_be_empty',
  PROJECT_NAME_NOT_UNIQUE: 'error_project_name_must_be_unique',
  NO_DATASET_ADDED: 'no_dataset_added',
  NO_SEGMENTATION_OR_QUESTION_ADDED: 'no_segmentations_or_questions_added',
};

export interface IProjectDetailsBl {
  initialize(): void;

  // datasets
  unlinkDatasetAsync(datasetId: string): any;
  assignDatasetToProjectAsync(datasetId: string): Promise<void | StickerError>;
  getProjectDetailsDatasetsAsync(projectId: string): Promise<IDatasetsTabDto | StickerError>;
  changeDatasetOrderAsync(from: number, to: number): Promise<void>;

  // users
  getProjectDetailsMembersAsync(projectId: string): Promise<void | StickerError>;
  addUserToProjectAsync(projectId: string, email: string, role: ProjectRole): Promise<void | StickerError>;
  removeUserFromProjectAsync(userId: string, projectId: string): Promise<void>;
  usersSortChanged(orderBy: string, orderType: SortingDirection): void;
  usersPaginationChange(pageNumber: number, pageSize: number): void;

  // overview
  getProjectDetailsAsync(projectId: string): Promise<void | StickerError>;
  changeName(name: string): void;
  nameChangedAsync(): Promise<void>;
  changeDescription(description: string): void;
  descriptionChangedAsync(): Promise<void>;
  updateProjectDetailsAsync(): Promise<void>;
  createProjectAsync(projectId: string): Promise<void | StickerError>;
  updateImageAssignmentPolicyAsync(policy: ImageAssignmentPolicy): Promise<void>;

  validateNameAsync(): Promise<void>;
  validateDescription(): void;
  validateDatasets(): void;

  publishAsync(projectId: string, discardDrafts: boolean): Promise<boolean>;
  canPublishAsync(): Promise<boolean>;

  store: IProjectDetailsStore;
}

@injectable()
export class ProjectDetailsBl implements IProjectDetailsBl {
  constructor(
    @inject(ProjectDetailsStoreType) public readonly store: IProjectDetailsStoreSetter,
    @inject(ProjectDetailsStatisticsStoreType) private readonly statisticsStore: IProjectDetailsStatisticsStore,
    @inject(RouterStoreType) private readonly routeStore: IRouterStore,
    @inject(NotificationsServiceType) private readonly notificationsService: INotificationsService,
    @inject(ProjectDetailsApiServiceType) private readonly projectDetailsApiService: IProjectDetailsApiService,
    @inject(BillingServiceType) private readonly billingService: IBillingService,
    @inject(EventBusType) private readonly eventBus: IEventBus,
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore,
    @multiInject(ProjectDetailsSubmoduleType) private readonly submodules: IProjectDetailsSubmodule[],
  ) {}

  @action
  initialize(): void {
    this.submodules.forEach(x => x.initialize());
    this.resetStore();
  }

  @action
  handleCanPublishCheck(response: ICanPublishProjectResponse): void {
    const nameErrors = [];
    if (!response.isNameValid) nameErrors.push('field_cant_be_empty');
    if (!response.isNameUnique) nameErrors.push('error_project_name_must_be_unique');
    this.store.nameStatus = InputStatus.buildFrom(nameErrors);

    this.store.datasetsStatus = response.areDatasetsValid ? InputStatus.valid() : InputStatus.buildFrom(['no_dataset_added']);
    this.store.usersStatus = InputStatus.valid();
    this.store.descriptionStatus = InputStatus.valid();
    this.store.imagesStatus = InputStatus.valid();
  }

  @action
  resetStore(): void {
    this.store.nameStatus = InputStatus.empty();
    this.store.descriptionStatus = InputStatus.empty();
    this.store.datasetsStatus = InputStatus.empty();
    this.store.usersStatus = InputStatus.empty();
    this.store.imagesStatus = InputStatus.empty();
  }

  @action
  async validateAsync(): Promise<void> {
    await this.validateNameAsync();
    this.validateDescription();
    this.validateDatasets();
    this.validateUsers();
    this.validateImages();
  }

  getValidationErrors(): string[] {
    return [...this.store.nameStatus.errorCodes, ...this.store.descriptionStatus.errorCodes, ...this.store.datasetsStatus.errorCodes, ...this.store.usersStatus.errorCodes];
  }

  @action
  changeName(name: string) {
    this.store.name = name;
    this.store.nameStatus = InputStatus.empty();
  }

  @action
  changeDescription(description: string) {
    this.store.description = description;
    this.store.descriptionStatus = InputStatus.empty();
  }

  async nameChangedAsync(): Promise<void> {
    await this.validateNameAsync();

    if (this.store.nameStatus.isValid) {
      await this.updateProjectDetailsAsync();
    }
  }

  async descriptionChangedAsync(): Promise<void> {
    this.validateDescription();
    await this.updateProjectDetailsAsync();
  }

  @action
  validateDescription(): void {
    this.store.descriptionStatus = InputStatus.valid();
  }

  @action
  async validateNameAsync(): Promise<void> {
    const nameErrorCodes: string[] = [];

    if (this.store.name === '' && this.store.name.length === 0) {
      nameErrorCodes.push(ERROR_CODES.PROJECT_NAME_EMPTY);
    } else {
      const result = await this.projectDetailsApiService.checkProjectNameUniquenessAsync(this.store.id, this.currentWorkspaceStore.currentWorkspace!.id, this.store.name);

      if (result instanceof StickerError) {
        this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'project:name_validation_failed'));
        throw result;
      }

      if (!result) {
        nameErrorCodes.push(ERROR_CODES.PROJECT_NAME_NOT_UNIQUE);
      }
    }

    this.store.nameStatus = InputStatus.buildFrom(nameErrorCodes);
  }

  @action
  validateDatasets(): void {
    if (this.store.status === ProjectStatus.Published) return;
    this.store.datasetsStatus = this.store.datasets.length ? InputStatus.valid() : InputStatus.buildFrom(['no_dataset_added']);
  }

  @action
  async assignDatasetToProjectAsync(datasetId: string) {
    const result = await this.projectDetailsApiService.assignDatasetAsync(this.store.id, datasetId);
    this.notifyAboutApiError(result, 'project:assign_dataset_to_project_failed', true);
    await this.getProjectDetailsDatasetsAsync(this.store.id);
    this.validateDatasets();
  }

  @action
  async unlinkDatasetAsync(datasetId: string) {
    const result = await this.projectDetailsApiService.unlinkDatasetAsync(this.store.id, datasetId);
    this.notifyAboutApiError(result, 'project:unlink_dataset_failed');
    await this.getProjectDetailsDatasetsAsync(this.store.id);
    this.validateDatasets();
  }

  @action
  async getProjectDetailsAsync(projectId: string) {
    const result = await this.projectDetailsApiService.getProjectDetailsAsync(projectId);
    this.notifyAboutApiError(result, getProjectDetailsFailedMessage);
    if (result instanceof StickerError) return;

    if (result.workspaceId !== this.currentWorkspaceStore.currentWorkspace!.id) {
      this.routeStore.replace(Home.Projects.List.All.withParams({ workspaceId: this.currentWorkspaceStore.currentWorkspace!.id }));
      return;
    }

    this.store.id = projectId;
    this.store.name = result.name;
    this.store.description = result.description;
    this.store.lastAnnotationDate = result.lastAnnotationDate;
    this.store.status = result.status;
    this.store.hasImportedAnnotations = result.hasImportedAnnotations;

    this.store.imageAssignmentPolicy = result.imageAssignmentPolicy as ImageAssignmentPolicy;

    this.store.createdDate = result.createdDate;
    this.store.isCreatedBeforeStatsRelease = result.isCreatedBeforeStatsRelease;

    // TODO: This will be refactoring in statistics overhall
    this.statisticsStore.offset = result.offset;
    this.statisticsStore.statisticsGeneratedOn = result.statisticsGeneratedOn;
    const timezone = timeZones.find(tz => tz.value === this.statisticsStore.offset);
    if (timezone) {
      this.statisticsStore.selectedTimeZone = timezone;
    }
  }

  @action
  async getProjectDetailsDatasetsAsync(projectId: string) {
    const workspaceId = this.currentWorkspaceStore.currentWorkspace!.id;
    const result = await this.projectDetailsApiService.getProjectDetailsDatasetsAsync(projectId, workspaceId);
    this.notifyAboutApiError(result, 'project:get_project_datasets_failed');
    if (result instanceof StickerError) return result;

    this.store.datasets = result.projectDatasets;
    this.store.availableDatasets = result.availableDatasets;

    if (!this.store.datasetsStatus.isEmpty) {
      this.validateDatasets();
    }

    return result;
  }

  @action
  async updateProjectDetailsAsync() {
    this.store.isReadyForPublish = false;
    const payload = {
      name: this.store.name,
      description: this.store.description,
      projectId: this.store.id,
    };
    const result = await this.projectDetailsApiService.updateProjectDetailsAsync(payload);
    this.notifyAboutApiError(result, updateProjectDetailsFailedMessage);
    this.store.isReadyForPublish = true;
    if (result instanceof StickerError) return;
  }

  @action
  async addUserToProjectAsync(projectId: string, email: string, role: ProjectRole) {
    const result = await this.projectDetailsApiService.addUserToProjectAsync(projectId, email, role);
    this.notifyAboutApiError(result, 'project:adding_user_to_project_failed');
    if (result instanceof StickerError) return;

    await this.getProjectDetailsMembersAsync(projectId);
    this.validateUsers();
  }

  @action
  async removeUserFromProjectAsync(userId: string, projectId: string) {
    const result = await this.projectDetailsApiService.removeUserFromProjectAsync(userId, projectId);
    this.notifyAboutApiError(result, updateProjectDetailsFailedMessage);
    if (result instanceof StickerError) return;

    await this.getProjectDetailsMembersAsync(projectId);

    this.validateUsers();

    this.eventBus.sendEvent(new UserRemovedFromProject());
  }

  validateUsers() {
    if (this.store.status === ProjectStatus.Published) return;
    this.store.usersStatus = InputStatus.valid();
  }

  validateImages() {
    if (this.store.status === ProjectStatus.Published) return;
    this.store.imagesStatus = InputStatus.valid();
  }

  @action
  async getProjectDetailsMembersAsync(projectId: string) {
    const result = await this.projectDetailsApiService.getProjectDetailsMembersAsync(projectId);
    this.notifyAboutApiError(result, getProjectDetailsFailedMessage);
    if (result instanceof StickerError) return;

    this.store.id = projectId;
    this.store.users = result.users.map(u => ({
      ...u,
      joinDate: u.joinDate,
      lastLoginDate: u.lastLoginDate,
    }));
    this.store.usersPagination.totalCount = this.store.users.length;
    this.store.usersPagination.pageNumber = 1;
  }

  @action
  async publishAsync(projectId: string, discardDrafts: boolean): Promise<boolean> {
    const value = await this.projectDetailsApiService.publishAsync(projectId, discardDrafts);

    if (value instanceof StickerError && !(value instanceof ForbiddenError)) {
      if (value.withCode(['PROJECT_ALREADY_PUBLISHED'])) {
        this.notificationsService.push(new ToastNotification(NotificationLevel.WARNING, { template: 'project_is_already_published' }));
        this.routeStore.push(Home.Projects.Details.Overview.withParams({ projectId: this.store.id, workspaceId: this.currentWorkspaceStore.currentWorkspace!.id }));
      } else if (value.withCode(['PROJECT_DRAFT_NOT_FOUND'])) {
        this.notificationsService.push(new ToastNotification(NotificationLevel.WARNING, { template: 'project_not_found' }));
        this.routeStore.push(Home.Projects.List.All.withParams({ workspaceId: this.currentWorkspaceStore.currentWorkspace!.id }));
      } else {
        this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, { template: 'something_went_wrong' }));
      }
      return false;
    }
    this.cleanup();

    while (!this.billingService.billing.accountExists) {
      await this.billingService.getCreditsAsync();
    }

    return true;
  }

  @action
  async canPublishAsync(): Promise<boolean> {
    let circuitBreaker = 100;
    while (circuitBreaker && !this.store.isReadyForPublish) {
      circuitBreaker = -1;
      await delay(50);
    }

    const response = await this.projectDetailsApiService.canPublishAsync(this.store.id);
    if (response instanceof StickerError) return false;

    this.handleCanPublishCheck(response);
    this.submodules.forEach(x => x.handleCanPublishCheck(response));
    const errors = this.getValidationErrors().concat(...this.submodules.map(x => x.getValidationErrors()));
    return errors.length === 0;
  }

  @action
  cleanup(): void {
    this.resetStore();
    this.submodules.forEach(x => x.cleanup());
  }

  @action
  async createProjectAsync(projectId: string): Promise<void | StickerError> {
    const workspaceId = this.currentWorkspaceStore.currentWorkspace!.id;
    const result = await this.projectDetailsApiService.createProjectAsync(projectId, workspaceId);
    if (result instanceof StickerError && !(result instanceof ForbiddenError)) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'project:project_creating_failed'));
      return result;
    }
  }

  notifyAboutApiError(result: any, errorCode: string, dontShowBadRequest: boolean = false) {
    if (result instanceof StickerError && !(result instanceof ForbiddenError) && (!dontShowBadRequest || !result.isBadRequest())) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, errorCode));
    }
  }

  async changeDatasetOrderAsync(from: number, to: number): Promise<void> {
    const datasetId = this.store.datasets[from].id;
    this.store.reorderDatasets(datasetId, to);

    const result = await this.projectDetailsApiService.updateProjectDatasetsOrderAsync({
      projectId: this.store.id,
      datasetsOrders: this.store.datasets.map(x => ({ id: x.id, order: x.order })),
    });

    this.notifyAboutApiError(result, 'project:updating_project_dataset_failed', true);
  }

  async updateImageAssignmentPolicyAsync(policy: ImageAssignmentPolicy): Promise<void> {
    const result = await this.projectDetailsApiService.updateProjectImageAssignmentPolicy({
      policy,
      projectId: this.store.id,
    });

    this.store.imageAssignmentPolicy = policy;
    this.notifyAboutApiError(result, 'project:updating_project_dataset_failed', true);
  }

  @action
  usersPaginationChange(pageNumber: number, pageSize: number) {
    this.store.usersPagination.pageNumber = pageNumber;
    this.store.usersPagination.pageSize = pageSize;
  }

  @action
  usersSortChanged(orderBy: string, orderType: SortingDirection): void {
    this.store.usersOrderBy = orderBy;
    this.store.usersOrderDirection = orderType;
  }
}
