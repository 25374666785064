import { inject, injectable } from 'inversify';
import { action, observable } from 'mobx';
import Papa, { NODE_STREAM_INPUT } from 'papaparse';
import { v4 } from 'uuid';
import validator from 'validator';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { MAX_FILE_NAME_LENGTH } from '../../helpers/global.constants';
import { StickerError } from '../../models/error.model';
import { NotificationLevel, ToastNotification } from '../notifications/models/notification.model';
import { INotificationsService, NotificationsServiceType } from '../notifications/services/notifications.service';
import { IDatasetsDetailsStore } from './datasetsDetails.store';
import { isAllowedSize, isAllowedType, toMBytes } from './helpers/image.helpers';
import { IImagesFromUrlsService, IImageUrl, ImagesFromUrlsServiceType } from './imagesFromUrlsUpload.service';
import { IImageFile, IImagesUploaderService, ImagesUploaderServiceType } from './imagesUpload.service';
import { IRejectedFile } from './models/IRejectedFile';
import { RejectStatus } from './models/RejectStatus';

export const UpdateDatasetFilesServiceType = Symbol('UPDATE_DATASET_FILES_SERVICE');

export interface IUpdateDatasetFilesService {
  setup(datasetsStore: IDatasetsDetailsStore): void;
  addImagesToDataset(images: File[]): Promise<void>;
  getUrlsFromFileAsync(file: typeof NODE_STREAM_INPUT): void;
  rejectUrlsFile(): void;
  cleanImagesFromFile(): void;
  cleanImagesFromUrl(): void;
  datasetsStore: IDatasetsDetailsStore;
  isProcessingImages: boolean;
}

@injectable()
export class UpdateDatasetFilesService implements IUpdateDatasetFilesService {
  @observable
  datasetsStore!: IDatasetsDetailsStore;

  @observable
  isProcessingImages: boolean = false;

  imageProcessingCount: number = 0;
  acceptedImagesPlaceholder: IImageFile[] = [];
  rejectedImagesPlaceholder: IRejectedFile[] = [];

  constructor(
    @inject(NotificationsServiceType) private readonly notificationsService: INotificationsService,
    @inject(ImagesUploaderServiceType) private readonly imagesUploaderService: IImagesUploaderService,
    @inject(ImagesFromUrlsServiceType) private readonly imagesFromUrlsService: IImagesFromUrlsService,
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore,
  ) {}

  setup(datasetsStore: IDatasetsDetailsStore) {
    this.datasetsStore = datasetsStore;
  }

  @action.bound
  async addImagesToDataset(images: File[]): Promise<void> {
    this.startProcessingImagesFiles();
    const acceptedImages: IImageFile[] = [];
    const rejectedImages: IRejectedFile[] = [];

    const duplicateNames = await this.imagesUploaderService.getDuplicatedImageNamesAsync(
      this.datasetsStore.details.id,
      images.map(x => x.name).concat(this.acceptedImagesPlaceholder.map(x => x.file.name)),
    );

    for (const file of images) {
      if (duplicateNames.includes(file.name)) {
        rejectedImages.push({ file, status: RejectStatus.DUPLICATED_NAME });
        continue;
      }
      if (!isAllowedType(file)) {
        rejectedImages.push({ file, status: RejectStatus.TYPE });
        continue;
      }
      if (!isAllowedSize(file)) {
        rejectedImages.push({ file, status: RejectStatus.SIZE });
        continue;
      }

      acceptedImages.push({ file });
    }
    await this.endProcessingImagesFiles(acceptedImages, rejectedImages);
  }

  @action.bound
  async getUrlsFromFileAsync(stream: typeof NODE_STREAM_INPUT) {
    this.isProcessingImages = true;
    Papa.parse(stream, {
      complete: async (res: any) => await this.handleFileParseFinish(this.datasetsStore.details.id, res),
      error: this.handleFileParseError,
      skipEmptyLines: true,
    } as any);
  }

  @action.bound
  rejectUrlsFile() {
    this.datasetsStore.imagesToUploadFromUrl.clear();
    this.notificationsService.push(new ToastNotification(NotificationLevel.INFO, 'Wrong file type'));
  }

  @action.bound
  async handleFileParseFinish(datasetId: string, result: any) {
    this.datasetsStore.imagesToUploadFromUrl.clear();

    const urls = result.data.map((x: any) => x[0] as string) as string[];

    const duplicateUrls = await this.imagesUploaderService.getDuplicatedUrlsAsync(datasetId, urls);

    const data: IImageUrl[] = [];

    for (const x of result.data) {
      const url = x[0];
      const isUrlValid = validator.isURL(url);
      const isDuplicated = duplicateUrls.includes(url) || data.some(x => x.url === url);
      const isTooLong = url.length > MAX_FILE_NAME_LENGTH;

      let status: RejectStatus | undefined;

      if (!isUrlValid) {
        status = RejectStatus.INVALID_URL;
      } else if (isDuplicated) {
        status = RejectStatus.DUPLICATED_URL;
      } else if (isTooLong) {
        status = RejectStatus.URL_TOO_LONG;
      }

      data.push({
        url,
        isValid: status === undefined,
        id: v4(),
        rejectStatus: status,
      });
    }

    this.datasetsStore.imagesToUploadFromUrl.replace(data);

    const willExceeds = await this.imagesFromUrlsService.willExceedsImageLimit(
      this.currentWorkspaceStore.currentWorkspace!.id,
      data.filter(x => x.isValid).map(x => x.url),
    );

    if (willExceeds instanceof StickerError) {
      this.datasetsStore.imagePreValidationWasSuccessful = false;
    } else {
      this.datasetsStore.willExceedsUploadImageSizeLimit = willExceeds.willExceedsAvailableSpace;
      this.datasetsStore.imagePreValidationWasSuccessful = willExceeds.areAllSuccessful;
      this.datasetsStore.ownerPlan = willExceeds.ownerPlan;
    }
    this.isProcessingImages = false;
  }

  @action.bound
  handleFileParseError(error: any) {
    this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, error, 'File parse error'));
  }

  @action.bound
  cleanImagesFromFile() {
    this.datasetsStore.imagePreValidationWasSuccessful = true;
    this.datasetsStore.imagesToUploadFromFile.clear();
    this.datasetsStore.imagesRejectedFromFile.clear();
    this.imageProcessingCount = 0;
    this.acceptedImagesPlaceholder = [];
    this.rejectedImagesPlaceholder = [];
    this.isProcessingImages = false;
  }

  @action.bound
  cleanImagesFromUrl() {
    this.datasetsStore.imagePreValidationWasSuccessful = true;
    this.datasetsStore.imagesToUploadFromUrl.clear();
  }

  @action.bound
  startProcessingImagesFiles() {
    this.imageProcessingCount += 1;
    this.isProcessingImages = this.imageProcessingCount > 0;
    if (this.imageProcessingCount <= 1) {
      this.acceptedImagesPlaceholder = [...this.datasetsStore.imagesToUploadFromFile];
      this.rejectedImagesPlaceholder = [...this.datasetsStore.imagesRejectedFromFile];
    }
  }

  @action.bound
  async endProcessingImagesFiles(acceptedImages: IImageFile[], rejectedImages: IRejectedFile[]) {
    this.imageProcessingCount = this.imageProcessingCount > 0 ? this.imageProcessingCount - 1 : 0;
    this.acceptedImagesPlaceholder.push(...acceptedImages);
    this.rejectedImagesPlaceholder.push(...rejectedImages);
    if (this.imageProcessingCount <= 0) {
      this.isProcessingImages = false;
      this.datasetsStore.imagesToUploadFromFile.replace(this.acceptedImagesPlaceholder);
      this.datasetsStore.imagesRejectedFromFile.replace(this.rejectedImagesPlaceholder);
    }

    const willExceeds = await this.imagesUploaderService.willExceedsImageLimit(
      this.currentWorkspaceStore.currentWorkspace!.id,
      this.datasetsStore.imagesToUploadFromFile.length,
      toMBytes(this.datasetsStore.imagesToUploadFromFile.reduce((sum, current) => sum + current.file.size, 0)),
    );

    this.datasetsStore.willExceedsUploadImageSizeLimit = willExceeds.willExceedsAvailableSpace;
    this.datasetsStore.imagePreValidationWasSuccessful = willExceeds.areAllSuccessful;
    this.datasetsStore.ownerPlan = willExceeds.ownerPlan;
  }
}
