import { CustomInput, FormGroup } from 'reactstrap';

import { ClampLines } from './ClampLines';
import React from 'react';
import { dinduNuffin } from '../helpers/function.helpers';

interface IProps {
  id: string;
  label: string;
  onClick: () => void;
  checked: boolean;
}

export class RadioInput extends React.Component<IProps> {
  render() {
    return (
      <FormGroup check>
        <CustomInput id={`radio-${this.props.id}`} type="radio" onClick={this.props.onClick} checked={this.props.checked} onChange={dinduNuffin}>
          <ClampLines onClick={this.props.onClick} id={`radio-label-${this.props.id}`} text={this.props.label} innerElement="p" />
        </CustomInput>
      </FormGroup>
    );
  }
}
