import { DashboardContainer } from '../../../containers/DashboardContainer';
import { ProjectDetailsHeaderContainer } from '../../../modules/projectDetails/containers/ProjectDetailsHeader.container';
import { ProjectDetailsRoute } from '../../../routes/projects/projectDetails.route';
import React from 'react';

const HeaderContainer = () => (
  <ProjectDetailsHeaderContainer />
);

export const ProjectDetailsView = () => (
    <DashboardContainer header={HeaderContainer}>
      <ProjectDetailsRoute />
    </DashboardContainer>
);
