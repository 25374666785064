import * as React from 'react';

import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { handleNonZeroIntegerChange, nonZeroIntegerRegex } from '../../../helpers/formHelpers';

import { IPlanInfo } from '../../user/user.store';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { ValidatedTextInput } from '../../../components/ValidatedTextInput';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IAddCreditsModalProps {
  addCreditsModalShown: boolean;
  planInfo: IPlanInfo;
  creditsToAdd: string;
  onAddCreditsClicked(id: string): void;
  onCreditsChange(value: string): void;
  onAddCreditsConfirmClicked(): void;
}

const AddCreditsModalPure = (props: IAddCreditsModalProps & ITranslatable) => {
  if (!props.planInfo) return null;

  const handleToggle = () => props.onAddCreditsClicked('');

  return (
    <Modal className="modal-width-600" isOpen={props.addCreditsModalShown} toggle={handleToggle}>
      <ModalHeader toggle={handleToggle}>{props.t('add_credits_modal_header')}</ModalHeader>
      <Form className="custom-inline-form">
        <ModalBody>
          <div>
            <Row>
              <Col>
                <ValidatedTextInput
                  type="text"
                  value={props.creditsToAdd}
                  labelText={props.t('add_credits_label')}
                  placeholder=""
                  onChange={handleNonZeroIntegerChange(props.onCreditsChange)}
                />
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button id="change-button" color="success" className="mr-2" onClick={props.onAddCreditsConfirmClicked} disabled={!props.creditsToAdd.match(nonZeroIntegerRegex)}>
            {props.t('add')}
          </Button>
          <Button id="change-button-cancel" color="primary" className="btn-outline-primary" onClick={handleToggle}>
            {props.t('cancel')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export const AddCreditsModal = as<React.SFC>(withNamespaces('common', { wait: true })(AddCreditsModalPure));
