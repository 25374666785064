import { BillingServiceType, IBillingService } from '../modules/billing/billing.service';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { IUserService, UserServiceType } from '../modules/user/user.service';
import { as, injectProps } from '../helpers/react.helpers';

import { ITranslatable } from '../helpers/translations.helpers';
import React from 'react';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IOuterProps {
  disabledPropsName?: string;
  disabled?: boolean;
  title?: string;
}

interface IInjectedProps {
  billingService: IBillingService;
  currentWorkspaceStore: ICurrentWorkspaceStore;
  userService: IUserService;
}

type Props = IOuterProps & IInjectedProps & ITranslatable;

@injectProps({
  billingService: BillingServiceType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  userService: UserServiceType,
})
@observer
class DisabledWhenPolicyExceededPure extends React.Component<Props> {
  render() {
    const isAnyPolicyLimitExceeded = this.props.billingService.billing.isAnyPolicyLimitExceeded;
    const encryption = this.props.currentWorkspaceStore.currentWorkspace?.encryption;
    const isEncryptionDisabled = encryption?.encrypted && !encryption?.encryptionAllowed;
    const disabledPropsName: string = this.props.disabledPropsName ? this.props.disabledPropsName : 'disabled';

    return React.Children.map(this.props.children, (child: any) => {
      const props: any = {
        title: isAnyPolicyLimitExceeded
          ? this.props.t('policy_limit_exceeded_block')
          : isEncryptionDisabled
          ? this.props.t('encryption_unavailable_block')
          : this.props.title || child.props.title,
      };
      props[disabledPropsName] = child.props.disabled || isAnyPolicyLimitExceeded || isEncryptionDisabled || this.props.disabled;
      return React.cloneElement(child, props);
    });
  }
}

export const DisabledWhenPolicyExceeded = as<React.ComponentClass<IOuterProps>>(withNamespaces('common', { wait: true })(DisabledWhenPolicyExceededPure));
