import { S_ColorPicker, S_ColorPickerProps } from '../../pickers/colorPicker/S_ColorPicker';

import React from 'react';
import { S_FieldLabel } from '../../labels/fieldLabel/S_FIeldLabel';

interface S_ColorFieldProps extends S_ColorPickerProps {
  labelText: string;
  disabled?: boolean;
}

export const S_ColorField: React.FC<S_ColorFieldProps> = (props: S_ColorFieldProps) => {
  return (
    <div>
      <S_FieldLabel text={props.labelText} />
      <S_ColorPicker {...props} />
    </div>
  );
};
