import * as React from 'react';

import { AuthServiceType, IAuthService } from '../auth.service';
import { AuthStoreType, IAuthStore } from '../auth.store';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { IStorageService, StorageServiceType } from '../../../services/storage.service';
import { IUserService, UserServiceType } from '../../user/user.service';
import { IUserStore, UserStoreType } from '../../user/user.store';
import { IWorkspaceService, WorkspaceServiceType } from '../../workspaces/workspaces.service';
import { InputStatus, StickerError } from '../../../models/error.model';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { AuthLinkCard } from '../components/AuthLinkCard';
import { AuthLogo } from '../components/AuthLogo';
import { GLOBAL_ERROR_CODES } from '../../../helpers/global.constants';
import { LoginForm } from '../components/LoginForm';
import autobind from 'autobind-decorator';
import { getEmailStatus } from '../../../helpers/email.helpers';
import qs from 'qs';

interface IInjectedProps extends RouteComponentProps {
  authService: IAuthService;
  authStore: IAuthStore;
  userService: IUserService;
  userStore: IUserStore;
  storageService: IStorageService;
  workspaceService: IWorkspaceService;
}

interface IState extends ILoaderState {
  email: string;
  password: string;
  isAuthenticated: boolean;
  emailStatus: InputStatus;
  passwordStatus: InputStatus;
  externalStatus: InputStatus;
  isEmailLocked: boolean;
  lockoutTime: number;
}

@injectProps({
  authService: AuthServiceType,
  authStore: AuthStoreType,
  userStore: UserStoreType,
  userService: UserServiceType,
  storageService: StorageServiceType,
  workspaceService: WorkspaceServiceType,
})
@autobind
class LoginContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  constructor(props: IInjectedProps) {
    super(props);

    this.state = {
      email: '',
      password: '',
      isAuthenticated: this.props.authStore.isAuthenticated,
      emailStatus: InputStatus.empty(),
      passwordStatus: InputStatus.empty(),
      externalStatus: InputStatus.empty(),
      isEmailLocked: false,
      isLoading: false,
      lockoutTime: 0,
    };

    this.presetUrlData();
  }

  lockoutInterval?: NodeJS.Timeout;

  async componentDidMount() {
    this.setupLockoutWarning();
  }

  componentWillUnmount() {
    this.clearLockoutInterval();
  }

  handleEmailChange = (value: string) => {
    this.setState({
      email: value,
      emailStatus: InputStatus.empty(),
      externalStatus: InputStatus.empty(),
    });
  };

  handlePasswordChange = (value: string) => {
    this.setState({
      password: value,
      passwordStatus: InputStatus.empty(),
      externalStatus: InputStatus.empty(),
    });
  };

  validateEmail(value: string) {
    const emailStatus = getEmailStatus(value, [], false);
    this.setState({ emailStatus });
    return emailStatus.isValid;
  }

  validatePassword(value: string) {
    const errorCodes: string[] = [];
    if (!value) {
      errorCodes.push('field_cant_be_empty');
    }
    const status = InputStatus.buildFrom(errorCodes);
    this.setState({ passwordStatus: status });
    return status.isValid;
  }

  async handleLogin() {
    this.setState({
      externalStatus: InputStatus.empty(),
    });
    const isEmailValid = this.validateEmail(this.state.email);
    const isPasswordValid = this.validatePassword(this.state.password);

    if (!(isEmailValid && isPasswordValid)) {
      return;
    }

    const result = await this.withLoaderAsync(
      () =>
        this.props.authService.authenticate({
          userName: this.state.email,
          password: this.state.password,
        }),
      'login-button',
    );

    if (result instanceof StickerError) {
      let errorCodes: string[] = (result.apiErrorResponse && result.apiErrorResponse.errorCodes) || [];

      if (errorCodes.includes(GLOBAL_ERROR_CODES.EMAIL_NOT_CONFIRMED)) {
        this.props.history.push(`/resendConfirmationEmail?email=${encodeURIComponent(this.state.email)}`);
        return true;
      }

      if (errorCodes.includes('USER_LOCKED_OUT')) {
        this.props.authService.setLockoutDate(result.apiErrorResponse?.errorContext.lockoutDate);
        this.setupLockoutWarning();
        errorCodes = [];
      }

      this.setState({
        externalStatus: new InputStatus(false, errorCodes),
      });
    } else {
      await this.withLoaderAsync(async () => {
        await this.props.userService.getUserInfoAsync();
        await this.props.userService.getUserSettingsAsync();
      }, 'login-button');

      this.props.storageService.initializeCryptoLocalStorage();
      this.presetUrlData();
      this.setState({ isAuthenticated: true });
    }
  }

  setupLockoutWarning() {
    if (this.props.authStore.lockoutDate !== undefined) {
      const currentDate = new Date();
      const parsedDate = new Date(this.props.authStore.lockoutDate);
      const timeDifference = currentDate >= parsedDate ? 0 : Math.ceil((parsedDate.getTime() - currentDate.getTime()) / 1000);
      this.setState({ lockoutTime: timeDifference });

      this.lockoutInterval = setInterval(() => {
        const time = this.state.lockoutTime - 1;
        this.setState({ lockoutTime: time });
        if (time === 0) {
          this.clearLockoutInterval();
          this.props.authService.setLockoutDate(undefined);
        }
      }, 1000);
    }
  }

  clearLockoutInterval() {
    if (this.lockoutInterval) {
      clearInterval(this.lockoutInterval);
      this.lockoutInterval = undefined;
    }
  }

  presetUrlData() {
    const searchParams = qs.parse(this.props.location.search.slice(1));
    if (searchParams.workspaceId !== undefined) {
      this.props.workspaceService.setPreferredWorkspace(searchParams.workspaceId.toLowerCase());
    }

    this.props.authService.setRouteReferrer(this.props.location.state);
  }

  render() {
    if (this.state.isAuthenticated) {
      return <Redirect to={this.props.authService.getAndClearReferrer()} />;
    }

    return (
      <>
        <AuthLogo />
        <LoginForm
          handleEmailChange={this.handleEmailChange}
          handlePasswordChange={this.handlePasswordChange}
          validateEmail={this.validateEmail}
          validatePassword={this.validatePassword}
          handleLogin={this.handleLogin}
          emailStatus={this.state.emailStatus}
          passwordStatus={this.state.passwordStatus}
          externalStatus={this.state.externalStatus}
          email={this.state.email}
          isEmailLocked={this.state.isEmailLocked}
          isLoginInProgress={this.state.isLoading}
          lockoutTime={this.state.lockoutTime}
        />
        <AuthLinkCard text="no_account" textWithLink="no_account_with_link" link="/register" withQueryString={true} />
      </>
    );
  }
}

export const LoginContainer = as<React.ComponentClass>(withRouter(LoginContainerPure));
