import { DevOptions } from './config.dev';
import { ProdOptions } from './config.prod';
import { injectable } from 'inversify';

declare var process: {
  env: {
    NODE_ENV: string;
    PLATFORM_ENV: string;
    CI_COMMIT_REF_SLUG: string;
  };
};

export const ConfigurationType = Symbol('CONFIGURATION');

export interface IAppConfig {
  readonly enableCaptcha: boolean;
  readonly captchaSecret: string;
  readonly imageUploadBaseUrl: string;
  readonly trainingApiBaseUrl: string;
  readonly userAccountDeletionDelayDayCount: number;
  readonly concurrentUploadsCount: number;
  readonly supportEmailAddress: string;
  readonly imagePreloadRange: number;
  readonly shopifyConfig: IShopifyConfig;
  readonly reLockInterval: number;
  readonly concurrentAttachmentUploadsCount: number;
  readonly runLocally: boolean;
  readonly newStatisticsDate: string;
}

export interface IShopifyConfig {
  readonly domain: string;
  readonly accessToken: string;
  readonly creditProductHandle: string;
  readonly enabled: boolean;
}

export interface IConfiguration {
  readonly version: string;
  readonly baseUrl: string;
  readonly hubUrl: string;
  readonly projectHubUrl: string;
  readonly uploadsHubUrl: string;
  readonly appConfig: IAppConfig;
  setupAppConfig(appConfig: IAppConfig): void;
}

@injectable()
export class Configuration implements IConfiguration {
  version: string;
  baseUrl: string;
  hubUrl: string;
  projectHubUrl: string;
  uploadsHubUrl: string;
  appConfig!: IAppConfig;

  constructor() {
    let configuration;
    if (process.env.NODE_ENV === 'development') {
      configuration = { ...DevOptions };
    } else {
      configuration = { ...ProdOptions };
    }

    this.version = configuration.version;
    this.baseUrl = configuration.baseUrl;
    this.hubUrl = configuration.hubUrl;
    this.projectHubUrl = configuration.projectHubUrl;
    this.uploadsHubUrl = configuration.uploadsHubUrl;
  }

  setupAppConfig(appConfig: IAppConfig) {
    this.appConfig = appConfig;
  }
}
