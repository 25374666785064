import { Polygon, PolygonProps } from 'react-leaflet';

import { ISegmentationPolygonProps } from '../SegmentationLayerFeatures';
import React from 'react';

interface IProps {
  isActive: boolean;
}

export class PolygonFeature extends React.Component<ISegmentationPolygonProps & PolygonProps & IProps> {
  handleRef = (element: any) => {
    if (this.props.isActive && element) {
      element.leafletElement.enableEdit();
    }
  }

  render() {
    return <Polygon<ISegmentationPolygonProps & PolygonProps> {...this.props} ref={this.handleRef} />;
  }
}
