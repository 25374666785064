import { EditMarkingToolBl, EditMarkingToolBlType } from '../../EditMarkingTool.bl';
import { EditMarkingToolPermissions, EditMarkingToolPermissionsType } from '../../EditMarkingTool.permissions';
import { EditMarkingToolStore, EditMarkingToolStoreType } from '../../EditMarkingTool.store';
import { as, injectProps } from '../../../../../../../../__legacy__/helpers/react.helpers';

import { ColorField } from '../../../commons/ui/fields/ColorField';
import React from 'react';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: EditMarkingToolBl;
  store: EditMarkingToolStore;
  permissions: EditMarkingToolPermissions;
}

@injectProps({
  bl: EditMarkingToolBlType,
  store: EditMarkingToolStoreType,
  permissions: EditMarkingToolPermissionsType
})
@observer
class ColorFieldContainerPure extends React.Component<InjectedProps> {
  render() {
    return (
      <ColorField
        color={this.props.store.color}
        onChangeColor={this.props.bl.changeColor}
        disabled={!this.props.permissions.canChangeColor}
      />
    );
  }
}

export const ColorFieldContainer = as<React.ComponentClass>(ColorFieldContainerPure);
