import * as React from 'react';

import { OverlayLoaderStore, OverlayLoaderStoreType } from '../../modules/common/OverlayLoader.store';

import { container } from '../diContainer';
import { v4 } from 'uuid';

export interface ILoaderState {
  isLoading: boolean;
}

export function withLoader(component: React.Component<any, ILoaderState>) {
  return async function (target: Function): Promise<void> {
    component.setState({ isLoading: true });
    try {
      await target.apply(component);
    } finally {
      component.setState({ isLoading: false });
    }
  };
}

export abstract class WithLoaderComponentBase<P, S extends ILoaderState = { isLoading: false }> extends React.Component<P, S> {

  loaderStore: OverlayLoaderStore;

  constructor(props: P) {
    super(props);
    this.loaderStore = container.get<OverlayLoaderStore>(OverlayLoaderStoreType);
    this.state = {
      isLoading: false,
    } as any;
  }

  loaders: Map<string, { cancelFunction: Function, buttonSpinnersKey?: string }> = new Map();

  async withLoaderAsync<T>(asyncFunction: Function, buttonSpinnersKey?: string): Promise<T> {
    const id = v4();
    let cancelled = false;
    this.loaders.set(id, { buttonSpinnersKey, cancelFunction: () => (cancelled = true) });

    this.setState({ isLoading: true });
    if (buttonSpinnersKey && this.loaderStore) this.loaderStore.enableLoader(buttonSpinnersKey);

    const result: T = await asyncFunction();

    if (!cancelled) {
      this.setState({ isLoading: false });
      if (buttonSpinnersKey && this.loaderStore) this.loaderStore.disableLoader(buttonSpinnersKey);
    }

    this.loaders.delete(id);
    return result;
  }

  clearLoaders() {
    this.loaders.forEach((data) => {
      data.cancelFunction();
      if (data.buttonSpinnersKey && this.loaderStore) this.loaderStore.disableLoader(data.buttonSpinnersKey);
    });
  }

  componentWillUnmount(): void {
    this.clearLoaders();
  }
}

export function autoLoader(buttonSpinnersKey?: string) {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const method = descriptor.value;
    descriptor.value = function (...args: any[]) {
      return (this as any).withLoaderAsync(() => method.apply(this, args), buttonSpinnersKey);
    };
  };
}
