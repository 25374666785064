import * as React from 'react';

import { NewStatisticsWarningRowContainer } from '../../../../modules/common/NewStatisticsWarningRow.container';
import { UsersFormContainer } from '../../../modules/projectDetails/containers/UsersForm.container';
import { UsersListContainer } from '../../../modules/projectDetails/containers/UsersList.container';

export const ProjectDetailsCollaboratorsView = () => (
  <div>
    <NewStatisticsWarningRowContainer />
    <UsersFormContainer />
    <UsersListContainer />
  </div>
);
