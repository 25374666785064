import React from 'react';

export interface IOverlayLoaderProps {
  isVisible: boolean;
}

export class OverlayLoader extends React.Component<IOverlayLoaderProps> {
  render() {
    return this.props.isVisible &&
      (
        <span>
          <div className="button-with-loader-overlay left" />
          <div className="button-with-loader-overlay right" />
        </span>
      );
  }
}
