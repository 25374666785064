import React from 'react';
import { withNamespaces } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import { Card, CardBody } from 'reactstrap';
import { as } from '../../../../helpers/react.helpers';
import { ITranslatable } from '../../../../helpers/translations.helpers';
import { ConfirmationStatus } from '../../containers/ConfirmEmailContainer';

interface IProps {
  confirmationStatus: ConfirmationStatus;
  onActivateAccount(): void;
  onGoToLoginClick(): void;
}

type Props = ITranslatable & IProps;

class ConfirmNewEmailPure extends React.Component<Props> {
  componentDidMount() {
    this.props.onActivateAccount();
  }

  render() {
    const { t } = this.props;

    return (
      <div className="account-activation">
        <Card>
          <CardBody>
            <p className="header">
              {this.props.confirmationStatus === 'InProgress' && t('confirming_email')}
              {this.props.confirmationStatus === 'Success' && t('email_confirmation_success')}
              {this.props.confirmationStatus === 'Failed' && t('email_confirmation_failed')}
            </p>
            <hr />
            <div className="content text-center">
              {this.props.confirmationStatus === 'InProgress' && <ClipLoader color="#4C516D" size={48} />}
              {this.props.confirmationStatus === 'Success' &&
                (
                  <div>
                    {t('email_confirmation_success_description')}
                    <a href="#" onClick={this.props.onGoToLoginClick}>{t('here')}</a>.
                  </div>
                )}
              {this.props.confirmationStatus === 'Failed' && t('email_confirmation_failed_description')}
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export const ConfirmNewEmail = as<React.SFC>(withNamespaces('auth')(ConfirmNewEmailPure));
