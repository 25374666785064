import React from 'react';
import { DashboardContainer } from '../../containers/DashboardContainer';
import { EvaluationsHeader } from '../../../modules/evaluations/containers/EvaluationsHeader';
import EvaluationsListContainer from '../../../modules/evaluations/containers/EvaluationsList.container';

export const EvaluationsView = () => {
  return (
    <div>
      <DashboardContainer header={EvaluationsHeader}>
        <EvaluationsListContainer />
      </DashboardContainer>
    </div>
  );
};
