// tslint:disable:function-name

import L, { LatLngBounds, LatLngTuple } from 'leaflet';
import { MapLayer, MapLayerProps, withLeaflet } from 'react-leaflet';

import autobind from 'autobind-decorator';

interface Props extends MapLayerProps {
  attribution?: string;
  className?: string;
  bounds: LatLngTuple[];
  opacity: number;
  url: string;
  zIndex: number;
}

export class ImageOverlayEx extends L.ImageOverlay {
  public _map!: L.Map;

  private defaultClassNames: string[] = [];

  _initImage() {
    this._image = new Image();
    this.defaultClassNames.push('leaflet-image-layer');
    if (this._zoomAnimated) {
      this.defaultClassNames.push('leaflet-zoom-animated');
    }

    this._image.onload = this.handleOnLoad;

    this.setClassName(this.options.className);

    if (this.options.zIndex) {
      this._updateZIndex();
    }

    this._image.src = this._url;
  }

  setUrl(url: string) {
    this._url = url;

    if (this._image) {
      this._image.src = '';
      this._image.src = url;
    }
    return this;
  }

  setClassName(className?: string) {
    const classNames = [...this.defaultClassNames, className];
    this._image.setAttribute('class', classNames.filter(x => x !== undefined).join(' '));

    return this;
  }

  @autobind
  async handleOnLoad() {
    this.fire('load');
  }
}

class CustomImageOverlay extends MapLayer<Props, ImageOverlayEx> {
  createLeafletElement(props: Props): ImageOverlayEx {
    const element = new ImageOverlayEx(
      props.url,
      props.bounds,
      this.getOptions(props),
    );
    this.contextValue = { ...props.leaflet, popupContainer: element };
    return element;
  }

  updateLeafletElement(fromProps: Props, toProps: Props) {
    if (toProps.url !== fromProps.url) {
      this.leafletElement.setUrl(toProps.url);
    }
    if (toProps.bounds && toProps.bounds !== fromProps.bounds) {
      this.leafletElement.setBounds(new LatLngBounds(toProps.bounds));
    }
    if (toProps.opacity !== fromProps.opacity) {
      this.leafletElement.setOpacity(toProps.opacity);
    }
    if (toProps.zIndex !== fromProps.zIndex) {
      this.leafletElement.setZIndex(toProps.zIndex);
    }
    if (toProps.className !== fromProps.className) {
      this.leafletElement.setClassName(toProps.className);
    }
  }
}

export default withLeaflet(CustomImageOverlay);
