import { ContainerModule, interfaces } from 'inversify';
import { CurrentWorkspaceBl, CurrentWorkspaceBlImpl, CurrentWorkspaceBlType } from './CurrentWorkspace.bl';
import { CurrentWorkspaceNavigator, CurrentWorkspaceNavigatorImpl, CurrentWorkspaceNavigatorType } from './services/navigator/CurrentWorkspaceNavigator';
import {
  CurrentWorkspaceStore,
  CurrentWorkspaceStoreSetterType,
  CurrentWorkspaceStoreType,
  ICurrentWorkspaceStore,
  ICurrentWorkspaceStoreSetter,
} from './CurrentWorkspace.store';

export const currentWorkspaceModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<ICurrentWorkspaceStore>(CurrentWorkspaceStoreType).to(CurrentWorkspaceStore).inSingletonScope();
  bind<ICurrentWorkspaceStoreSetter>(CurrentWorkspaceStoreSetterType).toService(CurrentWorkspaceStoreType);
  bind<CurrentWorkspaceBl>(CurrentWorkspaceBlType).to(CurrentWorkspaceBlImpl).inSingletonScope();
  bind<CurrentWorkspaceNavigator>(CurrentWorkspaceNavigatorType).to(CurrentWorkspaceNavigatorImpl).inSingletonScope();
});
