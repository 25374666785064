import { AnnotationToolType } from '../../../annotation/submodules/annotationTypes/models/annotationToolType';
import { QuestionType } from '../../../annotation/question.model';

export const MAX_TEXT_LENGTH = 1000;

export const MIN_ANSWERS_COUNT = 2;

export interface IAnnotationType {
  id: string;
  name: string;
  color: string;
  selectorType: AnnotationToolType;
  isUsed: boolean;
  order: number;
}

export interface IQuestion {
  id: string;
  type: QuestionType;
  isRequired: boolean;
  questionText: string;
  answers: IAnswer[];
  scopes: string[];
  isUsed: boolean;
  order: number;
}

export interface IAnswer {
  id: string;
  text: string;
}

export interface IToolUsage {
  toolId: string;
  occurrenceCount: number;
  imageCount: number;
}

export interface IToolAffect {
  toolId: string;
  objectsCount: number;
  questionsCount: number;
  attributesCount: number;
}
