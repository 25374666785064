import { IProjectDetailsBl, ProjectDetailsBlType } from '../projectDetails.bl';
import { as, injectProps } from '../../../helpers/react.helpers';

import { ProjectStatus } from '../../projects/projects.model';
import { PublishedProjectInfo } from '../components/PublishedProjectInfo';
import React from 'react';
import { observer } from 'mobx-react';

interface IInjectedProps {
  projectDetailsBl: IProjectDetailsBl;
}

@injectProps({ projectDetailsBl: ProjectDetailsBlType })
@observer
class PublishedProjectInfoContainerPure extends React.Component<IInjectedProps> {
  render() {
    const showPublishedAlert = this.props.projectDetailsBl.store.status === ProjectStatus.Published;
    return (
      <PublishedProjectInfo showPublishedAlert={showPublishedAlert} />
    );
  }
}

export const PublishedProjectInfoContainer = as<React.ComponentClass>(PublishedProjectInfoContainerPure);
