import * as React from 'react';

import { AttachmentsPreviewServiceType, IAttachmentsPreviewService } from '../attachmentsPreview.service';
import { AttachmentsServiceType, IAttachmentsService } from '../attachments.service';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { IReactionDisposer, action, reaction } from 'mobx';
import { IRouterStore, RouterStoreType } from '../../../stores/router.store';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { ApiServiceType } from '../../../services/api.service';
import { AttachmentDetailsPreviewHeader } from '../components/AttachmentDetailsPreviewHeader';
import { Home } from '../../../routes/config/Home';
import { IApiService } from '../../../services/api.service.base';
import { StickerError } from '../../../models/error.model';
import { WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { observer } from 'mobx-react';
import { toMBytes } from '../../datesets/helpers/image.helpers';

export interface IInjectedProps extends RouteComponentProps<{ workspaceId: string; attachmentId: string; parentId?: string }> {
  apiService: IApiService;
  routerStore: IRouterStore;
  previewService: IAttachmentsPreviewService;
  attachmentsService: IAttachmentsService;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

interface IState {
  total: number;
  name: string;
  current: number;
  previousId: string;
  nextId: string;
  isLoading: boolean;
  imageSize: number;
  imageWidth: number;
  imageHeight: number;
  createdDate: string;
  id: string;
  parentId: string;
}

@injectProps({
  apiService: ApiServiceType,
  routerStore: RouterStoreType,
  previewService: AttachmentsPreviewServiceType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  attachmentsService: AttachmentsServiceType,
})
@observer
class AttachmentsPreviewHeaderContainer extends WithLoaderComponentBase<IInjectedProps, IState> {
  imageChangeReactionDisposer: IReactionDisposer;

  constructor(props: IInjectedProps) {
    super(props);
    this.imageChangeReactionDisposer = reaction(
      () => this.props.match.params.attachmentId,
      () => this.showImageAsync(),
    );

    this.state = {
      total: 0,
      name: '',
      current: 0,
      previousId: '',
      nextId: '',
      isLoading: false,
      imageSize: 0,
      imageHeight: 0,
      imageWidth: 0,
      createdDate: '',
      id: '',
      parentId: ''
    };
  }

  async componentDidMount() {
    await this.showImageAsync();
  }

  componentWillUnmount() {
    this.imageChangeReactionDisposer();
  }

  @action.bound
  async showImageAsync() {
    const canView = this.props.attachmentsService.canUseAttachments();
    if (!canView) this.props.routerStore.push(Home.Attachments.List.Folder.withParams({ workspaceId: this.props.currentWorkspaceStore.currentWorkspace!.id, attachmentId: '' }));

    const data = this.props.previewService.data;
    const previewPaging = data.previewPaging;
    const attachmentId = this.props.match.params.attachmentId?.toLowerCase();
    const parentId = this.props.match.params.parentId?.toLowerCase() || '';
    const workspaceId = this.props.match.params.workspaceId.toLowerCase() || '';

    data.isPreviewImageLoading = true;
    data.lowQualityImagePreviewUrl = '';
    data.imagePreviewUrl = '';

    const [paging, image] = await Promise.all([
      this.props.previewService.getPreviewPagingAsync(workspaceId, parentId, previewPaging.id === parentId ? previewPaging.checkDate : ''),
      this.props.previewService.getImageInfoAsync(attachmentId),
    ]);

    if (image instanceof StickerError || paging instanceof StickerError) return;

    const oldPaging = { ...previewPaging };
    if (paging.wasChanged) {
      previewPaging.id = paging.id || '';
      previewPaging.checkDate = paging.checkDate;
      previewPaging.items = paging.items;
      previewPaging.checkDate = paging.checkDate;
    }

    let currentItemIndex = 1;
    const currentItem = previewPaging.items.find(x => x.imageId === image.id);
    if (currentItem !== undefined) {
      currentItemIndex = currentItem.index;
    } else {
      const previousItem = oldPaging.items.find(x => x.imageId === image.id);
      if (previousItem !== undefined) {
        const newImage = previewPaging.items.find(x => x.index === previousItem.index);
        if (newImage !== undefined) {
          this.props.routerStore.push(Home.Attachments.List.Preview.withParams({ workspaceId, attachmentId: newImage.imageId }));
          return;
        }
        if (previewPaging.items.length > 0) {
          const newImage = previewPaging.items[previewPaging.items.length - 1];
          this.props.routerStore.push(Home.Attachments.List.Preview.withParams({ workspaceId, attachmentId: newImage.imageId }));
          return;
        }
      }
      this.props.routerStore.push(Home.Attachments.Path);
      return;
    }

    const next = previewPaging.items.find(x => x.index === currentItemIndex + 1);
    const previous = previewPaging.items.find(x => x.index === currentItemIndex - 1);

    await this.props.previewService.showImageAsync(attachmentId, image.size);

    this.setState({
      total: previewPaging.items.length,
      name: image.name,
      current: currentItemIndex,
      previousId: previous ? previous.imageId : '',
      nextId: next ? next.imageId : '',
      imageSize: image.size,
      imageHeight: image.height,
      imageWidth: image.width,
      createdDate: image.createdDate,
      id: image.id,
      parentId: image.parentId
    });
  }

  render() {
    const data = this.props.previewService.data;
    const parentId = this.props.match.params.parentId || '';

    return (
      <AttachmentDetailsPreviewHeader
        channels={data.imageAttributes?.channels || 0}
        closeButtonLink={Home.Attachments.List.Folder.withParams({ workspaceId: this.props.match.params.workspaceId, attachmentId: parentId })}
        createdDate={this.state.createdDate}
        current={this.state.current}
        downloadSpeed={data.imageAttributes?.downloadSpeed}
        id={this.state.id}
        imageHeight={this.state.imageHeight}
        imageSize={toMBytes(this.state.imageSize)}
        imageWidth={this.state.imageWidth}
        isCached={data.imageAttributes?.isCached || false}
        isHighResImageLoading={data.imagePreviewUrl === ''}
        isLoading={data.isPreviewImageLoading}
        name={this.state.name}
        nextLink={Home.Attachments.List.Preview.withParams({ parentId, workspaceId: this.props.match.params.workspaceId, attachmentId: this.state.nextId })}
        parentId={this.state.parentId}
        previousLink={Home.Attachments.List.Preview.withParams({ parentId, workspaceId: this.props.match.params.workspaceId, attachmentId: this.state.previousId })}
        total={this.state.total}
      />
    );
  }
}

export default as<React.ComponentClass>(withRouter(AttachmentsPreviewHeaderContainer));
