import { AnnotationConfirmationModal, AnnotationConfirmationModalMode } from '../../../components/AnnotationConfirmationModal';
import { AnnotationReviewServiceType, IAnnotationReviewService } from '../annotationReview.service';
import { AnnotationsStoreType, IAnnotationsStore } from '../annotations.store';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { IReviewRejectReasonsStore, ReviewRejectReasonsStoreType } from '../submodules/reviewRejectReasones/reviewRejectReasons.store';
import { IWorkspaceService, WorkspaceServiceType } from '../../workspaces/workspaces.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { EditorMode } from '../../../../modules/editor/models/EditorModes';
import { GlobalHotKeys } from 'react-hotkeys';
import React from 'react';
import { ReviewNavigation } from '../components/ReviewNavigation';
import { SaveBeforeRejectConfirmationModal } from '../../../components/SaveBeforeRejectConfirmationModal';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import autobind from 'autobind-decorator';
import { dinduNuffin } from '../../../helpers/function.helpers';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ projectId: string; workspaceId: string }> {
  annotationReviewService: IAnnotationReviewService;
  workspaceService: IWorkspaceService;
  annotationsStore: IAnnotationsStore;
  reviewRejectReasonsStore: IReviewRejectReasonsStore;
}

interface IState extends ILoaderState {
  showAnnotationConfirmationModal: boolean;
  annotationConfirmationModalMode: AnnotationConfirmationModalMode;
  showDiscardConfirmationModal: boolean;
  showSaveBeforeRejectConfirmationModal: boolean;
}

@injectProps({
  annotationReviewService: AnnotationReviewServiceType,
  workspaceService: WorkspaceServiceType,
  reviewRejectReasonsStore: ReviewRejectReasonsStoreType,
  annotationsStore: AnnotationsStoreType,
})
@observer
class ReviewControlsContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  state: IState = {
    showAnnotationConfirmationModal: false,
    showDiscardConfirmationModal: false,
    annotationConfirmationModalMode: AnnotationConfirmationModalMode.ACCEPT,
    isLoading: false,
    showSaveBeforeRejectConfirmationModal: false,
  };

  @autobind
  handleAccept() {
    this.setState({ showAnnotationConfirmationModal: false });
    return this.withLoaderAsync(() => this.props.annotationReviewService.acceptAnnotationAsync(), 'accept-button');
  }

  @autobind
  handleReject() {
    this.setState({ showSaveBeforeRejectConfirmationModal: false });
    this.toggleAnnotationConfirmationModal(AnnotationConfirmationModalMode.REJECT);
  }

  @autobind
  handleConfirmReject(reason?: string) {
    this.setState({ showAnnotationConfirmationModal: false });
    if (reason) {
      this.handleRejectConfirmed(reason);
    } else {
      this.setState({ showSaveBeforeRejectConfirmationModal: true });
    }
  }

  @autobind
  handleRejectConfirmed(reason?: string) {
    this.setState({ showSaveBeforeRejectConfirmationModal: false });
    return this.withLoaderAsync(() => this.props.annotationReviewService.rejectAnnotationAsync(reason), 'reject-button');
  }

  @autobind
  handleSaveBeforeRejectConfirmed() {
    this.handleRejectConfirmed();
  }

  @autobind
  handleRejectCanceled() {
    this.setState({ showSaveBeforeRejectConfirmationModal: false, showAnnotationConfirmationModal: false });
  }

  @autobind
  handleEdit() {
    this.props.annotationReviewService.navigateToCorrect();
  }

  @autobind
  toggleAnnotationConfirmationModal(mode?: AnnotationConfirmationModalMode) {
    if (mode !== undefined) this.setState({ annotationConfirmationModalMode: mode });
    this.setState((prevState: IState) => ({ ...prevState, showAnnotationConfirmationModal: !prevState.showAnnotationConfirmationModal }));
  }

  @autobind
  async handleDiscardConfirmed() {
    if (this.state.isLoading) return;

    this.setState({ showDiscardConfirmationModal: false });
    await this.withLoaderAsync(() => this.props.annotationReviewService.discardAnnotationsAsync(), 'discard-annotation-button');
  }

  @autobind
  async handleDiscardCanceled() {
    this.setState({ showDiscardConfirmationModal: false });
  }

  @autobind
  async handleDiscard() {
    this.setState({ showDiscardConfirmationModal: true });
  }

  render() {
    const { uiStore } = this.props.annotationReviewService;
    const disabled = this.state.isLoading || uiStore.isImageLoading || this.props.annotationsStore.image === undefined;

    // TODO: Create IPermission for annotations
    const canDiscard = this.props.workspaceService.isInRole([WorkspaceRole.Owner, WorkspaceRole.Manager]);

    return (
      <>
        {!disabled && (
          <GlobalHotKeys
            keyMap={{
              ACCEPT: { sequence: 'ctrl+shift+enter', action: 'keydown' },
              REJECT: { sequence: 'ctrl+shift+backspace', action: 'keydown' },
              EDIT: ['e', 'E'],
            }}
            handlers={{
              ACCEPT: () => this.toggleAnnotationConfirmationModal(AnnotationConfirmationModalMode.ACCEPT),
              REJECT: () => this.toggleAnnotationConfirmationModal(AnnotationConfirmationModalMode.REJECT),
              EDIT: this.handleEdit,
            }}
          />
        )}
        <ReviewNavigation
          disabled={disabled}
          canDiscard={canDiscard}
          editorMode={EditorMode.BATCH_REVIEW}
          onAccept={this.handleAccept}
          onReject={this.handleReject}
          onEdit={this.handleEdit}
          projectId={this.props.match.params.projectId}
          onDiscardCanceled={this.handleDiscardCanceled}
          onDiscardConfirmed={this.handleDiscardConfirmed}
          onDiscard={this.handleDiscard}
          showDiscardModal={this.state.showDiscardConfirmationModal}
          canCancel={false}
          onCancel={dinduNuffin}
        />
        <AnnotationConfirmationModal
          showAnnotationConfirmationModal={this.state.showAnnotationConfirmationModal}
          annotationConfirmationModalMode={this.state.annotationConfirmationModalMode}
          rejectAutoCompleteOptions={this.props.reviewRejectReasonsStore.rejectionReasons.slice()}
          onAnnotationConfirm={this.state.annotationConfirmationModalMode === AnnotationConfirmationModalMode.ACCEPT ? this.handleAccept : this.handleConfirmReject}
          onAnnotationCancel={this.toggleAnnotationConfirmationModal}
        />
        <SaveBeforeRejectConfirmationModal
          onSaveBeforeRejectCancel={this.handleRejectCanceled}
          onSaveBeforeRejectConfirm={this.handleSaveBeforeRejectConfirmed}
          onSaveBeforeRejectDecline={this.handleReject}
          showSaveBeforeRejectModal={this.state.showSaveBeforeRejectConfirmationModal}
        />
      </>
    );
  }
}

export const ReviewControlsContainer = as<React.ComponentClass>(withRouter(ReviewControlsContainerPure));
