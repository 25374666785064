import * as React from 'react';

import { Col } from 'reactstrap';
import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { EnableForRole } from '../../../containers/EnableForRole';
import { Home } from '../../../routes/config/Home';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { LinkedButton } from '../../../components/LinkedButton';
import { ProjectStatus } from '../../projects/projects.model';
import { PublishButton } from './PublishButton';
import { PublishButtonContainer } from '../containers/PublishButton.container';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IDraftButtonsProps {
  projectId: string;
  workspaceId: string;
  projectStatus: ProjectStatus;
  nextStepLink: string;
}

const DraftButtonsPure = (props: IDraftButtonsProps & ITranslatable) => {
  const showDraftButtons = props.projectStatus === ProjectStatus.Draft;

  return showDraftButtons ? (
    <Col sm="4" className="text-right project-draft-right d-flex align-items-end flex-column">
      {props.nextStepLink && <LinkedButton color="primary" to={props.nextStepLink} text={props.t('next_step')} />}
      {<PublishButtonContainer>{childrenProps => <PublishButton {...childrenProps} color="success" />}</PublishButtonContainer>}
      <EnableForRole workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Trainer]} projectRoles={[]}>
        <DisabledWhenWorkspaceOwnerPolicyExceeded>
          <LinkedButton color="secondary" to={Home.Projects.List.All.withParams({ workspaceId: props.workspaceId })} disabled={false} text={props.t('save_and_exit')} />
        </DisabledWhenWorkspaceOwnerPolicyExceeded>
      </EnableForRole>
      <LinkedButton color="primary" to={Home.Projects.Details.Preview.withParams({ projectId: props.projectId, workspaceId: props.workspaceId })} text={props.t('preview')} />
    </Col>
  ) : null;
};

export const DraftButtons = as<React.ComponentClass<IDraftButtonsProps>>(withNamespaces('project', { wait: true })(DraftButtonsPure));
