import { ApiPolicyProvider, ApiPolicyProviderType, IApiPolicyProvider } from './api.policy';
import { ApiService, ApiServiceType } from './api.service';
import { ApiServiceImageUpload, ApiServiceImageUploadType } from './api.service.imageUpload';
import { BrowserService, BrowserServiceType, IBrowserService } from './browser.service';
import { CacheManager, CacheManagerType, ICacheManager } from './cacheManager';
import { ContainerModule, interfaces } from 'inversify';
import { CryptoService, CryptoServiceType, CryptoType, ICryptoService } from './crypto.service';
import { CryptoStore, CryptoStoreType, ICryptoStore } from '../stores/crypto.store';
import { EventBus, EventBusType, IEventBus } from './eventBus.service';
import { HubServiceType, INotificationHubService, NotificationHubService } from '../modules/notifications/services/notificationHub.service';
import { IProjectHubService, ProjectHubService, ProjectHubServiceType } from './projectHub.service';
import { IStorageService, StorageService, StorageServiceType } from './storage.service';
import { ITimerService, TimerService, TimerServiceType } from './timer.service';
import { IUploadsHubService, UploadsHubService, UploadsHubServiceType } from './uploadsHub.service';
import { ApiServiceTraining, ApiServiceTrainingType } from './api.service.training';

export const servicesModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<ApiService>(ApiServiceType).to(ApiService);
  bind<ApiServiceImageUpload>(ApiServiceImageUploadType).to(ApiServiceImageUpload);
  bind<ApiServiceTraining>(ApiServiceTrainingType).to(ApiServiceTraining);
  bind<ITimerService>(TimerServiceType).to(TimerService).inSingletonScope();
  bind<IApiPolicyProvider>(ApiPolicyProviderType).to(ApiPolicyProvider);
  bind<IBrowserService>(BrowserServiceType).to(BrowserService).inSingletonScope();
  bind<INotificationHubService>(HubServiceType).to(NotificationHubService).inSingletonScope();
  bind<IUploadsHubService>(UploadsHubServiceType).to(UploadsHubService).inSingletonScope();
  bind<IProjectHubService>(ProjectHubServiceType).to(ProjectHubService).inSingletonScope();
  bind<ICacheManager>(CacheManagerType).to(CacheManager).inSingletonScope();
  bind<Crypto>(CryptoType).toConstantValue(window.crypto);
  bind<ICryptoStore>(CryptoStoreType).to(CryptoStore).inSingletonScope();
  bind<ICryptoService>(CryptoServiceType).to(CryptoService).inSingletonScope();
  bind<IStorageService>(StorageServiceType).to(StorageService).inSingletonScope();
  bind<IEventBus>(EventBusType).to(EventBus).inSingletonScope();
});
