import * as React from 'react';
import { as, injectProps } from '../../../../__legacy__/helpers/react.helpers';
import { IModelDetailsService, ModelDetailsServiceType } from '../../modelDetails.service';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';
import { MODEL_STATUS } from '../../../models/models.model';
import { ITranslatable } from '../../../../__legacy__/helpers/translations.helpers';
import { withNamespaces } from 'react-i18next';
import { Home } from '../../../../__legacy__/routes/config/Home';
import { withPolling } from '../../../../__legacy__/helpers/polling.helpers';
import { Loader } from '../../../../__legacy__/components/Loader';
import { AnomalyDetection4 } from '../../../../__legacy__/components/metrics/AnomalyDetection4';
import { MODEL_VARIANTS } from '../../../../__legacy__/models/metrics.model';
import { AnomalyDetection8 } from '../../../../__legacy__/components/metrics/AnomalyDetection8';
import { ObjectClassification } from '../../../../__legacy__/components/metrics/ObjectClassification';

interface IInjectedProps extends RouteComponentProps<{ workspaceId: string; jobId?: string }> {
  modelDetailsService: IModelDetailsService;
}

@injectProps({
  modelDetailsService: ModelDetailsServiceType,
})
@observer
class ModelMetricsContainerPure extends React.Component<IInjectedProps & ITranslatable> {
  clearPolling?: () => void;

  async fetchProgress(jobId: string) {
    const status = this.props.modelDetailsService.store.status;
    // When pending or unknown we can't get training progress
    if (status === MODEL_STATUS.STARTING || status === MODEL_STATUS.UNKNOWN) return;

    await this.props.modelDetailsService.getModelTrainingProgressAsync(jobId);

    if (
      (status === MODEL_STATUS.FINISHED || status === MODEL_STATUS.IMPORTED || status === MODEL_STATUS.STOPPED || status === MODEL_STATUS.FAILED) &&
      this.clearPolling !== undefined
    ) {
      this.clearPolling();
    }
  }

  async componentDidMount() {
    const jobId = this.props.match.params?.jobId;
    const status = this.props.modelDetailsService.store.status;

    if (status === MODEL_STATUS.UNKNOWN) {
      setTimeout(() => this.componentDidMount(), 100);
      return;
    }

    if (jobId && this.clearPolling === undefined) {
      this.clearPolling = withPolling(() => this.fetchProgress(jobId), 10000);
    }
  }

  componentDidUpdate(): void {
    const status = this.props.modelDetailsService.store.status;
    const jobId = this.props.match.params?.jobId;

    // Navigate users to settings when metrics should not be accessed
    if (jobId && (status === MODEL_STATUS.STOPPED || status === MODEL_STATUS.STOPPING || status === MODEL_STATUS.FAILED)) {
      this.props.history.replace(
        Home.Models.Details.Paths.withParams({
          jobId,
          workspaceId: this.props.match.params.workspaceId,
          tab: 'settings',
        }),
      );
    }
  }

  componentWillUnmount() {
    if (this.clearPolling !== undefined) this.clearPolling();
  }

  handleRenderMetrics() {
    const { train, metrics, validation, curve, results, matrix, variant } = this.props.modelDetailsService.store;

    switch (variant) {
      case MODEL_VARIANTS.ANOMALY_DETECTION_4:
        return <AnomalyDetection4 ns="models" train={train} validation={validation} matrix={matrix} curve={curve} results={results} metrics={metrics} />;
      case MODEL_VARIANTS.ANOMALY_DETECTION_8:
        return <AnomalyDetection8 ns="models" matrix={matrix} curve={curve} results={results} metrics={metrics} />;
      case MODEL_VARIANTS.OBJECT_CLASSIFICATION:
        return <ObjectClassification ns="models" train={train} validation={validation} matrix={matrix} curve={curve} results={results} metrics={metrics} />;
    }
  }

  getLoaderText() {
    const { status, startingProgress, variant } = this.props.modelDetailsService.store;

    if (status === MODEL_STATUS.STARTING) {
      return startingProgress === null ? this.props.t('service_is_starting') : this.props.t(`starting_progress.${startingProgress}`);
    }

    if (status === MODEL_STATUS.RUNNING && variant === MODEL_VARIANTS.ANOMALY_DETECTION_8) {
      return this.props.t('starting_progress.4/4');
    }

    return this.props.t('list_loader');
  }

  shouldShowLoader() {
    const { status, variant } = this.props.modelDetailsService.store;

    return status === MODEL_STATUS.STARTING || status === MODEL_STATUS.UNKNOWN || (status === MODEL_STATUS.RUNNING && variant === MODEL_VARIANTS.ANOMALY_DETECTION_8);
  }

  render() {
    return (
      <Loader solid={true} isLoading={this.shouldShowLoader()} message={this.getLoaderText()} class="justify-content-start pt-5">
        {this.handleRenderMetrics()}
      </Loader>
    );
  }
}

export const ModelMetricsContainer = as<React.ComponentClass>(withNamespaces('models')(withRouter(ModelMetricsContainerPure)));
