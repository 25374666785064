import { inject, injectable } from 'inversify';
import { StickerError } from '../../../../models/error.model';
import { action } from 'mobx';
import { ApiServiceType } from '../../../../services/api.service';
import UrlAssembler from 'url-assembler';
import { IApiService } from '../../../../services/api.service.base';
import _ from 'lodash';
import { IProjectDetailsStatisticsStore, ProjectDetailsStatisticsStoreType } from './projectDetailsStatistics.store';
import { ToolStatisticsViewMode } from './projectDetailsStatistics.model';
import { IProjectDetailsStatisticsApiService, ProjectDetailsStatisticsApiServiceType } from './services/projectDetailsStatisticsApi.service';
import { IProjectDetailsSubmodule } from '../../services/IProjectDetailsSubmodule';
import { ICanPublishProjectResponse } from '../../projectDetails.models';

export const ProjectDetailsStatisticsBlType = Symbol('PROJECT_DETAILS_STATISTICS_SERVICE');

export interface IProjectDetailsStatisticsBl extends IProjectDetailsSubmodule {
  statisticsSelectAllAnnotationType(): void;
  statisticsSelectAllQuestions(): void;
  statisticsSelectAllImageSets(): void;
  downloadStatisticsAsync(projectId: string): Promise<void | StickerError>;
  getStatisticsAsync(projectId: string): Promise<void>;
  refreshToolStatistics(projectId: string): Promise<void>;
  getProjectToolStatisticsAsync(projectId: string): Promise<void>;
  statisticsSelectAnnotationType(annotationTypeId: string): void;
  statisticsSelectQuestion(questionId: string): void;
  statisticsSelectImageSet(imageSetId: string): void;
  resetToolStatistics(): void;
  getTextQuestionAnswers(questionId: string): Promise<void | StickerError>;
  getTextAttributeAnswers(data: { attributeId: string, annotationTypeId: string }): Promise<void | StickerError>;
  store: IProjectDetailsStatisticsStore;
}

@injectable()
export class ProjectDetailsStatisticsBl implements IProjectDetailsStatisticsBl {
  constructor(
    @inject(ProjectDetailsStatisticsStoreType) public readonly store: IProjectDetailsStatisticsStore,
    @inject(ProjectDetailsStatisticsApiServiceType) private readonly projectDetailsStatisticsApiService: IProjectDetailsStatisticsApiService,
    @inject(ApiServiceType) private readonly apiService: IApiService,
  ) { }

  async validateAsync(): Promise<void> {
    // nop
  }

  initialize(): void {
    // nop
  }

  cleanup(): void {
    // nop
  }

  getValidationErrors(): string[] {
    return [];
  }

  handleCanPublishCheck(_: ICanPublishProjectResponse): void {
    // nop
  }

  @action
  statisticsSelectAllQuestions(): void {
    this.store.toolStatisticsViewMode = ToolStatisticsViewMode.ALL_QUESTIONS;
    this.store.selectedToolStatistics = undefined;
  }

  @action
  statisticsSelectAllAnnotationType(): void {
    this.store.toolStatisticsViewMode = ToolStatisticsViewMode.All_TOOLS;
    this.store.selectedToolStatistics = undefined;
  }

  @action
  statisticsSelectAllImageSets(): void {
    this.store.toolStatisticsViewMode = ToolStatisticsViewMode.ALL_IMAGE_SETS;
    this.store.selectedToolStatistics = undefined;
  }

  @action
  resetToolStatistics(): void {
    this.store.toolStatisticsViewMode = ToolStatisticsViewMode.NONE;
    this.store.selectedToolStatistics = undefined;
  }

  @action
  statisticsSelectAnnotationType(annotationTypeId: string): void {
    this.store.selectedToolStatistics = this.store.toolStatistics.annotationTypesStatistics.find(x => x.annotationStatusStatistics.id === annotationTypeId);
    this.store.toolStatisticsViewMode = ToolStatisticsViewMode.TOOL;
  }

  @action
  statisticsSelectQuestion(questionId: string): void {
    this.store.selectedToolStatistics = this.store.toolStatistics.questionStatistics.find(x => x.annotationStatusStatistics.id === questionId);
    this.store.toolStatisticsViewMode = ToolStatisticsViewMode.QUESTION;
  }

  @action
  statisticsSelectImageSet(imageSetId: string): void {
    this.store.selectedToolStatistics = this.store.toolStatistics.imageSetsStatistics.find(x => x.annotationStatusStatistics.id === imageSetId);
    this.store.toolStatisticsViewMode = ToolStatisticsViewMode.IMAGE_SET;
  }

  @action
  async downloadStatisticsAsync(projectId: string): Promise<void | StickerError> {
    const url = UrlAssembler()
      .template('/Projects/DownloadStatistics')
      .query({
        projectId,
      })
      .toString();

    const result = await this.apiService.getFileAsync(url);

    if (result instanceof StickerError) return result;

    return undefined;
  }

  @action
  async getStatisticsAsync(projectId: string) {
    const result = await this.projectDetailsStatisticsApiService.getProjectStatisticsAsync(projectId, this.store.statisticsPaging);

    if (result instanceof StickerError) return;

    if (result.pagesCount < result.pageNumber) {
      this.store.statisticsPaging.pageNumber = result.pagesCount;
      await this.getStatisticsAsync(projectId);
    } else {
      this.store.statistics = result.data;
      this.store.statisticsPaging = {
        ... this.store.statisticsPaging,
        pageNumber: result.pageNumber,
        pagesCount: result.pagesCount,
        totalCount: result.totalCount,
      };
    }
  }

  @action
  async getProjectToolStatisticsAsync(projectId: string) {
    const result = await this.projectDetailsStatisticsApiService.getProjectToolStatistics(projectId);
    if (result instanceof StickerError) {
      if (result.withCode(['NO_STATISTICS'])) {
        await this.refreshToolStatistics(projectId);
      }
      return;
    }
    this.store.toolStatistics = result;
  }

  @action
  async refreshToolStatistics(projectId: string): Promise<void> {
    const result = await this.projectDetailsStatisticsApiService.refreshToolStatistics(projectId);
    if (result instanceof StickerError) return;
    this.store.toolStatistics = result;
  }

  @action.bound
  async getTextQuestionAnswers(questionId: string): Promise<void | StickerError> {
    const result = await this.apiService.getFileAsync('/Projects/GetTextQuestionAnswers', { params: { questionId } });

    if (result instanceof StickerError) return result;

    return undefined;
  }

  @action.bound
  async getTextAttributeAnswers(data: { attributeId: string, annotationTypeId: string }): Promise<void | StickerError> {
    const result = await this.apiService.getFileAsync('/Projects/GetTextAttributeAnswers', { params: { ...data } });

    if (result instanceof StickerError) return result;

    return undefined;
  }
}
