import { CheckForAnnotationsConflictsService, CheckForAnnotationsConflictsServiceImpl, CheckForAnnotationsConflictsServiceType } from './services/CheckForAnnotationsConflicts.service';
import { ContainerModule, interfaces } from 'inversify';
import { IImportAnnotationsService, ImportAnnotationsService, ImportAnnotationsServiceType } from './services/ImportAnnotations.service';
import { ImportAnnotationsBl, ImportAnnotationsBlImpl, ImportAnnotationsBlType } from './importAnnotations.bl';
import { ImportAnnotationsPermissions, ImportAnnotationsPermissionsImpl, ImportAnnotationsPermissionsType } from './importAnnotations.permissions';
import {
  ImportAnnotationsStore,
  ImportAnnotationsStoreImpl,
  ImportAnnotationsStoreSetter,
  ImportAnnotationsStoreSetterType,
  ImportAnnotationsStoreType,
} from './importAnnotations.store';

export const importAnnotationsModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<ImportAnnotationsStore>(ImportAnnotationsStoreType).to(ImportAnnotationsStoreImpl).inSingletonScope();
  bind<ImportAnnotationsStoreSetter>(ImportAnnotationsStoreSetterType).toService(ImportAnnotationsStoreType);

  bind<ImportAnnotationsBl>(ImportAnnotationsBlType).to(ImportAnnotationsBlImpl).inSingletonScope();
  bind<CheckForAnnotationsConflictsService>(CheckForAnnotationsConflictsServiceType).to(CheckForAnnotationsConflictsServiceImpl).inSingletonScope();
  bind<IImportAnnotationsService>(ImportAnnotationsServiceType).to(ImportAnnotationsService).inSingletonScope();

  bind<ImportAnnotationsPermissions>(ImportAnnotationsPermissionsType).to(ImportAnnotationsPermissionsImpl).inSingletonScope();
});
