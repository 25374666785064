import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';
import { withNamespaces } from 'react-i18next';
import { EvaluationDetailsServiceType, IEvaluationDetailsService } from '../../evaluationDetails.service';
import { EVALUATION_STATUS } from '../../../evaluations/evaluations.model';
import { as, injectProps } from '../../../../__legacy__/helpers/react.helpers';
import { ITranslatable } from '../../../../__legacy__/helpers/translations.helpers';
import { Loader } from '../../../../__legacy__/components/Loader';
import { Home } from '../../../../__legacy__/routes/config/Home';
import { withPolling } from '../../../../__legacy__/helpers/polling.helpers';
import { AnomalyDetection4 } from '../../../../__legacy__/components/metrics/AnomalyDetection4';
import { MODEL_VARIANTS } from '../../../../__legacy__/models/metrics.model';
import { AnomalyDetection8 } from '../../../../__legacy__/components/metrics/AnomalyDetection8';
import { ObjectClassification } from '../../../../__legacy__/components/metrics/ObjectClassification';

interface IInjectedProps extends RouteComponentProps<{ workspaceId: string; jobId?: string }> {
  evaluationDetailsService: IEvaluationDetailsService;
}

interface IState {
  status: EVALUATION_STATUS;
}

@injectProps({
  evaluationDetailsService: EvaluationDetailsServiceType,
})
@observer
class EvaluationMetricsContainerPure extends React.Component<IInjectedProps & ITranslatable, IState> {
  clearPolling?: () => void;

  async fetchProgress(jobId: string) {
    const status = this.props.evaluationDetailsService.store.status;
    // When pending or unknown we can't get training progress
    if (status === EVALUATION_STATUS.STARTING || status === EVALUATION_STATUS.UNKNOWN) return;

    await this.props.evaluationDetailsService.getEvaluationProgressAsync(jobId);

    if (
      (status === EVALUATION_STATUS.FINISHED || status === EVALUATION_STATUS.IMPORTED || status === EVALUATION_STATUS.STOPPED || status === EVALUATION_STATUS.FAILED) &&
      this.clearPolling !== undefined
    ) {
      this.clearPolling();
    }
  }

  async componentDidMount() {
    const jobId = this.props.match.params?.jobId;
    const status = this.props.evaluationDetailsService.store.status;

    if (status === EVALUATION_STATUS.UNKNOWN) {
      setTimeout(() => this.componentDidMount(), 100);
      return;
    }

    if (jobId && this.clearPolling === undefined) {
      this.clearPolling = withPolling(() => this.fetchProgress(jobId), 10000);
    }
  }

  componentDidUpdate(): void {
    const status = this.props.evaluationDetailsService.store.status;
    const jobId = this.props.match.params?.jobId;

    // Navigate users to settings when metrics should not be accessed
    if (jobId && (status === EVALUATION_STATUS.STOPPED || status === EVALUATION_STATUS.STOPPING || status === EVALUATION_STATUS.FAILED)) {
      this.props.history.replace(
        Home.Evaluations.Details.Paths.withParams({
          jobId,
          workspaceId: this.props.match.params.workspaceId,
          tab: 'settings',
        }),
      );
    }
  }

  componentWillUnmount() {
    if (this.clearPolling !== undefined) this.clearPolling();
  }

  handleRenderMetrics() {
    const { metrics, curve, results, matrix, modelVariant } = this.props.evaluationDetailsService.store;

    switch (modelVariant) {
      case MODEL_VARIANTS.ANOMALY_DETECTION_4:
        return <AnomalyDetection4 ns="evaluations" matrix={matrix} curve={curve} results={results} metrics={metrics} />;
      case MODEL_VARIANTS.ANOMALY_DETECTION_8:
        return <AnomalyDetection8 ns="evaluations" matrix={matrix} curve={curve} results={results} metrics={metrics} />;
      case MODEL_VARIANTS.OBJECT_CLASSIFICATION:
        return <ObjectClassification ns="evaluations" matrix={matrix} curve={curve} results={results} metrics={metrics} />;
    }
  }

  getLoaderText() {
    const { status, startingProgress } = this.props.evaluationDetailsService.store;

    if (status === EVALUATION_STATUS.STARTING) {
      return startingProgress === null ? this.props.t('service_is_starting') : this.props.t(`starting_progress.${startingProgress}`);
    }

    if (EVALUATION_STATUS.RUNNING) {
      return this.props.t('starting_progress.4/4');
    }

    return this.props.t('list_loader');
  }

  render() {
    const { status } = this.props.evaluationDetailsService.store;

    return (
      <Loader solid={true} isLoading={status !== EVALUATION_STATUS.FINISHED} message={this.getLoaderText()} class="justify-content-start pt-5">
        {this.handleRenderMetrics()}
      </Loader>
    );
  }
}

export const EvaluationMetricsContainer = as<React.ComponentClass>(withNamespaces('evaluations')(withRouter(EvaluationMetricsContainerPure)));
