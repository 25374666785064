import { AsyncQueue, queue as QueueConstructor } from 'async';
import { BrowserServiceType, IBrowserService } from '../../services/browser.service';
import { observable, action, computed } from 'mobx';
import { injectable, inject } from 'inversify';
import { StickerError, OperationResult, ForbiddenError, Failed, UnauthorizedError, UploadSuccess, PreprocessingError } from '../../models/error.model';
import { INotificationsService, NotificationsServiceType } from '../notifications/services/notifications.service';
import {
  DatasetUploadCompletedNotification, DatasetUploadFailedNotification, NotificationLevel, ToastNotification, DatasetUploadAbortedNotification, PolicyLimitExceededNotification,
} from '../notifications/models/notification.model';
import { ApiServiceImageUploadType } from '../../services/api.service.imageUpload';
import { IApiService } from '../../services/api.service.base';
import { ConfigurationType, IConfiguration } from '../../../configuration';
import { IUploadsHubService, UploadsHubServiceType } from '../../services/uploadsHub.service';
import { CryptoServiceType, ICryptoService } from '../../services/crypto.service';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';

const datasetNotFoundError = 'Uploading dataset not found';

export interface WillImageUploadExceedsLimitResult {
  willExceedsImageAmount: boolean;
  willExceedsAvailableSpace: boolean;
  areAllSuccessful: boolean;
  ownerPlan: string;
}

export enum UploadStatus {
  Waiting = 'Waiting',
  Uploading = 'Uploading',
  Uploaded = 'Uploaded',
  Canceled = 'Canceled',
  Paused = 'Paused',
  Interrupted = 'Interrupted',
}

export interface IImageToLoad { }

export interface IItemPayload {
  datasetId: string;
  item: IImageToLoad;
}

export interface IUploadedImage {
  imageSize: number;
}

export interface IDatasetUpload {
  datasetId: string;
  datasetName: string;
  items: IImageToLoad[];
  uploadedItemsCount: number;
  uploadedItemsSize: number;
  allItemsCount: number;
  status: UploadStatus;
  rejectedItems: IImageToLoad[];
  hasAnyError: boolean;
}

export interface IDatasetUploadService {
  datasetsQueue: IDatasetUpload[];
  addDatasetToQueueAsync(items: IImageToLoad[], datasetId: string, datasetName: string): Promise<void>;
  cancelUploadAsync(datasetId: string): Promise<void>;
  cancelUploads(): void;
  pauseUpload(datasetId: string): void;
  restoreUploadAsync(datasetId: string): void;
  onUploadFinishedSuccessfully: ((datasetId: string) => void)[];
  onUploadAborted: ((datasetId: string) => void)[];
  onDatasetUploadProgress: ((datasetId: string, imageSize: number) => void)[];
}

@injectable()
export abstract class UploadServiceBase<T extends IImageToLoad> implements IDatasetUploadService {
  @observable
  datasetsQueue: IDatasetUpload[] = [];
  imagesQueue?: AsyncQueue<IImageToLoad>;
  onUploadFinishedSuccessfully: ((datasetId: string) => void)[] = [];
  onUploadAborted: ((datasetId: string) => void)[] = [];
  onDatasetUploadProgress: ((datasetId: string, imageSize: number) => void)[] = [];

  constructor(
    @inject(BrowserServiceType) private readonly browserService: IBrowserService,
    @inject(NotificationsServiceType) protected readonly notificationService: INotificationsService,
    @inject(ApiServiceImageUploadType) protected readonly apiServiceImageUpload: IApiService,
    @inject(ConfigurationType) protected readonly configuration: IConfiguration,
    @inject(UploadsHubServiceType) protected readonly uploadsHub: IUploadsHubService,
    @inject(CryptoServiceType) protected readonly cryptoService: ICryptoService,
    @inject(CurrentWorkspaceStoreType) protected readonly currentWorkspaceStore: ICurrentWorkspaceStore,
  ) {
    this.browserService.addOnBeforeUnload(() => this.datasetsQueue.length > 0);
  }

  @computed
  get uploadingDataset(): IDatasetUpload | undefined {
    return this.datasetsQueue.find(ds => ds.status === UploadStatus.Uploading || ds.status === UploadStatus.Paused);
  }

  @action
  async addDatasetToQueueAsync(items: IImageToLoad[], datasetId: string, datasetName: string) {
    this.datasetsQueue.push({
      datasetId,
      datasetName,
      items,
      allItemsCount: items.length,
      rejectedItems: [],
      status: UploadStatus.Waiting,
      uploadedItemsCount: 0,
      uploadedItemsSize: 0,
      hasAnyError: false,
    });
    await this.uploadsHub.initializeAsync();
    await this.uploadsHub.startUploadAsync(datasetId);
    await this.tryUploadNextDatasetAsync();
  }

  @action.bound
  async uploadItemAsync(payload: IItemPayload, onUploadItemFinished: () => void) {
    const result = await this.upload(payload.item as T, payload.datasetId);
    const dataset = this.datasetsQueue.find(ds => ds.datasetId === payload.datasetId);
    if (!dataset) return; // post request finished after dataset was canceled
    dataset.uploadedItemsCount += 1;
    if (result.isSuccess) {
      const imageSize = (result as UploadSuccess).imageSize;
      dataset.uploadedItemsSize += imageSize;
      this.notifyOnImageUploadFinished(dataset, imageSize);
    } else {
      this.handleUploadError(payload.datasetId, result as Failed, payload.item as T);
      dataset.hasAnyError = true;
      dataset.rejectedItems.push(payload.item as T);
    }
    onUploadItemFinished(); // required to run 'drain' method
  }

  protected async handleUploadError(datasetId: string, result?: Failed, payload?: T) {
    if (result) {
      if (result.Error instanceof ForbiddenError || result instanceof UnauthorizedError) {
        await this.cancelUploadAsync(datasetId, UploadStatus.Interrupted);
        result.isHandled = true;
      }
      if (result.Error instanceof PreprocessingError) {
        this.notificationService.push(
          new ToastNotification(NotificationLevel.ERROR, { template: 'datasets:cannot_preprocess_file' }),
        );
        result.isHandled = true;
      }
      if (result.Error!.isBadRequestWithCode(['ADDING_IMAGE_NOT_ALLOWED_NOT_ENOUGH_FUNDS'])) {
        this.notificationService.push(
          new ToastNotification(NotificationLevel.ERROR, { template: 'datasets:upload_limit_is_exceeded' }),
        );
        await this.cancelUploadAsync(datasetId, UploadStatus.Interrupted);
        result.isHandled = true;
      }
      if (result.Error!.isBadRequestWithCode(['ADDING_IMAGE_NOT_ALLOWED'])) {
        this.notificationService.push(
          new ToastNotification(NotificationLevel.ERROR, { template: 'datasets:you_do_not_have_permisions_to_upload_to_this_dataset' }),
        );
        await this.cancelUploadAsync(datasetId, UploadStatus.Interrupted);
        result.isHandled = true;
      }
      if (result.Error!.isBadRequestWithCode(['UPLOAD_IN_PUBLISHED_NOT_ALLOWED'])) {
        this.notificationService.push(
          new ToastNotification(NotificationLevel.ERROR, { template: 'datasets:upload_to_published_dataset_is_not_allowed' }),
        );
        await this.cancelUploadAsync(datasetId, UploadStatus.Interrupted);
        result.isHandled = true;
      }
      if (result.Error!.isBadRequestWithCode(['POLICY_LIMITS_EXCEEDED'])) {
        this.notificationService.push(new PolicyLimitExceededNotification());
        await this.cancelUploadAsync(datasetId, UploadStatus.Interrupted);
        result.isHandled = true;
      }
    }

    if (!result || !result.isHandled) {
      this.notificationService.push(
        new ToastNotification(NotificationLevel.ERROR, { template: 'common:something_went_wrong' }),
      );
    }
  }

  @action
  async uploadDatasetAsync(datasetUpload: IDatasetUpload) {
    datasetUpload.status = UploadStatus.Uploading;
    this.imagesQueue = QueueConstructor(
      this.uploadItemAsync,
      this.configuration.appConfig && this.configuration.appConfig.concurrentUploadsCount || 1);
    this.imagesQueue.drain = this.onAllItemsUploadedAsync;

    datasetUpload.items.forEach(item =>
      this.imagesQueue!.push(
        {
          item,
          datasetId: datasetUpload.datasetId,
        },
        () => { },
      ),
    );
  }

  @action.bound
  pauseUpload(datasetId: string) {
    if (!this.uploadingDataset) throw new StickerError(datasetNotFoundError, undefined);
    if (this.uploadingDataset.datasetId !== datasetId) return;

    this.uploadingDataset.status = UploadStatus.Paused;
    this.imagesQueue!.pause();
  }

  @action.bound
  async restoreUploadAsync(datasetId: string) {
    if (!this.uploadingDataset) throw new StickerError(datasetNotFoundError, undefined);
    if (this.uploadingDataset.datasetId !== datasetId) return;

    this.uploadingDataset.status = UploadStatus.Uploading;
    this.imagesQueue!.resume();

    const lastItemAlreadyUploaded = this.imagesQueue!.idle();
    if (lastItemAlreadyUploaded) {
      await this.finishUploadAsync(this.uploadingDataset.datasetId);
    }
  }

  @action.bound
  async cancelUploadAsync(datasetId: string, uploadStatus: UploadStatus = UploadStatus.Canceled) {
    if (this.isDatasetUploadAborted(datasetId)) return;
    if (this.uploadingDataset && this.uploadingDataset.datasetId === datasetId) {
      this.imagesQueue!.kill();
      this.imagesQueue = undefined;
    }

    const dataset = this.datasetsQueue.find(d => d.datasetId === datasetId);
    if (!dataset) {
      return;
    }

    dataset.status = uploadStatus;

    this.onUploadAborted.forEach((f) => {
      if (f) f(dataset.datasetId);
    });

    this.notifyAboutDatasetUploadFinishAsync(dataset);
    this.removeDatasetFromQueue(dataset.datasetId);
    await this.tryUploadNextDatasetAsync();
  }

  @action.bound
  cancelUploads() {
    this.datasetsQueue = [];
    if (this.imagesQueue) {
      this.imagesQueue.kill();
      this.imagesQueue = undefined;
    }
  }

  @action.bound
  async tryUploadNextDatasetAsync() {
    if (this.uploadingDataset) return;

    const firstWaitingDataset = this.datasetsQueue.find(ds => ds.status === UploadStatus.Waiting);
    if (!firstWaitingDataset) return;

    await this.uploadDatasetAsync(firstWaitingDataset);
  }

  @action.bound
  async onAllItemsUploadedAsync() {
    const uploadingDataset = this.uploadingDataset;
    if (!uploadingDataset) throw new StickerError('Uploading dataset not found', undefined);

    if (uploadingDataset.status === UploadStatus.Paused) return;

    await this.finishUploadAsync(uploadingDataset.datasetId);
  }

  removeDatasetFromQueue(datasetId: string) {
    this.datasetsQueue = this.datasetsQueue.filter(q => q.datasetId !== datasetId);
  }

  @action.bound
  async finishUploadAsync(datasetId: string) {
    const dataset = this.datasetsQueue.find(d => d.datasetId === datasetId);
    if (!dataset) throw new StickerError(`Dataset with id ${datasetId} not found`, undefined);

    if (this.uploadingDataset!.datasetId === datasetId) {
      this.imagesQueue!.kill();
      this.imagesQueue = undefined;
    }

    dataset.status = UploadStatus.Uploaded;
    setTimeout(() => this.cleanQueueAndSendNotification(dataset), 100);

    this.tryUploadNextDatasetAsync();
  }

  @action.bound
  async cleanQueueAndSendNotification(dataset: IDatasetUpload) {
    this.removeDatasetFromQueue(dataset.datasetId);
    this.onUploadFinishedSuccessfully.forEach((f) => {
      if (f) f(dataset.datasetId);
    });
    await this.notifyAboutDatasetUploadFinishAsync(dataset);
  }

  async notifyAboutDatasetUploadFinishAsync(dataset: IDatasetUpload) {
    await this.uploadsHub.finishUploadAsync(dataset.datasetId);
    if (dataset.status === UploadStatus.Canceled) {
      this.notificationService.push(
        new ToastNotification(
          NotificationLevel.INFO,
          { template: 'datasets:dataset_upload_canceled' },
          { template: 'datasets:upload_of_x_canceled', data: { datasetName: dataset.datasetName } },
        ),
      );
    } else if (dataset.rejectedItems.length === dataset.allItemsCount) {
      this.notificationService.push(new DatasetUploadFailedNotification(dataset.datasetName));
    } else if (dataset.status === UploadStatus.Interrupted) {
      this.notificationService.push(new DatasetUploadAbortedNotification(dataset.datasetName));
    } else if (dataset.rejectedItems.length > 0) {
      this.datasetUploadCompletedWithInfoNotification(dataset);
    } else {
      this.notificationService.push(new DatasetUploadCompletedNotification(dataset.datasetName));
    }
  }

  isDatasetUploadAborted = (datasetId: string) => {
    const dataset = this.datasetsQueue.find(d => d.datasetId === datasetId);
    return !dataset || dataset.status === UploadStatus.Canceled || dataset.status === UploadStatus.Interrupted;
  };

  notifyOnImageUploadFinished(dataset: IDatasetUpload, imageSize: number) {
    this.onDatasetUploadProgress.forEach((c: ((datasetId: string, imageSize: number) => void)) => {
      c(dataset.datasetId, imageSize);
    });
  }

  abstract upload(item: T, datasetId: string): Promise<OperationResult>;

  abstract datasetUploadCompletedWithInfoNotification(dataset: IDatasetUpload): void;
}
