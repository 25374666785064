import { action, observable } from 'mobx';

import { DatasetStatus } from './datasetStatus.model';
import { IDatasetDetails } from './datasetsDetails.store';
import { IPagingInfoWithOrder } from '../../models/paginationInfo.model';
import { injectable } from 'inversify';

export const DatasetsStoreType = Symbol('DATASETS_STORE');
export const PAGE_SIZE = 120;

export const DatasetsDefaultPaging = {
  pageNumber: 1,
  pagesCount: 1,
  totalCount: 1,
  pageSize: PAGE_SIZE,
  orderBy: 'name',
  orderType: 'asc',
  search: '',
};

export interface IDatasetListItemViewModel {
  id: string;
  workspaceId: string;
  name: string;
  description: string;
  author: string;
  termsOfUse: string;
  imagesCount: number;
  projectsCount: number;
  imagesSize: number;
  status: DatasetStatus;
  lastModified: string;
  createdDate: string;
  orderBy?: string;
  orderType?: string;
  createdBy: string;
}

export interface IDatasetsStore {
  datasets: IDatasetListItemViewModel[];
  datasetsPaging: IPagingInfoWithOrder;
  workspaceId: string;
  stale: boolean;

  markAsUploaded(datasetId: string): void;
  markAsUploading(datasetId: string): void;
  incrementProgress(datasetId: string, imageSize: number): void;
  refreshFromDetails(details: IDatasetDetails): void;
}

@injectable()
export class DatasetsStore implements IDatasetsStore {
  @observable
  datasets: IDatasetListItemViewModel[] = [];

  @observable
  datasetsPaging: IPagingInfoWithOrder = DatasetsDefaultPaging;

  @observable
  workspaceId: string = '';

  @observable
  stale = false;

  @action
  markAsUploaded(datasetId: string): void {
    const dataset = this.datasets.find(x => x.id === datasetId);
    if (dataset === undefined) return;

    dataset.status = dataset.status === DatasetStatus.PUBLISHEDUPLOADING ? DatasetStatus.PUBLISHED : DatasetStatus.DRAFT;

    this.datasets.splice(
      this.datasets.findIndex(s => s.id === datasetId),
      1,
      dataset,
    );
  }

  @action
  incrementProgress(datasetId: string, imageSize: number): void {
    const dataset = this.datasets.find(x => x.id === datasetId);
    if (dataset === undefined) return;
    dataset.imagesCount += 1;
    dataset.imagesSize += imageSize;
    this.datasets.splice(
      this.datasets.findIndex(s => s.id === datasetId),
      1,
      dataset,
    );
  }

  @action
  markAsUploading(datasetId: string): void {
    const dataset = this.datasets.find(x => x.id === datasetId);
    if (dataset === undefined) return;

    dataset.status = dataset.status === DatasetStatus.DRAFT ? DatasetStatus.UPLOADING : DatasetStatus.PUBLISHEDUPLOADING;

    this.datasets.splice(
      this.datasets.findIndex(s => s.id === datasetId),
      1,
      dataset,
    );
  }

  @action
  refreshFromDetails(details: IDatasetDetails): void {
    const datasetIndex = this.datasets.findIndex(x => x.id === details.id);
    if (datasetIndex === -1) {
      this.datasets.push({ ...details });
    } else {
      this.datasets.splice(datasetIndex, 1, { ...details });
    }
    this.stale = false;
  }
}
