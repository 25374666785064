import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FilterTabType, IAnnotationToolFilter, IImageFilters, IImageFiltersData, ISavedFilter, ISorting } from '../../imageFilters.model';
import { ImageAnnotationStatus, ImageSetType } from '../../projectDetailsImages.model';

import { AnnotatedByBody } from './annotatedBy/AnnotatedByBody';
import { AnnotatedByHeader } from './annotatedBy/AnnotatedByHeader';
import { AnnotationToolsBody } from './annotationTools/AnnotationToolsBody';
import { AnnotationToolsHeader } from './annotationTools/AnnotationToolsHeader';
import { ButtonWithLoader } from '../../../../../../components/ButtonWithLoader';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { CorrectedBody } from './corrected/CorrectedBody';
import { CorrectedHeader } from './corrected/CorrectedHeader';
import { ITranslatable } from '../../../../../../helpers/translations.helpers';
import { ImageSetBody } from './imageSet/ImageSetBody';
import { ImageSetHeader } from './imageSet/ImageSetHeader';
import { InDatasetsBody } from './inDatasets/InDatasetsBody';
import { InDatasetsHeader } from './inDatasets/InDatasetsHeader';
import React from 'react';
import { ReviewedByBody } from './reviewdBy/ReviewedByBody';
import { ReviewedByHeader } from './reviewdBy/ReviewedByHeader';
import { SaveFiltersContainer } from '../../containers/filters/SaveFilters.container';
import { SortingBody } from './sorting/SortingBody';
import { SortingHeader } from './sorting/SortingHeader';
import { StatusBody } from './status/StatusBody';
import { StatusHeader } from './status/StatusHeader';
import { as } from '../../../../../../helpers/react.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

export interface ImageFiltersFormProps {
  isClearAllEnabled: boolean;
  activeFiltersCount: number;
  showClearAllConfirmation: boolean;
  activeFilterType: FilterTabType;
  imageFilters: IImageFilters;
  availableFilters: IImageFiltersData;
  isLoading: boolean;
  canSeeUsers: boolean;
  savedFilters: ISavedFilter[];
  onFilterTabSelect(filterType: FilterTabType): void;
  onSetCorrected(corrected: boolean[]): void;
  onSetImageSets(imageSets: ImageSetType[]): void;
  onSetStatuses(annotationStatuses: ImageAnnotationStatus[]): void;
  onSetInDatasets(datasets: string[]): void;
  onSetReviewedBy(reviewedBy: string[]): void;
  onSetAnnotatedBy(annotatedBy: string[]): void;
  onSetAnnotationTools(annotationTools: IAnnotationToolFilter[]): void;
  onSetSorting(sorting: ISorting[]): void;
  onClearAll(): void;
  onClearAllDeclined(): void;
  onSavedFilterLoad(savedFilter: ISavedFilter): void;
  onClearAllConfirmedAsync(): Promise<void>;
  onSavedFilterAsync(name: string): Promise<void>;
  onFilterDeleteAsync(id: string): Promise<void>;
  onApplyAsync(): Promise<void>;
  onModalClose(): void;
  onModalOpen(): void;
  isModalOpen: boolean;
}

@observer
class ImageFiltersFormPure extends React.Component<ImageFiltersFormProps & ITranslatable> {
  handleFilterSaveTabSelected = () => {
    this.props.onFilterTabSelect(FilterTabType.FilterSave);
  };

  renderBody() {
    switch (this.props.activeFilterType) {
      case FilterTabType.InDatasets:
        return <InDatasetsBody {...this.props} />;
      case FilterTabType.AnnotatedBy:
        return <AnnotatedByBody {...this.props} />;
      case FilterTabType.ReviewedBy:
        return <ReviewedByBody {...this.props} />;
      case FilterTabType.Status:
        return <StatusBody {...this.props} />;
      case FilterTabType.AnnotationTools:
        return <AnnotationToolsBody {...this.props} />;
      case FilterTabType.Corrected:
        return <CorrectedBody {...this.props} />;
      case FilterTabType.ImageSet:
        return <ImageSetBody {...this.props} />;
      case FilterTabType.Sorting:
        return <SortingBody {...this.props} />;
      case FilterTabType.FilterSave:
        return (
          <SaveFiltersContainer
            onSavedFilterLoad={this.props.onSavedFilterLoad}
            onSaveFilterAsync={this.props.onSavedFilterAsync}
            onFilterDeleteAsync={this.props.onFilterDeleteAsync}
            savedFilters={this.props.savedFilters}
          />
        );
      default:
        throw 'invalid tab';
    }
  }

  render() {
    return (
      <>
        <Modal
          onOpened={this.props.onModalOpen}
          className="image-filters-modal modal-width-1200"
          isOpen={this.props.isModalOpen}
          toggle={this.props.onModalClose}
        >
          <ModalHeader toggle={this.props.onModalClose}>{this.props.t('filters_and_sorting')}</ModalHeader>
          <ModalBody>
            <div className="image-filters-container">
              <div className="image-filters-headers">
                <InDatasetsHeader {...this.props} />
                <AnnotatedByHeader {...this.props} />
                <ReviewedByHeader {...this.props} />
                <StatusHeader {...this.props} />
                <AnnotationToolsHeader {...this.props} />
                <CorrectedHeader {...this.props} />
                <ImageSetHeader {...this.props} />
                <SortingHeader {...this.props} />
              </div>
              <div className="image-filters-body">{this.renderBody()}</div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="left">
              <Button color="primary" onClick={this.handleFilterSaveTabSelected} disabled={this.props.isLoading}>
                {this.props.t('save_load_filters')}
              </Button>
            </div>
            <Button color="warning" className="mr-2" outline onClick={this.props.onClearAll} disabled={this.props.isLoading || !this.props.isClearAllEnabled}>
              {this.props.t('clear_all')}
            </Button>
            <ButtonWithLoader color="success" onClick={this.props.onApplyAsync} isLoading={this.props.isLoading}>
              {this.props.t('apply')}
            </ButtonWithLoader>
          </ModalFooter>
        </Modal>
        <ConfirmationModal
          confirmationHeader={this.props.t('clear_all')}
          confirmationDescription={this.props.t('are_you_sure_you_want_to_delete_filters')}
          showModal={this.props.showClearAllConfirmation}
          onCancel={this.props.onClearAllDeclined}
          onConfirm={this.props.onClearAllConfirmedAsync}
          confirmationYes={this.props.t('delete')}
        />
      </>
    );
  }
}

export const ImageFiltersForm = as<React.ComponentClass<ImageFiltersFormProps>>(withNamespaces('common')(ImageFiltersFormPure));
