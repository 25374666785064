import { AddMarkingToolBl, AddMarkingToolBlType } from '../../AddMarkingTool.bl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { as, injectProps } from '../../../../../../../../__legacy__/helpers/react.helpers';

import { AddMarkingToolButton } from './AddMarkingToolButton';
import React from 'react';

interface InjectedProps {
  bl: AddMarkingToolBl;
}
@injectProps({
  bl: AddMarkingToolBlType,
})
class AddMarkingToolButtonContainerPure extends React.Component<InjectedProps & RouteComponentProps<{ projectId: string }>> {
  handleShowModal = () => {
    const projectId = this.props.match.params.projectId;
    this.props.bl.showModal(projectId);
  };

  render(): React.ReactNode {
    return <AddMarkingToolButton onShowModal={this.handleShowModal} />;
  }
}

export const AddMarkingToolButtonContainer = as<React.ComponentClass>(withRouter(AddMarkingToolButtonContainerPure));
