import './S_Dropzone.scss';

import Dropzone, { DropFileEventHandler } from 'react-dropzone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { S_ClassNameProps } from '../../CommonProps';
import classNames from 'classnames';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

interface S_DropzoneProps extends S_ClassNameProps {
  bodyText: string;
  multiple: boolean;
  invalid: boolean;
  onDropAccepted?: DropFileEventHandler;
  onDropRejected?: DropFileEventHandler;
}

export const S_Dropzone: React.FC<S_DropzoneProps> = (props: S_DropzoneProps) => (
  <Dropzone
    activeClassName="active"
    accept={'application/JSON'}
    className={classNames('s-dropzone', {
      'is-invalid': props.invalid,
    })}
    multiple={props.multiple}
    onDropRejected={props.onDropRejected}
    onDropAccepted={props.onDropAccepted}
  >
    <FontAwesomeIcon icon={faUpload} />
    {props.bodyText}
  </Dropzone>
);
