import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../../../workspaces/currentWorkspace/CurrentWorkspace.store';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { as, injectProps } from '../../../../../../../__legacy__/helpers/react.helpers';

import React from 'react';
import { observer } from 'mobx-react';
import { timeZones } from '../../../../../../../__legacy__/models/timeZones/timeZones.model';

interface InjectedProps {
  store: ICurrentWorkspaceStore;
}

@injectProps({
  store: CurrentWorkspaceStoreType,
})
@observer
class WorkspaceTimezoneContainerPure extends React.Component<InjectedProps & WithNamespaces> {
  render(): React.ReactNode {
    const timezone = timeZones.find(tz => tz.value === this.props.store.currentWorkspace?.timeZoneOffset ?? 0);

    return <div className="workspace-timezone-container">{this.props.t('projects.details.stats.stats_generated_for')}:&nbsp;<strong>{timezone?.label}</strong></div>;
  }
}

export const WorkspaceTimezoneContainer = as<React.ComponentClass>(withNamespaces('new')(WorkspaceTimezoneContainerPure));
