import { ContainerModule, interfaces } from 'inversify';
import { IIntegrationsService, IntegrationsService, IntegrationsServiceType } from './integrations.service';
import { IIntegrationsStore, IntegrationsStore, IntegrationsStoreType } from './integrations.store';

export const integrationModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IIntegrationsService>(IntegrationsServiceType)
    .to(IntegrationsService)
    .inSingletonScope();
  bind<IIntegrationsStore>(IntegrationsStoreType)
    .to(IntegrationsStore)
    .inSingletonScope();
});
