import { AnnotationCommand } from './annotationCommand';
import { IAnnotationService } from '../annotation.service';
import { IAnnotationsStore } from '../annotations.store';
import { IFreeDrawSegmentationService } from '../freeDrawSegmentation.service';
import { ISegmentation } from '../annotations.interface';
import { LatLngBounds } from 'leaflet';
import { cloneDeep } from 'lodash/fp';
import { getLatLngsForGeojson } from '../../../helpers/geometry/polygon.helpers';

export class ChangeSegmentationWithAnswersCommand extends AnnotationCommand {

  private segmentationBefore: ISegmentation | undefined;
  private segmentationAfter: ISegmentation | undefined;

  constructor(
    annotationService: IAnnotationService,
    annotationsStore: IAnnotationsStore,
    freeDrawSegmentationService: IFreeDrawSegmentationService,
    segmentationBefore: ISegmentation | undefined,
    segmentationAfter: ISegmentation | undefined,
  ) {
    super(annotationService, annotationsStore, freeDrawSegmentationService);
    this.segmentationBefore = cloneDeep(segmentationBefore);
    this.segmentationAfter = cloneDeep(segmentationAfter);
  }

  executeUndo() {
    if (this.segmentationBefore) {
      const segmentation = this.annotationsStore.segmentations.find(x => x.feature.id === this.segmentationAfter?.feature.id);
      if (!segmentation) {
        const segmentations = [...this.annotationsStore.segmentations, this.segmentationBefore];
        this.annotationService.setSegmentations(segmentations);
      } else {
        segmentation.feature = this.segmentationBefore.feature;
        segmentation.questions = this.segmentationBefore.questions;
        const latlngs = getLatLngsForGeojson(this.segmentationBefore.feature);
        segmentation.latlngs = latlngs;
        segmentation.bbox = new LatLngBounds(latlngs);
      }
    } else if (this.segmentationAfter?.feature) {
      this.annotationService.deleteSegmentation(this.segmentationAfter.feature.id);
      if (this.segmentationAfter.feature.id === this.freeDrawSegmentationService.freeDrawFeature?.id) this.freeDrawSegmentationService.clear();
    }
  }

  executeRedo(): void {
    if (this.segmentationAfter) {
      const segmentation = this.annotationsStore.segmentations.find(x => x.feature.id === this.segmentationBefore?.feature.id);
      if (!segmentation) {
        const segmentations = [...this.annotationsStore.segmentations, this.segmentationAfter];
        this.annotationService.setSegmentations(segmentations);
      } else {
        segmentation.feature = this.segmentationAfter.feature;
        segmentation.questions = this.segmentationAfter.questions;
        const latlngs = getLatLngsForGeojson(segmentation.feature);
        segmentation.latlngs = latlngs;
        segmentation.bbox = new LatLngBounds(latlngs);
      }
    } else if (this.segmentationBefore?.feature) {
      this.annotationService.deleteSegmentation(this.segmentationBefore.feature.id);
    }
  }
}
