import { inject, injectable } from 'inversify';
import { action, autorun as autoRun, IReactionDisposer, when } from 'mobx';
import { StickerError } from '../../models/error.model';
import { ApiServiceType } from '../../services/api.service';
import { IApiService } from '../../services/api.service.base';
import { BrowserServiceType, IBrowserService } from '../../services/browser.service';
import { IRegisterModel, ILoginModel, IConfirmEmailModel, IResendEmailModel } from './auth.model';
import { AuthStoreSetterType, IAuthStoreSetter } from './auth.store';
import { LocationState } from 'history';
import { Auth } from '../../routes/config/Auth';
import { matchPath } from 'react-router';
import { Home } from '../../routes/config/Home';
import { IWorkspaceService, WorkspaceServiceType } from '../workspaces/workspaces.service';
import { UUIDRegex } from '../../helpers/string.helper';
import { CryptoServiceType, ICryptoService } from '../../services/crypto.service';
import autobind from 'autobind-decorator';

const apiUrl = '/Authorization/';

export const AuthServiceType = Symbol('AUTH_SERVICE');

export interface IAuthService {
  authenticate(loginModel: ILoginModel): Promise<void | StickerError>;
  register(registerModel: IRegisterModel): Promise<void | StickerError>;
  confirmEmail(confirmEmailModel: IConfirmEmailModel): Promise<void | StickerError>;
  confirmNewEmail(confirmEmailModel: IConfirmEmailModel): Promise<void | StickerError>;
  resendConfirmationEmail(resendEmailModel: IResendEmailModel): Promise<void | StickerError>;
  logout(): void;
  requestPasswordRequest(email: string): Promise<void | StickerError>;
  resetPassword(token: string, newPassword: string): Promise<void | StickerError>;
  setRouteReferrer(route: LocationState): void;
  getAndClearReferrer(): string;
  showVersionChangedMessage(changed?: boolean): void;
  setLockoutDate(lockoutDate: string | undefined): void;
}

const IGNORED_ROUTE: string[] = [Auth.Register.Path, Auth.Login.Path, Auth.ForgotPassword.Path, Auth.ResetPassword.Path];

@injectable()
export class AuthService implements IAuthService {
  logoutWhenEmptyToken?: IReactionDisposer;

  constructor(
    @inject(AuthStoreSetterType) public readonly data: IAuthStoreSetter,
    @inject(ApiServiceType) private readonly apiService: IApiService,
    @inject(BrowserServiceType) private readonly browserService: IBrowserService,
    @inject(WorkspaceServiceType) private readonly workspaceService: IWorkspaceService,
    @inject(CryptoServiceType) private readonly cryptoService: ICryptoService,
  ) {
    when(
      () => this.data.token !== '',
      () =>
        (this.logoutWhenEmptyToken = autoRun(() => {
          if (this.data.token === '') this.logout();
        })),
    );
    when(
      () => this.data.token === '',
      () => {
        if (this.logoutWhenEmptyToken) this.logoutWhenEmptyToken();
      },
    );
  }

  @autobind
  setLockoutDate(lockoutDate: string | undefined): void {
    this.data.setLockoutDate(lockoutDate);
  }

  @autobind
  showVersionChangedMessage(changed?: boolean): void {
    this.data.setVersionChanged(changed);
  }

  @action.bound
  async authenticate(loginModel: ILoginModel): Promise<StickerError | undefined> {
    const url = `${apiUrl}Authenticate`;
    const result = await this.apiService.postAsync<ILoginModel, string>(url, loginModel);

    if (result instanceof StickerError) return result;

    this.data.updateToken(result);

    return undefined;
  }

  @action.bound
  async logout() {
    this.browserService.clearCallBacks();
    this.data.clearToken();
    this.cryptoService.clearAll();
    window.location.replace('/');
  }

  @action.bound
  async register(registerModel: IRegisterModel) {
    const url = `${apiUrl}Register`;
    const result = await this.apiService.postAsync<IRegisterModel>(url, registerModel);

    if (result instanceof StickerError) return result;

    return undefined;
  }

  @action.bound
  async confirmEmail(confirmEmailModel: IConfirmEmailModel) {
    const url = `${apiUrl}ConfirmEmail`;
    const result = await this.apiService.postAsync<IConfirmEmailModel, string>(url, confirmEmailModel);

    if (result instanceof StickerError) return result;

    return undefined;
  }

  @action.bound
  async confirmNewEmail(confirmEmailModel: IConfirmEmailModel) {
    const url = `${apiUrl}ChangeEmail`;
    const result = await this.apiService.postAsync<IConfirmEmailModel, string>(url, confirmEmailModel);

    if (result instanceof StickerError) return result;

    return undefined;
  }

  @action.bound
  async resendConfirmationEmail(resendEmailModel: IResendEmailModel) {
    const url = `${apiUrl}ResendConfirmationEmail`;
    const result = await this.apiService.postAsync<IResendEmailModel, boolean>(url, resendEmailModel);

    if (result instanceof StickerError) return result;

    return undefined;
  }

  @action.bound
  async requestPasswordRequest(email: string) {
    const url = `${apiUrl}RequestResetPassword`;
    const result = await this.apiService.postAsync<{ email: string }>(url, { email });

    if (result instanceof StickerError) return result;

    return undefined;
  }

  @action.bound
  async resetPassword(token: string, newPassword: string) {
    const url = `${apiUrl}ResetPassword`;
    const result = await this.apiService.postAsync<{ token: string; newPassword: string }>(url, {
      token,
      newPassword,
    });

    if (result instanceof StickerError) return result;

    return undefined;
  }

  @autobind
  setRouteReferrer(route: LocationState): void {
    if (route !== undefined && !IGNORED_ROUTE.includes(route.from.pathname)) {
      const match = matchPath<{ workspaceId: string }>(route.from.pathname, { path: Home.Path });
      if (match && UUIDRegex.test(match.params.workspaceId)) {
        this.data.setRouteReferrer(route.from.pathname);
        return;
      }
    }
    this.data.setRouteReferrer('');
  }

  @autobind
  getAndClearReferrer(): string {
    const ref = this.data.routeReferrer;
    this.data.setRouteReferrer('');
    if (ref) return ref;

    const preferredWorkspaceId = this.workspaceService.store.preferredWorkspaceId;
    if (preferredWorkspaceId) return Home.Projects.List.All.withParams({ workspaceId: preferredWorkspaceId });

    const firstAvailable = this.workspaceService.store.workspaces[0].id;
    return Home.Projects.List.All.withParams({ workspaceId: firstAvailable });
  }
}
