import { computed, observable } from 'mobx';

import { IClarification } from './models/Clarifications.models';
import { SortingDirection } from '../../../../models/sortingDirection.model';
import { injectable } from 'inversify';

export const ClarificationsStoreType = Symbol('CLARIFICATIONS_STORE');
export const ClarificationsStoreSetterType = Symbol('CLARIFICATIONS_STORE_SETTER');

export interface IClarificationsStore {
  // data
  readonly question: string;
  readonly clarification: IClarification | undefined;
  readonly archiveClarifications: IClarification[];
  readonly hasUnansweredClarification: boolean;

  readonly clarificationsListSortDirection: SortingDirection;

  // conditions
  readonly canShowRequestClarification: boolean;
  readonly canShowClarify: boolean;
}

export interface IClarificationsStoreSetter extends IClarificationsStore {
  setArchiveClarifications(clarifications: IClarification[]): void;
  setClarificationsListSortDirection(sortDirection: SortingDirection): void;
  setQuestion(question: string): void;
  clear(): void;
}

@injectable()
export class ClarificationsStore implements IClarificationsStoreSetter {

  @computed
  get clarification(): IClarification | undefined {
      const notReadClarifications = this.archiveClarifications.filter(c => !c.isRead);

      if (!notReadClarifications.length) return undefined;

      return notReadClarifications.sort((a, b) => new Date(b.askedDate).getTime() - new Date(a.askedDate).getTime())[0];
  }

  @computed
  get hasUnansweredClarification(): boolean {
    return this.clarification?.answer === null;
  }

  @observable
  archiveClarifications: IClarification[] = [];

  @observable
  clarificationsListSortDirection: SortingDirection = SortingDirection.DESC;

  @observable
  question: string = '';

  @computed
  get canShowRequestClarification(): boolean {
    return this.clarification === undefined || this.clarification !== undefined && this.clarification.answer !== null;
  }

  @computed
  get canShowClarify(): boolean {
    return this.clarification !== undefined && !this.clarification.answer;
  }

  setArchiveClarifications(archiveClarifications: IClarification[]): void {
    this.archiveClarifications = archiveClarifications;
  }

  setClarificationsListSortDirection(sortDirection: SortingDirection): void {
    this.clarificationsListSortDirection = sortDirection;
  }

  setQuestion(question: string) {
    this.question = question;
  }

  clear(): void {
    this.archiveClarifications = [];
  }
}
