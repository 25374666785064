import 'react-picky/dist/picky.css';

import { Col, CustomInput, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { GTable, IGColumnProps } from '../../../../../components/table/GTable';
import {
  IProjectActivity,
  IProjectActivityAddAttribute,
  IProjectActivityAddAttributeAnswer,
  IProjectActivityAddAttributeScope,
  IProjectActivityAddDataset,
  IProjectActivityAddQuestion,
  IProjectActivityAddQuestionAnswer,
  IProjectActivityAddTool,
  IProjectActivityAddUser,
  IProjectActivityDescriptionChange,
  IProjectActivityDownloadExport,
  IProjectActivityExport,
  IProjectActivityImageAssignmentPolicyChange,
  IProjectActivityImport,
  IProjectActivityImportAnnotations,
  IProjectActivityRemoveAttribute,
  IProjectActivityRemoveAttributeAnswer,
  IProjectActivityRemoveAttributeScope,
  IProjectActivityRemoveDataset,
  IProjectActivityRemoveQuestion,
  IProjectActivityRemoveQuestionAnswer,
  IProjectActivityRemoveTool,
  IProjectActivityRemoveUser,
  IProjectActivityRename,
  IProjectActivityUpdateAttribute,
  IProjectActivityUpdateAttributeAnswer,
  IProjectActivityUpdateQuestion,
  IProjectActivityUpdateQuestionAnswer,
  IProjectActivityUpdateTool,
  ProjectActivity,
  allProjectActivities,
} from '../projectDetailsActivities.models';
import { ImageAssignmentPolicy, ToolDeleteStrategy } from '../../../projectDetails.models';
import React, { ChangeEvent } from 'react';

import { ExportDataFilter } from '../../../../projects/projects.model';
import { IPagingInfoWithOrder } from '../../../../../models/paginationInfo.model';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { Loader } from '../../../../../components/Loader';
import { Picky } from 'react-picky';
import { RenderSelectAllProps } from 'react-picky/dist/types';
import { ValueType } from 'react-select/lib/types';
import { as } from '../../../../../helpers/react.helpers';
import { toLocaleDateTimeString } from '../../../../../helpers/date.helpers';
import { withNamespaces } from 'react-i18next';

const LEGACY_EXPORT_SCHEMA = '1.5';

export interface IActivityLogProps {
  activities: IProjectActivity<any>[];
  isLoading: boolean;
  selectedActivities: { label: string; value: string }[];
  searchValue: string;
  paging: IPagingInfoWithOrder;
  onOrderingChange(orderBy: string, orderType: string): void;
  onPaginationChange(pageNumber: number, pageSize: number): void;
  onActorChange(value: string): void;
  onActivitiesChange(activities: ValueType<{ label: string; value: string }>): void;
  onExportDownload(backupId: string, schemaVersion: string, selectedDataFilter?: ExportDataFilter): Promise<void>;
}

class ActivityLogPure extends React.Component<IActivityLogProps & ITranslatable> {
  get columns(): IGColumnProps<IProjectActivity<any>>[] {
    return [
      {
        field: 'createdDate',
        headerName: this.props.t('date'),
        renderer: this.renderCreatedDate,
        width: 180,
      },
      {
        field: 'actor',
        headerName: this.props.t('actor'),
        width: 400,
      },
      {
        field: 'activity',
        headerName: this.props.t('activity'),
        sortable: false,
        renderer: this.activityRenderer,
        minWidth: 600,
      },
    ];
  }

  renderCreatedDate = (a: IProjectActivity<any>) => <>{toLocaleDateTimeString(a.createdDate)}</>;

  activityRenderer = (data: IProjectActivity<any>) => {
    switch (data.activity) {
      case ProjectActivity.DraftCreated: {
        return <span>{this.props.t('activity_description_DraftCreated')}.</span>;
      }
      case ProjectActivity.Rename: {
        const projectActivity: IProjectActivity<IProjectActivityRename> = data;
        return (
          <div className="word-wrap-and-break-line">
            {this.props.t('activity_description_Rename')}
            <b>{projectActivity.values.name}</b>.
          </div>
        );
      }
      case ProjectActivity.DescriptionChange: {
        const projectActivity: IProjectActivity<IProjectActivityDescriptionChange> = data;
        return (
          <div className="word-wrap-and-break-line">
            {this.props.t('activity_description_DescriptionChange')}
            <b>{projectActivity.values.description}</b>.
          </div>
        );
      }
      case ProjectActivity.AddDataset: {
        const projectActivity: IProjectActivity<IProjectActivityAddDataset> = data;
        return (
          <span>
            {this.props.t('activity_description_AddDataset')}
            <b>{projectActivity.values.name}</b>.
          </span>
        );
      }
      case ProjectActivity.RemoveDataset: {
        const projectActivity: IProjectActivity<IProjectActivityRemoveDataset> = data;
        return (
          <span>
            {this.props.t('activity_description_RemoveDataset')}
            <b>{projectActivity.values.name}</b>.
          </span>
        );
      }
      case ProjectActivity.AddTool: {
        const projectActivity: IProjectActivity<IProjectActivityAddTool> = data;
        return (
          <span>
            {this.props.t('activity_description_AddTool')}
            <b>{projectActivity.values.name}</b>.
          </span>
        );
      }
      case ProjectActivity.UpdateTool: {
        const projectActivity: IProjectActivity<IProjectActivityUpdateTool> = data;
        const values = projectActivity.values;

        const properties = [];
        if (values.originalName !== values.name) {
          properties.push({
            name: 'activity_description_UpdateTool.name',
            original: values.originalName,
            current: values.name,
          });
        }
        if (values.originalColor !== values.color) {
          properties.push({
            name: 'activity_description_UpdateTool.color',
            original: values.originalColor,
            current: values.color,
          });
        }
        if (values.originalSelectorType !== values.selectorType) {
          properties.push({
            name: 'activity_description_UpdateTool.selectorType',
            original: this.props.t(values.originalSelectorType.toLocaleLowerCase()),
            current: this.props.t(values.selectorType.toLocaleLowerCase()),
          });
        }
        if (values.originalOrder !== values.order) {
          properties.push({
            name: 'activity_description_UpdateTool.order',
            original: values.originalOrder.toString(),
            current: values.order.toString(),
          });
        }

        return (
          <span>
            {this.props.t('activity_description_UpdateTool.start')}
            <b>{values.originalName}</b>
            {this.props.t('activity_description_UpdateTool.changed')}
            {properties.map(x => this.props.t(x.name)).join(', ')} {this.props.t('activity_description_common.from')} <b>{properties.map(x => x.original).join(', ')}</b>{' '}
            {this.props.t('activity_description_common.to')} <b>{properties.map(x => x.current).join(', ')}</b>.
          </span>
        );
      }
      case ProjectActivity.RemoveTool: {
        const projectActivity: IProjectActivity<IProjectActivityRemoveTool> = data;
        return (
          <span>
            {this.props.t('activity_description_RemoveTool')}
            <b>{projectActivity.values.name}</b>
            {this.renderRelatedAnnotationsCount(projectActivity.values.strategy, projectActivity.values.updatedAnnotationsCount)}.
          </span>
        );
      }
      case ProjectActivity.AddQuestion: {
        const projectActivity: IProjectActivity<IProjectActivityAddQuestion> = data;
        return (
          <span>
            {this.props.t('activity_description_AddQuestion')}
            <b>{projectActivity.values.question}</b>.
          </span>
        );
      }
      case ProjectActivity.UpdateQuestion: {
        const projectActivity: IProjectActivity<IProjectActivityUpdateQuestion> = data;
        const values = projectActivity.values;

        const properties = [];
        if (values.question !== values.originalQuestion) {
          properties.push({
            name: 'activity_description_common.name',
            original: values.originalQuestion,
            current: values.question,
          });
        }
        if (values.answerType !== values.originalAnswerType) {
          properties.push({
            name: 'activity_description_common.answerType',
            original: this.props.t(`${values.originalAnswerType.toLocaleLowerCase()}_question_type`),
            current: this.props.t(`${values.answerType.toLocaleLowerCase()}_question_type`),
          });
        }
        if (values.order !== values.originalOrder) {
          properties.push({
            name: 'activity_description_common.order',
            original: values.originalOrder,
            current: values.order,
          });
        }
        if (values.isRequired !== values.originalIsRequired) {
          properties.push({
            name: 'activity_description_common.isRequired',
            original: values.originalIsRequired,
            current: values.isRequired,
          });
        }

        return (
          <span>
            {this.props.t('activity_description_UpdateQuestion')}
            <b>{values.originalQuestion}</b>
            {this.props.t('activity_description_common.changed')}
            {properties.map(x => this.props.t(x.name)).join(', ')} {this.props.t('activity_description_common.from')} <b>{properties.map(x => x.original).join(', ')}</b>{' '}
            {this.props.t('activity_description_common.to')} <b>{properties.map(x => x.current).join(', ')}</b>.
          </span>
        );
      }
      case ProjectActivity.RemoveQuestion: {
        const projectActivity: IProjectActivity<IProjectActivityRemoveQuestion> = data;
        return (
          <span>
            {this.props.t('activity_description_RemoveQuestion')}
            <b>{projectActivity.values.question}</b>
            {this.renderRelatedAnnotationsCount(projectActivity.values.strategy, projectActivity.values.updatedAnnotationsCount)}.
          </span>
        );
      }
      case ProjectActivity.AddUser: {
        const projectActivity: IProjectActivity<IProjectActivityAddUser> = data;
        return (
          <span>
            {this.props.t('activity_description_AddUser_01')}
            <b>{projectActivity.values.email}</b>
            {this.props.t('activity_description_AddUser_02')}
            <b>{projectActivity.values.role}</b>.
          </span>
        );
      }
      case ProjectActivity.RemoveUser: {
        const projectActivity: IProjectActivity<IProjectActivityRemoveUser> = data;
        return (
          <span>
            {this.props.t('activity_description_RemoveUser_01')}
            <b>{projectActivity.values.email}</b>
            {this.props.t('activity_description_RemoveUser_02')}.
          </span>
        );
      }
      case ProjectActivity.Publish: {
        return <span>{this.props.t('activity_description_Publish')}.</span>;
      }
      case ProjectActivity.Export: {
        const projectActivity: IProjectActivity<IProjectActivityExport> = data;
        return (
          <span className="allow-new-lines">
            {projectActivity.values.isGeneratedDuringImport ? (
              <>
                {this.props.t('activity_description_Export_during_import', { ...projectActivity.values })}
                {projectActivity.values.isExportReady ? this.props.t('activity_description_Export_annotated_accepted', { ...projectActivity.values }) : ''}{' '}
              </>
            ) : (
              <>
                {this.props.t(
                  projectActivity.values.configurationVersion
                    ? projectActivity.values.setVersion
                      ? 'activity_description_Export_01'
                      : 'activity_description_Export_01_no_set'
                    : 'activity_description_Export_01_legacy',
                  { ...projectActivity.values },
                )}
                {projectActivity.values.isExportReady ? this.props.t('activity_description_Export_annotated_accepted', { ...projectActivity.values }) : ''}
              </>
            )}
          </span>
        );
      }
      case ProjectActivity.DownloadExport: {
        const projectActivity: IProjectActivity<IProjectActivityDownloadExport> = data;
        const translatedDataFilterType = this.props.t(`activity_description_Download_Export.filter_types.${projectActivity.values.dataFilterType}`);
        return (
          <span className="allow-new-lines">
            {this.props.t('activity_description_Download_Export.body', { ...projectActivity.values, selectedFilter: translatedDataFilterType })}
          </span>
        );
      }
      case ProjectActivity.Import: {
        const projectActivity: IProjectActivity<IProjectActivityImport> = data;
        return (
          <span>
            {projectActivity.values.wasAnnotationsImported ? this.props.t('activity_description_Import_with_Annotation') : this.props.t('activity_description_Import')}
            {projectActivity.values.fileName ? (
              <>
                {this.props.t('activity_description_Import_from_file')}
                <b>{projectActivity.values.fileName}</b>
              </>
            ) : (
              ''
            )}
            .
          </span>
        );
      }
      case ProjectActivity.ImportAnnotations: {
        const projectActivity: IProjectActivity<IProjectActivityImportAnnotations> = data;
        return <span className="allow-new-lines">{this.props.t('activity_description_Import_Annotations', { ...projectActivity.values })}</span>;
      }
      case ProjectActivity.AddAttribute: {
        const projectActivity: IProjectActivity<IProjectActivityAddAttribute> = data;
        return (
          <span>
            {this.props.t('activity_description_AddAttribute')}
            <b>{projectActivity.values.attribute}</b>.
          </span>
        );
      }
      case ProjectActivity.UpdateAttribute: {
        const projectActivity: IProjectActivity<IProjectActivityUpdateAttribute> = data;
        const values = projectActivity.values;

        const properties = [];
        if (values.attribute !== values.originalAttribute) {
          properties.push({
            name: 'activity_description_common.name',
            original: values.originalAttribute,
            current: values.attribute,
          });
        }
        if (values.answerType !== values.originalAnswerType) {
          properties.push({
            name: 'activity_description_common.answerType',
            original: this.props.t(`${values.originalAnswerType.toLocaleLowerCase()}_question_type`),
            current: this.props.t(`${values.answerType.toLocaleLowerCase()}_question_type`),
          });
        }
        if (values.order !== values.originalOrder) {
          properties.push({
            name: 'activity_description_common.order',
            original: values.originalOrder,
            current: values.order,
          });
        }
        if (values.isRequired !== values.originalIsRequired) {
          properties.push({
            name: 'activity_description_common.isRequired',
            original: values.originalIsRequired,
            current: values.isRequired,
          });
        }

        return (
          <span>
            {this.props.t('activity_description_UpdateAttribute')}
            <b>{values.originalAttribute}</b>
            {this.props.t('activity_description_common.changed')}
            {properties.map(x => this.props.t(x.name)).join(', ')} {this.props.t('activity_description_common.from')} <b>{properties.map(x => x.original).join(', ')}</b>{' '}
            {this.props.t('activity_description_common.to')} <b>{properties.map(x => x.current).join(', ')}</b>.
          </span>
        );
      }
      case ProjectActivity.RemoveAttribute: {
        const projectActivity: IProjectActivity<IProjectActivityRemoveAttribute> = data;
        return (
          <span>
            {this.props.t('activity_description_RemoveAttribute')}
            <b>{projectActivity.values.attribute}</b>
            {this.renderRelatedAnnotationsCount(projectActivity.values.strategy, projectActivity.values.updatedAnnotationsCount)}.
          </span>
        );
      }
      case ProjectActivity.AddAttributeAnswer: {
        const projectActivity: IProjectActivity<IProjectActivityAddAttributeAnswer> = data;
        return (
          <span>
            {this.props.t('activity_description_common.addAnswer')}
            <b>{projectActivity.values.answer}</b> {this.props.t('activity_description_common.to')} {this.props.t('activity_description_common.question')}{' '}
            <b>{projectActivity.values.attribute}</b>.
          </span>
        );
      }
      case ProjectActivity.UpdateAttributeAnswer: {
        const projectActivity: IProjectActivity<IProjectActivityUpdateAttributeAnswer> = data;
        return (
          <span>
            {this.props.t('activity_description_common.updateAnswer')}
            <b>{projectActivity.values.originalAnswer}</b> {this.props.t('activity_description_common.to')} <b>{projectActivity.values.answer}</b>{' '}
            {this.props.t('activity_description_common.from')} {this.props.t('activity_description_common.attribute')} <b>{projectActivity.values.attribute}</b>.
          </span>
        );
      }
      case ProjectActivity.RemoveAttributeAnswer: {
        const projectActivity: IProjectActivity<IProjectActivityRemoveAttributeAnswer> = data;
        return (
          <span>
            {this.props.t('activity_description_common.removeAnswer')}
            <b>{projectActivity.values.answer}</b> {this.props.t('activity_description_common.from')} {this.props.t('activity_description_common.attribute')}{' '}
            <b>{projectActivity.values.attribute}</b>
            {this.renderRelatedAnnotationsCount(projectActivity.values.strategy, projectActivity.values.updatedAnnotationsCount)}.
          </span>
        );
      }
      case ProjectActivity.AddAttributeScope: {
        const projectActivity: IProjectActivity<IProjectActivityAddAttributeScope> = data;
        return (
          <span>
            {this.props.t('activity_description_AddAttributeScope')}
            <b>{projectActivity.values.scope}</b> {this.props.t('activity_description_common.to')} {this.props.t('activity_description_common.attribute')}{' '}
            <b>{projectActivity.values.attribute}</b>.
          </span>
        );
      }
      case ProjectActivity.RemoveAttributeScope: {
        const projectActivity: IProjectActivity<IProjectActivityRemoveAttributeScope> = data;
        return (
          <span>
            {this.props.t('activity_description_RemoveAttributeScope')}
            <b>{projectActivity.values.scope}</b> {this.props.t('activity_description_common.from')} {this.props.t('activity_description_common.attribute')}{' '}
            <b>{projectActivity.values.attribute}</b>
            {this.renderRelatedAnnotationsCount(projectActivity.values.strategy, projectActivity.values.updatedAnnotationsCount)}.
          </span>
        );
      }
      case ProjectActivity.AddQuestionAnswer: {
        const projectActivity: IProjectActivity<IProjectActivityAddQuestionAnswer> = data;
        return (
          <span>
            {this.props.t('activity_description_common.addAnswer')}
            <b>{projectActivity.values.answer}</b> {this.props.t('activity_description_common.to')} {this.props.t('activity_description_common.question')}{' '}
            <b>{projectActivity.values.question}</b>.
          </span>
        );
      }
      case ProjectActivity.UpdateQuestionAnswer: {
        const projectActivity: IProjectActivity<IProjectActivityUpdateQuestionAnswer> = data;
        return (
          <span>
            {this.props.t('activity_description_common.updateAnswer')}
            <b>{projectActivity.values.originalAnswer}</b> {this.props.t('activity_description_common.to')} <b>{projectActivity.values.answer}</b>{' '}
            {this.props.t('activity_description_common.from')} {this.props.t('activity_description_common.question')} <b>{projectActivity.values.question}</b>.
          </span>
        );
      }
      case ProjectActivity.RemoveQuestionAnswer: {
        const projectActivity: IProjectActivity<IProjectActivityRemoveQuestionAnswer> = data;
        return (
          <span>
            {this.props.t('activity_description_common.removeAnswer')}
            <b>{projectActivity.values.answer}</b> {this.props.t('activity_description_common.from')} {this.props.t('activity_description_common.question')}{' '}
            <b>{projectActivity.values.question}</b>
            {this.renderRelatedAnnotationsCount(projectActivity.values.strategy, projectActivity.values.updatedAnnotationsCount)}.
          </span>
        );
      }
      case ProjectActivity.ChangeImageAssignmentPolicy: {
        const projectActivity: IProjectActivity<IProjectActivityImageAssignmentPolicyChange> = data;
        return (
          <div className="word-wrap-and-break-line">
            {this.props.t('activity_description_ImageAssignmentPolicyChange')}
            <b> {`${this.translateImageAssignmentPolicy(projectActivity.values.policy as ImageAssignmentPolicy)}.`} </b>
          </div>
        );
      }
      default:
        return <span>Undefined action</span>;
    }
  };

  renderRelatedAnnotationsCount = (strategy: ToolDeleteStrategy, count: number) => {
    switch (strategy) {
      case ToolDeleteStrategy.Leave:
        return <span> {this.props.t('activity_description_common.related_annotations_left', { count })}</span>;
      case ToolDeleteStrategy.MoveToDraft:
        return <span> {this.props.t('activity_description_common.related_annotations_draft', { count })}</span>;
      case ToolDeleteStrategy.MoveToReview:
        return <span> {this.props.t('activity_description_common.related_annotations_to_review', { count })}</span>;
      case ToolDeleteStrategy.Discard:
        return <span> {this.props.t('activity_description_common.related_annotations_discarded', { count })}</span>;
      default:
        return <span />;
    }
  };

  translateImageAssignmentPolicy = (policy: ImageAssignmentPolicy) => {
    switch (policy) {
      case ImageAssignmentPolicy.RANDOM:
        return this.props.t('image_assignment_policy_random');
      case ImageAssignmentPolicy.SEQUENTIAL:
        return this.props.t('image_assignment_policy_sequential');
      case ImageAssignmentPolicy.PARALLEL:
        return this.props.t('image_assignment_policy_parallel');
    }
  };

  onSortChanged = (orderBy: string, orderType: string) => this.props.onOrderingChange(orderBy, orderType);

  onActivityTypesChanges = (values: any) => this.props.onActivitiesChange(values);

  searchChange = (event: ChangeEvent<HTMLInputElement>) => this.props.onActorChange(event.target.value);

  renderOption = ({ style, isSelected, item, selectValue, labelKey, valueKey }: any) => {
    const onClick = (e: any) => {
      selectValue(item);
      e.preventDefault();
    };
    return (
      <li style={style} className={`picky-option ${isSelected ? 'selected' : 'not-selected'}`} key={`${item[valueKey]}-li`} onClick={onClick}>
        <CustomInput id={`${item[valueKey]}-custom-input`} key={item[valueKey]} type="checkbox" checked={isSelected} label={item[labelKey]} disabled={false} readOnly={true} />
      </li>
    );
  };

  renderSelectAll = ({ filtered, tabIndex, allSelected, toggleSelectAll, multiple }: RenderSelectAllProps) => {
    if (multiple && !filtered) {
      return (
        <div className="picky-select-all">
          <CustomInput
            id="select-all"
            type="checkbox"
            checked={allSelected === 'all'}
            label={this.props.t('select_all')}
            disabled={false}
            readOnly={true}
            onClick={toggleSelectAll}
          />
        </div>
      );
    }
  };

  doNothing = (e: any) => e.preventDefault();

  getExportDownloadClickHandler = (backupId: string, schemaVersion: string) => async () => {
    if (schemaVersion === LEGACY_EXPORT_SCHEMA) {
      this.props.onExportDownload(backupId, schemaVersion);
    } else {
      await this.props.onExportDownload(backupId, schemaVersion, ExportDataFilter.ALL);
    }
  };

  render() {
    const options = allProjectActivities.map(o => ({ label: this.props.t(`activity_type_${o}`), value: o.toString() }));
    return (
      <>
        <div className="activity-search">
          <Form onSubmit={this.doNothing} className="custom-inline-form add-new-user">
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label>{this.props.t('actor')}</Label>
                  <Input id="user-dropdown" name="select" value={this.props.searchValue} placeholder={this.props.t('actor')} onChange={this.searchChange} />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>{this.props.t('activity_type')}</Label>
                  <Picky
                    id="picky"
                    options={options}
                    value={this.props.selectedActivities}
                    multiple={true}
                    includeSelectAll={true}
                    includeFilter={false}
                    onChange={this.onActivityTypesChanges}
                    dropdownHeight={600}
                    labelKey="label"
                    valueKey="value"
                    render={this.renderOption}
                    renderSelectAll={this.renderSelectAll}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <div>
            <Loader isLoading={this.props.isLoading}>
              <GTable<IProjectActivity<any>>
                columns={this.columns}
                items={this.props.activities}
                paginationProps={{
                  pageNumber: this.props.paging.pageNumber,
                  pageSize: this.props.paging.pageSize,
                  totalCount: this.props.paging.totalCount,
                  onChange: this.props.onPaginationChange,
                }}
                onSortChanged={this.onSortChanged}
                sortingModel={[{ orderBy: this.props.paging.orderBy || '', orderType: this.props.paging.orderType || '' }]}
              />
            </Loader>
          </div>
        </div>
      </>
    );
  }
}

export const ActivityLog = as<React.ComponentClass<IActivityLogProps>>(withNamespaces('common', { wait: true })(ActivityLogPure));
