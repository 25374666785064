import * as React from 'react';

import AttachmentImagePreviewContainer from '../../../modules/attachments/containers/AttachmentImagePreview.container';
import AttachmentsPreviewHeaderContainer from '../../../modules/attachments/containers/AttachmentsPreviewHeader.container';
import { DashboardContainer } from '../../../containers/DashboardContainer';

const Header = () => (
  <AttachmentsPreviewHeaderContainer />
);

export const AttachmentImagePreviewView = () => (
    <div>
      <DashboardContainer header={Header} className={'no-padding'}>
        <AttachmentImagePreviewContainer />
      </DashboardContainer>
    </div>
);
