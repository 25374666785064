import * as React from 'react';

import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../../modules/common/OverlayLoader.store';
import { as, injectProps } from '../../../helpers/react.helpers';
import { faEye, faUnlink } from '@fortawesome/free-solid-svg-icons';

import { IProjectDataset } from '../projectDetails.models';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { ProjectDetailsDatasetsTable } from './ProjectDetailsDatasetsTable';
import { UnlinkDatasetModal } from './UnlinkDatasetModal';
import _ from 'lodash';
import autobind from 'autobind-decorator';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

const loaderId = 'unlink-dataset-button';

library.add(faUnlink, faEye);

interface IProjectDetailsDatasetsListProps {
  workspaceId: string;
  projectDatasets: IProjectDataset[];
  isLoading: boolean;
  onDatasetUnlinked(datasetId: string): Promise<any>;
  canViewDataset: boolean;
  canUnlinkDataset: boolean;
  onDatasetOrderChange(from: number, to: number): void;
  canEditDatasetOrder: boolean;
}

interface IState {
  showModal: boolean;
  datasetId?: string;
}

interface IInjectedProps {
  overlayLoaderStore: IOverlayLoaderStore;
}

@injectProps({
  overlayLoaderStore: OverlayLoaderStoreType,
})
@observer
class ProjectDetailsDatasetsListPure extends React.Component<IProjectDetailsDatasetsListProps & ITranslatable & IInjectedProps, IState> {
  state: IState = {
    showModal: false
  };

  @autobind
  toggleModal(id: string) {
    this.setState({
      showModal: true,
      datasetId: id,
    });
  }

  @autobind
  hideModal() {
    this.setState({
      showModal: false,
      datasetId: undefined,
    });
  }

  @autobind
  handleConfirm() {
    this.props.overlayLoaderStore.enableLoader(loaderId);
    this.props.onDatasetUnlinked(this.state.datasetId!).then(() => {
      this.hideModal();
      this.props.overlayLoaderStore.disableLoader(loaderId);
    });
  }

  @autobind
  handleCancel() {
    this.hideModal();
    this.props.overlayLoaderStore.disableLoader(loaderId);
  }

  render() {
    return (
      <>
        <ProjectDetailsDatasetsTable
          workspaceId={this.props.workspaceId}
          projectDatasets={this.props.projectDatasets}
          isLoading={this.props.isLoading}
          onUnlink={this.toggleModal}
          canViewDataset={this.props.canViewDataset}
          canUnlinkDataset={this.props.canUnlinkDataset}
          canEditDatasetOrder={this.props.canEditDatasetOrder}
          onDatasetOrderChange={this.props.onDatasetOrderChange}
        />
        <UnlinkDatasetModal show={this.state.showModal} hideModal={this.hideModal} onConfirm={this.handleConfirm} onCancel={this.handleCancel} />
      </>
    );
  }
}

export const ProjectDetailsDatasetsList = as<React.ComponentClass<IProjectDetailsDatasetsListProps>>(withNamespaces('project', { wait: true })(ProjectDetailsDatasetsListPure));
