import { BaseRouteConfig, StickerRoute } from './BaseRoute';

export class DatasetsPath extends BaseRouteConfig {
  public get Index(): string {
    return 'datasets';
  }

  public readonly List = new StickerRoute<{ workspaceId: string }>(`${this.Path}/list`);

  public readonly Create = new StickerRoute<{ workspaceId: string, datasetDraftId: string }>(`${this.Path}/create/:datasetDraftId?`);

  public readonly Details = {
    Path: `${this.Path}/:datasetId`,
    Paths: new StickerRoute<{ workspaceId: string; datasetId: string; tab: string }>(`${this.Path}/:datasetId/:tab`),
    Images: new StickerRoute<{ workspaceId: string; datasetId: string, page?: number }>(`${this.Path}/:datasetId/images/:page?`),
    Preview: new StickerRoute<{ workspaceId: string; datasetId: string, imageId: string }>(`${this.Path}/:datasetId/preview/:imageId`),
    Projects: new StickerRoute<{ workspaceId: string; datasetId: string }>(`${this.Path}/:datasetId/projects`),
  };
}
