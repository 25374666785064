import * as React from 'react';

import { ILoaderState, WithLoaderComponentBase } from '../../../../../helpers/loader.helpers';
import { IProjectDetailsToolsBl, IProjectDetailsToolsBlType } from '../projectDetailsTools.bl';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { AnnotationTypeDelete } from '../components/AnnotationTypeDelete';
import { IAnnotationType } from '../projectDetailsTools.models';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { ToolDeleteStrategy } from '../../../projectDetails.models';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps {
  projectDetailsToolsBl: IProjectDetailsToolsBl;
}

interface IOuterProps {
  isUsedAsScope: boolean;
  canEdit: boolean;
  annotationType: IAnnotationType;
}

interface IState extends ILoaderState {
  showModal: boolean;
  showDiscardConfirmModal: boolean;
  strategy: ToolDeleteStrategy;
}

@injectProps({ projectDetailsToolsBl: IProjectDetailsToolsBlType })
@observer
class AnnotationTypeDeleteContainerPure extends WithLoaderComponentBase<IInjectedProps & IOuterProps, IState> {
  constructor(props: IInjectedProps & IOuterProps & ITranslatable) {
    super(props);

    this.state = {
      strategy: ToolDeleteStrategy.None,
      showModal: false,
      showDiscardConfirmModal: false,
      isLoading: false,
    };
  }

  @autobind
  async handleDeleteConfirmed() {
    if (this.state.strategy === ToolDeleteStrategy.Discard) {
      await this.withLoaderAsync(async () => {
        await this.props.projectDetailsToolsBl.getAnnotationTypeAffectAsync(this.props.annotationType.id);
        this.setState({ showDiscardConfirmModal: true });
      }, 'tool-delete-confirm-button');
    } else {
      await this.handleDeleteAnnotationTypeAsync();
    }
  }

  @autobind
  async handleDeleteAnnotationTypeAsync() {
    await this.withLoaderAsync(async () => {
      await this.props.projectDetailsToolsBl.deleteAnnotationTypeAsync(this.props.annotationType.id, this.state.strategy);
      this.setState({ showDiscardConfirmModal: false });
    }, 'tool-delete-confirm-button');
  }

  @autobind
  async handleCancel() {
    if (!this.state.isLoading) {
      this.setState({ showModal: false });
    }
  }

  @autobind
  async handleCancelDiscard() {
    if (!this.state.isLoading) {
      this.setState({ showDiscardConfirmModal: false });
    }
  }

  @autobind
  async handleDelete() {
    this.withLoaderAsync(async () => {
      await this.props.projectDetailsToolsBl.getAnnotationTypeUsageAsync(this.props.annotationType.id);
      this.setState({ showModal: true });
    });
  }

  @autobind
  async handleStrategyChange(strategy: ToolDeleteStrategy) {
    this.setState({ strategy });
  }

  render() {
    const toolUsage = this.props.projectDetailsToolsBl.store.toolsUsage;
    const toolAffect = this.props.projectDetailsToolsBl.store.toolAffect;

    return (
      <AnnotationTypeDelete
        canEdit={this.props.canEdit}
        isLoading={this.state.isLoading}
        showModal={this.state.showModal}
        isUsedAsScope={this.props.isUsedAsScope}
        showDiscardConfirmModal={this.state.showDiscardConfirmModal}
        strategy={this.state.strategy}
        toolUsage={toolUsage}
        onCancel={this.handleCancel}
        onCancelDiscard={this.handleCancelDiscard}
        onDelete={this.handleDelete}
        onConfirmDiscard={this.handleDeleteAnnotationTypeAsync}
        onDeleteConfirmed={this.handleDeleteConfirmed}
        onStrategyChange={this.handleStrategyChange}
        toolAffect={toolAffect}
      />
    );
  }
}

export const AnnotationTypeDeleteContainer = as<React.ComponentClass<IOuterProps>>(AnnotationTypeDeleteContainerPure);
