import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import React, { FormEvent } from 'react';

import { ButtonWithLoader } from '../../../../../components/ButtonWithLoader';
import { IQuestionModel } from '../../../../annotation/question.model';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { ProgressBar } from '../../../../../components/ProgressBar';
import QuestionWithAnswers from '../../../../annotation/components/annotations/QuestionWithAnswers';
import { as } from '../../../../../helpers/react.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IProps {
  isModalOpen: boolean;
  disabled: boolean;
  projectHasNoQuestions: boolean;
  batchAnswerQuestionsRequestInProgress: boolean;
  batchAnswerBatchesCount: number;
  batchAnswerSuccessfulBatches: number;
  batchAnswerFailedBatches: number;
  questions: ReadonlyArray<IQuestionModel>;
  batchAnswerQuestionsModalError: string | undefined;
  ownerAccountIsChargeable: boolean;
  onAnswered(questionId: string, answer: string): void;
  onSubmit(): Promise<void>;
  onModalShow(): void;
  onModalClose(): void;
}

@observer
class BatchAnswerQuestionsPure extends React.Component<IProps & ITranslatable> {
  preventSubmit = (e: FormEvent<HTMLFormElement>) => e.preventDefault();

  questionsBody = (props: IProps) => (
    <div>
      <Row>
        <Col md={12}>{this.props.t('answer_questions_modal_header')}</Col>
      </Row>
      <Row>
        <Col md={12} className="question-list">
          <Form onSubmit={this.preventSubmit}>
            {props.questions.map(q => (
              <QuestionWithAnswers key={q.id} question={q} disabled={false} isAttribute={false} onAnswered={this.props.onAnswered} />
            ))}
          </Form>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <p className="error-feedback margin-top-10">{props.batchAnswerQuestionsModalError !== undefined && this.props.t(props.batchAnswerQuestionsModalError)}</p>
        </Col>
      </Row>
    </div>
  );

  questionsFooter = (props: IProps) => {
    return (
      <>
        <ButtonWithLoader isLoading={props.batchAnswerQuestionsRequestInProgress} color="success" className="mr-2" onClick={this.props.onSubmit}>
          <div className={props.batchAnswerQuestionsRequestInProgress ? 'batch-answer-submit' : ''}>{this.props.t('submit')}</div>
        </ButtonWithLoader>

        <Button outline color="primary" className="ml-1" onClick={this.props.onModalClose}>
          {this.props.t('cancel')}
        </Button>
      </>
    );
  };

  inProgressBody = (props: IProps) => {
    const isAnweringFinished =
      this.props.batchAnswerBatchesCount !== 0 && this.props.batchAnswerSuccessfulBatches + this.props.batchAnswerFailedBatches === this.props.batchAnswerBatchesCount;

    return (
      <div>
        <Row>
          <Col>{this.props.t('answering_started')}</Col>
        </Row>
        <Row className="progress-row margin-top-26">
          <Col>
            <div className="batch-progress">
              <b>{this.props.t(isAnweringFinished ? 'answering_done' : 'answering_progress')}</b>
            </div>
            {this.props.batchAnswerBatchesCount === 0 ? (
              <ProgressBar value={0} max={100} />
            ) : (
              <ProgressBar disableGrowAnimation value={this.props.batchAnswerSuccessfulBatches + this.props.batchAnswerFailedBatches} max={this.props.batchAnswerBatchesCount} />
            )}
          </Col>
        </Row>
        <Row className="margin-top-26 batch-error">
          <Col>
            {this.props.batchAnswerFailedBatches > 0 && <span>{this.props.t('batch_answer_questions_failed_error')}</span>}
            {this.props.ownerAccountIsChargeable && this.props.batchAnswerFailedBatches > 0 && <span> {this.props.t('refunded_credits')}</span>}
          </Col>
        </Row>
      </div>
    );
  };

  inProgressFooter = (props: IProps) => {
    return (
      <Button color="primary" onClick={this.props.onModalClose}>
        {this.props.t('close')}
      </Button>
    );
  };

  render() {
    const titleKey = this.props.projectHasNoQuestions
      ? 'project_has_no_questions'
      : this.props.disabled
      ? 'at_least_on_image_must_be_selected'
      : 'answer_questions_for_selected_images';

    return (
      <>
        <Button disabled={this.props.disabled} onClick={this.props.onModalShow} id={'answer-questions-button'} title={this.props.t(titleKey)}>
          {this.props.t('answer_questions')}
        </Button>
        <Modal className="batch-answer-modal" isOpen={this.props.isModalOpen} toggle={this.props.onModalClose}>
          <ModalHeader toggle={this.props.onModalClose}>{this.props.t('batch_questions_modal_header')}</ModalHeader>
          <ModalBody>
            <div>{this.props.batchAnswerQuestionsRequestInProgress ? this.inProgressBody(this.props) : this.questionsBody(this.props)}</div>
          </ModalBody>
          <ModalFooter>
            <div>{this.props.batchAnswerQuestionsRequestInProgress ? this.inProgressFooter(this.props) : this.questionsFooter(this.props)}</div>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export const BatchAnswerQuestions = as<React.ComponentClass<IProps>>(withNamespaces('common')(BatchAnswerQuestionsPure));
