import { WithNamespaces, withNamespaces } from 'react-i18next';

import { PaginationLink } from 'reactstrap';
import React from 'react';

interface Props {
  onClick(): void;
}

const S_PaginationFirstPageButtonPure: React.FC<Props & WithNamespaces> = ({ onClick, t }) => <PaginationLink onClick={onClick}>{t('pagination.first_page')}</PaginationLink>;

export const S_PaginationFirstPageButton = withNamespaces('design')(S_PaginationFirstPageButtonPure);
