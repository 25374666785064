import { IProjectStatsFiltersStore, ProjectStatsFiltersStoreType } from '../filters/ProjectStatsFilters.store';
import { as, injectProps } from '../../../../../__legacy__/helpers/react.helpers';

import { GeneralSection } from './GeneralSection';
import React from 'react';
import { WithLoaderComponentBase } from '../../../../../__legacy__/helpers/loader.helpers';
import { observer } from 'mobx-react';

interface InjectedProps {
  filtersStore: IProjectStatsFiltersStore;
}

@injectProps({
  filtersStore: ProjectStatsFiltersStoreType,
})
@observer
class GeneralSectionContainerPure extends WithLoaderComponentBase<InjectedProps> {
  render(): React.ReactNode {
    return (
      <GeneralSection
        endDate={this.props.filtersStore.endDate}
        startDate={this.props.filtersStore.startDate}
      />
    );
  }
}

export const GeneralSectionContainer = as<React.ComponentClass>(GeneralSectionContainerPure);
