import { Container, Row } from 'reactstrap';
import { faArrowAltCircleLeft, faBan, faShare, faUserSlash } from '@fortawesome/free-solid-svg-icons';

import { DiscardAnnotationConfirmationModal } from './DiscardAnnotationConfirmationModal';
import { EditorMode } from '../../../../modules/editor/models/EditorModes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { NavigationButton } from './NavigationButton';
import { QuitAnnotationsModal } from './QuitAnnotationsModal';
import React from 'react';
import RequestClarificationButtonContainer from '../submodules/clarifications/containers/RequestClarificationButton.container';
import { SubmitAnnotationButtonContainer } from '../containers/SubmitAnnotationButton.container';
import { ValidSaveIcon } from '../../../components/icons/ValidSaveIcon';
import { as } from '../../../helpers/react.helpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';

library.add(faUserSlash, faBan, faArrowAltCircleLeft, faShare);

export interface IAnnotationNavigationComponentProps {
  onSubmit(): void;
  onSave(): void;
  onSaveAsDraft(): void;
  onAbandonDraft(): void;
  onSkip(): void;
  onDiscardConfirmed(): void;
  onDiscardCanceled(): void;
  onDiscard(): void;
  onCancel(): void;
  onCancelCancel(): void;
  onConfirmCancel(): void;

  disabled: boolean;
  showDiscardModal: boolean;
  showCancelModal: boolean;

  hideSkip: boolean;
  hideDraft: boolean;
  hideAbandonDraft: boolean;

  canCancel: boolean;
  canDiscard: boolean;
  canSave: boolean;
  canSubmit: boolean;
  editorMode: EditorMode;
}

class AnnotationNavigationPure extends React.Component<IAnnotationNavigationComponentProps & ITranslatable> {
  render() {
    return (
      <Container fluid className="annotation-navigation">
        <DiscardAnnotationConfirmationModal
          showDiscardModal={this.props.showDiscardModal}
          onDiscardCancel={this.props.onDiscardCanceled}
          onDiscardConfirm={this.props.onDiscardConfirmed}
        />
        <QuitAnnotationsModal isOpen={this.props.showCancelModal} onCancel={this.props.onCancelCancel} onConfirm={this.props.onConfirmCancel} />
        <Row>
          <div>
            {this.props.canCancel && (
              <NavigationButton loaderKey="cancel-annotation-button" disabled={this.props.disabled} onClick={this.props.onCancel} title={'cancel_tooltip'} caption="cancel">
                <FontAwesomeIcon icon={faArrowAltCircleLeft} />
              </NavigationButton>
            )}
            {!this.props.hideAbandonDraft && (
              <NavigationButton
                loaderKey="abandon-annotation-button"
                disabled={this.props.disabled}
                onClick={this.props.onAbandonDraft}
                title={'abandon_draft_tooltip'}
                caption="abandon_draft"
              >
                <FontAwesomeIcon icon={faUserSlash} />
              </NavigationButton>
            )}
            {!this.props.hideSkip && (
              <NavigationButton loaderKey="skip-annotation-button" disabled={this.props.disabled} onClick={this.props.onSkip} title={'skip_tooltip'} caption="skip">
                <FontAwesomeIcon icon={faShare} />
              </NavigationButton>
            )}
            {this.props.canDiscard && (
              <NavigationButton
                id="discard-annotation"
                loaderKey="discard-annotation-button"
                disabled={this.props.disabled}
                onClick={this.props.onDiscard}
                title={'discard_tooltip'}
                caption="discard"
              >
                <FontAwesomeIcon icon={faBan} />
              </NavigationButton>
            )}
            <RequestClarificationButtonContainer disabled={this.props.disabled} editorMode={this.props.editorMode} />
          </div>
          <div className="on-right">
            {!this.props.hideDraft && (
              <NavigationButton
                loaderKey="save-draft-button"
                disabled={this.props.disabled}
                onClick={this.props.onSaveAsDraft}
                title={'save_draft_tooltip'}
                caption="save_as_draft"
              >
                <ValidSaveIcon />
              </NavigationButton>
            )}
            {this.props.canSave && (
              <NavigationButton loaderKey="save-annotation-button" disabled={this.props.disabled} onClick={this.props.onSave} title={'save_tooltip'} caption="save">
                <ValidSaveIcon />
              </NavigationButton>
            )}
            {this.props.canSubmit && (
              <SubmitAnnotationButtonContainer onSubmit={this.props.onSubmit} disabled={this.props.disabled} />
            )}
          </div>
        </Row>
      </Container>
    );
  }
}

export const AnnotationNavigation = as<React.ComponentClass<IAnnotationNavigationComponentProps>>(withNamespaces('annotation', { wait: true })(AnnotationNavigationPure));
