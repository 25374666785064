import { Card, CardBody } from 'reactstrap';

import React from 'react';

interface IPlanUpgradeRequiredMessageProps {
  message: string;
  linkText: string;
}

export class PlanUpgradeRequiredMessage extends React.PureComponent<IPlanUpgradeRequiredMessageProps> {
  render() {
    return (
      <div className="plan-upgrage-required-message-container">
        <Card className="plan-upgrage-required-message">
          <CardBody>
            <div className="message">{this.props.message}</div>
            <a className="text-gradient" target="_blank" href="https://zillin.io/pricing.html">{this.props.linkText}</a>
          </CardBody>
        </Card>
      </div>
    );
  }
}
