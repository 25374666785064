import * as React from 'react';

import { AlertBarStoreType, IAlertBarStore } from '../../modules/alertBar/AlertBar.store';
import { as, injectProps } from '../helpers/react.helpers';

import { alertBarHeight } from '../../modules/alertBar/ui/AlertBar';
import { observer } from 'mobx-react';

interface IProps {
  children?: React.ReactElement<any>[] | React.ReactElement<any>;
  header?: React.ComponentType;
  className?: string;
}

interface IInjectedProps {
  alertBarStore: IAlertBarStore;
}

@injectProps({
  alertBarStore: AlertBarStoreType,
})
@observer
class ViewContainerPure extends React.Component<IProps & IInjectedProps> {
  render() {
    const Header = this.props.header;
    const alertsCount = this.props.alertBarStore.alerts.length;

    return (
      <div className="inside" style={{ height: `calc(100% - 130px - ${alertBarHeight(alertsCount)}`, marginTop: `${alertBarHeight(alertsCount) + 70}px` }}>
        {Header && (
          <div className="page-header">
            <Header />
          </div>
        )}
        <div className={`page-content ${this.props.className || ''}`}>{this.props.children}</div>
      </div>
    );
  }
}

export const ViewContainer = as<React.ComponentClass<IProps>>(ViewContainerPure);
