import './ProjectStatsSections.scss';

import { ConfigurationType, IConfiguration } from '../../../../configuration';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../workspaces/currentWorkspace/CurrentWorkspace.store';
import { EventBusType, EventListeningDisposer, IEventBus } from '../../../../__legacy__/services/eventBus.service';
import { IProjectDetailsStore, ProjectDetailsStoreType } from '../../../../__legacy__/modules/projectDetails/projectDetails.store';
import { IProjectStatsBl, ProjectStatsBlType } from './ProjectStats.bl';
import { IProjectStatsFiltersBl, ProjectStatsFiltersBlType } from './filters/ProjectStatsFilters.bl';
import { IProjectStatsFiltersStore, ProjectStatsFiltersStoreType } from './filters/ProjectStatsFilters.store';
import { IProjectStatsStore, ProjectStatsStoreType } from './ProjectStats.store';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  WorkspaceTimeZoneUpdatedEvent,
  WorkspaceTimeZoneUpdatedEventType,
} from '../../../../__legacy__/modules/workspaces/sub/UpdateWorkspaceTimeZone/events/WorkspaceTimeZoneUpdatedEvent';

import { AnnotationToolsSectionContainer } from './annotationTools/AnnotationToolsSection.container';
import { GeneralSectionContainer } from './general/GeneralSection.container';
import { InformationRow } from './informationRow/InformationRow';
import { Loader } from '../../../../__legacy__/components/Loader';
import { ProjectStatsTopBar } from './ProjectStatsTopBar';
import React from 'react';
import { TimePeriod } from '../../../../design/selects/timePeriodSelector/S_TimePeriodSelector';
import { WithLoaderComponentBase } from '../../../../__legacy__/helpers/loader.helpers';
import { WorkPerformanceSectionContainer } from './workPerformance/WorkPerformanceSection.container';
import { as } from '../../../../common/dependencyInjection/as';
import { injectProps } from '../../../../__legacy__/helpers/react.helpers';
import moment from 'moment';
import { observer } from 'mobx-react';
import { toLocaleDateString } from '../../../../__legacy__/helpers/date.helpers';

interface IProps {
  onChangeViewClicked(): void;
}

interface InjectedProps {
  bl: IProjectStatsBl;
  store: IProjectStatsStore;
  filtersStore: IProjectStatsFiltersStore;
  filtersBl: IProjectStatsFiltersBl;
  projectDetailsStore: IProjectDetailsStore;
  configuration: IConfiguration;
  currentWorkspaceStore: ICurrentWorkspaceStore;
  eventBus: IEventBus;
}

@injectProps({
  bl: ProjectStatsBlType,
  store: ProjectStatsStoreType,
  filtersStore: ProjectStatsFiltersStoreType,
  filtersBl: ProjectStatsFiltersBlType,
  projectDetailsStore: ProjectDetailsStoreType,
  configuration: ConfigurationType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  eventBus: EventBusType,
})
@observer
class ProjectStatsSectionsContainerPure extends WithLoaderComponentBase<IProps & InjectedProps & RouteComponentProps<{ projectId: string }>> {
  private workspaceTimeZoneUpdatedDisposer?: EventListeningDisposer;

  componentDidMount = async () => {
    await this.loadAsync();
    this.workspaceTimeZoneUpdatedDisposer = this.props.eventBus.addListener<WorkspaceTimeZoneUpdatedEvent>(this.handleWorkspaceTimeZoneUpdated, WorkspaceTimeZoneUpdatedEventType);
  };

  handleWorkspaceTimeZoneUpdated = async () => await this.loadAsync();

  loadAsync = async () => {
    const projectId = this.props.match.params.projectId;
    const statsStartDate = moment(this.props.currentWorkspaceStore.currentWorkspaceTime).startOf('month').utcOffset(0, true);
    const statsEndDate = moment(this.props.currentWorkspaceStore.currentWorkspaceTime).endOf('month').utcOffset(0, true);

    await this.props.bl.displayStatsAsync(projectId, TimePeriod.Month, statsStartDate, statsEndDate);

    this.props.filtersBl.load(
      projectId,
      this.props.store.generalStats.map(gs => ({ id: gs.id, email: gs.email })),
      statsStartDate,
      statsEndDate,
    );
  };

  componentWillUnmount() {
    this.workspaceTimeZoneUpdatedDisposer?.();
  }

  render(): React.ReactNode {
    return (
      <div className="project-stats-sections-container">
        <Loader isLoading={this.props.store.isLoading}>
          {this.props.projectDetailsStore.isCreatedBeforeStatsRelease && (
            <InformationRow onClick={this.props.onChangeViewClicked} date={toLocaleDateString(this.props.configuration.appConfig.newStatisticsDate)} />
          )}

          <ProjectStatsTopBar />
          <GeneralSectionContainer />
          <AnnotationToolsSectionContainer />
          <WorkPerformanceSectionContainer />
        </Loader>
      </div>
    );
  }
}

export const ProjectStatsSectionsContainer = as<React.ComponentClass<IProps>>(withRouter(ProjectStatsSectionsContainerPure));
