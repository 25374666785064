import * as React from 'react';

import { Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { ITranslatable } from '../../../helpers/translations.helpers';
import Select from 'react-select';
import { UserRole } from '../../user/user.store';
import { as } from '../../../helpers/react.helpers';
import { handleSelectChange } from '../../../helpers/formHelpers';
import { withNamespaces } from 'react-i18next';

export interface IChangeUserRoleModalProps {
  changeUserRoleModalShown: boolean;
  selectedRole: UserRole;
  onSelectedRoleChange(role: UserRole): void;
  onChangeRoleClicked(id: string): void;
  onSaveRoleClicked(): void;
}

const ChangeUserRoleModal = (props: IChangeUserRoleModalProps & ITranslatable) => {
  const handleToggle = () => props.onChangeRoleClicked('');

  return (
    <Modal className="modal-width-600" isOpen={props.changeUserRoleModalShown} toggle={handleToggle}>
      <ModalHeader toggle={handleToggle}>{props.t('change_user_role')}</ModalHeader>
      <Form className="custom-inline-form">
        <ModalBody>
          <div>
            <Row>
              <Col>
                <FormGroup>
                  <Label>{props.t('select_role')}</Label>
                  <Select
                    id="user-change-role-dropdown"
                    value={{ label: props.t(props.selectedRole), value: props.selectedRole }}
                    onChange={handleSelectChange((value: UserRole) => props.onSelectedRoleChange(value))}
                    options={[UserRole.Administrator, UserRole.User].map(r => ({ label: props.t(r), value: r }))}
                    classNamePrefix="custom-select"
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button id="change-button" color="success" className="mr-2" onClick={props.onSaveRoleClicked}>
            {props.t('save')}
          </Button>
          <Button id="change-button-cancel" color="primary" className="btn-outline-primary" onClick={handleToggle}>
            {props.t('cancel')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default as<React.SFC>(withNamespaces('common', { wait: true })(ChangeUserRoleModal));
