import { IUserService, UserServiceType } from '../modules/user/user.service';
import { IWorkspaceService, WorkspaceServiceType } from '../modules/workspaces/workspaces.service';
import { as, injectProps } from '../helpers/react.helpers';

import { ProjectRole } from '../models/userRole.model';
import React from 'react';
import { WorkspaceRole } from '../modules/workspaces/workspaces.store';
import { observer } from 'mobx-react';

interface IOuterProps {
  projectRoles: ProjectRole[];
  workspaceRoles: WorkspaceRole[];
  inProject?: string;
}

interface IInjectedProps {
  workspaceService: IWorkspaceService;
  userService: IUserService;
}

@injectProps({
  userService: UserServiceType,
  workspaceService: WorkspaceServiceType,
})
@observer
class RestrictAccessToPure extends React.Component<IOuterProps & IInjectedProps> {
  render() {
    const projectRoles = this.props.projectRoles || [];
    const workspaceRoles = this.props.workspaceRoles || [];

    const userRoleInProject = this.props.userService.getUserRoleInProject(this.props.inProject || '');
    const userRoleInWorkspace = this.props.workspaceService.getUserRoleInCurrentWorkspace();

    return (
      userRoleInProject && projectRoles.includes(userRoleInProject) ||
      userRoleInWorkspace && workspaceRoles.includes(userRoleInWorkspace))
      ? this.props.children
      : null;
  }
}

export const RestrictAccessTo = as<React.ComponentClass<IOuterProps>>(RestrictAccessToPure);
