import { inject, injectable } from 'inversify';
import { action } from 'mobx';
import { buffer2base64, bufferToHex } from '../helpers/string.helper';
import { CryptoStoreType, ICryptoStore } from '../stores/crypto.store';

// Just for unit tests
if (window.Crypto === undefined) {
  window.Crypto = require('@trust/webcrypto');
  window.TextEncoder = require('util').TextEncoder;
}

export interface ICryptoService {
  hasKey(workspaceId: string): boolean;
  generateKey(workspaceId: string, verificationPreimage: string): Promise<string>;
  loadKey(workspaceId: string, payload: string, verificationPreimage: string, checksum: string): Promise<boolean>;
  unloadKey(workspaceId: string): void;
  reloadKey(workspaceId: string): Promise<void>;
  checkKey(workspaceId: string, verificationPreimage: string): Promise<boolean>;
  exportKey(workspaceId: string): Promise<string | undefined>;
  encrypt(workspaceId: string, file: ArrayBuffer): Promise<Blob>;
  decrypt(workspaceId: string, file: ArrayBuffer): Promise<ArrayBuffer>;
  hash(payload: ArrayBuffer): Promise<ArrayBuffer>;
  clearAll(): void;
}

export const CryptoType = Symbol('CRYPTO');

export const CryptoServiceType = Symbol('CRYPTO_SERVICE');

const Algo = 'AES-CBC';

@injectable()
export class CryptoService implements ICryptoService {
  constructor(@inject(CryptoStoreType) private store: ICryptoStore, @inject(CryptoType) private crypto: Crypto) { }

  @action.bound
  async generateKey(workspaceId: string, verificationPreimage: string): Promise<string> {
    const key = await this.crypto.subtle.generateKey({ name: Algo, length: 256 }, true, ['encrypt', 'decrypt']);
    const rawKey = bufferToHex(await this.crypto.subtle.exportKey('raw', key));
    const checksum = await this.generateChecksum(verificationPreimage, key, Buffer.alloc(16, 0));
    this.store.set({ workspaceId, key, rawKey, checksum });
    return checksum;
  }

  @action.bound
  async loadKey(workspaceId: string, payload: string, verificationPreimage: string, checksum: string): Promise<boolean> {
    try {
      const key = await this.importKey(payload.trim());

      if (checksum !== (await this.generateChecksum(verificationPreimage, key, Buffer.alloc(16, 0)))) {
        return false;
      }

      this.store.set({ workspaceId, key, checksum, rawKey: payload });

      return true;
    } catch {
      return false;
    }
  }

  @action.bound
  async reloadKey(workspaceId: string) {
    const crypto = this.store.get(workspaceId);
    if (!crypto) return;
    crypto.key = await this.importKey(crypto.rawKey!);
    this.store.set(crypto);
  }

  async checkKey(workspaceId: string, verificationPreimage: string): Promise<boolean> {
    const crypto = this.store.get(workspaceId);

    if (!crypto || !crypto.key) return false;

    return crypto.checksum === (await this.generateChecksum(verificationPreimage, crypto.key, Buffer.alloc(16, 0)));
  }

  private async importKey(rawKey: string) {
    return await this.crypto.subtle.importKey('raw', Buffer.from(rawKey, 'hex'), Algo, true, ['encrypt', 'decrypt']);
  }

  hasKey(workspaceId: string): boolean {
    const crypto = this.store.get(workspaceId);
    return !!crypto && !!crypto.key;
  }

  @action.bound
  unloadKey(workspaceId: string): void {
    this.store.remove(workspaceId);
  }

  async exportKey(workspaceId: string): Promise<string | undefined> {
    const crypto = this.store.get(workspaceId);
    if (!crypto || !crypto.key) return undefined;
    const blob = new Blob([bufferToHex(await this.crypto.subtle.exportKey('raw', crypto.key))], { type: 'text/plain' });
    return URL.createObjectURL(blob);
  }

  async encrypt(workspaceId: string, message: ArrayBuffer, iv?: ArrayBuffer): Promise<Blob> {
    const crypto = this.store.get(workspaceId);
    if (!crypto || !crypto.key) throw 'There is no key for this workspace';
    return await this.encryptWithKey(message, crypto.key, iv);
  }

  async encryptWithKey(message: ArrayBuffer, key?: CryptoKey, defaultIV?: ArrayBuffer): Promise<Blob> {
    if (!key) return new Blob([message]);
    const iv = defaultIV || this.crypto.getRandomValues(new Uint8Array(16));
    return new Blob([iv, await this.crypto.subtle.encrypt({ iv, name: Algo }, key, message)]);
  }

  async decrypt(workspaceId: string, message: ArrayBuffer): Promise<ArrayBuffer> {
    const crypto = this.store.get(workspaceId);
    if (!crypto || !crypto.key) return message;
    const iv = message.slice(0, 16);
    const body = message.slice(16);
    return await this.crypto.subtle.decrypt({ iv, name: Algo }, crypto.key, body);
  }

  async hash(payload: ArrayBuffer): Promise<ArrayBuffer> {
    return await this.crypto.subtle.digest({ name: 'SHA-256' }, payload);
  }

  @action.bound
  clearAll(): void {
    this.store.clearAll();
  }

  private async generateChecksum(verificationPreimage: string, key: CryptoKey, iv: ArrayBuffer): Promise<string> {
    const payload = await (await this.encryptWithKey(new TextEncoder().encode(verificationPreimage), key, iv)).arrayBuffer();
    return buffer2base64(await this.hash(payload));
  }
}
