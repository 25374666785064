import { injectable, inject } from 'inversify';
import { ApiServiceType, IPagedResult } from '../../../services/api.service';
import { StickerError } from '../../../models/error.model';

import { IApiService } from '../../../services/api.service.base';
import { IProjectListDto } from '../projects.model';
import { ProjectOwnership } from '../projects.store';

export interface IGetProjectsRequest {
  workspaceId: string;
  pageNumber: number;
  pageSize: number;
  orderBy: string;
  orderType: string;
  ownership: ProjectOwnership;
}

export interface IGetProjectExclusiveDatasetsRequest {
  projectId: string;
}

export interface IDeleteProjectRequest {
  projectId: string;
  deleteDatasets: boolean;
}

export interface IImportProjectRequest {
  projectId: string;
  importAnnotations: boolean;
  importImageSets: boolean;
  workspaceId: string;
  file: File;
}

export interface IExportRequest {
  projectId: string;
  fileFormat: string;
  dataFilter: string;
}

export const ProjectsApiServiceType = Symbol('PROJECTS_API_SERVICE');

export interface IProjectsApiService {
  getProjectsAsync(request: IGetProjectsRequest): Promise<IPagedResult<IProjectListDto> | StickerError>;
  getProjectExclusiveDatasetsAsync(request: IGetProjectExclusiveDatasetsRequest): Promise<string[] | StickerError>;
  deleteProjectAsync(request: IDeleteProjectRequest): Promise<void | StickerError>;
  importProjectAsync(request: IImportProjectRequest): Promise<void | StickerError>;
}

@injectable()
export class ProjectsApiService implements IProjectsApiService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService) { }

  async getProjectsAsync(request: IGetProjectsRequest): Promise<IPagedResult<IProjectListDto> | StickerError> {
    return this.apiService.getAsync<IPagedResult<IProjectListDto>>('/Projects/All', { params: request });
  }

  async getProjectExclusiveDatasetsAsync(request: IGetProjectExclusiveDatasetsRequest): Promise<string[] | StickerError> {
    return this.apiService.getAsync<string[]>('/Projects/ExclusiveDatasets', { params: request });
  }

  async deleteProjectAsync(request: IDeleteProjectRequest): Promise<void | StickerError> {
    return this.apiService.postAsync<IDeleteProjectRequest, void>('/Projects/delete', request);
  }

  async importProjectAsync(request: IImportProjectRequest): Promise<void | StickerError> {
    const formData = new FormData();
    formData.append('projectDraftId', request.projectId);
    formData.append('importAnnotations', request.importAnnotations.toString());
    formData.append('importImageSets', request.importImageSets.toString());
    formData.append('workspaceId', request.workspaceId);
    formData.append('file', request.file);
    formData.append('fileName', request.file.name);

    return await this.apiService.postAsync<FormData, void>('/Import/ImportProjectFromFile', formData);
  }
}
