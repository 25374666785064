import { ITranslatable } from '../../../../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  labelKey: string;
  className?: string;
}

class FilterContentPure extends React.Component<IProps & ITranslatable> {
  render() {
    return (
      <div className={`image-filter-content ${this.props.className}`}>
        <h5>{this.props.t(this.props.labelKey)}</h5>
        {this.props.children}
      </div>
    );
  }
}

export const FilterContent = as<React.ComponentClass<IProps>>(withNamespaces('common')(FilterContentPure));
