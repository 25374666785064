import { inject, injectable } from 'inversify';
import _ from 'lodash';
import { ForbiddenError, StickerError } from '../../../../../models/error.model';
import { SortingDirection } from '../../../../../models/sortingDirection.model';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../../../../modules/common/OverlayLoader.store';
import { NotificationLevel, ToastNotification } from '../../../../notifications/models/notification.model';
import { INotificationsService, NotificationsServiceType } from '../../../../notifications/services/notifications.service';
import { EmptyAnnotationToolFilter, IImageFilters, ImageDefaultFilters, SortingBy } from '../imageFilters.model';
import { ImagesDefaultPaging } from '../projectDetailsImages.model';
import { IProjectDetailsImagesStoreSetter, ProjectDetailsImagesStoreSetterType } from '../projectDetailsImages.store';
import { IGetImagesRequest, IImageFiltersApiService, ImageFiltersApiServiceType, ISaveFiltersRequest, IUpsertFiltersRequest } from './imageFiltersApi.service';

export const ImageFilterServiceType = Symbol('IMAGE_FILTER_SERVICE');

export interface IImageFilterService {
  setImageNameAsync(imageName: string): Promise<void>;
  applyAsync(filters: IImageFilters): Promise<void>;
  upsertFiltersAsync(projectId: string, filters: IImageFilters): Promise<string>;

  filterImages(): Promise<void>;

  clearAllAsync(): Promise<void>;
  clearStatusesAsync(): Promise<void>;
  clearCorrectedAsync(): Promise<void>;
  clearImageSetsAsync(): Promise<void>;
  clearInDatasetsAsync(): Promise<void>;
  clearReviewedByAsync(): Promise<void>;
  clearAnnotatedByAsync(): Promise<void>;
  clearAnnotationToolsAsync(): Promise<void>;

  setDefaultImagePaging(): Promise<void>;
  paginationChange(pageNumber: number, pageSize: number): Promise<void>;
  changeSortingAsync(by: SortingBy, direction: SortingDirection | undefined): Promise<void>;

  saveFilterAsync(name: string, filters: IImageFilters): Promise<void>;
  deleteFilterAsync(filterId: string): Promise<void>;
  getFilterDataAsync(projectId: string, filterId: string | undefined): Promise<void>;
}

@injectable()
export class ImageFilterService implements IImageFilterService {
  constructor(
    @inject(ProjectDetailsImagesStoreSetterType) private readonly store: IProjectDetailsImagesStoreSetter,
    @inject(ImageFiltersApiServiceType) private readonly imageFiltersApiService: IImageFiltersApiService,
    @inject(OverlayLoaderStoreType) private readonly overlayLoaderStore: IOverlayLoaderStore,
    @inject(NotificationsServiceType) private readonly notificationsService: INotificationsService,
  ) {}

  async getFilterDataAsync(projectId: string, filterId: string | undefined): Promise<void> {
    const filtersResult = await this.imageFiltersApiService.getActiveFilters({ projectId, filterId });

    if (filtersResult instanceof StickerError) return;

    this.store.setFilterData(filtersResult.availableFilters, filtersResult.activeFilter, filtersResult.savedFilters);
  }

  async applyAsync(filters: IImageFilters): Promise<void> {
    this.store.setFilters(filters);
    await this.filterImages();
  }

  async upsertFiltersAsync(projectId: string, filters: IImageFilters): Promise<string> {
    const upsertRequest: IUpsertFiltersRequest = {
      projectId,
      filters: {
        ...filters,
        annotationTools: filters.annotationTools.filter(x => x.l1FilterType !== undefined),
        sorting: filters.sorting.filter(x => x.by !== undefined),
      },
    };
    const result = await this.imageFiltersApiService.upsertFiltersAsync(upsertRequest);

    if (result instanceof StickerError) throw result;

    return result;
  }

  async clearAllAsync(): Promise<void> {
    this.store.setFilters(ImageDefaultFilters);
    await this.filterImages();
  }

  async setImageNameAsync(imageName: string): Promise<void> {
    const newFilters = {
      ...this.store.imageFilters,
      imageName,
    };

    this.store.setFilters(newFilters);

    await this.handleSearchChangeDebounced();
  }

  async clearInDatasetsAsync(): Promise<void> {
    const newFilters = {
      ...this.store.imageFilters,
      datasets: [],
    };

    this.store.setFilters(newFilters);
    await this.filterImages();
  }

  async clearAnnotationToolsAsync(): Promise<void> {
    const newFilters = {
      ...this.store.imageFilters,
      annotationTools: [{ ...EmptyAnnotationToolFilter }],
    };

    this.store.setFilters(newFilters);
    await this.filterImages();
  }

  async clearAnnotatedByAsync(): Promise<void> {
    const newFilters = {
      ...this.store.imageFilters,
      annotatedBy: [],
    };

    this.store.setFilters(newFilters);
    await this.filterImages();
  }

  async clearReviewedByAsync(): Promise<void> {
    const newFilters = {
      ...this.store.imageFilters,
      reviewedBy: [],
    };

    this.store.setFilters(newFilters);
    await this.filterImages();
  }

  async clearStatusesAsync(): Promise<void> {
    const newFilters = {
      ...this.store.imageFilters,
      annotationStatuses: [],
    };

    this.store.setFilters(newFilters);
    await this.filterImages();
  }

  async clearImageSetsAsync(): Promise<void> {
    const newFilters = {
      ...this.store.imageFilters,
      imageSets: [],
    };

    this.store.setFilters(newFilters);
    await this.filterImages();
  }

  async clearCorrectedAsync(): Promise<void> {
    const newFilters = {
      ...this.store.imageFilters,
      corrected: [],
    };

    this.store.setFilters(newFilters);
    await this.filterImages();
  }

  async paginationChange(pageNumber: number, pageSize: number): Promise<void> {
    this.store.setImagePaging({
      ...this.store.imagesPaging,
      pageNumber,
      pageSize,
    });
    await this.filterImages();
  }

  async changeSortingAsync(by: SortingBy, direction: SortingDirection | undefined): Promise<void> {
    const newFilters = {
      ...this.store.imageFilters,
      sorting: direction === undefined ? [] : [{ by, direction, order: 0 }],
    };

    this.store.setFilters(newFilters);
    await this.filterImages();
  }

  async saveFilterAsync(name: string, filters: IImageFilters): Promise<void> {
    const saveRequest: ISaveFiltersRequest = {
      name,
      projectId: this.store.projectId,
      filters: {
        ...filters,
        annotationTools: filters.annotationTools.filter(x => x.l1FilterType !== undefined),
        sorting: filters.sorting.filter(x => x.by !== undefined),
      },
    };

    this.overlayLoaderStore.enableLoader('filter-save');
    await this.imageFiltersApiService.saveFiltersAsync(saveRequest);
    const filtersResult = await this.imageFiltersApiService.getActiveFilters({ projectId: this.store.projectId, filterId: this.store.filterId });
    this.overlayLoaderStore.disableLoader('filter-save');

    if (filtersResult instanceof StickerError) return;

    this.store.setFilterData(filtersResult.availableFilters, filtersResult.activeFilter, filtersResult.savedFilters);
  }

  async deleteFilterAsync(filterId: string): Promise<void> {
    this.overlayLoaderStore.enableLoader('filter-delete');
    await this.imageFiltersApiService.deleteFiltersAsync({ filterId });
    const filtersResult = await this.imageFiltersApiService.getActiveFilters({ projectId: this.store.projectId, filterId: this.store.filterId });
    this.overlayLoaderStore.disableLoader('filter-delete');

    this.notifyAboutApiError(filtersResult, 'project:deleting_filter_failed');
    if (filtersResult instanceof StickerError) return;

    this.store.setFilterData(filtersResult.availableFilters, filtersResult.activeFilter, filtersResult.savedFilters);
  }

  async filterImages(): Promise<void> {
    const request: IGetImagesRequest = {
      projectId: this.store.projectId,
      pageNumber: this.store.imagesPaging.pageNumber,
      pageSize: this.store.imagesPaging.pageSize,
      filters: {
        ...this.store.imageFilters,
        annotationTools: this.store.imageFilters.annotationTools.filter(x => x.l1FilterType !== undefined),
      },
    };

    this.overlayLoaderStore.enableLoader('details-images-loading');
    const result = await this.imageFiltersApiService.getProjectImagesAsync(request);
    this.overlayLoaderStore.disableLoader('details-images-loading');

    this.notifyAboutApiError(result, 'project:filtering_images_failed');
    if (result instanceof StickerError) return;

    if (result.pagesCount < result.pageNumber) {
      this.store.setImagePaging({
        ...this.store.imagesPaging,
        pageNumber: result.pagesCount,
      });
      await this.filterImages();
    } else {
      this.store.updateImages(
        result.data,
        {
          ...this.store.imagesPaging,
          pageNumber: Math.max(result.pageNumber, 1),
          pagesCount: result.pagesCount,
          totalCount: result.totalCount,
          totalImagesCount: result.totalImagesCount,
          unlockedImagesCount: result.unlockedImagesCount,
        },
        result.filterId,
      );
    }
  }

  async setDefaultImagePaging(): Promise<void> {
    this.store.setImagePaging({ ...ImagesDefaultPaging });

    if (this.store.projectId) {
      await this.filterImages();
    }
  }

  private notifyAboutApiError(result: any, errorCode: string, dontShowBadRequest: boolean = false) {
    if (result instanceof StickerError && !(result instanceof ForbiddenError) && (!dontShowBadRequest || !result.isBadRequest())) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, errorCode));
    }
  }

  private handleSearchChangeDebounced = _.debounce(this.filterImages, 500, { leading: false, trailing: true });
}
