import * as React from 'react';

import { ExportDataBlType, IExportDataBl } from '../../../../modules/projects/details/exportData/ExportData.bl';
import {
  IBaseNotification,
  IHubNotification,
  ILocalizable,
  NotificationLevel,
  NotificationPopupType,
} from '../models/notification.model';
import { INotificationsService, NotificationsServiceType } from '../services/notifications.service';
import { as, injectProps } from '../../../helpers/react.helpers';

import { ExportDataFilter } from '../../../../modules/projects/details/exportData/models/ExportDataFilter';
import { HubNotificationMapper } from '../mappers/hubNotification.mapper';
import autobind from 'autobind-decorator';
import { centerTypeSelector } from '../services/notification.helpers';
import { computed } from 'mobx';
import { observer } from 'mobx-react';

export interface INotificationModel extends INotificationMapper {
  id: string;
  type: NotificationPopupType;
  date: Date;
  projectExportId?: string;
  exportDataFilter?: ExportDataFilter;
  onRemove(id: string): void;
}

export interface INotificationMapper {
  level: NotificationLevel;
  title: string | ILocalizable;
  body: string | ILocalizable | React.ReactElement<any> | any;
  wasRead: boolean;
}

export interface INotificationsHubProps {
  notifications: INotificationModel[];
  isOpen: boolean;
  onClearAll(): void;
  onToggle(): void;
}

interface IInjectedProps {
  notificationService: INotificationsService;
  exportDataBl: IExportDataBl;
}

interface IState {
  showNotifications: boolean;
}

@injectProps({
  notificationService: NotificationsServiceType,
  exportDataBl: ExportDataBlType
})
@observer
class NotificationsHubContainerPure extends React.Component<IInjectedProps, IState> {
  state: IState = {
    showNotifications: false,
  };

  componentDidMount() {
    this.props.notificationService.initializeHubAsync();
  }

  @autobind
  handleToggle() {
    if (!this.state.showNotifications) this.props.notificationService.markAsRead();
    this.setState(priv => ({ showNotifications: !priv.showNotifications }));
  }

  @computed
  get notifications(): INotificationModel[] {
    return this.props.notificationService.notificationStore
      .notifications
      .filter(centerTypeSelector)
      .sort((a: IBaseNotification, b: IBaseNotification) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      })
      .map(
        n =>
        ({
          ...HubNotificationMapper.toNotification(n as IHubNotification)!,
          id: n.id,
          date: new Date(n.date),
          type: n.popupType,
          wasRead: n.wasRead,
          onRemove: () => this.props.notificationService.remove(n),
        } as INotificationModel),
      );
  }

  render() {
    return React.Children.map(this.props.children, (child: any) =>
      React.cloneElement(child, {
        isOpen: this.state.showNotifications,
        notifications: this.notifications,
        onToggle: this.handleToggle,
        onClearAll: () => this.props.notificationService.clearHub()
      } as INotificationsHubProps),
    );
  }
}

export const NotificationsHubContainer = as<React.ComponentClass>(NotificationsHubContainerPure);
