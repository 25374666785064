import './ExportDataForm.scss';

import { WithNamespaces, withNamespaces } from 'react-i18next';

import { ExportButtonContainer } from '../button/ExportButton.container';
import { ExportDataFilter } from '../../models/ExportDataFilter';
import { ISelectOption } from '../../../../../../__legacy__/helpers/formHelpers';
import React from 'react';
import { S_SelectField } from '../../../../../../design/fields/selectField/S_SelectField';
import { toLocaleDateTimeString } from '../../../../../../__legacy__/helpers/date.helpers';

interface Props {
  selectedFilter: ExportDataFilter;
  onChangeDataRange(dataRange: ExportDataFilter): void;
  annotationsLastModifiedDate: string;
}

const ExportDataFormPure: React.FC<Props & WithNamespaces> = (props: Props & WithNamespaces) => {
  const { t, onChangeDataRange, annotationsLastModifiedDate } = props;

  const options: ISelectOption<ExportDataFilter>[] = [
    { value: ExportDataFilter.ALL, label: t('new.projects.details.export_data.everything') },
    { value: ExportDataFilter.ACCEPTED, label: t('new.projects.details.export_data.all_accepted_data') },
    { value: ExportDataFilter.ANNOTATED, label: t('new.projects.details.export_data.all_annotated_data') },
    { value: ExportDataFilter.CONFIG_ONLY, label: t('new.projects.details.export_data.project_configuration_only') },
  ];

  return (
    <div className="export-data-form">
      <strong className="last-modified-date">
        {t('new.projects.details.export_data.annotations_last_modified_date', { annotationsLastModifiedDate: toLocaleDateTimeString(annotationsLastModifiedDate) })}
      </strong>
      <div className="export-data-selector">
        <S_SelectField
          id="data-range-select"
          labelText={t('new.projects.details.export_data.select_range')}
          options={options}
          value={options.find(o => o.value === props.selectedFilter)!}
          onChange={onChangeDataRange}
        />
        <ExportButtonContainer />
      </div>
      <div className="json-documentation-link">
        <p>
          {t('new.projects.details.export_data.our_json_format_documentation_can_be_found')}
          <a className="styled_link_bold" href="https://api.zillin.io/export-json-format.html" target="blank">
            {` ${t('here')}`}
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export const ExportDataForm = withNamespaces()(ExportDataFormPure);
