import { AnnotationServiceType, IAnnotationService } from '../annotation.service';
import { AnnotationTypeBlType, IAnnotationTypeBl } from '../submodules/annotationTypes/annotationType.bl';
import { AnnotationTypeStoreType, IAnnotationTypeStore } from '../submodules/annotationTypes/annotationType.store';
import { AnnotationUiStoreType, IAnnotationUiStore } from '../annotationUi.store';
import { AnnotationsStoreType, IAnnotationsStore } from '../annotations.store';
import { FreeDrawSegmentationServiceType, IFreeDrawSegmentationService } from '../freeDrawSegmentation.service';
import { ISegmentationOrderBl, SegmentationOrderBlType } from '../submodules/segmentations/segmentationContextMenu/segmentationsOrder.Bl';
import { IUndoRedoHistory, UndoRedoHistoryType } from '../undoRedoHistory.service';
import { IUserStore, UserStoreType } from '../../user/user.store';
import { Observer, observer } from 'mobx-react';
import { as, injectProps } from '../../../helpers/react.helpers';

import { FREE_DRAW_TOOLS } from '../annotations.interface';
import { ImageSegmentations } from '../submodules/segmentations/segmentationContextMenu/imageSegmentations.context';
import React from 'react';
import { SegmentationsLayer } from '../components/SegmentationsLayer';
import { VerticalMenuItem } from '../submodules/segmentations/components/verticalMenu/VerticalMenu';
import autobind from 'autobind-decorator';
import { constant } from 'lodash/fp';
import { dinduNuffin } from '../../../helpers/function.helpers';
import { transaction } from 'mobx';

interface IInjectedProps {
  annotationService: IAnnotationService;
  annotationUiStore: IAnnotationUiStore;
  annotationTypeBl: IAnnotationTypeBl;
  annotationTypeStore: IAnnotationTypeStore;
  freeDrawSegmentationService: IFreeDrawSegmentationService;
  userStore: IUserStore;
  undoRedoHistory: IUndoRedoHistory;
  annotationsStore: IAnnotationsStore;
  segmentationOrderBl: ISegmentationOrderBl;
}

interface IOuterProps {
  readonly?: boolean;
}

@injectProps({
  annotationService: AnnotationServiceType,
  annotationUiStore: AnnotationUiStoreType,
  annotationTypeBl: AnnotationTypeBlType,
  annotationTypeStore: AnnotationTypeStoreType,
  freeDrawSegmentationService: FreeDrawSegmentationServiceType,
  userStore: UserStoreType,
  undoRedoHistory: UndoRedoHistoryType,
  annotationsStore: AnnotationsStoreType,
  segmentationOrderBl: SegmentationOrderBlType,
})
@observer
class SegmentationsContainerPure extends React.Component<IInjectedProps & IOuterProps> {
  @autobind
  handleSegmentationClicked(id: string) {
    if (this.props.readonly) {
      this.props.annotationsStore.drawFocusFrameForId = id;
      this.props.annotationService.selectSegmentation(id);
      return;
    }

    transaction(() => {
      this.props.annotationService.selectSegmentation(id);
      if (this.props.annotationsStore.selectedSegmentation) {
        this.props.annotationUiStore.isInValidation = false;

        if (FREE_DRAW_TOOLS.includes(this.props.annotationsStore.selectedSegmentation.feature.featureType)) {
          this.props.freeDrawSegmentationService.setFreeDrawFeature(this.props.annotationsStore.selectedSegmentation!.feature);
          this.props.annotationsStore.drawFocusFrameForId = id;
          const previousAnnotationTypeId = this.props.annotationTypeStore.activeAnnotationTypeId;
          const annotationTypeId = this.props.annotationsStore.selectedSegmentation.feature.properties?.annotationTypeId;
          if (annotationTypeId !== previousAnnotationTypeId) {
            this.props.annotationTypeBl.handleSegmentationClickedInSegmentationContainer(annotationTypeId).then(succeeded => {
              if (succeeded && this.props.freeDrawSegmentationService.freeDrawFeature) {
                this.props.freeDrawSegmentationService.selectFeature();
              }
            });
          }
        } else {
          this.props.freeDrawSegmentationService.clear();
          this.props.annotationsStore.drawFocusFrameForId = undefined;
        }
      }
    });
  }

  @autobind
  handleSegmentationTabed(id: string) {
    if (this.props.readonly) {
      this.props.annotationService.selectSegmentation(id);
      return;
    }

    const segmentation = this.props.annotationsStore.segmentations.find(s => s.feature.id === id);

    transaction(() => {
      if (segmentation) {
        if (FREE_DRAW_TOOLS.includes(segmentation.feature.featureType)) {
          this.props.freeDrawSegmentationService.setFreeDrawFeature(segmentation.feature);
          this.props.freeDrawSegmentationService.selectFeature();
        } else {
          this.props.freeDrawSegmentationService.clear();
        }
        this.props.annotationsStore.drawFocusFrameForId = segmentation.feature.id;
        this.props.annotationTypeBl.handleSegmentationTapInSegmetationContainer();
      }

      this.props.annotationService.selectSegmentation(id);
    });
  }

  @autobind
  async handleSegmentationDeselected() {
    if (await this.props.annotationTypeBl.handleSegmentationDeselectedInSegmentationContainer()) {
      this.props.annotationService.deselectSegmentation();
      this.props.annotationsStore.drawFocusFrameForId = undefined;
    }
  }

  @autobind
  handleSegmentationOver(segmentation: string) {
    this.props.annotationsStore.hoveredSegmentation = segmentation;
  }

  @autobind
  handleSegmentationOut() {
    this.props.annotationsStore.hoveredSegmentation = undefined;
  }

  @autobind
  handleCheckPendingChanges() {
    return this.props.undoRedoHistory.canUndo;
  }

  render() {
    return (
      <>
        <ImageSegmentations.Consumer>
          {ctx => (
            <Observer>
              {() =>
                ctx.store.activeTab === VerticalMenuItem.ANNOTATED_IN_PROJECTS && ctx.store.selectedProjects.map(projectId => (
                  <SegmentationsLayer
                    key={projectId}
                    readonly={true}
                    editable={false}
                    segmentations={ctx.store.segmentations[projectId] || []}
                    hiddenSegmentations={this.props.annotationsStore.hiddenSegmentations.slice()}
                    freeDrawInProgress={false}
                    areAttributesShown={this.props.userStore.areAttributesShown}
                    opacityLevel={this.props.userStore.opacityLevel}
                    zoomLevel={this.props.annotationUiStore.zoomLevel}
                    annotationTypesOptions={[]}
                    onSegmentationSelected={dinduNuffin}
                    onSegmentationDeselected={dinduNuffin}
                    onSegmentationTabed={dinduNuffin}
                    onSegmentationOver={dinduNuffin}
                    onSegmentationOut={dinduNuffin}
                    onNextSegmentationSelected={constant(undefined)}
                    onPreviousSegmentationSelected={constant(undefined)}
                    checkPendingChanges={constant(false)}
                    reactLeafletRenderFingerprint={this.props.annotationUiStore.reactLeafletRenderFingerprint}
                  />
                ))
              }
            </Observer>
          )}
        </ImageSegmentations.Consumer>
        <SegmentationsLayer
          readonly={this.props.readonly}
          editable={this.props.annotationTypeStore.activeAnnotationTypeId === undefined}
          segmentations={this.props.annotationsStore.segmentations.slice()}
          selectedSegmentation={this.props.annotationsStore.selectedSegmentation}
          hoveredSegmentation={this.props.annotationsStore.hoveredSegmentation}
          freeDrawInProgress={this.props.freeDrawSegmentationService.freeDrawInProgress}
          invalidSegmentation={this.props.annotationsStore.invalidSegmentation}
          areAttributesShown={this.props.userStore.areAttributesShown}
          onSegmentationSelected={this.handleSegmentationClicked}
          onSegmentationDeselected={this.handleSegmentationDeselected}
          onNextSegmentationSelected={this.props.annotationService.getNextSegmentation}
          onPreviousSegmentationSelected={this.props.annotationService.getPreviousSegmentation}
          onSegmentationTabed={this.handleSegmentationTabed}
          onSegmentationOver={this.handleSegmentationOver}
          onSegmentationOut={this.handleSegmentationOut}
          checkPendingChanges={this.handleCheckPendingChanges}
          featureInEditId={this.props.annotationsStore.selectedSegmentation?.feature.id}
          opacityLevel={this.props.userStore.opacityLevel}
          zoomLevel={this.props.annotationUiStore.zoomLevel}
          activeAnnotationTypeId={this.props.annotationTypeStore.activeAnnotationTypeId}
          annotationTypesOptions={this.props.annotationTypeStore.annotationTypesOptions}
          hiddenSegmentations={this.props.annotationsStore.hiddenSegmentations.slice()}
          drawFocusFrameForId={this.props.annotationsStore.drawFocusFrameForId}
          reactLeafletRenderFingerprint={this.props.annotationUiStore.reactLeafletRenderFingerprint}
        />
      </>
    );
  }
}

export const SegmentationsContainer = as<React.ComponentClass<IOuterProps>>(SegmentationsContainerPure);
