import * as React from 'react';

import { ITranslatable } from '../../../helpers/translations.helpers';
import { IconedButton } from '../../../components/IconedButton';
import { ProgressBar } from '../../../components/ProgressBar';
import { as } from '../../../helpers/react.helpers';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';

library.add(faShoppingCart);

interface ILimitProps {
  enabled: boolean;
  accountExists: boolean;
  totalCredits: number;
  usedCredits: number;
  onCartClick(): void;
}

type IProps = ILimitProps & ITranslatable;

class LimitPure extends React.PureComponent<IProps> {
  render() {
    const { usedCredits, totalCredits, t } = this.props;
    return (
      this.props.accountExists && (
        <div className="top-nav-item limit">
          <div className="float-left indicator">
            <p className="numbers">
              {usedCredits}/{totalCredits}
            </p>
            <ProgressBar value={usedCredits} max={totalCredits} />
            <p className="title">{t('annotated_images')}</p>
          </div>
          <IconedButton
            color="shopping-cart-link"
            onClick={this.props.onCartClick}
            disabled={!this.props.enabled}
            title={this.props.enabled ? t('increase_limit') : this.props.t('shop_disabled_message')}
            icon={faShoppingCart}
          />
        </div>
      )
    );
  }
}

export const Limit = as<React.StatelessComponent<ILimitProps>>(withNamespaces('billing', { wait: true })(LimitPure));
