import * as React from 'react';

import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { ClipLoader } from 'react-spinners';
import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IAttachmentUploadProgressData } from '../attachmentUploadStore';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { ProgressBar } from '../../../components/ProgressBar';
import { as } from '../../../helpers/react.helpers';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { handleClickAndPassData } from '../../../helpers/formHelpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

library.add(faTimes);

export interface IAttachmentUploadDropdownProps {
  progressData: IAttachmentUploadProgressData[];
  showConfirmModal: boolean;
  onToggleConfirmModal(): void;
  onCancelUpload(attachmentId: string): void;
  onCancelUploadConfirm(): void;
}

const DropdownItemWithProgressBar = (props: {
  attachment: IAttachmentUploadProgressData,
  onCancelUpload(attachmentId: string): void,
}) => (
  <DropdownItem tag="div" toggle={false} key={props.attachment.id} className="datasets-single-item">
    <div className="top">
      <div className="name text-left">{props.attachment.name}</div>
      <div className="current-progress text-right">{props.attachment.progress}%</div>
    </div>
    <div className="bottom">
    <ProgressBar success={props.attachment.isSuccess} value={props.attachment.progress} />
      {props.attachment.progress < 100 && <div onClick={handleClickAndPassData(props.onCancelUpload)(props.attachment.id)}>
        <FontAwesomeIcon icon={faTimes} color="red" />
      </div>}
    </div>
  </DropdownItem>
);

@observer
class AttachmentUploadDropdownPure extends React.Component<IAttachmentUploadDropdownProps & ITranslatable> {

  render() {
    return (
      <div className="top-nav-item" >
        {
          this.props.progressData.length > 0 && (
            <UncontrolledDropdown className="top-nav-dropdown attachments-upload">
              <DropdownToggle caret>
                <ClipLoader loading={true} size={14} /> <span>{this.props.t('attachments_upload')}</span>
              </DropdownToggle>
              <DropdownMenu right>
                {this.props.progressData
                  .map(attachment => DropdownItemWithProgressBar({ attachment, onCancelUpload: this.props.onCancelUpload }))}
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
        <ConfirmationModal
          showModal={this.props.showConfirmModal}
          onConfirm={this.props.onCancelUploadConfirm}
          onCancel={this.props.onToggleConfirmModal}
          confirmationHeader="cancel_attachment_upload"
          confirmationQuestion="attachment_upload_cancelation_confirmation_question"
        />
      </div>
    );
  }
}

export const AttachmentUploadDropdown = as<React.ComponentClass<IAttachmentUploadDropdownProps>>(
  withNamespaces('common', { wait: true })(AttachmentUploadDropdownPure),
);
