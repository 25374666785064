import './S_CollapseSection.scss';

import React, { useState } from 'react';
import { faAngleDown, faAngleUp, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
  showInfoIcon?: boolean;
  infoIconTooltip?: string;
  onInfoIconClick?(e: any): void;
}

export const S_CollapseSection: React.FC<Props> = props => {
  const { title, subtitle, children, showInfoIcon, infoIconTooltip, onInfoIconClick } = props;

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <div className="s-collapse-section-header disable-selection" onClick={toggle}>
        <div className="section-title-container">
          <p className="section-title">{title}</p>
          <p className="section-subtitle">{subtitle}</p>
          {showInfoIcon && (
            <p className="section-info-icon" onClick={onInfoIconClick}>
              <FontAwesomeIcon icon={faInfoCircle} title={infoIconTooltip} />
            </p>
          )}
        </div>
        <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} />
      </div>
      {isOpen && <div className="s-collapse-section-body">{children}</div>}
    </div>
  );
};
