import 'react-datepicker/dist/react-datepicker.css';

import { CheckboxMultiSelect } from '../../../../../../../components/CheckboxMultiSelect';
import { FilterContent } from '../FilterContent';
import { ISelectOption } from '../../../../../../../helpers/formHelpers';
import { ITranslatable } from '../../../../../../../helpers/translations.helpers';
import { ImageFiltersFormProps } from '../ImageFiltersForm';
import React from 'react';
import { as } from '../../../../../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

@observer
class CorrectedBodyPure extends React.Component<ImageFiltersFormProps & ITranslatable> {

  @autobind
  handleSelectedChanged(selected: ISelectOption<boolean>[]) {
    this.props.onSetCorrected(selected.map(x => x.value));
  }

  render() {
    const options: ISelectOption<boolean>[] = [
      { label: this.props.t('yes'), value: true },
      { label: this.props.t('no'), value: false },
    ];

    const selectedOptions = options.filter(o => this.props.imageFilters.corrected.includes(o.value));

    return (
      <FilterContent labelKey={'corrected'}>
        <CheckboxMultiSelect<ISelectOption<boolean>>
          options={options}
          values={selectedOptions}
          labelField={'label'}
          valueField={'value'}
          onSelectChange={this.handleSelectedChanged}
        />
      </FilterContent>
    );
  }
}

export const CorrectedBody = as<React.ComponentClass<ImageFiltersFormProps>>(withNamespaces('project')(CorrectedBodyPure));
