import { ContainerModule, interfaces } from 'inversify';
import { ExportDataBl, ExportDataBlType, IExportDataBl } from './ExportData.bl';
import { ExportDataStore, ExportDataStoreImpl, ExportDataStoreSetter, ExportDataStoreSetterType, ExportDataStoreType } from './ExportData.store';
import { ExportProjectService, ExportProjectServiceType, IExportProjectService } from './services/ExportProject.service';
import { GetProjectExportsService, GetProjectExportsServiceType, IGetProjectExportsService } from './services/GetProjectExports.service';
import { IIsCurrentExportGeneratedService, IsCurrentExportGeneratedService, IsCurrentExportGeneratedServiceType } from './services/IsCurrentExportGenerated.service';

export const exportDataModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<ExportDataStore>(ExportDataStoreType).to(ExportDataStoreImpl).inSingletonScope();
  bind<ExportDataStoreSetter>(ExportDataStoreSetterType).toService(ExportDataStoreType);

  bind<IExportDataBl>(ExportDataBlType).to(ExportDataBl).inSingletonScope();

  bind<IGetProjectExportsService>(GetProjectExportsServiceType).to(GetProjectExportsService).inSingletonScope();
  bind<IExportProjectService>(ExportProjectServiceType).to(ExportProjectService).inSingletonScope();
  bind<IIsCurrentExportGeneratedService>(IsCurrentExportGeneratedServiceType).to(IsCurrentExportGeneratedService).inSingletonScope();
});
