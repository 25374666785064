import { AnnotationToolType } from '../../../../../../../__legacy__/modules/annotation/submodules/annotationTypes/models/annotationToolType';
import { getRandomRgb } from '../../../../../../../__legacy__/helpers/color.helpers';
import uuid from 'uuid';

export class MarkingTool {
  id: string;
  name: string;
  color: string;
  selectorType: AnnotationToolType;
  isUsed: boolean;
  order: number;

  constructor(id: string, name: string, color: string, selectorType: AnnotationToolType, isUsed: boolean, order: number) {
    this.id = id;
    this.name = name;
    this.color = color;
    this.selectorType = selectorType;
    this.isUsed = isUsed;
    this.order = order;
  }

  static empty() {
    return new MarkingTool(uuid.v4(), '', getRandomRgb(), AnnotationToolType.RECTANGLE, false, 0);
  }
}
