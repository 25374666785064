import { Col, Container, Row } from 'reactstrap';
import { faArrowAltCircleLeft, faBan, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { AcceptIcon } from '../../../components/icons/AcceptIcon';
import { DiscardAnnotationConfirmationModal } from './DiscardAnnotationConfirmationModal';
import { EditorMode } from '../../../../modules/editor/models/EditorModes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { NavigationButton } from './NavigationButton';
import { QuitAnnotationsModal } from './QuitAnnotationsModal';
import React from 'react';
import RequestClarificationButtonContainer from '../submodules/clarifications/containers/RequestClarificationButton.container';
import { as } from '../../../helpers/react.helpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';

library.add(faArrowAltCircleLeft, faCheckCircle, faTimesCircle, faBan);

export interface ICorrectionNavigationComponentProps {
  onSubmitAndAccept(): void;
  onReject(): void;
  onCancel(): void;
  onCancelCancel(): void;
  onConfirmCancel(): void;
  onDiscardConfirmed(): void;
  onDiscardCanceled(): void;
  onDiscard(): void;
  showDiscardModal: boolean;
  disabled: boolean;
  showCancelModal: boolean;
  canDiscard: boolean;
  editorMode: EditorMode;
}

class CorrectNavigationPure extends React.Component<ICorrectionNavigationComponentProps & ITranslatable> {
  render() {
    return (
      <Container fluid className="annotation-navigation">
        <DiscardAnnotationConfirmationModal
          showDiscardModal={this.props.showDiscardModal}
          onDiscardCancel={this.props.onDiscardCanceled}
          onDiscardConfirm={this.props.onDiscardConfirmed}
        />
        <QuitAnnotationsModal isOpen={this.props.showCancelModal} onCancel={this.props.onCancelCancel} onConfirm={this.props.onConfirmCancel} />
        <Row>
          <Col>
            <NavigationButton loaderKey="cancel-annotation-button" disabled={this.props.disabled} onClick={this.props.onCancel} title={'cancel_correct_tooltip'} caption="cancel">
              <FontAwesomeIcon icon={faArrowAltCircleLeft} />
            </NavigationButton>
            {this.props.canDiscard && (
              <NavigationButton
                id="discard-annotation"
                loaderKey="discard-annotation-button"
                disabled={this.props.disabled}
                onClick={this.props.onDiscard}
                title={'discard_tooltip'}
                caption="discard"
              >
                <FontAwesomeIcon icon={faBan} />
              </NavigationButton>
            )}
            <RequestClarificationButtonContainer disabled={this.props.disabled} editorMode={this.props.editorMode} />
          </Col>
          <Col className="on-right">
            <NavigationButton loaderKey="reject-button" disabled={this.props.disabled} onClick={this.props.onReject} title={'reject_tooltip'} caption="reject">
              <FontAwesomeIcon icon={faTimesCircle} />
            </NavigationButton>
            <NavigationButton
              id="submit-annotation"
              loaderKey="submit-and-accept-button"
              disabled={this.props.disabled}
              onClick={this.props.onSubmitAndAccept}
              title={'submit_and_Accept_tooltip'}
              caption="submit_and_accept"
            >
              <AcceptIcon />
            </NavigationButton>
          </Col>
        </Row>
      </Container>
    );
  }
}

export const CorrectNavigation = as<React.ComponentClass<ICorrectionNavigationComponentProps>>(withNamespaces('annotation', { wait: true })(CorrectNavigationPure));
