import { FilterHeader } from '../FilterHeader';
import { FilterTabType } from '../../../imageFilters.model';
import { ITranslatable } from '../../../../../../../helpers/translations.helpers';
import { ImageFiltersFormProps } from '../ImageFiltersForm';
import React from 'react';
import { as } from '../../../../../../../helpers/react.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

@observer
class ImageSetHeaderPure extends React.Component<ImageFiltersFormProps & ITranslatable> {
  handleClear = () => this.props.onSetImageSets([]);

  render() {
    const isSelected = this.props.activeFilterType === FilterTabType.ImageSet;
    const count = this.props.imageFilters.imageSets.length;

    return (
      <FilterHeader
        filterType={FilterTabType.ImageSet}
        isSelected={isSelected}
        labelKey={'image_set'}
        count={count}
        showClear={count > 0}
        onClear={this.handleClear}
        onSelect={this.props.onFilterTabSelect}
      />
    );
  }
}

export const ImageSetHeader = as<React.ComponentClass<ImageFiltersFormProps>>(withNamespaces('common')(ImageSetHeaderPure));
