import { SortingDirection } from '../models/sortingDirection.model';
import _ from 'lodash';

export function sortBy<T>(collection: T[], orderBy: string, sortingDirection?: SortingDirection, defaultOrderBy?: string, defaultDirection?: SortingDirection): T[] {
  if (sortingDirection) {
    return _.orderBy(collection, orderBy, toLodashDirection(sortingDirection));
  }
  if (defaultOrderBy && defaultDirection) {
    return _.orderBy(collection, defaultOrderBy, toLodashDirection(defaultDirection));
  }
  return collection;
}

function toLodashDirection(sortingDirection: SortingDirection) {
  if (sortingDirection === SortingDirection.ASC) {
    return 'asc';
  }
  return 'desc';
}
