import { ContainerModule, interfaces } from 'inversify';
import { IUpdateWorkspaceTimeZoneBl, UpdateWorkspaceTimeZoneBl, UpdateWorkspaceTimeZoneBlType } from './sub/UpdateWorkspaceTimeZone/UpdateWorkspaceTimeZone.bl';
import { IUpdateWorkspaceTimeZoneService, UpdateWorkspaceTimeZoneService, UpdateWorkspaceTimeZoneServiceType } from './sub/UpdateWorkspaceTimeZone/services/UpdateWorkspaceTimeZone.service';
import { IUpdateWorkspaceTimeZoneStore, IUpdateWorkspaceTimeZoneStoreSetter, UpdateWorkspaceTimeZoneStore, UpdateWorkspaceTimeZoneStoreSetterType, UpdateWorkspaceTimeZoneStoreType } from './sub/UpdateWorkspaceTimeZone/UpdateWorkspaceTimeZone.store';
import { IWorkspaceService, WorkspaceService, WorkspaceServiceType } from './workspaces.service';
import { IWorkspacesPermissions, WorkspacesPermissions, WorkspacesPermissionsType } from './workspaces.permissions';
import { IWorkspacesStore, WorkspacesStore, WorkspacesStoreType } from './workspaces.store';

export const workspacesModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IWorkspacesStore>(WorkspacesStoreType).to(WorkspacesStore).inSingletonScope();
  bind<IWorkspaceService>(WorkspaceServiceType).to(WorkspaceService).inSingletonScope();
  bind<IUpdateWorkspaceTimeZoneBl>(UpdateWorkspaceTimeZoneBlType).to(UpdateWorkspaceTimeZoneBl).inSingletonScope();
  bind<IUpdateWorkspaceTimeZoneStore>(UpdateWorkspaceTimeZoneStoreType).to(UpdateWorkspaceTimeZoneStore).inSingletonScope();
  bind<IUpdateWorkspaceTimeZoneStoreSetter>(UpdateWorkspaceTimeZoneStoreSetterType).toService(UpdateWorkspaceTimeZoneStoreType);
  bind<IUpdateWorkspaceTimeZoneService>(UpdateWorkspaceTimeZoneServiceType).to(UpdateWorkspaceTimeZoneService).inRequestScope();
  bind<IWorkspacesPermissions>(WorkspacesPermissionsType).to(WorkspacesPermissions).inSingletonScope();
});
