import { AddMarkingToolBl, AddMarkingToolBlType } from '../../AddMarkingTool.bl';
import { AddMarkingToolStore, AddMarkingToolStoreType } from '../../AddMarkingTool.store';
import { as, injectProps } from '../../../../../../../../__legacy__/helpers/react.helpers';

import { ColorFieldContainer } from '../fields/ColorField.container';
import { MarkingToolForm } from '../../../commons/ui/markingToolForm/MarkingToolForm';
import { MarkingToolModal } from '../../../commons/MarkingToolModal';
import { NameFieldContainer } from '../fields/NameField.container';
import React from 'react';
import { ToolTypeFieldContainer } from '../fields/ToolTypeField.container';
import { WithLoaderComponentBase } from '../../../../../../../../__legacy__/helpers/loader.helpers';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: AddMarkingToolBl;
  store: AddMarkingToolStore;
}

@injectProps({
  bl: AddMarkingToolBlType,
  store: AddMarkingToolStoreType,
})
@observer
class ModalContainerPure extends WithLoaderComponentBase<InjectedProps> {
  handleSubmit = async () => await this.withLoaderAsync(this.props.bl.addMarkingToolAsync, 'add-or-edit-marking-tool-key');
  handleHide = () => this.props.bl.hideModal();
  
  render() {
    return (
      <MarkingToolModal headerKey="new.projects.details.marking_tools.add.header" onHide={this.handleHide} onSubmit={this.handleSubmit} show={this.props.store.showModal}>
        <MarkingToolForm>
          <NameFieldContainer />
          <ToolTypeFieldContainer />
          <ColorFieldContainer />
        </MarkingToolForm>
      </MarkingToolModal>
    );
  }
}

export const AddMarkingToolModalContainer = as<React.ComponentClass>(ModalContainerPure);
