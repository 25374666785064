import leaflet from 'leaflet';

export class CircleMarkerEditorEx extends leaflet.Editable.CircleMarkerEditor {
  addHooks() {
    super.addHooks();
    if (this.feature) this.initVertexMarkers(undefined);
    return this;
  }

  initVertexMarkers(latlng: any) {
    if (!this.enabled()) return;
    this.addVertexMarker(latlng || this.getLatLng());
  }

  getLatLng() {
    return this.feature.getLatLng();
  }

  addVertexMarker(latlng: any) {
    return new this.tools.options.vertexMarkerClass(latlng, [latlng], this);
  }

  onVertexMarkerClick(e: any) {
    leaflet.Editable.makeCancellable(e);
    // Fired when a `click` is issued on a vertex, before any internal action is being processed.
    this.fireAndForward('editable:vertex:click', e);
    if (e._cancelled) return;
    if (this.tools.drawing() && this.tools._drawingEditor !== this) return;
    this.fireAndForward('editable:vertex:clicked', e);
    this.commitDrawing(e);
  }

  onVertexRawMarkerClick(e: any) {
    // Fired when a `click` is issued on a vertex without any special key and without being in drawing mode.
    this.fireAndForward('editable:vertex:rawclick', e);
    if (e._cancelled) return;
  }

  onVertexDeleted(e: any) {
    this.fireAndForward('editable:vertex:deleted', e);
  }

  onVertexMarkerCtrlClick(e: any) {
    this.fireAndForward('editable:vertex:ctrlclick', e);
  }

  onVertexMarkerShiftClick(e: any) {
    this.fireAndForward('editable:vertex:shiftclick', e);
  }

  onVertexMarkerMetaKeyClick(e: any) {
    this.fireAndForward('editable:vertex:metakeyclick', e);
  }

  onVertexMarkerAltClick(e: any) {
    this.fireAndForward('editable:vertex:altclick', e);
  }

  onVertexMarkerContextMenu(e: any) {
    this.fireAndForward('editable:vertex:contextmenu', e);
  }

  onVertexMarkerMouseDown(e: any) {
    this.fireAndForward('editable:vertex:mousedown', e);
  }

  onVertexMarkerMouseOver(e: any) {
    this.fireAndForward('editable:vertex:mouseover', e);
  }

  onVertexMarkerMouseOut(e: any) {
    this.fireAndForward('editable:vertex:mouseout', e);
  }

  onVertexMarkerDrag(e: any) { }
  onVertexMarkerDragStart(e: any) { }
  onVertexMarkerDragEnd(e: any) {
    this.fireAndForward('editable:circle:vertex:dragend', e);
  }

  onDragStart(e: any) {
    this.editLayer.clearLayers();
    super.onDragStart(e);
  }

  onDrag(e: any) {
    this.onMove(e);
    this.fireAndForward('editable:drag', e);
  }

  onDragEnd(e: any) {
    this.initVertexMarkers(undefined);
    super.onDragEnd(e);
    this.refresh();
  }

  onDrawingMouseUp(e: any) {
    this.commitDrawing(e);
    e.originalEvent._simulated = false;
    super.onDrawingMouseUp(e);
    this.refresh();
  }

  refresh() {
    this.feature.redraw();
    this.onEditing();
  }
}
