import { DatasetStatus } from '../datesets/datasetStatus.model';
import { ProjectRole } from '../../models/userRole.model';
import { ProjectStatus } from '../projects/projects.model';

export enum ToolDeleteStrategy {
  None = 'None',
  Leave = 'Leave',
  MoveToReview = 'MoveToReview',
  MoveToDraft = 'MoveToDraft',
  Discard = 'Discard',
}

export interface IProjectDetails {
  workspaceId: string;
  name: string;
  lastAnnotationDate?: string;
  description: string;
  offset?: number;
  statisticsGeneratedOn?: Date;
  status: ProjectStatus;
  imageAssignmentPolicy: string;
  hasImportedAnnotations: boolean;
  createdDate: Date;
  isCreatedBeforeStatsRelease: boolean;
}

export interface IProjectDataset {
  id: string;
  name: string;
  description: string;
  imagesCount: number;
  reviewsDonePercentage: number;
  status: DatasetStatus;
  order: number;
}

export interface IProjectAvailableDatasetDto {
  id: string;
  name: string;
  description: string;
  imagesCount: number;
  reviewsDonePercentage: number;
  status: DatasetStatus;
}

export interface IUserDetails {
  id: string;
  email: string;
  role: ProjectRole;
  joinDate?: string;
  lastLoginDate?: string;
  totalReviewed: number;
  totalSubmitted: number;
}

export interface IMembers {
  users: IUserDetails[];
}

export interface IDatasetsTabDto {
  availableDatasets: IProjectAvailableDatasetDto[];
  projectDatasets: IProjectDataset[];
}

export interface IUpdateProjectDetailsPayload {
  projectId: string;
  name: string;
  description: string;
}

export interface ICanPublishProjectResponse {
  isNameUnique: boolean;
  isNameValid: boolean;
  areDatasetsValid: boolean;
  areToolsValid: boolean;
}

export interface IUpdateDatasetsOrderRequest {
  projectId: string;
  datasetsOrders: { id: string, order: number }[];
}

export interface IUpdateImageAssignmentPolicyRequest {
  projectId: string;
  policy: string;
}

export enum ImageAssignmentPolicy {
  NONE = 'None',
  RANDOM = 'Random',
  SEQUENTIAL = 'Sequential',
  PARALLEL = 'Parallel',
}

export enum ProjectPublishStrategy {
  None = 'None',
  KeepDrafts = 'KeepDrafts',
  DiscardDrafts = 'DiscardDrafts',
}
