import * as React from 'react';

import { WithNamespaces, withNamespaces } from 'react-i18next';
import { faEdit, faUnlink } from '@fortawesome/free-solid-svg-icons';

import { AttachmentThumbnail } from './AttachmentThumbnail';
import { IAttachment } from '../attachments.store';
import { Loader } from '../../../components/Loader';
import { S_Pagination } from '../../../../design/pagination/S_Pagination';
import autobind from 'autobind-decorator';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { scrollTop } from '../../../helpers/react.helpers';

library.add(faUnlink, faEdit);

export interface IAttachmentsListThumbsProps {
  attachments: IAttachment[];
  authToken: string;
  isLoading: boolean;
  isDownloadDisabled: boolean;
  workspaceId: string;
  selectedAttachments: {
    ids: string[],
    isAllSelected: boolean,
  };
  pagination: {
    pageNumber: number,
    pageSize: number,
    totalCount: number,
  };
  canView: boolean;
  onPaginationChange(pageNumber: number, pageSize: number): void;
  onThumbCheck(id: string, isBatch: boolean): void;
  onThumbClick(id: string): void;
  onThumbDelete(id: string): void;
  getThumbnailUrl(id: string): string;
}

@observer
class AttachmentsListThumbsPure extends React.Component<IAttachmentsListThumbsProps & WithNamespaces> {

  @autobind
  handlePaginationChange(pageNumber: number, pageSize: number) {
    scrollTop();
    this.props.onPaginationChange(pageNumber, pageSize);
  }

  render() {
    return (
      <div className="details-attachment-list">
        <Loader isLoading={this.props.isLoading}>
          <div className="thumbnails">
            {this.props.attachments.map((attachment: IAttachment) => {
              return (<AttachmentThumbnail
                key={attachment.id}
                attachment={attachment}
                authToken={this.props.authToken}
                isChecked={this.props.selectedAttachments.isAllSelected
                  ? !this.props.selectedAttachments.ids.includes(attachment.id)
                  : this.props.selectedAttachments.ids.includes(attachment.id)}
                workspaceId={this.props.workspaceId}
                t={this.props.t}
                onCheck={this.props.onThumbCheck}
                onClick={this.props.onThumbClick}
                onDelete={this.props.onThumbDelete}
                getThumbnailUrl={this.props.getThumbnailUrl}
                disabled={!this.props.canView}
                previewDisabled={this.props.isDownloadDisabled}
              />);
            })}
          </div>
          <div className="thumbnails-paging">
            <S_Pagination
              pageNumber={this.props.pagination.pageNumber}
              pageSize={this.props.pagination.pageSize}
              totalCount={this.props.pagination.totalCount}
              onChange={this.handlePaginationChange}
            />
          </div>
        </Loader>
      </div>
    );
  }
}

export const AttachmentsListThumbs = withNamespaces('attachment', { wait: true })(AttachmentsListThumbsPure);
