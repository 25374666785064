import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../helpers/react.helpers';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

library.add(faCircleNotch);

export interface IProps {
  onConfirm(): void;
  onCancel(): void;
  onToggle(): void;
  isOpen: boolean;
  isLoading: boolean;
}

@observer
class SaveDraftAnnotationsModalPure extends React.Component<IProps & ITranslatable> {
  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>{this.props.t('warning')}</ModalHeader>
        <ModalBody>
          <p className="mt-3">{this.props.t('do_you_want_to_save_your_current_work')}</p>
        </ModalBody>
        <ModalFooter>
          <Button className="mr-2" color="success" onClick={this.props.onConfirm} disabled={this.props.isLoading}>
            {this.props.isLoading ? <FontAwesomeIcon icon={faCircleNotch} className={'fa-spin'} /> : this.props.t('save')}
          </Button>
          <Button className="mr-2" color="warning" onClick={this.props.onCancel}>
            {this.props.t('do_not_save')}
          </Button>
          <Button outline color="primary" onClick={this.props.onToggle}>
            {this.props.t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export const SaveDraftAnnotationsModal = as<React.SFC<IProps>>(withNamespaces('common', { wait: true })(SaveDraftAnnotationsModalPure));
