import { WithNamespaces, withNamespaces } from 'react-i18next';

import React from 'react';
import { S_LinkInTable } from '../../../../../design/links/linkInTable/S_LinkInTable';
import { S_WorkspaceOwnerIcon } from '../../../../../design/icons/S_WorkspaceOwnerIcon/S_WorkspaceOwnerIcon';

interface Props {
  workspaceId: string;
  name: string;
  isEncrypted: boolean;
  isLocked: boolean;
  isMy: boolean;
  onClick: (workspaceId: string) => void;
}

const CurrentWorkspaceSelectLinkInTablePure: React.FC<Props & WithNamespaces> = props => {
  const handleClick = () => props.onClick(props.workspaceId);
  const prefix = props.isEncrypted ? '🔒 ' : '';
  const suffix = props.isLocked ? '(locked)' : '';
  return (
    <>
      <S_WorkspaceOwnerIcon isMy={props.isMy} notOwnerTitle={props.t('workspaces.list.you_are_invited_tooltip')} />
      <S_LinkInTable disabled={props.isLocked} title={props.name} onClick={handleClick}>{`${prefix}${props.name}${suffix}`}</S_LinkInTable>
    </>
  );
};

export const CurrentWorkspaceSelectLinkInTable = withNamespaces('new')(CurrentWorkspaceSelectLinkInTablePure);
