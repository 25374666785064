import * as React from 'react';

import { Card, CardBody, Form, FormGroup, Label, NavLink } from 'reactstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { Auth } from '../../../routes/config/Auth';
import { AuthFormTabs } from './AuthFormTabs';
import { ButtonWithLoader } from '../../../components/ButtonWithLoader';
import { ExternalLinks } from '../../../routes/config/ExternalLinks';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { InputStatus } from '../../../models/error.model';
import { PasswordWithScoreInputsContainer } from '../containers/PasswordWithScoreInputsContainer';
import ReCAPTCHA from 'react-google-recaptcha';
import Select from 'react-select';
import { ValidatedCheckboxInput } from '../../../components/ValidatedCheckboxInput';
import { ValidatedTextInput } from '../../../components/ValidatedTextInput';
import { as } from '../../../helpers/react.helpers';
import countryList from 'react-select-country-list';
import { formAlertWithTranslations } from '../../../components/FormAlert';
import { handleSelectChange } from '../../../helpers/formHelpers';
import { withNamespaces } from 'react-i18next';

export interface IRegistrationFormProps {
  onEmailChange(value: string): void;
  onPasswordChange(value: string): void;
  onPasswordStatusChange(status: InputStatus): void;
  onAcceptRegulationsChange(): void;
  onAcceptNewsletterChange(): void;
  validateEmail(value: string): void;
  onRegister(): void;
  onCaptchaVerified(value: any): void;
  onCompanyChange(value: string): void;
  onCountryChange(value: string): void;
  emailStatus: InputStatus;
  passwordStatus: InputStatus;
  externalStatus: InputStatus;
  isDisabledSubmit: boolean;
  passwordControlRef: React.RefObject<PasswordWithScoreInputsContainer>;
  captchaRef: React.RefObject<ReCAPTCHA>;
  email: string;
  company: string;
  country: string;
  areRegulationsAccepted: boolean;
  isNewsletterAccepted: boolean;
  regulationsStatus: InputStatus;
  enableCaptcha: boolean;
  captchaSecret: string;
}

class RegistrationFormPure extends React.Component<IRegistrationFormProps & RouteComponentProps & ITranslatable> {
  regulationsLabel() {
    return (
      <p className="regulation-label">
        {this.props.t('i_am_over_18_and_accept_regulations')}
        &nbsp;
        <NavLink className="styled_link" href={ExternalLinks.Regulations.TermsAndConditions.Path} target="_blank">
          {this.props.t('terms_and_conditions')}
        </NavLink>
        {this.props.t('and')}
        <NavLink className="styled_link" href={ExternalLinks.Regulations.PrivacyPolicy.Path} target="_blank">
          {this.props.t('privacy_policy')}
        </NavLink>
        {this.props.t('of_zillin')}
      </p>
    );
  }

  newsletterLabel() {
    return (
      <p className="regulation-label">
        {this.props.t('I agree to receive Zillin\'s newsletter')}
      </p>
    );
  }

  handleMouseDown(event: any) {
    event.preventDefault();
  }

  render() {
    const validationErrorOccurred =
      this.props.externalStatus.isValid === false ||
      this.props.emailStatus.isValid === false ||
      this.props.passwordStatus.isValid === false ||
      this.props.regulationsStatus.isValid === false;

    const Errors = formAlertWithTranslations('auth');

    const countryOptions = countryList().getData();

    return (
      <Card>
        <CardBody>
          <AuthFormTabs />
          <Errors errors={this.props.externalStatus} useDefaultMessageWhenCodeNotFound={true} />
          <Form className="register">
            {this.props.enableCaptcha && (
              <ReCAPTCHA
                sitekey={this.props.captchaSecret}
                size="invisible"
                ref={this.props.captchaRef}
                onChange={this.props.onCaptchaVerified}
              />
            )}

            <ValidatedTextInput
              id="register-email-input"
              value={this.props.email}
              labelText={this.props.t('email_address')}
              type={'text'}
              placeholder={this.props.t('enter_business_email')}
              onChange={this.props.onEmailChange}
              onBlur={this.props.validateEmail}
              isValid={this.props.emailStatus.isValid}
              feedbacks={this.props.emailStatus.errorCodes.map(this.props.t)}
            />
            <PasswordWithScoreInputsContainer
              labelText={this.props.t('password')}
              ref={this.props.passwordControlRef}
              onPasswordChange={this.props.onPasswordChange}
              onPasswordStatusChange={this.props.onPasswordStatusChange}
            />
            <ValidatedTextInput
              id="register-company-input"
              value={this.props.company}
              labelText={this.props.t('organisation')}
              type={'text'}
              placeholder={this.props.t('enter_organisation')}
              onChange={this.props.onCompanyChange}
            />
            <FormGroup className="form-group-layout">
              <Label>{this.props.t('country')}</Label>
              <Select
                id="country-dropdown"
                placeholder={this.props.t('enter_country')}
                defaultValue={null}
                value={countryOptions.find((o: any) => o.value === this.props.country)}
                onChange={handleSelectChange(this.props.onCountryChange)}
                options={countryOptions}
                classNamePrefix="custom-select"
              />
            </FormGroup>
            <ValidatedCheckboxInput
              onChange={this.props.onAcceptRegulationsChange}
              checked={this.props.areRegulationsAccepted}
              isValid={this.props.regulationsStatus.isValid}
              id="accept_regulations"
              className="register-checkbox"
              labelChildren={this.regulationsLabel()}
              feedbacks={this.props.regulationsStatus.errorCodes}
            />
            <ValidatedCheckboxInput
              onChange={this.props.onAcceptNewsletterChange}
              checked={this.props.isNewsletterAccepted}
              className="register-checkbox"
              id="accept_newsletter"
              labelChildren={this.newsletterLabel()}
            />
            <ButtonWithLoader
              block
              id="create-account-button"
              loaderKey="create-account-button"
              color="primary"
              disabled={validationErrorOccurred || this.props.isDisabledSubmit}
              isLoading={this.props.isDisabledSubmit}
              onClick={this.props.onRegister}
            >
              {this.props.t('create_account')}
            </ButtonWithLoader>

            <p className="already-have-account-text">
              {this.props.t('already_have_an_account')}
              <Link
                onMouseDown={this.handleMouseDown}
                to={`${Auth.Login.Path}/${this.props.location.search}`}
              >
                {this.props.t('log_in_here')}
              </Link>
            </p>
          </Form>
        </CardBody>
      </Card>
    );
  }
}

export const RegistrationForm = as<React.SFC<IRegistrationFormProps>>(withRouter(withNamespaces('auth')(RegistrationFormPure)));
