// tslint:disable:function-name

import { Draggable } from 'leaflet';

export class DraggableEx extends Draggable {
  dragButtons: number;

  constructor(dragButtons: number, element: HTMLElement, dragStartTarget?: HTMLElement, preventOutline?: boolean) {
    super(element, dragStartTarget, preventOutline);
    this.dragButtons = dragButtons;
  }

  _onDown(e: MouseEvent) {
    if (!(this.dragButtons & e.buttons)) return;
    super._onDown(e);
  }
}
