
import { inject, injectable } from 'inversify';

import { ForbiddenError, StickerError } from '../../../../models/error.model';
import { QuestionModel } from '../../../annotation/question.model';
import _ from 'lodash';
import { ImageSetType } from './projectDetailsImages.model';
import { IProjectDetailsImagesApiService, ProjectDetailsImagesApiServiceType } from './services/projectDetailsImagesApi.service';
import { IProjectDetailsSubmodule } from '../../services/IProjectDetailsSubmodule';
import { ICanPublishProjectResponse } from '../../projectDetails.models';
import { IProjectDetailsImagesStoreSetter, ProjectDetailsImagesStoreSetterType } from './projectDetailsImages.store';
import { ToastNotification, NotificationLevel } from '../../../notifications/models/notification.model';
import autobind from 'autobind-decorator';
import { IImageFilterService, ImageFilterServiceType } from './services/imageFilters.service';
import { EventBusType, EventListeningDisposer, IEventBus } from '../../../../services/eventBus.service';
import { NotificationsServiceType, INotificationsService } from '../../../notifications/services/notifications.service';
import { ImportAnnotationsFinished, ImportAnnotationsFinishedType } from '../../../../../modules/projects/details/images/importAnnotations/events/ImportAnnotationsFinished';

export const ProjectDetailsImagesBlType = Symbol('PROJECT_DETAILS_IMAGES_SERVICE');

export interface IProjectDetailsImagesBl extends IProjectDetailsSubmodule {
  // images
  getImageTabDataAsync(projectId: string, filterId: string | undefined): Promise<void>;
  getProjectImagesAsync(): Promise<void>;

  // batch answer
  toggleAnswerQuestionsModal(): void;
  batchAnswerQuestionsAsync(): Promise<void>;
  updateBatchAnswerQuestionsProgressAsync(failed: boolean, totalBatchesCount: number): void;
  batchAnswerQuestionsRequestFinishedAsync(): void;

  // sets
  updateProjectImageSetAsync(imageId: string, setType: ImageSetType): Promise<void | StickerError>;
  updateBatchProjectImageSetAsync(setType: ImageSetType): Promise<void | StickerError>;

  // other/common
  changeSelectionMode(): void;
  setToggledImages(selectedImages: string[], lastSelectedId: string | undefined): void;
}

@injectable()
export class ProjectDetailsImagesBl implements IProjectDetailsImagesBl {
  private importAnnotationsFinishedDisposer?: EventListeningDisposer;

  constructor(
    @inject(ProjectDetailsImagesStoreSetterType) private readonly store: IProjectDetailsImagesStoreSetter,
    @inject(ProjectDetailsImagesApiServiceType) private readonly projectDetailsImagesApiService: IProjectDetailsImagesApiService,
    @inject(NotificationsServiceType) private readonly notificationsService: INotificationsService,
    @inject(ImageFilterServiceType) private readonly imageFilterService: IImageFilterService,
    @inject(EventBusType) private readonly eventBus: IEventBus,
  ) { }

  initialize(): void {
    this.importAnnotationsFinishedDisposer = this.eventBus.addListener(this.handleImportAnnotationsFinishedAsync, ImportAnnotationsFinishedType);
    this.store.resetStorage('', undefined);
  }

  cleanup(): void {
    this.importAnnotationsFinishedDisposer?.();
    this.store.resetStorage('', undefined);
  }

  async validateAsync(): Promise<void> {
    // nop
  }

  getValidationErrors(): string[] {
    return [];
  }

  handleCanPublishCheck(_: ICanPublishProjectResponse): void {
    // nop
  }

  @autobind
  async handleImportAnnotationsFinishedAsync(event: ImportAnnotationsFinished): Promise<void> {
    if (this.store.projectId !== event.projectId) return;
    await this.getProjectImagesAsync();
  }

  async getImageTabDataAsync(projectId: string, filterId: string | undefined): Promise<void> {
    this.store.resetStorage(projectId, filterId);

    const [questionsResult] = await Promise.all([
      this.projectDetailsImagesApiService.getQuestions({ projectId }),
      this.imageFilterService.getFilterDataAsync(projectId, filterId),
    ]);

    if (questionsResult instanceof StickerError) return;

    this.store.setQuestions(
      questionsResult.map(q => new QuestionModel(projectId, q.id, q.type, q.isRequired, q.text, q.answers, [], q.answer)),
    );
  }

  async getProjectImagesAsync(): Promise<void> {
    await this.imageFilterService.filterImages();
  }

  changeSelectionMode() {
    this.store.setSelectionMode(this.store.selectionMode === 'Select' ? 'Deselect' : 'Select');
  }

  setToggledImages(toggledImages: string[], lastSelectedId: string | undefined) {
    this.store.setToggledImages(toggledImages, lastSelectedId);
  }

  toggleAnswerQuestionsModal() {
    this.store.resetBatchAnswerQuestions();
  }

  async batchAnswerQuestionsAsync() {
    let isValid = true;

    _.each(this.store.questions, (q) => { if (!q.validate()) isValid = false; });

    if (!isValid) return;

    this.store.setBatchAnswerQuestionsRequestInProgress(true);

    const result = await this.projectDetailsImagesApiService.batchAnswerQuestionsAsync(
      {
        projectId: this.store.projectId,
        filterId: this.store.filterId,
        toggledImages: this.store.toggledImages.slice(),
        selectionMode: this.store.selectionMode,
        questions: this.store.questions.slice(),
        sessionId: this.store.batchAnswerQuestionModalSessionId!,
      });

    if (result instanceof StickerError) {
      let errorCode;

      if (result.apiErrorResponse && result.apiErrorResponse.errorCodes.length > 0) {
        errorCode = result.apiErrorResponse.errorCodes[0];
      }

      this.store.setBatchAnswerQuestionsRequestInProgress(false, errorCode);
    }
  }

  async updateBatchAnswerQuestionsProgressAsync(failed: boolean, totalBatchesCount: number) {
    const { batchAnswerFailedBatches, batchAnswerSuccessfulBatches } = this.store;

    if (failed) {
      this.store.setBatchAnswerQuestionsProgress(totalBatchesCount, batchAnswerFailedBatches + 1, batchAnswerSuccessfulBatches);
    } else {
      this.store.setBatchAnswerQuestionsProgress(totalBatchesCount, batchAnswerFailedBatches, batchAnswerSuccessfulBatches + 1);
    }
  }

  async batchAnswerQuestionsRequestFinishedAsync() {
    this.store.setSelectionMode('Select');
    await this.getProjectImagesAsync();
  }

  async updateProjectImageSetAsync(imageId: string, setType: ImageSetType): Promise<void | StickerError> {
    const currentImageSetType = this.store.detailsImages.find(i => i.id === imageId)!.imageSet;
    this.store.updateImageSetType(imageId, setType);

    const result = await this.projectDetailsImagesApiService.updateProjectImageSetAsync({
      projectId: this.store.projectId,
      filterId: '',
      toggledImages: [imageId],
      selectionMode: 'Deselect',
      projectImageSet: setType,
    });

    this.notifyAboutApiError(result, 'project:updating_project_images_set_failed');
    if (result instanceof StickerError) {
      this.store.updateImageSetType(imageId, currentImageSetType);
      return;
    }
  }

  async updateBatchProjectImageSetAsync(setType: ImageSetType): Promise<void | StickerError> {
    const result = await this.projectDetailsImagesApiService.updateProjectImageSetAsync({
      projectId: this.store.projectId,
      filterId: this.store.filterId,
      toggledImages: this.store.toggledImages.slice(),
      selectionMode: this.store.selectionMode,
      projectImageSet: setType,
    });

    this.notifyAboutApiError(result, 'project:updating_project_images_set_failed');
    if (result instanceof StickerError) return;

    await this.getProjectImagesAsync();
  }

  notifyAboutApiError(result: any, errorCode: string, dontShowBadRequest: boolean = false) {
    if (result instanceof StickerError && !(result instanceof ForbiddenError) && (!dontShowBadRequest || !result.isBadRequest())) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, errorCode));
    }
  }
}
