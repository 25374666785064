import { FilterContent } from '../FilterContent';
import { ISelectOption } from '../../../../../../../helpers/formHelpers';
import { ITranslatable } from '../../../../../../../helpers/translations.helpers';
import { ImageFiltersFormProps } from '../ImageFiltersForm';
import { ImageSetType } from '../../../projectDetailsImages.model';
import React from 'react';
import { ToogleButtonsMultiSelect } from '../../../../../../../components/ToogleButtonsMultiSelect';
import { as } from '../../../../../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

@observer
class ImageSetBodyPure extends React.Component<ImageFiltersFormProps & ITranslatable> {

  @autobind
  handleSelectedChanged(selected: ISelectOption<ImageSetType>[]) {
    this.props.onSetImageSets(selected.map(x => x.value));
  }

  render() {
    const options: ISelectOption<ImageSetType>[] =
      Object.values(ImageSetType).map(t => ({ label: this.props.t(`image_set_options.${t}`), value: t }));

    const selectedOptions = options.filter(o => this.props.imageFilters.imageSets.includes(o.value));

    return (
      <FilterContent labelKey={'image_set'}>
        <ToogleButtonsMultiSelect<ISelectOption<ImageSetType>>
          options={options}
          values={selectedOptions}
          labelField={'label'}
          valueField={'value'}
          onSelectChange={this.handleSelectedChanged}
        />
      </FilterContent>
    );
  }
}

export const ImageSetBody = as<React.ComponentClass<ImageFiltersFormProps>>(withNamespaces('project')(ImageSetBodyPure));
