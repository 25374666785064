import { BaseRouteConfig, StickerRoute } from './BaseRoute';

export class AnnotationsPath extends BaseRouteConfig {
  public get Index(): string {
    return 'annotations';
  }

  public readonly Create = new StickerRoute<{ workspaceId: string; projectId: string }>(`${this.Path}/:projectId/create`);
  public readonly Review = new StickerRoute<{ workspaceId: string; projectId: string }>(`${this.Path}/:projectId/review`);
}
