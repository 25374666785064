import { PaginationItem, PaginationLink } from 'reactstrap';
import React, { useState } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import { S_PaginationInput } from '../paginationControls/paginationInput/S_PaginationInput';

interface Props {
  lastPage: number;
  onChange(page: number): void;
}

export const S_PaginationGoToPure: React.FC<Props & WithNamespaces> = ({ lastPage, onChange, t }) => {
  const invalidPageError: string = 'Invalid page number';
  const [value, setValue] = useState<string>('');

  let insertedPage: number | undefined = undefined;

  const insertedPageIsOutOfRange = (): boolean => lastPage < insertedPage!;
  const navigateToLastPage = () => onChange(lastPage);
  const insertedPageIsBeforeFirstPage = (): boolean => insertedPage! < 1;
  const navigateToFirstPage = () => onChange(1);
  const navigateToInsertedPage = () => onChange(insertedPage!);

  const handleSubmit = () => {
    try {
      parseInsertedPage();
      if (insertedPageIsOutOfRange()) {
        navigateToLastPage();
      } else if (insertedPageIsBeforeFirstPage()) {
        navigateToFirstPage();
      } else {
        navigateToInsertedPage();
      }
    } catch (e) {
      if (e !== invalidPageError) throw e;
    } finally {
      setValue('');
    }
  };

  const parseInsertedPage = () => {
    insertedPage = Number.parseInt(value, 10);

    if (Number.isNaN(insertedPage)) {
      throw invalidPageError;
    }
  };

  return (
    <>
      <PaginationItem className="pagination-goto-input">
        <S_PaginationInput id="pagination-goto-input" onChange={setValue} value={value} onSubmit={handleSubmit} />
      </PaginationItem>
      <PaginationItem>
        <PaginationLink id="pagination-goto-button" onClick={handleSubmit}>
          {t('pagination.go')}
        </PaginationLink>
      </PaginationItem>
    </>
  );
};

export const StickerPaginationGotoTestable = S_PaginationGoToPure;
export const S_PaginationGoTo = withNamespaces('design')(S_PaginationGoToPure);
