import { CacheManager, CacheManagerType, ICacheManager } from '../services/cacheManager';
import { CryptoServiceType, ICryptoService } from '../services/crypto.service';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import React, { Component } from 'react';
import { as, injectProps } from '../helpers/react.helpers';

import { Loader } from './Loader';
import { StickerError } from '../models/error.model';
import autobind from 'autobind-decorator';
import { delay } from '../helpers/function.helpers';

const RETRY_NUMBER: number = 30;

interface IInjectedProps {
  store: ICurrentWorkspaceStore;
  cryptoService: ICryptoService;
  cacheManager: ICacheManager;
}

interface IProps {
  source: string;
  headers?: Headers;
}

type PropType = IInjectedProps & IProps;

interface IState {
  image?: string;
  retryCount: number;
}

@injectProps({
  store: CurrentWorkspaceStoreType,
  cryptoService: CryptoServiceType,
  cacheManager: CacheManagerType,
})
class LazyImagePure extends Component<PropType, IState> {
  currentTimeout?: NodeJS.Timeout = undefined;
  isComponentMounted: boolean = false;

  constructor(props: PropType) {
    super(props);

    this.state = {
      image: undefined,
      retryCount: 0,
    };
  }

  async componentDidMount() {
    this.getImage();
    this.isComponentMounted = true;
  }

  componentWillUnmount() {
    if (this.state.image) URL.revokeObjectURL(this.state.image);
    if (this.currentTimeout) clearTimeout(this.currentTimeout);
    this.isComponentMounted = false;
  }

  async getImage() {
    if (this.props.store.currentWorkspace?.encryption.encrypted) {
      while (!(await this.props.cryptoService.checkKey(this.props.store.currentWorkspace.id, this.props.store.currentWorkspace.id))) await delay(1000);
    }

    const blob = await this.fetch();

    if (!this.isComponentMounted) return;

    if (blob !== undefined) {
      const image = window.URL.createObjectURL(blob);
      this.setState({ image });
    } else {
      if (this.state.retryCount <= RETRY_NUMBER) {
        this.setState({ retryCount: this.state.retryCount + 1 }, () => {
          this.currentTimeout = setTimeout(() => this.getImage(), this.state.retryCount * 1000);
        });
      }
    }
  }

  @autobind
  async fetch(): Promise<Blob | undefined> {
    const cacheAvailable = await CacheManager.checkCacheAvailabilityAsync();

    if (cacheAvailable) {
      const cachedArrayBuffer = await this.props.cacheManager.tryGetFromCacheAsync(this.props.source);
      if (cachedArrayBuffer) {
        return new Blob([cachedArrayBuffer]);
      }
    }

    const response = await fetch(this.props.source, { headers: this.props.headers });

    if (response instanceof StickerError || response.status !== 200) {
      return undefined;
    }

    let arrayBufferResponse = await response.arrayBuffer();

    arrayBufferResponse = await this.props.cryptoService.decrypt(this.props.store.currentWorkspace!.id, arrayBufferResponse);

    if (cacheAvailable) {
      try {
        await this.props.cacheManager.makeRoomInCacheAsync(arrayBufferResponse.byteLength);
        await this.props.cacheManager.putInCacheAsync(this.props.source, arrayBufferResponse);
      } catch {
        // caching failed but the image can still be shown
      }
    }

    return new Blob([arrayBufferResponse]);
  }

  render() {
    return (
      <Loader isLoading={this.state.image === undefined}>
        <img src={this.state.image} />
      </Loader>
    );
  }
}

export const LazyImage = as<React.ComponentClass<IProps>>(LazyImagePure);
