import { IObservableArray, action, observable } from 'mobx';

import { IRejectionReason } from './models/rejectionReason';
import { injectable } from 'inversify';

export const ReviewRejectReasonsStoreType = Symbol('ANNOTATION_REVIEW_STORE');

export interface IReviewRejectReasonsStore {
  readonly rejectionReasons: IObservableArray<IRejectionReason>;
}

export interface IReviewRejectReasonsStoreSetter extends IReviewRejectReasonsStore {
  setRejectionReasons(rejectionReasons: IRejectionReason[]): void;
}

@injectable()
export class ReviewRejectReasonsStore implements IReviewRejectReasonsStoreSetter {
  @observable
  rejectionReasons: IObservableArray<IRejectionReason> = observable.array([]);

  @action
  setRejectionReasons(rejectionReasons: IRejectionReason[]): void {
    this.rejectionReasons.replace(rejectionReasons);
  }
}
