export interface IProjectExport {
  id: string;
  createdDate: string;
  status: ProjectExportStatus;
  fileSchemeVersion: string;
  configurationVersion: number;
  annotationsVersion: number;
  setVersion: number;
  hasAllFilesGenerated: boolean;
  lastDatasetsModifiedDate: string;
}

export enum ProjectExportStatus {
  None = 'None',
  New = 'New',
  InProgress = 'InProgress',
  Finished = 'Finished',
}