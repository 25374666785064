import * as is from 'is_js';

import Semaphore from 'semaphore-async-await';
import { injectable } from 'inversify';

const IMAGE_CACHE_NAME = 'image-cache';
const DATASET_PREVIEW_CACHE = 'image-preview-cache';
const TIMESTAMP_HEADER_NAME = 'timestamp';

export const CacheManagerType = Symbol('CACHE_MANAGER');

const CACHE_CLEANUP_SHIFT = 1000 * 60 * 60 * 24 * 3; // 72h
const CACHE_MINIMAL_SIZE = 150 * 1024 * 1024 * 8; // 150MB

export interface ICacheManager {
  tryGetFromCacheAsync(fullUrl: string): Promise<ArrayBuffer | undefined>;
  putInCacheAsync(fullUrl: string, response: ArrayBuffer): Promise<void>;
  makeRoomInCacheAsync(newEntrySize: number): Promise<void>;
  checkIfInCache(fullUrl: string): Promise<boolean>;
  cleanupOldEntriesAsync(): Promise<void>;
}

@injectable()
export class CacheManager implements ICacheManager {

  public static lock = new Semaphore(1);

  public static async checkCacheAvailabilityAsync(): Promise<boolean> {
    if (!('caches' in self)) return false;

    // firefox in private reports that cache is available, but throws security error on access
    if (is.firefox()) {
      try {
        await caches.open('test-cache');
      } catch (e) {
        return false;
      }
    }

    // safari has limited storage and has no navigator.storage.estimate
    let quotaEstimate: StorageEstimate;
    try {
      quotaEstimate = await navigator.storage.estimate();
    } catch (e) {
      return false;
    }

    if ((quotaEstimate.quota || 0) < CACHE_MINIMAL_SIZE) {
      return false;
    }

    return true;
  }

  public async tryGetFromCacheAsync(fullUrl: string): Promise<ArrayBuffer | undefined> {
    try {
      await CacheManager.lock.acquire();
      const request = new Request(fullUrl);

      const imageCache = await caches.open(IMAGE_CACHE_NAME);

      const imageMatch = await imageCache.match(request);

      let output: ArrayBuffer | undefined = undefined;

      if (imageMatch) {
        output = await imageMatch.arrayBuffer();
      }
      return output;
    } finally {
      CacheManager.lock.release();
    }
  }

  public async checkIfInCache(fullUrl: string): Promise<boolean> {
    try {
      await CacheManager.lock.acquire();
      const request = new Request(fullUrl);

      const imageCache = await caches.open(IMAGE_CACHE_NAME);

      return (await imageCache.match(request) !== undefined);
    } finally {
      CacheManager.lock.release();
    }
  }

  public async putInCacheAsync(fullUrl: string, response: ArrayBuffer): Promise<void> {
    try {
      await CacheManager.lock.acquire();
      const request = new Request(fullUrl, {
        headers: { [TIMESTAMP_HEADER_NAME]: new Date().getTime().toString() },
      });

      const imageCache = await caches.open(IMAGE_CACHE_NAME);

      await imageCache.put(request, new Response(response));
    } finally {
      CacheManager.lock.release();
    }
  }

  public async makeRoomInCacheAsync(newEntrySize: number): Promise<void> {
    try {
      await CacheManager.lock.acquire();
      const quota = await navigator.storage.estimate();
      const estimatedUsage = quota.usage! + newEntrySize;
      const percentageUsed = (estimatedUsage / quota.quota!) * 100;
      if (percentageUsed > 95) {
        const imageCache = await caches.open(IMAGE_CACHE_NAME);

        const sortedKeys = (await imageCache.keys())
          .map((key) => {
            const fetchTimestampHeaderValue = key.headers.get(TIMESTAMP_HEADER_NAME);
            const timestamp = fetchTimestampHeaderValue ? parseInt(fetchTimestampHeaderValue, 10) : Number.MIN_SAFE_INTEGER;
            return { key, fetchTimestamp: timestamp };
          })
          .sort((a, b) => a.fetchTimestamp - b.fetchTimestamp)
          .map(x => x.key);

        const keysToDelete: Request[] = [];
        let sizeSum = 0;
        for (const iterator of sortedKeys) {
          const entry = await imageCache.match(iterator);
          const entrySize = (await entry!.blob()).size;
          keysToDelete.push(iterator);
          sizeSum += entrySize;
          if (sizeSum > estimatedUsage * 0.3) break;
        }

        for (const iterator of keysToDelete) {
          await imageCache.delete(iterator.url);
        }
      }
    } finally {
      CacheManager.lock.release();
    }
  }

  public async cleanupOldEntriesAsync(): Promise<void> {
    const isAvailable = await CacheManager.checkCacheAvailabilityAsync();
    if (!isAvailable) return;

    try {
      await CacheManager.lock.acquire();
      await this.cleanupCache(IMAGE_CACHE_NAME);
      await this.cleanupCache(DATASET_PREVIEW_CACHE);
    } finally {
      CacheManager.lock.release();
    }
  }

  private async cleanupCache(cacheName: string) {
    const fromTimestamp = new Date().getTime() - CACHE_CLEANUP_SHIFT;

    const imageCache = await caches.open(cacheName);

    const keysToDelete = (await imageCache.keys())
      .map((key) => {
        const fetchTimestampHeaderValue = key.headers.get(TIMESTAMP_HEADER_NAME);
        const timestamp = fetchTimestampHeaderValue ? parseInt(fetchTimestampHeaderValue, 10) : Number.MIN_SAFE_INTEGER;
        return { key, fetchTimestamp: timestamp };
      })
      .filter(x => x.fetchTimestamp < fromTimestamp)
      .map(x => x.key);

    for (const iterator of keysToDelete) {
      await imageCache.delete(iterator.url);
    }
  }
}
