import { injectable, inject } from 'inversify';
import { ApiServiceType } from '../../../services/api.service';
import { StickerError } from '../../../models/error.model';

import { IApiService } from '../../../services/api.service.base';
import { ProjectRole } from '../../../models/userRole.model';
import {
  IProjectDetails,
  IMembers,
  IDatasetsTabDto,
  IUpdateProjectDetailsPayload,
  ICanPublishProjectResponse,
  IUpdateDatasetsOrderRequest,
  IUpdateImageAssignmentPolicyRequest,
} from '../projectDetails.models';

export const ProjectDetailsApiServiceType = Symbol('PROJECT_DETAILS_API_SERVICE');

export interface IProjectDetailsApiService {
  assignDatasetAsync(projectId: string, datasetId: string): Promise<{} | StickerError>;
  unlinkDatasetAsync(projectId: string, datasetId: string): Promise<{} | StickerError>;
  getProjectDetailsAsync(projectId: string): Promise<IProjectDetails | StickerError>;
  getProjectDetailsMembersAsync(projectId: string): Promise<IMembers | StickerError>;
  getProjectDetailsDatasetsAsync(projectId: string, workspaceId: string): Promise<IDatasetsTabDto | StickerError>;
  updateProjectDetailsAsync(detailsPayload: IUpdateProjectDetailsPayload): Promise<{} | StickerError>;
  addUserToProjectAsync(projectId: string, email: string, role: ProjectRole): Promise<{} | StickerError>;
  removeUserFromProjectAsync(userId: string, projectId: string): Promise<{} | StickerError>;
  canPublishAsync(projectId: string): Promise<ICanPublishProjectResponse | StickerError>;
  publishAsync(projectId: string, discardDrafts: boolean): Promise<{} | StickerError>;
  checkProjectNameUniquenessAsync(projectId: string, workspaceId: string, name: string): Promise<boolean | StickerError>;
  createProjectAsync(projectId: string, workspaceId: string): Promise<void | StickerError>;
  updateProjectDatasetsOrderAsync(request: IUpdateDatasetsOrderRequest): Promise<void | StickerError>;
  updateProjectImageAssignmentPolicy(request: IUpdateImageAssignmentPolicyRequest): Promise<void | StickerError>;
}

@injectable()
export class ProjectDetailsApiService implements IProjectDetailsApiService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService) {}

  async createProjectAsync(projectId: string, workspaceId: string): Promise<void | StickerError> {
    return await this.apiService.postAsync('/ProjectDraft/Create', { projectId, workspaceId });
  }

  publishAsync(projectId: string, discardDrafts: boolean): Promise<{} | StickerError> {
    return this.apiService.postAsync('/projectdraft/publish', { discardDrafts, projectDraftId: projectId });
  }

  checkProjectNameUniquenessAsync(projectId: string, workspaceId: string, name: string): Promise<boolean | StickerError> {
    return this.apiService.getAsync<boolean>('/Projects/CheckProjectNameUniqueness', { params: { projectId, workspaceId, name } });
  }

  canPublishAsync(projectId: string): Promise<ICanPublishProjectResponse | StickerError> {
    return this.apiService.getAsync<ICanPublishProjectResponse>('/ProjectDraft/CanPublish', { params: { projectId } });
  }

  async assignDatasetAsync(projectId: string, datasetId: string) {
    return this.apiService.postAsync<any>('/Projects/AssignDataset', { projectId, datasetId });
  }

  async unlinkDatasetAsync(projectId: string, datasetId: string) {
    return this.apiService.postAsync<any>('/Projects/UnlinkDataset', { projectId, datasetId });
  }

  async getProjectDetailsAsync(projectId: string) {
    return this.apiService.getAsync<IProjectDetails>('/Projects/Details', { params: { projectId } });
  }

  async getProjectDetailsMembersAsync(projectId: string) {
    return this.apiService.getAsync<IMembers>('/Projects/Members', { params: { projectId } });
  }

  async getProjectDetailsDatasetsAsync(projectId: string, workspaceId: string): Promise<IDatasetsTabDto | StickerError> {
    const url = '/Projects/Datasets';
    return this.apiService.getAsync<IDatasetsTabDto>(url, { params: { projectId, workspaceId } });
  }

  async updateProjectDetailsAsync(detailsPayload: IUpdateProjectDetailsPayload) {
    return this.apiService.postAsync<IUpdateProjectDetailsPayload>('/Projects/UpdateProjectDetails', detailsPayload);
  }

  async addUserToProjectAsync(projectId: string, email: string, role: ProjectRole) {
    return this.apiService.postAsync<{}>('/Projects/AddUser', { projectId, role, userEmail: email });
  }

  async removeUserFromProjectAsync(userId: string, projectId: string) {
    return this.apiService.postAsync<{}>('/Projects/RemoveUser', { userId, projectId });
  }

  async updateProjectDatasetsOrderAsync(request: IUpdateDatasetsOrderRequest): Promise<void | StickerError> {
    return this.apiService.postAsync<IUpdateDatasetsOrderRequest, void>('/Projects/UpdateDatasetsOrder', request);
  }

  async updateProjectImageAssignmentPolicy(request: IUpdateImageAssignmentPolicyRequest): Promise<void | StickerError> {
    return this.apiService.postAsync<IUpdateImageAssignmentPolicyRequest, void>('/Projects/UpdateImageAssignmentPolicy', request);
  }
}
