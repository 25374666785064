import * as React from 'react';

import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { Home } from '../../../routes/config/Home';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { LinkedButton } from '../../../components/LinkedButton';
import { NoAccessTooltip } from '../../../containers/NoAccessTooltip';
import { PageHeader } from '../../../components/PageHeader';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IDatasetHeaderProps {
  datasetsCount: number;
  workspaceId: string;
  canCreateDataset: boolean;
}

class DatasetsHeaderPure extends React.Component<IDatasetHeaderProps & ITranslatable> {
  render() {
    return (
      <PageHeader headerText={`${this.props.t('your_datasets')}`} headerNumber={this.props.datasetsCount} tabNavPresent="clean">
        <NoAccessTooltip hasAccess={this.props.canCreateDataset}>
          <DisabledWhenWorkspaceOwnerPolicyExceeded>
            <LinkedButton
              color="primary"
              className="float-right"
              text={`${this.props.t('create_dataset')}`}
              to={Home.Datasets.Create.withParams({ datasetDraftId: '', workspaceId: this.props.workspaceId })}
            />
          </DisabledWhenWorkspaceOwnerPolicyExceeded>
        </NoAccessTooltip>
      </PageHeader>
    );
  }
}

export const DatasetsHeader = as<React.ComponentClass<IDatasetHeaderProps>>(withNamespaces('datasets')(DatasetsHeaderPure));
