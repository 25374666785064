import * as React from 'react';

import { AuthServiceType, IAuthService } from '../auth.service';
import { AuthStoreType, IAuthStore } from '../auth.store';
import { IWorkspacesStore, WorkspacesStoreType } from '../../workspaces/workspaces.store';
import { InputStatus, StickerError } from '../../../models/error.model';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { Auth } from '../../../routes/config/Auth';
import { AuthLinkCard } from '../components/AuthLinkCard';
import { AuthLogo } from '../components/AuthLogo';
import { GLOBAL_ERROR_CODES } from '../../../helpers/global.constants';
import { ResetPasswordRequestForm } from '../components/ResetPasswordRequestForm';
import autobind from 'autobind-decorator';
import { getEmailStatus } from '../../../helpers/email.helpers';

interface IInjectedProps extends RouteComponentProps {
  authService: IAuthService;
  authStore: IAuthStore;
  workspaceStore: IWorkspacesStore;
}

interface IState {
  email: string;
  emailStatus: InputStatus;
  externalStatus: InputStatus;
  isRequestPending: boolean;
  isRequestDone: boolean;
}

@injectProps({
  authService: AuthServiceType,
  authStore: AuthStoreType,
  workspaceStore: WorkspacesStoreType,
})
@autobind
class RequestResetPasswordContainerPure extends React.Component<IInjectedProps, IState> {
  state: IState = {
    email: '',
    emailStatus: InputStatus.empty(),
    externalStatus: InputStatus.empty(),
    isRequestPending: false,
    isRequestDone: false,
  };

  handleEmailChange = (value: string) => {
    this.setState({
      email: value,
      emailStatus: InputStatus.empty(),
      externalStatus: InputStatus.empty(),
    });
  };

  validateEmail(value: string) {
    const emailStatus = getEmailStatus(value, [], false);
    this.setState({ emailStatus });
    return emailStatus.isValid;
  }

  async handlePasswordResetRequest() {
    const isEmailValid = this.validateEmail(this.state.email);

    if (!isEmailValid) {
      return;
    }

    this.setState({ isRequestPending: true });

    const result = await this.props.authService.requestPasswordRequest(this.state.email);

    if (result instanceof StickerError) {
      const errorCodes: string[] = !!result.apiErrorResponse
        ? result.apiErrorResponse.errorCodes
        : [GLOBAL_ERROR_CODES.FATAL_EXCEPTION];

      this.setState({
        externalStatus: new InputStatus(false, errorCodes),
        isRequestPending: false,
      });
    } else {
      this.setState({
        externalStatus: new InputStatus(true),
        isRequestPending: false,
        isRequestDone: true,
      });
    }
  }

  render() {
    if (this.props.authStore.isAuthenticated) {
      return <Redirect to={this.props.authService.getAndClearReferrer()} />;
    }

    return (
      <>
        <AuthLogo />
        <ResetPasswordRequestForm
          handleEmailChange={this.handleEmailChange}
          validateEmail={this.validateEmail}
          onRequestPasswordReset={this.handlePasswordResetRequest}
          emailStatus={this.state.emailStatus}
          externalStatus={this.state.externalStatus}
          isDisabledSubmit={this.state.isRequestPending || this.state.isRequestDone}
        />
        <AuthLinkCard text="no_account" textWithLink="no_account_with_link" link={Auth.Register.Path} />
      </>
    );
  }
}

export const RequestResetPasswordContainer = as<React.ComponentClass>(withRouter(RequestResetPasswordContainerPure));
