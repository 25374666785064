import * as React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { ITranslatable } from '../helpers/translations.helpers';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { as } from '../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface DecryptionScriptSampleModalProps {
  showDecryptionScriptSampleModal: boolean;
  onDecryptionScriptSampleModalCancel(): void;
}

const sampleScript = `#!/bin/bash
set -e
if [ "$#" -ne 2 ]; then
  echo Usage: $0 archive.zip decryption.key
  exit 1
fi
ARCHIVE_NAME=$1
KEY_NAME=$2
UNZIP_DIRECTORY="$ARCHIVE_NAME encrypted"
DESTINATION_DIRECTORY="$ARCHIVE_NAME decrypted"
unzip "$ARCHIVE_NAME" -d "$UNZIP_DIRECTORY"
mkdir "$DESTINATION_DIRECTORY"
cd "$UNZIP_DIRECTORY"
for FILE in *; do
  tail -c+17 "$FILE" | openssl aes-256-cbc -d -K $(cat "../$KEY_NAME") -iv $(head -c 16 "$FILE" | xxd -ps) -out "../$DESTINATION_DIRECTORY/$FILE"
done`;

class DecryptionScriptSampleModalPure extends React.Component<DecryptionScriptSampleModalProps & ITranslatable> {
  copyToClipboard() {
    const el = document.createElement('textarea');
    el.value = sampleScript;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  render() {
    return (
      <Modal isOpen={this.props.showDecryptionScriptSampleModal} toggle={this.props.onDecryptionScriptSampleModalCancel}>
        <ModalHeader toggle={this.props.onDecryptionScriptSampleModalCancel}>{this.props.t('decrypt_files_sample_script_header')}</ModalHeader>
        <ModalBody className="decryption-sample-modal">
          <p>{'Downloaded files are encrypted. You can decrypt them using OpenSSL. Sample Bash script decrypting files from archive:'}</p>
          <SyntaxHighlighter language="bash">{sampleScript}</SyntaxHighlighter>
        </ModalBody>
        <ModalFooter>
          <Button color="info" className="mr-2" onClick={this.copyToClipboard}>
            {this.props.t('copy')}
          </Button>
          <Button outline color="primary" onClick={this.props.onDecryptionScriptSampleModalCancel}>
            {this.props.t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export const DecryptionScriptSampleModal = as<React.StatelessComponent<DecryptionScriptSampleModalProps>>(
  withNamespaces('common', { wait: true })(DecryptionScriptSampleModalPure),
);
