import * as React from 'react';
import classNames from 'classnames';
import { GetHandleProps, GetTrackProps, Handles, Rail, Slider, SliderItem, Tracks } from 'react-compound-slider';

interface IHandleProps {
  domain: number[];
  handle: SliderItem;
  getHandleProps: GetHandleProps;
}

const Handle: React.SFC<IHandleProps> = ({ domain: [min, max], handle: { id, value, percent }, getHandleProps }) => (
  <div
    role="slider"
    aria-valuemin={min}
    aria-valuemax={max}
    aria-valuenow={value}
    className="slider-handle"
    style={{ left: `${percent}%` }}
    {...getHandleProps(id)}
  />
);

interface ITrackProps {
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
}

const Track: React.SFC<ITrackProps> = ({ source, target, getTrackProps }) => (
  <div
    className="slider-track"
    style={{ left: `${source.percent}%`, width: `${target.percent - source.percent}%` }}
    {...getTrackProps()}
  />
);

export interface IZliderProps {
  min: number;
  max: number;
  step: number;
  value: number;
  disabled?: boolean;
  onChange(value: number): void;
  fullWidth?: boolean;
}

export class Zlider extends React.Component<IZliderProps> {
  public handleChange = (values: ReadonlyArray<number>) => {
    this.props.onChange(values[0]);
  };

  public render() {
    const domain = [this.props.min, this.props.max];
    const values = [Math.min(Math.max(this.props.value || this.props.min, this.props.min), this.props.max)];

    return (
      <div style={{ width: '100%' }}>
        <Slider
          mode={1}
          step={this.props.step}
          domain={domain}
          className={classNames('slider', {
            'full-width': this.props.fullWidth
          })}
          onUpdate={this.handleChange}
          values={values}
          disabled={this.props.disabled}
        >
          <Rail>
            {({ getRailProps }) => (
              <div
              className={classNames("slider-rail", {
                "slider-rail-disabled": this.props.disabled,
              })}
              {...getRailProps()} />
            )}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className={classNames("slider-handles", {
                "slider-handles-disabled": this.props.disabled,
              })}>
                {handles.map(handle => (
                  <Handle key={handle.id} handle={handle} domain={domain} getHandleProps={getHandleProps} />
                ))}
              </div>
            )}
          </Handles>
          <Tracks right={false}>
            {({ tracks, getTrackProps }) => (
              <div className={classNames("slider-tracks", {
                "slider-tracks-disabled": this.props.disabled,
              })}>
                {tracks.map(({ id, source, target }) => (
                  <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                ))}
              </div>
            )}
          </Tracks>
        </Slider>
      </div>
    );
  }
}
