import * as React from 'react';

import { Card, CardBody, CardTitle } from 'reactstrap';
import { ICredits, IPlanInfo, Plan } from '../user.store';

import { ITranslatable } from '../../../helpers/translations.helpers';
import { ProgressBar } from '../../../components/ProgressBar';
import { as } from '../../../helpers/react.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

export interface IUserPlanWidgetProps {
  planInfo: IPlanInfo;
  credits: ICredits;
}

type IProps = IUserPlanWidgetProps & ITranslatable;

const renderLimit = (limit: number) => (limit < 0 ? <span className="account-infinity-limit">&infin;</span> : <span>{limit}</span>);

const LimitConsumption = (props: { used: number; limit: number }) => {
  return (
    <div className={`right limit ${props.limit >= 0 && props.used > props.limit ? 'exceeded' : ''}`}>
      <p className="numbers">
        {props.used}/{renderLimit(props.limit)}
      </p>
      {props.limit >= 0 && <ProgressBar value={props.used} max={props.limit} />}
    </div>
  );
};

const UserPlanWidget = (props: IProps) => {
  return (
    <Card className="flex-fill card-with-list">
      <CardBody>
        <CardTitle tag="h5" className="name">
          {props.t('current_plan')}
        </CardTitle>
        <div className="list-row first">
          <div className="left">{props.t('your_current_plan')}</div>
          <div className="right">{props.t(props.planInfo.plan)}</div>
        </div>
        <div className="list-row">
          <div className="left">{props.t('imagesAmount')}</div>
          <LimitConsumption used={props.credits.imagesAmount} limit={props.planInfo.imagesAmount!} />
        </div>
        <div className="list-row">
          <div className="left">{props.t('usedSpace')}</div>
          <LimitConsumption used={parseFloat(props.credits.usedSpace.toFixed(2))} limit={props.planInfo.availableSpace! * 1024} />
        </div>
        <div className="list-row">
          <div className="left">{props.t('workspaces')}</div>
          <LimitConsumption used={props.credits.workspaces} limit={props.planInfo.workspaces!} />
        </div>
        <div className="list-row">
          <div className="left">{props.t('workspaceUsers')}</div>
          <LimitConsumption used={props.credits.workspaceUsers} limit={props.planInfo.workspaceUsers!} />
        </div>
        <div className="list-row">
          <div className="left">{props.t('workspaceWorkers')}</div>
          <LimitConsumption used={props.credits.workspaceWorkers} limit={props.planInfo.workspaceWorkers!} />
        </div>
        {props.planInfo.plan === Plan.Starter ? (
          <div className="list-row">
            <div className="left">{props.t('image_annotations_left')}</div>
            <div className="right limit">
              <p className="numbers">{props.credits.totalCredits - props.credits.usedCredits}</p>
            </div>
          </div>
        ) : (
          <div className="list-row">
            <p>
              {props.t('want_to_cancel')}
              <a className="styled_link" href="https://zillin.io/contact.html">
                {props.t('contact_us')}
              </a>
            </p>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default as<React.ComponentClass>(observer(withNamespaces('user')(UserPlanWidget)));
