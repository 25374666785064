export interface IWorkspaceAction {
  actionType: WorkspaceActionType;
  worker: boolean | string;
  assistant: boolean | string;
  guest: boolean | string;
  collaborator: boolean | string;
  developer: boolean | string;
  trainer: boolean | string;
  manager: boolean | string;
  owner: boolean | string;
}

export enum WorkspaceActionType {
  PREVIEW_PROJECT_LIST = 'PREVIEW_PROJECT_LIST',
  PREVIEW_PROJECT_DETAILS = 'PREVIEW_PROJECT_DETAILS',
  CREATE_PROJECT = 'CREATE_PROJECT',
  EDIT_PROJECT = 'EDIT_PROJECT',
  ACCESS_PROJECT_WORKERS_TAB = 'ACCESS_PROJECT_WORKERS_TAB',
  ACCESS_PROJECT_ACTIVITY_LOG = 'ACCESS_PROJECT_ACTIVITY_LOG',
  ACCESS_PROJECT_STATS = 'ACCESS_PROJECT_STATS',
  EDIT_MARKING_TOOLS = 'EDIT_MARKING_TOOLS',
  DELETE_PROJECT = 'DELETE_PROJECT',
  BROWSE_ALL_PROJECT_ANNOTATIONS = 'BROWSE_ALL_PROJECT_ANNOTATIONS',
  EDIT_ALL_PROJECT_ANNOTATIONS = 'EDIT_ALL_PROJECT_ANNOTATIONS',
  REQUEST_CLARIFICATIONS = 'REQUEST_CLARIFICATIONS',
  CLARIFY = 'CLARIFY',
  DISCARD_ANNOTATIONS = 'DISCARD_ANNOTATIONS',
  IMPORT_ANNOTATIONS = 'IMPORT_ANNOTATIONS',
  EXPORT_ANNOTATIONS = 'EXPORT_ANNOTATIONS',
  PREVIEW_DATASETS_LIST = 'PREVIEW_DATASETS_LIST',
  CREATE_DATASET = 'CREATE_DATASET',
  PREVIEW_DATASET_DETAILS = 'PREVIEW_DATASET_DETAILS',
  EDIT_DATASET = 'EDIT_DATASET',
  DELETE_DATASET = 'DELETE_DATASET',
  DELETE_IMAGE = 'DELETE_IMAGE',
  DOWNLOAD_DATASET = 'DOWNLOAD_DATASET',
  BROWSE_AND_DOWNLOAD_ATTACHMENTS = 'BROWSE_AND_DOWNLOAD_ATTACHMENTS',
  UPLOAD_ATTACHMENTS = 'UPLOAD_ATTACHMENTS',
  DELETE_ATTACHMENTS = 'DELETE_ATTACHMENTS',
  WORKSPACE_TEAM_MANAGEMENT = 'WORKSPACE_TEAM_MANAGEMENT',
  PERSONAL_DATA_VIEW = 'PERSONAL_DATA_VIEW',
  LIST_MODELS = 'LIST_MODELS',
  LIST_INFERENCES = 'LIST_INFERENCES',
  READ_MODEL_AND_INFERENCE_DETAILS = 'READ_MODEL_AND_INFERENCE_DETAILS',
  TRAIN_MODEL = 'TRAIN_MODEL',
  UPDATE_MODEL = 'UPDATE_MODEL',
  DELETE_MODEL = 'DELETE_MODEL',
  DELETE_ANY_MODEL_OR_INFERENCE = 'DELETE_ANY_MODEL_OR_INFERENCE',
  UPLOAD_MODEL = 'UPLOAD_MODEL',
  DOWNLOAD_MODEL = 'DOWNLOAD_MODEL',
  RUN_INFERENCE = 'RUN_INFERENCE',
  DELETE_INFERENCE = 'DELETE_INFERENCE',
}

export const WorkspaceActions: IWorkspaceAction[] = [
  {
    actionType: WorkspaceActionType.PREVIEW_PROJECT_LIST,
    worker: 'if_assigned',
    assistant: true,
    guest: true,
    collaborator: true,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.PREVIEW_PROJECT_DETAILS,
    worker: false,
    assistant: true,
    guest: true,
    collaborator: true,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.CREATE_PROJECT,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: false,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.EDIT_PROJECT,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: false,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.ACCESS_PROJECT_WORKERS_TAB,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: false,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.ACCESS_PROJECT_ACTIVITY_LOG,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: false,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.ACCESS_PROJECT_STATS,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: false,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.EDIT_MARKING_TOOLS,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: false,
    developer: 'only_color',
    trainer: 'only_color',
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.DELETE_PROJECT,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: false,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.BROWSE_ALL_PROJECT_ANNOTATIONS,
    worker: false,
    assistant: true,
    guest: true,
    collaborator: true,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.EDIT_ALL_PROJECT_ANNOTATIONS,
    worker: false,
    assistant: true,
    guest: false,
    collaborator: true,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.REQUEST_CLARIFICATIONS,
    worker: true,
    assistant: true,
    guest: true,
    collaborator: true,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.CLARIFY,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: false,
    developer: false,
    trainer: false,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.DISCARD_ANNOTATIONS,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: false,
    developer: false,
    trainer: false,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.IMPORT_ANNOTATIONS,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: true,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.EXPORT_ANNOTATIONS,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: false,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.PREVIEW_DATASETS_LIST,
    worker: false,
    assistant: true,
    guest: true,
    collaborator: true,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.CREATE_DATASET,
    worker: false,
    assistant: 'unpublished_only',
    guest: false,
    collaborator: true,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.PREVIEW_DATASET_DETAILS,
    worker: false,
    assistant: true,
    guest: true,
    collaborator: true,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.EDIT_DATASET,
    worker: false,
    assistant: 'his_unpublished_dataset_only',
    guest: false,
    collaborator: true,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.DELETE_DATASET,
    worker: false,
    assistant: 'his_unpublished_dataset_only',
    guest: false,
    collaborator: true,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.DELETE_IMAGE,
    worker: false,
    assistant: 'only_images_in_his_unpublished_dataset',
    guest: false,
    collaborator: 'unmarked_and_unblocked_only',
    developer: 'unmarked_and_unblocked_only',
    trainer: 'unmarked_and_unblocked_only',
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.DOWNLOAD_DATASET,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: false,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.BROWSE_AND_DOWNLOAD_ATTACHMENTS,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: false,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.UPLOAD_ATTACHMENTS,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: false,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.DELETE_ATTACHMENTS,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: false,
    developer: false,
    trainer: false,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.WORKSPACE_TEAM_MANAGEMENT,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: false,
    developer: 'read_only',
    trainer: 'read_only',
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.PERSONAL_DATA_VIEW,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: false,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  { actionType: WorkspaceActionType.LIST_MODELS, worker: false, assistant: false, guest: true, collaborator: true, developer: true, trainer: true, manager: true, owner: true },
  { actionType: WorkspaceActionType.LIST_INFERENCES, worker: false, assistant: false, guest: true, collaborator: true, developer: true, trainer: true, manager: true, owner: true },
  {
    actionType: WorkspaceActionType.READ_MODEL_AND_INFERENCE_DETAILS,
    worker: false,
    assistant: false,
    guest: true,
    collaborator: true,
    developer: true,
    trainer: true,
    manager: true,
    owner: true,
  },
  { actionType: WorkspaceActionType.TRAIN_MODEL, worker: false, assistant: false, guest: false, collaborator: false, developer: false, trainer: true, manager: true, owner: true },
  {
    actionType: WorkspaceActionType.UPDATE_MODEL,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: false,
    developer: false,
    trainer: 'if_model_owner',
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.DELETE_MODEL,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: false,
    developer: false,
    trainer: 'if_model_owner',
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.DELETE_ANY_MODEL_OR_INFERENCE,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: false,
    developer: false,
    trainer: false,
    manager: true,
    owner: true,
  },
  { actionType: WorkspaceActionType.UPLOAD_MODEL, worker: false, assistant: false, guest: false, collaborator: true, developer: true, trainer: true, manager: true, owner: true },
  {
    actionType: WorkspaceActionType.DOWNLOAD_MODEL,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: 'if_model_owner',
    developer: 'if_model_owner',
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.RUN_INFERENCE,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: false,
    developer: false,
    trainer: true,
    manager: true,
    owner: true,
  },
  {
    actionType: WorkspaceActionType.DELETE_INFERENCE,
    worker: false,
    assistant: false,
    guest: false,
    collaborator: false,
    developer: false,
    trainer: true,
    manager: true,
    owner: true,
  },
];
