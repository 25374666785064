import * as React from 'react';

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { IProjectDetailsBl, ProjectDetailsBlType } from '../projectDetails.bl';
import { ImportAnnotationsPermissions, ImportAnnotationsPermissionsType } from '../../../../modules/projects/details/images/importAnnotations/importAnnotations.permissions';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { ProjectDetailsHeader } from '../components/ProjectDetailsHeader';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ projectId: string; workspaceId: string }> {
  projectDetailsBl: IProjectDetailsBl;
  currentWorkspaceStore: ICurrentWorkspaceStore;
  importAnnotationsPermissions: ImportAnnotationsPermissions;
}

@injectProps({
  projectDetailsBl: ProjectDetailsBlType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  importAnnotationsPermissions: ImportAnnotationsPermissionsType,
})
@observer
class ProjectDetailsHeaderContainerPure extends React.Component<IInjectedProps> {
  async componentDidMount() {
    await this.props.projectDetailsBl.initialize();

    await this.props.projectDetailsBl.getProjectDetailsAsync(this.props.match.params.projectId);
  }

  @autobind
  handleNameChange(name: string) {
    this.props.projectDetailsBl.changeName(name);
  }

  @autobind
  async handleNameBlurAsync() {
    await this.props.projectDetailsBl.nameChangedAsync();
  }

  @autobind
  handleDescriptionChange(description: string) {
    this.props.projectDetailsBl.changeDescription(description);
  }

  @autobind
  async handleDescriptionBlurAsync() {
    await this.props.projectDetailsBl.descriptionChangedAsync();
  }

  render() {
    const { name, description, nameStatus, descriptionStatus, status, id } = this.props.projectDetailsBl.store;

    return (
      <ProjectDetailsHeader
        id={id}
        workspaceId={this.props.currentWorkspaceStore.currentWorkspace!.id}
        name={name}
        description={description}
        onNameChange={this.handleNameChange}
        onDescriptionChange={this.handleDescriptionChange}
        onNameBlur={this.handleNameBlurAsync}
        onDescriptionBlur={this.handleDescriptionBlurAsync}
        nameMaxLength={200}
        descriptionMaxLength={500}
        nameStatus={nameStatus}
        descriptionStatus={descriptionStatus}
        projectStatus={status}
        canImportAnnotations={this.props.importAnnotationsPermissions.canImportAnnotations(status)}
      />
    );
  }
}

export const ProjectDetailsHeaderContainer = as<React.ComponentClass>(withRouter(ProjectDetailsHeaderContainerPure));
