import { Polyline, PolylineProps } from 'react-leaflet';

import { ISegmentationPolygonProps } from '../SegmentationLayerFeatures';
import React from 'react';

interface IProps {
  isActive: boolean;
}

export class PolylineFeature extends React.Component<ISegmentationPolygonProps & PolylineProps & IProps> {
  handleRef = (element: any) => {
    if (this.props.isActive && element) {
      element.leafletElement.enableEdit();
    }
  }

  render() {
    return <Polyline<ISegmentationPolygonProps & PolylineProps> {...this.props} ref={this.handleRef} />;
  }
}
