import * as React from 'react';

import { CustomInput, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { faEdit, faEllipsisV, faTrashAlt, faUnlink } from '@fortawesome/free-solid-svg-icons';

import { ClampLines } from '../../../components/ClampLines';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Home } from '../../../routes/config/Home';
import { IDatasetDetailsImage } from '../datasetsDetails.store';
import { LazyImage } from '../../../components/LazyImage';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { NoAccessTooltip } from '../../../containers/NoAccessTooltip';
import { TranslationFunction } from 'i18next';
import autobind from 'autobind-decorator';
import { handleClickAndPassData } from '../../../helpers/formHelpers';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faUnlink, faEdit);

export interface IDatasetThumbnailProps {
  image: IDatasetDetailsImage;
  workspaceId: string;
  t: TranslationFunction;
  disabled: boolean;
  datasetId: string;
  authToken: string;
  isChecked: boolean;
  isOwnerOrManager: boolean;
  canDownloadImages: boolean;
  isImageDownloadable: boolean;
  onCheck(id: string, isBatch: boolean): void;
  onDelete(id: string): void;
  onRename(id: string): void;
  canRenameImages: boolean;
  canDeleteUnmarkedImages: boolean;
  canDeleteMarkedImages: boolean;
}

export class DatasetThumbnail extends React.Component<IDatasetThumbnailProps> {
  @autobind
  getNavigationUrl(imageId: string) {
    const { datasetId, workspaceId } = this.props;
    return Home.Datasets.Details.Preview.withParams({ datasetId, imageId, workspaceId });
  }

  componentDidMount() {
    // this will disable marking of texts in Chrome and Edge
    document.onselectstart = (e: any) => {
      if (e.target && e.target.classList && e.target.classList.value === 'custom-control-label') return false;
    };
  }

  renderImage = () => {
    return (
      <LazyLoadComponent threshold={300}>
        <LazyImage source={this.props.image.url.replace('https://sticker-test.azurewebsites.net', '')} headers={new Headers({ Authorization: `Bearer ${this.props.authToken}` })} />
      </LazyLoadComponent>
    );
  };

  renderName = () => (
    <ClampLines lines={3} text={this.props.image.name} />
  );

  getOnChange = (id: string) => (e: any) => {
    if (this.props.isOwnerOrManager || (!this.props.image.hasAnnotations && !this.props.image.hasLocks) || this.props.canDownloadImages) {
      this.props.onCheck(id, e.nativeEvent.shiftKey);
    }
  };

  stopPropagation(e: { stopPropagation(): void }) {
    e.stopPropagation();
  }

  render() {
    const disabled = !this.props.isOwnerOrManager && !this.props.canDownloadImages && (this.props.image.hasAnnotations || this.props.image.hasLocks);
    const canDeleteImage = this.props.canDeleteMarkedImages || (this.props.canDeleteUnmarkedImages && !this.props.image.hasLocks && !this.props.image.hasAnnotations);
    let title = '';
    if (!this.props.isOwnerOrManager && !this.props.canDownloadImages) {
      if (this.props.image.hasLocks) {
        title = this.props.t('image_is_already_locked');
      } else if (this.props.image.hasAnnotations) {
        title = this.props.t('image_is_already_marked');
      }
    }

    return (
      <div className="thumb">
        <div className="image-container">
          {this.props.disabled && this.renderImage()}
          {!this.props.disabled && (
            <div>
              {this.props.canDeleteUnmarkedImages && (
                <div className="thumb-checkbox" onClick={this.getOnChange(this.props.image.id)} title={title}>
                  <CustomInput
                    checked={this.props.isChecked}
                    onChange={this.stopPropagation}
                    onClick={this.stopPropagation}
                    value={this.props.image.id}
                    id={this.props.image.id}
                    disabled={disabled}
                    className={`${this.props.isChecked ? 'visible' : ''} ${disabled ? 'locked' : ''}`}
                    type="checkbox"
                  />
                </div>
              )}
              <UncontrolledDropdown className="thumb-dropdown">
                <DropdownToggle>
                  <FontAwesomeIcon icon={faEllipsisV} />
                </DropdownToggle>
                <DropdownMenu>
                <NoAccessTooltip hasAccess={this.props.canRenameImages}>
                    <DropdownItem disabled={!this.props.canRenameImages} onClick={handleClickAndPassData(this.props.onRename)(this.props.image.id)}>
                      {this.props.t('rename_image')}
                    </DropdownItem>
                  </NoAccessTooltip>
                  <NoAccessTooltip hasAccess={this.props.canDeleteUnmarkedImages}>
                    <DropdownItem disabled={!canDeleteImage} onClick={handleClickAndPassData(this.props.onDelete)(this.props.image.id)} className="red">
                      <FontAwesomeIcon className="red" icon={faTrashAlt} /> {this.props.t('delete_image')}
                    </DropdownItem>
                  </NoAccessTooltip>
                </DropdownMenu>
              </UncontrolledDropdown>
              <Link className="text-link-in-table" to={this.getNavigationUrl(this.props.image.id)}>
                {this.renderImage()}
              </Link>
            </div>
          )}
        </div>
        <div className="description">
          <div className="file-name">
            {this.props.disabled ? (
              this.renderName()
            ) : (
              <Link className="text-link-in-table" title={this.props.image.name} to={this.getNavigationUrl(this.props.image.id)}>
                {this.renderName()}
              </Link>
            )}
          </div>
        </div>
        <div className="attributes">
          <span className="attribute">
            {this.props.t('image_size')}:<span className={'attribute-value'}> {this.props.image.size.toFixed(2)}</span>
          </span>
          <span className="attribute">
            {this.props.t('Resolution')}:<span className={'attribute-value'}> {`${this.props.image.width}x${this.props.image.height}`}</span>
          </span>
        </div>
      </div>
    );
  }
}
