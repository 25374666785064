import { ClarificationsPermissionsType, IClarificationsPermissions } from '../Clarifications.permissions';
import { ClarificationsStoreType, IClarificationsStore } from '../Clarifications.store';
import { Col, Row } from 'reactstrap';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { ITranslatable } from '../../../../../helpers/translations.helpers';
import React from 'react';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  clarificationsStore: IClarificationsStore;
  clarificationsPermissions: IClarificationsPermissions;
}

@injectProps({
  clarificationsStore: ClarificationsStoreType,
  clarificationsPermissions: ClarificationsPermissionsType,
})
@observer
class CurrentClarificationContainer extends React.Component<IInjectedProps & ITranslatable> {

  render() {
    const clarification = this.props.clarificationsStore.clarification;
    const canSeePersonalData = this.props.clarificationsPermissions.canSeePersonalData();

    if (!clarification) return null;
    return (
      <div className="current-clarification">
        {canSeePersonalData && <Row><Col><span>{this.props.t('clarifications.common.asked_by')}: </span>{clarification.askedBy}</Col></Row>}
        <Row><Col><span>{this.props.t('clarifications.common.question')}: </span>{clarification.question}</Col></Row>
        {(canSeePersonalData && clarification.answeredBy) && <Row><Col><span>{this.props.t('clarifications.common.answered_by')}: </span>{clarification.answeredBy}</Col></Row>}
        {clarification.answer && <Row><Col><span>{this.props.t('clarifications.common.answer')}: </span>{clarification.answer}</Col></Row>}
      </div>
    );
  }
}

export default as<React.ComponentClass>(withNamespaces('common', { wait: true })(CurrentClarificationContainer));
