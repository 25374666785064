import { DatasetDetails, IDatasetDetailsContext, IDatasetDetailsRouteParams, makeDatasetDetailsContext } from '../../../modules/datesets/datasetDetails.context';
import { Route, RouteComponentProps } from 'react-router';

import { DatasetDetailsHeaderContainer } from '../../../modules/datesets/containers/DatasetDetailsHeader.container';
import { DatasetDetailsRoute } from '../../../routes/datasets/datasetDetails.route';
import { Home } from '../../../routes/config/Home';
import { NotFoundView } from '../../NotFound.view';
import { PreviewView } from '../Preview.view';
import React from 'react';
import { SwitchWithNotFound } from '../../../containers/route.containers';
import { ViewContainer } from '../../../containers/ViewContainer';

interface IProps extends RouteComponentProps<IDatasetDetailsRouteParams> {}
interface IState {
  context: IDatasetDetailsContext;
}

export class DatasetDetailsView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      context: makeDatasetDetailsContext(props),
    };
  }

  render() {
    return (
      <DatasetDetails.Provider value={this.state.context}>
        <SwitchWithNotFound notFound={NotFoundView}>
          <Route path={Home.Datasets.Details.Preview.Path}>
            <PreviewView />
          </Route>
          <Route path={Home.Datasets.Details.Path}>
            <ViewContainer header={DatasetDetailsHeaderContainer}>
              <DatasetDetailsRoute />
            </ViewContainer>
          </Route>
        </SwitchWithNotFound>
      </DatasetDetails.Provider>
    );
  }
}
