import { AnnotationCreateView } from '../views/home/annotations/AnnotationCreate.view';
import { AnnotationReviewView } from '../views/home/annotations/AnnotationReview.view';
import { Home } from './config/Home';
import { NotFoundView } from '../views/NotFound.view';
import React from 'react';
import { Route } from 'react-router';
import { SwitchWithNotFound } from '../containers/route.containers';

export const AnnotationsRoute = () => (
  <SwitchWithNotFound notFound={NotFoundView}>
    <Route path={Home.Annotations.Create.Path} component={AnnotationCreateView} />
    <Route path={Home.Annotations.Review.Path} component={AnnotationReviewView} />
  </SwitchWithNotFound>
);
