import * as React from 'react';

import { AuthContainer } from '../../modules/auth/containers/AuthContainer';
import { RequestResetPasswordContainer } from '../../modules/auth/containers/RequestResetPasswordContainer';

export const ForgotPasswordView = () => (
  <div>
    <AuthContainer>
      <RequestResetPasswordContainer />
    </AuthContainer>
  </div>
);
