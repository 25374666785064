import { IImage, LOW_QUALITY_PRELOAD_MIN_IMAGE_SIZE, NoImageReason } from './annotations.interface';
import { IReactionDisposer, action, computed, observable, reaction } from 'mobx';

import { IApiService } from '../../services/api.service.base';
import { IConfiguration } from '../../../configuration';
import { NO_IMAGE_TO_DISPLAY_ERROR_CODES } from '../../helpers/global.constants';
import { StickerError } from '../../models/error.model';
import { injectable } from 'inversify';

export interface IQueueItem {
  image: IImage;
  reLockIntervalId?: number;
}

export interface IImagesQueueService {
  isAnythingToDisplay: boolean;
  areAnyImagesToLoad: boolean;
  noImageReason: NoImageReason;
  startAsync(clearOnStart: boolean): Promise<void>;
  takeImageFromQueue(): IImage | undefined;
  clear(): void;
  dispose(): void;
}

@injectable()
export class ImagesQueueServiceBase {
  protected readonly apiService: IApiService;
  protected setLoader: (enabled: boolean) => void;
  private getNewImageCallback?: () => Promise<IImage | StickerError>;
  private refreshImageLockCallback?: (imageId: string) => Promise<void | StickerError>;
  private refreshImageFailedCallback?: (imageId: string) => void;
  private imageQueueMaxLength = 0;
  private queueSizeChangeReaction?: IReactionDisposer;
  protected takenItem?: IQueueItem;
  protected configuration: IConfiguration;

  constructor(
    configuration: IConfiguration,
    apiService: IApiService,
    setLoader: (enabled: boolean) => void) {
    this.apiService = apiService;
    this.setLoader = setLoader;
    this.configuration = configuration;
  }

  public get currentImageId(): string | undefined {
    return this.takenItem?.image.id;
  }

  @observable
  public areAnyImagesToLoad: boolean = true;

  @computed
  get isAnythingToDisplay(): boolean {
    return this.queue.length > 0;
  }

  @observable
  public queue: IQueueItem[] = [];

  @observable
  public removeLocks: boolean = true;

  @observable
  public noImageReason: NoImageReason = NoImageReason.Unknown;

  @computed
  get isAnyRequestInProgress(): boolean {
    return this.requestsInProgressCount > 0;
  }

  @observable
  private requestsInProgressCount: number = 0;

  @action
  public takeImageFromQueue(): IImage | undefined {
    this.removeLockRefresh(this.takenItem);
    const item = this.queue.shift();

    if (item === undefined) return;
    this.takenItem = item;

    return item.image;
  }

  @action
  public clear() {
    if (this.queueSizeChangeReaction) this.queueSizeChangeReaction();
    this.queue.forEach(i => this.removeLockRefresh(i));
    this.removeLockRefresh(this.takenItem);
    this.takenItem = undefined;
    this.areAnyImagesToLoad = true;
    this.queue = [];
  }

  @action
  public dispose() {
    if (this.queueSizeChangeReaction) this.queueSizeChangeReaction();
  }

  @action
  public async startAsync(clearOnStart: boolean) {
    if (clearOnStart) this.clear();

    this.queueSizeChangeReaction = reaction(
      () => this.queue.length,
      async () => {
        for (let i = 0; i < this.imageQueueMaxLength; i += 1) {
          if (this.requestsInProgressCount + this.queue.length + 1 <= this.imageQueueMaxLength && this.areAnyImagesToLoad) {
            try {
              this.setLoader(true);
              this.requestsInProgressCount += 1;
              await this.loadImageToQueue();
              this.removeLocks = false;
            } catch (error) {
              if (this.queueSizeChangeReaction) this.queueSizeChangeReaction();
            } finally {
              this.requestsInProgressCount -= 1;
              if (this.requestsInProgressCount === 0) this.setLoader(false);
            }
          }
        }
      },
      {
        fireImmediately: true,
      },
    );
  }

  @action
  protected async setupBaseAsync(
    getNewImageCallback: () => Promise<IImage | StickerError>,
    imageQueueMaxLength: number = 1,
    refreshLockCallback: (imageId: string) => Promise<void | StickerError>,
    refreshImageFailedCallback: (imageId: string) => void,
  ) {
    this.getNewImageCallback = getNewImageCallback;
    this.imageQueueMaxLength = imageQueueMaxLength;
    this.refreshImageLockCallback = refreshLockCallback;
    this.refreshImageFailedCallback = refreshImageFailedCallback;
  }

  @action
  private async loadImageToQueue(): Promise<boolean> {
    if (!this.getNewImageCallback) return false;
    if (!this.areAnyImagesToLoad) return false;

    const result = await this.getNewImageCallback();

    if (result instanceof StickerError) {
      this.assignProperNoImageReason(result.apiErrorResponse?.errorCodes);
      this.areAnyImagesToLoad = false;
      return false;
    }

    if (this.takenItem && this.takenItem.image.id === result.id || this.queue.some(i => i.image.id === result.id)) {
      return false;
    }

    const item = {
      image: observable({ ...result, url: '', lowQualityUrl: '' }),
    };

    this.addLockRefresh(item);

    this.startLoading(item);

    this.queue.push(item);

    return true;
  }

  @action
  private assignProperNoImageReason(errorCodes?: string[]) {
    if (!errorCodes) {
      this.noImageReason = NoImageReason.Unknown;
      return;
    }

    if (errorCodes.includes(NO_IMAGE_TO_DISPLAY_ERROR_CODES.NO_MORE_CREDITS)) {
      this.noImageReason = NoImageReason.Credits;
    } else if (errorCodes.includes(NO_IMAGE_TO_DISPLAY_ERROR_CODES.NO_MORE_IMAGES_IN_PROJECT) ||
      errorCodes.includes(NO_IMAGE_TO_DISPLAY_ERROR_CODES.NO_ANNOTATIONS_TO_REVIEW)) {
      this.noImageReason = NoImageReason.NoMoreImages;
    } else if (errorCodes.includes(NO_IMAGE_TO_DISPLAY_ERROR_CODES.NO_MORE_UNLOCKED_IMAGES_IN_PROJECT) ||
      errorCodes.includes(NO_IMAGE_TO_DISPLAY_ERROR_CODES.NO_UNLOCKED_ANNOTATIONS_TO_REVIEW)) {
      this.noImageReason = NoImageReason.Locked;
    } else {
      this.noImageReason = NoImageReason.QueueError;
    }
  }

  async startLoading(item: IQueueItem) {
    await Promise.all([
      this.loadLowQualityImage(item),
      this.loadImage(item),
    ]);
  }

  async loadLowQualityImage(item: IQueueItem) {
    if (item.image.size < LOW_QUALITY_PRELOAD_MIN_IMAGE_SIZE) return;
    const imageFile = await this.apiService.getImageAsync(this.apiService.getUrl(`/Images/GetImageThumbnail/${item.image.id}`));
    if (!(imageFile instanceof StickerError)) {
      item.image.lowQualityUrl = URL.createObjectURL(imageFile.blob);
    }
  }

  async loadImage(item: IQueueItem) {
    const imageFile = await this.apiService.getImageAsync(this.apiService.getUrl(`/Images/GetImage/${item.image.id}`));
    if (!(imageFile instanceof StickerError)) {
      item.image.url = URL.createObjectURL(imageFile.blob);
    }
  }

  private addLockRefresh(item: IQueueItem): void {
    if (!item || !item.image || !item.image.id) {
      return;
    }

    item.reLockIntervalId =
      this.refreshImageLockCallback !== undefined
        ? window.setInterval(() => this.refreshImage(item.image.id), this.configuration.appConfig.reLockInterval * 60 * 1000)
        : undefined;
  }

  private async refreshImage(imageId: string) {
    const result = await this.refreshImageLockCallback!(imageId);
    if (result && result.isBadRequestWithCode(['IMAGE_ALREADY_LOCKED'])) {
      if (this.takenItem?.image.id === imageId) {
        this.removeLockRefresh(this.takenItem);
        this.takenItem = undefined;
      } else {
        const item = this.queue.find(x => x.image.id === imageId);
        if (item) {
          this.removeLockRefresh(item);
          const index = this.queue.indexOf(item);
          if (index > -1) {
            this.queue.splice(index, 1);
          }
        }
      }
      this.refreshImageFailedCallback!(imageId);
    }
  }

  private removeLockRefresh(item?: IQueueItem): void {
    if (!item || !item.reLockIntervalId) {
      return;
    }

    window.clearInterval(item.reLockIntervalId);
  }
}
