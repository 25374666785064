import * as React from 'react';

import { Button, Card, CardBody, CardTitle } from 'reactstrap';

import { Home } from '../../../../__legacy__/routes/config/Home';
import { ITranslatable } from '../../../../__legacy__/helpers/translations.helpers';
import { Link } from 'react-router-dom';
import { Loader } from '../../../../__legacy__/components/Loader';
import { as } from '../../../../__legacy__/helpers/react.helpers';
import { toLocaleDateString } from '../../../../__legacy__/helpers/date.helpers';
import { withNamespaces } from 'react-i18next';

interface IClarificationsOverviewProps {
  isLoading: boolean;
  toClarify: number;
  oldestWaitingSince?: string;
  unclearImageId?: string;
  unclearImagesFilterId?: string;
  projectId: string;
  workspaceId: string;
}

const ClarificationsOverviewPure = (props: IClarificationsOverviewProps & ITranslatable) => {
  const { toClarify, workspaceId, projectId, unclearImageId, unclearImagesFilterId, oldestWaitingSince } = props;

  return (
    <Card className="flex-fill card-with-list slim-margin">
      <CardBody>
        <Loader isLoading={props.isLoading}>
          <div className="info">
            <CardTitle tag="h5" className="name">
              {props.t('stopped_annotations')}
            </CardTitle>
            <div className="list-row">
              <div className="left">{props.t('waiting_for_clarification')}</div>
              <div className="right">
                {toClarify}
              </div>
            </div>
            {oldestWaitingSince && (
              <div className="list-row">
                <div className="left">{props.t('oldest_waiting_since')}</div>
                <div className="right">{toLocaleDateString(oldestWaitingSince)}</div>
              </div>
            )}
          </div>
          <div className="bottom">
            {unclearImageId && unclearImagesFilterId && (
              <Link to={Home.FreeAccess.View.withParams({ projectId, workspaceId, imageId: unclearImageId, filterId: unclearImagesFilterId })} className="float-left">
                <Button color="primary">{props.t('clarify')}</Button>
              </Link>
            )}
          </div>
        </Loader>
      </CardBody>
    </Card>
  );
};

export const ClarificationsOverview = as<React.ComponentClass<IClarificationsOverviewProps>>(withNamespaces('project', { wait: true })(ClarificationsOverviewPure));
