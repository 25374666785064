// tslint:disable:function-name
// tslint:disable:variable-name

import 'leaflet-draw';

import { AddPolygonVertexCommand, AddPolygonVerticesCommand } from '../../undoRedoCommands/undoRedoCommands';
import { DRAWING_STATES, ICON_MARKER_SIZE, MOUSE_BUTTON, MOUSE_BUTTONS, MOUSE_EVENTS, MouseLeafletEvent } from '../../annotations.interface';
import leaflet, { DrawEvents, DrawOptions, LatLng, LeafletEvent, LeafletEventHandlerFn, Map, Marker, Point, Polygon, Polyline } from 'leaflet';

import { Feature } from 'geojson';
import { IUndoRedoHistory } from '../../undoRedoHistory.service';
import area from '@turf/area';
import autobind from 'autobind-decorator';
import { getSimplifiedGeoJSON } from '../../../../helpers/geometry/polygon.helpers';
import simplify from 'simplify-geojson';

@autobind
export class DrawPolygon extends leaflet.Draw.Polygon implements IDrawer {
  private readonly GUIDELINE_OPACITY: number = 0.5;

  private readonly undoRedoHistory: IUndoRedoHistory;
  private readonly onDrawCreated: (geojson: Feature) => void;
  private _solidGuideLine: Polyline | undefined = undefined;
  private drawState: DRAWING_STATES = DRAWING_STATES.NOT_DRAWING;
  private cursorLeftStartMarkerBoundingBox: boolean = false;
  private startMarkerLatLng?: LatLng;

  constructor(
    map: Map,
    onDrawCreated: (geojson: Feature) => void,
    undoRedoHistory: IUndoRedoHistory,
    options?: DrawOptions.PolygonOptions,
  ) {
    super(map, { ...options, repeatMode: true });
    this.onDrawCreated = onDrawCreated;
    this.undoRedoHistory = undoRedoHistory;
  }

  public enable() {
    if (!this.enabled()) {
      super.enable();
    }

    return this;
  }

  public disable() {
    super.disable();
    this.handleDrawStop();
    this.undoRedoHistory.clearDrawingHistory();
    return this;
  }

  public disableMouseMarkerFocus() {}

  addVertexWrapper(latlng: LatLng) {
    if (this._markers && this._markers.length > 0) {
      // if second marker is inside starting box then ignore it
      if (this._markers.length === 1 && this.isInStartMarkerBoundingBox(latlng)) return;

      // if last marker is has exactly same position as new one then ignore it
      const lastMarkerLatLng = this._markers[this._markers.length - 1].getLatLng();
      if (lastMarkerLatLng.lat === latlng.lat && lastMarkerLatLng.lng === latlng.lng) return;

      // if we try to add marker that would close the shape, but shape would be invalid then ignore it
      if (this.isInStartMarkerBoundingBox(latlng)) {
        const markers = this._markers.map(m => m.getLatLng());
        markers.push(latlng);

        if (markers.length > 2 && !this.shapeIsValid(markers)) return;
      }
    }

    this.addVertex(latlng);
    if (this.drawState === DRAWING_STATES.DRAWING_BY_DRAGGING) {
      this.undoRedoHistory.addCommand(new AddPolygonVerticesCommand(this, latlng));
    } else {
      this.undoRedoHistory.addCommand(new AddPolygonVertexCommand(this, latlng));
    }
  }

  getMarkersCount = (): number => (this._markers ? this._markers.length : 0);

  _drawGuide(pointA: Point, pointB: Point) {
    const latlngA = this._map.layerPointToLatLng(pointA);
    const latlngB = this._map.layerPointToLatLng(pointB);

    this._clearGuides();

    this._solidGuideLine = new Polyline([latlngA, latlngB], {
      ...this.options.shapeOptions,
      opacity: this.GUIDELINE_OPACITY < (this.options?.shapeOptions?.fillOpacity || 0) ? this.options.shapeOptions?.fillOpacity : this.GUIDELINE_OPACITY,
    });
    this._solidGuideLine.addTo(this._map);
  }

  _clearGuides() {
    if (this._solidGuideLine) {
      this._solidGuideLine.remove();
    }
  }

  public addHandlers() {
    this._map.on(MOUSE_EVENTS.MOUSE_DOWN, this.handleMouseDown as LeafletEventHandlerFn);
    this._map.on(MOUSE_EVENTS.MOUSE_UP, this.handleMouseUp as LeafletEventHandlerFn);
    this._map.on(MOUSE_EVENTS.MOUSE_MOVE, this.handleMouseMove as LeafletEventHandlerFn);

    this._map.on(leaflet.Draw.Event.DRAWSTOP, this.handleDrawStop);
    this._map.on(leaflet.Draw.Event.DRAWVERTEX, this.handleDrawVertex);
    this._map.on(leaflet.Draw.Event.CREATED, this.handleDrawCreated as LeafletEventHandlerFn);
  }

  public removeHandlers() {
    this._map.off(MOUSE_EVENTS.MOUSE_DOWN, this.handleMouseDown as LeafletEventHandlerFn);
    this._map.off(MOUSE_EVENTS.MOUSE_UP, this.handleMouseUp as LeafletEventHandlerFn);
    this._map.off(MOUSE_EVENTS.MOUSE_MOVE, this.handleMouseMove as LeafletEventHandlerFn);

    this._map.off(leaflet.Draw.Event.DRAWSTOP, this.handleDrawStop);
    this._map.off(leaflet.Draw.Event.DRAWVERTEX, this.handleDrawVertex);
    this._map.off(leaflet.Draw.Event.CREATED, this.handleDrawCreated as LeafletEventHandlerFn);
  }

  handleMouseUp(e: MouseLeafletEvent) {
    if (this._disableMarkers || this.drawState === DRAWING_STATES.NOT_DRAWING || e.originalEvent.button !== MOUSE_BUTTON.LEFT) return;
    this.drawState = DRAWING_STATES.DRAWING_BY_CLICKING;

    if (this.isInStartMarkerBoundingBox(e.latlng) && this.cursorLeftStartMarkerBoundingBox && this.getMarkersCount() > 2) {
      this._finishShape();
    }
  }

  handleMouseDown(e: MouseLeafletEvent) {
    if (this._disableMarkers || e.originalEvent.button !== MOUSE_BUTTON.LEFT) return;
    if (this.startMarkerLatLng === undefined) this.setStartMarkerLatLng(e.latlng);

    if (this.isInStartMarkerBoundingBox(e.latlng) && this.cursorLeftStartMarkerBoundingBox && this.getMarkersCount() > 2) {
      this._finishShape();
      return;
    }

    switch (this.drawState) {
      case DRAWING_STATES.NOT_DRAWING:
      case DRAWING_STATES.DRAWING_BY_CLICKING:
        this.addVertexWrapper(e.latlng);
        this.drawState = DRAWING_STATES.DRAWING_BY_DRAGGING;
        break;
    }
  }

  handleMouseMove(e: MouseLeafletEvent) {
    if (!this.cursorLeftStartMarkerBoundingBox && this.startMarkerLatLng) {
      if (this.isInStartMarkerBoundingBox(e.latlng)) return;

      this.cursorLeftStartMarkerBoundingBox = true;
    }

    switch (this.drawState) {
      case DRAWING_STATES.DRAWING_BY_DRAGGING:
        if (e.originalEvent.buttons & MOUSE_BUTTONS.LEFT) {
          this.addVertexWrapper(e.latlng);
        } else {
          this.drawState = DRAWING_STATES.DRAWING_BY_CLICKING;
        }
        break;
    }
  }

  setStartMarkerLatLng(latlng: LatLng) {
    this.startMarkerLatLng = latlng;
  }

  getBoundingBox() {
    if (this.startMarkerLatLng === undefined) return undefined;

    const center = this._map.latLngToLayerPoint(this.startMarkerLatLng);

    return {
      topLeft: new Point(center.x - ICON_MARKER_SIZE / 2 + 1, center.y - ICON_MARKER_SIZE / 2 + 1),
      bottomRight: new Point(center.x + ICON_MARKER_SIZE / 2 - 2, center.y + ICON_MARKER_SIZE / 2 - 2),
    };
  }

  isInStartMarkerBoundingBox(latlng: LatLng) {
    if (!this.startMarkerLatLng) return undefined;

    const point = this._map.latLngToLayerPoint(latlng);
    const boundingBox = this.getBoundingBox()!;

    return point.x >= boundingBox.topLeft.x && point.x <= boundingBox.bottomRight.x && point.y >= boundingBox.topLeft.y && point.y <= boundingBox.bottomRight.y;
  }

  handleDrawStop() {
    this.drawState = DRAWING_STATES.NOT_DRAWING;
  }

  handleDrawVertex() {
    this._markers.slice(1).forEach(m => this._markerGroup.removeLayer(m));
  }

  handleDrawCreated(e: DrawEvents.Created & LeafletEvent): void {
    const simplifiedGeojson = getSimplifiedGeoJSON(e.layer);
    this.onDrawCreated(simplifiedGeojson);
  }

  _createMarker(latlng: LatLng) {
    const marker = new Marker(latlng, {
      icon: leaflet.divIcon({
        iconAnchor: [ICON_MARKER_SIZE / 2, ICON_MARKER_SIZE / 2],
        iconSize: [ICON_MARKER_SIZE, ICON_MARKER_SIZE],
      }),
      opacity: 1,
      zIndexOffset: this.options.zIndexOffset || 0 * 2,
      keyboard: false,
    });

    this._markerGroup.addLayer(marker);
    return marker;
  }

  _enableNewMarkers() {
    setTimeout(() => {
      this._disableMarkers = false;
    }, 200);
  }

  _finishShape() {
    if (!this._shapeIsValid()) return;
    super._finishShape();
    this.disable();
  }

  _shapeIsValid() {
    return this.shapeIsValid(this._markers.map(m => m.getLatLng())) && super._shapeIsValid();
  }

  shapeIsValid(latlngs: LatLng[]) {
    const feature = new Polygon(latlngs);
    const simplifiedFeature = simplify(feature.toGeoJSON(), 0.1) as Feature;

    return simplifiedFeature && area(simplifiedFeature) >= 1;
  }

  // FIXES some bugs with contextmenu click or different click behavior in browsers
  // See S20-120, S20-133 bugs on JIRA
  // tslint:disable-next-line:function-name
  _onTouch() {}
  _onMouseDown() {}
  _onMouseUp() {}
}
