import * as React from 'react';

import { EvaluationDatasetsList } from '../components/EvaluationDatasetsList';
import { as, injectProps } from '../../../../__legacy__/helpers/react.helpers';
import { ITranslatable } from '../../../../__legacy__/helpers/translations.helpers';
import { observer } from 'mobx-react';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../common/OverlayLoader.store';
import { EvaluationDetailsServiceType, IEvaluationDetailsService } from '../../evaluationDetails.service';
import { IWorkspaceService, WorkspaceServiceType } from '../../../../__legacy__/modules/workspaces/workspaces.service';

interface IInjectedProps extends ITranslatable {
  evaluationsDetailsService: IEvaluationDetailsService;
  overlayLoader: IOverlayLoaderStore;
  workspaceService: IWorkspaceService;
}

@injectProps({
  evaluationsDetailsService: EvaluationDetailsServiceType,
  overlayLoader: OverlayLoaderStoreType,
  workspaceService: WorkspaceServiceType,
})
@observer
class EvaluationDatasetsContainerPure extends React.Component<IInjectedProps> {
  changeDatasetSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.evaluationsDetailsService.changeDatasetSelection(e.target.value);
  };

  render() {
    const { datasets, status, datasetIds, isOwner } = this.props.evaluationsDetailsService.store;
    const workspaceRole = this.props.workspaceService.getUserRoleInCurrentWorkspace();
    return (
      <EvaluationDatasetsList
        isOwner={isOwner}
        workspaceRole={workspaceRole}
        datasets={datasets}
        datasetIds={datasetIds}
        status={status}
        isLoading={!!this.props.overlayLoader.isSpinnerVisible('evaluation-datasets-list')}
        changeDatasetSelection={this.changeDatasetSelection}
        changeAllDatasetsSelection={this.props.evaluationsDetailsService.changeAllDatasetsSelection}
      />
    );
  }
}

export const EvaluationDatasetsContainer = as<React.ComponentClass>(EvaluationDatasetsContainerPure);
