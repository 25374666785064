import { CollapsibleImportSummary } from '../../../components/CollapsibleSummary';
import { IImportQuestionRejection } from '../projects.model';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../helpers/react.helpers';
import { mapQuestionRejectionReason } from './ImportQuestionsSummary';
import { withNamespaces } from 'react-i18next';

export interface IProps {
  isLastSummaryItem?: boolean;
  isPanelOpen: boolean;
  handlePanelToggle(): void;
  rejectedAttributes: IImportQuestionRejection[];
  importedAttributesCount: number;
  skippedAttributesCount: number;
}

class ImportAttributesSummaryPure extends React.Component<IProps & ITranslatable> {
  getRejectionReasons = (): string[] => {
    return this.props.rejectedAttributes.map(x => {
      return `${x.text} - ${this.props.t(mapQuestionRejectionReason(x.reason))}`;
    });
  };

  render() {
    return (
      <>
        <CollapsibleImportSummary
          importedItemsCount={this.props.importedAttributesCount}
          skippedItemsCount={this.props.skippedAttributesCount}
          rejectionReasons={this.getRejectionReasons()}
          isPanelOpen={this.props.isPanelOpen}
          togglePanel={this.props.handlePanelToggle}
          isLastSummaryItem={this.props.isLastSummaryItem}
          importedMessage={this.props.t('attributes_imported', { count: this.props.importedAttributesCount })}
          skippedMessage={this.props.t('attributes_skipped', { count: this.props.skippedAttributesCount })}
        />
      </>
    );
  }
}

export const ImportAttributesSummary = as<React.ComponentClass<IProps>>(withNamespaces('project')(ImportAttributesSummaryPure));
