import * as React from 'react';

import { GTable, IGColumnProps } from '../../../components/table/GTable';
import { faDownload, faEdit, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Home } from '../../../routes/config/Home';
import { IDatasetDetailsImage } from '../datasetsDetails.store';
import { IDatasetDetailsImagesProps } from './DatasetDetailsImagesList';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { IconedButton } from '../../../components/IconedButton';
import { Link } from 'react-router-dom';
import { LinkedButton } from '../../../components/LinkedButton';
import { Loader } from '../../../components/Loader';
import { NoAccessTooltip } from '../../../containers/NoAccessTooltip';
import { Settings } from '../../../../modules/settings/SettingsContext';
import { as } from '../../../helpers/react.helpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { toLocaleDateTimeString } from '../../../helpers/date.helpers';
import { withNamespaces } from 'react-i18next';

library.add(faDownload, faEye, faTrashAlt);

@observer
class DatasetDetailsImagesListTablePure extends React.Component<IDatasetDetailsImagesProps & ITranslatable> {
  static contextType = Settings;
  declare context: React.ContextType<typeof Settings>;

  get gColumns(): IGColumnProps<IDatasetDetailsImage>[] {
    const columns: IGColumnProps<IDatasetDetailsImage>[] = [
      {
        field: 'name',
        headerName: this.props.t('name'),
        renderer: this.renderName,
        minWidth: 400,
      },
      {
        field: 'createdDate',
        headerName: this.props.t('uploadDate'),
        renderer: (image: IDatasetDetailsImage) => <div>{toLocaleDateTimeString(image.createdDate)}</div>,
        width: 200,
      },
      {
        field: 'width',
        headerName: this.props.t('image_width'),
        width: 150,
      },
      {
        field: 'height',
        headerName: this.props.t('image_height'),
        width: 150,
      },
      {
        field: 'size',
        headerName: this.props.t('image_size'),
        renderer: this.renderSize,
        width: 150,
      },
      {
        field: 'annotationsCount',
        headerName: this.props.t('annotated'),
        width: 150,
      },
      {
        field: 'acceptedAnnotationsCount',
        headerName: this.props.t('accepted'),
        width: 150,
      },
      {
        field: '',
        headerName: this.props.t('actions'),
        renderer: this.renderActions,
        width: 130,
        sortable: false,
        cellClass: 'g-actions',
      },
    ];

    return columns;
  }

  onSortChanged = (orderBy: string, orderType: string) => this.props.onOrderingChange(orderBy, orderType);

  handleDownloadClick = (id: string) => () => this.props.onImageDownload(id);

  handleDeleteClick = (id: string) => () => this.props.onImageDeleted(id);

  handleRenameClick = (id: string) => () => this.props.onRenameImage(id);

  handlePageSizeChange = (pageNumber: number, pageSize: number) => {
    this.context.setDatasetDetailsImagesPageSize(pageSize);
    this.props.onPaginationChange(pageNumber, pageSize);
  }

  getPreviewUrl = (image: IDatasetDetailsImage) => {
    const { datasetId, workspaceId } = this.props;
    return Home.Datasets.Details.Preview.withParams({ workspaceId, datasetId, imageId: image.id });
  };

  renderActions = (image: IDatasetDetailsImage) => {
    let title = this.props.t('delete_image');
    if (!this.props.canDeleteMarkedImages) {
      if (image.hasLocks) {
        title = this.props.t('image_is_already_locked');
      } else if (image.hasAnnotations) {
        title = this.props.t('image_is_already_marked');
      }
    }

    return (
      <div>
        <DisabledWhenWorkspaceOwnerPolicyExceeded>
          <LinkedButton className="btn-icon" to={this.getPreviewUrl(image)}>
            <FontAwesomeIcon icon={faEye} title={this.props.t('preview_image')} />
          </LinkedButton>
        </DisabledWhenWorkspaceOwnerPolicyExceeded>
        <NoAccessTooltip hasAccess={this.props.canRenameImages}>
          <IconedButton disabled={!this.props.canRenameImages} icon={faEdit} onClick={this.handleRenameClick(image.id)} title={this.props.t('rename_image')} />
        </NoAccessTooltip>
        <NoAccessTooltip hasAccess={this.props.canDownloadImages} title={this.props.t(`cannot_download_${this.props.downloadProhibitionReason}`)}>
          <DisabledWhenWorkspaceOwnerPolicyExceeded>
            <IconedButton
              disabled={!this.props.isImageDownloadable}
              icon={faDownload}
              onClick={this.handleDownloadClick(image.id)}
              title={this.props.t(this.props.isImageDownloadable ? 'download_image' : `cannot_download_${this.props.downloadProhibitionReason}`)}
            />
          </DisabledWhenWorkspaceOwnerPolicyExceeded>
        </NoAccessTooltip>
        <NoAccessTooltip hasAccess={this.props.canDeleteUnmarkedImages}>
          <IconedButton
            color="red"
            icon={faTrashAlt}
            disabled={!this.props.canDeleteMarkedImages && (image.hasAnnotations || image.hasLocks)}
            title={title}
            onClick={this.handleDeleteClick(image.id)}
          />
        </NoAccessTooltip>
      </div>
    );
  };

  renderName = (image: IDatasetDetailsImage) =>
    this.props.disabled ? (
      <span>{image.name}</span>
    ) : (
      <Link className="text-link-in-table" title={image.name} to={this.getPreviewUrl(image)}>
        {image.name}
      </Link>
    );

  renderSize = (image: IDatasetDetailsImage) => <span>{image.size.toFixed(2)}</span>;

  render() {
    return (
      <div className="table-container table-with-images">
        <Loader isLoading={this.props.isLoading}>
          <GTable
            columns={this.gColumns}
            items={this.props.datasetImages}
            onSortChanged={this.onSortChanged}
            paginationProps={{
              pageNumber: this.props.paging.pageNumber,
              pageSize: this.context.store.datasetDetailsImagesPageSize,
              totalCount: this.props.paging.totalCount,
              onChange: this.handlePageSizeChange,
            }}
            sortingModel={this.props.paging.orderBy && this.props.paging.orderType ? [{ orderBy: this.props.paging.orderBy, orderType: this.props.paging.orderType }] : []}
          />
        </Loader>
      </div>
    );
  }
}

export const DatasetDetailsImagesListTable = as<React.ComponentClass<IDatasetDetailsImagesProps>>(withNamespaces('datasets', { wait: true })(DatasetDetailsImagesListTablePure));
