import { ContainerModule, interfaces } from 'inversify';
import { IProjectsApiService, ProjectsApiService, ProjectsApiServiceType } from './services/projectApi.service';
import { IProjectsPermissions, ProjectsPermissions, ProjectsPermissionsType } from './projects.permissions';
import { IProjectsService, ProjectsService, ProjectsServiceType } from './projects.service';
import { IProjectsStore, ProjectsStore, ProjectsStoreType } from './projects.store';

export const projectsModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IProjectsStore>(ProjectsStoreType)
    .to(ProjectsStore)
    .inSingletonScope();
  bind<IProjectsService>(ProjectsServiceType)
    .to(ProjectsService)
    .inSingletonScope();
  bind<IProjectsApiService>(ProjectsApiServiceType)
    .to(ProjectsApiService)
    .inSingletonScope();
  bind<IProjectsPermissions>(ProjectsPermissionsType)
    .to(ProjectsPermissions)
    .inSingletonScope();
});
