import * as React from 'react';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IBackNavigationProps } from '../containers/freeAccessNavigation.container';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { as } from '../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { withNamespaces } from 'react-i18next';

class BackToProjectDetailsButtonPure extends React.PureComponent<IBackNavigationProps & ITranslatable> {
  constructor(props: IBackNavigationProps & ITranslatable) {
    super(props);
  }

  @autobind
  handleClick(event: React.MouseEvent) {
    event.preventDefault();
    this.props.goTo(this.props.quitLink);
  }

  render() {
    return (
      <Button onClick={this.handleClick} tabIndex={-1}>
        <FontAwesomeIcon icon={faArrowLeft} />
        {this.props.t('back')}
      </Button>
    );
  }
}

export const BackToProjectDetailsButton = as<React.ComponentClass<IBackNavigationProps>>(withNamespaces('annotation')(BackToProjectDetailsButtonPure));
