import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router';

import { DatasetDetails } from '../datasetDetails.context';
import { DatasetDetailsImagePreviewContainer } from './DatasetDetailsImagePreview.container';
import { ImageSegmentationsContextProvider } from '../../annotation/submodules/segmentations/segmentationContextMenu/imageSegmentations.context';
import { Observer } from 'mobx-react';
import { as } from '../../../helpers/react.helpers';

class DatasetDetailsPreviewContainerPure extends React.Component<RouteComponentProps<{ imageId: string }>> {
  render() {
    return (
      <DatasetDetails.Consumer>
        {({ store }) => (
          <Observer>
            {() => (
              <ImageSegmentationsContextProvider imageId={this.props.match.params.imageId} enabled>
                <DatasetDetailsImagePreviewContainer
                  height={(store.imagePreviewInfo && store.imagePreviewInfo.height) || 0}
                  width={(store.imagePreviewInfo && store.imagePreviewInfo.width) || 0}
                  url={(store && store.imagePreviewUrl) || ''}
                  lowQualityUrl={(store && store.lowQualityImagePreviewUrl) || ''}
                  isLoading={store.isPreviewImageLoading}
                  imageId={this.props.match.params.imageId}
                />
              </ImageSegmentationsContextProvider>
            )}
          </Observer>
        )}
      </DatasetDetails.Consumer>
    );
  }
}

export const DatasetDetailsPreviewContainer = as<React.ComponentClass>(withRouter(DatasetDetailsPreviewContainerPure));
