import { injectable } from 'inversify';
import { observable } from 'mobx';
import { IPagingInfoWithOrder, PaginationInfoDefault } from '../../__legacy__/models/paginationInfo.model';
import { EVALUATION_STATUS } from '../evaluations/evaluations.model';
import { IPredictionImage } from '../../__legacy__/models/predictions.model';
import { Parameters } from '../../__legacy__/helpers/parameters.helpers';
import { ITrainingMetrics, ITrainingResult, IChartPoint, ITrainingMatrix, MODEL_VARIANTS } from '../../__legacy__/models/metrics.model';
import { IEvaluationDetailsDto, IEvaluationStatusDto } from './evaluationDetails.model';

export const EvaluationDetailsStoreType = Symbol('EVALUATION_DETAILS_STORE');

export interface IEvaluationProject {
  value: string;
  label: string;
}

export interface IEvaluationModel {
  value: string;
  label: string;
}

export interface IEvaluationDataset {
  id: string;
  name: string;
  isSelected: boolean;
  testImagesCount: number;
  trainImagesCount: number;
  validationImagesCount: number;
  createdDate: string;
}

export interface IEvaluationImagesPaging extends IPagingInfoWithOrder {
  fetchPredictions: boolean;
}

export const defaultEvaluationImagesPaging: IEvaluationImagesPaging = {
  ...PaginationInfoDefault,
  orderBy: '',
  orderType: '',
  search: '',
  fetchPredictions: false,
};

export interface IEvaluationConfig {
  [k: string]: number | boolean | string;
}

export interface IEvaluationDetailsValidationErrors {
  showAll: boolean;
  // No key set -> not validated yet
  // null -> valid
  // object -> invalid
  settings: Map<
    string,
    {
      message: string;
      messageParameters: Record<string, any>;
    } | null
  >;
  header: Map<
    string,
    {
      message: string;
    } | null
  >;
  // undefined -> not validated yet
  // true/false -> invalid/valid
  datasets: boolean | undefined;
  images: boolean | undefined;
}

export type IEvaluationDetailsStore = EvaluationDetailsStore;
export const EVALUATION_DETAILS_STORE_INITIAL_STATE: Omit<IEvaluationDetailsStore, 'resetStore'> = {
  id: '',
  name: '',
  description: '',
  status: EVALUATION_STATUS.UNKNOWN,
  startingProgress: null,
  failureReason: null,
  modelVariant: MODEL_VARIANTS.ANOMALY_DETECTION_4,
  projectId: '',
  modelId: '',
  datasetIds: [],
  evaluationConfig: {},
  createdAt: '',
  projects: [],
  models: [],
  datasets: [],
  images: [],
  evaluationImagesPaging: defaultEvaluationImagesPaging,
  params: [],
  results: [],
  matrix: [],
  metrics: {
    auc: 0,
  },
  curve: [],
  evaluationDetailsValidationErrors: {
    showAll: false,
    settings: new Map(),
    header: new Map(),
    datasets: undefined,
    images: undefined,
  },
  isOwner: true,
  isTrainingOwner: false,
};

@injectable()
export class EvaluationDetailsStore {
  @observable id: string;
  @observable name: string;
  @observable description: string;
  @observable status: EVALUATION_STATUS;
  @observable startingProgress: IEvaluationStatusDto['starting_progress'];
  @observable failureReason: IEvaluationDetailsDto['failure_reason'];
  @observable modelVariant: MODEL_VARIANTS;
  @observable projectId: string;
  @observable modelId: string;
  @observable datasetIds: string[];
  @observable evaluationConfig: IEvaluationConfig;
  @observable createdAt: string;
  @observable projects: IEvaluationProject[];
  @observable models: IEvaluationModel[];
  @observable datasets: IEvaluationDataset[];
  @observable images: IPredictionImage[];
  @observable evaluationImagesPaging: IEvaluationImagesPaging;
  @observable params: Parameters;
  @observable metrics: ITrainingMetrics;
  @observable results: ITrainingResult[];
  @observable matrix: ITrainingMatrix[];
  @observable curve: IChartPoint[];
  @observable evaluationDetailsValidationErrors: IEvaluationDetailsValidationErrors;
  @observable isOwner: boolean;
  @observable isTrainingOwner: boolean;

  constructor() {
    this.id = EVALUATION_DETAILS_STORE_INITIAL_STATE.id;
    this.name = EVALUATION_DETAILS_STORE_INITIAL_STATE.name;
    this.description = EVALUATION_DETAILS_STORE_INITIAL_STATE.description;
    this.status = EVALUATION_DETAILS_STORE_INITIAL_STATE.status;
    this.startingProgress = EVALUATION_DETAILS_STORE_INITIAL_STATE.startingProgress;
    this.failureReason = EVALUATION_DETAILS_STORE_INITIAL_STATE.failureReason;
    this.modelVariant = EVALUATION_DETAILS_STORE_INITIAL_STATE.modelVariant;
    this.projectId = EVALUATION_DETAILS_STORE_INITIAL_STATE.projectId;
    this.datasetIds = EVALUATION_DETAILS_STORE_INITIAL_STATE.datasetIds;
    this.evaluationConfig = EVALUATION_DETAILS_STORE_INITIAL_STATE.evaluationConfig;
    this.createdAt = EVALUATION_DETAILS_STORE_INITIAL_STATE.createdAt;
    this.projects = EVALUATION_DETAILS_STORE_INITIAL_STATE.projects;
    this.models = EVALUATION_DETAILS_STORE_INITIAL_STATE.projects;
    this.modelId = EVALUATION_DETAILS_STORE_INITIAL_STATE.modelId;
    this.datasets = EVALUATION_DETAILS_STORE_INITIAL_STATE.datasets;
    this.images = EVALUATION_DETAILS_STORE_INITIAL_STATE.images;
    this.evaluationImagesPaging = EVALUATION_DETAILS_STORE_INITIAL_STATE.evaluationImagesPaging;
    this.params = EVALUATION_DETAILS_STORE_INITIAL_STATE.params;
    this.metrics = EVALUATION_DETAILS_STORE_INITIAL_STATE.metrics;
    this.results = EVALUATION_DETAILS_STORE_INITIAL_STATE.results;
    this.matrix = EVALUATION_DETAILS_STORE_INITIAL_STATE.matrix;
    this.curve = EVALUATION_DETAILS_STORE_INITIAL_STATE.curve;
    this.evaluationDetailsValidationErrors = EVALUATION_DETAILS_STORE_INITIAL_STATE.evaluationDetailsValidationErrors;
    this.isOwner = EVALUATION_DETAILS_STORE_INITIAL_STATE.isOwner;
    this.isTrainingOwner = EVALUATION_DETAILS_STORE_INITIAL_STATE.isTrainingOwner;
  }

  resetStore(isDraft: boolean) {
    Object.assign<IEvaluationDetailsStore, Omit<IEvaluationDetailsStore, 'resetStore'>>(this, EVALUATION_DETAILS_STORE_INITIAL_STATE);
    this.status = isDraft ? EVALUATION_STATUS.DRAFT : EVALUATION_DETAILS_STORE_INITIAL_STATE.status;
    this.name = isDraft ? `Evaluation ${new Date().toLocaleString()}` : '';
  }
}
