import { inject, injectable } from 'inversify';
import { IProjectDetailsStore, ProjectDetailsStoreType } from '../../../../../../__legacy__/modules/projectDetails/projectDetails.store';
import { ProjectStatus } from '../../../../../../__legacy__/modules/projects/projects.model';
import { WorkspaceRole } from '../../../../../../__legacy__/modules/workspaces/workspaces.store';
import { ICurrentWorkspaceStore, CurrentWorkspaceStoreType } from '../../../../../workspaces/currentWorkspace/CurrentWorkspace.store';
import { EditMarkingToolStore, EditMarkingToolStoreType } from './EditMarkingTool.store';

export const EditMarkingToolPermissionsType = Symbol('EDIT_MARKING_TOOL_PERMISSIONS');

export interface EditMarkingToolPermissions {
  canOpenEditMarkingToolModal: boolean;
  canChangeName: boolean;
  canChangeToolType: boolean;
  canChangeColor: boolean;
}

@injectable()
export class EditMarkingToolPermissionsImpl implements EditMarkingToolPermissions {
  constructor(
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore,
    @inject(EditMarkingToolStoreType) private readonly editStore: EditMarkingToolStore,
    @inject(ProjectDetailsStoreType) private readonly projectDetailsStore: IProjectDetailsStore,
  ) {}

  get canOpenEditMarkingToolModal(): boolean {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;

    return [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Trainer].includes(currentWorkspaceRole);
  }

  get canChangeName(): boolean {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;

    if (this.projectDetailsStore.status === ProjectStatus.Published) {
      return [WorkspaceRole.Owner, WorkspaceRole.Manager].includes(currentWorkspaceRole);
    }

    return [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Trainer].includes(currentWorkspaceRole);
  }

  get canChangeToolType(): boolean {
    if (this.editStore.isUsed) return false;

    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;

    if (this.projectDetailsStore.status === ProjectStatus.Published) {
      return [WorkspaceRole.Owner, WorkspaceRole.Manager].includes(currentWorkspaceRole);
    }

    return [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Trainer].includes(currentWorkspaceRole);
  }

  get canChangeColor(): boolean {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    return [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Trainer].includes(currentWorkspaceRole);
  }
}
