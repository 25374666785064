import { AnnotationToolFilterType, IAnnotationToolFilter, IImageFilters, IImageFiltersData } from '../../../imageFilters.model';

import { FiltersDropdownRow } from '../FiltersDropdownRow';
import { ITranslatable } from '../../../../../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../../../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IAnnotationToolsFilterInfoProps {
  availableFilters: IImageFiltersData;
  imageFilters: IImageFilters;
}

class AnnotationToolsFilterInfoPure extends React.Component<IAnnotationToolsFilterInfoProps & ITranslatable> {
  formatNumberValues(values: string[]) {
    const getInfinity = () => <span className="infinity-icon">&infin;</span>;

    if (values.length !== 2) return <span>-{getInfinity()} {this.props.t('image_filters.to')} {getInfinity()}</span>;

    let p1 = parseFloat(values[0]);
    let p2 = parseFloat(values[1]);

    if (isNaN(p1)) p1 = Number.NEGATIVE_INFINITY;
    if (isNaN(p2)) p2 = Number.POSITIVE_INFINITY;

    const min = Math.min(p1, p2);
    const max = Math.max(p1, p2);

    return (
      <span>
        {isFinite(min) ? min : (<span>-{getInfinity()}</span>)} {this.props.t('image_filters.to')} {isFinite(max) ? max : <span>{getInfinity()}</span>}
      </span>
    );
  }

  renderAnnotationTool(atool: IAnnotationToolFilter, labels: React.ReactElement[]) {
    let l1Label: string | undefined = undefined;
    let l2Label: string | undefined = undefined;
    let l3Label: React.ReactElement | undefined = undefined;

    const l1 = this.props.availableFilters.annotationTypes.find(x => x.id === atool.l1Filter);
    if (l1 !== undefined) {
      l1Label = l1.name;
      if (atool.l2FilterType !== undefined) {
        const l2 = l1.attributes.find(x => x.id === atool.l2Filter);
        if (l2 !== undefined) {
          l2Label = l2.text;

          if (atool.l3FilterType) {
            switch (atool.l3FilterType) {
              case AnnotationToolFilterType.AttributeAnswer:
                const selected = l2.possibleAnswers.filter(a => atool.l3Filters.some(b => a.id === b)).sort((a, b) => a.order - b.order).map(a => a.text);
                l3Label = selected.length ? <>{selected.join(', ')}</> : undefined;
                break;
              case AnnotationToolFilterType.AttributeAnswerText:
                l3Label = atool.l3Filters.length === 1 && atool.l3Filters[0] ? <>{atool.l3Filters[0]}</> : undefined;
                break;
              case AnnotationToolFilterType.AttributeAnswerNumber:
                l3Label = this.formatNumberValues(atool.l3Filters);
                break;
            }
          }
        }

        if (l3Label) {
          labels.push(<>{l1Label} - {l2Label} - {l3Label}</>);
        } else if (l2Label) {
          labels.push(<>{l1Label} - {l2Label}</>);
        } else {
          labels.push(<>{l1Label}</>);
        }
      }
    }
  }

  renderQuestion(atool: IAnnotationToolFilter, labels: React.ReactElement[]) {
    let l1Label: string | undefined = undefined;
    let l3Label: React.ReactElement | undefined = undefined;

    const l1 = this.props.availableFilters.questionFilters.find(x => x.id === atool.l1Filter);
    if (l1 !== undefined) {
      l1Label = l1.text;

      if (atool.l3FilterType) {
        switch (atool.l3FilterType) {
          case AnnotationToolFilterType.QuestionAnswer:
            const selected = l1.possibleAnswers.filter(a => atool.l3Filters.some(b => a.id === b)).sort((a, b) => a.order - b.order).map(a => a.text);
            l3Label = selected.length ? <>{selected.join(', ')}</> : undefined;
            break;
          case AnnotationToolFilterType.QuestionAnswerText:
            l3Label = atool.l3Filters.length === 1 && atool.l3Filters[0] ? <>{atool.l3Filters[0]}</> : undefined;
            break;
          case AnnotationToolFilterType.QuestionAnswerNumber:
            l3Label = this.formatNumberValues(atool.l3Filters);
            break;
        }
      }

      if (l3Label) {
        labels.push(<>{l1Label} - {l3Label}</>);
      } else {
        labels.push(<>{l1Label}</>);
      }
    }
  }

  render() {
    const labels: React.ReactElement[] = [];
    for (const atool of this.props.imageFilters.annotationTools.slice()) {
      if (atool.l1FilterType === AnnotationToolFilterType.AnnotationTool) {
        this.renderAnnotationTool(atool, labels);
      } else {
        this.renderQuestion(atool, labels);
      }
    }
    return (
      <FiltersDropdownRow label={this.props.t('image_filters.tool')} count={labels.length}>
        <>{labels.map((d, i) => <div key={i}>{d}</div>)}</>
      </FiltersDropdownRow>);
  }
}

export const AnnotationToolsFilterInfo = as<React.ComponentClass<IAnnotationToolsFilterInfoProps>>(withNamespaces('project')(AnnotationToolsFilterInfoPure));
