import * as React from 'react';

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { Datasets, IDatasetsContext } from '../datasets.context';
import { DatasetsPermissionsType, IDatasetsPermissions } from '../datasets.permissions';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { as, injectProps } from '../../../helpers/react.helpers';

import { DatasetDetails } from '../datasetDetails.context';
import { DatasetDetailsHeader } from '../components/DatasetDetailsHeader';
import { InputStatus } from '../../../models/error.model';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IState extends ILoaderState {
  name: string;
  nameStatus: InputStatus;
  description: string;
  descriptionStatus: InputStatus;
  author: string;
  authorStatus: InputStatus;
  termsOfUse: string;
  termsOfUseStatus: InputStatus;
}

interface IInjectedProps {
  datasetsPermissions: IDatasetsPermissions;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

@injectProps({
  datasetsPermissions: DatasetsPermissionsType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
@observer
class DatasetDetailsHeaderContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  static contextType = DatasetDetails;
  declare context: React.ContextType<typeof DatasetDetails>;

  state: IState = {
    name: '',
    description: '',
    author: '',
    termsOfUse: '',
    nameStatus: InputStatus.empty(),
    descriptionStatus: InputStatus.empty(),
    authorStatus: InputStatus.empty(),
    termsOfUseStatus: InputStatus.empty(),
    isLoading: true,
  };

  componentDidMount() {
    this.setup();
  }

  async setup() {
    await this.withLoaderAsync(() => this.context.service.getDatasetDetailsAsync(this.context.route.match.params.datasetId));
    const dataset = this.context.service.store.details;
    this.setState({
      name: dataset.name,
      description: dataset.description,
      author: dataset.author,
      termsOfUse: dataset.termsOfUse,
      nameStatus: InputStatus.valid(),
      descriptionStatus: InputStatus.valid(),
      authorStatus: InputStatus.valid(),
      termsOfUseStatus: InputStatus.valid(),
    });
  }

  @autobind
  handleNameChange(name: string) {
    this.setState({ name, nameStatus: InputStatus.empty() });
  }

  handleNameBlurAsync = (datasets: IDatasetsContext) => async () => {
    const nameStatus = InputStatus.buildFrom(await this.context.service.validateDatasetNameAsync(this.state.name, this.context.route.match.params.datasetId));

    if (nameStatus.isValid && this.state.name !== this.context.service.store.details.name) {
      datasets.service.markStale();
      await this.datasetUpdateAsync();
      datasets.store.refreshFromDetails(this.context.store.details);
    }

    this.setState({ nameStatus });
  };

  @autobind
  handleDescriptionChange(description: string) {
    this.setState({ description, descriptionStatus: InputStatus.empty() });
  }

  @autobind
  handleTermsOfUseChange(termsOfUse: string) {
    this.setState({ termsOfUse, termsOfUseStatus: InputStatus.empty() });
  }

  @autobind
  handleAuthorChange(author: string) {
    this.setState({ author, authorStatus: InputStatus.empty() });
  }

  handleDescriptionBlurAsync = (datasets: IDatasetsContext) => async () => {
    if (this.state.description !== this.context.service.store.details.description) {
      datasets.service.markStale();
      await this.datasetUpdateAsync();
      datasets.store.refreshFromDetails(this.context.store.details);
    }
    if (this.state.descriptionStatus.isEmpty) {
      this.setState({ descriptionStatus: InputStatus.valid() });
    }
  };

  handleTermsOfUseBlurAsync = (datasets: IDatasetsContext) => async () => {
    if (this.state.termsOfUse !== this.context.service.store.details.termsOfUse) {
      datasets.service.markStale();
      await this.datasetUpdateAsync();
      datasets.store.refreshFromDetails(this.context.store.details);
    }
    if (this.state.termsOfUseStatus.isEmpty) {
      this.setState({ termsOfUseStatus: InputStatus.valid() });
    }
  };

  handleAuthorBlurAsync = (datasets: IDatasetsContext) => async () => {
    if (this.state.author !== this.context.service.store.details.author) {
      datasets.service.markStale();
      await this.datasetUpdateAsync();
      datasets.store.refreshFromDetails(this.context.store.details);
    }
    if (this.state.authorStatus.isEmpty) {
      this.setState({ authorStatus: InputStatus.valid() });
    }
  };

  @autobind
  async datasetUpdateAsync() {
    await this.context.service.updateDatasetDetailsAsync({
      name: this.state.name,
      description: this.state.description,
      datasetId: this.context.route.match.params.datasetId,
      author: this.state.author,
      termsOfUse: this.state.termsOfUse,
    });
  }

  @autobind
  imageClickCallback() {
    this.context.service.setDefaultImagesPaging();
  }

  render() {
    const details = this.context.store.details;
    return (
      <Datasets.Consumer>
        {datasets => (
          <DatasetDetailsHeader
            details={details}
            workspaceId={this.props.currentWorkspaceStore.currentWorkspace!.id}
            name={this.state.name}
            description={this.state.description}
            onNameChange={this.handleNameChange}
            onDescriptionChange={this.handleDescriptionChange}
            onNameBlur={this.handleNameBlurAsync(datasets)}
            onDescriptionBlur={this.handleDescriptionBlurAsync(datasets)}
            nameMaxLength={200}
            descriptionMaxLength={500}
            nameStatus={this.state.nameStatus}
            descriptionStatus={this.state.descriptionStatus}
            isLoading={this.state.isLoading}
            imageClickCallback={this.imageClickCallback}
            termsOfUse={this.state.termsOfUse}
            author={this.state.author}
            termsOfUseStatus={this.state.termsOfUseStatus}
            authorStatus={this.state.authorStatus}
            onAuthorBlur={this.handleAuthorBlurAsync(datasets)}
            onTermsOfUseBlur={this.handleTermsOfUseBlurAsync(datasets)}
            onAuthorChange={this.handleAuthorChange}
            onTermsOfUseChange={this.handleTermsOfUseChange}
            canEditDetails={this.props.datasetsPermissions.canEditDatasetDetails(details.status, details.createdById)}
          />
        )}
      </Datasets.Consumer>
    );
  }
}

export const DatasetDetailsHeaderContainer = as<React.ComponentClass>(DatasetDetailsHeaderContainerPure);
