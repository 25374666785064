import './S_Modal.scss';

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import React from 'react';
import { S_ClassNameProps } from '../../CommonProps';
import classNames from 'classnames';

export interface S_ModalPropsBase {
  headerText: string;
  show: boolean;
  loaderKey?: string;
  children?: React.ReactNode;
}

interface S_ModalProps extends S_ModalPropsBase, S_ClassNameProps {
  buttons?: React.ReactNode[];
  onHide?: () => void;
}

export const S_Modal: React.FC<S_ModalProps> = (props: S_ModalProps) => (
  <Modal className={classNames('s_modal', props.className)} isOpen={props.show} toggle={props.onHide}>
    <ModalHeader toggle={props.onHide}>{props.headerText}</ModalHeader>
    <ModalBody>{props.children}</ModalBody>
    {props.buttons && <ModalFooter>{props.buttons}</ModalFooter>}
  </Modal>
);
