import { AttachmentType, IAttachment } from '../attachments.store';
import { GTable, IGColumnProps } from '../../../components/table/GTable';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { faCircleNotch, faDownload, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faFile, faFolderOpen } from '@fortawesome/free-regular-svg-icons';

import { CustomInput } from 'reactstrap';
import { EnableForRole } from '../../../containers/EnableForRole';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Home } from '../../../routes/config/Home';
import { ICurrentDownloadAttachment } from '../attachmentUploadStore';
import { IconedButton } from '../../../components/IconedButton';
import { Link } from 'react-router-dom';
import { Loader } from '../../../components/Loader';
import React from 'react';
import { RenameAttachmentIconedButtonContainer } from '../../../../modules/attachments/rename/ui/iconedButton/RenameAttachmentIconedButton.container';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import { bytesToMBsOrGBs } from '../../../helpers/string.helper';
import { dinduNuffin } from '../../../helpers/function.helpers';
import { disableTextMarkingsForClass } from '../../../helpers/react.helpers';
import { handleClickAndPassData } from '../../../helpers/formHelpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { toLocaleDateTimeString } from '../../../helpers/date.helpers';

library.add(faFolderOpen, faFile, faDownload, faEye, faTrashAlt, faCircleNotch);

export interface IOuterProps {
  attachments: IAttachment[];
  currentDownloadAttachments: ICurrentDownloadAttachment[];
  isLoading: boolean;
  isDownloadDisabled: boolean;
  isDeleteRequestInProgress: boolean;
  parentId?: string;
  sorting: {
    orderBy: string;
    orderType: string;
  };
  pagination: {
    pageNumber: number;
    pageSize: number;
    totalCount: number;
  };
  selectedAttachments: {
    ids: string[];
    isAllSelected: boolean;
  };
  workspaceId: string;
  canView: boolean;
  onFolderSelect(id: string): void;
  onDownloadAttachments(ids: string[]): void;
  onAttachmentsSelectionChanged(ids: string[]): void;
  onDeleted(id: string): void;
  onOrderingChange(orderBy: string, orderType: string): void;
  onPaginationChange(pageNumber: number, pageSize: number): void;
  onSelectAttachment(id: string): void;
  onSelectAllAttachments(): void;
}

class AttachmentsListTablePure extends React.Component<IOuterProps & WithNamespaces> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    disableTextMarkingsForClass('custom-control-label');
  }

  get gColumns() {
    const gColumns: IGColumnProps<IAttachment>[] = [];

    gColumns.push(
      {
        field: '',
        headerName: '',
        headerRenderer: this.renderSelectHeader,
        renderer: this.renderCheckbox,
        sortable: false,
        width: 35,
      },
      {
        field: 'type',
        headerName: this.props.t('type'),
        renderer: this.renderType,
        sortable: false,
        width: 60,
      },
      {
        field: 'name',
        headerName: this.props.t('name'),
        renderer: this.renderName,
        sortable: true,
        minWidth: 400,
      },
      {
        field: 'createdDate',
        headerName: this.props.t('created_date'),
        renderer: this.renderCreatedDate,
        sortable: true,
        width: 170,
      },
      {
        field: 'modifiedDate',
        headerName: this.props.t('modified_date'),
        renderer: this.renderModifiedDate,
        sortable: true,
        width: 170,
      },
      {
        field: 'author',
        headerName: this.props.t('author'),
        renderer: this.renderAuthor,
        width: 400,
        sortable: true,
      },
      {
        field: 'size',
        headerName: this.props.t('attachment_size'),
        renderer: this.renderSize,
        sortable: true,
        width: 240,
      },
      {
        field: '',
        headerName: this.props.t('actions'),
        renderer: this.renderActions,
        sortable: false,
        width: 110,
      },
    );

    return gColumns;
  }

  stopPropagation(e: { stopPropagation(): void }) {
    e.stopPropagation();
  }

  renderType = (attachment: IAttachment) => <FontAwesomeIcon icon={attachment.type === AttachmentType.FOLDER ? faFolderOpen : faFile} />;

  renderSelectHeader = () => (
    <div className="g-checkbox header" onClick={this.props.onSelectAllAttachments}>
      <div>
        <CustomInput
          checked={this.props.selectedAttachments.isAllSelected}
          onChange={this.stopPropagation}
          onClick={this.stopPropagation}
          disabled={!this.props.canView}
          type="checkbox"
          id={'id-select-all'}
          value={'value-select-all'}
        />
      </div>
    </div>
  );

  renderCheckbox = (attachment: IAttachment) => (
    <div className="g-checkbox" onClick={handleClickAndPassData(this.props.onSelectAttachment)(attachment.id)}>
      <div>
        <CustomInput
          checked={
            this.props.selectedAttachments.isAllSelected ? !this.props.selectedAttachments.ids.includes(attachment.id) : this.props.selectedAttachments.ids.includes(attachment.id)
          }
          onChange={this.stopPropagation}
          onClick={this.stopPropagation}
          type="checkbox"
          value={attachment.id}
          id={attachment.id}
          disabled={!this.props.canView}
        />
      </div>
    </div>
  );

  getOnDownloadAttachments = (id: string) => () => {
    if (this.props.currentDownloadAttachments.some(a => a.id === id)) return;
    this.props.onDownloadAttachments([id]);
  };

  renderName = (attachment: IAttachment) => {
    if (this.props.canView && !this.props.isDownloadDisabled && attachment.type === AttachmentType.IMAGE_FILE) {
      return (
        <Link className="text-link-in-table" title={attachment.name} to={this.getPreviewUrl(attachment.id)}>
          {attachment.name}
        </Link>
      );
    }

    let onClick: (e: React.MouseEvent) => void = dinduNuffin;
    if (this.props.canView) {
      if (attachment.type === AttachmentType.FOLDER) {
        onClick = handleClickAndPassData(this.props.onFolderSelect)(attachment.id);
      } else if (!this.props.isDownloadDisabled) {
        onClick = this.getOnDownloadAttachments(attachment.id);
      }
    }
    return (
      <span className={this.props.canView && !this.props.isDownloadDisabled ? 'text-link-in-table' : ''} onClick={onClick}>
        {attachment.name}
      </span>
    );
  };

  getPreviewUrl(attachmentId: string) {
    return Home.Attachments.List.Preview.withParams({ attachmentId, workspaceId: this.props.workspaceId, parentId: this.props.parentId || '' });
  }

  renderCreatedDate = (attachment: IAttachment) => <>{toLocaleDateTimeString(attachment.createdDate)}</>;

  renderModifiedDate = (attachment: IAttachment) => <>{toLocaleDateTimeString(attachment.modifiedDate)}</>;

  renderAuthor = (attachment: IAttachment) => <>{attachment.author}</>;

  renderSize = (attachment: IAttachment) => (
    <>{attachment.type === AttachmentType.FOLDER ? `${bytesToMBsOrGBs(attachment.size)} (${attachment.items} ${this.props.t('items')})` : bytesToMBsOrGBs(attachment.size)}</>
  );

  renderActions = (attachment: IAttachment) => {
    const { t } = this.props;
    const isDownloaded = this.props.currentDownloadAttachments.some(a => a.id === attachment.id);
    return (
      <div className="g-actions">
        <RenameAttachmentIconedButtonContainer id={attachment.id} name={attachment.name} parentId={attachment.parentId} />
        <EnableForRole
          workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Trainer, WorkspaceRole.Collaborator, WorkspaceRole.Guest]}
          projectRoles={[]}
        >
          {isDownloaded ? (
            <FontAwesomeIcon icon={faCircleNotch} className={'fa-spin'} />
          ) : (
            <IconedButton
              onClick={handleClickAndPassData(this.props.onDownloadAttachments)([attachment.id])}
              title={
                this.props.canView
                  ? this.props.isDownloadDisabled
                    ? t('common:policy_limit_exceeded_block')
                    : t('download_attachments')
                  : t('you_do_not_have_permission_to_perform_this_operation')
              }
              icon={'download'}
              disabled={!this.props.canView || this.props.isDownloadDisabled}
            />
          )}
        </EnableForRole>
        <EnableForRole workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager]} projectRoles={[]}>
          <IconedButton
            onClick={handleClickAndPassData(this.props.onDeleted)(attachment.id)}
            title={this.props.canView ? t('delete_attachment') : t('you_do_not_have_permission_to_perform_this_operation')}
            icon={'trash-alt'}
            color="red"
            disabled={!this.props.canView || isDownloaded}
          />
        </EnableForRole>
      </div>
    );
  };

  onSortChanged = (orderBy: string, orderType: string) => this.props.onOrderingChange(orderBy, orderType);

  render() {
    return (
      <div>
        <Loader isLoading={this.props.isLoading}>
          <div className="attachments-table">
            <GTable<IAttachment>
              columns={this.gColumns}
              items={this.props.attachments}
              paginationProps={{
                pageNumber: this.props.pagination.pageNumber,
                pageSize: this.props.pagination.pageSize,
                totalCount: this.props.pagination.totalCount,
                onChange: this.props.onPaginationChange,
              }}
              onSortChanged={this.onSortChanged}
              sortingModel={[this.props.sorting]}
            />
          </div>
        </Loader>
      </div>
    );
  }
}

export const AttachmentsListTable = withNamespaces('attachment', { wait: true })(AttachmentsListTablePure);
