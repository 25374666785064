import './ProjectPicker.scss';

import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QuestionModel } from '../../../../question.model';
import QuestionWithAnswers from '../../../../components/annotations/QuestionWithAnswers';
import React from 'react';
import { S_Checkbox } from '../../../../../../../design/inputs/checkbox/S_Checkbox';
import { dinduNuffin } from '../../../../../../helpers/function.helpers';

interface Props {
  id: string;
  name: string;
  count: number;
  checked: boolean;
  opened: boolean;
  questions: QuestionModel[];
  onChange(id: string, value: boolean): void;
  onOpen(id: string, value: boolean): void;
}

export const ProjectPickerOption: React.FC<Props> = props => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => props.onChange(props.id, e.target.checked);
  const handleOpen = () => props.onOpen(props.id, !props.opened);

  return (
    <div>
      <div className="project-picker-row-container">
        <div className="project-picker-options">
          <S_Checkbox
            id={props.id}
            label={
              <>
                <span className="ellipsisAndHidden">{props.name}</span>
                <span className="count">&nbsp;{`(${props.count})`}</span>
              </>
            }
            checked={props.checked}
            onChange={handleChange}
          />
          <div className="angle-container">{props.questions.length > 0 && <FontAwesomeIcon icon={props.opened ? faAngleUp : faAngleDown} onClick={handleOpen} />}</div>
        </div>
      </div>
      {props.opened && (
        <div className="project-picker-questions">
          {props.questions.map(q => (
            <div className="project-picker-question" key={q.id}>
              <QuestionWithAnswers key={q.id} question={q} disabled={true} isAttribute={false} onAnswered={dinduNuffin} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
