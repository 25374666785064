// tslint:disable:function-name
// tslint:disable:variable-name

import 'leaflet-draw';

import { KEYBOARD_EVENTS, KeyboardLeafletEvent, MOUSE_BUTTON, MOUSE_EVENTS, MouseLeafletEvent } from '../../annotations.interface';
import { equals, getAngleBetweenTwoVectors, multiply, projection, rotatePoint, subtract, sum, toUnitVector } from '../../../../helpers/geometry/vector.helpers';
import leaflet, { DrawEvents, DrawOptions, LatLng, LeafletEvent, LeafletEventHandlerFn, Map, Marker, Point, Polygon, Polyline } from 'leaflet';

import { AddRotatedRectangleVertexCommand } from '../../undoRedoCommands/addRotatedRectangleVertexCommand';
import { Feature } from 'geojson';
import { IUndoRedoHistory } from '../../undoRedoHistory.service';
import { _allowStateChangesInsideComputed } from 'mobx';
import autobind from 'autobind-decorator';

const MIN_SIDE_SIZE = 3;

export enum ROTATED_RECTANGLE_STATES {
  NOT_DRAWING = 'NOT_DRAWING',
  MAIN_AXIS = 'MAIN_AXIS',
  SECONDARY_AXIS = 'SECONDARY_AXIS',
}

@autobind
export class DrawRotatedRectangle extends leaflet.Draw.Polygon implements IDrawer {
  private readonly GUIDELINE_OPACITY: number = 0.5;

  private readonly undoRedoHistory: IUndoRedoHistory;
  private readonly onDrawCreated: (geojson: Feature) => void;

  private _guideLatLng: LatLng[] = [];

  private _solidGuideLine: Polygon | undefined = undefined;

  // variables used for rotating/resizing rectangle main axis
  private _lastMainSideEndPosition?: LatLng = undefined;
  private _lastMousePosition?: LatLng = undefined;
  private _saveLastMousePosition?: boolean = true;
  private _lastMouseMarkerPosition?: LatLng = undefined;
  private _saveLastMouseMarkerPosition?: boolean = true;
  private _theta?: number;

  get drawingState(): ROTATED_RECTANGLE_STATES {
    if (this._markers.length === 0) {
      return ROTATED_RECTANGLE_STATES.NOT_DRAWING;
    }
    if (this._markers.length === 1) {
      return ROTATED_RECTANGLE_STATES.MAIN_AXIS;
    }

    return ROTATED_RECTANGLE_STATES.SECONDARY_AXIS;
  }

  constructor(
    map: Map,
    onDrawCreated: (geojson: Feature) => void,
    undoRedoHistory: IUndoRedoHistory,
    isImprovedVisibilityCursorEnabled: boolean,
    options?: DrawOptions.PolygonOptions,
  ) {
    super(map, { ...options, repeatMode: true });
    this.onDrawCreated = onDrawCreated;
    this.undoRedoHistory = undoRedoHistory;
  }

  public disableMouseMarkerFocus() {}

  public addHandlers() {
    this._map.on(MOUSE_EVENTS.MOUSE_DOWN, this.handleMouseDown as LeafletEventHandlerFn);
    this._map.on(MOUSE_EVENTS.MOUSE_UP, this.handleMouseUp as LeafletEventHandlerFn);

    this._map.on(leaflet.Draw.Event.DRAWVERTEX, this.handleDrawVertex);
    this._map.on(leaflet.Draw.Event.CREATED, this.handleDrawCreated as LeafletEventHandlerFn);

    this._map.on(KEYBOARD_EVENTS.KEY_DOWN, this.handleKeyDown as LeafletEventHandlerFn);
    this._map.on(KEYBOARD_EVENTS.KEY_UP, this.handleKeyUp as LeafletEventHandlerFn);
  }

  public removeHandlers() {
    this._map.off(MOUSE_EVENTS.MOUSE_DOWN, this.handleMouseDown as LeafletEventHandlerFn);
    this._map.off(MOUSE_EVENTS.MOUSE_UP, this.handleMouseUp as LeafletEventHandlerFn);

    this._map.off(leaflet.Draw.Event.DRAWVERTEX, this.handleDrawVertex);
    this._map.off(leaflet.Draw.Event.CREATED, this.handleDrawCreated as LeafletEventHandlerFn);

    this._map.off(KEYBOARD_EVENTS.KEY_DOWN, this.handleKeyDown as LeafletEventHandlerFn);
    this._map.off(KEYBOARD_EVENTS.KEY_UP, this.handleKeyUp as LeafletEventHandlerFn);
  }

  private handleKeyDown(e: KeyboardLeafletEvent) {
    if (e.originalEvent.shiftKey || e.originalEvent.ctrlKey) {
      this._saveLastMousePosition = false;
      this._lastMainSideEndPosition = this._lastMainSideEndPosition === undefined ? this._markers[1].getLatLng() : this._lastMainSideEndPosition;
    }
  }

  private handleKeyUp(e: KeyboardLeafletEvent) {
    if (e.originalEvent.key === 'Shift' || e.originalEvent.key === 'Control') {
      this._saveLastMousePosition = true;
      this._lastMainSideEndPosition = undefined;
    }
  }

  public enable() {
    if (!this.enabled()) {
      super.enable();
    }

    return this;
  }

  public disable() {
    if (this.enabled()) {
      super.disable();
    }
    this.undoRedoHistory.clearDrawingHistory();
    return this;
  }

  getMarkersCount() {
    return this._markers.length;
  }

  checkValidity(): boolean {
    let willBeValid = true;

    willBeValid = willBeValid && this._guideLatLng.length === 2;
    willBeValid = willBeValid && !equals(this._markers[0].getLatLng(), this._guideLatLng[0]);
    willBeValid = willBeValid && !equals(this._markers[0].getLatLng(), this._guideLatLng[1]);
    willBeValid = willBeValid && !equals(this._markers[1].getLatLng(), this._guideLatLng[0]);
    willBeValid = willBeValid && !equals(this._markers[1].getLatLng(), this._guideLatLng[1]);

    return willBeValid;
  }

  handleMouseUp(e: MouseLeafletEvent) {
    if (this._disableMarkers) return;
    const distance = this._calculateFinishDistance(e.latlng);
    if (e.originalEvent.button !== MOUSE_BUTTON.LEFT || distance < MIN_SIDE_SIZE) return;

    if (this.drawingState === ROTATED_RECTANGLE_STATES.MAIN_AXIS) {
      this.addVertexWrapper(e.latlng);
    }
    if (this.drawingState === ROTATED_RECTANGLE_STATES.SECONDARY_AXIS) {
      if (this.checkValidity()) {
        this._guideLatLng.forEach((latLng: LatLng) => {
          this.addVertex(latLng);
        });
        this._finishShape();
      }
    }
  }

  handleMouseDown(e: MouseLeafletEvent) {
    if (this._disableMarkers) return;
    const distance = this._calculateFinishDistance(e.latlng);
    if (e.originalEvent.button !== MOUSE_BUTTON.LEFT || distance < MIN_SIDE_SIZE) return;

    if (this.drawingState !== ROTATED_RECTANGLE_STATES.SECONDARY_AXIS) {
      this.addVertexWrapper(e.latlng);
    }
  }

  _onMouseMove(e: MouseLeafletEvent) {
    if (e.originalEvent.ctrlKey && this.drawingState === ROTATED_RECTANGLE_STATES.SECONDARY_AXIS) {
      this.handleMainAxisRotate(e);
      this.onMouseMoveWhileRotating(e);
      return;
    }

    if (e.originalEvent.shiftKey && this.drawingState === ROTATED_RECTANGLE_STATES.SECONDARY_AXIS) {
      this.handleMainAxisResize(e);
    }

    this._lastMousePosition = this._saveLastMousePosition ? e.latlng : this._lastMousePosition;
    this._lastMouseMarkerPosition = this._saveLastMouseMarkerPosition ? this._mouseMarker.getLatLng() : this._lastMouseMarkerPosition;

    super._onMouseMove(e);
  }

  @autobind
  onMouseMoveWhileRotating(e: MouseLeafletEvent) {
    if (this._lastMouseMarkerPosition === undefined || this._theta === undefined) {
      return;
    }

    const mainSideStartPoint = this._markers[0].getLatLng();
    const rotatedSecondarySideEndPointLatLng = rotatePoint(this._lastMouseMarkerPosition, this._theta, mainSideStartPoint);

    this._currentLatLng = rotatedSecondarySideEndPointLatLng;
    this._updateTooltip(rotatedSecondarySideEndPointLatLng);
    this._updateGuide(this._map.latLngToLayerPoint(rotatedSecondarySideEndPointLatLng));
    this._mouseMarker.setLatLng(rotatedSecondarySideEndPointLatLng);
    leaflet.DomEvent.preventDefault(e.originalEvent);
  }

  private handleMainAxisResize(e: MouseLeafletEvent) {
    if (this._markers.length === 0 || this._lastMousePosition === undefined || this._lastMainSideEndPosition === undefined) return;

    const mainSideStartPoint = this._markers[0].getLatLng();

    const changeVector = subtract(e.latlng, this._lastMousePosition);
    const mainSideVector = subtract(this._lastMainSideEndPosition, mainSideStartPoint);
    const scaleVector = sum(changeVector, mainSideVector);
    const newMainSideVector = projection(scaleVector, mainSideVector);
    const newMainSideEndPoint = sum(newMainSideVector, mainSideStartPoint);

    this._markers[1].setLatLng(newMainSideEndPoint);
    this._poly.setLatLngs(this._markers.map(x => x.getLatLng()));
  }

  private handleMainAxisRotate(e: MouseLeafletEvent) {
    if (this._markers.length === 0 || this._lastMouseMarkerPosition === undefined || this._lastMainSideEndPosition === undefined) return;

    const mainSideStartPoint = this._markers[0].getLatLng();
    const mainSideEndPoint = this._lastMainSideEndPosition;
    const secondarySideEndPoint = this._lastMouseMarkerPosition;
    const targetSecondaryAxisEndPoint = e.latlng;

    this._theta = getAngleBetweenTwoVectors(mainSideStartPoint, secondarySideEndPoint, mainSideStartPoint, targetSecondaryAxisEndPoint);
    const rotatedMainSideEndPointLatLng = rotatePoint(mainSideEndPoint, this._theta, mainSideStartPoint);

    this._markers[1].setLatLng(rotatedMainSideEndPointLatLng);
    this._poly.setLatLngs(this._markers.map(x => x.getLatLng()));
  }

  handleDrawVertex() {
    this._markers.slice(1).forEach(m => this._markerGroup.removeLayer(m));
  }

  handleDrawCreated(e: DrawEvents.Created & LeafletEvent): void {
    const geojson = e.layer.toGeoJSON();
    this.onDrawCreated(geojson);
  }

  addVertexWrapper(latlng: LatLng) {
    if (this._disableMarkers) return;

    this._disableNewMarkers();

    this.addVertex(latlng);
    this.undoRedoHistory.addCommand(new AddRotatedRectangleVertexCommand(this, latlng));

    this._enableNewMarkers();
  }

  _drawGuide(pointA: Point, pointB: Point) {
    const latlngA = this._map.layerPointToLatLng(pointA);
    const latlngB = this._map.layerPointToLatLng(pointB);

    const opacity = this.GUIDELINE_OPACITY < (this.options?.shapeOptions?.fillOpacity || 0) ? this.options.shapeOptions?.fillOpacity : this.GUIDELINE_OPACITY;

    if (this.drawingState !== ROTATED_RECTANGLE_STATES.SECONDARY_AXIS) {
      this._clearGuides();

      this._solidGuideLine = new Polyline([latlngA, latlngB], {
        ...this.options.shapeOptions,
        opacity,
      });
      this._solidGuideLine.addTo(this._map);
    } else {
      const mainSizeStartPoint = this._markers[0].getLatLng();
      const mainSideEndPoint = this._markers[1].getLatLng();
      const changeVector = subtract(latlngB, latlngA);
      const mainSideVector = subtract(mainSideEndPoint, mainSizeStartPoint);
      const rotatedVector = new LatLng(mainSideVector.lng, -mainSideVector.lat);
      const rotatedUnitVector = toUnitVector(rotatedVector);
      const changeDotProduct = changeVector.lat * rotatedUnitVector.lat + changeVector.lng * rotatedUnitVector.lng;

      const firstGuidePoint = sum(mainSideEndPoint, multiply(rotatedUnitVector, changeDotProduct));
      const secondGuidePoint = subtract(firstGuidePoint, mainSideVector);

      this._guideLatLng = [firstGuidePoint, secondGuidePoint];
      this._solidGuideLine = new Polygon([this._markers[0].getLatLng(), this._markers[1].getLatLng(), firstGuidePoint, secondGuidePoint, this._markers[0].getLatLng()], {
        ...this.options.shapeOptions,
        opacity,
      });
    }
    this._solidGuideLine.addTo(this._map);
  }

  _clearGuides() {
    if (this._solidGuideLine) {
      this._solidGuideLine.remove();
    }
  }

  _finishShape() {
    this._ensureClockwise();
    super._finishShape();
    this.disable();
  }

  _ensureClockwise() {
    const [left, right, bottomRight, bottomLeft] = this._poly._rings[0];
    if (left.x > right.x !== left.y > bottomLeft.y) {
      this._poly._rings[0] = [right, left, bottomLeft, bottomRight];
      this._poly._parts[0] = [right, left, bottomLeft, bottomRight];
      const [leftLL, rightLL, bottomRightLL, bottomLeftLL] = this._poly._latlngs;
      this._poly._latlngs = [rightLL, leftLL, bottomLeftLL, bottomRightLL];
    }
  }

  _calculateFinishDistance(latLng: LatLng) {
    const point = this._map.latLngToContainerPoint(latLng);
    const minDistance = Math.min(...this._markers.map(x => this._map.latLngToContainerPoint(x.getLatLng())).map(x => x.distanceTo(point)));

    return minDistance;
  }

  _createMarker(latlng: LatLng) {
    const marker = new Marker(latlng, {
      icon: leaflet.divIcon({
        className: 'leaflet-mouse-marker',
        iconAnchor: [20, 20],
        iconSize: [40, 40],
      }),
      opacity: 0,
      zIndexOffset: this.options.zIndexOffset || 0 * 2,
      keyboard: false,
    });

    this._markerGroup.addLayer(marker);
    return marker;
  }

  _onTouch() {}
  _onMouseDown() {}
  _onMouseUp() {}
  _updateFinishHandler() {}
  _cleanUpShape() {}

  _enableNewMarkers() {
    setTimeout(() => {
      this._disableMarkers = false;
    }, 200);
  }
}
