import { EmptyAnnotationToolFilter, IAnnotationToolFilter } from '../../../imageFilters.model';

import { AnnotationToolRowForm } from './AnnotationToolRowForm';
import { Button } from 'reactstrap';
import { FilterContent } from '../FilterContent';
import { ITranslatable } from '../../../../../../../helpers/translations.helpers';
import { ImageFiltersFormProps } from '../ImageFiltersForm';
import React from 'react';
import { as } from '../../../../../../../helpers/react.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

@observer
class AnnotationToolsBodyPure extends React.Component<ImageFiltersFormProps & ITranslatable> {

  handleFilterToolChanged = (toolFilter: IAnnotationToolFilter) => {
    const newArray = this.props.imageFilters.annotationTools.slice();
    const index = newArray.findIndex(x => x.order === toolFilter.order);
    if (index > -1) {
      newArray.splice(index, 1, toolFilter);
      this.props.onSetAnnotationTools(newArray);
    }
  }

  handleAddAnother = () => {
    const topOrder = this.props.imageFilters.annotationTools.map(x => x.order).reduce((x, y) => x > y ? x : y, 0);
    const newAnnotationTools = this.props.imageFilters.annotationTools.concat({
      ...EmptyAnnotationToolFilter,
      order: topOrder + 1,
    });
    this.props.onSetAnnotationTools(newAnnotationTools);
  }

  handleDelete = (order: number) => {
    const newSoring = this.props.imageFilters.annotationTools
      .filter(x => x.order !== order)
      .sort((a, b) => a.order - b.order)
      .map((a, i) => ({ ...a, order: i }));

    this.props.onSetAnnotationTools(newSoring);
  }

  render() {
    const tools = this.props.imageFilters.annotationTools;
    return (
      <FilterContent labelKey={'annotation_tools'}>
        {tools.map(x =>
        (<AnnotationToolRowForm
          key={x.order}
          availableImageFilters={this.props.availableFilters}
          toolFilter={x}
          showDelete={tools.length > 1}
          onToolFilterChanged={this.handleFilterToolChanged}
          onDelete={this.handleDelete}
        />))}
        <Button color="Primary" onClick={this.handleAddAnother} >+ {this.props.t('add_another')} </Button>
      </FilterContent>

    );
  }
}

export const AnnotationToolsBody = as<React.ComponentClass<ImageFiltersFormProps>>(withNamespaces('common')(AnnotationToolsBodyPure));
