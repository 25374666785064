import './S_Action.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { S_ButtonProps } from '../buttons/S_ButtonProps';
import { S_IconButton } from '../buttons/iconButton/S_IconButton';
import { S_TitleProps } from '../CommonProps';

interface S_ActionProps extends S_ButtonProps, S_TitleProps {
  icon: IconProp;
  title: string;
}

export const S_Action: React.FC<S_ActionProps> = props => (
  <S_IconButton className="s-action" iconClassName="s-action-icon" icon={props.icon} onClick={props.onClick} title={props.title} disabled={props.disabled}/>
);

