import "../WorkspaceActionsInfo.scss"

import { WithNamespaces, withNamespaces } from 'react-i18next';

import React from 'react';
import { S_OneButtonModal } from '../../../../design/modals/oneButtonModal/S_OneButtonModal';
import { WorkspaceActionsTable } from '../table/WorkspaceActionsTable';

interface IProps {
  show: boolean;
  toggle(): void;
}

const WorkspaceActionsModalPure: React.FC<IProps & WithNamespaces> = ({ show, t, toggle }: IProps & WithNamespaces) => {
  const header = t('team.workspace_actions.modal_header');
  const ok = t('team.workspace_actions.modal_button_text');

  return (
    <S_OneButtonModal show={show} buttonText={ok} headerText={header} onClick={toggle} onHide={toggle} className="workspace-actions-modal">
      <WorkspaceActionsTable />
    </S_OneButtonModal>
  );
};

export const WorkspaceActionsModal = withNamespaces('new')(WorkspaceActionsModalPure);
