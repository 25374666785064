import { IAdminUserInfo, IPlanInfo, IUserStore, Plan, UserRole, UserStoreType } from '../../user/user.store';
import { IUserService, UserServiceType } from '../../user/user.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithLoaderComponentBase, autoLoader } from '../../../helpers/loader.helpers';
import { as, injectProps } from '../../../helpers/react.helpers';

import { IAddCreditsModalProps } from '../components/AddCreditsModal';
import { IAdminUsersListProps } from '../components/AdminUsersList';
import { IChangeUserPlanModalProps } from '../components/ChangeUserPlanModal';
import { IChangeUserRoleModalProps } from '../components/ChangeUserRoleModal';
import { ILockUserModalProps } from '../components/LockUserModal';
import { IPagingInfoWithOrder } from '../../../models/paginationInfo.model';
import { InputStatus } from '../../../models/error.model';
import React from 'react';
import _ from 'lodash';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IAdminUsersContainerProps extends IAddCreditsModalProps, IChangeUserPlanModalProps, IChangeUserRoleModalProps, ILockUserModalProps, IAdminUsersListProps {
  currentUserId: string;
  users: IAdminUserInfo[];
  paging: IPagingInfoWithOrder;
  changeUserRoleModalShown: boolean;
  changeUserPlanModalShown: boolean;
  lockAccountModalShown: boolean;
  selectedRole: UserRole;
  planDefaults: IPlanInfo[];
  imagesAmountStatus: InputStatus;
  availableSpaceStatus: InputStatus;
  workspacesStatus: InputStatus;
  workspaceUsersStatus: InputStatus;
  workspaceWorkersStatus: InputStatus;
  onPaginationChange(pageNumber: number, pageSize: number): void;
  onOrderingChange(orderBy: string, orderType: string): void;
  onChangeRoleClicked(id: string): void;
  onSelectedRoleChange(role: UserRole): void;
  onSaveRoleClicked(): void;
  onNumericInputBlur(name: string): void;
  onPlanChange(plan: Plan): void;
  onChangePlanClicked(id: string): void;
  onCustomLimitsChange(value: boolean): void;
  onLimitToggle(name: string, unlimited: boolean): void;
  onLimitChange(name: string, value?: number): void;
  onSavePlanClicked(): void;
  onLockAccountClicked(id: string): void;
  onLockAccountChange(locked: boolean): void;
  onLockAccountSave(): void;
}

interface IInjectedProps extends RouteComponentProps {
  userStore: IUserStore;
  userService: IUserService;
}

interface IState {
  changeUserRoleModalShown: boolean;
  changeUserPlanModalShown: boolean;
  addCreditsModalShown: boolean;
  lockAccountModalShown: boolean;
  selectedUserId: string;
  selectedRole: UserRole;
  userIdSelectedToRoleChange: string;
  planInfo?: IPlanInfo;
  creditsToAdd: string;
  imagesAmountStatus: InputStatus;
  availableSpaceStatus: InputStatus;
  workspacesStatus: InputStatus;
  workspaceUsersStatus: InputStatus;
  workspaceWorkersStatus: InputStatus;
  isLoading: boolean;
}

@injectProps({
  userStore: UserStoreType,
  userService: UserServiceType,
})
@observer
class UsersContainer extends WithLoaderComponentBase<IInjectedProps, IState> {
  constructor(props: IInjectedProps) {
    super(props);
    this.state = {
      changeUserRoleModalShown: false,
      changeUserPlanModalShown: false,
      addCreditsModalShown: false,
      lockAccountModalShown: false,
      selectedUserId: '',
      selectedRole: UserRole.User,
      userIdSelectedToRoleChange: '',
      creditsToAdd: '',
      planInfo: undefined,
      imagesAmountStatus: InputStatus.empty(),
      availableSpaceStatus: InputStatus.empty(),
      workspacesStatus: InputStatus.empty(),
      workspaceUsersStatus: InputStatus.empty(),
      workspaceWorkersStatus: InputStatus.empty(),
      isLoading: true,
    };
  }

  @autoLoader()
  async componentDidMount() {
    await this.props.userService.getUsers();
    await this.props.userService.getPlanDefaultsAsync();
  }

  getDefaultPlan = (plan?: Plan): IPlanInfo => this.props.userStore.planDefaults.find(p => p.plan === (plan || (this.state.planInfo && this.state.planInfo!.plan) || 'Starter'))!;

  @autobind
  @autoLoader()
  private async handlePaginationChange(pageNumber: number, pageSize: number) {
    await this.props.userService.userListPaginationChange(pageNumber, pageSize);
  }

  @autobind
  @autoLoader()
  async handleOrderingChange(orderBy: string, orderType: string) {
    await this.props.userService.userListOrderChange(orderBy, orderType);
  }

  @autobind
  @autoLoader()
  async handleSearchChange(search: string) {
    await this.props.userService.userListSearchChange(search);
  }

  handleSearchChangeDebounced = _.debounce(this.handleSearchChange, 1000, {
    leading: false,
    trailing: true,
  });

  @autobind
  toggleChangeRoleModal(id: string, role?: UserRole) {
    this.setState((prevState: IState) => ({
      ...prevState,
      userIdSelectedToRoleChange: id,
      selectedRole: role || UserRole.User,
      changeUserRoleModalShown: !prevState.changeUserRoleModalShown,
    }));
  }

  @autobind
  toggleChangePlanModal(id: string) {
    const planInfo = this.props.userStore.adminUserInfo.find(p => p.id === id);

    if (planInfo) {
      const defaultPlan = this.getDefaultPlan(planInfo.plan);
      planInfo.availableSpace = planInfo.availableSpace || defaultPlan.availableSpace;
      planInfo.imagesAmount = planInfo.imagesAmount || defaultPlan.imagesAmount;
      planInfo.workspaceUsers = planInfo.workspaceUsers || defaultPlan.workspaceUsers;
      planInfo.workspaceWorkers = planInfo.workspaceWorkers || defaultPlan.workspaceWorkers;
      planInfo.workspaces = planInfo.workspaces || defaultPlan.workspaces;
    }

    const validate = !this.state.changeUserPlanModalShown && planInfo && planInfo.customLimits;

    this.setState((prevState: IState) => ({
      ...prevState,
      planInfo,
      selectedUserId: id,
      changeUserPlanModalShown: !prevState.changeUserPlanModalShown,
      availableSpaceStatus: validate ? InputStatus.buildFrom(this.getErrorCodes(planInfo!.availableSpace)) : InputStatus.empty(),
      imagesAmountStatus: validate ? InputStatus.buildFrom(this.getErrorCodes(planInfo!.imagesAmount)) : InputStatus.empty(),
      workspaceUsersStatus: validate ? InputStatus.buildFrom(this.getErrorCodes(planInfo!.workspaceUsers)) : InputStatus.empty(),
      workspaceWorkersStatus: validate ? InputStatus.buildFrom(this.getErrorCodes(planInfo!.workspaceWorkers)) : InputStatus.empty(),
      workspacesStatus: validate ? InputStatus.buildFrom(this.getErrorCodes(planInfo!.workspaces)) : InputStatus.empty(),
    }));
  }
  @autobind
  async handleSaveRoleChange() {
    await this.props.userService.changeUserRoleAsync(this.state.userIdSelectedToRoleChange, this.state.selectedRole);
    this.toggleChangeRoleModal('', this.state.selectedRole);
  }

  @autobind
  handleChangeSelectedRole(role: UserRole) {
    this.setState({ selectedRole: role });
  }

  @autobind
  async handleSavePlanChange() {
    if (this.isFormValid()) {
      await this.props.userService.changeUserPlanAsync(this.state.selectedUserId, this.state.planInfo!);
      this.toggleChangePlanModal('');
    } else {
      this.validateForm();
    }
  }

  @autobind
  handlePlanChange(plan: Plan) {
    const defaultPlan = this.getDefaultPlan(plan);
    this.setState((prevState: IState) => ({
      ...prevState,
      planInfo: prevState.planInfo && {
        ...prevState.planInfo,
        plan,
        imagesAmount: !prevState.planInfo.customLimits ? defaultPlan.imagesAmount : prevState.planInfo.imagesAmount,
        availableSpace: !prevState.planInfo.customLimits ? defaultPlan.availableSpace : prevState.planInfo.availableSpace,
        workspaces: !prevState.planInfo.customLimits ? defaultPlan.workspaces : prevState.planInfo.workspaces,
        workspaceWorkers: !prevState.planInfo.customLimits ? defaultPlan.workspaceWorkers : prevState.planInfo.workspaceWorkers,
        workspaceUsers: !prevState.planInfo.customLimits ? defaultPlan.workspaceUsers : prevState.planInfo.workspaceUsers,
      },
    }));
  }

  @autobind
  handleCustomLimitsChange(customLimits: boolean) {
    const planDefaults = this.getDefaultPlan(this.state.planInfo!.plan);

    this.setState((prevState: IState) => {
      const newState = {
        ...prevState,
        planInfo: prevState.planInfo && {
          ...prevState.planInfo,
          customLimits,
          imagesAmount: planDefaults.imagesAmount,
          availableSpace: planDefaults.availableSpace,
          workspaces: planDefaults.workspaces,
          workspaceWorkers: planDefaults.workspaceWorkers,
          workspaceUsers: planDefaults.workspaceUsers,
        },
      };

      if (!customLimits) {
        newState.workspacesStatus = InputStatus.empty();
        newState.imagesAmountStatus = InputStatus.empty();
        newState.availableSpaceStatus = InputStatus.empty();
        newState.workspaceUsersStatus = InputStatus.empty();
        newState.workspaceWorkersStatus = InputStatus.empty();
      }

      return newState;
    });
  }

  @autobind
  handleLimitToggle(name: string, unlimited: boolean) {
    this.setState((prevState: IState) => ({
      ...prevState,
      planInfo: prevState.planInfo && { ...prevState.planInfo, [name]: unlimited ? -1 : undefined },
      [`${name}Status`]: InputStatus.buildFrom(this.getErrorCodes(unlimited ? -1 : undefined)),
    }));
  }

  @autobind
  handleLimitChange(name: string, value?: number) {
    this.setState((prevState: IState) => ({
      ...prevState,
      planInfo: prevState.planInfo && { ...prevState.planInfo, [name]: value },
    }));
  }

  @autobind
  handleReset() {
    const planDefaults = this.getDefaultPlan();

    this.setState((prevState: IState) => ({
      ...prevState,
      planInfo: prevState.planInfo && {
        ...prevState.planInfo,
        imagesAmount: planDefaults.imagesAmount,
        availableSpace: planDefaults.availableSpace,
        workspaces: planDefaults.workspaces,
        workspaceWorkers: planDefaults.workspaceWorkers,
        workspaceUsers: planDefaults.workspaceUsers,
      },
      imagesAmountStatus: InputStatus.empty(),
      availableSpaceStatus: InputStatus.empty(),
      workspacesStatus: InputStatus.empty(),
      workspaceUsersStatus: InputStatus.empty(),
      workspaceWorkersStatus: InputStatus.empty(),
    }));
  }

  @autobind
  toggleAddCreditsModal(id: string) {
    this.setState((prevState: IState) => ({
      ...prevState,
      selectedUserId: id,
      planInfo: this.props.userStore.adminUserInfo.find(p => p.id === id),
      addCreditsModalShown: !prevState.addCreditsModalShown,
      creditsToAdd: '',
    }));
  }

  @autobind
  handleCreditsChange(creditsToAdd: string) {
    this.setState({ creditsToAdd });
  }

  @autobind
  handleAddCreditsConfirmClicked() {
    const parsedValue = parseInt(this.state.creditsToAdd, 10);
    if (isNaN(parsedValue) || this.state.creditsToAdd !== parsedValue.toString()) return;

    if (parseInt) this.props.userService.addCreditsAsync(this.state.selectedUserId, parseInt(this.state.creditsToAdd, 10));
    this.toggleAddCreditsModal('');
  }

  @autobind
  toggleLockAccountModal(id: string) {
    this.setState((prevState: IState) => ({
      ...prevState,
      selectedUserId: id,
      planInfo: this.props.userStore.adminUserInfo.find(p => p.id === id),
      lockAccountModalShown: !prevState.lockAccountModalShown,
    }));
  }

  @autobind
  handleLockAccountChange(locked: boolean) {
    this.setState((prevState: IState) => ({
      ...prevState,
      planInfo: prevState.planInfo && { ...prevState.planInfo, isActive: !locked },
    }));
  }

  @autobind
  handleLockAccountSave() {
    this.props.userService.lockUserAsync(this.state.selectedUserId, !this.state.planInfo!.isActive);
    this.toggleLockAccountModal('');
  }

  getErrorCodes = (value: number | undefined) => (value === undefined || value === null || isNaN(value) || value < -1 || value === 0 ? ['field_is_required'] : []);

  @autobind
  validateInput(name: string) {
    if (this.state.planInfo) {
      const value: number = this.getValueByKey(this.state.planInfo, name);

      this.setState((prevState: IState) => ({
        ...prevState,
        [`${name}Status`]: InputStatus.buildFrom(this.getErrorCodes(value)),
      }));
    }
  }

  getValueByKey = (obj: any, key: any) => obj[key];

  @autobind
  validateForm() {
    this.setState((prevState: IState) => ({
      ...prevState,
      imagesAmountStatus: InputStatus.buildFrom(this.getErrorCodes(prevState.planInfo!.imagesAmount)),
      availableSpaceStatus: InputStatus.buildFrom(this.getErrorCodes(prevState.planInfo!.availableSpace)),
      workspacesStatus: InputStatus.buildFrom(this.getErrorCodes(prevState.planInfo!.workspaces)),
      workspaceUsersStatus: InputStatus.buildFrom(this.getErrorCodes(prevState.planInfo!.workspaceUsers)),
      workspaceWorkersStatus: InputStatus.buildFrom(this.getErrorCodes(prevState.planInfo!.workspaceWorkers)),
    }));
  }

  @autobind
  isFormValid() {
    return (
      !this.state.planInfo!.customLimits ||
      (this.getErrorCodes(this.state.planInfo!.imagesAmount).length === 0 &&
        this.getErrorCodes(this.state.planInfo!.availableSpace).length === 0 &&
        this.getErrorCodes(this.state.planInfo!.workspaces).length === 0 &&
        this.getErrorCodes(this.state.planInfo!.workspaceUsers).length === 0 &&
        this.getErrorCodes(this.state.planInfo!.workspaceUsers).length === 0 &&
        this.getErrorCodes(this.state.planInfo!.workspaceWorkers).length === 0)
    );
  }

  render() {
    return React.Children.map(this.props.children, (child: any) =>
      React.cloneElement(child, {
        currentUserId: this.props.userStore.userInfo.id,
        users: this.props.userStore.adminUserInfo,
        paging: this.props.userStore.adminUsersPaging,
        changeUserRoleModalShown: this.state.changeUserRoleModalShown,
        changeUserPlanModalShown: this.state.changeUserPlanModalShown,
        addCreditsModalShown: this.state.addCreditsModalShown,
        lockAccountModalShown: this.state.lockAccountModalShown,
        selectedRole: this.state.selectedRole,
        plansInfo: this.props.userStore.adminUserInfo,
        planInfo: this.state.planInfo,
        planDefaults: this.props.userStore.planDefaults,
        imagesAmountStatus: this.state.imagesAmountStatus,
        availableSpaceStatus: this.state.availableSpaceStatus,
        workspacesStatus: this.state.workspacesStatus,
        workspaceUsersStatus: this.state.workspaceUsersStatus,
        workspaceWorkersStatus: this.state.workspaceWorkersStatus,
        creditsToAdd: this.state.creditsToAdd,
        isLoading: this.state.isLoading,
        onNumericInputBlur: this.validateInput,
        onSavePlanClicked: this.handleSavePlanChange,
        onPlanChange: this.handlePlanChange,
        onCustomLimitsChange: this.handleCustomLimitsChange,
        onLimitToggle: this.handleLimitToggle,
        onLimitChange: this.handleLimitChange,
        onReset: this.handleReset,
        onAddCreditsClicked: this.toggleAddCreditsModal,
        onCreditsChange: this.handleCreditsChange,
        onAddCreditsConfirmClicked: this.handleAddCreditsConfirmClicked,
        onLockAccountClicked: this.toggleLockAccountModal,
        onLockAccountChange: this.handleLockAccountChange,
        onLockAccountSave: this.handleLockAccountSave,
        onPaginationChange: this.handlePaginationChange,
        onChangeRoleClicked: this.toggleChangeRoleModal,
        onChangePlanClicked: this.toggleChangePlanModal,
        onOrderingChange: this.handleOrderingChange,
        onSaveRoleClicked: this.handleSaveRoleChange,
        onSelectedRoleChange: this.handleChangeSelectedRole,
        onSearchChange: this.handleSearchChangeDebounced,
      } as IAdminUsersContainerProps),
    );
  }
}

export default as<React.ComponentClass>(withRouter(UsersContainer));
