import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { IUpdateWorkspaceTimeZoneBl, UpdateWorkspaceTimeZoneBlType } from '../sub/UpdateWorkspaceTimeZone/UpdateWorkspaceTimeZone.bl';
import { IUserService, UserServiceType } from '../../user/user.service';
import { IWorkspaceService, WorkspaceServiceType } from '../workspaces.service';
import { InputStatus, StickerError } from '../../../models/error.model';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { ChangeWorkspaceOwnerModal } from '../components/ChangeWorkspaceOwnerModal';
import { DeleteWorkspaceConfirmationModal } from '../components/DeleteWorkspaceConfirmationModal';
import { IWorkspaceMember } from '../workspaces.store';
import React from 'react';
import { RenameWorkspaceModal } from '../components/RenameWorkspaceModal';
import { Settings } from '../../../../modules/settings/SettingsContext';
import { UpdateWorkspaceTimeZoneModalContainer } from '../sub/UpdateWorkspaceTimeZone/containers/UpdateWorkspaceTimeZoneModal.container';
import { WorkspacesList } from '../components/WorkspacesList';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IState extends ILoaderState {
  showRenameWorkspaceModal: boolean;
  renameWorkspaceName: string;
  renameWorkspaceInputStatus: InputStatus;
  workspaceToRename: string;

  deleteWorkspaceModalShown: boolean;
  workspaceToDelete: string;

  showChangeOwnerModal: boolean;
  ownerEmail: string;
  ownerEmailStatus: InputStatus;
  workspaceTeamMembers: IWorkspaceMember[];
  workspaceToChangeOwner: string;
}

interface IInjectedProps extends RouteComponentProps<{ workspaceId: string }> {
  workspaceService: IWorkspaceService;
  userService: IUserService;
  currentWorkspaceStore: ICurrentWorkspaceStore;
  updateWorkspaceTimeZoneBl: IUpdateWorkspaceTimeZoneBl;
}

@injectProps({
  workspaceService: WorkspaceServiceType,
  userService: UserServiceType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  updateWorkspaceTimeZoneBl: UpdateWorkspaceTimeZoneBlType,
})
@observer
class WorkspacesListContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  static contextType = Settings;
  declare context: React.ContextType<typeof Settings>;

  constructor(props: IInjectedProps) {
    super(props);

    this.state = {
      isLoading: false,
      showRenameWorkspaceModal: false,
      renameWorkspaceName: '',
      renameWorkspaceInputStatus: InputStatus.empty(),
      workspaceToRename: '',

      deleteWorkspaceModalShown: false,
      workspaceToDelete: '',

      showChangeOwnerModal: false,
      ownerEmail: '',
      ownerEmailStatus: InputStatus.empty(),
      workspaceTeamMembers: [],
      workspaceToChangeOwner: '',
    };
  }

  componentDidMount() {
    this.props.workspaceService.initWorkspaceListAsync();
  }

  componentWillUnmount(): void {
    this.props.workspaceService.disposeWorkspaceListAsync();
  }

  @autobind
  toggleRenameWorkspaceModal(workspaceId: string) {
    if (this.state.isLoading) return;

    const workspace = this.props.workspaceService.store.workspaces.find(w => w.id === workspaceId);

    this.setState(
      (prevState: IState): IState => ({
        ...prevState,
        showRenameWorkspaceModal: !prevState.showRenameWorkspaceModal,
        renameWorkspaceName: workspace ? workspace.name : '',
        renameWorkspaceInputStatus: InputStatus.empty(),
        workspaceToRename: workspaceId,
      }),
    );
  }

  @autobind
  toggleChangeOwnerModal(workspaceId: string) {
    this.props.workspaceService.store.takeOverWorkspaceLimits = undefined;
    const listItem = this.props.workspaceService.store.workspaceListItems.find(w => w.id === workspaceId);
    this.setState(
      (prevState: IState): IState => ({
        ...prevState,
        showChangeOwnerModal: !prevState.showChangeOwnerModal,
        ownerEmail: '',
        ownerEmailStatus: InputStatus.empty(),
        workspaceTeamMembers: listItem ? listItem.members : [],
        workspaceToChangeOwner: workspaceId,
      }),
    );
  }

  @autobind
  toggleDeleteModal(workspaceId: string) {
    this.setState(prevState => ({
      deleteWorkspaceModalShown: !prevState.deleteWorkspaceModalShown,
      workspaceToDelete: workspaceId,
    }));
  }

  @autobind
  toggleUpdateWorkspaceTimeZoneModal(workspaceId: string, timeZoneOffset: number) {
    this.props.updateWorkspaceTimeZoneBl.showForm(workspaceId, timeZoneOffset);
  }

  @autobind
  handleRenameWorkspaceNameChange(value: string) {
    this.setState(
      (prevState: IState): IState => ({
        ...prevState,
        renameWorkspaceName: value,
        renameWorkspaceInputStatus: this.validateWorkspaceName(value),
      }),
    );
  }

  @autobind
  validateWorkspaceName(value: string): InputStatus {
    const errorCodes: string[] = [];

    if (value.trim() === '') {
      errorCodes.push('field_cant_be_empty');
    }

    const isNotUnique = this.props.workspaceService.store.workspaces.map(w => w.name.toLocaleUpperCase()).includes(value.trim().toLocaleUpperCase());

    if (isNotUnique) {
      errorCodes.push('DUPLICATE_WORKSPACE_NAME');
    }

    return InputStatus.buildFrom(errorCodes);
  }

  @autobind
  async renameWorkspace() {
    if (this.state.isLoading) return;

    await this.withLoaderAsync(async () => {
      if (this.state.renameWorkspaceInputStatus.isValid !== true) return;
      const result = await this.props.workspaceService.renameWorkspaceAsync(this.state.workspaceToRename, this.state.renameWorkspaceName);

      if (result instanceof StickerError) {
        this.setState(
          (prevState: IState): IState => ({
            ...prevState,
            renameWorkspaceInputStatus: InputStatus.buildFrom(result.apiErrorResponse!.errorCodes),
          }),
        );
      } else {
        this.setState(
          (prevState: IState): IState => ({
            ...prevState,
            renameWorkspaceInputStatus: InputStatus.empty(),
            showRenameWorkspaceModal: false,
          }),
        );
      }
    }, 'rename-workspace-button');
  }

  @autobind
  async handleChangeOwnerClicked() {
    await this.props.workspaceService.getCurrentWorkspaceMembersAsync();
    this.toggleChangeOwnerModal('');
  }

  @autobind
  async handleChangeOwnerConfirmedAsync() {
    if (this.state.isLoading) return;

    await this.withLoaderAsync(async () => {
      if (this.state.ownerEmailStatus.isValid) {
        const result = await this.props.workspaceService.changeOwnerAsync(this.state.ownerEmail, this.state.workspaceToChangeOwner);

        if (result instanceof StickerError && result.apiErrorResponse!.errorCodes.includes('CAN_NOT_TAKEOVER_WORKSPACE')) {
          this.props.workspaceService.store.takeOverWorkspaceLimits = undefined;
          this.props.workspaceService.getTakeOverWorkspaceLimitsAsync(this.state.ownerEmail);
        } else {
          this.toggleChangeOwnerModal('');
          await this.props.userService.getUserInfoAsync();
        }
      }
    }, 'change-workspace-owner-button');
  }

  @autobind
  handleDeleteClicked() {
    this.setState({ deleteWorkspaceModalShown: true });
  }

  @autobind
  handleCancelDelete() {
    if (this.state.isLoading) return;

    this.setState({ deleteWorkspaceModalShown: false });
  }

  @autobind
  async handleConfirmDelete() {
    if (this.state.isLoading) return;

    this.withLoaderAsync(async () => {
      await this.props.workspaceService.deleteWorkspaceAsync(this.state.workspaceToDelete);
      this.setState({ deleteWorkspaceModalShown: false });
    }, 'delete-workspace-button');
  }

  @autobind
  handleOwnerEmailChange(email: string) {
    this.props.workspaceService.store.takeOverWorkspaceLimits = undefined;
    this.setState({
      ownerEmail: email,
      ownerEmailStatus: InputStatus.valid(),
    });
    this.props.workspaceService.getTakeOverWorkspaceLimitsAsync(email);
  }

  @autobind
  async handleOrderingChange(orderBy: string, orderType: string) {
    this.props.workspaceService.store.workspacesPaging.orderBy = orderBy;
    this.props.workspaceService.store.workspacesPaging!.orderType = orderType;
    await this.withLoaderAsync(() => this.props.workspaceService.getWorkspaceListItemsAsync());
  }

  @autobind
  async handlePaginationChange(pageNumber: number, pageSize: number) {
    this.props.workspaceService.store.workspacesPaging.pageNumber = pageNumber;
    this.props.workspaceService.store.workspacesPaging.pageSize = pageSize;
    this.context.setWorkspacesPageSize(pageSize);
    await this.withLoaderAsync(() => this.props.workspaceService.getWorkspaceListItemsAsync());
  }

  render() {
    const toggleRenameWorkspaceModalWithEmpty = () => {
      if (this.state.isLoading) return;
      this.toggleRenameWorkspaceModal('');
    };
    const toggleDeleteModalWithEmpty = () => {
      if (this.state.isLoading) return;
      this.toggleDeleteModal('');
    };
    const toggleChangeOwnerModalWithEmpty = () => {
      if (this.state.isLoading) return;
      this.toggleChangeOwnerModal('');
    };

    return (
      <>
        <RenameWorkspaceModal
          showRenameWorkspace={this.state.showRenameWorkspaceModal}
          renameWorkspaceName={this.state.renameWorkspaceName}
          renameWorkspaceNameStatus={this.state.renameWorkspaceInputStatus}
          toggleRenameWorkspace={toggleRenameWorkspaceModalWithEmpty}
          onNameChange={this.handleRenameWorkspaceNameChange}
          onRenameSave={this.renameWorkspace}
        />
        <DeleteWorkspaceConfirmationModal
          deleteWorkspaceModalShown={this.state.deleteWorkspaceModalShown}
          onConfirmDelete={this.handleConfirmDelete}
          onCancelDelete={toggleDeleteModalWithEmpty}
        />
        <ChangeWorkspaceOwnerModal
          showChangeOwner={this.state.showChangeOwnerModal}
          ownerEmail={this.state.ownerEmail}
          ownerEmailStatus={this.state.ownerEmailStatus}
          users={this.state.workspaceTeamMembers}
          takeoverWorkspaceLimits={this.props.workspaceService.store.takeOverWorkspaceLimits}
          onOwnerEmailChange={this.handleOwnerEmailChange}
          toggleOwnerModal={toggleChangeOwnerModalWithEmpty}
          onOwnerSave={this.handleChangeOwnerConfirmedAsync}
        />
        <UpdateWorkspaceTimeZoneModalContainer />
        <WorkspacesList
          isLoading={this.state.isLoading || this.props.workspaceService.store.showLoader}
          currentWorkspaceId={this.props.currentWorkspaceStore.currentWorkspace!.id}
          workspaces={this.props.workspaceService.store.workspaceListItems}
          onOrderingChange={this.handleOrderingChange}
          onPaginationChange={this.handlePaginationChange}
          pagination={{ ...this.props.workspaceService.store.workspacesPaging, pageSize: this.context.store.workspacesPageSize }}
          toggleChangeOwnerOnList={this.toggleChangeOwnerModal}
          toggleDeleteWorkspaceOnList={this.toggleDeleteModal}
          toggleRenameWorkspaceOnList={this.toggleRenameWorkspaceModal}
          toggleUpdateWorkspaceTimeZoneOnList={this.toggleUpdateWorkspaceTimeZoneModal}
          sorting={{
            orderBy: this.props.workspaceService.store.workspacesPaging.orderBy || '',
            orderType: this.props.workspaceService.store.workspacesPaging.orderType || '',
          }}
        />
      </>
    );
  }
}

export const WorkspacesListContainer = as<React.ComponentClass>(withRouter(WorkspacesListContainerPure));
