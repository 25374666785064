import { WithNamespaces, withNamespaces } from 'react-i18next';

import React from 'react';
import { S_IconButton } from '../../../../../../../../design/buttons/iconButton/S_IconButton';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

interface Props {
  onShowModal(): void;
  disabled?: boolean;
}

const EditMarkingToolButtonPure: React.FC<Props & WithNamespaces> = props => (
  <S_IconButton onClick={props.onShowModal} title={props.t('edit')} icon={faEdit} disabled={props.disabled} />
);

export const EditMarkingToolButton = withNamespaces()(EditMarkingToolButtonPure);
