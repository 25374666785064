import * as React from 'react';

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { IProjectAvailableDatasetDto, IProjectDataset } from '../projectDetails.models';
import { IProjectDetailsBl, ProjectDetailsBlType } from '../projectDetails.bl';
import { IProjectDetailsPermissions, ProjectDetailsPermissionsType } from '../projectDetails.permissions';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { DatasetStatus } from '../../datesets/datasetStatus.model';
import { ProjectDatasetsForm } from '../components/ProjectDatasetsForm';
import { ProjectDetailsDatasetsList } from '../components/ProjectDetailsDatasetsList';
import { ProjectImageAssignmentPolicyFormContainer } from './ProjectImageAssignmentPolicyForm.container';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ projectId: string }> {
  projectDetailsBl: IProjectDetailsBl;
  permissions: IProjectDetailsPermissions;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

interface IState extends ILoaderState {
  selectedDatasetId?: string;
}

@injectProps({
  projectDetailsBl: ProjectDetailsBlType,
  permissions: ProjectDetailsPermissionsType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
@observer
class ProjectDetailsDatasetsContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  componentDidMount() {
    this.refreshState();
    this.withLoaderAsync(async () => {
      await this.props.projectDetailsBl.getProjectDetailsDatasetsAsync(this.props.match.params.projectId);
    });
  }

  refreshState() {
    this.setState({ selectedDatasetId: undefined });
  }

  @autobind
  nonProjectDatasets(): IProjectAvailableDatasetDto[] {
    const projectDatasets = this.props.projectDetailsBl.store.datasets;
    return this.props.projectDetailsBl.store.availableDatasets.filter(ds => projectDatasets.every(pds => pds.id !== ds.id)).sort((a, b) => a.name.localeCompare(b.name));
  }

  projectDatasets(): IProjectDataset[] {
    return this.props.projectDetailsBl.store.datasets;
  }

  @autobind
  async handleDatasetUnlinkedAsync(datasetId: string) {
    await this.withLoaderAsync(async () => {
      await this.props.projectDetailsBl.unlinkDatasetAsync(datasetId);
      this.refreshState();
    });
  }

  @autobind
  async handleSelectedDatasetChanged(selectedDatasetId: string) {
    this.setState({ selectedDatasetId });
  }

  @autobind
  async onDatasetAddedAsync() {
    await this.withLoaderAsync(async () => {
      if (this.state.selectedDatasetId === undefined) return;
      await this.props.projectDetailsBl.assignDatasetToProjectAsync(this.state.selectedDatasetId);
      this.refreshState();
    }, 'add-dataset-button');
  }

  @autobind
  async handleDatasetOrderChangeAsync(from: number, to: number) {
    await this.props.projectDetailsBl.changeDatasetOrderAsync(from, to);
  }

  render() {
    const canViewDataset = this.props.permissions.canViewDataset();
    const canUnlinkDataset = this.props.permissions.canUnlinkDataset();

    return (
      <>
        <ProjectDatasetsForm
          workspaceId={this.props.currentWorkspaceStore.currentWorkspace!.id}
          projectId={this.props.projectDetailsBl.store.id}
          selectedDatasetId={this.state.selectedDatasetId}
          projectHasDraftDatasets={this.props.projectDetailsBl.store.datasets.some(d => d.status === DatasetStatus.DRAFT)}
          datasets={this.nonProjectDatasets()}
          onDatasetAddedAsync={this.onDatasetAddedAsync}
          onSelectedDatasetChanged={this.handleSelectedDatasetChanged}
        />
        <ProjectImageAssignmentPolicyFormContainer />
        <ProjectDetailsDatasetsList
          workspaceId={this.props.currentWorkspaceStore.currentWorkspace!.id}
          projectDatasets={this.props.projectDetailsBl.store.datasets}
          isLoading={this.state.isLoading}
          onDatasetUnlinked={this.handleDatasetUnlinkedAsync}
          canViewDataset={canViewDataset}
          canUnlinkDataset={canUnlinkDataset}
          onDatasetOrderChange={this.handleDatasetOrderChangeAsync}
          canEditDatasetOrder={this.props.permissions.canEditDatasetOrder()}
        />
      </>
    );
  }
}

export const ProjectDetailsDatasetsContainer = as<React.ComponentClass>(withRouter(ProjectDetailsDatasetsContainerPure));
