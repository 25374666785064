import * as React from 'react';

import { Button, Col, CustomInput, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { IPlanInfo, Plan } from '../../user/user.store';
import { handleCheck, handleNumberChange, handleSelectChange } from '../../../helpers/formHelpers';

import { ITranslatable } from '../../../helpers/translations.helpers';
import { InputStatus } from '../../../models/error.model';
import Select from 'react-select';
import { ValidatedTextInput } from '../../../components/ValidatedTextInput';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IChangeUserPlanModalProps {
  changeUserPlanModalShown: boolean;
  planInfo: IPlanInfo;
  planDefaults: IPlanInfo[];
  imagesAmountStatus: InputStatus;
  availableSpaceStatus: InputStatus;
  workspacesStatus: InputStatus;
  workspaceUsersStatus: InputStatus;
  workspaceWorkersStatus: InputStatus;
  onNumericInputBlur(name: string): void;
  onPlanChange(plan: Plan): void;
  onCustomLimitsChange(value: boolean): void;
  onLimitToggle(name: string, unlimited: boolean): void;
  onLimitChange(name: string, value?: number): void;
  onReset(): void;
  onChangePlanClicked(id: string): void;
  onSavePlanClicked(): void;
}

interface INumericInputWithToggle {
  disabled?: boolean;
  name: string;
  value?: number;
  default?: number;
  checked: boolean;
  inputStatus: InputStatus;
  onValueChange(value?: number): void;
  onToggle(value: boolean): void;
  onBlur(): void;
}

const UNLIMITED = -1;

const NumericInputWithTogglePure = (props: INumericInputWithToggle & ITranslatable) => {
  return (
    <Row className="change-plan-numeric-with-toggle">
      <Col xs={8}>
        <ValidatedTextInput
          type="text"
          id={`${props.name}-input`}
          labelText={props.t(props.name)}
          min={1}
          value={props.value && props.value !== UNLIMITED ? props.value : ''}
          isValid={props.value && props.value === UNLIMITED ? undefined : props.inputStatus.isValid}
          onChange={handleNumberChange(props.onValueChange)}
          disabled={props.disabled || props.value === UNLIMITED}
          feedbacks={props.inputStatus.errorCodes}
          onBlur={props.onBlur}
          styleClassName={props.disabled ? 'disabled-input' : ''}
        />
      </Col>
      <Col xs={4}>
        <br />
        <FormGroup>
          <CustomInput
            type="checkbox"
            id={`${props.name}-unlimited-checkbox`}
            checked={props.checked}
            onChange={handleCheck(props.onToggle)}
            label={props.t('unlimited')}
            disabled={props.disabled}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

const NumericInputWithToggle = as<React.SFC<INumericInputWithToggle>>(withNamespaces('common', { wait: true })(NumericInputWithTogglePure));

const ChangeUserPlanModal = (props: IChangeUserPlanModalProps & ITranslatable) => {
  if (!props.planInfo) return null;

  const handleToggle = () => props.onChangePlanClicked('');

  const handleUnlimitedToggle = (name: string) => (unlimited: boolean) => props.onLimitToggle(name, unlimited);
  const handleLimitChange = (name: string) => (value?: number) => props.onLimitChange(name, value);
  const handleReset = () => props.onReset();

  const planDefaults = props.planDefaults.find(p => p.plan === (props.planInfo.plan || 'Starter'))!;

  const getOnBlur = (name: string) => () => props.onNumericInputBlur(name);

  return (
    <Modal className="modal-width-600" isOpen={props.changeUserPlanModalShown} toggle={handleToggle}>
      <ModalHeader toggle={handleToggle}>{props.t('edit_plan_and_limits')}</ModalHeader>
      <Form className="custom-inline-form">
        <ModalBody>
          <div>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>{props.t('active_plan')}</Label>
                  <Select
                    id="user-change-plan-dropdown"
                    value={{ label: props.t(props.planInfo.plan), value: props.planInfo.plan }}
                    onChange={handleSelectChange((value: Plan) => props.onPlanChange(value))}
                    options={[Plan.Starter, Plan.Professional, Plan.Enterprise].map(r => ({ label: props.t(r), value: r }))}
                    classNamePrefix="custom-select"
                  />
                </FormGroup>
                <hr />
                <FormGroup className="checkbox">
                  <CustomInput
                    type="checkbox"
                    id="user-change-plan-custom-limits-checkbox"
                    checked={props.planInfo.customLimits}
                    onChange={handleCheck(props.onCustomLimitsChange)}
                    label={props.t('custom_limits')}
                  />
                </FormGroup>
                <NumericInputWithToggle
                  name="imagesAmount"
                  checked={props.planInfo.imagesAmount === -1}
                  value={props.planInfo.imagesAmount}
                  default={planDefaults.imagesAmount}
                  disabled={!props.planInfo.customLimits}
                  inputStatus={props.imagesAmountStatus}
                  onToggle={handleUnlimitedToggle('imagesAmount')}
                  onValueChange={handleLimitChange('imagesAmount')}
                  onBlur={getOnBlur('imagesAmount')}
                />
                <NumericInputWithToggle
                  name="availableSpace"
                  checked={props.planInfo.availableSpace === -1}
                  value={props.planInfo.availableSpace}
                  default={planDefaults.availableSpace}
                  disabled={!props.planInfo.customLimits}
                  inputStatus={props.availableSpaceStatus}
                  onToggle={handleUnlimitedToggle('availableSpace')}
                  onValueChange={handleLimitChange('availableSpace')}
                  onBlur={getOnBlur('availableSpace')}
                />
                <NumericInputWithToggle
                  name="workspaces"
                  checked={props.planInfo.workspaces === -1}
                  value={props.planInfo.workspaces}
                  default={planDefaults.workspaces}
                  disabled={!props.planInfo.customLimits}
                  inputStatus={props.workspacesStatus}
                  onToggle={handleUnlimitedToggle('workspaces')}
                  onValueChange={handleLimitChange('workspaces')}
                  onBlur={getOnBlur('workspaces')}
                />
                <NumericInputWithToggle
                  name="workspaceUsers"
                  checked={props.planInfo.workspaceUsers === -1}
                  value={props.planInfo.workspaceUsers}
                  default={planDefaults.workspaceUsers}
                  disabled={!props.planInfo.customLimits}
                  inputStatus={props.workspaceUsersStatus}
                  onToggle={handleUnlimitedToggle('workspaceUsers')}
                  onValueChange={handleLimitChange('workspaceUsers')}
                  onBlur={getOnBlur('workspaceUsers')}
                />
                <NumericInputWithToggle
                  name="workspaceWorkers"
                  checked={props.planInfo.workspaceWorkers === -1}
                  value={props.planInfo.workspaceWorkers}
                  default={planDefaults.workspaceWorkers}
                  disabled={!props.planInfo.customLimits}
                  inputStatus={props.workspaceWorkersStatus}
                  onToggle={handleUnlimitedToggle('workspaceWorkers')}
                  onValueChange={handleLimitChange('workspaceWorkers')}
                  onBlur={getOnBlur('workspaceWorkers')}
                />
                <Button onClick={handleReset} disabled={!props.planInfo.customLimits} color="outline-secondary">
                  {props.t('reset_to_defaults')}
                </Button>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button id="change-button" color="success" className="mr-2" onClick={props.onSavePlanClicked}>
            {props.t('save')}
          </Button>
          <Button id="change-button-cancel" color="primary" className="btn-outline-primary" onClick={handleToggle}>
            {props.t('cancel')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default as<React.SFC>(withNamespaces('common', { wait: true })(ChangeUserPlanModal));
