import { LatLng } from 'leaflet';

/*
e1-------e2
|        |  \
|        |   \
|        |    \
p2-------t1----e3----p3
|                    |
|                    |
|                    |
p1-------------------X

rectangle p2, e1, e2, e3 is orientation marker drawn on top of first edge of rotated rectangle (p2-p3)

*/
export const getOrientationMarkerCoords = (p1: LatLng, p2: LatLng, p3: LatLng, zoom: number): LatLng[] => {
  // we assume that `p1` and `p2` are on the same straight and we need to calculate coords of a point p3 that is
  // located on the same straight and distance between `p2` and `p3` is `extensionsLength`
  // changeSign decides if p3 is "after" p2 or "before"
  const getExtensionCoords = (p1: LatLng, p2: LatLng, extensionLength: number, changeSign: boolean = false): LatLng => {
    const sign = Math.sign(p2.lng - p1.lng) * (changeSign ? -1 : 1);

    // our straight is vertical, so we only need to add extensionsLength to y coordinate (latitude) or extract it depending on change sign
    // this is a special case in which code below produces division by zero (because p1.lng - p2.lng = 0)
    if (sign === 0) return new LatLng(p2.lat + Math.sign(p2.lat - p1.lat) * extensionLength * (changeSign ? -1 : 1), p2.lng);

    const slope = (p1.lat - p2.lat) / (p1.lng - p2.lng);
    const x = p2.lng + sign * extensionLength * Math.sqrt(1 / (1 + slope * slope));
    const y = p2.lat + sign * slope * extensionLength * Math.sqrt(1 / (1 + slope * slope));
    return new LatLng(y, x);
  };

  const scale = zoom * 0.01;

  const p1Cloned = new LatLng(p1.lat, p1.lng);
  const p2Cloned = new LatLng(p2.lat, p2.lng);
  const p3Cloned = new LatLng(p3.lat, p3.lng);
  const p2p3Distance = Math.sqrt(Math.pow(p2.lat - p3.lat, 2) + Math.pow(p2.lng - p3.lng, 2));

  const height = 7 / scale; // p2 <-> e1 distance
  const bottomWidth = p2p3Distance < 21 / scale ? (p2p3Distance * 2) / 3 : Math.min(21 / scale, (p2p3Distance * 2) / 3); // p2 <-> e3 distance
  const topWidth = (bottomWidth * 2) / 3; // e1 <-> e2 distance

  const e1 = getExtensionCoords(p1Cloned, p2Cloned, height);
  const t1 = getExtensionCoords(p3Cloned, p2Cloned, topWidth, true);
  const e2 = new LatLng(t1.lat + (e1.lat - p2.lat), t1.lng + (e1.lng - p2.lng));
  const e3 = getExtensionCoords(p3Cloned, p2Cloned, bottomWidth, true);

  return [p2Cloned, e1, e2, e3];
};
