import * as React from 'react';

import { Datasets, IDatasetsContext } from '../datasets.context';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { INotificationsService, NotificationsServiceType } from '../../notifications/services/notifications.service';
import { IUserService, UserServiceType } from '../../user/user.service';
import { NotificationLevel, ToastNotification } from '../../notifications/models/notification.model';
import { Observer, observer } from 'mobx-react';
import { as, injectProps } from '../../../helpers/react.helpers';

import { DatasetDetails } from '../datasetDetails.context';
import { DatasetDetailsImageListControls } from '../components/DatasetDetailsImageListControls';
import { ListViewMode } from '../../user/user.store';
import { StickerError } from '../../../models/error.model';
import autobind from 'autobind-decorator';

interface IInjectedProps {
  userService: IUserService;
  notificationsService: INotificationsService;
}

interface IState extends ILoaderState {
  showDeleteConfirmation: boolean;
}

@injectProps({
  userService: UserServiceType,
  notificationService: NotificationsServiceType,
})
@observer
class DatasetDetailsImageListControlsContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  static contextType = DatasetDetails;
  declare context: React.ContextType<typeof DatasetDetails>;

  constructor(props: IInjectedProps) {
    super(props);

    this.state = {
      showDeleteConfirmation: false,
      isLoading: false,
    };
  }

  // tslint:disable-next-line:function-name
  UNSAFE_componentWillMount() {
    this.context.store.selectedImages.clear();
  }

  @autobind
  handleOrderByChange(orderBy: string) {
    this.context.store.detailsImagesPaging.orderBy = orderBy;
    this.doSort();
  }

  @autobind
  handleOrderDirectionChange(orderType: string) {
    this.context.store.detailsImagesPaging.orderType = orderType;
    this.doSort();
  }

  @autobind
  handleViewModeChange(mode: ListViewMode) {
    this.props.userService.setDatasetImageListViewMode(mode);
  }

  @autobind
  async doSort() {
    const { pageSize, pageNumber, orderBy, orderType } = this.context.store.detailsImagesPaging;

    await this.withLoaderAsync(async () => await this.context.service.getDatasetImagesAsync(this.context.route.match.params.datasetId, pageNumber, pageSize, orderBy, orderType));
  }

  handleBulkDelete = (datasets: IDatasetsContext) => async () => {
    this.toggleConfirmationModal();
    const { store, service } = this.context;
    await service.bulkDeleteImages(
      store.details.id,
      store.selectedImages.map(si => si.id),
    );
    datasets.store.refreshFromDetails(store.details);
  };

  @autobind
  toggleConfirmationModal() {
    this.setState((oldState: IState) => {
      return { showDeleteConfirmation: !oldState.showDeleteConfirmation };
    });
  }

  @autobind
  async handleDownloadImages() {
    const selectedImages = this.context.store.selectedImages;

    if (selectedImages.length === 0) return;
    if (selectedImages.length === 1 && this.context.service.isValidFileName(this.context.store.detailsImages.filter(i => i.id === selectedImages[0].id).pop()!.name)) {
      this.context.service.downloadImageAsync(selectedImages[0].id).then(result => {
        if (result instanceof StickerError) this.props.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'common:cant_download_image'));
      });
    } else {
      await this.context.service.downloadSelectedImagesAsync(
        this.context.route.match.params.datasetId,
        selectedImages.map(si => si.id),
      );
    }
  }

  render() {
    const store = this.context.service.store;
    const selectedImagesCount = store.selectedImages.length;
    const usedImagesCount = store.selectedImages.filter(si => si.hasAnnotation || si.hasLock).length || 0;

    return (
      <Datasets.Consumer>
        {datasets => (
          <Observer>
            {() => (
              <DatasetDetailsImageListControls
                selectedImagesCount={selectedImagesCount}
                usedImagesCount={usedImagesCount}
                userPermissions={this.props.userService.getUserPermissions()}
                viewMode={this.props.userService.data.datasetImageListViewMode || ListViewMode.List}
                orderBy={store.detailsImagesPaging.orderBy || ''}
                orderDirection={store.detailsImagesPaging.orderType || ''}
                showBulkDeleteConfirmation={this.state.showDeleteConfirmation}
                onViewModeChange={this.handleViewModeChange}
                onOrderByChange={this.handleOrderByChange}
                onOrderDirectionChange={this.handleOrderDirectionChange}
                onBulkDelete={this.handleBulkDelete(datasets)}
                toggleConfirmationModal={this.toggleConfirmationModal}
                downloadImages={this.handleDownloadImages}
              />
            )}
          </Observer>
        )}
      </Datasets.Consumer>
    );
  }
}

export const DatasetDetailsImageListControlsContainer = as<React.ComponentClass>(DatasetDetailsImageListControlsContainerPure);
