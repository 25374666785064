import './SegmentationWithActions.scss';

import { IObjectListSegmentation } from './ObjectsList.container';
import { MarkingToolIcon } from './MarkingToolIcon';
import { ObjectListActions } from './ObjectListActions';
import React from 'react';

export interface IMarkingToolItemProps {
  isReadOnly: boolean;
  objectListSegmentation: IObjectListSegmentation;
  onShowHideClick?(segmentation: IObjectListSegmentation): void;
  onDeleteClick?(segmentation: IObjectListSegmentation): void;
  onClick?(segmentation: IObjectListSegmentation): void;
  showActions?: boolean;
  index?: number;
}

export const SegmentationWithActions: React.FC<IMarkingToolItemProps> = ({
  isReadOnly,
  objectListSegmentation,
  index,
  showActions = true,
  onShowHideClick,
  onDeleteClick: onDeleteSegmentationClick,
  onClick,
}) => {
  const isVisible: boolean = objectListSegmentation.isVisible && objectListSegmentation.isMarkingToolTypeVisible;

  const handleShowHideClick = () => {
    if (onShowHideClick) onShowHideClick(objectListSegmentation);
  };
  const handleDeleteSegmentationClick = () => {
    if (onDeleteSegmentationClick) onDeleteSegmentationClick(objectListSegmentation);
  };
  const handleSegmentationClick = () => {
    if (onClick) onClick(objectListSegmentation);
  };

  const maxWidth = index !== undefined && index >= 100 ? 138 - (10 * index) / 100 : 128;

  return (
    <div key={objectListSegmentation.id} className={`segmentation ${onClick !== undefined ? 'clickable' : ''}`} onClick={handleSegmentationClick}>
      <div className="icon-with-name" title={objectListSegmentation.name}>
        <div className="marking-tool-icon">
          <MarkingToolIcon toolType={objectListSegmentation.feature.featureType} color={objectListSegmentation.feature.color} />
        </div>
        <div className={`${objectListSegmentation.isSelected ? 'active' : ''} marking-tool-name`} style={{ maxWidth }}>
          {objectListSegmentation.name}
        </div>
        {index && <span className={`${objectListSegmentation.isSelected ? 'active' : ''}`}>&nbsp; #{index}</span>}
      </div>
      {showActions && <ObjectListActions isDeleteVisible={!isReadOnly} isVisible={isVisible} onDeleteClick={handleDeleteSegmentationClick} onShowHideClick={handleShowHideClick} />}
    </div>
  );
};
