import { inject, injectable } from 'inversify';
import { action, observable } from 'mobx';
import uuid from 'uuid';
import { StickerError } from '../../../models/error.model';
import { ApiServiceType } from '../../../services/api.service';
import { IApiService } from '../../../services/api.service.base';
import { AnnotationServiceType, IAnnotationService } from '../../annotation/annotation.service';
import { IImage } from '../../annotation/annotations.interface';
import { AnnotationUiStoreType, IAnnotationUiStore } from '../../annotation/annotationUi.store';
import { FreeDrawSegmentationServiceType, IFreeDrawSegmentationService } from '../../annotation/freeDrawSegmentation.service';
import { QuestionModel } from '../../annotation/question.model';
import { AnnotationsStoreType, IAnnotationsStore } from '../../annotation/annotations.store';
import { AnnotationTypeBlType, IAnnotationTypeBl } from '../../annotation/submodules/annotationTypes/annotationType.bl';
import { AnnotationToolType } from '../../annotation/submodules/annotationTypes/models/annotationToolType';
import { IProjectDetailsToolsBl, IProjectDetailsToolsBlType } from '../sub/tools/projectDetailsTools.bl';
import { PreviewDefaultImage } from '../../../components/StaticImages';
import { DatasetStatus } from '../../datesets/datasetStatus.model';

export const ProjectPreviewServiceType = Symbol('PROJECT_PREVIEW_SERVICE');

export interface IProjectPreviewService {
  setupAsync(projectId: string): Promise<void>;
  dispose(): void;
}

@injectable()
export class ProjectPreviewService implements IProjectPreviewService {
  constructor(
    @inject(AnnotationServiceType) private readonly annotationService: IAnnotationService,
    @inject(AnnotationTypeBlType) private readonly annotationTypeBl: IAnnotationTypeBl,
    @inject(AnnotationUiStoreType) private readonly uiData: IAnnotationUiStore,
    @inject(ApiServiceType) private readonly apiService: IApiService,
    @inject(FreeDrawSegmentationServiceType) private readonly freeDrawSegmentationService: IFreeDrawSegmentationService,
    @inject(IProjectDetailsToolsBlType) private readonly projectDetailsToolsBl: IProjectDetailsToolsBl,
    @inject(AnnotationsStoreType) private readonly annotationsStore: IAnnotationsStore,
  ) { }

  @action
  dispose(): void {
    this.annotationTypeBl.previewFinished();
    this.annotationsStore.questions = [];
    this.annotationsStore.currentQuestions = [];
  }

  @action
  async setupAsync(projectId: string) {
    this.freeDrawSegmentationService.clear();
    this.annotationService.clearAnnotations();
    this.annotationsStore.image = undefined;
    this.uiData.changeIsImageLoading(true);

    await Promise.all([this.getRandomProjectImageAsync(projectId), this.projectDetailsToolsBl.getProjectDetailsAnnotationViewAsync(projectId)]);

    this.annotationTypeBl.previewStarted(
      this.projectDetailsToolsBl.store.annotationTypes.map(m => ({
        ...m,
        projectId,
        selectorType: (AnnotationToolType as any)[m.selectorType.toUpperCase()] as AnnotationToolType,
        isUsed: false,
      })),
    );

    this.annotationsStore.questions = this.projectDetailsToolsBl.store.questions.map(
      qd =>
        new QuestionModel(
          projectId,
          qd.id,
          qd.type,
          qd.isRequired,
          qd.questionText,
          qd.answers.map(a => ({
            id: a.id,
            text: a.text,
            selected: false,
          })),
          qd.scopes,
        ),
    );
    this.annotationService.setImageQuestionsAsCurrent();
  }

  setDefaultImage() {
    this.annotationsStore.image = this.getDefaultImage();
  }

  getDefaultImage = () =>
  ({
    id: uuid.v4(),
    name: 'image-preview.jpg',
    height: 1641,
    width: 1094,
    url: PreviewDefaultImage,
    lowQualityUrl: PreviewDefaultImage,
    size: 0.429,
    dataSetName: 'Preview',
    datasetStatus: DatasetStatus.PUBLISHED,
    isAssignedToCurrentUser: false,
    isAssigned: false,
    lockedByName: '',
    canAddAnnotations: true,
    annotations: undefined,
    isWaitingForReviewAfterReject: false
  } as IImage);

  async getRandomProjectImageAsync(projectId: string) {
    const result = await this.apiService.getAsync<IImage | StickerError>(`/Images/GetProjectDraftRandomImage/${projectId}`);

    if (!result || result instanceof StickerError) {
      this.setDefaultImage();
    } else {
      this.annotationsStore.image = observable({ ...result, url: '', lowQualityUrl: '' });

      this.startLoading(this.annotationsStore.image);
    }
  }

  async startLoading(item: IImage) {
    await Promise.all([this.loadLowQualityImage(item), this.loadImage(item)]);
  }

  async loadLowQualityImage(image: IImage) {
    const imageFile = await this.apiService.getImageAsync(this.apiService.getUrl(`/Images/GetImageThumbnail/${image.id}`));
    if (!(imageFile instanceof StickerError)) {
      image.lowQualityUrl = URL.createObjectURL(imageFile.blob);
    }
  }

  async loadImage(image: IImage) {
    const imageFile = await this.apiService.getImageAsync(this.apiService.getUrl(`/Images/GetImage/${image.id}`));
    if (!(imageFile instanceof StickerError)) {
      image.url = URL.createObjectURL(imageFile.blob);
    }
  }
}
