
import { injectable, inject } from 'inversify';
import { ConfigurationType, IConfiguration } from '../../configuration';
import { AuthStoreSetterType, IAuthStoreSetter } from '../modules/auth/auth.store';
import { BaseHubService } from './baseHub.service';

export const UploadsHubServiceType = Symbol('UPLOAD_HUB_SERVICE');

export interface IUploadsHubService {
  initializeAsync(): Promise<void>;
  startUploadAsync(datasetId: string): Promise<void>;
  finishUploadAsync(datasetId: string): Promise<void>;
}

const HUB_METHODS = {
  START_UPLOAD: 'StartUploadAsync',
  FINISH_UPLOAD: 'FinishUploadAsync',
};

@injectable()
export class UploadsHubService extends BaseHubService implements IUploadsHubService {
  constructor(
    @inject(ConfigurationType) config: IConfiguration,
    @inject(AuthStoreSetterType) auth: IAuthStoreSetter) {
    super(auth, config.uploadsHubUrl);
  }

  async startUploadAsync(datasetId: string): Promise<void> {
    await this.connectAsync();
    await this.connection.send(HUB_METHODS.START_UPLOAD, {
      datasetId,
    });
  }

  async finishUploadAsync(datasetId: string): Promise<void> {
    await this.connectAsync();
    await this.connection.send(HUB_METHODS.FINISH_UPLOAD, {
      datasetId,
    });
  }
}
