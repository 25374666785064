import { ExportDataFilter } from '../../projects/projects.model';
import uuid from 'uuid';

export interface IBaseNotification {
  id: string;
  date: string;
  popupType: NotificationPopupType;
  wasRead: boolean;
}

export interface IModalNotification extends IBaseNotification {
  level: NotificationLevel;
  body: string | ILocalizable;
  title?: string | ILocalizable;
}

export interface IToastNotification extends IBaseNotification {
  level: NotificationLevel;
  body: string | ILocalizable;
  title?: string | ILocalizable;
}

export interface IHubNotification extends IBaseNotification {
  type: NotificationType;
}

export interface IDatasetUploadCompletedNotification extends IHubNotification {
  datasetName: string;
}

export interface IAttachmentsUploadNotification extends IHubNotification {
  uploadedCount: number;
  failedFiles: string[];
}

export interface IDatasetUploadCompletedWithInfoNotification extends IHubNotification {
  datasetName: string;
  rejectedUrls: string[];
}

export interface IDatasetUploadFailedNotification extends IHubNotification {
  datasetName: string;
}

export interface IDatasetUploadAbortedNotification extends IHubNotification {
  datasetName: string;
}

export interface IPaymentPendingNotification extends IHubNotification {}

export interface IPaymentFailedNotification extends IHubNotification {}

export interface IAccountRechargedNotification extends IHubNotification {
  credits: number;
  newLimit: number;
}

export interface IBatchAnswerQuestionsFinishedNotification extends IHubNotification {}

export interface IBatchAnswerQuestionsFailedNotification extends IHubNotification {
  batchNumber: number;
  totalBatchesCount: number;
}

export interface IClarifiedNotification extends IHubNotification {
  projectName: string;
  imageName: string;
}

export interface IProjectExportFinishedNotification extends IHubNotification {
  projectName: string;
  dataFilterType: ExportDataFilter;
  projectExportId: string;
}

export interface ILocalizable {
  template: string;
  data?: object;
}

export enum NotificationLevel {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

export enum NotificationPopupType {
  Toast = 'Toast',
  Modal = 'Modal',
  NotificationCenter = 'NotificationCenter',
}

export enum NotificationType {
  DatasetUploadCompleted = 'DatasetUploadCompleted',
  DatasetCompletedWithInfo = 'DatasetCompletedWithInfo',
  DatasetUploadFailed = 'DatasetUploadFailed',
  PaymentPending = 'PaymentPending',
  PaymentFailed = 'PaymentFailed',
  AccountRecharged = 'AccountRecharged',
  DatasetUploadAborted = 'DatasetUploadAborted',
  AttachmentsUploadCompleted = 'AttachmentsUploadCompleted',
  AttachmentsUploadFailed = 'AttachmentsUploadFailed',
  SomeAttachmentsUploadFailed = 'SomeAttachmentsUploadFailed',
  BatchAnswerQuestionsFinished = 'BatchAnswerQuestionsFinished',
  BatchAnswerQuestionsFailed = 'BatchAnswerQuestionsFailed',
  ProjectExportFinished = 'ProjectExportFinished',
  Clarified = "Clarified",
}

abstract class BaseNotification implements IBaseNotification {
  id: string;
  date: string;
  wasRead: boolean;

  constructor(public popupType: NotificationPopupType) {
    this.id = uuid.v4();
    this.date = new Date().toISOString();
    this.wasRead = false;
  }
}

export class ModalNotification extends BaseNotification implements IModalNotification {
  constructor(public level: NotificationLevel, public body: string | ILocalizable, public title: string | ILocalizable) {
    super(NotificationPopupType.Modal);
  }
}

export class ToastNotification extends BaseNotification implements IToastNotification {
  constructor(public level: NotificationLevel, public body: string | ILocalizable, public title?: string | ILocalizable) {
    super(NotificationPopupType.Toast);
  }
}

export class HubNotification extends BaseNotification implements IHubNotification {
  constructor(public type: NotificationType) {
    super(NotificationPopupType.NotificationCenter);
  }
}

export class DatasetUploadCompletedNotification extends HubNotification implements IDatasetUploadCompletedNotification {
  constructor(public datasetName: string) {
    super(NotificationType.DatasetUploadCompleted);
  }
}

export class DatasetUploadCompletedWithInfoNotification extends HubNotification implements IDatasetUploadCompletedWithInfoNotification {
  constructor(public datasetName: string, public rejectedUrls: string[]) {
    super(NotificationType.DatasetCompletedWithInfo);
  }
}

export class AttachmentsUploadNotification extends HubNotification implements IAttachmentsUploadNotification {
  constructor(public failedFiles: string[], public uploadedCount: number) {
    super(
      uploadedCount === 0
        ? NotificationType.AttachmentsUploadFailed
        : failedFiles.length === 0
        ? NotificationType.AttachmentsUploadCompleted
        : NotificationType.SomeAttachmentsUploadFailed,
    );
  }
}

export class DatasetUploadFailedNotification extends HubNotification implements IDatasetUploadFailedNotification {
  constructor(public datasetName: string) {
    super(NotificationType.DatasetUploadFailed);
  }
}

export class DatasetUploadAbortedNotification extends HubNotification implements IDatasetUploadAbortedNotification {
  constructor(public datasetName: string) {
    super(NotificationType.DatasetUploadAborted);
  }
}

export class PolicyLimitExceededNotification extends ToastNotification {
  constructor() {
    super(NotificationLevel.ERROR, 'common:policy_limit_exceeded_body', '');
  }
}

export class ClarifiedNotification extends HubNotification implements IClarifiedNotification {
  constructor(public projectName: string, public imageName: string) {
    super(NotificationType.Clarified);
  }
}
