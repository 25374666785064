import { AttachmentsServiceType, IAttachmentsService } from '../attachments.service';
import { IRouterStore, RouterStoreType } from '../../../stores/router.store';
import { IUserStore, UserStoreType } from '../../user/user.store';
import { InputStatus, StickerError } from '../../../models/error.model';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { AttachmentAddFolder } from '../components/AttachmentAddFolder';
import { AttachmentType } from '../attachments.store';
import React from 'react';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ attachmentId: string }> {
  attachmentsService: IAttachmentsService;
  userStore: IUserStore;
  router: IRouterStore;
}

interface IState {
  isModalOpen: boolean;
  folderName: string;
  folderNameStatus: InputStatus;
}

@injectProps({
  attachmentsService: AttachmentsServiceType,
  userStore: UserStoreType,
  router: RouterStoreType,
})
@observer
class AttachmentAddFolderContainerPure extends React.Component<IInjectedProps, IState> {
  constructor(props: IInjectedProps) {
    super(props);

    this.state = {
      isModalOpen: false,
      folderName: '',
      folderNameStatus: InputStatus.empty(),
    };
  }

  @autobind
  handleFolderNameChange(folderName: string) {
    const folderNames = this.props.attachmentsService.store.attachments.filter(a => a.type === AttachmentType.FOLDER).map(a => a.name.toLocaleUpperCase());
    const nameTaken = folderNames.includes(folderName.trim().toLocaleUpperCase());

    this.setState({
      folderName,
      folderNameStatus: nameTaken ? InputStatus.buildFrom(['DIRECTORY_ALREADY_EXISTS']) : InputStatus.empty(),
    });
  }

  @autobind
  async handleAddFolder() {
    const result = await this.props.attachmentsService.addAttachmentFolderAsync(
      this.state.folderName,
      this.props.match.params.attachmentId,
    );

    if (result instanceof StickerError) {
      this.setState({ folderNameStatus: InputStatus.buildFrom(result.apiErrorResponse!.errorCodes) });
    } else {
      this.setState(({ isModalOpen: false, folderName: '' }));
    }
  }

  toggleModal = () => {
    this.setState((prev: IState) => ({ isModalOpen: !prev.isModalOpen, folderName: '' }));
  }

  render() {
    const canView = this.props.attachmentsService.canUseAttachments();
    return (
      <AttachmentAddFolder
        canView={canView}
        isModalOpen={this.state.isModalOpen}
        folderName={this.state.folderName}
        toggleModal={this.toggleModal}
        onFolderNameChange={this.handleFolderNameChange}
        onAddFolderConfirmClicked={this.handleAddFolder}
        folderNameStatus={this.state.folderNameStatus}
      />
    );
  }
}

export const AttachmentAddFolderContainer = as<React.ComponentClass>(withRouter(AttachmentAddFolderContainerPure));
