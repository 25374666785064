import React from 'react';
import { S_FieldLabel } from '../../labels/fieldLabel/S_FIeldLabel';
import { S_IdProps } from '../../CommonProps';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';

interface S_SelectFieldProps<T> extends S_IdProps {
  labelText: string;
  options: S_SelectFieldOption<T>[];
  value: S_SelectFieldOption<T>;
  onChange(value: T): void;
  disabled?: boolean;
}

export type S_SelectFieldOption<T> = { label: string; value: T };

export const S_SelectField = <T,>(props: S_SelectFieldProps<T>) => {
  const handleChange = (selectedOption: ValueType<S_SelectFieldOption<T>>) => {
    const selectedFieldOption = selectedOption as S_SelectFieldOption<T>;
    props.onChange(selectedFieldOption?.value);
  };

  return (
    <div>
      <S_FieldLabel text={props.labelText} />
      <Select
        id={props.id}
        defaultValue={props.options[0]}
        value={props.value}
        onChange={handleChange}
        options={props.options}
        classNamePrefix="custom-select"
        isDisabled={props.disabled}
      />
    </div>
  );
};
