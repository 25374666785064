import { ContainerModule, interfaces } from 'inversify';
import { PredictionImagesPreviewService, PredictionImagesPreviewServiceType } from './predictionImagesPreview.service';
import { PredictionImagesPreviewStore, PredictionImagesPreviewStoreType } from './predictionImagesPreview.store';
import { PredictionImagesPreviewApiService, PredictionImagesPreviewApiServiceType } from './predictionImagesPreviewApi.service';

export const predictionImagesModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<PredictionImagesPreviewService>(PredictionImagesPreviewServiceType).to(PredictionImagesPreviewService).inSingletonScope();
  bind<PredictionImagesPreviewApiService>(PredictionImagesPreviewApiServiceType).to(PredictionImagesPreviewApiService).inSingletonScope();
  bind<PredictionImagesPreviewStore>(PredictionImagesPreviewStoreType).to(PredictionImagesPreviewStore).inSingletonScope();
});
