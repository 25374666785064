import * as React from 'react';

import { AttachmentType, IAttachment } from '../attachments.store';
import { CustomInput, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { ExtensionDefinitions, FileExtensionDefinition } from '../../../helpers/fileExtensions.helpers';
import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faEllipsisV, faTrashAlt, faUnlink } from '@fortawesome/free-solid-svg-icons';
import { faFile, faFolderOpen } from '@fortawesome/free-regular-svg-icons';

import { ClampLines } from '../../../components/ClampLines';
import { EnableForRole } from '../../../containers/EnableForRole';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Home } from '../../../routes/config/Home';
import { LazyImage } from '../../../components/LazyImage';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { RenameAttachmentDropdownItemContainer } from '../../../../modules/attachments/rename/ui/dropdownItem/RenameAttachmentDropdownItem.container';
import { TranslationFunction } from 'i18next';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import autobind from 'autobind-decorator';
import { bytesToMBsOrGBs } from '../../../helpers/string.helper';
import { dinduNuffin } from '../../../helpers/function.helpers';
import { disableTextMarkingsForClass } from '../../../helpers/react.helpers';
import { handleClickAndPassData } from '../../../helpers/formHelpers';
import { toLocaleDateTimeString } from '../../../helpers/date.helpers';

library.add(faUnlink, faEdit, faFile, faFolderOpen);

export interface IAttachmentThumbnailProps {
  attachment: IAttachment;
  authToken: string;
  isChecked: boolean;
  workspaceId: string;
  disabled: boolean;
  previewDisabled: boolean;
  t: TranslationFunction;
  onCheck(id: string, isBatch: boolean): void;
  onClick(id: string): void;
  onDelete(id: string): void;
  getThumbnailUrl(id: string): string;
}

export class AttachmentThumbnail extends React.Component<IAttachmentThumbnailProps> {
  componentDidMount() {
    disableTextMarkingsForClass('custom-control-label');
  }

  renderAttachmentImage() {
    switch (this.props.attachment.type) {
      case AttachmentType.IMAGE_FILE:
        const image = (
          <LazyLoadComponent threshold={300}>
            <LazyImage source={this.props.getThumbnailUrl(this.props.attachment.id)} headers={new Headers({ Authorization: `Bearer ${this.props.authToken}` })} />
          </LazyLoadComponent>
        );

        return this.props.previewDisabled ? (
          image
        ) : (
          <Link className="text-link-in-table" to={this.getPreviewUrl()}>
            {image}
          </Link>
        );
      case AttachmentType.FOLDER:
        return <FontAwesomeIcon icon={faFolderOpen} />;
      case AttachmentType.FILE:
        const icon = this.getFileTypeAttachmentIcon();
        return <FontAwesomeIcon icon={icon} />;
      default:
        return <FontAwesomeIcon icon={faFile} />;
    }
  }

  getPreviewUrl() {
    return Home.Attachments.List.Preview.withParams({
      attachmentId: this.props.attachment.id,
      workspaceId: this.props.workspaceId,
      parentId: this.props.attachment.parentId || '',
    });
  }

  @autobind
  getFileTypeAttachmentIcon(): IconDefinition {
    const fileExtension = this.props.attachment.name.split('.').pop();
    let icon = faFile;

    if (!fileExtension) return icon;

    ExtensionDefinitions.forEach((ed: FileExtensionDefinition) => {
      if (ed.extensions.includes(fileExtension)) {
        icon = ed.icon;
      }
    });

    return icon;
  }

  renderName = () => {
    let divProps: any = {};

    if (!this.props.disabled && !this.props.previewDisabled) {
      divProps = { onClick: handleClickAndPassData(this.props.onClick)(this.props.attachment.id) };
    } else {
      divProps = { className: 'disabled' };
    }

    return (
      <div {...divProps}>
        <ClampLines lines={3} text={this.props.attachment.name} />
      </div>
    );
  };

  renderSize = () => (
    <>
      {this.props.attachment.type === AttachmentType.FOLDER
        ? `${bytesToMBsOrGBs(this.props.attachment.size)} (${this.props.attachment.items} ${this.props.t('items')})`
        : bytesToMBsOrGBs(this.props.attachment.size)}
    </>
  );

  getOnChange = (id: string) => (e: any) => {
    this.props.onCheck(id, e.nativeEvent.shiftKey);
  };

  stopPropagation(e: { stopPropagation(): void }) {
    e.stopPropagation();
  }

  render() {
    return (
      <div className="thumb">
        <div className={`image-container ${this.props.disabled || this.props.previewDisabled ? 'disabled' : ''}`}>
          <div
            className={`${this.props.attachment.type !== AttachmentType.IMAGE_FILE ? 'icon' : ''}`}
            onClick={this.props.disabled || this.props.previewDisabled ? dinduNuffin : handleClickAndPassData(this.props.onClick)(this.props.attachment.id)}
          >
            {this.renderAttachmentImage()}
          </div>
          <div>
            <UncontrolledDropdown className="thumb-dropdown">
              <DropdownToggle>
                <FontAwesomeIcon icon={faEllipsisV} />
              </DropdownToggle>
              <DropdownMenu>
                <RenameAttachmentDropdownItemContainer id={this.props.attachment.id} name={this.props.attachment.name} parentId={this.props.attachment.parentId} />
                <EnableForRole workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager]} projectRoles={[]}>
                  <DropdownItem disabled={this.props.disabled} onClick={handleClickAndPassData(this.props.onDelete)(this.props.attachment.id)} className="red">
                    <FontAwesomeIcon icon={faTrashAlt} /> {this.props.t('attachment:delete_attachment')}
                  </DropdownItem>
                </EnableForRole>
              </DropdownMenu>
            </UncontrolledDropdown>
            <div className="thumb-checkbox" onClick={this.getOnChange(this.props.attachment.id)} title={this.props.attachment.name}>
              <CustomInput
                checked={this.props.isChecked}
                onChange={this.stopPropagation}
                onClick={this.stopPropagation}
                value={this.props.attachment.id}
                id={this.props.attachment.id}
                disabled={this.props.disabled}
                className={`${this.props.isChecked ? 'visible' : ''}`}
                type="checkbox"
              />
            </div>
          </div>
        </div>
        <div className="description">
          <div className="file-name">{this.renderName()}</div>
        </div>
        <div className="file-details">
          <div className="detail">
            {this.props.t('created')}:<span className={'detail-value'}> {toLocaleDateTimeString(this.props.attachment.createdDate)}</span>
          </div>
          <div className="detail">
            {this.props.t('size')}:<span className={'detail-value'}> {this.renderSize()}</span>
          </div>
          {this.props.attachment.author && (
            <div className="detail">
              {this.props.t('author')}:
              <span className={'detail-value'} title={this.props.attachment.author}>
                {' '}
                {this.props.attachment.author}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
