import { injectable, inject } from 'inversify';
import { action, runInAction } from 'mobx';
import uuid from 'uuid';
import { StickerError } from '../../models/error.model';
import { IAnswerModel, IQuestionModel, QuestionModel, QuestionType } from '../annotation/question.model';
import { IAnnotationDto, IImage, ISegmentation, IQuestionModelDto, ISegmentationDto, AnnotationStatus } from '../annotation/annotations.interface';
import { BaseAnnotationControl, IBaseAnnotationControl } from '../annotation/baseAnnotationControl';
import { NotificationsServiceType, INotificationsService } from '../notifications/services/notifications.service';
import { AnnotationApiServiceType, IAnnotationApiService } from '../annotation/annotationApi.service';
import { AnnotationServiceType, IAnnotationService } from '../annotation/annotation.service';
import { AnnotationUiStoreType, IAnnotationUiStore } from '../annotation/annotationUi.store';
import { RouterStoreType, IRouterStore } from '../../stores/router.store';
import { ProjectHubServiceType, IProjectHubService } from '../../services/projectHub.service';
import { ToastNotification, NotificationLevel } from '../notifications/models/notification.model';
import { WorkspaceRole } from '../workspaces/workspaces.store';
import { FreeAccessImagesQueueService, FreeAccessImagesQueueServiceType } from './freeAccessImagesQueue.service';
import { IFreeAccessStore, FreeAccessStoreType, FreeAccessMode, AnnotateStage } from './freeAccess.store';
import { TimerServiceType, ITimerService } from '../../services/timer.service';
import { FreeAccessApiServiceType, IFreeAccessApiService } from './freeAccessApi.service';
import { FreeDrawSegmentationServiceType, IFreeDrawSegmentationService } from '../annotation/freeDrawSegmentation.service';
import { LatLngBounds } from 'leaflet';
import { IUndoRedoHistory, UndoRedoHistoryType } from '../annotation/undoRedoHistory.service';
import { AnnotationTypeBlType, IAnnotationTypeBl } from '../annotation/submodules/annotationTypes/annotationType.bl';
import { getLatLngsForGeojson } from '../../helpers/geometry/polygon.helpers';
import { IAnnotationsStore, AnnotationsStoreType } from '../annotation/annotations.store';
import { ReviewRejectionReasonBlType, IReviewRejectionReasonBl } from '../annotation/submodules/reviewRejectReasones/reviewRejectionReason.bl';
import { EventBusType, EventListeningDisposer, IEventBus } from '../../services/eventBus.service';
import { ClarificationAddedEvent, ClarificationAddedEventType } from '../annotation/submodules/clarifications/events/ClarificationAddedEvent';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { EditorMode } from '../../../modules/editor/models/EditorModes';
import { FreeAccessAnnotationApiServiceType, IFreeAccessAnnotationApiService } from '../../../modules/editor/services/FreeAccessAnnotationApi.service';
import { EditAndAcceptAnnotationRequest, EditAndRejectAnnotationRequest, IAcceptAnnotationRequest, IRejectAnnotationRequest } from '../../../modules/editor/models/Requests';
import { DatasetStatus } from '../datesets/datasetStatus.model';

export const FreeAccessServiceType = Symbol('FREE_ACCESS_SERVICE');

export interface IFreeAccessService extends IBaseAnnotationControl {
  initializeAsync(workspaceId: string, projectId: string, imageId: string, filterId: string, mode: FreeAccessMode): Promise<void>;
  rejectFixAnnotationAsync(): Promise<void>;
  unlockImagesAsync(): Promise<void>;
  updateLockImageInfoAsync(): Promise<void>;
  cancelCorrectAnnotationAsync(): Promise<void>;
  cancelReviewAnnotationAsync(): Promise<void>;
  cancelCreateAnnotationAsync(): Promise<void>;
  getProjectStatusAsync(projectId: string): Promise<void>;
  changeModeToEdit(): void;

  acceptAnnotationAsync(): Promise<void>;
  rejectAnnotationAsync(reason?: string): Promise<void>;
  editAndAcceptOrRejectAnnotationAsync(accept: boolean, rejectionReason?: string): Promise<void>;
  saveAnnotationDraftAsync(): Promise<void>;

  correctAnnotationAsync(): Promise<void>;
  correctAndSubmitAnnotationForReviewAsync(): Promise<void>;
  discardAnnotationAsync(editorMode: EditorMode): Promise<void>;
  editAnnotationAsync(): Promise<void>;
  submitAnnotationsForReviewAsync(): Promise<void>;

  changeModeAsync(mode: FreeAccessMode): Promise<void>;
}

@injectable()
export class FreeAccessService extends BaseAnnotationControl implements IFreeAccessService {
  private eventListenersDisposer: EventListeningDisposer[] = [];
  private projectId: string = '';
  private imageId: string = '';
  private filterId: string = '';

  constructor(
    @inject(AnnotationApiServiceType) private readonly annotationApiService: IAnnotationApiService,
    @inject(AnnotationServiceType) private readonly annotationService: IAnnotationService,
    @inject(AnnotationTypeBlType) private readonly annotationTypeBl: IAnnotationTypeBl,
    @inject(ProjectHubServiceType) private readonly projectHubService: IProjectHubService,
    @inject(RouterStoreType) private readonly routerStore: IRouterStore,
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore,
    @inject(FreeAccessStoreType) private readonly freeAccessStore: IFreeAccessStore,
    @inject(FreeAccessImagesQueueServiceType) private readonly freeAccessImagesQueueService: FreeAccessImagesQueueService,
    @inject(FreeAccessApiServiceType) private readonly freeAccessApiService: IFreeAccessApiService,
    @inject(TimerServiceType) private readonly timer: ITimerService,
    @inject(FreeDrawSegmentationServiceType) private readonly freeDrawSegmentationService: IFreeDrawSegmentationService,
    @inject(UndoRedoHistoryType) private readonly undoRedoService: IUndoRedoHistory,
    @inject(NotificationsServiceType) notificationsService: INotificationsService,
    @inject(AnnotationUiStoreType) uiStore: IAnnotationUiStore,
    @inject(AnnotationsStoreType) annotationsStore: IAnnotationsStore,
    @inject(ReviewRejectionReasonBlType) private readonly reviewRejectionReasonBl: IReviewRejectionReasonBl,
    @inject(EventBusType) private readonly eventBus: IEventBus,
    @inject(FreeAccessAnnotationApiServiceType) private readonly freeAccessAnnotationApiService: IFreeAccessAnnotationApiService,
  ) {
    super(uiStore, notificationsService, annotationsStore);
    this.imagesQueueService = freeAccessImagesQueueService;
  }

  private get mode(): FreeAccessMode {
    return this.freeAccessStore.freeAccessMode;
  }

  handleImageDisplayed = (image: IImage) => {};

  setupAsync(): Promise<void> {
    return Promise.resolve();
  }

  private addEventListeners() {
    this.clearEventListeners();
    this.eventListenersDisposer.push(this.eventBus.addListener<ClarificationAddedEvent>(this.clarificationAddedListenerAsync, ClarificationAddedEventType));
  }

  private clearEventListeners() {
    this.eventListenersDisposer.forEach(d => d());
    this.eventListenersDisposer = [];
  }

  dispose() {
    this.freeAccessStore.questions = [];
    this.annotationTypeBl.freeAccessFinished();
    this.clearEventListeners();
    super.dispose();
  }

  @action
  async initializeAsync(workspaceId: string, projectId: string, imageId: string, filterId: string, mode: FreeAccessMode) {
    if (imageId !== this.imageId) this.clearPreviousImageLockAsync();

    if (this.projectId !== projectId) {
      this.freeAccessStore.questions = [];
    }

    this.projectId = projectId;
    this.imageId = imageId;
    this.filterId = filterId;
    this.freeAccessStore.setView(mode);
    this.freeDrawSegmentationService.clear();
    this.annotationService.deselectSegmentation();
    this.annotationsStore.setSegmentations([]);
    this.annotationsStore.projectId = projectId;

    const shouldResetSelectedAnnotationType = this.mode === FreeAccessMode.VIEW || this.mode === FreeAccessMode.REVIEW;
    await this.annotationTypeBl.freeAccessStarted(projectId, shouldResetSelectedAnnotationType);

    if (this.annotationsStore.image && this.imageId !== this.annotationsStore.image.id) {
      runInAction(() => {
        this.imagesQueueService.clear();
        this.uiStore.changeIsImageLoading(true);
        this.annotationsStore.setImageName('', '', DatasetStatus.None);
        this.annotationsStore.image = undefined;
        this.cleanupAnnotations();
      });
    }

    await this.refreshQuestionsIfNeededAsync();

    await Promise.all([
      this.freeAccessImagesQueueService.setupAsync(workspaceId, projectId, imageId, filterId, mode),
      this.projectHubService.initializeAsync(),
      this.requestRejectionReasonsAsync(),
    ]);

    this.showImage();
    this.timer.startTimer();

    this.addEventListeners();
  }

  @action
  async changeModeAsync(mode: FreeAccessMode, annotateStage?: AnnotateStage) {
    this.freeAccessStore.setView(mode, annotateStage);

    const shouldResetSelectedAnnotationType = mode === FreeAccessMode.VIEW || mode === FreeAccessMode.REVIEW;
    await this.annotationTypeBl.freeAccessStarted(this.annotationsStore.projectId, shouldResetSelectedAnnotationType);

    await this.refreshQuestionsIfNeededAsync();

    await this.freeAccessImagesQueueService.setupAsync(this.currentWorkspaceStore.currentWorkspace!.id, this.annotationsStore.projectId, this.imageId, this.filterId, mode);

    this.showImage();
    this.timer.startTimer();
  }

  cleanupAnnotations() {
    this.freeDrawSegmentationService.clear();
    this.annotationsStore.id = undefined;
    this.annotationsStore.setSegmentations([]);
    this.annotationsStore.questions.forEach((q: IQuestionModel) => {
      q.answers.forEach(a => (a.selected = false));
      q.answer = undefined;
    });
  }

  @action
  loadAnnotations(image: IImage): void {
    const annotationInfoResult = image.annotations;

    if (!annotationInfoResult) {
      this.cleanupAnnotations();
      return;
    }

    const annotations = annotationInfoResult.annotations;
    if (annotations === undefined) throw new StickerError('No annotations found', undefined);
    this.annotationsStore.id = annotationInfoResult.id;
    const segmentations = annotations.segmentations.map((s: ISegmentationDto) => {
      const attributes = this.mapQuestions(s.feature.properties!.annotationTypeId, s.questions);
      const feature = {
        ...s.feature,
        id: uuid.v4(),
        color: this.annotationService.getAnnotationTypeColor(s.feature.properties!.annotationTypeId),
        featureType: s.feature.properties!.featureType,
      };

      const latlngs = getLatLngsForGeojson(feature);

      return {
        feature,
        latlngs,
        id: s.id,
        questions: attributes,
        bbox: new LatLngBounds(latlngs),
        priority: s.priority,
      } as ISegmentation;
    });

    this.annotationsStore.setSegmentations(segmentations.filter(s => s.feature.color !== ''));
    this.annotationsStore.questions = this.mapQuestions(undefined, annotationInfoResult.annotations.questions);
    this.annotationService.setImageQuestionsAsCurrent();
  }

  async requestRejectionReasonsAsync() {
    await this.reviewRejectionReasonBl.getReasons(this.projectId);
  }

  @action
  async changeModeToEdit() {
    await this.changeModeAsync(FreeAccessMode.REVIEWCORRECT);
  }

  mapQuestions(annotationTypeId: string | undefined, questionsDtos: IQuestionModelDto[]): IQuestionModel[] {
    const currentQuestions = annotationTypeId ? this.freeAccessStore.questions.filter(q => q.scopes.includes(annotationTypeId)) : this.freeAccessStore.questions;

    const models: IQuestionModel[] = [];
    for (const currentQuestion of currentQuestions) {
      const existingAnswer = questionsDtos.find(x => x.id === currentQuestion.id);

      const questionModel = new QuestionModel(
        currentQuestion.projectId,
        currentQuestion.id,
        currentQuestion.type as QuestionType,
        currentQuestion.isRequired,
        currentQuestion.text,
        currentQuestion.answers.map((x: IAnswerModel) => {
          return { id: x.id, selected: x.selected, text: x.text };
        }),
        currentQuestion.scopes,
        currentQuestion.answer,
      );

      questionModel.answers.forEach((x: IAnswerModel) => {
        const existing = existingAnswer?.answers.find(y => y.id === x.id);
        x.selected = existing?.selected || false;
      });
      questionModel.answer = existingAnswer?.answer;

      models.push(questionModel);
    }

    return models;
  }

  @action
  async requestQuestionsAsync(): Promise<QuestionModel[]> {
    const result = await this.annotationApiService.requestQuestionsAsync(this.projectId);
    if (result instanceof Error) throw result;
    return result;
  }

  async assignQuestions() {
    this.annotationsStore.questions = this.mapQuestions(undefined, this.freeAccessStore.questions);
    this.annotationService.setImageQuestionsAsCurrent();
  }

  @action
  async refreshQuestionsIfNeededAsync() {
    const isEmpty = this.freeAccessStore.questions.length === 0;

    if (isEmpty) {
      const questions = await this.requestQuestionsAsync();

      this.freeAccessStore.questions = questions;
      this.assignQuestions(); // no await
      return;
    }

    if (this.projectHubService.didQuestionsChangedFor(this.annotationsStore.projectId) || this.projectHubService.didAnnotationTypesChangedFor(this.annotationsStore.projectId)) {
      if (this.projectHubService.didQuestionsChangedFor(this.annotationsStore.projectId)) {
        const result = this.projectHubService.popNewQuestionsFor(this.annotationsStore.projectId);
        this.freeAccessStore.questions = [...result];
      }
    }
    this.assignQuestions(); // no await
  }

  @action.bound
  async rejectAnnotationAsync(reason: string = '') {
    if (this.freeAccessStore.freeAccessMode === FreeAccessMode.REVIEWCORRECT) {
      await this.editAndAcceptOrRejectAnnotationAsync(false, reason);
    } else {
      this.undoRedoService.clearDrawingHistory();
      this.undoRedoService.clearHistory();

      const request: IRejectAnnotationRequest = {
        reason,
        id: this.annotationsStore.id!,
        duration: this.timer.duration,
      };

      const result = await this.freeAccessAnnotationApiService.rejectAsync(request);

      this.handleResult(result);
      if (result === undefined) {
        if (reason) {
          this.reviewRejectionReasonBl.handleRejection(reason);
        }
        this.freeAccessStore.rejectionReason = reason;
      }
    }

    await this.changeModeAsync(FreeAccessMode.VIEW, AnnotateStage.REJECTED);
  }

  @action.bound
  async cancelCorrectAnnotationAsync(): Promise<void> {
    await this.changeModeAsync(FreeAccessMode.REVIEW);
  }

  @action.bound
  async cancelReviewAnnotationAsync(): Promise<void> {
    await this.annotationApiService.unlockImageAsync(this.freeAccessStore.imageId, this.freeAccessStore.projectId, false);
    await this.changeModeAsync(FreeAccessMode.VIEW);
  }

  @action.bound
  async cancelCreateAnnotationAsync(): Promise<void> {
    await this.annotationApiService.unlockImageAsync(this.freeAccessStore.imageId, this.freeAccessStore.projectId, false);
    await this.changeModeAsync(FreeAccessMode.VIEW);
  }

  @action.bound
  async discardAnnotationAsync(editorMode: EditorMode): Promise<void> {
    this.undoRedoService.clearDrawingHistory();
    this.undoRedoService.clearHistory();

    let result = undefined;
    const request = { id: this.annotationsStore.id!, duration: this.timer.duration };

    switch (editorMode) {
      case EditorMode.FREEACCESS_ANNOTATION:
        result = await this.freeAccessAnnotationApiService.discardDuringAnnotationAsync(request);
        break;
      case EditorMode.FREEACCESS_EDIT:
        result = await this.freeAccessAnnotationApiService.discardDuringFreeAccessEditAsync(request);
        break;
      case EditorMode.FREEACCESS_REVIEW:
        result = await this.freeAccessAnnotationApiService.discardDuringReviewAsync(request);
        break;
      case EditorMode.FREEACCESS_REVIEWEDIT:
        result = await this.freeAccessAnnotationApiService.discardDuringReviewEditAsync(request);
        break;
    }
    this.handleResult(result);
    await this.changeModeAsync(FreeAccessMode.VIEW, AnnotateStage.NOTANNOTATED);
  }

  @action.bound
  async acceptAnnotationAsync() {
    this.undoRedoService.clearDrawingHistory();
    this.undoRedoService.clearHistory();

    const request: IAcceptAnnotationRequest = {
      id: this.annotationsStore.id!,
      duration: this.timer.duration,
    };

    const result = await this.freeAccessAnnotationApiService.acceptAsync(request);

    this.handleResult(result);
    await this.changeModeAsync(FreeAccessMode.VIEW, AnnotateStage.REVIEWED);
  }

  async handleResult(result: void | StickerError) {
    if (result instanceof StickerError) {
      if (result.isBadRequestWithCode(['ANNOTATION_NOT_FOUND'])) {
        this.annotationsStore.image = undefined;
        this.imagesQueueService.areAnyImagesToLoad = false;
        this.notificationsService.push(new ToastNotification(NotificationLevel.WARNING, 'notifications:operation_was_unsuccessful_dataset_changed'));
      } else if (result.isBadRequestWithCode(['IMAGE_ALREADY_LOCKED'])) {
        this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, '', 'image_is_locked_by_another_user'));
      } else {
        this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, '', 'something_went_wrong'));
      }
    }
  }

  @action
  async showImage() {
    const image = this.imagesQueueService.takeImageFromQueue();
    if (image === undefined) return;

    if (this.annotationsStore.image && this.annotationsStore.image.id === image.id) {
      this.loadAnnotations(image);
      this.undoRedoService.clearHistory();
      return;
    }
    this.uiStore.changeIsImageLoading(true);
    this.annotationsStore.setImageName(image.name, image.dataSetName, image.datasetStatus);
    setTimeout(
      action(() => {
        this.annotationsStore.image = image;
        this.loadAnnotations(image);
      }),
      0,
    );
  }

  @action
  async editAndAcceptOrRejectAnnotationAsync(accept: boolean, rejectionReason?: string): Promise<void> {
    if (!(await this.freeDrawSegmentationService.clearAsync())) return;

    if (!this.annotationsStore.id || !this.annotationService.validateAnnotations()) {
      this.annotationTypeBl.handleSubmitFixInFreeAccess();
      return;
    }

    const annotations = {
      id: this.annotationsStore.id,
      questions: this.annotationsStore.questions,
      segmentations: this.annotationsStore.segmentations.map(s => ({
        id: s.id,
        questions: s.questions,
        feature: {
          type: s.feature.type,
          properties: s.feature.properties,
          geometry: s.feature.geometry,
        },
        priority: s.priority,
      })),
    } as IAnnotationDto;

    let result;

    if (accept) {
      result = await this.freeAccessAnnotationApiService.editAndAcceptAsync(new EditAndAcceptAnnotationRequest(annotations, this.timer.duration, this.annotationsStore.id!));
    } else {
      result = await this.freeAccessAnnotationApiService.editAndRejectAsync(
        new EditAndRejectAnnotationRequest(rejectionReason, annotations, this.timer.duration, this.annotationsStore.id!),
      );
    }

    this.undoRedoService.clearDrawingHistory();
    this.undoRedoService.clearHistory();

    if (result instanceof StickerError) {
      if (result.isBadRequestWithCode(['ANNOTATION_NOT_FOUND'])) {
        this.notificationsService.push(new ToastNotification(NotificationLevel.WARNING, 'notifications:operation_was_unsuccessful_dataset_changed'));
      } else if (result.isBadRequestWithCode(['IMAGE_ALREADY_LOCKED'])) {
        this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, '', 'image_is_locked_by_another_user'));
      } else {
        this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, '', 'something_went_wrong'));
      }
      return;
    }
    await this.changeModeAsync(FreeAccessMode.VIEW, AnnotateStage.REVIEWED);
  }

  @action
  async rejectFixAnnotationAsync() {
    await this.rejectAnnotationAsync();
  }

  @action
  async clearPreviousImageLockAsync() {
    if (this.imageId && this.projectId && this.mode !== FreeAccessMode.VIEW) {
      await this.annotationApiService.unlockImageAsync(this.imageId, this.projectId, false);
    }
  }

  @action
  async unlockImagesAsync() {
    if (!!this.projectId) {
      await this.annotationApiService.unlockUserImagesForProjectAsync(this.projectId);
    }
  }

  @action
  async submitAnnotationsForReviewAsync(): Promise<void> {
    if (!(await this.freeDrawSegmentationService.clearAsync())) return;
    if (!this.annotationService.validateAnnotations()) {
      this.annotationTypeBl.handleInvalidSubmitAnnotationInFreeAccess();
      return;
    }

    const id = this.annotationsStore.id || uuid.v4();
    const resultTask = await this.freeAccessAnnotationApiService.submitForReviewAsync({
      id,
      projectId: this.projectId,
      imageId: this.imageId,
      duration: this.timer.duration,
      annotation: this.mapAnnotationDto(id),
    });

    this.undoRedoService.clearDrawingHistory();
    this.undoRedoService.clearHistory();

    if (resultTask instanceof StickerError) {
      this.handleStickerErrors(resultTask);
      return;
    }

    this.refreshQuestionsIfNeededAsync();
    this.annotationTypeBl.handleSubmitAnnotationInFreeAccess();

    await this.changeModeAsync(FreeAccessMode.VIEW, AnnotateStage.ANNOTATED);
  }

  @action
  async saveAnnotationDraftAsync(): Promise<void> {
    this.freeDrawSegmentationService.clear();

    const id = this.annotationsStore.id ?? uuid.v4();

    const result = await this.freeAccessAnnotationApiService.saveDraftAsync({
      id,
      projectId: this.projectId,
      imageId: this.imageId,
      duration: this.timer.duration,
      annotation: this.mapAnnotationDto(id),
    });

    this.undoRedoService.clearDrawingHistory();
    this.undoRedoService.clearHistory();

    if (result instanceof StickerError) {
      this.handleStickerErrors(result);
      return;
    }

    await this.changeModeAsync(FreeAccessMode.VIEW, AnnotateStage.DRAFT);
  }

  handleStickerErrors(e: StickerError) {
    if (e.apiErrorResponse!.errorCodes.includes('NOT_ENOUGH_CREDITS')) {
      const currentWorkspace = this.currentWorkspaceStore.currentWorkspace;

      const message = currentWorkspace?.role === WorkspaceRole.Owner ? 'no_more_credits_description_owner' : 'no_more_credits_description';

      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, '', `common:${message}`));
      this.routerStore.push('/');
    }

    if (e.isBadRequestWithCode(['PROJECT_NOT_FOUND'])) {
      this.annotationsStore.image = undefined;
      this.imagesQueueService.areAnyImagesToLoad = false;
    } else if (e.isBadRequestWithCode(['IMAGE_ALREADY_LOCKED'])) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, '', 'image_is_locked_by_another_user'));
    } else {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, '', 'something_went_wrong'));
    }

    return;
  }

  @action.bound
  async correctAnnotationAsync(): Promise<void> {
    if (this.freeAccessStore.status !== AnnotationStatus.DRAFT) {
      this.freeDrawSegmentationService.clear();
      if (!this.annotationService.validateAnnotations()) {
        return;
      }
    }

    const result = await this.freeAccessAnnotationApiService.correctAsync({
      id: this.annotationsStore.id!,
      annotation: this.mapAnnotationDto(),
      duration: this.timer.duration,
    });

    this.undoRedoService.clearDrawingHistory();
    this.undoRedoService.clearHistory();

    if (result instanceof StickerError) return;

    await this.changeModeAsync(FreeAccessMode.VIEW);
  }

  @action.bound
  async editAnnotationAsync(): Promise<void> {
    if (this.freeAccessStore.status !== AnnotationStatus.DRAFT) {
      this.freeDrawSegmentationService.clear();
      if (!this.annotationService.validateAnnotations()) {
        return;
      }
    }

    const result = await this.freeAccessAnnotationApiService.editAsync({
      id: this.annotationsStore.id!,
      annotation: this.mapAnnotationDto(),
      duration: this.timer.duration,
    });

    this.undoRedoService.clearDrawingHistory();
    this.undoRedoService.clearHistory();

    if (result instanceof StickerError) return;

    await this.changeModeAsync(FreeAccessMode.VIEW);
  }

  @action.bound
  async correctAndSubmitAnnotationForReviewAsync(): Promise<void> {
    this.freeDrawSegmentationService.clear();
    if (!this.annotationService.validateAnnotations()) {
      return;
    }

    const result = await this.freeAccessAnnotationApiService.correctAndSubmitForReviewAsync({
      id: this.annotationsStore.id!,
      duration: this.timer.duration,
      annotation: this.mapAnnotationDto(),
    });

    this.undoRedoService.clearDrawingHistory();
    this.undoRedoService.clearHistory();

    if (result instanceof StickerError) return;

    await this.changeModeAsync(FreeAccessMode.VIEW, AnnotateStage.ANNOTATED);
  }

  @action.bound
  async updateLockImageInfoAsync() {
    const result = await this.freeAccessApiService.getImageLockInfo(this.freeAccessStore.projectId, this.freeAccessStore.imageId);
    if (result instanceof StickerError) {
      return;
    }

    this.freeAccessStore.lockedByName = result;
  }

  private mapAnnotationDto(id: string | undefined = undefined) {
    return {
      id: id || this.annotationsStore.id!,
      questions: this.annotationsStore.questions,
      segmentations: this.annotationsStore.segmentations.map(s => ({
        id: s.id,
        questions: s.questions,
        feature: {
          type: s.feature.type,
          properties: s.feature.properties,
          geometry: s.feature.geometry,
        },
        priority: 0,
      })),
    };
  }

  @action.bound
  private async clarificationAddedListenerAsync(event: ClarificationAddedEvent) {
    await this.changeModeAsync(FreeAccessMode.VIEW);
  }

  @action
  async getProjectStatusAsync(projectId: string): Promise<void> {
    const result = await this.annotationApiService.getProjectStatusAsync(projectId);
    this.freeAccessStore.projectStatus = result.projectStatus;
  }
}
