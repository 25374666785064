import * as React from 'react';

import { Col, Row } from 'reactstrap';

import { AnnotatingOverviewContainer } from '../../../../modules/projects/project/overview/AnnotatingOverview.container';
import { ClarificationsOverviewContainer } from '../../../../modules/projects/project/overview/ClarificationsOverview.container';
import { ProjectDetailsGlobalErrorsContainer } from '../../../modules/projectDetails/containers/ProjectOverviewErrors.container';
import { ReviewOverviewWidgetContainer } from '../../../../modules/projects/project/overview/ReviewOverview.container';
import { ToolStatisticsRefreshButtonContainer } from '../../../modules/projectDetails/sub/statistics/containers/ToolStatisticsRefreshButtonContainer';
import { ToolsStatistics } from '../../../modules/projectDetails/sub/statistics/components/ToolsStatistics';

export const ProjectDetailsOverviewView = () => (
    <div>
      <Row>
        <Col>
          <ProjectDetailsGlobalErrorsContainer />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex pr-2">
          <AnnotatingOverviewContainer />
        </Col>
        <Col className="d-flex pl-2">
          <ReviewOverviewWidgetContainer />
        </Col>
          <ClarificationsOverviewContainer />
      </Row>
      <Row>
        <Col>
          <ToolStatisticsRefreshButtonContainer />
        </Col>
      </Row>
      <Row>
        <Col>
          <ToolsStatistics />
        </Col>
      </Row>
    </div>
);
