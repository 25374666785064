import { Alert } from 'reactstrap';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../../../helpers/react.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IProps {
  isUpToDate: boolean;
}

@observer
export class OutOfDateMessagePure extends React.Component<ITranslatable & IProps> {

  render() {
    return (
      <>
        {!this.props.isUpToDate &&
          <Alert color="warning" className="out-of-date-message">
            {this.props.t('statisticts_dataset_unlinked')}
          </Alert>}
      </>
    );
  }
}

export const OutOfDateMessage = as<React.ComponentClass<IProps>>(withNamespaces('common', { wait: true })(OutOfDateMessagePure));
