import { IAnswerStatisticDto, ToolStatisticsViewMode } from '../projectDetailsStatistics.model';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../../../../modules/common/OverlayLoader.store';
import { IProjectDetailsStatisticsBl, ProjectDetailsStatisticsBlType } from '../projectDetailsStatistics.bl';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { AllStatusChart } from '../components/AllStatusChart';
import { AnnotationStatusChart } from '../components/AnnotationStatusChart';
import { AnswersChart } from '../components/AnswersChart';
import { CustomScrollbar } from '../../../../../components/CustomScrollbar';
import { EmptyStatistics } from '../components/EmptyStatistics';
import { Loader } from '../../../../../components/Loader';
import { OutOfDateMessage } from '../components/OutOfDateMessage';
import React from 'react';
import { handleClickAndPassData } from '../../../../../helpers/formHelpers';
import { observer } from 'mobx-react';

interface IInjectedProps {
  projectDetailsStatisticsBl: IProjectDetailsStatisticsBl;
  overlayLoader: IOverlayLoaderStore;
}

type Props = IInjectedProps;

@injectProps({
  projectDetailsStatisticsBl: ProjectDetailsStatisticsBlType,
  overlayLoader: OverlayLoaderStoreType,
})
@observer
class ToolsStatisticsContentContainerPure extends React.Component<Props> {
  render() {
    const store = this.props.projectDetailsStatisticsBl.store;

    if (store.toolStatisticsViewMode === ToolStatisticsViewMode.NONE) {
      return <EmptyStatistics />;
    }

    const toolStatistics = store.toolStatistics;
    const isUpToDate = toolStatistics.headers.isUpToDate;

    const toolStatisticsViewMode = store.toolStatisticsViewMode;
    const isLoading = this.props.overlayLoader.isSpinnerVisible('tool-statistics-loading') || false;
    const selectedToolStatistics = store.selectedToolStatistics;

    return (
      <div className="statistics">
        <Loader isLoading={isLoading} />
        <OutOfDateMessage isUpToDate={isUpToDate} />
        <CustomScrollbar autoHide>
          <div className="scrollable-container">
            {toolStatisticsViewMode === ToolStatisticsViewMode.All_TOOLS && <AllStatusChart annotationStatuses={toolStatistics.allAnnotationTypeStatistics} title={'all_tools'} />}
            {toolStatisticsViewMode === ToolStatisticsViewMode.ALL_QUESTIONS && (
              <AllStatusChart annotationStatuses={toolStatistics.allQuestionsStatistics} title={'all_questions'} />
            )}
            {toolStatisticsViewMode === ToolStatisticsViewMode.ALL_IMAGE_SETS && ( 
              <AllStatusChart annotationStatuses={toolStatistics.allImageSetsStatistics} title={'all_image_sets'} translateNames />
            )}
            {(toolStatisticsViewMode === ToolStatisticsViewMode.TOOL ||
              toolStatisticsViewMode === ToolStatisticsViewMode.QUESTION ||
              toolStatisticsViewMode === ToolStatisticsViewMode.IMAGE_SET) && (
              <>
                <AnnotationStatusChart annotationStatus={selectedToolStatistics?.annotationStatusStatistics} translateNames={toolStatisticsViewMode === ToolStatisticsViewMode.IMAGE_SET} />
                {selectedToolStatistics?.answerStatistics.map((as: IAnswerStatisticDto) => {
                  return (
                    <AnswersChart
                      key={as.id}
                      answerStatistics={as}
                      onGetAnswers={
                        toolStatisticsViewMode === ToolStatisticsViewMode.QUESTION
                          ? (handleClickAndPassData(this.props.projectDetailsStatisticsBl.getTextQuestionAnswers)(as.id) as any)
                          : (handleClickAndPassData(this.props.projectDetailsStatisticsBl.getTextAttributeAnswers)({
                              attributeId: as.id,
                              annotationTypeId: selectedToolStatistics?.annotationStatusStatistics.id,
                            }) as any)
                      }
                    />
                  );
                })}
              </>
            )}
          </div>
        </CustomScrollbar>
      </div>
    );
  }
}

export const ToolsStatisticsContentContainer = as<React.ComponentClass>(ToolsStatisticsContentContainerPure);
