import './CurrentWorkspaceSelectorOption.scss';

import { IWorkspace, WorkspaceRole } from '../../../../../../__legacy__/modules/workspaces/workspaces.store';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import React from 'react';
import { S_WorkspaceOwnerIcon } from '../../../../../../design/icons/S_WorkspaceOwnerIcon/S_WorkspaceOwnerIcon';

interface Props {
  workspace: IWorkspace;
}

const CurrentWorkspaceSelectorOptionPure: React.FC<Props & WithNamespaces> = props => {
  const encrypted = props.workspace.encryption?.encrypted;
  const locked = props.workspace.locked;
  const owned = props.workspace.role === WorkspaceRole.Owner;
  const label = props.workspace.name;

  const prefix = encrypted ? '🔒 ' : '';
  const suffix = locked ? props.t('workspaces.current.locked') : '';
  const className = `current-workspace-selector-option ${owned ? 'option-role-owner' : ''}`;
  return (
    <div className={className}>
      <S_WorkspaceOwnerIcon isMy={owned} notOwnerTitle={props.workspace.ownerEmail} />
      {`${prefix} ${label} ${suffix}`}
    </div>
  );
};
export const CurrentWorkspaceSelectorOption = withNamespaces('new')(CurrentWorkspaceSelectorOptionPure);
