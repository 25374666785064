import React from 'react';
import { as } from '../../helpers/react.helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../helpers/translations.helpers';
import { withNamespaces } from 'react-i18next';
import { IModelImagePredictionDto } from '../../../modules/modelDetails/modelDetails.model';
import { IEvaluationImagePredictionDto } from '../../../modules/evaluationDetails/evaluationDetails.model';
import classNames from 'classnames';

interface IProps {
  data: IModelImagePredictionDto | IEvaluationImagePredictionDto;
  readonly?: boolean;
  threshold: number;
}

const PredictionImagesPredictionsPure = (props: IProps & ITranslatable) => {
  const isScoreNull = props.data.metrics.score === null;
  const isBad = props.data.metrics.score! > props.threshold
  const anomalyClass = isBad ? 'rejected' : 'approved';
  const anomalyText = props.t(isBad ? 'annotation.bad' : 'annotation.good');

  return (
    <div className={classNames('predictions-view', {'annotation-tools-readonly-overlay': props.readonly})}>
        <div className="header">
          <FontAwesomeIcon icon="chart-bar" /> {props.t('metrics')}
        </div>
        <div className="predictions-view-inner">
          <div className="attributes">
            <span className="attribute">
              {props.t('score')}:<span className="annotation-status"> {props.data.metrics.score?.toFixed(3)}</span>
            </span>
          </div>
          <div className="attributes">
            <span className="attribute">
              {props.t('evaluated_as')}: {isScoreNull ? <span>{props.t('na')}</span> :<span className={`annotation-status ${anomalyClass}`}>{anomalyText}</span>}
            </span>
          </div>
        </div>
  </div>
  )
}


export const PredictionImagesPredictions = as<React.FC<IProps>>(withNamespaces('evaluations')(PredictionImagesPredictionsPure))
