import * as React from 'react';

import { AnnotationUiStoreType, IAnnotationUiStore } from '../../annotation/annotationUi.store';
import { AttachmentsPreviewServiceType, IAttachmentsPreviewService } from '../attachmentsPreview.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { DrawingContainer } from '../../annotation/containers/Drawing.container';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { Image } from '../../annotation/components/Image';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  annotationUiStore: IAnnotationUiStore;
  previewService: IAttachmentsPreviewService;
}

interface IState {
  currentZoom: number;
}

type IProps = ITranslatable & IInjectedProps & RouteComponentProps<{ attachmentId: string }>;

@injectProps({
  annotationUiStore: AnnotationUiStoreType,
  previewService: AttachmentsPreviewServiceType,
})
@observer
class AttachmentImagePreviewContainer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      currentZoom: 100,
    };
  }

  @autobind
  handleZoomChanged(zoom: number) {
    this.setState({ currentZoom: zoom });
  }

  @autobind
  handleLowResImageLoaded() {
    this.props.previewService.data.isPreviewImageLoading = false;
  }

  render() {
    const data = this.props.previewService.data;
    return (
      <div className="dataset-image-preview">
        <Image
          isLeftMenuOpened={false}
          isLoading={data.isPreviewImageLoading}
          isHighResImageLoading={!data.imagePreviewUrl}
          url={data.imagePreviewUrl || ''}
          lowQualityUrl={data.lowQualityImagePreviewUrl || ''}
          height={data.imageInfo.height || 0}
          width={data.imageInfo.width || 0}
          zoomLevel={this.state.currentZoom}
          minZoomLevel={this.props.annotationUiStore.minZoomLevel}
          maxZoomLevel={this.props.annotationUiStore.maxZoomLevel}
          onZoomChanged={this.handleZoomChanged}
          onLowResImageLoaded={this.handleLowResImageLoaded}
        >
          <DrawingContainer disableCursorGuides={false} />
        </Image>
      </div>
    );
  }
}

export default as<React.ComponentClass>(withRouter(withNamespaces('common', { wait: true })(AttachmentImagePreviewContainer)));
