import * as React from 'react';

import { FormGroup, Label } from 'reactstrap';
import { ILoaderState, WithLoaderComponentBase } from '../../../../../helpers/loader.helpers';
import { IProjectDetailsImagesStore, ProjectDetailsImagesStoreType } from '../projectDetailsImages.store';
import { IProjectDetailsPermissions, ProjectDetailsPermissionsType } from '../../../projectDetails.permissions';
import { ProjectDetailsImagesBl, ProjectDetailsImagesBlType } from '../projectDetailsImages.bl';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { ImageSetType } from '../projectDetailsImages.model';
import Select from 'react-select';
import autobind from 'autobind-decorator';
import { handleSelectChange } from '../../../../../helpers/formHelpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps extends ITranslatable {
  projectDetailsImagesBl: ProjectDetailsImagesBl;
  imagesStore: IProjectDetailsImagesStore;
  permissions: IProjectDetailsPermissions;
}

interface IState extends ILoaderState {
  selectedImageSetType: any;
}

@injectProps({
  projectDetailsImagesBl: ProjectDetailsImagesBlType,
  imagesStore: ProjectDetailsImagesStoreType,
  permissions: ProjectDetailsPermissionsType,
})
@observer
class ProjectDetailsImageSetTypeContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  state: IState = {
    selectedImageSetType: null,
    isLoading: false,
  };

  @autobind
  async handleImagesSetTypeChanged(setType: ImageSetType) {
    await this.withLoaderAsync(
      async () => await this.props.projectDetailsImagesBl.updateBatchProjectImageSetAsync(setType),
      'details-images-loading');

    this.setState({ selectedImageSetType: null });
  }

  render() {
    const { toggledImages, selectionMode, imagesPaging } = this.props.imagesStore;
    const isDisabled = (selectionMode === 'Select' && toggledImages.length === 0) || (selectionMode === 'Deselect' && toggledImages.length === imagesPaging.totalCount);
    const canUpdateImageSet = this.props.permissions.canUpdateImageSet();

    return (
      <>
        {canUpdateImageSet &&
          <FormGroup className="set-image-set-type">
            <Label>{this.props.t('image_set_type_label')}</Label>
            <Select
              isClearable
              id="select-image-set-type"
              value={this.state.selectedImageSetType}
              onChange={handleSelectChange((value: ImageSetType) => this.handleImagesSetTypeChanged(value))}
              options={Object.values(ImageSetType).map(t => ({ label: this.props.t(`image_set_options.${t}`), value: t }))}
              isDisabled={isDisabled}
              classNamePrefix="custom-select"
            />
          </FormGroup>
        }
      </>
    );
  }
}

export const ProjectDetailsImageSetTypeContainer = as<React.ComponentClass>(withNamespaces('common')(ProjectDetailsImageSetTypeContainerPure));
