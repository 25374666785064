import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { ITranslatable } from '../../../../../helpers/translations.helpers';
import React from 'react';
import { UpdateWorkspaceTimeZoneFormContainer } from '../containers/UpdateWorkspaceTimeZoneForm.container';
import { UpdateWorkspaceTimeZoneSubmitButtonContainer } from '../containers/UpdateWorkspaceTimeZoneSubmitButton.container';
import { as } from '../../../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { withNamespaces } from 'react-i18next';

interface IProps {
  showModal: boolean;
  onHideModal(): void;
}

class UpdateWorkspaceTimeZoneModalPure extends React.Component<IProps & ITranslatable> {

  @autobind
  private preventDefault(e: any){
    e.preventDefault();
  }

  render() {
    return (
      <Modal className="modal-width-600" isOpen={this.props.showModal} toggle={this.props.onHideModal}>
        <ModalHeader toggle={this.props.onHideModal}>{this.props.t('update_workspace_timezone')}</ModalHeader>
        <Form className="custom-inline-form" onSubmit={this.preventDefault}>
          <ModalBody>
            <UpdateWorkspaceTimeZoneFormContainer />
          </ModalBody>
          <ModalFooter>
            <UpdateWorkspaceTimeZoneSubmitButtonContainer />
            <Button color="primary" className="btn-outline-primary" onClick={this.props.onHideModal}>
              {this.props.t('workspace_cancel')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export const UpdateWorkspaceTimeZoneModal = as<React.ComponentClass<IProps>>(withNamespaces('workspace')(UpdateWorkspaceTimeZoneModalPure));
