import { WithNamespaces, withNamespaces } from 'react-i18next';

import { DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

interface IProps {
  disabled?: boolean;
  onClick(): void;
}

const RenameAttachmentDropdownItemPure: React.FC<IProps & WithNamespaces> = (props: IProps & WithNamespaces) => {
  const { onClick, disabled, t } = props;

  return (
    <DropdownItem disabled={disabled} onClick={onClick} title={disabled ? t('you_do_not_have_permission_to_perform_this_operation') : ''}>
      <FontAwesomeIcon icon={faEdit} /> {t('attachments.rename_attachment')}
    </DropdownItem>
  );
};

export const RenameAttachmentDropdownItem = withNamespaces('new')(RenameAttachmentDropdownItemPure);
