import './ImportSummaryPane.scss';

import { IImportAnnotationRejection, importAnnotationsRejectionReasonTranslation } from '../../../../models/ImportAnnotationsReport';
import React, { useState } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faCaretUp, faCaretDown);

interface Props {
  importedItemsCount: number;
  skippedItemsCount: number;
  rejectedAnnotations: IImportAnnotationRejection[] | undefined;
}

const ImportSummaryPanePure: React.FC<Props & WithNamespaces> = props => {
  const { t, importedItemsCount, rejectedAnnotations, skippedItemsCount } = props;
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const toggle = () => setIsOpened(!isOpened);

  const rejectionReasons = rejectedAnnotations!.map(
    a => `${a.datasetName} - ${a.imageName} - ${t(`new.projects.details.import_annotations.steps.summary.errors.${importAnnotationsRejectionReasonTranslation(a.reason)}`)}`,
  );

  return (
    <div className="import-summary-pane">
      <p>
        {importedItemsCount} {t(`new.projects.details.import_annotations.steps.summary.annotation_imported`, { count: importedItemsCount })}
        {importedItemsCount > 0 && <span className="success-text"> {t('new.projects.details.import_annotations.steps.summary.successfully')}</span>}
      </p>
      {props.skippedItemsCount > 0 && (
        <p className="collapsible" onClick={toggle}>
          {skippedItemsCount} {t(`new.projects.details.import_annotations.steps.summary.annotation`, { count: skippedItemsCount })}
          <span className="error-text"> {t('new.projects.details.import_annotations.steps.summary.skipped')} </span>
          <FontAwesomeIcon icon={isOpened ? faCaretUp : faCaretDown} />
        </p>
      )}
      {isOpened && (
        <div className="skipped-items-pane">
          {rejectionReasons.map((_, index) => (
            <div className="skipped-item" key={index}>{rejectionReasons[index]}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export const ImportSummaryPane = withNamespaces()(ImportSummaryPanePure);
