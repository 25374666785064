import 'leaflet-path-transform';

import { CircleMarker, Map, Polyline } from 'leaflet';
import { copyAndTranslatePolygon, getSimplifiedGeoJSON } from '../../../helpers/geometry/polygon.helpers';

import { AnnotationToolType } from '../submodules/annotationTypes/models/annotationToolType';
import { Feature } from 'geojson';
import { ISegmentationPolygonProps } from './SegmentationLayerFeatures';
import { IUndoRedoHistory } from '../undoRedoHistory.service';
import { MOUSE_EVENTS } from '../annotations.interface';
import { MouseClickLimiter } from '../../../helpers/mouse.helpers';
import { PathTransformEx } from './DrawControl.models/PathTransformEx';
import autobind from 'autobind-decorator';
import { cloneDeep } from 'lodash/fp';
import { keyboardShortcutsServiceInstance } from '../../../services/keyboardShortcuts.service';
import uuid from 'uuid';

// don't use MOUSE_EVENTS because it fires before or after other events and duplicates code
const EDIT_EVENTS = {
  EDIT_ENABLE: 'editable:enable',
  EDIT_DISABLE: 'editable:disable',
  EDITING: 'editable:editing',
  DRAG_START: 'editable:dragstart',
  DRAG_END: 'editable:dragend', // event fires before 'mouseup'
  MARKER_DRAG_START: 'editable:vertex:dragstart', // event fires before 'mouseup'
  MARKER_DRAG_END: 'editable:vertex:dragend', // event fires before 'mouseup'
  MARKER_CIRCLE_DRAG_END: 'editable:circle:vertex:dragend',
  MARKER_DELETED: 'editable:vertex:deleted', // event fires after 'mouseup'
  MARKER_CLICKED: 'editable:vertex:clicked', // event fires after 'mouseup'
  MARKER_NEW: 'editable:vertex:new',
};

interface ICurrentlyEditing {
  polygon?: Polyline | CircleMarker;
  edited: boolean;
}

interface IOptions {
  onDrawCopied(id: string): void;
  onDrawPasted(geojson: Feature, id: string): void;
  onDrawEdited(index: string | undefined, geojson: Feature): void;
  onDrawingRemove(id: string): void;
  onEditEnabled(id: string): void;
  onEditDisabling(): Promise<boolean>;
  onEditDisabled(): void;
}

@autobind
export class DrawingEditor {
  private readonly map!: Map;
  private readonly options: IOptions;
  private readonly undoRedoHistory: IUndoRedoHistory;

  currentlyEditing: ICurrentlyEditing = { polygon: undefined, edited: false };
  private copiedDrawing?: Polyline;
  private isRotating = false;

  constructor(map: Map, options: IOptions, undoRedoHistory: IUndoRedoHistory) {
    this.map = map;
    map.dragging.disable();
    this.options = options;
    this.undoRedoHistory = undoRedoHistory;
  }

  async disableAll() {
    const cancelled = await this.options.onEditDisabling();
    if (cancelled) return;

    this.saveChanges();
    this.disableRotation();
    this.disableEdit();
    this.currentlyEditing = { polygon: undefined, edited: false };
    this.options.onEditDisabled();
  }

  disableAllExcept(storeKey: string | undefined) {
    this.saveChanges();
    this.map.eachLayer((layer: any) => {
      if (layer.options.storeKey === storeKey) return;
      this.disableEdit(layer);
      this.disableRotation(layer);
      this.addTransformHandler(layer);
    });
  }

  disableEdit(polygon: any = this.currentlyEditing.polygon) {
    if (!polygon || !polygon.disableEdit) return;
    polygon.disableEdit();
  }

  disableRotation(polygon: any = this.currentlyEditing.polygon) {
    if (!polygon
      || !polygon.transform
      || !this.canRotate(this.currentlyEditing.polygon)) return;

    polygon.transform.disable();
    polygon.off('rotatestart', this.rotateStartHandler);
    polygon.off('rotateend', this.rotateEndHandler);
  }

  enableEdit() {
    if (!this.currentlyEditing.polygon || !this.currentlyEditing.polygon._map) return;
    (this.currentlyEditing.polygon as any).enableEdit();
  }

  enableRotation() {
    if (!this.currentlyEditing.polygon
      || !this.canRotate(this.currentlyEditing.polygon)
      || this.currentlyEditing.polygon.transform._enabled) return;

    this.currentlyEditing.polygon.transform.enable();
    this.currentlyEditing.polygon.transform.reset();
    this.currentlyEditing.polygon.on('rotatestart', this.rotateStartHandler);
    this.currentlyEditing.polygon.on('rotateend', this.rotateEndHandler);

    const options = {
      scaling: false,
      rotation: true,
      handlerOptions: {
        radius: 6,
      },
      boundsOptions: {
        color: this.currentlyEditing.polygon.options.color,
        weight: 2,
        dashArray: [5],
        opacity: 0.7,
      },
      rotateHandleOptions: {
        color: this.currentlyEditing.polygon.options.color,
        weight: 2,
        dashArray: [5],
        opacity: 0.7,
      },
      handleLength: 150,
    };

    this.currentlyEditing.polygon.transform.setOptions(options);
  }

  enableAll() {
    this.enableEdit();
    this.enableRotation();
  }

  enableAllForPolygonWith(storeKey: string) {
    this.disableRotation();
    this.disableEdit();
    this.currentlyEditing = { polygon: undefined, edited: false };
    this.map.eachLayer((layer: any) => {
      if (layer.options.storeKey !== storeKey || !layer.enableEdit) return;
      layer.enableEdit();
    });
  }

  addHandlers() {
    keyboardShortcutsServiceInstance.registerKeyDown('`', this.disableAll);
    keyboardShortcutsServiceInstance.registerKeyDown('Escape', this.disableAll);
    keyboardShortcutsServiceInstance.registerKeyDown('Delete', this.handleDelete);
    keyboardShortcutsServiceInstance.registerKeyDown('c', this.handleCopy);
    keyboardShortcutsServiceInstance.registerKeyDown('v', this.handlePaste);

    this.map.on(EDIT_EVENTS.EDITING, this.handleEditing);
    this.map.on(EDIT_EVENTS.EDIT_ENABLE, this.handleEditEnable);
    this.map.on(EDIT_EVENTS.EDIT_DISABLE, this.handleEditDisable);
    this.map.on(EDIT_EVENTS.DRAG_START, this.handleDragStart);
    this.map.on(EDIT_EVENTS.DRAG_END, this.handleDragEnd);

    this.map.on(EDIT_EVENTS.MARKER_DELETED, this.handleMarkerDeleted);
    this.map.on(EDIT_EVENTS.MARKER_DRAG_START, this.handleDragStart);
    this.map.on(EDIT_EVENTS.MARKER_DRAG_END, this.handleMarkerDragEnd);
    this.map.on(EDIT_EVENTS.MARKER_CIRCLE_DRAG_END, this.handleMarkerDragEnd);

    this.map.on(MOUSE_EVENTS.MOUSE_DOWN, this.handleMapMouseDown);
    this.map.on(MOUSE_EVENTS.MOUSE_UP, this.handleMapClick);
  }

  removeHandlers() {
    keyboardShortcutsServiceInstance.unregisterKeyDown('`', this.disableAll);
    keyboardShortcutsServiceInstance.unregisterKeyDown('Escape', this.disableAll);
    keyboardShortcutsServiceInstance.unregisterKeyDown('Delete', this.handleDelete);
    keyboardShortcutsServiceInstance.unregisterKeyDown('c', this.handleCopy);
    keyboardShortcutsServiceInstance.unregisterKeyDown('v', this.handlePaste);

    this.map.off(EDIT_EVENTS.EDITING, this.handleEditing);
    this.map.off(EDIT_EVENTS.EDIT_ENABLE, this.handleEditEnable);
    this.map.off(EDIT_EVENTS.DRAG_START, this.handleDragStart);
    this.map.off(EDIT_EVENTS.DRAG_END, this.handleDragEnd);

    this.map.off(EDIT_EVENTS.MARKER_DELETED, this.handleMarkerDeleted);
    this.map.off(EDIT_EVENTS.MARKER_DRAG_START, this.handleDragStart);
    this.map.off(EDIT_EVENTS.MARKER_DRAG_END, this.handleMarkerDragEnd);
    this.map.off(EDIT_EVENTS.MARKER_CIRCLE_DRAG_END, this.handleMarkerDragEnd);

    this.map.off(MOUSE_EVENTS.MOUSE_DOWN, this.handleMapMouseDown);
    this.map.off(MOUSE_EVENTS.MOUSE_UP, this.handleMapClick);
  }

  handleMapClick(evt: any) {
    if (!this.isRotating && this.currentlyEditing.polygon && evt.originalEvent?.target.classList.contains('leaflet-container')) {
      if (MouseClickLimiter.distinguishClick()) {
        this.disableAll();
      }
    }
  }

  handleMapMouseDown(evt: any) {
    if (!this.isRotating && evt.originalEvent.target.classList.contains('leaflet-container')) {
      MouseClickLimiter.markMoment();
    }
  }

  handleCopy(e: KeyboardEvent) {
    if (this.currentlyEditing.polygon && (e.ctrlKey || e.metaKey) && (this.currentlyEditing.polygon instanceof Polyline)) {
      this.copiedDrawing = cloneDeep(this.currentlyEditing.polygon);
      const { storeKey } = this.copiedDrawing.options as ISegmentationPolygonProps;
      this.options.onDrawCopied(storeKey);
    }
  }

  handlePaste(e: KeyboardEvent) {
    if (this.copiedDrawing && (e.ctrlKey || e.metaKey)) {
      const drawing = copyAndTranslatePolygon(this.copiedDrawing, 10, 10, this.map);
      const id = uuid.v4();
      this.options.onDrawPasted(drawing.toGeoJSON(), id);
      this.enableAllForPolygonWith(id);
      this.undoRedoHistory.addCommand(this.undoRedoHistory.getNewChangeStateCommand());
    }
  }

  private handleDelete() {
    if (this.currentlyEditing.polygon) {
      const id = this.currentPolygonStoreKey!;
      this.disableAll();
      this.options.onDrawingRemove(id);
      this.undoRedoHistory.addCommand(this.undoRedoHistory.getNewChangeStateCommand());
    }
  }

  private handleEditing() {
    this.currentlyEditing.edited = true;
  }

  private handleEditEnable(e: any) {
    this.disableAllExcept(e.layer.options.storeKey);
    this.currentlyEditing.polygon = e.layer;
    this.addTransformHandler(e.layer);
    this.enableRotation();
    this.options.onEditEnabled(this.currentPolygonStoreKey!);
  }

  private handleEditDisable() {
    this.saveChanges();
    if (!this.isRotating) {
      this.disableRotation();
    }
  }

  private addTransformHandler(layer: any) {
    if (!layer
      || this.getPolygonToolType(layer) === AnnotationToolType.POINT
      || this.getPolygonToolType(this.currentlyEditing.polygon) === AnnotationToolType.VECTOR
      || this.getPolygonToolType(this.currentlyEditing.polygon) === AnnotationToolType.POLYLINE
      || this.getPolygonToolType(this.currentlyEditing.polygon) === AnnotationToolType.BRUSH) return;

    if (!layer.transform) {
      layer.transform = new PathTransformEx(layer);
    }
  }

  rotateStartHandler() {
    this.isRotating = true;
    this.disableEdit();
    this.map.scrollWheelZoom.disable();
  }

  rotateEndHandler() {
    this.isRotating = false;
    this.handleEditing();
    this.enableEdit();
    this.map.scrollWheelZoom.enable();
  }

  handleDragEnd() {
    this.disableEdit();
    this.saveChanges();
    this.enableAll();
  }

  handleMarkerDeleted() {
    setTimeout(
      () => {
        this.disableEdit();
        this.saveChanges();
        this.enableAll();
      },
      0);
  }

  handleMarkerDragEnd() {
    this.disableEdit();
    this.saveChanges();
    this.enableAll();
  }

  handleDragStart() {
    this.disableRotation();
  }

  saveChanges() {
    if (!this.currentlyEditing.edited || !this.currentlyEditing.polygon) return;

    let geoJson: Feature;
    if (this.currentlyEditing.polygon instanceof Polyline && this.currentlyEditing.polygon.getLatLngs().length > 4) {
      geoJson = getSimplifiedGeoJSON(this.currentlyEditing.polygon);
    } else {
      geoJson = this.currentlyEditing.polygon.toGeoJSON();
    }

    if (!geoJson) return;
    this.options.onDrawEdited(
      this.currentPolygonStoreKey!,
      geoJson,
    );

    this.undoRedoHistory.addCommand(this.undoRedoHistory.getNewChangeStateCommand());
    this.currentlyEditing.edited = false;
  }

  getPolygonToolType(polygon: any): AnnotationToolType | undefined {
    return polygon
      ? (polygon.options as ISegmentationPolygonProps).type
      : undefined;
  }

  canRotate(polygon: any): boolean {
    return polygon
      ? (polygon.options as ISegmentationPolygonProps).canRotate
      : false;
  }

  get currentPolygonStoreKey(): string | undefined {
    return this.currentlyEditing.polygon
      ? (this.currentlyEditing.polygon.options as ISegmentationPolygonProps).storeKey
      : undefined;
  }
}
