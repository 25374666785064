import * as React from 'react';

import { Col, Row } from 'reactstrap';

import { AnnotationColoredStatus } from './AnnotationColoredStatus';
import { AnnotationStatus } from '../annotations.interface';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IAnnotationStatusInfoProps {
  isImageLoaded: boolean;
  annotationStatus: AnnotationStatus | undefined;
  isAssigned: boolean;
  annotatedByName: string;
  reviewedByName: string;
  reviewRejectionReason?: string;
  hasUnansweredClarification: boolean;
  isWaitingForReviewAfterReject: boolean;
}

class AnnotationStatusInfoPure extends React.Component<IAnnotationStatusInfoProps & ITranslatable> {
  render() {
    const { isImageLoaded, isAssigned, annotationStatus, annotatedByName, reviewedByName, reviewRejectionReason, isWaitingForReviewAfterReject, t } = this.props;

    const canSeeUsers = !!annotatedByName;

    return (
      <>
        {isImageLoaded && (
          <Row className="annotation-status-info">
            <Col>
              <div>
                <span>{t('status')}:</span> <AnnotationColoredStatus annotationStatus={annotationStatus} hasUnansweredClarification={this.props.hasUnansweredClarification} />
              </div>
              {canSeeUsers && (
                <>
                  {annotationStatus === AnnotationStatus.DRAFT && (
                    <>
                      <div>
                        <span>{t('saved_by')}:</span> {annotatedByName}
                      </div>
                      {!isAssigned && <div>{t('unassigned')}</div>}
                    </>
                  )}
                  {(annotationStatus === AnnotationStatus.TOREVIEW || annotationStatus === AnnotationStatus.ACCEPTED || annotationStatus === AnnotationStatus.REJECTED) && (
                    <div>
                      <span>{t('annotated_by')}:</span> {annotatedByName}
                    </div>
                  )}
                  {annotationStatus === AnnotationStatus.ACCEPTED && (
                    <div>
                      <span>{t('accepted_by')}:</span> {reviewedByName}
                    </div>
                  )}
                  {annotationStatus === AnnotationStatus.REJECTED && (
                    <>
                      <div>
                        <span>{t('rejected_by')}:</span> {reviewedByName}
                      </div>
                    </>
                  )}
                </>
              )}
              {(annotationStatus === AnnotationStatus.REJECTED || isWaitingForReviewAfterReject) && (
                <div className="rejection-reason">
                  <span>{t('reviewer_note')}:</span> {reviewRejectionReason}
                </div>
              )}
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export const AnnotationStatusInfo = as<React.StatelessComponent<IAnnotationStatusInfoProps>>(withNamespaces('annotation')(AnnotationStatusInfoPure));
