import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../../../../helpers/react.helpers';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IProps {
  labelKey: string;
  count: number;
  onClear(): void;
}

@observer
class FilterPillPure extends React.Component<IProps & ITranslatable> {
  render() {
    if (this.props.count === 0) return <></>;

    return (
      <div className="image-filter-pill">
        <span>{this.props.t(this.props.labelKey)} <span className="count">({this.props.count})</span></span>
        <span onClick={this.props.onClear} className="clear-button">
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
    );
  }
}

export const FilterPill = as<React.ComponentClass<IProps>>(withNamespaces('common')(FilterPillPure));
