import { AnnotationsStoreType, IAnnotationsStore } from '../../../../__legacy__/modules/annotation/annotations.store';
import { CursorControlBlType, ICursorControlBl } from '../CursorControl.bl';
import { FreeAccessStoreType, IFreeAccessStore } from '../../../../__legacy__/modules/freeAccess/freeAccess.store';
import { IUserStore, UserStoreType } from '../../../../__legacy__/modules/user/user.store';
import { as, injectProps } from '../../../../__legacy__/helpers/react.helpers';

import { CursorVisibilityCheckbox } from './CursorVisibilityCheckbox';
import React from 'react';
import { observer } from 'mobx-react';

interface IProps {
  disabled: boolean;
}

interface InjectedProps {
  bl: ICursorControlBl;
  userStore: IUserStore;
  annotationStore: IAnnotationsStore;
  freeAccessStore: IFreeAccessStore;
}

@injectProps({
  bl: CursorControlBlType,
  userStore: UserStoreType,
  annotationStore: AnnotationsStoreType,
  freeAccessStore: FreeAccessStoreType,
})
@observer
class CursorVisibilityCheckboxContainerPure extends React.Component<IProps & InjectedProps> {
  handleChange = () => this.props.bl.toggleImprovedVisibilityCursor();
  render(): React.ReactNode {
    return <CursorVisibilityCheckbox disabled={this.props.disabled} checked={this.props.userStore.isImprovedVisibilityCursorEnabled} onChange={this.handleChange} />;
  }
}

export const CursorVisibilityCheckboxContainer = as<React.ComponentClass<IProps>>(CursorVisibilityCheckboxContainerPure);
