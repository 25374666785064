import { MODEL_VARIANTS } from '../../__legacy__/models/metrics.model';

export enum MODEL_STATUS {
  UNKNOWN = 'Unknown', // Used when we didn't get data from backend yet
  DRAFT = 'Draft',
  FAILED = 'Failed',
  FAILING = 'Failing',
  FINISHED = 'Finished',
  IMPORTED = 'Imported',
  STARTING = 'Starting',
  RUNNING = 'Running',
  STOPPED = 'Stopped',
  STOPPING = 'Stopping',
}

export interface IModelListDto {
  created_at: string;
  job_id: string;
  model_name: string;
  model_variant: MODEL_VARIANTS;
  progress: string;
  project_name: string;
  score: number;
  status: MODEL_STATUS;
  training_time: number;
  is_owner: boolean;
  starting_progress: '1/4' | '2/4' | '3/4' | null;
  failure_reason: string | null;
}
