import { AnnotationsStoreType, IAnnotationsStore } from '../../../annotations.store';
import { ClarificationAddedEvent, ClarificationAddedEventType } from '../events/ClarificationAddedEvent';
import { ClarificationsBlType, IClarificationsBl } from '../services/Clarifications.bl';
import { EventBusType, EventListeningDisposer, IEventBus } from '../../../../../services/eventBus.service';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import ClarificationsArchiveListContainer from './ClarificationsArchiveList.container';
import CurrentClarificationContainer from './CurrentClarification.container';
import React from 'react';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ projectId: string }> {
  clarificationsBl: IClarificationsBl;
  annotationsStore: IAnnotationsStore;
  eventBus: IEventBus;
}

@injectProps({
  clarificationsBl: ClarificationsBlType,
  annotationsStore: AnnotationsStoreType,
  eventBus: EventBusType,
})
@observer
class ClarificationsContainer extends React.Component<IInjectedProps> {
  private clarificationAddedDisposer?: EventListeningDisposer;

  componentDidMount() {
    this.getClarifications();

    this.clarificationAddedDisposer = this.props.eventBus.addListener<ClarificationAddedEvent>(this.handleClarificationAdded, ClarificationAddedEventType);
  }

  componentDidUpdate() {
    this.getClarifications();
  }

  componentWillUnmount() {
    this.clarificationAddedDisposer?.();
    this.props.clarificationsBl.dispose();
  }

  getClarifications() {
    if (this.props.annotationsStore.image) {
      this.props.clarificationsBl.getClarificationsAsync(this.props.match.params.projectId, this.props.annotationsStore.image.id);
    }
  }

  @autobind
  handleClarificationAdded() {
    this.getClarifications();
  }

  render() {
    this.props.annotationsStore.image;
    return (
      <div className="image-clarifications row">
        <CurrentClarificationContainer />
        <ClarificationsArchiveListContainer />
      </div>
    );
  }
}

export default as<React.ComponentClass>(withRouter(ClarificationsContainer));
