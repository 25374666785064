import { action, observable } from 'mobx';

import { ReviewMode } from './models/reviewMode';
import { injectable } from 'inversify';

export const AnnotationReviewStoreType = Symbol('ANNOTATION_REVIEW_STORE');

export interface IAnnotationReviewStore {
  readonly currentMode: ReviewMode;
}

export interface IAnnotationReviewStoreSetter extends IAnnotationReviewStore {
  setCurrentMode(mode: ReviewMode): void;
}

@injectable()
export class AnnotationReviewStore implements IAnnotationReviewStoreSetter {
  @observable
  currentMode: ReviewMode = ReviewMode.Review;

  @action
  setCurrentMode(mode: ReviewMode): void {
    this.currentMode = mode;
  }
}
