import * as React from 'react';

import { LinkProps } from 'react-router-dom';

interface IProps extends LinkProps {
  id?: string;
  to: string;
  target?: string;
  title?: string;
  callback?(): void;
}

export class ExternalLink extends React.Component<IProps> {
  handleLinkClick = (e: Event) => {
    e.preventDefault();
    if (this.props.callback) this.props.callback();
    window.open(this.props.to, this.props.target || '_blank');
  };

  render() {
    return (
      // @ts-ignore - jakiś błąd w definicjach wewnętrznych typów po przebudowaniu yarn locka, działa ale pluje błędem
      <a onClick={this.handleLinkClick} {...this.props} href="">
        {this.props.children}
      </a>
    );
  }
}
