import { ISegmentationOrderBl, SegmentationOrderBlType } from '../../segmentationsOrder.Bl';
import { as, injectProps } from '../../../../../../../helpers/react.helpers';

import { ISegmentationContextMenuChildrenProps } from '../../components/SegmentationContextMenu';
import React from 'react';
import { SegmentationContextMenuItem } from '../../components/SegmentationContextMenuItem';

interface IInjectProps {
  segmentationOrderBl: ISegmentationOrderBl;
}

@injectProps({
  segmentationOrderBl: SegmentationOrderBlType,
})
class BringToFrontContextMenuItemContainerPure extends React.Component<ISegmentationContextMenuChildrenProps & IInjectProps> {

  handleAction = () => {
    this.props.segmentationOrderBl.bringSegmentationToFront(this.props.segmentationId);
  }

  render() {
    return <SegmentationContextMenuItem {...this.props} text="bring_to_front" icon="bring-front-icon" action={this.handleAction} />;
  }
}

export const BringToFrontContextMenuItemContainer = as<React.ComponentClass<ISegmentationContextMenuChildrenProps>>(BringToFrontContextMenuItemContainerPure);
