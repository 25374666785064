import { injectable, inject } from 'inversify';
import { action } from 'mobx';
import { EventBusType, IEventBus } from '../../../../../services/eventBus.service';
import { AnnotationsStoreType, IAnnotationsStore } from '../../../annotations.store';
import { AnnotationUiStoreType, IAnnotationUiStore } from '../../../annotationUi.store';
import { SegmentationDeselectedEvent, SegmentationDeselectedEventType } from '../events/segmentationDeselectedEvent';
import { SegmentationSelectedEvent, SegmentationSelectedEventType } from '../events/segmentationSelectedEvent';

export const SegmentationOrderBlType = Symbol('SEGMENTATION_ORDER_BL');

export interface ISegmentationOrderBl {
  bringSegmentationToFront(segmentationId: string): void;
  sendSegmentationToBack(segmentationId: string): void;
}

interface SelectedSegmentation {
  id: string;
  prevIndex: number;
}

@injectable()
export class SegmentationOrderBl implements ISegmentationOrderBl {
  private _selectedSegmentation: SelectedSegmentation | undefined;

  constructor(
    @inject(AnnotationUiStoreType) private readonly uiStore: IAnnotationUiStore,
    @inject(AnnotationsStoreType) private readonly annotationsStore: IAnnotationsStore,
    @inject(EventBusType) eventBus: IEventBus,
  ) {
    eventBus.addListener<SegmentationSelectedEvent>(this.onSegmentationSelectedListener, SegmentationSelectedEventType);
    eventBus.addListener<SegmentationDeselectedEvent>(this.onSegmentationDeselectedListener, SegmentationDeselectedEventType);
  }

  private onSegmentationSelectedListener = (event: SegmentationSelectedEvent) => {
    this.moveSelectedSegmentationToPreviousPosition();
    const prevIndex = this.moveSegmentationToFront(event.selectedSegmentationFeatureId);
    this._selectedSegmentation = {
      prevIndex,
      id: event.selectedSegmentationFeatureId,
    };
    this.forceReRender();
  };

  private onSegmentationDeselectedListener = () => {
    this.moveSelectedSegmentationToPreviousPosition();
    this._selectedSegmentation = undefined;
    this.forceReRender();
  }

  @action.bound
  bringSegmentationToFront(segmentationId: string): void {
    if (this._selectedSegmentation?.id === segmentationId) {
      this.moveSelectedSegmentationToFront();
    } else {
      this.moveSegmentationToFront(segmentationId);
      this.forceReRender();
    }
  }

  @action.bound
  sendSegmentationToBack(segmentationId: string): void {
    if (this._selectedSegmentation?.id === segmentationId) {
      this.moveSelectedSegmentationToBack();
    } else {
      this.moveSegmentationToBack(segmentationId);
      this.forceReRender();
    }

  }

  private moveSegmentationToFront(segmentationId: string): number {
    const index = this.annotationsStore.segmentations.findIndex(x => x.feature.id === segmentationId);
    const newArray = this.annotationsStore.segmentations.concat();
    const segmentation = newArray.splice(index, 1)[0];
    newArray.push(segmentation);
    this.annotationsStore.setSegmentations(newArray);
    return index;
  }

  private moveSegmentationToBack(id: string): number {
    const index = this.annotationsStore.segmentations.findIndex(x => x.feature.id === id);
    const newArray = this.annotationsStore.segmentations.concat();
    const segmentation = newArray.splice(index, 1)[0];
    newArray.push(segmentation);
    newArray.unshift(segmentation);
    this.annotationsStore.setSegmentations(newArray);
    this.forceReRender();
    return index;
  }

  private moveSegmentationToPosition(segmentationId: string, position: number) {
    const index = this.annotationsStore.segmentations.findIndex(x => x.feature.id === segmentationId);
    if (index === position || index === -1) return ;
    const newArray = this.annotationsStore.segmentations.concat();
    const segmentation = newArray.splice(index, 1)[0];
    newArray.splice(position, 0, segmentation);
    this.annotationsStore.setSegmentations(newArray);
    return index;
  }

  private moveSelectedSegmentationToFront() {
    if (this._selectedSegmentation) {
      const lastIndex = this.annotationsStore.segmentations.length - 1;
      this._selectedSegmentation.prevIndex = lastIndex;
    }
  }

  private moveSelectedSegmentationToBack() {
    if (this._selectedSegmentation) {
      this._selectedSegmentation.prevIndex = 0;
    }
  }

  private moveSelectedSegmentationToPreviousPosition() {
    if (this._selectedSegmentation) {
      this.moveSegmentationToPosition(this._selectedSegmentation.id, this._selectedSegmentation.prevIndex);
    }
  }

  private forceReRender(): void {
    this.uiStore.forceReRender();
  }
}
