import { IApiService } from '../../../../../../../__legacy__/services/api.service.base';
import { StickerError } from '../../../../../../../__legacy__/models/error.model';
import { inject, injectable } from 'inversify';
import { ApiServiceType } from '../../../../../../../__legacy__/services/api.service';
import { AnnotationToolType } from '../../../../../../../__legacy__/modules/annotation/submodules/annotationTypes/models/annotationToolType';
import { MarkingTool } from '../../commons/models/MarkingTool';

export const GetMarkingToolServiceType = Symbol('GET_MARKING_TOOL_SERVICE');

export interface GetMarkingToolService {
  getMarkingToolAsync(request: GetMarkingToolRequest): Promise<MarkingToolResponse>;
}

export class MarkingToolResponse {
  id: string;
  name: string;
  color: string;
  selectorType: AnnotationToolType;
  isUsed: boolean;
  order: number;

  constructor(response: IMarkingToolResponse) {
    this.id = response.id;
    this.name = response.name;
    this.color = response.color;
    this.order = response.order;
    this.selectorType = response.selectorType;
    this.isUsed = response.isUsed;
  }

  get model(): MarkingTool {
    return new MarkingTool(this.id, this.name, this.color, this.selectorType, this.isUsed, this.order);
  }
}

interface IMarkingToolResponse {
  id: string;
  name: string;
  color: string;
  selectorType: AnnotationToolType;
  isUsed: boolean;
  order: number;
}

export class GetMarkingToolRequest {
  projectId: string;
  annotationTypeId: string;

  constructor(projectId: string, annotationTypeId: string) {
    this.projectId = projectId;
    this.annotationTypeId = annotationTypeId;
  }
}

@injectable()
export class GetMarkingToolServiceImpl implements GetMarkingToolService {
  constructor(@inject(ApiServiceType) readonly apiService: IApiService) {}

  async getMarkingToolAsync(request: GetMarkingToolRequest): Promise<MarkingToolResponse> {
    const result = await this.apiService.getAsync<IMarkingToolResponse>('/AnnotationTypes/GetAnnotationType', { params: request });

    if (result instanceof StickerError) {
      throw result;
    }

    return new MarkingToolResponse(result);
  }
}
