import * as React from 'react';

import { AnnotationsStoreType, IAnnotationsStore } from '../annotations.store';
import { ClarificationsStoreType, IClarificationsStore } from '../submodules/clarifications/Clarifications.store';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { AnnotationStatusInfo } from '../components/AnnotationStatusInfo';
import ClarificationsContainer from '../submodules/clarifications/containers/Clarifications.container';
import { Container } from 'reactstrap';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps {
  annotationsStore: IAnnotationsStore;
  clarificationsStore: IClarificationsStore;
}

@injectProps({
  annotationsStore: AnnotationsStoreType,
  clarificationsStore: ClarificationsStoreType,
})
@observer
class AnnotationStatusInfoContainerPure extends React.Component<IInjectedProps> {
  render() {
    return (
      <Container>
        <AnnotationStatusInfo
          isImageLoaded={!!this.props.annotationsStore.image}
          annotationStatus={this.props.annotationsStore.image?.annotations?.status}
          isAssigned={this.props.annotationsStore.image?.annotations?.isAssigned || false}
          annotatedByName={this.props.annotationsStore.image?.annotations?.author || ''}
          reviewedByName={this.props.annotationsStore.image?.annotations?.reviewedByName || ''}
          reviewRejectionReason={this.props.annotationsStore.image?.annotations?.reviewRejectionReason}
          hasUnansweredClarification={this.props.clarificationsStore.hasUnansweredClarification}
          isWaitingForReviewAfterReject={this.props.annotationsStore.image?.annotations?.isWaitingForReviewAfterReject || false}
        />
        <ClarificationsContainer />
      </Container>
    );
  }
}

export const AnnotationStatusInfoContainer = as<React.ComponentClass>(withRouter(AnnotationStatusInfoContainerPure));
