import * as React from 'react';

import { AuthServiceType, IAuthService } from '../auth.service';
import { AuthStoreType, IAuthStore } from '../auth.store';
import { Col, Container, Row } from 'reactstrap';
import { INotificationsService, NotificationsServiceType } from '../../notifications/services/notifications.service';
import { NotificationLevel, ToastNotification } from '../../notifications/models/notification.model';
import { as, injectProps } from '../../../helpers/react.helpers';

interface IProps {
  children: React.ReactElement<any>[] | React.ReactElement<any>;
}

interface IInjectedProps {
  authService: IAuthService;
  authStore: IAuthStore;
  notificationService: INotificationsService;
}

@injectProps({
  authService: AuthServiceType,
  authStore: AuthStoreType,
  notificationService: NotificationsServiceType,
})
export class AuthContainerPure extends React.Component<IProps & IInjectedProps> {
  componentDidMount() {
    if (this.props.authStore.versionChanged) {
      this.props.notificationService.push(
        new ToastNotification(NotificationLevel.INFO, 'aplication_updated'),
      );
      this.props.authService.showVersionChangedMessage(false);
    }
  }

  render() {
    return (
      <div className="auth-outer-container">
        <div className="auth-inner">
          <Container>
            <Row>
              <Col sm="12" lg={{ size: 6, offset: 3 }}>
                {this.props.children}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export const AuthContainer = as<React.ComponentClass<IProps>>(AuthContainerPure);
