import { IImageFilters, IImageFiltersData } from '../../../imageFilters.model';

import { FiltersDropdownRow } from '../FiltersDropdownRow';
import { ITranslatable } from '../../../../../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../../../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IAnnotatedByFilterInfoProps {
  availableFilters: IImageFiltersData;
  imageFilters: IImageFilters;
}

export class AnnotatedByFilterInfoPure extends React.Component<IAnnotatedByFilterInfoProps & ITranslatable> {
  render() {
    const selectedOptions = this.props.availableFilters.annotatedBy
      .slice()
      .sort((a, b) => (a.email > b.email ? 1 : -1))
      .filter(d => this.props.imageFilters.annotatedBy.includes(d.id));

    return (
      <FiltersDropdownRow label={this.props.t('image_filters.annotatedBy')} count={selectedOptions.length}>
        <>{selectedOptions.map(d => <div key={d.id}>{d.email}</div>)}</>
      </FiltersDropdownRow>);
  }

}

export const AnnotatedByFilterInfo = as<React.ComponentClass<IAnnotatedByFilterInfoProps>>(withNamespaces('project')(AnnotatedByFilterInfoPure));
