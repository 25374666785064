import * as React from 'react';

import { AnnotationStatus } from '../annotations.interface';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IAnnotationColoredStatusProps {
  annotationStatus: AnnotationStatus | undefined;
  hasUnansweredClarification: boolean;
}

class AnnotationColoredStatusPure extends React.Component<IAnnotationColoredStatusProps & ITranslatable> {

  getStatusTextAndClass() {
    let textText;
    let statusClass;
    
    if (this.props.hasUnansweredClarification) {
      textText = this.props.t('waiting_for_clarification');
      statusClass = 'clarification';

      return { textText, statusClass };
    }

    switch (this.props.annotationStatus) {
      case AnnotationStatus.TOREVIEW:
        textText = this.props.t('waiting_for_approval');
        statusClass = 'annotated';
        break;
      case AnnotationStatus.ACCEPTED:
        textText = this.props.t('finished');
        statusClass = 'approved';
        break;
      case AnnotationStatus.DRAFT:
        textText = this.props.t('waiting_for_submission');
        statusClass = 'not-submitted';
        break;
      case AnnotationStatus.REJECTED:
        textText = this.props.t('waiting_for_correction');
        statusClass = 'rejected';
        break;
      default:
        textText = this.props.t('waiting_for_annotation');
        statusClass = 'not-annotated';
    }

    return { textText, statusClass };
  }

  render() {
    const { textText, statusClass } = this.getStatusTextAndClass();

    return <span className={`annotation-status ${statusClass}`}>{textText}</span>;
  }
}

export const AnnotationColoredStatus = as<React.ComponentClass<IAnnotationColoredStatusProps>>(withNamespaces('project')(AnnotationColoredStatusPure));
