import { DragDropContext, Draggable, DropResult, Droppable, ResponderProvided } from '@qzsoftware/react-beautiful-dnd';
import React, { CSSProperties } from 'react';
import { S_Pagination, S_PaginationProps } from '../../../design/pagination/S_Pagination';

import { GHeader } from './GHeader';
import { SortingDirection } from '../../models/sortingDirection.model';
import { debounce } from 'lodash';

const MIN_CELL_WIDTH = 120;

// We need to switch from string constants to enum
// These are compatibility helper functions
export const stringOrderToEnumDirection = (orderDirection: string | undefined) => {
  if (orderDirection === 'asc') {
    return SortingDirection.ASC;
  }
  if (orderDirection === 'desc') {
    return SortingDirection.DESC;
  }
  return undefined;
};

export const enumDirectionToStringOrder = (sortingDirection: SortingDirection | undefined): 'asc' | 'desc' | '' => {
  if (sortingDirection === SortingDirection.ASC) {
    return 'asc';
  }
  if (sortingDirection === SortingDirection.DESC) {
    return 'desc';
  }
  return '';
};

export interface IGTableProps<T> {
  columns: IGColumnProps<T>[];
  items: T[];
  noItemsPlaceholder?: string;
  getRowHeight?(item: T): number;
  paginationProps?: S_PaginationProps;
  sortingModel?: IGSortingModel;
  onSortChanged?(orderBy: string, orderType: string): void;
  enableDrag?: boolean;
  onRowDragEnd?(from: number, to: number): void;
}

export interface IGColumnProps<T> {
  headerName: string;
  field: string;
  width?: number;
  maxWidth?: number;
  minWidth?: number;
  sortable?: boolean;
  headerTooltip?: string;
  cellClass?: string;
  cellStyle?(item: T): CSSProperties;
  headerClassName?: string;
  renderer?(item: T): JSX.Element;
  headerRenderer?(): JSX.Element;
  isDragHandle?: boolean;
}

export type IGSortingModel = { orderBy: string; orderType: string }[];

interface IGTableState {
  containerWidth: number;
}

export interface IGRowModel {
  id: string;
  [field: string]: any;
}

export class GTable<T extends IGRowModel> extends React.Component<IGTableProps<T>, IGTableState> {
  constructor(props: IGTableProps<T>) {
    super(props);
    this.state = {
      containerWidth: 0,
    };
  }

  private container: any = null;
  private scroll: any = null;

  componentDidUpdate() {
    if (this.container && this.container.offsetWidth !== this.state.containerWidth) {
      this.setState({ containerWidth: this.container.offsetWidth });
    }
    this.reattachScrollEvent();
  }

  componentDidMount() {
    this.setState({ containerWidth: this.container.offsetWidth });

    const resize = debounce(() => this.container && this.setState({ containerWidth: this.container.offsetWidth }), 300);

    window.addEventListener('resize', () => {
      resize();
    });

    this.reattachScrollEvent();
  }

  reattachScrollEvent() {
    if (!this.scroll) return;
    this.scroll.removeEventListener('scroll', this.handleVerticalScroll);
    this.scroll.addEventListener('scroll', this.handleVerticalScroll);
  }

  handleVerticalScroll = (event: any) => {
    this.container.scrollLeft = event.target.scrollLeft;
  };

  getOnSortChanged(field: string) {
    return (orderType: string) => {
      if (this.props.onSortChanged !== undefined) {
        this.props.onSortChanged(field, orderType);
      }
    };
  }

  getDefaultCellWidth = (): { defaultCellWidth: number; tableWidth: number } => {
    let totalDefinedWidth = this.props.columns.filter(c => c.width !== undefined).reduce((sum: number, currentItem: IGColumnProps<T>) => sum + currentItem.width!, 0);
    let widthLeft = this.state.containerWidth - totalDefinedWidth;
    let cellsWithUndefinedWidthCount = this.props.columns.filter(c => c.width === undefined).length;
    const undefinedWidthCellWidth = widthLeft / cellsWithUndefinedWidthCount;

    const a = this.props.columns.filter(c => c.maxWidth !== undefined || c.minWidth !== undefined);
    for (const cell of a) {
      if (cell.maxWidth! < undefinedWidthCellWidth) {
        widthLeft = widthLeft - cell.maxWidth!;
        totalDefinedWidth = totalDefinedWidth + cell.maxWidth!;
        cellsWithUndefinedWidthCount -= 1;
      }
      if (cell.minWidth! > undefinedWidthCellWidth) {
        widthLeft = widthLeft - cell.minWidth!;
        totalDefinedWidth = totalDefinedWidth + cell.minWidth!;
        cellsWithUndefinedWidthCount -= 1;
      }
    }

    if (widthLeft > MIN_CELL_WIDTH * cellsWithUndefinedWidthCount) {
      return { defaultCellWidth: widthLeft / (cellsWithUndefinedWidthCount || 1), tableWidth: this.state.containerWidth };
    }

    return { defaultCellWidth: MIN_CELL_WIDTH, tableWidth: totalDefinedWidth + MIN_CELL_WIDTH * cellsWithUndefinedWidthCount };
  };

  getWidth = (c: IGColumnProps<T>, defaultWidth: number) => {
    if (c.width !== undefined) {
      return c.width;
    }
    if (c.maxWidth !== undefined) {
      if (c.maxWidth < defaultWidth) {
        return c.maxWidth;
      }
    }
    if (c.minWidth !== undefined) {
      if (c.minWidth > defaultWidth) {
        return c.minWidth;
      }
    }
    return defaultWidth;
  };

  getCellStyle = (c: IGColumnProps<T>, item: T, defaultWidth: number, rowHeight?: number) => {
    return {
      width: this.getWidth(c, defaultWidth),
      height: rowHeight,
      ...(c.cellStyle ? c.cellStyle(item) : {}),
    };
  };

  renderHeaders = (defaultWidth: number) => (
    <>
      {this.props.columns.map((c: IGColumnProps<T>) => {
        const orderType = this.props.sortingModel?.find(sm => sm.orderBy.toLocaleLowerCase() === c.field.toLocaleLowerCase())?.orderType?.toLocaleLowerCase();
        return (
          <GHeader
            key={`header-${c.headerName}`}
            name={c.headerName}
            className={c.headerClassName}
            tooltip={c.headerTooltip}
            width={this.getWidth(c, defaultWidth)}
            onSortChanged={c.sortable === false ? undefined : this.getOnSortChanged(c.field)}
            orderType={orderType}
            headerRenderer={c.headerRenderer}
          />
        );
      })}
    </>
  );

  renderRows = (defaultWidth: number) => (
    <>
      {this.props.items.map((item: T, itemIndex: number) => {
        const rowHeight = this.props.getRowHeight ? this.props.getRowHeight(item) : undefined;
        return (
          <Draggable key={`table-key-${item.id}`} draggableId={`table-key-${item.id}`} index={itemIndex} isDragDisabled={!this.props.enableDrag}>
            {provided => (
              <div className="g-row" style={{ height: rowHeight }} {...provided.draggableProps} ref={provided.innerRef}>
                {this.props.columns.map((c: IGColumnProps<T>, index: number) => (
                  <div
                    key={`${c.headerName}-${index}`}
                    className={`g-cell ${c.cellClass || ''} ${c.isDragHandle ? 'g-drag-handle' : ''}`}
                    style={this.getCellStyle(c, item, defaultWidth, rowHeight)}
                    {...(c.isDragHandle ? provided.dragHandleProps : {})}
                  >
                    {c.renderer ? c.renderer(item) : <span>{item[c.field]}</span>}
                  </div>
                ))}
              </div>
            )}
          </Draggable>
        );
      })}
    </>
  );

  renderNoItemsPlaceholder = () => this.props.noItemsPlaceholder && <div className="g-empty">{this.props.noItemsPlaceholder}</div>;

  handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (result.destination && this.props.onRowDragEnd) {
      let sourceIndex = result.source.index;
      let destinationIndex = result.destination.index;

      if (this.props.paginationProps) {
        const { pageNumber, pageSize } = this.props.paginationProps;
        const pageOffset = (pageNumber - 1) * pageSize;
        sourceIndex = pageOffset + sourceIndex;
        destinationIndex = pageOffset + destinationIndex;
      }

      this.props.onRowDragEnd(sourceIndex, destinationIndex);
    }
  };

  render() {
    const widths = this.getDefaultCellWidth();
    return (
      <>
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <div className="g-table-container" ref={(ref: any) => (this.container = ref)}>
            {this.state.containerWidth !== 0 && (
              <>
                <Droppable droppableId="g-droppable">
                  {provided => (
                    <div className="g-table" style={{ width: widths.tableWidth }} ref={provided.innerRef} {...provided.droppableProps}>
                      <div className="g-headers" style={{ minWidth: this.state.containerWidth }}>
                        {this.renderHeaders(widths.defaultCellWidth)}
                      </div>
                      <div className="g-rows" style={{ minWidth: this.state.containerWidth }}>
                        {this.props.items.length ? this.renderRows(widths.defaultCellWidth) : this.renderNoItemsPlaceholder()}
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                {this.props.paginationProps && <S_Pagination {...this.props.paginationProps} />}
              </>
            )}
          </div>
        </DragDropContext>
        {this.state.containerWidth < widths.tableWidth && (
          <div className="g-vertical-scroll-container" style={{ width: this.state.containerWidth }} ref={(ref: any) => (this.scroll = ref)}>
            <div className="g-vertical-scroll" style={{ width: widths.tableWidth }}>
              The Elder Scroll
            </div>
          </div>
        )}
      </>
    );
  }
}