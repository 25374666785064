import { FilterContent } from '../FilterContent';
import { ISelectOption } from '../../../../../../../helpers/formHelpers';
import { ITranslatable } from '../../../../../../../helpers/translations.helpers';
import { ImageAnnotationStatus } from '../../../projectDetailsImages.model';
import { ImageFiltersFormProps } from '../ImageFiltersForm';
import React from 'react';
import { ToogleButtonsMultiSelect } from '../../../../../../../components/ToogleButtonsMultiSelect';
import { as } from '../../../../../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

@observer
class StatusBodyPure extends React.Component<ImageFiltersFormProps & ITranslatable> {

  @autobind
  handleSelectedChanged(selected: ISelectOption<ImageAnnotationStatus>[]) {
    this.props.onSetStatuses(selected.map(x => x.value));
  }

  render() {
    const options: ISelectOption<ImageAnnotationStatus>[] = [
      { value: ImageAnnotationStatus.NONE, label: this.props.t('waiting_for_annotation') },
      { value: ImageAnnotationStatus.CLARIFICATION, label: this.props.t('waiting_for_clarification') },
      { value: ImageAnnotationStatus.DRAFT, label: this.props.t('waiting_for_submission') },
      { value: ImageAnnotationStatus.REJECTED, label: this.props.t('waiting_for_correction') },
      { value: ImageAnnotationStatus.TOREVIEW, label: this.props.t('waiting_for_approval') },
      { value: ImageAnnotationStatus.ACCEPTED, label: this.props.t('finished') },
    ];

    const selectedOptions = options.filter(o => this.props.imageFilters.annotationStatuses.includes(o.value));

    return (
      <FilterContent labelKey={'status'}>
        <ToogleButtonsMultiSelect<ISelectOption<ImageAnnotationStatus>>
          options={options}
          values={selectedOptions}
          labelField={'label'}
          valueField={'value'}
          onSelectChange={this.handleSelectedChanged}
        />
      </FilterContent>
    );
  }
}

export const StatusBody = as<React.ComponentClass<ImageFiltersFormProps>>(withNamespaces('project')(StatusBodyPure));
