import { Col, Row } from 'reactstrap';

import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  showPublishedAlert: boolean;
}

const PublishedProjectInfoPure = (props: ITranslatable & IProps) => {
  return (
    <>
      {props.showPublishedAlert && <Row>
        <Col>
          <div className="alert alert-info">
            <strong>{props.t('info')} </strong>{props.t('this_project_has_been_published')}
          </div>
        </Col>
      </Row>}
    </>
  );
};

export const PublishedProjectInfo = as<React.StatelessComponent<IProps>>(withNamespaces('project')(PublishedProjectInfoPure));
