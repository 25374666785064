import { IProjectDetailsImagesStore, ProjectDetailsImagesStoreType } from '../../projectDetailsImages.store';
import { as, injectProps } from '../../../../../../helpers/react.helpers';

import { FilteredImagesCounter } from '../../components/filters/FilteredImagesCounter';
import React from 'react';
import { observer } from 'mobx-react';

interface IInjectedProps {
  imagesStore: IProjectDetailsImagesStore;
}

@injectProps({
  imagesStore: ProjectDetailsImagesStoreType,
})
@observer
class FilteredImagesCounterContainerPure extends React.Component<IInjectedProps> {
  render() {
    const { imagesPaging } = this.props.imagesStore;

    return <FilteredImagesCounter totalFilteredCount={imagesPaging.totalCount} totalImagesCount={imagesPaging.totalImagesCount} />;
  }
}

export const FilteredImagesCounterContainer = as<React.ComponentClass>(FilteredImagesCounterContainerPure);
