import { PaginationItem } from 'reactstrap';
import React from 'react';
import { S_PaginationSelect } from '../paginationControls/paginationSelect/S_PaginationSelect';
import { S_PaginationText } from '../paginationControls/paginationText/S_PaginationText';
import { pageSizeOptions } from './S_PaginationPageSizeOptions';

interface Props {
  pageSize: number;
  onChange(selectedPageSize: number): void;
}

export const S_PaginationPageSize: React.FC<Props> = ({ pageSize, onChange }) => (
  <>
    <PaginationItem className="pagination-page-size-label">
      <S_PaginationText text="pagination.show" />
    </PaginationItem>
    <PaginationItem className="pagination-page-size-select">
      <S_PaginationSelect options={pageSizeOptions} selected={pageSize} onChange={onChange} />
    </PaginationItem>
  </>
);
