import * as React from 'react';

import { Button, Card, CardBody, CardTitle } from 'reactstrap';

import { withNamespaces } from 'react-i18next';
import { ITranslatable } from '../../../../__legacy__/helpers/translations.helpers';
import { Loader } from '../../../../__legacy__/components/Loader';
import { RestrictAccessTo } from '../../../../__legacy__/containers/RestrictAccessTo';
import { ProjectRole } from '../../../../__legacy__/models/userRole.model';
import { Link } from '../../../../__legacy__/containers/Link';
import { Home } from '../../../../__legacy__/routes/config/Home';
import { as } from '../../../../__legacy__/helpers/react.helpers';

interface IReviewOverviewWidgetProps {
  isLoading: boolean;
  toReviewCount: number;
  acceptedCount: number;
  rejectedCount: number;
  projectId: string;
  workspaceId: string;
}

const ReviewOverviewPure = (props: IReviewOverviewWidgetProps & ITranslatable) => {
  const total = props.acceptedCount + props.toReviewCount;
  const percentage = total === 0 ? 0 : ((props.acceptedCount / total) * 100).toFixed(2);

  return (
    <Card className="flex-fill card-with-list slim-margin">
      <CardBody>
        <Loader isLoading={props.isLoading}>
          <div className="info">
            <CardTitle tag="h5" className="name">
              {props.t('review_progress')} {props.acceptedCount}/{total} ({percentage}%)
            </CardTitle>
            <div className="list-row first">
              <div className="left">{props.t('waiting_for_review')}</div>
              <div className="right">{props.toReviewCount}</div>
            </div>
            <div className="list-row">
              <div className="left">{props.t('waiting_for_correction')}</div>
              <div className="right">{props.rejectedCount}</div>
            </div>
          </div>
          <RestrictAccessTo projectRoles={[ProjectRole.Reviewer]} workspaceRoles={[]} inProject={props.projectId}>
            <div className="bottom">
              <Link to={Home.Annotations.Review.withParams({ projectId: props.projectId, workspaceId: props.workspaceId })} className="float-left">
                <Button color="primary">{props.t('start_review')}</Button>
              </Link>
            </div>
          </RestrictAccessTo>
        </Loader>
      </CardBody>
    </Card>
  );
};

export const ReviewOverview = as<React.ComponentClass<IReviewOverviewWidgetProps>>(withNamespaces('project', { wait: true })(ReviewOverviewPure));
