import * as React from 'react';

import classNames from 'classnames';

interface IProps {
  id?: string;
  text: string;
  lines?: number;
  innerElement?: string;
  isDropDownAnswer?: boolean;
  onClick?(e: any): void;
}

export class ClampLines extends React.Component<IProps> {
  static defaultProps = {
    lines: 3,
    innerElement: 'div',
  };

  render() {
    return (
      <div
        onClick={this.props.onClick}
        className={classNames(
          {
            'answer-option': this.props.isDropDownAnswer,
          },
          'clamp-lines',
          `clamp-lines-${this.props.lines}`,
        )}
        id={this.props.id}
        title={this.props.text}
      >
        {React.createElement(this.props.innerElement!, null, this.props.text)}
      </div>
    );
  }
}
