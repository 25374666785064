import { StickerEvent } from '../../../services/eventBus.service';

export const WorkspaceCreatedEventType = Symbol('WORKSPACE_CREATED_EVENT');

export class WorkspaceCreatedEvent extends StickerEvent {
  constructor(public newWorkspaceId: string) {
    super();
  }

  get type(): Symbol {
    return WorkspaceCreatedEventType;
  }
}
