import * as React from 'react';

import { Button, ButtonProps } from 'reactstrap';

interface IProps extends ButtonProps {
  id?: string;
  to: string;
  target?: string;
  text?: string;
  title?: string;
  callback?(): void;
}

export class ExternalLinkedButton extends React.Component<IProps> {
  handleLinkClick = (e: any) => {
    e.preventDefault();
    if (this.props.callback) this.props.callback();
    window.open(this.props.to, this.props.target || '_blank');
  };

  render() {
    const { text, ...props } = this.props;
    return (
      <Button onClick={this.handleLinkClick} {...props}>
        {text}
        {this.props.children}
      </Button>
    );
  }
}
