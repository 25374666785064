import * as React from 'react';

import { AuthContainer } from '../../modules/auth/containers/AuthContainer';
import { AuthLogo } from '../../modules/auth/components/AuthLogo';
import {ConfirmNewEmail} from '../../modules/auth/components/emailConfirmation/ConfirmNewEmail';
import { ConfirmNewEmailContainer } from '../../modules/auth/containers/ConfirmNewEmailContainer';

export const ConfirmNewEmailView = () => (
  <div>
    <AuthContainer>
      <ConfirmNewEmailContainer>
        <AuthLogo />
        <ConfirmNewEmail />
      </ConfirmNewEmailContainer>
    </AuthContainer>
  </div>
);
