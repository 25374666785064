import * as React from 'react';

import { ConfigurationType, IConfiguration } from '../../../../configuration';
import { as, injectProps } from '../../../helpers/react.helpers';

import PrivacyPolicy from '../components/PrivacyPolicy';
import { observer } from 'mobx-react';

interface IProps {
  config: IConfiguration;
}

@injectProps({
  config: ConfigurationType,
})
@observer
class PrivacyPolicyContainerPure extends React.Component<IProps> {
  render() {
    return (
      !this.props.config.appConfig.runLocally && <PrivacyPolicy /> 
    );
  };
}

export const PrivacyPolicyContainer = as<React.ComponentClass>(PrivacyPolicyContainerPure);
