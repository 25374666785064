import { CurrentWorkspaceBl, CurrentWorkspaceBlType } from '../../CurrentWorkspace.bl';

import { CurrentWorkspaceSelectLinkInTable } from './CurrentWorkspaceSelectLinkInTable';
import React from 'react';
import { as } from '../../../../../common/dependencyInjection/as';
import { injectProps } from '../../../../../__legacy__/helpers/react.helpers';

interface Props {
  name: string;
  workspaceId: string;
  isEncrypted: boolean;
  isLocked: boolean;
  isMy: boolean;
}

interface InjectedProps {
  bl: CurrentWorkspaceBl;
}

type AllProps = InjectedProps & Props;

@injectProps({ bl: CurrentWorkspaceBlType })
class CurrentWorkspaceSelectLinkInTableContainerPure extends React.Component<AllProps> {
  render() {
    return (
      <CurrentWorkspaceSelectLinkInTable
        workspaceId={this.props.workspaceId}
        onClick={this.props.bl.selectWorkspace}
        name={this.props.name}
        isEncrypted={this.props.isEncrypted}
        isLocked={this.props.isLocked}
        isMy={this.props.isMy}
      />
    );
  }
}

export const CurrentWorkspaceSelectLinkTableContainer = as<React.ComponentClass<Props>>(CurrentWorkspaceSelectLinkInTableContainerPure);
