import { WithNamespaces, withNamespaces } from 'react-i18next';

import { IconedButton } from '../../../../../__legacy__/components/IconedButton';
import React from 'react';

interface IProps {
  disabled?: boolean;
  onClick(): void;
}

const RenameAttachmentIconedButtonPure: React.FC<IProps & WithNamespaces> = (props: IProps & WithNamespaces) => {
  const { onClick, disabled, t } = props;

  return (
    <IconedButton onClick={onClick} title={disabled ? t('you_do_not_have_permission_to_perform_this_operation') : t('attachments.rename_attachment')} icon={'edit'} disabled={disabled} />
  );
};

export const RenameAttachmentIconedButton = withNamespaces('new')(RenameAttachmentIconedButtonPure);
