import { ITranslatable } from '../helpers/translations.helpers';
import React from 'react';
import { as } from '../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  title?: string;
  hasAccess: boolean;
}

class NoAccessTooltipPure extends React.Component<IProps & ITranslatable> {
  render() {
    return React.Children.map(this.props.children, (child: any) => {
      return React.cloneElement(child, {
        disabled: child.props.disabled || !this.props.hasAccess,
        title: this.props.hasAccess ? child.props.title : this.props.title || this.props.t('you_do_not_have_permission_to_perform_this_operation'),
      });
    });
  }
}

export const NoAccessTooltip = as<React.ComponentClass<IProps>>(withNamespaces('common', { wait: true })(NoAccessTooltipPure));
