export class StickerError extends Error {
  apiErrorResponse?: ApiErrorResponse;

  constructor(m: string, apiErrorResponse?: ApiErrorResponse) {
    super(m);
    this.apiErrorResponse = apiErrorResponse;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, StickerError.prototype);
  }

  isBadRequestWithCode(codes: string[]) {
    return this.isBadRequest() && this.withCode(codes);
  }

  isBadRequest() {
    return this.apiErrorResponse?.statusCode === 400;
  }

  withCode(codes: string[]) {
    return this.apiErrorResponse?.errorCodes.some(r => codes.includes(r)) || false;
  }
}

export class UnauthorizedError extends StickerError {
  constructor() {
    super('Unauthorized', new ApiErrorResponse(401, 'Unauthorized', []));
    Object.setPrototypeOf(this, UnauthorizedError.prototype);
  }
}

export class ForbiddenError extends StickerError {
  constructor() {
    super('Forbidden', new ApiErrorResponse(403, 'Forbidden', []));
    Object.setPrototypeOf(this, ForbiddenError.prototype);
  }
}

export class LockedUserError extends StickerError {
  constructor() {
    super('Locked', new ApiErrorResponse(460, 'Locked', []));
    Object.setPrototypeOf(this, LockedUserError.prototype);
  }
}

export class PreprocessingError extends StickerError {
  constructor() {
    super('Preprocessing Error');
    Object.setPrototypeOf(this, PreprocessingError.prototype);
  }
}

export class VersionMissmatchError extends StickerError {
  constructor() {
    super('Version Missmatch Error');
    Object.setPrototypeOf(this, VersionMissmatchError.prototype);
  }
}

export class ApiErrorResponse {
  constructor(
    public readonly statusCode: number,
    public readonly message: string,
    public readonly errorCodes: string[],
  ) { }

  public readonly errorContext: any = {};
}

export class InputStatus {
  constructor(public isValid: boolean | undefined = undefined, public errorCodes: string[] = []) { }

  static empty(): InputStatus {
    return new InputStatus(undefined, []);
  }

  static valid(): InputStatus {
    return new InputStatus(true, []);
  }

  static invalid(): InputStatus {
    return new InputStatus(false, []);
  }

  static buildFrom(errorCodes: string[]) {
    return new InputStatus(errorCodes.length === 0, errorCodes);
  }

  addErrorCode(code: string) {
    this.errorCodes.push(code);
    this.isValid = false;
  }

  get isEmpty(): boolean {
    return this.isValid === undefined;
  }
}

export class OperationResult {
  public isSuccess: boolean = true;
  public Error?: StickerError = undefined;

  constructor(isSuccess: boolean, error: StickerError | undefined = undefined) {
    this.isSuccess = isSuccess;
    this.Error = error;
  }
}

export class Failed extends OperationResult {
  constructor(error: StickerError | undefined = undefined) {
    super(false, error);
    this.isHandled = false;
  }

  public isHandled: boolean;
}

export class Success extends OperationResult {
  constructor() {
    super(true);
  }
}

export class UploadSuccess extends OperationResult {
  public imageSize: number;

  constructor(imageSize: number) {
    super(true);
    this.imageSize = imageSize;
  }
}
