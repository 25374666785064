import { ITranslatable } from '../../../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  count: number;
}

const selectedImagesCounter = (props: ITranslatable & IProps) => (
  <div>
    {props.count > 0 && <>{props.count} {props.count > 1 ? props.t('images_selected') : props.t('image_selected')}</>}
  </div>
);

export const SelectedImagesCounter = as<React.FunctionComponent<IProps>>(withNamespaces('common')(selectedImagesCounter));
