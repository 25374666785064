import { IProjectDetailsImagesBl, ProjectDetailsImagesBlType } from '../../projectDetailsImages.bl';
import { IProjectDetailsImagesStore, ProjectDetailsImagesStoreType } from '../../projectDetailsImages.store';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../../../../helpers/react.helpers';

import { FiltersModal } from '../../components/filters/FiltersModal';
import { ITranslatable } from '../../../../../../helpers/translations.helpers';
import React from 'react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps extends ITranslatable, RouteComponentProps<{ workspaceId: string; projectId: string; filterId: string }> {
  projectDetailsImagesStore: IProjectDetailsImagesStore;
  projectDetailsImagesBl: IProjectDetailsImagesBl;
}

interface IFiltersProps {
  isOpen: boolean;
  toggle(): void;
}

@injectProps({
  projectDetailsImagesStore: ProjectDetailsImagesStoreType,
  projectDetailsImagesBl: ProjectDetailsImagesBlType,
})
class FiltersDropdownContainerPure extends React.Component<IFiltersProps & IInjectedProps> {
  async componentDidMount() {
    const { projectId, filterId } = this.props.match.params;
    await this.props.projectDetailsImagesBl.getImageTabDataAsync(projectId, filterId);
  }

  render() {
    const { availableFilters, imageFilters } = this.props.projectDetailsImagesStore;

    return <FiltersModal availableFilters={availableFilters} imageFilters={imageFilters} isOpen={this.props.isOpen} toggle={this.props.toggle} />;
  }
}

export const FiltersContainer = as<React.ComponentClass<IFiltersProps>>(withRouter(withNamespaces('project')(FiltersDropdownContainerPure)));
