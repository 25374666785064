import * as React from 'react';

import { Col, Container, Row } from 'reactstrap';
import { ModelPermissions, WorkspaceRole } from '../../../__legacy__/modules/workspaces/workspaces.store';
import { EvaluationDetailsInfoForm } from './EvaluationDetailsInfoForm';
import { EvaluationDetailsHeaderButtons } from './EvaluationDetailsHeaderButtons';
import { IEvaluationDetailsValidationErrors, IEvaluationProject } from '../evaluationDetails.store';
import { ValueType } from 'react-select/lib/types';
import { EVALUATION_STATUS } from '../../evaluations/evaluations.model';
import { IEvaluationDetailsDto, IEvaluationStatusDto, SelectOption } from '../evaluationDetails.model';
import { EvaluationDetailsTabsContainer } from '../containers/EvaluationDetailsTabs.contrainer';
import { EvaluationDetailsInfo } from './EvaluationDetailsInfo';
import { GlobalValidationErrorsContainer } from '../containers/GlobalValidationErrors.container';
import { MODEL_VARIANTS } from '../../../__legacy__/models/metrics.model';

interface IProps {
  isCreate: boolean;
  workspaceRole: WorkspaceRole;
  isOwner: boolean;
  isTrainingOwner: boolean;
  name: string;
  description: string;
  projectId: string;
  status: EVALUATION_STATUS;
  startingProgress: IEvaluationStatusDto['starting_progress'];
  failureReason: IEvaluationDetailsDto['failure_reason'];
  createdAt: string;
  modelVariant: MODEL_VARIANTS;
  projects: IEvaluationProject[];
  onChangeName: (name: string) => void;
  onChangeDescription: (description: string) => void;
  onChangeProject: (value: ValueType<SelectOption>) => void;
  downloadModelAsync(): void;
  startEvaluationAsync(): void;
  stopEvaluationAsync(): void;
  models: IEvaluationProject[];
  modelId: string;
  onChangeModel: (value: ValueType<SelectOption>) => void;
  handleBlurName: (name: string) => void;
  handleBlurProject: (projectId: string) => void;
  handleBlurModel: (modelId: string) => void;
  handleBlurDescription: (description: string) => void;
  errors: IEvaluationDetailsValidationErrors;
  handleChangeModelVariant: (value: ValueType<SelectOption>) => void;
  handleBlurModelVariant: (modelVariant: MODEL_VARIANTS) => void;
}

export const EvaluationDetailsHeader = (props: IProps) => {
  const {
    isCreate,
    isOwner,
    isTrainingOwner,
    workspaceRole,
    name,
    description,
    projectId,
    status,
    startingProgress,
    failureReason,
    createdAt,
    modelVariant,
    projects,
    models,
    modelId,
    onChangeModel,
    onChangeProject,
    onChangeName,
    onChangeDescription,
    downloadModelAsync,
    startEvaluationAsync,
    stopEvaluationAsync,
    errors,
    handleBlurName,
    handleBlurModel,
    handleBlurProject,
    handleBlurDescription,
    handleBlurModelVariant,
    handleChangeModelVariant,
  } = props;

  // If we are creating new evaluation check for run permissions, otherwise check update permissions
  const isAllDisabled = isCreate
    ? !ModelPermissions.runInference.includes(workspaceRole)
    : isOwner
    ? !ModelPermissions.updateInferenceWhenOwner.includes(workspaceRole)
    : !ModelPermissions.updateInference.includes(workspaceRole);

  return (
    <Container fluid>
      <Row>
        <Col>
          <EvaluationDetailsInfoForm
            name={name}
            description={description}
            projectId={projectId}
            status={status}
            modelVariant={modelVariant}
            projects={projects}
            modelId={modelId}
            models={models}
            onChangeModel={onChangeModel}
            onChangeProject={onChangeProject}
            onChangeName={onChangeName}
            onChangeDescription={onChangeDescription}
            onChangeModelVariant={handleChangeModelVariant}
            errors={errors}
            handleBlurName={handleBlurName}
            handleBlurProject={handleBlurProject}
            handleBlurModel={handleBlurModel}
            handleBlurDescription={handleBlurDescription}
            isAllDisabled={isAllDisabled}
            handleBlurModelVariant={handleBlurModelVariant}
          />
          {isCreate && <GlobalValidationErrorsContainer />}
        </Col>
        <Col xs={3}>{!isCreate && <EvaluationDetailsInfo status={status} failureReason={failureReason} startingProgress={startingProgress} createdAt={createdAt} />}</Col>
        <Col xs={2}>
          <EvaluationDetailsHeaderButtons
            isOwner={isOwner}
            isTrainingOwner={isTrainingOwner}
            status={status}
            downloadModelAsync={downloadModelAsync}
            startEvaluationAsync={startEvaluationAsync}
            stopEvaluationAsync={stopEvaluationAsync}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EvaluationDetailsTabsContainer />
        </Col>
      </Row>
    </Container>
  );
};
