import * as React from 'react';
import { withNamespaces } from 'react-i18next';
import { ITranslatable } from '../../../__legacy__/helpers/translations.helpers';
import { as } from '../../../__legacy__/helpers/react.helpers';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { toLocaleDateTimeString } from '../../../__legacy__/helpers/date.helpers';
import { EVALUATION_STATUS } from '../../evaluations/evaluations.model';
import { ProgressBar } from '../../../__legacy__/components/ProgressBar';
import { IEvaluationDetailsDto, IEvaluationStatusDto } from '../evaluationDetails.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

interface IProps {
  status: EVALUATION_STATUS;
  failureReason: IEvaluationDetailsDto['failure_reason'];
  startingProgress: IEvaluationStatusDto['starting_progress'];
  createdAt: string;
}

const EvaluationDetailsInfoPure = (props: IProps & ITranslatable) => {
  const { t, status, createdAt, startingProgress, failureReason } = props;

  const getStatusText = (status: EVALUATION_STATUS) => {
    let className = '';
    switch (status) {
      case EVALUATION_STATUS.RUNNING:
        className = 'text-warning';
        break;
      case EVALUATION_STATUS.STARTING:
      case EVALUATION_STATUS.STOPPING:
        className = 'text-secondary';
        break;
      case EVALUATION_STATUS.FAILED:
      case EVALUATION_STATUS.STOPPED:
        className = 'text-danger';
        break;
      case EVALUATION_STATUS.FINISHED:
      case EVALUATION_STATUS.IMPORTED:
        className = 'text-success';
    }
    return <span className={className}>{status}</span>;
  };

  const getProgressBar = (startingProgress: IEvaluationStatusDto['starting_progress']): React.ReactElement => {
    let progress = 0;
    switch (startingProgress) {
      case '1/4':
        progress = 25;
        break;
      case '2/4':
        progress = 50;
        break;
      case '3/4':
        progress = 75;
        break;
    }

    return <ProgressBar value={progress} enableStripesAnimation />;
  };

  return (
    <Card className="flex-fill card-with-list no-shadow dataset-details">
      <CardBody>
        <div className="info">
          <CardTitle tag="h5" className="name">
            {props.t('informations')}
          </CardTitle>
          {status !== EVALUATION_STATUS.UNKNOWN && (
            <>
              <div className="list-row first">
                <div className="left">{t('status')}</div>
                <div className="right">
                  {getStatusText(status)}
                  {(status === EVALUATION_STATUS.STOPPED || status === EVALUATION_STATUS.FAILED) && !!failureReason && (
                    <span className="training-failure-reason" title={failureReason}>
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </span>
                  )}
                  {status === EVALUATION_STATUS.STARTING && getProgressBar(startingProgress)}
                </div>
              </div>
              <div className="list-row">
                <div className="left">{t('common:createdDate')}</div>
                <div className="right">{toLocaleDateTimeString(createdAt)}</div>
              </div>
            </>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export const EvaluationDetailsInfo = as<React.FC<IProps>>(withNamespaces('evaluations')(EvaluationDetailsInfoPure));
