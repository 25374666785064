import { IAdminUserInfo, UserRole } from '../../user/user.store';
import { faCoins, faEdit, faLock, faUserTag } from '@fortawesome/free-solid-svg-icons';

import { IPagingInfoWithOrder } from '../../../models/paginationInfo.model';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { IconedButton } from '../../../components/IconedButton';
import { Loader } from '../../../components/Loader';
import React from 'react';
import { Settings } from '../../../../modules/settings/SettingsContext';
import { Table } from '../../../components/TableVisibleWhenWithData';
import { as } from '../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import countryList from 'react-select-country-list';
import { toLocaleDateTimeString } from '../../../helpers/date.helpers';
import { withNamespaces } from 'react-i18next';

export interface IAdminUsersListProps {
  currentUserId: string;
  users: IAdminUserInfo[];
  paging: IPagingInfoWithOrder;
  isLoading: boolean;
  onChangeRoleClicked(id: string, role: UserRole): void;
  onChangePlanClicked(id: string): void;
  onAddCreditsClicked(id: string): void;
  onChangePlanClicked(id: string): void;
  onLockAccountClicked(id: string): void;
  onPaginationChange(pageNumber: number, pageSize: number): void;
  onOrderingChange(orderBy: string, orderType: string): void;
  onSearchChange(search: string): void;
}

interface ICountry {
  value: string;
  label: string;
}

type Props = IAdminUsersListProps & ITranslatable;

class AdminUsersListPure extends React.Component<Props> {
  static contextType = Settings;
  declare context: React.ContextType<typeof Settings>;

  private countries: ICountry[];

  constructor(props: Props) {
    super(props);
    this.countries = countryList().getData();
  }

  get columns() {
    const columns = [
      {
        field: 'email',
        tooltipField: 'email',
        headerName: this.props.t('email'),
        pinned: 'left',
        autoHeight: false,
        suppressSizeToFit: false,
        minWidth: 400,
      },
      {
        field: 'name',
        tooltipField: 'name',
        headerName: this.props.t('name'),
      },
      {
        field: 'organization',
        tooltipField: 'organization',
        headerName: this.props.t('organization'),
      },
      {
        field: 'country',
        headerName: this.props.t('country'),
        valueFormatter: this.formatCountry,
      },
      {
        field: 'registrationDate',
        headerName: this.props.t('registration_date'),
        valueFormatter: this.formatDateTime,
        sortingOrder: ['desc', 'asc', 'null'],
        width: 180,
      },
      {
        field: 'lastLoginDate',
        headerName: this.props.t('last_login_date'),
        valueFormatter: this.formatDateTime,
        sortingOrder: ['desc', 'asc', 'null'],
        width: 180,
      },
      {
        field: 'workTime',
        headerName: this.props.t('worktime'),
        valueFormatter: this.formatTimeSpan,
        comparator: () => 0,
        sortingOrder: ['desc', 'asc', 'null'],
        width: 120,
      },
      {
        field: 'role',
        headerName: this.props.t('role'),
        width: 130,
      },
      {
        field: 'plan',
        headerName: this.props.t('plan'),
        width: 120,
      },
      {
        field: 'availableCredits',
        headerName: this.props.t('available_credits'),
        width: 150,
      },
      {
        field: 'customLimits',
        headerName: this.props.t('custom_limits'),
        valueFormatter: this.formatBool,
        width: 130,
      },
      {
        field: 'isActive',
        headerName: this.props.t('active'),
        valueFormatter: this.formatBool,
        width: 100,
      },
      {
        field: 'dataSizeInMb',
        headerName: this.props.t('files_size'),
        valueFormatter: this.formatFileSize,
        width: 120,
      },
      {
        field: 'actions',
        headerName: this.props.t('actions'),
        cellRenderer: 'actionsRenderer',
        autoHeight: false,
        width: 140,
        pinned: 'right',
        sortable: false,
      },
    ];
    return columns;
  }

  get defaultColDef() {
    return {
      autoHeight: true,
      suppressSizeToFit: true,
      sortable: true,
    };
  }

  @autobind
  handleChangeRoleClicked(id: string) {
    const role = this.props.users.find(u => u.id === id)!.role;
    return () => this.props.onChangeRoleClicked(id, role);
  }

  handleChangePlanClicked = (id: string) => () => this.props.onChangePlanClicked(id);
  handleAddCreditsClicked = (id: string) => () => this.props.onAddCreditsClicked(id);
  handleLockAccountClicked = (id: string) => () => this.props.onLockAccountClicked(id);

  formatDateTime = (params: any) => {
    return toLocaleDateTimeString(params.value);
  };

  formatTimeSpan = (params: any) => {
    const timespan = params.value;
    if (timespan.includes('.')) {
      return timespan.substr(0, timespan.lastIndexOf('.'));
    }
    return timespan;
  };

  formatCountry = (params: any) => {
    const countryCode = params.value;
    if (!countryCode) return '';
    const country = this.countries.find((o: ICountry) => o.value === countryCode);
    return country ? country.label : '';
  };

  formatBool = (params: any) => this.props.t(params.value ? 'yes' : 'no');

  formatFileSize = (params: any) => {
    if (params.value) {
      return this.props.t(params.value > 1024 ? `${(params.value / 1024).toFixed(2)} GB` : `${params.value.toFixed(2)} MB`);
    }

    return '0 MB';
  };

  renderActions(role: string, id: string) {
    return (
      <>
        {this.props.currentUserId !== id && <IconedButton onClick={this.handleChangeRoleClicked(id)} icon={faUserTag} color="red" title={this.props.t('change_user_role')} />}
        {role !== UserRole.Administrator && (
          <>
            <IconedButton onClick={this.handleAddCreditsClicked(id)} icon={faCoins} title={this.props.t('add_credits')} />
            <IconedButton onClick={this.handleChangePlanClicked(id)} icon={faEdit} title={this.props.t('change_user_plan')} />
            <IconedButton onClick={this.handleLockAccountClicked(id)} icon={faLock} color="red" title={this.props.t('lock_account')} />
          </>
        )}
      </>
    );
  }

  @autobind
  onSortChanged(params: any) {
    const sorting = params.api.getSortModel()[0];
    let orderBy = '';
    let orderType = '';
    if (sorting !== undefined) {
      orderBy = sorting.colId;
      orderType = sorting.sort;
    }

    this.props.onOrderingChange(orderBy, orderType);
  }

  @autobind
  handlePageChange(pageNumber: number, pageSize: number) {
    this.context.setAdminPanelPageSize(pageSize);
    this.props.onPaginationChange(pageNumber, pageSize);
  }

  render() {
    return (
      <Loader isLoading={this.props.isLoading}>
        <div className="table-container admin-table-with-users">
          <Table
            onSortChanged={this.onSortChanged}
            columnDefs={this.columns}
            defaultColDef={this.defaultColDef}
            rowData={this.props.users}
            animateRows={true}
            suppressDragLeaveHidesColumns={true}
            onSearchChange={this.props.onSearchChange}
            enableBrowserTooltips={true}
            paginationProps={{
              pageNumber: this.props.paging.pageNumber,
              pageSize: this.context.store.adminPanelPageSize,
              totalCount: this.props.paging.totalCount,
              onChange: this.handlePageChange,
            }}
            frameworkComponents={{
              actionsRenderer: ({ data }: { data: IAdminUserInfo }) => this.renderActions(data.role, data.id),
            }}
          />
        </div>
      </Loader>
    );
  }
}

export const AdminUsersList = as<React.ComponentClass>(withNamespaces('common', { wait: true })(AdminUsersListPure));
