import { S_Modal, S_ModalPropsBase } from '../modal/S_Modal';

import React from 'react';
import { S_BarLoader } from '../../loaders/barLoader/S_BarLoader';

export const S_LoadingModal: React.FC<S_ModalPropsBase> = (props: S_ModalPropsBase) => (
  <S_Modal {...props}>
    {props.children}
    <S_BarLoader />
  </S_Modal>
);
