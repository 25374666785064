import { IStatsBase } from '../../general/models/GeneralStats';

export enum MarkingToolType {
  None = 'None',
  Point = 'Point',
  Rectangle = 'Rectangle',
  Vector = 'Vector',
  Polyline = 'Polyline',
  Polygon = 'Polygon',
  RotatedRectangle = 'RotatedRectangle',
  Brush = 'Brush',
}

export interface IAnnotationToolsStats extends IStatsBase {
  added: number;
  modified: number;
  deleted: number;
  annotationPoints: number;
  reviewPoints: number;
  editPoints: number;
  totalPoints: number;
  velocity: number;
  markingToolsStats: IMarkingToolStats[];
  questionsStats: IQuestionStats[];
}

export interface IMarkingToolStats {
  id: string;
  name: string;
  added: number;
  modified: number;
  deleted: number;
  markingToolType: MarkingToolType;
  color: string;
  annotationPoints: number;
  reviewPoints: number;
  editPoints: number;
  totalPoints: number;
  attributesStats: IQuestionStats[];
}

export interface IQuestionStats {
  id: string;
  text: string;
  added: number;
  modified: number;
  deleted: number;
  questionType: string;
  annotationPoints: number;
  reviewPoints: number;
  editPoints: number;
  totalPoints: number;
}
