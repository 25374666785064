import * as React from 'react';

import { Col, Container, Row } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { faAngleLeft, faRedo, faUndo } from '@fortawesome/free-solid-svg-icons';

import AnnotationImageNameHeaderContainer from '../../../modules/annotation/containers/AnnotationImageNameHeader.container';
import { AnnotationMoreOptions } from '../../../modules/annotation/components/AnnotationMoreOptions';
import { AnnotationProgressContainer } from '../../../modules/annotation/containers/AnnotationProgress.container';
import { AnnotationStatusInfoContainer } from '../../../modules/annotation/containers/AnnotationStatusInfoContainer';
import { AnnotationTopBarItem } from '../../../modules/annotation/components/AnnotationTopBarItem';
import { AnnotationTypesContainer } from '../../../modules/annotation/submodules/annotationTypes/containers/AnnotationTypes.container';
import { AnnotationViewOptions } from '../../../modules/annotation/components/AnnotationViewOptions';
import { BackToProjectsButtonWithDraftContainer } from '../../../modules/annotation/containers/BackToProjectsButtonWithDraftContainer';
import { CreateAnnotationContainer } from '../../../modules/annotation/containers/CreateAnnotation.container';
import { CreateControlsContainer } from '../../../modules/annotation/containers/CreateControls.container';
import { CurrentWorkspaceContainer } from '../../../modules/workspaces/containers/CurrentWorkspace.container';
import { DrawingContainer } from '../../../modules/annotation/containers/Drawing.container';
import { HiddenAnnotationsToastContainer } from '../../../containers/HiddenAnnotationsToast.container';
import Image from '../../../modules/annotation/components/Image';
import ImageContainer from '../../../modules/annotation/containers/Image.container';
import { ImageSegmentationsContextProvider } from '../../../modules/annotation/submodules/segmentations/segmentationContextMenu/imageSegmentations.context';
import { LeftMenuContainer } from '../../../modules/annotation/submodules/segmentations/containers/LeftMenu.container';
import { NoImagePlaceholder } from '../../../modules/annotation/components/NoImagePlaceholder';
import { ProjectRole } from '../../../models/userRole.model';
import QuestionsContainer from '../../../modules/annotation/containers/Questions.container';
import QuestionsList from '../../../modules/annotation/components/annotations/QuestionsList';
import RedirectFromAnnotationContainer from '../../../modules/annotation/containers/RedirectFromAnnotationContainer';
import ReturnToToolsContainer from '../../../modules/annotation/containers/ReturnToTools.container';
import { SegmentationsContainer } from '../../../modules/annotation/containers/Segmentations.container';
import SegmentationsSaveContainer from '../../../modules/annotation/containers/SegmentationsSave.container';
import { UndoRedoAnnotationsShortcutsContainer } from '../../../modules/annotation/containers/UndoRedoAnnotationsShortcuts.container';
import { UnlockWorkspaceModal } from '../../../modules/workspaces/components/UnlockWorkspaceModal';
import ViewAttributesButton from '../../../modules/annotation/components/ViewAttributesButton';
import ViewAttributesContainer from '../../../modules/annotation/containers/ViewAttributesContainer';
import { as } from '../../../helpers/react.helpers';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faUndo, faRedo, faAngleLeft);

class AnnotationCreateViewPure extends React.Component<RouteComponentProps<{ workspaceId: string; projectId: string }>> {
  render() {
    return (
      <div className="annotation-view">
        <CurrentWorkspaceContainer>{unlockModalProps => <UnlockWorkspaceModal {...unlockModalProps} />}</CurrentWorkspaceContainer>
        <RedirectFromAnnotationContainer allowedRoles={[ProjectRole.Annotator, ProjectRole.Reviewer]} />
        <CreateAnnotationContainer>
          {createAnnotationProps => (
            <ImageSegmentationsContextProvider imageId={createAnnotationProps.image?.id}>
              <div className="annotation-view-top-bar" style={{ marginTop: createAnnotationProps.marginTop }}>
                <Container fluid>
                  <AnnotationTopBarItem variation="labeled-icon">
                    <BackToProjectsButtonWithDraftContainer />
                  </AnnotationTopBarItem>
                  <AnnotationTopBarItem>
                    <UndoRedoAnnotationsShortcutsContainer undo={true} />
                  </AnnotationTopBarItem>
                  <AnnotationTopBarItem>
                    <UndoRedoAnnotationsShortcutsContainer undo={false} />
                  </AnnotationTopBarItem>
                  <AnnotationTopBarItem variation="labeled-icon">
                    <AnnotationViewOptions disableCursorConfig={false} />
                  </AnnotationTopBarItem>
                  <AnnotationTopBarItem variation="show-attributes">
                    <ViewAttributesContainer>
                      <ViewAttributesButton />
                    </ViewAttributesContainer>
                  </AnnotationTopBarItem>
                  <AnnotationTopBarItem variation="image-name">
                    <AnnotationImageNameHeaderContainer />
                    <AnnotationProgressContainer />
                  </AnnotationTopBarItem>
                  <AnnotationTopBarItem variation="right-side more">
                    <AnnotationMoreOptions create globalShortcuts={[]} />
                  </AnnotationTopBarItem>
                </Container>
              </div>
              <div className="annotation-view-inner" style={{ marginTop: createAnnotationProps.marginTop + 70, height: `calc(100vh - ${70 + createAnnotationProps.marginTop}px)` }}>
                <Container fluid>
                  <Row>
                    <LeftMenuContainer currentProjectId={this.props.match.params.projectId} isReadOnly={false} showObjectsList={true} isReview={false} />
                    <Col className="px-0">
                      <HiddenAnnotationsToastContainer />
                      <ImageContainer>
                        {createAnnotationProps.noImage ? (
                          <NoImagePlaceholder
                            onRefresh={createAnnotationProps.handleRefresh}
                            noImageReason={createAnnotationProps.noImageReason}
                            mode={'Annotate'}
                            workspaceId={this.props.match.params.workspaceId}
                          />
                        ) : (
                          <Image>
                            <SegmentationsContainer />
                            <DrawingContainer disableCursorGuides={false} />
                          </Image>
                        )}
                      </ImageContainer>
                    </Col>
                    <div className="right-column" style={{ height: `calc(100vh - ${70 + createAnnotationProps.marginTop}px)` }}>
                      <div className="scrollable-container">
                        <div className="scrollable-container-content">
                          <AnnotationTypesContainer>
                            <AnnotationStatusInfoContainer />
                            <ReturnToToolsContainer />
                          </AnnotationTypesContainer>
                          <QuestionsContainer>
                            <QuestionsList />
                          </QuestionsContainer>
                          <SegmentationsSaveContainer />
                        </div>
                      </div>
                      <div className="set-navigation-container">
                        <CreateControlsContainer />
                      </div>
                    </div>
                  </Row>
                </Container>
              </div>
            </ImageSegmentationsContextProvider>
          )}
        </CreateAnnotationContainer>
      </div>
    );
  }
}

export const AnnotationCreateView = as<React.ComponentClass>(withRouter(AnnotationCreateViewPure));
