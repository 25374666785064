import * as React from 'react';

import { AuthStoreType, IAuthStore } from '../modules/auth/auth.store';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router-dom';
import { as, injectProps } from '../helpers/react.helpers';

import { Auth } from '../routes/config/Auth';
import autobind from 'autobind-decorator';

interface IInjectedProps {
  authStore: IAuthStore;
}

interface IPrivateRouteProps extends IInjectedProps, RouteProps {}

@injectProps({ authStore: AuthStoreType })
@autobind
class PrivateRoutePure extends React.Component<IPrivateRouteProps> {
  routeRenderer(props: RouteComponentProps): React.ReactNode {
    const {
      component,
      authStore: { isAuthenticated },
    } = this.props;
    const Component: any = component;
    const redirectOptions = {
      pathname: Auth.Login.Path,
      state: { from: this.props.location },
    };

    return isAuthenticated ? <Component {...props} /> : <Redirect to={redirectOptions} />;
  }
  render() {
    const { component, authStore, ...rest } = this.props;
    return <Route {...rest} render={this.routeRenderer} />;
  }
}

export const PrivateRoute = as<React.ComponentClass<RouteProps>>(PrivateRoutePure);

interface ISwitchProps {
  children: React.ReactElement<any>[] | React.ReactElement<any>;
  notFound: any;
}

export const SwitchWithNotFound = ({ children, notFound: Component }: ISwitchProps) => (
  <Switch>
    {children}
    <Route component={Component} />
  </Switch>
);
