import * as React from 'react';

import Bowser from 'bowser';
import BrowserCompatibilityWarning from '../components/BrowserCompatibilityWarning';
import autobind from 'autobind-decorator';

export class BrowserCompatibilityContainer extends React.Component {
  state = { showModal: !this.isBrowserSupported() };

  isBrowserSupported() {
    const browser = Bowser.parse(window.navigator.userAgent);
    return ['Firefox', 'Chrome'].includes(browser.browser.name || 'unknown') && browser.platform.type === 'desktop';
  }

  @autobind
  handleClose() {
    this.setState({ showModal: false });
  }

  render() {
    return this.state.showModal && <BrowserCompatibilityWarning onClose={this.handleClose} />;
  }
}
