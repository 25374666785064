import { ExportDataBlType, IExportDataBl } from '../../ExportData.bl';
import { ExportDataStore, ExportDataStoreType } from '../../ExportData.store';
import { as, injectProps } from '../../../../../../__legacy__/helpers/react.helpers';

import { DownloadExportModal } from './DownloadExportModal';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: IExportDataBl;
  store: ExportDataStore;
}

@injectProps({
  bl: ExportDataBlType,
  store: ExportDataStoreType,
})
@observer
class DownloadExportModalContainerPure extends React.Component<InjectedProps & RouteComponentProps<{ projectId: string }>> {
  handleHide = () => {
    this.props.bl.hideDownloadModal();
  };

  render() {
    return <DownloadExportModal show={this.props.store.showDownloadModal} onHide={this.handleHide} downloadLink={this.props.store.downloadLink} />;
  }
}

export const DownloadExportModalContainer = as<React.ComponentClass>(DownloadExportModalContainerPure);
