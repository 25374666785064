import { ImportAnnotationsBl, ImportAnnotationsBlType } from '../../../importAnnotations.bl';
import { ImportAnnotationsStore, ImportAnnotationsStoreType } from '../../../importAnnotations.store';

import { ImportAnnotationsStep } from '../../../models/ImportAnnotationsSteps';
import { InformationStepModal } from './InformationStepModal';
import React from 'react';
import { as } from '../../../../../../../../common/dependencyInjection/as';
import { injectProps } from '../../../../../../../../__legacy__/helpers/react.helpers';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: ImportAnnotationsBl;
  store: ImportAnnotationsStore;
}

@injectProps({
  bl: ImportAnnotationsBlType,
  store: ImportAnnotationsStoreType,
})
@observer
class InformationStepContainerPure extends React.Component<InjectedProps> {
  handleHide = () => {
    this.props.bl.cancelImport();
  };

  handleSubmit = () => {
    this.props.bl.goToSelectFileStep();
  };

  get isCurrentStep() {
    return this.props.store.currentStep === ImportAnnotationsStep.Information;
  }

  render() {
    return <InformationStepModal onHide={this.handleHide} onSubmit={this.handleSubmit} show={this.isCurrentStep} />;
  }
}

export const InformationStepContainer = as<React.ComponentClass>(InformationStepContainerPure);
