import * as React from 'react';

import { CryptoServiceType, ICryptoService } from '../../../services/crypto.service';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { EventBusType, IEventBus } from '../../../services/eventBus.service';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { IRouterStore, RouterStoreType } from '../../../stores/router.store';
import { IUserService, UserServiceType } from '../../user/user.service';
import { IWorkspaceService, WorkspaceServiceType } from '../workspaces.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { ICurrentWorkspaceRoleProps } from '../components/CurrentWorkspaceRole';
import { IUnlockModalProps } from '../components/UnlockWorkspaceModal';
import { WorkspaceDecryptionFailedEvent } from '../events/workspaceDecryptionFailedEvent';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IOwnProps {
  children(unlockModalProps: IUnlockModalProps, currentWorkspaceRoleProps: ICurrentWorkspaceRoleProps): React.ReactNode;
}

interface IInjectedProps {
  workspaceService: IWorkspaceService;
  cryptoService: ICryptoService;
  router: IRouterStore;
  userService: IUserService;
  eventBus: IEventBus;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

interface IState extends ILoaderState {
  desiredWorkspaceId?: string;
  isUnlockModalShown: boolean;
  expectedChecksum?: string;
  wrongKeyFile: boolean;
  workspaceName?: string;
}

@injectProps({
  userService: UserServiceType,
  workspaceService: WorkspaceServiceType,
  cryptoService: CryptoServiceType,
  router: RouterStoreType,
  eventBus: EventBusType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
@observer
class CurrentWorkspaceContainerPure extends WithLoaderComponentBase<IOwnProps & IInjectedProps & RouteComponentProps<{ workspaceId?: string }>, IState> {
  constructor(props: IOwnProps & IInjectedProps & RouteComponentProps) {
    super(props);
    this.state = {
      isUnlockModalShown: false,
      expectedChecksum: undefined,
      desiredWorkspaceId: undefined,
      wrongKeyFile: false,
      isLoading: false,
    };
  }

  async componentDidMount() {
    const workspace = this.props.currentWorkspaceStore.currentWorkspace;
    if (workspace && workspace.encryption.encrypted && !(await this.props.cryptoService.checkKey(workspace.id, workspace.id))) {
      await this.tryOpenWorkspace(workspace.id);
    }
  }

  // tslint:disable-next-line:function-name
  async UNSAFE_componentWillReceiveProps(props: IInjectedProps & RouteComponentProps) {
    const workspace = props.currentWorkspaceStore.currentWorkspace;
    if (this.state.desiredWorkspaceId === workspace?.id) return;
    if (workspace && workspace.encryption.encrypted && !(await props.cryptoService.checkKey(workspace.id, workspace.id)) && !this.state.desiredWorkspaceId) {
      await this.tryOpenWorkspace(workspace.id);
    } else {
      this.setState({ isUnlockModalShown: false, desiredWorkspaceId: undefined, expectedChecksum: undefined, wrongKeyFile: false });
    }
  }

  @autobind
  async tryOpenWorkspace(desiredWorkspaceId: string) {
    const desiredWorkspace = this.props.workspaceService.store.workspaces.find(w => w.id === desiredWorkspaceId)!;
    if (desiredWorkspace.encryption.encrypted && !this.props.cryptoService.hasKey(desiredWorkspaceId)) {
      this.showUnlockModal(desiredWorkspaceId, desiredWorkspace.encryption.checksum);
      return;
    }
  }

  @autobind
  async tryUnlockWorkspace(desiredWorkspaceId: string, key?: string): Promise<void> {
    if (this.state.expectedChecksum && key !== undefined) {
      if (!(await this.props.cryptoService.loadKey(desiredWorkspaceId, key, desiredWorkspaceId, this.state.expectedChecksum))) {
        this.setState({ wrongKeyFile: true });
        return;
      }
    }

    this.setState({ isUnlockModalShown: false, desiredWorkspaceId: undefined, expectedChecksum: undefined, wrongKeyFile: false });
  }

  @autobind
  showUnlockModal(desiredWorkspaceId: string, expectedChecksum: string) {
    const workspaceName = this.props.workspaceService.store.workspaces.find(w => w.id === desiredWorkspaceId)?.name;
    this.setState({ desiredWorkspaceId, expectedChecksum, workspaceName, isUnlockModalShown: true });
  }

  @autobind
  async onFilesDrop(files: File[]): Promise<void> {
    this.tryUnlockWorkspace(this.state.desiredWorkspaceId!, await files[0].text());
  }

  @autobind
  onWrongFilesDrop(files: File[]) {
    this.setState({ wrongKeyFile: true });
  }

  @autobind
  onCancel() {
    this.props.eventBus.sendEvent(new WorkspaceDecryptionFailedEvent());
  }

  @autobind
  async onMenuOpen() {
    this.withLoaderAsync(async () => this.props.userService.getUserInfoAsync());
  }

  render() {
    return (
      <>
        {this.props.children(
          {
            onCancel: this.onCancel,
            onFilesDrop: this.onFilesDrop,
            onWrongFilesDrop: this.onWrongFilesDrop,
            showUnlockModal: this.state.isUnlockModalShown,
            wrongKeyFile: this.state.wrongKeyFile,
            workspaceName: this.state.workspaceName,
          },
          {
            role: this.props.currentWorkspaceStore.currentWorkspace?.role,
          },
        )}
      </>
    );
  }
}

export const CurrentWorkspaceContainer = as<React.SFC<IOwnProps>>(withRouter(CurrentWorkspaceContainerPure));
