import { WithNamespaces, withNamespaces } from 'react-i18next';

import React from 'react';
import { S_LoadingModal } from '../../../../../../../../design/modals/loadingModal/S_LoadingModal';

interface Props {
  show: boolean;
}

const ProcessingStepModalPure: React.FC<Props & WithNamespaces> = (props: Props & WithNamespaces) => {
  const { t, show } = props;
  return (
    <S_LoadingModal headerText={`${t('new.projects.details.import_annotations.header')} - ${t('new.projects.details.import_annotations.steps.processing.header')}`} show={show}>
      <p>{t('new.projects.details.import_annotations.steps.processing.body')}</p>
    </S_LoadingModal>
  );
};

export const ProcessingStepModal = withNamespaces('common')(ProcessingStepModalPure);
