import { ContainerModule, interfaces } from 'inversify';
import {
  INotificationsService,
  NotificationsService,
  NotificationsServiceType,
} from './services/notifications.service';
import { INotificationsStore, NotificationsStore, NotificationsStoreType } from './stores/notifications.store';

export const notificationsModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<INotificationsService>(NotificationsServiceType)
    .to(NotificationsService)
    .inSingletonScope();
  bind<INotificationsStore>(NotificationsStoreType)
    .to(NotificationsStore)
    .inSingletonScope();
});
