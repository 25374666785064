import { ICanPublishProjectResponse } from '../projectDetails.models';

export const ProjectDetailsSubmoduleType = Symbol('PROJECT_DETAILS_SUBMODULE');

export interface IProjectDetailsSubmodule {
  initialize(): void;
  cleanup(): void;
  validateAsync(): Promise<void>;
  getValidationErrors(): string[];
  handleCanPublishCheck(response: ICanPublishProjectResponse): void;
}
