import { injectable, inject } from 'inversify';
import { AuthStoreSetterType, IAuthStoreSetter } from '../modules/auth/auth.store';
import { RouterStoreType, IRouterStore } from '../stores/router.store';
import { UnauthorizedError, ForbiddenError, LockedUserError, StickerError, VersionMissmatchError } from '../models/error.model';
import { Home } from '../routes/config/Home';
import { NotificationsStoreType, INotificationsStore } from '../modules/notifications/stores/notifications.store';
import { PolicyLimitExceededNotification } from '../modules/notifications/models/notification.model';
import _ from 'lodash';
import { GLOBAL_ERROR_CODES } from '../helpers/global.constants';
import { clearPageCache } from '../helpers/cache.helpers';
import { ErrorsServiceType, IErrorsService } from '../modules/errors/errors.service';
import { ICurrentWorkspaceStore, CurrentWorkspaceStoreType } from '../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';

export const ApiPolicyProviderType = Symbol('API_POLICY_PROVIDER');

export interface IPolicy {
  [index: number]: (error?: any) => Promise<StickerError>;
}

export interface IApiPolicyProvider {
  getGlobal(): IPolicy;
}

@injectable()
export class ApiPolicyProvider implements IApiPolicyProvider {
  constructor(
    @inject(AuthStoreSetterType) public readonly authStore: IAuthStoreSetter,
    @inject(NotificationsStoreType) private readonly notificationStore: INotificationsStore,
    @inject(RouterStoreType) private readonly routerStore: IRouterStore,
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore,
    @inject(ErrorsServiceType) private readonly errorsService: IErrorsService,
  ) { }

  getGlobal(): IPolicy {
    const policy: IPolicy = {};

    policy[400] = async (error?: any) => {
      if (error.response.data) {
        const errorCodes = error.response.data.errorCodes;

        if (errorCodes && errorCodes instanceof Array && errorCodes.length > 0) {
          if (errorCodes.includes(GLOBAL_ERROR_CODES.POLICY_LIMITS_EXCEEDED)) {
            this.notificationStore.notifications.push(new PolicyLimitExceededNotification());
            this.routerStore.push(Home.Projects.List.All.withParams({ workspaceId: this.currentWorkspaceStore.currentWorkspace!.id }));
          }
        }
      }

      return new StickerError(error.statusText, error.response.data);
    };

    policy[401] = async () => {
      this.authStore.clearToken();
      return new UnauthorizedError();
    };

    policy[402] = async () => {
     // this.authStore.clearToken();
     //  clearPageCache(true);
     // window.location.reload();
      return new ForbiddenError();
    };

    policy[403] =  async () => {
      this.errorsService.setUnauthorized();
      return new ForbiddenError();
    };

    policy[417] = async () => {
      this.authStore.setVersionChanged();
      this.authStore.clearToken();
      clearPageCache(true);
      return new VersionMissmatchError();
    };

    policy[433] =  async () => {
      this.errorsService.setUnauthorized();
      return new ForbiddenError();
    };

    policy[460] = async () => {
      this.routerStore.push(Home.Locked.Path);
      return new LockedUserError();
    };

    return policy;
  }
}
