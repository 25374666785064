import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import React, { useState } from 'react';

import { CursorGuidesCheckboxContainer } from '../../../../modules/editor/cursorControl/cursorGuidesCheckbox/CursorGuidesCheckbox.container';
import { CursorVisibilityCheckboxContainer } from '../../../../modules/editor/cursorControl/cursorVisibilityCheckbox/CursorVisibilityCheckbox.container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { OpacityContainer } from '../containers/OpacityContainer';
import { ZoomContainer } from '../containers/ZoomContainer';
import { as } from '../../../helpers/react.helpers';
import { faMagic } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';

library.add(faMagic);

interface IProps {
  disableCursorConfig: boolean;
  children?: React.ReactElement<any>[] | React.ReactElement<any>;
}

const AnnotationViewOptionsPure = (props: IProps & ITranslatable) => {
  const { disableCursorConfig, t } = props;
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(prevState => !prevState);

  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle tabIndex={-1}>
        <FontAwesomeIcon icon={faMagic} /> {t('view_options')}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem toggle={false}>
          <ZoomContainer />
        </DropdownItem>
        <DropdownItem toggle={false}>
          <OpacityContainer />
        </DropdownItem>
        <DropdownItem toggle={false}>
          <CursorVisibilityCheckboxContainer disabled={disableCursorConfig} />
        </DropdownItem>
        <DropdownItem toggle={false}>
          <CursorGuidesCheckboxContainer disabled={disableCursorConfig} />
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export const AnnotationViewOptions = as<React.FunctionComponent<IProps>>(withNamespaces('annotation')(AnnotationViewOptionsPure));
