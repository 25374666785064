export enum RejectStatus {
  TYPE = 'invalid_file_type',
  SIZE = 'invalid_file_size',
  CONTENT = 'invalid_file_content',
  UNREADABLE = 'file_is_unreadable',
  MISSING_IMAGE = 'missing_image_file',
  DUPLICATED_NAME = 'duplicated_file_name',
  INVALID_URL = 'invalid_url',
  DUPLICATED_URL = 'duplicated_url',
  URL_TOO_LONG = 'url_too_long',
}
