import { EditMarkingToolBl, EditMarkingToolBlType } from '../../EditMarkingTool.bl';
import { EditMarkingToolPermissions, EditMarkingToolPermissionsType } from '../../EditMarkingTool.permissions';
import { EditMarkingToolStore, EditMarkingToolStoreType } from '../../EditMarkingTool.store';
import { as, injectProps } from '../../../../../../../../__legacy__/helpers/react.helpers';

import React from 'react';
import { ToolTypeField } from '../../../commons/ui/fields/ToolTypeField';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: EditMarkingToolBl;
  store: EditMarkingToolStore;
  permissions: EditMarkingToolPermissions;
}

@injectProps({
  bl: EditMarkingToolBlType,
  store: EditMarkingToolStoreType,
  permissions: EditMarkingToolPermissionsType
})
@observer
class ToolTypeFieldContainerPure extends React.Component<InjectedProps> {
  render() {
    return (
      <ToolTypeField
        onChangeToolType={this.props.bl.changeToolType}
        toolType={this.props.store.toolType}
        disabled={!this.props.permissions.canChangeToolType}
      />
    );
  }
}

export const ToolTypeFieldContainer = as<React.ComponentClass>(ToolTypeFieldContainerPure);
