import { action, observable } from 'mobx';

import { AnnotationStatus } from '../annotation/annotations.interface';
import { IFreeAccessPaging } from './freeAccessApi.service';
import { IQuestionModel } from '../annotation/question.model';
import { ProjectStatus } from '../projects/projects.model';
import { injectable } from 'inversify';

export const FreeAccessStoreType = Symbol('FREE_ACCESS_STORE');

export enum AnnotateStage {
  NOTANNOTATED = 'NotAnnotated',
  ANNOTATED = 'Annotated',
  DRAFT = 'Draft',
  REVIEWED = 'Reviewed',
  REJECTED = 'Rejected',
}

export enum FreeAccessMode {
  VIEW = 'View',
  ANNOTATION = 'Annotation',
  REVIEW = 'Review',
  REVIEWCORRECT = 'ReviewCorrect',
  ANNOTATIONRESET = 'AnnotationReset',
  CORRECT = 'Correct',
}

export interface IFreeAccessStore {
  showImageDeletedMessage: boolean;
  index: number;
  total: number;
  previousId: string;
  nextId: string;
  projectId: string;
  projectStatus: ProjectStatus;
  imageId: string;
  annotatedByName: string;
  reviewedByName: string;
  lockedByName: string;
  canAddAnnotations: boolean;
  activePreloads: string[];
  paging: IFreeAccessPaging;
  freeAccessMode: FreeAccessMode;
  questions: IQuestionModel[];
  annotateStage: AnnotateStage;
  status?: AnnotationStatus;
  rejectionReason?: string;
  isAssignedToCurrentUser: boolean;
  isAssigned: boolean;
  isImported: boolean;
  isWaitingForReviewAfterReject: boolean;
  
  setView(freeAccessMode: FreeAccessMode, annotateStage?: AnnotateStage): void;
}

@injectable()
export class FreeAccessStore implements IFreeAccessStore {
  rejectionReason?: string | undefined;

  activePreloads: string[] = [];

  @observable
  projectId: string = '';

  @observable
  projectStatus: ProjectStatus = ProjectStatus.Draft;

  @observable
  imageId: string = '';

  @observable
  previousId: string = '';

  @observable
  nextId: string = '';

  @observable
  total: number = 0;

  @observable
  index: number = 0;

  @observable
  annotatedByName = '';

  @observable
  reviewedByName = '';

  @observable
  lockedByName = '';

  @observable
  isAssignedToCurrentUser = false;

  @observable
  isAssigned = false;

  @observable
  canAddAnnotations = true;

  @observable
  freeAccessMode = FreeAccessMode.VIEW;

  @observable
  isWaitingForReviewAfterReject: boolean = false;

  @observable
  paging = {
    wasChanged: false,
    checkDate: '',
    items: [],
  } as IFreeAccessPaging;

  @observable
  questions: IQuestionModel[] = [];

  @observable
  annotateStage: AnnotateStage = AnnotateStage.NOTANNOTATED;

  @observable
  showImageDeletedMessage: boolean = false;

  @observable
  status = undefined;

  @observable
  isImported = false;

  @action
  setView(freeAccessMode: FreeAccessMode, annotateStage?: AnnotateStage): void {
    this.freeAccessMode = freeAccessMode;
    if (annotateStage) {
      this.annotateStage = annotateStage;
    }
  }
}
