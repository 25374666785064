import * as React from 'react';

import { Col, Row } from 'reactstrap';

import { CreateDatasetContainer } from '../../../modules/datesets/containers/CreateDataset.container';
import { CreateDatasetFilesContainer } from '../../../modules/datesets/containers/CreateDatasetFiles.container';
import { PageHeaderClean } from '../../../components/PageHeaderClean';
import { ViewContainer } from '../../../containers/ViewContainer';

const Header = () => (
  <PageHeaderClean customClass="clean">
    <CreateDatasetContainer />
  </PageHeaderClean>
);

export const CreateDatasetView = () => (
  <ViewContainer header={Header}>
    <Row>
      <Col sm="6">
        <CreateDatasetFilesContainer />
      </Col>
    </Row>
  </ViewContainer>
);
