import { inject, injectable } from 'inversify';
import { StickerError } from '../../../../../__legacy__/models/error.model';
import { ApiServiceType, IPagedResult } from '../../../../../__legacy__/services/api.service';
import { IApiService } from '../../../../../__legacy__/services/api.service.base';
import { IProjectExport } from '../models/ProjectExport';

export const GetProjectExportsServiceType = Symbol('GET_PROJECT_EXPORTS_SERVICE');

interface GetProjectExportsRequest {
  projectId: string;
  pageNumber: number;
  pageSize: number;
  orderBy: string;
  orderType: string;
}

export interface GetProjectExportsResponse {
  lastAnnotationChange: Date;
  isCurrentExportGenerated: boolean;
  isCurrentlyGenerating: boolean;
  latestExportId: string;
  projectExports: IPagedResult<IProjectExport>;
}

export interface IGetProjectExportsService {
  getProjectExportsAsync(request: GetProjectExportsRequest): Promise<GetProjectExportsResponse>;
}

@injectable()
export class GetProjectExportsService implements IGetProjectExportsService {
  constructor(@inject(ApiServiceType) readonly apiService: IApiService) {}

  async getProjectExportsAsync(request: GetProjectExportsRequest): Promise<GetProjectExportsResponse> {
    const url = '/Export/GetProjectExports';
    const result = await this.apiService.getAsync<GetProjectExportsResponse>(url, { params: request });

    if (result instanceof StickerError) {
      throw result;
    }

    return result;
  }
}
