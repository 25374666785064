import { withNamespaces } from 'react-i18next';
import * as React from 'react';
import { ITranslatable } from '../../../__legacy__/helpers/translations.helpers';
import { as } from '../../../__legacy__/helpers/react.helpers';
import { MODEL_STATUS } from '../../models/models.model';
import { ButtonWithLoader } from '../../../__legacy__/components/ButtonWithLoader';
import { LinkedButton } from '../../../__legacy__/components/LinkedButton';
import { Home } from '../../../__legacy__/routes/config/Home';
import { EnableForRole } from '../../../__legacy__/containers/EnableForRole';
import { ModelPermissions } from '../../../__legacy__/modules/workspaces/workspaces.store';

interface IProps {
  workspaceId?: string;
  id?: string;
  status: MODEL_STATUS;
  startTrainingAsync(): void;
  stopTrainingAsync: () => void;
  downloadModelAsync: () => void;
  isOwner: boolean;
}

const ModelDetailsHeaderButtonsPure = (props: IProps & ITranslatable) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { workspaceId, id, t, isOwner, status, startTrainingAsync, downloadModelAsync, stopTrainingAsync } = props;
  const isStartTrainingAvailable = status === MODEL_STATUS.DRAFT || status === MODEL_STATUS.STOPPED || status === MODEL_STATUS.FAILED;
  const isStopTrainingAvailable = status === MODEL_STATUS.RUNNING;
  const isDownloadModelAvailable = status === MODEL_STATUS.FINISHED || status === MODEL_STATUS.IMPORTED;
  const isEvaluationAvailable = workspaceId && id && (status === MODEL_STATUS.FINISHED || status === MODEL_STATUS.IMPORTED);

  const handleFetch = (callback?: () => void) => async () => {
    setIsLoading(true);
    await callback?.();
    setIsLoading(false);
  };

  return (
    <div className="model-details-actions">
      <EnableForRole projectRoles={[]} workspaceRoles={isOwner ? ModelPermissions.updateModelWhenOwner : ModelPermissions.updateModel}>
        <ButtonWithLoader
          isLoading={isLoading}
          id="start-model-training-button"
          color="primary"
          title={t(isStartTrainingAvailable ? 'start_training.text' : 'start_training.not_available')}
          onClick={handleFetch(startTrainingAsync)}
          disabled={!isStartTrainingAvailable}
        >
          {props.t('start_training.button')}
        </ButtonWithLoader>
      </EnableForRole>
      <EnableForRole projectRoles={[]} workspaceRoles={isOwner ? ModelPermissions.updateModelWhenOwner : ModelPermissions.updateModel}>
        <ButtonWithLoader
          isLoading={isLoading}
          id="stop-model-training-button"
          color="primary"
          title={t(isStopTrainingAvailable ? 'stop_training.text' : 'stop_training.not_available')}
          onClick={handleFetch(stopTrainingAsync)}
          disabled={!isStopTrainingAvailable}
        >
          {props.t('stop_training.button')}
        </ButtonWithLoader>
      </EnableForRole>
      <EnableForRole projectRoles={[]} workspaceRoles={isOwner ? ModelPermissions.downloadModelWhenOwner : ModelPermissions.downloadModel}>
        <ButtonWithLoader
          id="download-model-training-button"
          color="primary"
          isLoading={isLoading}
          title={t(isDownloadModelAvailable ? 'download.button' : 'download.not_available')}
          disabled={!isDownloadModelAvailable}
          onClick={handleFetch(downloadModelAsync)}
        >
          {props.t('download.button')}
        </ButtonWithLoader>
      </EnableForRole>
      <EnableForRole projectRoles={[]} workspaceRoles={ModelPermissions.runInference}>
        <LinkedButton
          id="run-evaluation-button"
          color="primary"
          title={t(isDownloadModelAvailable ? 'run_evaluation.button' : 'run_evaluation.not_available')}
          disabled={!isEvaluationAvailable}
          to={`${Home.Evaluations.Create.Settings.withParams({
            workspaceId: workspaceId || '',
          })}?training_job_id=${id}`}
        >
          {t('run_evaluation.button')}
        </LinkedButton>
      </EnableForRole>
    </div>
  );
};

export const ModelDetailsHeaderButtons = as<React.FC<IProps>>(withNamespaces('models')(ModelDetailsHeaderButtonsPure));
