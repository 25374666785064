import { IImportToolRejection, ImportToolRejectionReason } from '../projects.model';

import { CollapsibleImportSummary } from '../../../components/CollapsibleSummary';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IProps {
  isLastSummaryItem?: boolean;
  isPanelOpen: boolean;
  handlePanelToggle(): void;
  rejectedTools: IImportToolRejection[];
  importedToolsCount: number;
  skippedToolsCount: number;
}

class ImportAnnotationToolsSummaryPure extends React.Component<IProps & ITranslatable> {
  mapAnnotationToolRejectionReason(reason: ImportToolRejectionReason) {
    switch (reason) {
      case ImportToolRejectionReason.INVALID_MARKING_TOOL_TYPE:
        return this.props.t('invalid_marking_tool_type');
      case ImportToolRejectionReason.INVALID_COLOR_FORMAT:
        return this.props.t('invalid_color_format');
      case ImportToolRejectionReason.INVALID_MARKING_TOOL_NAME:
        return this.props.t('invalid_marking_tool_name');
      case ImportToolRejectionReason.DUPLICATED_MARKING_TOOL_NAME:
        return this.props.t('duplicated_marking_tool_name');
      default:
        return this.props.t('unknown');
    }
  }

  getRejectionReasons = (): string[] => {
    return this.props.rejectedTools.map(x => {
      return `${x.toolName} - ${this.props.t(this.mapAnnotationToolRejectionReason(x.reason))}`;
    });
  };

  render() {
    return (
      <>
        <CollapsibleImportSummary
          importedItemsCount={this.props.importedToolsCount}
          skippedItemsCount={this.props.skippedToolsCount}
          rejectionReasons={this.getRejectionReasons()}
          isPanelOpen={this.props.isPanelOpen}
          togglePanel={this.props.handlePanelToggle}
          isLastSummaryItem={this.props.isLastSummaryItem}
          importedMessage={this.props.t('annotation_tools_imported', { count: this.props.importedToolsCount })}
          skippedMessage={this.props.t('tools_skipped', { count: this.props.skippedToolsCount })}
        />
      </>
    );
  }
}

export const ImportAnnotationToolsSummary = as<React.ComponentClass<IProps>>(withNamespaces('project')(ImportAnnotationToolsSummaryPure));
