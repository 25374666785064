import { IAnnotationService } from '../annotation.service';
import { IAnnotationsStore } from '../annotations.store';
import { IFreeDrawSegmentationService } from '../freeDrawSegmentation.service';
import { IUndoRedoCommand } from './undoRedoCommand';

export class AnnotationCommand implements IUndoRedoCommand {
  constructor(
    protected readonly annotationService: IAnnotationService,
    protected readonly annotationsStore: IAnnotationsStore,
    protected readonly freeDrawSegmentationService: IFreeDrawSegmentationService,
  ) {
  }

  executeUndo() {
    this.freeDrawSegmentationService.clear();
  }

  executeRedo() {
  }
}
