// tslint:disable:function-name
// tslint:disable:variable-name

import leaflet, { FeatureGroup, LatLngBounds, LatLngBoundsLiteral, LatLngExpression, Layer, Map, Rectangle, rectangle } from 'leaflet';

import { DEFAULT_STROKE_OPACITY } from '../../../../helpers/global.constants';
import { IQuestionModel } from '../../question.model';
import { ISegmentation } from '../../annotations.interface';
import { cloneDeep } from 'lodash';

export class AttributePopup extends leaflet.Popup {
  private readonly FRAME_PADDING_POINT: number = 10;

  _container!: HTMLElement;
  _latlng!: LatLngExpression;
  _contentNode!: HTMLElement;
  _width: number = 0;
  _content: string = '';
  _map!: Map;
  _group!: FeatureGroup;
  _showOpacity: boolean;
  _frame?: Rectangle;
  _segmentation: ISegmentation;
  _bbox: LatLngBounds;

  constructor(segmentation: ISegmentation, map: Map, featureGroup: FeatureGroup, showOpacity: boolean) {
    super({ segmentation } as any);
    this._map = map;
    this._group = featureGroup;
    this._showOpacity = showOpacity;
    this._segmentation = segmentation;
    this._bbox = cloneDeep(this._segmentation.bbox);
    this.openOn(this._map);
    this.addTo(this._group);
  }

  initialize(options: any, source: Layer) {
    const initailizeOptions = {} as any;
    initailizeOptions.className = 'attribute-popup';
    initailizeOptions.autoClose = false;
    initailizeOptions.closeOnClick = false;
    initailizeOptions.closeButton = false;
    initailizeOptions.storeKey = options.segmentation.feature.id;

    super.initialize(initailizeOptions, source);
  }

  onRemove(map: Map) {
    this._frame?.removeFrom(map);
    super.onRemove(map);
    return this;
  }

  _updateContent() {
    const bounds = this.getBounds();
    this._latlng = bounds.getNorthWest();

    this._frame?.removeFrom(this._map);
    this._frame = rectangle(bounds, { dashArray: '3, 3', opacity: this._showOpacity ? DEFAULT_STROKE_OPACITY : 1, fill: false, weight: 1, color: '#fff' });
    this._frame.addTo(this._group);

    this._content = this.getAttributeContentHtml(this._segmentation.questions);
    super._updateContent();
  }

  _updatePosition() {
    if (!this._map) { return; }

    const pos = this._map.latLngToLayerPoint(this._latlng);
    const bounds = this.getBounds();
    this._width = this._map.latLngToLayerPoint(bounds.getNorthWest()).distanceTo(this._map.latLngToLayerPoint(bounds.getNorthEast()));

    this._contentNode.style.width = 'unset';
    this._contentNode.style.minWidth = `${this._width}px`;
    this._contentNode.style.pointerEvents = 'none';
    this._contentNode.style.userSelect = 'none';

    this._container.style.top = `${pos.y}px`;
    this._container.style.left = `${pos.x - 1}px`;
    if (this._showOpacity) this._container.style.opacity = `${DEFAULT_STROKE_OPACITY}`;

    this._container.oncontextmenu = (e: Event) => e.preventDefault();
  }

  updateData(showOpacity: boolean, hover: boolean) {
    this._showOpacity = showOpacity;
    this._container.style.opacity = this._showOpacity ? `${DEFAULT_STROKE_OPACITY}` : '1';
    this._container.style.zIndex = hover ? '10000' : 'auto';
    this._content = this.getAttributeContentHtml(this._segmentation.questions);
    this._updateContent();
  }

  getBounds(): LatLngBounds {
    const bounds = leaflet.geoJSON(this._segmentation.feature.geometry).getBounds();
    const framePadding = this._segmentation.feature.geometry.type === 'Point' || this._segmentation.feature.geometry.type === 'LineString' ? this.FRAME_PADDING_POINT : 0;

    const extended: LatLngBoundsLiteral = [
      [bounds.getSouth() - framePadding, bounds.getWest() - framePadding],
      [bounds.getNorth() + framePadding, bounds.getEast() + framePadding],
    ];

    return new LatLngBounds(extended);
  }

  getAttributeContentHtml = (questions: IQuestionModel[]): string => {
    const getAnswers = (item: IQuestionModel): string => item.answer || item.answers.filter(x => x.selected).map(x => x.text).join(',');

    if (questions.length === 1) {
      const answers = getAnswers(questions[0]);
      return `<span class="attribute">${answers || '-'}</span>`;
    }

    return questions.map((item, key) => {
      const answers = getAnswers(item);
      return `<span class="attribute"><b>Q${key + 1}:</b>${answers || '-'}</span>`;
    }).join('');
  };
}
