import autobind from 'autobind-decorator';
import { inject, injectable } from 'inversify';
import { MAX_IMPORT_FILE_SIZE } from '../../../../../__legacy__/modules/projects/containers/MaxFileSize';
import { EventBusType, EventListeningDisposer, IEventBus } from '../../../../../__legacy__/services/eventBus.service';
import { ImportAnnotationsFailureResponseEvent, ImportAnnotationsFailureResponseEventType } from './events/ImportAnnotationsFailureResponseEvent';
import { ImportAnnotationsFinished } from './events/ImportAnnotationsFinished';
import { ImportAnnotationsSuccessResponseEvent, ImportAnnotationsSuccessResponseEventType } from './events/ImportAnnotationsSuccessResponseEvent';

import { ImportAnnotationsStoreSetter, ImportAnnotationsStoreSetterType } from './importAnnotations.store';
import { ImportAnnotationConflict, ResolveConflictDecision } from './models/ImportAnnotationsConflicts';
import { ImportAnnotationsStep } from './models/ImportAnnotationsSteps';
import { ResolveConflictsStrategy } from './models/ResolveConflictsStrategy';
import { CheckForAnnotationsConflictsRequest, CheckForAnnotationsConflictsService, CheckForAnnotationsConflictsServiceType } from './services/CheckForAnnotationsConflicts.service';
import { ImportAnnotationsRequest, IImportAnnotationsService, ImportAnnotationsServiceType } from './services/ImportAnnotations.service';

export const ImportAnnotationsBlType = Symbol('IMPORT_ANNOTATIONS_BL');

export interface ImportAnnotationsBl {
  startImport(projectId: string): void;
  cancelImport(): void;
  importAnnotationsAsync(): Promise<void>;

  // information step
  goToSelectFileStep(): void;

  // select file step
  validateFileAsync(file: File): Promise<void>;
  goToImportAnnotationsAsync(): Promise<void>;

  // how to resolve conflicts step
  changeResolveConflictsStrategy(strategy: ResolveConflictsStrategy): void;
  resolveConflicts(): void;

  // resolve conflicts step
  changeConflictDecision(conflict: ImportAnnotationConflict): void;
  replaceAllAnnotations(): void;
  keepAllAnnotations(): void;

  // summary step
  finishImport(): void;
}

@autobind
@injectable()
export class ImportAnnotationsBlImpl implements ImportAnnotationsBl {
  private successListenerDisposer?: EventListeningDisposer;
  private failureListenerDisposer?: EventListeningDisposer;

  constructor(
    @inject(ImportAnnotationsStoreSetterType) private readonly store: ImportAnnotationsStoreSetter,
    @inject(CheckForAnnotationsConflictsServiceType) private readonly checkForAnnotationsConflictsService: CheckForAnnotationsConflictsService,
    @inject(ImportAnnotationsServiceType) private readonly importAnnotationsService: IImportAnnotationsService,
    @inject(EventBusType) private readonly eventBus: IEventBus,
  ) {}

  public startImport(projectId: string): void {
    this.store.setProjectId(projectId);
    this.store.setCurrentStep(ImportAnnotationsStep.Information);
  }

  public cancelImport(): void {
    this.store.clean();
  }

  public async importAnnotationsAsync(): Promise<void> {
    if (this.store.report || this.store.processing) return;

    this.goToProcessingStep();
    this.successListenerDisposer = this.eventBus.addListener(this.importAnnotationsSucceeded, ImportAnnotationsSuccessResponseEventType);
    this.failureListenerDisposer = this.eventBus.addListener(this.importAnnotationsFailed, ImportAnnotationsFailureResponseEventType);
    this.store.setProcessing(true);
    this.store.setCurrentStep(ImportAnnotationsStep.Processing);

    if (this.store.fileValidationError || !this.store.file) return;

    try {
      const request = new ImportAnnotationsRequest(this.store.projectId!, this.store.file!, this.store.conflicts!);
      await this.importAnnotationsService.importAnnotations(request);
    } catch {
      this.store.setFileValidationError('unknown_error_during_import');
      this.goToSelectFileStep();
    } finally {
      this.store.setProcessing(false);
    }
  }

  public goToSelectFileStep(): void {
    this.store.setCurrentStep(ImportAnnotationsStep.SelectFile);
  }

  public async validateFileAsync(file: File): Promise<void> {
    this.goToProcessingStep();
    this.store.cleanFileInfo();

    if (file !== undefined && file.size > MAX_IMPORT_FILE_SIZE) {
      this.store.setFileValidationError('file_size_exceeded');
    } else {
    try {
      const request = new CheckForAnnotationsConflictsRequest(await file.text(), this.store.projectId!);
      const response = await this.checkForAnnotationsConflictsService.checkForAnnotationsConflictsAsync(request);
      this.store.setConflictsResults(response, file);
    } catch (error) {
      this.store.setFileValidationError('file_format_is_incorrect');
    }
    }

    this.goToSelectFileStep();
  }

  public async goToImportAnnotationsAsync(): Promise<void> {
    if (this.store.conflicts?.length === 0) {
      await this.importAnnotationsAsync();
    } else {
      this.goToHowToResolveConflictsStep();
    }
  }

  public changeResolveConflictsStrategy(strategy: ResolveConflictsStrategy): void {
    this.store.setResolveConflictsStrategy(strategy);
  }

  public resolveConflicts(): void {
    switch (this.store.resolveConflictsStrategy) {
      case ResolveConflictsStrategy.KeepAll:
        this.store.setAllConflictsDecisions(ResolveConflictDecision.keepOriginal);
        break;

      case ResolveConflictsStrategy.ReplaceAll:
        this.store.setAllConflictsDecisions(ResolveConflictDecision.replaceAnnotation);
        break;

      case ResolveConflictsStrategy.Manually:
        this.goToResolveConflictsStep();
        break;
    }
  }

  public changeConflictDecision(conflict: ImportAnnotationConflict): void {
    if (this.store.conflicts) {
      this.store.setConflictDecision(conflict);
    }
  }

  public replaceAllAnnotations() {
    this.store.setAllConflictsDecisions(ResolveConflictDecision.replaceAnnotation);
  }

  public keepAllAnnotations() {
    this.store.setAllConflictsDecisions(ResolveConflictDecision.keepOriginal);
  }

  finishImport(): void {
    this.store.clean();
  }

  private importAnnotationsFailed(event: ImportAnnotationsFailureResponseEvent): void {
    this.store.setImportAnnotationsFailed(event.errorCodes);
    this.disposeEventHandlers();
    this.goToSummaryStep();
  }

  private importAnnotationsSucceeded(event: ImportAnnotationsSuccessResponseEvent): void {
    this.store.setImportAnnotationsFinished(event.report);
    this.eventBus.sendEvent(new ImportAnnotationsFinished(this.store.projectId!));
    this.disposeEventHandlers();
    this.goToSummaryStep();
  }

  disposeEventHandlers(): void {
    this.failureListenerDisposer?.();
    this.successListenerDisposer?.();
  }

  private goToProcessingStep(): void {
    this.store.setCurrentStep(ImportAnnotationsStep.Processing);
  }

  private goToSummaryStep(): void {
    this.store.setCurrentStep(ImportAnnotationsStep.Summary);
  }

  private goToHowToResolveConflictsStep() {
    this.store.setCurrentStep(ImportAnnotationsStep.HowToResolveConflicts);
  }

  private goToResolveConflictsStep(): void {
    this.store.setCurrentStep(ImportAnnotationsStep.ResolveConflicts);
  }
}
