import { inject, injectable } from 'inversify';
import { matchPath } from 'react-router';
import { IWorkspacesPermissions, WorkspacesPermissionsType } from '../../../../../__legacy__/modules/workspaces/workspaces.permissions';
import { Home } from '../../../../../__legacy__/routes/config/Home';
import { IRouterStore, RouterStoreType } from '../../../../../__legacy__/stores/router.store';

export const CurrentWorkspaceNavigatorType = Symbol('CURRENT_WORKSPACE_NAVIGATOR');

export interface CurrentWorkspaceNavigator {
  navigateOrReplaceWith(workspaceId: string): boolean;
}

@injectable()
export class CurrentWorkspaceNavigatorImpl implements CurrentWorkspaceNavigator {
  constructor(@inject(RouterStoreType) private readonly routerStore: IRouterStore, @inject(WorkspacesPermissionsType) private readonly permissions: IWorkspacesPermissions) {}

  defaultLocation = (newWorkspaceId: string): string => Home.Projects.List.All.withParams({ workspaceId: newWorkspaceId });

  navigateOrReplaceWith(newWorkspaceId: string): boolean {
    const oldUrl = this.routerStore.location.pathname;
    const oldWorkspaceId = matchPath<{ workspaceId: string }>(oldUrl, Home.Path)?.params.workspaceId;

    if (!oldWorkspaceId) return false;

    if (this.navigateToDefaultIfNotPermitted(Home.Team.List.Path, oldUrl, newWorkspaceId, this.permissions.canAccessTeam)) return true;
    if (this.navigateToDefaultIfNotPermitted(Home.Attachments.Path, oldUrl, newWorkspaceId, this.permissions.canAccessAttachments)) return true;
    if (this.navigateToDefaultIfNotPermitted(Home.Datasets.Path, oldUrl, newWorkspaceId, this.permissions.canAccessDatasets)) return true;

    if (this.navigateToAttachmentsListWhenOnSubPath(oldUrl, oldWorkspaceId, newWorkspaceId)) return true;
    if (this.navigateToDatasetsListWhenOnSubPath(oldUrl, oldWorkspaceId, newWorkspaceId)) return true;
    if (this.navigateToProjectsListWhenOnSubPath(oldUrl, oldWorkspaceId, newWorkspaceId)) return true;
    if (this.navigateToModelsListWhenOnSubPath(oldUrl, oldWorkspaceId, newWorkspaceId)) return true;
    if (this.navigateToEvaluationsListWhenOnSubPath(oldUrl, oldWorkspaceId, newWorkspaceId)) return true;

    this.stayOnTheSamePathWithNewWorkspaceId(oldUrl, oldWorkspaceId, newWorkspaceId);
    return false;
  }

  private navigateToDefaultIfNotPermitted(path: string, oldUrl: string, workspaceId: string, canAccess: (workspaceId: string) => boolean): boolean {
    const pathMatch = matchPath(oldUrl, path);
    if (pathMatch && !canAccess(workspaceId)) {
      this.navigateToDefault(workspaceId);
      return true;
    }
    return false;
  }

  private navigateToDefault(newWorkspaceId: string) {
    const newUrl = this.defaultLocation(newWorkspaceId);
    this.routerStore.push(newUrl);
  }

  private navigateToAttachmentsListWhenOnSubPath(oldUrl: string, oldWorkspaceId: string, newWorkspaceId: string): boolean {
    const attachmentPath = Home.Attachments.Path;
    const attachmentsListUrlWithOldId = Home.Attachments.List.Folder.withParams({ workspaceId: oldWorkspaceId, attachmentId: '' });
    const attachmentsListUrlWithNewId = Home.Attachments.List.Folder.withParams({ workspaceId: newWorkspaceId, attachmentId: '' });
    return this.navigateToListWhenOnSubPath(oldUrl, attachmentPath, attachmentsListUrlWithOldId, attachmentsListUrlWithNewId);
  }

  private navigateToDatasetsListWhenOnSubPath(oldUrl: string, oldWorkspaceId: string, newWorkspaceId: string): boolean {
    const datasetsPath = Home.Datasets.Path;
    const datasetsListUrlWithOldId = Home.Datasets.List.withParams({ workspaceId: oldWorkspaceId });
    const datasetsListUrlWithNewId = Home.Datasets.List.withParams({ workspaceId: newWorkspaceId });
    return this.navigateToListWhenOnSubPath(oldUrl, datasetsPath, datasetsListUrlWithOldId, datasetsListUrlWithNewId);
  }

  private navigateToProjectsListWhenOnSubPath(oldUrl: string, oldWorkspaceId: string, newWorkspaceId: string): boolean {
    const projectsPath = Home.Projects.Path;
    const projectsListUrlWithOldId = Home.Projects.List.All.withParams({ workspaceId: oldWorkspaceId });
    const projectsListUrlWithNewId = Home.Projects.List.All.withParams({ workspaceId: newWorkspaceId });
    return this.navigateToListWhenOnSubPath(oldUrl, projectsPath, projectsListUrlWithOldId, projectsListUrlWithNewId);
  }

  private navigateToModelsListWhenOnSubPath(oldUrl: string, oldWorkspaceId: string, newWorkspaceId: string): boolean {
    const modelsPath = Home.Models.Path;
    const modelsListUrlWithOldId = Home.Models.List.All.withParams({ workspaceId: oldWorkspaceId });
    const modelsListUrlWithNewId = Home.Models.List.All.withParams({ workspaceId: newWorkspaceId });
    return this.navigateToListWhenOnSubPath(oldUrl, modelsPath, modelsListUrlWithOldId, modelsListUrlWithNewId);
  }

  private navigateToEvaluationsListWhenOnSubPath(oldUrl: string, oldWorkspaceId: string, newWorkspaceId: string): boolean {
    const evaluationsPath = Home.Evaluations.Path;
    const evaluationsListUrlWithOldId = Home.Evaluations.List.All.withParams({ workspaceId: oldWorkspaceId });
    const evaluationsListUrlWithNewId = Home.Evaluations.List.All.withParams({ workspaceId: newWorkspaceId });
    return this.navigateToListWhenOnSubPath(oldUrl, evaluationsPath, evaluationsListUrlWithOldId, evaluationsListUrlWithNewId);
  }

  private navigateToListWhenOnSubPath(oldUrl: string, path: string, excludeUrl: string, listUrlWithNewWorkspace: string) {
    const match = matchPath(oldUrl, path);
    if (match && oldUrl !== excludeUrl) {
      this.routerStore.push(listUrlWithNewWorkspace);
      return true;
    }
    return false;
  }

  private stayOnTheSamePathWithNewWorkspaceId(oldUrl: string, oldWorkspaceId: string, newWorkspaceId: string) {
    const newUrl = oldUrl.replace(oldWorkspaceId, newWorkspaceId);
    this.routerStore.push(newUrl);
  }
}
