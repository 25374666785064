import * as React from 'react';

import { ITranslatable } from '../../../helpers/translations.helpers';
import { InputStatus } from '../../../models/error.model';
import { PasswordScoreMeter } from './PasswordScoreMeter';
import { ValidatedTextInput } from '../../../components/ValidatedTextInput';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IPasswordWithScoreInputsProps {
  onPasswordChange(value: string): void;
  validatePassword(value: string): void;
  passwordStatus: InputStatus;
  passwordScore: number | null;
  labelText: string;
}

const PasswordWithScoreInputsPure = withNamespaces('auth')((props: IPasswordWithScoreInputsProps & ITranslatable) => (
  <div>
    <ValidatedTextInput
      labelText={props.labelText}
      id="register-password-input"
      type={'password'}
      placeholder={props.t('enter_password')}
      onChange={props.onPasswordChange}
      onBlur={props.validatePassword}
      isValid={props.passwordStatus.isValid}
      feedbacks={props.passwordStatus.errorCodes.map(props.t)}
      enablePreview={true}
    />
    <PasswordScoreMeter score={props.passwordScore} />
  </div>
));

export const PasswordWithScoreInputs = as<React.SFC<IPasswordWithScoreInputsProps>>(PasswordWithScoreInputsPure);
