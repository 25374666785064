import * as React from 'react';

import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { ConfigurationType, IConfiguration } from '../../../../configuration';
import { as, injectProps } from '../../../helpers/react.helpers';

import { ButtonWithLoader } from '../../../components/ButtonWithLoader';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { RouteComponentProps } from 'react-router';
import autobind from 'autobind-decorator';
import { withNamespaces } from 'react-i18next';

interface IDeleteAccountModal {
  showDeleteAccountModal: boolean;
  isDeleteInProgress: boolean;
  onConfirm(): void;
  onCancel(): void;
}

interface IInjectedProps extends RouteComponentProps {
  configuration: IConfiguration;
}

@injectProps({
  configuration: ConfigurationType,
})
class DeleteAccountModal extends React.Component<IInjectedProps & IDeleteAccountModal & ITranslatable> {
  @autobind
  handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    this.props.onConfirm();
  }

  render() {
    return (
      <Modal isOpen={this.props.showDeleteAccountModal} toggle={this.props.onCancel}>
        <ModalHeader>{this.props.t('delete_account')}</ModalHeader>
        <Form onSubmit={this.handleSubmit}>
          <ModalBody>
            <Row>
              <Col>
                <div className="alerts">
                  <p>
                    <span className="encrypted-warning">{this.props.t('warning')}:</span> {this.props.t('this_cannot_be_undone')}
                  </p>
                </div>
                <ul>
                  <li>{this.props.t('all_shared_project_with_your_team_will_be_inaccessible')},</li>
                  <li className="pt-2">{this.props.t('all_work_made_statistics_and_history_will_be_deleted')},</li>
                  <li className="pt-2">{this.props.t('all_configured_projects_will_be_deleted')},</li>
                  <li className="pt-2">{this.props.t('all_datasets_and_images_will_be_deleted_as_well')}.</li>
                </ul>
                <p>
                  {this.props.t('all_your_data_will_be_deleted_in_next')}
                  {this.props.configuration.appConfig!.userAccountDeletionDelayDayCount}
                  {this.props.t('days')}.&nbsp;
                  {this.props.t('if_you_are_not_sure_about_deletion_please_follow_instructions_in_email_we_will_send_you_after_delete')}
                </p>
                <div className="alerts mt-2">
                  <p>{this.props.t('are_you_sure_to_delete_your_account')}</p>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <ButtonWithLoader
              className="mr-2"
              id="confirm-delete"
              loaderKey="confirm-delete"
              type="submit"
              color="warning"
              onClick={this.handleSubmit}
              disabled={this.props.isDeleteInProgress}
              isLoading={this.props.isDeleteInProgress}
            >
              {this.props.t('yes')}, {this.props.t('delete_account_and_logout')}
            </ButtonWithLoader>
            <Button id="cancel-delete" color="primary" className="btn-outline-primary" onClick={this.props.onCancel}>
              {this.props.t('no')}, {this.props.t('keep_account')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default as<React.ComponentClass<IDeleteAccountModal>>(withNamespaces('user', { wait: true })(DeleteAccountModal));
