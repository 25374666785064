import * as React from 'react';

import { TabsSingleItem, TabsSingleItemState } from '../../../__legacy__/components/TabsSingleItem';
import { Home } from '../../../__legacy__/routes/config/Home';
import { withNamespaces } from 'react-i18next';
import { ITranslatable } from '../../../__legacy__/helpers/translations.helpers';
import { TabsContainer } from '../../../__legacy__/containers/TabsContainer';
import { as } from '../../../__legacy__/helpers/react.helpers';
import { MODEL_STATUS } from '../../models/models.model';
import { IModelDetailsValidationErrors } from '../modelDetails.store';

interface IProps {
  modelId?: string;
  modelDetailsValidationErrors: IModelDetailsValidationErrors;
  modelStatus: MODEL_STATUS;
  workspaceId: string;
}

const ModelDetailsTabsPure = (props: IProps & ITranslatable) => {
  const { t, modelId, modelStatus, workspaceId, modelDetailsValidationErrors } = props;
  function isValidatedTab(link: string): link is Exclude<keyof IModelDetailsValidationErrors, 'showAll'> {
    return modelDetailsValidationErrors[link as keyof IModelDetailsValidationErrors] !== undefined;
  }

  const getClass = (link: string): TabsSingleItemState => {
    const pathname = window.location.pathname.split('/');
    const tabName = pathname[pathname.length - 1];
    const className: TabsSingleItemState = tabName === link ? 'active' : 'passive';
    if (isValidatedTab(link)) {
      const validationErrors = modelDetailsValidationErrors[link];
      let isError = false;

      if (typeof validationErrors === 'undefined') {
        return className;
      }

      if (typeof validationErrors === 'boolean') {
        isError = validationErrors;
      } else {
        // Nothing validated yet
        if (validationErrors.size === 0 && !modelDetailsValidationErrors.showAll) {
          return className;
        }

        isError = Array.from(validationErrors.values()).some(error => error !== null);
      }

      const classNameSuffix = isError ? 'error' : 'validated';
      return `${className} ${classNameSuffix}` as TabsSingleItemState;
    }

    return className;
  };

  const buildTab = (link: string, label: string, disabled?: boolean, tooltip?: string, callback?: () => void): JSX.Element => {
    const route =
      modelId === undefined
        ? Home.Models.Create.Paths.withParams({
            workspaceId,
            tab: link,
          })
        : Home.Models.Details.Paths.withParams({
            workspaceId,
            jobId: modelId,
            tab: link,
          });

    return <TabsSingleItem text={t(label)} link={route} state={getClass(link)} callback={callback} disabled={disabled} tooltip={tooltip} />;
  };

  const isMetricsDisabled = modelStatus === MODEL_STATUS.STOPPED || modelStatus === MODEL_STATUS.STOPPING || modelStatus === MODEL_STATUS.FAILED;
  const metricsTooltip = isMetricsDisabled ? t('metrics_tooltip') : undefined;

  return (
    <TabsContainer variant="default">
      {buildTab('settings', 'settings')}
      {buildTab('datasets', 'datasets')}
      {buildTab('images', 'images')}
      {modelId !== undefined ? buildTab('metrics', 'metrics', isMetricsDisabled, metricsTooltip) : <></>}
    </TabsContainer>
  );
};

export const ModelDetailsTabs = as<React.FC<IProps>>(withNamespaces('models')(ModelDetailsTabsPure));
