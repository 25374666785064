import { AnnotationServiceType, IAnnotationService } from '../annotation.service';
import { AnnotationTypeBlType, IAnnotationTypeBl } from '../submodules/annotationTypes/annotationType.bl';
import { AnnotationUiStoreType, IAnnotationUiStore } from '../annotationUi.store';
import { AnnotationsStoreType, IAnnotationsStore } from '../annotations.store';
import { FreeDrawSegmentationServiceType, IFreeDrawSegmentationService } from '../freeDrawSegmentation.service';
import { IUndoRedoHistory, UndoRedoHistoryType } from '../undoRedoHistory.service';
import { as, injectProps } from '../../../helpers/react.helpers';

import { Button } from 'reactstrap';
import { ChangeSegmentationWithAnswersCommand } from '../undoRedoCommands/changeSegmentationWithAnswersCommand';
import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { LatLngBounds } from 'leaflet';
import React from 'react';
import { SegmentationAnswerAddedCommand } from '../undoRedoCommands/segmentationAnswerAddedCommand';
import { action } from 'mobx';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { getLatLngsForGeojson } from '../../../helpers/geometry/polygon.helpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

library.add(faExclamationTriangle);

interface IInjectedProps {
  annotationService: IAnnotationService;
  undoRedoHistory: IUndoRedoHistory;
  uiStore: IAnnotationUiStore;
  annotationTypeBl: IAnnotationTypeBl;
  freeDrawSegmentationService: IFreeDrawSegmentationService;
  annotationsStore: IAnnotationsStore;
}

@injectProps({
  annotationService: AnnotationServiceType,
  annotationTypeBl: AnnotationTypeBlType,
  uiStore: AnnotationUiStoreType,
  undoRedoHistory: UndoRedoHistoryType,
  freeDrawSegmentationService: FreeDrawSegmentationServiceType,
  annotationsStore: AnnotationsStoreType,
})
@observer
class SegmentationsSaveContainer extends React.Component<IInjectedProps & ITranslatable> {
  @action.bound
  handleSaveAndShowNext() {
    this.handleSave();
    if (!this.props.annotationsStore.selectedSegmentation) this.props.annotationService.moveToNextInvalidSegmentation();
  }

  // TODO: Refactor
  @action.bound
  handleSave() {
    if (this.props.freeDrawSegmentationService.freeDrawFeature) {
      this.handleFreeDrawSave();
    } else {
      this.handleRegularDrawSave();
    }
  }

  handleFreeDrawSave() {
    const feature = this.props.freeDrawSegmentationService.freeDrawFeature;
    const segmentation = feature ? this.props.annotationsStore.segmentations.find(s => s.feature.id === feature?.id) : undefined;

    if (!feature || !feature.geometry.coordinates.length) {
      if (segmentation) {
        const command = new ChangeSegmentationWithAnswersCommand(
          this.props.annotationService,
          this.props.annotationsStore,
          this.props.freeDrawSegmentationService,
          segmentation,
          undefined,
        );

        this.props.annotationService.deleteSegmentation(segmentation.feature.id);

        this.props.undoRedoHistory.addCommand(command);
      }
      this.secureClear();
      return;
    }

    this.props.annotationsStore.currentQuestions.forEach(q => q.validate());
    if (this.props.annotationsStore.currentQuestions.filter(q => q.status.isValid === false).length > 0) return;

    const latlngs = getLatLngsForGeojson(feature);

    const editedSegmentation = {
      feature,
      latlngs,
      questions: this.props.annotationsStore.currentQuestions,
      bbox: new LatLngBounds(latlngs),
      priority: segmentation !== undefined ? segmentation.priority : 0,
    };

    const command = new ChangeSegmentationWithAnswersCommand(
      this.props.annotationService,
      this.props.annotationsStore,
      this.props.freeDrawSegmentationService,
      segmentation,
      editedSegmentation,
    );

    if (!segmentation) {
      this.props.annotationService.addNewSegmentation(editedSegmentation);
    } else {
      segmentation.feature = editedSegmentation.feature;
      segmentation.questions = editedSegmentation.questions;
      segmentation.latlngs = editedSegmentation.latlngs;
      segmentation.bbox = editedSegmentation.bbox;
    }

    this.props.undoRedoHistory.addCommand(command);

    this.clear();
  }

  handleRegularDrawSave() {
    const feature = this.props.annotationsStore.selectedSegmentation?.feature;
    const segmentation = feature ? this.props.annotationsStore.segmentations.find(s => s.feature.id === feature?.id) : undefined;

    if (!segmentation || !feature || !feature.geometry.coordinates.length) {
      if (segmentation) {
        this.props.annotationService.deleteSegmentation(segmentation.feature.id);
      }
      this.clear();
      return;
    }

    this.props.annotationsStore.currentQuestions.forEach(q => q.validate());
    if (this.props.annotationsStore.currentQuestions.filter(q => q.status.isValid === false).length > 0) return;

    this.props.undoRedoHistory.addCommand(
      new SegmentationAnswerAddedCommand(
        this.props.annotationService,
        this.props.annotationsStore,
        this.props.freeDrawSegmentationService,
        this.props.annotationTypeBl,
        segmentation.feature.id,
        segmentation.questions.slice(),
        this.props.annotationsStore.currentQuestions.slice(),
      ),
    );

    segmentation.questions = this.props.annotationsStore.currentQuestions;

    this.clear();
  }

  @action.bound
  handleCancel() {
    this.secureClear();
  }

  @action.bound
  async secureClear() {
    if (await this.props.freeDrawSegmentationService.clearAsync()) {
      this.props.annotationService.deselectSegmentation();
    }
  }

  @action.bound
  clear() {
    this.props.freeDrawSegmentationService.clear();
    this.props.annotationService.deselectSegmentation();
  }

  @action.bound
  cancelClear() {
    this.props.freeDrawSegmentationService.cancelClear();
  }

  render() {
    if (
      !this.props.annotationsStore.isSelectedSegWithQuestions &&
      !this.props.annotationsStore.isSelectedSegOfFreeDrawType &&
      !this.props.freeDrawSegmentationService.freeDrawInProgress
    ) {
      return null;
    }

    return (
      <div className="questions-save">
        {(!this.props.uiStore.isInValidation || this.props.annotationsStore.invalidQuestionsCount <= 1) && (
          <>
            <Button color="primary" onClick={this.handleSave} tabIndex={-1}>
              {this.props.t('save')}
            </Button>
            <Button color="primary cancel" onClick={this.handleCancel} tabIndex={-1}>
              {this.props.t('cancel')}
            </Button>
          </>
        )}
        {this.props.uiStore.isInValidation && this.props.annotationsStore.invalidQuestionsCount > 1 && (
          <>
            <p className="warning-feedback">
              <FontAwesomeIcon icon={faExclamationTriangle} className="warning-icon" />
              {this.props.t('invalid_questions_left', { count: this.props.annotationsStore.invalidQuestionsCount })}
            </p>
            <Button color="primary" onClick={this.handleSaveAndShowNext} tabIndex={-1}>
              {this.props.t('save_and_show_next')}
            </Button>
          </>
        )}
        <ConfirmationModal
          confirmationHeader={this.props.t('clear_freedraw_confirmation_header')}
          confirmationQuestion={this.props.t('clear_freedraw_confirmation_body')}
          onCancel={this.cancelClear}
          onConfirm={this.clear}
          showModal={this.props.freeDrawSegmentationService.showClearConfirmation}
          confirmationYes={this.props.t('do_not_save')}
        />
      </div>
    );
  }
}

export default as<React.ComponentClass>(withNamespaces('common')(SegmentationsSaveContainer));
