import { IProjectDetailsStatisticsBl, ProjectDetailsStatisticsBlType } from '../projectDetailsStatistics.bl';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { Button } from 'reactstrap';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import React from 'react';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  projectDetailsStatisticsBl: IProjectDetailsStatisticsBl;
}

type Props = ITranslatable & RouteComponentProps<{ projectId: string }> & IInjectedProps;

@injectProps({
  projectDetailsStatisticsBl: ProjectDetailsStatisticsBlType,
})
@observer
class DownloadAsCsvButtonContainerPure extends React.Component<Props> {
  @autobind
  async handleExportDownload() {
    await this.props.projectDetailsStatisticsBl.downloadStatisticsAsync(this.props.match.params.projectId);
  }

  render() {
    return (
      <div className="display-inline-block">
        <Button disabled={this.props.projectDetailsStatisticsBl.store.offset === null} className="margin-top--10" onClick={this.handleExportDownload}>
          {this.props.t('download_as_csv')}
        </Button>
      </div>
    );
  }
}

export const DownloadAsCsvButtonContainer = as<React.ComponentClass>(withRouter(withNamespaces('common')(DownloadAsCsvButtonContainerPure)));
