import * as React from 'react';

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../workspaces/currentWorkspace/CurrentWorkspace.store';
import { as, injectProps } from '../../../__legacy__/helpers/react.helpers';

import { Home } from '../../../__legacy__/routes/config/Home';
import { ITranslatable } from '../../../__legacy__/helpers/translations.helpers';
import { TabsContainer } from '../../../__legacy__/containers/TabsContainer';
import { TabsSingleItem } from '../../../__legacy__/components/TabsSingleItem';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

@injectProps({
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
class EvaluationsTabsPure extends React.Component<IInjectedProps & ITranslatable> {
  render() {
    const path = window.location.pathname;
    let filterIndex = ['all', 'owned', 'others'].findIndex(s => path.includes(s));
    if (filterIndex < 0) {
      filterIndex = 0;
    }

    const workspaceId = this.props.currentWorkspaceStore.currentWorkspace!.id;

    return (
      <TabsContainer variant="default" className="models-tabs">
        <TabsSingleItem text={this.props.t('all_evaluations')} link={Home.Evaluations.List.All.withParams({ workspaceId })} state={filterIndex === 0 ? 'active' : 'passive'} />
        <TabsSingleItem text={this.props.t('my_evaluations')} link={Home.Evaluations.List.Owned.withParams({ workspaceId })} state={filterIndex === 1 ? 'active' : 'passive'} />
        <TabsSingleItem text={this.props.t('other_evaluations')} link={Home.Evaluations.List.Others.withParams({ workspaceId })} state={filterIndex === 2 ? 'active' : 'passive'} />
      </TabsContainer>
    );
  }
}

export const EvaluationsTabs = as<React.ComponentClass>(withNamespaces('evaluations', { wait: true })(EvaluationsTabsPure));
