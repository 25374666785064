import { WithNamespaces, withNamespaces } from 'react-i18next';

import React from 'react';
import { S_CollapseSection } from '../../../../../design/sections/collapseSection/S_CollapseSection';
import { WorkPerformanceStatsTableContainer } from './table/WorkPerformanceStatsTable.container';
import { as } from '../../../../../__legacy__/helpers/react.helpers';

interface IProps {
  endDate: moment.Moment;
  startDate: moment.Moment;
}

const WorkPerformanceSectionPure: React.FC<IProps & WithNamespaces> = (props: IProps & WithNamespaces) => {
  const { startDate, endDate, t } = props;
  const subtitle = `(${startDate.format('DD MMMM yyyy')} - ${endDate.format('DD MMMM yyyy')})`;
  return (
    <S_CollapseSection title={t('projects.details.stats.work_performance.section_title')} subtitle={subtitle}>
      <WorkPerformanceStatsTableContainer />
    </S_CollapseSection>
  );
};

export const WorkPerformanceSection = as<React.ComponentClass<IProps>>(withNamespaces('new')(WorkPerformanceSectionPure));

