import { IImageFilters } from '../imageFilters.model';

export const getActiveFiltersCount = (imageFilters: IImageFilters) => {
  let activeFiltersCount = 0;

  if (imageFilters.datasets.length) activeFiltersCount = activeFiltersCount + 1;
  if (imageFilters.annotatedBy.length) activeFiltersCount = activeFiltersCount + 1;
  if (imageFilters.reviewedBy.length) activeFiltersCount = activeFiltersCount + 1;
  if (imageFilters.annotationStatuses.length) activeFiltersCount = activeFiltersCount + 1;
  if (imageFilters.corrected.length) activeFiltersCount = activeFiltersCount + 1;
  if (imageFilters.imageSets.length) activeFiltersCount = activeFiltersCount + 1;
  if (imageFilters.unclear.length) activeFiltersCount = activeFiltersCount + 1;
  if (imageFilters.annotationTools.some(x => x.l1FilterType !== undefined)) activeFiltersCount = activeFiltersCount + 1;

  return activeFiltersCount;
};
