import * as React from 'react';

import { IAnswerModel, IQuestionModel, QuestionType } from '../../question.model';

import { AnswerDropDown } from './AnswerDropDown';
import { AnswerWithCheckBox } from './AnswerWithCheckBox';
import { AnswerWithRadioButton } from './AnswerWithRadioButton';
import { ITranslatable } from '../../../../helpers/translations.helpers';
import { NumericAnswer } from './NumberAnswer';
import { TextAnswer } from './TextAnswer';
import { as } from '../../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IProps {
  question: IQuestionModel;
  disabled: boolean;
  isAttribute: boolean;
  onAnswered(questionId: string, answer: string): void;
}

@observer
class QuestionWithAnswers extends React.Component<IProps & ITranslatable> {
  componentDidMount() {
    setTimeout(
      () => {
        const elements = document.getElementsByClassName('text-input-answer-attribute');
        if (elements.length > 0 && document.activeElement !== elements[0]) {
          let element = elements[0];
          if (element.tagName !== 'INPUT' && element.tagName !== 'TEXTAREA') {
            element = elements[0].getElementsByTagName('input')[0] || elements[0].getElementsByTagName('textarea')[0];
          }
          (element as HTMLInputElement).focus();
        }
      },
      0);
  }

  @autobind
  onAnswered(answer: string): void {
    this.props.onAnswered(this.props.question.id, answer);
  }

  renderAnswer = (answer: IAnswerModel): JSX.Element | null => {
    const { question } = this.props;

    switch (question.type) {
      case QuestionType.TRUE_FALSE:
      case QuestionType.ALTERNATIVE:
      case QuestionType.STATUS_PASS_FAIL:
      case QuestionType.SET_TRAIN_TEST:
        return (
          <AnswerWithRadioButton
            disabled={this.props.disabled}
            name={question.id}
            key={answer.id}
            answer={answer}
            onAnswered={this.onAnswered}
          />
        );
      case QuestionType.MULTISELECT:
        return (
          <AnswerWithCheckBox
            disabled={this.props.disabled}
            name={question.id}
            key={answer.id}
            answer={answer}
            onAnswered={this.onAnswered}
          />
        );
      default:
        throw new Error(`Type not implemented: ${question.type}`);
    }
  };

  renderFeedbacks(): JSX.Element[] {
    return this.props.question.status.errorCodes.map((feedback, i) => {
      return <li key={i}>{this.props.t(feedback)}</li>;
    });
  }

  render() {
    const { question } = this.props;
    const hasErrors = question.status.isValid === false;

    return (
      <div className={`question-list-single-item ${hasErrors ? 'invalid' : ''}`} key={question.id}>
        <div className="question-text" title={question.text}>
          <span>{question.text}</span> <span className="required">{question.isRequired ? '*' : ''}</span>
        </div>
        {question.type === QuestionType.ALTERNATIVE && question.answers.length > 9
          ? <AnswerDropDown answers={question.answers} onAnswered={this.onAnswered} />
          : (<div className="answer-list">{question.answers.map(this.renderAnswer)}</div>)}
        {question.type === QuestionType.OPEN && <TextAnswer
          disabled={this.props.disabled}
          className={this.props.isAttribute ? 'text-input-answer-attribute' : ''}
          answer={question.answer || ''}
          onAnswered={this.onAnswered}
        />}
        {question.type === QuestionType.NUMBER && <NumericAnswer
          disabled={this.props.disabled}
          className={this.props.isAttribute ? 'text-input-answer-attribute' : ''}
          answer={question.answer || ''}
          onAnswered={this.onAnswered}
        />}
        {hasErrors && <ul className="feedback">{this.renderFeedbacks()}</ul>}
      </div>
    );
  }
}

export default as<React.ComponentClass<IProps>>(withNamespaces('annotation', { wait: true })(QuestionWithAnswers));
