import { ExportDataBlType, IExportDataBl } from '../../ExportData.bl';
import { ExportDataStore, ExportDataStoreType } from '../../ExportData.store';
import { as, injectProps } from '../../../../../../__legacy__/helpers/react.helpers';

import { ExportButton } from './ExportButton';
import React from 'react';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: IExportDataBl;
  store: ExportDataStore;
}

@injectProps({
  bl: ExportDataBlType,
  store: ExportDataStoreType,
})
@observer
class ExportButtonContainerPure extends React.Component<InjectedProps> {
  handleClick = async () => {
    if (await this.props.bl.isCurrentExportGeneratedAsync()) {
      this.props.bl.showDownloadModal(this.props.store.latestExportId, this.props.store.selectedDataFilter);
      return;
    }

    await this.props.bl.exportProjectAsync();
  };

  render() {
    return <ExportButton onClick={this.handleClick} disabled={this.props.store.isCurrentlyGenerating} />;
  }
}

export const ExportButtonContainer = as<React.ComponentClass>(ExportButtonContainerPure);
