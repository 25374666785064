import { Badge, Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import { ButtonWithLoader } from '../../../components/ButtonWithLoader';
import { ExternalLink } from '../../../components/ExternalLink';
import { ExternalLinks } from '../../../routes/config/ExternalLinks';
import { InputStatus } from '../../../models/error.model';
import { Plan } from '../../user/user.store';
import React from 'react';
import { S_Select } from '../../../../design/inputs/select/S_Select';
import { ValidatedCheckboxInput } from '../../../components/ValidatedCheckboxInput';
import { ValidatedTextInput } from '../../../components/ValidatedTextInput';
import { timeZones } from '../../../models/timeZones/timeZones.model';

export interface ICreateWorkspaceModalProps {
  showCreateWorkspace: boolean;
  newWorkspaceName: string;
  newWorkspaceNameStatus: InputStatus;
  newWorkspaceEncryption: boolean;
  newWorkspaceTimeZone: number;
  canAddWorkspace: boolean;
  canEncrypt: boolean;
  plan: Plan;
  onCloseModal(): void;
  onCreateNameChange(value: string): void;
  onEncryptionChange(): void;
  onTimeZoneChanged(newTimeZone: number): void;
  onSubmitCreate(): void;
}

const CreateWorkspaceModalPure: React.FC<ICreateWorkspaceModalProps & WithNamespaces> = (props: ICreateWorkspaceModalProps & WithNamespaces) => {
  const handleTimeZoneChanged = (label: string) => {
    const newValue = timeZones.find(tz => tz.label === label)?.value;

    if (newValue === undefined) return;

    props.onTimeZoneChanged(newValue);
  };

  const selectedTimeZoneLabel = timeZones.find(tz => tz.value === props.newWorkspaceTimeZone)?.label;

  return (
    <Modal className="modal-width-600" isOpen={props.showCreateWorkspace} toggle={props.onCloseModal}>
      <ModalHeader toggle={props.onCloseModal}>{props.t('create_new_workspace')}</ModalHeader>
      <Form className="custom-inline-form">
        <ModalBody>
          <Row>
            <Col className="mb-2">
              <ValidatedTextInput
                id="workspace-name"
                labelText={props.t('workspace_name')}
                placeholder={props.t('workspace_type_new_workspace_name_here')}
                value={props.newWorkspaceName}
                onChange={props.onCreateNameChange}
                onPressEnter={props.onSubmitCreate}
                isValid={props.newWorkspaceNameStatus.isValid}
                maxLength={200}
                feedbacks={props.newWorkspaceNameStatus.errorCodes.map(ec => props.t(ec))}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <S_Select onChange={handleTimeZoneChanged} options={timeZones.map(t => t.label)} selected={selectedTimeZoneLabel!} />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <ValidatedCheckboxInput
                id="encryption"
                checked={props.newWorkspaceEncryption}
                disabled={!props.canEncrypt}
                onChange={props.onEncryptionChange}
                styleClassName="border-0"
              >
                {!props.canEncrypt && (
                  <Badge href="https://zillin.io/pricing.html" color="primary" target="_blank" title={props.t('plan_upgrade')}>
                    Enterprise
                  </Badge>
                )}{' '}
                {props.t('workspace_enable_encryption')}
              </ValidatedCheckboxInput>
            </Col>
          </Row>
          {!props.canAddWorkspace && (
            <Row>
              <Col>
                <p className="error-feedback darker">
                  {props.t('you_have_reached_workspaces_limit', { plan: props.t(props.plan) })} <br />{' '}
                  <ExternalLink to={ExternalLinks.ZillinIo.Pricing.Path}>{props.t('upgrade_to_higher_plan')}</ExternalLink> {props.t('to_create_more')}
                </p>
              </Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <ButtonWithLoader
            loaderKey="create-workspace-button"
            id="create-workspace"
            disabled={props.newWorkspaceNameStatus.isValid !== true || !props.canAddWorkspace}
            color="success"
            className="mr-2"
            onClick={props.onSubmitCreate}
          >
            {props.t('create_workspace')}
          </ButtonWithLoader>
          <Button color="primary" className="btn-outline-primary" onClick={props.onCloseModal}>
            {props.t('cancel')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export const CreateWorkspaceModal = withNamespaces('workspace')(CreateWorkspaceModalPure);
