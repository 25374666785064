import { Col, Container, Row } from 'reactstrap';
import { faArrowAltCircleLeft, faArrowCircleLeft, faArrowCircleRight, faBan, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { AcceptIcon } from '../../../components/icons/AcceptIcon';
import { DiscardAnnotationConfirmationModal } from './DiscardAnnotationConfirmationModal';
import { EditorMode } from '../../../../modules/editor/models/EditorModes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { NavigationButton } from './NavigationButton';
import React from 'react';
import RequestClarificationButtonContainer from '../submodules/clarifications/containers/RequestClarificationButton.container';
import { ValidEditIcon } from '../../../components/icons/ValidEditIcon';
import { as } from '../../../helpers/react.helpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';

library.add(faArrowCircleRight, faTimesCircle, faArrowCircleLeft, faBan, faArrowAltCircleLeft);

export interface IReviewNavigationComponentProps {
  onAccept(): void;
  onReject(): void;
  onEdit(): void;
  onDiscard(): void;
  onDiscardConfirmed(): void;
  onDiscardCanceled(): void;
  onDiscard(): void;
  onCancel(): void;
  disabled: boolean;
  projectId: string;
  canDiscard: boolean;
  showDiscardModal: boolean;
  canCancel: boolean;
  editorMode: EditorMode;
}

class ReviewNavigationPure extends React.Component<IReviewNavigationComponentProps & ITranslatable> {
  render() {
    return (
      <Container fluid className="annotation-navigation">
        <DiscardAnnotationConfirmationModal
          showDiscardModal={this.props.showDiscardModal}
          onDiscardCancel={this.props.onDiscardCanceled}
          onDiscardConfirm={this.props.onDiscardConfirmed}
        />
        <Row>
          <Col>
            {this.props.canCancel && (
              <NavigationButton loaderKey="cancel-annotation-button" disabled={this.props.disabled} onClick={this.props.onCancel} title={'cancel_tooltip'} caption="cancel">
                <FontAwesomeIcon icon={faArrowAltCircleLeft} />
              </NavigationButton>
            )}
            {this.props.canDiscard && (
              <NavigationButton
                id="discard-annotation"
                loaderKey="discard-annotation-button"
                disabled={this.props.disabled}
                onClick={this.props.onDiscard}
                title={'discard_tooltip'}
                caption="discard"
              >
                <FontAwesomeIcon icon={faBan} />
              </NavigationButton>
            )}
            <RequestClarificationButtonContainer disabled={this.props.disabled} editorMode={this.props.editorMode} />
          </Col>
          <Col className="on-right">
            <NavigationButton loaderKey="" disabled={this.props.disabled} onClick={this.props.onEdit} title={'correct_tooltip'} caption="fix">
              <ValidEditIcon />
            </NavigationButton>
            <NavigationButton loaderKey="reject-button" disabled={this.props.disabled} onClick={this.props.onReject} title={'reject_tooltip'} caption="reject">
              <FontAwesomeIcon icon={faTimesCircle} />
            </NavigationButton>
            <NavigationButton
              id="accept-annotation"
              loaderKey="accept-button"
              disabled={this.props.disabled}
              onClick={this.props.onAccept}
              title={'review_accept_tooltip'}
              caption="accept"
            >
              <AcceptIcon />
            </NavigationButton>
          </Col>
        </Row>
      </Container>
    );
  }
}

export const ReviewNavigation = as<React.ComponentClass<IReviewNavigationComponentProps>>(withNamespaces('annotation', { wait: true })(ReviewNavigationPure));
