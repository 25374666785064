import './ProjectStatsSections.scss';

import { ExportProjectStatsButtonContainer } from './export/ui/ExportProjectStatsButton.container';
import { ProjectStatsFilters } from './filters/ui/ProjectStatsFilters';
import React from 'react';

export const ProjectStatsTopBar: React.FC = () => (
  <div className="project-stats-top-bar">
    <ProjectStatsFilters />
    <ExportProjectStatsButtonContainer />
  </div>
);
