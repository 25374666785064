import { ClampLines } from '../../../../components/ClampLines';
import { FormGroup } from 'reactstrap';
import { IAnswerModel } from '../../question.model';
import React from 'react';
import Select from 'react-select';
import { handleSelectChange } from '../../../../helpers/formHelpers';
import { observer } from 'mobx-react';
import uuid from 'uuid';

export interface IAnswerDropDownProps {
  answers: IAnswerModel[];
  onAnswered(answer: string): void;
}

export const AnswerDropDown = observer(({ answers, onAnswered }: IAnswerDropDownProps) => {
  const selected = answers.find(a => a.selected);

  let selectedOption = null;
  if (selected) selectedOption = { label: selected.text, value: selected.id };

  const filter = (option: { label: string, value: string }, rawInput: string) =>
    option.label.toLocaleUpperCase().includes(rawInput.toLocaleUpperCase());

  const guid: string = uuid.v4();

  const formatOptionLabel = ({ label }: any) => (
    <ClampLines
      id={`answer-${uuid.v4()}`}
      text={label}
      lines={20}
      innerElement={'div'}
      isDropDownAnswer
    />
  );

  const scrollToOpenedDropDown = () => {
    const toolsContainer: any = document.getElementsByClassName('scrollable-container-content')[0];
    const parent = document.getElementsByClassName(guid)[0].parentElement;
    if (!parent) return;
    const selectContainer = parent.parentElement;

    if (!toolsContainer || !selectContainer) return;

    /*
      DEBUG:
      ---
      console.log('container scrollHeight: ', toolsContainer.scrollHeight); // total height
      console.log('container scrollTop: ', toolsContainer.scrollTop); // how far is scrolled
      console.log('container offsetHeight: ', toolsContainer.offsetHeight); // visible window height
      console.log('selectContainer offsetTop: ', selectContainer.offsetTop); // how far from top is select
      ---

      selectContainer.offsetTop - how far (low) in container is select placed in pixels
      toolsContainer.offsetHeight - how high is visible window in pixels
      toolsContainer.scrollTop - how far (low) is currently the top of visible window in pixels
      toolsContainer.scrollHeight - total height of tools container in pixels
    */

    if (selectContainer.offsetTop + 150 > toolsContainer.offsetHeight + toolsContainer.scrollTop) {
      // we have to use setTimeout because the opened menu is not visible until end of this handler
      setTimeout(
        () => {
          // get opened dropdown
          const openedDropDown = document.getElementsByClassName(guid)[0].children[1];
          openedDropDown.scrollIntoView();
        },
        0);
    }
  };

  return (
    <FormGroup check className="question-dropdown">
      <Select
        className={guid}
        options={answers.map(a => ({ label: a.text, value: a.id }))}
        value={selectedOption}
        onChange={handleSelectChange(onAnswered)}
        isSearchable={true}
        filterOption={filter}
        onMenuOpen={scrollToOpenedDropDown}
        formatOptionLabel={formatOptionLabel}
      />
    </FormGroup>
  );
});
