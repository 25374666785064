import * as React from 'react';

import { CustomInput, FormGroup } from 'reactstrap';
import { handleChange, handleClickAndPassData } from '../../../../helpers/formHelpers';

import { ClampLines } from '../../../../components/ClampLines';
import { IAnswerProps } from '../../question.model';
import { observer } from 'mobx-react';

export const AnswerWithCheckBox = observer(({ disabled: readonly, name, answer, onAnswered: onAnswerSelect }: IAnswerProps) => (
  <FormGroup check title={answer.text}>
    <CustomInput type="checkbox" id={answer.id} name={name} value={answer.id} checked={answer.selected} onChange={handleChange(onAnswerSelect)} disabled={readonly}>
      <ClampLines onClick={handleClickAndPassData(onAnswerSelect)(answer.id)} id={`answer-${answer.id}`} text={answer.text} lines={3} innerElement={'p'} />
    </CustomInput>
  </FormGroup>
));
