import { S_Pagination, S_PaginationProps } from '../../../design/pagination/S_Pagination';
import { IPredictionImage } from '../../models/predictions.model';
import React, { useState, useEffect } from 'react';
import { as, scrollTop } from '../../helpers/react.helpers';
import { Loader } from '../Loader';
import { ToggleSwitchWithLabel } from './ToggleSwitchWithLabel';
import { SliderWithLabel } from './SliderWithLabel';
import { PredictionImageThumbnail } from './PredictionImageThumbnail';
import { withNamespaces } from 'react-i18next';
import { ITranslatable } from '../../helpers/translations.helpers';
import { PredictionImagesPreviewStore } from './predictionImagesPreview.store';
import { useParams } from 'react-router-dom';
import { Home } from '../../routes/config/Home';

export interface IPredictionImagesProps {
  allowImageDetails: boolean;
  jobType: NonNullable<PredictionImagesPreviewStore['jobType']>;
  detailsImages: ReadonlyArray<IPredictionImage>;
  pagination: S_PaginationProps;
  authToken: string;
  showSwitchGroup: boolean;
  disableSwitch: boolean;
  showPredictions: boolean;
  onSwitchChange(): void;
  isLoading: boolean;
}

const PredictionImagesPure = ({
  detailsImages,
  pagination,
  authToken,
  showSwitchGroup,
  disableSwitch,
  showPredictions,
  onSwitchChange,
  isLoading,
  jobType,
  allowImageDetails,
  t,
}: IPredictionImagesProps & ITranslatable) => {
  const params = useParams<{ workspaceId: string; jobId: string }>();
  const [max, setMax] = useState<number>(100);
  const [min, setMin] = useState<number>(0);
  const [step, setStep] = useState<number>(1);
  const [threshold, setThreshold] = useState<number>(50);

  useEffect(() => {
    const sortedScores = detailsImages
      .map(image => image.prediction?.score)
      .filter(score => typeof score === 'number')
      .sort((a, b) => (a as number) - (b as number)) as number[];

    let step = sortedScores.reduce((minDifference, currentScore, index) => {
      if (index === 0) return minDifference;
      const difference = currentScore - sortedScores[index - 1];
      return Math.min(minDifference, difference);
    }, Infinity);

    step = step === Infinity ? 1 : Math.max(step, 0.01);

    let min = sortedScores[0] || 0;
    let max = sortedScores[sortedScores.length - 1] || 100;

    min = Math.floor(min / step) * step;
    max = Math.ceil(max / step) * step;

    const threshold = (min + max) / 2;

    setMin(min);
    setMax(max);
    setStep(step);
    setThreshold(threshold);
  }, [detailsImages]);

  const handlePaginationChange = (pageNumber: number, pageSize: number) => {
    scrollTop();
    pagination.onChange(pageNumber, pageSize);
  };

  const handleThresholdChange = (value: number) => {
    setThreshold(value);
  };

  const getOnClickLink = (image: IPredictionImage): string => {
    const parameters = {
      workspaceId: params.workspaceId,
      jobId: params.jobId,
      imageId: image.id,
    };
    switch (jobType) {
      case 'EVALUATION':
        return Home.Evaluations.Details.ImageDetails.withParams(parameters);
      case 'TRAINING':
        return Home.Models.Details.ImageDetails.withParams(parameters);
    }
  };

  const isSliderDisabled = !showPredictions || isLoading;

  return (
    <div className="image-list-controls">
      {showSwitchGroup && (
        <div className="right-elements">
          <ToggleSwitchWithLabel label={t('show_predictions')} checked={showPredictions} onChange={onSwitchChange} disabled={disableSwitch} />
          <SliderWithLabel label={t('threshold')} min={min} max={max} step={step} value={threshold} onChange={handleThresholdChange} disabled={isSliderDisabled} />
        </div>
      )}
      <div className="project-details-images">
        <Loader isLoading={isLoading} />
        {detailsImages.length === 0 && <div className="no-image-message">{t('no_images')}</div>}
        {detailsImages.length > 0 && (
          <div>
            <div className="thumbnails">
              {detailsImages.map((image: IPredictionImage) => (
                <PredictionImageThumbnail
                  onClickLink={allowImageDetails ? getOnClickLink(image) : undefined}
                  key={image.id}
                  image={image}
                  t={t}
                  authToken={authToken}
                  threshold={threshold}
                  showPredictions={showPredictions}
                />
              ))}
            </div>
            <div className="thumbnails-paging">
              <S_Pagination {...pagination} onChange={handlePaginationChange} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const predictionImagesWithTranslation = (ns: string) => as<React.ComponentClass<IPredictionImagesProps>>(withNamespaces(ns)(PredictionImagesPure));
