import {
  GTable,
  IGColumnProps,
  enumDirectionToStringOrder,
  stringOrderToEnumDirection,
} from '../../../components/table/GTable';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { ITeamMember } from '../models/TeamMember';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { IconedButton } from '../../../components/IconedButton';
import { NoAccessTooltip } from '../../../containers/NoAccessTooltip';
import React from 'react';
import { S_PaginationProps } from '../../../../design/pagination/S_Pagination';
import { SortingDirection } from '../../../models/sortingDirection.model';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import _ from 'lodash';
import { as } from '../../../helpers/react.helpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { nameOf } from '../../../helpers/object.helpers';
import { withNamespaces } from 'react-i18next';

library.add(faTrashAlt, faEdit);

interface IProps {
  currentWorkspaceId: string;
  currentUserEmail: string;
  teamMembers: ITeamMember[];
  canEditUsers: boolean;
  onToggleRemoveUserModal(email: string): void;
  onToggleChangeUserRoleModal(email: string): void;
  orderBy: string;
  orderDirection: SortingDirection | undefined;
  onSortChange(orderBy: string, orderDirection: SortingDirection | undefined): void;
  pagination: S_PaginationProps;
}

type Props = IProps & ITranslatable;

class TeamPure extends React.Component<Props> {
  get gColumns(): IGColumnProps<ITeamMember>[] {
    const columns: IGColumnProps<ITeamMember>[] = [
      {
        field: nameOf<ITeamMember>('email'),
        headerName: this.props.t('email'),
      },
      {
        field: nameOf<ITeamMember>('name'),
        headerName: this.props.t('name'),
      },
      {
        field: nameOf<ITeamMember>('role'),
        headerName: this.props.t('role'),
        cellClass: 'user-role',
      },
      {
        field: nameOf<ITeamMember>('submitted'),
        headerName: this.props.t('submitted'),
        headerTooltip: this.props.t('submitted_tooltip'),
      },
      {
        field: nameOf<ITeamMember>('reviewed'),
        headerName: this.props.t('reviewed'),
        headerTooltip: this.props.t('reviewed_tooltip'),
      },
      {
        field: nameOf<ITeamMember>('edited'),
        headerName: this.props.t('edited'),
        headerTooltip: this.props.t('edited_tooltip'),
      },
    ];

    if (this.props.canEditUsers) {
      columns.push({
        field: '',
        headerName: this.props.t('actions'),
        sortable: false,
        renderer: this.actionsRenderer,
        width: 200,
      });
    }

    return columns;
  }

  onRemove = (email: string) => {
    return () => this.props.onToggleRemoveUserModal(email);
  };

  onEdit = (email: string) => {
    return () => this.props.onToggleChangeUserRoleModal(email);
  };

  actionsRenderer = (data: ITeamMember) => {
    const canManageUser = this.props.currentUserEmail.toLocaleUpperCase() !== data.email.toLocaleUpperCase();

    return canManageUser ? (
      <div className="g-actions">
        <NoAccessTooltip hasAccess={data.role !== WorkspaceRole.Owner}>
          <IconedButton onClick={this.onEdit(data.email)} icon={'edit'} color="red" />
          <IconedButton onClick={this.onRemove(data.email)} icon={'trash-alt'} color="red" />
        </NoAccessTooltip>
      </div>
    ) : (
      <></>
    );
  };

  handleSortChange = (orderBy: string, orderDirection: string) => {
    this.props.onSortChange(orderBy, stringOrderToEnumDirection(orderDirection));
  };

  render() {
    return (
      <div className="table-container wizard-users">
        <GTable
          noItemsPlaceholder={this.props.t('there_are_no_active_collaborators')}
          columns={this.gColumns}
          items={this.props.teamMembers}
          onSortChanged={this.handleSortChange}
          sortingModel={[
            { orderBy: this.props.orderBy, orderType: enumDirectionToStringOrder(this.props.orderDirection) },
          ]}
          paginationProps={this.props.pagination}
        />
      </div>
    );
  }
}

export const Team = as<React.ComponentClass<IProps>>(withNamespaces('project', { wait: true })(TeamPure));
