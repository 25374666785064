import { action, IReactionDisposer, reaction } from 'mobx';
import { inject, injectable } from 'inversify';
import storage from 'store';
import { IProjectsStore, ProjectsStoreType } from '../modules/projects/projects.store';
import { IWorkspacesStore, WorkspacesStoreType } from '../modules/workspaces/workspaces.store';
import { IUserStore, UserStoreType } from '../modules/user/user.store';
import { AnnotationTypeStoreType, IAnnotationTypeStore } from '../modules/annotation/submodules/annotationTypes/annotationType.store';
import { CryptoServiceType, ICryptoService } from './crypto.service';
import { CryptoStoreType, ICryptoStore } from '../stores/crypto.store';
import SimpleCrypto from 'simple-crypto-js';
import { AuthStoreSetterType, IAuthStoreSetter } from '../modules/auth/auth.store';
import { IClarificationsStore, ClarificationsStoreType } from '../modules/annotation/submodules/clarifications/Clarifications.store';

export const StorageServiceType = Symbol('STORAGE_SERVICE');

export interface IWorkspaceCryptoEntry {
  workspaceId: string;
  checksum: string;
  rawKey: string;
}

export interface IStorageService {
  initializeLocalStorage(): void;
  initializeCryptoLocalStorage(): void;
}

@injectable()
export class StorageService implements IStorageService {
  constructor(
    @inject(ProjectsStoreType) private projectsStore: IProjectsStore,
    @inject(WorkspacesStoreType) private workspaceStore: IWorkspacesStore,
    @inject(UserStoreType) private userStore: IUserStore,
    @inject(AnnotationTypeStoreType) private annotationTypeStore: IAnnotationTypeStore,
    @inject(AuthStoreSetterType) private authStore: IAuthStoreSetter,
    @inject(ClarificationsStoreType) private clarificationsStore: IClarificationsStore,
    @inject(CryptoServiceType) private cryptoService: ICryptoService,
    @inject(CryptoStoreType) private cryptoStore: ICryptoStore,
  ) { }

  reactions: IReactionDisposer[] = [];
  simpleCrypto!: SimpleCrypto;

  @action.bound
  initializeLocalStorage() {
    this.reactions.forEach(reaction => reaction());
    this.reactions = [];

    this.trackProperty(this.authStore, 'versionChanged');
    this.trackProperty(this.workspaceStore, 'preferredWorkspaceId');
    this.trackProperty(this.userStore, 'areAttributesShown');
    this.trackProperty(this.userStore, 'opacityLevel');
    this.trackProperty(this.annotationTypeStore, 'hiddenAnnotationTypes');
    this.trackProperty(this.clarificationsStore, 'clarificationsListSortDirection');
    this.trackProperty(this.userStore, 'isImprovedVisibilityCursorEnabled');
    this.trackProperty(this.userStore, 'areCursorGuidesEnabled');

    this.trackAuthToken();
    this.trackProjectsOrder();
  }

  @action.bound
  initializeCryptoLocalStorage() {
    this.simpleCrypto = new SimpleCrypto(this.userStore.userInfo.id);

    const storageKey = 'workspaceCryptos';

    const data = window.localStorage.getItem(storageKey);

    if (data) {
      try {
        const decrypted = this.simpleCrypto.decrypt(data) as IWorkspaceCryptoEntry[];
        decrypted.forEach(x => this.cryptoService.loadKey(x.workspaceId, x.rawKey, x.workspaceId, x.checksum));
      } catch {
        window.localStorage.setItem(storageKey, JSON.stringify([]));
      }
    }

    this.reactions.push(
      reaction(
        () => this.cryptoStore.workspaceCryptos,
        (value) => {
          const mapped = value.map(x => ({ workspaceId: x.workspaceId, rawKey: x.rawKey, checksum: x.checksum }));
          const encrypted = this.simpleCrypto.encrypt(mapped);
          window.localStorage.setItem('workspaceCryptos', encrypted);
        },
      ),
    );

    window.addEventListener('storage', (evt: StorageEvent) => {
      if (evt.key !== 'workspaceCryptos' || !evt.newValue) return;

      const list = this.simpleCrypto.decrypt(evt.newValue) as IWorkspaceCryptoEntry[];

      list.forEach((x) => {
        if (!this.cryptoService.hasKey(x.workspaceId)) {
          this.cryptoService.loadKey(x.workspaceId, x.rawKey, x.workspaceId, x.checksum);
        }
      });
    });
  }

  trackProperty<T>(store: T, key: keyof T) {
    const value = storage.get(key.toString());
    store[key] = value ? value : store[key];
    this.reactions.push(reaction(() => store[key], (value) => { storage.set(key.toString(), value); }));
  }

  trackProjectsOrder() {
    this.projectsStore.projectsPaging = { ...this.projectsStore.projectsPaging, ...storage.get('prefferedProjectsOrder') };
    this.reactions.push(
      reaction(() => this.projectsStore.projectsPaging, (value) => { storage.set('prefferedProjectsOrder', { orderBy: value.orderBy, orderType: value.orderType }); }),
    );
  }

  trackAuthToken() {
    this.trackProperty(this.authStore, 'token');

    window.addEventListener('storage', (evt: StorageEvent) => {
      if (evt.key !== 'token') return;
      const isAuthenticated = this.authStore.isAuthenticated;
      this.authStore.updateToken(evt.newValue ? JSON.parse(evt.newValue) : '');
      if (!isAuthenticated) {
        window.location.reload();
      }
    });
  }
}
