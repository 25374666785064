import * as React from 'react';

import { Col, CustomInput, FormFeedback, FormGroup, Row } from 'reactstrap';

import { ITranslatable } from '../helpers/translations.helpers';
import { as } from '../helpers/react.helpers';
import { handleChange } from '../helpers/formHelpers';
import { withNamespaces } from 'react-i18next';

interface IValidatedCheckboxInputProps {
  id?: string;
  labelText?: string;
  checked?: boolean;
  onChange(): void;
  isValid?: boolean;
  feedbacks?: string[];
  styleClassName?: string;
  className?: string;
  labelChildren?: React.ReactElement<any>[] | React.ReactElement<any>;
  disabled?: boolean;
}

class ValidatedCheckboxInputPure extends React.Component<IValidatedCheckboxInputProps & ITranslatable> {
  renderFeedbacks(): JSX.Element[] {
    const uniqueFeedbacks = this.props.feedbacks ? Array.from(new Set(this.props.feedbacks)) : [];
    return uniqueFeedbacks.map((feedback, i) => {
      return (
        <li className="input-feedback" key={i}>
          {this.props.t(feedback)}
        </li>
      );
    });
  }

  render() {
    return (
      <div className={this.props.className}>
        <Row>
          <Col md="1">
            <FormGroup>
              <CustomInput
                id={this.props.id || ''}
                disabled={this.props.disabled}
                checked={this.props.checked}
                valid={this.props.isValid === true}
                invalid={this.props.isValid === false}
                onChange={handleChange(this.props.onChange)}
                type="checkbox"
                className={this.props.styleClassName}
              />
            </FormGroup>
          </Col>
          <Col className="custom-checkbox-input-label" onClick={this.props.onChange}>{this.props.labelText || this.props.labelChildren || this.props.children}</Col>
        </Row>
        <Row hidden={this.props.isValid !== false}>
          <Col>
            <FormGroup>
              <FormFeedback className="display-block">
                <ul>{this.renderFeedbacks()}</ul>
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

export const ValidatedCheckboxInput = as<React.StatelessComponent<IValidatedCheckboxInputProps>>(
  withNamespaces('common', { wait: true })(ValidatedCheckboxInputPure));
