import { injectable, inject } from 'inversify';
import { StickerError } from '../../../../../models/error.model';
import { ApiServiceType } from '../../../../../services/api.service';
import { IApiService } from '../../../../../services/api.service.base';
import { IClarification } from '../models/Clarifications.models';
import { GetClarificationsRequest, ClarifyRequest, IRequestClarificationRequest } from '../models/Clarifications.requests';

export const ClarificationsApiServiceType = Symbol('CLARIFICATIONS_API_SERVICE');

export interface IClarificationsApiService {
  clarifyAsync(request: ClarifyRequest): Promise<void | StickerError>;
  getClarificationsAsync(request: GetClarificationsRequest): Promise<IClarification[] | StickerError>;
  requestClarificationAsync(request: IRequestClarificationRequest): Promise<void | StickerError>
}

@injectable()
export class ClarificationsApiService implements IClarificationsApiService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService) {}

  async getClarificationsAsync(request: GetClarificationsRequest): Promise<IClarification[] | StickerError> {
    return await this.apiService.getAsync(`/Clarifications/GetClarifications?projectId=${request.projectId}&imageId=${request.imageId}`);
  }

  async clarifyAsync(request: ClarifyRequest): Promise<void | StickerError> {
    return await this.apiService.postAsync('/Clarifications/Clarify', request);
  }

  async requestClarificationAsync(request: IRequestClarificationRequest): Promise<void | StickerError> {
    return await this.apiService.postAsync('/Clarifications/RequestClarification', request);
  }
}
