import { AnnotationServiceType, IAnnotationService } from '../../../../../annotation.service';
import { AnnotationsStoreType, IAnnotationsStore } from '../../../../../annotations.store';
import { IUndoRedoHistory, UndoRedoHistoryType } from '../../../../../undoRedoHistory.service';
import { as, injectProps } from '../../../../../../../helpers/react.helpers';

import { ISegmentationContextMenuChildrenProps } from '../../components/SegmentationContextMenu';
import React from 'react';
import { SegmentationContextMenuItem } from '../../components/SegmentationContextMenuItem';

interface IInjectProps {
  annotationService: IAnnotationService;
  annotationStore: IAnnotationsStore;
  undoRedoHistory: IUndoRedoHistory;
}

@injectProps({
  annotationService: AnnotationServiceType,
  undoRedoHistory: UndoRedoHistoryType,
  annotationStore: AnnotationsStoreType,
})
class DeleteSegmentationContextMenuItemContinerPure extends React.Component<ISegmentationContextMenuChildrenProps & IInjectProps> {
  handleAction = () => {
    this.props.annotationService.deleteSegmentation(this.props.annotationStore.selectedSegmentation!.feature.id);
    this.props.undoRedoHistory.addCommand(this.props.undoRedoHistory.getNewChangeStateCommand());
  };

  render() {
    return <SegmentationContextMenuItem {...this.props} text="delete" icon="delete-option-icon" action={this.handleAction} />;
  }
}

export const DeleteSegmentationContextMenuItemContiner = as<React.ComponentClass<ISegmentationContextMenuChildrenProps>>(DeleteSegmentationContextMenuItemContinerPure);
