import { AnnotationCommand } from './annotationCommand';
import { IAnnotationService } from '../annotation.service';
import { IAnnotationsStore } from '../annotations.store';
import { IFreeDrawSegmentationService } from '../freeDrawSegmentation.service';
import { IQuestionModel } from '../question.model';
import { cloneDeep } from 'lodash/fp';

export class AnswerQuestionCommand extends AnnotationCommand {
  private questionBefore: IQuestionModel;
  private questionAfter: IQuestionModel;

  constructor(
    annotationService: IAnnotationService,
    annotationsStore: IAnnotationsStore,
    freeDrawSegmentationService: IFreeDrawSegmentationService,
    questionBefore: IQuestionModel,
    questionAfter: IQuestionModel,
  ) {
    super(annotationService, annotationsStore, freeDrawSegmentationService);
    this.questionBefore = cloneDeep(questionBefore);
    this.questionAfter = cloneDeep(questionAfter);
  }

  executeUndo() {
    super.executeUndo();
    this.annotationService.deselectSegmentation();
    const question = this.annotationsStore.currentQuestions.find(x => x.id === this.questionBefore.id)!;
    question.answers = cloneDeep(this.questionBefore?.answers);
    question.answer = cloneDeep(this.questionBefore?.answer);
  }

  executeRedo(): void {
    super.executeRedo();
    this.annotationService.deselectSegmentation();
    const question = this.annotationsStore.currentQuestions.find(x => x.id === this.questionAfter.id)!;
    question.answers = cloneDeep(this.questionAfter?.answers);
    question.answer = cloneDeep(this.questionAfter?.answer);
  }
}
