import { Alert, Button, Col, Form, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Trans, withNamespaces } from 'react-i18next';

import { Bold } from '../../../components/BoldText';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import React from 'react';
import { as } from '../../../helpers/react.helpers';
import { faKey } from '@fortawesome/free-solid-svg-icons';

export interface IUnlockModalProps {
  showUnlockModal: boolean;
  wrongKeyFile: boolean;
  workspaceName?: string;
  onFilesDrop(files: File[]): void;
  onWrongFilesDrop(files: File[]): void;
  onCancel(): void;
}

const UnlockWorkspaceModalPure = (props: IUnlockModalProps & ITranslatable) => {
  return (
    <Modal className="modal-width-600" isOpen={props.showUnlockModal} toggle={props.onCancel}>
      <ModalHeader toggle={props.onCancel}>{props.t('key_upload')}</ModalHeader>
      <Form className="custom-inline-form">
        <ModalBody>
          <p>
            <Trans i18nKey={'key_upload_description'} values={{ workspace: props.workspaceName }} components={[Bold]} />
          </p>

          <Row>
            <Col>
              {props.wrongKeyFile && <Alert color="warning">{props.t('key_error')}</Alert>}
              <Dropzone className="dropzone-generic" activeClassName="active" onDropAccepted={props.onFilesDrop} onDropRejected={props.onWrongFilesDrop} accept=".key">
                <FontAwesomeIcon icon={faKey} />
                {props.t('key_dropzone')}
              </Dropzone>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" className="btn-outline-primary" onClick={props.onCancel}>
            {props.t('cancel')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export const UnlockWorkspaceModal = as<React.ComponentClass<IUnlockModalProps>>(withNamespaces('workspace')(UnlockWorkspaceModalPure));
