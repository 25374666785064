import { AttachmentsServiceType, IAttachmentsService } from '../attachments.service';
import { as, injectProps } from '../../../helpers/react.helpers';

import { Button } from 'reactstrap';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import autobind from 'autobind-decorator';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  attachmentsService: IAttachmentsService;
}

library.add(faTrashAlt);

@injectProps({
  attachmentsService: AttachmentsServiceType,
})
@observer
class AttachmentSelectAllContainerPure extends React.Component<IInjectedProps & ITranslatable> {

  @autobind
  onSelectAllAttachments() {
    const selectedAttachments = this.props.attachmentsService.store.selectedAttachments;

    selectedAttachments.isAllSelected = !selectedAttachments.isAllSelected;
    selectedAttachments.ids = [];
  }

  render() {
    const isAllSelected = this.props.attachmentsService.store.selectedAttachments.isAllSelected;
    const canView = this.props.attachmentsService.canUseAttachments();
    return (
      <Button
        color={isAllSelected ? 'secondary' : 'primary'}
        onClick={this.onSelectAllAttachments}
        className={'attachments-button select-all'}
        title={canView ? '' : this.props.t('you_do_not_have_permission_to_perform_this_operation')}
        disabled={!canView}
      >
        {`${this.props.t(isAllSelected ? 'Deselect' : 'Select')}`}
      </Button>
    );
  }
}

export const AttachmentSelectAllContainer = as<React.ComponentClass>(withNamespaces('attachment', { wait: true })(AttachmentSelectAllContainerPure));
