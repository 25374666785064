import { DrawBrush } from '../components/DrawControl.models/Brush/Draw.Brush';
import { IGeometry } from '../components/DrawControl.models/Brush/BrushGeometry';
import { IUndoRedoCommand } from './undoRedoCommand';

export class AddBrushBlobCommand implements IUndoRedoCommand {
  constructor(public brush: DrawBrush, private readonly previousState: IGeometry[], readonly state: IGeometry[]) {}

  executeUndo() {
    if (this.previousState.length === 0) {
      this.brush.freeDrawSegmentationService.clear();
      this.brush.annotationService.setImageQuestionsAsCurrent();
    } else {
      this.brush.replaceGeometry(this.previousState);
    }
  }

  executeRedo() {
    if (this.previousState.length === 0) this.brush.initGeometry(this.state);
    else this.brush.replaceGeometry(this.state);
  }
}
