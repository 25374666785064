import { AnnotateStage, FreeAccessMode, FreeAccessStoreType, IFreeAccessStore } from '../freeAccess.store';
import { AnnotationUiStoreType, IAnnotationUiStore } from '../../annotation/annotationUi.store';
import { AnnotationsStoreType, IAnnotationsStore } from '../../annotation/annotations.store';
import { FreeAccessServiceType, IFreeAccessService } from '../freeAccess.service';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { IProjectDetailsImagesStore, ProjectDetailsImagesStoreType } from '../../projectDetails/sub/images/projectDetailsImages.store';
import { IRouterStore, RouterStoreType } from '../../../stores/router.store';
import { IUndoRedoHistory, UndoRedoHistoryType } from '../../annotation/undoRedoHistory.service';
import { as, injectProps } from '../../../helpers/react.helpers';

import { EditorMode } from '../../../../modules/editor/models/EditorModes';
import { Home } from '../../../routes/config/Home';
import { Prompt } from 'react-router';
import { QuitAnnotationsModal } from '../../annotation/components/QuitAnnotationsModal';
import React from 'react';
import { SaveDraftAnnotationsModal } from '../../annotation/components/SaveDraftAnnotationsModal';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps {
  freeAccessStore: IFreeAccessStore;
  uiData: IAnnotationUiStore;
  routerStore: IRouterStore;
  historyService: IUndoRedoHistory;
  freeAccessService: IFreeAccessService;
  projectDetailsImagesStore: IProjectDetailsImagesStore;
  annotationsStore: IAnnotationsStore;
}

interface IState extends ILoaderState {
  showModal: boolean;
  currentLink?: string;
  confirmedNavigation: boolean;
}

interface IProps {
  workspaceId: string;
  projectId: string;
  filterId: string;
  callback: () => void;
  children(freeNav: IFreeAccessNavigationProps, backNav: IBackNavigationProps): React.ReactNode;
}

export interface IFreeAccessNavigationProps {
  previousLink: string;
  nextLink: string;
  total: number;
  current: number;
  isLoading: boolean;
  goTo(link: string): void;
}

export interface IBackNavigationProps {
  quitLink: string;
  goTo(link: string): void;
}

@injectProps({
  freeAccessStore: FreeAccessStoreType,
  uiData: AnnotationUiStoreType,
  routerStore: RouterStoreType,
  historyService: UndoRedoHistoryType,
  freeAccessService: FreeAccessServiceType,
  projectDetailsImagesStore: ProjectDetailsImagesStoreType,
  annotationsStore: AnnotationsStoreType,
})
@observer
class FreeAccessNavigationContainerPure extends WithLoaderComponentBase<IProps & IInjectedProps, IState> {
  constructor(props: IProps & IInjectedProps) {
    super(props);
    this.state = { showModal: false, isLoading: false, confirmedNavigation: false };
  }

  @autobind
  handleNavigation(link: string) {
    if (
      this.props.freeAccessStore.freeAccessMode !== FreeAccessMode.VIEW &&
      this.props.freeAccessStore.freeAccessMode !== FreeAccessMode.REVIEW &&
      this.props.historyService.canUndo
    ) {
      this.setState({ showModal: true, currentLink: link });
    } else {
      this.props.annotationsStore.setHiddenSegmentations([]);
      this.props.routerStore.push(link);
      this.setState({ showModal: false });
    }
  }

  @autobind
  handleQuitCancel() {
    this.setState({ showModal: false, currentLink: undefined });
  }

  @autobind
  handleQuitConfirm() {
    this.props.historyService.clearHistory();
    this.props.routerStore.push(this.state.currentLink || '');
    this.setState({ showModal: false, confirmedNavigation: true, currentLink: undefined });
  }

  @autobind
  handleToggleDraft() {
    this.setState({ showModal: false, currentLink: undefined });
  }

  @autobind
  handleCancelDraft() {
    this.props.historyService.clearHistory();
    this.props.routerStore.push(this.state.currentLink || '');
    this.setState({ showModal: false, confirmedNavigation: true, currentLink: undefined });
  }

  @autobind
  async handleSaveDraft() {
    this.setState({ showModal: false });
    await this.withLoaderAsync(async () => {
      if (this.getEditorMode() === EditorMode.FREEACCESS_EDIT) {
        await this.props.freeAccessService.editAnnotationAsync();
      } else {
        await this.props.freeAccessService.saveAnnotationDraftAsync();
      }

      this.props.historyService.clearHistory();
    });
    this.props.routerStore.push(this.state.currentLink || '');
    this.setState({ showModal: false, confirmedNavigation: true, currentLink: undefined });
  }

  handleBlockedNavigation = (): boolean => {
    if (!this.state.confirmedNavigation && this.props.historyService.canUndo) {
      this.setState({ showModal: true });
      return false;
    }

    return true;
  };

  getEditorMode = (): EditorMode => (this.props.freeAccessStore.freeAccessMode === FreeAccessMode.ANNOTATIONRESET ? EditorMode.FREEACCESS_EDIT : EditorMode.FREEACCESS_ANNOTATION);

  render() {
    const { workspaceId, projectId, filterId } = this.props;
    const { previousId, nextId } = this.props.freeAccessStore;

    const freeNav = {
      nextLink: Home.FreeAccess.View.withParams({ workspaceId, projectId, filterId, imageId: nextId }),
      previousLink: Home.FreeAccess.View.withParams({ workspaceId, projectId, filterId, imageId: previousId }),
      isLoading: this.state.isLoading || (this.props.uiData.isImageLoading && !this.props.freeAccessStore.showImageDeletedMessage),
      current: this.props.freeAccessStore.index,
      total: this.props.freeAccessStore.total,
      goTo: this.handleNavigation,
    };
    const backNav = {
      quitLink: Home.Projects.Details.Images.withParams({ workspaceId, projectId }),
      goTo: this.handleNavigation,
    };

    const showDraftModal = this.props.freeAccessStore.annotateStage === AnnotateStage.NOTANNOTATED || this.props.freeAccessStore.annotateStage === AnnotateStage.DRAFT;

    return (
      <>
        {this.props.children(freeNav, backNav)}
        <Prompt when={true} message={this.handleBlockedNavigation} />
        <SaveDraftAnnotationsModal
          isOpen={this.state.showModal && showDraftModal}
          onCancel={this.handleCancelDraft}
          onConfirm={this.handleSaveDraft}
          onToggle={this.handleToggleDraft}
          isLoading={this.state.isLoading}
        />
        <QuitAnnotationsModal isOpen={this.state.showModal && !showDraftModal} onCancel={this.handleQuitCancel} onConfirm={this.handleQuitConfirm} />
      </>
    );
  }
}

export const FreeAccessNavigationContainer = as<React.ComponentClass<IProps>>(FreeAccessNavigationContainerPure);
