import { action, observable } from 'mobx';

import { InputStatus } from '../../../__legacy__/models/error.model';
import { injectable } from 'inversify';

export const RenameAttachmentStoreType = Symbol('RENAME_ATTACHMENT_STORE');
export const RenameAttachmentStoreSetterType = Symbol('RENAME_ATTACHMENT_STORE_SETTER');

export interface IRenameAttachmentStore {
  readonly showModal: boolean;
  readonly nameStatus: InputStatus;
  readonly name: string;
  readonly id: string;
  readonly parentId?: string;
}

export interface IRenameAttachmentStoreSetter extends IRenameAttachmentStore {
  initialize(attachmentId: string, attachmentName: string, parentId: string | undefined): void;
  setShowModal(showModal: boolean): void;
  setNameStatus(nameStatus: InputStatus): void;
  setName(name: string): void;
}

@injectable()
export class RenameAttachmentStore implements IRenameAttachmentStoreSetter {
  @observable
  showModal: boolean = false;

  @observable
  nameStatus: InputStatus = InputStatus.empty();

  @observable
  name: string = '';

  @observable
  id: string = '';

  @observable
  parentId: string | undefined = undefined;

  @action
  initialize(attachmentId: string, attachmentName: string, parentId: string | undefined): void {
    this.showModal = false;
    this.nameStatus = InputStatus.empty();
    this.id = attachmentId;
    this.name = attachmentName;
    this.parentId = parentId;
  }

  setShowModal(showModal: boolean): void {
    this.showModal = showModal;
  }

  setNameStatus(nameStatus: InputStatus): void {
    this.nameStatus = nameStatus;
  }

  setName(name: string): void {
    this.name = name;
  }
}
