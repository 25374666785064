import { IProjectStatsFiltersStore, ProjectStatsFiltersStoreType } from '../filters/ProjectStatsFilters.store';
import { as, injectProps } from '../../../../../__legacy__/helpers/react.helpers';

import React from 'react';
import { WithLoaderComponentBase } from '../../../../../__legacy__/helpers/loader.helpers';
import { WorkPerformanceSection } from './WorkPerformanceSection';
import { observer } from 'mobx-react';

interface InjectedProps {
  filtersStore: IProjectStatsFiltersStore;
}

@injectProps({
  filtersStore: ProjectStatsFiltersStoreType,
})
@observer
class WorkPerformanceSectionContainerPure extends WithLoaderComponentBase<InjectedProps> {
  render(): React.ReactNode {
    return <WorkPerformanceSection endDate={this.props.filtersStore.endDate} startDate={this.props.filtersStore.startDate} />;
  }
}

export const WorkPerformanceSectionContainer = as<React.ComponentClass>(WorkPerformanceSectionContainerPure);
