import './ObjectListActions.scss';

import { faEye, faEyeSlash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { IconedButton } from '../../../../../../components/IconedButton';
import React from 'react';

export interface IObjectListActionsProps {
  isVisible: boolean;
  isDeleteVisible: boolean;
  onShowHideClick(): void;
  onDeleteClick(): void;
}

export const ObjectListActions: React.FC<IObjectListActionsProps> = ({ isVisible, isDeleteVisible, onShowHideClick, onDeleteClick }) => {
  const handleShowHideClick = (event: React.MouseEvent<HTMLElement>) => {
    onShowHideClick();
    event.stopPropagation();
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    onDeleteClick();
    event.stopPropagation();
  };

  return (
    <div className="object-list-actions">
      {isVisible && (
        <IconedButton
          className="clickable"
          icon={faEye}
          onClick={handleShowHideClick}
          tabIndex={-1}
        />
      )}
      {!isVisible && (
        <IconedButton
          className="clickable"
          icon={faEyeSlash}
          onClick={handleShowHideClick}
          tabIndex={-1}
        />
      )}
      {isDeleteVisible && (
        <IconedButton
          className="clickable"
          icon={faTrashAlt}
          onClick={handleDeleteClick}
          tabIndex={-1}
        />
      )}
    </div>
  );
};
