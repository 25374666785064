import './S_TextInput.scss';

import React, { ChangeEvent, ChangeEventHandler, FC } from 'react';

import { Input } from 'reactstrap';

export interface S_TextInputProps {
  id: string;
  value: string;
  onChange(value: string): void;
  placeholder?: string;
  valid?: boolean;
  disabled?: boolean;
}

export const S_TextInput: FC<S_TextInputProps> = (props: S_TextInputProps) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e: ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value);
  return (
    <Input
      id={props.id}
      value={props.value}
      valid={props.valid}
      placeholder={props.placeholder}
      disabled={props.disabled}
      className={`s-text-input ${props.valid === false ? 'invalid' : ''}`}
      onChange={handleChange}
    />
  );
}
