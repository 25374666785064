import * as React from 'react';

import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { DatasetDetailsImagesListTable } from './DatasetDetailsImagesListTable';
import { DatasetDetailsImagesListThumbs } from './DatasetDetailsImagesListThumbs';
import { DatasetDownloadProhibitionReason } from '../models/DatasetDownloadProhibitionReason';
import { IDatasetDetailsImage } from '../datasetsDetails.store';
import { IPagingInfoWithOrder } from '../../../models/paginationInfo.model';
import { InputStatus } from '../../../models/error.model';
import { ListViewMode } from '../../user/user.store';
import { Loader } from '../../../components/Loader';
import { RenameImageModal } from '../containers/RenameImageModal';
import { as } from '../../../helpers/react.helpers';

export interface IDatasetDetailsImagesProps {
  workspaceId: string;
  datasetId: string;
  datasetImages: IDatasetDetailsImage[];
  isLoading: boolean;
  showDeleteConfirmationModal: boolean;
  imageIdToDeleteWasAnnotated: boolean;
  imageIdToDeleteIsLocked: boolean;
  paging: IPagingInfoWithOrder;
  canDownloadImages: boolean;
  isImageDownloadable: boolean;
  canDeleteMarkedImages: boolean;
  canDeleteUnmarkedImages: boolean;
  downloadProhibitionReason: DatasetDownloadProhibitionReason | undefined;
  disabled: boolean;
  onImageDownload(id: string): void;
  onImageDeleted(id: string): void;
  onCancelImageDelete(): void;
  onConfirmImageDelete(): void;
  onPaginationChange(pageNumber: number, pageSize: number): void;
  onOrderingChange(orderBy: string, orderType: string): void;
  viewMode: ListViewMode;
  authToken: string;
  isUploading: boolean;
  imagesToBulkDelete: string[];
  onThumbCheck(id: string, isBatch: boolean): void;
  toggleRenameImageModal(imageName: string): void;
  showRenameImageConfirmationModal: boolean;
  renameImageName: string;
  renameImageInputStatus: InputStatus;
  renameImage(): void;
  handleRenameImageNameChange(value: string): void;
  onRenameImage(id: string): void;
  canRenameImages: boolean;
}

export class DatasetDetailsImagesListPure extends React.Component<IDatasetDetailsImagesProps> {
  getMessage(): string {
    let message = 'datasets:image_delete_confirmation_message';

    if (this.props.imageIdToDeleteWasAnnotated) {
      message = 'datasets:remove_this_image_has_already_been_annotated';
    }

    if (this.props.imageIdToDeleteIsLocked) {
      message = 'datasets:remove_this_image_is_in_use';
    }

    return message;
  }

  render() {
    const message = this.getMessage();
    const toggleRenameImageModalWithEmpty = () => this.props.toggleRenameImageModal('');

    return (
      <div className="details-image-list">
        <Loader isLoading={this.props.isUploading}>
          {this.props.viewMode === ListViewMode.List && <DatasetDetailsImagesListTable {...this.props} />}
          {this.props.viewMode === ListViewMode.Tiles && <DatasetDetailsImagesListThumbs {...this.props} />}
        </Loader>
        <ConfirmationModal
          showModal={this.props.showDeleteConfirmationModal}
          onCancel={this.props.onCancelImageDelete}
          onConfirm={this.props.onConfirmImageDelete}
          confirmationHeader="datasets:image_delete_confirmation_header"
          confirmationQuestion={message}
          confirmationYes="datasets:delete"
        />
        <RenameImageModal
          showRenameImage={this.props.showRenameImageConfirmationModal}
          renameImageName={this.props.renameImageName}
          renameImageNameStatus={this.props.renameImageInputStatus}
          toggleRenameImage={toggleRenameImageModalWithEmpty}
          onNameChange={this.props.handleRenameImageNameChange}
          onRenameSave={this.props.renameImage}
        />
      </div>
    );
  }
}

export const DatasetDetailsImagesList = as<React.ComponentClass<IDatasetDetailsImagesProps>>(DatasetDetailsImagesListPure);
