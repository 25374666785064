import { S_IconSelectOption, S_IconSelectOptionProps } from './option/S_IconSelectOption';

import React from 'react';
import Select from 'react-select';

interface Props {
  options: S_IconSelectOptionProps[];
  value: S_IconSelectOptionProps;
  isSearchable?: boolean;
  disabled?: boolean;
  onChange(value: any): void;
}

export const S_IconSelect: React.FC<Props> = props => (
  <Select<S_IconSelectOptionProps>
    options={props.options}
    value={props.value}
    isDisabled={props.disabled}
    isSearchable={props.isSearchable}
    components={{ Option: S_IconSelectOption }}
    classNamePrefix="custom-select"
    onChange={props.onChange}
  />
);
