import { IWorkspace, WorkspaceRole } from '../../../__legacy__/modules/workspaces/workspaces.store';

import { getCurrentUTCTime } from '../../../__legacy__/helpers/date.helpers';
import { injectable } from 'inversify';
import { observable } from 'mobx';

export const CurrentWorkspaceStoreType = Symbol('CURRENT_WORKSPACE_STORE');

export interface ICurrentWorkspaceStore {
  readonly currentWorkspace: IWorkspace | undefined;
  readonly currentWorkspaceTime: Date;
  isInRole(roles: WorkspaceRole[]): boolean;
}

export const CurrentWorkspaceStoreSetterType = Symbol('CURRENT_WORKSPACE_STORE_SETTER');
export interface ICurrentWorkspaceStoreSetter extends ICurrentWorkspaceStore {
  setCurrentWorkspace(selectedWorkspace: IWorkspace | undefined): void;
}

@injectable()
export class CurrentWorkspaceStore implements ICurrentWorkspaceStoreSetter {
  @observable
  currentWorkspace: IWorkspace | undefined;

  setCurrentWorkspace(selectedWorkspace: IWorkspace | undefined): void {
    this.currentWorkspace = selectedWorkspace;
  }

  isInRole(roles: WorkspaceRole[]): boolean {
    if (!this.currentWorkspace?.role) return false;
    return roles.includes(this.currentWorkspace.role);
  }

  get currentWorkspaceTime(): Date {
    const now = getCurrentUTCTime();
    now.setMinutes(now.getMinutes() - this.currentWorkspace!.timeZoneOffset);
    return now;
  }
}
