import { IObservableArray, action, computed, observable } from 'mobx';
import { IPagingInfo, PaginationInfoDefault } from '../../models/paginationInfo.model';

import { ITeamMember } from './models/TeamMember';
import { SortingDirection } from '../../models/sortingDirection.model';
import { TimePeriod } from '../../../design/selects/timePeriodSelector/S_TimePeriodSelector';
import { applyPagination } from '../../../common/pagination/applyPagination';
import { injectable } from 'inversify';
import { nameOf } from '../../helpers/object.helpers';
import { sortBy } from '../../helpers/collections.helper';

export const TeamStoreType = Symbol('IMPORT_ANNOTATIONS_STORE');
export const TeamStoreSetterType = Symbol('IMPORT_ANNOTATIONS_STORE_SETTER');

export interface ITeamStore {
  readonly currentPageTeamMembers: ITeamMember[];
  readonly orderBy: string;
  readonly orderDirection: SortingDirection;
  readonly pagination: IPagingInfo;
  readonly statisticsTimePeriod: TimePeriod;
  readonly teamMembers: ITeamMember[];

  readonly isLoading: boolean;
}

export interface ITeamStoreSetter extends ITeamStore {
  setPagination(pageNumber: number, pageSize: number): void;
  setSorting(orderBy: string, orderDirection: SortingDirection): void;
  setStatisticsTimePeriod(timePeriod: TimePeriod): void;
  setTeamMembers(teamMembers: ITeamMember[]): void;
  setIsLoading(isLoading: boolean): void;
}

const defaultSortingBy: string = nameOf<ITeamMember>('email');
const defaultSortingDirection: SortingDirection = SortingDirection.ASC;

@injectable()
export class TeamStore implements ITeamStore, ITeamStoreSetter {
  @observable
  private _teamMembers: IObservableArray<ITeamMember> = observable.array([]);

  @observable
  orderBy: string = defaultSortingBy;

  @observable
  orderDirection = defaultSortingDirection;

  @observable
  statisticsTimePeriod = TimePeriod.Month;

  @observable
  pagination = PaginationInfoDefault;

  @observable
  isLoading: boolean = false;

  @computed
  get currentPageTeamMembers(): ITeamMember[] {
    const sorted = sortBy(this._teamMembers, this.orderBy, this.orderDirection, defaultSortingBy, defaultSortingDirection);
    return applyPagination(sorted, this.pagination);
  }

  @computed
  get teamMembers(): ITeamMember[] {
    return this._teamMembers;
  }

  @action
  setTeamMembers(teamMembers: ITeamMember[]): void {
    this._teamMembers.replace(teamMembers);
    this.pagination.totalCount = this._teamMembers.length;
    this.pagination.pageNumber = 1;
  }

  @action
  setSorting(orderBy: string, orderDirection: SortingDirection) {
    this.orderBy = orderBy;
    this.orderDirection = orderDirection;
  }

  @action
  setStatisticsTimePeriod(timePeriod: TimePeriod) {
    this.statisticsTimePeriod = timePeriod;
  }

  @action
  setPagination(pageNumber: number, pageSize: number) {
    this.pagination.pageNumber = pageNumber;
    this.pagination.pageSize = pageSize;
  }

  @action
  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }
}
