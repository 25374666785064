import { WithNamespaces, withNamespaces } from 'react-i18next';

import React from 'react';
import { S_PrimaryButton } from '../../../../../../../design/buttons/textButton/primaryButton/S_PrimaryButton';

interface Props {
  onStartImport(): void;
}

const ImportAnnotationsButtonPure: React.FC<Props & WithNamespaces> = props => (
  <S_PrimaryButton onClick={props.onStartImport} text={props.t('new.projects.details.import_annotations.header')} className="import-annotations-button" />
);

export const ImportAnnotationsButton = withNamespaces()(ImportAnnotationsButtonPure);
