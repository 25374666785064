import * as React from 'react';
import { as, injectProps } from '../../../../__legacy__/helpers/react.helpers';
import { observer } from 'mobx-react';
import { EvaluationSettingsForm } from '../components/EvaluationSettingsForm';
import { ValueType } from 'react-select/lib/types';
import { Loader } from '../../../../__legacy__/components/Loader';
import { EvaluationDetailsServiceType, IEvaluationDetailsService } from '../../evaluationDetails.service';
import { BooleanOption } from '../../evaluationDetails.model';
import { ModelPermissions } from '../../../../__legacy__/modules/workspaces/workspaces.store';
import { IWorkspaceService, WorkspaceServiceType } from '../../../../__legacy__/modules/workspaces/workspaces.service';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../common/OverlayLoader.store';

interface IInjectedProps {
  evaluationDetailsService: IEvaluationDetailsService;
  workspaceService: IWorkspaceService;
  overlayLoader: IOverlayLoaderStore;
}

@injectProps({
  evaluationDetailsService: EvaluationDetailsServiceType,
  workspaceService: WorkspaceServiceType,
  overlayLoader: OverlayLoaderStoreType,
})
@observer
class EvaluationSettingsFormContainerPure extends React.Component<IInjectedProps> {
  handleChangeBooleanParam = (label: string) => (value: ValueType<BooleanOption>) => {
    this.props.evaluationDetailsService.handleChangeBooleanParam(label, (value as BooleanOption).value);
  };

  handleChangeNumericParam = (label: string) => (value: string) => {
    this.props.evaluationDetailsService.handleChangeNumericParam(label, value);
  };

  handleBlurNumericParam = (label: string) => (value: string) => {
    this.props.evaluationDetailsService.handleBlurNumericParam(label, value);
  };

  handleBlurBooleanParam = (label: string) => (value: ValueType<BooleanOption>) => {
    this.props.evaluationDetailsService.handleBlurBooleanParam(label, (value as BooleanOption).value);
  };

  render() {
    const { evaluationConfig, evaluationDetailsValidationErrors, params, status, isOwner } = this.props.evaluationDetailsService.store;
    const workspaceRole = this.props.workspaceService.getUserRoleInCurrentWorkspace();

    return (
      <Loader isLoading={!!this.props.overlayLoader.isSpinnerVisible('evaluation-settings-list')} class="evaluation-settings-list">
        <EvaluationSettingsForm
          params={params}
          evaluationConfig={evaluationConfig}
          evaluationDetailsValidationErrors={evaluationDetailsValidationErrors}
          status={status}
          onChangeNumericParam={this.handleChangeNumericParam}
          onBlurNumericParam={this.handleBlurNumericParam}
          onChangeBooleanParam={this.handleChangeBooleanParam}
          onBlurBooleanParam={this.handleBlurBooleanParam}
          disableAll={isOwner ? !ModelPermissions.updateInferenceWhenOwner.includes(workspaceRole) : !ModelPermissions.updateInference.includes(workspaceRole)}
        />
      </Loader>
    );
  }
}

export const EvaluationSettingsFormContainer = as<React.ComponentClass>(EvaluationSettingsFormContainerPure);
