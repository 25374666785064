import * as React from 'react';

import { Button, Card, CardBody, CardTitle } from 'reactstrap';
import { faCloud, faExclamationTriangle, faUpload } from '@fortawesome/free-solid-svg-icons';

import { ButtonWithLoader } from '../../../components/ButtonWithLoader';
import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { DatasetStatus } from '../datasetStatus.model';
import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IImageFile } from '../imagesUpload.service';
import { IImageUrl } from '../imagesFromUrlsUpload.service';
import { IRejectedFile } from '../models/IRejectedFile';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { ImageUploader } from './ImagesUploader';
import { ImagesUrlUploader } from './ImagesUrlUploader';
import { Loader } from '../../../components/Loader';
import { NODE_STREAM_INPUT } from 'papaparse';
import { NoAccessTooltip } from '../../../containers/NoAccessTooltip';
import { UploadOption } from './ImageUploadModal';
import { as } from '../../../helpers/react.helpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';

library.add(faUpload, faCloud, faExclamationTriangle);

export interface IImageUploadProps {
  datasetId: string;
  datasetStatus: DatasetStatus;
  isCreationEnabled: boolean;
  isEncryptionEnabled: boolean;
  isProcessingImages: boolean;
  imagesToUploadFromFile: IImageFile[];
  imagesRejectedFromFile: IRejectedFile[];
  imagesToUploadFromUrl: IImageUrl[];
  willExceedsUploadImageSizeLimit: boolean;
  imagePreValidationWasSuccessful: boolean;
  onFormSubmit(): void;
  cleanImagesFromUrl(datasetId: string): void;
  cleanImagesFromFile(datasetId: string): void;
  rejectUrlsFile(datasetId: string): void;
  addImagesToDataset(images: File[]): void;
  getUrlsFromFileAsync(file: typeof NODE_STREAM_INPUT): void;
  toggleUploadConfirmationModal(): void;
  isConfirmationModalOpen: boolean;
  ownerPlan: string;
  canUploadImages: boolean;
  loaderKey?: string;
}

interface IImageUploadFormProps {
  showSubmitButtons: boolean;
  selectedUploadOption?: UploadOption;
  onSelectDriveOption(): void;
  onSelectUrlOption(): void;
  onCancel(): void;
}

type IProps = IImageUploadProps & IImageUploadFormProps & ITranslatable;

class ImageUploadFormPure extends React.Component<IProps> {
  render() {
    return (
      <>
        <NoAccessTooltip hasAccess={this.props.canUploadImages}>
          <Card className="flex-fill dataset-upload-card">
            <Loader isLoading={this.props.datasetStatus !== DatasetStatus.DRAFT && this.props.datasetStatus !== DatasetStatus.PUBLISHED}>
              {this.props.selectedUploadOption === UploadOption.None && (
                <CardBody>
                  <div className="permissions-overlay" />
                  <>
                    <CardTitle tag="p" className="strong">
                      {this.props.t('please_select_dataset_source')}
                    </CardTitle>
                    <div className="dataset-source">
                      <Button outline color="primary" onClick={this.props.onSelectDriveOption} className="ml-0">
                        <FontAwesomeIcon icon={faUpload} />
                        <p>{this.props.t('dataset_source_local')}</p>
                        <span>{this.props.t('upload_from_hard_drive')}</span>
                      </Button>
                      <Button outline color="primary" onClick={this.props.onSelectUrlOption} disabled={this.props.isEncryptionEnabled}>
                        <FontAwesomeIcon icon={faCloud} />
                        <p>{this.props.t('dataset_source_cloud')}</p>
                        <span>{this.props.t(this.props.isEncryptionEnabled ? 'option_unavailable_in_encrypted_workspaces' : 'share_your_csv_links_with_us')}</span>
                      </Button>
                    </div>
                  </>
                </CardBody>
              )}

              {this.props.selectedUploadOption !== UploadOption.None && (
                <CardBody>
                  <CardTitle tag="p" className="strong">
                    {this.props.t('please_select_your_files')}
                  </CardTitle>
                  {this.props.selectedUploadOption === UploadOption.Drive && (
                    <ImageUploader
                      datasetId={this.props.datasetId}
                      isProcessingImages={this.props.isProcessingImages}
                      imagesToUploadFromFile={this.props.imagesToUploadFromFile}
                      imagesRejectedFromFile={this.props.imagesRejectedFromFile}
                      cleanImagesFromUrl={this.props.cleanImagesFromUrl}
                      addImagesToDataset={this.props.addImagesToDataset}
                    />
                  )}

                  {this.props.selectedUploadOption === UploadOption.Url && (
                    <ImagesUrlUploader
                      datasetId={this.props.datasetId}
                      isProcessingImages={this.props.isProcessingImages}
                      imagesToUploadFromUrl={this.props.imagesToUploadFromUrl}
                      cleanImagesFromFile={this.props.cleanImagesFromFile}
                      rejectUrlsFile={this.props.rejectUrlsFile}
                      getUrlsFromFileAsync={this.props.getUrlsFromFileAsync}
                    />
                  )}

                  {this.props.willExceedsUploadImageSizeLimit && (
                    <p className="error-feedback">
                      {this.props.t('uploading_selected_file_will_exceed_workspace_owners_total_files_size_limit', { plan: this.props.t(this.props.ownerPlan) })}
                    </p>
                  )}
                  {!this.props.imagePreValidationWasSuccessful && (
                    <p className="warning-feedback">
                      <FontAwesomeIcon icon={faExclamationTriangle} className="warning-icon" /> {this.props.t('we_were_not_able_to_validate_all_provided_files')}
                    </p>
                  )}
                  {this.props.showSubmitButtons && (
                    <div className="float-right pb-3">
                      <DisabledWhenWorkspaceOwnerPolicyExceeded>
                        <ButtonWithLoader
                          color={'primary'}
                          disabled={!this.props.isCreationEnabled}
                          loaderKey={this.props.loaderKey}
                          onClick={this.props.datasetStatus === DatasetStatus.DRAFT ? this.props.onFormSubmit : this.props.toggleUploadConfirmationModal}
                          id="upload-button"
                        >
                          {this.props.t('upload')}
                        </ButtonWithLoader>
                      </DisabledWhenWorkspaceOwnerPolicyExceeded>
                      <Button color={'primary'} outline onClick={this.props.onCancel} id="cancel-button">
                        {this.props.t('cancel')}
                      </Button>
                    </div>
                  )}
                </CardBody>
              )}
            </Loader>
          </Card>
        </NoAccessTooltip>
        <ConfirmationModal
          confirmationHeader="datasets:confirm_image_upload"
          confirmationQuestion="datasets:confirm_image_upload_body"
          confirmationYes={this.props.t('continue')}
          onCancel={this.props.toggleUploadConfirmationModal}
          onConfirm={this.props.onFormSubmit}
          showModal={this.props.isConfirmationModalOpen}
        />
      </>
    );
  }
}

export const ImageUploadForm = as<React.FunctionComponent<IImageUploadProps & IImageUploadFormProps>>(withNamespaces('datasets', { wait: true })(ImageUploadFormPure));
