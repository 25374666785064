import * as React from 'react';

import { Card, CardBody, CardTitle } from 'reactstrap';

import { as } from '../../../helpers/react.helpers';

const PrivacyPolicy = () => {
  const policyLink = 'https://zillin.io/legal/zillin_privacy_policy.pdf';
  const contactMail = 'hello@zillin.io';

  return (
    <Card className="flex-fill">
      <CardBody>
        <CardTitle tag="h5" className="name">
          Privacy Policy Update
        </CardTitle>
        <p>
          {'We are updating our Privacy Policy on 26.05.2021.'} <br />
          {'You can view our New Privacy Policy by visiting: '}
          <a target="_blank" href={policyLink} className="styled_link nav_link" rel="noopener noreferrer">
            {policyLink}.
          </a>
          <br />
          {'If you have any questions about your privacy or our Privacy Policy, please contact us at '}
          <a target="_blank" href={`mailto:${contactMail}`} className="styled_link nav_link" rel="noopener noreferrer">
            {contactMail}.
          </a>
        </p>
      </CardBody>
    </Card>
  );
};

export default as<React.ComponentClass>(PrivacyPolicy);
