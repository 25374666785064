import * as React from 'react';

import { GTable, IGColumnProps, enumDirectionToStringOrder, stringOrderToEnumDirection } from '../../../components/table/GTable';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { toLocaleDateString, toLocaleDateTimeString } from '../../../helpers/date.helpers';

import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { IUserDetails } from '../projectDetails.models';
import { IconedButton } from '../../../components/IconedButton';
import { Loader } from '../../../components/Loader';
import { NoAccessTooltip } from '../../../containers/NoAccessTooltip';
import { S_PaginationProps } from '../../../../design/pagination/S_Pagination';
import { SortingDirection } from '../../../models/sortingDirection.model';
import { handleClickAndPassData } from '../../../helpers/formHelpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { sortBy } from '../../../helpers/collections.helper';

library.add(faTrashAlt, faEdit);

interface IUsersListProps {
  users: IUserDetails[];
  orderBy: string;
  orderDirection: SortingDirection | undefined;
  isLoading: boolean;
  canRemoveUserFromProject: boolean;
  onRemoveUser(userId: string): void;
  onSortChange(orderBy: string, orderDirection: SortingDirection | undefined): void;
  pagination: S_PaginationProps;
}

const UsersListPure: React.FC<IUsersListProps & WithNamespaces> = props => {
  const joinDateRenderer = (data: IUserDetails) => <div>{data.joinDate ? toLocaleDateString(data.joinDate) : ''}</div>;

  const lastLoginDateRenderer = (data: IUserDetails) => <div>{data.lastLoginDate ? toLocaleDateTimeString(data.lastLoginDate) : ''}</div>;

  const actionsRenderer = (data: IUserDetails) => (
    <div className="g-actions">
      <NoAccessTooltip hasAccess={props.canRemoveUserFromProject}>
        <DisabledWhenWorkspaceOwnerPolicyExceeded>
          <IconedButton onClick={handleClickAndPassData(props.onRemoveUser)(data.id)} icon={'trash-alt'} color="red" title={props.t('remove_user')} />
        </DisabledWhenWorkspaceOwnerPolicyExceeded>
      </NoAccessTooltip>
    </div>
  );

  const handleSortChange = (orderBy: string, orderDirection: string) => props.onSortChange(orderBy, stringOrderToEnumDirection(orderDirection));

  const gColumns: IGColumnProps<IUserDetails>[] = [
    {
      field: 'email',
      headerName: props.t('email_address'),
    },
    {
      field: 'role',
      headerName: props.t('role'),
      cellClass: 'user-role',
      width: 200,
    },
    {
      field: 'totalReviewed',
      headerName: props.t('total_reviewed'),
      headerTooltip: props.t('total_reviewed_tooltip'),
      width: 200,
    },
    {
      field: 'totalSubmitted',
      headerName: props.t('total_submitted'),
      headerTooltip: props.t('total_submitted_tooltip'),
      width: 200,
    },
    {
      field: 'joinDate',
      headerName: props.t('date_joined'),
      renderer: joinDateRenderer,
      width: 200,
    },
    {
      field: 'lastLoginDate',
      headerName: props.t('last_login_date'),
      renderer: lastLoginDateRenderer,
      width: 200,
    },
    {
      field: '',
      headerName: props.t('actions'),
      renderer: actionsRenderer,
      sortable: false,
      width: 200,
    },
  ];

  const sorted = sortBy(props.users, props.orderBy, props.orderDirection);

  return (
    <div className="table-container">
      <Loader isLoading={props.isLoading}>
        <GTable<IUserDetails>
          noItemsPlaceholder={props.t('there_are_no_active_collaborators')}
          columns={gColumns}
          items={sorted}
          onSortChanged={handleSortChange}
          sortingModel={[{ orderBy: props.orderBy, orderType: enumDirectionToStringOrder(props.orderDirection) }]}
          paginationProps={props.pagination}
        />
      </Loader>
    </div>
  );
};

export const UsersList = withNamespaces('project', { wait: true })(UsersListPure);
