export const areEqualWithCaseIgnore = function (value1: string, value2: string) {
  return value1.trim().toLocaleLowerCase() === value2.trim().toLocaleLowerCase();
};

export function camelize(str: string) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return '';
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

export function getAcronym(value: string | null): string {
  return value === null || value === ''
    ? ''
    : value
      .split(' ')
      .map(w => w[0])
      .join('');
}

export function buffer2base64(buffer: ArrayBuffer): string {
  return btoa(new Uint8Array(buffer).reduce((str, byte) => str + String.fromCharCode(byte), ''));
}

export function bufferToHex(buffer: ArrayBuffer) {
  return new Uint8Array(buffer).reduce(
    (str, byte) =>
      str +
      byte
        .toString(16)
        .toUpperCase()
        .padStart(2, '0'),
    '',
  );
}

export function bytesToMBsOrGBs(bytes: number): string {
  if (bytes) {
    const megaBytes = bytes / 1024 / 1024;
    return megaBytes > 1024 ? `${(megaBytes / 1024).toFixed(2)} GB` : `${megaBytes.toFixed(2)} MB`;
  }

  return '0 MB';
}

export const UUIDRegex = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/i;
