import { IImagesQueueService, ImagesQueueServiceBase } from './imagesQueueServiceBase';
import { inject, injectable } from 'inversify';
import { ApiServiceType } from '../../services/api.service';
import { IApiService } from '../../services/api.service.base';
import { AnnotationApiServiceType, IAnnotationApiService } from './annotationApi.service';
import { AnnotationUiStoreType, IAnnotationUiStore } from './annotationUi.store';
import { INotificationsService, NotificationsServiceType } from '../notifications/services/notifications.service';
import { NotificationLevel, ToastNotification } from '../notifications/models/notification.model';
import { ConfigurationType, IConfiguration } from '../../../configuration';
import { AnnotationsStoreType, IAnnotationsStore } from './annotations.store';

export const AnnotationImagesQueueServiceType = Symbol('ANNOTATION_IMAGES_QUEUE_SERVICE');

@injectable()
export class AnnotationImagesQueueService extends ImagesQueueServiceBase implements IImagesQueueService {

  annotationApiService!: IAnnotationApiService;
  uiData: IAnnotationUiStore;
  notificationService: INotificationsService;

  constructor(
    @inject(ApiServiceType) apiService: IApiService,
    @inject(AnnotationApiServiceType) annotationApiService: IAnnotationApiService,
    @inject(AnnotationUiStoreType) uiData: IAnnotationUiStore,
    @inject(NotificationsServiceType) notificationService: INotificationsService,
    @inject(ConfigurationType) configuration: IConfiguration,
    @inject(AnnotationsStoreType) private readonly annotationsStore: IAnnotationsStore,
  ) {
    super(configuration, apiService, (value: boolean) => { this.uiData.isQueueRequestInProgress = value; });

    this.annotationApiService = annotationApiService;
    this.uiData = uiData;
    this.notificationService = notificationService;
  }

  public async setupAsync(projectId: string) {
    await super.setupBaseAsync(
      () => this.annotationApiService.getImageForAnnotationAsync(projectId, this.removeLocks),
      2,
      (imageId: string) => this.annotationApiService.reLockImageForAnnotationAsync(imageId, projectId),
      this.handleLockRefreshFail,
    );
  }

  private handleLockRefreshFail(imageId: string) {
    if (this.annotationsStore.image?.id === imageId) {
      this.notificationService.push(new ToastNotification(NotificationLevel.ERROR, 'image_is_locked_by_another_user'));
      setTimeout(() => { window.location.reload(); }, 4000);
    }
  }
}
