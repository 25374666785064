import { AttachmentsFolderView, AttachmentsView } from '../views/home/attachments/Attachments.view';

import { AttachmentImagePreviewView } from '../views/home/attachments/AttachmentImagePreview.view';
import { Home } from './config/Home';
import { NotFoundView } from '../views/NotFound.view';
import React from 'react';
import { Route } from 'react-router';
import { SwitchWithNotFound } from '../containers/route.containers';

export const AttachmentsRoute = () => (
  <SwitchWithNotFound notFound={NotFoundView}>
    <Route path={Home.Attachments.List.Preview.Path} component={AttachmentImagePreviewView} />
    <Route path={Home.Attachments.List.Folder.Path} component={AttachmentsFolderView} />
    <Route path={Home.Attachments.Path} component={AttachmentsView} />
  </SwitchWithNotFound>
);
