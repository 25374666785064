import * as React from 'react';

import { AnnotationCreationServiceType, IAnnotationCreationService } from '../modules/annotation/annotationCreation.service';
import { AuthServiceType, IAuthService } from '../modules/auth/auth.service';
import { BillingServiceType, IBillingService } from '../modules/billing/billing.service';
import { ConfigurationType, IConfiguration } from '../../configuration';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { IImagesFromUrlsService, ImagesFromUrlsServiceType } from '../modules/datesets/imagesFromUrlsUpload.service';
import { IImagesUploaderService, ImagesUploaderServiceType } from '../modules/datesets/imagesUpload.service';
import { IUserService, UserServiceType } from '../modules/user/user.service';
import { IUserStore, UserStoreType } from '../modules/user/user.store';
import { UserDropdown, UsernameVisibilityState } from '../components/UserDropdown';
import { as, injectProps } from '../helpers/react.helpers';

import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IOwnProps {
  usernameVisibility: UsernameVisibilityState;
}

interface IInjectedProps {
  authService: IAuthService;
  userStore: IUserStore;
  imagesFromUrlsService: IImagesFromUrlsService;
  imagesUploaderService: IImagesUploaderService;
  annotationCreationService: IAnnotationCreationService;
  billingService: IBillingService;
  userService: IUserService;
  currentWorkspaceStore: ICurrentWorkspaceStore;
  config: IConfiguration;
}

interface IState {
  showModal: boolean;
  showLimitModal: boolean;
}

@injectProps({
  authService: AuthServiceType,
  userStore: UserStoreType,
  imagesFromUrlsService: ImagesFromUrlsServiceType,
  imagesUploaderService: ImagesUploaderServiceType,
  annotationCreationService: AnnotationCreationServiceType,
  userService: UserServiceType,
  billingService: BillingServiceType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  config: ConfigurationType,
})
@observer
class UserDropdownContainerPure extends React.Component<IInjectedProps & IOwnProps, IState> {
  state: IState = {
    showModal: false,
    showLimitModal: false,
  };

  // TODO: This should be deleted in https://qzsolutions.atlassian.net/browse/S20-1653
  componentDidMount() {
    this.props.userService.getUserInfoAsync();
  }

  @autobind
  handleLogout() {
    if (this.props.imagesUploaderService.datasetsQueue.length !== 0 || this.props.imagesFromUrlsService.datasetsQueue.length !== 0) {
      this.setState({ showModal: true });
      return;
    }
    this.props.annotationCreationService.unlockImagesAsync();
    this.props.authService.logout();
  }

  @autobind
  handleConfirm() {
    this.props.imagesFromUrlsService.cancelUploads();
    this.props.imagesUploaderService.cancelUploads();
    this.props.authService.logout();
  }

  @autobind
  handleCancel() {
    this.setState({ showModal: false });
  }

  @autobind
  handleLimitIncrease() {
    this.setState({ showLimitModal: true });
  }

  render() {
    return (
      <UserDropdown
        workspaceId={this.props.currentWorkspaceStore.currentWorkspace!.id}
        shortName={this.props.userStore.shortName}
        email={this.props.userStore.userInfo.email}
        showModal={this.state.showModal}
        queryString={this.props.userStore.userInfo.queryString}
        showIncreaseLimit={!this.props.config.appConfig.runLocally && this.props.userStore.userInfo.showCart}
        increaseLimitEnabled={this.props.billingService.billing.enabled}
        onLogout={this.handleLogout}
        onCancel={this.handleCancel}
        onConfirm={this.handleConfirm}
        onIncreaseLimit={this.handleLimitIncrease}
        usernameVisibility={this.props.usernameVisibility}
      />
    );
  }
}

export const UserDropdownContainer = as<React.ComponentClass<IOwnProps>>(UserDropdownContainerPure);
