import { IProjectDetailsStore, ProjectDetailsStoreType } from '../projectDetails.store';
import { IProjectDetailsToolsStore, ProjectDetailsToolsStoreType } from '../sub/tools/projectDetailsTools.store';
import { as, injectProps } from '../../../helpers/react.helpers';

import React from 'react';
import { formAlertWithTranslations } from '../../../components/FormAlert';
import { observer } from 'mobx-react';

interface IProps {
  projectDetailsStore: IProjectDetailsStore;
  projectDetailsToolsStore: IProjectDetailsToolsStore;
}

const Errors = formAlertWithTranslations('project');

@injectProps({
  projectDetailsStore: ProjectDetailsStoreType,
  projectDetailsToolsStore: ProjectDetailsToolsStoreType,
})
@observer
class GlobalValidationErrorsContainerPure extends React.Component<IProps> {
  render() {
    return (
      <>
        <Errors errors={this.props.projectDetailsStore.datasetsStatus} />
        <Errors errors={this.props.projectDetailsToolsStore.toolsStatus} />
      </>
    );
  }
}

export const GlobalValidationErrorsContainer = as<React.ComponentClass>(GlobalValidationErrorsContainerPure);
