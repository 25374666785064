import { ContainerModule, interfaces } from 'inversify';

import { IEvaluationsStore, EvaluationsStore, EvaluationsStoreType } from './evaluations.store';
import { IEvaluationsService, EvaluationsService, EvaluationsServiceType } from './evaluations.service';
import { IEvaluationsApiService, EvaluationsApiService, EvaluationsApiServiceType } from './services/evaluationsApi.service';

export const evaluationsModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IEvaluationsStore>(EvaluationsStoreType)
    .to(EvaluationsStore)
    .inSingletonScope();
  bind<IEvaluationsService>(EvaluationsServiceType)
    .to(EvaluationsService)
    .inSingletonScope();
  bind<IEvaluationsApiService>(EvaluationsApiServiceType)
    .to(EvaluationsApiService)
    .inSingletonScope();
});
