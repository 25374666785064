import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { WorkspaceRole } from '../workspaces.store';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface ICurrentWorkspaceRoleProps {
  role?: WorkspaceRole;
}

class CurrentWorkspaceRolePure extends React.PureComponent<ICurrentWorkspaceRoleProps & ITranslatable> {
  render() {
    return (
      <div className="current-workspace top-nav-item ml-2 workspace-role">
        {this.props.t('your_role')}: {this.props.t(this.props.role?.toString() || '')}
      </div>
    );
  }
}

export const CurrentWorkspaceRole = as<React.SFC<ICurrentWorkspaceRoleProps>>(withNamespaces('workspace', { wait: true })(CurrentWorkspaceRolePure));
