import * as React from 'react';

import { S_Pagination, S_PaginationProps } from '../../../../design/pagination/S_Pagination';

import { DraftProjectThumbItem } from './projectListItems/DraftProjectThumbItem';
import { IProjectListItem } from '../projects.store';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { ListViewMode } from '../../user/user.store';
import { ProjectThumbItem } from './projectListItems/ProjectThumbItem';
import { Row } from 'reactstrap';
import { Settings } from '../../../../modules/settings/SettingsContext';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IProjectsListThumbsProps {
  workspaceId: string;
  projects: IProjectListItem[];
  projectsListViewMode: ListViewMode;
  pagination: S_PaginationProps;
  onDelete(projectId: string): void;
  onGoToClarifications?(projectId: string): void;
}

class ProjectsListThumbsPure extends React.Component<IProjectsListThumbsProps & ITranslatable> {
  static contextType = Settings;
  declare context: React.ContextType<typeof Settings>;

  constructor(props: any) {
    super(props);
  }

  handleCHange = (pageNumber: number, pageSize: number) => {
    this.context.setProjectsCardsPageSize(pageSize);
    this.props.pagination.onChange(pageNumber, pageSize);
  }

  render() {
    const projects = this.props.projects.map(project => (
      <React.Fragment key={project.id}>
        {project.status === 'Published' && (
          <ProjectThumbItem
            id={project.id}
            name={project.name}
            description={project.description}
            imageCount={project.imagesCount}
            usersCount={project.usersCount}
            myRejectedCount={project.myRejectedCount}
            onProjectDelete={this.props.onDelete}
            workspaceId={this.props.workspaceId}
            unansweredClarificationsCount={project.unansweredClarificationsCount}
            onGoToClarifications={this.props.onGoToClarifications}
            canAnnotate={project.canAnnotate}
            canDelete={project.canDelete}
            canEdit={project.canEdit}
            canReview={project.canReview}
            canView={project.canView}
            canExport={project.canExport}
          />
        )}
        {project.status === 'Draft' && (
          <DraftProjectThumbItem
            id={project.id}
            name={project.name}
            description={project.description}
            publishReady={project.canBePublished}
            deleteDraft={this.props.onDelete}
            workspaceId={this.props.workspaceId}
            canDelete={project.canDelete}
            canEdit={project.canEdit}
            canView={project.canView}
          />
        )}
      </React.Fragment>
    ));

    return (
      <>
        <Row className="project-list">{projects}</Row>
        <S_Pagination {...this.props.pagination} pageSize={this.context.store.projectsCardsPageSize} onChange={this.handleCHange} />
      </>
    );
  }
}

export const ProjectsListThumbs = as<React.ComponentClass<IProjectsListThumbsProps>>(withNamespaces('project', { wait: true })(ProjectsListThumbsPure));
