import { IProjectDetailsImagesBl, ProjectDetailsImagesBlType } from '../projectDetailsImages.bl';
import { IProjectDetailsImagesStore, ProjectDetailsImagesStoreType } from '../projectDetailsImages.store';
import { IProjectDetailsPermissions, ProjectDetailsPermissionsType } from '../../../projectDetails.permissions';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { Button } from 'reactstrap';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import React from 'react';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps extends ITranslatable {
  projectDetailsImagesBl: IProjectDetailsImagesBl;
  imagesStore: IProjectDetailsImagesStore;
  permissions: IProjectDetailsPermissions;
}

@injectProps({
  projectDetailsImagesBl: ProjectDetailsImagesBlType,
  imagesStore: ProjectDetailsImagesStoreType,
  permissions: ProjectDetailsPermissionsType,
})
@observer
class SelectAllButtonContainerPure extends React.Component<IInjectedProps> {
  changeSelectionMode = () => this.props.projectDetailsImagesBl.changeSelectionMode();

  render() {
    const { selectionMode } = this.props.imagesStore;

    if (!this.props.permissions.canUpdateImageSet() && !this.props.permissions.canBatchAnswer()) return null;

    return (
      <Button
        color={selectionMode === 'Select' ? 'primary' : 'secondary'}
        onClick={this.changeSelectionMode}
      >
        {this.props.t(`${selectionMode}`)}
      </Button>
    );
  }
}

export const SelectAllButtonContainer = as<React.ComponentClass>(withNamespaces('common')(SelectAllButtonContainerPure));
