import { LatLng } from 'leaflet';

const PLUS_SIZE = 4;
const ARROW_SIZE = 8;
const ARROW_ANGLE = 40;

export const getVectorStartCoords = (point: LatLng, zoom: number): LatLng[] => [
  new LatLng(point.lat - PLUS_SIZE / zoom * 100, point.lng),
  new LatLng(point.lat + PLUS_SIZE / zoom * 100, point.lng),
  new LatLng(point.lat, point.lng),
  new LatLng(point.lat, point.lng - PLUS_SIZE / zoom * 100),
  new LatLng(point.lat, point.lng + PLUS_SIZE / zoom * 100),
];

export const getVectorEndCoords = ([start, end]: LatLng[], zoom: number): LatLng[] => {
  const alpha = Math.atan2(end.lng - start.lng, end.lat - start.lat) - Math.PI / 2;
  const halfArrowAngle = ARROW_ANGLE / 2 * Math.PI / 180;
  const arrowSize = ARROW_SIZE / zoom * 100;
  return [
    new LatLng(end.lat + arrowSize * Math.sin(alpha + halfArrowAngle), end.lng - arrowSize * Math.cos(alpha + halfArrowAngle)),
    new LatLng(end.lat, end.lng),
    new LatLng(end.lat + arrowSize * Math.sin(alpha - halfArrowAngle), end.lng - arrowSize * Math.cos(alpha - halfArrowAngle)),
  ];
};
