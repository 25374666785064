import * as React from 'react';

import { ITranslatable } from '../../../helpers/translations.helpers';
import { PageHeader } from '../../../components/PageHeader';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

class UserDetailsHeaderPure extends React.Component<ITranslatable, {}> {
  render() {
    return (
      <PageHeader
      id="userDetails-header"
      headerText={this.props.t('account')}
      tabNavPresent="clean"
    />
    );
  }
}

export const UserDetailsHeader = as<React.ComponentClass>(withNamespaces('common', { wait: true })(UserDetailsHeaderPure))