import { AnnotationImageNameHeader } from '../../annotation/components/AnnotationImageNameHeader';
import { DatasetStatus } from '../datasetStatus.model';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { InputStatus } from '../../../models/error.model';
import { MAX_FILE_NAME_LENGTH } from '../../../helpers/global.constants';
import React from 'react';
import { ValidatedTextInput } from '../../../components/ValidatedTextInput';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  canRenameImages: boolean;
  name: string;
  nameStatus: InputStatus;
  newName: string;
  onCancelRenameImage(): void;
  onImageNameBlur(): Promise<void>;
  onImageNameChange(imageName: string): void;
  datasetName?: string;
  datasetStatus?: DatasetStatus;
  onStartEdit(): void;
}

interface IState {
  renderInput: boolean;
}

class EditableImagePreviewHeaderPure extends React.Component<IProps & ITranslatable, IState> {
  constructor(props: IProps & ITranslatable) {
    super(props);

    this.state = {
      renderInput: false,
    };
  }

  render() {
    const containerClassName = `image-name-wrapped ${this.props.canRenameImages ? 'hover-highlight' : ''}`;
    const showWarning =
      this.props.datasetStatus !== undefined && this.props.datasetStatus !== DatasetStatus.PUBLISHED && this.props.datasetStatus !== DatasetStatus.PUBLISHEDUPLOADING;

    const openRenameInput = () => {
      if (this.props.canRenameImages) {
        this.props.onStartEdit();
        this.setState({
          renderInput: true,
        });
      }
    };

    const closeRenameInput = () => {
      this.setState({
        renderInput: false,
      });
    };

    const onImageNameBlur = async () => {
      await this.props.onImageNameBlur();

      if (this.props.nameStatus.isEmpty) {
        closeRenameInput();
      }
    };

    const onCancelRenameImage = () => {
      this.props.onCancelRenameImage();
      closeRenameInput();
    };

    return (
      <>
        {this.state.renderInput ? (
          <ValidatedTextInput
            id="rename-image"
            type={'text'}
            maxLength={MAX_FILE_NAME_LENGTH}
            value={this.props.newName}
            placeholder={this.props.t('type_image_name_here')}
            onChange={this.props.onImageNameChange}
            onBlur={onImageNameBlur}
            styleClassName={'input_click_editable image-preview-name'}
            isValid={this.props.nameStatus.isValid}
            feedbacks={this.props.nameStatus.errorCodes.map(ec => this.props.t(`attachments.validation.${ec}`))}
            feedbacksParams={{ nsSeparator: '_' }}
            autofocus
            onPressEnter={onImageNameBlur}
            onPressEscape={onCancelRenameImage}
          />
        ) : (
          <div className={containerClassName} onClick={openRenameInput}>
            <AnnotationImageNameHeader imageName={this.props.name} datasetName={this.props.datasetName!} showWarning={showWarning} warningTooltip={this.props.t('unpublished')} />
          </div>
        )}
      </>
    );
  }
}

export const EditableImagePreviewHeader = as<React.ComponentClass<IProps>>(withNamespaces('new')(EditableImagePreviewHeaderPure));
