import * as React from 'react';

import { Col, Container, Row } from 'reactstrap';

import { Auth } from '../routes/config/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../helpers/translations.helpers';
import { as } from '../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

class ErrorViewPure extends React.Component<ITranslatable> {
  render() {
    return (
      <Container className="error-page">
        <Row className="h-100 w-100">
          <Col md="6" className="align-self-center">
            {/* here lies Major Tom */}
          </Col>
          <Col md="6" className="align-self-center">
            <h1>OOPS!</h1>
            <p>
              {this.props.t('error_page_body')} <br /> {this.props.t('error_page_body2')}
            </p>
            <a href={Auth.Login.Path} className={'btn btn-primary'} rel="noreferrer">
              <FontAwesomeIcon icon="home" /> <span className="description">{this.props.t('projects')}</span>
            </a>
          </Col>
        </Row>
      </Container>
    );
  }
}

export const ErrorView = as<React.ComponentClass>(withNamespaces('common')(ErrorViewPure));
