import { S_SelectField, S_SelectFieldOption } from '../../../../../../../../design/fields/selectField/S_SelectField';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import { AnnotationToolType } from '../../../../../../../../__legacy__/modules/annotation/submodules/annotationTypes/models/annotationToolType';
import React from 'react';
import { orderBy } from 'lodash';

interface Props {
  toolType: AnnotationToolType;
  onChangeToolType(toolType: AnnotationToolType): void;
  disabled?: boolean;
}

const ToolTypeFieldPure: React.FC<Props & WithNamespaces> = (props: Props & WithNamespaces) => {
  const options: S_SelectFieldOption<AnnotationToolType>[] = orderBy<S_SelectFieldOption<AnnotationToolType>>(
    [
      { label: props.t('point'), value: AnnotationToolType.POINT },
      { label: props.t('rectangle'), value: AnnotationToolType.RECTANGLE },
      { label: props.t('polygon'), value: AnnotationToolType.POLYGON },
      { label: props.t('vector'), value: AnnotationToolType.VECTOR },
      { label: props.t('polyline'), value: AnnotationToolType.POLYLINE },
      { label: props.t('rotatedrectangle'), value: AnnotationToolType.ROTATEDRECTANGLE },
      { label: props.t('brush'), value: AnnotationToolType.BRUSH },
    ],
    o => o.label,
  );

  return (
    <S_SelectField
      labelText={props.t('projects.details.annotation_tools.marking_tools.tool_type')}
      onChange={props.onChangeToolType}
      options={options}
      value={options.find(o => o.value === props.toolType)!}
      disabled={props.disabled}
    />
  );
}

export const ToolTypeField = withNamespaces('new')(ToolTypeFieldPure);
