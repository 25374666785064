import React, { useState } from 'react';

import { LegacyStatisticsContainer } from '../../../../__legacy__/modules/projectDetails/sub/statistics/containers/LegacyStatistics.container';
import { ProjectStatsSectionsContainer } from './ProjectStatsSections.container';

export const ProjectStatsSwitcher: React.FC = () => {
  const [showNewStats, setShowNewStats] = useState<boolean>(true);

  const toggleStats = () => setShowNewStats(!showNewStats);

  return showNewStats ? <ProjectStatsSectionsContainer onChangeViewClicked={toggleStats} /> : <LegacyStatisticsContainer onChangeViewClicked={toggleStats} />;
};
