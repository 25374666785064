import { Button } from 'reactstrap';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IWorkspaceSubmitButtonProps {
  label: string;
  disabled: boolean;
  onSubmit(): void;
}

class WorkspaceSubmitButtonPure extends React.Component<IWorkspaceSubmitButtonProps & ITranslatable> {
  render() {
    return (
      <Button disabled={this.props.disabled} color="success" className="mr-2" onClick={this.props.onSubmit}>
        {this.props.t(this.props.label)}
      </Button>
    );
  }
}

export const WorkspaceSubmitButton = as<React.ComponentClass<IWorkspaceSubmitButtonProps>>(withNamespaces('workspace')(WorkspaceSubmitButtonPure));
