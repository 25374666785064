import * as React from 'react';

import { Container } from 'reactstrap';
import { ITranslatable } from '../helpers/translations.helpers';
import { as } from '../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

type customClassType = 'with-form' | 'clean';

interface IProps {
  customClass: customClassType;
  children: React.ReactElement<any>[] | React.ReactElement<any>;
}

const PageHeaderCleanTranslated = withNamespaces('common')((props: IProps & ITranslatable) => (
  <Container fluid className={`page-header-clean ${props.customClass}`}>
    {props.children}
  </Container>
));

export const PageHeaderClean = as<React.SFC<IProps>>(PageHeaderCleanTranslated);
