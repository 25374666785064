import {
  CheckAttachmentNameUniquenessService,
  CheckAttachmentNameUniquenessServiceType,
  ICheckAttachmentNameUniquenessService,
} from './services/CheckAttachmentNameUniqueness.service';
import { ContainerModule, interfaces } from 'inversify';
import { IRenameAttachmentBl, RenameAttachmentBl, RenameAttachmentBlType } from './RenameAttachment.bl';
import { IRenameAttachmentService, RenameAttachmentService, RenameAttachmentServiceType } from './services/RenameAttachment.service';
import {
  IRenameAttachmentStore,
  IRenameAttachmentStoreSetter,
  RenameAttachmentStore,
  RenameAttachmentStoreSetterType,
  RenameAttachmentStoreType,
} from './RenameAttachment.store';

export const renameAttachmentModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IRenameAttachmentStore>(RenameAttachmentStoreType).to(RenameAttachmentStore).inSingletonScope();
  bind<IRenameAttachmentStoreSetter>(RenameAttachmentStoreSetterType).toService(RenameAttachmentStoreType);

  bind<IRenameAttachmentService>(RenameAttachmentServiceType).to(RenameAttachmentService).inRequestScope();
  bind<ICheckAttachmentNameUniquenessService>(CheckAttachmentNameUniquenessServiceType).to(CheckAttachmentNameUniquenessService).inRequestScope();

  bind<IRenameAttachmentBl>(RenameAttachmentBlType).to(RenameAttachmentBl).inSingletonScope();
});
