import { AnnotationCommand } from './annotationCommand';
import { IAnnotationService } from '../annotation.service';
import { IAnnotationTypeBl } from '../submodules/annotationTypes/annotationType.bl';
import { IAnnotationsStore } from '../annotations.store';
import { IFreeDrawSegmentationService } from '../freeDrawSegmentation.service';
import { IQuestionModel } from '../question.model';

export class SegmentationAnswerAddedCommand extends AnnotationCommand {

  private annotationTypeBl: IAnnotationTypeBl;
  private segmentationId: string;
  private oldAnswers: IQuestionModel[];
  private newAnswers: IQuestionModel[];

  constructor(
    annotationService: IAnnotationService,
    annotationsStore: IAnnotationsStore,
    freeDrawSegmentationService: IFreeDrawSegmentationService,
    annotationTypeBl: IAnnotationTypeBl,
    segmentationId: string,
    oldAnswers: IQuestionModel[],
    newAnswers: IQuestionModel[],
  ) {
    super(annotationService, annotationsStore, freeDrawSegmentationService);
    this.segmentationId = segmentationId;
    this.oldAnswers = oldAnswers;
    this.newAnswers = newAnswers;
    this.annotationTypeBl = annotationTypeBl;
  }

  executeUndo() {
    const segmentation = this.annotationsStore.segmentations.find(x => x.feature.id === this.segmentationId);
    if (!segmentation) return;
    segmentation.questions = this.oldAnswers.slice();
    this.annotationService.selectSegmentation(this.segmentationId);
    this.annotationsStore.drawFocusFrameForId = this.segmentationId;
    this.freeDrawSegmentationService.clear();
  }

  executeRedo(): void {
    const segmentation = this.annotationsStore.segmentations.find(x => x.feature.id === this.segmentationId);
    if (!segmentation) return;
    segmentation.questions = this.newAnswers.slice();
    this.annotationTypeBl.handleSegmentationAnswerAddedCommandRedo();
    this.annotationService.selectSegmentation(this.segmentationId);
    this.annotationsStore.drawFocusFrameForId = this.segmentationId;
  }
}
