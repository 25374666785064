import { IPreviewImageAttributes } from '../datesets/datasetsDetails.store';
import { injectable } from 'inversify';
import { observable } from 'mobx';

export const AttachmentsPreviewStoreType = Symbol('ATTACHMENTS_PREVIEW_STORE');

export interface IAttachmentPreviewImageInfo {
  id: string;
  name: string;
  width: number;
  height: number;
  size: number;
  createdDate: string;
  parentId: string;
}

export interface IPreviewPaging {
  id: string;
  wasChanged: boolean;
  checkDate: string;
  items: IPreviewPagingItem[];
}

export interface IPreviewPagingItem {
  imageId: string;
  index: number;
  size: number;
}

export interface IAttachmentsPreviewStore {
  imageInfo: IAttachmentPreviewImageInfo;
  previewPaging: IPreviewPaging;
  isPreviewImageLoading: boolean;
  lowQualityImagePreviewUrl: string;
  imagePreviewUrl: string;
  activePreloads: string[];
  imageAttributes: IPreviewImageAttributes;
}

@injectable()
export class AttachmentsPreviewStore implements IAttachmentsPreviewStore {
  @observable
  imageInfo: IAttachmentPreviewImageInfo = {
    createdDate: '',
    name: '',
    id: '',
    height: 0,
    width: 0,
    size: 0,
    parentId: ''
  };

  @observable
  previewPaging: IPreviewPaging = {
    id: '',
    checkDate: '',
    items: [],
    wasChanged: true,
  };

  @observable
  imageAttributes: IPreviewImageAttributes = {
    channels: 0,
    isCached: false,
    downloadSpeed: 0,
  };

  @observable
  isPreviewImageLoading: boolean = false;

  @observable
  lowQualityImagePreviewUrl: string = '';

  @observable
  imagePreviewUrl: string = '';

  @observable
  activePreloads: string[] = [];
}
