import { FieldStatus } from '../FieldStatus';
import React from 'react';
import { S_FieldError } from '../../errors/fieldError/S_FieldError';
import { S_FieldLabel } from '../../labels/fieldLabel/S_FIeldLabel';
import { S_TextInput } from '../../inputs/textInput/S_TextInput';

interface S_TextFieldProps {
  id: string;
  value: string;
  labelText: string;
  fieldStatus: FieldStatus;
  disabled?: boolean;
  placeholder?: string;
  onChange(value: string): void;
}

export const S_TextField: React.FC<S_TextFieldProps> = (props: S_TextFieldProps) => (
  <div>
    <S_FieldLabel text={props.labelText} />
    <S_TextInput id={props.id} value={props.value} valid={props.fieldStatus.isValid} placeholder={props.placeholder} onChange={props.onChange} disabled={props.disabled} />
    {props.fieldStatus.isValid === false && <S_FieldError feedbacks={props.fieldStatus.errors} />}
  </div>
);
