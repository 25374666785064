import { inject, injectable } from 'inversify';
import { InputStatus, StickerError } from '../../../../models/error.model';
import { action } from 'mobx';
import { ApiServiceType } from '../../../../services/api.service';
import { IRouterStore, RouterStoreType } from '../../../../stores/router.store';
import _ from 'lodash';

import { IProjectDetailsActivitiesStore, ProjectDetailsActivitiesStoreType } from './projectDetailsActivities.store';
import UrlAssembler from 'url-assembler';
import { IApiService } from '../../../../services/api.service.base';
import { ExportDataFilter } from '../../../projects/projects.model';
import { IProjectDetailsActivitiesApiService, ProjectDetailsActivitiesApiServiceType } from './services/projectDetailsActivitiesApi.service';
import { IProjectDetailsSubmodule } from '../../services/IProjectDetailsSubmodule';
import { ICanPublishProjectResponse } from '../../projectDetails.models';
import { AuthStoreType, IAuthStore } from '../../../auth/auth.store';
import { IPagingInfoWithOrder } from '../../../../models/paginationInfo.model';

export const ProjectDetailsActivitiesBlType = Symbol('PROJECT_DETAILS_ACTIVITIES_SERVICE');

export interface IProjectDetailsActivitiesBl extends IProjectDetailsSubmodule {
  getProjectActivitiesAsync(projectId: string, activities: string[], actor: string, pagingInfo: IPagingInfoWithOrder): Promise<void>;
  downloadExportAsync(projectId: string, backupId: string, dataFilter?: ExportDataFilter): Promise<void | StickerError>;
  store: IProjectDetailsActivitiesStore;
  getProjectExportUrl(projectId: string, backupId: string): string;
}

@injectable()
export class ProjectDetailsActivitiesBl implements IProjectDetailsActivitiesBl {
  constructor(
    @inject(ProjectDetailsActivitiesStoreType) public readonly store: IProjectDetailsActivitiesStore,
    @inject(ProjectDetailsActivitiesApiServiceType) private readonly projectDetailsActivitiesApiService: IProjectDetailsActivitiesApiService,
    @inject(ApiServiceType) private readonly apiService: IApiService,
    @inject(RouterStoreType) public readonly routeService: IRouterStore,
    @inject(AuthStoreType) public readonly authStore: IAuthStore,
  ) { }

  @action
  initialize(): void {
    this.store.activitylogStatus = InputStatus.empty();
  }

  @action
  cleanup(): void {
    this.store.activitylogStatus = InputStatus.empty();
  }

  @action
  async validateAsync(): Promise<void> {
    this.store.activitylogStatus = InputStatus.valid();
  }

  @action
  handleCanPublishCheck(_: ICanPublishProjectResponse): void {
    this.store.activitylogStatus = InputStatus.valid();
  }

  getValidationErrors(): string[] {
    return this.store.activitylogStatus.errorCodes;
  }

  @action
  async downloadExportAsync(projectId: string, backupId: string, dataFilter: ExportDataFilter): Promise<void | StickerError> {
    const url = UrlAssembler()
      .template('/Export/DownloadExportData')
      .query({
        projectId,
        backupId,
        dataFilter,
      })
      .toString();

    const result = await this.apiService.getFileAsync(url);

    if (result instanceof StickerError) return result;

    return undefined;
  }

  @action
  async getProjectActivitiesAsync(projectId: string, activities: string[], actor: string, pagingInfo: IPagingInfoWithOrder) {
    this.store.showLoader = true;
    const result = await this.projectDetailsActivitiesApiService.getProjectActivitiesAsync(projectId, activities, actor, pagingInfo);

    if (result instanceof StickerError){
      this.store.showLoader = false;
      return;
    }

    if (result.pagesCount < result.pageNumber) {
      await this.getProjectActivitiesAsync(projectId, activities, actor, { ...pagingInfo, pageNumber: result.pagesCount });
    } else {
      this.store.projectActivities = result.data;
      this.store.projectActivitiesPaging = {
        ...this.store.projectActivitiesPaging,
        pageNumber: result.pageNumber,
        pagesCount: result.pagesCount,
        totalCount: result.totalCount,
      };
    }

    this.store.showLoader = false;
  }

  getProjectExportUrl(projectId: string, backupId: string): string {
    const url = `/export/downloadExportData?ProjectId=${projectId}&BackupId=${backupId}&access_token=${this.authStore.token}`;

    return this.apiService.getUrl(url);
  }
}
