import { AnnotationsStoreType, IAnnotationsStore } from '../annotations.store';
import { as, injectProps } from '../../../helpers/react.helpers';

import { IAnnotationProgress } from '../annotations.interface';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { StatsProgress } from '../components/StatsProgress';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  annotationsStore: IAnnotationsStore;
}

type Props = IInjectedProps & ITranslatable;

@injectProps({
  annotationsStore: AnnotationsStoreType,
})
@observer
class AnnotationProgressContainerPure extends React.Component<Props> {
  render() {
    const { you, all, totalImages }: IAnnotationProgress = this.props.annotationsStore.annotationProgress;
    return (
      <StatsProgress
        header={this.props.t('annotation_progress')}
        stats={[
          { label: this.props.t('annotated_by_you'), value: you.toString() },
          { label: this.props.t('annotated_by_all'), value: all.toString() },
          { label: this.props.t('images_in_total'), value: totalImages.toString() },
        ]}
      />
    );
  }
}

export const AnnotationProgressContainer = as<React.ComponentClass>(withNamespaces('common')(AnnotationProgressContainerPure));
