import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { IProjectDetailsImagesBl, ProjectDetailsImagesBlType } from '../projectDetailsImages.bl';
import { IProjectDetailsImagesStore, ProjectDetailsImagesStoreType } from '../projectDetailsImages.store';
import { IProjectDetailsPermissions, ProjectDetailsPermissionsType } from '../../../projectDetails.permissions';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { BatchAnswerQuestions } from '../components/BatchAnswerQuestions';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import React from 'react';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IState {
  showModal: boolean;
}

interface IInjectedProps extends ITranslatable {
  permissions: IProjectDetailsPermissions;
  projectDetailsImagesBl: IProjectDetailsImagesBl;
  currentWorkspaceStore: ICurrentWorkspaceStore;
  imagesStore: IProjectDetailsImagesStore;
}

@injectProps({
  permissions: ProjectDetailsPermissionsType,
  projectDetailsImagesBl: ProjectDetailsImagesBlType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  imagesStore: ProjectDetailsImagesStoreType,
})
@observer
class BatchAnswerQuestionsContainerPure extends React.Component<IInjectedProps, IState> {
  constructor(props: IInjectedProps) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  handleSubmitAsync = async () => await this.props.projectDetailsImagesBl.batchAnswerQuestionsAsync();

  handleModalShow = () => {
    this.setState({ showModal: true });
    this.props.projectDetailsImagesBl.toggleAnswerQuestionsModal();
  };

  handleModalClose = () => {
    this.setState({ showModal: false });
    this.props.projectDetailsImagesBl.toggleAnswerQuestionsModal();
  };

  handleAnswer = (questionId: string, answer: string) => {
    const question = this.props.imagesStore.questions.find(x => x.id === questionId);

    if (question) {
      // TODO: this should not be done this way.
      question.onAnswered(answer);
    }
  };

  render() {
    const {
      toggledImages,
      selectionMode,
      imagesPaging,
      questions,
      batchAnswerBatchesCount,
      batchAnswerFailedBatches,
      batchAnswerSuccessfulBatches,
      batchAnswerQuestionsError: batchAnswerQuestionsModalError,
      batchAnswerQuestionsRequestInProgress,
    } = this.props.imagesStore;

    const projectHasNoQuestions = questions.length === 0;

    const disabled =
      (selectionMode === 'Select' && toggledImages.length === 0) || (selectionMode === 'Deselect' && toggledImages.length === imagesPaging.totalCount) || projectHasNoQuestions;

    const ownerAccountIsChargeable = this.props.currentWorkspaceStore.currentWorkspace?.ownerAccountIsChargeable ?? false;

    if (!this.props.permissions.canBatchAnswer()) return null;

    return (
      <BatchAnswerQuestions
        batchAnswerBatchesCount={batchAnswerBatchesCount}
        batchAnswerFailedBatches={batchAnswerFailedBatches}
        batchAnswerQuestionsModalError={batchAnswerQuestionsModalError}
        batchAnswerQuestionsRequestInProgress={batchAnswerQuestionsRequestInProgress}
        batchAnswerSuccessfulBatches={batchAnswerSuccessfulBatches}
        disabled={disabled}
        isModalOpen={this.state.showModal}
        ownerAccountIsChargeable={ownerAccountIsChargeable}
        projectHasNoQuestions={projectHasNoQuestions}
        questions={questions}
        onAnswered={this.handleAnswer}
        onSubmit={this.handleSubmitAsync}
        onModalClose={this.handleModalClose}
        onModalShow={this.handleModalShow}
      />
    );
  }
}

export const BatchAnswerQuestionsContainer = as<React.ComponentClass>(withNamespaces('common')(BatchAnswerQuestionsContainerPure));
