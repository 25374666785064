import { ClarificationsApiService, ClarificationsApiServiceType, IClarificationsApiService } from './services/ClarificationsApi.service';
import { ClarificationsBl, ClarificationsBlType, IClarificationsBl } from './services/Clarifications.bl';
import { ClarificationsPermissions, ClarificationsPermissionsType, IClarificationsPermissions } from './Clarifications.permissions';
import { ClarificationsStore, ClarificationsStoreSetterType, ClarificationsStoreType, IClarificationsStore, IClarificationsStoreSetter } from './Clarifications.store';
import { ContainerModule, interfaces } from 'inversify';

export const clarificationsModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IClarificationsStore>(ClarificationsStoreType)
    .to(ClarificationsStore)
    .inSingletonScope();
  bind<IClarificationsStoreSetter>(ClarificationsStoreSetterType)
    .toService(ClarificationsStoreType);

  bind<IClarificationsPermissions>(ClarificationsPermissionsType)
    .to(ClarificationsPermissions)
    .inSingletonScope();

  bind<IClarificationsApiService>(ClarificationsApiServiceType)
    .to(ClarificationsApiService)
    .inSingletonScope();
  bind<IClarificationsBl>(ClarificationsBlType)
    .to(ClarificationsBl)
    .inSingletonScope();
});
