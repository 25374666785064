import './LegacyInformationRow.scss';

import { WithNamespaces, withNamespaces } from 'react-i18next';

import React from 'react';
import { S_WarningRow } from '../../../../../../design/warningRow/S_WarningRow';
import { as } from '../../../../../helpers/react.helpers';

interface IProps {
  onClick(): void;
}

export const LegacyInformationRowPure: React.FC<IProps & WithNamespaces> = ({ onClick, t }) => {
  const text = t('projects.details.stats.information_row.old_stats_message');
  return <S_WarningRow className="legacy-stats-information-row alert-warning" text={text} onClick={onClick} />;
};

export const LegacyInformationRow = as<React.ComponentClass<IProps>>(withNamespaces('new')(LegacyInformationRowPure));
