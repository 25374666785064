﻿import * as React from 'react';

import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { IProjectDetailsBl, ProjectDetailsBlType } from '../projectDetails.bl';
import { IProjectDetailsPermissions, ProjectDetailsPermissionsType } from '../projectDetails.permissions';
import { IUserService, UserServiceType } from '../../user/user.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { Settings } from '../../../../modules/settings/SettingsContext';
import { SortingDirection } from '../../../models/sortingDirection.model';
import { UsersList } from '../components/UsersList';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps extends RouteComponentProps<{ projectId: string }>, ITranslatable {
  projectDetailsBl: IProjectDetailsBl;
  userService: IUserService;
  projectDetailsPermissions: IProjectDetailsPermissions;
}

interface IState extends ILoaderState {
  isDeleteModalOpen: boolean;
  userId?: string;
}

@injectProps({
  projectDetailsBl: ProjectDetailsBlType,
  userService: UserServiceType,
  projectDetailsPermissions: ProjectDetailsPermissionsType,
})
@observer
class UsersListContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  static contextType = Settings;
  declare context: React.ContextType<typeof Settings>;

  constructor(props: IInjectedProps) {
    super(props);

    this.state = {
      isDeleteModalOpen: false,
      userId: undefined,
      isLoading: true,
    };
  }

  async componentDidMount() {
    await this.withLoaderAsync(async () => {
      await this.props.projectDetailsBl.getProjectDetailsMembersAsync(this.props.match.params.projectId);
    });
  }

  handleRemoveUserFromProjectConfirmed = async () => {
    await this.withLoaderAsync(async () => {
      if (this.state.userId !== undefined) {
        await this.props.projectDetailsBl.removeUserFromProjectAsync(this.state.userId, this.props.match.params.projectId);
      }

      this.setState({ isDeleteModalOpen: false });
    }, 'delete-user-button');
  };

  handleRemoveUserFromProjectCanceled = () => {
    this.setState({ isDeleteModalOpen: false });
  };

  handleRemoveUserFromProject = (userId: string) => {
    this.setState({
      userId,
      isDeleteModalOpen: true,
    });
  };

  handleSortChange = (orderBy: string, orderType: SortingDirection) => {
    this.props.projectDetailsBl.usersSortChanged(orderBy, orderType);
  };

  handlePaginationChange = (pageNumber: number, pageSize: number) => {
    this.context.setUsersPageSize(pageSize);
    this.props.projectDetailsBl.usersPaginationChange(pageNumber, pageSize);
  };

  render() {
    const canRemoveUserFromProject = this.props.projectDetailsPermissions.canRemoveUserFromProject();

    return (
      <>
        <UsersList
          users={this.props.projectDetailsBl.store.usersList}
          onRemoveUser={this.handleRemoveUserFromProject}
          onSortChange={this.handleSortChange}
          isLoading={this.state.isLoading}
          orderBy={this.props.projectDetailsBl.store.usersOrderBy}
          orderDirection={this.props.projectDetailsBl.store.usersOrderDirection}
          canRemoveUserFromProject={canRemoveUserFromProject}
          pagination={{
            ...this.props.projectDetailsBl.store.usersPagination,
            pageSize: this.context.store.usersPageSize,
            onChange: this.handlePaginationChange,
          }}
        />
        <ConfirmationModal
          showModal={this.state.isDeleteModalOpen}
          confirmationHeader={this.props.t('delete_user_from_project_confirmation_header')}
          confirmationQuestion={this.props.t('delete_user_from_project_confirmation_message')}
          onCancel={this.handleRemoveUserFromProjectCanceled}
          onConfirm={this.handleRemoveUserFromProjectConfirmed}
          showSpinner={this.state.isLoading}
          confirmationYes={this.props.t('remove')}
          loaderKey="delete-user-button"
        />
      </>
    );
  }
}

export const UsersListContainer = as<React.ComponentClass>(withRouter(withNamespaces('common')(UsersListContainerPure)));
