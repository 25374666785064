import { ClarificationsBlType, IClarificationsBl } from '../services/Clarifications.bl';
import { ClarificationsStoreType, IClarificationsStore } from '../Clarifications.store';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { EditorMode } from '../../../../../../modules/editor/models/EditorModes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { NavigationButton } from '../../../components/NavigationButton';
import React from 'react';
import RequestClarificationModalContainer from './RequestClarificationModal.container';
import autobind from 'autobind-decorator';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IOuterProps {
  editorMode: EditorMode;
  disabled?: boolean;
}

interface IState {
  showModal: boolean;
}

interface IInjectedProps {
  clarificationsStore: IClarificationsStore;
  clarificationsBl: IClarificationsBl;
}

@injectProps({
  clarificationsStore: ClarificationsStoreType,
  clarificationsBl: ClarificationsBlType,
})
@observer
class RequestClarificationButtonContainer extends React.Component<IInjectedProps & IOuterProps & ITranslatable, IState> {
  state: IState = {
    showModal: false,
  };

  @autobind
  handleToggle() {
    this.setState(prevState => ({ showModal: !prevState.showModal }));
    this.props.clarificationsBl.setClarificationQuestion('');
  }

  render() {
    return (
      <>
        {this.props.clarificationsStore.canShowRequestClarification && (
          <NavigationButton
            loaderKey="request-clarification-button"
            caption="clarifications.request_clarification_button_caption"
            onClick={this.handleToggle}
            disabled={this.props.disabled ?? false}
            title={'clarifications.request_clarification_button_tooltip'}
          >
            <FontAwesomeIcon icon={faQuestionCircle} />
          </NavigationButton>
        )}
        <RequestClarificationModalContainer showModal={this.state.showModal} onToggle={this.handleToggle} editorMode={this.props.editorMode} />
      </>
    );
  }
}

export default as<React.ComponentClass<IOuterProps>>(withNamespaces('common', { wait: true })(RequestClarificationButtonContainer));
