import { ContainerModule, interfaces } from 'inversify';
import { IProjectOverviewApiService, ProjectOverviewApiService, ProjectOverviewApiServiceType } from './projectOverviewApi.service';
import { IProjectOverviewBl, ProjectOverviewBl, ProjectOverviewBlType } from './projectOverview.bl';
import { IProjectOverviewStore, ProjectOverviewStore, ProjectOverviewStoreType } from './projectOverview.store';

export const projectOverviewModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IProjectOverviewApiService>(ProjectOverviewApiServiceType).to(ProjectOverviewApiService).inSingletonScope();
  bind<IProjectOverviewStore>(ProjectOverviewStoreType).to(ProjectOverviewStore).inSingletonScope();
  bind<IProjectOverviewBl>(ProjectOverviewBlType).to(ProjectOverviewBl).inSingletonScope();
});
