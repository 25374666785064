import * as React from 'react';

interface S_ClampLinesProps {
  text: string;
  id?: string;
  innerElement?: string;
  lines?: number;
  onClick?(e: React.MouseEvent<HTMLDivElement>): void;
}

export const S_ClampLines: React.FC<S_ClampLinesProps> = (props: S_ClampLinesProps) => (
  <div className={`clamp-lines clamp-lines-${props.lines}`} title={props.text} onClick={props.onClick}>
    {React.createElement(props.innerElement || 'div', null, props.text)}
  </div>
);
