import { AnnotationsStoreType, IAnnotationsStore } from '../annotations.store';
import { as, injectProps } from '../../../helpers/react.helpers';

import { IReviewProgress } from '../annotations.interface';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { StatsProgress } from '../components/StatsProgress';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  annotationsStore: IAnnotationsStore;
}

type Props = IInjectedProps & ITranslatable;

@injectProps({
  annotationsStore: AnnotationsStoreType,
})
@observer
class ReviewProgressContainer extends React.Component<Props> {
  render() {
    const { youAccepted, youRejected, allAccepted, allRejected, awaitingReview, totalImages }: IReviewProgress = this.props.annotationsStore.reviewProgress;
    const { t } = this.props;

    const title = `${t('accepted_by_you')}: ${youAccepted} | ${t('rejected_by_you')}: ${youRejected} | ${t('all_accepted')}: ${allAccepted} | ${t('all_rejected')}: ${allRejected}`;

    return (
      <StatsProgress
        header={t('review_progress')}
        title={title}
        stats={[
          { label: t('reviewed_by_you'), value: (youAccepted + youRejected).toString() },
          { label: t('reviewed_by_all'), value: (allAccepted + allRejected).toString() },
          { label: t('awaiting_review'), value: (awaitingReview).toString() },
          { label: t('images_in_total'), value: totalImages.toString() },
        ]}
      />
    );
  }
}

export default as<React.ComponentClass>(withNamespaces('common')(ReviewProgressContainer));
