import './SummaryStepModal.scss';

import { WithNamespaces, withNamespaces } from 'react-i18next';

import { ImportSummaryPaneContainer } from './importSummaryPane/ImportSummaryPane.container';
import React from 'react';
import { S_OneButtonModal } from '../../../../../../../../design/modals/oneButtonModal/S_OneButtonModal';
import { UnregisteredUsersSummaryPaneContainer } from './unregisteredUsersSummaryPane/UnregisteredUsersSummaryPane.container';

interface Props {
  show: boolean;
  onHide(): void;
}

const SummaryStepModalPure: React.FC<Props & WithNamespaces> = (props: Props & WithNamespaces) => {
  const { t, show, onHide } = props;
  return (
    <S_OneButtonModal
      headerText={t('new.projects.details.import_annotations.steps.summary.header')}
      buttonText={t('new.projects.details.import_annotations.steps.summary.close')}
      onClick={onHide}
      onHide={onHide}
      show={show}
    >
      <div className="summary-step-body">
        <div>{t('new.projects.details.import_annotations.steps.summary.summary')}: </div>
        <ImportSummaryPaneContainer />
        <UnregisteredUsersSummaryPaneContainer />
      </div>
    </S_OneButtonModal>
  );
};

export const SummaryStepModal = withNamespaces()(SummaryStepModalPure);
