import {
  CurrentStatisticsView,
  IProjectToolStatisticsDto,
  IStatisticsListItem,
  IToolStatisticsDto,
  ToolStatisticsViewMode,
} from './projectDetailsStatistics.model';
import { IPagingInfoWithOrder, PaginationInfoDefault } from '../../../../models/paginationInfo.model';
import { action, observable } from 'mobx';

import { injectable } from 'inversify';
import { currentTimeZone } from '../../../../models/timeZones/timeZones.model';

export const ProjectDetailsStatisticsStoreType = Symbol('PROJECT_DETAILS_STATISTICS_STORE');

export interface IProjectDetailsStatisticsStore {
  statistics: IStatisticsListItem[];
  statisticsPaging: IPagingInfoWithOrder;
  statisticsLoading: boolean;
  selectedTimeZone: { label: string; value: number };
  offset?: number;
  statisticsGeneratedOn?: Date;

  toolStatistics: IProjectToolStatisticsDto;
  selectedToolStatistics?: IToolStatisticsDto;
  currentStatisticsView: CurrentStatisticsView;
  toolStatisticsViewMode: ToolStatisticsViewMode;
  resetStatisticsOrdering(): void;
}

@injectable()
export class ProjectDetailsStatisticsStore implements IProjectDetailsStatisticsStore {
  @observable
  statistics: IStatisticsListItem[] = [];

  @observable
  statisticsPaging: IPagingInfoWithOrder = {
    ...PaginationInfoDefault,
    orderBy: 'date',
    orderType: 'desc',
  };

  @observable
  statisticsLoading: boolean = false;

  @observable
  selectedTimeZone: { label: string; value: number } = currentTimeZone || { label: 'UTC ± 00:00', value: 0 };

  @observable
  offset?: number = undefined;

  @observable
  statisticsGeneratedOn?: Date = undefined;

  @observable
  toolStatistics: IProjectToolStatisticsDto = {
    projectId: '',
    generatedAt: '',
    headers: { annotationToolsHeaders: [], annotationQuestionHeaders: [], imageSetsHeaders: [], isUpToDate: true },
    annotationTypesStatistics: [],
    questionStatistics: [],
    allAnnotationTypeStatistics: [],
    allQuestionsStatistics: [],
    imageSetsStatistics: [],
    allImageSetsStatistics: [],
  };

  @observable
  selectedToolStatistics?: IToolStatisticsDto = undefined;

  @observable
  toolsSelectedAnnotationType = '';

  @observable
  toolsSelectedQuestion = '';

  @observable
  currentStatisticsView = CurrentStatisticsView.TOOLS;

  @observable
  toolStatisticsViewMode = ToolStatisticsViewMode.NONE;

  @action
  resetStatisticsOrdering() {
    this.statisticsPaging.orderBy = 'date';
    this.statisticsPaging.orderType = 'desc';
  }
}
