import { AnnotationTypeBl, AnnotationTypeBlType, IAnnotationTypeBl } from './annotationType.bl';
import { AnnotationTypeStore, AnnotationTypeStoreSetterType, AnnotationTypeStoreType, IAnnotationTypeStore, IAnnotationTypeStoreSetter } from './annotationType.store';
import { ContainerModule, interfaces } from 'inversify';
import { GetAnnotationTypesService, GetAnnotationTypesServiceType, IGetAnnotationTypesService } from './services/getAnnotationTypesService';

export const annotationTypeModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IGetAnnotationTypesService>(GetAnnotationTypesServiceType)
    .to(GetAnnotationTypesService)
    .inSingletonScope();
  bind<IAnnotationTypeBl>(AnnotationTypeBlType)
    .to(AnnotationTypeBl)
    .inSingletonScope();
  bind<IAnnotationTypeStore>(AnnotationTypeStoreType)
    .to(AnnotationTypeStore)
    .inSingletonScope();
  bind<IAnnotationTypeStoreSetter>(AnnotationTypeStoreSetterType)
    .toService(AnnotationTypeStoreType);
});
