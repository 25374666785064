import * as React from 'react';

import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { ButtonWithLoader } from '../../../../../components/ButtonWithLoader';
import { IClarification } from '../models/Clarifications.models';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { TextAreaWithAutocomplete } from '../../../../../components/TextAreaWithAutocomplete';
import { as } from '../../../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface ClarifyModalProps {
  showModal: boolean;
  clarification: IClarification;
  answer: string;
  onToggleChange(): void;
  onAnswerChange(value: string): void;
  onSaveClarificationAnswer(): void;
}

class ClarifyModalPure extends React.Component<ClarifyModalProps & ITranslatable> {
  render() {
    return (
      <Modal className="modal-width-600 image-clairifications-modal" isOpen={this.props.showModal} toggle={this.props.onToggleChange}>
        <ModalHeader toggle={this.props.onToggleChange}>{this.props.t('clarifications.answer_modal.header')}</ModalHeader>
        <Form className="custom-inline-form">
          <ModalBody>
            <Row>
                <Col>
                  <p><span className="bold-text">{this.props.t('clarifications.common.asked_by')}:</span> {this.props.clarification.askedBy}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p><span className="bold-text">{this.props.t('clarifications.common.question')}:</span> {this.props.clarification.question}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>{this.props.t('clarifications.answer_modal.caption')}:</p>
                  <TextAreaWithAutocomplete
                    maxLength={210}
                    value={this.props.answer}
                    onChange={this.props.onAnswerChange}
                    autocompleteOptions={[]}
                    autofocus
                    counter
                  />
                </Col>
              </Row>
          </ModalBody>
          <ModalFooter>
            <ButtonWithLoader
              loaderKey="answer-clarification-button"
              id="answer-clarification"
              color="success"
              className="mr-2"
              onClick={this.props.onSaveClarificationAnswer}
              disabled={!this.props.answer}
            >
              {this.props.t('clarifications.answer_modal.save')}
            </ButtonWithLoader>
            <Button id="button-cancel" color="primary" className="btn-outline-primary" onClick={this.props.onToggleChange}>
              {this.props.t('cancel')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export const ClarifyModal = as<React.FunctionComponent<ClarifyModalProps>>(
  withNamespaces('common', { wait: true })(ClarifyModalPure),
);
