import autobind from 'autobind-decorator';
import { inject, injectable } from 'inversify';
import { AuthStoreType, IAuthStore } from '../../../../__legacy__/modules/auth/auth.store';
import { NotificationLevel, ToastNotification } from '../../../../__legacy__/modules/notifications/models/notification.model';
import { INotificationsService, NotificationsServiceType } from '../../../../__legacy__/modules/notifications/services/notifications.service';
import { IApiService } from '../../../../__legacy__/services/api.service.base';
import { ApiServiceImageUploadType } from '../../../../__legacy__/services/api.service.imageUpload';
import { EventBusType, EventListeningDisposer, IEventBus } from '../../../../__legacy__/services/eventBus.service';
import { IProjectHubService, ProjectHubServiceType } from '../../../../__legacy__/services/projectHub.service';
import { ProjectExportFinishedEvent, ProjectExportFinishedEventType } from './events/ProjectExportFinishedEvent';
import { ProjectExportStartedEvent, ProjectExportStartedEventType } from './events/ProjectExportStartedEvent';
import { ExportDataStoreSetter, ExportDataStoreSetterType } from './ExportData.store';
import { ExportDataFilter } from './models/ExportDataFilter';
import { IExportProjectService, ExportProjectServiceType } from './services/ExportProject.service';
import { IGetProjectExportsService, GetProjectExportsServiceType } from './services/GetProjectExports.service';
import { IIsCurrentExportGeneratedService, IsCurrentExportGeneratedServiceType } from './services/IsCurrentExportGenerated.service';

export const ExportDataBlType = Symbol('EXPORT_DATA_BL');

export interface IExportDataBl {
  changeOrderAsync(orderBy: string, orderType: string): Promise<void>;
  changePaginationAsync(pageNumber: number, pageSize: number): Promise<void>;
  cleanup(): void;
  exportProjectAsync(): void;
  getProjectExportDownloadUrl(projectExportId: string, dataFilterType: ExportDataFilter): string;
  hideDownloadModal(): void;
  hideExportModal(): void;
  initializeAsync(projectId: string): Promise<void>;
  isCurrentExportGeneratedAsync(): Promise<boolean>;
  selectDataFilter(newDataFilter: ExportDataFilter): void;
  showDownloadModal(projectExportId: string, selectedFilter: ExportDataFilter): void;
}

@autobind
@injectable()
export class ExportDataBl implements IExportDataBl {
  private projectExportStartedDisposer?: EventListeningDisposer;
  private projectExportFinishedDisposer?: EventListeningDisposer;

  constructor(
    @inject(ExportDataStoreSetterType) private readonly store: ExportDataStoreSetter,
    @inject(AuthStoreType) private readonly authStore: IAuthStore,
    @inject(ApiServiceImageUploadType) private readonly apiServiceImageUpload: IApiService,
    @inject(GetProjectExportsServiceType) private readonly getProjectExportsService: IGetProjectExportsService,
    @inject(NotificationsServiceType) private readonly notificationService: INotificationsService,
    @inject(ExportProjectServiceType) private readonly exportProjectService: IExportProjectService,
    @inject(EventBusType) private readonly eventBus: IEventBus,
    @inject(ProjectHubServiceType) private readonly projectHubService: IProjectHubService,
    @inject(IsCurrentExportGeneratedServiceType) private readonly isCurrentExportGeneratedService: IIsCurrentExportGeneratedService,
  ) {}

  selectDataFilter(newDataFilter: ExportDataFilter) {
    this.store.setSelectedDataFilter(newDataFilter);
  }

  async exportProjectAsync() {
    try {
      this.store.setIsCurrentlyGenerating(true);
      await this.exportProjectService.exportProjectAsync({ projectId: this.store.projectId, dataFilterType: this.store.selectedDataFilter });
      this.showExportModal();
    } catch (error) {
      this.notificationService.push(new ToastNotification(NotificationLevel.ERROR, 'generating_export_failed'));
    }

    this.eventBus.sendEvent(new ProjectExportStartedEvent(this.store.projectId));
  }

  async isCurrentExportGeneratedAsync(): Promise<boolean> {
    try {
      return await this.isCurrentExportGeneratedService.isCurrentExportGeneratedAsync({ projectId: this.store.projectId });
    } catch (error) {
      this.notificationService.push(new ToastNotification(NotificationLevel.ERROR, 'export_failed'));
      throw error;
    }
  }

  getProjectExportDownloadUrl(projectExportId: string, dataFilterType: ExportDataFilter): string {
    const url = `/export/downloadProjectExport?ExportId=${projectExportId}&DataFilterType=${dataFilterType}&access_token=${this.authStore.token}`;

    return this.apiServiceImageUpload.getUrl(url);
  }

  private showExportModal(): void {
    this.store.setShowExportModal(true);
  }

  hideExportModal(): void {
    this.store.setShowExportModal(false);
  }

  showDownloadModal(projectExportId: string, selectedFilter: ExportDataFilter): void {
    const downloadLink = this.getProjectExportDownloadUrl(projectExportId, selectedFilter);
    this.store.setDownloadLink(downloadLink);
    this.store.setShowDownloadModal(true);
  }

  hideDownloadModal(): void {
    this.store.setDownloadLink('');
    this.store.setShowDownloadModal(false);
  }

  private async loadProjectExportsAsync(projectId: string): Promise<void> {
    try {
      this.store.setShowLoader(true);
      const response = await this.getProjectExportsService.getProjectExportsAsync({ ...this.store.paging, projectId });
      this.store.load(projectId, response);
    } catch (error) {
      this.notificationService.push(new ToastNotification(NotificationLevel.ERROR, 'getting_project_exports_failed'));
    }
  }

  async initializeAsync(projectId: string): Promise<void> {
    this.projectExportStartedDisposer = this.eventBus.addListener(this.handleProjectExportStartedAsync, ProjectExportStartedEventType);
    this.projectExportFinishedDisposer = this.eventBus.addListener(this.handleProjectExportFinishedAsync, ProjectExportFinishedEventType);

    await this.projectHubService.initializeAsync();
    await this.loadProjectExportsAsync(projectId);
  }

  async handleProjectExportStartedAsync(e: ProjectExportStartedEvent): Promise<void> {
    if (e.projectId === this.store.projectId) await this.loadProjectExportsAsync(this.store.projectId);
  }

  async handleProjectExportFinishedAsync(e: ProjectExportFinishedEvent): Promise<void> {
    if (e.projectId === this.store.projectId) await this.loadProjectExportsAsync(this.store.projectId);
  }

  cleanup(): void {
    this.projectExportStartedDisposer?.();
    this.projectExportFinishedDisposer?.();
  }

  async changePaginationAsync(pageNumber: number, pageSize: number): Promise<void> {
    this.store.paging.pageNumber = pageNumber;
    this.store.paging.pageSize = pageSize;

    await this.loadProjectExportsAsync(this.store.projectId);
  }

  async changeOrderAsync(orderBy: string, orderType: string): Promise<void> {
    this.store.paging.orderBy = orderBy || '';
    this.store.paging.orderType = orderType || '';

    await this.loadProjectExportsAsync(this.store.projectId);
  }
}
