import * as React from 'react';

import { IUserService, UserServiceType } from '../user.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { ITranslatable } from '../../../helpers/translations.helpers';
import { IUserPlanWidgetProps } from '../components/UserPlanWidget';
import { WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IProps extends RouteComponentProps<{ projectId: string }> {
  userService: IUserService;
}

@injectProps({
  userService: UserServiceType,
})
@observer
class UserPlanWidgetContainer extends WithLoaderComponentBase<IProps & ITranslatable> {
  render() {
    return React.Children.map(this.props.children, (child: any) =>
      React.cloneElement(child, {
        planInfo: this.props.userService.data.planInfo,
        credits: {
          accountExists: this.props.userService.billing.accountExists,
          availableCredits: this.props.userService.billing.availableCredits,
          usedCredits: this.props.userService.billing.usedCredits,
          totalCredits: this.props.userService.billing.totalCredits,
          imagesAmount: this.props.userService.billing.imagesAmount,
          usedSpace: this.props.userService.billing.usedSpace,
          workspaces: this.props.userService.billing.workspaces,
          workspaceUsers: this.props.userService.billing.workspaceUsers,
          workspaceWorkers: this.props.userService.billing.workspaceWorkers,
        },
      } as IUserPlanWidgetProps),
    );
  }
}
export default as<React.ComponentClass>(withRouter(withNamespaces('user')(UserPlanWidgetContainer)));
