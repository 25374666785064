import * as React from 'react';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GlobalHotKeys } from 'react-hotkeys';
import { IFreeAccessNavigationProps } from '../containers/freeAccessNavigation.container';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { RouteComponentProps } from 'react-router-dom';
import { as } from '../../../helpers/react.helpers';
import { handleClickAndPassData } from '../../../helpers/formHelpers';
import { withNamespaces } from 'react-i18next';

class FreeAccessImageNavigationPure extends React.Component<IFreeAccessNavigationProps & ITranslatable & RouteComponentProps<{ projectId: string }>> {
  render() {
    return (
      <div className="image-navigation" >
        <GlobalHotKeys
          allowChanges={true}
          keyMap={{
            LEFT: { sequence: 'left', action: 'keyup' },
            RIGHT: { sequence: 'right', action: 'keyup' },
          }}
          handlers={{
            LEFT: () => { if (this.props.current > 1 && !this.props.isLoading) { this.props.goTo(this.props.previousLink); } },
            RIGHT: () => { if (this.props.current < this.props.total && !this.props.isLoading) { this.props.goTo(this.props.nextLink); } },
          }}
        />
        <Button
          onClick={handleClickAndPassData(this.props.goTo)(this.props.previousLink)}
          color="primary"
          className="p-2"
          disabled={this.props.current <= 1 || this.props.isLoading}
          tabIndex={-1}
        >
          <FontAwesomeIcon icon="angle-left" className="mr-1 ml-0" /> <span className="description">{this.props.t('previous')}</span>
        </Button>
        <span>
          {this.props.current} {this.props.t('of')} {this.props.total}
        </span>
        <Button
          onClick={handleClickAndPassData(this.props.goTo)(this.props.nextLink)}
          color="primary"
          className="p-2"
          disabled={this.props.current >= this.props.total || this.props.isLoading}
          tabIndex={-1}
        >
          <span className="description">{this.props.t('next')}</span> <FontAwesomeIcon icon="angle-right" className="mr-0 ml-1" />
        </Button>
      </div>
    );
  }
}

export const FreeAccessImageNavigation = as<React.StatelessComponent<IFreeAccessNavigationProps>>(withNamespaces('common', { wait: true })(FreeAccessImageNavigationPure));
