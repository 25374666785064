import { injectable, inject } from 'inversify';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { t } from 'i18next';
import { INotificationsStore, NotificationsStoreType } from '../stores/notifications.store';
import { autorun, action } from 'mobx';
import {
  HubNotification,
  ModalNotification,
  ToastNotification,
  IBaseNotification,
  ILocalizable,
} from '../models/notification.model';
import { HubServiceType, INotificationHubService } from './notificationHub.service';
import { centerTypeSelector, myswalTypeSelector } from './notification.helpers';

export const NotificationsServiceType = Symbol('NOTIFICATION_SERVICE');

export interface INotificationsService {
  notificationStore: INotificationsStore;
  remove(notification: IBaseNotification): any;
  clearHub(): any;
  markAsRead(): any;
  push(notification: IBaseNotification): void;
  initializeHubAsync(): Promise<void>;
}

@injectable()
export class NotificationsService implements INotificationsService {
  MySwal = withReactContent(Swal);

  constructor(
    @inject(NotificationsStoreType) public readonly notificationStore: INotificationsStore,
    @inject(HubServiceType) private readonly hubService: INotificationHubService,
  ) {
    autorun(() => {
      const nonHubNotifications = this.notificationStore.notifications.filter(myswalTypeSelector);

      if (nonHubNotifications.length === 0) return;
      const notification = nonHubNotifications.shift();
      if (!notification) return;
      this.notificationStore.notifications = this.notificationStore.notifications.filter(x => x.id !== notification.id);
      this.handleNotificationShow(notification);
    });
  }

  async initializeHubAsync(): Promise<void> {
    await this.hubService.initializeAsync();
  }

  @action
  push(notification: IBaseNotification) {
    if (notification instanceof HubNotification) {
      this.hubService.pushNotificationAsync(notification);
    } else {
      this.notificationStore.notifications.push(notification);
    }
  }

  clearHub() {
    const hubNotifications = this.notificationStore.notifications.filter(centerTypeSelector);
    this.hubService.removeNotificationsAsync(hubNotifications.map(n => n.id));
  }

  remove(notification: IBaseNotification) {
    this.hubService.removeNotificationsAsync([notification.id]);
  }

  markAsRead() {
    const readNotifications = this.notificationStore
      .notifications
      .filter(n => !n.wasRead)
      .map(n => n.id);
    if (readNotifications.length > 0) this.hubService.markAsReadNotificationsAsync(readNotifications);
  }

  handleNotificationShow(notification: IBaseNotification) {
    if (notification instanceof ModalNotification) {
      this.MySwal.fire({
        title: this.getLocalizedContent(notification.title),
        html: this.getLocalizedContent(notification.body),
        type: notification.level,
        animation: false,
        showCloseButton: true,
        wasRead: notification.wasRead,
      });
    }
    if (notification instanceof ToastNotification) {
      this.MySwal.fire({
        title: this.getLocalizedContent(notification.title),
        text: this.getLocalizedContent(notification.body),
        type: notification.level,
        toast: true,
        timer: 3000,
        position: 'top-end',
        showConfirmButton: false,
      });
    }
  }

  getLocalizedContent = (content?: string | ILocalizable) => {
    if (!content) return content;

    if (typeof content === 'string') return t(content);

    if (content.template) return t(content.template, content.data || {});

    return content;
  };
}
