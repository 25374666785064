import { StickerRoute } from './BaseRoute';

class ExternalLinksClass {
  public readonly ZillinSitePath = 'https://zillin.io';

  public readonly Regulations = {
    TermsAndConditions: new StickerRoute<{}>(`${this.ZillinSitePath}/terms_and_conditions.html`),
    PrivacyPolicy: new StickerRoute<{}>(`${this.ZillinSitePath}/privacy_policy.html`),
  };

  public readonly ZillinIo = {
    Tutorials: new StickerRoute<{}>(`${this.ZillinSitePath}/tutorials.html`),
    Faq: new StickerRoute<{}>(`${this.ZillinSitePath}/faq.html`),
    Pricing: new StickerRoute<{}>(`${this.ZillinSitePath}/pricing.html`),
  };

  public readonly ZillinPublicApi = {
    Documentation: new StickerRoute<{}>('https://api.zillin.io'),
  };
}

export const ExternalLinks = new ExternalLinksClass();
