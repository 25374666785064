import { inject, injectable } from 'inversify';
import { IWorkspacesStore, WorkspacesStoreType, IWorkspace, WorkspaceRole, IWorkspaceMember, ITakeOverWorkspaceLimits, IWorkspaceListItem } from './workspaces.store';
import { action } from 'mobx';
import { StickerError } from '../../models/error.model';
import { IApiService } from '../../services/api.service.base';
import { CryptoServiceType, ICryptoService } from '../../services/crypto.service';
import { IUserService, UserServiceType } from '../user/user.service';
import autobind from 'autobind-decorator';
import { WorkspaceTimeZoneUpdatedEvent, WorkspaceTimeZoneUpdatedEventType } from './sub/UpdateWorkspaceTimeZone/events/WorkspaceTimeZoneUpdatedEvent';
import { ApiServiceType, IPagedResult } from '../../services/api.service';
import { EventBusType, EventListeningDisposer, IEventBus } from '../../services/eventBus.service';
import { IWorkspacesPermissions, WorkspacesPermissionsType } from './workspaces.permissions';
import { WorkspaceDeletedEvent } from './events/workspaceDeletedEvent';
import { ICurrentWorkspaceStore, CurrentWorkspaceStoreType } from '../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { TimePeriod } from '../../../design/selects/timePeriodSelector/S_TimePeriodSelector';
import { currentTimeZone } from '../../models/timeZones/timeZones.model';
import { UserInfoLoadedEventType } from '../user/events/userInfoLoadedEvent';

export const WorkspaceServiceType = Symbol('WORKSPACES_SERVICE');

export interface IWorkspaceService {
  // permissions
  getUserRoleInCurrentWorkspace(): WorkspaceRole;
  isInRole(roles: WorkspaceRole[]): boolean;

  // users
  changeOwnerAsync(userEmail: string, workspaceId?: string): Promise<void | StickerError>;
  getCurrentWorkspaceMembersAsync(): Promise<void | StickerError>;

  // limits
  getTakeOverWorkspaceLimitsAsync(userEmail: string): Promise<ITakeOverWorkspaceLimits | StickerError>;

  // list
  initWorkspaceListAsync(): Promise<void>;
  disposeWorkspaceListAsync(): Promise<void>;
  getWorkspaceListItemsAsync(): Promise<void | StickerError>;
  createWorkspaceAsync(name: string, encrypted: boolean, timeZoneOffset: number): Promise<string | StickerError>;
  renameWorkspaceAsync(workspaceId: string, name: string): Promise<void | StickerError>;
  deleteWorkspaceAsync(id: string): Promise<void | StickerError>;
  deleteCurrentWorkspaceAsync(): Promise<void | StickerError>;

  // current workspace
  setPreferredWorkspace(workspaceId: string): void;

  store: IWorkspacesStore;
}

const apiUrl = '/Workspace/';

@injectable()
export class WorkspaceService implements IWorkspaceService {
  disposers: EventListeningDisposer[] = [];

  constructor(
    @inject(WorkspacesStoreType) public readonly store: IWorkspacesStore,
    @inject(ApiServiceType) private readonly apiService: IApiService,
    @inject(CryptoServiceType) private readonly cryptoService: ICryptoService,
    @inject(UserServiceType) private readonly userService: IUserService,
    @inject(EventBusType) private readonly eventBus: IEventBus,
    @inject(WorkspacesPermissionsType) private readonly workspacesPermissions: IWorkspacesPermissions,
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore,
  ) {
    eventBus.addListener<WorkspaceTimeZoneUpdatedEvent>(this.workspaceTimeZoneUpdatedListenerAsync, WorkspaceTimeZoneUpdatedEventType);
  }

  @action
  workspaceTimeZoneUpdatedListenerAsync = async (event: WorkspaceTimeZoneUpdatedEvent) => {
    const workspace = this.store.workspaces.find(w => w.id === event.workspaceId);
    if (workspace) {
      workspace.timeZoneOffset = event.timezone;
    }

    await this.getWorkspaceListItemsAsync();
  };

  @autobind
  setPreferredWorkspace(workspaceId: string): void {
    this.store.setPreferredWorkspace(workspaceId);
  }

  isInRole(workspaceRoles: WorkspaceRole[]) {
    const userRoleInWorkspace = this.getUserRoleInCurrentWorkspace();
    return workspaceRoles.includes(userRoleInWorkspace);
  }

  @action.bound
  async initWorkspaceListAsync(): Promise<void> {
    this.disposers = [this.eventBus.addListener(this.userInfoLoadedListener, UserInfoLoadedEventType)];
    await this.getWorkspaceListItemsAsync();
  }

  @action.bound
  async disposeWorkspaceListAsync(): Promise<void> {
    this.disposers.forEach(d => d());
    this.disposers = [];
  }

  @action.bound
  async createWorkspaceAsync(name: string, encrypted = false, timeZoneOffset: number): Promise<string | StickerError> {
    const url = `${apiUrl}Create`;
    const result = await this.apiService.postAsync<{ name: string; encrypted: boolean; timeZoneOffset: number }, string>(url, { name, encrypted, timeZoneOffset });

    if (result instanceof StickerError) {
      return result;
    }

    const workspaceId = result;
    let checksum;
    if (encrypted) {
      checksum = await this.cryptoService.generateKey(workspaceId, workspaceId);
      const result = await this.apiService.postAsync<{ workspaceId: string; checksum: string }, string>(`${apiUrl}EnableEncryption`, { workspaceId, checksum });
      if (result instanceof StickerError) {
        return result;
      }
    }

    this.store.addWorkspace(workspaceId, name.trim(), this.userService.data.userInfo.showCart, this.userService.data.planInfo.plan, timeZoneOffset, '', checksum);
    await this.getWorkspaceListItemsAsync();

    return workspaceId;
  }

  @action.bound
  async renameWorkspaceAsync(workspaceId: string, name: string) {
    const url = `${apiUrl}Rename`;
    const result = await this.apiService.postAsync<{ workspaceId: string; name: string }, string>(url, { name, workspaceId });

    if (result instanceof StickerError) return result;

    const newWorkspaces = this.store.workspaces.slice();
    newWorkspaces.find(w => w.id === workspaceId)!.name = name.trim();
    this.store.workspaces = newWorkspaces;

    const index = this.store.workspaceListItems.findIndex(w => w.id === workspaceId);
    if (index === -1) return;
    const newListItems = this.store.workspaceListItems.slice();
    newListItems[index].name = name.trim();
    this.store.workspaceListItems = newListItems;
  }

  @action.bound
  async deleteWorkspaceAsync(workspaceId: string) {
    await this.performWorkspaceDeletionAsync(workspaceId);
  }

  @action.bound
  async deleteCurrentWorkspaceAsync() {
    const workspace = this.currentWorkspaceStore.currentWorkspace;
    if (!workspace) return;

    await this.performWorkspaceDeletionAsync(workspace.id);
  }

  private async performWorkspaceDeletionAsync(workspaceId: string) {
    const currentTimeZoneOffset = currentTimeZone?.value || 0;
    const deleteResult = await this.apiService.postAsync<{ id: string; timeZoneOffset: number }, string>(`${apiUrl}Delete`, {
      id: workspaceId,
      timeZoneOffset: currentTimeZoneOffset,
    });
    if (deleteResult instanceof StickerError) return;

    const getResult = await this.apiService.getAsync<IWorkspace[]>(`${apiUrl}All`, {});
    if (getResult instanceof StickerError) return;

    if (getResult.length === 0) throw new StickerError('No user workspaces');

    this.store.workspaces = getResult;

    this.eventBus.sendEvent(new WorkspaceDeletedEvent());

    this.store.workspaceListItems = this.store.workspaceListItems.filter(w => w.id !== workspaceId);
    if (this.store.workspaceListItems.length === 0) await this.getWorkspaceListItemsAsync();
  }

  getUserRoleInCurrentWorkspace(): WorkspaceRole {
    const workspace = this.store.workspaces.find(x => x.id === this.currentWorkspaceStore.currentWorkspace!.id);
    return workspace ? workspace.role : WorkspaceRole.None;
  }

  @action.bound
  async changeOwnerAsync(userEmail: string, workspaceId: string = '') {
    const result = await this.apiService.postAsync<{ userEmail: string; workspaceId: string }>(`${apiUrl}changeWorkspaceOwner`, {
      userEmail,
      workspaceId: workspaceId !== '' ? workspaceId : this.currentWorkspaceStore.currentWorkspace!.id,
    });

    if (result instanceof StickerError) return result;

    const newWorkspaces = this.store.workspaces.slice();
    newWorkspaces.find(w => w.id === this.currentWorkspaceStore.currentWorkspace!.id)!.role = WorkspaceRole.Manager;
    this.store.workspaces = newWorkspaces;

    await this.getCurrentWorkspaceMembersAsync();
    await this.getWorkspaceListItemsAsync();
  }

  @action.bound
  async getCurrentWorkspaceMembersAsync() {
    if (!this.workspacesPermissions.canAccessTeam(this.currentWorkspaceStore.currentWorkspace!.id)) return;
    const result = await this.apiService.getAsync<IWorkspaceMember[]>(
      `${apiUrl}getTeamMembers?workspaceId=${this.currentWorkspaceStore.currentWorkspace!.id}&timePeriod=${TimePeriod.All}`,
    );

    if (result instanceof StickerError) {
      return;
    }

    this.store.currentWorkspaceTeam = result;
  }

  @action.bound
  async getTakeOverWorkspaceLimitsAsync(userEmail: string) {
    const result = await this.apiService.getAsync<ITakeOverWorkspaceLimits>(
      `${apiUrl}getTakeOverWorkspaceLimits?workspaceId=${this.currentWorkspaceStore.currentWorkspace!.id}&email=${userEmail}`,
    );

    if (!(result instanceof StickerError)) {
      this.store.takeOverWorkspaceLimits = result;
    }

    return result;
  }

  @action.bound
  async getWorkspaceListItemsAsync(): Promise<void | StickerError> {
    this.store.showLoader = true;
    const result = await this.apiService.getAsync<IPagedResult<IWorkspaceListItem>>(`${apiUrl}getList`, {
      params: {
        workspaceName: this.store.workspaceNameSearch,
        owner: this.store.workspaceOwnerSearch,
        pageSize: this.store.workspacesPaging.pageSize,
        pageNumber: this.store.workspacesPaging.pageNumber,
        orderBy: this.store.workspacesPaging.orderBy,
        orderType: this.store.workspacesPaging.orderType,
      },
    });

    if (result instanceof StickerError) {
      this.store.showLoader = false;
      return;
    }

    if (result.totalCount > 0 && result.pagesCount < result.pageNumber) {
      this.store.workspacesPaging.pageNumber = result.pagesCount;
      await this.getWorkspaceListItemsAsync();
    } else {
      this.store.workspacesPaging = {
        ...this.store.workspacesPaging,
        pageNumber: result.pageNumber,
        pagesCount: result.pagesCount,
        totalCount: result.totalCount,
      };
      this.store.workspaceListItems = result.data;
    }

    this.store.showLoader = false;
  }

  private userInfoLoadedListener = () => this.getWorkspaceListItemsAsync();
}
