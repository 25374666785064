import * as React from 'react';

import { Button, Card, CardBody, Form } from 'reactstrap';

import { ITranslatable } from '../../../helpers/translations.helpers';
import { InputStatus } from '../../../models/error.model';
import { PasswordWithScoreInputsContainer } from '../containers/PasswordWithScoreInputsContainer';
import { as } from '../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { formAlertWithTranslations } from '../../../components/FormAlert';
import { withNamespaces } from 'react-i18next';

export interface IResetPasswordFormProps {
  onPasswordChange(value: string): void;
  onPasswordStatusChange(status: InputStatus): void;
  onResetPassword(): void;
  passwordStatus: InputStatus;
  externalStatus: InputStatus;
  isDisabledSubmit: boolean;
  passwordControlRef: React.RefObject<PasswordWithScoreInputsContainer>;
}

class ResetPasswordFormPure extends React.Component<IResetPasswordFormProps & ITranslatable> {
  @autobind
  onSubmit(event: React.FormEvent) {
    event.preventDefault();
    this.props.onResetPassword();
  }

  render() {
    const validationErrorOccurred =
      this.props.externalStatus.isValid === false || this.props.passwordStatus.isValid === false;

    const Errors = formAlertWithTranslations('auth');

    return (
      <Card>
        <CardBody>
          <Errors errors={this.props.externalStatus} useDefaultMessageWhenCodeNotFound={true} />
          <Form onSubmit={this.onSubmit}>
            <PasswordWithScoreInputsContainer
              labelText={`${this.props.t('password')} *`}
              ref={this.props.passwordControlRef}
              onPasswordChange={this.props.onPasswordChange}
              onPasswordStatusChange={this.props.onPasswordStatusChange}
            />
            <Button
              block
              color="primary"
              type="submit"
              disabled={validationErrorOccurred || this.props.isDisabledSubmit}
            >
              {this.props.t('reset_password')}
            </Button>
          </Form>
        </CardBody>
      </Card>
    );
  }
}
export const ResetPasswordForm = as<React.StatelessComponent<IResetPasswordFormProps>>(withNamespaces('auth')(ResetPasswordFormPure));
