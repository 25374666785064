import { Card, CardBody } from 'reactstrap';
import { ConfirmationStatus, ResendStatus } from '../../containers/ConfirmEmailContainer';

import { ClipLoader } from 'react-spinners';
import { ITranslatable } from '../../../../helpers/translations.helpers';
import React from 'react';
import ResendEmail from './ResendEmail';
import { as } from '../../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  resendStatus: ResendStatus;
  confirmationStatus: ConfirmationStatus;
  onResendActivationEmailClick(): void;
  onActivateAccount(): void;
  onGoToLoginClick(): void;
}

type Props = ITranslatable & IProps;

class ConfirmEmail extends React.Component<Props> {
  componentDidMount() {
    this.props.onActivateAccount();
  }

  render() {
    const { t } = this.props;

    return (
      <div className="account-activation">
        <Card>
          <CardBody>
            <p className="header">
              {this.props.confirmationStatus === 'InProgress' && t('activating_account')}
              {this.props.confirmationStatus === 'Success' && t('activation_success')}
              {this.props.confirmationStatus === 'Failed' && t('activation_failed')}
            </p>
            <hr />
            <div className="content text-center">
              {this.props.confirmationStatus === 'InProgress' && <ClipLoader color="#4C516D" size={48} />}
              {this.props.confirmationStatus === 'Success' &&
                (
                  <div>
                    {t('activation_success_description')}
                    <a href="#" onClick={this.props.onGoToLoginClick}>{t('here')}</a>.
                  </div>
                )}
              {this.props.confirmationStatus === 'Failed' && t('activation_failed_description')}
            </div>
            <br />
            {this.props.confirmationStatus !== 'Success' &&
              <ResendEmail
                onResendActivationEmailClick={this.props.onResendActivationEmailClick}
                resendStatus={this.props.resendStatus}
              />
            }
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default as<React.SFC>(withNamespaces('auth')(ConfirmEmail));
