import 'leaflet-draw';
import 'leaflet-editable';
import 'leaflet.path.drag';

import * as React from 'react';

import { AttributesPopupDrawer } from './DrawControl.models/AttributesPopupDrawer';
import { ISegmentation } from '../annotations.interface';
import { Map } from 'leaflet';
import { as } from '../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { withLeaflet } from 'react-leaflet';

interface IOuterProps {
  areAttributesShown: boolean;
  selectedSegmentation?: ISegmentation;
  hoveredSegmentation?: string;
  segmentations: ISegmentation[];
}

interface IProps extends IOuterProps {
  leaflet: { map: Map };
}

class AttributePopupControlPure extends React.Component<IProps> {
  private map!: Map;
  private attributesPopupDrawer!: AttributesPopupDrawer;

  componentDidMount() {
    if (super.componentDidMount) {
      super.componentDidMount();
    }
    this.map = this.props.leaflet.map;
    this.setAttributesPopupDrawer(this.props);
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.hoveredSegmentation !== this.props.hoveredSegmentation) {
      if (this.props.hoveredSegmentation === undefined) this.attributesPopupDrawer.hout(prevProps.hoveredSegmentation!);
      else this.attributesPopupDrawer.hover(this.props.hoveredSegmentation);
    } else {
      this.attributesPopupDrawer.refresh(this.props.selectedSegmentation, this.props.areAttributesShown, this.props.segmentations);
    }
  }

  componentWillUnmount() {
    this.attributesPopupDrawer.clear();
  }

  @autobind
  setAttributesPopupDrawer(props: IProps) {
    this.attributesPopupDrawer = new AttributesPopupDrawer(this.map);
    this.attributesPopupDrawer.refresh(props.selectedSegmentation, props.areAttributesShown, this.props.segmentations);
  }

  render() {
    return <>{this.props.children}</>;
  }
}

export const AttributePopupControl = as<React.ComponentClass<IOuterProps>>(withLeaflet(AttributePopupControlPure));
