import * as React from 'react';

import { Card, CardBody } from 'reactstrap';

import { ITranslatable } from '../../../helpers/translations.helpers';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

const ResolutionWarningCardComponent = withNamespaces('auth')((props: ITranslatable) => (
  <div className="auth-desktop-warning-card">
    <Card>
      <CardBody>
        <p>
          {props.t('zillin_desktop_use_1')}
          <b>{props.t('zillin_desktop_use_2')}</b>
          {props.t('zillin_desktop_use_3')}
        </p>
      </CardBody>
    </Card>
  </div>
));

export const ResolutionWarningCard = as<React.SFC>(ResolutionWarningCardComponent);
