import * as React from 'react';

import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { InputStatus } from '../../../models/error.model';
import { RestrictAccessTo } from '../../../containers/RestrictAccessTo';
import { ValidatedTextInput } from '../../../components/ValidatedTextInput';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IProjectHeadProps {
  name: string;
  description: string;
  onNameChange(value: string): void;
  onDescriptionChange(value: string): void;
  onNameBlur(): void;
  onDescriptionBlur(): void;
  nameMaxLength: number;
  nameStatus: InputStatus;
  descriptionMaxLength: number;
  descriptionStatus: InputStatus;
}

class ProjectHeaderFormPure extends React.Component<IProjectHeadProps & ITranslatable> {
  render() {
    const { t } = this.props;

    return (
      <>
        <RestrictAccessTo projectRoles={[]} workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Trainer]} inProject={''}>
          <div className="image-input-body">
            <DisabledWhenWorkspaceOwnerPolicyExceeded>
              <ValidatedTextInput
                id="project-name-textbox"
                type={'text'}
                placeholder={t('type_your_project_name_here')}
                maxLength={this.props.nameMaxLength}
                value={this.props.name}
                onChange={this.props.onNameChange}
                onBlur={this.props.onNameBlur}
                isValid={this.props.nameStatus.isValid}
                feedbacks={this.props.nameStatus.errorCodes.map(e => t(e))}
              />
            </DisabledWhenWorkspaceOwnerPolicyExceeded>
          </div>
          <DisabledWhenWorkspaceOwnerPolicyExceeded>
            <ValidatedTextInput
              id="project-description-textbox"
              type={'textarea'}
              placeholder={t('type_your_project_description_here')}
              maxLength={this.props.descriptionMaxLength}
              value={this.props.description}
              onChange={this.props.onDescriptionChange}
              onBlur={this.props.onDescriptionBlur}
              isValid={this.props.descriptionStatus.isValid}
              feedbacks={this.props.descriptionStatus.errorCodes.map(e => t(e))}
            />
          </DisabledWhenWorkspaceOwnerPolicyExceeded>
        </RestrictAccessTo>
        <RestrictAccessTo projectRoles={[]} workspaceRoles={[WorkspaceRole.Collaborator, WorkspaceRole.Guest]} inProject={''}>
          <h1>{this.props.name}</h1>
          <p>{this.props.description}</p>
        </RestrictAccessTo>
      </>
    );
  }
}

export const ProjectHeaderForm = as<React.StatelessComponent<IProjectHeadProps>>(withNamespaces('project')(ProjectHeaderFormPure));
