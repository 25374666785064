import * as React from 'react';

import { Col, Container, Row } from 'reactstrap';

import AttachmentUploadDropdownContainer from '../modules/attachments/containers/AttachmentUploadDropdown.container';
import { CreateWorkspaceContainer } from '../modules/workspaces/containers/CreateWorkspace.container';
import { CurrentWorkspaceContainer } from '../modules/workspaces/containers/CurrentWorkspace.container';
import { CurrentWorkspaceRole } from '../modules/workspaces/components/CurrentWorkspaceRole';
import { CurrentWorkspaceSelectorContainer } from '../../modules/workspaces/currentWorkspace/ui/selector/CurrentWorkspaceSelector.container';
import { DatasetsUploadDropdown } from './DatasetsUploadDropdown';
import { DatasetsUploadDropdownContainer } from '../containers/DatasetsUploadDropdown.container';
import { GoToWorkspacesButtonContainer } from '../modules/workspaces/containers/GoToWorkspacesButtonContainer';
import { LimitContainer } from '../modules/billing/containers/Limit.container';
import { MoreOptionsWorkspaceContainer } from '../modules/workspaces/containers/MoreOptionsWorkspace.container';
import { NotificationsHub } from '../modules/notifications/components/NotificationsHub';
import { NotificationsHubContainer } from '../modules/notifications/containers/NotificationsHub.container';
import { PolicyLimitsExceededWarningContainer } from '../modules/billing/containers/PolicyLimitsExceededWarning.container';
import { UnlockWorkspaceModal } from '../modules/workspaces/components/UnlockWorkspaceModal';
import { UserDropdownContainer } from '../containers/UserDropdown.container';
import { alertBarHeight } from '../../modules/alertBar/ui/AlertBar';

interface IProps {
  alertsCount: number;
}

export const TopNav: React.FC<IProps> = ({ alertsCount }: IProps) => (
  <div className="top-nav" style={{ top: `${alertBarHeight(alertsCount)}px` }}>
    <Container fluid>
      <Row className="align-items-center">
        <Col sm="12" className="right-side">
          <CurrentWorkspaceContainer>
            {(unlockWorkspaceModal, currentWorkspaceRoleProps) => (
              <>
                <CurrentWorkspaceSelectorContainer />
                <UnlockWorkspaceModal {...unlockWorkspaceModal} />
                <MoreOptionsWorkspaceContainer />
                <CreateWorkspaceContainer />
                <GoToWorkspacesButtonContainer />
                <CurrentWorkspaceRole {...currentWorkspaceRoleProps} />
              </>
            )}
          </CurrentWorkspaceContainer>
          <PolicyLimitsExceededWarningContainer />
          <LimitContainer />
          <AttachmentUploadDropdownContainer />
          <DatasetsUploadDropdownContainer>
            <DatasetsUploadDropdown />
          </DatasetsUploadDropdownContainer>
          <NotificationsHubContainer>
            <NotificationsHub />
          </NotificationsHubContainer>
          <UserDropdownContainer usernameVisibility="username-show" />
        </Col>
      </Row>
    </Container>
  </div>
);
