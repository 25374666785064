import { IProjectDetailsImagesStore, ProjectDetailsImagesStoreType } from '../projectDetailsImages.store';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import React from 'react';
import { SelectedImagesCounter } from '../components/SelectedImagesCounter';
import { observer } from 'mobx-react';

interface IInjectedProps {
  imagesStore: IProjectDetailsImagesStore;
}

@injectProps({
  imagesStore: ProjectDetailsImagesStoreType,
})
@observer
class SelectedImagesCounterContainerPure extends React.Component<IInjectedProps> {
  render() {
    const { selectionMode, toggledImages, imagesPaging } = this.props.imagesStore;
    const count = selectionMode === 'Select'
      ? toggledImages.length
      : imagesPaging.unlockedImagesCount - toggledImages.length;

    return <SelectedImagesCounter count={count} />;
  }
}

export const SelectedImagesCounterContainer = as<React.ComponentClass>(SelectedImagesCounterContainerPure);
