import { AlertBarStoreType, IAlertBarStore } from '../../../modules/alertBar/AlertBar.store';
import { Button, Col, Container, Row } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../helpers/react.helpers';
import React from 'react';
import { observer } from 'mobx-react';
import { PredictionImagesPreviewService, PredictionImagesPreviewServiceType } from './predictionImagesPreview.service';
import { HiddenAnnotationsToastContainer } from '../../containers/HiddenAnnotationsToast.container';
import ImageContainer from '../../modules/annotation/containers/Image.container';
import Image from '../../modules/annotation/components/Image';
import { SegmentationsContainer } from '../../modules/annotation/containers/Segmentations.container';
import { DrawingContainer } from '../../modules/annotation/containers/Drawing.container';
import { AnnotationTopBarItem } from '../../modules/annotation/components/AnnotationTopBarItem';
import { ImageSegmentationsContextProvider } from '../../modules/annotation/submodules/segmentations/segmentationContextMenu/imageSegmentations.context';
import { AnnotationViewOptions } from '../../modules/annotation/components/AnnotationViewOptions';
import { alertBarHeight } from '../../../modules/alertBar/ui/AlertBar';
import { Link } from '../../containers/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Home } from '../../routes/config/Home';
import { ITranslatable } from '../../helpers/translations.helpers';
import { withNamespaces } from 'react-i18next';
import { GlobalHotKeys } from 'react-hotkeys';
import AnnotationImageNameHeaderContainer from '../../modules/annotation/containers/AnnotationImageNameHeader.container';
import { AnnotationTypesContainer } from '../../modules/annotation/submodules/annotationTypes/containers/AnnotationTypes.container';
import QuestionsContainer from '../../modules/annotation/containers/Questions.container';
import QuestionsList from '../../modules/annotation/components/annotations/QuestionsList';
import SegmentationsSaveContainer from '../../modules/annotation/containers/SegmentationsSave.container';
import { LeftMenuContainer } from '../../modules/annotation/submodules/segmentations/containers/LeftMenu.container';
import { WithLoaderComponentBase } from '../../helpers/loader.helpers';
import { Loader } from '../Loader';
import { PredictionImagesPreviewStore } from './predictionImagesPreview.store';
import { PredictionImagesPredictions } from './PredictionImagesPredictions';
import { PredictionImagesSettings } from './PredictionImagesSettings';

library.add(faArrowLeft);

interface IProps {
  jobType: NonNullable<PredictionImagesPreviewStore['jobType']>;
}

interface IInjectedProps extends RouteComponentProps<{ workspaceId: string; jobId: string; imageId: string }> {
  predictionImagesService: PredictionImagesPreviewService;
  alertBarStore: IAlertBarStore;
}

interface IState {
  isLoading: boolean,
  threshold: number,
  showHeatmap: boolean
}

@injectProps({
  predictionImagesService: PredictionImagesPreviewServiceType,
  alertBarStore: AlertBarStoreType,
})
@observer
class PredictionImagesPreviewContainerPure extends WithLoaderComponentBase<IProps & IInjectedProps & ITranslatable, IState> {
  constructor(props: IProps & IInjectedProps & ITranslatable) {
    super(props);

    this.state = {
      isLoading: false,
      threshold: 80,
      showHeatmap: false
    };
  }

  async componentDidMount() {
    this.withLoaderAsync<boolean>(async () => {
      const { workspaceId, jobId, imageId } = this.props.match.params;

      await this.props.predictionImagesService.initializeAsync(workspaceId, jobId, imageId, this.props.jobType);
    });
  }

  componentDidUpdate(): void {
    const { workspaceId, imageId } = this.props.match.params;

    this.props.predictionImagesService.updateImageId(workspaceId, imageId);
  }

  componentWillUnmount(): void {
    this.props.predictionImagesService.dispose();
  }

  navigate(forward: boolean) {
    const { predictionImagesPreviewStore: imagesStore } = this.props.predictionImagesService;
    const { workspaceId, jobId } = this.props.match.params;

    const parameters = {
      workspaceId,
      jobId,
      imageId: forward ? imagesStore.nextImage : imagesStore.previousImage,
    };

    const jobType = this.props.jobType;

    switch (jobType) {
      case 'EVALUATION':
        return this.props.history.push(Home.Evaluations.Details.ImageDetails.withParams(parameters));
      case 'TRAINING':
        return this.props.history.push(Home.Models.Details.ImageDetails.withParams(parameters));
    }
  }

  backLink(): string {
    const { workspaceId, jobId } = this.props.match.params;
    const jobType = this.props.jobType;
    const parameters = {
      workspaceId,
      jobId,
    };
    switch (jobType) {
      case 'EVALUATION':
        return Home.Evaluations.Details.Images.withParams(parameters);
      case 'TRAINING':
        return Home.Models.Details.Images.withParams(parameters);
    }
  }

  handleThresholdChange = (value: number) => {
    this.setState((state) => ({...state, threshold: value}));
  };

  handleToggleShowHeatmap = () => this.setState(state => ({...state, showHeatmap: !this.state.showHeatmap}))

  render() {
    const { imageId } = this.props.match.params;
    const alertBarH = alertBarHeight(this.props.alertBarStore.alerts.length);
    const { predictionImagesPreviewStore: imagesStore } = this.props.predictionImagesService;

    return (
      <Loader isLoading={this.state.isLoading} solid class=".fullscreen-loader">
        <div className="annotation-view free-access">
          <ImageSegmentationsContextProvider imageId={imageId}>
            <div>
              <div className="annotation-view-top-bar" style={{ marginTop: alertBarH, visibility: this.state.isLoading ? 'hidden' : 'visible' }}>
                <Container fluid>
                  <AnnotationTopBarItem variation="labeled-icon">
                    <Link to={this.backLink()} className="btn btn-link">
                      <FontAwesomeIcon icon={faArrowLeft} /> {this.props.t('back')}
                    </Link>
                  </AnnotationTopBarItem>

                  <AnnotationTopBarItem variation="labeled-icon">
                    <AnnotationViewOptions disableCursorConfig={true} />
                  </AnnotationTopBarItem>
                  <AnnotationTopBarItem variation="labeled-icon">
                    <Button onClick={this.handleToggleShowHeatmap} className="btn primary" tabIndex={-1}>
                      <FontAwesomeIcon icon={this.state.showHeatmap ? faEye : faEyeSlash} /> {this.props.t('heatmap')}
                    </Button>
                  </AnnotationTopBarItem>
                  <AnnotationTopBarItem variation="image-name">
                    <AnnotationImageNameHeaderContainer />
                  </AnnotationTopBarItem>
                  <AnnotationTopBarItem variation="image-navigation-item">
                    <div className="image-navigation">
                      <GlobalHotKeys
                        allowChanges={true}
                        keyMap={{
                          LEFT: { sequence: 'left', action: 'keyup' },
                          RIGHT: { sequence: 'right', action: 'keyup' },
                        }}
                        handlers={{
                          LEFT: () => {
                            if (imagesStore.indexOf > 0) {
                              this.navigate(false);
                            }
                          },
                          RIGHT: () => {
                            if (imagesStore.indexOf + 1 < imagesStore.total) {
                              this.navigate(true);
                            }
                          },
                        }}
                      />
                      <Button onClick={() => this.navigate(false)} color="primary" className="p-2" disabled={imagesStore.indexOf < 1} tabIndex={-1}>
                        <FontAwesomeIcon icon="angle-left" className="mr-1 ml-0" /> <span className="description">{this.props.t('previous')}</span>
                      </Button>
                      <span>
                        {imagesStore.indexOf + 1} {this.props.t('of')} {imagesStore.total}
                      </span>
                      <Button onClick={() => this.navigate(true)} color="primary" className="p-2" disabled={imagesStore.indexOf + 1 >= imagesStore.total} tabIndex={-1}>
                        <span className="description">{this.props.t('next')}</span> <FontAwesomeIcon icon="angle-right" className="mr-0 ml-1" />
                      </Button>
                    </div>
                  </AnnotationTopBarItem>
                </Container>
              </div>
              <div className="annotation-view-inner" style={{ marginTop: alertBarH + 70, height: `calc(100vh - ${70 + alertBarH}px)` }}>
                <Container fluid>
                  <Row>
                    <LeftMenuContainer currentProjectId={this.props.predictionImagesService.annotationsStore.projectId} isReadOnly={true} showObjectsList={true} isReview={false} />
                    <Col className="px-0">
                      <HiddenAnnotationsToastContainer />
                      <ImageContainer showHeatmap={this.state.showHeatmap} heatmapUrl={this.props.predictionImagesService.predictionImagesPreviewStore.evaluationImageDetails?.heatmap_url}>
                        <Image>
                          <SegmentationsContainer readonly={true} />
                          <DrawingContainer disableCursorGuides={false} />
                        </Image>
                      </ImageContainer>
                    </Col>
                    <div className="right-column">
                      <div className="scrollable-container">
                        <div className="scrollable-container-content">
                          <PredictionImagesSettings handleThresholdChange={this.handleThresholdChange} threshold={this.state.threshold}/>
                          {!!this.props.predictionImagesService.predictionImagesPreviewStore.evaluationImageDetails && (
                            <PredictionImagesPredictions readonly threshold={this.state.threshold} data={this.props.predictionImagesService.predictionImagesPreviewStore.evaluationImageDetails} />
                          )}
                          <AnnotationTypesContainer readonly />
                          <QuestionsContainer readonly>
                            <QuestionsList />
                          </QuestionsContainer>
                          <SegmentationsSaveContainer />
                        </div>
                      </div>
                    </div>
                  </Row>
                </Container>
              </div>
            </div>
          </ImageSegmentationsContextProvider>
        </div>
      </Loader>
    );
  }
}

export const PredictionImagesPreviewContainer = as<React.ComponentClass<IProps>>(withNamespaces('annotation')(withRouter(PredictionImagesPreviewContainerPure)));
