import { StickerEvent } from '../../../../../services/eventBus.service';

export const ClarificationAddedEventType = Symbol('CLARIFICATION_ADDED_EVENT');

export class ClarificationAddedEvent extends StickerEvent {

  get type(): Symbol {
    return ClarificationAddedEventType;
  }
}
