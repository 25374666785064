import { AnnotationServiceType, IAnnotationService } from '../annotation.service';
import { AnnotationUiStoreType, IAnnotationUiStore } from '../annotationUi.store';
import { AnnotationsStoreType, IAnnotationsStore } from '../annotations.store';
import { FreeDrawSegmentationServiceType, IFreeDrawSegmentationService } from '../freeDrawSegmentation.service';
import { as, injectProps } from '../../../helpers/react.helpers';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  annotationService: IAnnotationService;
  freeDrawSegmentationService: IFreeDrawSegmentationService;
  uiStore: IAnnotationUiStore;
  annotationsStore: IAnnotationsStore;
}

export interface IReturnToToolsContainerProps {
  readonly?: boolean;
}

@injectProps({
  annotationService: AnnotationServiceType,
  freeDrawSegmentationService: FreeDrawSegmentationServiceType,
  uiStore: AnnotationUiStoreType,
  annotationsStore: AnnotationsStoreType,
})
@observer
class ReturnToToolsContainer extends React.Component<ITranslatable & IInjectedProps & IReturnToToolsContainerProps> {

  @autobind
  async handleOnClick() {
    if (await this.props.freeDrawSegmentationService.clearAsync()) {
      this.props.uiStore.isInValidation = false;
      this.props.annotationService.deselectSegmentation();
    }
  }

  render() {
    if ((this.props.readonly && !this.props.annotationsStore.isSelectedSegWithQuestions) ||
      (!this.props.readonly && !this.props.freeDrawSegmentationService.freeDrawInProgress) && (
        !this.props.annotationsStore.isSelectedSegWithQuestions &&
        !this.props.annotationsStore.isSelectedSegOfFreeDrawType)
    ) return null;
    return (
      <div className="questions-return">
        <Button onClick={this.handleOnClick} tabIndex={-1}>
          <FontAwesomeIcon icon="angle-left" />
          {this.props.t('return_to_all_tools')}
        </Button>
      </div>
    );
  }
}

export default as<React.ComponentClass<IReturnToToolsContainerProps>>(withNamespaces('common')(ReturnToToolsContainer));
