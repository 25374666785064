import { inject, injectable } from 'inversify';
import { ToastNotification, NotificationLevel } from '../../../notifications/models/notification.model';
import { NotificationsServiceType, INotificationsService } from '../../../notifications/services/notifications.service';
import { EventBusType, IEventBus } from '../../../../services/eventBus.service';
import { UpdateWorkspaceTimeZoneStoreSetterType, IUpdateWorkspaceTimeZoneStoreSetter } from './UpdateWorkspaceTimeZone.store';
import { WorkspaceTimeZoneUpdatedEvent } from './events/WorkspaceTimeZoneUpdatedEvent';
import { UpdateWorkspaceTimeZoneRequest, IUpdateWorkspaceTimeZoneService, UpdateWorkspaceTimeZoneServiceType } from './services/UpdateWorkspaceTimeZone.service';

export const UpdateWorkspaceTimeZoneBlType = Symbol('UPDATE_WORKSPACE_TIMEZONE_BL_TYPE');

export interface IUpdateWorkspaceTimeZoneBl {
  hideForm(): void;
  selectTimeZone(timeZone: number): void;
  showForm(workspaceId: string, timeZoneOffset: number): void;
  submitFormAsync(): Promise<void>;
}

@injectable()
export class UpdateWorkspaceTimeZoneBl implements IUpdateWorkspaceTimeZoneBl {
  private originalTimeZoneOffset: number | undefined;

  constructor(
    @inject(UpdateWorkspaceTimeZoneStoreSetterType) private readonly store: IUpdateWorkspaceTimeZoneStoreSetter,
    @inject(UpdateWorkspaceTimeZoneServiceType) private readonly updateTimeZoneService: IUpdateWorkspaceTimeZoneService,
    @inject(NotificationsServiceType) private readonly notifications: INotificationsService,
    @inject(EventBusType) private readonly eventBus: IEventBus,
  ) {}

  public showForm(workspaceId: string, timeZoneOffset: number): void {
    this.originalTimeZoneOffset = timeZoneOffset;
    this.store.showForm(workspaceId, timeZoneOffset);
  }

  public hideForm(): void {
    this.store.hideForm();
  }

  public selectTimeZone(timeZone: number): void {
    this.store.setSelectedTimeZoneOffset(timeZone);
  }

  public async submitFormAsync(): Promise<void> {
    if (this.store.selectedTimeZoneOffset === this.originalTimeZoneOffset) {
      this.hideForm();
      return;
    }

    try {
      const request = new UpdateWorkspaceTimeZoneRequest(this.store.workspaceId, this.store.selectedTimeZoneOffset);
      await this.updateTimeZoneService.updateWorkspaceTimeZone(request);
      this.eventBus.sendEvent(new WorkspaceTimeZoneUpdatedEvent(this.store.workspaceId, this.store.selectedTimeZoneOffset));
      this.hideForm();
    } catch (e) {
      this.notifications.push(new ToastNotification(NotificationLevel.ERROR, 'workspace:update_timezone_failed'));
    }
  }
}
