import { IProjectStatsBl, ProjectStatsBlType } from '../../ProjectStats.bl';
import { IProjectStatsFiltersStore, ProjectStatsFiltersStoreType } from '../../filters/ProjectStatsFilters.store';
import { IProjectStatsStore, ProjectStatsStoreType } from '../../ProjectStats.store';
import { as, injectProps } from '../../../../../../__legacy__/helpers/react.helpers';

import { GeneralStatsTable } from './GeneralStatsTable';
import React from 'react';
import { SortingDirection } from '../../../../../../__legacy__/models/sortingDirection.model';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: IProjectStatsBl;
  filtersStore: IProjectStatsFiltersStore;
  store: IProjectStatsStore;
}

@injectProps({
  bl: ProjectStatsBlType,
  filtersStore: ProjectStatsFiltersStoreType,
  store: ProjectStatsStoreType,
})
@observer
class GeneralStatsTableContainerPure extends React.Component<InjectedProps> {
  get items() {
    const { visibleWorkers } = this.props.filtersStore;
    const { generalStats } = this.props.store;

    return generalStats.filter(s => visibleWorkers.map(w => w.id).includes(s.id));
  }

  @autobind
  handleOnSortChange(orderBy: string, orderDirection: SortingDirection) {
    this.props.bl.changeGeneralStatsSorting(orderBy, orderDirection);
  }

  render(): React.ReactNode {
    return (
      <GeneralStatsTable
        items={this.items}
        orderBy={this.props.store.generalStatsOrderBy}
        orderDirection={this.props.store.generalStatsOrderDirection}
        onSortChange={this.handleOnSortChange}
      />
    );
  }
}

export const GeneralStatsTableContainer = as<React.ComponentClass>(GeneralStatsTableContainerPure);
