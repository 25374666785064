import * as React from 'react';

import { Col, Container, Row } from 'reactstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import AnnotationImageNameHeaderContainer from '../../../modules/annotation/containers/AnnotationImageNameHeader.container';
import { AnnotationMoreOptions } from '../../../modules/annotation/components/AnnotationMoreOptions';
import { AnnotationStatusInfoContainer } from '../../../modules/annotation/containers/AnnotationStatusInfoContainer';
import { AnnotationTopBarItem } from '../../../modules/annotation/components/AnnotationTopBarItem';
import { AnnotationTypesContainer } from '../../../modules/annotation/submodules/annotationTypes/containers/AnnotationTypes.container';
import { AnnotationViewOptions } from '../../../modules/annotation/components/AnnotationViewOptions';
import { BackToProjectsButtonContainer } from '../../../modules/annotation/containers/BackToProjectsButtonContainer';
import { CorrectControlsContainer } from '../../../modules/annotation/containers/CorrectControls.container';
import { CurrentWorkspaceContainer } from '../../../modules/workspaces/containers/CurrentWorkspace.container';
import { DrawingContainer } from '../../../modules/annotation/containers/Drawing.container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HiddenAnnotationsToastContainer } from '../../../containers/HiddenAnnotationsToast.container';
import { Home } from '../../../routes/config/Home';
import { ITranslatable } from '../../../helpers/translations.helpers';
import Image from '../../../modules/annotation/components/Image';
import ImageContainer from '../../../modules/annotation/containers/Image.container';
import { ImageSegmentationsContextProvider } from '../../../modules/annotation/submodules/segmentations/segmentationContextMenu/imageSegmentations.context';
import { LeftMenuContainer } from '../../../modules/annotation/submodules/segmentations/containers/LeftMenu.container';
import { NoImagePlaceholder } from '../../../modules/annotation/components/NoImagePlaceholder';
import { ProjectRole } from '../../../models/userRole.model';
import QuestionsContainer from '../../../modules/annotation/containers/Questions.container';
import QuestionsList from '../../../modules/annotation/components/annotations/QuestionsList';
import RedirectFromAnnotationContainer from '../../../modules/annotation/containers/RedirectFromAnnotationContainer';
import ReturnToToolsContainer from '../../../modules/annotation/containers/ReturnToTools.container';
import { ReviewContainer } from '../../../modules/annotation/submodules/review/containers/Review.container';
import { ReviewControlsContainer } from '../../../modules/annotation/containers/ReviewControls.container';
import { ReviewMode } from '../../../modules/annotation/submodules/review/models/reviewMode';
import { ReviewModeBuilderContainer } from '../../../modules/annotation/submodules/review/containers/ReviewModeBuilder.container';
import { ReviewModeSwitchContainer } from '../../../modules/annotation/submodules/review/containers/ReviewModeSwitch.container';
import ReviewProgressContainer from '../../../modules/annotation/containers/ReviewProgress.container';
import { SegmentationsContainer } from '../../../modules/annotation/containers/Segmentations.container';
import SegmentationsSaveContainer from '../../../modules/annotation/containers/SegmentationsSave.container';
import { UndoRedoAnnotationsShortcutsContainer } from '../../../modules/annotation/containers/UndoRedoAnnotationsShortcuts.container';
import { UnlockWorkspaceModal } from '../../../modules/workspaces/components/UnlockWorkspaceModal';
import ViewAttributesButton from '../../../modules/annotation/components/ViewAttributesButton';
import ViewAttributesContainer from '../../../modules/annotation/containers/ViewAttributesContainer';
import { as } from '../../../helpers/react.helpers';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { withNamespaces } from 'react-i18next';

class AnnotationReviewViewPure extends React.Component<ITranslatable & RouteComponentProps<{ workspaceId: string; projectId: string }>> {
  backToProjectReview = () => (
    <>
      <Link to={Home.Projects.List.All.withParams({ workspaceId: this.props.match.params.workspaceId })} className="btn btn-link">
        <FontAwesomeIcon icon={faArrowLeft} /> {this.props.t('back')}
      </Link>
    </>
  );
  backToProjectCorrect = () => <BackToProjectsButtonContainer />;

  moreOptionsReview = () => <AnnotationMoreOptions review globalShortcuts={[]} />;
  moreOptionsCorrect = () => <AnnotationMoreOptions correct globalShortcuts={[]} />;

  segmentationReview = () => <SegmentationsContainer readonly={true} />;
  segmentationCorrect = () => (
    <>
      <SegmentationsContainer />
      <DrawingContainer disableCursorGuides={false} />
    </>
  );

  undoRedoReview = () => <></>;
  undoRedoCorrect = () => (
    <>
      <AnnotationTopBarItem>
        <UndoRedoAnnotationsShortcutsContainer undo={true} />
      </AnnotationTopBarItem>
      <AnnotationTopBarItem>
        <UndoRedoAnnotationsShortcutsContainer undo={false} />
      </AnnotationTopBarItem>
    </>
  );

  controlsContainerReview = () => <ReviewControlsContainer />;
  controlsContainerCorrect = () => <CorrectControlsContainer />;

  render() {
    return (
      <div className="annotation-view">
        <CurrentWorkspaceContainer>{unlockModalProps => <UnlockWorkspaceModal {...unlockModalProps} />}</CurrentWorkspaceContainer>
        <RedirectFromAnnotationContainer allowedRoles={[ProjectRole.Reviewer]} />
        <ReviewContainer>
          {reviewProps => (
            <ImageSegmentationsContextProvider imageId={reviewProps.image?.id}>
              <div className="annotation-view-top-bar" style={{ marginTop: reviewProps.marginTop }}>
                <Container fluid>
                  <AnnotationTopBarItem variation="labeled-icon">
                    <ReviewModeSwitchContainer reviewBuilder={this.backToProjectReview} correctBuilder={this.backToProjectCorrect} />
                  </AnnotationTopBarItem>
                  <ReviewModeSwitchContainer reviewBuilder={this.undoRedoReview} correctBuilder={this.undoRedoCorrect} />
                  <AnnotationTopBarItem variation="labeled-icon">
                    <AnnotationViewOptions disableCursorConfig={reviewProps.mode === ReviewMode.Review} />
                  </AnnotationTopBarItem>
                  <AnnotationTopBarItem variation="show-attributes">
                    <ViewAttributesContainer>
                      <ViewAttributesButton />
                    </ViewAttributesContainer>
                  </AnnotationTopBarItem>
                  <AnnotationTopBarItem variation="image-name">
                    <AnnotationImageNameHeaderContainer />
                    <ReviewProgressContainer />
                  </AnnotationTopBarItem>
                  <AnnotationTopBarItem variation="right-side more">
                    <ReviewModeSwitchContainer reviewBuilder={this.moreOptionsReview} correctBuilder={this.moreOptionsCorrect} />
                  </AnnotationTopBarItem>
                </Container>
              </div>
              <div className="annotation-view-inner" style={{ marginTop: reviewProps.marginTop + 70, height: `calc(100vh - ${70 + reviewProps.marginTop}px)` }}>
                <Container fluid>
                  <Row>
                    <LeftMenuContainer currentProjectId={this.props.match.params.projectId} isReadOnly={true} showObjectsList={true} isReview={true}/>
                    <Col className="px-0">
                      <HiddenAnnotationsToastContainer />
                      <ImageContainer>
                        {reviewProps.noImage ? (
                          <NoImagePlaceholder
                            mode={'Review'}
                            onRefresh={reviewProps.handleRefresh}
                            workspaceId={this.props.match.params.workspaceId}
                            noImageReason={reviewProps.noImageReason}
                          />
                        ) : (
                          <Image>
                            <ReviewModeSwitchContainer reviewBuilder={this.segmentationReview} correctBuilder={this.segmentationCorrect} />
                          </Image>
                        )}
                      </ImageContainer>
                    </Col>
                    <div className="right-column" style={{ height: `calc(100vh - ${70 + reviewProps.marginTop}px)` }}>
                      <div className="scrollable-container">
                        <div className="scrollable-container-content">
                          <ReviewModeBuilderContainer>
                            {(mode: ReviewMode) => {
                              const readonly = mode === ReviewMode.Review;
                              return (
                                <>
                                  <AnnotationTypesContainer readonly={readonly}>
                                    <AnnotationStatusInfoContainer />
                                    <ReturnToToolsContainer readonly={readonly} />
                                  </AnnotationTypesContainer>
                                  <QuestionsContainer readonly={readonly}>
                                    <QuestionsList />
                                  </QuestionsContainer>
                                  {!readonly && <SegmentationsSaveContainer />}
                                </>
                              );
                            }}
                          </ReviewModeBuilderContainer>
                        </div>
                      </div>
                      <div className="set-navigation-container">
                        <ReviewModeSwitchContainer reviewBuilder={this.controlsContainerReview} correctBuilder={this.controlsContainerCorrect} />
                      </div>
                    </div>
                  </Row>
                </Container>
              </div>
            </ImageSegmentationsContextProvider>
          )}
        </ReviewContainer>
      </div>
    );
  }
}

export const AnnotationReviewView = as<React.ComponentClass>(withRouter(withNamespaces('annotation')(AnnotationReviewViewPure)));
