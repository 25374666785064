import './CurrentWorkspaceSelector.scss';

import Select, { components } from 'react-select';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import { CurrentWorkspaceSelectorOption } from './option/CurrentWorkspaceSelectorOption';
import { IWorkspace } from '../../../../../__legacy__/modules/workspaces/workspaces.store';
import React from 'react';
import { ValueType } from 'react-select/lib/types';

interface Props {
  workspaces: IWorkspace[];
  currentWorkspace: IWorkspace | undefined;
  isLoading: boolean | undefined;
  onRefresh: () => void;
  onSelected: (id: string) => void;
}

interface SelectOption<T> {
  value: string;
  label: string;
  additionalData: T;
}

export const CurrentWorkspaceSelectorPure: React.FC<Props & WithNamespaces> = props => {
  const options: SelectOption<IWorkspace>[] = props.workspaces.map(
    w =>
      ({
        label: w.name,
        value: w.id,
        additionalData: w,
      } as SelectOption<IWorkspace>),
  );

  const handleOnChange = (value: ValueType<SelectOption<IWorkspace>>) => {
    const selectedOption = value as SelectOption<IWorkspace>;
    if (selectedOption) {
      props.onSelected(selectedOption.additionalData.id);
    }
  };

  const renderOption = (props: any) => {
    const workspace = props.data.additionalData as IWorkspace;
    return (
      <components.Option {...props}>
        <CurrentWorkspaceSelectorOption workspace={workspace} />
      </components.Option>
    );
  };

  const isOptionDisabled = (value: SelectOption<IWorkspace>) => {
    return value.additionalData.locked;
  };

  return (
    <div className="current-workspace-selector top-nav-item" id="current-workspace-nav-item">
      <label>{props.t('current_workspace')}</label>
      <Select
        className="current-workspace-selector-select"
        id="current-workspace-dropdown"
        value={options.find(({ additionalData: w }) => w.id === props.currentWorkspace?.id)}
        options={options}
        classNamePrefix="custom-select"
        onMenuOpen={props.onRefresh}
        isLoading={props.isLoading}
        onChange={handleOnChange}
        isOptionDisabled={isOptionDisabled}
        components={{ Option: renderOption }}
      />
    </div>
  );
};

export const CurrentWorkspaceSelector = withNamespaces('workspace')(CurrentWorkspaceSelectorPure);
