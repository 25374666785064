import { ApiPolicyProviderType, IApiPolicyProvider } from './api.policy';
import { inject, injectable } from 'inversify';
import { ConfigurationType, IConfiguration } from '../../configuration';
import { AuthStoreSetterType, IAuthStoreSetter } from '../modules/auth/auth.store';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { CacheManagerType, ICacheManager } from './cacheManager';
import { CryptoServiceType, ICryptoService } from './crypto.service';
import { ApiServiceBase } from './api.service.base';

export const ApiServiceTrainingType = Symbol('API_SERVICE_TRAINING');

@injectable()
export class ApiServiceTraining extends ApiServiceBase {
  constructor(
    @inject(ConfigurationType) public readonly config: IConfiguration,
    @inject(AuthStoreSetterType) protected readonly authStore: IAuthStoreSetter,
    @inject(CurrentWorkspaceStoreType) protected readonly currentWorkspaceStore: ICurrentWorkspaceStore,
    @inject(ApiPolicyProviderType) protected readonly apiPolicy: IApiPolicyProvider,
    @inject(CacheManagerType) protected readonly cacheManager: ICacheManager,
    @inject(CryptoServiceType) protected readonly cryptoService: ICryptoService,
  ) {
    super(config, authStore, currentWorkspaceStore, apiPolicy, cacheManager, cryptoService);
  }

  public getUrl = (urlSuffix: string): string => `https://training-orchestrator.happyfield-83ea5ac3.westeurope.azurecontainerapps.io/api/v1${urlSuffix}`;
}
