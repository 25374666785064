import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { IUserService, UserServiceType } from '../../user/user.service';
import { IWorkspaceService, WorkspaceServiceType } from '../workspaces.service';
import React, { ChangeEvent } from 'react';
import { as, injectProps } from '../../../helpers/react.helpers';

import { ITranslatable } from '../../../helpers/translations.helpers';
import _ from 'lodash';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  workspaceService: IWorkspaceService;
  userService: IUserService;
}

@injectProps({
  workspaceService: WorkspaceServiceType,
  userService: UserServiceType,
})
@observer
class WorkspaceListSearchContainerPure extends React.Component<IInjectedProps & ITranslatable> {
  @autobind
  handleChangeWorkspaceName(event: ChangeEvent<HTMLInputElement>) {
    this.props.workspaceService.store.workspaceNameSearch = event.target.value;
    this.handleSearchChangeDebounced();
  }

  @autobind
  handleChangeOwner(event: ChangeEvent<HTMLInputElement>) {
    this.props.workspaceService.store.workspaceOwnerSearch = event.target.value;
    this.handleSearchChangeDebounced();
  }

  @autobind
  async handleSearchChange() {
    await this.props.workspaceService.getWorkspaceListItemsAsync();
  }

  handleSearchChangeDebounced = _.debounce(
    this.handleSearchChange,
    1000,
    {
      leading: false,
      trailing: true,
    },
  );

  render() {
    return (
      <Form>
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label>{this.props.t('workspace_name')}</Label>
              <Input
                id="workspace-search"
                value={this.props.workspaceService.store.workspaceNameSearch}
                placeholder={this.props.t('workspace_name')}
                onChange={this.handleChangeWorkspaceName}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>{this.props.t('owner_email')}</Label>
              <Input
                id="owner-search"
                value={this.props.workspaceService.store.workspaceOwnerSearch}
                placeholder={this.props.t('owner_email')}
                onChange={this.handleChangeOwner}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}

export const WorkspaceListSearchContainer = as<React.ComponentClass>(withNamespaces('common')(WorkspaceListSearchContainerPure));
