import { FiltersDropdownRow } from './FiltersDropdownRow';
import { IImageFilters } from '../../imageFilters.model';
import { ITranslatable } from '../../../../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../../../../helpers/react.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IFilterInfoProps {
  imageFilters: IImageFilters;
}

@observer
class ImageNameFilterInfoPure extends React.Component<IFilterInfoProps & ITranslatable> {
  render() {
    return (
      <>
        {this.props.imageFilters.imageName.length > 0 &&
          <FiltersDropdownRow label={this.props.t('image_filters.image_name')} count={1}>
            <>{this.props.imageFilters.imageName}</>
          </FiltersDropdownRow>}
      </>
    );
  }
}

export const ImageNameFilterInfo = as<React.ComponentClass<IFilterInfoProps>>(withNamespaces('common')(ImageNameFilterInfoPure));
