// tslint:disable:function-name
// tslint:disable:variable-name

import leaflet, { DomUtil, Map, PathDraggable, Point } from 'leaflet';

import { MOUSE_BUTTONS } from '../../annotations.interface';

class PathDraggableEx extends PathDraggable {
  _onDown(e: MouseEvent) {
    if (!(e.buttons & MOUSE_BUTTONS.LEFT)) return;
    super._onDown(e);
  }
}

export class DrawPathDrag extends leaflet.Handler.PathDrag {
  private wasOrientationMarkerInitialized: boolean = false;
  private orientationMarker: any;

  addHooks() {
    if (!this._draggable) { this._draggable = new PathDraggableEx(this._path); }
    this._draggable.on(this.getEvents(), this as any).enable();
    DomUtil.addClass(this._draggable._element, 'leaflet-path-draggable');
  }

  private calculateOffset(e: any): { start: Point; offset: Point } {
    const event = e.originalEvent.touches && e.originalEvent.touches.length === 1 ? e.originalEvent.touches[0] : e.originalEvent;
    const nextStartPoint = leaflet.point(event.clientX, event.clientY);
    const currentOffset: Point = nextStartPoint.subtract(this._startPoint);

    return { start: nextStartPoint, offset: currentOffset };
  }

  private initializeOrientationMarker(map: Map) {
    this.wasOrientationMarkerInitialized = true;
    const storeKey = this._path.options.storeKey;

    if (storeKey && map) {
      map.eachLayer((layer: any) => {
        if (layer.options.storeKey === `${storeKey}-orientation-marker`) this.orientationMarker = layer;
      });
    }
  }

  _onDrag(e: any) {
    const map: Map = this._path._map;
    if (!this.wasOrientationMarkerInitialized) this.initializeOrientationMarker(map);

    const offsetCalculationResult: { start: Point; offset: Point } = this.calculateOffset(e);
    this._offset = offsetCalculationResult.offset;
    this._startPoint = offsetCalculationResult.start;
    this._path.eachLatLng(this.updateLatLng, this);

    if (this.orientationMarker) {
      this.orientationMarker.eachLatLng(this.updateLatLng, this);
      this.orientationMarker.redraw();
    }

    this._path.redraw();

    e.latlng = this._path._map.layerPointToLatLng(this._startPoint);
    e.offset = this._offset;
    this._path.fire('drag', e);

    e.latlng = this._path.getCenter ? this._path.getCenter() : this._path.getLatLng();

    this._path.fire('move', e);
  }
}
