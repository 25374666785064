import * as React from 'react';

import { ITranslatable } from '../../../helpers/translations.helpers';
import { PageHeader } from '../../../components/PageHeader';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export const AdminHeader = as<React.StatelessComponent>(
  withNamespaces('project', { wait: true })((props: ITranslatable) => (
    <PageHeader
      id="admin-header"
      headerText={props.t('admin_panel')}
      tabNavPresent="clean"
    />
  )),
);
