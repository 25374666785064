import * as React from 'react';

import { CreateDatasetServiceType, ICreateDatasetService } from '../createDataset.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { DatasetNameForm } from '../components/DatasetNameForm';
import { Home } from '../../../routes/config/Home';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ datasetDraftId: string; workspaceId: string }> {
  createDatasetService: ICreateDatasetService;
}

@injectProps({
  createDatasetService: CreateDatasetServiceType,
})
@(withRouter as any)
@observer
class CreateDatasetContainerPure extends React.Component<IInjectedProps> {
  // tslint:disable-next-line:function-name
  UNSAFE_componentWillMount(): void {
    const draftId = this.props.createDatasetService.initNewDraft();
    const path = Home.Datasets.Create.withParams({ datasetDraftId: draftId, workspaceId: this.props.match.params.workspaceId });

    this.props.history.push(path);
  }

  render() {
    const { draft, isValid } = this.props.createDatasetService.datasetsDraftStore;
    if (!draft) return <div />;

    return (
      <DatasetNameForm
        name={draft.name}
        description={draft.description}
        onNameChange={this.props.createDatasetService.changeDraftNameAsync}
        onDescriptionChange={this.props.createDatasetService.changeDraftDescription}
        nameStatus={draft.nameStatus}
        externalStatus={draft.externalStatus}
        isCreationEnabled={isValid && !draft.willExceedsUploadImageSizeLimit}
        author={draft.author}
        onAuthorChange={this.props.createDatasetService.changeDraftAuthor}
        termsOfUse={draft.termsOfUse}
        onTermsOfUseChange={this.props.createDatasetService.changeDraftTermsOfUse}
      />
    );
  }
}

export const CreateDatasetContainer = as<React.ComponentClass>(CreateDatasetContainerPure);
