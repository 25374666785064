import { injectable, inject } from 'inversify';
import { ConfigurationType, IConfiguration } from '../../configuration';
import { IQuestionModel, QuestionModel } from '../modules/annotation/question.model';
import { action } from 'mobx';
import { BaseHubService } from './baseHub.service';
import { BillingStoreType, IBillingStore } from '../modules/billing/billing.store';
import { IProjectDetailsImagesBl, ProjectDetailsImagesBlType } from '../modules/projectDetails/sub/images/projectDetailsImages.bl';
import { IProjectImportReport } from '../modules/projects/projects.model';
import { IProjectsService, ProjectsServiceType } from '../modules/projects/projects.service';
import { EventBusType, IEventBus } from './eventBus.service';
import { AuthStoreSetterType, IAuthStoreSetter } from '../modules/auth/auth.store';
import { GetAnnotationTypesResponse, IAnnotationTypeResponse } from '../modules/annotation/submodules/annotationTypes/services/getAnnotationTypesService';
import { IAnnotationType } from '../modules/annotation/submodules/annotationTypes/models/annotationType';
import { IProjectDetailsImagesStore, ProjectDetailsImagesStoreType } from '../modules/projectDetails/sub/images/projectDetailsImages.store';
import { ImportAnnotationsSuccessResponseEvent } from '../../modules/projects/details/images/importAnnotations/events/ImportAnnotationsSuccessResponseEvent';
import { ImportAnnotationsFailureResponseEvent } from '../../modules/projects/details/images/importAnnotations/events/ImportAnnotationsFailureResponseEvent';
import { IImportAnnotationsReport } from '../../modules/projects/details/images/importAnnotations/models/ImportAnnotationsReport';
import { ProjectExportFinishedEvent } from '../../modules/projects/details/exportData/events/ProjectExportFinishedEvent';
import { ProjectExportStartedEvent } from '../../modules/projects/details/exportData/events/ProjectExportStartedEvent';
import { AlertsUpdatedEvent } from '../../modules/alertBar/models/AlertsUpdatedEvent';
import { Alert } from '../../modules/alertBar/models/Alert';

export const ProjectHubServiceType = Symbol('PROJECT_HUB_SERVICE');

export interface IProjectHubService {
  initializeAsync(): Promise<void>;
  didQuestionsChangedFor(projectId: string): boolean;
  popNewQuestionsFor(projectId: string): IQuestionModel[];
  didAnnotationTypesChangedFor(projectId: string): boolean;
  popNewAnnotationTypesFor(projectId: string): IAnnotationType[];
}

const HUB_METHODS = {
  QUESTIONS_CHANGED: 'OnProjectQuestionsChanged',
  ANNOTATION_TYPES_CHANGED: 'OnProjectAnnotationTypesChanged',
  DRAFT_IMPORT_FINISHED: 'OnDraftImportFinished',
  DRAFT_IMPORT_FAILED: 'OnDraftImportFailed',
  BATCH_ANSWER_QUESTIONS_FINISHED: 'OnBatchAnswerQuestionsFinished',
  BATCH_ANSWER_QUESTIONS_FAILED: 'OnBatchAnswerQuestionsFailed',
  IMPORT_ANNOTATIONS_FINISHED: 'OnAnnotationsImportFinished',
  IMPORT_ANNOTATIONS_FAILED: 'OnAnnotationsImportFailed',
  ALERTS_UPDATED: "OnAlertsUpdated",
  PROJECT_EXPORT_FINISHED: 'OnProjectExportFinished',
  PROJECT_EXPORT_STARTED: 'OnProjectExportStarted',
};

@injectable()
export class ProjectHubService extends BaseHubService implements IProjectHubService {
  private newQuestions: IQuestionModel[] = [];
  private newAnnotationTypes: IAnnotationType[] = [];

  constructor(
    @inject(ConfigurationType) config: IConfiguration,
    @inject(AuthStoreSetterType) auth: IAuthStoreSetter,
    @inject(ProjectsServiceType) private projectsService: IProjectsService,
    @inject(ProjectDetailsImagesBlType) private projectDetailsImagesBl: IProjectDetailsImagesBl,
    @inject(BillingStoreType) private billingStore: IBillingStore,
    @inject(ProjectDetailsImagesStoreType) private imagesStore: IProjectDetailsImagesStore,
    @inject(EventBusType) private eventBus: IEventBus,
  ) {
    super(auth, config.projectHubUrl);
    this.addHandlers();
  }

  addHandlers() {
    this.connection.on(HUB_METHODS.QUESTIONS_CHANGED, this.handleQuestionsChanged);
    this.connection.on(HUB_METHODS.ANNOTATION_TYPES_CHANGED, this.handleAnnotationTypesChanged);
    this.connection.on(HUB_METHODS.DRAFT_IMPORT_FINISHED, this.handleDraftImportFinished);
    this.connection.on(HUB_METHODS.DRAFT_IMPORT_FAILED, this.handleDraftImportFailed);
    this.connection.on(HUB_METHODS.BATCH_ANSWER_QUESTIONS_FINISHED, this.handleBatchAnswerQuestionsFinished);
    this.connection.on(HUB_METHODS.BATCH_ANSWER_QUESTIONS_FAILED, this.handleBatchAnswerQuestionsFailed);
    this.connection.on(HUB_METHODS.IMPORT_ANNOTATIONS_FINISHED, this.handleImportAnnotationsFinished);
    this.connection.on(HUB_METHODS.IMPORT_ANNOTATIONS_FAILED, this.handleImportAnnotationsFailed);
    this.connection.on(HUB_METHODS.ALERTS_UPDATED, this.handleAlertsUpdated);
    this.connection.on(HUB_METHODS.PROJECT_EXPORT_FINISHED, this.handleProjectExportFinished);
    this.connection.on(HUB_METHODS.PROJECT_EXPORT_STARTED, this.handleProjectExportStarted);
  }

  @action.bound
  handleQuestionsChanged = (projectId: string, response: IQuestionModel[]) => {
    this.newQuestions = this.newQuestions.filter(q => q.projectId !== projectId).concat(response);
  };

  @action.bound
  handleAnnotationTypesChanged = (projectId: string, response: IAnnotationTypeResponse[]) => {
    // Question is: Should we really get this data here or just ask for them when they are really needed?
    // Using GetAnnotationTypesResponse so the mapping is not copied in two places.
    const apiResponse = new GetAnnotationTypesResponse({ annotationTypes: response });
    this.newAnnotationTypes = this.newAnnotationTypes.filter(q => q.projectId !== projectId).concat(apiResponse.annotationTypes);
  };

  @action.bound
  handleDraftImportFinished = async (report: IProjectImportReport) => {
    await this.projectsService.finishProjectImportAsync(report);
  };

  @action.bound
  handleDraftImportFailed = async (errorCodes: string[]) => {
    await this.projectsService.failProjectImportAsync(errorCodes);
  };

  @action.bound
  handleBatchAnswerQuestionsFinished = async (fee: number, batchNumber: number, totalBatchesCount: number, sessionId: string) => {
    this.billingStore.usedCredits += fee;

    if (this.imagesStore.batchAnswerQuestionModalSessionId !== sessionId) return;

    this.projectDetailsImagesBl.updateBatchAnswerQuestionsProgressAsync(false, totalBatchesCount);
    if (batchNumber === totalBatchesCount) await this.projectDetailsImagesBl.batchAnswerQuestionsRequestFinishedAsync();
  };

  @action.bound
  handleBatchAnswerQuestionsFailed = async (batchNumber: number, totalBatchesCount: number, sessionId: string) => {
    if (this.imagesStore.batchAnswerQuestionModalSessionId !== sessionId) return;

    this.projectDetailsImagesBl.updateBatchAnswerQuestionsProgressAsync(true, totalBatchesCount);
    if (batchNumber === totalBatchesCount) await this.projectDetailsImagesBl.batchAnswerQuestionsRequestFinishedAsync();
  };

  handleImportAnnotationsFinished = async (report: IImportAnnotationsReport) => {
    this.eventBus.sendEvent(new ImportAnnotationsSuccessResponseEvent(report));
  };

  handleImportAnnotationsFailed = async (errorCodes: string[]) => {
    this.eventBus.sendEvent(new ImportAnnotationsFailureResponseEvent(errorCodes));
  };

  handleAlertsUpdated = async (alerts: Alert[]) => {
    this.eventBus.sendEvent(new AlertsUpdatedEvent(alerts));
  };

  handleProjectExportStarted = async (projectId: string) => {
    this.eventBus.sendEvent(new ProjectExportStartedEvent(projectId));
  };

  handleProjectExportFinished = async (projectId: string) => {
    this.eventBus.sendEvent(new ProjectExportFinishedEvent(projectId));
  };

  didQuestionsChangedFor(projectId: string) {
    return this.newQuestions.some(q => q.projectId === projectId);
  }

  didAnnotationTypesChangedFor(projectId: string) {
    return this.newAnnotationTypes.some(q => q.projectId === projectId);
  }

  popNewQuestionsFor(projectId: string) {
    const values = this.newQuestions.filter(at => at.projectId === projectId).map(q => new QuestionModel(q.projectId, q.id, q.type, q.isRequired, q.text, q.answers, q.scopes));
    this.newQuestions = this.newQuestions.filter(at => at.projectId !== projectId);
    return values;
  }

  popNewAnnotationTypesFor(projectId: string) {
    const values = this.newAnnotationTypes.filter(at => at.projectId === projectId);
    this.newAnnotationTypes = this.newAnnotationTypes.filter(at => at.projectId !== projectId);
    return values;
  }
}
