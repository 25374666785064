import React from 'react';
import { as } from '../helpers/react.helpers';
import { BOOLEAN_OPTIONS, Parameter } from '../helpers/parameters.helpers';
import { ValidatedTextInput } from './ValidatedTextInput';
import Select from 'react-select';

interface IProps {
  label: string;
  parameter: Parameter;
  fieldIndex: number;
  value: any;
  alwaysShowError?: boolean;
  disabled?: boolean;
  error?: {
    message: string;
    messageParameters: Record<string, any>;
  } | null;
  onChange: (value: any) => void;
  onBlur: (value: any) => void;
}

const ValidatedParameterPure = (props: IProps) => {
  const { label, parameter, fieldIndex, error, disabled, onChange, onBlur, alwaysShowError, value } = props;

  const isFieldValidated = error !== undefined || alwaysShowError;
  const hasError = error != null;

  if ('config' in parameter) {
    const {
      label: parameterLabel,
      config: { min, max, step },
    } = parameter;

    return (
      <div className={`list-row ${fieldIndex === 0 ? 'first' : ''}`}>
        <div className="left" style={{ paddingRight: '1rem' }}>
          {label}
        </div>
        <div className="right right-form">
          <ValidatedTextInput
            id={`model-${parameterLabel}-numeric-field`}
            type="number"
            min={min}
            max={max}
            step={step}
            isValid={isFieldValidated ? !hasError : undefined}
            feedbacks={hasError ? [error!.message] : []}
            feedbacksParams={hasError ? error!.messageParameters : {}}
            value={value}
            onChange={onChange}
            disabled={disabled}
            onBlur={onBlur}
          />
        </div>
      </div>
    );
  }

  const { label: parameterLabel } = parameter;
  return (
    <div className={`list-row ${fieldIndex === 0 ? 'first' : ''}`}>
      <div className="left" style={{ paddingRight: '1rem' }}>
        {label}
      </div>
      <div className="right right-form">
        <Select
          id={`model-${parameterLabel}-boolean-field`}
          value={value}
          options={BOOLEAN_OPTIONS}
          // Selects are always valid, because we preselect default value and users can only change it
          // to one of the available
          classNamePrefix={`${isFieldValidated ? 'is-valid' : ''} custom-select`}
          onChange={onChange}
          onBlur={onBlur}
          isDisabled={disabled}
        />
      </div>
    </div>
  );
};

export const ValidatedParameter = as<React.FC<IProps>>(ValidatedParameterPure);
