import { ContainerModule, interfaces } from 'inversify';
import { GetTeamMembersService, GetWorkspaceTeamMembersServiceType, IGetTeamMembersService } from './services/getTeamMembers.service';
import { ITeamApiService, TeamApiService, TeamApiServiceType } from './services/teamApi.service';
import { ITeamPermissions, TeamPermissions, TeamPermissionsType } from './team.permissions';
import { ITeamService, TeamService, TeamServiceType } from './team.bl';
import { ITeamStore, ITeamStoreSetter, TeamStore, TeamStoreSetterType, TeamStoreType } from './team.store';

export const teamModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<ITeamStore>(TeamStoreType).to(TeamStore).inSingletonScope();
  bind<ITeamStoreSetter>(TeamStoreSetterType).toService(TeamStoreType);

  bind<ITeamService>(TeamServiceType).to(TeamService).inSingletonScope();

  bind<ITeamApiService>(TeamApiServiceType).to(TeamApiService).inSingletonScope();

  bind<ITeamPermissions>(TeamPermissionsType).to(TeamPermissions).inSingletonScope();

  bind<IGetTeamMembersService>(GetWorkspaceTeamMembersServiceType).to(GetTeamMembersService).inRequestScope();
});
