import * as React from 'react';
import { withNamespaces } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { as } from '../helpers/react.helpers';
import { ITranslatable } from '../helpers/translations.helpers';
import { ButtonWithLoader } from './ButtonWithLoader';

interface IConfirmationModalProps {
  confirmationDescription?: string;
  confirmationQuestion?: string;
  confirmationQuestionValues?: any;
  confirmationHeader: string;
  confirmationYes?: string;
  confirmationNo?: string;
  showSpinner?: boolean;
  showModal: boolean;
  onConfirm(): void;
  onCancel(): void;
  confirmColor?: string;
  cancelColor?: string;
  loaderKey?: string;
}

class ConfirmationModalPure extends React.Component<IConfirmationModalProps & ITranslatable> {
  render() {
    return (
      <Modal className="responsive-modal" isOpen={this.props.showModal} toggle={this.props.onCancel}>
        <ModalHeader toggle={this.props.onCancel}>{this.props.t(this.props.confirmationHeader)}</ModalHeader>
        <ModalBody>
          <div className="mt-3">
            {this.props.confirmationDescription && <p>{this.props.t(this.props.confirmationDescription)}</p>}
            {this.props.confirmationQuestion && <p>{this.props.t(this.props.confirmationQuestion, this.props.confirmationQuestionValues)}</p>}
            {this.props.children}
          </div>
        </ModalBody>
        <ModalFooter>
          <ButtonWithLoader className="mr-2" color={this.props.confirmColor || 'warning'} onClick={this.props.onConfirm} loaderKey={this.props.loaderKey}>
            {this.props.t(this.props.confirmationYes || 'yes')}
          </ButtonWithLoader>
          <Button outline color={this.props.cancelColor || 'primary'} onClick={this.props.onCancel}>
            {this.props.t(this.props.confirmationNo || 'cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export const ConfirmationModal = as<React.StatelessComponent<IConfirmationModalProps>>(withNamespaces('common', { wait: true })(ConfirmationModalPure));