import { IPagingInfoWithOrder, PaginationInfoDefault } from '../../models/paginationInfo.model';
import { computed, observable } from 'mobx';

import { ProjectRole } from '../../models/userRole.model';
import { getAcronym } from '../../helpers/string.helper';
import { injectable } from 'inversify';

export const UserStoreType = Symbol('USER_STORE');

export enum UserRole {
  User = 'User',
  Administrator = 'Administrator',
}

export enum Plan {
  Starter = 'Starter',
  Professional = 'Professional',
  Enterprise = 'Enterprise',
  Admin = 'Admin',
}

export interface IUserInfo {
  id: string;
  email: string;
  fullName: string;
  company: string;
  country: string;
  phoneNumber: string;
  queryString: string;
  credits: ICredits;
  role: UserRole;
  consents: IConsent[];
  showCart: boolean;
  projectRoles: IProjectRole[];
  emailChangePending: boolean;
}

export interface IAdminUserInfo extends IPlanInfo {
  id: string;
  email: string;
  role: UserRole;
  name: string;
  country: string;
  lastLoginDate: Date;
  organization: string;
  registrationDate: Date;
  workTime: string;
  imagesSize: number;
  attachmentSizeInB: number;
  dataSizeInMb: number;
}

export interface IPlanInfo {
  plan: Plan;
  availableCredits: number;
  imagesAmount?: number;
  availableSpace?: number;
  workspaces?: number;
  workspaceUsers?: number;
  workspaceWorkers?: number;
  customLimits: boolean;
  isActive: boolean;
}

export interface ICredits {
  accountExists: boolean;
  totalCredits: number;
  usedCredits: number;
  availableCredits: number;
  imagesAmount: number;
  usedSpace: number;
  workspaces: number;
  workspaceUsers: number;
  workspaceWorkers: number;
}

export interface IProjectRole {
  projectId: string;
  role: ProjectRole;
}

export enum ConsentType {
  None = 'none',
  DataAdministration = 'DataAdministration',
  Newsletter = 'Newsletter',
}

export interface IConsent {
  type: ConsentType;
  date: Date;
  isGiven: boolean;
}

export enum ListViewMode {
  List = 'List',
  Tiles = 'Tiles',
}

export interface IUserStore {
  userInfo: IUserInfo;
  planInfo: IPlanInfo;
  adminUserInfo: IAdminUserInfo[];
  adminUsersPaging: IPagingInfoWithOrder;
  plansInfo: IPlanInfo[];
  planDefaults: IPlanInfo[];
  plansPaging: IPagingInfoWithOrder;
  shortName: string;
  projectImageListViewMode: ListViewMode;
  datasetImageListViewMode: ListViewMode;
  attachmentsListViewMode: ListViewMode;
  projectListViewMode: ListViewMode;
  areAttributesShown: boolean;
  opacityLevel: number;
  isImprovedVisibilityCursorEnabled: boolean;
  areCursorGuidesEnabled: boolean;
}

@injectable()
export class UserStore implements IUserStore {
  @observable
  userInfo: IUserInfo = {
    id: '',
    email: '',
    fullName: '',
    company: '',
    country: '',
    phoneNumber: '',
    queryString: '',
    credits: {
      accountExists: false,
      totalCredits: 0,
      usedCredits: 0,
      availableCredits: 0,
      imagesAmount: 0,
      usedSpace: 0,
      workspaces: 0,
      workspaceUsers: 0,
      workspaceWorkers: 0,
    },
    showCart: false,
    role: UserRole.User,
    consents: [],
    projectRoles: [],
    emailChangePending: false,
  };

  @observable
  planInfo: IPlanInfo = {
    availableCredits: 0,
    customLimits: false,
    isActive: false,
    plan: Plan.Starter,
    imagesAmount: 0,
    availableSpace: 0,
    workspaces: 0,
    workspaceUsers: 0,
    workspaceWorkers: 0,
  };

  @observable
  adminUserInfo: IAdminUserInfo[] = [];

  @observable
  plansInfo: IPlanInfo[] = [];

  @observable
  planDefaults: IPlanInfo[] = [];

  @observable
  adminUsersPaging: IPagingInfoWithOrder = {
    ...PaginationInfoDefault,
    orderBy: '',
    orderType: '',
  };

  @observable
  plansPaging: IPagingInfoWithOrder = {
    pageNumber: 1,
    pagesCount: 1,
    totalCount: 1,
    pageSize: 100,
    orderBy: '',
    orderType: '',
  };

  @observable
  projectImageListViewMode: ListViewMode = ListViewMode.Tiles;

  @observable
  datasetImageListViewMode: ListViewMode = ListViewMode.Tiles;

  @observable
  attachmentsListViewMode: ListViewMode = ListViewMode.List;

  @observable
  projectListViewMode: ListViewMode = ListViewMode.List;

  @observable
  areAttributesShown: boolean = false;

  @observable
  opacityLevel: number = 20;

  @observable
  isImprovedVisibilityCursorEnabled = false;

  @observable
  areCursorGuidesEnabled = false;

  @computed
  get shortName(): string {
    const fullNameAcronym = getAcronym(this.userInfo.fullName);
    return (fullNameAcronym.length > 1 ? fullNameAcronym : this.userInfo.email).substring(0, 2);
  }
}
