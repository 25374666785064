import { AnnotationServiceType, IAnnotationService } from '../annotation.service';
import { AnnotationsStoreType, IAnnotationsStore } from '../annotations.store';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { as, injectProps } from '../../../helpers/react.helpers';

import { ITranslatable } from '../../../helpers/translations.helpers';
import { NavigationButton } from '../components/NavigationButton';
import { ProjectStatus } from '../../projects/projects.model';
import React from 'react';
import { SubmitIcon } from '../../../components/icons/SubmitIcon';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps extends RouteComponentProps<{ projectId: string }> {
  store: IAnnotationsStore;
  service: IAnnotationService;
}

interface IProps {
  onSubmit(): void;
  disabled: boolean;
}

@injectProps({
  store: AnnotationsStoreType,
  service: AnnotationServiceType,
})
@observer
class SubmitAnnotationButtonContainerPure extends React.Component<IProps & IInjectedProps & ITranslatable> {
  async componentDidMount() {
    await this.props.service.getProjectStatusAsync(this.props.match.params.projectId);
  }
  render() {
    const isProjectDraft = this.props.store.projectStatus === ProjectStatus.Draft;
    return (
      <NavigationButton
        id="submit-annotation"
        loaderKey="submit-annotation-button"
        disabled={this.props.disabled || isProjectDraft}
        onClick={this.props.onSubmit}
        title={'submit_tooltip'}
        caption="submit"
      >
        <SubmitIcon />
      </NavigationButton>
    );
  }
}
export const SubmitAnnotationButtonContainer = as<React.ComponentClass<IProps>>(withRouter(withNamespaces('common')(SubmitAnnotationButtonContainerPure)));
