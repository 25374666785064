import { action, observable } from 'mobx';

import { IAnnotationType } from './models/annotationType';
import { IAnnotationTypeOptions } from './models/annotationTypeOptions';
import { injectable } from 'inversify';

export const AnnotationTypeStoreType = Symbol('ANNOTATION_TYPE_STORE');
export const AnnotationTypeStoreSetterType = Symbol('ANNOTATION_TYPE_STORE_SETTER');

interface IHiddenAnnotationType {
  projectId: string;
  annotationTypes: string[];
}

export interface IAnnotationTypeStore {
  readonly activeAnnotationTypeId: string | undefined;
  readonly annotationTypes: IAnnotationType[];
  readonly hiddenAnnotationTypes: IHiddenAnnotationType[];
  readonly annotationTypesOptions: IAnnotationTypeOptions[];
  readonly annotationTypeKeyMap: {} | undefined;
  readonly annotationTypeKeyHandlers: {} | undefined;
}

export interface IAnnotationTypeStoreSetter extends IAnnotationTypeStore {
  setAnnotationTypes(annotationTypes: IAnnotationType[]): void;
  setActiveAnnotationTypeId(activeAnnotationTypeId?: string): void;
  setHiddenAnnotationTypes(hiddenAnnotationTypes: IHiddenAnnotationType[]): void;
  setAnnotationTypeOptions(annotationTypesOptions?: IAnnotationTypeOptions[]): void;
  setAnnotationTypeKeyMap(annotationTypeKeyMap?: {}, annotationTypeKeyHandlers?: {}): void;
  clean(): void;
}

@injectable()
export class AnnotationTypeStore implements IAnnotationTypeStoreSetter {
  @observable
  annotationTypes: IAnnotationType[] = [];

  @observable
  hiddenAnnotationTypes: IHiddenAnnotationType[] = [];

  @observable
  activeAnnotationTypeId: string | undefined = undefined;

  @observable
  annotationTypesOptions: IAnnotationTypeOptions[] = [];

  @observable
  annotationTypeKeyHandlers: {} | undefined;

  @observable
  annotationTypeKeyMap: {} | undefined;

  @action
  setAnnotationTypes(annotationTypes: IAnnotationType[]): void {
    this.annotationTypes = annotationTypes;
  }

  @action
  setHiddenAnnotationTypes(hiddenAnnotationTypes: IHiddenAnnotationType[]): void {
    this.hiddenAnnotationTypes = hiddenAnnotationTypes;
  }

  @action
  setActiveAnnotationTypeId(activeAnnotationTypeId?: string) {
    this.activeAnnotationTypeId = activeAnnotationTypeId;
  }

  @action
  setAnnotationTypeOptions(annotationTypesOptions: IAnnotationTypeOptions[]): void {
    this.annotationTypesOptions = annotationTypesOptions;
  }

  @action
  setAnnotationTypeKeyMap(annotationTypeKeyMap?: {}, annotationTypeKeyHandlers?: {}): void {
    this.annotationTypeKeyMap = annotationTypeKeyMap;
    this.annotationTypeKeyHandlers = annotationTypeKeyHandlers;
  }

  @action
  clean(): void {
    this.annotationTypes = [];
    this.activeAnnotationTypeId = undefined;
  }
}
