import './ProjectDetailsImagesListTable.scss';

import { GTable, IGColumnProps } from '../../../../../components/table/GTable';
import { IProjectDetailsImage, ImageAnnotationStatus, ImageSetType } from '../projectDetailsImages.model';
import React, { SyntheticEvent } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import { CustomInput } from 'reactstrap';
import { DatasetStatus } from '../../../../datesets/datasetStatus.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Home } from '../../../../../routes/config/Home';
import { IProjectDetailsImagesListProps } from './ProjectDetailsImagesList';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { Link } from 'react-router-dom';
import { Loader } from '../../../../../components/Loader';
import Select from 'react-select';
import { SortingBy } from '../imageFilters.model';
import { SortingDirection } from '../../../../../models/sortingDirection.model';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { handleSelectChange } from '../../../../../helpers/formHelpers';
import { nameOf } from '../../../../../helpers/object.helpers';
import { observer } from 'mobx-react';
import { toLocaleDateString } from '../../../../../helpers/date.helpers';

interface IImageListItem extends IProjectDetailsImage {
  isChecked: boolean;
}

interface IImagesIGColumnProps<T> extends IGColumnProps<T> {
  sortingBy: SortingBy;
}

@observer
class ProjectDetailsImagesListTablePure extends React.Component<IProjectDetailsImagesListProps & WithNamespaces> {
  gColumns: IImagesIGColumnProps<IImageListItem>[] = [];

  constructor(props: IProjectDetailsImagesListProps & ITranslatable) {
    super(props);

    if (this.props.canBatchAnswerQuestions) {
      this.gColumns.push({
        field: 'isChecked',
        headerName: '',
        width: 40,
        renderer: this.renderCheckbox,
        headerRenderer: this.renderSelectHeader,
        sortable: false,
        sortingBy: SortingBy.None,
      });
    }

    this.gColumns.push(
      {
        field: nameOf<IImageListItem>('name'),
        headerName: this.props.t('project_details_images.header_name'),
        renderer: this.renderName,
        sortingBy: SortingBy.Name,
      },
      {
        field: nameOf<IImageListItem>('datasetName'),
        headerName: this.props.t('project_details_images.header_datasetName'),
        renderer: this.renderDatasetName,
        sortingBy: SortingBy.DatasetName,
      },
      {
        field: nameOf<IImageListItem>('status'),
        headerName: this.props.t('project_details_images.header_status'),
        renderer: this.renderStatus,
        sortingBy: SortingBy.Status,
        width: 220,
      },
    );

    if (this.props.canSeeUsers) {
      this.gColumns.push(
        {
          field: nameOf<IImageListItem>('annotatedBy'),
          headerName: this.props.t('project_details_images.header_annotatedBy'),
          sortingBy: SortingBy.AnnotatedBy,
        },
        {
          field: nameOf<IImageListItem>('reviewedBy'),
          headerName: this.props.t('project_details_images.header_reviewedBy'),
          sortingBy: SortingBy.ReviewedBy,
        },
      );
    }

    this.gColumns.push({
      field: nameOf<IImageListItem>('dateOfMarking'),
      headerName: this.props.t('project_details_images.header_dateOfMarking'),
      sortingBy: SortingBy.DateOfMarking,
      renderer: this.renderDateOfMarking,
    });

    this.gColumns.push({
      field: nameOf<IImageListItem>('dateOfApproval'),
      headerName: this.props.t('project_details_images.header_dateOfApproval'),
      sortingBy: SortingBy.DateOfApproval,
      renderer: this.renderDateOfApproval,
    });

    this.gColumns.push({
      field: nameOf<IImageListItem>('wasImageCorrected'),
      headerName: this.props.t('project_details_images.header_wasImageCorrected'),
      headerTooltip: this.props.t('corrected_during_review'),
      renderer: this.renderWasImageCorrected,
      sortingBy: SortingBy.WasImageCorrected,
      width: 120,
    });

    this.gColumns.push({
      field: nameOf<IImageListItem>('imageSet'),
      headerName: this.props.t('project_details_images.header_imageSet'),
      renderer: this.renderImageSet,
      sortingBy: SortingBy.ImageSet,
      cellClass: 'image-set-column',
      width: 150,
    });

    this.gColumns.push({
      field: 'workTime',
      headerName: this.props.t('project_details_images.header_workTime'),
      renderer: this.renderWorkTime,
      width: 190,
      sortingBy: SortingBy.WorkTime,
    });
  }

  onSortChanged = (orderBy: string, orderType: string) => {
    const by = this.gColumns.find(x => x.field === orderBy)?.sortingBy;
    const direction: SortingDirection | undefined = Object.values(SortingDirection).find(x => x.toLocaleLowerCase() === orderType.toLocaleLowerCase());

    if (by) {
      this.props.onOrderingChange(by, direction);
    }
  };

  getNavigationUrl = (imageId: string) =>
    Home.FreeAccess.View.withParams({ imageId, workspaceId: this.props.workspaceId, projectId: this.props.projectId, filterId: this.props.filterId });

  renderName = (image: IImageListItem) =>
    this.props.disabled ? (
      <span>{image.name}</span>
    ) : (
      <Link className="text-link-in-table text-elipsis" title={image.name} to={this.getNavigationUrl(image.id)}>
        {image.name}
      </Link>
    );

  renderDatasetName = (image: IImageListItem) => {
    const showWarning = image.datasetStatus !== DatasetStatus.PUBLISHED && image.datasetStatus !== DatasetStatus.PUBLISHEDUPLOADING;

    return (
      <div>
        {showWarning && (
          <span className="unpublished-dataset-icon">
            <FontAwesomeIcon title={this.props.t('unpublished')} icon={faExclamationTriangle} />
            &nbsp;
          </span>
        )}
        <span>{image.datasetName}</span>
      </div>
    );
  };

  renderWasImageCorrected = (image: IImageListItem) => <span>{image.wasImageCorrected ? this.props.t('yes') : this.props.t('no')}</span>;

  renderDateOfMarking = ({ dateOfMarking }: IImageListItem) => <span>{dateOfMarking && toLocaleDateString(dateOfMarking)}</span>;

  renderDateOfApproval = ({ dateOfApproval }: IImageListItem) => <span>{dateOfApproval && toLocaleDateString(dateOfApproval)}</span>;

  renderStatus = (image: IImageListItem) => {
    let textText;
    let statusClass;

    switch (image.status) {
      case ImageAnnotationStatus.TOREVIEW:
        textText = this.props.t('waiting_for_approval');
        statusClass = 'annotated';
        break;
      case ImageAnnotationStatus.ACCEPTED:
        textText = this.props.t('finished');
        statusClass = 'approved';
        break;
      case ImageAnnotationStatus.DRAFT:
        textText = this.props.t('waiting_for_submission');
        statusClass = 'not-submitted';
        break;
      case ImageAnnotationStatus.REJECTED:
        textText = this.props.t('waiting_for_correction');
        statusClass = 'rejected';
        break;
      case ImageAnnotationStatus.CLARIFICATION:
        textText = this.props.t('waiting_for_clarification');
        statusClass = 'clarification';
        break;
      default:
        textText = this.props.t('waiting_for_annotation');
        statusClass = 'not-annotated';
    }

    return <span className={`text-elipsis annotation-status ${statusClass}`}>{textText}</span>;
  };

  renderCheckbox = (item: IImageListItem) => (
    <div className="image-list-checkbox" title={item.isLocked ? this.props.t('someone_is_working') : ''} onClick={this.getOnCheckboxChange(item)}>
      {this.props.canSelectImages && (
        <div>
          <CustomInput
            checked={item.isChecked}
            onChange={this.stopPropagation}
            onClick={this.stopPropagation}
            value={item.id}
            id={item.id}
            disabled={item.isLocked}
            className={`${item.isLocked ? 'locked' : ''}`}
            type="checkbox"
          />
        </div>
      )}
    </div>
  );

  renderSelectHeader = () => (
    <div className="image-list-checkbox header" onClick={this.props.onCheckAll}>
      {this.props.canSelectImages && (
        <div>
          <CustomInput
            checked={this.props.selectionMode === 'Deselect'}
            onChange={this.stopPropagation}
            onClick={this.stopPropagation}
            value={'id-1'}
            id={'id-1'}
            type="checkbox"
          />
        </div>
      )}
    </div>
  );

  renderImageSet = (item: IImageListItem) => (
    <div>
      <Select
        id={`image-set-type-dropdown-${item.id}`}
        value={{ label: this.props.t(`image_set_options.${item.imageSet}`), value: item.imageSet }}
        onChange={handleSelectChange((value: ImageSetType) => this.props.onImageSetChange(item.id, value))}
        options={Object.values(ImageSetType).map(t => ({ label: this.props.t(`image_set_options.${t}`), value: t }))}
        classNamePrefix="custom-select"
        className="dropdown"
        isDisabled={!this.props.canUpdateImageSet}
        menuPosition={'fixed'}
      />
    </div>
  );

  renderWorkTime = (item: IImageListItem) => <>{this.removeFractionPartFromTimeSpan(item.workTime)}</>;

  stopPropagation(e: SyntheticEvent) {
    e.stopPropagation();
  }

  getOnCheckboxChange(image: any) {
    return (e: any) => {
      if (this.props.canSelectImages && !image.isLocked) {
        this.props.onCheck(image.id, e.nativeEvent.shiftKey);
      }
    };
  }

  removeFractionPartFromTimeSpan(timeSpan: string) {
    return timeSpan.includes('.') ? timeSpan.substring(0, timeSpan.lastIndexOf('.')) : timeSpan;
  }

  render() {
    const images: IImageListItem[] = this.props.detailsImages.map(i => {
      const isInList = this.props.toggledImages.some(id => id === i.id);

      return {
        ...i,
        isChecked: !i.isLocked && ((isInList && this.props.selectionMode === 'Select') || (!isInList && this.props.selectionMode === 'Deselect')),
      };
    });

    return (
      <div>
        <Loader isLoading={this.props.isLoading}>
          <GTable<IImageListItem>
            columns={this.gColumns}
            items={images}
            paginationProps={this.props.pagination}
            sortingModel={[{ orderBy: this.props.orderBy, orderType: this.props.orderType }]}
            onSortChanged={this.onSortChanged}
          />
        </Loader>
      </div>
    );
  }
}

export const ProjectDetailsImagesListTable = withNamespaces('project', { wait: true })(ProjectDetailsImagesListTablePure);
