import { IImageFilters, IImageFiltersData, SortingBy } from '../../../imageFilters.model';

import { FiltersDropdownRow } from '../FiltersDropdownRow';
import { ITranslatable } from '../../../../../../../helpers/translations.helpers';
import React from 'react';
import { SortingDirection } from '../../../../../../../models/sortingDirection.model';
import { as } from '../../../../../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface ISortingFilterInfoProps {
  availableFilters: IImageFiltersData;
  imageFilters: IImageFilters;
}

class SortingFilterInfoPure extends React.Component<ISortingFilterInfoProps & ITranslatable> {

  render() {
    const selected = this.props.imageFilters.sorting
      .slice()
      .sort((a, b) => a.order - b.order)
      .map(x => ({
        value: x.by || SortingBy.Name,
        label: `${this.props.t(`sorting_by.${x.by || SortingBy.Name}`)}
        ${this.props.t(x.direction === SortingDirection.ASC ? 'image_filters.order_type_asc' : 'image_filters.order_type_desc')}`,
      }));

    return (
      <FiltersDropdownRow label={this.props.t('image_filters.used_sorting')} count={selected.length}>
        <>{selected.map((d, i) => <div key={i}>{d.label}</div>)}</>
      </FiltersDropdownRow>);
  }
}

export const SortingFilterInfo = as<React.ComponentClass<ISortingFilterInfoProps>>(withNamespaces('project')(SortingFilterInfoPure));
