import { ImageSetType } from '../modules/projectDetails/sub/images/projectDetailsImages.model';

export interface Point2D {
  x: number;
  y: number;
}

export interface IConfusionMatrix {
  id: number;
  name: string;
  good: number;
  weak: number;
  bad: number;
}

export enum GROUND_TRUTH {
  BAD = 'Bad',
  GOOD = 'Good',
}

export interface IPredictionImage {
  id: string;
  name: string;
  imageSet: ImageSetType;
  url: string;
  prediction?: {
    confusion_matrix: IConfusionMatrix[] | null;
    recall: number | null;
    precision: number | null;
    f1: number | null;
    auc: number | null;
    count: number | null;
    precision_recall_curve: Point2D[] | null;
    score: number | null;
    ground_truth: GROUND_TRUTH | null;
  };
}
