import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../helpers/translations.helpers';
import React from 'react';
import { as } from '../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  importedItemsCount: number;
  skippedItemsCount: number;
  rejectionReasons: string[];
  isLastSummaryItem?: boolean;
  isPanelOpen: boolean;
  togglePanel(): void;
  importedMessage: string;
  skippedMessage: string;
}

class CollapsibleImportSummaryPure extends React.Component<IProps & ITranslatable> {
  render() {
    return (
      <>
        <p>
          {this.props.importedItemsCount} {this.props.importedMessage}
          {this.props.importedItemsCount > 0 && <span className="success"> {this.props.t('successfully')}</span>}
          {(!this.props.isLastSummaryItem || this.props.skippedItemsCount > 0) && <span>,</span>}
        </p>
        {this.props.skippedItemsCount > 0 && (
          <p className="collapsible" onClick={this.props.togglePanel}>
            {this.props.skippedItemsCount} {this.props.skippedMessage}
            <span className="fail"> {this.props.t('skipped')}</span> <FontAwesomeIcon icon={this.props.isPanelOpen ? faCaretUp : faCaretDown} />
          </p>
        )}
        <Collapse isOpen={this.props.isPanelOpen} className="collapsible-summary">
          <ul className="mb-0">
            {this.props.rejectionReasons?.map( (x, index) => (
              <li key={index}>{this.props.rejectionReasons[index]}</li>
            ))}
          </ul>
        </Collapse>
      </>
    );
  }
}

export const CollapsibleImportSummary = as<React.ComponentClass<IProps>>(withNamespaces('project')(CollapsibleImportSummaryPure));
