import { Home } from '../config/Home';
import { NotFoundView } from '../../views/NotFound.view';
import React from 'react';
import { Route } from 'react-router';
import { SwitchWithNotFound } from '../../containers/route.containers';
import { ModelsView } from '../../views/home/Models.view';
import { ModelDetailsCreateView, ModelDetailsView } from '../../views/home/modelDetails/modelDetails.view';
import { predictionImagesPreviewModelsView } from '../../components/predictionImages/predictionImagesPreview.view';

export const ModelsRoute = () => (
  <SwitchWithNotFound notFound={NotFoundView}>
    <Route path={Home.Models.List.Path} component={ModelsView} />
    <Route path={Home.Models.Create.Path} component={ModelDetailsCreateView} />
    <Route path={Home.Models.Details.ImageDetails.Path} component={predictionImagesPreviewModelsView} />
    <Route path={Home.Models.Details.Path} component={ModelDetailsView} />
  </SwitchWithNotFound>
);
