import { action, observable } from 'mobx';

import { LatLng } from 'leaflet';
import autobind from 'autobind-decorator';
import { injectable } from 'inversify';
import uuid from 'uuid';

export const AnnotationUiStoreType = Symbol('ANNOTATION_UI_STORE');

export interface IAnnotationUiStore {
  zoomLevel: number;
  minZoomLevel: number;
  maxZoomLevel: number;
  isImageLoading: boolean;
  isQueueRequestInProgress: boolean;
  cursorLatLng?: LatLng;
  isInValidation: boolean;
  reactLeafletRenderFingerprint: string;
  isLeftMenuOpened: boolean;
  changeZoomLevel(zoom: number): void;
  changeIsImageLoading(isLoading: boolean): void;
  setCurrentCursorPosition(latlng?: LatLng): void;
  forceReRender(): void;
  setIsLeftMenuOpened(value: boolean): void;
}

@injectable()
export class AnnotationUiStore implements IAnnotationUiStore {
  @observable
  isImageLoading: boolean = true;

  @observable
  isQueueRequestInProgress: boolean = false;

  cursorLatLng?: LatLng = undefined;

  @observable
  zoomLevel: number = 100;

  @observable
  isInValidation: boolean = false;

  minZoomLevel: number = 3;

  maxZoomLevel: number = 3000;

  @observable
  reactLeafletRenderFingerprint: string = uuid.v4();

  @observable
  isLeftMenuOpened: boolean = false;

  @action
  changeZoomLevel(zoom: number) {
    this.zoomLevel = zoom;
  }

  @action
  changeIsImageLoading(isLoading: boolean) {
    this.isImageLoading = isLoading;
  }

  @autobind
  setCurrentCursorPosition(latlng?: LatLng) {
    this.cursorLatLng = latlng;
  }

  @action
  forceReRender() {
    this.reactLeafletRenderFingerprint = uuid.v4();
  }

  @autobind
  setIsLeftMenuOpened(value: boolean) {
    this.isLeftMenuOpened = value;
  }
}
