import * as React from 'react';

import { ConfirmationModal } from './ConfirmationModal';
import { ITranslatable } from '../helpers/translations.helpers';
import { as } from '../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IAbandonDraftConfirmationModalProps {
  showAbandonModal: boolean;
  onAbandonConfirm(): void;
  onAbandonCancel(): void;
}

class AbandonDraftConfirmationModalPure extends React.Component<IAbandonDraftConfirmationModalProps & ITranslatable> {
  render() {
    return (
      <ConfirmationModal
        showModal={this.props.showAbandonModal}
        onConfirm={this.props.onAbandonConfirm}
        onCancel={this.props.onAbandonCancel}
        confirmationHeader="annotation:abandon_draft"
        confirmationQuestion="annotation:abandon_draft_confirm_message"
        confirmationYes={this.props.t('abandon')}
      />
    );
  }
}

export const AbandonDraftConfirmationModal = as<React.StatelessComponent<IAbandonDraftConfirmationModalProps>>(
  withNamespaces('annotation', { wait: true })(AbandonDraftConfirmationModalPure),
);
