import { inject, injectable } from 'inversify';
import _ from 'lodash';
import { TimePeriod } from '../../../../../design/selects/timePeriodSelector/S_TimePeriodSelector';
import { formatTimeSpan, toLocaleDateString } from '../../../../../__legacy__/helpers/date.helpers';
import { NotificationLevel, ToastNotification } from '../../../../../__legacy__/modules/notifications/models/notification.model';
import { INotificationsService, NotificationsServiceType } from '../../../../../__legacy__/modules/notifications/services/notifications.service';
import { IAnnotationToolsStats } from '../annotationTools/models/AnnotationToolsStats';
import { IProjectStatsFiltersStore, ProjectStatsFiltersStoreType } from '../filters/ProjectStatsFilters.store';
import { IGeneralStats } from '../general/models/GeneralStats';
import { IProjectStatsStore, ProjectStatsStoreType } from '../ProjectStats.store';
import { ExportProjectStatsServiceType, IExportProjectStatsRequest, IExportProjectStatsService } from './ExportProjectStats.service';
import { IGeneralStatsExportModel, IAnnotationToolsStatsExportModel, IWorkPerformanceStatsExportModel } from './models/ExportProjectStatsModels';

export const ExportProjectStatsBlType = Symbol('EXPORT_PROJECT_STATS_BL_TYPE');

export interface IExportProjectStatsBl {
  exportStatsAsync(): Promise<void>;
}

@injectable()
export class ExportProjectStatsBl implements IExportProjectStatsBl {
  constructor(
    @inject(ExportProjectStatsServiceType) private readonly exportStatsService: IExportProjectStatsService,
    @inject(NotificationsServiceType) private readonly notificationService: INotificationsService,
    @inject(ProjectStatsFiltersStoreType) private readonly filtersStore: IProjectStatsFiltersStore,
    @inject(ProjectStatsStoreType) private readonly statsStore: IProjectStatsStore,
  ) {}

  async exportStatsAsync(): Promise<void> {
    try {
      const request: IExportProjectStatsRequest = this.getExportStatsRequest();
      await this.exportStatsService.exportStatsAsync(request);
    } catch (error) {
      this.notificationService.push(new ToastNotification(NotificationLevel.ERROR, 'exporting_stats_failed'));
    }
  }

  private getExportStatsRequest(): IExportProjectStatsRequest {
    return {
      projectId: this.filtersStore.projectId,
      timePeriod: this.filtersStore.timePeriod,
      startDate: this.filtersStore.startDate,
      endDate: this.filtersStore.endDate,
      generalStats: this.getGeneralStats(),
      annotationToolsStats: this.getAnnotationToolsStats(),
      workPerformanceStats: this.getWorkPerformanceStats(),
    };
  }

  private getGeneralStats = (): IGeneralStatsExportModel[] => {
    const { visibleWorkers, timePeriod } = this.filtersStore;
    const { generalStats } = this.statsStore;

    const stats = generalStats.filter(gs => visibleWorkers.map(w => w.id).includes(gs.id));

    if (timePeriod === TimePeriod.Month) {
      return _.flatten(stats.map(s => this.mapGeneralStatsToExportModel(s)));
    }

    const date = timePeriod === TimePeriod.Day ? toLocaleDateString(this.filtersStore.startDate.toString()) : '';
    return stats
      .filter(gs => visibleWorkers.map(w => w.id).includes(gs.id))
      .map(gs => ({
        date,
        email: gs.email,
        annotated: gs.submitted,
        reviewed: gs.reviewed,
        edited: gs.edited,
        annotationWorkTime: formatTimeSpan(gs.annotationWorkTime),
        reviewWorkTime: formatTimeSpan(gs.reviewWorkTime),
        editWorkTime: formatTimeSpan(gs.editedWorkTime),
        totalWorkTime: formatTimeSpan(gs.workTime),
      }));
  };

  private getAnnotationToolsStats = (): IAnnotationToolsStatsExportModel[] => {
    const { visibleWorkers } = this.filtersStore;
    const { annotationToolsStats } = this.statsStore;

    const stats = annotationToolsStats.filter(ats => visibleWorkers.map(w => w.id).includes(ats.id));
    return _.flatten(stats.map(s => this.mapAnnotationToolStatsToExportModel(s)));
  };

  private mapGeneralStatsToExportModel = (item: IGeneralStats): IGeneralStatsExportModel[] => {
    return item.dailyGeneralStats.map(dgs => ({
      email: item.email,
      date: toLocaleDateString(dgs.day),
      annotated: dgs.submitted,
      reviewed: dgs.reviewed,
      edited: dgs.edited,
      annotationWorkTime: formatTimeSpan(dgs.annotationWorkTime),
      reviewWorkTime: formatTimeSpan(dgs.reviewWorkTime),
      editWorkTime: formatTimeSpan(dgs.editedWorkTime),
      totalWorkTime: formatTimeSpan(dgs.workTime),
    }));
  };

  private mapAnnotationToolStatsToExportModel = (item: IAnnotationToolsStats): IAnnotationToolsStatsExportModel[] => {
    return _.flatten(
      item.markingToolsStats.map(at =>
        [
          {
            email: item.email,
            annotationToolType: at.markingToolType.toString(),
            annotationToolName: at.name,
            added: at.added,
            modified: at.modified,
            deleted: at.deleted,
            annotationPoints: at.annotationPoints.toFixed(1),
            reviewPoints: at.reviewPoints.toFixed(1),
            editPoints: at.editPoints.toFixed(1),
            totalPoints: at.totalPoints.toFixed(1)
          },
        ].concat(
          at.attributesStats?.map(a => ({
            email: item.email,
            annotationToolType: a.questionType,
            annotationToolName: a.text,
            added: a.added,
            modified: a.modified,
            deleted: a.deleted,
            annotationPoints: a.annotationPoints.toFixed(1),
            reviewPoints: a.reviewPoints.toFixed(1),
            editPoints: a.editPoints.toFixed(1),
            totalPoints: a.totalPoints.toFixed(1)
          })),
        ),
      ),
    ).concat(
      item.questionsStats.map(q => ({
        email: item.email,
        annotationToolType: q.questionType,
        annotationToolName: q.text,
        added: q.added,
        modified: q.modified,
        deleted: q.deleted,
        annotationPoints: q.annotationPoints.toFixed(1),
        reviewPoints: q.reviewPoints.toFixed(1),
        editPoints: q.editPoints.toFixed(1),
        totalPoints: q.totalPoints.toFixed(1)
      })),
    );
  };

  private getWorkPerformanceStats = (): IWorkPerformanceStatsExportModel[] => {
    const { visibleWorkers } = this.filtersStore;
    const { workPerformanceStats } = this.statsStore;

    return workPerformanceStats
      .filter(wps => visibleWorkers.map(w => w.id).includes(wps.id))
      .map(wps => ({
        email: wps.email,
        totalAdditionsAndModifications: wps.totalAdditionsAndModifications,
        objectsNotYetReviewed: wps.awaitingReview,
        objectsAccepted: wps.accepted,
        accuracy: `${(wps.accuracy * 100).toFixed(2)}%`,
      }));
  };
}
