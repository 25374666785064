﻿import * as React from 'react';

import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../../../../modules/common/OverlayLoader.store';
import { IProjectDetailsPermissions, ProjectDetailsPermissionsType } from '../../../projectDetails.permissions';
import { IProjectDetailsStore, ProjectDetailsStoreType } from '../../../projectDetails.store';
import { IProjectDetailsToolsBl, IProjectDetailsToolsBlType } from '../projectDetailsTools.bl';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { AnnotationTypesList } from '../components/AnnotationTypesList';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ projectId: string }> {
  projectDetailsToolsBl: IProjectDetailsToolsBl;
  projectDetailsPermissions: IProjectDetailsPermissions;
  projectDetailsStore: IProjectDetailsStore;
  overlayLoaderStore: IOverlayLoaderStore;
}

@injectProps({
  projectDetailsToolsBl: IProjectDetailsToolsBlType,
  projectDetailsPermissions: ProjectDetailsPermissionsType,
  projectDetailsStore: ProjectDetailsStoreType,
  overlayLoaderStore: OverlayLoaderStoreType,
})
@observer
class AnnotationTypesListContainerPure extends React.Component<IInjectedProps> {
  // tslint:disable-next-line:function-name
  async UNSAFE_componentWillMount() {
    const projectId = this.props.match.params.projectId;
    await this.props.projectDetailsToolsBl.getProjectDetailsAnnotationViewAsync(projectId);
  }

  @autobind
  async handleOrderChangeAsync(annotationTypeId: string, index: number) {
    await this.props.projectDetailsToolsBl.changeAnnotationTypeOrderAsync(annotationTypeId, index);
  }

  render() {
    const usedScopes = this.props.projectDetailsToolsBl.store.questions
      .map(q => q.scopes)
      .reduce((prevValue: string[], currentValue: string[]) => prevValue.concat(currentValue), []);

    const sorted = this.props.projectDetailsToolsBl.store.annotationTypes.slice().sort((a, b) => a.order - b.order);
    const withUsage = sorted.map(at => ({ ...at, isUsedAsScope: usedScopes.includes(at.id.toUpperCase()) }));

    const canEdit = this.props.projectDetailsPermissions.canEditProject(this.props.projectDetailsStore.status);

    return (
      <AnnotationTypesList
        list={withUsage}
        onOrderChange={this.handleOrderChangeAsync}
        canEdit={canEdit}
        isLoading={this.props.overlayLoaderStore.isSpinnerVisible('getProjectDetailsAnnotation')}
      />
    );
  }
}

export const AnnotationTypesListContainer = as<React.ComponentClass>(withRouter(AnnotationTypesListContainerPure));
