import { CustomInput } from 'reactstrap';
import React from 'react';
import { S_ClampLines } from '../../misc/S_ClampLines';
import { S_ClassNameProps } from '../../CommonProps';
import classNames from 'classnames';
import { dinduNuffin } from '../../../__legacy__/helpers/function.helpers';

interface S_RadioProps extends S_ClassNameProps {
  id: string;
  checked: boolean;
  text: string;
  onClick(e: any): void;
}

export const S_Radio: React.FC<S_RadioProps> = (props: S_RadioProps) => {
  return (
    <CustomInput className={classNames('s-radio', props.className)} id={`radio-${props.id}`} type="radio" onClick={props.onClick} checked={props.checked} onChange={dinduNuffin}>
      <S_ClampLines onClick={props.onClick} text={props.text} innerElement="p" />
    </CustomInput>
  );
};
