import { IBaseNotification } from '../models/notification.model';
import { injectable } from 'inversify';
import { observable } from 'mobx';

export const NotificationsStoreType = Symbol('NOTIFICATION_STORE');

export interface INotificationsStore {
  notifications: IBaseNotification[];
}

@injectable()
export class NotificationsStore implements INotificationsStore {

  @observable
  notifications: IBaseNotification[] = [];
}
