import { ITranslatable } from '../../../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  label: string;
  overflow?: boolean;
}

class PersonalInformationRowPure extends React.Component<IProps & ITranslatable> {
  static defaultProps = {
    overflow: false,
  };

  render() {
    const overflow = this.props.overflow ? 'visible' : 'auto';
    return (
      <div className="list-row">
        <div className="left">{this.props.t(this.props.label)}</div>
        <div className={`right`} style={{ overflow }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export const PersonalInformationRow = as<React.ComponentClass<IProps>>(withNamespaces('user')(PersonalInformationRowPure));
