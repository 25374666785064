import * as React from 'react';

import { DashboardContainer } from '../../containers/DashboardContainer';
import { ProjectsHeader } from '../../modules/projects/containers/ProjectsHeader';
import ProjectsListContainer from '../../modules/projects/containers/ProjectsList.container';

export const ProjectsView = () => (
    <div>
      <DashboardContainer header={ProjectsHeader}>
        <ProjectsListContainer />
      </DashboardContainer>
    </div>
);
