import { IImportAnnotationsReport } from '../../../modules/projects/details/images/importAnnotations/models/ImportAnnotationsReport';
import { ProjectRole } from '../../models/userRole.model';

export enum ExportFileFormat {
  JSON = 'json',
  CSV = 'csv',
}

export enum ExportDataFilter {
  ALL = 'all',
  ACCEPTED = 'accepted',
  ANNOTATED = 'annotated',
  CONFIG_ONLY = 'configOnly',
}

export enum ProjectStatus {
  Published = 'Published',
  Draft = 'Draft',
}

export interface IExportModel {
  projectId: string;
  fileFormat: string;
  dataFilter: string;
}

export enum ImportStage {
  STEP1,
  STEP2,
  STEP3,
  PROCESSING,
  ERROR,
  FINISHED,
}

export enum ImportImageSetRejectionReason {
  DATASET_NOT_FOUND = 'DatasetNotFound',
  IMAGE_NOT_FOUND = 'ImageNotFound',
  IMAGE_DUPLICATED = 'ImageDuplicated',
  INVALID_SET_TYPE = 'InvalidSetType',
}

export enum ImportToolRejectionReason {
  INVALID_MARKING_TOOL_TYPE = 'InvalidMarkingToolType',
  INVALID_COLOR_FORMAT = 'InvalidColorFormat',
  INVALID_MARKING_TOOL_NAME = 'InvalidMarkingToolName',
  DUPLICATED_MARKING_TOOL_NAME = 'DuplicatedMarkingToolName',
}

export enum ImportQuestionRejectionReason {
  INVALID_ANSWER_TYPE = 'InvalidAnswerType',
  INVALID_SCOPE = 'InvalidScope',
  DUPLICATED_TEXT = 'DuplicatedText',
  EMPTY_SCOPE_LIST = 'EmptyScopeList',
}

export interface IProjectImportReport extends IImportAnnotationsReport {
  projectId: string;
  importedQuestionsCount: number;
  skippedQuestionsCount: number;
  rejectedQuestions: IImportQuestionRejection[];
  importedToolsCount: number;
  skippedToolsCount: number;
  rejectedTools: IImportToolRejection[];
  importedAttributesCount: number;
  skippedAttributesCount: number;
  rejectedAttributes: IImportQuestionRejection[];
  importedImageSetsCount: number;
  skippedImageSetsCount: number;
  rejectedImageSets: IImportImageSetRejection[];
  assignedDatasetNames: string[];
  rejectedDatasetNames: string[];
}

export interface IImportImageSetRejection {
  datasetName: string;
  imageName: string;
  reason: ImportImageSetRejectionReason;
}

export interface IImportToolRejection {
  toolName: string;
  reason: ImportToolRejectionReason;
}

export interface IImportQuestionRejection {
  text: string;
  hasImageScope: boolean;
  reason: ImportQuestionRejectionReason;
}

export interface IProjectListDto {
  id: string;
  name: string;
  description: string;
  modifiedDate: string;
  status: ProjectStatus;
  imagesCount: number;
  awaitingCount: number;
  annotatedCount: number;
  reviewedCount: number;
  myRejectedCount: number;
  progress: number;
  usersCount: number;
  role: ProjectRole;
  isOwner: string;
  canBePublished: boolean;
  unansweredClarificationsCount: number;
  unansweredClarificationImageId: string;
}
