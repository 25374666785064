import './HowToResolveConflictsStepModal.scss';

import { WithNamespaces, withNamespaces } from 'react-i18next';

import React from 'react';
import { ResolveConflictsStrategy } from '../../../models/ResolveConflictsStrategy';
import { S_Radio } from '../../../../../../../../design/inputs/radio/S_Radio';
import { S_TwoButtonModal } from '../../../../../../../../design/modals/twoButtonModal/S_TwoButtonModal';

interface Props {
  show: boolean;
  conflictsCount: number;
  resolveConflictsStrategy: ResolveConflictsStrategy;
  onChangeStrategy(strategy: ResolveConflictsStrategy): void;
  onHide(): void;
  onSubmit(): void;
}

const HowToResolveConflictsStepModalPure: React.FC<Props & WithNamespaces> = (props: Props & WithNamespaces) => {
  const { t, show, resolveConflictsStrategy, onChangeStrategy, onHide, onSubmit } = props;
  const selectKeepAllStrategy = () => onChangeStrategy(ResolveConflictsStrategy.KeepAll);
  const selectReplaceAllStrategy = () => onChangeStrategy(ResolveConflictsStrategy.ReplaceAll);
  const selectManuallyStrategy = () => onChangeStrategy(ResolveConflictsStrategy.Manually);

  return (
    <S_TwoButtonModal
      cancelText={t('new.projects.details.import_annotations.cancel')}
      headerText={`${t('new.projects.details.import_annotations.header')} - ${t('new.projects.details.import_annotations.steps.how_to_resolve_conflicts.header')}`}
      submitText={t('new.projects.details.import_annotations.next')}
      onCancel={onHide}
      onHide={onHide}
      onSubmit={onSubmit}
      show={show}
      className="resolve-conflicts-modal"
    >
      <p>
        {props.conflictsCount}{' '}
        {t('new.projects.details.import_annotations.steps.how_to_resolve_conflicts.conflicted_annotations_found', {count: props.conflictsCount})}
      </p>
      <p>{t('new.projects.details.import_annotations.steps.how_to_resolve_conflicts.what_would_you_like_to_do')}</p>
      <S_Radio
        id="option-keep"
        className="form-check"
        checked={resolveConflictsStrategy === ResolveConflictsStrategy.KeepAll}
        text={t('new.projects.details.import_annotations.steps.how_to_resolve_conflicts.skip_annotations')}
        onClick={selectKeepAllStrategy}
      />
      <S_Radio
        id="option-replace"
        className="form-check"
        checked={resolveConflictsStrategy === ResolveConflictsStrategy.ReplaceAll}
        text={t('new.projects.details.import_annotations.steps.how_to_resolve_conflicts.replace_existing_annotations')}
        onClick={selectReplaceAllStrategy}
      />
      <S_Radio
        id="option-manually"
        className="form-check"
        checked={resolveConflictsStrategy === ResolveConflictsStrategy.Manually}
        text={t('new.projects.details.import_annotations.steps.how_to_resolve_conflicts.let_me_decide')}
        onClick={selectManuallyStrategy}
      />
    </S_TwoButtonModal>
  );
};

export const HowToResolveConflictsStepModal = withNamespaces('common')(HowToResolveConflictsStepModalPure);
