import { injectable, inject } from 'inversify';
import { ApiServiceType } from '../../services/api.service';
import { StickerError } from '../../models/error.model';
import { IApiService } from '../../services/api.service.base';
import { FreeAccessMode } from './freeAccess.store';
import { IImage } from '../annotation/annotations.interface';

export const FreeAccessApiServiceType = Symbol('FREE_ACCESS_API_SERVICE');

export interface IFreeAccessPaging {
  wasChanged: boolean;
  checkDate: string;
  items: IFreeAccessPagingItem[];
}

export interface IFreeAccessPagingItem {
  imageId: string;
  index: number;
  size: number;
}

export interface IGetImagesPagingPayload {
  projectId: string;
  orderBy: string;
  orderType: string;
  lastUpdate?: string;
  filterId: string;
}

export interface IFreeAccessApiService {
  getImageLockInfo(projectId: string, imageId: string): Promise<string | StickerError>;
  getImageInfoToFreeAccess(projectId: string, imageId: string): Promise<IImage | StickerError>;
  getFreeAccessPaging(payload: IGetImagesPagingPayload): Promise<IFreeAccessPaging | StickerError>;
  reLockImageAsync(imageId: string, projectId: string, mode: FreeAccessMode): Promise<void | StickerError>;
  lockImageAsync(imageId: string, projectId: string, mode: FreeAccessMode): Promise<void | StickerError>;
}

@injectable()
export class FreeAccessApiService implements IFreeAccessApiService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService) { }

  getImageLockInfo = (projectId: string, imageId: string): Promise<string | StickerError> =>
    this.apiService.getAsync(`/images/GetImageLockInfo?projectId=${projectId}&imageId=${imageId}`);

  getImageInfoToFreeAccess = (projectId: string, imageId: string): Promise<IImage | StickerError> =>
    this.apiService.getAsync(`/Images/GetImageToFreeAccess?projectId=${projectId}&imageId=${imageId}`);

  getFreeAccessPaging = (payload: IGetImagesPagingPayload): Promise<IFreeAccessPaging | StickerError> =>
    this.apiService.postAsync<IGetImagesPagingPayload, IFreeAccessPaging>('/Projects/FreeAccessPaging', payload);

  reLockImageAsync = (imageId: string, projectId: string, mode: FreeAccessMode): Promise<void | StickerError> =>
    this.apiService.postAsync('/images/ReLockImageForFreeAccess', { imageId, projectId, mode });

  lockImageAsync = (imageId: string, projectId: string, mode: FreeAccessMode): Promise<void | StickerError> =>
    this.apiService.postAsync('/images/LockImageForFreeAccess', { imageId, projectId, mode });
}
