import { ImportAnnotationsBl, ImportAnnotationsBlType } from '../../../importAnnotations.bl';
import { ImportAnnotationsStore, ImportAnnotationsStoreType } from '../../../importAnnotations.store';

import { ImportAnnotationsStep } from '../../../models/ImportAnnotationsSteps';
import { ProcessingStepModal } from './ProcessingStepModal';
import React from 'react';
import { as } from '../../../../../../../../common/dependencyInjection/as';
import { injectProps } from '../../../../../../../../__legacy__/helpers/react.helpers';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: ImportAnnotationsBl;
  store: ImportAnnotationsStore;
}

@injectProps({
  bl: ImportAnnotationsBlType,
  store: ImportAnnotationsStoreType,
})
@observer
class ProcessingStepContainerPure extends React.Component<InjectedProps> {

  get isCurrentStep() {
    return this.props.store.currentStep === ImportAnnotationsStep.Processing;
  }

  render() {
    return <ProcessingStepModal show={this.isCurrentStep} />;
  }
}

export const ProcessingStepContainer = as<React.ComponentClass>(ProcessingStepContainerPure);
