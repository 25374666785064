import React from 'react';
import { observer } from 'mobx-react';
import { IModelDetailsService, ModelDetailsServiceType } from '../modelDetails.service';
import { as, injectProps } from '../../../__legacy__/helpers/react.helpers';
import { formAlertWithTranslations } from '../../../__legacy__/components/FormAlert';

interface IInjectedProps {
  modelDetailsService: IModelDetailsService;
}

const Errors = formAlertWithTranslations('models');

@injectProps({
  modelDetailsService: ModelDetailsServiceType,
})
@observer
class GlobalValidationErrorsContainerPure extends React.Component<IInjectedProps> {
  render() {
    const {
      getTabsValidationErrors,
      store: { modelDetailsValidationErrors },
    } = this.props.modelDetailsService;
    const errors = getTabsValidationErrors();

    return <>{modelDetailsValidationErrors.showAll && Array.from(errors.entries()).map(([tab, error]) => <Errors key={tab} errors={error} />)}</>;
  }
}

export const GlobalValidationErrorsContainer = as<React.ComponentClass>(GlobalValidationErrorsContainerPure);
