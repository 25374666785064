import { IUpdateWorkspaceTimeZoneBl, UpdateWorkspaceTimeZoneBlType } from '../UpdateWorkspaceTimeZone.bl';
import { IUpdateWorkspaceTimeZoneStore, UpdateWorkspaceTimeZoneStoreType } from '../UpdateWorkspaceTimeZone.store';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import React from 'react';
import { UpdateWorkspaceTimeZoneForm } from '../components/UpdateWorkspaceTimeZoneForm';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps {
  store: IUpdateWorkspaceTimeZoneStore;
  bl: IUpdateWorkspaceTimeZoneBl;
}

@injectProps({
  store: UpdateWorkspaceTimeZoneStoreType,
  bl: UpdateWorkspaceTimeZoneBlType,
})
@observer
class UpdateWorkspaceTimeZoneFormContainerPure extends React.Component<IInjectedProps> {
  @autobind
  private handleTimeZoneChanged(timeZone: number) {
    this.props.bl.selectTimeZone(timeZone);
  }

  render() {
    const { store } = this.props;
    return <UpdateWorkspaceTimeZoneForm onTimeZoneChanged={this.handleTimeZoneChanged} selectedTimeZone={store.selectedTimeZoneOffset} />;
  }
}

export const UpdateWorkspaceTimeZoneFormContainer = as<React.ComponentClass>(UpdateWorkspaceTimeZoneFormContainerPure);
