import { LatLng, Map } from 'leaflet';

import { AnnotationToolType } from '../submodules/annotationTypes/models/annotationToolType';
import { DrawBrush } from './DrawControl.models/Brush/Draw.Brush';
import { DrawPoint } from './DrawControl.models/Draw.point';
import { DrawPolyLine } from './DrawControl.models/Draw.polyline';
import { DrawPolygon } from './DrawControl.models/Draw.polygon';
import { DrawRectangle } from './DrawControl.models/Draw.rectangle';
import { DrawRotatedRectangle } from './DrawControl.models/Draw.rotatedRectangle';
import { DrawVector } from './DrawControl.models/Draw.vector';
import { Feature } from 'geojson';
import { IAnnotationService } from '../annotation.service';
import { IAnnotationTypeOptions } from '../submodules/annotationTypes/models/annotationTypeOptions';
import { IBrushTypeOptions } from '../annotations.interface';
import { IFreeDrawSegmentationService } from '../freeDrawSegmentation.service';
import { IUndoRedoHistory } from '../undoRedoHistory.service';

export class DrawerFactory {
  public static createDrawer(
    map: Map,
    annotationToolType: AnnotationToolType,
    color: string,
    fillOpacity: number,
    activeAnnotationTypeId: string | undefined,
    cursorPosition: LatLng | undefined,
    onDrawCreated: (geojson: Feature) => void,
    onDrawStarted: (geojson: Feature) => void,
    undoRedoHistory: IUndoRedoHistory,
    annotationService: IAnnotationService,
    freeDrawSegmentationService: IFreeDrawSegmentationService,
    options: IAnnotationTypeOptions | undefined,
    feature?: Feature,
  ): IDrawer | null {
    switch (annotationToolType) {
      case AnnotationToolType.POLYGON:
        return new DrawPolygon(map, onDrawCreated, undoRedoHistory, { shapeOptions: { color, fillOpacity, opacity: 1 }, drawError: false } as any);
      case AnnotationToolType.ROTATEDRECTANGLE:
        return new DrawRotatedRectangle(map, onDrawCreated, undoRedoHistory, { shapeOptions: { color, fillOpacity, opacity: 1 }, drawError: false } as any);
      case AnnotationToolType.RECTANGLE:
        return new DrawRectangle(map, onDrawCreated, undoRedoHistory, { shapeOptions: { color, fillOpacity, opacity: 1 }, drawError: false, showArea: false } as any);

      case AnnotationToolType.POINT:
        return new DrawPoint(map, onDrawCreated, { color, opacity: 1, fill: false } as any);
      case AnnotationToolType.VECTOR:
        return new DrawVector(map, onDrawCreated, undoRedoHistory, { shapeOptions: { color, opacity: 1 }, drawError: false } as any);
      case AnnotationToolType.POLYLINE:
        return new DrawPolyLine(map, onDrawCreated, undoRedoHistory, { shapeOptions: { color, opacity: 1 }, drawError: false } as any);
      case AnnotationToolType.BRUSH:
        const brushOptions = options as IBrushTypeOptions;
        return new DrawBrush(
          map,
          cursorPosition,
          activeAnnotationTypeId,
          undoRedoHistory,
          annotationService,
          freeDrawSegmentationService,
          { color, fillOpacity, opacity: 1, data: feature, diameter: brushOptions.brushDiameter, mode: brushOptions.brushMode },
          onDrawCreated,
          onDrawStarted,
          brushOptions.onDiameterChange,
        );
      default:
        return null;
    }
  }
}
