import { StickerEvent } from '../../../../../services/eventBus.service';

export const WorkspaceTimeZoneUpdatedEventType = Symbol('WORKSPACE_TIMEZONE_UPDATED_EVENT');

export class WorkspaceTimeZoneUpdatedEvent extends StickerEvent {
  constructor(public readonly workspaceId: string, public readonly timezone: number) {
    super();
  }

  get type(): Symbol {
    return WorkspaceTimeZoneUpdatedEventType;
  }
}
