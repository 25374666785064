import './S_FieldError.scss';

import React from 'react';

interface S_FieldErrorProps {
  feedbacks: string[];
}

export const S_FieldError: React.FC<S_FieldErrorProps> = (props: S_FieldErrorProps) => {
  const renderFeedback = (feedback: string, index: number): React.ReactNode => (
    <li className="error-list-item" key={index}>
      {feedback}
    </li>
  );

  return (
    <div className="form-feedback">
      <ul>{props.feedbacks.map(renderFeedback)}</ul>
    </div>
  );
};
