import * as React from 'react';

import { ExternalLinks } from '../../../routes/config/ExternalLinks';
import { ZebraWhiteLogo } from '../../../components/StaticImages';

export const AuthLogo = () => {
  return (
    <div className="auth-logo">
      <a href={ExternalLinks.ZillinSitePath} target="_blank" rel="noopener noreferrer">
        <div className="logo">
          <div className="image">
            <ZebraWhiteLogo />
          </div>
          <div className="text">
            <h1>Aurora Cloud</h1>
          </div>
        </div>
      </a>
    </div>
  );
};
