import * as React from 'react';

import { AnnotationUiStoreType, IAnnotationUiStore } from '../../annotation/annotationUi.store';
import { Col, Container, Row } from 'reactstrap';
import { IUserStore, UserStoreType } from '../../user/user.store';
import { as, injectProps } from '../../../helpers/react.helpers';

import { DatasetDetails } from '../datasetDetails.context';
import { DrawingContainer } from '../../annotation/containers/Drawing.container';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { Image } from '../../annotation/components/Image';
import { ImageSegmentations } from '../../annotation/submodules/segmentations/segmentationContextMenu/imageSegmentations.context';
import { LeftMenuContainer } from '../../annotation/submodules/segmentations/containers/LeftMenu.container';
import { Observer } from 'mobx-react';
import { SegmentationsLayer } from '../../annotation/components/SegmentationsLayer';
import { VerticalMenuItem } from '../../annotation/submodules/segmentations/components/verticalMenu/VerticalMenu';
import autobind from 'autobind-decorator';
import { constant } from 'lodash/fp';
import { dinduNuffin } from '../../../helpers/function.helpers';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  annotationUiStore: IAnnotationUiStore;
  userStore: IUserStore;
}

interface IState {
  currentZoom: number;
}

export interface IDatasetDetailsPreviewProps {
  imageId: string;
  isLoading: boolean;
  url: string;
  lowQualityUrl: string;
  width: number;
  height: number;
}

type IProps = IDatasetDetailsPreviewProps & ITranslatable & IInjectedProps;

@injectProps({ annotationUiStore: AnnotationUiStoreType, userStore: UserStoreType })
class DatasetDetailsImagePreviewContainerPure extends React.Component<IProps, IState> {
  static contextType = DatasetDetails;
  declare context: React.ContextType<typeof DatasetDetails>;

  constructor(props: IProps) {
    super(props);

    this.state = {
      currentZoom: 100,
    };
  }

  @autobind
  handleLowResImageLoaded() {
    this.context.store.isPreviewImageLoading = false;
  }

  @autobind
  handleZoomChanged(zoom: number) {
    this.setState({ currentZoom: zoom });
  }

  render() {
    const { url, width, height, isLoading, lowQualityUrl } = this.props;

    return (
      <Container fluid className="dataset-image-preview">
        <Row>
          <LeftMenuContainer defaultTabOpened={VerticalMenuItem.ANNOTATED_IN_PROJECTS} isReadOnly={true} showObjectsList={false} isReview={false} />
          <Col className="px-0">
            <Image
              isLeftMenuOpened={this.props.annotationUiStore.isLeftMenuOpened}
              isLoading={isLoading}
              isHighResImageLoading={url === ''}
              url={url || ''}
              lowQualityUrl={lowQualityUrl || ''}
              height={height || 0}
              width={width || 0}
              zoomLevel={this.state.currentZoom}
              minZoomLevel={this.props.annotationUiStore.minZoomLevel}
              maxZoomLevel={this.props.annotationUiStore.maxZoomLevel}
              onZoomChanged={this.handleZoomChanged}
              onLowResImageLoaded={this.handleLowResImageLoaded}
            >
              <ImageSegmentations.Consumer>
                {ctx => (
                  <Observer>
                    {() =>
                      ctx.store.selectedProjects.map(projectId => (
                        <SegmentationsLayer
                          key={projectId}
                          readonly={true}
                          editable={false}
                          segmentations={ctx.store.segmentations[projectId] || []}
                          freeDrawInProgress={false}
                          areAttributesShown={true}
                          opacityLevel={this.props.userStore.opacityLevel}
                          zoomLevel={this.state.currentZoom}
                          annotationTypesOptions={[]}
                          hiddenSegmentations={[]}
                          onSegmentationSelected={dinduNuffin}
                          onSegmentationDeselected={dinduNuffin}
                          onSegmentationTabed={dinduNuffin}
                          onSegmentationOver={dinduNuffin}
                          onSegmentationOut={dinduNuffin}
                          onNextSegmentationSelected={constant(undefined)}
                          onPreviousSegmentationSelected={constant(undefined)}
                          checkPendingChanges={constant(false)}
                          reactLeafletRenderFingerprint={this.props.annotationUiStore.reactLeafletRenderFingerprint}
                        />
                      ))
                    }
                  </Observer>
                )}
              </ImageSegmentations.Consumer>
              <DrawingContainer disableCursorGuides />
            </Image>
          </Col>
        </Row>
      </Container>
    );
  }
}

export const DatasetDetailsImagePreviewContainer = as<React.ComponentClass<IDatasetDetailsPreviewProps>>(
  withNamespaces('datasets', { wait: true })(DatasetDetailsImagePreviewContainerPure),
);
