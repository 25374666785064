import { injectable, inject } from 'inversify';
import { StickerError } from '../../../models/error.model';
import { ApiServiceType } from '../../../services/api.service';
import { IApiService } from '../../../services/api.service.base';

export const TeamApiServiceType = Symbol('TEAM_API_SERVICE_TYPE');

export interface IAddUserToWorkspaceRequest {
  workspaceId: string;
  userEmail: string;
  role: string;
}

export interface IChangeMemberRoleRequest {
  workspaceId: string;
  userEmail: string;
  role: string;
}

export interface IRemoveUserFromWorkspaceRequest {
  workspaceId: string;
  userEmail: string;
}

export interface ITeamApiService {
  addUserToWorkspaceAsync(request: IAddUserToWorkspaceRequest): Promise<void | StickerError>;
  changeUserRoleAsync(request: IChangeMemberRoleRequest): Promise<void | StickerError>;
  removeUserFromWorkspaceAsync(request: IRemoveUserFromWorkspaceRequest): Promise<void | StickerError>;
}

@injectable()
export class TeamApiService implements ITeamApiService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService) {}

  async addUserToWorkspaceAsync(request: IAddUserToWorkspaceRequest): Promise<void | StickerError> {
    return await this.apiService.postAsync<IAddUserToWorkspaceRequest, void>('/Workspace/addUser', request);
  }

  async changeUserRoleAsync(request: IChangeMemberRoleRequest): Promise<void | StickerError> {
    return this.apiService.postAsync<IChangeMemberRoleRequest, void>('/Workspace/changeUserRole', request);
  }

  async removeUserFromWorkspaceAsync(request: IRemoveUserFromWorkspaceRequest): Promise<void | StickerError> {
    return await this.apiService.postAsync<IRemoveUserFromWorkspaceRequest, void>('/Workspace/removeUser', request);
  }
}
