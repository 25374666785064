import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../__legacy__/helpers/react.helpers';
import { observer } from 'mobx-react';
import { ValueType } from 'react-select/lib/types';
import { EvaluationDetailsHeader } from '../components/EvaluationDetailsHeader';
import { ModelVariantOption, SelectOption } from '../evaluationDetails.model';
import { EvaluationDetailsServiceType, IEvaluationDetailsService } from '../evaluationDetails.service';
import { WithLoaderComponentBase } from '../../../__legacy__/helpers/loader.helpers';
import { Loader } from '../../../__legacy__/components/Loader';
import { EVALUATION_STATUS } from '../../evaluations/evaluations.model';
import { withPolling } from '../../../__legacy__/helpers/polling.helpers';
import { IWorkspaceService, WorkspaceServiceType } from '../../../__legacy__/modules/workspaces/workspaces.service';
import { EvaluationsServiceType, IEvaluationsService } from '../../evaluations/evaluations.service';
import qs from 'qs';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../common/OverlayLoader.store';
import { observe } from 'mobx';

interface IInjectedProps extends RouteComponentProps<{ jobId?: string }> {
  evaluationsService: IEvaluationsService;
  evaluationDetailsService: IEvaluationDetailsService;
  workspaceService: IWorkspaceService;
  overlayLoader: IOverlayLoaderStore;
}

@injectProps({
  evaluationsService: EvaluationsServiceType,
  evaluationDetailsService: EvaluationDetailsServiceType,
  workspaceService: WorkspaceServiceType,
  overlayLoader: OverlayLoaderStoreType,
})
@observer
class EvaluationDetailsHeaderContainerPure extends WithLoaderComponentBase<IInjectedProps> {
  clearPolling?: () => void;
  disposeStatusObserver: () => void;

  shouldRefresh(status: EVALUATION_STATUS) {
    return status === EVALUATION_STATUS.STARTING || status === EVALUATION_STATUS.RUNNING || status === EVALUATION_STATUS.STOPPING || status === EVALUATION_STATUS.FAILING;
  }

  isCreate() {
    return this.props.match.params.jobId === undefined;
  }

  constructor(props: IInjectedProps) {
    super(props);
    this.props.evaluationDetailsService.store.resetStore(this.isCreate());
    this.disposeStatusObserver = observe(this.props.evaluationDetailsService.store, 'status', ({ oldValue, newValue }) => {
      if (oldValue !== newValue && oldValue !== EVALUATION_STATUS.UNKNOWN && newValue === EVALUATION_STATUS.FAILED) {
        this.props.evaluationDetailsService.getEvaluationDetailsAsync(this.props.match.params.jobId!);
      }
    });

    this.state = {
      isLoading: false,
    };
  }

  async componentDidMount() {
    const isCreate = this.isCreate();
    await this.withLoaderAsync(async () => {
      const promises = [];

      if (!isCreate) {
        promises.push(this.props.evaluationDetailsService.getEvaluationDetailsAsync(this.props.match.params.jobId!));
      } else {
        promises.push(
          this.props.evaluationDetailsService.getEvaluationProjectsAsync(),
          this.props.evaluationDetailsService.getEvaluationModelsAsync(),
          this.props.evaluationDetailsService.getEvaluationSettingsSchemeAsync(isCreate),
        );
      }

      await Promise.all(promises);
    });

    if (!isCreate) {
      return;
    }

    const parsedSearch = qs.parse(this.props.location.search.slice(1));
    const modelId = parsedSearch.training_job_id;

    if (modelId) {
      this.props.evaluationDetailsService.handleChangeModel(modelId);
      // Also blur to trigger validation
      this.props.evaluationDetailsService.handleBlurModel(modelId);
    }
  }

  async componentDidUpdate() {
    const status = this.props.evaluationDetailsService.store.status;
    // Initialize the retrieval of model information at the top of the tree so that other components can update accordingly.
    if (this.shouldRefresh(status) && this.clearPolling === undefined && !this.isCreate()) {
      this.clearPolling = withPolling(async () => {
        await this.props.evaluationDetailsService.getEvaluationStatusAsync(this.props.match.params.jobId!);
      }, 10000);
    } else if (!this.shouldRefresh(status) && this.clearPolling !== undefined) {
      this.clearPolling();
      this.clearPolling = undefined;
    }
  }

  componentWillUnmount(): void {
    if (this.clearPolling !== undefined) this.clearPolling();
    this.disposeStatusObserver();
  }

  handleChangeProject = (value: ValueType<SelectOption>) => {
    this.props.evaluationDetailsService.handleChangeProject((value as SelectOption).value);
  };

  handleChangeModel = (value: ValueType<SelectOption>) => {
    this.props.evaluationDetailsService.handleChangeModel((value as SelectOption).value);
  };

  handleChangeModelVariant = (value: ValueType<SelectOption>) => {
    this.props.evaluationDetailsService.handleChangeModelVariant((value as ModelVariantOption).value);
  };

  render() {
    const {
      store,
      handleChangeName,
      handleChangeDescription,
      downloadModelAsync,
      startEvaluationAsync,
      stopEvaluationAsync,
      handleBlurName,
      handleBlurProject,
      handleBlurModel,
      handleBlurDescription,
      handleBlurModelVariant,
    } = this.props.evaluationDetailsService;

    const {
      name,
      description,
      models,
      modelId,
      projectId,
      modelVariant,
      projects,
      status,
      evaluationDetailsValidationErrors,
      createdAt,
      isTrainingOwner,
      isOwner,
      startingProgress,
      failureReason,
    } = store;
    const workspaceRole = this.props.workspaceService.getUserRoleInCurrentWorkspace();
    return (
      <Loader isLoading={this.state.isLoading || !!this.props.overlayLoader.isSpinnerVisible('evaluation-header')} class="evaluation-header">
        <EvaluationDetailsHeader
          isCreate={this.isCreate()}
          isOwner={isOwner}
          isTrainingOwner={isTrainingOwner}
          workspaceRole={workspaceRole}
          name={name}
          description={description}
          projectId={projectId}
          status={status}
          startingProgress={startingProgress}
          failureReason={failureReason}
          modelVariant={modelVariant}
          createdAt={createdAt}
          projects={projects}
          models={models}
          modelId={modelId}
          onChangeName={handleChangeName}
          onChangeDescription={handleChangeDescription}
          onChangeProject={this.handleChangeProject}
          onChangeModel={this.handleChangeModel}
          startEvaluationAsync={startEvaluationAsync}
          stopEvaluationAsync={stopEvaluationAsync}
          handleBlurName={handleBlurName}
          handleBlurProject={handleBlurProject}
          handleBlurModel={handleBlurModel}
          downloadModelAsync={downloadModelAsync}
          errors={evaluationDetailsValidationErrors}
          handleBlurDescription={handleBlurDescription}
          handleBlurModelVariant={handleBlurModelVariant}
          handleChangeModelVariant={this.handleChangeModelVariant}
        />
      </Loader>
    );
  }
}

export const EvaluationDetailsHeaderContainer = as<React.ComponentClass>(withRouter(EvaluationDetailsHeaderContainerPure));
