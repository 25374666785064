import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router';

import { Auth } from '../../../routes/config/Auth';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { TabsContainer } from '../../../containers/TabsContainer';
import { TabsSingleItem } from '../../../components/TabsSingleItem';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export const AuthFormTabs = as<React.StatelessComponent>(
  withRouter(withNamespaces('auth')(
    (props: RouteComponentProps & ITranslatable) => {
      const query = props.location.search !== '' ? `/${props.location.search}` : '';
      return (
        <TabsContainer variant="on-auth-view">
          <TabsSingleItem
            text={props.t('login')}
            link={`${Auth.Login.Path}${query}`}
            state={props.location.pathname.includes(Auth.Login.Path) ? 'active' : 'passive'}
          />
          <TabsSingleItem
            text={props.t('register')}
            link={`${Auth.Register.Path}${query}`}
            state={props.location.pathname.includes(Auth.Register.Path) ? 'active' : 'passive'}
          />
        </TabsContainer>
      );
    }),
  ),
);
