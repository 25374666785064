import { Home } from '../config/Home';
import { NotFoundView } from '../../views/NotFound.view';
import React from 'react';
import { Route } from 'react-router';
import { SwitchWithNotFound } from '../../containers/route.containers';
import { UsersView } from '../../views/home/admin/Users.view';

export const AdminRoute = () => (
  <SwitchWithNotFound notFound={NotFoundView}>
    <Route path={Home.Admin.Users.Path} component={UsersView} />
  </SwitchWithNotFound>
);
