import { FixedSizeGrid } from 'react-window';
import React from 'react';

interface IProps<T> {
  items: T[];
  itemHeight: number;
  crossAxesCount: number;
  itemBuilder(element: T): JSX.Element;
}

export class StickerVirtualGrid<T> extends React.Component<IProps<T>> {
  render() {
    const { crossAxesCount, items, itemHeight, itemBuilder } = this.props;
    const rowCount = Math.ceil(items.length / crossAxesCount);
    const height = Math.max(Math.min(this.props.itemHeight * rowCount, 600), 250);
    const width = 500;
    const itemWidth = (width - 20) / crossAxesCount;
    return (
      <FixedSizeGrid
        height={height}
        width={width}
        columnWidth={itemWidth}
        rowHeight={itemHeight}
        columnCount={crossAxesCount}
        rowCount={rowCount}
      >
        {({ columnIndex, rowIndex, style }) => {
          const index = rowIndex * crossAxesCount + columnIndex;
          if (index < items.length) {
            return (
              <div style={style}>
                {itemBuilder(items[index])}
              </div>
            );
          }
          return <div />;
        }}
      </FixedSizeGrid>
    );
  }
}
