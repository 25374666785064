import * as React from 'react';

import { Button, Card, CardBody, CardTitle, CustomInput, FormGroup, NavLink } from 'reactstrap';

import { CountryRowContainer } from '../../containers/personalInformations/rows/CountryRow.container';
import DeleteAccountModal from '../DeleteAccountModal';
import { ExternalLinks } from '../../../../routes/config/ExternalLinks';
import { ITranslatable } from '../../../../helpers/translations.helpers';
import { InputStatus } from '../../../../models/error.model';
import { PasswordWithScoreInputsContainer } from '../../../auth/containers/PasswordWithScoreInputsContainer';
import ResetPasswordModal from '../ResetPasswordModal';
import { ValidatedTextInput } from '../../../../components/ValidatedTextInput';
import { as } from '../../../../helpers/react.helpers';
import { handleChange } from '../../../../helpers/formHelpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';
import { ChangeEmailModal } from '../ChangeEmailModal';

export interface IPersonalInformationWidgetProps {
  fullName: string;
  company: string;
  email: string;
  showPasswordModal: boolean;
  passwordFormIsValid: boolean;
  oldPasswordStatus: InputStatus;
  userLinkExtension: string;
  newPasswordControlRef: React.RefObject<PasswordWithScoreInputsContainer>;
  showNewsletter: boolean;
  showTCAndPP: boolean;
  onFullNameChange(value: string): void;
  onCompanyChange(value: string): void;
  onFullNameBlur(value: string): void;
  onCompanyBlur(value: string): void;
  onShowChangePasswordModal(): void;
  onHideChangePasswordModal(): void;
  onShowChangeEmailModal(): void;
  onHideChangeEmailModal(): void;
  onOldPasswordChange(password: string): void;
  onNewPasswordChange(password: string): void;
  onOldPasswordBlur(): void;
  onNewPasswordStatusChange(inputStatus: InputStatus): void;
  onResetPassword(): void;
  showDeleteAccountModal: boolean;
  onShowDeleteAccountModal(): void;
  onHideDeleteAccountModal(): void;
  onDeleteAccount(): void;
  isDeleteInProgress: boolean;
  isNewsletterConsentChecked: boolean;
  onNewsletterConsentChange(): void;
  showChangeEmailModal: boolean;
  onPasswordChange(password: string): void;
  onPasswordBlur(): void;
  passwordStatus: InputStatus;
  emailStatus: InputStatus;
  onEmailChange(email: string): void;
  onEmailBlur(): void;
  onChangeEmailConfirmation(): void;
  emailChangePending: boolean;
}

type IProps = IPersonalInformationWidgetProps & ITranslatable;

const PersonalInformationWidget = (props: IProps) => {
  return (
    <Card className="flex-fill card-with-list">
      <CardBody>
        <CardTitle tag="h5" className="name">
          {props.t('personal_information')}
        </CardTitle>
        <div className="list-row first">
          <div className="left">{props.t('full_name')}</div>
          <div className="right">
            <ValidatedTextInput
              id="account-full-name"
              type={'text'}
              maxLength={255}
              onBlur={props.onFullNameBlur}
              onChange={props.onFullNameChange}
              styleClassName={'text-right'}
              placeholder={props.t('full_name')}
              value={props.fullName || ''}
            />
          </div>
        </div>
        <div className="list-row">
          <div className="left">{props.t('organization')}</div>
          <div className="right">
            <ValidatedTextInput
              id="account-company"
              type={'text'}
              maxLength={255}
              onBlur={props.onCompanyBlur}
              onChange={props.onCompanyChange}
              styleClassName={'text-right'}
              placeholder={props.t('organization')}
              value={props.company || ''}
            />
          </div>
        </div>
        <CountryRowContainer />
        <div className="list-row">
          <div className="left">{props.t('email_address')}</div>
          <div id="account-email" className="right">
            {props.email} <i>{props.emailChangePending && props.t('email_change_pending')}</i>
            <Button className="ml-2" color="primary" onClick={props.onShowChangeEmailModal}>
              {props.t('change_email')}
            </Button>
            <ChangeEmailModal
                show={props.showChangeEmailModal}
                passwordStatus={props.passwordStatus}
                emailStatus={props.emailStatus}
                onConfirm={props.onChangeEmailConfirmation}
                onCancel={props.onHideChangeEmailModal}
                onPasswordChange={props.onPasswordChange}
                onPasswordBlur={props.onPasswordBlur}
                onEmailChange={props.onEmailChange}
                onEmailBlur={props.onEmailBlur}
            />
          </div>
        </div>
        <div className="list-row">
          <div className="left">{props.t('auth:password')}</div>
          <div className="right">
            <Button color="primary" onClick={props.onShowChangePasswordModal}>
              {props.t('change_password')}
            </Button>
            <ResetPasswordModal
              showPasswordModal={props.showPasswordModal}
              onNewPasswordChange={props.onNewPasswordChange}
              onOldPasswordChange={props.onOldPasswordChange}
              onOldPasswordBlur={props.onOldPasswordBlur}
              onNewPasswordStatusChange={props.onNewPasswordStatusChange}
              onCancel={props.onHideChangePasswordModal}
              oldPasswordStatus={props.oldPasswordStatus}
              passwordFormIsValid={props.passwordFormIsValid}
              passwordControlRef={props.newPasswordControlRef}
              onConfirm={props.onResetPassword}
            />
          </div>
        </div>
        <div className="list-row">
          <div className="left" />
          <div id="delete-account" className="right">
            <Button id="delete-account" color="primary" onClick={props.onShowDeleteAccountModal}>
              {props.t('delete_account')}
            </Button>
            <DeleteAccountModal
              isDeleteInProgress={props.isDeleteInProgress}
              showDeleteAccountModal={props.showDeleteAccountModal}
              onCancel={props.onHideDeleteAccountModal}
              onConfirm={props.onDeleteAccount}
            />
          </div>
        </div>
        <div>
          {props.showNewsletter && (
            <div>
              <FormGroup className="mb-0">
                <CustomInput
                  id={'newsletter-consent'}
                  checked={props.isNewsletterConsentChecked}
                  onChange={handleChange(props.onNewsletterConsentChange)}
                  type="checkbox"
                  label={props.t("I agree to receive Zillin's newsletter")}
                />
              </FormGroup>
            </div>
          )}
          {props.showTCAndPP && (
            <div>
              <NavLink className="styled_link" href={`${ExternalLinks.Regulations.TermsAndConditions.Path}${props.userLinkExtension}`} target="_blank">
                {props.t('terms_and_conditions')}
              </NavLink>
              {props.t('and')}
              <NavLink className="styled_link" href={`${ExternalLinks.Regulations.PrivacyPolicy.Path}${props.userLinkExtension}`} target="_blank">
                {props.t('privacy_policy')}
              </NavLink>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default as<React.ComponentClass>(observer(withNamespaces('user')(PersonalInformationWidget)));
