import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autobind from 'autobind-decorator';

interface IProps {
  icon: any;
  color?: string;
  float?: string;
  title?: string;
  disabled?: boolean;
  className?: string;
  minRange: number;
  maxRange: number;
  value: number;
  onChange(value: number): void;
  tabIndex?: number;
}

interface IState {
  showSlider: boolean;
}

export class IconedButtonWithSlider extends React.Component<IProps, IState> {
  private wrapperRef: any;

  constructor(props: IProps) {
    super(props);
    this.state = {
      showSlider: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  @autobind
  setWrapperRef(node: any) {
    this.wrapperRef = node;
  }

  @autobind
  toggleSlider(event: any) {
    event.stopPropagation();
    this.setState(prevState => ({ showSlider: !prevState.showSlider }));
  }

  @autobind
  handleChange(event: any) {
    event.stopPropagation();
    if (event.target.value) this.props.onChange(event.target.value);
  }

  @autobind
  handleClickOutside(event: any) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showSlider: false });
    }
  }

  @autobind
  stopDamnPropagation(event: any) {
    event.stopPropagation();
  }

  render() {
    return (
      <div className="btn-with-slider-wrapper" onClick={this.stopDamnPropagation} ref={this.setWrapperRef}>
        <button
          className={`btn btn-icon btn-with-slider
            ${this.props.className}
            ${this.props.color || ''}
            ${this.props.float || ''}
            ${this.props.disabled ? 'icon-disabled' : ''}
            ${this.state.showSlider ? 'selected' : ''}`}
          onClick={this.toggleSlider}
          title={this.props.title}
          disabled={this.props.disabled}
          tabIndex={this.props.tabIndex || 0}
        >
          <span className="btn-icon-slider-label">{this.props.value}</span><FontAwesomeIcon icon={this.props.icon} />
        </button>
        {this.state.showSlider &&
          <div className="btn-icon-slider" onClick={this.stopDamnPropagation}>
            <span>Size</span>
            <span className="value">{this.props.value}</span>
            <input
              type="range"
              id="slider"
              name="slider"
              value={this.props.value}
              min={this.props.minRange}
              max={this.props.maxRange}
              step={1}
              onChange={this.handleChange}
              tabIndex={this.props.tabIndex || 0}
            />
          </div>
        }
      </div>
    );
  }
}
