import * as React from 'react';

import { Col, Row } from 'reactstrap';
import { EditablePageHeader, IHeaderPageProps } from '../../../components/EditablePageHeader';

import { DatasetDetailsOverviewTabContainer } from '../containers/DatasetDetailsOverview.container';
import { DatasetDetailsTabs } from './DatasetDetailsTabs';
import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { IDatasetDetails } from '../datasetsDetails.store';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { InputStatus } from '../../../models/error.model';
import { Loader } from '../../../components/Loader';
import { PageHeader } from '../../../components/PageHeader';
import { RestrictAccessTo2 } from '../../../containers/RestrictAccessTo2';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IHeaderProps extends IHeaderPageProps {
  details: IDatasetDetails;
  workspaceId: string;
  isLoading: boolean;
  imageClickCallback(): void;
  onAuthorBlur(value: string): void;
  onTermsOfUseBlur(value: string): void;
  authorStatus?: InputStatus;
  termsOfUseStatus?: InputStatus;
  onAuthorChange(value: string): void;
  onTermsOfUseChange(value: string): void;
  author: string;
  termsOfUse?: string;
  canEditDetails: boolean;
}

class DatasetDetailsHeaderPure extends React.Component<IHeaderProps & ITranslatable> {
  render() {
    return (
      <>
        <Row className="dataset-details-header">
          <Col>
            <RestrictAccessTo2 hasAccess={this.props.canEditDetails}>
              <Loader isLoading={this.props.isLoading}>
                <DisabledWhenWorkspaceOwnerPolicyExceeded>
                  <EditablePageHeader
                    name={this.props.name}
                    onNameChange={this.props.onNameChange}
                    onNameBlur={this.props.onNameBlur}
                    nameMaxLength={this.props.nameMaxLength}
                    nameStatus={this.props.nameStatus}
                    description={this.props.description}
                    onDescriptionChange={this.props.onDescriptionChange}
                    onDescriptionBlur={this.props.onDescriptionBlur}
                    descriptionMaxLength={this.props.descriptionMaxLength}
                    descriptionStatus={this.props.descriptionStatus}
                    tabNavPresent="with-tab-nav"
                    namePlaceholder="type_dataset_name"
                    descriptionPlaceholder="type_dataset_description"
                    imageSrc={this.props.imageSrc}
                    t={this.props.t}
                    author={this.props.author}
                    onAuthorChange={this.props.onAuthorChange}
                    onAuthorBlur={this.props.onAuthorBlur}
                    authorStatus={this.props.authorStatus}
                    termsOfUse={this.props.termsOfUse}
                    onTermsOfUseChange={this.props.onTermsOfUseChange}
                    onTermsOfUseBlur={this.props.onTermsOfUseBlur}
                    termsOfUseStatus={this.props.termsOfUseStatus}
                    authorPlaceholder="type_dataset_author"
                    termsOfUsePlaceholder="type_dataset_terms_of_use"
                  />
                </DisabledWhenWorkspaceOwnerPolicyExceeded>
              </Loader>
            </RestrictAccessTo2>
            <RestrictAccessTo2 hasAccess={!this.props.canEditDetails}>
              <PageHeader
                headerText={this.props.name}
                headerDescription={this.props.description}
                headerAuthor={this.props.author}
                headerTermsOfUse={this.props.termsOfUse}
                tabNavPresent="with-tab-nav"
              />
            </RestrictAccessTo2>
          </Col>
          <DatasetDetailsOverviewTabContainer />
        </Row>

        <Row>
          <Col>
            <DatasetDetailsTabs workspaceId={this.props.workspaceId} details={this.props.details} imageClickCallback={this.props.imageClickCallback} />
          </Col>
        </Row>
      </>
    );
  }
}

export const DatasetDetailsHeader = as<React.FunctionComponent<IHeaderProps>>(withNamespaces('datasets', { wait: true })(DatasetDetailsHeaderPure));
