/**
 * Download file using <a> element. It prevents browsers from blocking the download treating
 * it as popup window.
 */
export function downloadFile(fileUrl: string) {
  const a = document.createElement('a');
  a.href = fileUrl;
  a.download = '';
  a.click();
}
