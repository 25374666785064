import { ClipLoader } from 'react-spinners';
import React from 'react';

interface ILoaderProps {
  isLoading: boolean;
  size?: number;
  solid?: boolean;
  hideSpinner?: boolean;
  message?: string;
  class?: string;
}

interface IState {
  showMessage: boolean;
}

export class Loader extends React.PureComponent<ILoaderProps, IState> {
  constructor(props: ILoaderProps) {
    super(props);
    this.state = { showMessage: false };
  }

  componentDidMount(): void {
    if (this.props.message) {
      setTimeout(() => {
        this.setState({ showMessage: true });
      }, 2000);
    }
  }

  render() {
    return (
      <div className="loader-header">
        <div className={this.props.isLoading ? `loading d-flex flex-column ${this.props.solid ? 'solid' : ''} ${this.props.class || ''}` : 'loaded'}>
          {!this.props.hideSpinner && <ClipLoader loading={this.props.isLoading} size={this.props.size ? this.props.size : 70} />}
          {this.props.isLoading && this.state.showMessage && <div className="h5 mt-4">{this.props.message}</div>}
        </div>
        {this.props.children}
      </div>
    );
  }
}
