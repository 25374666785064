import React, { useEffect, useState } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { dateToUTC, getCurrentUTCTime } from '../../../../../__legacy__/helpers/date.helpers';

import { Alert } from '../../../models/Alert';

interface IProps {
  alert: Alert;
  onAlertOutdated(alert: Alert): void;
}

const CountdownAlertPure: React.FC<IProps & WithNamespaces> = ({ alert, onAlertOutdated, t }: IProps & WithNamespaces) => {
  const formatToString = (value: number) => (value.toString().length < 2 ? `0${value}` : value.toString());
  let STOP: boolean = false;

  const countdownDate = dateToUTC(alert.validUntil).getTime();
  const countDown = countdownDate - getCurrentUTCTime().getTime();
  const [minute, second] = getReturnValues(countDown);

  const [minuteString, setMinuteString] = useState(formatToString(minute));
  const [secondString, setSecondString] = useState(formatToString(second));

  const updateTime = (): boolean => {
    if (STOP) return false;

    const newCountDown = countdownDate - getCurrentUTCTime().getTime();
    const [newMinute, newSecond] = getReturnValues(newCountDown);
    const scheduleNextUpdate = newMinute >= 0 || newSecond >= 0;

    if (!scheduleNextUpdate) return false;

    setMinuteString(formatToString(newMinute));
    setSecondString(formatToString(newSecond));

    return true;
  };

  const update = () => {
    setTimeout(() => {
      if (updateTime()) {
        update();
      } else {
        onAlertOutdated(alert);
      }
    }, 1000);
  };

  useEffect(() => {
    update();
    return () => {
      STOP = true;
    };
  }, []);

  return <div className="alerts-bar-alert">{t('alerts.restart_countdown_message', { minutes: minuteString, seconds: secondString })}</div>;
};

const getReturnValues = (countdown: number) => {
  const hours = Math.floor(countdown / (1000 * 60 * 60));
  const minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60)) + hours * 60;
  const seconds = Math.floor((countdown % (1000 * 60)) / 1000);

  return [minutes, seconds];
};

export const CountdownAlert = withNamespaces('new')(CountdownAlertPure);
