import { WithNamespaces, withNamespaces } from 'react-i18next';

import React from 'react';
import { S_TwoButtonModal } from '../../../../../../../../design/modals/twoButtonModal/S_TwoButtonModal';

interface Props {
   show: boolean;
   onHide(): void;
   onSubmit(): void;
}

const InformationStepModalPure: React.FC<Props & WithNamespaces> = (props: Props & WithNamespaces) => {
  const { t, show, onHide, onSubmit } = props;
  return (
    <S_TwoButtonModal
      cancelText={t('new.projects.details.import_annotations.cancel')}
      headerText={`${t('new.projects.details.import_annotations.header')} - ${t('new.projects.details.import_annotations.steps.information.header')}`}
      submitText={t('new.projects.details.import_annotations.next')}
      onCancel={onHide}
      onHide={onHide}
      onSubmit={onSubmit}
      show={show}
    >
      <p>{t('new.projects.details.import_annotations.steps.information.body')}</p>
    </S_TwoButtonModal>
  );
};

export const InformationStepModal = withNamespaces('common')(InformationStepModalPure);
