import * as React from 'react';

type TabsVariant = 'default' | 'on-auth-view';

interface IProps {
  variant?: TabsVariant;
  className?: string;
  children: React.ReactElement<any>[] | React.ReactElement<any>;
}

export const TabsContainer = (props: IProps) =>
  <div className={`generic-tabs ${props.variant} ${props.className}`}>{props.children}</div>;
