import { StickerEvent } from '../../../../__legacy__/services/eventBus.service';

export const CurrentWorkspaceChangedEventType = Symbol('CURRENT_WORKSPACE_CHANGED_EVENT');

export class CurrentWorkspaceChangedEvent extends StickerEvent {
  constructor(public willBeNavigatedToDifferentPath: boolean) {
    super();
  }

  get type(): Symbol {
    return CurrentWorkspaceChangedEventType;
  }
}
