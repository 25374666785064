import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { IAttachmentBreadcrumb } from '../attachments.store';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../helpers/react.helpers';
import { handleClickAndPassData } from '../../../helpers/formHelpers';
import { withNamespaces } from 'react-i18next';

export interface IOuterProps {
  breadcrumbs: IAttachmentBreadcrumb[];
  onFolderSelect(id: string): void;
}

class AttachmentsBreadcrumbs extends React.Component<IOuterProps & ITranslatable> {
  render() {
    return (
      <>
        {this.props.breadcrumbs && this.props.breadcrumbs.length ?
          <Breadcrumb>
            <BreadcrumbItem
              onClick={handleClickAndPassData(this.props.onFolderSelect)('')}
              key={'breadcrumb-home'}
            >
              {this.props.t('attachments')}
            </BreadcrumbItem>
            {this.props.breadcrumbs.map((breadcrumb, i, arr) => (
              <BreadcrumbItem
                key={`breadcrumb-${breadcrumb.id}`}
                onClick={handleClickAndPassData(this.props.onFolderSelect)(breadcrumb.id)}
                active={arr.length - 1 === i}
              >
                {breadcrumb.name}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
          : ''}
      </>
    );
  }
}

export default as<React.ComponentClass<IOuterProps>>(withNamespaces('attachment')(AttachmentsBreadcrumbs));
