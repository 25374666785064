import * as React from 'react';

import { AnnotationTypesListContainer } from '../../../modules/projectDetails/sub/tools/containers/AnnotationTypesList.container';
import { AttributesListContainer } from '../../../modules/projectDetails/sub/tools/containers/AttributesList.container';
import { PublishedProjectInfoContainer } from '../../../modules/projectDetails/containers/PublishedProjectInfo.container';
import { QuestionsListContainer } from '../../../modules/projectDetails/sub/tools/containers/QuestionsList.container';

export const ProjectDetailsAnnotationToolsView = () => (
  <div>
    <PublishedProjectInfoContainer />
    <div>
      <AnnotationTypesListContainer />
      <AttributesListContainer />
      <QuestionsListContainer />
    </div>
  </div>
);
