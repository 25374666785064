// tslint:disable:function-name
// tslint:disable:variable-name

import 'leaflet-draw';

import { MOUSE_BUTTON, MOUSE_EVENTS, MouseLeafletEvent } from '../../annotations.interface';
import leaflet, { DrawEvents, LeafletEvent, LeafletEventHandlerFn, Map, MarkerOptions } from 'leaflet';

import { Feature } from 'geojson';
import autobind from 'autobind-decorator';
import { getSimplifiedGeoJSON } from '../../../../helpers/geometry/polygon.helpers';
import { inImageBounds } from '../../../../helpers/geometry/mapExtensions.helpers';

@autobind
export class DrawPoint extends leaflet.Draw.CircleMarker implements IDrawer {
  private readonly onDrawCreated: (geojson: Feature) => void;

  constructor(map: Map, onDrawCreated: (geojson: Feature) => void, options?: MarkerOptions) {
    super(map, options);
    this.onDrawCreated = onDrawCreated;
  }

  disableMouseMarkerFocus() {
    this._mouseMarker = leaflet.marker(this._map.getCenter(), {
      icon: leaflet.divIcon({
        className: 'leaflet-mouse-marker',
        iconAnchor: [20, 20],
        iconSize: [40, 40],
      }),
      opacity: 0,
      zIndexOffset: this.options.zIndexOffset,
      keyboard: false,
    });
  }

  public addHandlers() {
    this._map.on(leaflet.Draw.Event.CREATED, this.handleDrawCreated as LeafletEventHandlerFn);
    this._map.on(MOUSE_EVENTS.MOUSE_UP, this.handleMouseUp as LeafletEventHandlerFn);
  }

  public removeHandlers() {
    this._map.off(leaflet.Draw.Event.CREATED, this.handleDrawCreated as LeafletEventHandlerFn);
    this._map.off(MOUSE_EVENTS.MOUSE_UP, this.handleMouseUp as LeafletEventHandlerFn);
  }

  handleDrawCreated(e: DrawEvents.Created & LeafletEvent): void {
    const simplifiedGeojson = getSimplifiedGeoJSON(e.layer);
    if (!simplifiedGeojson) return;

    this.onDrawCreated(simplifiedGeojson);
  }

  handleMouseUp(e: MouseLeafletEvent) {
    if (e.originalEvent.button !== MOUSE_BUTTON.LEFT) return;
    if (inImageBounds(this._marker.getLatLng(), this._map)) {
      this._fireCreatedEvent();

      this.disable();
      if (this.options.repeatMode) {
        this.enable();
      }
    }
  }

  _onClick() {}
  _onTouch() {}
}
