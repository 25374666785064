import * as React from 'react';

import { IProjectDetailsBl, ProjectDetailsBlType } from '../projectDetails.bl';
import { IProjectDetailsPermissions, ProjectDetailsPermissionsType } from '../projectDetails.permissions';
import { IProjectDetailsStore, ProjectDetailsStoreType } from '../projectDetails.store';
import { as, injectProps } from '../../../helpers/react.helpers';

import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { ImageAssignmentPolicy } from '../projectDetails.models';
import { ProjectImageAssignmentPolicyForm } from '../components/ProjectImageAssignmentPolicyForm';
import { ProjectStatus } from '../../projects/projects.model';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IProps {}

interface IInjectedProps {
  projectDetailsStore: IProjectDetailsStore;
  projectDetailsBl: IProjectDetailsBl;
  projectDetailsPermissions: IProjectDetailsPermissions;
}

interface IState {
  showConfirmationModal: boolean;
  selectedPolicy: ImageAssignmentPolicy;
}

@injectProps({
  projectDetailsBl: ProjectDetailsBlType,
  projectDetailsPermissions: ProjectDetailsPermissionsType,
  projectDetailsStore: ProjectDetailsStoreType,
})
@observer
class ProjectImageAssignmentPolicyFormContainerPure extends React.Component<IProps & IInjectedProps & ITranslatable, IState> {
  constructor(props: IProps & IInjectedProps & ITranslatable) {
    super(props);
    this.state = {
      showConfirmationModal: false,
      selectedPolicy: this.props.projectDetailsStore.imageAssignmentPolicy,
    };
  }

  handlePolicyChangeConfirm = async () => {
    await this.props.projectDetailsBl.updateImageAssignmentPolicyAsync(this.state.selectedPolicy);
    this.setState({ showConfirmationModal: false });
  };

  handlePolicyChangeCancel = () => {
    this.setState({ showConfirmationModal: false, selectedPolicy: this.props.projectDetailsStore.imageAssignmentPolicy });
  };

  handlePolicyChange = async (policy: ImageAssignmentPolicy) => {
    if(this.props.projectDetailsStore.status === ProjectStatus.Published) {
      this.setState({ showConfirmationModal: true, selectedPolicy: policy });
      return;
    }

    await this.props.projectDetailsBl.updateImageAssignmentPolicyAsync(policy);
  };

  render() {
    return (
      <>
        <ProjectImageAssignmentPolicyForm
          onPolicyChange={this.handlePolicyChange}
          selectedPolicy={this.props.projectDetailsStore.imageAssignmentPolicy}
          canUpdateImageAssignmentPolicy={this.props.projectDetailsPermissions.canUpdateImageAssignmentPolicy()}
        />
        <ConfirmationModal
          confirmationHeader={this.props.t('image_assignment_policy_change_header')}
          confirmationDescription={this.props.t('image_assignment_policy_change_body')}
          onConfirm={this.handlePolicyChangeConfirm}
          onCancel={this.handlePolicyChangeCancel}
          showModal={this.state.showConfirmationModal}
          confirmationYes={this.props.t('save')}
          confirmColor="success"
        >
          <p>
            <strong>{this.props.t('image_assignment_policy_change_note')}</strong>
          </p>
        </ConfirmationModal>
      </>
    );
  }
}

export const ProjectImageAssignmentPolicyFormContainer = as<React.ComponentClass>(withNamespaces('common')(ProjectImageAssignmentPolicyFormContainerPure));
