import { DomEvent, LatLng, LeafletMouseEvent, Point } from 'leaflet';
import { ISegmentation, ISegmentationFeature } from '../annotations.interface';
import { Polygon, PolygonProps } from 'react-leaflet';

import { AnnotationToolType } from '../submodules/annotationTypes/models/annotationToolType';
import { DEFAULT_STROKE_OPACITY } from '../../../helpers/global.constants';
import { PointFeature } from './features/PointFeature';
import { PolygonFeature } from './features/PolygonFeature';
import { PolylineFeature } from './features/PolylineFeature';
import React from 'react';
import { RectangleFeature } from './features/RectangleFeature';
import { RotatedRectangleFeature } from './features/RotatedRectangleFeature';
import { VectorFeature } from './features/VectorFeature';
import { observer } from 'mobx-react';

export interface ISegmentationPolygonProps {
  color: string;
  storeKey: string;
  type: AnnotationToolType;
  canRotate: boolean;
}

export interface ISegmentationLayerFeaturesProps {
  segmentations: ISegmentation[];
  selectedSegmentation?: ISegmentation;
  hoveredSegmentation?: string;
  readonly?: boolean;
  featureInEditId?: string;
  opacityLevel: number;
  zoomLevel: number;
  activeAnnotationTypeId?: string;
  contextMenuSegmentationId?: string;
  reactLeafletRenderFingerprint: string;
  onSegmentationSelected(id: string): void;
  onSegmentationOver(segmentation: string): void;
  onSegmentationOut(): void;
  onContextMenu(position: Point, segmentationId: string): void;
}

@observer
export class SegmentationLayerFeatures extends React.Component<ISegmentationLayerFeaturesProps> {
  handleSegmentationOver = (segmentationId: string) => (_: LeafletMouseEvent) => {
    if (this.props.featureInEditId === undefined) this.props.onSegmentationOver(segmentationId);
  };

  handleSegmentationOut = (segmentationId: string) => (_: LeafletMouseEvent) => {
    if (this.props.featureInEditId === undefined && this.props.hoveredSegmentation === segmentationId) this.props.onSegmentationOut();
  };

  handleClick = (feature: ISegmentationFeature) => (e: LeafletMouseEvent) => {
    DomEvent.stop(e.originalEvent);
    if (this.props.activeAnnotationTypeId !== undefined && this.props.activeAnnotationTypeId !== AnnotationToolType.SELECT) return;
    this.props.onSegmentationSelected(feature.id);
  };

  handleContextMenu = (feature: ISegmentationFeature) => (e: LeafletMouseEvent) => {
    if (this.props.activeAnnotationTypeId !== undefined && this.props.activeAnnotationTypeId !== AnnotationToolType.SELECT) return;

    if (!this.props.readonly && (!this.props.featureInEditId || this.props.featureInEditId === feature.id)) {
      this.props.onContextMenu(e.containerPoint, feature.id);
      this.props.onSegmentationSelected(feature.id);
    }
  };

  // https://github.com/PaulLeCam/react-leaflet/issues/205
  // https://github.com/PaulLeCam/react-leaflet/issues/332
  composeKey(segmentation: ISegmentation): string {
    return `${segmentation.feature.id} ${this.props.reactLeafletRenderFingerprint} ${this.props.readonly}`;
  }

  renderFeature = (segmentation: ISegmentation) => {
    const key = this.composeKey(segmentation);

    const props = this.props;
    const { feature, latlngs } = segmentation;
    const selectedOpacity = props.opacityLevel / 100;

    const isEditedSegmentation =
      (props.featureInEditId !== undefined && feature.id !== props.featureInEditId) ||
      (props.contextMenuSegmentationId !== undefined && feature.id !== props.contextMenuSegmentationId);

    const opacity = isEditedSegmentation ? DEFAULT_STROKE_OPACITY : 1;
    const fillOpacity = isEditedSegmentation ? selectedOpacity / 2 : selectedOpacity;
    const isActive = this.props.selectedSegmentation?.feature.id === segmentation.feature.id && !this.props.readonly && !this.props.activeAnnotationTypeId;

    switch (feature.featureType) {
      case AnnotationToolType.POINT: {
        const point = (latlngs as LatLng[])[0];
        return (
          <PointFeature
            isActive={isActive}
            center={point}
            radius={4}
            key={key}
            storeKey={feature.id}
            fillOpacity={fillOpacity}
            color={feature.color}
            type={AnnotationToolType.POINT}
            onclick={this.handleClick(feature)}
            oncontextmenu={this.handleContextMenu(feature)}
            onmouseover={this.handleSegmentationOver(feature.id)}
            onmouseout={this.handleSegmentationOut(feature.id)}
            canRotate={false}
            opacity={opacity}
            className={'with-context-menu'}
          />
        );
      }
      case AnnotationToolType.RECTANGLE:
        return (
          <RectangleFeature
            isActive={isActive}
            positions={latlngs}
            key={this.composeKey(segmentation)}
            storeKey={feature.id}
            fillOpacity={fillOpacity}
            color={feature.color}
            type={AnnotationToolType.ROTATEDRECTANGLE}
            onclick={this.handleClick(feature)}
            oncontextmenu={this.handleContextMenu(feature)}
            onmouseover={this.handleSegmentationOver(feature.id)}
            onmouseout={this.handleSegmentationOut(feature.id)}
            canRotate={false}
            opacity={opacity}
            className={'with-context-menu'}
          />
        );
      case AnnotationToolType.POLYGON:
        return (
          <PolygonFeature
            isActive={isActive}
            key={this.composeKey(segmentation)}
            storeKey={feature.id}
            fillOpacity={fillOpacity}
            color={feature.color}
            positions={latlngs}
            type={AnnotationToolType.POLYGON}
            onclick={this.handleClick(feature)}
            oncontextmenu={this.handleContextMenu(feature)}
            onmouseover={this.handleSegmentationOver(feature.id)}
            onmouseout={this.handleSegmentationOut(feature.id)}
            canRotate={false}
            opacity={opacity}
            className={'with-context-menu'}
          />
        );
      case AnnotationToolType.ROTATEDRECTANGLE:
        return (
          <RotatedRectangleFeature
            isActive={isActive}
            key={this.composeKey(segmentation)}
            feature={feature}
            latlngs={latlngs as LatLng[]}
            opacity={opacity}
            fillOpacity={fillOpacity}
            readonly={props.readonly}
            onSegmentationSelected={props.onSegmentationSelected}
            onSegmentationOver={this.handleSegmentationOver(feature.id)}
            onSegmentationOut={this.handleSegmentationOut(feature.id)}
            onClick={this.handleClick(feature)}
            onContextMenu={this.handleContextMenu(feature)}
            className={'with-context-menu'}
            zoomLevel={this.props.zoomLevel}
          />
        );
      case AnnotationToolType.POLYLINE:
        return (
          <PolylineFeature
            isActive={isActive}
            key={this.composeKey(segmentation)}
            storeKey={feature.id}
            fillOpacity={fillOpacity}
            color={feature.color}
            type={AnnotationToolType.POLYLINE}
            positions={latlngs}
            onclick={this.handleClick(feature)}
            oncontextmenu={this.handleContextMenu(feature)}
            onmouseover={this.handleSegmentationOver(feature.id)}
            onmouseout={this.handleSegmentationOut(feature.id)}
            canRotate={false}
            opacity={opacity}
            className={'with-context-menu'}
          />
        );
      case AnnotationToolType.VECTOR: {
        return (
          <VectorFeature
            key={this.composeKey(segmentation)}
            isActive={isActive}
            feature={feature}
            fillOpacity={fillOpacity}
            latlngs={latlngs as LatLng[]}
            onClick={this.handleClick(feature)}
            onContextMenu={this.handleContextMenu(feature)}
            onSegmentationOut={this.handleSegmentationOut(feature.id)}
            onSegmentationOver={this.handleSegmentationOver(feature.id)}
            opacity={opacity}
            zoomLevel={this.props.zoomLevel}
          />
        );
      }
      case AnnotationToolType.BRUSH:
        if (
          props.selectedSegmentation?.feature.id === feature.id &&
          feature.geometry.coordinates.length &&
          !props.readonly &&
          props.activeAnnotationTypeId === props.selectedSegmentation?.feature?.properties?.annotationTypeId
        ) {
          return null;
        }

        return (
          <Polygon<ISegmentationPolygonProps & PolygonProps>
            key={this.composeKey(segmentation)}
            storeKey={feature.id}
            fillOpacity={fillOpacity}
            color={feature.color}
            positions={latlngs}
            type={AnnotationToolType.BRUSH}
            onclick={this.handleClick(feature)}
            oncontextmenu={this.handleContextMenu(feature)}
            onmouseover={this.handleSegmentationOver(feature.id)}
            onmouseout={this.handleSegmentationOut(feature.id)}
            canRotate={false}
            opacity={opacity}
            className={'with-context-menu'}
          />
        );
    }
  };

  render() {
    return (
      <>
        {this.props.segmentations.map((segmentation: ISegmentation) => {
          return <React.Fragment key={segmentation.feature.id}>{this.renderFeature(segmentation)}</React.Fragment>;
        })}
      </>
    );
  }
}
