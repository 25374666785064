import * as React from 'react';

import { AdminRoute } from './admin/admin.route';
import { AnnotationsRoute } from './annotations.route';
import { AttachmentsRoute } from './attachments.route';
import { DatasetsView } from '../views/home/Datasets.view';
import { EvaluationsRoute } from './evaluations/evaluations.route';
import { FreeAccessRoute } from './freeAccess.route';
import { Home } from './config/Home';
import { IntegrationsView } from '../views/home/Integrations.view';
import { LockedView } from '../views/Locked.view';
import { ModelsRoute } from './models/models.route';
import { NotFoundView } from '../views/NotFound.view';
import { ProjectsRoute } from './projects/projects.route';
import { Route } from 'react-router';
import { SwitchWithNotFound } from '../containers/route.containers';
import { TeamView } from '../views/home/Team.view';
import { UserDetailsAccountView } from '../views/home/user/UserDetailsAccount.view';
import { WorkspacesView } from '../views/home/Workspaces.view';

export const HomeRoute = () => (
    <SwitchWithNotFound notFound={NotFoundView}>
      <Route path={Home.Projects.Path} component={ProjectsRoute} />
      <Route path={Home.Datasets.Path} component={DatasetsView} />
      <Route path={Home.Annotations.Path} component={AnnotationsRoute} />
      <Route path={Home.FreeAccess.Path} component={FreeAccessRoute} />
      <Route path={Home.Admin.Path} component={AdminRoute} />
      <Route path={Home.User.Path} component={UserDetailsAccountView} />
      <Route path={Home.Locked.Path} component={LockedView} />
      <Route path={Home.Integrations.Path} component={IntegrationsView} />
      <Route path={Home.Team.Path} component={TeamView} />
      <Route path={Home.Workspaces.Path} component={WorkspacesView} />
      <Route path={Home.Attachments.Path} component={AttachmentsRoute} />
      <Route path={Home.Models.Path} component={ModelsRoute} />
      <Route path={Home.Evaluations.Path} component={EvaluationsRoute} />
    </SwitchWithNotFound>
);
