import './VerticalMenu.scss';

import { WithNamespaces, withNamespaces } from 'react-i18next';

import React from 'react';

interface IVerticalMenuProps {
  showObjectsList: boolean;
  activeTab?: VerticalMenuItem;
  toggle(menuItem: VerticalMenuItem): void;
}

const VerticalMenuForTranslation: React.FC<IVerticalMenuProps & WithNamespaces> = ({ t, activeTab, showObjectsList, toggle }: IVerticalMenuProps & WithNamespaces) => {
  const getToggle = (menuItem: VerticalMenuItem) => () => toggle(menuItem);

  return (
    <div className="vertical-menu">
      {showObjectsList && (
        <div className={`vertical-menu-item ${activeTab === VerticalMenuItem.LIST_OF_OBJECTS ? 'active' : ''}`} onClick={getToggle(VerticalMenuItem.LIST_OF_OBJECTS)} title={t('show_list_of_drawn_objects')}>
          {t('list_of_objects')}
        </div>
      )}
      <div
        className={`vertical-menu-item ${activeTab === VerticalMenuItem.ANNOTATED_IN_PROJECTS ? 'active' : ''}`}
        onClick={getToggle(VerticalMenuItem.ANNOTATED_IN_PROJECTS)}
        title={t('show_annotations_from_other_projects')}
      >
        {t('annotated_in_projects')}
      </div>
    </div>
  );
};

export enum VerticalMenuItem {
  ANNOTATED_IN_PROJECTS = 'annotated_in_projects',
  LIST_OF_OBJECTS = 'list_of_objects',
}

export const VerticalMenu = withNamespaces()(VerticalMenuForTranslation);
