import { CurrentWorkspaceBl, CurrentWorkspaceBlType } from '../../CurrentWorkspace.bl';

import { CurrentWorkspaceSelectAction } from './CurrentWorkspaceSelectAction';
import React from 'react';
import { as } from '../../../../../common/dependencyInjection/as';
import { injectProps } from '../../../../../__legacy__/helpers/react.helpers';

interface Props {
  workspaceId: string;
  isLocked: boolean;
}

interface InjectedProps {
  bl: CurrentWorkspaceBl;
}

type AllProps = Props & InjectedProps;

@injectProps({
  bl: CurrentWorkspaceBlType,
})
class CurrentWorkspaceSelectActionContainerPure extends React.Component<AllProps> {
  render() {
    return <CurrentWorkspaceSelectAction onClick={this.props.bl.selectWorkspace} workspaceId={this.props.workspaceId} isLocked={this.props.isLocked} />;
  }
}

export const CurrentWorkspaceSelectActionContainer = as<React.ComponentClass<Props>>(CurrentWorkspaceSelectActionContainerPure);
