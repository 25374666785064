import { INotificationsService, NotificationsServiceType } from '../../../notifications/services/notifications.service';
import { NotificationLevel, ToastNotification } from '../../../notifications/models/notification.model';
import { inject, injectable } from 'inversify';
import { StickerError, ForbiddenError, InputStatus } from '../../../../models/error.model';
import _ from 'lodash';
import { IProjectDetailsToolsStore, IProjectDetailsToolsStoreSetter, ProjectDetailsToolsStoreSetterType } from './projectDetailsTools.store';
import { IAnnotationType, IQuestion } from './projectDetailsTools.models';
import { IProjectDetailsSubmodule } from '../../services/IProjectDetailsSubmodule';
import { ICanPublishProjectResponse, ToolDeleteStrategy } from '../../projectDetails.models';
import { IQuestionsApiService, QuestionsApiServiceType } from './services/questionsApi.service';
import { AnnotationTypesApiServiceType, IAnnotationTypesApiService } from './services/annotationTypesApi.service';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../../../modules/common/OverlayLoader.store';
import { ProjectStatus } from '../../../projects/projects.model';
import { EventBusType, EventListeningDisposer, IEventBus } from '../../../../services/eventBus.service';
import { action } from 'mobx';
import { MarkingToolAddedEventType } from '../../../../../modules/projects/details/annotationTools/markingTools/add/events/MarkingToolAddedEvent';
import { MarkingToolEditedEventType } from '../../../../../modules/projects/details/annotationTools/markingTools/edit/events/MarkingToolEditedEvent';

export const IProjectDetailsToolsBlType = Symbol('PROJECT_DETAILS_TOOLS_SERVICE');

export interface IProjectDetailsToolsBl extends IProjectDetailsSubmodule {
  addAnnotationTypeAsync(annotationType: IAnnotationType): Promise<void>;
  updateAnnotationTypeAsync(annotationType: IAnnotationType): Promise<void>;
  isUniqueAnnotationTypeNameAsync(toolName: string, id: string): Promise<boolean>;
  deleteAnnotationTypeAsync(id: string, strategy: ToolDeleteStrategy): Promise<void>;
  changeAnnotationTypeOrderAsync(annotationTypeId: string, index: number): Promise<void>;
  getAnnotationTypeUsageAsync(annotationTypeId: string): Promise<void>;
  getAnnotationTypeAffectAsync(annotationTypeId: string): Promise<void>;

  addQuestionAsync(question: IQuestion): Promise<void>;
  updateQuestionAsync(question: IQuestion, strategy: ToolDeleteStrategy): Promise<void>;
  changeQuestionOrderAsync(questionId: string, index: number, isAttribute: boolean): Promise<void>;
  deleteQuestionAsync(id: string, strategy: ToolDeleteStrategy): any;
  getQuestionAnswersAndScopesUsageAsync(questionId: string, answerIds: string[], scopesIds: string[]): Promise<void>;
  getQuestionAnswersAndScopesAffectAsync(questionId: string, answerIds: string[], scopesIds: string[]): Promise<void>;
  getQuestionUsageAsync(questionId: string): Promise<void>;
  getQuestionAffectAsync(questionId: string): Promise<void>;
  isUniqueQuestionTextAsync(text: string, id: string, isAttribute: boolean): Promise<boolean>;
  getProjectDetailsAnnotationViewAsync(projectId: string): void;
  store: IProjectDetailsToolsStore;
}

@injectable()
export class ProjectDetailsToolsBl implements IProjectDetailsToolsBl {
  private markingToolAddedDisposer?: EventListeningDisposer;
  private markingToolEditedDisposer?: EventListeningDisposer;

  constructor(
    @inject(ProjectDetailsToolsStoreSetterType) public readonly store: IProjectDetailsToolsStoreSetter,
    @inject(NotificationsServiceType) private readonly notificationsService: INotificationsService,
    @inject(QuestionsApiServiceType) private readonly questionsApiService: IQuestionsApiService,
    @inject(AnnotationTypesApiServiceType) private readonly annotationTypesApiService: IAnnotationTypesApiService,
    @inject(OverlayLoaderStoreType) private readonly overlayLoaderStore: IOverlayLoaderStore,
    @inject(EventBusType) private readonly eventBus: IEventBus,
  ) {}

  initialize(): void {
    this.store.resetStore();
    this.markingToolAddedDisposer = this.eventBus.addListener(this.handleMarkingToolAddedAsync, MarkingToolAddedEventType);
    this.markingToolEditedDisposer = this.eventBus.addListener(this.handleMarkingToolEditedAsync, MarkingToolEditedEventType);
  }

  cleanup(): void {
    this.store.resetStore();
    this.markingToolAddedDisposer?.();
    this.markingToolEditedDisposer?.();
  }

  async addAnnotationTypeAsync(annotationType: IAnnotationType): Promise<void> {
    const result = await this.annotationTypesApiService.addAnnotationTypeAsync({
      annotationTypeId: annotationType.id,
      color: annotationType.color,
      name: annotationType.name,
      order: annotationType.order,
      projectId: this.store.id,
      selectorType: annotationType.selectorType,
    });
    this.notifyAboutApiError(result, 'project:adding_annotation_type_failed', true);
    await this.getProjectDetailsAnnotationViewAsync(this.store.id);
    await this.validateAsync();
  }

  async updateAnnotationTypeAsync(annotationType: IAnnotationType): Promise<void> {
    const result = await this.annotationTypesApiService.updateAnnotationTypeAsync({
      color: annotationType.color,
      name: annotationType.name,
      id: annotationType.id,
      selectorType: annotationType.selectorType,
      order: annotationType.order,
    });

    this.notifyAboutApiError(result, 'project:updating_annotation_type_failed');
    await this.getProjectDetailsAnnotationViewAsync(this.store.id);
    await this.validateAsync();
  }

  async isUniqueAnnotationTypeNameAsync(name: string, annotationTypeId: string): Promise<boolean> {
    const result = await this.annotationTypesApiService.checkAnnotationTypeNameUniqueness({ annotationTypeId, name, projectId: this.store.id });
    this.notifyAboutApiError(result, 'project:get_annotation_settings_failed');
    if (result instanceof StickerError) return true;
    return result;
  }

  async getAnnotationTypeUsageAsync(annotationTypeId: string): Promise<void> {
    const result = await this.annotationTypesApiService.getAnnotationTypeUsage({ annotationTypeId });
    this.notifyAboutApiError(result, 'project:get_annotation_settings_failed');
    if (result instanceof StickerError) return;

    this.store.setToolUsage(result);
  }

  async getAnnotationTypeAffectAsync(annotationTypeId: string): Promise<void> {
    const result = await this.annotationTypesApiService.getAnnotationTypeAffect({ annotationTypeId });
    this.notifyAboutApiError(result, 'project:get_annotation_settings_failed');
    if (result instanceof StickerError) return;

    this.store.setToolAffect(result);
  }

  async deleteAnnotationTypeAsync(id: string, strategy: ToolDeleteStrategy) {
    const result = await this.annotationTypesApiService.deleteAnnotationTypeAsync({
      strategy,
      annotationTypeId: id,
      projectId: this.store.id,
    });

    if (result instanceof StickerError) {
      if (result.isBadRequestWithCode(['NO_STRATEGY_SELECTED'])) {
        this.notificationsService.push(new ToastNotification(NotificationLevel.WARNING, 'project:delete_tool_select_strategy'));
      } else {
        this.notifyAboutApiError(result, 'project:delete_annotation_type_failed');
      }
    }

    await this.getProjectDetailsAnnotationViewAsync(this.store.id);
    await this.validateAsync();
  }

  async changeAnnotationTypeOrderAsync(annotationTypeId: string, index: number): Promise<void> {
    this.store.reorderAnnotationTypes(annotationTypeId, index);

    const result = await this.annotationTypesApiService.updateAnnotationTypesOrderAsync({
      projectId: this.store.id,
      toolOrders: this.store.annotationTypes.map(x => ({ id: x.id, order: x.order })),
    });

    this.notifyAboutApiError(result, 'project:updating_annotation_type_failed');
    await this.getProjectDetailsAnnotationViewAsync(this.store.id);
    await this.validateAsync();
  }

  async changeQuestionOrderAsync(questionId: string, index: number, isAttribute: boolean): Promise<void> {
    this.store.reorderQuestions(questionId, index, isAttribute);

    const result = await this.questionsApiService.updateQuestionsOrderAsync({
      projectId: this.store.id,
      orders: this.store.questions.map(x => ({ id: x.id, order: x.order })),
    });

    this.notifyAboutApiError(result, 'project:updating_question_failed', true);
    await this.getProjectDetailsAnnotationViewAsync(this.store.id);
    await this.validateAsync();
  }

  async addQuestionAsync(question: IQuestion): Promise<void> {
    const result = await this.questionsApiService.addQuestionAsync({
      projectId: this.store.id,
      questionId: question.id,
      answerType: question.type,
      answers: question.answers,
      isRequired: question.isRequired,
      scopes: question.scopes,
      text: question.questionText,
    });

    this.notifyAboutApiError(result, 'project:adding_question_failed', true);
    await this.getProjectDetailsAnnotationViewAsync(this.store.id);
    await this.validateAsync();
  }

  async updateQuestionAsync(question: IQuestion, strategy: ToolDeleteStrategy): Promise<void> {
    const result = await this.questionsApiService.updateQuestionAsync({
      strategy,
      projectId: this.store.id,
      questionType: question.type,
      questionId: question.id,
      answers: question.answers,
      isRequired: question.isRequired,
      scopes: question.scopes,
      text: question.questionText,
      order: question.order,
    });

    this.notifyAboutApiError(result, 'project:updating_question_failed', true);
    await this.getProjectDetailsAnnotationViewAsync(this.store.id);
    await this.validateAsync();
  }

  async deleteQuestionAsync(questionId: string, strategy: ToolDeleteStrategy) {
    const question = this.store.questions.find(q => q.id === questionId);
    const result = await this.questionsApiService.deleteQuestionAsync({ strategy, questionId, projectId: this.store.id });

    if (result instanceof StickerError) {
      if (result.isBadRequestWithCode(['NO_STRATEGY_SELECTED'])) {
        this.notificationsService.push(new ToastNotification(NotificationLevel.WARNING, 'project:delete_tool_select_strategy'));
      } else {
        this.notifyAboutApiError(result, question && question.scopes.includes('Image') ? 'project:delete_question_failed' : 'project:delete_attribute_failed');
      }
    }
    await this.getProjectDetailsAnnotationViewAsync(this.store.id);
    await this.validateAsync();
  }

  async getQuestionUsageAsync(questionId: string): Promise<void> {
    const result = await this.questionsApiService.getQuestionUsage({ questionId });
    this.notifyAboutApiError(result, 'project:get_annotation_settings_failed');
    if (result instanceof StickerError) return;

    this.store.setToolUsage(result);
  }

  async getQuestionAffectAsync(questionId: string): Promise<void> {
    const result = await this.questionsApiService.getQuestionAffect({ questionId });
    this.notifyAboutApiError(result, 'project:get_annotation_settings_failed');
    if (result instanceof StickerError) return;

    this.store.setToolAffect(result);
  }

  async getQuestionAnswersAndScopesUsageAsync(questionId: string, answerIds: string[], scopesIds: string[]): Promise<void> {
    const result = await this.questionsApiService.getQuestionAnswersUsage({ questionId, answerIds, scopesIds });
    this.notifyAboutApiError(result, 'project:get_annotation_settings_failed');
    if (result instanceof StickerError) return;

    this.store.setToolUsage(result);
  }

  async getQuestionAnswersAndScopesAffectAsync(questionId: string, answerIds: string[], scopesIds: string[]): Promise<void> {
    const result = await this.questionsApiService.getQuestionAnswersAffect({ questionId, answerIds, scopesIds });
    this.notifyAboutApiError(result, 'project:get_annotation_settings_failed');
    if (result instanceof StickerError) return;

    this.store.setToolAffect(result);
  }

  async getProjectDetailsAnnotationViewAsync(projectId: string) {
    this.overlayLoaderStore.enableLoader('getProjectDetailsAnnotation');
    const result = await this.questionsApiService.getProjectDetailsAnnotationViewAsync({ projectId });
    this.notifyAboutApiError(result, 'project:get_annotation_settings_failed');
    this.overlayLoaderStore.disableLoader('getProjectDetailsAnnotation');
    if (result instanceof StickerError) return;

    this.store.setRetrievedData(projectId, result.status, result.annotationTypes, result.questions);
  }

  async isUniqueQuestionTextAsync(text: string, questionId: string, isAttribute: boolean): Promise<boolean> {
    const result = await this.questionsApiService.checkQuestionTextUniqueness({ text, questionId, isAttribute, projectId: this.store.id });
    this.notifyAboutApiError(result, 'project:get_annotation_settings_failed');
    if (result instanceof StickerError) return true;
    return result;
  }

  handleCanPublishCheck(response: ICanPublishProjectResponse): void {
    this.store.setValidationStatus(response.areToolsValid ? InputStatus.valid() : InputStatus.buildFrom(['no_segmentations_or_questions_added']));
  }

  async validateAsync(): Promise<void> {
    if (this.store.status === ProjectStatus.Published) return;
    this.store.setValidationStatus(
      this.store.annotationTypes.length > 0 || this.store.questions.length > 0 ? InputStatus.valid() : InputStatus.buildFrom(['no_segmentations_or_questions_added']),
    );
  }

  getValidationErrors(): string[] {
    return this.store.toolsStatus.errorCodes;
  }

  private notifyAboutApiError(result: any, errorCode: string, dontShowBadRequest: boolean = false) {
    if (result instanceof StickerError && !(result instanceof ForbiddenError) && (!dontShowBadRequest || !result.isBadRequest())) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, errorCode));
    }
  }

  @action.bound
  async handleMarkingToolAddedAsync(): Promise<void> {
    await this.getProjectDetailsAnnotationViewAsync(this.store.id);
    await this.validateAsync();
  }

  @action.bound
  async handleMarkingToolEditedAsync(): Promise<void> {
    await this.getProjectDetailsAnnotationViewAsync(this.store.id);
    await this.validateAsync();
  }
}
