﻿import * as React from 'react';

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { IProjectHubService, ProjectHubServiceType } from '../../../services/projectHub.service';
import { IProjectsService, ProjectsServiceType } from '../projects.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { Home } from '../../../routes/config/Home';
import { ImportModal } from '../components/ImportModal';
import { ImportStage } from '../projects.model';
import { MAX_IMPORT_FILE_SIZE } from './MaxFileSize';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import uuid from 'uuid';

export interface IInjectedProps {
  projectHubService: IProjectHubService;
  projectsService: IProjectsService;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

interface IState {
  isShown: boolean;
  importAnnotations: boolean;
  importImageSets: boolean;
  draftId: string;
  validationError?: string;
  file?: File;
}

@injectProps({
  projectHubService: ProjectHubServiceType,
  projectsService: ProjectsServiceType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
@observer
class ImportContainer extends React.Component<IInjectedProps & RouteComponentProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      isShown: false,
      draftId: '',
      importAnnotations: true,
      importImageSets: true,
    };
  }

  @autobind
  async handleModalHidden() {
    if (this.canCloseModal()) {
      this.setState({ isShown: false });
    }
    if (this.props.projectsService.store.importStage === ImportStage.FINISHED) {
      await this.props.projectsService.refreshAsync();
    }
  }

  @autobind
  async handleGoToProject() {
    this.setState({ isShown: false });
    this.props.history.push(Home.Projects.Details.AnnotationView.withParams({ projectId: this.state.draftId, workspaceId: this.props.currentWorkspaceStore.currentWorkspace!.id }));
  }

  @autobind
  handleModalShown() {
    this.setState({ isShown: true, importAnnotations: true });
    this.props.projectsService.store.importStage = ImportStage.STEP1;
  }

  @autobind
  handleAcceptWarning() {
    this.props.projectsService.store.importStage = ImportStage.STEP2;
  }

  @autobind
  async handleUploadStarted() {
    const draftId = uuid.v4();

    this.setState({ draftId });
    await this.props.projectHubService.initializeAsync();

    this.props.projectsService.store.importStage = ImportStage.PROCESSING;
    await this.props.projectsService.importProjectAsync(draftId, this.state.importAnnotations, this.state.importImageSets, this.state.file!);
    this.setState({ file: undefined });
  }

  @autobind
  handleFileDropped(files: File[]) {
    const file = files[0];

    if (file !== undefined && file.size > MAX_IMPORT_FILE_SIZE) {
      this.setState({ validationError: 'file_size_exceeded' });
      return;
    }

    this.setState({ file: files.length > 0 ? files[0] : undefined, validationError: undefined });
  }

  @autobind
  async handleFilePicked() {
    this.props.projectsService.store.importStage = ImportStage.STEP3;
  }

  @autobind
  handleImportAnnotationChanged() {
    this.setState(prevState => ({ importAnnotations: !prevState.importAnnotations }));
  }

  @autobind
  handleImportImageSetsChanged() {
    this.setState(prevState => ({ importImageSets: !prevState.importImageSets }));
  }

  @autobind
  canCloseModal(): boolean {
    return this.props.projectsService.store.importStage !== ImportStage.PROCESSING;
  }

  render() {
    return (
      <ImportModal
        canCloseModal={this.canCloseModal()}
        fileName={this.state.file?.name}
        isShown={this.state.isShown}
        canUpload={this.state.file !== undefined}
        stage={this.props.projectsService.store.importStage}
        report={this.props.projectsService.store.importReport}
        errorCodes={this.props.projectsService.store.importErrorCodes}
        importAnnotations={this.state.importAnnotations}
        validationError={this.state.validationError}
        warningAccepted={this.handleAcceptWarning}
        modalHidden={this.handleModalHidden}
        modalsShown={this.handleModalShown}
        uploadStarted={this.handleUploadStarted}
        filesDropped={this.handleFileDropped}
        filePicked={this.handleFilePicked}
        goToProject={this.handleGoToProject}
        importAnnotationsChanged={this.handleImportAnnotationChanged}
        importImageSetsChanged={this.handleImportImageSetsChanged}
        importImageSets={this.state.importImageSets}
      />
    );
  }
}

export default as<React.ComponentClass>(withRouter(ImportContainer));
