import { AttachmentRenamedEvent, AttachmentRenamedEventType } from '../../../../modules/attachments/rename/events/AttachmentRenamedEvent';
import { AttachmentType, IAttachment } from '../attachments.store';
import { AttachmentUploadServiceType, IAttachmentUploadService } from '../attachmentUploadService';
import { AttachmentsServiceType, IAttachmentsService } from '../attachments.service';
import { AuthStoreType, IAuthStore } from '../../auth/auth.store';
import { BillingServiceType, IBillingService } from '../../billing/billing.service';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { EventBusType, EventListeningDisposer, IEventBus } from '../../../services/eventBus.service';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../../modules/common/OverlayLoader.store';
import { IReactionDisposer, reaction } from 'mobx';
import { IRouterStore, RouterStoreType } from '../../../stores/router.store';
import { IUserStore, UserStoreType } from '../../user/user.store';
import { IWorkspacesPermissions, WorkspacesPermissionsType } from '../../workspaces/workspaces.permissions';
import { RouteComponentProps, withRouter } from 'react-router';
import { WorkspaceRole, WorkspacesStoreType } from '../../workspaces/workspaces.store';
import { as, injectProps } from '../../../helpers/react.helpers';

import { AttachmentsList } from '../components/AttachmentsList';
import { Home } from '../../../routes/config/Home';
import React from 'react';
import { Settings } from '../../../../modules/settings/SettingsContext';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IOuterProps {
  canAddFolder: boolean;
}

interface IInjectedProps extends RouteComponentProps<{ workspaceId: string; attachmentId: string }> {
  attachmentsService: IAttachmentsService;
  attachmentsUploadService: IAttachmentUploadService;
  authStore: IAuthStore;
  userStore: IUserStore;
  overlayLoaderStore: IOverlayLoaderStore;
  router: IRouterStore;
  billingService: IBillingService;
  workspacesPermissions: IWorkspacesPermissions;
  currentWorkspaceStore: ICurrentWorkspaceStore;
  eventBus: IEventBus;
}

interface IState extends ILoaderState {
  isDeleteConfirmationModalOpen: boolean;
  attachmentToDelete?: IAttachment;
  lastSelectedAttachmentId?: string;
}

@injectProps({
  attachmentsService: AttachmentsServiceType,
  attachmentsUploadService: AttachmentUploadServiceType,
  authStore: AuthStoreType,
  userStore: UserStoreType,
  workspacesStore: WorkspacesStoreType,
  overlayLoaderStore: OverlayLoaderStoreType,
  router: RouterStoreType,
  billingService: BillingServiceType,
  workspacesPermissions: WorkspacesPermissionsType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  eventBus: EventBusType,
})
@observer
class AttachmentListContainer extends WithLoaderComponentBase<IInjectedProps & IOuterProps, IState> {
  static contextType = Settings;
  declare context: React.ContextType<typeof Settings>;
  
  private AttachmentRenamedDisposer?: EventListeningDisposer;

  constructor(props: IInjectedProps & IOuterProps) {
    super(props);

    this.state = {
      isLoading: true,
      isDeleteConfirmationModalOpen: false,
      attachmentToDelete: undefined,
      lastSelectedAttachmentId: undefined,
    };

    this.AttachmentRenamedDisposer = this.props.eventBus.addListener<AttachmentRenamedEvent>(this.handleAttachmentRenamed, AttachmentRenamedEventType);
  }

  // TODO: force currentWorkspaceId change to reload related paths in https://qzsolutions.atlassian.net/browse/S20-1795
  workspaceChangeReactionDisposer: IReactionDisposer = reaction(
    () => this.props.currentWorkspaceStore.currentWorkspace?.id,
    () => {
      const workspaceId = this.props.currentWorkspaceStore.currentWorkspace?.id;
      if (workspaceId) {
        if (!this.props.match.params.attachmentId) {
          if (this.props.workspacesPermissions.canAccessAttachments(workspaceId)) {
            this.loadData();
          }
          this.props.attachmentsService.store.clearSelectedAttachments();
        } else {
          this.props.router.push(Home.Attachments.List.Folder.withParams({ workspaceId, attachmentId: '' }));
        }
      }
    },
  );

  componentWillUnmount() {
    this.workspaceChangeReactionDisposer();
    this.AttachmentRenamedDisposer?.();
  }

  componentDidMount() {
    this.loadData();
  }

  handleAttachmentRenamed = async () => {
    await this.loadData();
  };

  loadData() {
    this.withLoaderAsync(() => this.props.attachmentsService.getAttachmentsAsync(this.props.match.params.attachmentId), 'attachment-list-loader');
  }

  @autobind
  handleFolderSelect(id: string): void {
    this.props.router.push(Home.Attachments.List.Folder.withParams({ workspaceId: this.props.match.params.workspaceId, attachmentId: id }));
    this.props.attachmentsService.store.clearSelectedAttachments();
  }

  @autobind
  handleDeleted(id: string): void {
    this.setState({
      isDeleteConfirmationModalOpen: true,
      attachmentToDelete: this.props.attachmentsService.store.attachments.find(a => a.id === id),
    });
  }

  @autobind
  handleDeleteConfirmed(): void {
    this.props.attachmentsService.deleteAttachmentsAsync(false, [this.state.attachmentToDelete!.id], this.props.match.params.attachmentId);
    this.setState({ isDeleteConfirmationModalOpen: false, attachmentToDelete: undefined });
  }

  @autobind
  handleDeleteCanceled(): void {
    this.setState({ isDeleteConfirmationModalOpen: false, attachmentToDelete: undefined });
  }

  @autobind
  async handleDownloadAttachments(ids: string[]) {
    await this.props.attachmentsUploadService.downloadAttachmentsAsync(false, ids);
  }

  @autobind
  handleAttachmentsSelectionChanged(ids: string[]) {
    this.props.attachmentsService.store.selectedAttachments.ids = ids;
  }

  @autobind
  async handlePaginationChange(pageNumber: number, pageSize: number) {
    await this.withLoaderAsync(() => {
      this.props.attachmentsService.getAttachmentsAsync(this.props.match.params.attachmentId, pageNumber, pageSize), 'attachment-list-loader';
      this.context.setAttachmentsPageSize(pageSize);
    });
  }

  @autobind
  async handleOrderingChange(orderBy: string, orderType: string) {
    const paging = this.props.attachmentsService.store.getPaging(this.props.match.params.workspaceId, this.props.match.params.attachmentId);
    paging.orderBy = orderBy;
    paging.orderType = orderType;
    this.props.attachmentsService.store.updatePaging(paging);
    await this.withLoaderAsync(() => this.props.attachmentsService.getAttachmentsAsync(this.props.match.params.attachmentId), 'attachment-list-loader');
  }

  @autobind
  handleSelectAttachment(id: string) {
    const canView = this.props.attachmentsService.canUseAttachments();
    if (!canView) return;

    this.setState({ lastSelectedAttachmentId: id });

    const selectedAttachments = this.props.attachmentsService.store.selectedAttachments;

    selectedAttachments.ids.includes(id) ? selectedAttachments.ids.splice(selectedAttachments.ids.indexOf(id), 1) : selectedAttachments.ids.push(id);

    this.props.attachmentsService.store.selectedAttachments.ids = selectedAttachments.ids.slice();
  }

  @autobind
  handleSelectAllAttachments() {
    const canView = this.props.attachmentsService.canUseAttachments();
    if (!canView) return;

    const selectedAttachments = this.props.attachmentsService.store.selectedAttachments;

    selectedAttachments.isAllSelected = !selectedAttachments.isAllSelected;
    selectedAttachments.ids = [];
  }

  @autobind
  handleThumbCheck(id: string, isBatch: boolean) {
    if (!isBatch) {
      this.handleSelectAttachment(id);
      return;
    }

    const attachments = this.props.attachmentsService.store.attachments;
    const lastIndex = attachments.findIndex(i => i.id === this.state.lastSelectedAttachmentId);
    const markUpToIndex = attachments.findIndex(i => i.id === id);

    if (lastIndex === undefined || markUpToIndex === undefined || lastIndex === -1 || markUpToIndex === -1) {
      this.handleSelectAttachment(id);
      return;
    }

    const changeDirection = lastIndex < markUpToIndex ? 1 : -1;
    const comparison = lastIndex < markUpToIndex ? (k: number, n: number) => k <= n : (k: number, n: number) => k >= n;

    const selectedAttachments = this.props.attachmentsService.store.selectedAttachments.ids;

    for (let i = lastIndex; comparison(i, markUpToIndex); i += changeDirection) {
      const attachment = attachments[i];
      const idToAdd = attachment.id;

      if (!selectedAttachments.includes(idToAdd)) {
        selectedAttachments.push(idToAdd);
      }
    }
  }

  @autobind
  handleThumbClick(id: string) {
    const attachment = this.props.attachmentsService.store.attachments.find(a => a.id === id);

    if (!attachment) return;

    switch (attachment.type) {
      case AttachmentType.FOLDER:
        this.props.router.push(
          Home.Attachments.List.Folder.withParams({
            workspaceId: this.props.match.params.workspaceId,
            attachmentId: attachment.id,
          }),
        );
        break;
      case AttachmentType.FILE:
        this.props.attachmentsUploadService.downloadAttachmentsAsync(false, [attachment.id], this.props.match.params.attachmentId);
        break;
      case AttachmentType.IMAGE_FILE:
        this.props.router.push(
          Home.Attachments.List.Preview.withParams({
            workspaceId: this.props.match.params.workspaceId,
            attachmentId: attachment.id,
            parentId: attachment.parentId || '',
          }),
        );
        break;
    }
  }

  render() {
    const isLoading =
      this.state.isLoading || this.props.attachmentsUploadService.data.progressData.length > 0 || this.props.overlayLoaderStore.isSpinnerVisible('attachment-list-loader');

    const workspace = this.props.currentWorkspaceStore.currentWorkspace;
    const isOwner = workspace?.role === WorkspaceRole.Owner;
    const canView = this.props.attachmentsService.canUseAttachments();

    const paging = this.props.attachmentsService.store.getPaging(this.props.match.params.workspaceId, this.props.match.params.attachmentId);
    paging.pageSize = this.context.store.attachmentsPageSize;

    return (
      <AttachmentsList
        canAddFolder={this.props.canAddFolder}
        canView={canView}
        isOwner={isOwner}
        breadcrumbs={this.props.attachmentsService.store.breadcrumbs.slice().reverse()}
        attachmentsListViewMode={this.props.userStore.attachmentsListViewMode}
        parentId={this.props.match.params.attachmentId}
        attachments={this.props.attachmentsService.store.attachments}
        currentDownloadAttachments={this.props.attachmentsUploadService.data.currentDownloadAttachment}
        isLoading={isLoading}
        isDeleteConfirmationModalOpen={this.state.isDeleteConfirmationModalOpen}
        attachmentToDelete={this.state.attachmentToDelete}
        workspaceId={this.props.match.params.workspaceId}
        pagination={paging}
        sorting={{
          orderBy: paging.orderBy || '',
          orderType: paging.orderType || '',
        }}
        selectedAttachments={{
          ids: this.props.attachmentsService.store.selectedAttachments.ids,
          isAllSelected: this.props.attachmentsService.store.selectedAttachments.isAllSelected,
        }}
        isDownloadDisabled={workspace?.locked || this.props.billingService.billing.isAnyPolicyLimitExceeded}
        isDeleteRequestInProgress={this.props.attachmentsService.store.isDeleteRequestInProgress}
        authToken={this.props.authStore.token}
        onFolderSelect={this.handleFolderSelect}
        onDownloadAttachments={this.handleDownloadAttachments}
        onAttachmentsSelectionChanged={this.handleAttachmentsSelectionChanged}
        onDeleteCanceled={this.handleDeleteCanceled}
        onDeleteConfirmed={this.handleDeleteConfirmed}
        onDeleted={this.handleDeleted}
        onOrderingChange={this.handleOrderingChange}
        onPaginationChange={this.handlePaginationChange}
        onSelectAttachment={this.handleSelectAttachment}
        onSelectAllAttachments={this.handleSelectAllAttachments}
        onThumbCheck={this.handleThumbCheck}
        onThumbClick={this.handleThumbClick}
        onThumbDelete={this.handleDeleted}
        getThumbnailUrl={this.props.attachmentsService.getAttachmentThumbnailUrl}
      />
    );
  }
}

export default as<React.ComponentClass<IOuterProps>>(withRouter(AttachmentListContainer));
