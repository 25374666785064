import { inject, injectable } from 'inversify';
import _ from 'lodash';
import { sortBy } from '../../helpers/collections.helper';
import { nameOf } from '../../helpers/object.helpers';
import { ForbiddenError, StickerError } from '../../models/error.model';
import { SortingDirection } from '../../models/sortingDirection.model';
import { EventBusType, EventListeningDisposer, IEventBus } from '../../services/eventBus.service';
import { ToastNotification, NotificationLevel } from '../notifications/models/notification.model';
import { NotificationsServiceType, INotificationsService } from '../notifications/services/notifications.service';
import { WorkspaceRole } from '../workspaces/workspaces.store';
import { GetTeamMembersRequest, GetWorkspaceTeamMembersServiceType, IGetTeamMembersService } from './services/getTeamMembers.service';
import { ITeamApiService, TeamApiServiceType } from './services/teamApi.service';
import { ITeamStoreSetter, TeamStoreSetterType } from './team.store';
import { ITeamMember } from './models/TeamMember';
import { CurrentWorkspaceChangedEvent, CurrentWorkspaceChangedEventType } from '../../../modules/workspaces/currentWorkspace/events/CurrentWorkspaceChangedEvent';
import { ICurrentWorkspaceStore, CurrentWorkspaceStoreType } from '../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { TimePeriod } from '../../../design/selects/timePeriodSelector/S_TimePeriodSelector';

export const TeamServiceType = Symbol('TEAM_SERVICE');

const defaultSortingBy: string = nameOf<ITeamMember>('email');
const defaultSortingDirection: SortingDirection = SortingDirection.ASC;

export interface ITeamService {
  initializeAsync(): Promise<void>;
  dispose(): void;
  changeStatisticsTimePeriodAsync(timePeriod: TimePeriod): void;
  refreshStatisticsAsync(): void;
  changeSorting(orderBy: string, orderDirection: SortingDirection): void;
  addUserToWorkspaceAsync(userEmail: string, role: WorkspaceRole): Promise<void | StickerError>;
  changeUserRoleAsync(userEmail: string, role: WorkspaceRole): Promise<void | StickerError>;
  removeUserFromWorkspaceAsync(userEmail: string): Promise<void | StickerError>;
  changePagination(pageNumber: number, pageSize: number): Promise<void>;
}

@injectable()
export class TeamService implements ITeamService {
  private workspaceChangedListenerDisposer?: EventListeningDisposer;

  constructor(
    @inject(TeamStoreSetterType) private readonly teamStore: ITeamStoreSetter,
    @inject(TeamApiServiceType) private readonly teamApiService: ITeamApiService,
    @inject(EventBusType) private readonly eventBus: IEventBus,
    @inject(NotificationsServiceType) private readonly notificationsService: INotificationsService,
    @inject(GetWorkspaceTeamMembersServiceType) private readonly getWorkspaceTeamMembersService: IGetTeamMembersService,
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore,
  ) {}

  async initializeAsync(): Promise<void> {
    this.workspaceChangedListenerDisposer = this.eventBus.addListener(this.currentWorkspaceChangedListener, CurrentWorkspaceChangedEventType);
    await this.getTeamMembersAsync();
  }

  dispose(): void {
    this.workspaceChangedListenerDisposer?.();
  }

  public async addUserToWorkspaceAsync(userEmail: string, role: WorkspaceRole) {
    const result = await this.teamApiService.addUserToWorkspaceAsync({
      userEmail,
      role: role.toString(),
      workspaceId: this.currentWorkspaceStore.currentWorkspace?.id!,
    });

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'adding_user_to_workspace_failed');
      return result;
    }

    await this.getTeamMembersAsync();
  }

  public async changeUserRoleAsync(userEmail: string, role: WorkspaceRole) {
    const result = await this.teamApiService.changeUserRoleAsync({
      userEmail,
      role: role.toString(),
      workspaceId: this.currentWorkspaceStore.currentWorkspace?.id!,
    });

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'role_change_failed', true);
      return result;
    }

    await this.getTeamMembersAsync();
  }

  public async removeUserFromWorkspaceAsync(userEmail: string) {
    const result = await this.teamApiService.removeUserFromWorkspaceAsync({ userEmail, workspaceId: this.currentWorkspaceStore.currentWorkspace?.id! });

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'removing_user_from_workspace_failed');
      return result;
    }

    await this.getTeamMembersAsync();
  }

  public async changeStatisticsTimePeriodAsync(timePeriod: TimePeriod) {
    if (this.teamStore.statisticsTimePeriod !== timePeriod) {
      this.teamStore.setStatisticsTimePeriod(timePeriod);
      await this.getTeamMembersAsync();
    }
  }

  public async refreshStatisticsAsync() {
    await this.getTeamMembersAsync();
  }

  public changeSorting(orderBy: string, orderDirection: SortingDirection): void {
    this.teamStore.setSorting(orderBy, orderDirection);
    this.sortAndSetTeamMembers(this.teamStore.teamMembers);
  }

  private async getTeamMembersAsync() {
    this.teamStore.setIsLoading(true);

    try {
      const workspaceId = this.currentWorkspaceStore.currentWorkspace?.id!;
      const { statisticsTimePeriod } = this.teamStore;

      const request = new GetTeamMembersRequest(workspaceId, statisticsTimePeriod);
      const { teamMembers } = await this.getWorkspaceTeamMembersService.getTeamMembersAsync(request);

      this.sortAndSetTeamMembers(teamMembers);
    } catch (error) {
      this.notifyAboutApiError(error, 'fetching_team_failed');
    }

    this.teamStore.setIsLoading(false);
  }

  private sortAndSetTeamMembers(teamMembers: ITeamMember[]) {
    const sorted = sortBy(teamMembers, this.teamStore.orderBy, this.teamStore.orderDirection, defaultSortingBy, defaultSortingDirection);
    this.teamStore.setTeamMembers(sorted);
  }

  private notifyAboutApiError(result: any, errorCode: string, dontShowBadRequest: boolean = false) {
    if (result instanceof StickerError && !(result instanceof ForbiddenError) && (!dontShowBadRequest || !result.isBadRequest())) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, errorCode));
    }
  }

  async changePagination(pageNumber: number, pageSize: number): Promise<void> {
    this.teamStore.setPagination(pageNumber, pageSize);
  }

  currentWorkspaceChangedListener = (event: CurrentWorkspaceChangedEvent) => {
    if (!event.willBeNavigatedToDifferentPath) this.getTeamMembersAsync();
  };
}
