import { IUpdateWorkspaceTimeZoneBl, UpdateWorkspaceTimeZoneBlType } from '../UpdateWorkspaceTimeZone.bl';
import { IUpdateWorkspaceTimeZoneStore, UpdateWorkspaceTimeZoneStoreType } from '../UpdateWorkspaceTimeZone.store';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import React from 'react';
import { UpdateWorkspaceTimeZoneModal } from '../components/UpdateWorkspaceTimeZoneModal';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps {
  store: IUpdateWorkspaceTimeZoneStore;
  bl: IUpdateWorkspaceTimeZoneBl;
}

@injectProps({
  store: UpdateWorkspaceTimeZoneStoreType,
  bl: UpdateWorkspaceTimeZoneBlType,
})
@observer
class UpdateWorkspaceTimeZoneModalContainerPure extends React.Component<IInjectedProps> {
  @autobind
  private handleHideModal() {
    this.props.bl.hideForm();
  }

  render() {
    return <UpdateWorkspaceTimeZoneModal onHideModal={this.handleHideModal} showModal={this.props.store.showModal} />;
  }
}

export const UpdateWorkspaceTimeZoneModalContainer = as<React.ComponentClass>(UpdateWorkspaceTimeZoneModalContainerPure);
