import { LatLng, LatLngBounds, LeafletEvent } from 'leaflet';

import { AnnotationToolType } from './submodules/annotationTypes/models/annotationToolType';
import { DatasetStatus } from '../datesets/datasetStatus.model';
import { Feature } from 'geojson';
import { IAnnotationTypeOptions } from './submodules/annotationTypes/models/annotationTypeOptions';
import { IQuestionModel } from './question.model';

export interface ISegmentation {
  id?: string;
  feature: ISegmentationFeature;
  questions: IQuestionModel[];
  latlngs: LatLng[] | LatLng[][];
  bbox: LatLngBounds;
  priority: number;
}

export enum NoImageReason {
  Unknown = 'Unknown',
  QueueError = 'QueueError',
  Credits = 'Credits',
  NoMoreImages = 'NoMoreImages',
  Locked = 'Locked',
}

export interface IAnnotationProgress {
  you: number;
  all: number;
  totalImages: number;
}

export interface IReviewProgress {
  allAccepted: number;
  allRejected: number;
  totalImages: number;
  youAccepted: number;
  youRejected: number;
  awaitingReview: number;
}

export const LOW_QUALITY_PRELOAD_MIN_IMAGE_SIZE = 0.5;

export interface ISegmentationFeature extends Feature<any> {
  id: string;
  color: string;
  featureType: AnnotationToolType;
}

export interface IAnnotationDto {
  id: string;
  questions: IQuestionModelDto[];
  segmentations: ISegmentationDto[];
}

export interface ISegmentationDto {
  id?: string;
  questions: IQuestionModelDto[];
  feature: Feature;
  priority: number;
}

export interface IQuestionModelDto {
  id: string;
  answer?: string;
  answers: IAnswerModelDto[];
}

export interface IAnswerModelDto {
  id: string;
  selected: boolean;
}

export interface IImage {
  lowQualityUrl: string;

  id: string;
  name: string;
  url: string;
  height: number;
  width: number;
  annotations?: IAnnotationResponse;
  size: number;
  dataSetName: string;
  datasetStatus: DatasetStatus;
  lockedByName: string;
  canAddAnnotations: boolean;
}

export interface IAnnotationResponse {
  id: string;
  projectId: string;
  annotations: IAnnotationDto;
  status: AnnotationStatus;
  author: string;
  reviewedByName: string;
  reviewRejectionReason?: string;
  isAssignedToCurrentUser: boolean;
  isAssigned: boolean;
  isImported: boolean;
  isWaitingForReviewAfterReject: boolean;
}

export enum AnnotationStatus {
  NONE = 'None',
  DRAFT = 'Draft',
  REJECTED = 'Rejected',
  TOREVIEW = 'ToReview',
  ACCEPTED = 'Accepted',
  DISCARDED = 'Discarded',
}

export enum BRUSH_MODE {
  DRAW = 'DRAW',
  ERASE = 'ERASE',
}

export const FREE_DRAW_TOOLS: AnnotationToolType[] = [AnnotationToolType.BRUSH];

export interface IBrushTypeOptions extends IAnnotationTypeOptions {
  brushDiameter: number;
  brushMode: BRUSH_MODE;
  eraserAvailable: boolean;
  onDiameterChange(radius: number): void;
}

export enum DRAWING_STATES {
  NOT_DRAWING = 'NOT_DRAWING',
  DRAWING_BY_DRAGGING = 'DRAWING_BY_DRAGGING',
  DRAWING_BY_CLICKING = 'DRAWING_BY_CLICKING',
}

export interface MouseLeafletEvent extends LeafletEvent {
  latlng: LatLng;
  originalEvent: MouseEvent;
}

export interface KeyboardLeafletEvent extends LeafletEvent {
  originalEvent: KeyboardEvent;
}

export const MOUSE_EVENTS = {
  CLICK: 'click',
  DOUBLE_CLICK: 'dblclick',
  MOUSE_DOWN: 'mousedown',
  MOUSE_MOVE: 'mousemove',
  MOUSE_UP: 'mouseup',
  CONTEXT_MENU: 'contextmenu',
  MOUSE_OVER: 'mouseover',
  MOUSE_OUT: 'mouseout',
  MOUSE_LEAVE: 'mouseleave ',
  WHEEL: 'wheel',
};

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
export const MOUSE_BUTTON = {
  LEFT: 0,
  RIGHT: 2,
  MIDDLE: 1,
};

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/buttons
export enum MOUSE_BUTTONS {
  NONE = 0,
  LEFT = 1,
  RIGHT = 2,
  MIDDLE = 4,
}

export const KEYBOARD_EVENTS = {
  KEY_DOWN: 'keydown',
  KEY_UP: 'keyup',
};

export const ICON_SIZE: number = 7;
export const ICON_MARKER_SIZE: number = 12;
