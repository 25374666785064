import * as React from 'react';

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../workspaces/currentWorkspace/CurrentWorkspace.store';
import { IRouterStore, RouterStoreType } from '../../../__legacy__/stores/router.store';
import { as, injectProps } from '../../../__legacy__/helpers/react.helpers';

import { Button } from 'reactstrap';
import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../__legacy__/containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { EnableForRole } from '../../../__legacy__/containers/EnableForRole';
import { ITranslatable } from '../../../__legacy__/helpers/translations.helpers';
import { PageHeader } from '../../../__legacy__/components/PageHeader';
import { ModelPermissions } from '../../../__legacy__/modules/workspaces/workspaces.store';
import { EvaluationsTabs } from './EvaluationsTabs';
import { withNamespaces } from 'react-i18next';
import autobind from 'autobind-decorator';
import { Home } from '../../../__legacy__/routes/config/Home';
import { IEvaluationsService, EvaluationsServiceType } from '../evaluations.service';

interface IInjectedProps extends ITranslatable {
  evaluationsService: IEvaluationsService;
  currentWorkspaceStore: ICurrentWorkspaceStore;
  routerStore: IRouterStore;
}

@injectProps({
  evaluationsService: EvaluationsServiceType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  routerStore: RouterStoreType,
})
class EvaluationsHeaderPure extends React.Component<IInjectedProps> {
  @autobind
  async handleRunANewEvaluationClicked() {
    this.props.routerStore.push(Home.Evaluations.Create.Settings.withParams({ workspaceId: this.props.currentWorkspaceStore.currentWorkspace!.id }));
  }

  render() {
    return (
      <div>
        <PageHeader id="evaluations-header" headerText={this.props.t('evaluations_header')} tabNavPresent="with-tab-nav" tabNav={EvaluationsTabs}>
          <div className="text-right evaluations-header-right d-flex align-items-end flex-column">
            <EnableForRole workspaceRoles={ModelPermissions.runInference} projectRoles={[]}>
              <DisabledWhenWorkspaceOwnerPolicyExceeded>
                <Button onClick={this.handleRunANewEvaluationClicked} color="primary">
                  {this.props.t('run_new_evaluation')}
                </Button>
              </DisabledWhenWorkspaceOwnerPolicyExceeded>
            </EnableForRole>
          </div>
        </PageHeader>
      </div>
    );
  }
}

export const EvaluationsHeader = as<React.ComponentClass>(withNamespaces('evaluations')(EvaluationsHeaderPure));
