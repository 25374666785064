import * as React from 'react';

import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { ClipLoader } from 'react-spinners';
import { ConfirmationModal } from './ConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IDatasetsUploadDropdownProps } from '../containers/DatasetsUploadDropdown.container';
import { ITranslatable } from '../helpers/translations.helpers';
import { ProgressBar } from './ProgressBar';
import { as } from '../helpers/react.helpers';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { getProgressPercent } from '../helpers/number.helpers';
import { handleClickAndPassData } from '../helpers/formHelpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

library.add(faTimes);

const DropdownItemWithProgressBar = (props: {
  progress: number;
  id: string;
  name: string;
  hasAnyError: boolean;
  onCancelDatasetUploading(datasetId: string): void;
}) => (
  <DropdownItem tag="div" toggle={false} key={props.id} className="datasets-single-item">
    <div className="top">
      <div className="name text-left">{props.name}</div>
      <div className="current-progress text-right">{props.progress}%</div>
    </div>
    <div className="bottom">
      <ProgressBar success={!props.hasAnyError} value={props.progress} />
      <div onClick={handleClickAndPassData(props.onCancelDatasetUploading)(props.id)}>
        <FontAwesomeIcon icon={faTimes} color="red" />
      </div>
    </div>
  </DropdownItem>
);

const DatasetsUploadDropdownPure = (props: IDatasetsUploadDropdownProps & ITranslatable) => {
  return (
    <div className="top-nav-item">
      {props.queue.length > 0 && (
        <UncontrolledDropdown className="top-nav-dropdown datasets-upload">
          <DropdownToggle caret>
            <ClipLoader loading={true} size={14} /> <span>{props.t('datasets_upload')}</span>
          </DropdownToggle>
          <DropdownMenu right>
            {props.queue.map(ds =>
              DropdownItemWithProgressBar({
                progress: getProgressPercent(ds.uploadedCount, ds.allCount),
                id: ds.dataSetId,
                name: ds.dataSetName,
                hasAnyError: ds.hasAnyError,
                onCancelDatasetUploading: ds.onShowConfirmationModal,
              }),
            )}
          </DropdownMenu>
          <ConfirmationModal
            showModal={props.showModal}
            onConfirm={props.onConfirmCancel}
            onCancel={props.onResume}
            confirmationHeader="datasets:cancel_dataset_upload"
            confirmationQuestion="datasets:dataset_upload_cancelation_confirmation_question"
          />
        </UncontrolledDropdown>
      )}
    </div>
  );
};

export const DatasetsUploadDropdown = as<React.ComponentClass>(
  observer(withNamespaces('datasets', { wait: true })(DatasetsUploadDropdownPure)),
);
