import { CheckboxDropdownMultiSelect } from '../../../../../../../components/CheckboxDropdownMultiSelect';
import { FilterContent } from '../FilterContent';
import { ITranslatable } from '../../../../../../../helpers/translations.helpers';
import { IUserFilterOption } from '../../../imageFilters.model';
import { ImageFiltersFormProps } from '../ImageFiltersForm';
import React from 'react';
import { as } from '../../../../../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

@observer
class ReviewedByBodyPure extends React.Component<ImageFiltersFormProps & ITranslatable> {
  @autobind
  handleSelectedChanged(selected: IUserFilterOption[]) {
    this.props.onSetReviewedBy(selected.map(x => x.id));
  }

  render() {
    const options = this.props.availableFilters.reviewedBy;
    const selected = options.filter(d => this.props.imageFilters.reviewedBy.some(x => x === d.id));

    return (
      <FilterContent labelKey={'reviewed_by'}>
        <CheckboxDropdownMultiSelect<IUserFilterOption>
          className={'combo-container'}
          options={options}
          values={selected}
          labelField={'email'}
          valueField={'id'}
          onSelectChange={this.handleSelectedChanged}
          t={this.props.t}
        />
      </FilterContent>
    );
  }
}

export const ReviewedByBody = as<React.ComponentClass<ImageFiltersFormProps>>(withNamespaces('common')(ReviewedByBodyPure));
