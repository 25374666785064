import { Alert } from './Alert';
import { StickerEvent } from '../../../__legacy__/services/eventBus.service';

export const AlertsUpdatedEventType = Symbol('ALERTS_UPDATED_EVENT');

export class AlertsUpdatedEvent extends StickerEvent {
  constructor(public readonly alerts: Alert[]) {
    super();
  }

  get type(): Symbol {
    return AlertsUpdatedEventType;
  }
}
