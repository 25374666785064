import * as React from 'react';

import Dropzone from 'react-dropzone';
import { FileList } from '../../../components/FileList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRejectedFile } from '../models/IRejectedFile';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { PacmanLoader } from 'react-spinners';
import { RejectStatus } from '../models/RejectStatus';
import { as } from '../../../helpers/react.helpers';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';

library.add(faCheck);

export interface IImageDropZoneProps {
  onFilesDrop(files: File[]): void;
  acceptedFiles: File[];
  rejectedFiles: IRejectedFile[];
  isProcessingImages: boolean;
  acceptedFileTypes: string;
  disabled?: boolean;
}

interface IProps extends IImageDropZoneProps {
  children: React.ReactNode | React.ReactNode[];
}

export const ImagesDropZone = as<React.SFC<IImageDropZoneProps>>(
  withNamespaces('datasets')((props: IProps & ITranslatable) => {
    function getRejectedDescription(rejectedStatus: RejectStatus) {
      return props.t(rejectedStatus);
    }

    return (
      <>
        <Dropzone
          disabled={props.disabled}
          className="dropzone-generic"
          activeClassName="active"
          onDropRejected={props.onFilesDrop}
          onDropAccepted={props.onFilesDrop}
          accept={props.acceptedFileTypes}
        >
          {props.children}
        </Dropzone>
        {props.isProcessingImages && (
          <div className="pacman-loader">
            <span className="pacman-loader-caption">{props.t('processing_your_images')}</span>
            <PacmanLoader color="#1F2046" />
          </div>
        )}
        {((props.rejectedFiles.length > 0 || props.acceptedFiles.length > 0) && !props.isProcessingImages) && (
          <div className="file-list-container">
            <p className="valid">
              <FontAwesomeIcon icon="check" /> <strong>{props.acceptedFiles.length}</strong>{' '}
              {props.t('files_ready_to_upload')}
            </p>
            <p className="invalid">
              <FontAwesomeIcon icon="times" /> <strong>{props.rejectedFiles.length} </strong> {props.t('files_invalid')}
              {props.rejectedFiles.length > 0 && <span>:</span>}
            </p>
            <FileList
              items={props.rejectedFiles.map(f => ({
                name: f.file.name,
                description: getRejectedDescription(f.status),
              }))}
            />
          </div>
        )}
      </>
    );
  }),
);
