import { IUserService, UserServiceType } from '../../../user.service';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { CountryRow } from '../../../components/personalInformation/rows/CountryRow';
import React from 'react';
import countryList from 'react-select-country-list';
import { observer } from 'mobx-react';

interface IInjectProps {
  userService: IUserService;
}

@injectProps({
  userService: UserServiceType,
})
@observer
class CountryRowContainerPure extends React.Component<IInjectProps> {
  countryOptions: any;

  constructor(props: IInjectProps) {
    super(props);
    this.countryOptions = countryList().getData();
  }

  handleChange = (country: string) => {
    const { fullName, company } = this.props.userService.data.userInfo;
    this.props.userService.updateUserDetails(fullName, company, country);
  };

  render() {
    return <CountryRow countryOptions={this.countryOptions} country={this.props.userService.data.userInfo.country} onChange={this.handleChange} />;
  }
}

export const CountryRowContainer = as<React.ComponentClass>(CountryRowContainerPure);
