import React, { useState } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import { AnnotationToolsStatsTableContainer } from './table/AnnotationToolsStatsTable.container';
import { S_CollapseSection } from '../../../../../design/sections/collapseSection/S_CollapseSection';
import { WorkPointsRulesModal } from './workPointsRulesModal/WorkPointsRulesModal';
import { as } from '../../../../../__legacy__/helpers/react.helpers';

interface IProps {
  endDate: moment.Moment;
  startDate: moment.Moment;
}

const AnnotationToolsSectionPure: React.FC<IProps & WithNamespaces> = (props: IProps & WithNamespaces) => {
  const { startDate, endDate, t } = props;
  const subtitle = `(${startDate.format('DD MMMM yyyy')} - ${endDate.format('DD MMMM yyyy')})`;
  const [isWorkPointsRulesModalOpen, setIsWorkPointsRulesModalOpen] = useState<boolean>(false);
  const toggle = () => setIsWorkPointsRulesModalOpen(!isWorkPointsRulesModalOpen);
  const handleInfoIconClick = (e: MouseEvent) => {
    e.stopPropagation();
    toggle();
  };
  
  return (
    <>
      <S_CollapseSection
        title={t('projects.details.stats.annotation_tools.annotation_tools')}
        subtitle={subtitle}
        showInfoIcon
        infoIconTooltip={t('projects.details.stats.annotation_tools.rules_modal_tooltip')}
        onInfoIconClick={handleInfoIconClick}
      >
        <AnnotationToolsStatsTableContainer />
      </S_CollapseSection>
      <WorkPointsRulesModal show={isWorkPointsRulesModalOpen} toggle={toggle} />
    </>
  );
};

export const AnnotationToolsSection = as<React.ComponentClass<IProps>>(withNamespaces('new')(AnnotationToolsSectionPure));
