import { AlertBarStoreType, IAlertBarStore } from '../../../../modules/alertBar/AlertBar.store';
import { AnnotationsStoreType, IAnnotationsStore } from '../../annotation/annotations.store';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { IImage } from '../../annotation/annotations.interface';
import React from 'react';
import { alertBarHeight } from '../../../../modules/alertBar/ui/AlertBar';
import { observer } from 'mobx-react';

export interface IAnnotationPreviewRenderProps {
  marginTop: number;
  image?: IImage;
}

interface IAnnotationPreviewContainerProps {
  children(props: IAnnotationPreviewRenderProps): React.ReactNode;
}

interface IInjectedProps extends RouteComponentProps<{ projectId: string }> {
  alertsBarStore: IAlertBarStore;
  annotationsStore: IAnnotationsStore;
}

@injectProps({
  alertsBarStore: AlertBarStoreType,
  annotationsStore: AnnotationsStoreType,
})
@observer
class AnnotationPreviewContainerPure extends React.Component<IAnnotationPreviewContainerProps & IInjectedProps> {
  render() {
    return (
      <div>
        {this.props.children({
          marginTop: alertBarHeight(this.props.alertsBarStore.alerts.length),
          image: this.props.annotationsStore.image,
        })}
      </div>
    );
  }
}

export const AnnotationPreviewContainer = as<React.ComponentClass<IAnnotationPreviewContainerProps>>(withRouter(AnnotationPreviewContainerPure));
