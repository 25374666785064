import * as React from 'react';

import { IRouterStore, RouterStoreType } from '../stores/router.store';
import { as, injectProps } from '../helpers/react.helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GlobalHotKeys } from 'react-hotkeys';
import { ITranslatable } from '../helpers/translations.helpers';
import { LinkedButton } from './LinkedButton';
import { withNamespaces } from 'react-i18next';

export interface IImageNavigationProps {
  previousLink: string;
  nextLink: string;
  total: number;
  current: number;
  isLoading: boolean;
  disabled: boolean;
  callback?: () => void;
}

interface IInjectedProps {
  router: IRouterStore;
}

const ImageNavigationPure = (props: IImageNavigationProps & ITranslatable & IInjectedProps) => {
  const goTo = (to: string | false) => () => {
    if (props.disabled) return;
    props.callback && props.callback();
    if (to) props.router.push(to);
  };

  return (
    <div className="image-navigation">
      <GlobalHotKeys
        allowChanges={true}
        keyMap={{
          LEFT: { sequence: 'left', action: 'keyup' },
          RIGHT: { sequence: 'right', action: 'keyup' },
        }}
        handlers={{
          LEFT: goTo((props.current > 1 && !props.isLoading) && props.previousLink),
          RIGHT: goTo((props.current < props.total && !props.isLoading) && props.nextLink),
        }}
      />
      <LinkedButton
        to={props.previousLink}
        color="primary"
        className="p-2"
        disabled={props.current <= 1 || props.isLoading || props.disabled}
        callback={props.callback}
      >
        <FontAwesomeIcon icon="angle-left" className="mr-1 ml-0" /> <span className="description">{props.t('previous')}</span>
      </LinkedButton>
      <span>
        {props.current} {props.t('of')} {props.total}
      </span>
      <LinkedButton
        to={props.nextLink}
        color="primary"
        className="p-2"
        disabled={props.current >= props.total || props.isLoading || props.disabled}
        callback={props.callback}
      >
        <span className="description">{props.t('next')}</span> <FontAwesomeIcon icon="angle-right" className="mr-0 ml-1" />
      </LinkedButton>
    </div>
  );
};

export const ImageNavigation = as<React.StatelessComponent<IImageNavigationProps>>(
  withNamespaces('common', { wait: true })(injectProps({ router: RouterStoreType })(ImageNavigationPure)),
);
