import './S_IconSelectOption.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { OptionProps } from 'react-select/lib/components/Option';
import React from 'react';
import { components } from 'react-select';

export interface S_IconSelectOptionProps {
  label: string;
  value: any;
  icon?: IconProp;
}

const Option = components.Option;

export const S_IconSelectOption: React.FC<OptionProps<S_IconSelectOptionProps>> = (props: OptionProps<S_IconSelectOptionProps>) => (
  <Option {...props} className="s_icon-select-option">
    {props.data?.icon && <FontAwesomeIcon className="s_icon-select-option-icon" icon={props.data.icon} />}
    {props.label}
  </Option>
);
