import * as React from 'react';

import { CustomInput, FormGroup } from 'reactstrap';

import { ClampLines } from '../../../../components/ClampLines';
import { IAnswerProps } from '../../question.model';
import { handleClickAndPassData } from '../../../../helpers/formHelpers';
import { observer } from 'mobx-react';

const empty = () => {};

export const AnswerWithRadioButton = observer(({ disabled, name, answer, onAnswered: onAnswerSelect }: IAnswerProps) => (
  <FormGroup check title={answer.text}>
    <CustomInput
      type="radio"
      name={name}
      id={answer.id}
      value={answer.id}
      checked={answer.selected ?? false}
      onClick={handleClickAndPassData(onAnswerSelect)(answer.id)}
      onChange={empty}
      disabled={disabled}
    >
      <ClampLines onClick={handleClickAndPassData(onAnswerSelect)(answer.id)} id={`answer-${answer.id}`} text={answer.text} lines={3} innerElement={'p'} />
    </CustomInput>
  </FormGroup>
));
