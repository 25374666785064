import { injectable } from 'inversify';
import { observable } from 'mobx';

export const ProjectOverviewStoreType = Symbol('PROJECT_OVERVIEW_STORE');

export interface IProjectOverviewStore {
  waitingForAnnotationCount: number;
  draftsCount: number;
  toReviewCount: number;
  rejectedCount: number;
  acceptedCount: number;
  unclearCount: number;
  totalImageCount: number;
  publishMoreDatasets: boolean;
  unclearImageDate?: string;
  unclearImageId?: string;
  unclearImagesFilterId?: string;
}

@injectable()
export class ProjectOverviewStore implements IProjectOverviewStore {
  @observable
  acceptedCount: number = 0;
  
  @observable
  draftsCount: number = 0;
  
  @observable
  rejectedCount: number = 0;
  
  @observable
  toReviewCount: number = 0;
  
  @observable
  totalImageCount: number = 0;
  
  @observable
  unclearCount: number = 0;
  
  @observable
  waitingForAnnotationCount: number = 0;
  
  @observable
  publishMoreDatasets: boolean = false;
  
  @observable
  unclearImageDate?: string = undefined;
  
  @observable
  unclearImageId?: string = undefined;
  
  @observable
  unclearImagesFilterId?: string = undefined;
}
