import './AnnotationImageNameHeader.scss';

import React from 'react';
import { WarningIconBadge } from '../../../components/badges/iconBadges/WarningIconBadge';

interface IProps {
  datasetName: string;
  imageName: string;
  showWarning: boolean;
  warningTooltip: string;
}

export const AnnotationImageNameHeader: React.FC<IProps> = (props: IProps) => {
  const { imageName, datasetName, showWarning = false, warningTooltip } = props;

  return (
    <h1 title={`${imageName}${datasetName ? ` (${datasetName})` : ''}`}>
    {imageName}{' '}
    {datasetName && (
      <span className="subtitle">
        ({showWarning && <WarningIconBadge label="" title={warningTooltip} />}
        {datasetName})
      </span>
    )}
  </h1>
  );
};
