import { injectable, inject } from 'inversify';
import { AttachmentsStoreType, IAttachmentsStore, IAttachment, IAttachmentBreadcrumb } from './attachments.store';
import { IPagedResult } from '../../services/api.service';
import { StickerError, ForbiddenError, UnauthorizedError, LockedUserError } from '../../models/error.model';
import { NotificationsServiceType, INotificationsService } from '../notifications/services/notifications.service';
import { NotificationLevel, ToastNotification } from '../notifications/models/notification.model';
import { IApiService } from '../../services/api.service.base';
import { action } from 'mobx';
import { Plan } from '../user/user.store';
import { ICurrentWorkspaceStore, CurrentWorkspaceStoreType } from '../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { ApiServiceImageUploadType } from '../../services/api.service.imageUpload';

export const AttachmentsServiceType = Symbol('ATTACHMENTS_SERVICE');

interface IAttachmentsPayload {
  attachments: IPagedResult<IAttachment>;
  ancestors: IAttachmentBreadcrumb[];
}

interface IAddAttachmentDirectoryPayload {
  workspaceId: string;
  parentId?: string;
  name: string;
}

interface IDeleteAttachmentsPayload {
  workspaceId: string;
  allAttachments: boolean;
  parentId?: string;
  attachmentIds: string[];
}

interface ISelectedAttachmentsPayload {
  workspaceId: string;
  allAttachments: boolean;
  parentId?: string;
  attachmentIds: string[];
}

export interface IAttachmentsService {
  getAttachmentsAsync(parentId?: string, pageNumber?: number, pageSize?: number): Promise<void | StickerError>;
  getAttachmentThumbnailUrl(attachmentId: string): string;
  getSelectedAttachments(allAttachments: boolean, attachmentIds: string[], parentId?: string): Promise<number | StickerError>;
  addAttachmentFolderAsync(folderName: string, parentId?: string, pageNumber?: number): Promise<void | StickerError>;
  deleteAttachmentsAsync(allAttachments: boolean, attachmentIds: string[], parentId?: string): Promise<void | StickerError>;
  toggleDeleteAttachmentModal(show?: boolean): void;
  canUseAttachments(): boolean;
  store: IAttachmentsStore;
}

@injectable()
export class AttachmentsService implements IAttachmentsService {
  constructor(
    @inject(AttachmentsStoreType) public readonly store: IAttachmentsStore,
    @inject(ApiServiceImageUploadType) private readonly apiServiceImageUpload: IApiService,
    @inject(NotificationsServiceType) private readonly notificationsService: INotificationsService,
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore,
  ) {}

  canUseAttachments(): boolean {
    const workspace = this.currentWorkspaceStore.currentWorkspace;
    const canUseAttachments = workspace?.ownerPlanTier === Plan.Enterprise || workspace?.ownerPlanTier === Plan.Professional || workspace?.ownerPlanTier === Plan.Admin || false;
    return canUseAttachments;
  }

  @action
  async getAttachmentsAsync(parentId?: string, pageNumber?: number, pageSize?: number): Promise<void | StickerError> {
    const workspaceId = this.currentWorkspaceStore.currentWorkspace?.id;
    if (!workspaceId) return;

    const paging = this.store.getPaging(workspaceId, parentId);
    const updatedPageNumber = pageNumber ?? paging.pageNumber;
    const updatedPageSize = pageSize ?? paging.pageSize;

    const params = {
      workspaceId,
      parentId: parentId ? parentId : null,
      pageNumber: updatedPageNumber,
      pageSize: updatedPageSize,
      orderBy: paging.orderBy,
      orderType: paging.orderType,
    };

    const result = await this.apiServiceImageUpload.getAsync<IAttachmentsPayload>('/Attachments', { params });

    if (this.handleResponseError(result, 'attachment:get_attachments_failed')) return;

    const payload = result as IAttachmentsPayload;

    if (payload.attachments.totalCount > 0 && payload.attachments.pagesCount < payload.attachments.pageNumber) {
      await this.getAttachmentsAsync(parentId, payload.attachments.pagesCount - 1);
    } else {
      this.store.attachments = payload.attachments.data;
      this.store.breadcrumbs = payload.ancestors;

      this.store.updatePaging({
        ...paging,
        pageSize: updatedPageSize,
        pageNumber: payload.attachments.pageNumber,
        pagesCount: payload.attachments.pagesCount,
        totalCount: payload.attachments.totalCount,
      });
    }
  }

  @action.bound
  getAttachmentThumbnailUrl(attachmentId: string): string {
    return this.apiServiceImageUpload.getUrl(`/Attachments/thumbnail/${attachmentId}`);
  }

  @action
  async getSelectedAttachments(allAttachments: boolean, attachmentIds: string[], parentId?: string): Promise<number | StickerError> {
    const workspaceId = this.currentWorkspaceStore.currentWorkspace!.id;
    const result = await this.apiServiceImageUpload.postAsync<ISelectedAttachmentsPayload, number | StickerError>('/Attachments/selectedAttachments', {
      workspaceId,
      allAttachments,
      parentId,
      attachmentIds,
    });

    return result;
  }

  @action
  async addAttachmentFolderAsync(name: string, parentId?: string): Promise<void | StickerError> {
    const workspaceId = this.currentWorkspaceStore.currentWorkspace!.id;
    const result = await this.apiServiceImageUpload.postAsync<IAddAttachmentDirectoryPayload, void | StickerError>('/Attachments/addFolder', {
      workspaceId,
      parentId,
      name,
    });

    if (!(result instanceof StickerError)) {
      await this.getAttachmentsAsync(parentId);
    }

    return result;
  }

  @action
  async deleteAttachmentsAsync(allAttachments: boolean, attachmentIds: string[], parentId?: string): Promise<void | StickerError> {
    const workspaceId = this.currentWorkspaceStore.currentWorkspace!.id;
    this.store.isDeleteRequestInProgress = true;
    const result = await this.apiServiceImageUpload.postAsync<IDeleteAttachmentsPayload, void | StickerError>('/Attachments/deleteAttachments', {
      workspaceId,
      allAttachments,
      parentId,
      attachmentIds,
    });

    this.store.isDeleteRequestInProgress = false;

    if (this.handleResponseError(result, 'attachment:delete_attachments_failed')) return;

    this.store.clearSelectedAttachments();
    this.store.showDeleteConfirmationModal = false;

    await this.getAttachmentsAsync(parentId);
  }

  @action
  toggleDeleteAttachmentModal(show?: boolean): void {
    if (show !== undefined) {
      this.store.showDeleteConfirmationModal = show;
    } else {
      this.store.showDeleteConfirmationModal = !this.store.showDeleteConfirmationModal;
    }
  }

  private handleResponseError(result: any, notification: string): boolean {
    if (result instanceof StickerError) {
      if (result instanceof UnauthorizedError || result instanceof ForbiddenError || result instanceof LockedUserError) {
        throw result;
      }

      if (!result.isBadRequestWithCode(['USING_ATTACHMENTS_NOT_ALLOWED'])) {
        this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, notification));
      }

      return true;
    }

    return false;
  }
}
