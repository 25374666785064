import * as React from 'react';

import { ILoaderState, WithLoaderComponentBase } from '../../../../../helpers/loader.helpers';
import { IProjectDetailsToolsBl, IProjectDetailsToolsBlType } from '../projectDetailsTools.bl';
import { ToolType, mapToolType } from '../helpers/tools.helpers';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { AnnotationDiscardConfirmModal } from '../components/AnnotationDiscardConfirmModal';
import { IQuestion } from '../projectDetailsTools.models';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { ImageScopeName } from '../../../../annotation/question.model';
import { QuestionModalContainer } from './QuestionModal.container';
import { ToolDeleteModal } from '../components/ToolDeleteModal';
import { ToolDeleteStrategy } from '../../../projectDetails.models';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  projectDetailsToolsBl: IProjectDetailsToolsBl;
}

interface IOuterProps {
  questionId: string;
  isReadonly: boolean;
  isAttribute: boolean;
  onSubmit(): void;
  onCancel(): void;
  showEditModal: boolean;
  modalHeader: string;
}

interface IState extends ILoaderState {
  editedQuestion: IQuestion | undefined;
  strategy: ToolDeleteStrategy;
  deletedAnswerCount: number;
  showDiscardConfirmModal: boolean;
  showModal: boolean;
}

@injectProps({ projectDetailsToolsBl: IProjectDetailsToolsBlType })
@observer
class EditQuestionContainerPure extends WithLoaderComponentBase<IInjectedProps & IOuterProps & ITranslatable, IState> {
  constructor(props: IInjectedProps & IOuterProps & ITranslatable) {
    super(props);

    this.state = {
      editedQuestion: undefined,
      strategy: ToolDeleteStrategy.None,
      showDiscardConfirmModal: false,
      deletedAnswerCount: 0,
      isLoading: false,
      showModal: false,
    };
  }

  @autobind
  async handleSubmit(question: IQuestion) {
    const answersAndScopesDiff = this.getDeletedScopesAndAnswers(question);
    const deletedAnswers = answersAndScopesDiff[0];
    const deletedScopes = answersAndScopesDiff[1];

    if (deletedAnswers.length || deletedScopes.length) {
      await this.withLoaderAsync(async () => {
        await this.props.projectDetailsToolsBl.getQuestionAnswersAndScopesUsageAsync(question.id, deletedAnswers, deletedScopes);
      });
      const isUsed = this.props.projectDetailsToolsBl.store.toolsUsage?.imageCount || 0 > 0;

      if (isUsed) {
        this.setState({ showModal: true, deletedAnswerCount: deletedAnswers.length, editedQuestion: question });
        return;
      }
    }

    await this.withLoaderAsync(async () => {
      await this.props.projectDetailsToolsBl.updateQuestionAsync(question, this.state.strategy);
      this.props.onSubmit();
    }, 'submit-question-form-button');
  }
  
  @autobind
  getDeletedScopesAndAnswers(question: IQuestion) {
    const existingQuestion = this.props.projectDetailsToolsBl.store.questions.find(s => s.id === this.props.questionId)!;

    const existingAnswerIds = existingQuestion.answers.map(x => x.id);
    const newAnswersIds = question.answers.map(x => x.id);

    const deletedAnswers = existingAnswerIds.filter(x => !newAnswersIds.includes(x));
    const deletedScopes = existingQuestion.scopes.filter(x => !question.scopes.includes(x));

    return [deletedAnswers, deletedScopes];
  };

  @autobind
  async handleUpdateConfirmed() {
    if (this.state.strategy === ToolDeleteStrategy.Discard) {
      const answersAndScopesDiff = this.getDeletedScopesAndAnswers(this.state.editedQuestion!);
      const deletedAnswers = answersAndScopesDiff[0];
      const deletedScopes = answersAndScopesDiff[1];

      await this.props.projectDetailsToolsBl.getQuestionAnswersAndScopesAffectAsync(this.state.editedQuestion!.id, deletedAnswers, deletedScopes);
      this.setState({ showDiscardConfirmModal: true });
      return;
    }

    this.setState({ showModal: false });
    await this.withLoaderAsync(async () => {
      await this.props.projectDetailsToolsBl.updateQuestionAsync(this.state.editedQuestion!, this.state.strategy);
      this.props.onSubmit();
    });
  }

  @autobind
  async handleUpdateWithDiscardConfirmed() {
    this.setState({ showDiscardConfirmModal: false, showModal: false });
    await this.withLoaderAsync(async () => {
      await this.props.projectDetailsToolsBl.updateQuestionAsync(this.state.editedQuestion!, this.state.strategy);
      this.props.onSubmit();
    }, 'tool-delete-confirm-button');
  }

  @autobind
  async handleCancel() {
    if (!this.state.isLoading) {
      this.setState({ showModal: false });
    }
  }

  @autobind
  async handleStrategyChange(strategy: ToolDeleteStrategy) {
    this.setState({ strategy });
  }

  @autobind
  async handleCancelDiscard() {
    if (!this.state.isLoading) {
      this.setState({ showDiscardConfirmModal: false });
    }
  }

  render() {
    const questions = this.props.projectDetailsToolsBl.store.questions;
    const question = questions.find(s => s.id === this.props.questionId);
    const toolUsage = this.props.projectDetailsToolsBl.store.toolsUsage;
    const toolAffect = this.props.projectDetailsToolsBl.store.toolAffect;

    return (
      <>
        <QuestionModalContainer
          question={question}
          isReadonly={this.props.isReadonly}
          questionsCount={questions.length}
          selectedQuestionTypes={questions.filter(q => q.scopes.includes(ImageScopeName)).map(q => q.type)}
          existingQuestionsAndAttributes={questions.map(q => ({ text: q.questionText, type: q.type }))}
          onSubmit={this.handleSubmit}
          onCancel={this.props.onCancel}
          isAttribute={this.props.isAttribute}
          isLoading={this.state.isLoading}
          showModal={this.props.showEditModal}
          modalHeader={this.props.modalHeader}
        />
        <ToolDeleteModal
          strategy={this.state.strategy}
          showModal={this.state.showModal}
          onConfirm={this.handleUpdateConfirmed}
          onCancel={this.handleCancel}
          onStrategyChange={this.handleStrategyChange}
          toolType={mapToolType(this.props.isAttribute ? ToolType.Attribute : ToolType.Question)}
          annotationsCount={toolUsage?.imageCount || 0}
          objectsCount={toolUsage?.occurrenceCount || 0}
        />
        <AnnotationDiscardConfirmModal
          onCancel={this.handleCancelDiscard}
          onConfirm={this.handleUpdateWithDiscardConfirmed}
          showModal={this.state.showDiscardConfirmModal}
          annotationsCount={toolUsage?.imageCount || 0}
          objectsCount={toolAffect?.objectsCount || 0}
          questionsCount={toolAffect?.questionsCount || 0}
          attributesCount={toolAffect?.attributesCount || 0}
        />
      </>
    );
  }
}

export const EditQuestionContainer = as<React.ComponentClass<IOuterProps>>(withNamespaces('project', { wait: true })(EditQuestionContainerPure));
