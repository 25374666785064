import * as React from 'react';

import { Col, Row } from 'reactstrap';

import { DashboardContainer } from '../../../containers/DashboardContainer';
import { PrivacyPolicyContainer } from '../../../modules/user/containers/PrivacyPolicyContainer';
import { UserDetailsHeader } from '../../../modules/user/components/UserDetailsHeader';
import UserDetailsWidget from '../../../modules/user/components/personalInformation/PersonalInformationWidget';
import UserDetailsWidgetContainer from '../../../modules/user/containers/personalInformations/PersonalInformationWidget.container';
import UserPlanWidget from '../../../modules/user/components/UserPlanWidget';
import UserPlanWidgetContainer from '../../../modules/user/containers/UserPlanWidget.container';

const Header = () => <UserDetailsHeader />;

export const UserDetailsAccountView = () => (
  <DashboardContainer header={Header}>
    <div>
      <Row>
        <Col className="d-flex">
          <UserDetailsWidgetContainer>
            <UserDetailsWidget />
          </UserDetailsWidgetContainer>
        </Col>
        <Col className="d-flex">
          <UserPlanWidgetContainer>
            <UserPlanWidget />
          </UserPlanWidgetContainer>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex">
          <PrivacyPolicyContainer />
        </Col>
      </Row>
    </div>
  </DashboardContainer>
);
