import './UnregisteredUsersSummaryPane.scss';

import React, { useState } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IImportAnnotationUnregisteredUser } from '../../../../models/ImportAnnotationsReport';
import { ProjectRole } from '../../../../../../../../../__legacy__/models/userRole.model';

interface Props {
  unregisteredUsers: IImportAnnotationUnregisteredUser[];
}

const UnregisteredUsersSummaryPanePure: React.FC<Props & WithNamespaces> = props => {
  const { t, unregisteredUsers } = props;
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const toggle = () => setIsOpened(!isOpened);

  const mapUserRoles = (roles: string[]): string => {
    let result = '';
    if (roles.length === 2) result += `${ProjectRole.Annotator} ${t('and ')} ${ProjectRole.Reviewer}`;
    else if (roles.includes(ProjectRole.Annotator)) result += ProjectRole.Annotator;
    else if (roles.includes(ProjectRole.Reviewer)) result += ProjectRole.Reviewer;

    return result;
  };

  return (
    <>
      <p className="collapsible" onClick={toggle}>
        {unregisteredUsers.length} {t('new.projects.details.import_annotations.steps.summary.user', { count: unregisteredUsers.length })}
        <span className="fail"> {t('new.projects.details.import_annotations.steps.summary.not_found')}</span> <FontAwesomeIcon icon={isOpened ? faCaretUp : faCaretDown} />
      </p>
      <Collapse isOpen={isOpened} className="users-summary">
        <ul>
          {unregisteredUsers.map((user: any) => (
            <li key={user.email}>
              {user.email} - {mapUserRoles(user.roles)}
            </li>
          ))}
        </ul>
      </Collapse>
    </>
  );
};

export const UnregisteredUsersSummaryPane = withNamespaces()(UnregisteredUsersSummaryPanePure);
