import { injectable, inject } from 'inversify';
import { ApiServiceType } from '../../../../../services/api.service';
import { StickerError } from '../../../../../models/error.model';
import { IApiService } from '../../../../../services/api.service.base';
import { IToolAffect, IToolUsage } from '../projectDetailsTools.models';
import { ToolDeleteStrategy } from '../../../projectDetails.models';

export const AnnotationTypesApiServiceType = Symbol('PROJECT_DETAILS_ANNOTATION_TYPES_API_SERVICE');

export interface IAddAnnotationTypeRequest {
  projectId: string;
  annotationTypeId: string;
  name: string;
  color: string;
  order: number;
  selectorType: string;
}

export interface IUpdateAnnotationTypeRequest {
  id: string;
  name: string;
  color: string;
  order: number;
  selectorType: string;
}

export interface IUpdateAnnotationTypeOrderingRequest {
  projectId: string;
  toolOrders: { id: string, order: number }[];
}

export interface IGetAnnotationToolUsageRequest {
  annotationTypeId: string;
}

export interface IDeleteAnnotationTypeRequest {
  projectId: string;
  annotationTypeId: string;
  strategy: ToolDeleteStrategy;
}

export interface ICheckAnnotationTypeNameUniquenessRequest {
  projectId: string;
  annotationTypeId: string;
  name: string;
}

export interface IGetAnnotationToolAffectRequest {
  annotationTypeId: string;
}

export interface IAnnotationTypesApiService {
  addAnnotationTypeAsync(request: IAddAnnotationTypeRequest): Promise<void | StickerError>;
  updateAnnotationTypeAsync(request: IUpdateAnnotationTypeRequest): Promise<void | StickerError>;
  updateAnnotationTypesOrderAsync(request: IUpdateAnnotationTypeOrderingRequest): Promise<void | StickerError>;
  getAnnotationTypeUsage(request: IGetAnnotationToolUsageRequest): Promise<IToolUsage | StickerError>;
  deleteAnnotationTypeAsync(request: IDeleteAnnotationTypeRequest): Promise<void | StickerError>;
  checkAnnotationTypeNameUniqueness(request: ICheckAnnotationTypeNameUniquenessRequest): Promise<boolean | StickerError>;
  getAnnotationTypeAffect(request: IGetAnnotationToolAffectRequest): Promise<IToolAffect | StickerError>;
}

@injectable()
export class AnnotationTypesApiService implements IAnnotationTypesApiService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService) { }

  async addAnnotationTypeAsync(request: IAddAnnotationTypeRequest): Promise<void | StickerError> {
    return this.apiService.postAsync<IAddAnnotationTypeRequest, void>('/AnnotationTypes/Add', request);
  }

  async updateAnnotationTypeAsync(request: IUpdateAnnotationTypeRequest): Promise<void | StickerError> {
    return this.apiService.postAsync<IUpdateAnnotationTypeRequest, void>('/AnnotationTypes/Update', request);
  }

  async updateAnnotationTypesOrderAsync(request: IUpdateAnnotationTypeOrderingRequest): Promise<void | StickerError> {
    return this.apiService.postAsync<IUpdateAnnotationTypeOrderingRequest, void>('/AnnotationTypes/UpdateAnnotationTypeOrdering', request);
  }

  async getAnnotationTypeUsage(request: IGetAnnotationToolUsageRequest): Promise<IToolUsage | StickerError> {
    return this.apiService.getAsync<IToolUsage>('/AnnotationTypes/GetUsage', { params: request });
  }

  async deleteAnnotationTypeAsync(request: IDeleteAnnotationTypeRequest): Promise<void | StickerError> {
    return this.apiService.postAsync<IDeleteAnnotationTypeRequest, void>('/AnnotationTypes/Delete', request);
  }

  async checkAnnotationTypeNameUniqueness(request: ICheckAnnotationTypeNameUniquenessRequest): Promise<boolean | StickerError> {
    return this.apiService.getAsync<boolean>('/AnnotationTypes/CheckAnnotationTypeNameUniqueness', { params: request });
  }

  async getAnnotationTypeAffect(request: IGetAnnotationToolAffectRequest): Promise<IToolAffect | StickerError> {
    return this.apiService.getAsync<IToolAffect>('/AnnotationTypes/GetAffect', { params: request });
  }
}
