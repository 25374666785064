import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../../../../modules/common/OverlayLoader.store';
import { IProjectDetailsStatisticsBl, ProjectDetailsStatisticsBlType } from '../projectDetailsStatistics.bl';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { AnnotationToolType } from '../../../../annotation/submodules/annotationTypes/models/annotationToolType';
import { CustomScrollbar } from '../../../../../components/CustomScrollbar';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { Loader } from '../../../../../components/Loader';
import React from 'react';
import { ToolStatisticsViewMode } from '../projectDetailsStatistics.model';
import { ToolTypeIcon } from '../../../../../components/ToolTypeIcon';
import { WithLoaderComponentBase } from '../../../../../helpers/loader.helpers';
import autobind from 'autobind-decorator';
import { handleClickAndPassData } from '../../../../../helpers/formHelpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  projectDetailsStatisticsBl: IProjectDetailsStatisticsBl;
  overlayLoader: IOverlayLoaderStore;
}

type Props = IInjectedProps & RouteComponentProps<{ projectId: string }> & ITranslatable;

@injectProps({
  projectDetailsStatisticsBl: ProjectDetailsStatisticsBlType,
  overlayLoader: OverlayLoaderStoreType,
})
@observer
class ToolsStatisticsSidebarContainerPure extends WithLoaderComponentBase<Props> {
  async componentDidMount() {
    await this.withLoaderAsync(async () => {
      this.props.projectDetailsStatisticsBl.resetToolStatistics();
      await this.props.projectDetailsStatisticsBl.getProjectToolStatisticsAsync(this.props.match.params.projectId);
      if (this.props.projectDetailsStatisticsBl.store.toolStatistics.headers.annotationToolsHeaders.length > 0) {
        await this.selectAllAnnotationType();
      } else if (this.props.projectDetailsStatisticsBl.store.toolStatistics.headers.annotationQuestionHeaders.length > 0) {
        await this.selectAllQuestions();
      } else if (this.props.projectDetailsStatisticsBl.store.toolStatistics.headers.imageSetsHeaders.length > 0) {
        await this.selectAllImageSets();
      }
    }, 'tool-statistics-loading');
  }

  @autobind
  async selectAnnotationType(annotationTypeId: string) {
    await this.props.projectDetailsStatisticsBl.statisticsSelectAnnotationType(annotationTypeId);
  }

  @autobind
  async selectQuestion(questionId: string) {
    await this.props.projectDetailsStatisticsBl.statisticsSelectQuestion(questionId);
  }

  @autobind
  async selectImageSet(imageSetId: string) {
    await this.props.projectDetailsStatisticsBl.statisticsSelectImageSet(imageSetId);
  }

  @autobind
  async selectAllAnnotationType() {
    await this.props.projectDetailsStatisticsBl.statisticsSelectAllAnnotationType();
  }

  @autobind
  async selectAllQuestions() {
    await this.props.projectDetailsStatisticsBl.statisticsSelectAllQuestions();
  }

  @autobind
  async selectAllImageSets() {
    await this.props.projectDetailsStatisticsBl.statisticsSelectAllImageSets();
  }

  render() {
    const store = this.props.projectDetailsStatisticsBl.store;
    const statisticHeaders = store.toolStatistics.headers;
    const toolStatisticsViewMode = store.toolStatisticsViewMode;
    const selectedId = store.selectedToolStatistics?.annotationStatusStatistics.id;
    const isLoading = this.props.overlayLoader.isSpinnerVisible('tool-statistics-loading');

    return (
      <div className="sidebar">
        <Loader isLoading={isLoading} />
        <CustomScrollbar autoHide>
          {statisticHeaders.annotationToolsHeaders.length > 0 && <h6>{this.props.t('tools')}</h6>}
          <ul className="list-group list-group-flush">
            {statisticHeaders.annotationToolsHeaders.length > 0 && (
              <button
                type="button"
                className={`list-group-item list-group-item-action ${toolStatisticsViewMode === ToolStatisticsViewMode.All_TOOLS ? 'active' : ''}`}
                onClick={this.selectAllAnnotationType}
              >
                {this.props.t('all_tools')} ({statisticHeaders.annotationToolsHeaders.map(x => x.usageCount).reduce((a, b) => a + b, 0)})
              </button>
            )}
            {statisticHeaders.annotationToolsHeaders
              .slice()
              .sort((x, y) => x.order - y.order)
              .map(at => (
                <button
                  key={at.id}
                  type="button"
                  className={`list-group-item list-group-item-action indented-item ${selectedId === at.id ? 'active' : ''}`}
                  onClick={handleClickAndPassData(this.selectAnnotationType)(at.id)}
                >
                  <ToolTypeIcon toolType={at.type as AnnotationToolType} /> {at.name} ({at.usageCount})
                </button>
              ))}
          </ul>

          {statisticHeaders.annotationQuestionHeaders.length > 0 && <h6>{this.props.t('questions')}</h6>}
          <ul className="list-group list-group-flush">
            {statisticHeaders.annotationQuestionHeaders.length > 0 && (
              <button
                type="button"
                className={`list-group-item list-group-item-action ${toolStatisticsViewMode === ToolStatisticsViewMode.ALL_QUESTIONS ? 'active' : ''}`}
                onClick={this.selectAllQuestions}
              >
                {this.props.t('all_questions')} ({statisticHeaders.annotationQuestionHeaders.map(x => x.usageCount).reduce((a, b) => a + b, 0)})
              </button>
            )}
            {statisticHeaders.annotationQuestionHeaders
              .slice()
              .sort((x, y) => x.order - y.order)
              .map(q => (
                <button
                  key={q.id}
                  type="button"
                  className={`list-group-item list-group-item-action indented-item ${selectedId === q.id ? 'active' : ''}`}
                  onClick={handleClickAndPassData(this.selectQuestion)(q.id)}
                >
                  {q.name} ({q.usageCount})
                </button>
              ))}
          </ul>

          {statisticHeaders.imageSetsHeaders?.length > 0 && <h6>{this.props.t('sets')}</h6>}

          <ul className="list-group list-group-flush">
            {statisticHeaders.imageSetsHeaders?.length > 0 && (
              <button
                type="button"
                className={`list-group-item list-group-item-action ${toolStatisticsViewMode === ToolStatisticsViewMode.ALL_IMAGE_SETS ? 'active' : ''}`}
                onClick={this.selectAllImageSets}
              >
                {this.props.t('all_image_sets')} ({statisticHeaders.imageSetsHeaders.map(x => x.count).reduce((a, b) => a + b, 0)})
              </button>
            )}
            {statisticHeaders.imageSetsHeaders?.slice().map(is => (
              <button
                key={is.id}
                type="button"
                className={`list-group-item list-group-item-action indented-item ${selectedId === is.id ? 'active' : ''}`}
                onClick={handleClickAndPassData(this.selectImageSet)(is.id)}
              >
                {this.props.t(`image_set_options.${is.name}`)} ({is.count})
              </button>
            ))}
          </ul>
        </CustomScrollbar>
      </div>
    );
  }
}

export const ToolsStatisticsSidebarContainer = as<React.ComponentClass>(withRouter(withNamespaces('common', { wait: true })(ToolsStatisticsSidebarContainerPure)));
