// tslint:disable:function-name
// tslint:disable:variable-name

import 'leaflet-draw';

import { DRAWING_STATES, ICON_MARKER_SIZE, ICON_SIZE, MOUSE_BUTTON, MOUSE_EVENTS, MouseLeafletEvent } from '../../annotations.interface';
import leaflet, { DrawEvents, DrawOptions, LatLng, LeafletEvent, LeafletEventHandlerFn, Map, Marker } from 'leaflet';

import { AddVectorVertexCommand } from '../../undoRedoCommands/addVectorVertexCommand';
import { Feature } from 'geojson';
import { IUndoRedoHistory } from '../../undoRedoHistory.service';
import autobind from 'autobind-decorator';
import { getSimplifiedGeoJSON } from '../../../../helpers/geometry/polygon.helpers';

@autobind
export class DrawVector extends leaflet.Draw.Polyline implements IDrawer {
  private readonly undoRedoHistory: IUndoRedoHistory;
  private readonly onDrawCreated: (geojson: Feature) => void;

  private drawState: DRAWING_STATES = DRAWING_STATES.NOT_DRAWING;
  private waitUntilCursorLeaveMarkerSquare: boolean = false;
  _disableMarkers = true;

  constructor(
    map: Map,
    onDrawCreated: (geojson: Feature) => void,
    undoRedoHistory: IUndoRedoHistory,
    options?: DrawOptions.PolylineOptions,
  ) {
    super(map, options);
    this.onDrawCreated = onDrawCreated;
    this.undoRedoHistory = undoRedoHistory;
  }

  public enable() {
    if (!this.enabled()) {
      super.enable();
    }
    return this;
  }

  public disable() {
    if (this.enabled()) {
      super.disable();
      this.handleDrawStop();
    }
    this.undoRedoHistory.clearDrawingHistory();
    return this;
  }

  public disableMouseMarkerFocus() {
    this._mouseMarker = leaflet.marker(this._map.getCenter(), {
      icon: leaflet.divIcon({
        className: 'leaflet-mouse-marker',
        iconAnchor: [20, 20],
        iconSize: [40, 40],
      }),
      opacity: 0,
      zIndexOffset: this.options.zIndexOffset,
      keyboard: false,
    });
  }

  public addHandlers() {
    this._map.on(MOUSE_EVENTS.MOUSE_DOWN, this.handleMouseDown as LeafletEventHandlerFn);
    this._map.on(MOUSE_EVENTS.MOUSE_UP, this.handleMouseUp as LeafletEventHandlerFn);
    this._map.on(MOUSE_EVENTS.MOUSE_MOVE, this.handleMouseMove as LeafletEventHandlerFn);

    this._map.on(leaflet.Draw.Event.DRAWSTOP, this.handleDrawStop);
    this._map.on(leaflet.Draw.Event.DRAWVERTEX, this.handleDrawVertex);

    this._map.on(leaflet.Draw.Event.CREATED, this.handleDrawCreated as LeafletEventHandlerFn);
  }

  public removeHandlers() {
    this._map.off(MOUSE_EVENTS.MOUSE_DOWN, this.handleMouseDown as LeafletEventHandlerFn);
    this._map.off(MOUSE_EVENTS.MOUSE_UP, this.handleMouseUp as LeafletEventHandlerFn);
    this._map.off(MOUSE_EVENTS.MOUSE_MOVE, this.handleMouseMove as LeafletEventHandlerFn);

    this._map.off(leaflet.Draw.Event.DRAWSTOP, this.handleDrawStop);
    this._map.off(leaflet.Draw.Event.DRAWVERTEX, this.handleDrawVertex);

    this._map.off(leaflet.Draw.Event.CREATED, this.handleDrawCreated as LeafletEventHandlerFn);
  }

  handleMouseUp(e: MouseLeafletEvent) {
    if (e.originalEvent.button !== MOUSE_BUTTON.LEFT) return;

    if (this.waitUntilCursorLeaveMarkerSquare && this.getMarkersCount() === 1) {
      this.waitUntilCursorLeaveMarkerSquare = false;
      this.drawState = DRAWING_STATES.DRAWING_BY_CLICKING;
      return;
    }

    if (this.drawState === DRAWING_STATES.DRAWING_BY_DRAGGING) {
      this.addVertexWrapper(e.latlng);
      this.drawState = DRAWING_STATES.NOT_DRAWING;
    } else {
      this.drawState = DRAWING_STATES.DRAWING_BY_CLICKING;
      return;
    }

    this._finishShape();
  }

  handleMouseDown(e: MouseLeafletEvent) {
    if (e.originalEvent.button !== MOUSE_BUTTON.LEFT) return;

    this.waitUntilCursorLeaveMarkerSquare = true;

    switch (this.drawState) {
      case DRAWING_STATES.NOT_DRAWING:
      case DRAWING_STATES.DRAWING_BY_CLICKING:
        this.addVertexWrapper(e.latlng);
        this.drawState = DRAWING_STATES.DRAWING_BY_DRAGGING;
        break;
    }
  }

  handleMouseMove(e: MouseLeafletEvent) {
    if (this.waitUntilCursorLeaveMarkerSquare) {
      const distance = this._calculateFinishDistance(e.latlng);
      if (distance < ICON_SIZE) return;
      this.waitUntilCursorLeaveMarkerSquare = false;
    }
  }

  handleDrawStop() {
    this.drawState = DRAWING_STATES.NOT_DRAWING;
  }

  handleDrawVertex() {
    this._markers.slice(0, -1).forEach(m => this._markerGroup.removeLayer(m));
  }

  addLayer(marker: leaflet.Marker<any>) {
    this._markerGroup.addLayer(marker);
  }

  addVertexWrapper(latlng: LatLng) {
    if (this.getMarkersCount() === 2) return;

    this.addVertex(latlng);
    this.undoRedoHistory.addCommand(new AddVectorVertexCommand(this, latlng, this._markers));
  }

  getMarkersCount(): number {
    return this._markers.length;
  }

  handleDrawCreated(e: DrawEvents.Created & LeafletEvent): void {
    const simplifiedGeojson = getSimplifiedGeoJSON(e.layer);
    if (!simplifiedGeojson) return;

    if (this._markers.length < 2) return;
    this.onDrawCreated(simplifiedGeojson);
  }

  _createMarker(latlng: LatLng) {
    const marker = new Marker(latlng, {
      icon: leaflet.divIcon({
        iconAnchor: [ICON_MARKER_SIZE / 2, ICON_MARKER_SIZE / 2],
        iconSize: [ICON_MARKER_SIZE, ICON_MARKER_SIZE],
      }),
      opacity: 1,
      zIndexOffset: this.options.zIndexOffset || 0 * 2,
      keyboard: false,
    });

    this._markerGroup.addLayer(marker);
    return marker;
  }

  _calculateFinishDistance(latlng: LatLng) {
    if (!this._markers) this.enable(); // z jakiegoś powodu disable usuwa część zmiennych, enable je przywraca
    return super._calculateFinishDistance(latlng);
  }
}
