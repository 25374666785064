import { ImportAnnotationsBl, ImportAnnotationsBlType } from '../../../importAnnotations.bl';
import { ImportAnnotationsStore, ImportAnnotationsStoreType } from '../../../importAnnotations.store';

import { ImportAnnotationsStep } from '../../../models/ImportAnnotationsSteps';
import React from 'react';
import { ResolveConflictsStepModal } from './ResolveConflictsStepModal';
import { as } from '../../../../../../../../common/dependencyInjection/as';
import { injectProps } from '../../../../../../../../__legacy__/helpers/react.helpers';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: ImportAnnotationsBl;
  store: ImportAnnotationsStore;
}

@injectProps({
  bl: ImportAnnotationsBlType,
  store: ImportAnnotationsStoreType,
})
@observer
class ResolveConflictsStepContainerPure extends React.Component<InjectedProps> {
  handleHide = () => {
    this.props.bl.cancelImport();
  };

  handleSubmit = async () => {
    await this.props.bl.importAnnotationsAsync();
  };

  handleKeepAll = () => this.props.bl.keepAllAnnotations();
  handleReplaceAll = () => this.props.bl.replaceAllAnnotations();

  get isCurrentStep() {
    return this.props.store.currentStep === ImportAnnotationsStep.ResolveConflicts;
  }

  render() {
    return (
      <ResolveConflictsStepModal
        show={this.isCurrentStep}
        onHide={this.handleHide}
        onSubmit={this.handleSubmit}
        conflicts={this.props.store.conflicts!}
        keepAll={this.handleKeepAll}
        replaceAll={this.handleReplaceAll}
      />
    );
  }
}

export const ResolveConflictsStepContainer = as<React.ComponentClass>(ResolveConflictsStepContainerPure);
