﻿import * as React from 'react';

import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { IWorkspaceMember, WorkspaceRole } from '../../workspaces/workspaces.store';

import { ButtonWithLoader } from '../../../components/ButtonWithLoader';
import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { InputStatus } from '../../../models/error.model';
import { ProjectRole } from '../../../models/userRole.model';
import { RestrictAccessTo } from '../../../containers/RestrictAccessTo';
import Select from 'react-select';
import { ValidatedSelectInput } from '../../../components/ValidatedSelectInput';
import { as } from '../../../helpers/react.helpers';
import { handleSelectChange } from '../../../helpers/formHelpers';
import { withNamespaces } from 'react-i18next';

interface IUsersFormProps {
  users: IWorkspaceMember[];
  email: string;
  emailStatus: InputStatus;
  roles: ProjectRole[];
  selectedRole: ProjectRole;
  onEmailChange(email: string): void;
  onRoleChange(role: ProjectRole): void;
  validateEmail(email: string): void;
  onAddNewUser(): void;
}

export const UsersForm = as<React.StatelessComponent<IUsersFormProps>>(
  withNamespaces('project')((props: IUsersFormProps & ITranslatable) => {
    const options = props.users.map(u => ({ label: u.email, value: u.email }));
    const selected = options.find(u => u.value === props.email);

    return (
      <RestrictAccessTo workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Trainer]} projectRoles={[]} inProject={''}>
        <Form className="custom-inline-form add-new-user">
          <Row>
            <Col md={3}>
              <ValidatedSelectInput
                id="user-dropdown"
                name="select"
                value={selected || null}
                placeholder={props.t('email_address')}
                onChange={handleSelectChange(props.onEmailChange)}
                options={options}
                label={props.t('email_address')}
                isValid={props.emailStatus.isValid}
                feedbacks={props.emailStatus.errorCodes}
              />
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>{props.t('invitation_select_role')}</Label>
                <Select
                  id="user-role-dropdown"
                  value={{ label: props.selectedRole, value: props.selectedRole }}
                  onChange={handleSelectChange(props.onRoleChange)}
                  options={props.roles.map(o => ({ label: o, value: o }))}
                  classNamePrefix="custom-select"
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label>&nbsp;</Label>
                <DisabledWhenWorkspaceOwnerPolicyExceeded>
                  <ButtonWithLoader id="add-user-button" color="primary" onClick={props.onAddNewUser} disabled={props.emailStatus.isValid === false} loaderKey="add-user-button">
                    {props.t('add')}
                  </ButtonWithLoader>
                </DisabledWhenWorkspaceOwnerPolicyExceeded>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </RestrictAccessTo>
    );
  }),
);
