import React from 'react';
import { Progress } from 'reactstrap';
import ClassNames from 'classnames';

interface IProps {
  disableGrowAnimation?: boolean;
  success?: boolean;
  value: number;
  max?: number;
  enableStripesAnimation?: boolean;
}

export class ProgressBar extends React.Component<IProps> {
  render() {
    return (
      <Progress
        striped={this.props.enableStripesAnimation}
        animated={this.props.enableStripesAnimation}
        value={this.props.value}
        max={this.props.max}
        color={this.props.success !== undefined ? this.props.success ? 'success' : 'warning' : ''}
        barClassName={ClassNames(
          {
            'no-transition': this.props.disableGrowAnimation,
          },
          'progressBar',
        )}
      />
    );
  }
}
