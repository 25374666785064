import { IProjectsListThumbsProps, ProjectsListThumbs } from './ProjectsListThumbs';
import ProjectsListTable, { IProjectsListTableProps } from './ProjectsListTable';

import DeleteProjectConfirmationModal from './DeleteProjectConfirmationModal';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { ListViewMode } from '../../user/user.store';
import React from 'react';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IProjectsListProps extends IProjectsListTableProps, IProjectsListThumbsProps {
  projectsListViewMode: ListViewMode;
  showDeleteModal: boolean;
  projectDatasetsToDelete: string[];
  onDeleteConfirmed(deleteDatasets: boolean): void;
  onDeleteCanceled(): void;
}

class ProjectsListPure extends React.Component<IProjectsListProps & ITranslatable> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <>
        {this.props.projectsListViewMode === ListViewMode.List ?
          <ProjectsListTable
            workspaceId={this.props.workspaceId}
            projects={this.props.projects}
            pagination={this.props.pagination}
            sorting={this.props.sorting}
            onDelete={this.props.onDelete}
            onAnnotate={this.props.onAnnotate}
            onReview={this.props.onReview}
            onOrderingChange={this.props.onOrderingChange}
            onGoToClarifications={this.props.onGoToClarifications}
          /> :
          <ProjectsListThumbs
            projects={this.props.projects}
            projectsListViewMode={this.props.projectsListViewMode}
            onDelete={this.props.onDelete}
            workspaceId={this.props.workspaceId}
            onGoToClarifications={this.props.onGoToClarifications}
            pagination={this.props.pagination}
          />
        }
        <DeleteProjectConfirmationModal
          onCancel={this.props.onDeleteCanceled}
          onConfirm={this.props.onDeleteConfirmed}
          showModal={this.props.showDeleteModal}
          projectDatasetsToDelete={this.props.projectDatasetsToDelete}
        />
      </>
    );
  }
}

export const ProjectsList = as<React.ComponentClass<IProjectsListProps>>(withNamespaces('common', { wait: true })(ProjectsListPure));
