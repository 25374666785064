import { AnnotationApiService, AnnotationApiServiceType, IAnnotationApiService } from './annotationApi.service';
import { AnnotationCreationService, AnnotationCreationServiceType, IAnnotationCreationService } from './annotationCreation.service';
import { AnnotationImagesQueueService, AnnotationImagesQueueServiceType } from './annotationImagesQueue.service';
import { AnnotationPermissions, AnnotationPermissionsType, IAnnotationPermissions } from './annotation.permissions';
import { AnnotationReviewService, AnnotationReviewServiceType, IAnnotationReviewService } from './annotationReview.service';
import { AnnotationService, AnnotationServiceType, IAnnotationService } from './annotation.service';
import { AnnotationUiStore, AnnotationUiStoreType, IAnnotationUiStore } from './annotationUi.store';
import { AnnotationsStore, AnnotationsStoreType, IAnnotationsStore } from './annotations.store';
import { ContainerModule, interfaces } from 'inversify';
import { FreeDrawSegmentationService, FreeDrawSegmentationServiceType, IFreeDrawSegmentationService } from './freeDrawSegmentation.service';
import { IUndoRedoHistory, UndoRedoHistory, UndoRedoHistoryType } from './undoRedoHistory.service';
import { IUndoRedoHistoryQueue, UndoRedoHistoryQueue, UndoRedoHistoryQueueType } from './undoRedoHistoryQueue.service';
import { ReviewImagesQueueService, ReviewImagesQueueServiceType } from './reviewImagesQueue.service';

import { IImagesQueueService } from './imagesQueueServiceBase';
import { annotationReviewModule } from './submodules/review/annotationReview.module';
import { annotationTypeModule } from './submodules/annotationTypes/annotationType.module';
import { reviewRejectReasonsModule } from './submodules/reviewRejectReasones/reviewRejectReasons.module';
import { segmentationsModule } from './submodules/segmentations/segmentationContextMenu/segmentations.module';

const annotationModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IAnnotationApiService>(AnnotationApiServiceType)
    .to(AnnotationApiService)
    .inSingletonScope();
  bind<IAnnotationUiStore>(AnnotationUiStoreType)
    .to(AnnotationUiStore)
    .inSingletonScope();
  bind<IAnnotationReviewService>(AnnotationReviewServiceType)
    .to(AnnotationReviewService)
    .inSingletonScope();
  bind<IAnnotationService>(AnnotationServiceType)
    .to(AnnotationService)
    .inSingletonScope();
  bind<IAnnotationCreationService>(AnnotationCreationServiceType)
    .to(AnnotationCreationService)
    .inSingletonScope();
  bind<IUndoRedoHistoryQueue>(UndoRedoHistoryQueueType)
    .to(UndoRedoHistoryQueue)
    .inSingletonScope();
  bind<IUndoRedoHistory>(UndoRedoHistoryType)
    .to(UndoRedoHistory)
    .inSingletonScope();
  bind<IImagesQueueService>(AnnotationImagesQueueServiceType)
    .to(AnnotationImagesQueueService)
    .inTransientScope();
  bind<IImagesQueueService>(ReviewImagesQueueServiceType)
    .to(ReviewImagesQueueService)
    .inTransientScope();
  bind<IFreeDrawSegmentationService>(FreeDrawSegmentationServiceType)
    .to(FreeDrawSegmentationService)
    .inSingletonScope();

  bind<IAnnotationsStore>(AnnotationsStoreType)
    .to(AnnotationsStore)
    .inSingletonScope();

    bind<IAnnotationPermissions>(AnnotationPermissionsType)
    .to(AnnotationPermissions)
    .inSingletonScope();
});

export const annotationModules: ContainerModule[] = [
  annotationTypeModule,
  annotationReviewModule,
  annotationModule,
  segmentationsModule,
  reviewRejectReasonsModule
];
