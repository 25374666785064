import { StickerError } from '../../../../../models/error.model';
import { injectable, inject } from 'inversify';
import { ApiServiceType } from '../../../../../services/api.service';
import { IApiService } from '../../../../../services/api.service.base';
import { IRejectionReason } from '../models/rejectionReason';

export const RejectionReasonServiceType = Symbol('REJECTION_REASON_SERVICE');

export class GetRejectionReasonRequest {
  projectId: string;

  constructor(
    projectId: string,
  ) {
    this.projectId = projectId;
  }
}

export interface IRejectionReasonService {
  getRejectionReasons(request: GetRejectionReasonRequest): Promise<IRejectionReason[] | StickerError>;
}

@injectable()
export class RejectionReasonService implements IRejectionReasonService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService) { }

  async getRejectionReasons(request: GetRejectionReasonRequest): Promise<IRejectionReason[] | StickerError> {
    return await this.apiService.getAsync<IRejectionReason[]>('/annotation/RejectionReasons', { params: request });
  }
}
