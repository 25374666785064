import { injectable, inject } from 'inversify';
import { IApiService } from '../../services/api.service.base';
import { AttachmentsPreviewStoreType, IAttachmentPreviewImageInfo, IAttachmentsPreviewStore, IPreviewPaging, IPreviewPagingItem } from './attachmentsPreview.store';
import { StickerError } from '../../models/error.model';
import { action } from 'mobx';
import { CacheManager, CacheManagerType, ICacheManager } from '../../services/cacheManager';
import { LOW_QUALITY_PRELOAD_MIN_IMAGE_SIZE } from '../annotation/annotations.interface';
import { ConfigurationType, IConfiguration } from '../../../configuration';
import { ApiServiceImageUploadType } from '../../services/api.service.imageUpload';

export const AttachmentsPreviewServiceType = Symbol('ATTACHMENTS_PREVIEW_SERVICE');

export interface IAttachmentsPreviewService {
  data: IAttachmentsPreviewStore;
  getPreviewPagingAsync(workspaceId: string, parentId?: string, lastUpdate?: string): Promise<IPreviewPaging | StickerError>;
  getImageInfoAsync(attachmentId: string): Promise<IAttachmentPreviewImageInfo | StickerError>;
  showImageAsync(attachmentId: string, size: number): Promise<void>;
}

@injectable()
export class AttachmentsPreviewService implements IAttachmentsPreviewService {
  constructor(
    @inject(AttachmentsPreviewStoreType) public readonly data: IAttachmentsPreviewStore,
    @inject(ApiServiceImageUploadType) private readonly apiService: IApiService,
    @inject(ConfigurationType) public readonly config: IConfiguration,
    @inject(CacheManagerType) public readonly cacheManager: ICacheManager,
  ) {
  }

  @action
  public async getPreviewPagingAsync(workspaceId: string, parentId: string, lastUpdate?: string): Promise<IPreviewPaging | StickerError> {
    return await this.apiService.getAsync<IPreviewPaging>('/attachments/PreviewPaging', {
      params: {
        workspaceId,
        parentId,
        lastUpdate,
      },
    });
  }

  @action
  public async showImageAsync(attachmentId: string, size: number) {
    await Promise.all([
      this.loadLowQualityImageAsync(attachmentId, size),
      this.loadImageAsync(attachmentId),
    ]);
  }

  @action
  public async getImageInfoAsync(attachmentId: string) {
    const previewImageInfo = await this.fetchImageInfoAsync(attachmentId);

    if (previewImageInfo instanceof StickerError) return previewImageInfo;

    this.data.imageInfo = previewImageInfo;
    return previewImageInfo;
  }

  @action
  private async fetchImageInfoAsync(attachmentId: string) {
    const url = `/attachments/imageInfo?attachmentId=${attachmentId}`;

    const cacheAvailable = await CacheManager.checkCacheAvailabilityAsync();
    if (!cacheAvailable) {
      return await this.apiService.getAsync<IAttachmentPreviewImageInfo>(url);
    }

    const request = new Request(attachmentId);
    const imagePreviewCache = await caches.open('image-preview-cache');
    const imagePreviewMatch = await imagePreviewCache.match(request);

    if (imagePreviewMatch) {
      const cachedPreviewImageData = await imagePreviewMatch.json();
      const result = {} as IAttachmentPreviewImageInfo;
      Object.assign(result, cachedPreviewImageData) as IAttachmentPreviewImageInfo;
      return result;
    }

    const previewImage = await this.apiService.getAsync<IAttachmentPreviewImageInfo>(url);
    if (previewImage instanceof StickerError) return previewImage;

    const previewImageData = JSON.stringify(previewImage);
    imagePreviewCache.put(request, new Response(previewImageData));

    return previewImage;
  }

  @action
  async loadLowQualityImageAsync(attachmentId: string, size: number) {
    if (size >= LOW_QUALITY_PRELOAD_MIN_IMAGE_SIZE) {
      const imageFile = await this.apiService.getImageAsync(this.apiService.getUrl(`/attachments/thumbnail/${attachmentId}`));
      if (this.data.imageInfo.id === attachmentId && !(imageFile instanceof StickerError)) {
        this.data.lowQualityImagePreviewUrl = URL.createObjectURL(imageFile.blob);
      }
    }
    const currentItem = this.data.previewPaging.items.find(x => x.imageId === attachmentId);
    this.preLoadNextLowQualityImagesAsync(currentItem!.index);
  }

  @action
  async loadImageAsync(attachmentId: string) {
    let imageFile = null;

    if (!this.data.activePreloads.some(x => x === attachmentId)) {
      imageFile = await this.apiService.getImageAsync(this.apiService.getUrl(`/attachments/image/${attachmentId}`));
    }

    if (this.data.imageInfo.id === attachmentId && imageFile !== null && !(imageFile instanceof StickerError)) {
      this.data.imagePreviewUrl = URL.createObjectURL(imageFile.blob);
      this.data.imageAttributes = imageFile.attributes;
    }
    const currentItem = this.data.previewPaging.items.find(x => x.imageId === attachmentId);
    this.preLoadNextImagesAsync(currentItem!.index);
  }

  async preLoadNextImagesAsync(currentIndex: number) {

    const cacheAvailable = await CacheManager.checkCacheAvailabilityAsync();
    if (!cacheAvailable) return;

    const range = this.config.appConfig.imagePreloadRange;

    const imagesToPreLoadIds = this.data.previewPaging.items
      .filter(x => x.index > currentIndex - range && x.index < currentIndex + range && x.index !== currentIndex)
      .sort((n1, n2) => Math.abs(n1.index - currentIndex) - Math.abs(n2.index - currentIndex));

    await imagesToPreLoadIds.forEach(async (item: IPreviewPagingItem) => {
      const url = this.apiService.getUrl(`/attachments/image/${item.imageId}`);

      if (this.data.activePreloads.some(x => x === item.imageId)) return;
      if (await this.cacheManager.checkIfInCache(url)) return;

      this.data.activePreloads.push(item.imageId);
      const imageFile = await this.apiService.getImageAsync(url);

      if (this.data.imageInfo.id === item.imageId && !(imageFile instanceof StickerError)) {
        this.data.imagePreviewUrl = URL.createObjectURL(imageFile.blob);
        this.data.imageAttributes = imageFile.attributes;
      }

      const index = this.data.activePreloads.findIndex(x => x === item.imageId);
      if (index > -1) {
        this.data.activePreloads.splice(index, 1);
      }
    });
  }

  async preLoadNextLowQualityImagesAsync(currentIndex: number) {
    const cacheAvailable = await CacheManager.checkCacheAvailabilityAsync();
    if (!cacheAvailable) return;

    const range = this.config.appConfig.imagePreloadRange;
    const imagesToPreLoadIds = this.data.previewPaging.items
      .filter(x => x.index > currentIndex - range && x.index < currentIndex + range && x.index !== currentIndex)
      .sort((n1, n2) => Math.abs(n1.index - currentIndex) - Math.abs(n2.index - currentIndex));

    await imagesToPreLoadIds.forEach(async (item: IPreviewPagingItem) => {
      if (item.size >= LOW_QUALITY_PRELOAD_MIN_IMAGE_SIZE) {
        const [blob] = await Promise.all([
          await this.apiService.getImageAsync(this.apiService.getUrl(`/attachments/thumbnail/${item.imageId}`)),
          this.fetchImageInfoAsync(item.imageId),
        ]);

        if (this.data.imageInfo.id === item.imageId) {
          this.data.lowQualityImagePreviewUrl = URL.createObjectURL(blob);
        }
      } else {
        await this.fetchImageInfoAsync(item.imageId);
      }
    });
  }
}
