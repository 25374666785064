import { AnnotationConfirmationModal, AnnotationConfirmationModalMode } from '../../../components/AnnotationConfirmationModal';
import { AnnotationServiceType, IAnnotationService } from '../../annotation/annotation.service';
import { AnnotationTypeBlType, IAnnotationTypeBl } from '../../annotation/submodules/annotationTypes/annotationType.bl';
import { FreeAccessServiceType, IFreeAccessService } from '../freeAccess.service';
import { FreeAccessStoreType, IFreeAccessStore } from '../freeAccess.store';
import { FreeDrawSegmentationServiceType, IFreeDrawSegmentationService } from '../../annotation/freeDrawSegmentation.service';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { IReviewRejectReasonsStore, ReviewRejectReasonsStoreType } from '../../annotation/submodules/reviewRejectReasones/reviewRejectReasons.store';
import { IUndoRedoHistory, UndoRedoHistoryType } from '../../annotation/undoRedoHistory.service';
import { IWorkspaceService, WorkspaceServiceType } from '../../workspaces/workspaces.service';
import { as, injectProps } from '../../../helpers/react.helpers';

import { CorrectNavigation } from '../../annotation/components/CorrectNavigation';
import { EditorMode } from '../../../../modules/editor/models/EditorModes';
import { GlobalHotKeys } from 'react-hotkeys';
import React from 'react';
import { SaveBeforeRejectConfirmationModal } from '../../../components/SaveBeforeRejectConfirmationModal';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps {
  freeAccessService: IFreeAccessService;
  freeAccessStore: IFreeAccessStore;
  freeDrawSegmentationService: IFreeDrawSegmentationService;
  workspaceService: IWorkspaceService;
  historyService: IUndoRedoHistory;
  reviewRejectReasonsStore: IReviewRejectReasonsStore;
  annotationService: IAnnotationService;
  annotationTypeBl: IAnnotationTypeBl;
}

interface IState extends ILoaderState {
  isCancelModalShown: boolean;
  showAnnotationConfirmationModal: boolean;
  annotationConfirmationModalMode: AnnotationConfirmationModalMode;
  showDiscardConfirmationModal: boolean;
  showSaveBeforeRejectConfirmationModal: boolean;
}

@injectProps({
  freeAccessService: FreeAccessServiceType,
  freeAccessStore: FreeAccessStoreType,
  freeDrawSegmentationService: FreeDrawSegmentationServiceType,
  workspaceService: WorkspaceServiceType,
  historyService: UndoRedoHistoryType,
  reviewRejectReasonsStore: ReviewRejectReasonsStoreType,
  annotationService: AnnotationServiceType,
  annotationTypeBl: AnnotationTypeBlType,
})
@observer
class FreeAccessReviewControlsCorrectContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  constructor(props: IInjectedProps) {
    super(props);
    this.state = {
      isCancelModalShown: false,
      showAnnotationConfirmationModal: false,
      showDiscardConfirmationModal: false,
      annotationConfirmationModalMode: AnnotationConfirmationModalMode.SUBMIT,
      showSaveBeforeRejectConfirmationModal: false,
      isLoading: false,
    };
  }

  @autobind
  async handleSubmitAndAcceptAsync() {
    this.setState({ showAnnotationConfirmationModal: false });
    await this.withLoaderAsync<boolean>(async () => await this.props.freeAccessService.editAndAcceptOrRejectAnnotationAsync(true), 'submit-and-accept-button');
  }

  @autobind
  handleReject() {
    if (!this.props.annotationService.validateAnnotations()) {
      this.props.annotationTypeBl.handleReject();
      return;
    }

    this.setState({ showSaveBeforeRejectConfirmationModal: false });
    this.toggleAnnotationConfirmationModal(AnnotationConfirmationModalMode.REJECT);
  }

  @autobind
  async handleConfirmReject(reason?: string) {
    this.setState({ showAnnotationConfirmationModal: false });
    if (reason) {
      await this.handleRejectConfirmed(reason);
    } else {
      this.setState({ showSaveBeforeRejectConfirmationModal: true });
    }
  }

  @autobind
  async handleRejectConfirmed(reason?: string) {
    this.setState({ showSaveBeforeRejectConfirmationModal: false });
    this.props.freeAccessStore.rejectionReason = reason;
    await this.withLoaderAsync(async () => await this.props.freeAccessService.rejectAnnotationAsync(reason), 'reject-button');
  }

  @autobind
  handleSaveBeforeRejectConfirmed() {
    this.handleRejectConfirmed();
  }

  @autobind
  handleRejectCanceled() {
    this.setState({ showSaveBeforeRejectConfirmationModal: false, showAnnotationConfirmationModal: false });
  }

  @autobind
  async handleDiscardConfirmed() {
    if (this.state.isLoading) return;

    this.setState({ showAnnotationConfirmationModal: false });
    await this.withLoaderAsync(async () => this.props.freeAccessService.discardAnnotationAsync(EditorMode.FREEACCESS_REVIEWEDIT), 'discard-annotation-button');
  }

  @autobind
  async handleDiscardCanceled() {
    this.setState({ showDiscardConfirmationModal: false });
  }

  @autobind
  async handleDiscard() {
    this.setState({ showDiscardConfirmationModal: true });
  }

  @autobind
  async handleCancelConfirm() {
    this.setState({ isCancelModalShown: false });
    if (!(await this.props.freeDrawSegmentationService.clearAsync())) return;
    this.props.historyService.clearHistory();
    await this.withLoaderAsync(async () => this.props.freeAccessService.cancelCorrectAnnotationAsync(), 'cancel-annotation-button');
  }

  @autobind
  async handleCancelCancel() {
    this.setState({ isCancelModalShown: false });
  }

  @autobind
  async handleCancel() {
    if (!this.props.historyService.canUndo) {
      this.handleCancelConfirm();
    } else {
      this.setState({ isCancelModalShown: true });
    }
  }

  @autobind
  toggleAnnotationConfirmationModal(mode?: AnnotationConfirmationModalMode) {
    if (mode !== undefined) this.setState({ annotationConfirmationModalMode: mode });
    this.setState((prevState: IState) => ({ ...prevState, showAnnotationConfirmationModal: !prevState.showAnnotationConfirmationModal }));
  }

  render() {
    const disabled = this.state.isLoading || this.props.freeDrawSegmentationService.freeDrawInProgress || this.props.freeDrawSegmentationService.freeDrawFeature !== undefined;

    // TODO: Create IPermission for free access
    const canDiscard = this.props.workspaceService.isInRole([WorkspaceRole.Owner, WorkspaceRole.Manager]);

    return (
      <>
        {!disabled && (
          <GlobalHotKeys
            keyMap={{
              SUBMIT: { sequence: 'ctrl+shift+enter', action: 'keydown' },
              REJECT: { sequence: 'ctrl+shift+backspace', action: 'keydown' },
            }}
            handlers={{
              SUBMIT: () => {
                this.setState({ annotationConfirmationModalMode: AnnotationConfirmationModalMode.SUBMITANDACCEPT });
                this.toggleAnnotationConfirmationModal();
              },
              REJECT: () => {
                this.setState({ annotationConfirmationModalMode: AnnotationConfirmationModalMode.REJECT });
                this.toggleAnnotationConfirmationModal();
              },
            }}
          />
        )}{' '}
        <CorrectNavigation
          disabled={disabled}
          canDiscard={canDiscard}
          editorMode={EditorMode.FREEACCESS_REVIEWEDIT}
          onSubmitAndAccept={this.handleSubmitAndAcceptAsync}
          onReject={this.handleReject}
          onCancel={this.handleCancel}
          onConfirmCancel={this.handleCancelConfirm}
          onCancelCancel={this.handleCancelCancel}
          showCancelModal={this.state.isCancelModalShown}
          showDiscardModal={this.state.showDiscardConfirmationModal}
          onDiscardCanceled={this.handleDiscardCanceled}
          onDiscardConfirmed={this.handleDiscardConfirmed}
          onDiscard={this.handleDiscard}
        />
        <SaveBeforeRejectConfirmationModal
          onSaveBeforeRejectCancel={this.handleRejectCanceled}
          onSaveBeforeRejectConfirm={this.handleSaveBeforeRejectConfirmed}
          onSaveBeforeRejectDecline={this.handleReject}
          showSaveBeforeRejectModal={this.state.showSaveBeforeRejectConfirmationModal}
        />
        <AnnotationConfirmationModal
          showAnnotationConfirmationModal={this.state.showAnnotationConfirmationModal}
          annotationConfirmationModalMode={this.state.annotationConfirmationModalMode}
          rejectAutoCompleteOptions={this.props.reviewRejectReasonsStore.rejectionReasons}
          onAnnotationConfirm={
            this.state.annotationConfirmationModalMode === AnnotationConfirmationModalMode.SUBMITANDACCEPT ? this.handleSubmitAndAcceptAsync : this.handleConfirmReject
          }
          onAnnotationCancel={this.toggleAnnotationConfirmationModal}
        />
      </>
    );
  }
}

export const FreeAccessReviewControlsCorrectContainer = as<React.ComponentClass>(FreeAccessReviewControlsCorrectContainerPure);
