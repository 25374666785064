import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { IUserStore, Plan, UserStoreType } from '../../../../user/user.store';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { ActivityLogContainer } from './ActivityLog.container';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { PlanUpgradeRequiredMessage } from '../../../../../components/PlanUpgradeRequiredMessage';
import React from 'react';
import { WorkspaceRole } from '../../../../workspaces/workspaces.store';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps extends ITranslatable {
  userStore: IUserStore;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

interface IOuterProps {
  projectIdKey: string;
}

@injectProps({
  userStore: UserStoreType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})

@observer
class ActivityLogWrapperContainerPure extends React.Component<IInjectedProps & IOuterProps> {

  render() {
    const workspace = this.props.currentWorkspaceStore.currentWorkspace;
    const isOwner = workspace?.role === WorkspaceRole.Owner;
    const canViewActivityLog = workspace?.ownerPlanTier === Plan.Enterprise || workspace?.ownerPlanTier === Plan.Admin || false;

    return (canViewActivityLog
      ? (<ActivityLogContainer projectIdKey={this.props.projectIdKey} />)
      : (
        <PlanUpgradeRequiredMessage
          message={this.props.t('activity_log_is_only_available_in_enterprise_plan')}
          linkText={isOwner ? this.props.t('upgrade_now') : this.props.t('ask_your_workspace_owner_to_upgrade_now')}
        />)
    );
  }
}

export const ActivityLogWrapperContainer = as<React.ComponentClass<IOuterProps>>(withNamespaces('common')(ActivityLogWrapperContainerPure));
