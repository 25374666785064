import * as React from 'react';

import { AttachmentsServiceType, IAttachmentsService } from '../attachments.service';
import { IUserService, UserServiceType } from '../../user/user.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { AttachmentsListControls } from '../components/AttachmentsListControls';
import { ListViewMode } from '../../user/user.store';
import { WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IProps extends RouteComponentProps<{ workspaceId: string, attachmentId: string }> {
  attachmentsService: IAttachmentsService;
  userService: IUserService;
}

@injectProps({
  attachmentsService: AttachmentsServiceType,
  userService: UserServiceType,
})
@observer
class AttachmentListControlsContainerPure extends WithLoaderComponentBase<IProps> {

  // tslint:disable-next-line:function-name
  UNSAFE_componentWillMount() {
    this.props.attachmentsService.store.selectedAttachments = { isAllSelected: false, ids: [] };
  }

  @autobind
  handleOrderByChange(orderBy: string) {
    const paging = this.props.attachmentsService.store.getPaging(this.props.match.params.workspaceId, this.props.match.params.attachmentId);
    paging.orderBy = orderBy;
    this.props.attachmentsService.store.updatePaging(paging);
    this.doSort();
  }

  @autobind
  handleOrderDirectionChange(orderType: string) {
    const paging = this.props.attachmentsService.store.getPaging(this.props.match.params.workspaceId, this.props.match.params.attachmentId);
    paging.orderType = orderType;
    this.props.attachmentsService.store.updatePaging(paging);
    this.doSort();
  }

  @autobind
  handleViewModeChange(mode: ListViewMode) {
    this.props.userService.setAttachmentsListViewMode(mode);
  }

  @autobind
  async doSort() {
    await this.withLoaderAsync(() =>
      this.props.attachmentsService.getAttachmentsAsync(this.props.match.params.attachmentId));
  }

  render() {
    const attachmentsStore = this.props.attachmentsService.store;
    const paging = attachmentsStore.getPaging(this.props.match.params.workspaceId, this.props.match.params.attachmentId);

    return (
      <AttachmentsListControls
        viewMode={this.props.userService.data.attachmentsListViewMode}
        orderBy={paging.orderBy || ''}
        orderDirection={paging.orderType || ''}
        onViewModeChange={this.handleViewModeChange}
        onOrderByChange={this.handleOrderByChange}
        onOrderDirectionChange={this.handleOrderDirectionChange}
      />
    );
  }
}

export const AttachmentsListControlsContainer = as<React.ComponentClass>(withRouter(AttachmentListControlsContainerPure));
