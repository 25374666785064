import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router';
import { ModelDetailsTabs } from '../components/ModelDetailsTabs';
import { as, injectProps } from '../../../__legacy__/helpers/react.helpers';
import { IModelDetailsService, ModelDetailsServiceType } from '../modelDetails.service';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ jobId?: string; workspaceId: string }> {
  modelDetailsService: IModelDetailsService;
}

@injectProps({
  modelDetailsService: ModelDetailsServiceType,
})
@observer
class ModelDetailsTabsContainerPure extends React.Component<IInjectedProps> {
  render() {
    const id = this.props.match.params.jobId;
    const workspaceId = this.props.match.params.workspaceId;
    const { status, modelDetailsValidationErrors } = this.props.modelDetailsService.store;

    return <ModelDetailsTabs modelId={id} modelStatus={status} workspaceId={workspaceId} modelDetailsValidationErrors={modelDetailsValidationErrors} />;
  }
}

export const ModelDetailsTabsContainer = as<React.ComponentClass>(withRouter(ModelDetailsTabsContainerPure));
