import { AlertBarStoreType, IAlertBarStore } from './AlertBar.store';
import { AlertBartBlType, IAlertBarBl } from './AlertBar.bl';
import { as, injectProps } from '../../__legacy__/helpers/react.helpers';

import { Alert } from './models/Alert';
import { AlertBar } from './ui/AlertBar';
import React from 'react';
import { observer } from 'mobx-react';

interface InjectedProps {
  store: IAlertBarStore;
  bl: IAlertBarBl;
}

@injectProps({
  store: AlertBarStoreType,
  bl: AlertBartBlType,
})
@observer
class AlertBarContainerPure extends React.Component<InjectedProps> {
  componentDidMount = async () => await this.props.bl.initializeAsync();
  componentWillUnmount = () => this.props.bl.cleanup();
  handleAlertOutdated = (alert: Alert) => this.props.bl.clearOutdatedAlert(alert);

  render() {
    return this.props.store.alerts.length > 0 ? <AlertBar alerts={this.props.store.alerts} onAlertOutdated={this.handleAlertOutdated} /> : null;
  }
}

export const AlertBarContainer = as<React.ComponentClass>(AlertBarContainerPure);
