import { IUndoRedoHistory, UndoRedoHistoryType } from '../undoRedoHistory.service';
import { as, injectProps } from '../../../helpers/react.helpers';

import React from 'react';
import { UndoRedoAnnotationsShortcuts } from '../components/UndoRedoAnnotationsShortcuts';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps {
  undoRedoHistory: IUndoRedoHistory;
}

export interface IOuterProps {
  undo: boolean;
}

export interface IUndoRedoAnnotationsShortcutsProps {
  undo: boolean;
  enabled: boolean;
  onClick(): void;
}

@injectProps({
  undoRedoHistory: UndoRedoHistoryType,
})
@observer
class UndoRedoAnnotationsShortcutsContainerPure extends React.Component<IInjectedProps & IOuterProps> {
  @autobind
  handleClick() {
    this.props.undo ? this.props.undoRedoHistory.undo() : this.props.undoRedoHistory.redo();
  }

  render() {
    return (
      <UndoRedoAnnotationsShortcuts
        undo={this.props.undo}
        enabled={this.props.undo ? this.props.undoRedoHistory.canUndo : this.props.undoRedoHistory.canRedo}
        onClick={this.handleClick}
      />
    );
  }
}

export const UndoRedoAnnotationsShortcutsContainer = as<React.ComponentClass<IOuterProps>>(UndoRedoAnnotationsShortcutsContainerPure);
