import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../../../../../modules/common/OverlayLoader.store';
import { IProjectDetailsImagesStore, ProjectDetailsImagesStoreType } from '../../projectDetailsImages.store';
import { as, injectProps } from '../../../../../../helpers/react.helpers';

import { ImageFilters } from '../../components/filters/ImageFilters';
import React from 'react';
import { getActiveFiltersCount } from '../../services/imageFilterHelper';

interface IState {
  showModal: boolean;
}

interface IInjectedProps {
  imagesStore: IProjectDetailsImagesStore;
  overlayLoaderStore: IOverlayLoaderStore;
}

@injectProps({
  imagesStore: ProjectDetailsImagesStoreType,
  overlayLoaderStore: OverlayLoaderStoreType,
})
class ImageFiltersContainerPure extends React.Component<IInjectedProps, IState> {
  constructor(props: IInjectedProps) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  handleModalShow = () => {
    this.setState({ showModal: true });
  }

  handleModalClose = () => {
    if (this.props.overlayLoaderStore.isSpinnerVisible('details-images-loading')) return;
    this.setState({ showModal: false });
  }

  render() {
    const { imageFilters } = this.props.imagesStore;

    return (
      <ImageFilters
        activeFiltersCount={getActiveFiltersCount(imageFilters)}
        isModalOpen={this.state.showModal}
        onModalClose={this.handleModalClose}
        onModalShow={this.handleModalShow}
      />
    );
  }
}

export const ImageFiltersContainer = as<React.ComponentClass>(ImageFiltersContainerPure);
