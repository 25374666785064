import './S_ColorPicker.scss';

import ColorPicker from 'rc-color-picker';
import { Input } from 'reactstrap';
import React from 'react';
import { S_IdProps } from '../../CommonProps';

export interface S_ColorPickerProps extends S_IdProps {
  value: string;
  onChange(value: string): void;
  disabled?: boolean;
}

export const S_ColorPicker: React.FC<S_ColorPickerProps> = (props: S_ColorPickerProps) => {
  const handleChange = ({ color }: { color: string }) => props.onChange(color);
  const contrastColor = (color: string) => {
    const hex = color.slice(1);
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    // http://stackoverflow.com/a/3943023/112731
    const colorName = r * 0.299 + g * 0.587 + b * 0.114 > 186 ? 'black' : 'white';
    return `s-color-picker-${colorName}-text`;
  };

  return (
    <ColorPicker animation="slide-up" color={props.value} enableAlpha={false} onChange={handleChange}>
      <Input id={props.id} value={props.value} type={'text'} className={`rc-color-picker-trigger ${contrastColor(props.value)}`} readOnly={true} disabled={props.disabled} />
    </ColorPicker>
  );
};
