import { injectable, inject } from 'inversify';
import { ApiServiceType, IPagedResult } from '../../../../../services/api.service';
import { StickerError } from '../../../../../models/error.model';
import { IApiService } from '../../../../../services/api.service.base';

import { IProjectActivity } from '../projectDetailsActivities.models';
import { IPagingInfoWithOrder } from '../../../../../models/paginationInfo.model';

export const ProjectDetailsActivitiesApiServiceType = Symbol('PROJECT_DETAILS_ACTIVITIES_API_SERVICE');

export interface IProjectDetailsActivitiesApiService {
  getProjectActivitiesAsync(projectId: string, activities: string[], actor: string, pagingInfo: IPagingInfoWithOrder):
    Promise<IPagedResult<IProjectActivity<any>> | StickerError>;
}

@injectable()
export class ProjectDetailsActivitiesApiService implements IProjectDetailsActivitiesApiService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService) { }

  async getProjectActivitiesAsync(projectId: string, activities: string[], actor: string, pagingInfo: IPagingInfoWithOrder) {
    return await this.apiService.postAsync<{}, IPagedResult<IProjectActivity<any>>>('/Projects/activities', { projectId, activities, actor, ...pagingInfo });
  }
}
