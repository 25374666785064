import * as React from 'react';

import { faCopy, faEdit, faGlobe, faStopCircle, faSync, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import CopyToClipboard from 'react-copy-to-clipboard';
import { EditApiKeyModalContainer } from '../containers/EditApiKeyModal.container';
import { IApiKey } from '../integrations.store';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { IconedButton } from '../../../components/IconedButton';
import { Loader } from '../../../components/Loader';
import { Table } from '../../../components/TableVisibleWhenWithData';
import { as } from '../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { handleClickAndPassData } from '../../../helpers/formHelpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';

library.add(faTrashAlt, faEdit, faSync, faGlobe, faStopCircle, faCopy);

interface IState {
  showModal: boolean;
  apiKeyId?: string;
  copiedId?: string;
}

interface IApiKeyListItemViewModel {
  id: string;
  name: string;
  value: string;
  isActiveText: string;
  copiedClassName: string;
}

export interface IIntegrationsListComponentProps {
  apiKeys: IApiKey[];
  isLoading: boolean;
  onDeleteApiKey(apiKeyId: string): void;
}

class IntegrationsListPure extends React.Component<IIntegrationsListComponentProps & ITranslatable, IState> {
  state: IState = {
    showModal: false,
    apiKeyId: '',
  };

  get columns() {
    return [
      {
        field: 'name',
        headerName: this.props.t('name'),
      },
      {
        field: 'isActiveText',
        headerName: this.props.t('status'),
      },
      {
        field: 'value',
        headerName: this.props.t('value'),
        cellClass: 'cell-selection-enabled',
        minWidth: 305,
      },
      {
        headerName: this.props.t('actions'),
        cellRenderer: 'renderer',
        sortable: false,
      },
    ];
  }

  get defaultColDef() {
    return {
      autoHeight: true,
      sortable: true,
    };
  }

  renderButtonsAndIcons(params: IApiKeyListItemViewModel) {
    return (
      <div>
        <CopyToClipboard
          text={params.value}
          onCopy={handleClickAndPassData(this.handleCopied)(params.id)}
        >
          <IconedButton
            color={params.copiedClassName}
            icon={faCopy}
            title={this.props.t('copy_to_clipboard')}
          />
        </CopyToClipboard>
        <IconedButton
          onClick={handleClickAndPassData(this.showModal)(params.id)}
          icon={'edit'}
          title={this.props.t('edit_api_key')}
        />
        <IconedButton
          onClick={handleClickAndPassData(this.props.onDeleteApiKey)(params.id)}
          icon={'trash-alt'}
          title={this.props.t('delete_api_key')}
        />
      </div>
    );
  }

  @autobind
  showModal(id?: string) {
    this.setState({
      showModal: true,
      apiKeyId: id,
    });
  }

  @autobind
  hideModal() {
    this.setState({
      showModal: false,
      apiKeyId: undefined,
    });
  }

  @autobind
  handleCopied(id?: string) {
    this.setState({
      copiedId: id,
    });
  }

  render() {
    const apiKeys: IApiKeyListItemViewModel[] = this.props.apiKeys.map(element => ({
      id: element.id,
      name: element.name,
      value: element.value,
      isActiveText: this.props.t(element.isActive ? 'active' : 'inactive'),
      copiedClassName: element.id === this.state.copiedId ? 'success' : '',
    }));

    return (
      <div>
        <Loader isLoading={this.props.isLoading}>
          <div className="table-container">
            {apiKeys.length > 0 ?
              <Table
                columnDefs={this.columns}
                defaultColDef={this.defaultColDef}
                rowData={apiKeys}
                rowDragManaged={true}
                animateRows={true}
                domLayout="autoHeight"
                suppressDragLeaveHidesColumns={true}
                frameworkComponents={{
                  renderer: ({ data }: any) => <>{this.renderButtonsAndIcons(data)}</>,
                }}
              /> : null}
          </div>
        </Loader>
            <EditApiKeyModalContainer
              apiKeyId={this.state.apiKeyId!}
              onSubmit={this.hideModal}
              onCancel={this.hideModal}
              showModal={this.state.showModal}
            />
      </div>
    );
  }
}

export const IntegrationsList = as<React.ComponentClass<IIntegrationsListComponentProps>>(withNamespaces('integrations', { wait: true })(IntegrationsListPure));
