import { injectable, inject } from 'inversify';
import { action } from 'mobx';
import { StickerError } from '../../models/error.model';
import { ImagesUploaderServiceType, IImagesUploaderService } from './imagesUpload.service';
import { ImagesFromUrlsServiceType, IImagesFromUrlsService } from './imagesFromUrlsUpload.service';
import { IDatasetsDetailsStore } from './datasetsDetails.store';
import { IDatasetsStore } from './datasets.store';

export const UpdateDatasetServiceType = Symbol('UPDATE_DATASET_SERVICE');

export interface IUpdateDatasetService {
  datasetsDetailsStore: IDatasetsDetailsStore;
  datasetsStore: IDatasetsStore;
  uploadImages(datasetId: string): Promise<void | StickerError>;
  markDatasetAsUploaded(datasetId: string, uploadedItemsCount: number): void;
}

@injectable()
export class UpdateDatasetService implements IUpdateDatasetService {
  datasetsDetailsStore!: IDatasetsDetailsStore;
  datasetsStore!: IDatasetsStore;

  constructor(
    @inject(ImagesUploaderServiceType) private readonly imagesService: IImagesUploaderService,
    @inject(ImagesFromUrlsServiceType) private readonly urlsService: IImagesFromUrlsService,
  ) {
    this.imagesService.onUploadFinishedSuccessfully.push((datasetId: string) =>
      this.markDatasetAsUploaded(datasetId),
    );
    this.urlsService.onUploadFinishedSuccessfully.push((datasetId: string) =>
      this.markDatasetAsUploaded(datasetId),
    );
    this.imagesService.onUploadAborted.push((datasetId: string) =>
      this.markDatasetAsUploaded(datasetId),
    );
    this.urlsService.onUploadAborted.push((datasetId: string) =>
      this.markDatasetAsUploaded(datasetId),
    );
    this.imagesService.onDatasetUploadProgress.push((datasetId: string, imageSize: number) =>
      this.updateProgress(datasetId, imageSize),
    );
    this.urlsService.onDatasetUploadProgress.push((datasetId: string, imageSize: number) =>
      this.updateProgress(datasetId, imageSize),
    );
  }

  @action
  async uploadImages(dataSetId: string) {
    const dataSetName = this.datasetsDetailsStore.details.name;
    const validImagesFromUrl = this.datasetsDetailsStore.imagesToUploadFromUrl.filter(i => i.isValid);

    if (this.datasetsDetailsStore.imagesToUploadFromFile.length > 0) {
      await this.imagesService.addDatasetToQueueAsync(this.datasetsDetailsStore.imagesToUploadFromFile, dataSetId, dataSetName);
    }
    if (validImagesFromUrl.length > 0) {
      await this.urlsService.addDatasetToQueueAsync(validImagesFromUrl, dataSetId, dataSetName);
    }

    this.datasetsDetailsStore.markAsUploading(dataSetId);
    this.datasetsStore.markAsUploading(dataSetId);

    this.datasetsDetailsStore.imagesToUploadFromFile.clear();
    this.datasetsDetailsStore.imagesRejectedFromFile.clear();
    this.datasetsDetailsStore.imagesToUploadFromUrl.clear();
  }

  @action.bound
  markDatasetAsUploaded(dataSetId: string) {
    this.datasetsDetailsStore.markAsUploaded(dataSetId);
    this.datasetsStore.markAsUploaded(dataSetId);
  }

  @action.bound
  updateProgress(dataSetId: string, imageSize: number) {
    this.datasetsDetailsStore.incrementProgress(dataSetId, imageSize);
    this.datasetsStore.incrementProgress(dataSetId, imageSize);
  }
}
