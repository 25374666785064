import { StickerEvent } from '../../../../../../__legacy__/services/eventBus.service';

export const ImportAnnotationsFailureResponseEventType = Symbol('IMPORT_ANNOTATION_FAILURE_RESPONSE_EVENT');

export class ImportAnnotationsFailureResponseEvent extends StickerEvent {
  constructor(public readonly errorCodes: string[]) {
    super();
  }

  get type(): Symbol {
    return ImportAnnotationsFailureResponseEventType;
  }
}
