import { IImageFilters, IImageFiltersData, ISavedFilter, ImageDefaultFilters } from './imageFilters.model';
import { IImagesPagingInfo, IProjectDetailsImage, ImageSelectionMode, ImageSetType, ImagesDefaultPaging } from './projectDetailsImages.model';
import { IObservableArray, action, observable, set } from 'mobx';

import { IQuestionModel } from '../../../annotation/question.model';
import { injectable } from 'inversify';
import uuid from 'uuid';

export const ProjectDetailsImagesStoreType = Symbol('PROJECT_DETAILS_IMAGES_STORE');
export const ProjectDetailsImagesStoreSetterType = Symbol('PROJECT_DETAILS_IMAGES_STORE_SETTER');

export interface IProjectDetailsImagesStore {
  readonly projectId: string;
  readonly availableFilters: IImageFiltersData;
  readonly detailsImages: IObservableArray<IProjectDetailsImage>;
  readonly imagesPaging: IImagesPagingInfo;
  readonly imageFilters: IImageFilters;
  readonly filterId: string;
  readonly savedFilters: IObservableArray<ISavedFilter>;

  readonly selectionMode: ImageSelectionMode;
  readonly toggledImages: IObservableArray<string>;
  readonly lastSelectedImageId: string | undefined;

  readonly showAnswerQuestionsModal: boolean;
  readonly questions: IObservableArray<IQuestionModel>;
  readonly batchAnswerQuestionsRequestInProgress: boolean;
  readonly batchAnswerBatchesCount: number;
  readonly batchAnswerSuccessfulBatches: number;
  readonly batchAnswerFailedBatches: number;
  readonly batchAnswerQuestionsError?: string;
  readonly batchAnswerQuestionModalSessionId?: string;
}

export interface IProjectDetailsImagesStoreSetter extends IProjectDetailsImagesStore {
  resetStorage(projectId: string, filterId: string | undefined): void;
  setFilterData(availableImageFilters: IImageFiltersData, imageFilters: IImageFilters, savedFilters: ISavedFilter[]): void;
  setQuestions(imageTabQuestions: IQuestionModel[]): void;
  updateImages(images: IProjectDetailsImage[], paging: IImagesPagingInfo, filterId: string): void;
  setImagePaging(paging: IImagesPagingInfo): void;
  updateImageSetType(imageId: string, imageSetType: ImageSetType): void;
  setSelectionMode(selectionMode: ImageSelectionMode): void;
  setToggledImages(toggledImages: string[], lastSelectedId: string | undefined): void;
  setBatchAnswerQuestionsRequestInProgress(inProgress: boolean, errorCode?: string): void;
  resetBatchAnswerQuestions(): void;
  setBatchAnswerQuestionsProgress(totalBatchesCount: number, failedCount: number, successfulCount: number): void;
  setFilters(imageFilters: IImageFilters): void;
}

@injectable()
export class ProjectDetailsImagesStore implements IProjectDetailsImagesStoreSetter {
  @observable
  projectId: string = '';

  detailsImages: IObservableArray<IProjectDetailsImage> = observable.array([]);

  @observable
  imagesPaging: IImagesPagingInfo = ImagesDefaultPaging;

  @observable
  availableFilters: IImageFiltersData = {
    datasets: [],
    annotationTypes: [],
    questionFilters: [],
    annotatedBy: [],
    reviewedBy: [],
  };

  @observable
  filterId: string = '';

  @observable
  selectionMode: ImageSelectionMode = 'Select';

  @observable
  lastSelectedImageId: string | undefined = undefined;

  @observable
  toggledImages: IObservableArray<string> = observable.array([]);

  @observable
  showAnswerQuestionsModal: boolean = false;

  @observable
  questions: IObservableArray<IQuestionModel> = observable.array([]);

  @observable
  batchAnswerQuestionsRequestInProgress: boolean = false;

  @observable
  batchAnswerQuestionsError?: string = undefined;

  @observable
  batchAnswerQuestionModalSessionId: string = '';

  @observable
  batchAnswerBatchesCount: number = 0;

  @observable
  batchAnswerFailedBatches: number = 0;

  @observable
  batchAnswerSuccessfulBatches: number = 0;

  @observable
  imageFilters: IImageFilters = { ...ImageDefaultFilters };

  @observable
  savedFilters: IObservableArray<ISavedFilter> = observable.array([]);

  @action
  resetStorage(projectId: string, filterId: undefined): void {
    this.projectId = projectId;
    this.selectionMode = 'Select';
    this.lastSelectedImageId = undefined;
    this.toggledImages.clear();
    this.detailsImages.clear();
    this.showAnswerQuestionsModal = false;
    if (filterId !== undefined) {
      this.filterId = filterId!;
    }
    this.resetBatchAnswerQuestions();
  }

  @action
  updateImages(images: IProjectDetailsImage[], paging: IImagesPagingInfo, filterId: string): void {
    this.detailsImages.replace(images);
    this.filterId = filterId;
    set(this.imagesPaging, paging);
  }

  @action
  setFilterData(availableFilters: IImageFiltersData, imageFilters: IImageFilters, savedFilters: ISavedFilter[]): void {
    set(this.availableFilters, availableFilters);
    this.savedFilters.replace(savedFilters);
    set(this.imageFilters, {
      ...ImageDefaultFilters,
      ...imageFilters,
      annotationTools: imageFilters?.annotationTools.length ? imageFilters.annotationTools : ImageDefaultFilters.annotationTools,
      sorting: imageFilters?.sorting.length ? imageFilters.sorting : ImageDefaultFilters.sorting,
    });
  }

  @action
  setQuestions(questions: IQuestionModel[]): void {
    this.questions.replace(questions);
  }

  @action
  updateImageSetType(imageId: string, imageSetType: ImageSetType): void {
    const images = this.detailsImages.slice();
    const imageToUpdate = images.find(i => i.id === imageId);

    if (imageToUpdate) {
      imageToUpdate.imageSet = imageSetType;
      this.detailsImages.replace(images);
    }
  }

  @action
  setImagePaging(paging: IImagesPagingInfo): void {
    set(this.imagesPaging, paging);
  }

  @action
  setSelectionMode(selectionMode: ImageSelectionMode): void {
    this.selectionMode = selectionMode;
    this.toggledImages.clear();
  }

  @action
  setToggledImages(toggledImages: string[], lastSelectedId: string | undefined): void {
    this.toggledImages.replace(toggledImages);
    this.lastSelectedImageId = lastSelectedId;
  }

  @action
  setBatchAnswerQuestionsRequestInProgress(inProgress: boolean, errorCode?: string): void {
    this.batchAnswerQuestionsRequestInProgress = inProgress;
    this.batchAnswerQuestionsError = errorCode;
  }

  @action
  resetBatchAnswerQuestions(): void {
    this.batchAnswerQuestionsError = undefined;
    this.batchAnswerQuestionsRequestInProgress = false;
    this.batchAnswerQuestionModalSessionId = uuid.v4();
    this.batchAnswerBatchesCount = 0;
    this.batchAnswerSuccessfulBatches = 0;
    this.batchAnswerFailedBatches = 0;
  }

  @action
  setBatchAnswerQuestionsProgress(totalBatchesCount: number, failedCount: number, successfulCount: number): void {
    this.batchAnswerBatchesCount = totalBatchesCount;
    this.batchAnswerFailedBatches = failedCount;
    this.batchAnswerSuccessfulBatches = successfulCount;
  }

  @action
  setFilters(imageFilters: IImageFilters): void {
    set(this.imageFilters, imageFilters);
  }
}
