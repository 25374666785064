import * as React from 'react';

import { Col, Container, Row } from 'reactstrap';

import { InputStatus } from '../models/error.model';
import { TranslationFunction } from 'i18next';
import { ValidatedTextInput } from './ValidatedTextInput';

type tabNavPresentState = 'with-tab-nav' | 'clean';

export interface IHeaderPageProps {
  id?: string;
  name: string;
  description: string;
  onNameChange(value: string): void;
  onDescriptionChange(value: string): void;
  onNameBlur(): void;
  onDescriptionBlur(): void;
  nameMaxLength: number;
  nameStatus: InputStatus;
  descriptionMaxLength: number;
  descriptionStatus: InputStatus;
  imageSrc?: string;
  disabled?: boolean;
}

interface IProps extends IHeaderPageProps {
  tabNavPresent: tabNavPresentState;
  tabNav?: React.SFC;
  children?: React.ReactElement<any>[] | React.ReactElement<any>;
  namePlaceholder: string;
  descriptionPlaceholder: string;
  t: ((str: string) => string) & TranslationFunction<any, object, string>;
  authorPlaceholder: string;
  termsOfUsePlaceholder: string;
  onAuthorBlur(value: string): void;
  onTermsOfUseBlur(value: string): void;
  authorStatus?: InputStatus;
  termsOfUseStatus?: InputStatus;
  onAuthorChange(value: string): void;
  onTermsOfUseChange(value: string): void;
  author: string;
  termsOfUse?: string;
}

export class EditablePageHeader extends React.Component<IProps> {
  render() {
    const { t } = this.props;

    return (
      <Container fluid className={`${this.props.tabNavPresent}`}>
        <Row>
          <Col sm={this.props.children ? '8' : '12'} className="editable-header">
            <div className="image-input-body">
              {this.props.imageSrc && <img className="input-img" src={this.props.imageSrc} />}
              <ValidatedTextInput
                id="editable-header-name"
                type={'text'}
                maxLength={this.props.nameMaxLength}
                value={this.props.name}
                onChange={this.props.onNameChange}
                onBlur={this.props.onNameBlur}
                isValid={this.props.nameStatus.isValid}
                feedbacks={this.props.nameStatus.errorCodes.map(e => t(e))}
                styleClassName={'input_click_editable big'}
                placeholder={t(this.props.namePlaceholder)}
                disabled={this.props.disabled}
              />
            </div>
            <ValidatedTextInput
              type={'textarea'}
              maxLength={this.props.descriptionMaxLength}
              value={this.props.description}
              onChange={this.props.onDescriptionChange}
              onBlur={this.props.onDescriptionBlur}
              isValid={this.props.descriptionStatus.isValid}
              feedbacks={this.props.descriptionStatus.errorCodes.map(e => t(e))}
              styleClassName={'input_click_editable editable-header-field'}
              placeholder={t(this.props.descriptionPlaceholder)}
              disabled={this.props.disabled}
            />
            <ValidatedTextInput
              type={'textarea'}
              maxLength={this.props.descriptionMaxLength}
              value={this.props.author}
              onChange={this.props.onAuthorChange}
              onBlur={this.props.onAuthorBlur}
              isValid={this.props.authorStatus?.isValid}
              feedbacks={this.props.authorStatus?.errorCodes.map(e => t(e))}
              styleClassName={'input_click_editable editable-header-field'}
              placeholder={t(this.props.authorPlaceholder)}
              disabled={this.props.disabled}
            />
            <ValidatedTextInput
              type={'textarea'}
              maxLength={this.props.descriptionMaxLength}
              value={this.props.termsOfUse}
              onChange={this.props.onTermsOfUseChange}
              onBlur={this.props.onTermsOfUseBlur}
              isValid={this.props.termsOfUseStatus?.isValid}
              feedbacks={this.props.termsOfUseStatus?.errorCodes.map(e => t(e))}
              styleClassName={'input_click_editable editable-header-field'}
              placeholder={t(this.props.termsOfUsePlaceholder)}
              disabled={this.props.disabled}
            />
          </Col>
          {this.props.children && (
            <Col sm="4">
              <div className="float-right">{this.props.children}</div>
            </Col>
          )}
        </Row>
        <Row>
          <Col>{this.props.tabNav && <this.props.tabNav />}</Col>
        </Row>
      </Container>
    );
  }
}
