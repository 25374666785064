import './SegmentationsGroup.scss';

import React, { useState } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IObjectListSegmentation } from './ObjectsList.container';
import { ObjectListActions } from './ObjectListActions';
import { SegmentationWithActions } from './SegmentationWithActions';
import _ from 'lodash';

export interface IMarkingToolGroupItemProps {
  isReadOnly: boolean;
  segmentations: IObjectListSegmentation[];
  onAnnotationTypeHiddenClick(annotationTypeId: string): void;
  onSegmentationHiddenClick(segmentation: IObjectListSegmentation): void;
  onDeleteAnnotationTypeClick(annotationTypeId: string): void;
  onDeleteSegmentationClick(segmentation: IObjectListSegmentation): void;
  onSegmentationClick(segmentation: IObjectListSegmentation): void;
}

export const SegmentationsGroup: React.FC<IMarkingToolGroupItemProps> = ({
  isReadOnly,
  segmentations,
  onAnnotationTypeHiddenClick: onAnnotationTypeHiddenClicked,
  onSegmentationHiddenClick: onSegmentationHiddenClicked,
  onDeleteAnnotationTypeClick: onDeleteAnnotationTypeClicked,
  onDeleteSegmentationClick: onDeleteSegmentationClicked,
  onSegmentationClick: onSegmentationClicked,
}: IMarkingToolGroupItemProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const toggle = () => setIsOpened(!isOpened);
  const handleAnnotationTypeShowHideClicked = () => onAnnotationTypeHiddenClicked(segmentations[0].feature.properties!['annotationTypeId']);
  const handleAnnotationTypeDeleteClicked = () => onDeleteAnnotationTypeClicked(segmentations[0].feature.properties!['annotationTypeId']);
  const getSegmentationShowHideClicked = (segmentation: IObjectListSegmentation) => () => onSegmentationHiddenClicked(segmentation);
  const getHandleDeleteSegmentationClicked = (segmentation: IObjectListSegmentation) => () => onDeleteSegmentationClicked(segmentation);
  const getHandleSegmentationClicked = (segmentation: IObjectListSegmentation) => () => onSegmentationClicked(segmentation);

  return (
    <div className="segmentations-group">
      <div className="segmentations-group-header">
        <div className="segmentations-group-header-left-container">
          <div className={`segmentations-group-label ${segmentations.some(s => s.isSelected) ? 'selected' : ''}`}>
            <SegmentationWithActions
              isReadOnly={isReadOnly}
              objectListSegmentation={segmentations[0]}
              showActions={false}
            />
            &nbsp; ({segmentations.length})
          </div>
          <div className="segmentations-group-chevron-container" onClick={toggle}>
            {isOpened && <FontAwesomeIcon className="segmentations-group-header-chevron" icon={faChevronUp} />}
            {!isOpened && <FontAwesomeIcon className="segmentations-group-header-chevron" icon={faChevronDown} />}
          </div>
        </div>
        <ObjectListActions
          isDeleteVisible={!isReadOnly}
          isVisible={segmentations[0].isMarkingToolTypeVisible}
          onDeleteClick={handleAnnotationTypeDeleteClicked}
          onShowHideClick={handleAnnotationTypeShowHideClicked}
        />
      </div>
      {isOpened && (
        <div className="segmentations-group-items">
          {_.map(segmentations, (s, index) => (
            <SegmentationWithActions
              key={s.feature.id}
              isReadOnly={isReadOnly}
              objectListSegmentation={s}
              index={index + 1}
              onShowHideClick={getSegmentationShowHideClicked(s)}
              onDeleteClick={getHandleDeleteSegmentationClicked(s)}
              onClick={getHandleSegmentationClicked(s)}
            />
          ))}
        </div>
      )}
    </div>
  );
};
