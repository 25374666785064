import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { InputStatus } from '../../../models/error.model';
import { MAX_FILE_NAME_LENGTH } from '../../../helpers/global.constants';
import React from 'react';
import { ValidatedTextInput } from '../../../components/ValidatedTextInput';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IRenameImageModalProps {
  showRenameImage: boolean;
  renameImageName: string;
  renameImageNameStatus: InputStatus;
  toggleRenameImage(): void;
  onNameChange(value: string): void;
  onRenameSave(): void;
}

const RenameImageModalPure = (props: IRenameImageModalProps & ITranslatable) => {
  const preventSubmit = (e: any) => e.preventDefault();

  return (
    <Modal className="modal-width-600" isOpen={props.showRenameImage} toggle={props.toggleRenameImage}>
      <ModalHeader toggle={props.toggleRenameImage}>{props.t('rename_image')}</ModalHeader>
      <Form className="custom-inline-form" onSubmit={preventSubmit}>
        <ModalBody>
          <Row>
            <Col>
              <ValidatedTextInput
                onPressEnter={props.onRenameSave}
                labelText={props.t('image_name')}
                placeholder={props.t('type_image_name_here')}
                value={props.renameImageName}
                onChange={props.onNameChange}
                isValid={props.renameImageNameStatus.isValid}
                feedbacks={props.renameImageNameStatus.errorCodes.map(ec => props.t(ec))}
                feedbacksParams={{ nsSeparator: '_'}}
                maxLength={MAX_FILE_NAME_LENGTH}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <DisabledWhenWorkspaceOwnerPolicyExceeded>
            <Button disabled={props.renameImageNameStatus.isValid !== true} color="success" className="mr-2" onClick={props.onRenameSave}>
              {props.t('rename')}
            </Button>
          </DisabledWhenWorkspaceOwnerPolicyExceeded>

          <Button color="primary" className="btn-outline-primary" onClick={props.toggleRenameImage}>
            {props.t('cancel')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export const RenameImageModal = as<React.ComponentClass<IRenameImageModalProps>>(withNamespaces('common')(RenameImageModalPure));
