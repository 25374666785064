﻿import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { IProjectDetailsBl, ProjectDetailsBlType } from '../projectDetails.bl';
import { IUserService, UserServiceType } from '../../user/user.service';
import { IWorkspaceService, WorkspaceServiceType } from '../../workspaces/workspaces.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { InputStatus } from '../../../models/error.model';
import { ProjectRole } from '../../../models/userRole.model';
import React from 'react';
import { UsersForm } from '../components/UsersForm';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import autobind from 'autobind-decorator';
import { getEmailStatus } from '../../../helpers/email.helpers';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ projectId: string }> {
  projectDetailsBl: IProjectDetailsBl;
  userService: IUserService;
  workspaceService: IWorkspaceService;
}

interface IState extends ILoaderState {
  email: string;
  emailState: InputStatus;
  selectedRole: ProjectRole;
}

@injectProps({
  projectDetailsBl: ProjectDetailsBlType,
  userService: UserServiceType,
  workspaceService: WorkspaceServiceType,
})
@observer
class UsersFormContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  state = {
    email: '',
    emailState: InputStatus.empty(),
    selectedRole: ProjectRole.Annotator,
    isLoading: false,
  };

  componentDidMount() {
    if (this.props.workspaceService.isInRole([WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Trainer])) {
      this.props.workspaceService.getCurrentWorkspaceMembersAsync();
    }
  }

  @autobind
  handleEmailChange(email: string) {
    this.setState({
      email,
      emailState: InputStatus.empty(),
    });
  }

  @autobind
  handleRoleChange(role: ProjectRole) {
    this.setState({ selectedRole: role });
  }

  @autobind
  validateEmail(value: string) {
    const emailState = getEmailStatus(value, [...this.props.projectDetailsBl.store.users.map(u => u.email)]);
    this.setState({ emailState });
    return emailState.isValid;
  }

  @autobind
  async handleAddNewUser() {
    if (!this.validateEmail(this.state.email)) return;

    await this.withLoaderAsync(async () => {
      await this.props.projectDetailsBl.addUserToProjectAsync(this.props.match.params.projectId, this.state.email.trim(), this.state.selectedRole);

      await this.props.userService.getUserInfoAsync(); // to get user role in project

      this.setState({
        email: '',
        emailState: InputStatus.empty(),
      });
    }, 'add-user-button');
  }

  render() {
    const roles = [ProjectRole.Annotator, ProjectRole.Reviewer];

    const existingUsersEmails = this.props.projectDetailsBl.store.users.map(x => x.email);
    const users = this.props.workspaceService.store.currentWorkspaceTeam.filter(x => !existingUsersEmails.includes(x.email));

    return (
      <UsersForm
        roles={roles}
        users={users}
        email={this.state.email}
        emailStatus={this.state.emailState}
        selectedRole={this.state.selectedRole}
        onEmailChange={this.handleEmailChange}
        onRoleChange={this.handleRoleChange}
        validateEmail={this.validateEmail}
        onAddNewUser={this.handleAddNewUser}
      />
    );
  }
}

export const UsersFormContainer = as<React.ComponentClass>(withRouter(UsersFormContainerPure));
