﻿import * as React from 'react';

import { IIntegrationsService, IntegrationsServiceType } from '../integrations.service';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { InputStatus, StickerError } from '../../../models/error.model';
import { as, injectProps } from '../../../helpers/react.helpers';

import { CreateApiKeyForm } from '../components/CreateApiKeyForm';
import autobind from 'autobind-decorator';

export interface IInjectedProps {
  integrationsService: IIntegrationsService;
}

interface IState extends ILoaderState {
  name: string;
  nameState: InputStatus;
}

@injectProps({
  integrationsService: IntegrationsServiceType,
})
class CreateApiKeyFormContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  state = {
    name: '',
    nameState: InputStatus.empty(),
    isLoading: false,
  };

  @autobind
  handleEmailChange(name: string) {
    this.setState({
      name,
      nameState: InputStatus.empty(),
    });
  }

  @autobind
  validateName(value: string) {
    const nameState = InputStatus.valid();

    if (value.trim() === '') {
      nameState.addErrorCode('field_cant_be_empty');
    }

    if (!this.props.integrationsService.isNameUnique(value, '')) {
      nameState.addErrorCode('integrations:api_key_name_must_be_unique');
    }

    this.setState({ nameState });
    return nameState.isValid;
  }

  @autobind
  async handleAddNewApiKey() {
    await this.withLoaderAsync(
      async () => {
        if (!this.validateName(this.state.name)) return;
        const result = await this.props.integrationsService.createApiKey(this.state.name.trim());

        if (result instanceof StickerError) {
          this.setState({
            nameState: InputStatus.buildFrom([result.isBadRequestWithCode(['API_KEY_NAME_TAKEN']) ? 'integrations:api_key_name_must_be_unique' : 'something_went_wrong']),
          });
        } else {
          this.setState({
            name: '',
            nameState: InputStatus.empty(),
          });
        }
      },
      'add-api-key-button');
  }

  render() {
    return (
      <CreateApiKeyForm
        name={this.state.name}
        nameStatus={this.state.nameState}
        onNameChange={this.handleEmailChange}
        validateName={this.validateName}
        onAddNewApiKey={this.handleAddNewApiKey}
      />
    );
  }
}

export const CreateApiKeyFormContainer = as<React.ComponentClass>(CreateApiKeyFormContainerPure);
