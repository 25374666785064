import * as React from 'react';
import { as, injectProps } from '../../../../__legacy__/helpers/react.helpers';
import { IModelDetailsService, ModelDetailsServiceType } from '../../modelDetails.service';
import { observer } from 'mobx-react';
import autobind from 'autobind-decorator';
import { AuthStoreType, IAuthStore } from '../../../../__legacy__/modules/auth/auth.store';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../common/OverlayLoader.store';
import { Settings } from '../../../settings/SettingsContext';
import { predictionImagesWithTranslation } from '../../../../__legacy__/components/predictionImages/PredictionImages';
import { RouteComponentProps, matchPath, withRouter } from 'react-router-dom';
import { Home } from '../../../../__legacy__/routes/config/Home';
import { MODEL_STATUS } from '../../../models/models.model';

const PredictionImages = predictionImagesWithTranslation('models');

interface IInjectedProps extends RouteComponentProps {
  modelDetailsService: IModelDetailsService;
  authStore: IAuthStore;
  overlayLoader: IOverlayLoaderStore;
}

@injectProps({
  modelDetailsService: ModelDetailsServiceType,
  authStore: AuthStoreType,
  overlayLoader: OverlayLoaderStoreType,
})
@observer
class ModelImagesContainerPure extends React.Component<IInjectedProps> {
  static contextType = Settings;
  declare context: React.ContextType<typeof Settings>;

  @autobind
  async handlePaginationChange(pageNumber: number, pageSize: number) {
    this.props.modelDetailsService.changePagination(pageNumber, pageSize);
    this.context.setModelsImagesPageSize(pageSize);
  }

  @autobind
  async handleSwitchChange() {
    await this.props.modelDetailsService.togglePredictions();
  }

  render() {
    const { modelImagesPaging, images, status } = this.props.modelDetailsService.store;
    const isLoading = !!this.props.overlayLoader.isSpinnerVisible('model-images-loader') || !!this.props.overlayLoader.isSpinnerVisible('model-images-predictions-loader');
    const showSwitchGroup = !matchPath(this.props.location.pathname, Home.Models.Create.Images.Path)?.isExact;
    const disableSwitch = ![MODEL_STATUS.FINISHED, MODEL_STATUS.IMPORTED].includes(status);

    return (
      <PredictionImages
        jobType={'TRAINING'}
        allowImageDetails={this.props.modelDetailsService.store.status !== MODEL_STATUS.DRAFT}
        isLoading={isLoading}
        pagination={{
          pageNumber: modelImagesPaging.pageNumber,
          pageSize: modelImagesPaging.pageSize,
          totalCount: modelImagesPaging.totalCount,
          onChange: this.handlePaginationChange,
        }}
        showPredictions={modelImagesPaging.fetchPredictions}
        onSwitchChange={this.handleSwitchChange}
        detailsImages={images}
        authToken={this.props.authStore.token}
        showSwitchGroup={showSwitchGroup}
        disableSwitch={disableSwitch}
      />
    );
  }
}

export const ModelImagesContainer = as<React.ComponentClass>(withRouter(ModelImagesContainerPure));
