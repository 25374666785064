import * as React from 'react';

import { ISegmentationContextMenuChildrenProps } from './SegmentationContextMenu';
import { ITranslatable } from '../../../../../../helpers/translations.helpers';
import { as } from '../../../../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface ISegmentationContextMenuItemProps extends ISegmentationContextMenuChildrenProps {
  action(): void;
  text: string;
  icon: string;
}

class SegmentationContextMenuItemPure extends React.Component<ISegmentationContextMenuItemProps & ITranslatable> {

  handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.props.action();
    this.props.onMenuClose();
  }

  handleMouseUp = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
  }

  render() {
    return (
      <li onClick={this.handleOnClick} onMouseUp={this.handleMouseUp}>
        <div className={`menu-icon ${this.props.icon}`} /> {this.props.t(this.props.text)}
      </li>
    );
  }
}

export const SegmentationContextMenuItem = as<React.ComponentClass<ISegmentationContextMenuItemProps>>(withNamespaces('common')(SegmentationContextMenuItemPure));
