import * as React from 'react';
import { as } from '../../../../__legacy__/helpers/react.helpers';
import { GTable } from '../../../../__legacy__/components/table/GTable';
import { toLocaleDateTimeString } from '../../../../__legacy__/helpers/date.helpers';
import { withNamespaces } from 'react-i18next';
import { ITranslatable } from '../../../../__legacy__/helpers/translations.helpers';
import { EVALUATION_STATUS } from '../../../evaluations/evaluations.model';
import { IEvaluationDataset } from '../../evaluationDetails.store';

interface IProps {
  disableAll: boolean;
  datasets: IEvaluationDataset[];
  datasetIds: string[];
  status: EVALUATION_STATUS;
  changeDatasetSelection(e: React.ChangeEvent<HTMLInputElement>): void;
  changeAllDatasetsSelection(): void;
}

const EvaluationDatasetsListTablePure = (props: IProps & ITranslatable) => {
  const { t, datasets, datasetIds, status, changeDatasetSelection, changeAllDatasetsSelection, disableAll } = props;
  const isDisabled = disableAll || (status !== EVALUATION_STATUS.DRAFT && status !== EVALUATION_STATUS.STOPPED && status !== EVALUATION_STATUS.FAILED);

  const columns = React.useMemo(() => {
    return [
      {
        field: '',
        headerName: '',
        cellClass: 'd-flex align-items-center',
        renderer: (item: IEvaluationDataset) => (
          <>
            <input className="checkbox-primary" type="checkbox" checked={datasetIds.includes(item.id)} value={item.id} onChange={changeDatasetSelection} disabled={isDisabled} />
          </>
        ),
        headerRenderer: () => (
          <>
            <input
              className="checkbox-primary"
              type="checkbox"
              disabled={isDisabled}
              checked={datasets.length === datasetIds.length && datasets.length > 0}
              onChange={changeAllDatasetsSelection}
            />
          </>
        ),
        sortable: false,
        width: 50,
      },
      {
        field: 'name',
        headerName: 'Name',
        sortable: true,
        width: 300,
      },
      {
        field: 'description',
        headerName: 'Description',
        sortable: true,
        minWidth: 300,
      },
      {
        field: 'imagesCount',
        headerName: 'Images',
        sortable: true,
        width: 200,
      },
      {
        field: 'Images',
        headerName: 'Split',
        renderer: ({ testImagesCount, trainImagesCount, validationImagesCount }: IEvaluationDataset) => (
          <>
            {trainImagesCount}/{validationImagesCount}/{testImagesCount}
          </>
        ),
        sortable: true,
        width: 200,
      },
      {
        field: 'created',
        headerName: 'Created',
        renderer: (item: IEvaluationDataset) => <>{toLocaleDateTimeString(item.createdDate)}</>,
        sortable: true,
        width: 200,
      },
    ];
  }, [datasets, datasetIds]);

  return (
    <div>
      <GTable columns={columns} items={datasets} noItemsPlaceholder={t('no_datasets_in_project')} />
    </div>
  );
};

export const EvaluationDatasetsListTable = as<React.FC<IProps>>(withNamespaces('evaluations')(EvaluationDatasetsListTablePure));
