// tslint:disable: function-name

import { Feature, MultiPolygon, Polygon } from 'geojson';
import { IBBox, IGeometry, Shape, clipShapes, intersectShapeWithImagebounds, multipoligonToPoints, normalizeMultipolygon, normalizeShape, shapeToCoords } from './BrushGeometry';
import L, { LatLngTuple, Map } from 'leaflet';

import { BRUSH_MODE } from '../../../annotations.interface';
import ClipperLib from 'clipper-lib';
import uuid from 'uuid';

export interface IAction {
  shape: Shape;
  action: BRUSH_MODE;
}

export class BrushStack {
  public hasNewData: boolean = false;
  public currentGeometry: IGeometry[] = [];
  public currentGeometryIdCached: string | number | undefined = '';
  private _map: Map;
  private _actionStack: IAction[] = [];
  private _isProcessingStack: boolean = false;
  private _brushColor: string;
  private _fillOpacity: number;
  private _currentPolygons: { id: string; polygon: L.Polygon }[] = [];
  private _shapesToDeleteIds: string[] = [];
  private finishDrawing?: (value: void | PromiseLike<void>) => void;

  get drawingPromise(): Promise<void> {
    if (this._actionStack.length === 0) {
      this.finishDrawing = undefined;
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      this.finishDrawing = resolve;
    }).then(() => {
      this.finishDrawing = undefined;
    });
  }

  constructor(map: Map, feature: Feature<any> | undefined, color: string, fillOpacity: number) {
    this._map = map;
    this._brushColor = color;
    this._fillOpacity = fillOpacity;
    this.updateFeature(feature);
  }

  public clearCurrentPolygons() {
    this.hasNewData = false;
    this._currentPolygons.forEach(x => x.polygon.remove());
    this._currentPolygons = [];
  }

  public stackAction(shape: Shape | null, action: BRUSH_MODE) {
    if (!shape) {
      return;
    }

    this._actionStack.push({ shape, action });
    setTimeout(() => {
      this._processStack();
    }, 0);
  }

  public updateFillOpacity(fillOpacity: number) {
    this._fillOpacity = fillOpacity;
    this._currentPolygons.forEach(x => x.polygon.setStyle({ fillOpacity }));
  }

  public drawCurrentGeometry(force: boolean = false) {
    if (!this.hasNewData) return;
    // clean outdated
    const toDelete = this._currentPolygons.filter(o => this._shapesToDeleteIds.some(i => i === o.id));
    toDelete.forEach(x => {
      x.polygon.remove();
    });
    this._currentPolygons = this._currentPolygons.filter(o => !toDelete.some(i => i.id === o.id));
    this._shapesToDeleteIds = [];

    // add new shapes
    this.currentGeometry
      .filter(x => x.isNew || force)
      .forEach(x => {
        const coords = shapeToCoords(x.shape);
        const polygon = L.polygon(coords as LatLngTuple[][], { color: this._brushColor, fillOpacity: this._fillOpacity });
        polygon.addTo(this._map);
        this._currentPolygons.push({ polygon, id: x.id });
        x.isNew = false;
      });

    this.hasNewData = false;
  }

  public replaceGeometry(data: IGeometry[]) {
    this.clearCurrentPolygons();
    this.setCurrentGeometry(data);
    this.drawCurrentGeometry(true);
  }

  public get currentFeature(): Feature<Polygon | MultiPolygon, any> {
    const latlngs = [...this.currentGeometry.map(x => shapeToCoords(x.shape))];
    const feature = L.polygon(latlngs as LatLngTuple[][][]).toGeoJSON();
    if (!(feature.geometry.coordinates[0] && feature.geometry.coordinates[0][0])) {
      feature.geometry.coordinates = []; // leaflet returns [[undefined]] for empty polygon;
    }
    return feature;
  }

  private updateFeature(feature: Feature<any> | undefined) {
    if (feature) {
      if (feature.id !== this.currentGeometryIdCached) {
        const points = multipoligonToPoints((feature.geometry as MultiPolygon).coordinates);
        const normalized = normalizeMultipolygon(points);
        this.currentGeometryIdCached = feature.id;
        this.currentGeometry = normalized;
      } else {
        this.currentGeometry.forEach(x => (x.isNew = true));
      }
    } else {
      this.currentGeometry = [];
      this.currentGeometryIdCached = '';
    }
    this.hasNewData = true;
  }

  private setCurrentGeometry(data: IGeometry[]) {
    this.currentGeometry = data;
    this.hasNewData = true;
  }

  private _draw(shape: Shape) {
    const newPoly: IGeometry = { shape, id: uuid.v4(), isNew: true };
    if (!this.hasAnyCoordinates(this.currentGeometry)) {
      this.setCurrentGeometry([newPoly]);
    } else {
      const { matchedGeometry, notMatchedGeometry } = this.separateMatchedGeometry(newPoly);

      let clipped: IGeometry[] = [];
      if (matchedGeometry.length) {
        const { matchedPaths, notMatchedPaths: notMatchedPaths } = this.separateMatchedPaths(matchedGeometry, newPoly);
        const clippedShape = clipShapes(matchedPaths, shape, ClipperLib.ClipType.ctUnion);
        clippedShape.push(...notMatchedPaths);
        clipped = normalizeShape(clippedShape);
      } else {
        clipped = [{ shape, id: uuid.v4(), isNew: true }];
      }

      this.setCurrentGeometry([...([] as IGeometry[]).concat(...clipped), ...notMatchedGeometry]);
      this._shapesToDeleteIds.push(...matchedGeometry.map(x => x.id));
    }
  }

  private _erase(shape: Shape) {
    if (!this.hasAnyCoordinates(this.currentGeometry)) return;
    const newPoly: IGeometry = { shape, id: uuid.v4(), isNew: true };

    const { matchedGeometry, notMatchedGeometry } = this.separateMatchedGeometry(newPoly);

    if (matchedGeometry.length) {
      const { matchedPaths, notMatchedPaths: notMatchedPaths } = this.separateMatchedPaths(matchedGeometry, newPoly);
      const clippedShape = clipShapes(matchedPaths, shape, ClipperLib.ClipType.ctDifference);
      clippedShape.push(...notMatchedPaths);
      const normalized = normalizeShape(clippedShape);
      this.setCurrentGeometry([...([] as IGeometry[]).concat(...normalized), ...notMatchedGeometry]);
      this._shapesToDeleteIds.push(...matchedGeometry.map(x => x.id));
    }
  }

  private separateMatchedGeometry(newPoly: IGeometry) {
    const matchedGeometry = [];
    const notMatchedGeometry = [];
    for (const current of this.currentGeometry) {
      if (this.bboxContains(current.shape.getBBox(), newPoly.shape.getBBox())) {
        matchedGeometry.push(current);
      } else {
        notMatchedGeometry.push(current);
      }
    }

    return { matchedGeometry, notMatchedGeometry };
  }

  private separateMatchedPaths(shapes: IGeometry[], newPoly: IGeometry) {
    const matchedPaths = [];
    const notMatchedPaths = [];
    for (const path of ([] as Shape).concat(...shapes.map(x => x.shape))) {
      if (path.getOrientation() || this.bboxContains(path.getBBox(), newPoly.shape.getBBox())) {
        matchedPaths.push(path);
      } else {
        notMatchedPaths.push(path);
      }
    }

    return { matchedPaths, notMatchedPaths };
  }

  private bboxContains(current: IBBox, newPoly: IBBox) {
    return newPoly.left < current.right && newPoly.right > current.left && newPoly.top < current.bottom && newPoly.bottom > current.top;
  }

  private _processStack() {
    if (this._isProcessingStack || this._actionStack.length === 0) {
      this.finishDrawing?.();
      return;
    }
    this._isProcessingStack = true;
    const action = this._consolidatePendingShapes();
    if (action.shape.length) {
      if (action.action === BRUSH_MODE.DRAW) {
        this._draw(action.shape);
      } else if (action.action === BRUSH_MODE.ERASE) {
        this._erase(action.shape);
      }
    }

    this._isProcessingStack = false;
    this._processStack();
  }

  private _consolidatePendingShapes(): IAction {
    const stackLength = this._actionStack.length;
    const action = this._actionStack.shift()!;
    let shape = action.shape;

    for (let i = 1; i < stackLength; i += 1) {
      const pending = this._actionStack.shift();
      if (pending) {
        shape = clipShapes(shape, pending.shape, ClipperLib.ClipType.ctUnion);
      }
    }

    const intersection = intersectShapeWithImagebounds(this._map, shape);
    return { action: action.action, shape: intersection };
  }

  private hasAnyCoordinates(data: IGeometry[]): boolean {
    return data && data.length > 0;
  }
}
