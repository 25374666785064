import { AnnotationTypeBlType, IAnnotationTypeBl } from '../modules/annotation/submodules/annotationTypes/annotationType.bl';
import { AnnotationTypeStoreType, IAnnotationTypeStore } from '../modules/annotation/submodules/annotationTypes/annotationType.store';
import { AnnotationUiStoreType, IAnnotationUiStore } from '../modules/annotation/annotationUi.store';
import { AnnotationsStoreType, IAnnotationsStore } from '../modules/annotation/annotations.store';
import { ReviewImagesQueueService, ReviewImagesQueueServiceType } from '../modules/annotation/reviewImagesQueue.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { as, injectProps } from '../helpers/react.helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../helpers/translations.helpers';
import React from 'react';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps extends RouteComponentProps<{ projectId: string }> {
  annotationTypeStore: IAnnotationTypeStore;
  annotationTypeBl: IAnnotationTypeBl;
  annotationUiStore: IAnnotationUiStore;
  imagesQueueService: ReviewImagesQueueService;
  annotationsStore: IAnnotationsStore;
}

interface IState {
  showAnnotationsHiddenToast: boolean;
  imageId: string;
}

@injectProps({
  annotationTypeStore: AnnotationTypeStoreType,
  annotationTypeBl: AnnotationTypeBlType,
  annotationUiStore: AnnotationUiStoreType,
  imagesQueueService: ReviewImagesQueueServiceType,
  annotationsStore: AnnotationsStoreType,
})
@observer
class HiddenAnnotationsToastContainerPure extends React.Component<IInjectedProps & ITranslatable, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      showAnnotationsHiddenToast: false,
      imageId: '',
    };
  }

  get shouldRender() {
    return this.props.annotationsStore.image !== undefined || this.props.annotationUiStore.isImageLoading || this.props.imagesQueueService.isAnyRequestInProgress;
  }

  get hiddenTypesCount() {
    return this.props.annotationTypeStore.hiddenAnnotationTypes.find(x => x.projectId === this.props.match.params.projectId)?.annotationTypes.length;
  }

  showToast = () => {
    this.setState({ showAnnotationsHiddenToast: true });
  };

  hideToast = () => {
    this.setState({ showAnnotationsHiddenToast: false });
  };

  unhideAllAnnotationTypes = () => {
    this.props.annotationTypeBl.unhideAllAnnotationTypeOptions(this.props.match.params.projectId);
    this.props.annotationsStore.setHiddenSegmentations([]);
    this.hideToast();
  };

  anySegmentationIsHidden = (): boolean => {
    const hiddenMarkingToolsIds = this.props.annotationTypeStore.hiddenAnnotationTypes.find(hat => hat.projectId === this.props.match.params.projectId)?.annotationTypes;

    const existsHiddenSegmentation =
      this.props.annotationsStore.hiddenSegmentations.filter(hs =>
        this.props.annotationsStore.segmentations.some(s => (hs.segmentationId !== undefined && hs.segmentationId === s.id) || hs.featureId === s.feature.id),
      ).length > 0;

    const existsSegmentationWithHiddenTool = this.props.annotationsStore.segmentations.some(s =>
      hiddenMarkingToolsIds?.some(id => id === s.feature.properties!['annotationTypeId']),
    );

    return existsHiddenSegmentation || existsSegmentationWithHiddenTool;
  };

  componentDidMount() {
    this.setState({ imageId: this.props.annotationsStore.image?.id || '' });

    if (this.anySegmentationIsHidden()) {
      this.showToast();
    }
  }

  componentDidUpdate() {
    if (this.props.annotationsStore.image !== undefined && this.state.imageId !== this.props.annotationsStore.image.id) {
      this.setState({ imageId: this.props.annotationsStore.image.id });

      if (this.anySegmentationIsHidden()) {
        this.showToast();
      } else {
        this.hideToast();
      }
    }
  }

  render() {
    return this.shouldRender ? (
      <div className="toast-container">
        <Toast isOpen={this.state.showAnnotationsHiddenToast}>
          <ToastHeader toggle={this.hideToast}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span>{this.props.t('warning')}</span>
          </ToastHeader>
          <ToastBody>
            <span>
              {this.props.t('some_annotations_are_hidden')} <br />
            </span>
            <span className="text-link" onClick={this.unhideAllAnnotationTypes}>
              {this.props.t('click_here_to_unhide_all')}
            </span>
          </ToastBody>
        </Toast>
      </div>
    ) : null;
  }
}

export const HiddenAnnotationsToastContainer = as<React.ComponentClass<any>>(withRouter(withNamespaces('common')(HiddenAnnotationsToastContainerPure)));
