import * as React from 'react';

import { AuthContainer } from '../../modules/auth/containers/AuthContainer';
import { AuthLogo } from '../../modules/auth/components/AuthLogo';
import ConfirmEmail from '../../modules/auth/components/emailConfirmation/ConfirmEmail';
import ConfirmEmailContainer from '../../modules/auth/containers/ConfirmEmailContainer';

export const ConfirmEmailView = () => (
  <div>
    <AuthContainer>
      <ConfirmEmailContainer>
        <AuthLogo />
        <ConfirmEmail />
      </ConfirmEmailContainer>
    </AuthContainer>
  </div>
);
