import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { ModelSettingsFormContainer } from '../containers/ModelSettingsForm.container';

export const ModelSettings = () => {
  return (
    <div>
      <Row>
        <Col xs={6}>
          <ModelSettingsFormContainer />
        </Col>
      </Row>
    </div>
  );
};