import * as React from 'react';

import { Map, Point } from 'leaflet';

import { MOUSE_BUTTONS } from '../../../../annotations.interface';
import { as } from '../../../../../../helpers/react.helpers';
import { withLeaflet } from 'react-leaflet';

interface IInjectedProps {
  leaflet: { map: Map };
}

export interface ISegmentationContextMenuChildrenProps {
  onMenuClose(): void;
  segmentationId: string;
}

interface IState {
  currentHeight: number;
  currentWidth: number;
}

interface ISegmentationContextMenuProps {
  position: Point | undefined;
  segmentationId: string | undefined;
  onMenuClose(): void;
  children(childrenProps: ISegmentationContextMenuChildrenProps): React.ReactNode;
}

const Offset = 2;

export class SegmentationContextMenuPure extends React.Component<ISegmentationContextMenuProps & IInjectedProps, IState> {
  wrapperRef: React.RefObject<HTMLDivElement>;

  constructor(props: ISegmentationContextMenuProps & IInjectedProps) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = { currentHeight: 0, currentWidth: 0 };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.props.leaflet.map.on('zoomstart', this.handleMenuClosed);
  }

  componentDidUpdate() {
    const height = this.wrapperRef.current?.clientHeight || 0;
    const width = this.wrapperRef.current?.clientWidth || 0;
    if (height !== this.state.currentHeight || width !== this.state.currentWidth) {
      this.setState({ currentHeight: height, currentWidth: width });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    this.props.leaflet.map.off('zoomstart', this.handleMenuClosed);
  }

  handleClickOutside = (event: MouseEvent) => {
    const targetElement = event.target as HTMLElement;
    const isRightClick = (event.buttons & MOUSE_BUTTONS.RIGHT) === MOUSE_BUTTONS.RIGHT;
    const isSelfClick = this.wrapperRef && this.wrapperRef.current?.contains(event.target as any);
    if (!isSelfClick && (!targetElement.classList.contains('with-context-menu') || !isRightClick)) {
      this.handleMenuClosed();
    }
  }

  handleMouseOver = () => {
    this.props.leaflet.map.dragging.disable();
  }

  handleMouseLeave = () => {
    this.props.leaflet.map.dragging.enable();
  }

  handleMenuClosed = () => {
    this.props.leaflet.map.dragging.enable();
    this.props.onMenuClose();
  }

  calculateCorrectPosition(position: Point): ({ x: number, y: number }) {
    let x = position.x + Offset;
    let y = position.y + Offset;

    const mappWrapperElement = document.getElementsByClassName('map-wrapper')[0]!;

    if (this.state.currentHeight === undefined) {
      return { x: mappWrapperElement.clientWidth, y: mappWrapperElement.clientHeight };
    }

    if (x + this.state.currentWidth > mappWrapperElement.clientWidth) {
      x = x - this.state.currentWidth;
    }

    if (y + this.state.currentHeight > mappWrapperElement.clientHeight) {
      y = y - this.state.currentHeight;
    }

    return { x, y };
  }

  render() {
    if (!this.props.position || !this.props.segmentationId) return null;

    const correctPosition = this.calculateCorrectPosition(this.props.position);

    return (
      <div
        ref={this.wrapperRef}
        onMouseOver={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
        className="segmentation-context-menu"
        style={{ left: correctPosition.x, top: correctPosition.y }}
      >
        <ul>
          {this.props.children({
            onMenuClose: this.handleMenuClosed,
            segmentationId: this.props.segmentationId,
          })}
        </ul>
      </div>
    );
  }
}

export const SegmentationContextMenu = as<React.ComponentClass<ISegmentationContextMenuProps>>(withLeaflet(SegmentationContextMenuPure));
