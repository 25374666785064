import * as signalR from '@microsoft/signalr';
import { injectable, inject } from 'inversify';
import { AuthStoreSetterType, IAuthStoreSetter } from '../modules/auth/auth.store';
import { action, when } from 'mobx';

export interface IBaseHubService {
  initializeAsync(): Promise<void>;
}

@injectable()
export abstract class BaseHubService implements IBaseHubService {
  protected connection: signalR.HubConnection;

  constructor(
    @inject(AuthStoreSetterType) private readonly auth: IAuthStoreSetter,
    private readonly hubUrl: string,
  ) {
    this.connection = this.buildConnection();
    this.connection.onclose(() => this.connectAsync());
  }

  buildConnection() {
    return new signalR.HubConnectionBuilder()
      .withUrl(this.hubUrl, { accessTokenFactory: () => this.auth.token })
      .configureLogging(signalR.LogLevel.Critical)
      .build();
  }

  @action.bound
  public async initializeAsync() {
    await this.connectAsync();
  }

  disconnectHub = () => {
    this.connection.stop();
  }

  async connectAsync() {
    if (!this.auth.isAuthenticated) return;
    if (this.connection.state === signalR.HubConnectionState.Connected) return;

    try {
      await this.connection.start();
      when(() => this.auth.token === '', () => this.disconnectHub());
    } catch (error) {
      if (this.stopConnecting((error as signalR.HttpError).statusCode)) return;
      setTimeout(() => this.connectAsync(), 5000);
    }
  }

  stopConnecting(statusCode: number) {
    if (statusCode === 401) {
      this.auth.clearToken();
      return true;
    }
    if (statusCode === 460) return true;

    return false;
  }
}
