import * as React from 'react';

import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IDeleteWorkspaceConfirmationModalProps {
  deleteWorkspaceModalShown: boolean;
  onConfirmDelete(): void;
  onCancelDelete(): void;
}

const DeleteWorkspaceConfirmationModalPure = (props: IDeleteWorkspaceConfirmationModalProps & ITranslatable) => {
  return (
    <ConfirmationModal
      showModal={props.deleteWorkspaceModalShown}
      confirmationHeader={props.t('delete_workspace')}
      confirmationQuestion={props.t('delete_confirmation_workspace_message')}
      onCancel={props.onCancelDelete}
      onConfirm={props.onConfirmDelete}
      confirmationYes={props.t('delete')}
      loaderKey="delete-workspace-button"
    />
  );
};

export const DeleteWorkspaceConfirmationModal = as<React.SFC<IDeleteWorkspaceConfirmationModalProps>>(
  withNamespaces(['workspace', 'common'] as any, { wait: true })(DeleteWorkspaceConfirmationModalPure),
);
