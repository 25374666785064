import * as React from 'react';

import { TabsSingleItem, TabsSingleItemState } from '../../../__legacy__/components/TabsSingleItem';
import { Home } from '../../../__legacy__/routes/config/Home';
import { withNamespaces } from 'react-i18next';
import { ITranslatable } from '../../../__legacy__/helpers/translations.helpers';
import { TabsContainer } from '../../../__legacy__/containers/TabsContainer';
import { as } from '../../../__legacy__/helpers/react.helpers';
import { EVALUATION_STATUS } from '../../evaluations/evaluations.model';
import { IEvaluationDetailsValidationErrors } from '../evaluationDetails.store';

interface IProps {
  evaluationId?: string;
  evaluationStatus: EVALUATION_STATUS;
  workspaceId: string;
  evaluationDetailsValidationErrors: IEvaluationDetailsValidationErrors;
}

const EvaluationDetailsTabsPure = (props: IProps & ITranslatable) => {
  const { t, evaluationId, workspaceId, evaluationStatus, evaluationDetailsValidationErrors } = props;

  function isValidatedTab(link: string): link is Exclude<keyof IEvaluationDetailsValidationErrors, 'showALl'> {
    return evaluationDetailsValidationErrors[link as keyof IEvaluationDetailsValidationErrors] !== undefined;
  }

  const getClass = (link: string): TabsSingleItemState => {
    const pathname = window.location.pathname.split('/');
    const tabName = pathname[pathname.length - 1];
    const className = tabName === link ? 'active' : 'passive';

    if (isValidatedTab(link)) {
      const validationErrors = evaluationDetailsValidationErrors[link];
      let isError = false;

      if (typeof validationErrors === 'undefined') {
        return className;
      }

      if (typeof validationErrors === 'boolean') {
        isError = validationErrors;
      } else {
        // Nothing validated yet
        if (validationErrors.size === 0 && !evaluationDetailsValidationErrors.showAll) {
          return className;
        }

        isError = Array.from(validationErrors.values()).some(error => error !== null);
      }

      const classNameSuffix = isError ? 'error' : 'validated';
      return `${className} ${classNameSuffix}` as TabsSingleItemState;
    }

    return className;
  };

  const buildTab = (link: string, label: string, disabled?: boolean, tooltip?: string): JSX.Element => {
    const route =
      evaluationId === undefined
        ? Home.Evaluations.Create.Paths.withParams({
            workspaceId,
            tab: link,
          })
        : Home.Evaluations.Details.Paths.withParams({
            workspaceId,
            jobId: evaluationId,
            tab: link,
          });

    return <TabsSingleItem text={t(label)} link={route} state={getClass(link)} disabled={disabled} tooltip={tooltip} />;
  };

  const isMetricsDisabled = evaluationStatus === EVALUATION_STATUS.STOPPED || evaluationStatus === EVALUATION_STATUS.STOPPING || evaluationStatus === EVALUATION_STATUS.FAILED;
  const metricsTooltip = isMetricsDisabled ? t('metrics_tooltip') : undefined;

  return (
    <TabsContainer variant="default">
      {buildTab('settings', 'settings')}
      {buildTab('datasets', 'datasets')}
      {buildTab('images', 'images')}
      {evaluationId !== undefined ? buildTab('metrics', 'metrics', isMetricsDisabled, metricsTooltip) : <></>}
    </TabsContainer>
  );
};

export const EvaluationDetailsTabs = as<React.FC<IProps>>(withNamespaces('evaluations')(EvaluationDetailsTabsPure));
