import * as React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { ITranslatable } from '../helpers/translations.helpers';
import { as } from '../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface SaveBeforeRejectConfirmationModalProps {
  showSaveBeforeRejectModal: boolean;
  onSaveBeforeRejectConfirm(): void;
  onSaveBeforeRejectDecline(): void;
  onSaveBeforeRejectCancel(): void;
}

class SaveBeforeRejectConfirmationModalPure extends React.Component<SaveBeforeRejectConfirmationModalProps & ITranslatable> {
  render() {
    return (
      <Modal isOpen={this.props.showSaveBeforeRejectModal} toggle={this.props.onSaveBeforeRejectCancel}>
        <ModalHeader toggle={this.props.onSaveBeforeRejectCancel}>{this.props.t('confirmation')}</ModalHeader>
        <ModalBody>
          <p className="mt-3">{this.props.t('reject_annotation_without_reason_confirmation_body')}</p>
        </ModalBody>
        <ModalFooter>
          <Button className="mr-2" color="success" onClick={this.props.onSaveBeforeRejectConfirm}>
            {this.props.t('yes_send_image')}
          </Button>
          <Button color="primary" className="btn-outline-primary" onClick={this.props.onSaveBeforeRejectDecline}>
            {this.props.t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export const SaveBeforeRejectConfirmationModal = as<React.StatelessComponent<SaveBeforeRejectConfirmationModalProps>>(
  withNamespaces('annotation', { wait: true })(SaveBeforeRejectConfirmationModalPure),
);
