import { IRenameAttachmentStore, RenameAttachmentStoreType } from '../../RenameAttachment.store';
import { RenameAttachmentBl, RenameAttachmentBlType } from '../../RenameAttachment.bl';
import { as, injectProps } from '../../../../../__legacy__/helpers/react.helpers';

import React from 'react';
import { RenameAttachmentModal } from './RenameAttachmentModal';
import { WithLoaderComponentBase } from '../../../../../__legacy__/helpers/loader.helpers';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: RenameAttachmentBl;
  store: IRenameAttachmentStore;
}
@injectProps({
  bl: RenameAttachmentBlType,
  store: RenameAttachmentStoreType,
})
@observer
class RenameAttachmentModalContainerPure extends WithLoaderComponentBase<InjectedProps> {
  handleSubmit = async () => await this.withLoaderAsync(this.props.bl.renameAttachmentAsync, 'rename-attachment-key');
  handleHide = () => this.props.bl.hideModal();
  handleNameChange = (name: string) => this.props.bl.changeName(name);

  render(): React.ReactNode {
    return (
      <RenameAttachmentModal
        show={this.props.store.showModal}
        onSubmit={this.handleSubmit}
        onHide={this.handleHide}
        nameStatus={this.props.store.nameStatus}
        name={this.props.store.name}
        onNameChange={this.handleNameChange}
      />
    );
  }
}

export const RenameAttachmentModalContainer = as<React.ComponentClass>(RenameAttachmentModalContainerPure);
