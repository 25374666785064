import { Col, Row } from 'reactstrap';

import React from 'react';
import { S_Select } from '../../../../../../design/inputs/select/S_Select';
import { timeZones } from '../../../../../models/timeZones/timeZones.model';

interface IProps {
  selectedTimeZone: number;
  onTimeZoneChanged(timeZone: number): void;
}

export const UpdateWorkspaceTimeZoneForm: React.FC<IProps> = (props: IProps) => {
  const handleTimeZoneChanged = (label: string) => {
    const newValue = timeZones.find(tz => tz.label === label)?.value;

    if (!newValue === undefined) return;

    props.onTimeZoneChanged(newValue!);
  };

  const selectedTimeZoneLabel = timeZones.find(tz => tz.value === props.selectedTimeZone)?.label;

  return (
    <Row>
      <Col className="mb-2">
        <S_Select onChange={handleTimeZoneChanged} options={timeZones.map(t => t.label)} selected={selectedTimeZoneLabel!} />
      </Col>
    </Row>
  );
};
