import { injectable, inject } from 'inversify';
import ShopifyBuy from 'shopify-buy';
import { UserStoreType, IUserStore, ICredits } from '../user/user.store';
import { ApiServiceType } from '../../services/api.service';
import { IApiService } from '../../services/api.service.base';
import { action } from 'mobx';
import { StickerError } from '../../models/error.model';
import { BillingStoreType, IBillingStore } from './billing.store';
import { IConfiguration, ConfigurationType } from '../../../configuration';

export const BillingServiceType = Symbol('BILLING_SERVICE');

export interface IBillingService {
  billing: IBillingStore;
  initAsync(): Promise<void>;
  requestAsync(credits: number): Promise<void | StickerError>;
  getCreditsAsync(): Promise<void | StickerError>;
}

@injectable()
export class BillingService implements IBillingService {
  client: any;
  productVariantId?: string;
  constructor(
    @inject(UserStoreType) public readonly user: IUserStore,
    @inject(BillingStoreType) public readonly billing: IBillingStore,
    @inject(ApiServiceType) private readonly apiService: IApiService,
    @inject(ConfigurationType) private readonly config: IConfiguration,
  ) {
  }

  @action
  async initAsync() {
    this.client = await ShopifyBuy.buildClient({
      domain: this.config.appConfig.shopifyConfig.domain,
      storefrontAccessToken: this.config.appConfig.shopifyConfig.accessToken,
    });

    try {
      this.billing.enabled = this.config.appConfig.shopifyConfig.enabled;
      if (this.billing.enabled) {
        const product = await this.client.product.fetchByHandle(this.config.appConfig.shopifyConfig.creditProductHandle);
        this.productVariantId = product.variants[0].id;
        this.billing.creditPrice = product.variants[0].priceV2.amount;
        this.billing.currency = product.variants[0].priceV2.currencyCode;
      }
    } catch {
      this.billing.enabled = false;
    }
  }

  @action
  async requestAsync(credits: number) {
    const cart = await this.client.checkout.create({
      email: this.user.userInfo.email,
      lineItems: [
        {
          variantId: this.productVariantId,
          quantity: credits / 1000,
        },
      ],
    });

    window.location.href = cart.webUrl;
  }

  @action
  async getCreditsAsync(): Promise<void | StickerError> {
    const result = (await this.apiService.getAsync('/Billing/GetCredits', {})) as ICredits;
    this.billing.accountExists = result.accountExists;
    this.billing.availableCredits = result.availableCredits;
    this.billing.totalCredits = result.totalCredits;
    this.billing.usedCredits = result.usedCredits;
  }
}
