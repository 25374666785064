import { withNamespaces } from 'react-i18next';
import { Form, FormGroup, Label } from 'reactstrap';
import React from 'react';
import { ITranslatable } from '../../../__legacy__/helpers/translations.helpers';
import { as } from '../../../__legacy__/helpers/react.helpers';
import { IEvaluationDetailsValidationErrors, IEvaluationProject } from '../evaluationDetails.store';
import { ValueType } from 'react-select/lib/types';
import { EVALUATION_STATUS } from '../../evaluations/evaluations.model';
import { SelectOption } from '../evaluationDetails.model';
import { ValidatedTextInput } from '../../../__legacy__/components/ValidatedTextInput';
import { ValidatedSelectInput } from '../../../__legacy__/components/ValidatedSelectInput';
import { MODEL_VARIANTS, MODEL_VARIANTS_LIST } from '../../../__legacy__/models/metrics.model';

interface IProps {
  name: string;
  description: string;
  projectId: string;
  modelVariant: MODEL_VARIANTS;
  projects: IEvaluationProject[];
  onChangeProject: (project: ValueType<SelectOption>) => void;
  onChangeName: (name: string) => void;
  onChangeDescription: (description: string) => void;
  status: EVALUATION_STATUS;
  models: IEvaluationProject[];
  modelId: string;
  onChangeModel: (model: ValueType<SelectOption>) => void;
  handleBlurName: (name: string) => void;
  handleBlurProject: (projectId: string) => void;
  handleBlurModel: (modelId: string) => void;
  handleBlurDescription(description: string): void;
  errors: IEvaluationDetailsValidationErrors;
  isAllDisabled: boolean;
  onChangeModelVariant: (value: ValueType<SelectOption>) => void;
  handleBlurModelVariant: (modelVariant: MODEL_VARIANTS) => void;
}

const ModelDetailsInfoFormPure = (props: IProps & ITranslatable) => {
  const {
    errors,
    handleBlurModel,
    handleBlurProject,
    handleBlurName,
    handleBlurDescription,
    name,
    description,
    onChangeName,
    onChangeDescription,
    t,
    models,
    modelId,
    onChangeModel,
    projects,
    status,
    projectId,
    modelVariant,
    onChangeProject,
    isAllDisabled,
    handleBlurModelVariant,
    onChangeModelVariant,
  } = props;

  const isDisabled = isAllDisabled || (status !== EVALUATION_STATUS.DRAFT && status !== EVALUATION_STATUS.STOPPED && status !== EVALUATION_STATUS.FAILED);

  const validationError = (label: string): { message: string } | null | undefined => errors.header.get(label);
  const isFieldValidated = (label: string) => validationError(label) !== undefined || errors.showAll;
  const hasError = (label: string) => validationError(label) != null;

  const onBlurSelect = (callback: (param: string) => void, param: string) => () => callback(param);

  const onBlurModelVariant = (callback: (param: MODEL_VARIANTS) => void, param: MODEL_VARIANTS) => () => callback(param);

  return (
    <Form>
      <ValidatedTextInput
        id="model-name-textbox"
        type="text"
        placeholder={t('type_your_model_name_here')}
        value={name}
        onChange={onChangeName}
        disabled={isDisabled}
        onBlur={handleBlurName}
        isValid={isFieldValidated('name') ? !hasError('name') : undefined}
        feedbacks={hasError('name') ? [validationError('name')!.message] : []}
        feedbacksParams={hasError('name') ? { name: t('fields.evaluation_name') } : []}
      />
      <ValidatedTextInput
        id="model-name-textbox"
        type="textarea"
        placeholder={t('type_your_evaluation_description_here')}
        value={description}
        onChange={onChangeDescription}
        onBlur={handleBlurDescription}
        disabled={isDisabled}
        isValid={isFieldValidated('description') ? !hasError('description') : undefined}
      />
      <FormGroup>
        <Label>{t('type')}</Label>
        <ValidatedSelectInput
          id="type-dropdown"
          value={{ label: t(`model_variants.${modelVariant}`), value: modelVariant }}
          options={MODEL_VARIANTS_LIST.map(item => ({ label: t(`model_variants.${item}`), value: item }))}
          onChange={onChangeModelVariant}
          onBlur={onBlurModelVariant(handleBlurModelVariant, modelVariant)}
          disabled={isDisabled}
          isValid={isFieldValidated('modelVariant') ? !hasError('modelVariant') : undefined}
          feedbacks={hasError('modelVariant') ? [validationError('modelVariant')!.message] : []}
          feedbackParams={hasError('modelVariant') ? { name: t('fields.model_variant') } : []}
        />
      </FormGroup>
      <FormGroup>
        <Label>{t('model')}</Label>
        <ValidatedSelectInput
          id="project-dropdown"
          value={models.find(p => p.value === modelId) || null}
          options={models}
          onChange={onChangeModel}
          onBlur={onBlurSelect(handleBlurModel, modelId)}
          disabled={isDisabled}
          isValid={isFieldValidated('model') ? !hasError('model') : undefined}
          feedbacks={hasError('model') ? [validationError('model')!.message] : []}
          feedbackParams={hasError('model') ? { name: t('fields.model') } : []}
        />
      </FormGroup>
      <FormGroup>
        <Label>{t('project')}</Label>
        <ValidatedSelectInput
          id="project-dropdown"
          value={projects.find(p => p.value === projectId) || null}
          options={projects}
          onChange={onChangeProject}
          onBlur={onBlurSelect(handleBlurProject, projectId)}
          disabled={isDisabled}
          isValid={isFieldValidated('project') ? !hasError('project') : undefined}
          feedbacks={hasError('project') ? [validationError('project')!.message] : []}
          feedbackParams={hasError('project') ? { name: t('fields.project') } : []}
        />
      </FormGroup>
    </Form>
  );
};

export const EvaluationDetailsInfoForm = as<React.FC<IProps>>(withNamespaces('evaluations')(ModelDetailsInfoFormPure));
