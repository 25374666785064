import { AttributePopupControl } from './AttributePopupControl';
import { IAnnotationTypeOptions } from '../submodules/annotationTypes/models/annotationTypeOptions';
import { IHiddenSegmentation } from '../annotations.store';
import { ISegmentation } from '../annotations.interface';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { MapContextMenuSuppresor } from './MapContextMenuSuppresor';
import { Point } from 'leaflet';
import React from 'react';
import { SegmentationContextMenuContainer } from '../submodules/segmentations/segmentationContextMenu/containers/SegmentationContextMenu.container';
import { SegmentationFocusControl } from './SegmentationFocusControl';
import { SegmentationFrameDrawControl } from './SegmentationFrameDrawControl';
import { SegmentationLayerFeatures } from './SegmentationLayerFeatures';
import { as } from '../../../helpers/react.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

export interface ISegmentationsComponentProps {
  segmentations: ISegmentation[];
  annotationTypesOptions: IAnnotationTypeOptions[];
  hiddenSegmentations: IHiddenSegmentation[];
  selectedSegmentation?: ISegmentation;
  hoveredSegmentation?: string;
  invalidSegmentation?: ISegmentation;
  readonly?: boolean;
  editable: boolean;
  freeDrawInProgress: boolean;
  areAttributesShown: boolean;
  featureInEditId?: string;
  opacityLevel: number;
  zoomLevel: number;
  activeAnnotationTypeId?: string;
  drawFocusFrameForId?: string;
  reactLeafletRenderFingerprint: string;
  checkPendingChanges(): boolean;
  onSegmentationSelected(id: string): void;
  onSegmentationDeselected(): void;
  onNextSegmentationSelected(): ISegmentation | undefined;
  onPreviousSegmentationSelected(): ISegmentation | undefined;
  onSegmentationTabed(id: string): void;
  onSegmentationOver(segmentation: string): void;
  onSegmentationOut(): void;
}

@observer
class SegmentationsLayerPure extends React.Component<ISegmentationsComponentProps & ITranslatable> {
  render() {
    const props = this.props;
    const invalidQuestionsAnnotationTypesId = new Set(
      props.segmentations.filter(s => s.questions.some(q => q.status.isValid === false)).map(s => s.feature.properties!.annotationTypeId),
    );

    const hiddenAnnotationTypes = props.annotationTypesOptions
      .filter(x => !x.isVisible &&!invalidQuestionsAnnotationTypesId.has(x.id))
      .map(x => x.id);

    const visibleSegmentations = props.segmentations.filter(
      ({ id, feature }) =>
        !hiddenAnnotationTypes.some(x => x === feature.properties!.annotationTypeId) &&
        !this.props.hiddenSegmentations.some(s => (s.segmentationId !== undefined && s.segmentationId === id) || s.featureId === feature.id),
    );

    return (
      <SegmentationFrameDrawControl
        readonly={props.readonly ?? false}
        editable={props.editable}
        segmentation={props.freeDrawInProgress || !props.drawFocusFrameForId ? undefined : props.segmentations.find(x => x.feature.id === props.drawFocusFrameForId)}
        onSegmentationSelected={props.readonly ? props.onSegmentationSelected : props.onSegmentationTabed}
        onSegmentationDeselected={props.onSegmentationDeselected}
        onNextSegmentationSelected={props.freeDrawInProgress ? () => undefined : props.onNextSegmentationSelected}
        onPreviousSegmentationSelected={props.freeDrawInProgress ? () => undefined : props.onPreviousSegmentationSelected}
      >
        <AttributePopupControl
          areAttributesShown={props.areAttributesShown}
          selectedSegmentation={props.selectedSegmentation}
          hoveredSegmentation={props.hoveredSegmentation}
          segmentations={visibleSegmentations}
        >
          <MapContextMenuSuppresor>
            <SegmentationFocusControl invalidSegmentation={props.invalidSegmentation}>
              <SegmentationContextMenuContainer>
                {(handleMenuOpen: (position: Point, segmentationId: string) => void, contextMenuSegmentationId: string | undefined) => (
                  <SegmentationLayerFeatures
                    segmentations={visibleSegmentations}
                    selectedSegmentation={this.props.selectedSegmentation}
                    hoveredSegmentation={this.props.hoveredSegmentation}
                    contextMenuSegmentationId={contextMenuSegmentationId}
                    readonly={this.props.readonly}
                    featureInEditId={this.props.featureInEditId}
                    opacityLevel={this.props.opacityLevel}
                    zoomLevel={this.props.zoomLevel}
                    activeAnnotationTypeId={this.props.activeAnnotationTypeId}
                    onSegmentationSelected={this.props.onSegmentationSelected}
                    onSegmentationOver={this.props.onSegmentationOver}
                    onSegmentationOut={this.props.onSegmentationOut}
                    onContextMenu={handleMenuOpen}
                    reactLeafletRenderFingerprint={this.props.reactLeafletRenderFingerprint}
                  />
                )}
              </SegmentationContextMenuContainer>
            </SegmentationFocusControl>
          </MapContextMenuSuppresor>
        </AttributePopupControl>
      </SegmentationFrameDrawControl>
    );
  }
}

export const SegmentationsLayer = as<React.ComponentClass<ISegmentationsComponentProps>>(withNamespaces('common')(SegmentationsLayerPure));
