import * as React from 'react';
import { as } from '../../../../__legacy__/helpers/react.helpers';
import { Card, CardBody, CardTitle, Form } from 'reactstrap';
import { ITranslatable } from '../../../../__legacy__/helpers/translations.helpers';
import { withNamespaces } from 'react-i18next';
import { BooleanOption } from '../../modelDetails.model';
import { IModelConfig, IModelDetailsValidationErrors } from '../../modelDetails.store';
import { ValueType } from 'react-select/lib/types';
import { MODEL_STATUS } from '../../../models/models.model';
import { BOOLEAN_OPTIONS, PARAMETER_TYPE_INTERNAL, Parameter, Parameters } from '../../../../__legacy__/helpers/parameters.helpers';
import { ValidatedParameter } from '../../../../__legacy__/components/ValidatedParameter';
import { StickerError } from '../../../../__legacy__/models/error.model';

interface IProps {
  params: Parameters;
  modelDetailsValidationErrors: IModelDetailsValidationErrors;
  onChangeNumericParam: (label: string) => (value: string) => void;
  onBlurNumericParam: (label: string) => (value: string) => void;
  onChangeBooleanParam: (label: string) => (value: ValueType<BooleanOption>) => void;
  onBlurBooleanParam: (label: string) => (value: ValueType<BooleanOption>) => void;
  status: MODEL_STATUS;
  modelConfig: IModelConfig;
  isFormDisabled: boolean;
}

const ModelSettingsFormPure = (props: IProps & ITranslatable) => {
  const {
    t,
    params,
    isFormDisabled,
    status,
    modelConfig,
    modelDetailsValidationErrors,
    onChangeNumericParam,
    onBlurNumericParam,
    onChangeBooleanParam,
    onBlurBooleanParam,
  } = props;
  const { showAll, settings: settingsErrors } = modelDetailsValidationErrors;
  const isEditDisabled = isFormDisabled || (status !== MODEL_STATUS.DRAFT && status !== MODEL_STATUS.STOPPED && status !== MODEL_STATUS.FAILED);

  const createFormField = (item: Parameter, key: number): React.ReactNode => {
    const type = item.type;
    switch (type) {
      case PARAMETER_TYPE_INTERNAL.INT:
      case PARAMETER_TYPE_INTERNAL.FLOAT: {
        const { label } = item;
        const validationError = settingsErrors.get(label);

        return (
          <ValidatedParameter
            key={label}
            parameter={item}
            fieldIndex={key}
            disabled={isEditDisabled}
            label={t(`params.${label}`)}
            onBlur={onBlurNumericParam(label)}
            onChange={onChangeNumericParam(label)}
            value={modelConfig?.[label]?.toString() || ''}
            alwaysShowError={showAll}
            error={validationError}
          />
        );
      }
      case PARAMETER_TYPE_INTERNAL.BOOL: {
        const { label } = item;
        const validationError = settingsErrors.get(label);
        const currentValue = BOOLEAN_OPTIONS.find(option => option.value === (modelConfig?.[label] ?? false));
        const onBlur = () => onBlurBooleanParam(label)(currentValue);

        return (
          <ValidatedParameter
            key={label}
            parameter={item}
            fieldIndex={key}
            disabled={isEditDisabled}
            label={t(`params.${label}`)}
            onBlur={onBlur}
            onChange={onChangeBooleanParam(label)}
            value={currentValue}
            alwaysShowError={showAll}
            error={validationError}
          />
        );
      }
      // Will create compilation errors in case any form field isn't handled
      default:
        const exhaustiveCheck: never = type;
        throw new StickerError(`Unhandled form field: ${exhaustiveCheck}`);
    }
  };

  const mapSchemeToForm = () => {
    return params.reduce<React.ReactNode[]>((acc, item, key) => {
      acc.push(createFormField(item, key));
      return acc;
    }, []);
  };

  return (
    <Card className="flex-fill card-with-list slim-margin">
      <CardBody>
        <CardTitle tag={'h5'} className={'name'}>
          {t('parameters')}
        </CardTitle>
        <Form>{mapSchemeToForm()}</Form>
      </CardBody>
    </Card>
  );
};

export const ModelSettingsForm = as<React.FC<IProps>>(withNamespaces('models')(ModelSettingsFormPure));
