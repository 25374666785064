import * as React from 'react';

import { GTable, IGColumnProps } from '../../../components/table/GTable';
import _, { compact } from 'lodash';
import { faBars, faEye, faUnlink } from '@fortawesome/free-solid-svg-icons';

import { DatasetStatus } from '../../datesets/datasetStatus.model';
import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Home } from '../../../routes/config/Home';
import { IProjectDataset } from '../projectDetails.models';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { IconedButton } from '../../../components/IconedButton';
import { IconedLinkButton } from '../../../containers/IconedLinkButton';
import { Link } from '../../../containers/Link';
import { Loader } from '../../../components/Loader';
import { NoAccessTooltip } from '../../../containers/NoAccessTooltip';
import { WarningIconBadge } from '../../../components/badges/iconBadges/WarningIconBadge';
import { as } from '../../../helpers/react.helpers';
import { handleClickAndPassData } from '../../../helpers/formHelpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';

library.add(faUnlink, faEye);

interface IProjectDetailsDatasetsTableProps {
  workspaceId: string;
  projectDatasets: IProjectDataset[];
  isLoading: boolean;
  onUnlink(id: string): void;
  canViewDataset: boolean;
  canUnlinkDataset: boolean;
  canEditDatasetOrder: boolean;
  onDatasetOrderChange(from: number, to: number): void;
}

class ProjectDetailsDatasetsTablePure extends React.Component<IProjectDetailsDatasetsTableProps & ITranslatable> {
  get gColumns() {
    const gColumns: IGColumnProps<IProjectDataset>[] = compact([
      this.props.canEditDatasetOrder && {
        field: '',
        headerName: '',
        cellClass: '',
        width: 60,
        isDragHandle: true,
        renderer: () => <FontAwesomeIcon icon={faBars} />,
      },
      {
        field: 'name',
        headerName: this.props.t('name'),
        renderer: this.renderName,
        sortable: false,
        minWidth: 400,
      },
      {
        field: 'description',
        headerName: this.props.t('description'),
        sortable: false,
        minWidth: 400,
        renderer: this.renderDescription,
      },
      {
        field: 'imagesCount',
        headerName: this.props.t('images'),
        sortable: false,
        width: 90,
      },
      {
        field: 'reviewsDonePercentage',
        headerName: this.props.t('percent_done'),
        renderer: this.renderReviewsDonePercentage,
        sortable: false,
        width: 140,
      },
      {
        field: '',
        headerName: this.props.t('actions'),
        renderer: this.renderActions,
        sortable: false,
        width: 100,
      },
    ]);

    return gColumns;
  }

  renderName = (projectDataset: IProjectDataset) => {
    const isDraft = projectDataset.status === DatasetStatus.DRAFT;
    return isDraft ? this.renderNameDraft(projectDataset) : this.renderNamePublished(projectDataset);
  };

  renderNameDraft = (projectDataset: IProjectDataset) => {
    return (
      <div className={'draft project-details-dataset-list-draft'}>
        <div className="text-link-in-table">
          <Link to={Home.Datasets.Details.Images.withParams({ datasetId: projectDataset.id, workspaceId: this.props.workspaceId })}>
            <span>{projectDataset.name} </span>
          </Link>
        </div>
        <div>
        <Link to={Home.Datasets.Details.Images.withParams({ datasetId: projectDataset.id, workspaceId: this.props.workspaceId })}>
          <WarningIconBadge label={this.props.t('not_published')} title={this.props.t('dataset_must_be_published')} />
        </Link>
        </div>
      </div>
    );
  };

  renderNamePublished = (projectDataset: IProjectDataset) => {
    return (
      <Link to={Home.Datasets.Details.Images.withParams({ datasetId: projectDataset.id, workspaceId: this.props.workspaceId })}>
        <span className="text-link-in-table">{projectDataset.name}</span>
      </Link>
    );
  };

  renderDescription = (projectDataset: IProjectDataset) => {
    const description = projectDataset.description;
    return <span title={description}>{description}</span>;
  };

  renderReviewsDonePercentage = (projectDataset: IProjectDataset) => <>{`${projectDataset.reviewsDonePercentage}%`}</>;

  renderActions = (projectDataset: IProjectDataset) => {
    return (
      <div className="g-actions">
        <NoAccessTooltip hasAccess={this.props.canViewDataset}>
          <DisabledWhenWorkspaceOwnerPolicyExceeded>
            <IconedLinkButton
              disabled={!this.props.canViewDataset}
              to={Home.Datasets.Details.Images.withParams({ datasetId: projectDataset.id, workspaceId: this.props.workspaceId })}
              icon={faEye}
              title={this.props.t('view_dataset')}
            />
          </DisabledWhenWorkspaceOwnerPolicyExceeded>
        </NoAccessTooltip>
        <NoAccessTooltip hasAccess={this.props.canUnlinkDataset}>
          <IconedButton
            disabled={!this.props.canUnlinkDataset}
            onClick={handleClickAndPassData(this.props.onUnlink)(projectDataset.id)}
            icon={faUnlink}
            color="red"
            title={this.props.t('unlink_dataset')}
          />
        </NoAccessTooltip>
      </div>
    );
  };

  render() {
    return (
      <div className="table-container">
        <Loader isLoading={this.props.isLoading}>
          <GTable<IProjectDataset>
            noItemsPlaceholder={this.props.t('no_datasets_have_been_assigned_yet')}
            columns={this.gColumns}
            items={this.props.projectDatasets}
            enableDrag={true}
            onRowDragEnd={this.props.onDatasetOrderChange}
          />
        </Loader>
      </div>
    );
  }
}

export const ProjectDetailsDatasetsTable = as<React.ComponentClass<IProjectDetailsDatasetsTableProps>>(withNamespaces('project', { wait: true })(ProjectDetailsDatasetsTablePure));
