﻿import { PropertyName, ValueIterateeCustom } from 'lodash';
import { keyBy, merge, values } from 'lodash/fp';

interface IArrayItem {
  id: any;
  order: number;
}

export function reorderArray(arr: IArrayItem[], itemId: string, newOrder: number): any[] {
  const offset = arr[0].order;
  const item = arr.find(i => i.id === itemId)!;
  const newArray = arr.filter(i => i.id !== itemId);
  newArray.splice(newOrder - offset, 0, item);
  return refreshOrderProp(newArray, offset);
}

export function refreshOrderProp(arr: IArrayItem[], offset = 0): any[] {
  return arr.map((a, i) => ({ ...a, order: i + offset }));
}

export function outerJoinOn<L, R>(leftKey: ValueIterateeCustom<L, PropertyName>, rightKey: ValueIterateeCustom<R, PropertyName>, left: L[], right: R[]): (L & R)[] {
  return values(merge(keyBy(leftKey, left), keyBy(rightKey, right)));
}