// tslint:disable: function-name

import L, { LatLngBounds, LatLngBoundsLiteral, Map, PolylineOptions } from 'leaflet';

import { ISegmentation } from '../../annotations.interface';
import autobind from 'autobind-decorator';
import { toJS } from 'mobx';

export interface ISegmentationFrameDrawerOptions {
  readonly: boolean;
  editable: boolean;
  segmentation?: ISegmentation;
  selectSegmentation(id: string): void;
  segmentationDeselected(): void;
  nextSegmentationSelected(): ISegmentation | undefined;
  previousSegmentationSelected(): ISegmentation | undefined;
}

@autobind
export class SegmentationFrameDrawer {
  private _map: Map;
  private _polygonFrame!: L.Rectangle;
  private _options: ISegmentationFrameDrawerOptions;
  private readonly FRAME_PADDING: number = 5;
  private readonly FRAME_PADDING_POINT: number = 10;

  constructor(map: Map, options: ISegmentationFrameDrawerOptions) {
    this._map = map;
    this._options = options;
  }

  public update(options: ISegmentationFrameDrawerOptions) {
    this._options = options;

    this.updateFrame();
  }

  @autobind
  public addHandlers() {
    document.addEventListener('keyup', this.handleKeyboardUpEvents);
    document.addEventListener('keydown', this.handleKeyboardDownEvents);

    this._map.on('click', this.mapClicked);
  }

  @autobind
  public removeHandlers() {
    this.clearFrame();
    document.removeEventListener('keyup', this.handleKeyboardUpEvents);
    document.removeEventListener('keydown', this.handleKeyboardDownEvents);

    this._map.off('click', this.mapClicked);
  }

  @autobind
  mapClicked(e: any) {
    if (this._options.readonly && e.originalEvent.target.classList.contains('leaflet-container')) this._options.segmentationDeselected();
  }

  updateFrame() {
    if (this._polygonFrame) this._polygonFrame.removeFrom(this._map);
    if (!this._options.segmentation) return;

    const frameOptions: PolylineOptions = {
      color: this._options.segmentation.feature.color,
      weight: 2,
      dashArray: '5, 5',
      opacity: 0.7,
      fill: false,
    };

    this._polygonFrame = L.rectangle(this.getBounds(toJS(this._options.segmentation)), frameOptions);
    this._polygonFrame.addTo(this._map);
  }

  clearFrame() {
    if (this._polygonFrame) this._polygonFrame.removeFrom(this._map);
  }

  getBounds(segmentation: ISegmentation): LatLngBounds {
    const bounds = L.geoJSON(segmentation.feature.geometry).getBounds();
    const framePadding = segmentation.feature.geometry.type === 'Point' ? this.FRAME_PADDING_POINT : this.FRAME_PADDING;

    const extended: LatLngBoundsLiteral = [
      [bounds.getSouth() - framePadding, bounds.getWest() - framePadding],
      [bounds.getNorth() + framePadding, bounds.getEast() + framePadding],
    ];

    return new LatLngBounds(extended);
  }

  centerOnSegmentation(segmentation: ISegmentation) {
    if (!segmentation) return;
    const bounds = this.getBounds(toJS(segmentation));
    this._map.panTo(bounds.getCenter(), { animate: false });
  }

  @autobind
  private handleKeyboardUpEvents(e: KeyboardEvent) {
    switch (e.key) {
      case 'Escape':
        this._options.segmentationDeselected();
        break;
    }
  }

  @autobind
  private handleKeyboardDownEvents(e: KeyboardEvent) {
    switch (e.key) {
    }
  }
}
