import './S_FieldLabel.scss';

import { Label } from 'reactstrap';
import React from 'react';

interface S_FieldLabelProps {
  text: string;
}

export const S_FieldLabel: React.FC<S_FieldLabelProps> = (props: S_FieldLabelProps) => <Label className="s-field-label">{props.text}</Label>;
