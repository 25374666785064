import { IImageFilterService, ImageFilterServiceType } from '../../services/imageFilters.service';
import { IProjectDetailsImagesStore, ProjectDetailsImagesStoreType } from '../../projectDetailsImages.store';
import { as, injectProps } from '../../../../../../helpers/react.helpers';

import { FilterPill } from '../../components/filters/FilterPill';
import React from 'react';
import { observer } from 'mobx-react';

interface IInjectedProps {
  imageFilterService: IImageFilterService;
  imagesStore: IProjectDetailsImagesStore;
}

@injectProps({
  imageFilterService: ImageFilterServiceType,
  imagesStore: ProjectDetailsImagesStoreType,
})
@observer
class PillsContainerPure extends React.Component<IInjectedProps> {
  handleClearAnnotatedByAsync = async () => {
    await this.props.imageFilterService.clearAnnotatedByAsync();
  }

  handleClearAnnotationToolsAsync = async () => {
    await this.props.imageFilterService.clearAnnotationToolsAsync();
  }

  handleClearCorrectedAsync = async () => {
    await this.props.imageFilterService.clearCorrectedAsync();
  }

  handleClearImageSet = async () => {
    await this.props.imageFilterService.clearImageSetsAsync();
  }

  handleClearDatasets = async () => {
    await this.props.imageFilterService.clearInDatasetsAsync();
  }

  handleClearReviewedBy = async () => {
    await this.props.imageFilterService.clearReviewedByAsync();
  }

  handleClearStatus = async () => {
    await this.props.imageFilterService.clearStatusesAsync();
  }

  render() {
    const imageFilters = this.props.imagesStore.imageFilters;

    return (
      <div className="image-filters-pills">
        <FilterPill
          count={imageFilters.datasets.length}
          labelKey={'datasets'}
          onClear={this.handleClearDatasets}
        />
        <FilterPill
          count={imageFilters.annotatedBy.length}
          labelKey={'annotated_by'}
          onClear={this.handleClearAnnotatedByAsync}
        />
        <FilterPill
          count={imageFilters.reviewedBy.length}
          labelKey={'reviewed_by'}
          onClear={this.handleClearReviewedBy}
        />
        <FilterPill
          count={imageFilters.annotationStatuses.length}
          labelKey={'status'}
          onClear={this.handleClearStatus}
        />
        <FilterPill
          count={imageFilters.annotationTools.filter(x => x.l1FilterType !== undefined).length}
          labelKey={'annotation_tools'}
          onClear={this.handleClearAnnotationToolsAsync}
        />
        <FilterPill
          count={imageFilters.corrected.length}
          labelKey={'corrected'}
          onClear={this.handleClearCorrectedAsync}
        />
        <FilterPill
          count={imageFilters.imageSets.length}
          labelKey={'image_set'}
          onClear={this.handleClearImageSet}
        />
      </div>
    );
  }
}

export const PillsContainer = as<React.ComponentClass>(PillsContainerPure);
