import * as React from 'react';

import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { IProjectPreviewService, ProjectPreviewServiceType } from '../../projectDetails/services/projectPreview.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ projectId: string }> {
  projectPreviewService: IProjectPreviewService;
}

@injectProps({
  projectPreviewService: ProjectPreviewServiceType,
})
@observer
class PreviewControlsContainer extends WithLoaderComponentBase<IInjectedProps, ILoaderState> {
  // tslint:disable-next-line:function-name
  UNSAFE_componentWillMount() {
    this.withLoaderAsync(() => this.props.projectPreviewService.setupAsync(this.props.match.params.projectId));
  }

  componentWillUnmount() {
    this.props.projectPreviewService.dispose();
  }

  render() {
    return React.Children.map(this.props.children, (child: any) => React.cloneElement(child));
  }
}

export default as<React.ComponentClass>(withRouter(PreviewControlsContainer));
