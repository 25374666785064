import { BaseRouteConfig, StickerRoute } from './BaseRoute';

export class IntegrationsPath extends BaseRouteConfig {
  public get Index(): string {
    return 'api';
  }

  public readonly List = {
    Path: new StickerRoute<{ workspaceId: string }>(`${this.Path}/list`),
    All: new StickerRoute<{ workspaceId: string }>(`${this.Path}/list/all`),
  };
}
