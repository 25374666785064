import { inject, injectable } from 'inversify';
import { StickerError } from '../../../../../__legacy__/models/error.model';
import { ApiServiceType } from '../../../../../__legacy__/services/api.service';
import { IApiService } from '../../../../../__legacy__/services/api.service.base';

export const IsCurrentExportGeneratedServiceType = Symbol('IS_CURRENT_EXPORT_GENERATED_SERVICE');

interface IsCurrentExportGeneratedRequest {
  projectId: string;
}

export interface IIsCurrentExportGeneratedService {
  isCurrentExportGeneratedAsync(request: IsCurrentExportGeneratedRequest): Promise<boolean>;
}

@injectable()
export class IsCurrentExportGeneratedService implements IIsCurrentExportGeneratedService {
  constructor(@inject(ApiServiceType) readonly apiService: IApiService) {}

  async isCurrentExportGeneratedAsync(request: IsCurrentExportGeneratedRequest): Promise<boolean> {
    const url = '/Export/IsCurrentExportGenerated';
    const result = await this.apiService.getAsync<boolean>(url, { params: request });

    if (result instanceof StickerError) {
      throw result;
    }

    return result;
  }
}
