import * as React from 'react';

import { AuthContainer } from '../../modules/auth/containers/AuthContainer';
import { BrowserCompatibilityContainer } from '../../modules/auth/containers/BrowserCompatibilityContainer';
import { LoginContainer } from '../../modules/auth/containers/LoginContainer';

export const LoginView = () => (
  <div>
    <BrowserCompatibilityContainer />
    <AuthContainer>
      <LoginContainer />
    </AuthContainer>
  </div>
);