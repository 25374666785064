import { DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import React from 'react';

interface IDropdownItemProps {
  id?: string;
  linkTo: string;
  disabled?: boolean;
}

export class DropdownLinkItem extends React.PureComponent<IDropdownItemProps> {
  render() {
    return (
      <Link to={this.props.linkTo}>
        <DropdownItem id={this.props.id} disabled={this.props.disabled}>{this.props.children}</DropdownItem>
      </Link>
    );
  }
}
