import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { ErrorsServiceType, IErrorsService } from '../errors.service';
import { ErrorsStoreType, IErrorsStore } from '../errors.store';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { as, injectProps } from '../../../helpers/react.helpers';

import Col from 'reactstrap/lib/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Home } from '../../../routes/config/Home';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import Row from 'reactstrap/lib/Row';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps extends ITranslatable, RouteComponentProps {
  errorsStore: IErrorsStore;
  errorsService: IErrorsService;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

@injectProps({
  errorsStore: ErrorsStoreType,
  errorsService: ErrorsServiceType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
@observer
class NotAuthorizedContainerPure extends React.Component<IInjectedProps> {
  @autobind
  navigateToProjectsList() {
    this.props.errorsService.clearUnauthorized();
    this.props.history.push(Home.Projects.List.All.withParams({ workspaceId: this.props.currentWorkspaceStore.currentWorkspace!.id }));
  }

  @autobind
  refresh() {
    this.props.history.go(0);
  }

  render() {
    const isAuthorized = this.props.errorsStore.isAuthorized;

    return isAuthorized ? (
      this.props.children
    ) : (
      <div className="not-authorized-page">
        <Row className="h-100 w-100">
          <Col md="6" className="align-self-center">
            {/* here lies Major Tom */}
          </Col>
          <Col md="6" className="align-self-center">
            <h1>403</h1>
            <p>
              {this.props.t('403_body')} <br /> {this.props.t('403_body2')}
            </p>
            <button onClick={this.navigateToProjectsList} className={'btn btn-primary'}>
              <FontAwesomeIcon icon="home" /> <span className="description">{this.props.t('projects')}</span>
            </button>
            <button onClick={this.refresh} className={'btn btn-primary'}>
              <FontAwesomeIcon icon="sync" /> <span className="description">{this.props.t('refresh')}</span>
            </button>
          </Col>
        </Row>
      </div>
    );
  }
}

export const NotAuthorizedContainer = as<React.ComponentClass>(withRouter(withNamespaces('common')(NotAuthorizedContainerPure)));
