import * as React from 'react';

import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { as } from '../helpers/react.helpers';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faEye, faEyeSlash);

export interface IShowHidePasswordIconProps {
  isPasswordVisible: boolean;
  onClick(): void;
  className: string;
}

export const ShowHidePassword = as<React.SFC<IShowHidePasswordIconProps>>((props: IShowHidePasswordIconProps) => (
  <span onClick={props.onClick}>
    {props.isPasswordVisible && <FontAwesomeIcon icon={'eye'} className={props.className}/>}
    {!props.isPasswordVisible && <FontAwesomeIcon icon={'eye-slash'} className={props.className} />}
  </span>
));
