import { Polygon, Polyline, PolylineProps } from 'react-leaflet';
import { getVectorEndCoords, getVectorStartCoords } from '../../../../helpers/geometry/vectorTool.helpers';

import { AnnotationToolType } from '../../submodules/annotationTypes/models/annotationToolType';
import { ISegmentationFeature } from '../../annotations.interface';
import { ISegmentationPolygonProps } from '../SegmentationLayerFeatures';
import { LatLng } from 'leaflet';
import React from 'react';

export interface IVectorFeatureProps {
  isActive: boolean;
  feature: ISegmentationFeature;
  fillOpacity: number;
  latlngs: LatLng[];
  opacity: number;
  zoomLevel: number;
  onContextMenu(e: L.LeafletMouseEvent): void;
  onSegmentationOver(e: L.LeafletMouseEvent): void;
  onSegmentationOut(e: L.LeafletMouseEvent): void;
  onClick(e: any): void;
}

export class VectorFeature extends React.Component<IVectorFeatureProps> {
  handleRef = (element: any) => {
    if (this.props.isActive && element) {
      element.leafletElement.enableEdit();
    }
  };

  render() {
    const props = this.props;
    const id = props.feature.id;
    return (
      <div key={`${id}-vector-container`}>
        <Polyline<ISegmentationPolygonProps & PolylineProps>
          positions={getVectorStartCoords(props.latlngs[0], props.zoomLevel)}
          key={`${id}-start`}
          storeKey={`${id}-start`}
          color="orange"
          type={AnnotationToolType.VECTOR}
          onclick={props.onClick}
          onmouseover={props.onSegmentationOver}
          onmouseout={props.onSegmentationOut}
          oncontextmenu={props.onContextMenu}
          canRotate={false}
          opacity={props.opacity}
        />
        <Polygon<ISegmentationPolygonProps & PolylineProps>
          positions={getVectorEndCoords(props.latlngs, props.zoomLevel)}
          key={`${id}-end`}
          storeKey={`${id}-end`}
          color={props.feature.color}
          fillColor={props.feature.color}
          fillOpacity={props.opacity}
          type={AnnotationToolType.VECTOR}
          onclick={props.onClick}
          onmouseover={props.onSegmentationOver}
          onmouseout={props.onSegmentationOut}
          oncontextmenu={props.onContextMenu}
          canRotate={false}
          opacity={props.opacity}
        />
        <Polyline<ISegmentationPolygonProps & PolylineProps>
          key={id}
          storeKey={id}
          fillOpacity={props.fillOpacity}
          color={props.feature.color}
          type={AnnotationToolType.VECTOR}
          positions={props.latlngs}
          onclick={props.onClick}
          onmouseover={props.onSegmentationOver}
          onmouseout={props.onSegmentationOut}
          oncontextmenu={props.onContextMenu}
          canRotate={false}
          opacity={props.opacity}
          ref={this.handleRef}
        />
      </div>
    );
  }
}
