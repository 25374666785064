import { inject, injectable } from 'inversify';
import { InputStatus } from '../../../../../../__legacy__/models/error.model';
import { EventBusType, IEventBus } from '../../../../../../__legacy__/services/eventBus.service';
import { EditMarkingToolStoreSetter, EditMarkingToolStoreSetterType } from './EditMarkingTool.store';
import { AnnotationToolType } from '../../../../../../__legacy__/modules/annotation/submodules/annotationTypes/models/annotationToolType';
import { debounce } from 'lodash';
import autobind from 'autobind-decorator';
import { EditMarkingToolApiService, EditMarkingToolApiServiceType, EditMarkingToolRequest } from './services/EditMarkingToolApi.service';
import { CheckMarkingToolNameUniquenessRequest, CheckMarkingToolNameUniquenessService, CheckMarkingToolNameUniquenessServiceType } from '../commons/services/CheckMarkingToolNameUniqueness.service';
import { GetMarkingToolRequest, GetMarkingToolService, GetMarkingToolServiceType } from './services/GetMarkingTool.service';
import { MarkingToolEditedEvent } from './events/MarkingToolEditedEvent';

export const EditMarkingToolBlType = Symbol('EDIT_MARKING_TOOL_BL');

export interface EditMarkingToolBl {
  editMarkingToolAsync: () => Promise<void>;
  hideModal: () => void;
  showModalAsync: (projectId: string, markingTool: string) => void;
  changeName: (newName: string) => void;
  changeToolType: (newType: AnnotationToolType) => void;
  changeColor: (newColor: string) => void;
}

@autobind
@injectable()
export class EditMarkingToolBlImpl implements EditMarkingToolBl {
  constructor(
    @inject(EventBusType) private readonly eventBus: IEventBus,
    @inject(EditMarkingToolStoreSetterType) private readonly store: EditMarkingToolStoreSetter,
    @inject(EditMarkingToolApiServiceType) readonly editFormApiService: EditMarkingToolApiService,
    @inject(CheckMarkingToolNameUniquenessServiceType) readonly checkUniquenessService: CheckMarkingToolNameUniquenessService,
    @inject(GetMarkingToolServiceType) private readonly getAnnotationTypeService: GetMarkingToolService,
  ) {}

  public async editMarkingToolAsync(): Promise<void> {
    await this.validateNameAsync(this.store.name);
    if (!this.store.nameStatus.isValid) return;

    const request = new EditMarkingToolRequest(this.store.name, this.store.markingToolId, this.store.color, this.store.toolType);
    await this.editFormApiService.editMarkingToolAsync(request);

    this.eventBus.sendEvent(new MarkingToolEditedEvent());

    this.hideModal();
  }

  public hideModal() {
    this.store.setShowModal(false);
  }

  public changeName(newName: string): void {
    this.store.setName(newName);
    this.validateNameDebounced(newName);
  }

  public changeToolType(newType: AnnotationToolType): void {
    this.store.setToolType(newType);
  }

  public changeColor(newColor: string): void {
    this.store.setColor(newColor);
  }

  public async showModalAsync(projectId: string, markingToolId: string): Promise<void> {
    const request = new GetMarkingToolRequest(projectId, markingToolId);
    const response = await this.getAnnotationTypeService.getMarkingToolAsync(request);

    this.store.setupStore(projectId, response.model);
  }

  private async validateNameAsync(newName: string) {
    const nameStatus = InputStatus.valid();

    if (newName === '') {
      nameStatus.addErrorCode('field_cant_be_empty');
    }

    if (newName === 'Image') {
      nameStatus.addErrorCode('annotation_type_name_cannot_be_image');
    }

    const request = new CheckMarkingToolNameUniquenessRequest(this.store.projectId, newName, this.store.markingToolId);
    if (!(await this.checkUniquenessService.checkMarkingToolNameUniqueness(request))) {
      nameStatus.addErrorCode('annotation_tool_name_must_be_unique');
    }

    this.store.setNameStatus(nameStatus);
  }

  private validateNameDebounced = debounce(async (name: string) => await this.validateNameAsync(name), 300);
}
