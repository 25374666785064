import { AnnotationReviewStoreType, IAnnotationReviewStore } from '../../review/annotationReview.store';
import { AnnotationTypeBlType, IAnnotationTypeBl } from '../../annotationTypes/annotationType.bl';
import { AnnotationsStoreType, IAnnotationsStore } from '../../../annotations.store';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { VerticalMenu, VerticalMenuItem } from '../components/verticalMenu/VerticalMenu';
import { as, injectProps } from '../../../../../helpers/react.helpers';
import { compact, omit, toPairs } from 'lodash/fp';

import { ImageSegmentations } from '../segmentationContextMenu/imageSegmentations.context';
import { ObjectsListContainer } from '../components/objectsList/ObjectsList.container';
import { ProjectPicker } from '../components/projectPicker/ProjectPicker';
import React from 'react';
import { ReviewMode } from '../../review/models/reviewMode';
import { SortingDirection } from '../../../../../models/sortingDirection.model';
import { observer } from 'mobx-react';
import { sortBy } from '../../../../../helpers/collections.helper';
import { AnnotationUiStoreType, IAnnotationUiStore } from '../../../annotationUi.store';

export interface ILeftMenuContainerProps {
  isReadOnly: boolean;
  isReview: boolean;
  showObjectsList: boolean;
  currentProjectId?: string;
  defaultTabOpened?: VerticalMenuItem;
}

interface IInjectedProps {
  annotationReviewStore: IAnnotationReviewStore;
  annotationsStore: IAnnotationsStore;
  annotationTypeBl: IAnnotationTypeBl;
  annotationUiStore: IAnnotationUiStore;
}

type Props = ILeftMenuContainerProps & IInjectedProps & RouteComponentProps<{ projectId: string }>;

@injectProps({
  annotationReviewStore: AnnotationReviewStoreType,
  annotationsStore: AnnotationsStoreType,
  annotationTypeBl: AnnotationTypeBlType,
  annotationUiStore: AnnotationUiStoreType,
})
@observer
class LeftMenuContainerPure extends React.Component<Props, { activeTab?: VerticalMenuItem }> {
  static contextType = ImageSegmentations;
  declare context: React.ContextType<typeof ImageSegmentations>;

  constructor(props: Props) {
    super(props);
    this.state = { activeTab: props.defaultTabOpened };
    props.annotationUiStore.setIsLeftMenuOpened(props.defaultTabOpened !== undefined);
  }

  componentDidMount(): void {
    this.props.annotationsStore.setHiddenSegmentations([]);
    this.props.annotationTypeBl.unhideAllAnnotationTypeOptions(this.props.match.params.projectId);
  }

  handleProjectsPicked = (projectIds: string[]) => {
    this.context.store.selectedProjects.replace(projectIds);
    this.context.store.openedProjects.replace(projectIds);
  };

  handleOpenedChanged = (projectIds: string[]) => {
    this.context.store.openedProjects.replace(projectIds);
  };

  handleClose = () => this.toggle();

  getOptions = () => {
    return sortBy(
      toPairs(omit(compact([this.props.currentProjectId]), this.context.store.segmentations)).map(([projectId, segmentations]) => ({
        ...this.context.store.projects.find(p => p.id === projectId)!,
        count: segmentations.length,
        questions: this.context.store.answeredQuestions[projectId]!,
      })),
      'name',
      SortingDirection.ASC,
    );
  };

  toggle = (menuItem?: VerticalMenuItem) => {
    const activeTab = this.state.activeTab === menuItem ? undefined : menuItem;
    this.setState({ activeTab });
    this.props.annotationUiStore.setIsLeftMenuOpened(activeTab !== undefined);
    this.context.toggleTab(menuItem);
  };

  render() {
    const isReadonly = !(this.props.isReview && this.props.annotationReviewStore.currentMode === ReviewMode.Correct) && this.props.isReadOnly;

    return (
      <>
        <VerticalMenu toggle={this.toggle} activeTab={this.state.activeTab} showObjectsList={this.props.showObjectsList} />
        {this.state.activeTab === VerticalMenuItem.ANNOTATED_IN_PROJECTS && (
          <ProjectPicker
            options={this.getOptions()}
            selected={new Set(this.context.store.selectedProjects)}
            opened={new Set(this.context.store.openedProjects)}
            onChange={this.handleProjectsPicked}
            onOpen={this.handleOpenedChanged}
            onClose={this.handleClose}
            isLoaded={this.context.store.isLoaded}
          />
        )}
        {this.props.showObjectsList && this.state.activeTab === VerticalMenuItem.LIST_OF_OBJECTS && <ObjectsListContainer onClose={this.handleClose} isReadOnly={isReadonly} />}
      </>
    );
  }
}

export const LeftMenuContainer = as<React.ComponentClass<ILeftMenuContainerProps>>(withRouter(LeftMenuContainerPure));
