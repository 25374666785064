import { ImageOverlay, LatLng, LatLngBounds, Layer, Map } from 'leaflet';

import { RotableImageOverlay } from '../../modules/annotation/components/RotableImage';

export const calculateCurrentPosition = (e: MouseEvent, map: Map): LatLng | undefined => {
  if ((e.target as Element).classList?.value.includes('leaflet')) {
    const containerPoint = map.mouseEventToContainerPoint(e);
    const latlng = map.containerPointToLatLng(containerPoint);
    if (map.getBounds().contains(latlng)) {
      return latlng;
    }
  }

  return undefined;
};

export const getImageBounds = (map: Map): LatLngBounds => {
  let imageLayer: RotableImageOverlay | undefined;
  map.eachLayer((x: Layer) => {
    if (x instanceof ImageOverlay) {
      imageLayer = new RotableImageOverlay(x as any);
    }
  });

  return imageLayer ? new LatLngBounds(imageLayer.options.bounds) : new LatLngBounds([0, 0], [0, 0]);
};

export const inImageBounds = (position: LatLng, map: Map): boolean => {
  return getImageBounds(map).contains(position);
};

export const inMapBounds = (position: LatLng, map: Map): boolean => {
  return map.getBounds().contains(position);
};

export const scaleToZoom = (scale: number): number => Math.round(Math.pow(2, scale) * 100);

export const zoomToScale = (zoom: number): number => Math.log2(zoom / 100);
