import * as React from 'react';

import { CreateDatasetFilesServiceType, ICreateDatasetFilesService } from '../createDatasetFiles.service';
import { CreateDatasetServiceType, ICreateDatasetService } from '../createDataset.service';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { DatasetsPermissionsType, IDatasetsPermissions } from '../datasets.permissions';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { DatasetStatus } from '../datasetStatus.model';
import { Datasets } from '../datasets.context';
import { Home } from '../../../routes/config/Home';
import { ImageUploadForm } from '../components/ImageUploadForm';
import { StickerError } from '../../../models/error.model';
import { UploadOption } from '../components/ImageUploadModal';
import autobind from 'autobind-decorator';
import { dinduNuffin } from '../../../helpers/function.helpers';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ datasetDraftId: string; workspaceId: string }> {
  createDatasetService: ICreateDatasetService;
  createDatasetFilesService: ICreateDatasetFilesService;
  currentWorkspaceStore: ICurrentWorkspaceStore;
  datasetsPermissions: IDatasetsPermissions;
}

interface IState extends ILoaderState {
  selectedUploadOption: UploadOption;
}

@(withRouter as any)
@injectProps({
  createDatasetService: CreateDatasetServiceType,
  createDatasetFilesService: CreateDatasetFilesServiceType,
  datasetsPermissions: DatasetsPermissionsType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
@observer
class CreateDatasetFilesContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  static contextType = Datasets;
  declare context: React.ContextType<typeof Datasets>;

  constructor(props: IInjectedProps) {
    super(props);

    this.state = {
      selectedUploadOption: UploadOption.None,
      isLoading: false,
    };
  }

  // tslint:disable-next-line:function-name
  UNSAFE_componentWillMount(): void {
    const workspaceId = this.props.currentWorkspaceStore.currentWorkspace?.id;
    if (!workspaceId) return;

    const datasetDraftId = this.props.createDatasetService.initNewDraft();
    const path = Home.Datasets.Create.withParams({ datasetDraftId, workspaceId });
    this.props.history.push(path);
  }

  @autobind
  async handleCreateDatasetAsync() {
    this.withLoaderAsync(async () => {
      this.context.service.markStale();
      const result = await this.props.createDatasetService.createDatasetFromDraftAsync();
      if (result instanceof StickerError) return;

      const workspaceId = this.props.currentWorkspaceStore.currentWorkspace!.id;
      const datasetDraftId = this.props.match.params.datasetDraftId;

      this.props.history.push(Home.Datasets.Details.Images.withParams({ workspaceId, datasetId: datasetDraftId }));
      this.context.service.loadUserDatasetAsync();
    }, 'upload');
  }

  @autobind
  onSelectDriveOption() {
    this.setState({ selectedUploadOption: UploadOption.Drive });
  }

  @autobind
  onSelectUrlOption() {
    this.setState({ selectedUploadOption: UploadOption.Url });
  }

  @autobind
  onCancel() {
    this.props.createDatasetFilesService.cleanImagesFromUrl();
    this.props.createDatasetFilesService.cleanImagesFromFile();
    this.setState({ selectedUploadOption: UploadOption.None });
  }

  render() {
    const { draft, isValid } = this.props.createDatasetService.datasetsDraftStore;
    if (!draft) return <div />;

    return (
      <ImageUploadForm
        datasetId={draft.id}
        datasetStatus={DatasetStatus.DRAFT}
        imagesRejectedFromFile={draft.imagesRejectedFromFile || []}
        imagesToUploadFromFile={draft.imagesToUploadFromFile || []}
        imagesToUploadFromUrl={draft.imagesToUploadFromUrl || []}
        isCreationEnabled={isValid && !draft.willExceedsUploadImageSizeLimit}
        isProcessingImages={this.props.createDatasetFilesService.isProcessingImages}
        onFormSubmit={this.handleCreateDatasetAsync}
        addImagesToDataset={this.props.createDatasetFilesService.addImagesToDraft}
        cleanImagesFromUrl={this.props.createDatasetFilesService.cleanImagesFromUrl}
        getUrlsFromFileAsync={this.props.createDatasetFilesService.getUrlsFromFileAsync}
        cleanImagesFromFile={this.props.createDatasetFilesService.cleanImagesFromFile}
        rejectUrlsFile={this.props.createDatasetFilesService.rejectUrlsFile}
        willExceedsUploadImageSizeLimit={draft.willExceedsUploadImageSizeLimit}
        imagePreValidationWasSuccessful={draft.imagePreValidationWasSuccessful}
        ownerPlan={draft.ownerPlan}
        toggleUploadConfirmationModal={dinduNuffin}
        isConfirmationModalOpen={false}
        isEncryptionEnabled={this.props.currentWorkspaceStore.currentWorkspace?.encryption.encrypted || false}
        onCancel={this.onCancel}
        onSelectDriveOption={this.onSelectDriveOption}
        onSelectUrlOption={this.onSelectUrlOption}
        selectedUploadOption={this.state.selectedUploadOption}
        showSubmitButtons
        loaderKey="upload"
        canUploadImages={this.props.datasetsPermissions.canCreateDataset()}
      />
    );
  }
}

export const CreateDatasetFilesContainer = as<React.ComponentClass>(CreateDatasetFilesContainerPure);
