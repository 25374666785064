import './S_TextButton.scss';

import { Button } from 'reactstrap';
import { ClipLoader } from 'react-spinners';
import React from 'react';
import { S_TextButtonProps } from './S_TextButtonProps';

export const S_TextButton: React.FC<S_TextButtonProps> = (props: S_TextButtonProps) => {
  return (
    <Button {...props} className={`btn s-btn ${props.className} ${props.isLoading && 'is-loading'}`} disabled={props.disabled || props.isLoading}>
      {props.text}
      {props.isLoading && (
        <span className={'s-button-spinner'}>
          <ClipLoader color="#fff" size={18} />
        </span>
      )}
    </Button>
  );
};
