import './AlertBar.scss';

import { Alert, AlertType } from '../models/Alert';

import { CountdownAlert } from './alerts/countdown/CountdownAlert';
import React from 'react';
import { TextAlert } from './alerts/text/TextAlert';

interface IProps {
  alerts: Alert[];
  onAlertOutdated(alert: Alert): void;
}

export const alertBarHeight = (alertsCount: number): number => (alertsCount !== 0 ? 15 + 15 + alertsCount * 20 : 0);

export const AlertBar: React.FC<IProps> = ({ alerts, onAlertOutdated }: IProps) => {
  const renderRestartCountdownAlert = (alert: Alert) => <CountdownAlert key={alert.id} alert={alert} onAlertOutdated={onAlertOutdated} />;
  const renderTextAlert = (alert: Alert) => <TextAlert key={alert.id} alert={alert} onAlertOutdated={onAlertOutdated} />;

  const renderAlert = (alert: Alert) => {
    switch (alert.type) {
      case AlertType.RESTART_COUNTDOWN:
        return renderRestartCountdownAlert(alert);
      case AlertType.TEXT:
        return renderTextAlert(alert);
      default:
        return null;
    }
  };

  return <div className="alerts-bar">{alerts.map(a => renderAlert(a))}</div>;
};
