import { IUserStore, UserStoreType } from '../../user/user.store';
import { as, injectProps } from '../../../helpers/react.helpers';

import { OpacityControl } from '../components/OpacityControl';
import React from 'react';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps {
  userStore: IUserStore;
}

interface IState {
  isOpen: boolean;
}

@injectProps({
  userStore: UserStoreType,
})
@observer
class OpacityContainerPure extends React.Component<IInjectedProps, IState> {
  state: IState = {
    isOpen: false,
  };

  @autobind
  handleOpacityLevelChange(opacity: number) {
    this.props.userStore.opacityLevel = opacity;
  }

  @autobind
  handleToggle() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  render() {
    return <OpacityControl onOpacityLevelChange={this.handleOpacityLevelChange} opacityLevel={this.props.userStore.opacityLevel} minOpacityLevel={0} maxOpacityLevel={100} />;
  }
}

export const OpacityContainer = as<React.ComponentClass>(OpacityContainerPure);
