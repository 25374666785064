import { injectable, inject } from 'inversify';
import { action } from 'mobx';
import { StickerError } from '../../models/error.model';
import { IAnswerModel, IQuestionModel, QuestionModel, QuestionType } from './question.model';
import { IImage, ISegmentation, IQuestionModelDto, AnnotationStatus, ISegmentationDto, IAnnotationDto } from './annotations.interface';
import { BaseAnnotationControl, IBaseAnnotationControl } from './baseAnnotationControl';
import { NotificationsServiceType, INotificationsService } from '../notifications/services/notifications.service';
import { AnnotationApiServiceType, IAnnotationApiService } from './annotationApi.service';
import { AnnotationServiceType, IAnnotationService } from './annotation.service';
import { AnnotationUiStoreType, IAnnotationUiStore } from './annotationUi.store';
import { ProjectHubServiceType, IProjectHubService } from '../../services/projectHub.service';
import { ToastNotification, NotificationLevel } from '../notifications/models/notification.model';
import { IImagesQueueService } from './imagesQueueServiceBase';

import uuid from 'uuid';
import { FreeDrawSegmentationServiceType, IFreeDrawSegmentationService } from './freeDrawSegmentation.service';
import { IUndoRedoHistory, UndoRedoHistory, UndoRedoHistoryType } from './undoRedoHistory.service';
import { LatLngBounds } from 'leaflet';
import { AnnotationTypeBlType, IAnnotationTypeBl } from './submodules/annotationTypes/annotationType.bl';
import { AnnotationReviewStoreType, IAnnotationReviewStoreSetter } from './submodules/review/annotationReview.store';
import { ReviewMode } from './submodules/review/models/reviewMode';
import { ReviewImagesQueueService } from './reviewImagesQueue.service';
import { getLatLngsForGeojson } from '../../helpers/geometry/polygon.helpers';
import { IAnnotationsStore, AnnotationsStoreType } from './annotations.store';
import { TimerServiceType, ITimerService } from '../../services/timer.service';
import { ReviewRejectionReasonBlType, IReviewRejectionReasonBl } from './submodules/reviewRejectReasones/reviewRejectionReason.bl';
import { EventBusType, EventListeningDisposer, IEventBus } from '../../services/eventBus.service';
import { ClarificationAddedEvent, ClarificationAddedEventType } from './submodules/clarifications/events/ClarificationAddedEvent';
import { BatchAnnotationApiServiceType, IBatchAnnotationApiService } from '../../../modules/editor/services/BatchAnnotationApi.service';
import { EditAndAcceptAnnotationRequest, EditAndRejectAnnotationRequest, IAcceptAnnotationRequest, IRejectAnnotationRequest } from '../../../modules/editor/models/Requests';

export const AnnotationReviewServiceType = Symbol('ANNOTATION_REVIEW_SERVICE');

export interface IAnnotationReviewService extends IBaseAnnotationControl {
  acceptAnnotationAsync(): Promise<void>;
  discardAnnotationsAsync(): Promise<void>;
  refreshRequested(): void;
  rejectAnnotationAsync(reason?: string): Promise<void>;
  reviewFinishedAsync(): Promise<void>;
  reviewStartedAsync(projectId: string, reviewImagesQueueService: ReviewImagesQueueService): Promise<void>;

  cancelCorrectAsync(): Promise<void>;
  correctAcceptAnnotationAsync(): Promise<void>;
  correctDiscardAnnotationAsync(): Promise<void>;
  correctRejectAnnotation(reason?: string): void;
  navigateToCorrect(): void;
}

@injectable()
export class AnnotationReviewService extends BaseAnnotationControl implements IAnnotationReviewService {
  private projectId: string = '';

  private get reviewImagesQueueService(): ReviewImagesQueueService {
    return this.imagesQueueService as ReviewImagesQueueService;
  }

  private eventListenersDisposer: EventListeningDisposer[] = [];

  constructor(
    @inject(ProjectHubServiceType) private readonly projectHubService: IProjectHubService,
    @inject(NotificationsServiceType) public readonly notificationsService: INotificationsService,
    @inject(AnnotationApiServiceType) public readonly annotationApiService: IAnnotationApiService,
    @inject(AnnotationServiceType) public readonly annotationService: IAnnotationService,
    @inject(AnnotationTypeBlType) public readonly annotationTypeBl: IAnnotationTypeBl,
    @inject(AnnotationUiStoreType) public readonly uiStore: IAnnotationUiStore,
    @inject(FreeDrawSegmentationServiceType) private readonly freeDrawSegmentationService: IFreeDrawSegmentationService,
    @inject(UndoRedoHistoryType) private readonly undoRedoService: IUndoRedoHistory,
    @inject(AnnotationReviewStoreType) private readonly reviewStore: IAnnotationReviewStoreSetter,
    @inject(UndoRedoHistoryType) private readonly historyService: UndoRedoHistory,
    @inject(AnnotationsStoreType) annotationsStore: IAnnotationsStore,
    @inject(TimerServiceType) public readonly timer: ITimerService,
    @inject(ReviewRejectionReasonBlType) private readonly reviewRejectionReasonBl: IReviewRejectionReasonBl,
    @inject(EventBusType) public readonly eventBus: IEventBus,
    @inject(BatchAnnotationApiServiceType) private readonly batchAnnotationApiService: IBatchAnnotationApiService,
  ) {
    super(uiStore, notificationsService, annotationsStore);
  }
  handleImageDisplayed = (image: IImage) => this.loadAnnotations(image);

  private addEventListeners() {
    this.eventListenersDisposer.push(this.eventBus.addListener<ClarificationAddedEvent>(this.clarificationAddedListenerAsync, ClarificationAddedEventType));
  }

  private clearEventListeners() {
    this.eventListenersDisposer.forEach(d => d());
    this.eventListenersDisposer = [];
  }

  async reviewStartedAsync(projectId: string, reviewImagesQueueService: ReviewImagesQueueService): Promise<void> {
    reviewImagesQueueService.setupAsync(projectId);
    await this.setupAsync(projectId, reviewImagesQueueService);
  }

  async reviewFinishedAsync(): Promise<void> {
    const result = await this.annotationApiService.unlockUserImagesForProjectAsync(this.projectId);
    if (result instanceof StickerError) throw result;
    this.dispose();
  }

  refreshRequested(): void {
    this.reviewImagesQueueService.startAsync(true);
  }

  async cancelCorrectAsync(): Promise<void> {
    if (!(await this.freeDrawSegmentationService.clearAsync())) return;
    if (this.historyService.canUndo && this.annotationsStore.image) {
      this.loadAnnotations(this.annotationsStore.image);
    }
    this.switchModeToReview();
  }

  async correctAcceptAnnotationAsync(): Promise<void> {
    await this.sendCorrectedAsync(true);
  }

  @action
  async setupAsync(projectId: string, imagesQueueService: IImagesQueueService): Promise<void> {
    this.projectId = projectId;
    this.annotationsStore.projectId = projectId;
    this.reviewStore.setCurrentMode(ReviewMode.Review);
    this.imagesQueueService = imagesQueueService;
    this.projectHubService.initializeAsync();
    this.timer.startTimer();
    this.initReactions();
    this.imagesQueueService.startAsync(true);

    await Promise.all([this.annotationTypeBl.reviewStarted(this.projectId), this.requestQuestionsAsync(), this.requestRejectionReasonsAsync()]);

    await this.displayNextImageAsync();

    this.addEventListeners();
  }

  @action.bound
  private loadAnnotations(image: IImage): void {
    const annotationInfoResult = image.annotations;

    if (!annotationInfoResult) return;

    const annotations = annotationInfoResult.annotations;
    if (annotations === undefined) throw new StickerError('No annotations found', undefined);

    this.annotationsStore.id = annotationInfoResult.id;
    const segmentations: ISegmentation[] = annotations.segmentations.map((s: ISegmentationDto) => {
      const feature = {
        ...s.feature,
        id: uuid.v4(),
        color: this.annotationService.getAnnotationTypeColor(s.feature.properties!.annotationTypeId),
        featureType: s.feature.properties!.featureType,
      };
      const latlngs = getLatLngsForGeojson(feature);

      return {
        feature,
        latlngs,
        id: s.id,
        questions: this.mapQuestions(s.feature.properties!.annotationTypeId, s.questions),
        bbox: new LatLngBounds(latlngs),
        priority: s.priority,
      } as ISegmentation;
    });

    this.annotationsStore.setSegmentations(segmentations.filter(s => s.feature.color !== ''));
    this.annotationsStore.questions = this.mapQuestions(undefined, annotationInfoResult.annotations.questions);
    this.annotationService.setImageQuestionsAsCurrent();
  }

  private mapQuestions(annotationTypeId: string | undefined, questionsDtos: IQuestionModelDto[]): IQuestionModel[] {
    const currentQuestions = annotationTypeId ? this.annotationsStore.questions.filter(q => q.scopes.includes(annotationTypeId)) : this.annotationsStore.questions;

    const models: IQuestionModel[] = [];
    for (const currentQuestion of currentQuestions) {
      const existingAnswer = questionsDtos.find(x => x.id === currentQuestion.id);

      const questionModel = new QuestionModel(
        currentQuestion.projectId,
        currentQuestion.id,
        currentQuestion.type as QuestionType,
        currentQuestion.isRequired,
        currentQuestion.text,
        currentQuestion.answers.map((x: IAnswerModel) => {
          return { id: x.id, selected: x.selected, text: x.text };
        }),
        currentQuestion.scopes,
        currentQuestion.answer,
      );

      questionModel.answers.forEach((x: IAnswerModel) => {
        const existing = existingAnswer?.answers.find(y => y.id === x.id);
        x.selected = existing?.selected || false;
      });
      questionModel.answer = existingAnswer?.answer;

      models.push(questionModel);
    }

    return models;
  }

  @action
  async requestQuestionsAsync() {
    const result = await this.annotationApiService.requestQuestionsAsync(this.projectId);
    if (result instanceof Error) throw result;
    this.annotationsStore.questions = [...result];
    this.annotationService.setImageQuestionsAsCurrent();
  }

  async requestRejectionReasonsAsync() {
    await this.reviewRejectionReasonBl.getReasons(this.projectId);
  }

  @action
  async refreshQuestionsIfNeededAsync() {
    if (this.projectHubService.didQuestionsChangedFor(this.annotationsStore.projectId)) {
      const result = this.projectHubService.popNewQuestionsFor(this.annotationsStore.projectId);
      this.annotationsStore.questions = [...result];
      this.annotationService.setImageQuestionsAsCurrent();
    }
  }

  async rejectAnnotationAsync(reason: string = ''): Promise<void> {
    const request: IRejectAnnotationRequest = {
      reason,
      id: this.annotationsStore.id!,
      duration: this.timer.duration,
    };

    const result = await this.batchAnnotationApiService.rejectAsync(request);
    await this.reloadBasedOnResultAsync(result);

    if (reason) {
      this.reviewRejectionReasonBl.handleRejection(reason);
    }
  }

  async acceptAnnotationAsync(): Promise<void> {
    const request: IAcceptAnnotationRequest = {
      id: this.annotationsStore.id!,
      duration: this.timer.duration,
    };

    const result = await this.batchAnnotationApiService.acceptAsync(request);
    await this.reloadBasedOnResultAsync(result);
  }

  async discardAnnotationsAsync(): Promise<void> {
    const request = { id: this.annotationsStore.id!, duration: this.timer.duration };
    const result = await this.batchAnnotationApiService.discardDuringReviewAsync(request);
    await this.reloadBasedOnResultAsync(result);
  }

  async correctDiscardAnnotationAsync() {
    const request = { id: this.annotationsStore.id!, duration: this.timer.duration };
    const result = await this.batchAnnotationApiService.discardDuringReviewEditAsync(request);
    await this.reloadBasedOnResultAsync(result);
    this.switchModeToReview();
  }

  private async reloadBasedOnResultAsync(result: void | StickerError): Promise<void> {
    if (result instanceof StickerError) {
      this.handleStickerErrors(result);
      if (result.apiErrorResponse!.errorCodes.includes('ANNOTATION_NOT_FOUND')) {
        this.annotationsStore.image = undefined;
        this.imagesQueueService.areAnyImagesToLoad = false;
        return;
      }
    }
    await this.displayNextImageAsync();
  }

  async displayNextImageAsync() {
    await this.refreshQuestionsIfNeededAsync();
    await this.annotationTypeBl.handleDisplayNextImageInReview();
    this.freeDrawSegmentationService.clear();
    this.annotationService.clearAnnotations();
    this.annotationService.clearAnswers();
    this.updateReviewProgress();
    await this.displayImageFromQueueAsync();
    this.timer.startTimer();
  }

  @action
  private async sendCorrectedAsync(accept: boolean, rejectionReason?: string): Promise<void> {
    if (!(await this.freeDrawSegmentationService.clearAsync())) return;
    if (!this.annotationsStore.id || !this.annotationService.validateAnnotations()) {
      this.annotationTypeBl.handleSubmitReview();
      return;
    }

    this.annotationService.fixRectangles();

    const correctedAnnotation = this.mapAnnotationDto();

    // TODO: Jeden DTO używany jako input i output dla API, pozostałość po zapisie w JSONIE, do poprawy
    this.annotationsStore.image!.annotations = {
      ...this.annotationsStore.image!.annotations!,
      annotations: correctedAnnotation,
      status: accept ? AnnotationStatus.ACCEPTED : AnnotationStatus.REJECTED,
    };

    let result = undefined;

    if (accept) {
      const request = new EditAndAcceptAnnotationRequest(correctedAnnotation, this.timer.duration, this.annotationsStore.id);
      result = await this.batchAnnotationApiService.editAndAcceptAsync(request);
    } else {
      const request = new EditAndRejectAnnotationRequest(rejectionReason, correctedAnnotation, this.timer.duration, this.annotationsStore.id);
      result = await this.batchAnnotationApiService.editAndRejectAsync(request);
    }

    if (result instanceof StickerError) this.handleStickerErrors(result);

    this.displayNextImageAsync();
    this.switchModeToReview();
  }

  handleStickerErrors(e: StickerError) {
    if (e.isBadRequestWithCode(['ANNOTATION_NOT_FOUND'])) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.WARNING, 'notifications:operation_was_unsuccessful_dataset_changed'));
    } else if (e.isBadRequestWithCode(['IMAGE_ALREADY_LOCKED'])) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, '', 'image_is_locked_by_another_user'));
    } else {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, '', 'something_went_wrong'));
    }
  }

  @action
  async correctRejectAnnotation(reason?: string) {
    if (this.reviewStore.currentMode === ReviewMode.Correct) {
      await this.sendCorrectedAsync(false, reason);
    } else {
      await this.rejectAnnotationAsync(reason);
    }
    this.switchModeToReview();
  }

  dispose() {
    super.dispose();
    this.annotationService.clear();
    this.imagesQueueService.clear();
    this.imagesQueueService.dispose();
    this.annotationTypeBl.reviewFinished();
    this.clearEventListeners();
  }

  @action
  async updateReviewProgress() {
    const result = await this.annotationApiService.getReviewProgressAsync(this.projectId);
    if (result instanceof StickerError) return;
    this.annotationsStore.reviewProgress = result;
  }

  navigateToCorrect(): void {
    this.reviewStore.setCurrentMode(ReviewMode.Correct);
    this.annotationService.deselectSegmentation();
  }

  private switchModeToReview(): void {
    this.reviewStore.setCurrentMode(ReviewMode.Review);
    this.annotationService.deselectSegmentation();
    this.undoRedoService.clearDrawingHistory();
    this.undoRedoService.clearHistory();
  }

  @action.bound
  private async clarificationAddedListenerAsync(_: ClarificationAddedEvent) {
    await this.displayNextImageAsync();
    this.switchModeToReview();
  }

  private mapAnnotationDto(id: string | undefined = undefined): IAnnotationDto {
    return {
      id: id || this.annotationsStore.id!,
      questions: this.annotationsStore.questions,
      segmentations: this.annotationsStore.segmentations.map(s => ({
        id: s.id || uuid.v4(),
        questions: s.questions.filter(q => q.validate()),
        feature: {
          type: s.feature.type,
          properties: s.feature.properties,
          geometry: s.feature.geometry,
        },
        priority: s.priority,
      })),
    };
  }
}
