import * as React from 'react';

import { BillingServiceType, IBillingService } from '../billing.service';
import { ConfigurationType, IConfiguration } from '../../../../configuration';
import { IUserStore, UserStoreType } from '../../user/user.store';
import { as, injectProps } from '../../../helpers/react.helpers';

import IncreaseLimitContainer from './IncreaseLimit.container';
import { Limit } from '../components/Limit';
import { observer } from 'mobx-react';

interface IProps {
  billingService: IBillingService;
  userStore: IUserStore;
  config: IConfiguration;
}

interface IState {
  showModal: boolean;
}

@injectProps({
  billingService: BillingServiceType,
  userStore: UserStoreType,
  config: ConfigurationType,
})
@observer
class LimitContainerPure extends React.Component<IProps, IState> {
  state = {
    showModal: false,
  };

  handleCartClick = () => {
    this.setState({ showModal: true });
  };

  handleModalClosed = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <>
        {!this.props.config.appConfig.runLocally && this.props.userStore.userInfo.showCart &&
          (
            <>
              <Limit {...this.props.billingService.billing} onCartClick={this.handleCartClick} />
              <IncreaseLimitContainer showModal={this.state.showModal} onClosed={this.handleModalClosed} />
            </>
          )
        }
      </>
    );
  }
}

export const LimitContainer = as<React.ComponentClass>(LimitContainerPure);
