import { ClipLoader } from 'react-spinners';
import { ITranslatable } from '../../../../helpers/translations.helpers';
import React from 'react';
import { ResendStatus } from '../../containers/ConfirmEmailContainer';
import { as } from '../../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  resendStatus: ResendStatus;
  onResendActivationEmailClick(): void;
}

type Props = ITranslatable & IProps;

const ResendEmail = (props: Props) => {
  const { t } = props;

  return (
    <div className="text-center">
      {props.resendStatus === 'NotSent' && (
        <>{t('didnt_receive')} <a href="#" onClick={props.onResendActivationEmailClick}>{t('resend')}</a></>)}
      {props.resendStatus === 'Sending' && (<>{t('resending_email')} <ClipLoader color="#4C516D" size={18} /></>)}
      {props.resendStatus === 'Sent' && (<>{t('email_sent')}</>)}
    </div>
  );
};

export default as<React.SFC<IProps>>(withNamespaces('auth')(ResendEmail));
