import { ContainerModule, interfaces } from 'inversify';
import { ExportProjectStatsBl, ExportProjectStatsBlType, IExportProjectStatsBl } from './export/ExportProjectStats.bl';
import { ExportProjectStatsService, ExportProjectStatsServiceType, IExportProjectStatsService } from './export/ExportProjectStats.service';
import { GetProjectStatsService, GetProjectStatsServiceType, IGetProjectStatsService } from './services/GetProjectStats.service';
import { IProjectStatsBl, ProjectStatsBl, ProjectStatsBlType } from './ProjectStats.bl';
import { IProjectStatsFiltersBl, ProjectStatsFiltersBl, ProjectStatsFiltersBlType } from './filters/ProjectStatsFilters.bl';
import {
  IProjectStatsFiltersStore,
  IProjectStatsFiltersStoreSetter,
  ProjectStatsFiltersStore,
  ProjectStatsFiltersStoreSetterType,
  ProjectStatsFiltersStoreType,
} from './filters/ProjectStatsFilters.store';
import { IProjectStatsStore, IProjectStatsStoreSetter, ProjectStatsStore, ProjectStatsStoreSetterType, ProjectStatsStoreType } from './ProjectStats.store';

export const projectStatisticsModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IProjectStatsStore>(ProjectStatsStoreType).to(ProjectStatsStore).inSingletonScope();
  bind<IProjectStatsStoreSetter>(ProjectStatsStoreSetterType).toService(ProjectStatsStoreType);
  bind<IGetProjectStatsService>(GetProjectStatsServiceType).to(GetProjectStatsService).inRequestScope();
  bind<IProjectStatsBl>(ProjectStatsBlType).to(ProjectStatsBl).inRequestScope();

  bind<IProjectStatsFiltersBl>(ProjectStatsFiltersBlType).to(ProjectStatsFiltersBl).inRequestScope();
  bind<IProjectStatsFiltersStore>(ProjectStatsFiltersStoreType).to(ProjectStatsFiltersStore).inSingletonScope();
  bind<IProjectStatsFiltersStoreSetter>(ProjectStatsFiltersStoreSetterType).toService(ProjectStatsFiltersStoreType);

  bind<IExportProjectStatsService>(ExportProjectStatsServiceType).to(ExportProjectStatsService).inRequestScope();
  bind<IExportProjectStatsBl>(ExportProjectStatsBlType).to(ExportProjectStatsBl).inRequestScope();
});
