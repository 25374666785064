import { keyBy, toPairs } from 'lodash/fp';

import { AttributePopup } from './AttributePopup';
import { ISegmentation } from '../../annotations.interface';
import L from 'leaflet';
import autobind from 'autobind-decorator';

@autobind
export class AttributesPopupDrawer {
  private _map: L.Map;
  private _group: L.FeatureGroup = L.featureGroup();
  private _selectedSegmentation?: ISegmentation;
  private _segmentations: Map<string, ISegmentation> = new Map<string, ISegmentation>();
  private _areFramesDisplayed: boolean = false;
  private _popups: Map<string, AttributePopup> = new Map<string, AttributePopup>();

  constructor(map: L.Map) {
    this._map = map;
    this._group = L.featureGroup();
    this._group.addTo(this._map);
  }

  public refresh(selected: ISegmentation | undefined, redraw: boolean, segmentations: ISegmentation[]) {
    this._selectedSegmentation = selected;
    this._areFramesDisplayed = redraw;
    this._segmentations = new Map(toPairs(keyBy(s => s.feature.id, segmentations)));

    if (!this._areFramesDisplayed) {
      this.clear();
      return;
    }

    const lowOpacity = this._selectedSegmentation !== undefined;

    this._popups.forEach((attributePopup, key) => {
      const segmentation = this._segmentations.get(key);
      if (this._selectedSegmentation?.feature.id === key || !segmentation || !segmentation.bbox.equals(attributePopup._bbox)) {
        this._group.removeLayer(attributePopup);
        attributePopup.remove();
        this._popups.delete(key);
      } else {
        attributePopup.updateData(lowOpacity, false);
      }
    });

    this._segmentations.forEach((s: ISegmentation) => {
      if (s.questions.length && s.feature.id !== this._selectedSegmentation?.feature.id && !this._popups.has(s.feature.id)) {
        this._popups.set(s.feature.id, new AttributePopup(s, this._map, this._group, this._selectedSegmentation !== undefined));
      }
    });
  }

  public hover(key: string) {
    const lowOpacity = this._selectedSegmentation !== undefined;
    this._popups.get(key)?.updateData(lowOpacity, true);
  }

  public hout(key: string) {
    const lowOpacity = this._selectedSegmentation !== undefined;
    this._popups.get(key)?.updateData(lowOpacity, false);
  }

  public clear() {
    this._group.clearLayers();
    this._popups.clear();
  }
}
