import * as React from 'react';
import { as } from '../../helpers/react.helpers';
import { resultMetricsWithTranslation } from './contents/ResultMetrics';
import { IChartPoint, ITrainingMatrix, ITrainingMetrics, ITrainingResult } from '../../models/metrics.model';

interface IProps {
  ns: 'models' | 'evaluations';
  curve: IChartPoint[];
  matrix: ITrainingMatrix[];
  metrics: ITrainingMetrics;
  results: ITrainingResult[];
}

const AnomalyDetection8Pure = (props: IProps) => {
  const ResultMetrics = resultMetricsWithTranslation(props.ns);

  const { curve, matrix, metrics, results } = props;

  return (
    <>
      <ResultMetrics curve={curve} matrix={matrix} metrics={metrics} results={results} />
    </>
  );
};

export const AnomalyDetection8 = as<React.FC<IProps>>(AnomalyDetection8Pure);
