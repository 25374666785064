import { inject, injectable } from 'inversify';
import { action } from 'mobx';
import { ForbiddenError, InputStatus, StickerError } from '../../models/error.model';
import { Home } from '../../routes/config/Home';
import { ApiServiceType, IPagedResult } from '../../services/api.service';
import { IApiService } from '../../services/api.service.base';
import {
  DatasetsDetailsStoreType,
  IDatasetDetails,
  IDatasetDetailsImage,
  IDatasetDetailsProjects,
  IDatasetPreviewImage as IDatasetPreviewImageInfo,
  IDatasetsDetailsStore,
  IPreviewPaging,
  IPreviewPagingItem,
  DefaultPaging,
  IImageUsageInfo,
} from './datasetsDetails.store';
import { IRouterStore, RouterStoreType } from '../../stores/router.store';
import { AuthStoreType, IAuthStore } from '../auth/auth.store';
import { NotificationLevel, ToastNotification } from '../notifications/models/notification.model';
import { INotificationsService, NotificationsServiceType } from '../notifications/services/notifications.service';
import { ConfigurationType, IConfiguration } from '../../../configuration';
import { LOW_QUALITY_PRELOAD_MIN_IMAGE_SIZE } from '../annotation/annotations.interface';
import { CacheManager, CacheManagerType, ICacheManager } from '../../services/cacheManager';
import { ImagePreviewCache } from './cache/imagePreview.cache';
import * as path from 'path';
import { ICurrentWorkspaceStore, CurrentWorkspaceStoreType } from '../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';

export const DatasetDetailsServiceType = Symbol('DATASET_DETAILS_SERVICE');

export const ReservedFileNames = [
  'CON',
  'PRN',
  'AUX',
  'NUL',
  'COM1',
  'COM2',
  'COM3',
  'COM4',
  'COM5',
  'COM6',
  'COM7',
  'COM8',
  'COM9',
  'COM0',
  'LPT1',
  'LPT2',
  'LPT3',
  'LPT4',
  'LPT5',
  'LPT6',
  'LPT7',
  'LPT8',
  'LPT9',
  'LPT0',
  'CLOCK$',
];

export interface IDatasetDetailsService {
  bulkDeleteImages(datasetId: string, imagesToBulkDelete: string[]): Promise<void>;
  getDatasetDetailsAsync(datasetId: string): Promise<void>;
  getDatasetUserSettingsAsync(datasetId: string): Promise<void>;
  getDatasetImagesAsync(datasetId: string, pageNumber: number, pageSize: number, orderBy: string | undefined, orderType: string | undefined): Promise<void>;
  refreshImages(datasetId: string, pageNumber: number, pageSize: number, orderBy: string | undefined, orderType: string | undefined): Promise<void>;
  downloadDatasetUrl(datasetId: string): string;
  downloadImageAsync(imageId: string): Promise<void | StickerError>;
  deleteImageAsync(imageId: string): Promise<void | StickerError>;
  showImageAsync(datasetId: string, imageId: string, size: number): Promise<void>;
  getDatasetImageInfoAsync(datasetId: string, imageId: string): Promise<IDatasetPreviewImageInfo | StickerError>;
  getDatasetProjectsAsync(datasetId: string): Promise<void>;
  updateDatasetDetailsAsync(detailsPayload: IUpdateDatasetDetailsPayload): Promise<void>;
  validateDatasetNameAsync(name: string, datasetId: string): Promise<string[]>;
  getPreviewPaging(datasetId: string, lastUpdate?: string): Promise<IPreviewPaging | StickerError>;
  downloadSelectedImagesAsync(datasetId: string, imagesIds: string[]): Promise<void | StickerError>;
  getImageUsageInfo(datasetId: string, imageId: string): Promise<IImageUsageInfo | StickerError>;
  publishDatasetAsync(datasetId: string): Promise<void | StickerError>;
  deleteDatasetAsync(datasetId: string): Promise<void | StickerError>;
  setDefaultImagesPaging(): void;
  changeDatasetProjectsOrder(datasetId: string, orderBy: string | undefined, orderType: string | undefined): Promise<void>;
  changeDatasetProjectsPagination(datasetId: string, pageNumber: number, pageSize: number): Promise<void>;
  getDatasetDetailsImageViewAsync(datasetId: string): Promise<boolean>;
  renameImageAsync(imageId: string, name: string, datasetId: string): Promise<void | StickerError>;
  store: IDatasetsDetailsStore;
  checkImageNameUniquenessAsync(imageId: string, datasetId: string, name: string): Promise<boolean | StickerError>;
  validateImageNameAsync(imageName: string, imageId: string, datasetId: string): Promise<InputStatus>;
  isValidFileName(imageName: string): boolean;
}

interface IUpdateDatasetDetailsPayload {
  datasetId: string;
  name: string;
  description: string;
  author: string;
  termsOfUse: string;
}

@injectable()
export class DatasetDetailsService implements IDatasetDetailsService {
  constructor(
    @inject(ApiServiceType) private readonly apiService: IApiService,
    @inject(NotificationsServiceType) private readonly notificationsService: INotificationsService,
    @inject(DatasetsDetailsStoreType) public readonly store: IDatasetsDetailsStore,
    @inject(RouterStoreType) public readonly routeService: IRouterStore,
    @inject(AuthStoreType) public readonly authStore: IAuthStore,
    @inject(ConfigurationType) public readonly config: IConfiguration,
    @inject(CacheManagerType) public readonly cacheManager: ICacheManager,
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore,
  ) {
    store.activePreloads = [];
  }
  @action
  async getDatasetDetailsImageViewAsync(datasetId: string): Promise<boolean> {
    const url = `/datasets/userSettings?DatasetId=${datasetId}`;
    const result = await this.apiService.getAsync<{ orderBy: string; orderType: string }>(url);

    if (result instanceof StickerError) return false;

    const paging = {
      ...this.store.detailsImagesPaging,
      orderBy: result.orderBy ? result.orderBy : DefaultPaging.orderBy,
      orderType: result.orderType ? result.orderType : DefaultPaging.orderType,
    };

    this.store.setImagesPaging(paging);

    await this.getDatasetImagesAsync(
      datasetId,
      this.store.detailsImagesPaging.pageNumber,
      this.store.detailsImagesPaging.pageSize,
      this.store.detailsImagesPaging.orderBy,
      this.store.detailsImagesPaging.orderType,
    );

    return true;
  }

  @action
  async publishDatasetAsync(datasetId: string): Promise<void | StickerError> {
    const url = '/datasets/publish';
    const result = await this.apiService.postAsync(url, undefined, { params: { datasetId } });
    if (result instanceof StickerError) return result;
    await this.getDatasetDetailsAsync(datasetId);
  }

  @action
  async deleteDatasetAsync(datasetId: string): Promise<void | StickerError> {
    const url = '/datasets/remove';
    const result = await this.apiService.postAsync(url, undefined, { params: { datasetId } });
    if (result instanceof StickerError) return result;
  }

  @action
  async downloadSelectedImagesAsync(datasetId: string, imagesIds: string[]): Promise<void | StickerError> {
    const result = await this.apiService.postAsync<{ datasetId: string; imagesIds: string[] }, string>('/datasets/generatePartialDatasetDownloadId', { datasetId, imagesIds });

    if (!(result instanceof StickerError)) window.open(this.downloadPartialDatasetUrl(result), '_self');
  }

  @action
  async bulkDeleteImages(datasetId: string, imagesToBulkDelete: string[]): Promise<void> {
    const result = await this.apiService.postAsync<{ datasetId: string; imagesToBulkDelete: string[] }>('/Images/BulkDeleteImage', { datasetId, imagesToBulkDelete });

    this.store.selectedImages.clear();

    if (result instanceof StickerError) {
      if (!(result instanceof ForbiddenError)) {
        this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'datasets:get_dataset_details_failed'));
      }
      return;
    }
    const paging = this.store.detailsImagesPaging;

    await this.getDatasetImagesAsync(datasetId, paging.pageNumber, paging.pageSize, paging.orderBy, paging.orderType);
    await this.getDatasetDetailsAsync(datasetId);
  }

  async getDatasetDetailsAsync(datasetId: string) {
    const url = `/datasets/details?DatasetId=${datasetId}`;
    const result = await this.apiService.getAsync<IDatasetDetails>(url);

    if (result instanceof StickerError) {
      if (!(result instanceof ForbiddenError)) {
        this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'datasets:get_dataset_details_failed'));
        this.routeService.push(Home.Datasets.List.withParams({ workspaceId: this.currentWorkspaceStore.currentWorkspace!.id }));
      }
      return;
    }
    if (result.workspaceId !== this.currentWorkspaceStore.currentWorkspace?.id) {
      this.routeService.replace(Home.Datasets.List.withParams({ workspaceId: this.currentWorkspaceStore.currentWorkspace!.id }));
      return;
    }

    this.store.setDatasetDetails(result);
  }

  @action
  async getDatasetUserSettingsAsync(datasetId: string): Promise<void> {
    const url = `/datasets/userSettings?DatasetId=${datasetId}`;
    const result = await this.apiService.getAsync<{ orderBy: string; orderType: string }>(url);

    if (result instanceof StickerError) return;

    const paging = {
      ...DefaultPaging,
      orderBy: result.orderBy ? result.orderBy : DefaultPaging.orderBy,
      orderType: result.orderType ? result.orderType : DefaultPaging.orderType,
    };

    this.store.setImagesPaging(paging);
  }

  @action
  async getDatasetImagesAsync(datasetId: string, pageNumber: number, pageSize: number, orderBy: string = '', orderType: string = '') {
    let order = orderBy;
    let type = orderType;

    let url = `/datasets/images?DatasetId=${datasetId}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${order}&orderType=${type}`;
    let result = await this.apiService.getAsync<IPagedResult<IDatasetDetailsImage>>(url);

    if (result instanceof StickerError) {
      order = DefaultPaging.orderBy;
      type = DefaultPaging.orderType;

      url = `/datasets/images?DatasetId=${datasetId}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${order}&orderType=${type}`;
      result = await this.apiService.getAsync<IPagedResult<IDatasetDetailsImage>>(url);
      if (result instanceof StickerError) {
        this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'datasets:get_dataset_images_failed'));
        this.routeService.push(Home.Datasets.List.withParams({ workspaceId: this.currentWorkspaceStore.currentWorkspace!.id }));
        return;
      }
    }

    if (result.pagesCount < result.pageNumber) {
      await this.getDatasetImagesAsync(datasetId, result.pagesCount, pageSize, order, type);
    } else {
      this.store.setImages(
        result.data,
        {
          pageSize,
          orderBy: order,
          orderType: type,
          pageNumber: result.pageNumber,
          pagesCount: result.pagesCount,
          totalCount: result.totalCount,
        },
        result.totalCount,
      );
    }
  }

  @action
  async refreshImages(datasetId: string, pageNumber: number, pageSize: number, orderBy: string, orderType: string) {
    const url = `/datasets/images?DatasetId=${datasetId}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&orderType=${orderType}`;
    const result = await this.apiService.getAsync<IPagedResult<IDatasetDetailsImage>>(url);

    if (result instanceof StickerError) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'datasets:get_dataset_images_failed'));

      this.routeService.push(Home.Datasets.List.withParams({ workspaceId: this.currentWorkspaceStore.currentWorkspace!.id }));
      return;
    }

    if (result.pageNumber !== this.store.detailsImagesPaging.pageNumber) return;

    if (result.pagesCount < result.pageNumber) {
      await this.getDatasetImagesAsync(datasetId, result.pagesCount, pageSize, orderBy, orderType);
    } else {
      this.store.setImages(
        result.data,
        {
          pageSize,
          orderBy,
          orderType,
          pageNumber: result.pageNumber,
          pagesCount: result.pagesCount,
          totalCount: result.totalCount,
        },
        result.totalCount,
      );
    }
  }

  @action
  downloadDatasetUrl(datasetId: string) {
    return this.apiService.getUrl(`/datasets/download?DatasetId=${datasetId}&access_token=${this.authStore.token}`);
  }

  @action
  downloadPartialDatasetUrl(partialDatasetDownloadId: string) {
    return this.apiService.getUrl(`/datasets/downloadPartial?partialDatasetDownloadId=${partialDatasetDownloadId}&access_token=${this.authStore.token}`);
  }

  @action
  async downloadImageAsync(imageId: string) {
    return this.apiService.getFileAsync(`/images/DownloadImage/${imageId}`);
  }

  @action
  async getPreviewPaging(datasetId: string, lastUpdate?: string): Promise<IPreviewPaging | StickerError> {
    return this.apiService.getAsync<IPreviewPaging>(`/Datasets/PreviewPaging?datasetId=${datasetId}${lastUpdate ? `&lastUpdate=${lastUpdate}` : ''}`);
  }

  @action
  async showImageAsync(datasetId: string, imageId: string, size: number) {
    await Promise.all([this.loadLowQualityImage(datasetId, imageId, size), this.loadImage(datasetId, imageId)]);
  }

  @action
  async getDatasetImageInfoAsync(datasetId: string, imageId: string) {
    const previewImageInfo = await this.fetchImageInfoAsync(datasetId, imageId);

    if (previewImageInfo instanceof StickerError) return previewImageInfo;

    this.store.imagePreviewInfo = previewImageInfo;

    return previewImageInfo;
  }

  @action
  async getImageUsageInfo(datasetId: string, imageId: string) {
    const url = `/datasets/${datasetId}/imageUsageInfo/${imageId}`;
    return await this.apiService.getAsync<IImageUsageInfo>(url);
  }

  @action
  async fetchImageInfoAsync(datasetId: string, imageId: string) {
    const url = `/datasets/${datasetId}/imageInfo/${imageId}`;

    const cache = new ImagePreviewCache(this.currentWorkspaceStore.currentWorkspace!.id, datasetId, imageId);
    const result = await cache.getImageInfoAsync();

    if (result) {
      return result;
    }

    const previewImage = await this.apiService.getAsync<IDatasetPreviewImageInfo>(url);
    if (previewImage instanceof StickerError) return previewImage;

    cache.setImageInfoAsync(previewImage);
    return previewImage;
  }

  async loadLowQualityImage(datasetId: string, imageId: string, size: number) {
    if (this.store.details.workspaceId !== this.currentWorkspaceStore.currentWorkspace?.id) {
      this.routeService.replace(Home.Datasets.List.withParams({ workspaceId: this.currentWorkspaceStore.currentWorkspace!.id }));
      return;
    }
    if (size >= LOW_QUALITY_PRELOAD_MIN_IMAGE_SIZE) {
      const imageFile = await this.apiService.getImageAsync(this.apiService.getUrl(`/Images/GetImageThumbnail/${imageId}`));
      if (this.store.imagePreviewInfo.id === imageId && !(imageFile instanceof StickerError)) {
        this.store.lowQualityImagePreviewUrl = URL.createObjectURL(imageFile.blob);
      }
    }
    const currentItem = this.store.previewPaging.items.find(x => x.imageId === imageId);
    this.preLoadNextLowQualityImages(datasetId, this.store.previewPaging, currentItem!.index);
  }

  async loadImage(datasetId: string, imageId: string) {
    let imageFile = null;

    if (this.store.details.workspaceId !== this.currentWorkspaceStore.currentWorkspace?.id) {
      this.routeService.replace(Home.Datasets.List.withParams({ workspaceId: this.currentWorkspaceStore.currentWorkspace!.id }));
      return;
    }

    if (!this.store.activePreloads.some(x => x === imageId)) {
      imageFile = await this.apiService.getImageAsync(this.apiService.getUrl(`/datasets/${datasetId}/image/${imageId}`));
    }

    if (this.store.imagePreviewInfo.id === imageId && imageFile !== null && !(imageFile instanceof StickerError)) {
      this.store.imagePreviewUrl = URL.createObjectURL(imageFile.blob);
      this.store.imageAttributes = imageFile.attributes;
    }
    const currentItem = this.store.previewPaging.items.find(x => x.imageId === imageId);
    this.preLoadNextImages(datasetId, this.store.previewPaging, currentItem!.index);
  }

  async preLoadNextImages(datasetId: string, paging: IPreviewPaging, currentIndex: number) {
    const cacheAvailable = await CacheManager.checkCacheAvailabilityAsync();
    if (!cacheAvailable) return;

    const range = this.config.appConfig.imagePreloadRange;

    const imagesToPreLoadIds = paging.items
      .filter(x => x.index > currentIndex - range && x.index < currentIndex + range && x.index !== currentIndex)
      .sort((n1, n2) => Math.abs(n1.index - currentIndex) - Math.abs(n2.index - currentIndex));

    await imagesToPreLoadIds.forEach(async (item: IPreviewPagingItem) => {
      const url = this.apiService.getUrl(`/datasets/${datasetId}/image/${item.imageId}`);

      if (this.store.activePreloads.some(x => x === item.imageId)) return;
      if (await this.cacheManager.checkIfInCache(url)) return;

      this.store.activePreloads.push(item.imageId);
      const imageFile = await this.apiService.getImageAsync(url);
      if (this.store.imagePreviewInfo.id === item.imageId && !(imageFile instanceof StickerError)) {
        this.store.imagePreviewUrl = URL.createObjectURL(imageFile.blob);
        this.store.imageAttributes = imageFile.attributes;
      }

      const index = this.store.activePreloads.findIndex(x => x === item.imageId);
      if (index > -1) {
        this.store.activePreloads.splice(index, 1);
      }
    });
  }

  async preLoadNextLowQualityImages(datasetId: string, paging: IPreviewPaging, currentIndex: number) {
    const cacheAvailable = await CacheManager.checkCacheAvailabilityAsync();
    if (!cacheAvailable) return;

    const range = this.config.appConfig.imagePreloadRange;
    const imagesToPreLoadIds = paging.items
      .filter(x => x.index > currentIndex - range && x.index < currentIndex + range && x.index !== currentIndex)
      .sort((n1, n2) => Math.abs(n1.index - currentIndex) - Math.abs(n2.index - currentIndex));

    await imagesToPreLoadIds.forEach(async (item: IPreviewPagingItem) => {
      if (item.size >= LOW_QUALITY_PRELOAD_MIN_IMAGE_SIZE) {
        const [blob] = await Promise.all([
          await this.apiService.getImageAsync(this.apiService.getUrl(`/Images/GetImageThumbnail/${item.imageId}`)),
          this.fetchImageInfoAsync(datasetId, item.imageId),
        ]);

        if (this.store.imagePreviewInfo.id === item.imageId) {
          this.store.lowQualityImagePreviewUrl = URL.createObjectURL(blob as any);
        }
      } else {
        await this.fetchImageInfoAsync(datasetId, item.imageId);
      }
    });
  }

  @action
  async deleteImageAsync(imageId: string) {
    const result = await this.apiService.postAsync<{ imageId: string }>('/Images/DeleteImage', { imageId });

    if (result instanceof StickerError) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'datasets:update_dataset_details_failed'));
      return;
    }

    this.store.details.imagesCount -= 1;
    const removedImage = this.store.detailsImages.find(img => img.id === imageId)!;
    this.store.details.imagesSize -= removedImage.size;
    this.store.detailsImages.remove(removedImage);
    this.store.selectedImages.remove(this.store.selectedImages.find(si => si.id === imageId)!);
  }

  @action
  async renameImageAsync(imageId: string, name: string, datasetId: string) {
    const result = await this.apiService.postAsync<{ imageId: string; name: string; datasetId: string }>('/Images/RenameImage', { imageId, name, datasetId });

    if (result instanceof StickerError) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'datasets:update_dataset_details_failed'));
      return;
    }

    // when renaming on dataset list update current images list
    const image = this.store.detailsImages.find(img => img.id === imageId);
    if (image) image.name = name;
    const cache = new ImagePreviewCache(this.currentWorkspaceStore.currentWorkspace!.id, datasetId, imageId);
    cache.setNewImageNameAsync(name);
  }

  @action
  async getDatasetProjectsAsync(datasetId: string) {
    const url = '/datasets/projects';
    const result = await this.apiService.getAsync<IPagedResult<IDatasetDetailsProjects>>(url, {
      params: {
        datasetId,
        ...this.store.detailsProjectsPaging,
      },
    });

    if (result instanceof StickerError) {
      if (!(result instanceof ForbiddenError)) {
        this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'datasets:get_dataset_projects_failed'));
      }
      this.routeService.push(Home.Datasets.List.withParams({ workspaceId: this.currentWorkspaceStore.currentWorkspace!.id }));
      return;
    }

    this.store.setDatasetDetailsProjects(result.data, {
      ...this.store.detailsProjectsPaging,
      pageNumber: result.pageNumber,
      pagesCount: result.pagesCount,
      totalCount: result.totalCount,
    });
  }

  async changeDatasetProjectsOrder(datasetId: string, orderBy: string = '', orderType: string = ''): Promise<void> {
    this.store.setDatasetDetailsProjectsPaging({
      ...this.store.detailsProjectsPaging,
      orderBy,
      orderType,
    });

    await this.getDatasetProjectsAsync(datasetId);
  }

  async changeDatasetProjectsPagination(datasetId: string, pageNumber: number, pageSize: number) {
    this.store.setDatasetDetailsProjectsPaging({
      ...this.store.detailsProjectsPaging,
      pageNumber,
      pageSize,
    });

    await this.getDatasetProjectsAsync(datasetId);
  }

  @action
  async updateDatasetDetailsAsync(detailsPayload: IUpdateDatasetDetailsPayload) {
    const result = await this.apiService.postAsync<IUpdateDatasetDetailsPayload>('/Datasets/UpdateDetails', detailsPayload);

    if (result instanceof StickerError && !(result instanceof ForbiddenError)) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'datasets:update_dataset_details_failed'));
      return;
    }

    this.store.details.name = detailsPayload.name;
    this.store.details.description = detailsPayload.description;
    this.store.details.author = detailsPayload.author;
    this.store.details.termsOfUse = detailsPayload.termsOfUse;
  }

  async validateDatasetNameAsync(name: string, datasetId: string): Promise<string[]> {
    const nameErrorCodes: string[] = [];
    const workspaceId = this.currentWorkspaceStore.currentWorkspace?.id;

    if (name === '' && name.length === 0) {
      nameErrorCodes.push('field_cant_be_empty');
    } else {
      const url = '/datasets/CheckDatasetNameUniqueness';
      const params = {
        datasetId,
        workspaceId,
        name,
      };
      const result = await this.apiService.getAsync<boolean | StickerError>(url, { params });
      if (result instanceof StickerError) {
        nameErrorCodes.push(...(result?.apiErrorResponse?.errorCodes || ['something_went_wrong']));
      } else if (!result) {
        nameErrorCodes.push('duplicate_dataset_name');
      }
    }
    return nameErrorCodes;
  }

  @action
  setDefaultImagesPaging(): void {
    this.store.setImagesPaging(DefaultPaging);
  }

  @action
  checkImageNameUniquenessAsync(imageId: string, datasetId: string, name: string): Promise<boolean | StickerError> {
    return this.apiService.getAsync<boolean>('/Images/CheckImageNameUniqueness', { params: { imageId, datasetId, name } });
  }

  async validateImageNameAsync(imageName: string, imageId: string, datasetId: string): Promise<InputStatus> {
    const errorCodes: string[] = [];

    if (imageName.trim() === '') {
      errorCodes.push('field_cant_be_empty');
    }

    const format = /[*:"\\|<>\/?]+/;
    if (format.test(imageName)) errorCodes.push('no_special_characters');

    const baseName = path.basename(imageName, path.extname(imageName));
    if (ReservedFileNames.some(fn => baseName.toUpperCase() === fn)) errorCodes.push('forbidden_image_name');
    if (/^\.+$/.test(imageName)) errorCodes.push('forbidden_image_name');

    const isUnique = await this.checkImageNameUniquenessAsync(imageId, datasetId, imageName);
    if (!isUnique) errorCodes.push('duplicate_image_name');

    const nameStatus = InputStatus.buildFrom(errorCodes);
    return nameStatus;
  }

  isValidFileName(imageName: string): boolean {
    if (imageName.length > 255) return false;

    const format = /[*:"\\|<>\/?]+/;
    if (format.test(imageName)) return false;

    const baseName = path.basename(imageName, path.extname(imageName));
    if (ReservedFileNames.some(fn => baseName.toUpperCase() === fn)) return false;
    if (/^\.+$/.test(imageName)) return false;

    return true;
  }
}
