﻿export const isAllowedImageType = (img: string) => IMAGE_TYPES.includes((img.split('.').pop() || '').toLowerCase());

export const isAllowedType = (img: File) => IMAGE_TYPES.includes((img.name.split('.').pop() || '').toLowerCase());

export const isAllowedModelType = (file: File) => MODEL_TYPES.includes((file.name.split('.').pop() || '').toLowerCase());

export const isAllowedSize = (img: File) => img.size > 0 && toMBytes(img.size) < 32;

export const toMBytes = (sizeInBytes: number) => sizeInBytes / 1024 / 1024;

const IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'bmp'];

const MODEL_TYPES = ['zip'];

export function width(meta: ExifReader.ExpandedTags): number {
  return meta.file?.['Image Width']?.value || meta.exif?.ImageWidth.value || meta.iptc?.ImageWidth?.value || meta.pngFile?.['Image Width']?.value || 0;
}

export function height(meta: ExifReader.ExpandedTags): number {
  return meta.file?.['Image Height']?.value || meta.exif?.ImageLength.value || meta.iptc?.ImageLength?.value || meta.pngFile?.['Image Height']?.value || 0;
}

export interface IShape {
  width: number;
  height: number;
}

export const THUMBNAIL_SIZE = { width: 270, height: 200 };

export async function generateThumbnail(file: File | Blob | ArrayBuffer): Promise<Blob> {
  const image = await loadImage(file);
  const canvas = document.createElement('canvas');
  const { width, height } = resizeMax(image, THUMBNAIL_SIZE);
  canvas.width = width;
  canvas.height = height;
  canvas.getContext('2d')!.drawImage(image, 0, 0, width, height);
  return exportCanvas(canvas);
}

function loadImage(file: File | Blob | ArrayBuffer): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    const url = URL.createObjectURL(file);
    image.src = url;
    image.addEventListener('load', () => {
      URL.revokeObjectURL(url);
      resolve(image);
    });
    image.addEventListener('error', (event: ErrorEvent) => {
      URL.revokeObjectURL(url);
      reject(event.error || new Error('Could not create thumbnail'));
    });
  });
}

export function resizeMax(original: IShape, desired: IShape): IShape {
  const originalAspectRatio = original.width / original.height;
  const thumbnailAspectRatio = desired.width / desired.height;

  if (thumbnailAspectRatio < originalAspectRatio) {
    return {
      width: desired.width,
      height: Math.round(desired.width / originalAspectRatio),
    };
  }
  return {
    height: desired.height,
    width: Math.round(desired.height * originalAspectRatio),
  };
}

function exportCanvas(canvas: HTMLCanvasElement): Promise<Blob> {
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => (blob ? resolve(blob) : reject()), 'image/jpeg');
  });
}

export function stripExifTags(file: File): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = (event: ProgressEvent<FileReader>) => {
      resolve(removeExifTags(event.target!.result as ArrayBuffer));
    };
    fr.onerror = (event: ProgressEvent<FileReader>) => {
      reject(new Error('Could not strip exif tags'));
    };
    fr.readAsArrayBuffer(file);
  });
}

function removeExifTags(buffer: ArrayBuffer): Blob {
  const dv = new DataView(buffer);
  let offset = 0;
  let recess = 0;
  const pieces: any[] = [];
  let i = 0;
  if (dv.getUint16(offset) === 0xffd8) {
    offset += 2;
    let app1 = dv.getUint16(offset);
    offset += 2;
    while (offset < dv.byteLength) {
      if (app1 === 0xffe1) {

        pieces[i] = { recess, offset: offset - 2 };
        recess = offset + dv.getUint16(offset);
        i += 1;
      } else if (app1 === 0xffda) {
        break;
      }
      offset += dv.getUint16(offset);
      app1 = dv.getUint16(offset);
      offset += 2;
    }
    if (pieces.length > 0) {
      const newPieces: any[] = [];
      pieces.forEach((v) => {
        newPieces.push(buffer.slice(v.recess, v.offset));
      });
      newPieces.push(buffer.slice(recess));
      return new Blob(newPieces);
    }
  }
  return new Blob([buffer]);
}
