import { injectable } from 'inversify';

export const BrowserServiceType = Symbol('BROWSER_SERVICE');

type CallBackFunction = () => boolean;

export interface IBrowserService {
  addOnBeforeUnload(func: CallBackFunction): void;
  clearCallBacks(): void;
}

@injectable()
export class BrowserService implements IBrowserService {
  onBeforeUnloadCallBacks: CallBackFunction[] = [];

  constructor() {
    window.onbeforeunload = () => {
      if (this.onBeforeUnloadCallBacks.some(n => n())) return '';
      return undefined;
    };
  }

  addOnBeforeUnload(func: CallBackFunction): void {
    this.onBeforeUnloadCallBacks.push(func);
  }

  clearCallBacks() {
    this.onBeforeUnloadCallBacks = [];
  }
}
