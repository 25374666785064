import { Button, FormGroup, Input, Label } from 'reactstrap';

import { ButtonWithLoader } from '../../../../../../components/ButtonWithLoader';
import ClassNames from 'classnames';
import { FilterContent } from './FilterContent';
import { ISavedFilter } from '../../imageFilters.model';
import { ITranslatable } from '../../../../../../helpers/translations.helpers';
import { InputStatus } from '../../../../../../models/error.model';
import React from 'react';
import { SelectWithHax } from '../../../../../../components/SelectWithHax';
import { as } from '../../../../../../helpers/react.helpers';
import { handleChange } from '../../../../../../helpers/formHelpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface ISaveFilterProps {
  savedFilters: ISavedFilter[];
  selectedFilter: ISavedFilter | undefined;
  isLoading: boolean;
  newFilterName: string;
  newFilterNameStatus: InputStatus;
  isNewFilterNameUsed: boolean;
  showDeleteConfirmation: boolean;
  showFilterLoadedMessage: boolean;
  showFilterSavedMessage: boolean;
  onNewFilterNameChange(newName: string): void;
  onNewFilterNameBlur(): void;
  onFilterSave(): void;
  onFilterOverrideConfirmed(): void;
  onFilterOverrideDeclined(): void;
  onFilterSelected(selectedFilter: ISavedFilter | undefined): void;
  onSelectedFilterDelete(): void;
  onSelectedFilterLoad(): void;
  onFilterDeleteConfirmed(): void;
  onFilterDeleteDeclined(): void;
}

@observer
class SaveFiltersPure extends React.Component<ISaveFilterProps & ITranslatable> {

  handleSelectedFilterChange = (selectedFilter: any) => {
    this.props.onFilterSelected(selectedFilter || undefined);
  }

  render() {
    const getOptionLabel = (option: ISavedFilter) => option.name;
    const getOptionValue = (option: ISavedFilter) => option.id;
    const showSuccessMessages = !this.props.showDeleteConfirmation && !this.props.isNewFilterNameUsed;

    return (
      <FilterContent labelKey={'save_load_filters'} className="filter-save">
        <div>
          <FormGroup>
            <Label>{this.props.t('select_filter_to_load')}</Label>
            <div className="input-wrapper">
              <SelectWithHax<ISavedFilter>
                value={this.props.selectedFilter}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                onChange={this.handleSelectedFilterChange}
                options={this.props.savedFilters.slice()}
                isClearable={true}
              />
              <Button color="primary" onClick={this.props.onSelectedFilterLoad} disabled={this.props.isLoading || !this.props.selectedFilter || this.props.isNewFilterNameUsed}>
                {this.props.t('load')}
              </Button>
              <Button color="warning" onClick={this.props.onSelectedFilterDelete} disabled={this.props.isLoading || !this.props.selectedFilter || this.props.isNewFilterNameUsed}>
                {this.props.t('delete')}
              </Button>
            </div>
          </FormGroup>
        </div>
        <div className="message-wrapper">
          {showSuccessMessages && <p
            className={ClassNames({ 'success-text': true, 'fade-out': !this.props.showFilterLoadedMessage, visible: this.props.showFilterLoadedMessage })}
          >
            {this.props.t('filter_loaded_successfully')}
          </p>}
        </div>
        <div>
          <FormGroup className="form-group-layout relative-form-group">
            <Label>{this.props.t('save_filter')}</Label>
            <div className="input-wrapper">
              <Input
                value={this.props.newFilterName}
                valid={this.props.newFilterNameStatus.isValid === true}
                invalid={this.props.newFilterNameStatus.isValid === false}
                type={'text'}
                placeholder={this.props.t('type_filter_name')}
                onChange={handleChange(this.props.onNewFilterNameChange)}
                onBlur={this.props.onNewFilterNameBlur}
                maxLength={200}
              />
              <ButtonWithLoader color="primary" onClick={this.props.onFilterSave} disabled={this.props.isLoading || !this.props.newFilterName} loaderKey="filter-save">
                {this.props.t('save')}
              </ButtonWithLoader>
            </div>
          </FormGroup>
          {this.props.isNewFilterNameUsed &&
            <div className="filter-save-warning">
              <p className="warning-text">{this.props.t('filter_override_question', { filterName: this.props.newFilterName })}</p>
              <ButtonWithLoader color="warning" onClick={this.props.onFilterOverrideConfirmed} disabled={this.props.isLoading} loaderKey="filter-save">
                {this.props.t('yes')}
              </ButtonWithLoader>
              <Button color="primary" onClick={this.props.onFilterOverrideDeclined} disabled={this.props.isLoading}>
                {this.props.t('no')}
              </Button>
            </div>}
          {this.props.showDeleteConfirmation &&
            <div className="filter-save-warning">
              <p className="warning-text">{this.props.t('filter_delete_question')}</p>
              <ButtonWithLoader color="warning" onClick={this.props.onFilterDeleteConfirmed} disabled={this.props.isLoading} loaderKey="filter-delete">
                {this.props.t('yes')}
              </ButtonWithLoader>
              <Button color="primary" onClick={this.props.onFilterDeleteDeclined} disabled={this.props.isLoading}>
                {this.props.t('no')}
              </Button>
            </div>}
          <div className="message-wrapper">
            {showSuccessMessages && <p
              className={ClassNames({ 'success-text': true, 'fade-out': !this.props.showFilterSavedMessage, visible: this.props.showFilterSavedMessage })}
            >{this.props.t('filter_saved_successfully')}
            </p>}
          </div>
        </div>
      </FilterContent >
    );
  }
}

export const SaveFilters = as<React.ComponentClass<ISaveFilterProps>>(withNamespaces('common')(SaveFiltersPure));
