import './S_PaginationText.scss';

import { ITranslatable } from '../../../../__legacy__/helpers/translations.helpers';
import React from 'react';
import { as } from '../../../../__legacy__/helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  text: string;
  translationOptions?: any;
}

class S_PaginationTextPure extends React.Component<IProps & ITranslatable> {
  render() {
    return <p className="pagination-text page-link">{this.props.t(this.props.text, this.props.translationOptions)}</p>;
  }
}

export const S_PaginationText = as<React.ComponentClass<IProps>>(withNamespaces('design')(S_PaginationTextPure));
