import * as React from 'react';

import { Button, Col, FormGroup, Label, Modal, ModalBody, Row } from 'reactstrap';
import { clamp, round } from 'lodash/fp';

import { ITranslatable } from '../../../helpers/translations.helpers';
import { ValidatedTextInput } from '../../../components/ValidatedTextInput';
import { Zlider } from '../../../components/Slider';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IIncreaseLimitModalProps {
  showModal: boolean;
  credits: number;
  totalPrice: number;
  currency: string;
  onSend(): void;
  onCancel(): void;
  onCreditsChange(value: number): void;
}

type IProps = IIncreaseLimitModalProps & ITranslatable;

const MIN_CREDITS = 1000;
const MAX_CREDITS = 20_000;
const STEP = 1000;

class IncreaseLimitModal extends React.Component<IProps> {
  handleSliderChange = (value: number) => {
    this.setState({ credits: value });
    this.props.onCreditsChange(value);
  };

  handleCreditsChange = (value: string) => {
    const credits = clamp(MIN_CREDITS, MAX_CREDITS, round(parseInt(value, 10) / 1000) * 1000 || MIN_CREDITS);
    this.handleSliderChange(credits);
  };

  render() {
    const { t } = this.props;
    return (
      <Modal isOpen={this.props.showModal} toggle={this.props.onCancel} size="lg">
        <ModalBody>
          <h5 className="text-center">{t('increase_limit_modal_header')}</h5>
          <div>
            <p
              className="text-center"
              dangerouslySetInnerHTML={{ __html: t('increase_limit_modal_instruction') }}
            />
            <FormGroup>
              <Row>
                <Col sm={4} className="form-column">
                  <Label for="credits">{t('credits')}</Label>
                </Col>
                <Col sm={5}>
                  <Zlider
                    min={MIN_CREDITS}
                    max={MAX_CREDITS}
                    step={STEP}
                    value={this.props.credits - (this.props.credits % STEP)}
                    onChange={this.handleSliderChange}
                  />
                </Col>
                <Col sm={3}>
                  <ValidatedTextInput
                    id="credits"
                    type={'number'}
                    min={MIN_CREDITS}
                    max={MAX_CREDITS}
                    step={STEP}
                    onChange={this.handleCreditsChange}
                    styleClassName={''}
                    value={this.props.credits.toString()}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={9} className="form-column">
                  <Label>{t('total_price')}</Label>
                </Col>
                <Col sm={3}>
                  {this.props.totalPrice} {this.props.currency}
                </Col>
              </Row>
            </FormGroup>
            <Row>
              <Col>
                <Button color="link" onClick={this.props.onCancel}>
                  {t('cancel')}
                </Button>
              </Col>
              <Col className="text-right" sm={8}>
                <Button block color="primary" className="gradient" onClick={this.props.onSend}>
                  {t('buy_now')}
                </Button>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default as<React.StatelessComponent<IIncreaseLimitModalProps>>(
  withNamespaces('billing', { wait: true })(IncreaseLimitModal),
);
