import { StickerEvent } from '../../../services/eventBus.service';

export const UserRemovedFromProjectType = Symbol('USER_REMOVED_FROM_PROJECT');

export class UserRemovedFromProject extends StickerEvent {
  constructor() {
    super();
  }

  get type(): Symbol {
    return UserRemovedFromProjectType;
  }
}
