import { EditMarkingToolBl, EditMarkingToolBlType } from '../../EditMarkingTool.bl';
import { EditMarkingToolPermissions, EditMarkingToolPermissionsType } from '../../EditMarkingTool.permissions';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { as, injectProps } from '../../../../../../../../__legacy__/helpers/react.helpers';

import { EditMarkingToolButton } from './EditMarkingToolButton';
import React from 'react';

interface Props {
  markingToolId: string;
}

interface InjectedProps {
  bl: EditMarkingToolBl;
  permissions: EditMarkingToolPermissions;
}

type AllProps = Props & InjectedProps & RouteComponentProps<{ projectId: string }>;

@injectProps({
  bl: EditMarkingToolBlType,
  permissions: EditMarkingToolPermissionsType,
})
class EditMarkingToolButtonContainerPure extends React.Component<AllProps> {
  handleShowModal = () => {
    const projectId = this.props.match.params.projectId;
    this.props.bl.showModalAsync(projectId, this.props.markingToolId);
  };

  render(): React.ReactNode {
    return <EditMarkingToolButton disabled={!this.props.permissions.canOpenEditMarkingToolModal} onShowModal={this.handleShowModal} />;
  }
}

export const EditMarkingToolButtonContainer = as<React.ComponentClass<Props>>(withRouter(EditMarkingToolButtonContainerPure));
