import { AddMarkingToolBl, AddMarkingToolBlType } from '../../AddMarkingTool.bl';
import { AddMarkingToolStore, AddMarkingToolStoreType } from '../../AddMarkingTool.store';
import { as, injectProps } from '../../../../../../../../__legacy__/helpers/react.helpers';

import { ColorField } from '../../../commons/ui/fields/ColorField';
import React from 'react';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: AddMarkingToolBl;
  store: AddMarkingToolStore;
}

@injectProps({
  bl: AddMarkingToolBlType,
  store: AddMarkingToolStoreType,
})
@observer
class ColorFieldContainerPure extends React.Component<InjectedProps> {
  render() {
    return <ColorField color={this.props.store.color} onChangeColor={this.props.bl.changeColor} />;
  }
}

export const ColorFieldContainer = as<React.ComponentClass>(ColorFieldContainerPure);
