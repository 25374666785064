import { injectable, inject } from 'inversify';
import { ApiServiceType, IPagedResult } from '../../../../../services/api.service';
import { StickerError } from '../../../../../models/error.model';
import { IApiService } from '../../../../../services/api.service.base';
import { IStatisticsListItem, IProjectToolStatisticsDto } from '../projectDetailsStatistics.model';
import { IPagingInfoWithOrder } from '../../../../../models/paginationInfo.model';

export const ProjectDetailsStatisticsApiServiceType = Symbol('PROJECT_DETAILS_STATISTICS_API_SERVICE');

export interface IProjectDetailsStatisticsApiService {
  getProjectStatisticsAsync(projectId: string, pagingInfo: IPagingInfoWithOrder): Promise<IPagedResult<IStatisticsListItem> | StickerError>;
  getProjectToolStatistics(projectId: string): Promise<IProjectToolStatisticsDto | StickerError>;
  refreshToolStatistics(projectId: string): Promise<IProjectToolStatisticsDto | StickerError>;
}

@injectable()
export class ProjectDetailsStatisticsApiService implements IProjectDetailsStatisticsApiService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService) { }

  getProjectToolStatistics(projectId: string): Promise<IProjectToolStatisticsDto | StickerError> {
    return this.apiService.getAsync<IProjectToolStatisticsDto>(`/projects/GetProjectToolStatistics?ProjectId=${projectId}`);
  }

  refreshToolStatistics(projectId: string): Promise<IProjectToolStatisticsDto | StickerError> {
    return this.apiService.getAsync<IProjectToolStatisticsDto>(`/projects/RefreshProjectToolStatistics?ProjectId=${projectId}`);
  }

  async getProjectStatisticsAsync(projectId: string, pagingInfo: IPagingInfoWithOrder) {
    const { pageNumber, pageSize, orderBy, orderType } = pagingInfo;
    const url = `/Projects/Statistics?ProjectId=${projectId}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&orderType=${orderType}`;
    return await this.apiService.getAsync<IPagedResult<IStatisticsListItem>>(url);
  }
}
