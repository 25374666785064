import '@webscopeio/react-textarea-autocomplete/style.css';

import * as React from 'react';

import ReactTextareaAutocomplete, { ItemComponentProps } from '@webscopeio/react-textarea-autocomplete';

import { FormGroup } from 'reactstrap';
import { ITranslatable } from '../helpers/translations.helpers';
import { as } from '../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface ITextAreaWithAutocompleteProps {
  id?: string;
  value?: string | number;
  maxLength?: number;
  onChange(value: string): void;
  autofocus?: boolean;
  counter?: boolean;
  autocompleteOptions: string[];
}

type IProps = ITextAreaWithAutocompleteProps & ITranslatable;

class TextAreaWithAutocompletePure extends React.Component<IProps> {
  inputElement?: HTMLTextAreaElement;

  componentDidMount() {
    if (this.props.autofocus) {
      setTimeout(() => this.inputElement?.focus(), 0);
    }
  }

  getInputReference = (textarea: HTMLTextAreaElement) => { this.inputElement = textarea; };

  itemComponent = (i: ItemComponentProps<string>) => <div>{`${i.entity}`}</div>;

  loadingComponent = () => <div />;

  handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let value = e.target.value;
    if (this.props.maxLength) {
      value = value.substring(0, this.props.maxLength);
    }
    this.props.onChange(value);
  }

  render() {
    const maxLength = this.props.maxLength === undefined ? 100 : this.props.maxLength;

    return (
      <FormGroup className="form-group-layout relative-form-group text-area-with-autocomplete">
        <ReactTextareaAutocomplete<string>
          innerRef={this.getInputReference}
          onChange={this.handleChange}
          loadingComponent={this.loadingComponent}
          maxLength={maxLength}
          value={this.props.value}
          minChar={0}
          className="form-control"
          trigger={{
            '#': {
              component: this.itemComponent,
              dataProvider: token => this.props.autocompleteOptions.filter(text => text.toLowerCase().startsWith(token.toLowerCase())),
              output: item => `${item}`,
            },
          }}
        />
        {this.props.counter && (
          <div className="text-right">
            <small>
              {(this.props.value || '').toString().length}/{maxLength}
            </small>
          </div>
        )}
      </FormGroup>
    );
  }
}

export const TextAreaWithAutocomplete = as<React.ComponentClass<ITextAreaWithAutocompleteProps>>(withNamespaces('common', { wait: true })(TextAreaWithAutocompletePure));
