import * as React from 'react';
import { FormGroup, Label } from "reactstrap";
import classNames from 'classnames';

export interface IToggleSwitchWithLabelProps {
  label: string;
  checked: boolean;
  disabled?: boolean;
  onChange(): void;
}

export const ToggleSwitchWithLabel = ({ label, ...props }: IToggleSwitchWithLabelProps) => (
  <FormGroup>
    <Label>{label}</Label>
    <div>
      <label className="switch">
        <input type="checkbox" {...props} />
        <span
          className={classNames("switch-slider", {
            "switch-slider-disabled": props.disabled,
          })}
        />
        </label>
    </div>
  </FormGroup>
);
