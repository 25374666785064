import { BaseRouteConfig, StickerRoute } from './BaseRoute';

export class AttachmentsPath extends BaseRouteConfig {
  public get Index(): string {
    return 'attachments';
  }

  public readonly List = {
    Folder: new StickerRoute<{ workspaceId: string, attachmentId: string }>(`${this.Path}/:attachmentId`),
    Preview: new StickerRoute<{ workspaceId: string, attachmentId: string, parentId?: string }>(`${this.Path}/:attachmentId/preview/:parentId?`),
  };
}
