import * as React from 'react';

interface IProps {
  shortName: string;
  variation: string;
}

export const UserAvatar = (props: IProps) => (
  <span className={`user-avatar ${props.variation}`}>{props.shortName}</span>
);
