import { WithNamespaces, withNamespaces } from 'react-i18next';

import { FormGroup } from 'reactstrap';
import { RadioInput } from '../../../../../../../../../__legacy__/components/RadioInput';
import React from 'react';
import { ResolveConflictDecision } from '../../../../models/ImportAnnotationsConflicts';

interface Props {
  imageName: string;
  decision: ResolveConflictDecision;
  onKeepOriginal(): void;
  onReplaceAnnotation(): void;
}

const ResolveConflictTilePure: React.FC<Props & WithNamespaces> = props => {
  const { t, imageName, decision, onKeepOriginal, onReplaceAnnotation } = props;
  return (
    <div className="resolve-conflict">
      <p title={imageName}>{imageName}</p>
      <FormGroup check inline>
        <RadioInput
          label={t('new.projects.details.import_annotations.steps.resolve_conflicts.keep_original')}
          id={`keep-original-${imageName}`}
          checked={decision === ResolveConflictDecision.keepOriginal}
          onClick={onKeepOriginal}
        />
        <RadioInput
          label={t('new.projects.details.import_annotations.steps.resolve_conflicts.replace_annotation')}
          id={`replace-annotation-${imageName}`}
          checked={decision === ResolveConflictDecision.replaceAnnotation}
          onClick={onReplaceAnnotation}
        />
      </FormGroup>
      <hr />
    </div>
  );
};

export const ResolveConflictTile = withNamespaces()(ResolveConflictTilePure);
