﻿import * as React from 'react';

import { Col, Form, FormGroup, Label, Row } from 'reactstrap';

import { ButtonWithLoader } from '../../../components/ButtonWithLoader';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { InputStatus } from '../../../models/error.model';
import { ValidatedTextInput } from '../../../components/ValidatedTextInput';
import { WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { as } from '../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { withNamespaces } from 'react-i18next';

export interface ICreatApiKeyFormProps {
  name: string;
  nameStatus: InputStatus;
  onNameChange(email: string): void;
  validateName(email: string): void;
  onAddNewApiKey(): void;
}

class CreateApiKeyFormPure extends WithLoaderComponentBase<ICreatApiKeyFormProps & ITranslatable> {

  @autobind
  handleOnSubmit(e: React.FormEvent) {
    e.preventDefault();
    this.props.onAddNewApiKey();
  }

  render() {
    return (
      <>
        <h1>{this.props.t('how_does_api_key_generator_work')}</h1>
        <p>{this.props.t('enter_your_api_key_name_and_press_add_button_new_key_will_be_generated_automatically')}</p>

        <Form className="custom-inline-form add-api-key" onSubmit={this.handleOnSubmit}>
          <Row>
            <Col md={3}>
              <ValidatedTextInput
                id="new-api-key-input"
                labelText={this.props.t('new_api_key')}
                value={this.props.name}
                onChange={this.props.onNameChange}
                onBlur={this.props.validateName}
                feedbacks={this.props.nameStatus.errorCodes}
                isValid={this.props.nameStatus.isValid}
                placeholder={this.props.t('api_key_name')}
              />
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label>&nbsp;</Label>
                <ButtonWithLoader
                  id="add-api-key-button"
                  loaderKey="add-api-key-button"
                  color="primary"
                  disabled={this.props.nameStatus.isValid === false}
                >
                  {this.props.t('add')}
                </ButtonWithLoader>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

export const CreateApiKeyForm = as<React.StatelessComponent<ICreatApiKeyFormProps>>(withNamespaces('integrations')(CreateApiKeyFormPure));
