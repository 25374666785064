import React, { useState } from 'react';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormGroup } from 'reactstrap';
import ReactDatePicker from 'react-datepicker';

interface IDateRangePickerProps {
  maxDate?: Date;
  minDate?: Date;
  selectedEndDate: Date;
  selectedStartDate: Date;
  onSetDates(startDate: Date, endDate: Date): void;
}

export const DateRangePicker: React.FC<IDateRangePickerProps> = (props: IDateRangePickerProps) => {
  const { selectedStartDate, selectedEndDate, onSetDates, minDate, maxDate } = props;

  const [isOpen, setIsOpen] = useState(false);

  const [startDate, setStartDate] = useState(selectedStartDate);
  const [endDate, setEndDate] = useState<Date | null>(selectedEndDate);

  const toggle = () => {
    setIsOpen(prevState => !prevState);
  };

  const onChange = (dates: [Date, Date]) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);

    if (!dates[1]) return;

    onSetDates(dates[0], dates[1]);
    toggle();
  };

  const renderLabel = (): React.ReactNode => (
    <div className="date-label-container" onClick={toggle}>
      <div>
        <label onClick={toggle}>{`${selectedStartDate.toLocaleDateString()} - ${selectedEndDate!.toLocaleDateString()}`}</label>
      </div>
      <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} onClick={toggle} />
    </div>
  );

  return (
    <FormGroup className="date-range-field">
      <ReactDatePicker
        minDate={minDate}
        maxDate={maxDate}
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        open={isOpen}
        customInput={renderLabel()}
        onBlur={toggle}
        onClickOutside={toggle}
        selectsRange
        calendarStartDay={1}
        disabledKeyboardNavigation
      />
    </FormGroup>
  );
};
