import * as React from 'react';

import { AnnotationServiceType, IAnnotationService } from '../annotation.service';
import { AnnotationTypeBlType, IAnnotationTypeBl } from '../submodules/annotationTypes/annotationType.bl';
import { AnnotationTypeStoreType, IAnnotationTypeStore } from '../submodules/annotationTypes/annotationType.store';
import { AnnotationUiStoreType, IAnnotationUiStore } from '../annotationUi.store';
import { AnnotationsStoreType, IAnnotationsStore } from '../annotations.store';
import { Feature, Polygon, Position } from 'geojson';
import { FreeDrawSegmentationServiceType, IFreeDrawSegmentationService } from '../freeDrawSegmentation.service';
import { IUndoRedoHistory, UndoRedoHistoryType } from '../undoRedoHistory.service';
import { IUserStore, UserStoreType } from '../../user/user.store';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { as, injectProps } from '../../../helpers/react.helpers';

import { DrawingComponent } from '../components/DrawingComponent';
import { IAnnotationType } from '../../projectDetails/sub/tools/projectDetailsTools.models';
import { ILoaderState } from '../../../helpers/loader.helpers';
import { ISegmentation } from '../annotations.interface';
import { LatLngBounds } from 'leaflet';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { pointCoordsToLatLng } from '../../../helpers/geometry/polygon.helpers';
import { toJS } from 'mobx';

interface IProps {
  disableCursorGuides: boolean;
}

interface IInjectedProps {
  annotationService: IAnnotationService;
  annotationTypeBl: IAnnotationTypeBl;
  annotationTypeStore: IAnnotationTypeStore;
  freeDrawSegmentationService: IFreeDrawSegmentationService;
  uiStore: IAnnotationUiStore;
  undoRedoHistory: IUndoRedoHistory;
  userStore: IUserStore;
  annotationsStore: IAnnotationsStore;
}

export enum DRAW_CONTROL_MODE {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  WATCH = 'WATCH',
}

@injectProps({
  annotationService: AnnotationServiceType,
  annotationTypeBl: AnnotationTypeBlType,
  annotationTypeStore: AnnotationTypeStoreType,
  freeDrawSegmentationService: FreeDrawSegmentationServiceType,
  uiStore: AnnotationUiStoreType,
  undoRedoHistory: UndoRedoHistoryType,
  userStore: UserStoreType,
  annotationsStore: AnnotationsStoreType,
})
@observer
class DrawingContainerPure extends React.Component<IProps & IInjectedProps & RouteComponentProps<{ projectId: string }>, ILoaderState> {
  state = { isLoading: false };

  @autobind
  handleAnnotationCreated(feature: Feature<Polygon>) {
    if (this.props.freeDrawSegmentationService.freeDrawFeature) {
      this.props.freeDrawSegmentationService.updateFreeDrawFeature(feature);
      return;
    }
    // TODO: This code should be placed in annotationService, but because of dependency of IUndoRedoHistory on IAnnotationService it's not currently possible.
    if (this.checkImageIntersection(feature.geometry.coordinates)) {
      const activeAnnotationTypeId = this.props.annotationTypeStore.activeAnnotationTypeId!;
      const newSegmentation = this.props.annotationService.createNewSegmentation(activeAnnotationTypeId, feature);
      this.props.annotationService.addNewSegmentation(newSegmentation);
      this.showNewSegmentation(activeAnnotationTypeId, newSegmentation);
      this.props.undoRedoHistory.clearDrawingHistory();
      this.props.undoRedoHistory.addCommand(this.props.undoRedoHistory.getNewChangeStateCommand());
    }
  }

  @autobind
  showNewSegmentation(activeAnnotationTypeId: string, newSegmentation: ISegmentation) {
    const projectId = this.props.match.params.projectId;
    const activeMarkingToolOptions = this.props.annotationTypeStore.annotationTypesOptions.find(o => o.id === activeAnnotationTypeId);

    if (activeMarkingToolOptions !== undefined && !activeMarkingToolOptions.isVisible) {
      this.props.annotationTypeBl.setAnnotationTypeOptions(projectId, { ...activeMarkingToolOptions, isVisible: true });

      const segmentationToHide = this.props.annotationsStore.segmentations
        .filter(s => activeAnnotationTypeId === s.feature.properties!['annotationTypeId'] && s.feature.id !== newSegmentation.feature.id)
        .map(s => ({ segmentationId: s.id, featureId: s.feature.id }));
      segmentationToHide.forEach(s => this.props.annotationService.hideSegmentation(s.featureId, s.segmentationId));
    }
  }

  @autobind
  handleAnnotationUpdated(id: string, feature: Feature<Polygon>) {
    // TODO: This code should be placed in annotationService, but because of dependency of IUndoRedoHistory on IAnnotationService it's not currently possible.
    if (this.checkImageIntersection(feature.geometry.coordinates)) {
      this.props.annotationService.updateSegmentation(id, feature);
    } else {
      this.handleAnnotationRemove(id);
    }
  }

  checkImageIntersection(geometry: Position | Position[] | Position[][]) {
    const imageBB = new LatLngBounds([0, 0], [this.props.annotationsStore.image!.height, this.props.annotationsStore.image!.width]);

    let positions: Position[] = [];
    if (Array.isArray(geometry[0]) && Array.isArray(geometry[0][0])) {
      positions = ([] as Position[]).concat(...(geometry as Position[][]));
    } else if (Array.isArray(geometry[0])) {
      positions = geometry as Position[];
    } else {
      positions = [geometry as Position];
    }

    const latlngs = positions.map(x => pointCoordsToLatLng(x));
    return latlngs.some(x => imageBB.contains(x));
  }

  @autobind
  handleFreeDrawStarted(annotation: Feature) {
    if (!this.props.freeDrawSegmentationService.freeDrawFeature) {
      const activeAnnotationTypeId = this.props.annotationTypeStore.activeAnnotationTypeId;
      const newAnnotation = this.props.annotationService.createNewSegmentation(activeAnnotationTypeId as string, annotation);
      this.props.freeDrawSegmentationService.setFreeDrawFeature(newAnnotation.feature);
      this.props.annotationService.deselectSegmentation();
      this.props.annotationService.setQuestionsAsCurrent(newAnnotation.questions);
    }

    this.props.freeDrawSegmentationService.startFreeDraw();
  }

  @autobind
  handleFreeDrawCancel() {
    return this.props.freeDrawSegmentationService.clearAsync();
  }

  @autobind
  handleFreeDrawDelete() {
    this.props.freeDrawSegmentationService.clear(true);
  }

  @autobind
  handleAnnotationRemove(id: string) {
    this.props.freeDrawSegmentationService.clear(true);
    this.props.annotationService.deleteSegmentation(id);
    this.props.annotationTypeBl.handleAnnotationRemoveInDrawingContainer();
  }

  render() {
    const activeAnnotationTypeId = this.props.annotationTypeStore.activeAnnotationTypeId;
    const activeAnnotationType = this.props.annotationService.getAnnotationType(activeAnnotationTypeId as string);
    const getControlMode = (activeAnnotationType: IAnnotationType | undefined): DRAW_CONTROL_MODE =>
      activeAnnotationType !== undefined || this.props.annotationsStore.drawFocusFrameForId ? DRAW_CONTROL_MODE.CREATE : DRAW_CONTROL_MODE.EDIT;

    const areCursorGuidesEnabled = !this.props.disableCursorGuides && this.props.userStore.areCursorGuidesEnabled;
    return (
      <DrawingComponent
        activeAnnotationTypeId={activeAnnotationTypeId}
        activeAnnotationTypeOptions={this.props.annotationTypeStore.annotationTypesOptions.find(o => o.id === activeAnnotationTypeId)}
        color={activeAnnotationType ? activeAnnotationType.color : 'black'}
        fillOpacity={this.props.userStore.opacityLevel / 100}
        controlMode={getControlMode(activeAnnotationType)}
        stopAction={this.state.isLoading || !this.props.annotationsStore.image}
        selectorType={activeAnnotationType ? activeAnnotationType.selectorType : null}
        selectedSegmentation={this.props.annotationsStore.selectedSegmentation}
        freeDrawInProgress={this.props.freeDrawSegmentationService.freeDrawInProgress}
        freeDrawSegmentationFeature={toJS(this.props.freeDrawSegmentationService.freeDrawFeature)}
        currentCursorPosition={this.props.uiStore.cursorLatLng}
        onAnnotationCreated={this.handleAnnotationCreated}
        onAnnotationCopied={this.props.annotationService.copySegmentation}
        onAnnotationPasted={this.props.annotationService.pasteSegmentation}
        onAnnotationEdited={this.handleAnnotationUpdated}
        onAnnotationRemove={this.handleAnnotationRemove}
        onMouseMove={this.props.uiStore.setCurrentCursorPosition}
        undoRedoHistory={this.props.undoRedoHistory}
        freeDrawSegmentationService={this.props.freeDrawSegmentationService}
        onEditDisabled={this.props.annotationService.deselectSegmentation}
        onEditEnabled={this.props.annotationService.enableEdit}
        onFreeDrawStarted={this.handleFreeDrawStarted}
        onFreeDrawCancel={this.handleFreeDrawCancel}
        onFreeDrawDelete={this.handleFreeDrawDelete}
        annotationService={this.props.annotationService}
        isImprovedVisibilityCursorEnabled={this.props.userStore.isImprovedVisibilityCursorEnabled}
        areCursorGuidesEnabled={areCursorGuidesEnabled}
      />
    );
  }
}

export const DrawingContainer = as<React.ComponentClass<IProps>>(withRouter(DrawingContainerPure));
