import { inject, injectable } from 'inversify';
import moment from 'moment';
import { TimePeriod } from '../../../../../design/selects/timePeriodSelector/S_TimePeriodSelector';
import { StickerError } from '../../../../../__legacy__/models/error.model';
import { ApiService, ApiServiceType } from '../../../../../__legacy__/services/api.service';
import { IProjectStats } from '../models/ProjectStats';

export const GetProjectStatsServiceType = Symbol('GET_STATS_SERVICE');

export class GetProjectStatsRequest {
  projectId: string;
  timePeriod: TimePeriod;
  startDate: moment.Moment;
  endDate: moment.Moment;

  constructor(projectId: string, timePeriod: TimePeriod, startDate: moment.Moment, endDate: moment.Moment) {
    this.projectId = projectId;
    this.timePeriod = timePeriod;
    this.startDate = startDate;
    this.endDate = endDate;
  }
}

export interface IGetProjectStatsService {
  getStatsAsync(request: GetProjectStatsRequest): Promise<IProjectStats>;
}

@injectable()
export class GetProjectStatsService implements IGetProjectStatsService {
  constructor(@inject(ApiServiceType) private readonly apiService: ApiService) {}

  async getStatsAsync(request: GetProjectStatsRequest): Promise<IProjectStats> {
    const searchParams = new URLSearchParams({
      projectId: request.projectId,
      timePeriod: request.timePeriod.toString(),
      startDate: request.startDate.format(),
      endDate: request.endDate.format(),
    }).toString();

    const response = await this.apiService.getAsync<IProjectStats>(`/projects/ProjectStats?${searchParams}`);

    if (response instanceof StickerError) {
      throw response;
    }

    return response;
  }
}
