import * as React from 'react';

import { AuthStoreType, IAuthStore } from '../../auth/auth.store';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { Datasets, IDatasetsContext } from '../datasets.context';
import { DatasetsPermissionsType, IDatasetsPermissions } from '../datasets.permissions';
import { IImagesFromUrlsService, ImagesFromUrlsServiceType } from '../imagesFromUrlsUpload.service';
import { IImagesUploaderService, ImagesUploaderServiceType } from '../imagesUpload.service';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { INotificationsService, NotificationsServiceType } from '../../notifications/services/notifications.service';
import { IUserStore, ListViewMode, UserStoreType } from '../../user/user.store';
import { InputStatus, StickerError } from '../../../models/error.model';
import { NotificationLevel, ToastNotification } from '../../notifications/models/notification.model';
import _, { debounce } from 'lodash';
import { as, injectProps } from '../../../helpers/react.helpers';

import { DatasetDetails } from '../datasetDetails.context';
import { DatasetDetailsImagesList } from '../components/DatasetDetailsImagesList';
import { DatasetStatus } from '../datasetStatus.model';
import { ISelectedImage } from '../datasetsDetails.store';
import { Observer } from 'mobx-react';
import autobind from 'autobind-decorator';

interface IState extends ILoaderState {
  showDeleteConfirmationModal: boolean;
  imageIdToDeleteWasAnnotated: boolean;
  imageIdToDeleteIsLocked: boolean;
  imageIdToDelete?: string;
  lastSelectedImageId?: string;
  showRenameImageConfirmationModal: boolean;
  renameImageId: string;
  renameImageName: string;
  renameImageNameStatus: InputStatus;
  renameImageHasUrl: boolean;
}

export interface IInjectedProps {
  imagesService: IImagesUploaderService;
  urlsService: IImagesFromUrlsService;
  notificationService: INotificationsService;
  authStore: IAuthStore;
  userStore: IUserStore;
  datasetsPermissions: IDatasetsPermissions;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

@injectProps({
  imagesService: ImagesUploaderServiceType,
  urlsService: ImagesFromUrlsServiceType,
  notificationService: NotificationsServiceType,
  authStore: AuthStoreType,
  userStore: UserStoreType,
  datasetsPermissions: DatasetsPermissionsType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
class DatasetDetailsImagesContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  static contextType = DatasetDetails;
  declare context: React.ContextType<typeof DatasetDetails>;

  constructor(props: IInjectedProps) {
    super(props);

    this.state = {
      isLoading: false,
      showDeleteConfirmationModal: false,
      imageIdToDelete: '',
      imageIdToDeleteWasAnnotated: false,
      imageIdToDeleteIsLocked: false,
      lastSelectedImageId: undefined,
      showRenameImageConfirmationModal: false,
      renameImageId: '',
      renameImageName: '',
      renameImageNameStatus: InputStatus.empty(),
      renameImageHasUrl: false,
    } as IState;
  }

  async componentDidMount() {
    const { service, route } = this.context;

    const isSuccessful = await this.withLoaderAsync<boolean>(async () => {
      return await service.getDatasetDetailsImageViewAsync(route.match.params.datasetId);
    });

    if (!isSuccessful) return;

    this.props.imagesService.onUploadFinishedSuccessfully.push(this.refresh);
    this.props.urlsService.onUploadFinishedSuccessfully.push(this.refresh);
    this.props.imagesService.onUploadAborted.push(this.refresh);
    this.props.urlsService.onUploadAborted.push(this.refresh);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    let index = this.props.imagesService.onUploadFinishedSuccessfully.indexOf(this.refresh);
    if (index > -1) {
      this.props.imagesService.onUploadFinishedSuccessfully.splice(index, 1);
    }
    index = this.props.urlsService.onUploadFinishedSuccessfully.indexOf(this.refresh);
    if (index > -1) {
      this.props.urlsService.onUploadFinishedSuccessfully.splice(index, 1);
    }
    index = this.props.imagesService.onUploadAborted.indexOf(this.refresh);
    if (index > -1) {
      this.props.imagesService.onUploadAborted.splice(index, 1);
    }
    index = this.props.urlsService.onUploadAborted.indexOf(this.refresh);
    if (index > -1) {
      this.props.urlsService.onUploadAborted.splice(index, 1);
    }
  }

  refresh = async () => {
    const paging = this.context.service.store.detailsImagesPaging;
    await this.context.service.refreshImages(this.context.route.match.params.datasetId, paging.pageNumber, paging.pageSize, paging.orderBy, paging.orderType);
  };

  @autobind
  async onPaginationChange(pageNumber: number, pageSize: number) {
    const paging = this.context.service.store.detailsImagesPaging;
    await this.withLoaderAsync(
      async () => await this.context.service.getDatasetImagesAsync(this.context.route.match.params.datasetId, pageNumber, pageSize, paging.orderBy, paging.orderType),
    );
  }

  @autobind
  async handleOrderingChange(orderBy: string, orderType: string) {
    const paging = this.context.service.store.detailsImagesPaging;
    await this.withLoaderAsync(
      async () => await this.context.service.getDatasetImagesAsync(this.context.service.store.details.id, paging.pageNumber, paging.pageSize, orderBy, orderType),
    );
  }

  @autobind
  async handleDownloadImage(id: string) {
    this.context.service.downloadImageAsync(id).then(result => {
      if (result instanceof StickerError) {
        this.props.notificationService.push(new ToastNotification(NotificationLevel.ERROR, 'common:cant_download_image'));
      }
    });
  }

  @autobind
  handleDeleteImage(id: string) {
    const image = this.context.service.store.detailsImages.find(x => x.id === id);

    this.setState({
      showDeleteConfirmationModal: true,
      imageIdToDelete: id,
      imageIdToDeleteWasAnnotated: image !== undefined && image.hasAnnotations,
      imageIdToDeleteIsLocked: image !== undefined && image.hasLocks,
    });
  }

  @autobind
  handleCancelDeleteImage() {
    this.setState({
      showDeleteConfirmationModal: false,
      imageIdToDelete: undefined,
      imageIdToDeleteWasAnnotated: false,
      imageIdToDeleteIsLocked: false,
    });
  }

  handleConfirmDeleteImage = (datasets: IDatasetsContext) => async () => {
    this.state.imageIdToDelete && (await this.context.service.deleteImageAsync(this.state.imageIdToDelete));
    this.setState({ showDeleteConfirmationModal: false, imageIdToDelete: undefined });
    const paging = this.context.store.detailsImagesPaging;
    await this.withLoaderAsync(
      async () => await this.context.service.getDatasetImagesAsync(this.context.store.details.id, paging.pageNumber, paging.pageSize, paging.orderBy, paging.orderType),
    );
    datasets.store.refreshFromDetails(this.context.store.details);
  };

  @autobind
  toggleRenameImageModal(imageId: string) {
    const image = this.context.store.detailsImages.find(i => i.id === imageId);

    this.setState(
      (prevState: IState): IState => ({
        ...prevState,
        showRenameImageConfirmationModal: !prevState.showRenameImageConfirmationModal,
        renameImageId: imageId,
        renameImageName: image ? image.name : '',
        renameImageNameStatus: InputStatus.empty(),
        renameImageHasUrl: image?.hasFileUrl ?? false,
      }),
    );
  }

  @autobind
  handleRenameImageNameChange(value: string) {
    this.setState({ renameImageName: value });
    this.validateNameDebounced();
  }

  validateName = async () => {
    const inputStatus = await this.context.service.validateImageNameAsync(this.state.renameImageName, this.state.renameImageId, this.context.route.match.params.datasetId);
    this.setState({ renameImageNameStatus: inputStatus });
  };

  validateNameDebounced = debounce(async () => {
    await this.validateName();
  }, 300);

  @autobind
  async renameImage() {
    await this.validateName();
    if (this.state.renameImageNameStatus.isValid !== true) return;

    const result = await this.context.service.renameImageAsync(this.state.renameImageId, this.state.renameImageName, this.context.route.match.params.datasetId);

    if (result instanceof StickerError) {
      this.setState(
        (prevState: IState): IState => ({
          ...prevState,
          renameImageNameStatus: InputStatus.buildFrom(result.apiErrorResponse!.errorCodes),
        }),
      );
    } else {
      this.setState(
        (prevState: IState): IState => ({
          ...prevState,
          renameImageNameStatus: InputStatus.empty(),
          showRenameImageConfirmationModal: false,
        }),
      );
    }
  }

  @autobind
  handleNonBatchCheck(id: string) {
    this.setState({ lastSelectedImageId: id });

    const store = this.context.store;
    const imageToAdd = store!.detailsImages!.filter(i => i.id === id)[0];

    if (store.selectedImages.some(si => si.id === imageToAdd.id)) {
      store.selectedImages.remove(store.selectedImages.find(si => si.id === imageToAdd.id)!);
    } else {
      store?.selectedImages.push({ id: imageToAdd.id, hasLock: imageToAdd.hasLocks, hasAnnotation: imageToAdd.hasAnnotations });
    }
  }

  @autobind
  handleBatchCheck(id: string) {
    const store = this.context.store;

    const lastIndex = store.detailsImages.findIndex(i => i.id === this.state.lastSelectedImageId);
    const markUpToIndex = store.detailsImages.findIndex(i => i.id === id);

    if (lastIndex === undefined || markUpToIndex === undefined || lastIndex === -1 || markUpToIndex === -1) {
      this.handleNonBatchCheck(id);
      return;
    }

    const changeDirection = lastIndex < markUpToIndex ? 1 : -1;
    const comparison = lastIndex < markUpToIndex ? (k: number, n: number) => k <= n : (k: number, n: number) => k >= n;

    for (let i = lastIndex; comparison(i, markUpToIndex); i += changeDirection) {
      const image = store.detailsImages[i];
      const imageToAdd: ISelectedImage = { id: image.id, hasAnnotation: image.hasAnnotations, hasLock: image.hasLocks };
      const canDelete = this.props.datasetsPermissions.canDeleteMarkedImages() || (!image.hasAnnotations && !image.hasLocks);

      if (!store.selectedImages.find(image => image.id === imageToAdd.id) && canDelete) {
        store.selectedImages.push(imageToAdd);
      }
    }
  }

  @autobind
  handleThumbCheck(id: string, isBatch: boolean) {
    if (isBatch && this.state.lastSelectedImageId) this.handleBatchCheck(id);
    else this.handleNonBatchCheck(id);
  }

  render() {
    return (
      <Datasets.Consumer>
        {datasets => (
          <Observer>
            {() => {
              const datasetId = this.context.route.match.params.datasetId;
              const store = this.context.store;
              return (
                <DatasetDetailsImagesList
                  datasetId={datasetId}
                  workspaceId={this.props.currentWorkspaceStore.currentWorkspace!.id}
                  datasetImages={store.detailsImages || []}
                  isLoading={this.state.isLoading}
                  paging={store.detailsImagesPaging}
                  showDeleteConfirmationModal={this.state.showDeleteConfirmationModal}
                  canDownloadImages={this.props.datasetsPermissions.canDownloadImages()}
                  isImageDownloadable={store.details.canDownload || false}
                  downloadProhibitionReason={store.details.downloadProhibitionReason}
                  disabled={this.props.currentWorkspaceStore.currentWorkspace!.locked}
                  onImageDownload={this.handleDownloadImage}
                  onImageDeleted={this.handleDeleteImage}
                  onCancelImageDelete={this.handleCancelDeleteImage}
                  onConfirmImageDelete={this.handleConfirmDeleteImage(datasets)}
                  onPaginationChange={this.onPaginationChange}
                  onOrderingChange={this.handleOrderingChange}
                  viewMode={this.props.userStore.datasetImageListViewMode || ListViewMode.List}
                  authToken={this.props.authStore.token}
                  canDeleteMarkedImages={this.props.datasetsPermissions.canDeleteMarkedImages()}
                  canDeleteUnmarkedImages={this.props.datasetsPermissions.canDeleteUnmarkedImages(store.details.status, store.details.createdById)}
                  imageIdToDeleteWasAnnotated={this.state.imageIdToDeleteWasAnnotated || false}
                  imageIdToDeleteIsLocked={this.state.imageIdToDeleteIsLocked || false}
                  isUploading={store.details.status === DatasetStatus.UPLOADING || store.details.status === DatasetStatus.PUBLISHEDUPLOADING}
                  imagesToBulkDelete={store.selectedImages.map(si => si.id) || []}
                  onThumbCheck={this.handleThumbCheck}
                  showRenameImageConfirmationModal={this.state.showRenameImageConfirmationModal}
                  renameImageName={this.state.renameImageName}
                  handleRenameImageNameChange={this.handleRenameImageNameChange}
                  renameImage={this.renameImage}
                  renameImageInputStatus={this.state.renameImageNameStatus}
                  toggleRenameImageModal={this.toggleRenameImageModal}
                  onRenameImage={this.toggleRenameImageModal}
                  canRenameImages={this.props.datasetsPermissions.canRenameImages(store.details.status, store.details.createdById)}
                />
              );
            }}
          </Observer>
        )}
      </Datasets.Consumer>
    );
  }
}

export const DatasetDetailsImagesContainer = as<React.ComponentClass>(DatasetDetailsImagesContainerPure);
