import * as React from 'react';

import { CryptoServiceType, ICryptoService } from '../../../services/crypto.service';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { IRouterStore, RouterStoreType } from '../../../stores/router.store';
import { IUserService, UserServiceType } from '../../user/user.service';
import { IWorkspaceService, WorkspaceServiceType } from '../workspaces.service';
import { InputStatus, StickerError } from '../../../models/error.model';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { as, injectProps } from '../../../helpers/react.helpers';

import { ChangeWorkspaceOwnerModal } from '../components/ChangeWorkspaceOwnerModal';
import { DeleteWorkspaceConfirmationModal } from '../components/DeleteWorkspaceConfirmationModal';
import { MoreWorkspaceOptions } from '../components/MoreWorkspaceOptions';
import { RenameWorkspaceModal } from '../components/RenameWorkspaceModal';
import { UpdateWorkspaceTimeZoneModalContainer } from '../sub/UpdateWorkspaceTimeZone/containers/UpdateWorkspaceTimeZoneModal.container';
import { WorkspaceRole } from '../workspaces.store';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ workspaceId?: string }> {
  currentWorkspaceStore: ICurrentWorkspaceStore;
  workspaceService: IWorkspaceService;
  cryptoService: ICryptoService;
  userService: IUserService;
  router: IRouterStore;
}

interface IState extends ILoaderState {
  renameWorkspaceName: string;
  renameWorkspaceInputStatus: InputStatus;
  showRenameWorkspaceModal: boolean;
  deleteWorkspaceModalShown: boolean;
  moreOptionsDropdownOpened: boolean;
  showChangeOwnerModal: boolean;
  ownerEmail: string;
  ownerEmailStatus: InputStatus;
  showKeyDownload: boolean;
  encryptionKeyUrl?: string;
}

@injectProps({
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  workspaceService: WorkspaceServiceType,
  cryptoService: CryptoServiceType,
  userService: UserServiceType,
  router: RouterStoreType,
})
@observer
class MoreOptionsWorkspaceContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  constructor(props: IInjectedProps) {
    super(props);

    this.state = {
      showRenameWorkspaceModal: false,
      renameWorkspaceName: '',
      renameWorkspaceInputStatus: InputStatus.empty(),
      moreOptionsDropdownOpened: false,
      deleteWorkspaceModalShown: false,

      showChangeOwnerModal: false,
      ownerEmail: '',
      ownerEmailStatus: InputStatus.empty(),

      showKeyDownload: false,

      isLoading: false,
    };

    this.makeEncryptionKeyUrl(props);
  }

  // tslint:disable-next-line:function-name
  UNSAFE_componentWillReceiveProps(props: IInjectedProps) {
    this.makeEncryptionKeyUrl(props);
  }

  async makeEncryptionKeyUrl(props: IInjectedProps) {
    if (this.state.encryptionKeyUrl) URL.revokeObjectURL(this.state.encryptionKeyUrl);
    const encryptionKeyUrl = await props.cryptoService.exportKey(this.props.currentWorkspaceStore.currentWorkspace!.id);
    this.setState({ encryptionKeyUrl });
  }

  @autobind
  toggleRenameWorkspaceModal() {
    if (this.state.isLoading) return;

    const workspace = this.props.currentWorkspaceStore.currentWorkspace;
    this.setState(
      (prevState: IState): IState => ({
        ...prevState,
        showRenameWorkspaceModal: !prevState.showRenameWorkspaceModal,
        renameWorkspaceName: workspace!.name,
        renameWorkspaceInputStatus: InputStatus.empty(),
      }),
    );
  }

  @autobind
  toggleChangeOwnerModal() {
    this.props.workspaceService.store.takeOverWorkspaceLimits = undefined;
    this.setState(
      (prevState: IState): IState => ({
        ...prevState,
        showChangeOwnerModal: !prevState.showChangeOwnerModal,
        ownerEmail: '',
        ownerEmailStatus: InputStatus.empty(),
      }),
    );
  }

  @autobind
  handleRenameWorkspaceNameChange(value: string) {
    this.setState(
      (prevState: IState): IState => ({
        ...prevState,
        renameWorkspaceName: value,
        renameWorkspaceInputStatus: this.validateWorkspaceName(value),
      }),
    );
  }

  @autobind
  validateWorkspaceName(value: string): InputStatus {
    const errorCodes: string[] = [];

    if (value.trim() === '') {
      errorCodes.push('field_cant_be_empty');
    }

    const isNotUnique = this.props.workspaceService.store.workspaces.map(w => w.name.toLocaleUpperCase()).includes(value.trim().toLocaleUpperCase());

    if (isNotUnique) {
      errorCodes.push('DUPLICATE_WORKSPACE_NAME');
    }

    return InputStatus.buildFrom(errorCodes);
  }

  @autobind
  async renameWorkspace() {
    if (this.state.isLoading) return;

    await this.withLoaderAsync(async () => {
      if (this.state.renameWorkspaceInputStatus.isValid !== true) return;
      const workspace = this.props.currentWorkspaceStore.currentWorkspace;
      if (!workspace) return;
      const result = await this.props.workspaceService.renameWorkspaceAsync(workspace.id, this.state.renameWorkspaceName);

      if (result instanceof StickerError) {
        this.setState(
          (prevState: IState): IState => ({
            ...prevState,
            renameWorkspaceInputStatus: InputStatus.buildFrom(result.apiErrorResponse!.errorCodes),
          }),
        );
      } else {
        this.setState(
          (prevState: IState): IState => ({
            ...prevState,
            renameWorkspaceInputStatus: InputStatus.empty(),
            showRenameWorkspaceModal: false,
          }),
        );
      }
    }, 'rename-workspace-button');
  }

  @autobind
  toggleMoreOptions() {
    this.setState(
      (prevState: IState): IState => ({
        ...prevState,
        moreOptionsDropdownOpened: !prevState.moreOptionsDropdownOpened,
      }),
    );
  }

  @autobind
  async toggleKeyDownload() {
    const { currentWorkspace } = this.props.currentWorkspaceStore;

    if (currentWorkspace) {
      const encryptionKeyUrl = await this.props.cryptoService.exportKey(currentWorkspace.id);
      this.setState(
        (prevState: IState): IState => ({
          ...prevState,
          encryptionKeyUrl,
          showKeyDownload: !prevState.showKeyDownload,
        }),
      );
    }
  }

  @autobind
  async handleChangeOwnerClicked() {
    await this.props.workspaceService.getCurrentWorkspaceMembersAsync();
    this.toggleChangeOwnerModal();
  }

  @autobind
  async handleChangeOwnerConfirmedAsync() {
    if (this.state.isLoading) return;

    await this.withLoaderAsync(async () => {
      if (this.state.ownerEmailStatus.isValid) {
        const result = await this.props.workspaceService.changeOwnerAsync(this.state.ownerEmail);

        if (result instanceof StickerError && result.apiErrorResponse!.errorCodes.includes('CAN_NOT_TAKEOVER_WORKSPACE')) {
          this.props.workspaceService.store.takeOverWorkspaceLimits = undefined;
          this.props.workspaceService.getTakeOverWorkspaceLimitsAsync(this.state.ownerEmail);
        } else {
          this.toggleChangeOwnerModal();
          await this.props.userService.getUserInfoAsync();
        }
      }
    }, 'change-workspace-owner-button');
  }

  @autobind
  handleDeleteClicked() {
    this.setState({ deleteWorkspaceModalShown: true });
  }

  @autobind
  handleCancelDelete() {
    if (this.state.isLoading) return;

    this.setState({ deleteWorkspaceModalShown: false });
  }

  @autobind
  async handleConfirmDeleteAsync() {
    if (this.state.isLoading) return;

    await this.withLoaderAsync(async () => {
      await this.props.workspaceService.deleteCurrentWorkspaceAsync();
      this.setState({ deleteWorkspaceModalShown: false });
    }, 'delete-workspace-button');
  }

  @autobind
  handleOwnerEmailChange(email: string) {
    this.props.workspaceService.store.takeOverWorkspaceLimits = undefined;
    this.setState({
      ownerEmail: email,
      ownerEmailStatus: InputStatus.valid(),
    });
    this.props.workspaceService.getTakeOverWorkspaceLimitsAsync(email);
  }

  render() {
    const { currentWorkspace } = this.props.currentWorkspaceStore;

    const showMoreOptions = currentWorkspace && currentWorkspace.role === WorkspaceRole.Owner;

    const workspaceTeam = this.props.workspaceService.store.currentWorkspaceTeam.filter(x => x.role !== WorkspaceRole.Owner);

    return showMoreOptions ? (
      <>
        <MoreWorkspaceOptions
          toggleMoreOptions={this.toggleMoreOptions}
          moreOptionsDropdownOpened={this.state.moreOptionsDropdownOpened}
          onRenameClicked={this.toggleRenameWorkspaceModal}
          onChangeOwnerClicked={this.handleChangeOwnerClicked}
          isEncrypted={currentWorkspace?.encryption?.encrypted ?? false}
          workspaceName={currentWorkspace?.name ?? ''}
          onDeleteClicked={this.handleDeleteClicked}
          encryptionKeyUrl={this.state.encryptionKeyUrl ?? ''}
        />
        <RenameWorkspaceModal
          showRenameWorkspace={this.state.showRenameWorkspaceModal}
          renameWorkspaceName={this.state.renameWorkspaceName}
          renameWorkspaceNameStatus={this.state.renameWorkspaceInputStatus}
          toggleRenameWorkspace={this.toggleRenameWorkspaceModal}
          onNameChange={this.handleRenameWorkspaceNameChange}
          onRenameSave={this.renameWorkspace}
        />
        <DeleteWorkspaceConfirmationModal
          deleteWorkspaceModalShown={this.state.deleteWorkspaceModalShown}
          onCancelDelete={this.handleCancelDelete}
          onConfirmDelete={this.handleConfirmDeleteAsync}
        />
        <ChangeWorkspaceOwnerModal
          showChangeOwner={this.state.showChangeOwnerModal}
          ownerEmail={this.state.ownerEmail}
          ownerEmailStatus={this.state.ownerEmailStatus}
          users={workspaceTeam}
          takeoverWorkspaceLimits={this.props.workspaceService.store.takeOverWorkspaceLimits}
          onOwnerEmailChange={this.handleOwnerEmailChange}
          toggleOwnerModal={this.toggleChangeOwnerModal}
          onOwnerSave={this.handleChangeOwnerConfirmedAsync}
        />
        <UpdateWorkspaceTimeZoneModalContainer />
      </>
    ) : (
      <></>
    );
  }
}

export const MoreOptionsWorkspaceContainer = as<React.ComponentClass>(withRouter(MoreOptionsWorkspaceContainerPure));
