import { AlertBarStoreType, IAlertBarStore } from '../../../../../../modules/alertBar/AlertBar.store';
import { AnnotationReviewServiceType, IAnnotationReviewService } from '../../../annotationReview.service';
import { AnnotationReviewStoreType, IAnnotationReviewStore } from '../annotationReview.store';
import { AnnotationUiStoreType, IAnnotationUiStore } from '../../../annotationUi.store';
import { AnnotationsStoreType, IAnnotationsStore } from '../../../annotations.store';
import { IImage, NoImageReason } from '../../../annotations.interface';
import { IWorkspacesStore, WorkspacesStoreType } from '../../../../workspaces/workspaces.store';
import { ReviewImagesQueueService, ReviewImagesQueueServiceType } from '../../../reviewImagesQueue.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import React from 'react';
import { ReviewMode } from '../models/reviewMode';
import { alertBarHeight } from '../../../../../../modules/alertBar/ui/AlertBar';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

export interface IReviewRenderProps {
  noImage: boolean;
  noImageReason: NoImageReason;
  marginTop: number;
  mode: ReviewMode;
  image?: IImage;
  handleRefresh(): void;
}
interface IReviewContainerProps {
  children(props: IReviewRenderProps): React.ReactNode;
}
interface IInjectedProps extends RouteComponentProps<{ projectId: string; imageId?: string }> {
  annotationUiStore: IAnnotationUiStore;
  annotationReviewService: IAnnotationReviewService;
  imagesQueueService: ReviewImagesQueueService;
  workspaceStore: IWorkspacesStore;
  annotationsStore: IAnnotationsStore;
  alertStore: IAlertBarStore;
  reviewStore: IAnnotationReviewStore;
}

@injectProps({
  annotationUiStore: AnnotationUiStoreType,
  annotationReviewService: AnnotationReviewServiceType,
  workspaceStore: WorkspacesStoreType,
  imagesQueueService: ReviewImagesQueueServiceType,
  annotationsStore: AnnotationsStoreType,
  alertStore: AlertBarStoreType,
  reviewStore: AnnotationReviewStoreType
})
@observer
class ReviewContainerPure extends React.Component<IReviewContainerProps & IInjectedProps> {
  get noImage() {
    return this.props.annotationsStore.image === undefined && !this.props.annotationUiStore.isImageLoading && !this.props.imagesQueueService.isAnyRequestInProgress;
  }

  componentDidMount() {
    const projectId = this.props.match.params.projectId;
    this.props.annotationReviewService.reviewStartedAsync(projectId, this.props.imagesQueueService);
  }

  componentWillUnmount() {
    this.props.annotationReviewService.reviewFinishedAsync();
  }

  @autobind
  handleRefresh() {
    this.props.annotationReviewService.refreshRequested();
  }

  render() {
    return this.props.children({
      noImage: this.noImage,
      noImageReason: this.props.annotationsStore.noImageReason,
      marginTop: alertBarHeight(this.props.alertStore.alerts.length),
      image: this.props.annotationsStore.image,
      handleRefresh: this.handleRefresh,
      mode: this.props.reviewStore.currentMode
    });
  }
}

export const ReviewContainer = as<React.ComponentClass<IReviewContainerProps>>(withRouter(ReviewContainerPure));
