import { inject, injectable } from 'inversify';
import { StickerError } from '../../../__legacy__/models/error.model';
import { IModelDetailsDto, IModelImageDto, IModelImagePredictionsDto, IModelMetricsDto, IModelSettingsParamsDto, IModelStatusDto } from '../modelDetails.model';
import { IApiService } from '../../../__legacy__/services/api.service.base';
import { ApiServiceType, IPagedResult } from '../../../__legacy__/services/api.service';
import { ApiServiceTrainingType } from '../../../__legacy__/services/api.service.training';
import { IModelConfig } from '../modelDetails.store';
import { MODEL_VARIANTS } from '../../../__legacy__/models/metrics.model';

export interface GetModelProjectsRequest {
  workspaceId: string;
}

export interface IModelProjectsDto {
  id: string;
  name: string;
}

export interface IGetModelDatasets {
  projectId: string;
}

export interface IPostModelTrainingStartRequest {
  model_config_: IModelConfig;
  model_variant: string;
  model_name: string;
  model_description: string;
  datasets: {
    project_id: string;
    datasets_ids: string[];
  };
}

export interface IGetModelImagesRequest {
  pageNumber: number;
  pageSize: number;
  orderBy: string;
  orderType: string;
  search?: string;
  projectId: string;
  datasetsIds: string[];
}

export interface IPostRunTrainingDTo {
  'job-id': string;
}

export interface IGetModelDownloadUrlDto {
  url: string;
}

export const ModelDetailsApiServiceType = Symbol('MODEL_DETAILS_API_SERVICE');

export interface IModelDetailsApiService {
  getModelDetailsAsync(workspaceId: string, modelId: string): Promise<IModelDetailsDto | StickerError>;
  getProjectsInWorkspaceAsync(request: GetModelProjectsRequest): Promise<IModelProjectsDto[] | StickerError>;
  getModelDatasetsAsync(request: IGetModelDatasets): Promise<any[] | StickerError>;
  getModelSettingsParamsAsync(workspaceId: string, modelVariant: MODEL_VARIANTS): Promise<IModelSettingsParamsDto | StickerError>;
  postModelTrainingStartAsync(workspaceId: string, request: IPostModelTrainingStartRequest): Promise<IPostRunTrainingDTo | StickerError>;
  postModelTrainingRestartAsync(workspaceId: string, modelId: string, request: IPostModelTrainingStartRequest): Promise<IPostRunTrainingDTo | StickerError>;
  postModelTrainingStopAsync(workspaceId: string, modelId: string): Promise<string | StickerError>;
  getDownloadModelAsync(workspaceId: string, modelId: string): Promise<IGetModelDownloadUrlDto | StickerError>;
  getModelImagesAsync(request: IGetModelImagesRequest): Promise<IPagedResult<IModelImageDto> | StickerError>;
  getModelImagesPredictionsAsync(workspaceId: string, modelId: string, imagesIds: string[]): Promise<IModelImagePredictionsDto | StickerError>;
  getModelTrainingProgressAsync(workspaceId: string, modelId: string): Promise<IModelMetricsDto | StickerError>;
  getModelTrainingStatusAsync(workspaceId: string, modelId: string): Promise<IModelStatusDto | StickerError>;
}

@injectable()
export class ModelDetailsApiService implements IModelDetailsApiService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService, @inject(ApiServiceTrainingType) private readonly apiTrainingService: IApiService) {}

  async getModelDetailsAsync(workspaceId: string, modelId: string): Promise<IModelDetailsDto | StickerError> {
    return await this.apiTrainingService.getAsync(`/workspaces/${workspaceId}/training-jobs/${modelId}`);
  }

  async getProjectsInWorkspaceAsync(request: GetModelProjectsRequest): Promise<IModelProjectsDto[] | StickerError> {
    return await this.apiService.getAsync('/Models/Projects', { params: request });
  }

  async getModelDatasetsAsync(request: IGetModelDatasets): Promise<any[] | StickerError> {
    return await this.apiService.getAsync('/Models/Datasets', { params: request });
  }

  async getModelSettingsParamsAsync(workspaceId: string, modelVariant: MODEL_VARIANTS): Promise<IModelSettingsParamsDto | StickerError> {
    return await this.apiTrainingService.getAsync<IModelSettingsParamsDto | StickerError>(`/workspaces/${workspaceId}/model-variants/${modelVariant}/schema/training`);
  }

  async postModelTrainingStartAsync(workspaceId: string, request: IPostModelTrainingStartRequest): Promise<IPostRunTrainingDTo | StickerError> {
    return await this.apiTrainingService.postAsync(`/workspaces/${workspaceId}/training-jobs/start`, request);
  }

  async postModelTrainingRestartAsync(workspaceId: string, modelId: string, request: IPostModelTrainingStartRequest): Promise<IPostRunTrainingDTo | StickerError> {
    return await this.apiTrainingService.postAsync(`/workspaces/${workspaceId}/training-jobs/${modelId}/start`, request);
  }

  async postModelTrainingStopAsync(workspaceId: string, modelId: string): Promise<string | StickerError> {
    return await this.apiTrainingService.postAsync(`/workspaces/${workspaceId}/training-jobs/${modelId}/stop`);
  }

  async getDownloadModelAsync(workspaceId: string, modelId: string): Promise<IGetModelDownloadUrlDto | StickerError> {
    return this.apiTrainingService.getAsync(`/workspaces/${workspaceId}/training-jobs/${modelId}/model`);
  }

  async getModelImagesAsync(request: IGetModelImagesRequest): Promise<IPagedResult<IModelImageDto> | StickerError> {
    return await this.apiService.postAsync('/Models/Images', request);
  }

  async getModelImagesPredictionsAsync(workspaceId: string, modelId: string, imagesIds: string[]): Promise<IModelImagePredictionsDto | StickerError> {
    return await this.apiTrainingService.postAsync(`/workspaces/${workspaceId}/training-jobs/${modelId}/image-metrics`, imagesIds);
  }

  async getModelTrainingProgressAsync(workspaceId: string, modelId: string): Promise<IModelMetricsDto | StickerError> {
    return await this.apiTrainingService.getAsync(`/workspaces/${workspaceId}/training-jobs/${modelId}/progress`);
  }

  async getModelTrainingStatusAsync(workspaceId: string, modelId: string): Promise<IModelStatusDto | StickerError> {
    return await this.apiTrainingService.getAsync(`/workspaces/${workspaceId}/training-jobs/${modelId}/status`);
  }
}
