import * as React from 'react';

import { BillingServiceType, IBillingService } from '../billing.service';
import { INotificationsService, NotificationsServiceType } from '../../notifications/services/notifications.service';
import { ModalNotification, NotificationLevel } from '../../notifications/models/notification.model';
import { as, injectProps } from '../../../helpers/react.helpers';

import IncreaseLimitModal from '../components/IncreaseLimitModal';
import IncreaseLimitThanksModal from '../components/IncreaseLimitThanksModal';
import { StickerError } from '../../../models/error.model';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

export interface IIncreaseLimitOuterProps {
  showModal: boolean;
  onClosed(): void;
}

interface IInjectedProps {
  billingService: IBillingService;
  notificationsService: INotificationsService;
}

type IProps = IIncreaseLimitOuterProps & IInjectedProps;

interface IState {
  showModal: boolean;
  showSuccessModal: boolean;
  credits: number;
  totalPrice: number;
}

@injectProps({
  billingService: BillingServiceType,
  notificationsService: NotificationsServiceType,
})
@observer
class IncreaseLimitContainer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showModal: false,
      showSuccessModal: false,
      credits: 3000,
      totalPrice: 3 * this.props.billingService.billing.creditPrice,
    };
  }

  // tslint:disable-next-line:function-name
  UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    if (this.props.showModal !== nextProps.showModal) this.setState({ showModal: nextProps.showModal });
  }

  @autobind
  handleCreditsChange(credits: number) {
    this.setState({ credits, totalPrice: (credits / 1000) * this.props.billingService.billing.creditPrice });
  }

  @autobind
  async handleSend() {
    const result = await this.props.billingService.requestAsync(this.state.credits);

    if (result instanceof StickerError) {
      this.props.notificationsService.push(
        new ModalNotification(NotificationLevel.ERROR, 'billing:request_failed', 'billing:request_failed'),
      );
    }
  }

  @autobind
  handleCancel() {
    this.setState({ showModal: false });
    this.props.onClosed();
  }

  @autobind
  handleOpen() {
    this.setState({ showModal: true });
  }

  @autobind
  handleClosed() {
    this.setState({ showSuccessModal: false });
    this.props.onClosed();
  }

  render() {
    return (
      <>
        <IncreaseLimitModal
          showModal={this.state.showModal}
          credits={this.state.credits}
          totalPrice={this.state.totalPrice}
          currency={this.props.billingService.billing.currency}
          onCreditsChange={this.handleCreditsChange}
          onSend={this.handleSend}
          onCancel={this.handleCancel}
        />
        <IncreaseLimitThanksModal showModal={this.state.showSuccessModal} onOk={this.handleClosed} />
      </>
    );
  }
}

export default as<React.ComponentClass<IIncreaseLimitOuterProps>>(IncreaseLimitContainer);
