import { injectable } from 'inversify';
import { observable } from 'mobx';

export const CryptoStoreType = Symbol('CRYPTO_STORE');

export interface ICryptoStore {
  workspaceCryptos: IWorkspaceCrypto[];
  get(workspaceId: string): IWorkspaceCrypto | undefined;
  set(workspaceCrypto: IWorkspaceCrypto): void;
  remove(workspaceId: string): void;
  clearAll(): void;
}

export interface IWorkspaceCrypto {
  workspaceId: string;
  checksum?: string;
  key?: CryptoKey;
  rawKey?: string;
}

@injectable()
export class CryptoStore implements ICryptoStore {
  @observable
  workspaceCryptos: IWorkspaceCrypto[] = [];

  get(workspaceId: string) {
    return this.workspaceCryptos.find(x => x.workspaceId === workspaceId);
  }

  set(workspaceCrypto: IWorkspaceCrypto) {
    const storeIndex = this.workspaceCryptos.findIndex(x => x.workspaceId === workspaceCrypto.workspaceId);
    if (storeIndex !== -1) {
      this.workspaceCryptos.splice(storeIndex, 1);
    }
    this.workspaceCryptos = [...this.workspaceCryptos, workspaceCrypto];
  }

  remove(workspaceId: string) {
    const storeIndex = this.workspaceCryptos.findIndex(x => x.workspaceId === workspaceId);
    if (storeIndex !== -1) {
      this.workspaceCryptos.splice(storeIndex, 1);
      this.workspaceCryptos = [...this.workspaceCryptos];
    }
  }

  clearAll() {
    this.workspaceCryptos = [];
  }
}
