import React, { SyntheticEvent } from 'react';

import ClassNames from 'classnames';
import { FilterTabType } from '../../imageFilters.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../../../../helpers/translations.helpers';
import { as } from '../../../../../../helpers/react.helpers';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IProps {
  disabled?: boolean;
  filterType: FilterTabType;
  labelKey: string;
  count: number;
  showClear: boolean;
  isSelected: boolean;
  onSelect(filterType: FilterTabType): void;
  onClear(): void;
}

@observer
class FilterHeaderPure extends React.Component<IProps & ITranslatable> {

  handleClearClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    this.props.onClear();
  }

  handleClick = () => {
    if (this.props.disabled) return;
    this.props.onSelect(this.props.filterType);
  }

  render() {
    return (
      <div
        onClick={this.handleClick}
        title={this.props.disabled ? this.props.t('you_do_not_have_permissions_to_use_this_filter') : ''}
        className={ClassNames({ 'image-filter-header': true, selected: this.props.isSelected, disabled: this.props.disabled })}
      >
        <span className="label">{this.props.t(this.props.labelKey)} {this.props.count > 0 && <>({this.props.count})</>}</span>
        {this.props.showClear && <span onClick={this.handleClearClick} className="clear-button">
          <FontAwesomeIcon icon={faTimes} />
        </span>}
      </div>
    );
  }
}

export const FilterHeader = as<React.ComponentClass<IProps>>(withNamespaces('common')(FilterHeaderPure));
