import * as React from 'react';

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../workspaces/currentWorkspace/CurrentWorkspace.store';
import { IRouterStore, RouterStoreType } from '../../../__legacy__/stores/router.store';
import { as, injectProps } from '../../../__legacy__/helpers/react.helpers';

import { Button } from 'reactstrap';
import { EnableForRole } from '../../../__legacy__/containers/EnableForRole';
import { ITranslatable } from '../../../__legacy__/helpers/translations.helpers';
import { PageHeader } from '../../../__legacy__/components/PageHeader';
import { ModelsTabs } from './ModelsTabs';
import { withNamespaces } from 'react-i18next';
import autobind from 'autobind-decorator';
import { Home } from '../../../__legacy__/routes/config/Home';
import { ModelImportModalContainer } from './ModelImportModal.container';
import { IModelsService, ModelsServiceType } from '../models.service';
import { ModelPermissions } from '../../../__legacy__/modules/workspaces/workspaces.store';

interface IInjectedProps extends ITranslatable {
  modelsService: IModelsService;
  currentWorkspaceStore: ICurrentWorkspaceStore;
  routerStore: IRouterStore;
}

interface IState {
  showImportModal: boolean;
}

@injectProps({
  modelsService: ModelsServiceType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  routerStore: RouterStoreType,
})
class ModelsHeaderPure extends React.Component<IInjectedProps, IState> {
  state = {
    showImportModal: false,
  };

  @autobind
  async handleTrainANewModelClicked() {
    this.props.routerStore.push(Home.Models.Create.Settings.withParams({ workspaceId: this.props.currentWorkspaceStore.currentWorkspace!.id }));
  }

  @autobind
  handleImportNewModelClicked() {
    this.setState(state => ({ showImportModal: !state.showImportModal }));
  }

  render() {
    return (
      <div>
        <PageHeader id="models-header" headerText={this.props.t('models_header')} tabNavPresent="with-tab-nav" tabNav={ModelsTabs}>
          <div className="text-right models-header-right d-flex align-items-end flex-column">
            <EnableForRole workspaceRoles={ModelPermissions.trainModel} projectRoles={[]}>
              <Button onClick={this.handleTrainANewModelClicked} color="primary">
                {this.props.t('train_new_model')}
              </Button>
            </EnableForRole>
            <EnableForRole projectRoles={[]} workspaceRoles={ModelPermissions.uploadModel}>
              <Button onClick={this.handleImportNewModelClicked} color="primary">
                {this.props.t('import_new_model')}
              </Button>
            </EnableForRole>
          </div>
        </PageHeader>
        <ModelImportModalContainer
          showModal={this.state.showImportModal}
          toggleUploadModal={this.handleImportNewModelClicked}
          importModelAsync={this.props.modelsService.importModelsAsync}
        />
      </div>
    );
  }
}

export const ModelsHeader = as<React.ComponentClass>(withNamespaces('models')(ModelsHeaderPure));
