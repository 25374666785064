import './S_PaginationResults.scss';

import { PaginationItem } from 'reactstrap';
import React from 'react';
import { S_PaginationText } from '../paginationControls/paginationText/S_PaginationText';

interface Props {
  totalCount: number;
  pageSize: number;
  pageNumber: number;
}

export const S_PaginationResults: React.FC<Props> = ({ totalCount, pageSize, pageNumber }) => {
  const startNumber = (pageNumber - 1) * pageSize + 1;
  const endNumber = Math.min(pageNumber * pageSize, totalCount);

  return (
    <PaginationItem className="pagination-result">
      <S_PaginationText text={'pagination.results'} translationOptions={{ totalCount, startNumber, endNumber }} />
    </PaginationItem>
  );
};
