import { AxiosRequestConfig } from 'axios';
import { inject, injectable } from 'inversify';
import moment from 'moment';
import { TimePeriod } from '../../../../../design/selects/timePeriodSelector/S_TimePeriodSelector';
import { StickerError } from '../../../../../__legacy__/models/error.model';
import { ApiService, ApiServiceType } from '../../../../../__legacy__/services/api.service';
import { IGeneralStatsExportModel, IAnnotationToolsStatsExportModel, IWorkPerformanceStatsExportModel } from './models/ExportProjectStatsModels';

export const ExportProjectStatsServiceType = Symbol('EXPORT_PROJECT_STATS_SERVICE');

export interface IExportProjectStatsRequest {
  projectId: string;
  timePeriod: TimePeriod;
  startDate: moment.Moment;
  endDate: moment.Moment;
  generalStats: IGeneralStatsExportModel[];
  annotationToolsStats: IAnnotationToolsStatsExportModel[];
  workPerformanceStats: IWorkPerformanceStatsExportModel[];
}

export interface IExportProjectStatsService {
  exportStatsAsync(request: IExportProjectStatsRequest): Promise<void>;
}

@injectable()
export class ExportProjectStatsService implements IExportProjectStatsService {
  constructor(@inject(ApiServiceType) private readonly apiService: ApiService) {}

  async exportStatsAsync(request: IExportProjectStatsRequest): Promise<void> {
    const config: AxiosRequestConfig = {};
    config.method = 'POST';
    config.data = request;

    const result = await this.apiService.getFileAsync('/Projects/DownloadProjectStats', config);

    if (result instanceof StickerError) throw result;
  }
}
