import { IProjectDetailsStore, ProjectDetailsStoreType } from '../../projectDetails/projectDetails.store';
import { as, injectProps } from '../../../helpers/react.helpers';

import { GlobalHotKeys } from 'react-hotkeys';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { ProjectStatus } from '../../projects/projects.model';
import React from 'react';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  projectDetailsStore: IProjectDetailsStore;
}

interface IProps {
  onSubmit(): void;
}

@injectProps({
  projectDetailsStore: ProjectDetailsStoreType,
})
@observer
class SubmitHotkeyContainerPure extends React.Component<IProps & IInjectedProps & ITranslatable> {
  render() {
    const isProjectPublished = this.props.projectDetailsStore.status === ProjectStatus.Published;
    return isProjectPublished ? <GlobalHotKeys keyMap={{ SUBMIT: { sequence: 'ctrl+shift+enter', action: 'keydown' } }} handlers={{ SUBMIT: this.props.onSubmit }} /> : <></>;
  }
}
export const SubmitHotkeyContainer = as<React.ComponentClass<IProps>>(withNamespaces('common')(SubmitHotkeyContainerPure));
