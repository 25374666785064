import { INotificationsService, NotificationsServiceType } from '../notifications/services/notifications.service';
import { IUserInfo, IUserStore, UserStoreType, IAdminUserInfo, UserRole, IPlanInfo, Plan, ListViewMode, ConsentType } from './user.store';
import { NotificationLevel, ToastNotification } from '../notifications/models/notification.model';
import { inject, injectable } from 'inversify';

import { ApiServiceType, IPagedResult } from '../../services/api.service';
import { IApiService } from '../../services/api.service.base';
import { StickerError } from '../../models/error.model';
import { action, observable } from 'mobx';
import { IBillingStore, BillingStoreType } from '../billing/billing.store';
import { WorkspacesStoreType, IWorkspacesStore, WorkspaceRole, EncryptionMode } from '../workspaces/workspaces.store';
import { ProjectRole } from '../../models/userRole.model';
import { EventBusType, EventListeningDisposer, IEventBus } from '../../services/eventBus.service';

import { UserRemovedFromProject, UserRemovedFromProjectType } from '../projectDetails/events/userRemovedFromProject';
import { UserSettingsLoadedEvent } from './events/userSettingsLoadedEvent';
import { CurrentWorkspaceChangedEvent, CurrentWorkspaceChangedEventType } from '../../../modules/workspaces/currentWorkspace/events/CurrentWorkspaceChangedEvent';
import { ICurrentWorkspaceStore, CurrentWorkspaceStoreType } from '../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { Alert } from '../../../modules/alertBar/models/Alert';
import { AlertBarStoreSetterType, IAlertBarStoreSetter } from '../../../modules/alertBar/AlertBar.store';
import { UserInfoLoadedEvent } from './events/userInfoLoadedEvent';

export const UserServiceType = Symbol('USER_SERVICE');

interface IUpdateUserDetailsPayload {
  fullName: string;
  company: string;
  country: string;
}

interface IChangePasswordPayload {
  oldPassword: string;
  newPassword: string;
}

interface IUserSettingsPayload {
  workspaceId: string;
  projectImagesAsList: boolean;
  datasetImagesAsList: boolean;
  attachmentsAsList: boolean;
  projectsAsList: boolean;
}

interface IUserInfoPayload extends IUserInfo {
  workspaces: IWorkspacePayload[];
  planInfo: IPlanInfo;
  canAddWorkspace: boolean;
  isAnyPolicyLimitExceeded: boolean;
  alerts: Alert[];
}

interface IWorkspacePayload {
  name: string;
  id: string;
  role: WorkspaceRole;
  locked: boolean;
  encryption: EncryptionMode;
  ownerAccountIsChargeable: boolean;
  ownerPlanTier: Plan;
  timeZoneOffset: number;
  isCreatedBeforeStatsRelease: boolean;
  ownerEmail: string;
}

export interface IUserPermissions {
  planAllowsDownloadImageInDataset: boolean;
  canDownloadImageInDataset: boolean;
  canDeleteAnyImageInDataset: boolean;
  canDeleteUnlockedAndUnannotatedImageInDataset: boolean;
  canEditPublishedProject: boolean;
}

export interface IUserService {
  getUserInfoAsync(): Promise<void | StickerError>;
  getUserSettingsAsync(): Promise<void | StickerError>;
  getUsers(): Promise<void | StickerError>;
  updateUserDetails(fullName: string, company: string, country: string): Promise<void | StickerError>;
  getPlansInfoAsync(pageNumber: number, pageSize: number, orderBy?: string, orderType?: string, search?: string): Promise<void | StickerError>;
  changeUserPasswordAsync(oldPassword: string, newPassword: string): Promise<void | StickerError>;
  deleteUserAsync(): Promise<void | StickerError>;
  changeUserRoleAsync(id: string, role: UserRole): Promise<void | StickerError>;
  changeUserPlanAsync(id: string, planInfo: IPlanInfo): Promise<void | StickerError>;
  getPlanDefaultsAsync(): Promise<void | StickerError>;
  addCreditsAsync(userId: string, credits: number): Promise<void | StickerError>;
  lockUserAsync(userId: string, locked: boolean): Promise<void | StickerError>;
  setProjectImageListViewMode(mode: ListViewMode): void;
  setDatasetImageListViewMode(mode: ListViewMode): void;
  setAttachmentsListViewMode(mode: ListViewMode): void;
  setProjectsListViewMode(mode: ListViewMode): void;
  changeNewsletterConsent(userId: string, isGiven: boolean): Promise<void | StickerError>;
  getUserPermissions(): IUserPermissions;
  getUserRoleInProject(projectId: string): ProjectRole | undefined;
  isInRole(projectId: string | undefined, projectRoles: ProjectRole[]): boolean;
  changeEmail(password: string, newEmail: string): Promise<{} | StickerError>;
  userListPaginationChange(pageNumber: number, pageSize: number): Promise<void>;
  userListOrderChange(orderBy: string, orderType: string): Promise<void>;
  userListSearchChange(search: string): Promise<void>;
  data: IUserStore;
  billing: IBillingStore;
}

@injectable()
export class UserService implements IUserService {
  private disposers: EventListeningDisposer[];

  constructor(
    @inject(UserStoreType) public readonly data: IUserStore,
    @inject(AlertBarStoreSetterType) public readonly alertBarStoreSetter: IAlertBarStoreSetter,
    @inject(WorkspacesStoreType) public readonly workspaceStore: IWorkspacesStore,
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore,
    @inject(BillingStoreType) public readonly billing: IBillingStore,
    @inject(ApiServiceType) private readonly apiService: IApiService,
    @inject(NotificationsServiceType) private readonly notificationsService: INotificationsService,
    @inject(EventBusType) private readonly eventBus: IEventBus,
  ) {
    this.disposers = [
      this.eventBus.addListener(this.currentWorkspaceChangedListener, CurrentWorkspaceChangedEventType),
      this.eventBus.addListener(this.userRemovedFromProjectListener, UserRemovedFromProjectType),
    ];
  }

  async userListPaginationChange(pageNumber: number, pageSize: number): Promise<void> {
    this.data.adminUsersPaging = {
      ...this.data.adminUsersPaging,
      pageNumber,
      pageSize,
    };
    await this.getUsers();
  }

  async userListOrderChange(orderBy: string, orderType: string): Promise<void> {
    this.data.adminUsersPaging = {
      ...this.data.adminUsersPaging,
      orderBy,
      orderType,
    };
    await this.getUsers();
  }

  async userListSearchChange(search: string): Promise<void> {
    this.data.adminUsersPaging = {
      ...this.data.adminUsersPaging,
      search,
      pageNumber: 1,
    };
    await this.getUsers();
  }

  cleanup(): void {
    this.disposers.forEach(d => d());
  }

  @action.bound
  async getUserInfoAsync(): Promise<void | StickerError> {
    const url = '/Users/UserInfo';
    const result = await this.apiService.getAsync<IUserInfoPayload>(url);
    if (result instanceof StickerError) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'user:get_user_info_failed'));
      return;
    }

    this.data.userInfo = result;
    this.data.planInfo = result.planInfo;
    this.billing.accountExists = result.credits.accountExists;
    this.billing.availableCredits = result.credits.availableCredits;
    this.billing.totalCredits = result.credits.totalCredits;
    this.billing.usedCredits = result.credits.usedCredits;
    this.billing.isAnyPolicyLimitExceeded = result.isAnyPolicyLimitExceeded;
    this.billing.imagesAmount = result.credits.imagesAmount;
    this.billing.usedSpace = result.credits.usedSpace;
    this.billing.workspaces = result.credits.workspaces;
    this.billing.workspaceWorkers = result.credits.workspaceWorkers;
    this.billing.workspaceUsers = result.credits.workspaceUsers;
    this.workspaceStore.workspaces = result.workspaces;
    this.workspaceStore.canAddWorkspace = result.canAddWorkspace;
    this.alertBarStoreSetter.setAlerts(result.alerts);

    this.eventBus.sendEvent(new UserInfoLoadedEvent());
  }

  @action
  async getUserSettingsAsync(): Promise<void | StickerError> {
    const url = '/Users/UserSettings';
    const result = await this.apiService.getAsync<IUserSettingsPayload>(url);
    if (result instanceof StickerError) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'user:get_user_settings_failed'));
      return;
    }

    if (result) {
      if (result.workspaceId) {
        this.workspaceStore.setPreferredWorkspace(result.workspaceId);
      }
      this.data.projectImageListViewMode = result.projectImagesAsList ? ListViewMode.List : ListViewMode.Tiles;
      this.data.datasetImageListViewMode = result.datasetImagesAsList ? ListViewMode.List : ListViewMode.Tiles;
      this.data.attachmentsListViewMode = result.attachmentsAsList ? ListViewMode.List : ListViewMode.Tiles;
      this.data.projectListViewMode = result.projectsAsList ? ListViewMode.List : ListViewMode.Tiles;
    }

    this.eventBus.sendEvent(new UserSettingsLoadedEvent());
  }

  @action
  async getUsers(): Promise<void | StickerError> {
    const { pageNumber, pageSize, orderBy, orderType, search } = this.data.adminUsersPaging;
    const url = `/Users/GetUsers?pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&orderType=${orderType}&search=${search ?? ''}`;
    const result = await this.apiService.getAsync<IPagedResult<IAdminUserInfo>>(url);
    if (result instanceof StickerError) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'user:get_user_admin_info_failed'));
      return;
    }

    this.data.adminUserInfo = result.data;
    this.data.adminUsersPaging = {
      ...this.data.adminUsersPaging,
      totalCount: result.totalCount,
    };
  }

  @action
  async getPlansInfoAsync(pageNumber: number, pageSize: number, orderBy?: string, orderType?: string, search?: string): Promise<void | StickerError> {
    const url = `/Users/GetPlans?pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&orderType=${orderType}&search=${search}`;
    const result = await this.apiService.getAsync<IPagedResult<IPlanInfo>>(url);
    if (result instanceof StickerError) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'user:get_plans_info_failed'));
      return;
    }

    this.data.plansPaging = {
      search,
      pageSize,
      orderBy: orderBy || '',
      orderType: orderType || '',
      pageNumber: result.pageNumber,
      pagesCount: result.pagesCount,
      totalCount: result.totalCount,
    };

    this.data.plansInfo = observable.array(result.data, { deep: true });
  }

  @action
  async updateUserDetails(fullName: string, company: string, country: string) {
    const url = '/Users/UpdateDetails';
    const result = await this.apiService.postAsync<IUpdateUserDetailsPayload>(url, { fullName, company, country });

    if (!(result instanceof StickerError)) {
      this.data.userInfo.fullName = fullName;
      this.data.userInfo.company = company;
      this.data.userInfo.country = country;
    }
  }

  @action
  async changeUserPasswordAsync(oldPassword: string, newPassword: string) {
    const url = '/Authorization/ChangePassword';
    return this.apiService.postAsync<IChangePasswordPayload, void>(url, { oldPassword, newPassword });
  }

  @action
  async deleteUserAsync() {
    const url = '/Authorization/DeleteUser';
    const result = await this.apiService.postAsync<{}, void>(url, {});
    if (result instanceof StickerError) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'user:delete_user_failed'));
    }
    return result;
  }

  @action
  async changeUserRoleAsync(id: string, role: UserRole) {
    const url = '/Users/changeRole';
    const result = await this.apiService.postAsync<{ id: string; role: string }>(url, { id, role: role.toString() });

    if (!(result instanceof StickerError)) {
      const userIndex = this.data.adminUserInfo.findIndex(u => u.id === id);

      if (userIndex !== -1) {
        const newArray = this.data.adminUserInfo.slice();
        newArray[userIndex].role = role;
        if (role === UserRole.User) {
          newArray[userIndex].plan = Plan.Starter;
          newArray[userIndex].customLimits = false;
        }
        this.data.adminUserInfo = [];
        this.data.adminUserInfo = newArray;
      }
    }
  }

  @action
  async changeUserPlanAsync(id: string, plan: IPlanInfo): Promise<void | StickerError> {
    const url = '/Users/ChangePlan';
    const result = await this.apiService.postAsync(url, plan);

    if (!(result instanceof StickerError)) {
      const userIndex = this.data.adminUserInfo.findIndex(u => u.id === id);

      if (userIndex !== -1) {
        const newArray = this.data.adminUserInfo.slice();
        Object.assign(newArray[userIndex], plan);
        this.data.adminUserInfo = [];
        this.data.adminUserInfo = newArray;
      }
    }
  }

  @action
  async getPlanDefaultsAsync(): Promise<void | StickerError> {
    const url = '/Users/PlanDefaults';
    const result = await this.apiService.getAsync<IPlanInfo[]>(url);

    if (!(result instanceof StickerError)) {
      this.data.planDefaults = result;
    }
  }

  @action
  async addCreditsAsync(userId: string, credits: number): Promise<void | StickerError> {
    const url = '/Billing/RechargeAccountManually';
    const result = await this.apiService.postAsync(url, { userId, credits });

    if (!(result instanceof StickerError)) {
      const userIndex = this.data.adminUserInfo.findIndex(u => u.id === userId);

      if (userIndex !== -1) {
        const newArray = this.data.adminUserInfo.slice();
        newArray[userIndex].availableCredits += credits;
        this.data.adminUserInfo = [];
        this.data.adminUserInfo = newArray;
      }
    }
  }

  @action
  async lockUserAsync(userId: string, locked: boolean): Promise<void | StickerError> {
    const url = '/Users/Lock';
    const result = await this.apiService.postAsync(url, { userId, locked });

    if (!(result instanceof StickerError)) {
      const userIndex = this.data.adminUserInfo.findIndex(u => u.id === userId);

      if (userIndex !== -1) {
        const newArray = this.data.adminUserInfo.slice();
        newArray[userIndex].isActive = !locked;
        this.data.adminUserInfo = [];
        this.data.adminUserInfo = newArray;
      }
    }
  }

  @action
  async updateUserSettings(): Promise<void | StickerError> {
    const url = '/Users/UpdateUserSettings';
    await this.apiService.postAsync<IUserSettingsPayload>(url, {
      workspaceId: this.currentWorkspaceStore.currentWorkspace?.id ?? '',
      projectImagesAsList: this.data.projectImageListViewMode === ListViewMode.List,
      datasetImagesAsList: this.data.datasetImageListViewMode === ListViewMode.List,
      attachmentsAsList: this.data.attachmentsListViewMode === ListViewMode.List,
      projectsAsList: this.data.projectListViewMode === ListViewMode.List,
    });
  }

  @action
  setProjectImageListViewMode(mode: ListViewMode) {
    this.data.projectImageListViewMode = mode;
    this.updateUserSettings();
  }

  @action
  setDatasetImageListViewMode(mode: ListViewMode) {
    this.data.datasetImageListViewMode = mode;
    this.updateUserSettings();
  }

  @action
  setAttachmentsListViewMode(mode: ListViewMode) {
    this.data.attachmentsListViewMode = mode;
    this.updateUserSettings();
  }

  setProjectsListViewMode(mode: ListViewMode): void {
    this.data.projectListViewMode = mode;
    this.updateUserSettings();
  }

  getUserPermissions(): IUserPermissions {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    return {
      planAllowsDownloadImageInDataset: this.currentWorkspaceStore.currentWorkspace?.ownerPlanTier !== Plan.Starter,
      canDownloadImageInDataset: [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Trainer].includes(currentWorkspaceRole),
      canDeleteAnyImageInDataset: [WorkspaceRole.Owner, WorkspaceRole.Manager].includes(currentWorkspaceRole),
      canDeleteUnlockedAndUnannotatedImageInDataset: [WorkspaceRole.Collaborator, WorkspaceRole.Developer, WorkspaceRole.Trainer].includes(currentWorkspaceRole),
      canEditPublishedProject: [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Trainer].includes(currentWorkspaceRole),
    };
  }

  async changeNewsletterConsent(userId: string, isGiven: boolean): Promise<void | StickerError> {
    const url = '/Users/ChangeNewsletterConsent';
    const result = await this.apiService.postAsync(url, { userId, isGiven });

    if (!(result instanceof StickerError)) {
      const consent = this.data.userInfo.consents.find(x => x.type === ConsentType.Newsletter);

      if (consent) {
        consent.isGiven = isGiven;
      }
    }
  }

  getUserRoleInProject(projectId: string): ProjectRole | undefined {
    const projectRole = this.data.userInfo.projectRoles.find(p => p.projectId === projectId);
    return projectRole && projectRole.role;
  }

  isInRole(projectId: string | undefined, projectRoles: ProjectRole[]) {
    if (!projectId) {
      return false;
    }
    const userRoleInProject = this.getUserRoleInProject(projectId);
    return !!userRoleInProject && projectRoles.includes(userRoleInProject);
  }

  async changeEmail(password: string, email: string) {
    const url = '/Authorization/RequestChangeEmail';
    const result = await this.apiService.postAsync(url, { password, email });

    if (!(result instanceof StickerError)) {
      this.data.userInfo.emailChangePending = true;
    }

    return result;
  }

  private currentWorkspaceChangedListener = (e: CurrentWorkspaceChangedEvent) => {
    if (this.workspaceStore.preferredWorkspaceId === this.currentWorkspaceStore.currentWorkspace?.id) return;
    this.updateUserSettings();
  };
  private userRemovedFromProjectListener = (e: UserRemovedFromProject) => this.getUserInfoAsync();
}
