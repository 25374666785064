import * as React from 'react';

import { Alert, Button, Card, CardBody, Form } from 'reactstrap';

import { ITranslatable } from '../../../helpers/translations.helpers';
import { InputStatus } from '../../../models/error.model';
import { ValidatedTextInput } from '../../../components/ValidatedTextInput';
import { as } from '../../../helpers/react.helpers';
import { formAlertWithTranslations } from '../../../components/FormAlert';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IResetPasswordRequestFormProps {
  handleEmailChange(value: string): void;
  validateEmail(value: string): void;
  onRequestPasswordReset(): void;
  isDisabledSubmit: boolean;
  emailStatus: InputStatus;
  externalStatus: InputStatus;
}

@observer
class ResetPasswordRequestFormPure extends React.Component<IResetPasswordRequestFormProps & ITranslatable> {
  render() {
    const validationErrorOccured =
      this.props.externalStatus.isValid === false || this.props.emailStatus.isValid === false;

    const Errors = formAlertWithTranslations('auth');

    return (
      <Card>
        <CardBody>
          {this.props.externalStatus.isValid && (
            <Alert color="success">{this.props.t('password_reset_email_sent')}</Alert>
          )}
          <Errors errors={this.props.externalStatus} useDefaultMessageWhenCodeNotFound={true} />
          <Form>
            <ValidatedTextInput
              labelText={this.props.t('email_address')}
              type={'text'}
              placeholder={this.props.t('enter_email')}
              onChange={this.props.handleEmailChange}
              onBlur={this.props.validateEmail}
              onPressEnter={this.props.onRequestPasswordReset}
              isValid={this.props.emailStatus.isValid}
              feedbacks={this.props.emailStatus.errorCodes.map(this.props.t)}
            />
            <Button
              block
              color="primary"
              onClick={this.props.onRequestPasswordReset}
              disabled={validationErrorOccured || this.props.isDisabledSubmit}
            >
              {this.props.t('send_reset_password_request')}
            </Button>
          </Form>
        </CardBody>
      </Card>
    );
  }
}
export const ResetPasswordRequestForm = as<React.StatelessComponent<IResetPasswordRequestFormProps>>(withNamespaces('auth')(ResetPasswordRequestFormPure));
