import { CustomInput, FormGroup, Label } from 'reactstrap';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import { IWorker } from '../../ProjectStatsFilters.store';
import { Picky } from 'react-picky';
import React from 'react';
import { RenderSelectAllProps } from 'react-picky/dist/types';
import { S_SelectFieldOption } from '../../../../../../../design/fields/selectField/S_SelectField';
import { as } from '../../../../../../../common/dependencyInjection/as';

interface IProps {
  availableWorkers: IWorker[];
  selectedWorkers: IWorker[];
  onWorkersChange(workers: IWorker[]): void;
}

const WorkerSelectorPure: React.FC<IProps & WithNamespaces> = (props: IProps & WithNamespaces) => {
  const { availableWorkers, selectedWorkers, t } = props;

  const renderOption = ({ style, isSelected, item, selectValue, labelKey, valueKey }: any) => {
    const onClick = (e: any) => {
      selectValue(item);
      e.preventDefault();
    };
    return (
      <li style={style} className={`picky-option ${isSelected ? 'selected' : 'not-selected'}`} key={`${item[valueKey]}-li`} onClick={onClick}>
        <CustomInput id={`${item[valueKey]}-custom-input`} key={item[valueKey]} type="checkbox" checked={isSelected} label={item[labelKey]} disabled={false} readOnly={true} />
      </li>
    );
  };

  const onWorkersChange = (workers: any) => {
    props.onWorkersChange(workers.map((w: any) => ({ id: w.value, email: w.label })));
  };

  const renderSelectAll = ({ filtered, allSelected, toggleSelectAll, multiple }: RenderSelectAllProps) => {
    if (multiple && !filtered) {
      return (
        <div className="picky-select-all">
          <CustomInput id="select-all" type="checkbox" checked={allSelected === 'all'} label={t('select_all')} disabled={false} readOnly={true} onClick={toggleSelectAll} />
        </div>
      );
    }
  };

  const options: S_SelectFieldOption<string>[] = availableWorkers.map(w => ({ label: w.email, value: w.id }));
  const selectedOptions: S_SelectFieldOption<string>[] = selectedWorkers.map(w => ({ label: w.email, value: w.id }));
  return (
    <FormGroup className="worker-selector">
      <Label>{t('projects.details.stats.filters.email')}</Label>
      <Picky
        id="picky"
        options={options}
        value={selectedOptions}
        multiple={true}
        includeSelectAll={true}
        includeFilter={false}
        onChange={onWorkersChange}
        dropdownHeight={600}
        labelKey="label"
        valueKey="value"
        render={renderOption}
        renderSelectAll={renderSelectAll}
        numberDisplayed={0}
      />
    </FormGroup>
  );
};

export const WorkerSelector = as<React.ComponentClass<IProps>>(withNamespaces('new')(WorkerSelectorPure));
