import { ILoaderState, WithLoaderComponentBase } from '../../../../../../helpers/loader.helpers';

import { ISavedFilter } from '../../imageFilters.model';
import { InputStatus } from '../../../../../../models/error.model';
import React from 'react';
import { SaveFilters } from '../../components/filters/SaveFilters';

const FadeOutDelay = 1500;

interface IProps {
  savedFilters: ISavedFilter[];
  onSavedFilterLoad(savedFilter: ISavedFilter): void;
  onSaveFilterAsync(name: string): Promise<void>;
  onFilterDeleteAsync(id: string): Promise<void>;
}

interface IState extends ILoaderState {
  isNewFilterNameUsed: boolean;
  showDeleteConfirmation: boolean;
  newFilterName: string;
  newFilterNameStatus: InputStatus;
  selectedFilter: ISavedFilter | undefined;
  showFilterLoadedMessage: boolean;
  showFilterSavedMessage: boolean;
}

export class SaveFiltersContainer extends WithLoaderComponentBase<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isLoading: false,
      newFilterName: '',
      isNewFilterNameUsed: false,
      showDeleteConfirmation: false,
      selectedFilter: undefined,
      showFilterLoadedMessage: false,
      showFilterSavedMessage: false,
      newFilterNameStatus: InputStatus.empty(),
    };
  }

  handleNewFilterNameChange = (name: string) => {
    this.setState({
      newFilterName: name,
      newFilterNameStatus: InputStatus.empty(),
      isNewFilterNameUsed: false,
      showDeleteConfirmation: false,
    });
  }

  handleNewFilterNameBlur = () => {
    this.setState({
      newFilterNameStatus: !this.state.newFilterName ? InputStatus.empty() : InputStatus.valid(),
      showDeleteConfirmation: false,
      isNewFilterNameUsed: false,
    });
  }

  handleFilterSelected = (selectedFilter?: ISavedFilter) => {
    this.setState({
      selectedFilter,
      showDeleteConfirmation: false,
      newFilterNameStatus: InputStatus.empty(),
      isNewFilterNameUsed: false,
    });
  }

  handleSaveFilterAsync = async () => {
    await this.withLoaderAsync(async () => {
      if (this.props.savedFilters.some(x => x.name === this.state.newFilterName)) {
        this.setState({ isNewFilterNameUsed: true, newFilterNameStatus: InputStatus.invalid() });
      } else {
        await this.handleSaveConfirmedAsync();
      }
    });
  }

  handleSaveConfirmedAsync = async () => {
    await this.withLoaderAsync(async () => {
      await this.props.onSaveFilterAsync(this.state.newFilterName);
      this.setState({ isNewFilterNameUsed: false, newFilterName: '', newFilterNameStatus: InputStatus.empty(), showFilterSavedMessage: true });

      setTimeout(() => { this.setState({ showFilterSavedMessage: false }); }, FadeOutDelay);
    });
  }

  handleFilterOverrideDeclined = () => {
    this.setState({ isNewFilterNameUsed: false, newFilterName: '', newFilterNameStatus: InputStatus.empty() });
  }

  handleSelectedFilterDelete = () => {
    this.setState({ showDeleteConfirmation: true });
  }

  handleDeleteConfirmedAsync = async () => {
    await this.withLoaderAsync(async () => {
      if (this.state.selectedFilter) {
        await this.props.onFilterDeleteAsync(this.state.selectedFilter.id);
        this.setState({ showDeleteConfirmation: false, selectedFilter: undefined });
      }
    });
  }

  handleDeleteDeclined = () => {
    this.setState({ showDeleteConfirmation: false, selectedFilter: undefined });
  }

  handleSelectedFilterLoadAsync = async () => {
    await this.withLoaderAsync(async () => {
      if (this.state.selectedFilter) {
        this.props.onSavedFilterLoad(this.state.selectedFilter);
        this.setState({ selectedFilter: undefined, showFilterLoadedMessage: true });

        setTimeout(() => { this.setState({ showFilterLoadedMessage: false }); }, FadeOutDelay);
      }
    });
  }

  render() {
    return (
      <SaveFilters
        isLoading={this.state.isLoading}
        showDeleteConfirmation={this.state.showDeleteConfirmation}
        newFilterName={this.state.newFilterName}
        savedFilters={this.props.savedFilters}
        selectedFilter={this.state.selectedFilter}
        isNewFilterNameUsed={this.state.isNewFilterNameUsed}
        newFilterNameStatus={this.state.newFilterNameStatus}
        showFilterLoadedMessage={this.state.showFilterLoadedMessage}
        showFilterSavedMessage={this.state.showFilterSavedMessage}
        onNewFilterNameChange={this.handleNewFilterNameChange}
        onNewFilterNameBlur={this.handleNewFilterNameBlur}
        onFilterSave={this.handleSaveFilterAsync}
        onFilterSelected={this.handleFilterSelected}
        onSelectedFilterDelete={this.handleSelectedFilterDelete}
        onSelectedFilterLoad={this.handleSelectedFilterLoadAsync}
        onFilterOverrideConfirmed={this.handleSaveConfirmedAsync}
        onFilterOverrideDeclined={this.handleFilterOverrideDeclined}
        onFilterDeleteConfirmed={this.handleDeleteConfirmedAsync}
        onFilterDeleteDeclined={this.handleDeleteDeclined}
      />
    );
  }
}
