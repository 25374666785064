import { inject, injectable } from 'inversify';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { WorkspaceRole } from '../../../workspaces/workspaces.store';

export const ClarificationsPermissionsType = Symbol('CLARIFICATIONS_PERMISSIONS_TYPE');

export interface IClarificationsPermissions {
  canClarify(): boolean;
  canSeePersonalData(): boolean;
}

@injectable()
export class ClarificationsPermissions implements IClarificationsPermissions {
  constructor(@inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore) {}

  canClarify(): boolean {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    return [WorkspaceRole.Owner, WorkspaceRole.Manager].includes(currentWorkspaceRole);
  }

  canSeePersonalData(): boolean {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    return [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Trainer].includes(currentWorkspaceRole);
  }
}
