import { withNamespaces } from 'react-i18next';
import { Form, FormGroup, Label } from 'reactstrap';
import React from 'react';
import { ITranslatable } from '../../../__legacy__/helpers/translations.helpers';
import { ValidatedTextInput } from '../../../__legacy__/components/ValidatedTextInput';
import { as } from '../../../__legacy__/helpers/react.helpers';
import { IModelDetailsValidationErrors, IModelProject } from '../modelDetails.store';
import { ValueType } from 'react-select/lib/types';
import { SelectOption } from '../modelDetails.model';
import { MODEL_STATUS } from '../../models/models.model';
import { ValidatedSelectInput } from '../../../__legacy__/components/ValidatedSelectInput';
import { MODEL_VARIANTS, MODEL_VARIANTS_LIST } from '../../../__legacy__/models/metrics.model';

interface IProps {
  name: string;
  description: string;
  projectId: string;
  variant: MODEL_VARIANTS;
  projects: IModelProject[];
  onChangeProject: (project: ValueType<SelectOption>) => void;
  onChangeName: (name: string) => void;
  onChangeDescription: (description: string) => void;
  status: MODEL_STATUS;
  handleBlurName(name: string): void;
  handleBlurProject(projectId: string): void;
  handleBlurDescription(description: string): void;
  errors: IModelDetailsValidationErrors;
  isFormDisabled: boolean;
  onChangeVariant: (value: ValueType<SelectOption>) => void;
  handleBlurVariant: (modelVariant: MODEL_VARIANTS) => void;
}

const ModelDetailsInfoFormPure = (props: IProps & ITranslatable) => {
  const {
    t,
    handleBlurProject,
    name,
    description,
    projects,
    status,
    projectId,
    errors,
    variant,
    handleBlurName,
    onChangeProject,
    onChangeName,
    onChangeDescription,
    handleBlurDescription,
    isFormDisabled,
    handleBlurVariant,
    onChangeVariant,
  } = props;

  const isDisabled = isFormDisabled || (status !== MODEL_STATUS.DRAFT && status !== MODEL_STATUS.STOPPED && status !== MODEL_STATUS.FAILED);

  const validationError = (label: string): { message: string } | null | undefined => errors.header.get(label);
  const isFieldValidated = (label: string) => validationError(label) !== undefined || errors.showAll;
  const hasError = (label: string) => validationError(label) != null;

  const onBlurSelect = (callback: (param: string) => void, param: string) => () => callback(param);

  const onBlurVariant = (callback: (param: MODEL_VARIANTS) => void, param: MODEL_VARIANTS) => () => callback(param);

  return (
    <Form>
      <ValidatedTextInput
        id="model-name-textbox"
        type="text"
        placeholder={t('type_your_model_name_here')}
        value={name}
        onChange={onChangeName}
        disabled={isDisabled}
        onBlur={handleBlurName}
        isValid={isFieldValidated('name') ? !hasError('name') : undefined}
        feedbacks={hasError('name') ? [validationError('name')!.message] : []}
        feedbacksParams={hasError('name') ? { name: t('fields.model_name') } : []}
      />
      <ValidatedTextInput
        id="model-name-textbox"
        type="textarea"
        placeholder={t('type_your_model_description_here')}
        value={description}
        onChange={onChangeDescription}
        onBlur={handleBlurDescription}
        disabled={isDisabled}
        isValid={isFieldValidated('description') ? !hasError('description') : undefined}
      />
      <FormGroup>
        <Label>{t('type')}</Label>
        <ValidatedSelectInput
          id="type-dropdown"
          value={{ label: t(`model_variants.${variant}`), value: variant }}
          options={MODEL_VARIANTS_LIST.map(item => ({ label: t(`model_variants.${item}`), value: item }))}
          onChange={onChangeVariant}
          onBlur={onBlurVariant(handleBlurVariant, variant)}
          disabled={isDisabled}
          isValid={isFieldValidated('variant') ? !hasError('variant') : undefined}
          feedbacks={hasError('variant') ? [validationError('variant')!.message] : []}
          feedbackParams={hasError('variant') ? { name: t('fields.variant') } : []}
        />
      </FormGroup>
      <FormGroup>
        <Label>{t('project')}</Label>
        <ValidatedSelectInput
          id="project-dropdown"
          value={projects.find(p => p.value === projectId) || null}
          options={projects}
          onChange={onChangeProject}
          onBlur={onBlurSelect(handleBlurProject, projectId)}
          disabled={isDisabled}
          isValid={isFieldValidated('project') ? !hasError('project') : undefined}
          feedbacks={hasError('project') ? [validationError('project')!.message] : []}
          feedbackParams={hasError('project') ? { name: t('fields.project') } : []}
        />
      </FormGroup>
    </Form>
  );
};

export const ModelDetailsInfoForm = as<React.FC<IProps>>(withNamespaces('models')(ModelDetailsInfoFormPure));
