import { IPagingInfoWithOrder, PaginationInfoDefault } from '../../../../models/paginationInfo.model';
import { IProjectActivity, ProjectActivity, allProjectActivities } from './projectDetailsActivities.models';

import { InputStatus } from '../../../../models/error.model';
import { injectable } from 'inversify';
import { observable } from 'mobx';

export const ProjectDetailsActivitiesStoreType = Symbol('PROJECT_DETAILS_ACTIVITIES_STORE');

export interface IProjectDetailsActivitiesStore {
  projectActivities: IProjectActivity<any>[];
  showLoader: boolean;
  projectActivitiesPaging: IPagingInfoWithOrder;
  selectedProjectActivities: ProjectActivity[];
  activitylogStatus: InputStatus;
}

@injectable()
export class ProjectDetailsActivitiesStore implements IProjectDetailsActivitiesStore {
  @observable.shallow
  projectActivities: IProjectActivity<any>[] = [];

  @observable
  showLoader: boolean = false;

  @observable
  selectedProjectActivities = [...allProjectActivities];

  @observable
  projectActivitiesPaging: IPagingInfoWithOrder = {
    ...PaginationInfoDefault,
    orderBy: 'createdDate',
    orderType: 'desc',
  };

  @observable
  activitylogStatus: InputStatus = InputStatus.empty();
}
