import { injectable, inject } from 'inversify';
import { ApiServiceType } from '../../../../../services/api.service';
import { StickerError } from '../../../../../models/error.model';
import { IApiService } from '../../../../../services/api.service.base';
import { QuestionType } from '../../../../annotation/question.model';
import { IAnnotationType, IAnswer, IQuestion, IToolAffect, IToolUsage } from '../projectDetailsTools.models';
import { ProjectStatus } from '../../../../projects/projects.model';
import qs from 'qs';
import { ToolDeleteStrategy } from '../../../projectDetails.models';

export const QuestionsApiServiceType = Symbol('PROJECT_DETAILS_QUESTIONS_API_SERVICE');

export interface IAnnotationViewTabDto {
  annotationTypes: IAnnotationType[];
  questions: IQuestion[];
  status: ProjectStatus;
}

export interface IAddQuestionRequest {
  projectId: string;
  questionId: string;
  text: string;
  answerType: QuestionType;
  isRequired: boolean;
  answers: IAnswer[];
  scopes: string[];
}

export interface IUpdateQuestionRequest {
  projectId: string;
  questionId: string;
  questionType: string;
  text: string;
  isRequired: boolean;
  answers: IAnswer[];
  scopes: string[];
  order: number;
  strategy: ToolDeleteStrategy;
}

export interface IUpdateQuestionsOrderingRequest {
  projectId: string;
  orders: { id: string, order: number }[];
}

export interface IGetQuestionUsageRequest {
  questionId: string;
}

export interface IGetQuestionAnswersUsageRequest {
  questionId: string;
  answerIds: string[];
  scopesIds: string[];
}

export interface IDeleteQuestionRequest {
  projectId: string;
  questionId: string;
  strategy: ToolDeleteStrategy;
}

export interface ICheckQuestionTextUniquenessRequest {
  projectId: string;
  questionId: string;
  isAttribute: boolean;
  text: string;
}

export interface ICheckQuestionAnswerTextUniquenessRequest {
  answerId: string;
  questionId: string;
  text: string;
}

export interface IGetProjectDetailsAnnotationViewRequest {
  projectId: string;
}

export interface IGetQuestionAffectRequest {
  questionId: string;
}

export interface IGetQuestionAnswersAffectRequest {
  questionId: string;
  answerIds: string[];
  scopesIds: string[];
}

export interface IQuestionsApiService {
  addQuestionAsync(request: IAddQuestionRequest): Promise<void | StickerError>;
  updateQuestionAsync(request: IUpdateQuestionRequest): Promise<void | StickerError>;
  updateQuestionsOrderAsync(request: IUpdateQuestionsOrderingRequest): Promise<void | StickerError>;
  getQuestionUsage(request: IGetQuestionUsageRequest): Promise<IToolUsage | StickerError>;
  getQuestionAnswersUsage(request: IGetQuestionAnswersUsageRequest): Promise<IToolUsage | StickerError>;
  deleteQuestionAsync(request: IDeleteQuestionRequest): Promise<void | StickerError>;
  checkQuestionTextUniqueness(request: ICheckQuestionTextUniquenessRequest): Promise<boolean | StickerError>;
  getQuestionAffect(request: IGetQuestionAffectRequest): Promise<IToolAffect | StickerError>;
  getQuestionAnswersAffect(request: IGetQuestionAnswersAffectRequest): Promise<IToolAffect | StickerError>;

  getProjectDetailsAnnotationViewAsync(request: IGetProjectDetailsAnnotationViewRequest): Promise<IAnnotationViewTabDto | StickerError>;
}

@injectable()
export class QuestionsApiService implements IQuestionsApiService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService) { }

  async addQuestionAsync(request: IAddQuestionRequest): Promise<void | StickerError> {
    return this.apiService.postAsync<IAddQuestionRequest, void>('/Questions/Add', request);
  }

  async updateQuestionAsync(request: IUpdateQuestionRequest): Promise<void | StickerError> {
    return this.apiService.postAsync<IUpdateQuestionRequest, void>('/Questions/Update', request);
  }

  async updateQuestionsOrderAsync(request: IUpdateQuestionsOrderingRequest): Promise<void | StickerError> {
    return this.apiService.postAsync<IUpdateQuestionsOrderingRequest, void>('/Questions/UpdateQuestionsOrdering', request);
  }

  async getQuestionUsage(request: IGetQuestionUsageRequest): Promise<IToolUsage | StickerError> {
    return this.apiService.getAsync<IToolUsage>('/Questions/GetUsage', { params: request });
  }

  async getQuestionAnswersUsage(request: IGetQuestionAnswersUsageRequest): Promise<IToolUsage | StickerError> {
    return this.apiService.getAsync<IToolUsage>('/Questions/GetAnswersUsage', { params: request, paramsSerializer: (params: any) => qs.stringify(params) });
  }

  async deleteQuestionAsync(request: IDeleteQuestionRequest): Promise<void | StickerError> {
    return this.apiService.postAsync<IDeleteQuestionRequest, void>('/Questions/Delete', request);
  }

  async checkQuestionTextUniqueness(request: ICheckQuestionTextUniquenessRequest): Promise<boolean | StickerError> {
    return this.apiService.getAsync<boolean>('/Questions/CheckQuestionTextUniqueness', { params: request });
  }

  async getProjectDetailsAnnotationViewAsync(request: IGetProjectDetailsAnnotationViewRequest): Promise<IAnnotationViewTabDto | StickerError> {
    return this.apiService.getAsync<IAnnotationViewTabDto>('/Projects/AnnotationViewTab', { params: request });
  }

  async getQuestionAffect(request: IGetQuestionAffectRequest): Promise<IToolAffect | StickerError> {
    return this.apiService.getAsync<IToolAffect>('/Questions/GetAffect', { params: request });
  }

  async getQuestionAnswersAffect(request: IGetQuestionAnswersAffectRequest): Promise<IToolAffect | StickerError> {
    return this.apiService.getAsync<IToolAffect>('/Questions/GetAnswersAffect', { params: request, paramsSerializer: (params: any) => qs.stringify(params) });
  }
}
