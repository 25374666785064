import * as React from 'react';

import { AuthServiceType, IAuthService } from '../auth.service';
import { AuthStoreType, IAuthStore } from '../auth.store';
import { INotificationsService, NotificationsServiceType } from '../../notifications/services/notifications.service';
import { IWorkspacesStore, WorkspacesStoreType } from '../../workspaces/workspaces.store';
import { InputStatus, StickerError } from '../../../models/error.model';
import { NotificationLevel, ToastNotification } from '../../notifications/models/notification.model';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { Auth } from '../../../routes/config/Auth';
import { AuthLogo } from '../components/AuthLogo';
import { GLOBAL_ERROR_CODES } from '../../../helpers/global.constants';
import { PasswordWithScoreInputsContainer } from './PasswordWithScoreInputsContainer';
import { ResetPasswordForm } from '../components/ResetPasswordForm';
import autobind from 'autobind-decorator';

interface IInjectedProps extends RouteComponentProps<{ token: string }> {
  authService: IAuthService;
  authStore: IAuthStore;
  notificationService: INotificationsService;
  workspaceStore: IWorkspacesStore;
}

interface IState {
  password: string;
  passwordStatus: InputStatus;
  externalStatus: InputStatus;
  isRequestPending: boolean;
  passwordControlRef: React.RefObject<PasswordWithScoreInputsContainer>;
}

@injectProps({
  authService: AuthServiceType,
  authStore: AuthStoreType,
  notificationService: NotificationsServiceType,
  workspaceStore: WorkspacesStoreType,
})
@autobind
class ResetPasswordContainerPure extends React.Component<IInjectedProps, IState> {
  state: IState = {
    password: '',
    passwordStatus: InputStatus.empty(),
    externalStatus: InputStatus.empty(),
    isRequestPending: false,
    passwordControlRef: React.createRef(),
  };

  handlePasswordChange = (value: string) => {
    this.setState({
      password: value,
    });
  };

  handlePasswordStatusChange = (status: InputStatus) => {
    this.setState({
      passwordStatus: status,
    });
  };

  async handleResetPassword() {
    const isPasswordValid = this.state.passwordControlRef.current && this.state.passwordControlRef.current.validate();

    if (!isPasswordValid) {
      return;
    }

    this.setState({ isRequestPending: true });

    const result = await this.props.authService.resetPassword(this.props.match.params.token, this.state.password);

    if (result instanceof StickerError) {
      const errorCodes: string[] = result.apiErrorResponse
        ? result.apiErrorResponse.errorCodes
        : [GLOBAL_ERROR_CODES.FATAL_EXCEPTION];

      this.setState({
        externalStatus: new InputStatus(false, errorCodes),
        isRequestPending: false,
      });
      return;
    }

    this.props.notificationService.push(
      new ToastNotification(NotificationLevel.SUCCESS, 'auth:your_password_reset_succesfully'),
    );

    this.props.history.replace(Auth.Login.Path);
  }

  render() {
    if (this.props.authStore.isAuthenticated) {
      return <Redirect to={this.props.authService.getAndClearReferrer()} />;
    }

    return (
      <>
        <AuthLogo />
        <ResetPasswordForm
          onPasswordChange={this.handlePasswordChange}
          onPasswordStatusChange={this.handlePasswordStatusChange}
          onResetPassword={this.handleResetPassword}
          passwordStatus={this.state.passwordStatus}
          externalStatus={this.state.externalStatus}
          isDisabledSubmit={this.state.isRequestPending}
          passwordControlRef={this.state.passwordControlRef}
        />
      </>
    );
  }
}

export const ResetPasswordContainer = as<React.ComponentClass>(withRouter(ResetPasswordContainerPure));
