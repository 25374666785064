export enum AnnotationToolType {
  SELECT = 'Select',
  POINT = 'Point',
  POLYGON = 'Polygon',
  RECTANGLE = 'Rectangle',
  VECTOR = 'Vector',
  POLYLINE = 'Polyline',
  ROTATEDRECTANGLE = 'RotatedRectangle',
  BRUSH = 'Brush',
}
