import { ITeamService, TeamServiceType } from '../team.bl';
import { ITeamStore, TeamStoreType } from '../team.store';
import { S_TimePeriodSelector, TimePeriod } from '../../../../design/selects/timePeriodSelector/S_TimePeriodSelector';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { as, injectProps } from '../../../helpers/react.helpers';

import React from 'react';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectProps {
  teamStore: ITeamStore;
  teamService: ITeamService;
}

@injectProps({
  teamStore: TeamStoreType,
  teamService: TeamServiceType,
})
@observer
export class StatisticsTimeFrameSwitchContainerPure extends React.Component<IInjectProps & WithNamespaces> {
  @autobind
  handleTimePeriodChange(timePeriod: TimePeriod) {
    this.props.teamService.changeStatisticsTimePeriodAsync(timePeriod);
  }

  render() {
    const { statisticsTimePeriod } = this.props.teamStore;
    return (
      <div style={{ marginRight: '15px' }}>
        <S_TimePeriodSelector
          onPeriodChange={this.handleTimePeriodChange}
          selectedTimePeriod={statisticsTimePeriod}
          customLabels={{[TimePeriod.Month]: this.props.t('team.filter.month'), [TimePeriod.Day]: this.props.t('team.filter.today'), [TimePeriod.Week]: this.props.t('team.filter.week')}}
         />
      </div>
    );
  }
}

export const StatisticsTimeFrameSwitchContainer = as<React.ComponentClass>(withNamespaces('new')(StatisticsTimeFrameSwitchContainerPure));
