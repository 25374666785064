import React from 'react';

interface IProps {
  items: {
    name: string;
    description: string;
  }[];
}

export const FileList = (props: IProps) => (
  <div className="file-list">
    <ul>
      {props.items.map((f, i) => (
        <li key={i}>
          {f.name} ({f.description})
        </li>
      ))}
    </ul>
  </div>
);
