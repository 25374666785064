import { CurrentWorkspaceBl, CurrentWorkspaceBlType } from '../../CurrentWorkspace.bl';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../CurrentWorkspace.store';
import { IUserService, UserServiceType } from '../../../../../__legacy__/modules/user/user.service';
import { IWorkspacesStore, WorkspacesStoreType } from '../../../../../__legacy__/modules/workspaces/workspaces.store';

import { CurrentWorkspaceSelector } from './CurrentWorkspaceSelector';
import React from 'react';
import { as } from '../../../../../common/dependencyInjection/as';
import { injectProps } from '../../../../../__legacy__/helpers/react.helpers';
import { observer } from 'mobx-react';
import { withLoader } from '../../../../../__legacy__/helpers/loader.helpers';

interface InjectedProps {
  bl: CurrentWorkspaceBl;
  userService: IUserService;
  workspacesStore: IWorkspacesStore;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

interface State {
  isLoading: boolean;
}

@injectProps({
  bl: CurrentWorkspaceBlType,
  userService: UserServiceType,
  workspacesStore: WorkspacesStoreType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
@observer
class CurrentWorkspaceSelectorContainerPure extends React.Component<InjectedProps, State> {
  state: State = { isLoading: false };

  /// I think that this should be changed to
  /// getting only available workspaces for
  /// the user to pick from.
  /// Not all his infos and unused information
  /// about workspace with it.
  handleRefresh = () => withLoader(this)(this.props.userService.getUserInfoAsync);

  render(): React.ReactNode {
    return (
      <CurrentWorkspaceSelector
        currentWorkspace={this.props.currentWorkspaceStore.currentWorkspace}
        workspaces={this.props.workspacesStore.workspaces}
        isLoading={this.state.isLoading}
        onSelected={this.props.bl.selectWorkspace}
        onRefresh={this.handleRefresh}
      />
    );
  }
}

export const CurrentWorkspaceSelectorContainer = as<React.ComponentClass>(CurrentWorkspaceSelectorContainerPure);
