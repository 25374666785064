import * as React from 'react';

import { WithNamespaces, withNamespaces } from 'react-i18next';

import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';
import autobind from 'autobind-decorator';

export interface IDeleteAnnotationTypeConfirmationModalProps {
  showAnnotationTypeDeleteConfirmationModal: boolean;
  onAnnotationTypeDeleteConfirm(): void;
  onAnnotationTypeDeleteCancel(): void;
}

class DeleteAnnotationTypeConfirmationModalPure extends React.Component<IDeleteAnnotationTypeConfirmationModalProps & WithNamespaces> {
  @autobind
  onCancel() {
    this.props.onAnnotationTypeDeleteCancel();
  }

  render() {
    return (
      <ConfirmationModal
        confirmationHeader={this.props.t('delete_annotation_type_confirmation')}
        confirmationQuestion={this.props.t('delete_annotation_type_confirmation_body')}
        onCancel={this.onCancel}
        onConfirm={this.props.onAnnotationTypeDeleteConfirm}
        showModal={this.props.showAnnotationTypeDeleteConfirmationModal}
        confirmationYes={this.props.t('delete')}
      />
    );
  }
}

export const DeleteAnnotationTypeConfirmationModal = withNamespaces('annotation', { wait: true })(DeleteAnnotationTypeConfirmationModalPure);
