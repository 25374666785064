import { ImportAnnotationConflict, ResolveConflictDecision } from './models/ImportAnnotationsConflicts';
import { action, observable } from 'mobx';

import { IImportAnnotationsReport } from './models/ImportAnnotationsReport';
import { ImportAnnotationsStep } from './models/ImportAnnotationsSteps';
import { ResolveConflictsStrategy } from './models/ResolveConflictsStrategy';
import { injectable } from 'inversify';

export const ImportAnnotationsStoreType = Symbol('IMPORT_ANNOTATIONS_STORE');
export const ImportAnnotationsStoreSetterType = Symbol('IMPORT_ANNOTATIONS_STORE_SETTER');

export interface ImportAnnotationsStore {
  readonly currentStep: ImportAnnotationsStep;
  readonly file: File | undefined;
  readonly fileName: string | undefined;
  readonly fileValidationError: string | undefined;
  readonly conflicts: ImportAnnotationConflict[];
  readonly projectId: string | undefined;
  readonly resolveConflictsStrategy: ResolveConflictsStrategy;
  readonly processing: boolean;
  readonly report: IImportAnnotationsReport | undefined;
  readonly failureCodes: string[];
}

export interface ImportAnnotationsStoreSetter extends ImportAnnotationsStore {
  setCurrentStep(step: ImportAnnotationsStep): void;
  cleanFileInfo(): void;
  setProjectId(projectId: string): void;
  setConflictsResults(conflicts: ImportAnnotationConflict[], file: File): void;
  setFileValidationError(error: string): void;
  setResolveConflictsStrategy(strategy: ResolveConflictsStrategy): void;
  setProcessing(processing: boolean): void;
  setImportAnnotationsFinished(report: IImportAnnotationsReport): void;
  setImportAnnotationsFailed(errorCodes: string[]): void;
  cleanFileInfo(): void;
  clean(): void;
  setAllConflictsDecisions(decision: ResolveConflictDecision): void;
  setConflictDecision(conflict: ImportAnnotationConflict): void;
}

@injectable()
export class ImportAnnotationsStoreImpl implements ImportAnnotationsStoreSetter {
  @observable
  currentStep: ImportAnnotationsStep = ImportAnnotationsStep.None;

  @observable
  failureCodes: string[] = [];

  @observable
  file: File | undefined = undefined;

  @observable
  fileName: string | undefined;

  @observable
  conflicts: ImportAnnotationConflict[] = [];

  @observable
  fileValidationError: string | undefined;

  @observable
  projectId: string | undefined;

  @observable
  processing: boolean = false;

  @observable
  resolveConflictsStrategy: ResolveConflictsStrategy = ResolveConflictsStrategy.KeepAll;

  @observable
  report: IImportAnnotationsReport | undefined;

  @action
  setCurrentStep(step: ImportAnnotationsStep): void {
    this.currentStep = step;
  }

  @action
  setProjectId(projectId: string): void {
    this.projectId = projectId;
  }

  @action
  setFileValidationError(error: string): void {
    this.fileValidationError = error;
  }

  @action
  setConflictsResults(conflicts: ImportAnnotationConflict[], file: File): void {
    this.conflicts = conflicts;
    this.fileName = file.name;
    this.file = file;
  }

  @action
  setResolveConflictsStrategy(strategy: ResolveConflictsStrategy): void {
    this.resolveConflictsStrategy = strategy;
  }

  @action
  setProcessing(processing: boolean): void {
      this.processing = processing;
  }

  @action
  setImportAnnotationsFailed(errorCodes: string[]): void {
    this.processing = false;
    this.failureCodes = errorCodes;
    this.currentStep = ImportAnnotationsStep.Summary;
  }

  @action
  setImportAnnotationsFinished(report: IImportAnnotationsReport): void {
    this.report = report;
    this.processing = false;
    this.currentStep = ImportAnnotationsStep.Summary;
  }

  @action
  cleanFileInfo(): void {
    this.fileValidationError = undefined;
    this.fileName = undefined;
    this.conflicts = [];
    this.file = undefined;
  }

  @action
  clean(): void {
    this.fileName = undefined;
    this.projectId = undefined;
    this.conflicts = [];
    this.processing = false;
    this.fileValidationError = undefined;
    this.file = undefined;
    this.report = undefined;
    this.failureCodes = [];
    this.currentStep = ImportAnnotationsStep.None;
  }

  @action
  setAllConflictsDecisions(decision: ResolveConflictDecision): void {
    this.conflicts.forEach(c => c.decision = decision);
  }

  @action
  setConflictDecision(conflict: ImportAnnotationConflict) {
    const oldConflict = this.conflicts.find(c => c.dataSetName === conflict.dataSetName && c.imageName === conflict.imageName);
    oldConflict!.decision = conflict.decision;
  }
}
