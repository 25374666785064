import { withNamespaces } from 'react-i18next';
import * as React from 'react';
import { ITranslatable } from '../../../__legacy__/helpers/translations.helpers';
import { as } from '../../../__legacy__/helpers/react.helpers';
import { ButtonWithLoader } from '../../../__legacy__/components/ButtonWithLoader';
import { EVALUATION_STATUS } from '../../evaluations/evaluations.model';
import { ModelPermissions } from '../../../__legacy__/modules/workspaces/workspaces.store';
import { EnableForRole } from '../../../__legacy__/containers/EnableForRole';

interface IProps {
  isOwner: boolean;
  isTrainingOwner: boolean;
  status: EVALUATION_STATUS;
  startEvaluationAsync(): void;
  stopEvaluationAsync: () => void;
  downloadModelAsync: () => void;
}

const EvaluationDetailsHeaderButtonsPure = (props: IProps & ITranslatable) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const { t, status, downloadModelAsync, startEvaluationAsync, stopEvaluationAsync, isTrainingOwner, isOwner } = props;
  const isStartEvaluationAvailable = status === EVALUATION_STATUS.DRAFT || status === EVALUATION_STATUS.STOPPED || status === EVALUATION_STATUS.FAILED;
  const isStopEvaluationAvailable = status === EVALUATION_STATUS.RUNNING;
  const isDownloadModelAvailable = status !== EVALUATION_STATUS.DRAFT;

  const handleFetch = (callback?: () => void) => async () => {
    setIsLoading(true);
    await callback?.();
    setIsLoading(false);
  };

  return (
    <div className="evaluation-details-actions">
      <EnableForRole workspaceRoles={isOwner ? ModelPermissions.updateInferenceWhenOwner : ModelPermissions.updateInference} projectRoles={[]}>
        <ButtonWithLoader
          isLoading={isLoading}
          id="start-evaluation-button"
          color="primary"
          title={t(isStartEvaluationAvailable ? 'start_evaluation.text' : 'start_evaluation.not_available')}
          onClick={handleFetch(startEvaluationAsync)}
          disabled={!isStartEvaluationAvailable}
        >
          {props.t('start_evaluation.button')}
        </ButtonWithLoader>
      </EnableForRole>
      <EnableForRole workspaceRoles={isOwner ? ModelPermissions.updateInferenceWhenOwner : ModelPermissions.updateInference} projectRoles={[]}>
        <ButtonWithLoader
          isLoading={isLoading}
          id="stop-evaluation-button"
          color="primary"
          title={t(isStopEvaluationAvailable ? 'stop_evaluation.text' : 'stop_evaluation.not_available')}
          onClick={handleFetch(stopEvaluationAsync)}
          disabled={!isStopEvaluationAvailable}
        >
          {props.t('stop_evaluation.button')}
        </ButtonWithLoader>
      </EnableForRole>
      <EnableForRole workspaceRoles={isTrainingOwner ? ModelPermissions.downloadModelWhenOwner : ModelPermissions.downloadModel} projectRoles={[]}>
        <ButtonWithLoader
          isLoading={isLoading}
          id="download-model-training-button"
          color="primary"
          title={t(isDownloadModelAvailable ? 'download_model.button' : 'download_model.not_available')}
          onClick={handleFetch(downloadModelAsync)}
          disabled={!isDownloadModelAvailable}
        >
          {props.t('download_model.button')}
        </ButtonWithLoader>
      </EnableForRole>
    </div>
  );
};

export const EvaluationDetailsHeaderButtons = as<React.FC<IProps>>(withNamespaces('evaluations')(EvaluationDetailsHeaderButtonsPure));
