import { AlertBarBl, AlertBartBlType, IAlertBarBl } from './AlertBar.bl';
import { AlertBarStore, AlertBarStoreSetterType, AlertBarStoreType, IAlertBarStore, IAlertBarStoreSetter } from './AlertBar.store';
import { ContainerModule, interfaces } from 'inversify';

export const alertBarModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IAlertBarStore>(AlertBarStoreType).to(AlertBarStore).inSingletonScope();
  bind<IAlertBarStoreSetter>(AlertBarStoreSetterType).toService(AlertBarStoreType);

  bind<IAlertBarBl>(AlertBartBlType).to(AlertBarBl).inSingletonScope();
});
