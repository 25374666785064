import { AddPolygonVertexCommand } from './addPolygonVertexCommand';
import { AddVerticesCommandBase } from './addVerticesCommandBase';
import { DrawPolygon } from '../components/DrawControl.models/Draw.polygon';
import { LatLng } from 'leaflet';

export class AddPolygonVerticesCommand extends AddVerticesCommandBase {
  public polygon: DrawPolygon;
  constructor(
    polygon: DrawPolygon,
    latlng: LatLng,
  ) {
    super(latlng);
    this.polygon = polygon;
  }

  undoAction() {
    new AddPolygonVertexCommand(this.polygon, this.latlng[0]).executeUndo();
  }

  redoAction(latlng: LatLng): void {
    new AddPolygonVertexCommand(this.polygon, latlng).executeRedo();
  }
}
