import { inject, injectable } from 'inversify';
import moment from 'moment-timezone';
import { TimePeriod } from '../../../../design/selects/timePeriodSelector/S_TimePeriodSelector';
import { sortBy } from '../../../../__legacy__/helpers/collections.helper';
import { SortingDirection } from '../../../../__legacy__/models/sortingDirection.model';
import { NotificationLevel, ToastNotification } from '../../../../__legacy__/modules/notifications/models/notification.model';
import { INotificationsService, NotificationsServiceType } from '../../../../__legacy__/modules/notifications/services/notifications.service';
import { IAnnotationToolsStats } from './annotationTools/models/AnnotationToolsStats';
import { IGeneralStats } from './general/models/GeneralStats';
import { ProjectStatsStoreSetterType, IProjectStatsStoreSetter, defaultSortingDirection, defaultSortingBy } from './ProjectStats.store';
import { GetProjectStatsRequest, GetProjectStatsServiceType, IGetProjectStatsService } from './services/GetProjectStats.service';
import { IWorkPerformanceStats } from './workPerformance/models/WorkPerformance';

export const ProjectStatsBlType = Symbol('PROJECT_STATS_BL_TYPE');

export interface IProjectStatsBl {
  displayStatsAsync(projectId: string, timePeriod: TimePeriod, startDate: moment.Moment, endDate: moment.Moment): Promise<void>;
  changeAnnotationToolsStatsSorting(orderBy: string, orderDirection: SortingDirection): void;
  changeGeneralStatsSorting(orderBy: string, orderDirection: SortingDirection): void;
  changeWorkPerformanceStatsSorting(orderBy: string, orderDirection: SortingDirection): void;
}

@injectable()
export class ProjectStatsBl implements IProjectStatsBl {
  constructor(
    @inject(ProjectStatsStoreSetterType) private readonly store: IProjectStatsStoreSetter,
    @inject(GetProjectStatsServiceType) private readonly getStatsService: IGetProjectStatsService,
    @inject(NotificationsServiceType) private readonly notificationService: INotificationsService,
  ) {}

  async displayStatsAsync(projectId: string, timePeriod: TimePeriod, startDate: moment.Moment, endDate: moment.Moment): Promise<void> {
    this.store.setIsLoading(true);
    try {
      const request = new GetProjectStatsRequest(projectId, timePeriod, startDate, endDate);
      const stats = await this.getStatsService.getStatsAsync(request);
      this.sortAndSetGeneralStats(stats.generalStats);
      this.sortAndSetAnnotationToolsStats(stats.annotationToolsStats);
      this.sortAndSetWorkPerformanceStats(stats.workPerformanceStats);
    } catch (error) {
      this.notificationService.push(new ToastNotification(NotificationLevel.ERROR, 'getting_stats_failed'));
    }

    this.store.setIsLoading(false);
  }

  changeAnnotationToolsStatsSorting(orderBy: string, orderDirection: SortingDirection): void {
    this.store.setAnnotationToolsStatsSorting(orderBy, orderDirection);
    this.sortAndSetAnnotationToolsStats(this.store.annotationToolsStats);
  }

  changeGeneralStatsSorting(orderBy: string, orderDirection: SortingDirection): void {
    this.store.setGeneralStatsSorting(orderBy, orderDirection);
    this.sortAndSetGeneralStats(this.store.generalStats);
  }

  changeWorkPerformanceStatsSorting(orderBy: string, orderDirection: SortingDirection): void {
    this.store.setWorkPerformanceStatsSorting(orderBy, orderDirection);
    this.sortAndSetWorkPerformanceStats(this.store.workPerformanceStats);
  }

  private sortAndSetGeneralStats(generalStats: IGeneralStats[]) {
    const sorted = sortBy(generalStats, this.store.generalStatsOrderBy, this.store.generalStatsOrderDirection, defaultSortingBy, defaultSortingDirection);
    this.store.setGeneralStats(sorted);
  }

  private sortAndSetAnnotationToolsStats(annotationToolsStats: IAnnotationToolsStats[]) {
    const sorted = sortBy(annotationToolsStats, this.store.annotationToolsStatsOrderBy, this.store.annotationToolsStatsOrderDirection, defaultSortingBy, defaultSortingDirection);
    this.store.setAnnotationToolsStats(sorted);
  }

  private sortAndSetWorkPerformanceStats(workPerformanceStats: IWorkPerformanceStats[]) {
    const sorted = sortBy(workPerformanceStats, this.store.workPerformanceStatsOrderBy, this.store.workPerformanceStatsOrderDirection, defaultSortingBy, defaultSortingDirection);
    this.store.setWorkPerformanceStats(sorted);
  }
}
