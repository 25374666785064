import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Container, Input } from 'reactstrap';

export interface ISliderInputProps {
  icon: IconProp;
  label: string;
  value: number;
  min?: number;
  max?: number;
  step?: number;
  renderValue(value: number): React.ReactNode;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export class SliderInput extends React.Component<ISliderInputProps> {
  render() {
    return (
      <Container className="slider-container">
        <div className="d-flex slider-header">
          <div className="flex-grow-1">
            <FontAwesomeIcon icon={this.props.icon} /> {this.props.label}
          </div>
          <div>{this.props.renderValue(this.props.value)}</div>
        </div>
        <div className="slider-control">
          <Input
            type="range"
            min={this.props.min}
            max={this.props.max}
            step={this.props.step}
            value={this.props.value}
            onChange={this.props.onChange}
            className="custom-range"
          />
        </div>
      </Container>
    );
  }
}
