
import { injectable, inject } from 'inversify';
import { ConfigurationType, IConfiguration } from '../../../../configuration';
import { action } from 'mobx';
import { IHubNotification, NotificationType } from '../models/notification.model';
import { NotificationsStoreType, INotificationsStore } from '../stores/notifications.store';
import { BillingServiceType, IBillingService } from '../../billing/billing.service';
import { BaseHubService } from '../../../services/baseHub.service';
import { AuthStoreSetterType, IAuthStoreSetter } from '../../auth/auth.store';

export const HubServiceType = Symbol('NOTIFICATION_HUB_SERVICE');

export interface INotificationHubService {
  initializeAsync(): Promise<void>;
  removeNotificationsAsync(id: string[]): Promise<void>;
  markAsReadNotificationsAsync(ids: string[]): Promise<void>;
  pushNotificationAsync(notification: IHubNotification): Promise<void>;
}

const HUB_METHODS = {
  PUSH_NOTIFICATION: 'PushNotificationAsync',
  REMOVE_NOTIFICATIONS: 'RemoveNotificationsAsync',
  MARK_AS_READ_NOTIFICATIONS: 'MarkAsReadNotificationsAsync',
};

const HUB_HANDLERS = {
  NOTIFICATIONS_REMOVED: 'RemoveNotifications',
  NOTIFICATION_PUSHED: 'PushNotification',
  NOTIFICATIONS_PUSHED: 'PushNotifications',
  NOTIFICATIONS_MARKED_AS_READ: 'MarkAsReadNotifications',
};

interface IPushNotificationPayload {
  type: NotificationType;
  notification: string;
}

@injectable()
export class NotificationHubService extends BaseHubService implements INotificationHubService {
  constructor(
    @inject(ConfigurationType) config: IConfiguration,
    @inject(AuthStoreSetterType) auth: IAuthStoreSetter,
    @inject(NotificationsStoreType) private readonly notificationStore: INotificationsStore,
    @inject(BillingServiceType) private readonly billingService: IBillingService,
  ) {
    super(auth, config.hubUrl);
    this.addHandlers();
  }

  addHandlers() {
    this.connection.on(HUB_HANDLERS.NOTIFICATION_PUSHED, this.handleNotificationPushed);
    this.connection.on(HUB_HANDLERS.NOTIFICATIONS_REMOVED, this.handleNotificationsRemoved);
    this.connection.on(HUB_HANDLERS.NOTIFICATIONS_PUSHED, this.handleNotificationsPushed);
    this.connection.on(HUB_HANDLERS.NOTIFICATIONS_MARKED_AS_READ, this.handleNotificationsMarkedAsRead);
  }

  async pushNotificationAsync(notification: IHubNotification): Promise<void> {
    await this.connectAsync();
    await this.connection.send(HUB_METHODS.PUSH_NOTIFICATION, {
      type: notification.type,
      notification: JSON.stringify(notification),
    } as IPushNotificationPayload);
  }

  async removeNotificationsAsync(ids: string[]) {
    await this.connectAsync();
    await this.connection.send(HUB_METHODS.REMOVE_NOTIFICATIONS, ids);
  }

  async markAsReadNotificationsAsync(ids: string[]) {
    await this.connectAsync();
    await this.connection.send(HUB_METHODS.MARK_AS_READ_NOTIFICATIONS, ids);
  }

  @action
  handleNotificationPushed = (hubNotification: IHubNotification) => {
    if (!this.notificationStore.notifications.some(x => x.id === hubNotification.id)) {
      this.notificationStore.notifications.push(hubNotification);
    }
    this.billingService.getCreditsAsync();
  }

  @action
  handleNotificationsRemoved = (ids: string[]) => { this.notificationStore.notifications = this.notificationStore.notifications.filter(item => !ids.includes(item.id)); };

  @action
  handleNotificationsPushed = (hubNotifications: IHubNotification[]) => {
    const newNotifications = hubNotifications.filter(x => !this.notificationStore.notifications.some(n => n.id === x.id));
    this.notificationStore.notifications.push(...newNotifications);
  }

  @action
  handleNotificationsMarkedAsRead = (ids: string[]) =>
    ids.forEach((i: string) => {
      const notification = this.notificationStore.notifications.find(n => n.id === i);
      if (notification) notification.wasRead = true;
    });
}
