import * as React from 'react';

import { ClarificationsPermissionsType, IClarificationsPermissions } from '../../annotation/submodules/clarifications/Clarifications.permissions';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { EventBusType, EventListeningDisposer, IEventBus } from '../../../services/eventBus.service';
import { IImageFilterService, ImageFilterServiceType } from '../../projectDetails/sub/images/services/imageFilters.service';
import { IImageFilters, ImageDefaultFilters } from '../../projectDetails/sub/images/imageFilters.model';
import { IImagesFromUrlsService, ImagesFromUrlsServiceType } from '../../datesets/imagesFromUrlsUpload.service';
import { IImagesUploaderService, ImagesUploaderServiceType } from '../../datesets/imagesUpload.service';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { INotificationsService, NotificationsServiceType } from '../../notifications/services/notifications.service';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../../modules/common/OverlayLoader.store';
import { IProjectDetailsBl, ProjectDetailsBlType } from '../../projectDetails/projectDetails.bl';
import { IProjectsService, ProjectsServiceType } from '../projects.service';
import { IRouterStore, RouterStoreType } from '../../../stores/router.store';
import { IUserService, UserServiceType } from '../../user/user.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { CurrentWorkspaceChangedEventType } from '../../../../modules/workspaces/currentWorkspace/events/CurrentWorkspaceChangedEvent';
import { Home } from '../../../routes/config/Home';
import { IProjectDataset } from '../../projectDetails/projectDetails.models';
import { ImageAnnotationStatus } from '../../projectDetails/sub/images/projectDetailsImages.model';
import { Loader } from '../../../components/Loader';
import { ProjectOwnership } from '../projects.store';
import { ProjectsList } from '../components/ProjectsList';
import { StickerError } from '../../../models/error.model';
import { action } from 'mobx';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IProps extends RouteComponentProps {
  projectsService: IProjectsService;
  projectDetailsService: IProjectDetailsBl;
  currentWorkspaceStore: ICurrentWorkspaceStore;
  imagesFromUrlsService: IImagesFromUrlsService;
  imagesUploaderService: IImagesUploaderService;
  overlayLoader: IOverlayLoaderStore;
  notificationService: INotificationsService;
  userService: IUserService;
  routerStore: IRouterStore;
  imageFilterService: IImageFilterService;
  clarificationsPermissions: IClarificationsPermissions;
  eventBus: IEventBus;
}

interface IState extends ILoaderState {
  showDeleteModal: boolean;
  modalProjectId?: string;
  projectDatasetsToDelete: string[];
}

@injectProps({
  projectsService: ProjectsServiceType,
  projectDetailsService: ProjectDetailsBlType,
  imagesFromUrlsService: ImagesFromUrlsServiceType,
  imagesUploaderService: ImagesUploaderServiceType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  overlayLoader: OverlayLoaderStoreType,
  notificationService: NotificationsServiceType,
  userService: UserServiceType,
  routerStore: RouterStoreType,
  imageFilterService: ImageFilterServiceType,
  clarificationsPermissions: ClarificationsPermissionsType,
  eventBus: EventBusType,
})
@observer
class ProjectsListContainer extends WithLoaderComponentBase<IProps, IState> {
  currentWorkspaceChangeListenerDisposer: EventListeningDisposer;
  state: IState = {
    showDeleteModal: false,
    projectDatasetsToDelete: [],
    isLoading: false,
  };

  constructor(props: IProps) {
    super(props);
    this.currentWorkspaceChangeListenerDisposer = props.eventBus.addListener(this.refreshAsync, CurrentWorkspaceChangedEventType);
  }

  componentDidMount() {
    this.refreshAsync();
  }

  componentWillUnmount() {
    this.currentWorkspaceChangeListenerDisposer();
  }

  componentDidUpdate() {
    let ownership: ProjectOwnership = ProjectOwnership.All;
    const workspaceId = this.props.currentWorkspaceStore.currentWorkspace!.id;

    switch (this.props.location.pathname) {
      case Home.Projects.List.All.withParams({ workspaceId }):
        ownership = ProjectOwnership.All;
        break;
      case Home.Projects.List.Owned.withParams({ workspaceId }):
        ownership = ProjectOwnership.My;
        break;
      case Home.Projects.List.Others.withParams({ workspaceId }):
        ownership = ProjectOwnership.Others;
        break;
    }

    this.props.projectsService.changeOwnershipFilter(ownership);
  }

  @autobind
  async deleteDraft(draftId: string) {
    await this.props.projectsService.deleteProjectAsync(draftId, false);
    await this.refreshAsync();
  }

  @autobind
  async deleteProject(projectId: string) {
    this.setState({ showDeleteModal: true, modalProjectId: projectId });

    const result = await this.props.projectsService.getProjectExclusiveDatasetsAsync(projectId);
    if (!(result instanceof StickerError)) {
      this.setState({ projectDatasetsToDelete: result });
    }
  }

  @autobind
  handleDeleteCancel() {
    this.setState({ showDeleteModal: false, modalProjectId: undefined });
  }

  @autobind
  async handleDeleteConfirm(deleteDatasets: boolean) {
    if (this.state.isLoading || !this.state.modalProjectId) return;

    await this.withLoaderAsync(async () => {
      this.setState({ projectDatasetsToDelete: [] });

      if (deleteDatasets) {
        const result = await this.props.projectDetailsService.getProjectDetailsDatasetsAsync(this.state.modalProjectId!);
        if (!(result instanceof StickerError) && result.projectDatasets.length > 0) {
          const datasetsToRemove: IProjectDataset[] = result.projectDatasets;
          for (const datasetToRemove of datasetsToRemove) {
            await this.props.imagesFromUrlsService.cancelUploadAsync(datasetToRemove.id);
            await this.props.imagesUploaderService.cancelUploadAsync(datasetToRemove.id);
          }
        }
      }

      await this.props.projectsService.deleteProjectAsync(this.state.modalProjectId!, deleteDatasets);
      await this.props.projectsService.refreshAsync();
      this.setState({ showDeleteModal: false });
    }, 'delete-project-button');
  }

  @autobind
  async handleOrderChange(orderBy: string, orderType: string) {
    this.props.projectsService.changeOrder(orderBy, orderType);
  }

  @autobind
  async handlePaginationChange(pageNumber: number, pageSize: number) {
    this.props.projectsService.changePagination(pageNumber, pageSize);
  }

  @action.bound
  handleAnnotate(projectId: string) {
    this.props.routerStore.push(Home.Annotations.Create.withParams({ projectId, workspaceId: this.props.currentWorkspaceStore.currentWorkspace!.id }));
  }

  @action.bound
  handleReview(projectId: string) {
    this.props.routerStore.push(Home.Annotations.Review.withParams({ projectId, workspaceId: this.props.currentWorkspaceStore.currentWorkspace!.id }));
  }

  @autobind
  async handleGoToClarifications(projectId: string) {
    const clarificationFilter: IImageFilters = { ...ImageDefaultFilters, annotationStatuses: [ImageAnnotationStatus.CLARIFICATION] };
    const filterId = await this.props.imageFilterService.upsertFiltersAsync(projectId, clarificationFilter);
    const imageId = this.props.projectsService.store.projectsList.find(p => p.id === projectId)!.unansweredClarificationImageId;

    this.props.routerStore.push(
      Home.FreeAccess.View.withParams({
        projectId,
        imageId,
        filterId,
        workspaceId: this.props.currentWorkspaceStore.currentWorkspace!.id,
      }),
    );
  }

  refreshAsync = async () => {
    await Promise.all([this.props.projectsService.refreshAsync(), this.props.userService.getUserInfoAsync()]);
  };

  render() {
    const canClarify = this.props.clarificationsPermissions.canClarify();

    return (
      <Loader isLoading={this.props.overlayLoader.isSpinnerVisible('project-list')}>
        <ProjectsList
          workspaceId={this.props.currentWorkspaceStore.currentWorkspace!.id}
          projects={this.props.projectsService.store.projectsList}
          projectsListViewMode={this.props.userService.data.projectListViewMode}
          showDeleteModal={this.state.showDeleteModal}
          pagination={{
            pageNumber: this.props.projectsService.store.projectsPaging.pageNumber,
            pageSize: this.props.projectsService.store.projectsPaging.pageSize,
            totalCount: this.props.projectsService.store.projectsPaging.totalCount,
            onChange: this.handlePaginationChange,
          }}
          sorting={{
            orderBy: this.props.projectsService.store.projectsPaging.orderBy || '',
            orderType: this.props.projectsService.store.projectsPaging.orderType || '',
          }}
          onAnnotate={this.handleAnnotate}
          onReview={this.handleReview}
          onDelete={this.deleteProject}
          onDeleteConfirmed={this.handleDeleteConfirm}
          onDeleteCanceled={this.handleDeleteCancel}
          onOrderingChange={this.handleOrderChange}
          projectDatasetsToDelete={this.state.projectDatasetsToDelete}
          onGoToClarifications={canClarify ? this.handleGoToClarifications : undefined}
        />
      </Loader>
    );
  }
}

export default as<React.ComponentClass>(withRouter(ProjectsListContainer));
