import { AnnotationsStoreType, IAnnotationsStore } from '../annotations.store';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { as, injectProps } from '../../../helpers/react.helpers';

import { AnnotationImageNameHeader } from '../components/AnnotationImageNameHeader';
import { DatasetStatus } from '../../datesets/datasetStatus.model';
import React from 'react';
import { observer } from 'mobx-react';

interface IInjectedProps {
  annotationsStore: IAnnotationsStore;
}

@injectProps({
  annotationsStore: AnnotationsStoreType,
})
@observer
class AnnotationImageNameHeaderContainer extends React.Component<IInjectedProps & WithNamespaces> {
  render() {
    const showWarning = this.props.annotationsStore.datasetStatus !== DatasetStatus.PUBLISHED && this.props.annotationsStore.datasetStatus !== DatasetStatus.PUBLISHEDUPLOADING;

    return (
      <AnnotationImageNameHeader
        imageName={this.props.annotationsStore.imageName}
        datasetName={this.props.annotationsStore.datasetName}
        showWarning={showWarning}
        warningTooltip={this.props.t('unpublished')}
      />
    );
  }
}

export default as<React.ComponentClass>(withNamespaces()(AnnotationImageNameHeaderContainer));
