import * as React from 'react';

import { IRouterStore, RouterStoreType } from '../stores/router.store';
import { as, injectProps } from '../helpers/react.helpers';

interface IOuterProps {
  to: string;
  disabled?: boolean;
  className?: string;
  beforeClick?: Function;
}
interface IInjectedProps {
  router: IRouterStore;
}
interface IProps extends IInjectedProps, IOuterProps { }

@injectProps({ router: RouterStoreType })
class LinkPure extends React.Component<IProps> {
  handleLinkClick = (e: any) => {
    e.preventDefault();
    if (this.props.beforeClick && this.props.router.location.pathname !== this.props.to) this.props.beforeClick();
    if (!this.props.disabled) this.props.router.push(this.props.to);
  };

  render() {
    return <a className={`${this.props.className} cursor-pointer`} onClick={this.handleLinkClick}>{this.props.children}</a>;
  }
}

export const Link = as<React.ComponentClass<IOuterProps>>(LinkPure);
