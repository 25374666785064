import * as React from 'react';

import { AuthContainer } from '../../modules/auth/containers/AuthContainer';
import { RegistrationContainer } from '../../modules/auth/containers/RegistrationContainer';

export const RegisterView = () => (
  <div>
    <AuthContainer>
      <RegistrationContainer />
    </AuthContainer>
  </div>
);
