import * as React from 'react';

import { AlertBarStoreType, IAlertBarStore } from '../../modules/alertBar/AlertBar.store';
import { AttachmentsStoreType, IAttachmentsStore, defaultAttachmentsPaging } from '../modules/attachments/attachments.store';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { IUserStore, UserStoreType } from '../modules/user/user.store';
import { as, injectProps } from '../helpers/react.helpers';

import { SideNav } from '../components/SideNav';
import { TopNav } from '../components/TopNav';
import { alertBarHeight } from '../../modules/alertBar/ui/AlertBar';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IProps {
  children?: React.ReactElement<any>[] | React.ReactElement<any>;
  header?: React.SFC | React.ComponentClass;
  className?: string;
}

interface IInjectProps {
  userStore: IUserStore;
  currentWorkspaceStore: ICurrentWorkspaceStore;
  attachmentStore: IAttachmentsStore;
  alertBarStore: IAlertBarStore;
}

@injectProps({
  userStore: UserStoreType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  attachmentStore: AttachmentsStoreType,
  alertBarStore: AlertBarStoreType,
})
@observer
class DashboardContainerPure extends React.Component<IProps & IInjectProps> {
  @autobind
  handleBeforeAttachmentClick() {
    this.props.attachmentStore.attachmentsPaging = defaultAttachmentsPaging;
  }

  render() {
    const workspace = this.props.currentWorkspaceStore.currentWorkspace!;
    const alertsCount = this.props.alertBarStore.alerts.length;

    const Header = this.props.header;
    const userRole = this.props.userStore.userInfo.role;
    const userLinkExtension = !!this.props.userStore.userInfo.queryString ? `?${this.props.userStore.userInfo.queryString}` : '';

    return (
      <div>
        <SideNav
          userRole={userRole}
          userLinkExtension={userLinkExtension}
          workspaceRole={workspace.role}
          workspaceId={workspace.id}
          beforeAttachmentClick={this.handleBeforeAttachmentClick}
          alertsCount={alertsCount}
        />
        <TopNav alertsCount={alertsCount} />
        <div className="inside" style={{ height: `calc(100% - 130px - ${alertBarHeight(alertsCount)}`, marginTop: `${alertBarHeight(alertsCount) + 70}px` }}>
          {Header && (
            <div className="page-header">
              <Header />
            </div>
          )}
          <div className={`page-content ${this.props.className}`}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export const DashboardContainer = as<React.ComponentClass<IProps>>(DashboardContainerPure);
