import { ErrorView } from '../../../views/Error.View';
import { NotAuthorizedContainer } from './NotAuthorized.container';
import React from 'react';

interface IState {
  hasError: boolean;
}

export default class ErrorsContainer extends React.Component<{}, IState> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    return (
    <NotAuthorizedContainer>
      {this.state.hasError ? <ErrorView /> : this.props.children}
    </NotAuthorizedContainer>
    );
  }
}
