import * as React from 'react';

import { ITranslatable } from '../../../helpers/translations.helpers';
import { PageHeader } from '../../../components/PageHeader';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export const AttachmentsHeader = as<React.StatelessComponent>(
  withNamespaces('common', { wait: true })((props: ITranslatable) => (
    <PageHeader
      id="attachments-header"
      headerText={props.t('attachments')}
      tabNavPresent="clean"
    />
  )),
);
