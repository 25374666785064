import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faUnlink } from '@fortawesome/free-solid-svg-icons';
import { TranslationFunction } from 'i18next';
import * as React from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Select from 'react-select';
import classNames from 'classnames';
import { GROUND_TRUTH, IPredictionImage } from '../../models/predictions.model';
import { ImageSetType } from '../../modules/projectDetails/sub/images/projectDetailsImages.model';
import { LazyImage } from '../LazyImage';
import { ClampLines } from '../ClampLines';
import { Link } from '../../containers/Link';

library.add(faUnlink, faEdit);

interface ThumbnailProps {
  onClickLink?: string;
  image: IPredictionImage;
  t: TranslationFunction;
  authToken: string;
  showPredictions: boolean;
  threshold: number;
}

export const PredictionImageThumbnail = ({ image, t, authToken, showPredictions, threshold, onClickLink }: ThumbnailProps) => {
  const anomalyClass = image.prediction?.ground_truth === GROUND_TRUTH.BAD ? 'rejected' : 'approved';
  const anomalyText =
    typeof image.prediction?.ground_truth !== 'string' || !showPredictions ? '' : t(image.prediction?.ground_truth === GROUND_TRUTH.BAD ? 'annotation.bad' : 'annotation.good');

  const thumbClass =
    image.prediction?.score === undefined || !showPredictions
      ? ''
      : image.prediction?.score === null
      ? 'not-submitted'
      : threshold > image.prediction?.score
      ? 'approved'
      : 'rejected';

  return (
    <div className={`thumb ${thumbClass}`}>
      <div className="image-container">
        <LazyLoadComponent threshold={300}>
          {onClickLink !== undefined ? (
            <Link to={onClickLink}>
              <LazyImage source={image.url.replace('https://sticker-test.azurewebsites.net', '')} headers={new Headers({ Authorization: `Bearer ${authToken}` })} />
            </Link>
          ) : (
            <LazyImage source={image.url.replace('https://sticker-test.azurewebsites.net', '')} headers={new Headers({ Authorization: `Bearer ${authToken}` })} />
          )}
        </LazyLoadComponent>
        <div className={`thumb-set-type-dropdown disabled`}>
          <Select
            id={`image-set-type-dropdown-${image.id}`}
            value={{ label: t(`image_set_options.${image.imageSet}`), value: image.imageSet }}
            options={Object.values(ImageSetType).map(type => ({ label: t(`image_set_options.${type}`), value: type }))}
            classNamePrefix="custom-select"
            className={classNames({
              'not-set': image.imageSet === ImageSetType.NotSet,
              train: image.imageSet === ImageSetType.Train,
              ignore: image.imageSet === ImageSetType.Ignore,
              default: [ImageSetType.Private, ImageSetType.Test, ImageSetType.Validation].includes(image.imageSet),
            })}
            isDisabled={true}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      </div>
      <div className="description">
        <div className="file-name line-clamp">
          <ClampLines text={image.name} lines={1} />
        </div>
      </div>
      {showPredictions && typeof image.prediction?.score === 'number' && (
        <div className="attributes">
          <span className="attribute">
            {t('score')}:<span className="annotation-status"> {image.prediction?.score.toFixed(3)}</span>
          </span>
        </div>
      )}
      {showPredictions && anomalyText && (
        <div className="attributes">
          <span className="attribute">
            {t('annotation.text')}:<span className={`annotation-status ${anomalyClass}`}> {anomalyText}</span>
          </span>
        </div>
      )}
    </div>
  );
};
