import './S_Select.scss';

import React, { ChangeEvent } from 'react';

import { Input } from 'reactstrap';
import { S_ClassNameProps } from '../../CommonProps';

interface S_SelectProps extends S_ClassNameProps {
  selected: string;
  options: string[];
  onChange(value: string): void;
  optionClassName?: string;
}

export const S_Select: React.FC<S_SelectProps> = ({ selected, options, className, optionClassName, onChange }: S_SelectProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value);

  return (
    <Input className={`s-select ${className}`} type="select" value={selected} onChange={handleChange}>
      {options.map(o => (
        <option className={optionClassName} key={o}>
          {o}
        </option>
      ))}
    </Input>
  );
};
