import * as React from 'react';

import { DashboardContainer } from '../../../containers/DashboardContainer';
import { EvaluationDetailsCreateRoute, EvaluationDetailsRoute } from '../../../routes/evaluations/evaluationDetails.route';
import { EvaluationDetailsHeaderContainer } from '../../../../modules/evaluationDetails/containers/EvaluationDetailsHeader.container';

export const EvaluationDetailsView = () => {
  return (
    <DashboardContainer header={EvaluationDetailsHeaderContainer}>
      <EvaluationDetailsRoute />
    </DashboardContainer>
  );
};

export const EvaluationDetailsCreateView = () => {
  return (
    <DashboardContainer header={EvaluationDetailsHeaderContainer}>
      <EvaluationDetailsCreateRoute />
    </DashboardContainer>
  );
};
