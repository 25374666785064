import './InformationRow.scss';

import { WithNamespaces, withNamespaces } from 'react-i18next';

import React from 'react';
import { S_WarningRow } from '../../../../../design/warningRow/S_WarningRow';

interface IProps {
  date: string;
  onClick(): void;
}

const InformationRowPure: React.FC<IProps & WithNamespaces> = (props: IProps & WithNamespaces) => {
  const { t, date, onClick } = props;
  const text = t('projects.details.stats.information_row.new_stats_message', { date });
  return <S_WarningRow className="stats-information-row" text={text} onClick={onClick} />;
};

export const InformationRow = withNamespaces('new')(InformationRowPure);
