import { WithNamespaces, withNamespaces } from 'react-i18next';

import { Button } from 'reactstrap';
import { ImportAnnotationConflict } from '../../../models/ImportAnnotationsConflicts';
import React from 'react';
import { ResolveConflictTileContainer } from './resolveConflictsTile/ResolveConflictsTile.container';
import { S_TwoButtonModal } from '../../../../../../../../design/modals/twoButtonModal/S_TwoButtonModal';
import { StickerVirtualGrid } from '../../../../../../../../__legacy__/components/virtuals/StickerVirtualGrid';

interface Props {
  show: boolean;
  conflicts: ImportAnnotationConflict[];
  onHide(): void;
  onSubmit(): void;
  keepAll: () => void;
  replaceAll: () => void;
}

const ResolveConflictsStepModalPure: React.FC<Props & WithNamespaces> = (props: Props & WithNamespaces) => {
  const { t, show, conflicts, onHide, onSubmit, keepAll, replaceAll } = props;
  const itemBuilder = (conflict: ImportAnnotationConflict) => <ResolveConflictTileContainer key={`${conflict.dataSetName}/${conflict.imageName}`} conflict={conflict} />;

  return (
    <S_TwoButtonModal
      cancelText={t('new.projects.details.import_annotations.cancel')}
      headerText={`${t('new.projects.details.import_annotations.header')} - ${t('new.projects.details.import_annotations.steps.resolve_conflicts.header')}`}
      submitText={t('new.projects.details.import_annotations.next')}
      onCancel={onHide}
      onHide={onHide}
      onSubmit={onSubmit}
      show={show}
    >
      <div className="resolve-conflicts-container">
        <p>{t('new.projects.details.import_annotations.steps.resolve_conflicts.decide_how_to_resolve_conflicts')}:</p>
        <div className="resolve-conflict-buttons">
          <Button color="primary" onClick={keepAll}>
            {t('new.projects.details.import_annotations.steps.resolve_conflicts.keep_all')}
          </Button>
          <Button color="primary" onClick={replaceAll}>
            {t('new.projects.details.import_annotations.steps.resolve_conflicts.replace_all')}
          </Button>
        </div>
        <div className="resolve-conflict-list">
          <StickerVirtualGrid<ImportAnnotationConflict> crossAxesCount={1} itemHeight={97} items={conflicts} itemBuilder={itemBuilder} />
        </div>
      </div>
    </S_TwoButtonModal>
  );
};

export const ResolveConflictsStepModal = withNamespaces('common')(ResolveConflictsStepModalPure);
