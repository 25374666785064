import { StickerEvent } from "../../../../../__legacy__/services/eventBus.service";

export const ProjectExportFinishedEventType = Symbol('PROJECT_EXPORT_FINISHED_EVENT');

export class ProjectExportFinishedEvent extends StickerEvent {
  constructor(public readonly projectId: string) {
    super();
  }

  get type(): Symbol {
    return ProjectExportFinishedEventType;
  }
}
