import { Props } from 'react-select/lib/Select';
import React from 'react';
import Select from 'react-select';

export class SelectWithHax<T> extends React.Component<Props<T>> {
  handleScroll = (e: any) => e.target && !(e.target.classList.contains('custom-select__menu-list'));

  render() {
    return (
      <Select<T>
        {...this.props}
        value={this.props.value ?? null}
        classNamePrefix="custom-select"
        menuPortalTarget={document.body}
        maxMenuHeight={400}
        menuShouldBlockScroll={false}
        closeMenuOnScroll={this.handleScroll}
      />);
  }
}
