import { inject, injectable } from 'inversify';
import { InputStatus } from '../../../../../../__legacy__/models/error.model';
import { EventBusType, IEventBus } from '../../../../../../__legacy__/services/eventBus.service';
import * as uuid from 'uuid';
import { AnnotationToolType } from '../../../../../../__legacy__/modules/annotation/submodules/annotationTypes/models/annotationToolType';
import { debounce } from 'lodash';
import autobind from 'autobind-decorator';
import { AddMarkingToolStoreSetter, AddMarkingToolStoreSetterType } from './AddMarkingTool.store';
import { AddMarkingToolApiService, AddMarkingToolApiServiceType, AddMarkingToolRequest } from './services/AddMarkingToolApi.service';
import { CheckMarkingToolNameUniquenessRequest, CheckMarkingToolNameUniquenessService, CheckMarkingToolNameUniquenessServiceType } from '../commons/services/CheckMarkingToolNameUniqueness.service';
import { MarkingToolAddedEvent } from './events/MarkingToolAddedEvent';

export const AddMarkingToolBlType = Symbol('ADD_MARKING_TOOL_BL');

export interface AddMarkingToolBl {
  addMarkingToolAsync: () => Promise<void>;
  hideModal: () => void;
  showModal: (projectId: string) => void;
  changeName: (newName: string) => void;
  changeToolType: (newType: AnnotationToolType) => void;
  changeColor: (newColor: string) => void;
}

@autobind
@injectable()
export class AddMarkingToolBlImpl implements AddMarkingToolBl {
  constructor(
    @inject(EventBusType) private readonly eventBus: IEventBus,
    @inject(AddMarkingToolStoreSetterType) private readonly store: AddMarkingToolStoreSetter,
    @inject(AddMarkingToolApiServiceType) readonly createFormApiService: AddMarkingToolApiService,
    @inject(CheckMarkingToolNameUniquenessServiceType) readonly checkUniquenessService: CheckMarkingToolNameUniquenessService,
  ) {}

  public async addMarkingToolAsync(): Promise<void> {
    await this.validateNameAsync(this.store.name);
    if (!this.store.nameStatus.isValid) return;

    const request = new AddMarkingToolRequest(this.store.projectId, uuid.v4(), this.store.name, this.store.color, this.store.toolType);
    await this.createFormApiService.addMarkingToolAsync(request);

    this.eventBus.sendEvent(new MarkingToolAddedEvent());

    this.hideModal();
  }

  public hideModal() {
    this.store.setShowModal(false);
  }

  public changeName(newName: string): void {
    this.store.setName(newName);
    this.validateNameDebounced(newName);
  }

  public changeToolType(newType: AnnotationToolType): void {
    this.store.setToolType(newType);
  }

  public changeColor(newColor: string): void {
    this.store.setColor(newColor);
  }

  public showModal(projectId: string): void {
    this.store.setupStore(projectId)
  }

  private async validateNameAsync(newName: string) {
    const nameStatus = InputStatus.valid();

    if (newName === '') {
      nameStatus.addErrorCode('field_cant_be_empty');
    }

    if (newName === 'Image') {
      nameStatus.addErrorCode('annotation_type_name_cannot_be_image');
    }

    const request = new CheckMarkingToolNameUniquenessRequest(this.store.projectId, newName);
    if (!(await this.checkUniquenessService.checkMarkingToolNameUniqueness(request))) {
      nameStatus.addErrorCode('annotation_tool_name_must_be_unique');
    }

    this.store.setNameStatus(nameStatus);
  }

  private validateNameDebounced = debounce(async (name: string) => await this.validateNameAsync(name), 300);
}
