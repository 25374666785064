import * as React from 'react';

import { WithNamespaces, withNamespaces } from 'react-i18next';
import { faEdit, faUnlink } from '@fortawesome/free-solid-svg-icons';

import { DatasetThumbnail } from './DatasetThumbnail';
import { IDatasetDetailsImage } from '../datasetsDetails.store';
import { IDatasetDetailsImagesProps } from './DatasetDetailsImagesList';
import { S_Pagination } from '../../../../design/pagination/S_Pagination';
import { Settings } from '../../../../modules/settings/SettingsContext';
import autobind from 'autobind-decorator';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { scrollTop } from '../../../helpers/react.helpers';

library.add(faUnlink, faEdit);

@observer
class DatasetDetailsImagesListThumbsPure extends React.Component<IDatasetDetailsImagesProps & WithNamespaces> {
  static contextType = Settings;
  declare context: React.ContextType<typeof Settings>;
  
  @autobind
  handlePaginationChange(pageNumber: number, pageSize: number) {
    scrollTop();
    this.context.setDatasetDetailsImagesThumbnailsPageSize(pageSize);
    this.props.onPaginationChange(pageNumber, pageSize);
  }

  render() {
    return (
      <div>
        <div className="thumbnails">
          {this.props.datasetImages.map((image: IDatasetDetailsImage) => {
            return (<DatasetThumbnail
              key={image.id}
              workspaceId={this.props.workspaceId}
              image={image}
              t={this.props.t}
              disabled={this.props.disabled}
              datasetId={this.props.datasetId}
              authToken={this.props.authToken}
              isChecked={this.props.imagesToBulkDelete.some(x => x === image.id)}
              isOwnerOrManager={this.props.canDeleteMarkedImages}
              canDownloadImages={this.props.canDownloadImages}
              isImageDownloadable={this.props.isImageDownloadable}
              onCheck={this.props.onThumbCheck}
              onDelete={this.props.onImageDeleted}
              onRename={this.props.onRenameImage}
              canRenameImages={this.props.canRenameImages}
              canDeleteMarkedImages={this.props.canDeleteMarkedImages}
              canDeleteUnmarkedImages={this.props.canDeleteUnmarkedImages}
            />);
          })}
        </div>
        <div className="thumbnails-paging">
          <S_Pagination
            pageNumber={this.props.paging.pageNumber}
            pageSize={this.context.store.datasetDetailsImagesThumbnailsPageSize}
            totalCount={this.props.paging.totalCount}
            onChange={this.handlePaginationChange}
          />
        </div>
      </div>
    );
  }
}

export const DatasetDetailsImagesListThumbs = withNamespaces('datasets', { wait: true })(DatasetDetailsImagesListThumbsPure);
