import { injectable } from 'inversify';

export const EventBusType = Symbol('EVENT_BUS');
export type EventListeningDisposer = () => void;

export abstract class StickerEvent {
  abstract get type(): Symbol;
}

export interface IEventBus {
  addListener<T extends StickerEvent>(listener: (e: T) => void, type: Symbol): EventListeningDisposer;
  sendEvent(e: StickerEvent): void;
}

@injectable()
export class EventBus implements IEventBus {
  private observers: Map<Symbol, ((e: any) => void)[]> = new Map();

  addListener<T extends StickerEvent>(listener: (e: T) => void, type: Symbol): EventListeningDisposer {
    const observers = this.observers.get(type);
    if (!observers) {
      this.observers.set(type, [listener]);
    } else {
      observers.push(listener);
    }
    return this.generateDisposer(listener, type);
  }

  sendEvent(e: StickerEvent): void {
    this.observers.get(e.type)?.forEach(l => l(e));
  }

  private generateDisposer(listener: (e: any) => void, type: Symbol): () => void {
    return () => this.removeListener(listener, type);
  }

  private removeListener(listener: (e: StickerEvent) => void, type: Symbol): void {
    const observers = this.observers.get(type);
    if (!!observers) {
      const indexOf = observers.indexOf(listener);
      if (indexOf > -1) {
        observers.splice(indexOf, 1);
      }
    }
  }
}
