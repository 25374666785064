import { IApiService } from '../../../../../../../__legacy__/services/api.service.base';
import { StickerError } from '../../../../../../../__legacy__/models/error.model';
import { inject, injectable } from 'inversify';
import { ApiServiceType } from '../../../../../../../__legacy__/services/api.service';

export interface AddMarkingToolApiService {
  addMarkingToolAsync(request: AddMarkingToolRequest): Promise<void>;
}

export const AddMarkingToolApiServiceType = Symbol('ADD_MARKING_TOOL_API_SERVICE');

@injectable()
export class AddMarkingToolRequest {
  projectId: string;
  annotationTypeId: string;
  name: string;
  color: string;
  selectorType: string;

  constructor(projectId: string, markingToolId: string, name: string, color: string, selectorType: string) {
    this.projectId = projectId;
    this.annotationTypeId = markingToolId;
    this.name = name;
    this.color = color;
    this.selectorType = selectorType;
  }
}

@injectable()
export class AddMarkingToolApiServiceImpl implements AddMarkingToolApiService {
  constructor(@inject(ApiServiceType) readonly apiService: IApiService) {
  }

  async addMarkingToolAsync(request: AddMarkingToolRequest): Promise<void> {
    const result = await this.apiService.postAsync<AddMarkingToolRequest, void>('/AnnotationTypes/Add', request);

    if (result instanceof StickerError) {
      throw result;
    }
  }
}
