import { Configuration, ConfigurationType, IConfiguration } from '../configuration';

import { Container } from 'inversify';
import { addMarkingToolModule } from '../modules/projects/details/annotationTools/markingTools/add/AddMarkingTool.module';
import { alertBarModule } from '../modules/alertBar/AlertBar.module';
import { annotationModules } from './modules/annotation/annotation.module';
import { attachmentsModule } from './modules/attachments/attachments.module';
import { attachmentsModule2 } from '../modules/attachments/Attachments.module';
import { authModule } from './modules/auth/auth.module';
import { billingModule } from './modules/billing/billing.module';
import { clarificationsModule } from './modules/annotation/submodules/clarifications/Clarifications.module';
import { commonModule } from '../modules/common/Common.module';
import { currentWorkspaceModule } from '../modules/workspaces/currentWorkspace/CurrentWorkspace.module';
import { cursorControlModule } from '../modules/editor/cursorControl/CursorControl.module';
import { datasetsModule } from './modules/datesets/datasets.module';
import { editMarkingToolModule } from '../modules/projects/details/annotationTools/markingTools/edit/EditMarkingTool.module';
import { editorModule } from '../modules/editor/Editor.module';
import { errorsModule } from './modules/errors/errors.module';
import { exportDataModule } from '../modules/projects/details/exportData/ExportData.module';
import { freeAccessModule } from './modules/freeAccess/freeAccess.module';
import { importAnnotationsModule } from '../modules/projects/details/images/importAnnotations/importAnnotations.module';
import { integrationModule } from './modules/integration/integrations.module';
import { markingToolsModule } from '../modules/projects/details/annotationTools/markingTools/commons/MarkingTools.module';
import { notificationsModule } from './modules/notifications/notifications.module';
import { projectDetailsModule } from './modules/projectDetails/projectDetails.module';
import { projectOverviewModule } from '../modules/projects/project/overview/projectOverview.module';
import { projectStatisticsModule } from '../modules/projects/details/stats/ProjectStatistics.module';
import { projectsModule } from './modules/projects/projects.module';
import { renameAttachmentModule } from '../modules/attachments/rename/RenameAttachment.module';
import { servicesModule } from './services/services.module';
import { storesModule } from './stores/stores.module';
import { teamModule } from './modules/team/team.module';
import { userModule } from './modules/user/user.module';
import { workspacesModule } from './modules/workspaces/workspaces.module';
import { modelsModule } from '../modules/models/models.module';
import { modelDetailsModule } from '../modules/modelDetails/modelDetails.module';
import { evaluationsModule } from '../modules/evaluations/evaluations.module';
import { evaluationDetailsModule } from '../modules/evaluationDetails/evaluationDetails.module';
import { predictionImagesModule } from './components/predictionImages/predictionImagesPreview.module';

const container = new Container();

container.load(
  servicesModule,
  storesModule,
  authModule,
  ...annotationModules,
  datasetsModule,
  projectsModule,
  evaluationsModule,
  evaluationDetailsModule,
  modelsModule,
  modelDetailsModule,
  projectDetailsModule,
  projectStatisticsModule,
  userModule,
  notificationsModule,
  integrationModule,
  billingModule,
  workspacesModule,
  attachmentsModule,
  freeAccessModule,
  errorsModule,
  teamModule,
  clarificationsModule,
  projectOverviewModule,
  addMarkingToolModule,
  editMarkingToolModule,
  markingToolsModule,
  exportDataModule,
  currentWorkspaceModule,
  importAnnotationsModule,
  editorModule,
  alertBarModule,
  attachmentsModule2,
  renameAttachmentModule,
  commonModule,
  cursorControlModule,
  predictionImagesModule,
);

container.bind<IConfiguration>(ConfigurationType).to(Configuration).inSingletonScope();

export { container };
