import 'leaflet-draw';
import 'leaflet-editable';
import 'leaflet.path.drag';

import * as React from 'react';

import { ISegmentation } from '../annotations.interface';
import { Map } from 'leaflet';
import { SegmentationFrameDrawer } from './DrawControl.models/SegmentationFrameDrawer';
import { as } from '../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { withLeaflet } from 'react-leaflet';

export interface ISegmentationFrameDrawControlProps {
  readonly: boolean;
  editable: boolean;
  segmentation?: ISegmentation;
  onSegmentationSelected(id: string): void;
  onSegmentationDeselected(): void;
  onNextSegmentationSelected(): ISegmentation | undefined;
  onPreviousSegmentationSelected(): ISegmentation | undefined;
}

interface IProps extends ISegmentationFrameDrawControlProps {
  leaflet: { map: Map };
}

class SegmentationFrameDrawControlPure extends React.Component<IProps> {

  private map!: Map;
  private segmentationFrameDrawer!: SegmentationFrameDrawer;

  componentDidMount() {
    if (super.componentDidMount) super.componentDidMount();

    this.map = this.props.leaflet.map;
    this.map.keyboard.disable();
    this.setSegmentationFrameDrawer(this.props);
  }

  componentDidUpdate(props: IProps) {
    if (this.props.readonly !== props.readonly ||
      this.props.editable !== props.editable ||
      this.props.segmentation !== props.segmentation ||
      this.props.onSegmentationSelected !== props.onSegmentationSelected ||
      this.props.onNextSegmentationSelected !== props.onNextSegmentationSelected ||
      this.props.onPreviousSegmentationSelected !== props.onPreviousSegmentationSelected ||
      this.props.onSegmentationDeselected !== props.onSegmentationDeselected
    ) {
      this.segmentationFrameDrawer.update({
        readonly: this.props.readonly,
        editable: this.props.editable,
        segmentation: this.props.segmentation,
        selectSegmentation: this.props.onSegmentationSelected,
        nextSegmentationSelected: this.props.onNextSegmentationSelected,
        previousSegmentationSelected: this.props.onPreviousSegmentationSelected,
        segmentationDeselected: this.props.onSegmentationDeselected,
      });
    }
  }

  componentWillUnmount() {
    if (this.segmentationFrameDrawer) {
      this.segmentationFrameDrawer.clearFrame();
      this.segmentationFrameDrawer.removeHandlers();
    }
  }

  @autobind
  setSegmentationFrameDrawer(props: IProps) {
    this.segmentationFrameDrawer = new SegmentationFrameDrawer(
      this.map,
      {
        readonly: this.props.readonly,
        editable: this.props.editable,
        segmentation: props.segmentation,
        selectSegmentation: props.onSegmentationSelected,
        nextSegmentationSelected: props.onNextSegmentationSelected,
        previousSegmentationSelected: props.onPreviousSegmentationSelected,
        segmentationDeselected: props.onSegmentationDeselected,
      });

    this.segmentationFrameDrawer.addHandlers();
  }

  render() {
    return <>{this.props.children}</>;
  }
}

export const SegmentationFrameDrawControl = as<React.ComponentClass<ISegmentationFrameDrawControlProps>>(withLeaflet(SegmentationFrameDrawControlPure));
