import { Col, Row } from 'reactstrap';
import { faCheckSquare, faEdit, faTrashAlt, faUser } from '@fortawesome/free-solid-svg-icons';

import React from 'react';
import { ToolsStatisticsContentContainer } from '../containers/ToolsStatisticsContent.container';
import { ToolsStatisticsSidebarContainer } from '../containers/ToolsStatisticsSidebar.container';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';

library.add(faTrashAlt, faEdit, faUser, faCheckSquare);

@observer
export class ToolsStatistics extends React.Component {

  render() {
    return (
      <Row>
        <Col className="tool-statistics">
          <ToolsStatisticsSidebarContainer />
          <ToolsStatisticsContentContainer />
        </Col>
      </Row >
    );
  }
}
