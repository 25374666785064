import * as React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { faEye, faUnlink } from '@fortawesome/free-solid-svg-icons';

import { ButtonWithLoader } from '../../../components/ButtonWithLoader';
import { ITranslatable } from '../../../helpers/translations.helpers';
import _ from 'lodash';
import { as } from '../../../helpers/react.helpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';

library.add(faUnlink, faEye);

interface IUnlinkDatasetModalProps {
  show: boolean;
  hideModal(): void;
  onConfirm(): void;
  onCancel(): void;
}

class UnlinkDatasetModalPure extends React.Component<IUnlinkDatasetModalProps & ITranslatable> {
  render() {
    return (
      <Modal className="responsive-modal" isOpen={this.props.show} toggle={this.props.hideModal}>
        <ModalHeader toggle={this.props.hideModal}>{this.props.t('unlink_dataset')}</ModalHeader>
        <ModalBody>
          <div>
            <p className="mt-3">{this.props.t('do_you_want_to_unlink_dataset')}</p>
            <p>
              <strong>{this.props.t('do_you_want_to_unlink_dataset_note')}</strong>
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <ButtonWithLoader className="mr-2" color="warning" onClick={this.props.onConfirm} smallSpinner={true} loaderKey="unlink-dataset-button">
            {this.props.t('unlink')}
          </ButtonWithLoader>
          <Button color="primary" className="btn-outline-primary" onClick={this.props.onCancel}>
            {this.props.t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export const UnlinkDatasetModal = as<React.ComponentClass<IUnlinkDatasetModalProps>>(withNamespaces('project', { wait: true })(UnlinkDatasetModalPure));
