import { GTable, IGColumnProps } from '../../../__legacy__/components/table/GTable';
import { as, disableTextMarkingsForClass } from '../../../__legacy__/helpers/react.helpers';
import { faArrowAltCircleUp, faDownload, faEdit, faInfoCircle, faPlay, faStop, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { ITranslatable } from '../../../__legacy__/helpers/translations.helpers';
import { IconedButton } from '../../../__legacy__/components/IconedButton';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import React from 'react';
import { S_PaginationProps } from '../../../design/pagination/S_Pagination';
import { Settings } from '../../settings/SettingsContext';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';
import { IModelListItem } from '../models.store';
import { Home } from '../../../__legacy__/routes/config/Home';
import { formatSecondsToTime, toLocaleDateTimeString } from '../../../__legacy__/helpers/date.helpers';
import { MODEL_STATUS } from '../models.model';
import { handleClickAndPassData } from '../../../__legacy__/helpers/formHelpers';
import { S_ClipLoader } from '../../../design/loaders/clipLoader/S_ClipLoader';
import { EnableForRole } from '../../../__legacy__/containers/EnableForRole';
import { ModelPermissions } from '../../../__legacy__/modules/workspaces/workspaces.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProgressBar } from '../../../__legacy__/components/ProgressBar';

library.add(faArrowAltCircleUp, faDownload, faEdit, faPlay, faStop, faTrashAlt);

export interface IModelsListTableProps {
  workspaceId: string;
  models: IModelListItem[];
  sorting: {
    orderBy: string;
    orderType: string;
  };
  pagination: S_PaginationProps;
  downloadModelAsync: (modelId: string) => void;
  onRestartTraining(modelId: string): void;
  onStopTraining(modelId: string): void;
  onDeleteModel(model: IModelListItem): void;
  onOrderingChange(orderBy: string, orderType: string): void;
  onEditModel(model: IModelListItem): void;
}

// TODO(jduda): for now component class because i think it might need props injection in the future
// if not, then it can be changed to function component
class ModelsListTable extends React.Component<IModelsListTableProps & ITranslatable & RouteComponentProps> {
  static contextType = Settings;
  declare context: React.ContextType<typeof Settings>;

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    disableTextMarkingsForClass('custom-control-label');
  }

  get gColumns() {
    const gColumns: IGColumnProps<IModelListItem>[] = [];
    const { t } = this.props;

    gColumns.push(
      {
        field: 'modelName',
        headerName: t('name'),
        renderer: this.renderName,
        sortable: true,
        minWidth: 300,
      },
      {
        field: 'modelType',
        headerName: t('type'),
        renderer: this.renderType,
        sortable: true,
        width: 250,
      },
      {
        field: 'projectName',
        headerName: t('project'),
        renderer: this.renderProject,
        sortable: true,
        width: 250,
      },
      {
        field: 'createdAt',
        headerName: t('created_at'),
        renderer: this.renderCreationDate,
        width: 200,
        sortable: true,
      },
      {
        field: 'trainingTime',
        headerName: t('training_time'),
        renderer: this.renderTrainingTime,
        sortable: true,
        width: 140,
      },
      {
        field: 'progress',
        headerName: t('progress'),
        renderer: this.renderProgress,
        sortable: true,
        width: 120,
      },
      {
        field: 'score',
        headerName: t('score'),
        renderer: this.renderScore,
        sortable: true,
        width: 120,
      },
      {
        field: 'status',
        headerName: t('status'),
        renderer: this.renderStatus,
        sortable: true,
        width: 140,
      },
      {
        field: '',
        headerName: t('actions'),
        renderer: this.renderActions,
        sortable: false,
        width: 100,
      },
    );

    return gColumns;
  }

  stopPropagation(e: { stopPropagation(): void }) {
    e.stopPropagation();
  }

  renderStatus = (modelItem: IModelListItem) => this.getColoredModelStatusSpan(modelItem);

  getProgressBar(startingProgress: IModelListItem['startingProgress']): React.ReactElement {
    let progress = 0;
    switch (startingProgress) {
      case '1/4':
        progress = 25;
        break;
      case '2/4':
        progress = 50;
        break;
      case '3/4':
        progress = 75;
        break;
    }

    return <ProgressBar value={progress} enableStripesAnimation />;
  }

  getColoredModelStatusSpan(modelItem: IModelListItem): JSX.Element {
    const { status, failureReason, startingProgress } = modelItem;

    switch (status) {
      case MODEL_STATUS.RUNNING:
        return <span className="text-warning">{status}</span>;
      case MODEL_STATUS.STARTING:
        return (
          <>
            <span className="text-secondary" style={{ textAlign: 'center' }}>
              {status}
            </span>
            {this.getProgressBar(startingProgress)}
          </>
        );
      case MODEL_STATUS.STOPPING:
        return <span className="text-secondary">{status}</span>;
      case MODEL_STATUS.FAILED:
      case MODEL_STATUS.STOPPED:
        return (
          <>
            <span className="text-danger">{status}</span>
            {!!failureReason && (
              <span className="training-failure-reason" title={failureReason}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
            )}
          </>
        );
      case MODEL_STATUS.FINISHED:
      case MODEL_STATUS.IMPORTED:
        return <span className="text-success">{status}</span>;
      default:
        return <span>{status}</span>;
    }
  }
  renderName = (modelItem: IModelListItem) => {
    const { workspaceId } = this.props;

    return (
      <Link to={Home.Models.Details.Settings.withParams({ workspaceId, jobId: modelItem.id })}>
        <span className={'text-link-in-table'}>{modelItem.modelName}</span>
      </Link>
    );
  };

  renderType = (modelItem: IModelListItem) => {
    return <>{this.props.t(`model_variants.${modelItem.modelVariant}`)}</>;
  };

  renderCreationDate = (modelItem: IModelListItem) => <>{toLocaleDateTimeString(modelItem.createdAt)}</>;

  renderProject = (modelItem: IModelListItem) => <>{modelItem.projectName ? modelItem.projectName : this.props.t('na')}</>;

  renderTrainingTime = (modelItem: IModelListItem) => (
    <>{[MODEL_STATUS.STARTING, MODEL_STATUS.IMPORTED, MODEL_STATUS.STOPPING].includes(modelItem.status) ? this.props.t('na') : formatSecondsToTime(modelItem.trainingTime)}</>
  );

  renderProgress = (modelItem: IModelListItem) => <>{modelItem.progress === '0' ? this.props.t('na') : modelItem.progress}</>;

  renderScore = (modelItem: IModelListItem) => <>{modelItem.score === null ? this.props.t('na') : modelItem.score.toFixed(3)}</>;

  renderActions = (modelItem: IModelListItem) => {
    return (
      <div className="g-actions d-flex align-items-center">
        {(modelItem.status === MODEL_STATUS.STARTING || modelItem.status === MODEL_STATUS.FAILING || modelItem.status === MODEL_STATUS.STOPPING) && (
          <div style={{ marginRight: '0.48em' }}>
            <S_ClipLoader />
          </div>
        )}
        {modelItem.status === MODEL_STATUS.RUNNING && (
          <EnableForRole projectRoles={[]} workspaceRoles={modelItem.isOwner ? ModelPermissions.updateModelWhenOwner : ModelPermissions.updateModel}>
            <IconedButton onClick={handleClickAndPassData(this.props.onStopTraining)(modelItem.id)} title={this.props.t('stop_training.text')} icon={faStop} />
          </EnableForRole>
        )}
        {modelItem.status === MODEL_STATUS.STOPPED && (
          <EnableForRole projectRoles={[]} workspaceRoles={modelItem.isOwner ? ModelPermissions.updateModelWhenOwner : ModelPermissions.updateModel}>
            <IconedButton onClick={handleClickAndPassData(this.props.onRestartTraining)(modelItem.id)} title={this.props.t('restart_training.text')} icon={faPlay} />
          </EnableForRole>
        )}
        {modelItem.status === MODEL_STATUS.FAILED && (
          <EnableForRole projectRoles={[]} workspaceRoles={modelItem.isOwner ? ModelPermissions.updateModelWhenOwner : ModelPermissions.updateModel}>
            <IconedButton onClick={handleClickAndPassData(this.props.onEditModel)(modelItem)} title={this.props.t('edit_training')} icon={faEdit} />
          </EnableForRole>
        )}
        {(modelItem.status === MODEL_STATUS.FINISHED || modelItem.status === MODEL_STATUS.IMPORTED) && (
          <EnableForRole projectRoles={[]} workspaceRoles={modelItem.isOwner ? ModelPermissions.downloadModelWhenOwner : ModelPermissions.downloadModel}>
            <IconedButton onClick={handleClickAndPassData(this.props.downloadModelAsync)(modelItem.id)} title={this.props.t('download')} icon={faDownload} />
          </EnableForRole>
        )}
        <EnableForRole projectRoles={[]} workspaceRoles={modelItem.isOwner ? ModelPermissions.deleteModelWhenOwner : ModelPermissions.deleteModel}>
          <IconedButton onClick={handleClickAndPassData(this.props.onDeleteModel)(modelItem)} title={this.props.t('delete')} icon={faTrashAlt} color="red" />
        </EnableForRole>
      </div>
    );
  };

  onSortChanged = (orderBy: string, orderType: string) => this.props.onOrderingChange(orderBy, orderType);

  handleChange = (pageNumber: number, pageSize: number) => {
    this.context.setModelsPageSize(pageSize);
    this.props.pagination.onChange(pageNumber, pageSize);
  };

  render() {
    return (
      <div className="models-table">
        <GTable
          columns={this.gColumns}
          items={this.props.models}
          paginationProps={{ ...this.props.pagination, pageSize: this.context.store.modelsPageSize, onChange: this.handleChange }}
          onSortChanged={this.onSortChanged}
          sortingModel={[this.props.sorting]}
          noItemsPlaceholder={this.props.t('no_models_have_been_added_yet')}
        />
      </div>
    );
  }
}

export default as<React.ComponentClass<IModelsListTableProps>>(withRouter(withNamespaces('models', { wait: true })(ModelsListTable)));
