export class GetClarificationsRequest {
  projectId: string;
  imageId: string;

  constructor(projectId: string, imageId: string) {
    this.projectId = projectId;
    this.imageId = imageId;
  }
}

export class ClarifyRequest {
  clarificationId: string;
  answer: string;

  constructor(clarificationId: string, answer: string) {
    this.clarificationId = clarificationId;
    this.answer = answer;
  }
}

export interface IRequestClarificationRequest {
  projectId: string;
  imageId: string;
  question: string;
  duration: number;
}
