import * as React from 'react';

import { AuthContainer } from '../../modules/auth/containers/AuthContainer';
import { ResetPasswordContainer } from '../../modules/auth/containers/ResetPasswordContainer';

export const ResetPasswordView = () => (
  <div>
    <AuthContainer>
      <ResetPasswordContainer />
    </AuthContainer>
  </div>
);
