import { ExportDataBlType, IExportDataBl } from '../../../../modules/projects/details/exportData/ExportData.bl';
import { as, injectProps } from '../../../helpers/react.helpers';

import { ExportDataFilter } from '../../../../modules/projects/details/exportData/models/ExportDataFilter';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IProps {
  projectExportId: string;
  exportDataFilter: ExportDataFilter;
}

interface IInjectedProps {
  bl: IExportDataBl;
}

@injectProps({
  bl: ExportDataBlType,
})
@observer
class DownloadExportContainerPure extends React.Component<IProps & IInjectedProps & ITranslatable> {
  handleClick = () => this.props.bl.showDownloadModal(this.props.projectExportId, this.props.exportDataFilter);

  render() {
    return (
        <b onClick={this.handleClick} className="notification-export-link">
          {this.props.t('activity_description_Export_02')}
        </b>
        );
  }
}

export const DownloadExportContainer = as<React.ComponentClass<IProps>>(withNamespaces('common')(DownloadExportContainerPure));
