export enum ToolType {
  MarkingTool,
  Attribute,
  Question,
}

export const mapToolType = (type: ToolType) => {
  switch (type) {
    case ToolType.MarkingTool:
      return 'tool';

    case ToolType.Question:
      return 'question';

    case ToolType.Attribute:
      return 'attribute';
  }
};
