export abstract class BaseRouteConfig {
  public abstract get Index(): string;

  constructor(protected parentPath: string) {
    this.parentPath = parentPath;
  }

  public get Path(): string {
    return this.parentPath + this.Index;
  }
}

export interface IRouteParams {
  [key: string]: any | undefined;
}

export class StickerRoute<T extends IRouteParams> {
  public Path: string = '';
  constructor(path: string) {
    this.Path = path;
  }

  public withParams(params: T) {
    let path: string = this.Path;
    Object.keys(params).forEach(
      k =>
      (path = path
        .replace(`:${k}`, params[k])
        .replace('?', '')
        .replace(/([^:])(\/\/+)/g, '$1/')),
    );
    return path;
  }
}
