import './SegmentationRow.scss';

import { IObjectListSegmentation } from './ObjectsList.container';
import React from 'react';
import { SegmentationWithActions } from './SegmentationWithActions';
import { SegmentationsGroup } from './SegmentationsGroup';
import _ from 'lodash';

export interface IMarkingToolRowProps {
  isReadOnly: boolean;
  segmentations: IObjectListSegmentation[];
  onShowHideAnnotationTypeClick(annotationTypeId: string): void;
  onShowHideSegmentationClick(segmentation: IObjectListSegmentation): void;
  onDeleteAnnotationTypeClick(annotationTypeId: string): void;
  onDeleteSegmentationClick(segmentation: IObjectListSegmentation): void;
  onSegmentationClick(segmentation: IObjectListSegmentation): void;
}

export const SegmentationRow: React.FC<IMarkingToolRowProps> = ({
  isReadOnly,
  segmentations,
  onShowHideAnnotationTypeClick,
  onShowHideSegmentationClick,
  onDeleteAnnotationTypeClick,
  onDeleteSegmentationClick,
  onSegmentationClick,
}: IMarkingToolRowProps) => {
  const onShowHideSegmentationWithActions = (segmentation: IObjectListSegmentation) => onShowHideAnnotationTypeClick(segmentation.feature.properties!['annotationTypeId']);

  return (
    <div className="segmentation-row">
      {segmentations.length > 1 ? (
        <SegmentationsGroup
          isReadOnly={isReadOnly}
          segmentations={segmentations}
          onAnnotationTypeHiddenClick={onShowHideAnnotationTypeClick}
          onSegmentationHiddenClick={onShowHideSegmentationClick}
          onDeleteAnnotationTypeClick={onDeleteAnnotationTypeClick}
          onDeleteSegmentationClick={onDeleteSegmentationClick}
          onSegmentationClick={onSegmentationClick}
        />
      ) : (
        <SegmentationWithActions
          key={segmentations[0].id}
          isReadOnly={isReadOnly}
          objectListSegmentation={segmentations[0]}
          onShowHideClick={onShowHideSegmentationWithActions}
          onDeleteClick={onDeleteSegmentationClick}
          onClick={onSegmentationClick}
        />
      )}
    </div>
  );
};
