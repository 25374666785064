export const GLOBAL_ERROR_CODES = {
  FATAL_EXCEPTION: 'something_went_wrong',
  EMAIL_NOT_CONFIRMED: 'EMAIL_NOT_CONFIRMED',
  POLICY_LIMITS_EXCEEDED: 'POLICY_LIMITS_EXCEEDED',
};

export const NO_IMAGE_TO_DISPLAY_ERROR_CODES = {
  NO_MORE_CREDITS: 'NO_MORE_CREDITS',
  NO_MORE_IMAGES_IN_PROJECT: 'NO_MORE_IMAGES_IN_PROJECT',
  NO_MORE_UNLOCKED_IMAGES_IN_PROJECT: 'NO_MORE_UNLOCKED_IMAGES_IN_PROJECT',
  LACK_ANNOTATIONS_TO_REVIEW: 'LACK_ANNOTATIONS_TO_REVIEW',
  NO_UNLOCKED_ANNOTATIONS_TO_REVIEW: 'NO_UNLOCKED_ANNOTATIONS_TO_REVIEW',
  NO_ANNOTATIONS_TO_REVIEW: 'NO_ANNOTATIONS_TO_REVIEW',
};

export const COMMA_SEPARATOR = ', ';

export const DEFAULT_STROKE_OPACITY = 0.45;

export const ROTATION_HANDLER_RADIUS = 13;

export const MAX_FILE_NAME_LENGTH = 800;
