import { ContainerModule, interfaces } from 'inversify';
import { IModelDetailsStore, ModelDetailsStore, ModelDetailsStoreType } from './modelDetails.store';
import { IModelDetailsService, ModelDetailsService, ModelDetailsServiceType } from './modelDetails.service';
import {
  IModelDetailsApiService,
  ModelDetailsApiService,
  ModelDetailsApiServiceType,
} from './services/modelDetailsApi.service';

export const modelDetailsModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IModelDetailsApiService>(ModelDetailsApiServiceType)
    .to(ModelDetailsApiService)
    .inSingletonScope();
  bind<IModelDetailsStore>(ModelDetailsStoreType)
    .to(ModelDetailsStore)
    .inSingletonScope();
  bind<IModelDetailsService>(ModelDetailsServiceType)
    .to(ModelDetailsService)
    .inSingletonScope();
});