import { ContainerModule, interfaces } from 'inversify';

import { IModelsStore, ModelsStore, ModelsStoreType } from './models.store';
import { IModelsService, ModelsService, ModelsServiceType } from './models.service';
import { IModelsApiService, ModelsApiService, ModelsApiServiceType } from './services/modelsApi.service';

export const modelsModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IModelsStore>(ModelsStoreType)
    .to(ModelsStore)
    .inSingletonScope();
  bind<IModelsService>(ModelsServiceType)
    .to(ModelsService)
    .inSingletonScope();
  bind<IModelsApiService>(ModelsApiServiceType)
    .to(ModelsApiService)
    .inSingletonScope();
});