﻿import { DatasetUploadCompletedWithInfoNotification, NotificationLevel, ToastNotification } from '../notifications/models/notification.model';
import { Failed, OperationResult, PreprocessingError, StickerError, UploadSuccess } from '../../models/error.model';
import { IDatasetUpload, IDatasetUploadService, IImageToLoad, IUploadedImage, UploadServiceBase, WillImageUploadExceedsLimitResult } from './uploadServiceBase';
import { generateThumbnail, stripExifTags } from './helpers/image.helpers';

import { action } from 'mobx';
import { decodeImageAsync } from '../../helpers/imageDecoder.helpers';
import { injectable } from 'inversify';

export const ImagesUploaderServiceType = Symbol('IMAGES_UPLOADER_SERVICE');

export interface IImageFile extends IImageToLoad {
  file: File;
}

export interface IImagesUploaderService extends IDatasetUploadService {
  getDuplicatedImageNamesAsync(datasetId: string, imageFileNames: string[]): Promise<string[]>;
  getDuplicatedUrlsAsync(datasetId: string, urls: string[]): Promise<string[]>;
  willExceedsImageLimit(workspaceId: string, newImagesCount: number, newImagesSizeInMB: number): Promise<WillImageUploadExceedsLimitResult>;
}

@injectable()
export class ImagesUploadService extends UploadServiceBase<IImageFile> implements IImagesUploaderService {
  @action
  async getDuplicatedImageNamesAsync(datasetId: string, imageFileNames: string[]): Promise<string[]> {
    const distinct = imageFileNames.filter((v, i, a) => a.indexOf(v) === i);

    const url = '/Datasets/getDuplicatedFileNames';
    const result = await this.apiServiceImageUpload.postAsync<{ datasetId: string; imageNames: string[] }, string[]>(url, { datasetId, imageNames: distinct });

    if (result instanceof StickerError) throw result;

    return result;
  }

  @action
  async getDuplicatedUrlsAsync(datasetId: string, urls: string[]): Promise<string[]> {
    const distinct = urls.filter((v, i, a) => a.indexOf(v) === i);

    const url = '/Datasets/getDuplicatedUrls';
    const result = await this.apiServiceImageUpload.postAsync<{ datasetId: string; imageUrls: string[] }, string[]>(url, { datasetId, imageUrls: distinct });

    if (result instanceof StickerError) throw result;

    return result;
  }

  @action
  async upload(item: IImageFile, datasetId: string): Promise<OperationResult> {
    const url = '/datasets/addImage';
    const formData = new FormData();
    formData.append('datasetId', datasetId);
    formData.append('fileName', item.file.name);
    try {
      const encrypt = this.cryptoService.hasKey(this.currentWorkspaceStore.currentWorkspace!.id);
      const strippedImage = await stripExifTags(item.file);
      const strippedImageBuffer = await strippedImage.arrayBuffer();

      const meta = await decodeImageAsync(strippedImageBuffer);
      formData.append('width', meta.width.toString());
      formData.append('height', meta.height.toString());

      formData.append('isEncrypted', encrypt ? 'true' : 'false');
      formData.append('image', encrypt ? await this.cryptoService.encrypt(this.currentWorkspaceStore.currentWorkspace!.id, strippedImageBuffer) : strippedImage);
      if (encrypt) {
        const thumbnail = await generateThumbnail(strippedImage);
        formData.append('thumbnail', await this.cryptoService.encrypt(this.currentWorkspaceStore.currentWorkspace!.id, await thumbnail.arrayBuffer()));
      }
    } catch {
      return new Failed(new PreprocessingError());
    }
    if (this.isDatasetUploadAborted(datasetId)) return new Failed();
    try {
      const result = await this.apiServiceImageUpload.postAsync<FormData, IUploadedImage>(url, formData);
      if (result instanceof StickerError) {
        return new Failed(result);
      }
      return new UploadSuccess(result.imageSize);
    } catch {
      return new Failed();
    }
  }

  async handleUploadError(datasetId: string, result: Failed, item: IImageFile) {
    if (result && result.Error!.isBadRequestWithCode(['WRONG_FILE_TYPE'])) {
      this.notificationService.push(new ToastNotification(NotificationLevel.WARNING, { template: 'datasets:file_x_is_not_an_image', data: { fileName: item.file.name } }));
      result.isHandled = true;
    }
    if (result && result.Error && !result.Error.apiErrorResponse) {
      this.notificationService.push(new ToastNotification(NotificationLevel.WARNING, { template: 'datasets:image_x_empty_or_inaccessible', data: { imageName: item.file.name } }));
      result.isHandled = true;
    }

    super.handleUploadError(datasetId, result, item);
  }

  datasetUploadCompletedWithInfoNotification(dataset: IDatasetUpload): void {
    this.notificationService.push(new DatasetUploadCompletedWithInfoNotification(dataset.datasetName, dataset.rejectedItems.map(it => (it as IImageFile).file.name)));
  }

  async willExceedsImageLimit(workspaceId: string, newImagesCount: number, newImagesSizeInMB: number): Promise<WillImageUploadExceedsLimitResult> {
    const url = `/Datasets/WillImageUploadExceedsLimit?WorkspaceId=${workspaceId}&NewImagesCount=${newImagesCount}&NewImageSize=${newImagesSizeInMB}`;
    const result = await this.apiServiceImageUpload.getAsync<WillImageUploadExceedsLimitResult>(url);
    if (result instanceof StickerError) throw result;

    return result;
  }
}
