import * as React from 'react';

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { IImageFilterService, ImageFilterServiceType } from '../sub/images/services/imageFilters.service';
import { IProjectDetailsActivitiesBl, ProjectDetailsActivitiesBlType } from '../sub/activityLog/projectDetailsActivities.bl';
import { IProjectDetailsBl, ProjectDetailsBlType } from '../projectDetails.bl';
import { IProjectDetailsToolsBl, IProjectDetailsToolsBlType } from '../sub/tools/projectDetailsTools.bl';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { ProjectDetailsTabs } from '../components/ProjectDetailsTabs';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ projectId: string, workspaceId: string }> {
  projectDetailsBl: IProjectDetailsBl;
  imageFilterService: IImageFilterService;
  projectDetailsToolsBl: IProjectDetailsToolsBl;
  projectDetailsActivitiesBl: IProjectDetailsActivitiesBl;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

@injectProps({
  projectDetailsBl: ProjectDetailsBlType,
  imageFilterService: ImageFilterServiceType,
  projectDetailsToolsBl: IProjectDetailsToolsBlType,
  projectDetailsActivitiesBl: ProjectDetailsActivitiesBlType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
@observer
class ProjectDetailsTabsContainerPure extends React.Component<IInjectedProps> {
  @autobind
  async imageClickCallback() {
    await this.props.imageFilterService.setDefaultImagePaging();
  }

  render() {
    const { id, datasetsStatus, usersStatus, status, imagesStatus } = this.props.projectDetailsBl.store;
    const { toolsStatus } = this.props.projectDetailsToolsBl.store;
    const { activitylogStatus } = this.props.projectDetailsActivitiesBl.store;

    return (
      <ProjectDetailsTabs
        imageClickCallback={this.imageClickCallback}
        projectId={id}
        projectStatus={status}
        workspaceId={this.props.currentWorkspaceStore.currentWorkspace!.id}
        datasetsStatus={datasetsStatus}
        usersStatus={usersStatus}
        toolsStatus={toolsStatus}
        activityLogStatus={activitylogStatus}
        imagesStatus={imagesStatus}
      />);
  }
}

export const ProjectDetailsTabsContainer = as<React.ComponentClass>(withRouter(ProjectDetailsTabsContainerPure));
