import * as React from 'react';

import { AnnotationToolType } from '../models/annotationToolType';
import AnnotationTypeListItem from './AnnotationTypesListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IAnnotationType } from '../models/annotationType';
import { IAnnotationTypeOptions } from '../models/annotationTypeOptions';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { as } from '../../../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { withNamespaces } from 'react-i18next';

export interface IAnnotationTypeSelectedProps {
  projectId: string;
  annotationTypes: IAnnotationType[];
  annotationTypesOptions: IAnnotationTypeOptions[];
  activeAnnotationTypeId?: string;
  readonly: boolean;
  showList: boolean;
  onAnnotationTypeOptionsChanged(projectId: string, annotationTypeOption: IAnnotationTypeOptions): void;
  onSelectedAnnotationDelete(): void;
  getShortcutKey(order: number): string | undefined;
}

class AnnotationTypeSelectedPure extends React.Component<IAnnotationTypeSelectedProps & ITranslatable> {
  @autobind
  handleAnnotationTypeClicked(id: string) {}

  render() {
    const selectedAnnotationType = this.props.annotationTypes.find(a => a.id === this.props.activeAnnotationTypeId);
    if (this.props.showList || !selectedAnnotationType || selectedAnnotationType.selectorType !== AnnotationToolType.BRUSH) return null;

    return (
      <div id="annotation-selector" className={`annotation-types ${this.props.readonly ? 'annotation-tools-readonly-overlay' : ''}`}>
        <div className="header">
          <FontAwesomeIcon icon="tags" /> {this.props.t('selected_annotation_header')}
        </div>
        <AnnotationTypeListItem
          key={selectedAnnotationType.id}
          onAnnotationTypeClicked={this.handleAnnotationTypeClicked}
          onAnnotationTypeOptionsChanged={this.props.onAnnotationTypeOptionsChanged}
          onSelectedAnnotationDelete={this.props.onSelectedAnnotationDelete}
          getShortcutKey={this.props.getShortcutKey}
          {...selectedAnnotationType}
          active={true}
          deleteSelected={true}
          readonly={false}
          options={this.props.annotationTypesOptions.find(o => o.id === selectedAnnotationType.id)!}
        />
      </div>
    );
  }
}

export const AnnotationTypeSelected = as<React.ComponentClass<IAnnotationTypeSelectedProps>>(withNamespaces('annotation')(AnnotationTypeSelectedPure));
