import * as React from 'react';

import { IconName, library } from '@fortawesome/fontawesome-svg-core';
import { faBook, faCog, faDatabase, faHome } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faBook, faHome, faDatabase, faCog);

interface IProps {
  id?: string;
  name: string;
  icon: IconName;
  link: string;
}

export const SideNavMenuExternalItem = (props: IProps) => (
  <li id={props.id} className="nav-list-item passive">
    <a target="_blank" href={props.link} rel="noopener noreferrer">
      <FontAwesomeIcon icon={props.icon} />
      <p>{props.name}</p>
    </a>
  </li >
);
