import { WithNamespaces, withNamespaces } from 'react-i18next';

import { Button } from 'reactstrap';
import { DisabledWhenPolicyExceeded } from '../../../containers/DisabledWhenPolicyExceeded';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { as } from '../../../helpers/react.helpers';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

interface IProps {
  onCreateButtonClicked(): void;
}

library.add(faPlus);

const CreateWorkspaceButtonPure = function (props: IProps & WithNamespaces) {
  return (
    <div className="create-workspace">
      <DisabledWhenPolicyExceeded>
        <Button
          color="primary"
          onClick={props.onCreateButtonClicked}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </DisabledWhenPolicyExceeded>
    </div>
  );
};

export const CreateWorkspaceButton = as<React.SFC<IProps>>(withNamespaces('common')(CreateWorkspaceButtonPure));
