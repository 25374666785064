import * as React from 'react';

import { AnnotationToolType } from '../models/annotationToolType';
import AnnotationTypeListItem from './AnnotationTypesListItem';
import { DomUtil } from 'leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IAnnotationType } from '../models/annotationType';
import { IAnnotationTypeOptions } from '../models/annotationTypeOptions';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { as } from '../../../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IAnnotationTypeListProps {
  projectId: string;
  annotationTypes: IAnnotationType[];
  annotationTypesOptions: IAnnotationTypeOptions[];
  activeAnnotationTypeId?: string;
  readonly: boolean;
  showList: boolean;
  onAnnotationTypeSelected(id: string): void;
  onAnnotationTypeOptionsChanged(projectId: string, annotationTypeOption: IAnnotationTypeOptions, handleSegmentationVisibility?: boolean): void;
  onSelectedAnnotationDelete(): void;
  getShortcutKey(order: number): string | undefined;
}

class AnnotationTypesListPure extends React.Component<IAnnotationTypeListProps & ITranslatable> {
  render() {
    const isSelectToolActive = this.props.activeAnnotationTypeId === undefined && !this.props.readonly;
    const element = document.getElementsByClassName('annotation-view')[0] as any;

    if (element) {
      if (this.props.activeAnnotationTypeId === undefined || this.props.readonly) {
        DomUtil.removeClass(element, 'no-user-select');
      } else {
        DomUtil.addClass(element, 'no-user-select');
      }
    }

    if (!this.props.showList || this.props.annotationTypes.length === 0) return null;

    return (
      <div id="annotation-selector" className={`annotation-types ${this.props.readonly ? 'annotation-tools-readonly-overlay' : ''}`}>
        <div className="header">
          <FontAwesomeIcon icon="tags" /> {this.props.t('annotation_header')}
        </div>
        {this.props.annotationTypes.length && (
          <AnnotationTypeListItem
            key="select_tool"
            id={AnnotationToolType.SELECT}
            projectId={this.props.projectId}
            onAnnotationTypeClicked={this.props.onAnnotationTypeSelected}
            onAnnotationTypeOptionsChanged={this.props.onAnnotationTypeOptionsChanged}
            onSelectedAnnotationDelete={this.props.onSelectedAnnotationDelete}
            readonly={this.props.readonly}
            getShortcutKey={getEscShortcut}
            color="#262B47"
            name={this.props.t('select_tool')}
            order={0}
            selectorType={AnnotationToolType.SELECT}
            active={isSelectToolActive}
            isUsed={false}
          />
        )}
        {this.props.annotationTypes.map(m => (
          <AnnotationTypeListItem
            key={m.id}
            onAnnotationTypeClicked={this.props.onAnnotationTypeSelected}
            onAnnotationTypeOptionsChanged={this.props.onAnnotationTypeOptionsChanged}
            onSelectedAnnotationDelete={this.props.onSelectedAnnotationDelete}
            getShortcutKey={this.props.getShortcutKey}
            {...m}
            order={this.props.annotationTypes.indexOf(m)}
            active={m.id === this.props.activeAnnotationTypeId && !this.props.readonly}
            readonly={this.props.readonly}
            options={this.props.annotationTypesOptions.find(o => o.id === m.id)!}
          />
        ))}
      </div>
    );
  }
}

const getEscShortcut = () => '͠ˎ';

export const AnnotationTypesList = as<React.ComponentClass<IAnnotationTypeListProps>>(withNamespaces('annotation')(AnnotationTypesListPure));
