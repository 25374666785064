import { inject, injectable } from 'inversify';
import { StickerError } from '../../../../../../../__legacy__/models/error.model';
import { ApiServiceType } from '../../../../../../../__legacy__/services/api.service';
import { IApiService } from '../../../../../../../__legacy__/services/api.service.base';


export const CheckMarkingToolNameUniquenessServiceType = Symbol('CHECK_MARKING_TOOL_NAME_UNIQUENESS_SERVICE');

export interface CheckMarkingToolNameUniquenessService {
  checkMarkingToolNameUniqueness(request: CheckMarkingToolNameUniquenessRequest): Promise<boolean>;
}

export class CheckMarkingToolNameUniquenessRequest {
  projectId: string;
  name: string;
  annotationTypeId?: string;

  constructor(projectId: string, name: string, markingToolId?: string) {
    this.projectId = projectId;
    this.name = name;
    this.annotationTypeId = markingToolId;
  }
}

@injectable()
export class CheckMarkingToolNameUniquenessServiceImpl implements CheckMarkingToolNameUniquenessService {
  constructor(@inject(ApiServiceType) readonly apiService: IApiService) {}

  async checkMarkingToolNameUniqueness(request: CheckMarkingToolNameUniquenessRequest): Promise<boolean> {
    const result = await this.apiService.getAsync<boolean>('/AnnotationTypes/CheckAnnotationTypeNameUniqueness', { params: request });

    if (result instanceof StickerError) {
      throw result;
    }

    return result;
  }
}
