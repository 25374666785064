import { injectable } from 'inversify';
import { observable } from 'mobx';

export const BillingStoreType = Symbol('BILLING_STORE');

export interface IBillingStore {
  accountExists: boolean;
  totalCredits: number;
  usedCredits: number;
  availableCredits: number;
  creditPrice: number;
  currency: string;
  enabled: boolean;
  isAnyPolicyLimitExceeded: boolean;
  imagesAmount: number;
  usedSpace: number;
  workspaces: number;
  workspaceUsers: number;
  workspaceWorkers: number;
}

@injectable()
export class BillingStore implements IBillingStore {
  @observable
  accountExists: boolean = true;

  @observable
  totalCredits: number = 0;

  @observable
  usedCredits: number = 0;

  @observable
  availableCredits: number = 0;

  @observable
  creditPrice: number = 0;

  @observable
  currency: string = 'EUR';

  @observable
  isAnyPolicyLimitExceeded: boolean = false;

  @observable
  imagesAmount: number = 0;

  @observable
  usedSpace: number = 0;

  @observable
  workspaces: number = 0;

  @observable
  workspaceUsers: number = 0;

  @observable
  workspaceWorkers: number = 0;

  @observable
  enabled: boolean = false;
}
