﻿import * as React from 'react';

import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { IAnswerModel, QuestionForm } from '../components/QuestionForm';
import { ILoaderState, WithLoaderComponentBase } from '../../../../../helpers/loader.helpers';
import { IProjectDetailsToolsBl, IProjectDetailsToolsBlType } from '../projectDetailsTools.bl';
import { IQuestion, MIN_ANSWERS_COUNT } from '../projectDetailsTools.models';
import { ImageScopeName, QuestionType, isTextQuestion } from '../../../../annotation/question.model';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { ButtonWithLoader } from '../../../../../components/ButtonWithLoader';
import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { InputStatus } from '../../../../../models/error.model';
import { ModalFooter } from 'reactstrap/lib';
import autobind from 'autobind-decorator';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import uuid from 'uuid';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  projectDetailsToolsBl: IProjectDetailsToolsBl;
}

interface IOuterProps {
  isAttribute: boolean;
  isReadonly: boolean;
  isLoading: boolean;
  question: IQuestion | undefined;
  questionsCount: number;
  selectedQuestionTypes: QuestionType[];
  existingQuestionsAndAttributes: { text: string; type: QuestionType }[];
  onSubmit(model: IQuestion): void;
  onCancel(): void;
  showModal: boolean;
  modalHeader: string;
}

interface IState extends ILoaderState {
  id: string;
  questionType: QuestionType;
  scopes: string[];
  questionText: string;
  isRequired: boolean;
  answers: IAnswerModel[];
  questionTextStatus: InputStatus;
  scopesStatus: InputStatus;
  answersStatus: InputStatus;
  isEdit: boolean;
  isUsed: boolean;
  isAttribute: boolean;
  order: number;
}

const questionTypes = Object.values(QuestionType);

const getDefaultTrueFalseAnswers = (): IAnswerModel[] => [
  { text: 'Yes', status: InputStatus.empty(), id: uuid.v4() },
  { text: 'No', status: InputStatus.empty(), id: uuid.v4() },
];

const getDefaultStatusPassFailAnswers = (): IAnswerModel[] => [
  { text: 'Pass', status: InputStatus.empty(), id: uuid.v4() },
  { text: 'Fail', status: InputStatus.empty(), id: uuid.v4() },
];

const getDefaultSetTrainTestAnswers = (): IAnswerModel[] => [
  { text: 'Train', status: InputStatus.empty(), id: uuid.v4() },
  { text: 'Test', status: InputStatus.empty(), id: uuid.v4() },
];

const getInitialState = (props: Props): IState => {
  const { question, isAttribute } = props;

  if (question) {
    return {
      isAttribute,
      id: question.id,
      questionType: question.type,
      scopes: question.scopes,
      questionText: question.questionText,
      isRequired: question.isRequired,
      answers: question.answers.map(x => ({ ...x, status: InputStatus.empty() })),
      questionTextStatus: InputStatus.empty(),
      answersStatus: InputStatus.empty(),
      scopesStatus: InputStatus.empty(),
      isEdit: true,
      isUsed: question.isUsed,
      order: question.order,
      isLoading: false,
    };
  }

  return {
    isAttribute,
    id: uuid.v4(),
    questionType: QuestionType.TRUE_FALSE,
    scopes: isAttribute ? [] : [ImageScopeName],
    questionText: '',
    isRequired: false,
    answers: getDefaultTrueFalseAnswers(),
    questionTextStatus: InputStatus.empty(),
    answersStatus: InputStatus.empty(),
    scopesStatus: InputStatus.empty(),
    isEdit: false,
    isUsed: false,
    order: props.questionsCount,
    isLoading: false,
  };
};

type Props = IOuterProps & ITranslatable & IInjectedProps;

@injectProps({ projectDetailsToolsBl: IProjectDetailsToolsBlType })
@observer
class QuestionModalContainerPure extends WithLoaderComponentBase<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = getInitialState(props);
  }

  @autobind
  handleQuestionTypeChange(questionType: QuestionType): void {
    let answers: IAnswerModel[] = this.state.answers;
    let questionText: string = this.state.questionText;

    if (questionType === QuestionType.TRUE_FALSE) {
      answers = getDefaultTrueFalseAnswers();
    } else if (questionType === QuestionType.STATUS_PASS_FAIL) {
      answers = getDefaultStatusPassFailAnswers();
      questionText = this.props.t('default_status_question');
    } else if (questionType === QuestionType.SET_TRAIN_TEST) {
      questionText = this.props.t('default_set_question');
      answers = getDefaultSetTrainTestAnswers();
    } else if (isTextQuestion(questionType) || this.state.questionType === QuestionType.TRUE_FALSE) {
      answers = [];
    }

    this.setState({
      questionText,
      questionType,
      answers,
      answersStatus: InputStatus.empty(),
      questionTextStatus: InputStatus.empty(),
    });
  }

  handleQuestionTextValidationAsyncDebounced = debounce(async (text: string) => await this.handleQuestionTextValidationAsync(text), 300);

  @autobind
  handleQuestionTextChange(questionText: string): void {
    this.setState({ questionText });
    this.handleQuestionTextValidationAsyncDebounced(questionText);
  }

  @autobind
  async handleSubmitAsync(): Promise<void> {
    await this.withLoaderAsync(async () => {
      if (!(await this.validateFormAsync())) {
        return;
      }

      const newQuestion = {
        id: this.state.id,
        type: this.state.questionType,
        questionText: this.state.questionText.trim(),
        isRequired: this.state.isRequired,
        answers: this.state.answers.filter(x => x.text),
        scopes: this.state.scopes,
        isUsed: this.state.isUsed,
        order: this.state.order,
      };

      this.props.onSubmit(newQuestion);
    }, 'submit-question-form-button');
  }

  @autobind
  toggleIsRequiredStatus(): void {
    this.setState(prev => ({ isRequired: !prev.isRequired }));
  }

  @autobind
  handleAnswerAdd(): void {
    const answersStatus = this.state.answers.length + 1 >= MIN_ANSWERS_COUNT ? InputStatus.empty() : this.state.answersStatus;
    this.setState(prev => ({ answersStatus, answers: [...prev.answers, { id: uuid.v4(), text: '', status: InputStatus.empty() }] }));
  }

  @autobind
  async handleAnswerChange(text: string, id: string): Promise<void> {
    const answers = this.state.answers.slice();
    const answer = answers.find(x => x.id === id);
    answer!.text = text;
    this.validateAnswers(answers);
  }

  @autobind
  handleAnswerRemove(id: string): void {
    const answers = this.state.answers.slice().filter(x => x.id !== id);
    const answersStatus = this.getAnswersSummaryStatus(answers);
    this.validateAnswers(answers);
    this.setState({ answers, answersStatus });
  }

  @autobind
  async handleQuestionTextValidationAsync(questionText: string): Promise<boolean> {
    const questionTextStatus: InputStatus = InputStatus.valid();

    if (questionText.trim() === '') {
      questionTextStatus.addErrorCode('field_cant_be_empty');
    } else if (!(await this.props.projectDetailsToolsBl.isUniqueQuestionTextAsync(questionText, this.state.id, this.state.isAttribute))) {
      questionTextStatus.addErrorCode('QUESTION_TEXT_IS_NOT_UNIQUE');
    }

    this.setState({ questionTextStatus });
    return questionTextStatus.isValid === true;
  }

  @autobind
  handleAnswersSummaryValidation(): boolean {
    const answersStatus = this.getAnswersSummaryStatus(this.state.answers);
    this.setState({ answersStatus });
    return answersStatus.isValid === true;
  }

  @autobind
  async validateFormAsync(): Promise<boolean> {
    const isQuestionValid = await this.handleQuestionTextValidationAsync(this.state.questionText);
    const areAnswersValid = this.validateAnswers(this.state.answers);
    const areScopesValid = this.handleScopeValidation(this.state.scopes);
    const questionIsOpenOrAnswersSummaryIsValid = isTextQuestion(this.state.questionType) || this.handleAnswersSummaryValidation();

    return isQuestionValid && areAnswersValid && areScopesValid && questionIsOpenOrAnswersSummaryIsValid;
  }

  @autobind
  validateAnswers(answers: IAnswerModel[]): boolean {
    answers.forEach((a: IAnswerModel) => {
      const answerStatus = InputStatus.valid();
      if (a.text.trim() === '') {
        answerStatus.addErrorCode('field_cant_be_empty');
      }
      if (answers.filter(b => a.text === b.text).length > 1) {
        answerStatus.addErrorCode('project:answer_text_cant_duplicate');
      }
      a.status = answerStatus;
    });

    this.setState({ answers });

    return answers.filter(a => !a.status.isValid).length === 0;
  }

  @autobind
  isValidForm(): boolean {
    return (
      this.state.questionTextStatus.errorCodes.length === 0 &&
      (this.state.questionType === QuestionType.TRUE_FALSE || isTextQuestion(this.state.questionType) || this.state.answers.every(a => a.status.isValid !== false)) &&
      this.state.answersStatus.isValid !== false
    );
  }

  @autobind
  getAnswersSummaryStatus(answers: IAnswerModel[]): InputStatus {
    return answers.length >= MIN_ANSWERS_COUNT ? InputStatus.valid() : new InputStatus(false, ['project:at_least_two_answers_are_required']);
  }

  @autobind
  handleScopeValidation(scopes: string[]): boolean {
    const scopesStatus: InputStatus = InputStatus.valid();
    if (scopes.length === 0) {
      scopesStatus.addErrorCode('project:scope_is_required');
    }
    this.setState({ scopesStatus });
    return scopesStatus.isValid === true;
  }

  @autobind
  handleScopeChange(scopes: { value: string; label: string }[] | null): void {
    const newScopes = scopes ? scopes.map((s: { value: string; label: string }) => s.value) : [];
    this.setState({ scopes: newScopes, scopesStatus: InputStatus.empty() });
  }

  componentDidUpdate(prevProps: IOuterProps) {
    if (this.props.showModal && !prevProps.showModal) this.setState(getInitialState(this.props));
  }

  render() {
    return (
      <Modal className="modal-width-600" isOpen={this.props.showModal} toggle={this.props.onCancel}>
        <ModalHeader toggle={this.props.onCancel}>{this.props.modalHeader}</ModalHeader>
        <ModalBody>
          <QuestionForm
            questionTypes={questionTypes}
            id={this.state.id}
            answers={this.state.answers}
            isRequired={this.state.isRequired}
            questionText={this.state.questionText}
            questionType={this.state.questionType}
            selectedQuestionTypes={this.props.selectedQuestionTypes}
            onQuestionTypeChange={this.handleQuestionTypeChange}
            onQuestionTextChange={this.handleQuestionTextChange}
            onRequiredChange={this.toggleIsRequiredStatus}
            onAnswerAdd={this.handleAnswerAdd}
            onAnswerChange={this.handleAnswerChange}
            onAnswerRemove={this.handleAnswerRemove}
            questionTextStatus={this.state.questionTextStatus}
            answersStatus={this.state.answersStatus}
            isValid={this.isValidForm()}
            isReadonly={this.props.isReadonly}
            isInUse={this.state.isUsed}
            annotationTypes={this.props.projectDetailsToolsBl.store.annotationTypes}
            onScopeChange={this.handleScopeChange}
            scopes={this.state.scopes}
            scopesStatus={this.state.scopesStatus}
            isAttribute={this.state.isAttribute}
          />
        </ModalBody>
        <ModalFooter>
          {!this.state.isEdit && (
            <DisabledWhenWorkspaceOwnerPolicyExceeded>
              <ButtonWithLoader color="primary" onClick={this.handleSubmitAsync} disabled={!this.isValidForm()} loaderKey="submit-question-form-button">
                {this.props.t('add')}
              </ButtonWithLoader>
            </DisabledWhenWorkspaceOwnerPolicyExceeded>
          )}
          {this.state.isEdit && (
            <>
              {!this.props.isReadonly && (
                <DisabledWhenWorkspaceOwnerPolicyExceeded>
                  <ButtonWithLoader className="mr-2" color="success" onClick={this.handleSubmitAsync} disabled={!this.isValidForm()} loaderKey="submit-question-form-button">
                    {this.props.t('save')}
                  </ButtonWithLoader>
                </DisabledWhenWorkspaceOwnerPolicyExceeded>
              )}
              <Button outline color="primary" onClick={this.props.onCancel}>
                {this.props.isReadonly ? this.props.t('close') : this.props.t('cancel')}
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

export const QuestionModalContainer = as<React.ComponentClass<IOuterProps>>(withNamespaces('common')(QuestionModalContainerPure));
