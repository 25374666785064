import ExifReader from 'exifreader';
import BMP from 'bmp-js';
import FileType from 'file-type';

export interface ImageMetadata {
  width: number;
  height: number;
  channels: number | undefined;
  colorType: number | undefined;
}

export async function decodeImageAsync(data: ArrayBuffer | SharedArrayBuffer): Promise<ImageMetadata> {
  const buffer = new Buffer(data);
  const metadata: ImageMetadata = { width: 0, height: 0, channels: undefined, colorType: undefined };
  const fileType = await FileType.fromBuffer(buffer);

  if (fileType) {
    if (['jpg', 'jpeg', 'png'].includes(fileType.ext)) {
      const exifData = ExifReader.load(buffer, { expanded: true });
      metadata.height = exifHeight(exifData);
      metadata.width = exifWidth(exifData);
      metadata.channels = exifData.file?.['Color Components']?.value;
      metadata.colorType = exifData.pngFile?.['Color Type']?.value;

      return metadata;
    }

    if (['bmp'].includes(fileType.ext)) {
      const bmpData = BMP.decode(buffer);
      metadata.height = bmpData.height;
      metadata.width = bmpData.width;

      return metadata;
    }
  }

  throw 'Not supported file type';
}

function exifWidth(meta: ExifReader.ExpandedTags): number {
  return (
    meta.file?.['Image Width']?.value ||
    meta.exif?.ImageWidth.value ||
    meta.iptc?.ImageWidth?.value ||
    meta.pngFile?.['Image Width']?.value ||
    0
  );
}

function exifHeight(meta: ExifReader.ExpandedTags): number {
  return (
    meta.file?.['Image Height']?.value ||
    meta.exif?.ImageLength.value ||
    meta.iptc?.ImageLength?.value ||
    meta.pngFile?.['Image Height']?.value ||
    0
  );
}
