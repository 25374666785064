import './ExpandableTable.scss';

import { DragDropContext, Draggable, DraggableProvided, DropResult, Droppable } from '@qzsoftware/react-beautiful-dnd';
import React, { CSSProperties } from 'react';
import { S_Pagination, S_PaginationProps } from '../../../design/pagination/S_Pagination';
import { faAngleDown, faAngleUp, faBars } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GHeader } from './GHeader';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { handleClickAndPassData } from '../../helpers/formHelpers';

const MIN_CELL_WIDTH = 120;
const DRAG_CELL_WIDTH = 60;

export interface IExpandableTableProps<RowType extends IExpandableRowModel, SubRowType extends IExpandableRowModel> {
  columns: IExpandableColumnProps<RowType>[];
  items: RowType[];
  subColumns?: IExpandableSubColumnProps<SubRowType>[];
  noItemsPlaceholder?: string;
  getRowHeight?(item: RowType): number;
  paginationProps?: S_PaginationProps;
  sortingModel?: IExpandableSortingModel;
  onSortChanged?(orderBy: string, orderType: string): void;
  enableDrag?: boolean;
  onRowDragEnd?(from: number, to: number): void;
}

export interface IExpandableColumnProps<T extends IExpandableRowModel> extends IExpandableGColumnPropsBase<T> {
  headerName: string;
  sortable?: boolean;
  headerTooltip?: string;
  headerClassName?: string;
  headerRenderer?(): JSX.Element;
}

export interface IExpandableSubColumnProps<T extends IExpandableRowModel> extends IExpandableGColumnPropsBase<T> {}

interface IExpandableGColumnPropsBase<T extends IExpandableRowModel> {
  field: string;
  cellClass?: string;
  cellStyle?(item: T): CSSProperties;
  renderer?(item: T): JSX.Element;
  width?: number;
  maxWidth?: number;
  minWidth?: number;
}

export type IExpandableSortingModel = { orderBy: string; orderType: string }[];

interface IExpandableTableState<T extends IExpandableRowModel> {
  containerWidth: number;
  expandedRowsIndexes: number[];
  columns: IExpandableColumnProps<T>[];
}

export interface IExpandableRowModel<SubRowType = any> {
  id: string;
  [field: string]: any;
  subRows?: SubRowType[];
}

interface CellProps {
  key: React.Key;
  className: string;
  style: React.CSSProperties;
}

export class ExpandableTable<T extends IExpandableRowModel<K>, K extends IExpandableRowModel> extends React.Component<IExpandableTableProps<T, K>, IExpandableTableState<T>> {
  constructor(props: IExpandableTableProps<T, K>) {
    super(props);
    this.state = {
      containerWidth: 0,
      expandedRowsIndexes: [],
      columns: this.calculateColumns(),
    };
  }

  private container: any = null;
  private scroll: any = null;
  private isExpandable: boolean = false;

  calculateColumns = (): IExpandableColumnProps<T>[] => {
    const columns = [...this.props.columns];
    if (this.props.enableDrag) {
      columns.unshift({
        field: '',
        headerName: '',
        width: DRAG_CELL_WIDTH,
      });
    }

    if (this.props.items.some(i => i.subRows && i.subRows.length !== 0)) {
      columns.push({
        field: '',
        headerName: '',
        width: DRAG_CELL_WIDTH,
      });
      this.isExpandable = true;
    } else {
      this.isExpandable = false;
    }

    return columns;
  };

  componentDidUpdate(prevProps: IExpandableTableProps<T, K>) {
    if (prevProps.columns !== this.props.columns) {
      this.setState({ columns: this.calculateColumns() });
    }
    if (this.container && this.container.offsetWidth !== this.state.containerWidth) {
      this.setState({ containerWidth: this.container.offsetWidth });
    }
    this.reattachScrollEvent();
  }

  componentDidMount() {
    this.setState({ containerWidth: this.container.offsetWidth });

    const resize = debounce(() => this.container && this.setState({ containerWidth: this.container.offsetWidth }), 300);

    window.addEventListener('resize', () => {
      resize();
    });

    this.reattachScrollEvent();
  }

  reattachScrollEvent() {
    if (!this.scroll) return;
    this.scroll.removeEventListener('scroll', this.handleVerticalScroll);
    this.scroll.addEventListener('scroll', this.handleVerticalScroll);
  }

  handleVerticalScroll = (event: any) => {
    this.container.scrollLeft = event.target.scrollLeft;
  };

  getOnSortChanged(field: string) {
    return (orderType: string) => {
      if (this.props.onSortChanged !== undefined) {
        this.setState({ ...this.state, expandedRowsIndexes: [] });
        this.props.onSortChanged(field, orderType);
      }
    };
  }

  getDefaultCellWidth = (): { defaultCellWidth: number; tableWidth: number } => {
    let totalDefinedWidth = this.state.columns.filter(c => c.width !== undefined).reduce((sum: number, currentItem: IExpandableColumnProps<T>) => sum + currentItem.width!, 0);
    let widthLeft = this.state.containerWidth - totalDefinedWidth;
    let cellsWithUndefinedWidthCount = this.state.columns.filter(c => c.width === undefined).length;
    const undefinedWidthCellWidth = widthLeft / cellsWithUndefinedWidthCount;

    const a = this.state.columns.filter(c => c.maxWidth !== undefined || c.minWidth !== undefined);
    for (const cell of a) {
      if (cell.maxWidth! < undefinedWidthCellWidth) {
        widthLeft = widthLeft - cell.maxWidth!;
        totalDefinedWidth = totalDefinedWidth + cell.maxWidth!;
        cellsWithUndefinedWidthCount -= 1;
      }
      if (cell.minWidth! > undefinedWidthCellWidth) {
        widthLeft = widthLeft - cell.minWidth!;
        totalDefinedWidth = totalDefinedWidth + cell.minWidth!;
        cellsWithUndefinedWidthCount -= 1;
      }
    }

    if (widthLeft > MIN_CELL_WIDTH * cellsWithUndefinedWidthCount) {
      return {
        defaultCellWidth: widthLeft / (cellsWithUndefinedWidthCount || 1),
        tableWidth: this.state.containerWidth,
      };
    }

    return {
      defaultCellWidth: MIN_CELL_WIDTH,
      tableWidth: totalDefinedWidth + MIN_CELL_WIDTH * cellsWithUndefinedWidthCount,
    };
  };

  getWidth = <T extends IExpandableRowModel>(c: IExpandableGColumnPropsBase<T>, defaultWidth: number) => {
    if (c.width !== undefined) {
      return c.width;
    }
    if (c.maxWidth !== undefined) {
      if (c.maxWidth < defaultWidth) {
        return c.maxWidth;
      }
    }
    if (c.minWidth !== undefined) {
      if (c.minWidth > defaultWidth) {
        return c.minWidth;
      }
    }
    return defaultWidth;
  };

  getCellStyle = <T extends IExpandableRowModel>(c: IExpandableGColumnPropsBase<T>, item: T, defaultWidth: number, rowHeight?: number) => {
    return {
      width: this.getWidth(c, defaultWidth),
      height: rowHeight,
      ...(c.cellStyle ? c.cellStyle(item) : {}),
    };
  };

  renderHeaders = (defaultWidth: number) => (
    <>
      {this.state.columns.map((c: IExpandableColumnProps<T>) => {
        const orderType = this.props.sortingModel?.find(sm => sm.orderBy.toLocaleLowerCase() === c.field.toLocaleLowerCase())?.orderType?.toLocaleLowerCase();
        return (
          <GHeader
            key={`header-${c.headerName}`}
            name={c.headerName}
            className={c.headerClassName}
            tooltip={c.headerTooltip}
            width={this.getWidth(c, defaultWidth)}
            onSortChanged={c.sortable === false ? undefined : this.getOnSortChanged(c.field)}
            orderType={orderType}
            headerRenderer={c.headerRenderer}
          />
        );
      })}
    </>
  );

  handleRowClicked = ({ index, item }: { index: number; item: T }) => {
    if (!item.subRows || item.subRows.length === 0) return;
    return this.state.expandedRowsIndexes.every(i => i !== index) ? this.expandRow(index) : this.collapseRow(index);
  };

  expandRow = (index: number) => {
    this.setState(prev => ({ expandedRowsIndexes: [...prev.expandedRowsIndexes, index] }));
  };

  collapseRow = (index: number) => {
    this.setState(prev => ({ expandedRowsIndexes: [...prev.expandedRowsIndexes.filter(i => i !== index)] }));
  };

  renderRows = (defaultWidth: number) => (
    <>
      {this.props.items.map((item: T, itemIndex: number) => {
        const rowHeight = this.props.getRowHeight ? this.props.getRowHeight(item) : undefined;
        const rowIndex =
          itemIndex + this.props.items.filter((_, index) => index < itemIndex && this.state.expandedRowsIndexes.some(i => i === index)).map(item => item.subRows).length;
        return (
          <Draggable
            key={`table-key-${item.id}`}
            draggableId={`table-key-${item.id}`}
            index={itemIndex}
            isDragDisabled={!this.props.enableDrag || this.state.expandedRowsIndexes.some(i => i === itemIndex)}
          >
            {provided => (
              <div {...provided.draggableProps}>
                <div
                  className={classNames({ 'g-expandable-row': item.subRows && item.subRows.length > 0 }, 'g-row')}
                  style={{ height: rowHeight }}
                  ref={provided.innerRef}
                  onClick={handleClickAndPassData(this.handleRowClicked)({ item, index: itemIndex })}
                >
                  {this.state.columns.map((column: IExpandableColumnProps<T>, columnIndex: number) =>
                    this.renderCell(column, columnIndex, item, defaultWidth, rowHeight, itemIndex, provided),
                  )}
                </div>
                {this.state.expandedRowsIndexes.some(i => i === itemIndex) && this.renderSubRows(item, rowHeight, defaultWidth, rowIndex)}
              </div>
            )}
          </Draggable>
        );
      })}
    </>
  );

  renderCell = (
    column: IExpandableColumnProps<T>,
    columnIndex: number,
    item: IExpandableRowModel,
    defaultWidth: number,
    rowHeight: number | undefined,
    itemIndex: number,
    provided: DraggableProvided,
  ) => {
    const cellProps = {
      key: `${column.headerName}-${columnIndex}`,
      className: `g-cell ${column.cellClass || ''}`,
      style: this.getCellStyle(column, item, defaultWidth, rowHeight),
    };

    const isDragColumn = this.props.enableDrag && columnIndex === 0;
    if (isDragColumn) {
      return this.renderDragCell(cellProps, itemIndex, provided);
    }

    const isCollapseIndicatorColumn = columnIndex === this.state.columns.length - 1 && this.isExpandable;
    if (isCollapseIndicatorColumn) {
      return this.renderCollapseIndicatorCell(cellProps, item, itemIndex);
    }

    return this.renderValueCell(cellProps, column, item);
  };

  renderValueCell = <T extends IExpandableRowModel>(props: CellProps, c: IExpandableColumnProps<T>, item: T) => (
    <div key={props.key} className={props.className} style={props.style}>
      {c.renderer ? c.renderer(item) : <span>{item[c.field]}</span>}
    </div>
  );

  renderDragCell = (props: CellProps, itemIndex: number, provided: DraggableProvided) => (
    <div
      key={props.key}
      className={`${props.className} ${this.state.expandedRowsIndexes.some(i => i === itemIndex) ? 'g-drag-handle-disabled' : 'g-drag-handle'}`}
      style={props.style}
      {...provided.dragHandleProps}
    >
      <FontAwesomeIcon icon={faBars} />
    </div>
  );

  renderCollapseIndicatorCell = <T extends IExpandableRowModel>(props: CellProps, item: T, itemIndex: number) => (
    <div key={props.key} className={props.className} style={props.style}>
      {item.subRows && item.subRows.length > 0 && (
        <span>
          <FontAwesomeIcon icon={this.state.expandedRowsIndexes.some(i => i === itemIndex) ? faAngleUp : faAngleDown} />
        </span>
      )}
    </div>
  );

  renderSubRows = (item: IExpandableRowModel, rowHeight: number | undefined, defaultWidth: number, rowIndex: number) => {
    return (
      <div>
        {item.subRows &&
          item.subRows.map((subItem, index) => (
            <div key={`sub-item-connector-${subItem.id}`}>
              <div
                className={classNames({ ' connector-last': index === item.subRows!.length - 1 }, 'connector connector-top-to-bottom')}
                style={{ top: 100 + (rowIndex + index) * 62.25 }}
              />
              <div className="connector connector-left-to-right" style={{ top: 105 + (rowIndex + index) * 62.25 }} />
            </div>
          ))}
        {item.subRows &&
          item.subRows.map(subItem => (
            <div className="g-row g-row-sub" style={{ height: rowHeight }} key={`table-key-sub-item-${subItem.id}`}>
              {this.props.subColumns!.map((c: IExpandableSubColumnProps<K>, index: number) => {
                const cellStyle = this.getCellStyle(c, subItem, defaultWidth, rowHeight);
                if (index === 0 && !this.props.enableDrag) {
                  cellStyle.width = (cellStyle.width as number) - DRAG_CELL_WIDTH;
                }
                return (
                  <div key={`${subItem.id}-${index}`} className={`g-cell ${c.cellClass || ''}`} style={cellStyle}>
                    {c.renderer ? c.renderer(subItem) : <span>{subItem[c.field]}</span>}
                  </div>
                );
              })}
            </div>
          ))}
      </div>
    );
  };

  renderNoItemsPlaceholder = () => this.props.noItemsPlaceholder && <div className="g-empty">{this.props.noItemsPlaceholder}</div>;

  handleDragEnd = ({ source, destination }: DropResult) => {
    /* 
    After ZIL-2488 dragging is not expected to work with expandable table.
    Code underneath implemented dragging when only one row could be expanded:
    
    if (destination && this.props.onRowDragEnd) {
      this.props.onRowDragEnd(source.index, destination.index);
      if (source.index !== destination.index && this.state.expandedRow !== undefined) {
        if (source.index > this.state.expandedRow && destination.index <= this.state.expandedRow) {
          //this.setState(prev => ({ expandedRow: prev.expandedRow! + 1 }));
        }
        if (source.index < this.state.expandedRow && destination.index >= this.state.expandedRow) {
          //this.setState(prev => ({ expandedRow: prev.expandedRow! - 1 }));
        }
      }
    }
    */
  };

  render() {
    const widths = this.getDefaultCellWidth();
    return (
      <>
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <div className="g-table-container" ref={(ref: any) => (this.container = ref)}>
            {this.state.containerWidth !== 0 && (
              <>
                <Droppable droppableId="g-droppable">
                  {provided => (
                    <div className="g-table g-expandable-table" style={{ width: widths.tableWidth }} ref={provided.innerRef} {...provided.droppableProps}>
                      <div className="g-headers" style={{ minWidth: this.state.containerWidth }}>
                        {this.renderHeaders(widths.defaultCellWidth)}
                      </div>
                      <div className="g-rows" style={{ minWidth: this.state.containerWidth }}>
                        {this.props.items.length ? this.renderRows(widths.defaultCellWidth) : this.renderNoItemsPlaceholder()}
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                {this.props.paginationProps && <S_Pagination {...this.props.paginationProps} />}
              </>
            )}
          </div>
        </DragDropContext>
        {this.state.containerWidth < widths.tableWidth && (
          <div className="g-vertical-scroll-container" style={{ width: this.state.containerWidth }} ref={(ref: any) => (this.scroll = ref)}>
            <div className="g-vertical-scroll" style={{ width: widths.tableWidth }}>
              The Elder Scroll
            </div>
          </div>
        )}
      </>
    );
  }
}
