import { Bar } from 'react-chartjs-2';
import { IAnnotationStatusStatisticsDto } from '../projectDetailsStatistics.model';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../../../helpers/react.helpers';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

library.add(faChartBar);

interface IAnnotationProgressChartProps {
  annotationStatus?: IAnnotationStatusStatisticsDto;
  translateNames?: boolean;
}

@observer
class AnnotationStatusChartPure extends React.Component<IAnnotationProgressChartProps & ITranslatable> {
  render() {
    const { t } = { ...this.props };

    if (!this.props.annotationStatus) return <></>;

    const segmentationData = {
      labels: [t('awaiting_review'), t('accepted'), t('rejected')],
      datasets: [
        {
          backgroundColor: '#00478d',
          hoverBackgroundColor: '#00478d',
          data: [this.props.annotationStatus.toReview, this.props.annotationStatus.accepted, this.props.annotationStatus.rejected],
        },
      ],
    };

    return (
      <div className="statistic-wrapper">
        <h5>{this.props.translateNames ? this.props.t(this.props.annotationStatus.name) : this.props.annotationStatus.name}</h5>
        <div className="statistics-body">
          <div className="statistics-summary">
            <div className="list">
              <span>
                {t('awaiting_review')}: {this.props.annotationStatus.toReview}
              </span>
              <span>
                {t('accepted')}: {this.props.annotationStatus.accepted}
              </span>
              <span>
                {t('rejected')}: {this.props.annotationStatus.rejected}
              </span>
            </div>
          </div>
          <div className="chart-wrapper">
            <div className="statistics-chart">
              <Bar
                data={segmentationData}
                options={{
                  maintainAspectRatio: false,
                  legend: {
                    display: false,
                  },
                  tooltips: {
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                      label(tooltipItem: any) {
                        return `${tooltipItem.yLabel}`;
                      },
                    },
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          suggestedMin: 0,
                        },
                      },
                    ],
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const AnnotationStatusChart = as<React.ComponentClass<IAnnotationProgressChartProps>>(withNamespaces('common', { wait: true })(AnnotationStatusChartPure));
