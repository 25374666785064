import { IAnnotationDto } from '../../../__legacy__/modules/annotation/annotations.interface';
import assert from 'assert';

export class EditAndAcceptAnnotationRequest {
  id: string;
  annotation: IAnnotationDto;
  duration: number;

  constructor(annotation: IAnnotationDto, duration: number, id: string) {
    this.annotation = annotation;
    this.duration = duration;
    this.id = id;
  }
}

export class EditAndRejectAnnotationRequest {
  id: string;
  annotation: IAnnotationDto;
  duration: number;
  rejectionReason: string;

  constructor(rejectionReason: string | undefined = '', annotation: IAnnotationDto, duration: number, id: string) {
    this.annotation = annotation;
    this.duration = duration;
    this.id = id;
    assert(rejectionReason !== undefined, 'No reason provided on rejection');
    this.rejectionReason = rejectionReason;
  }
}

export interface ISaveAnnotationDraftRequest {
  id: string;
  projectId: string;
  imageId: string;
  duration: number;
  annotation: IAnnotationDto;
}

export interface ISubmitAnnotationForReviewRequest {
  id: string;
  projectId: string;
  imageId: string;
  duration: number;
  annotation: IAnnotationDto;
}

export interface IEditAnnotationRequest {
  id: string;
  annotation: IAnnotationDto;
  duration: number;
}

export interface ICorrectAnnotationRequest {
  id: string;
  annotation: IAnnotationDto;
  duration: number;
}

export interface ICorrectAndSubmitAnnotationForReviewRequest {
  id: string;
  annotation: IAnnotationDto;
  duration: number;
}

export interface IAbandonDraftRequest {
  id: string;
  annotation: IAnnotationDto | null;
  duration: number;
}

export interface IRequestClarificationDuringAnnotationRequest {
  id: string;
  projectId: string;
  imageId: string;
  question: string;
  annotation: IAnnotationDto;
  duration: number;
}

export interface IRequestClarificationDuringReviewRequest {
  id: string;
  projectId: string;
  imageId: string;
  question: string;
  duration: number;
}

export interface IRequestClarificationDuringReviewEditRequest {
  id: string;
  projectId: string;
  imageId: string;
  question: string;
  annotation: IAnnotationDto;
  duration: number;
}

export interface IRequestClarificationDuringFreeAccessEditRequest {
  id: string;
  projectId: string;
  imageId: string;
  question: string;
  annotation: IAnnotationDto;
  duration: number;
}

export interface IAcceptAnnotationRequest {
  id: string;
  duration: number;
}

export interface IRejectAnnotationRequest {
  id: string;
  duration: number;
  reason: string;
}

export interface IDiscardAnnotationDuringAnnotationRequest {
  id: string;
  duration: number;
}

export interface IDiscardAnnotationDuringReviewRequest {
  id: string;
  duration: number;
}

export interface IDiscardAnnotationDuringReviewEditRequest {
  id: string;
  duration: number;
}

export interface IDiscardAnnotationDuringFreeAccessEditRequest {
  id: string;
  duration: number;
}
