import * as React from 'react';

import { AuthContainer } from '../../modules/auth/containers/AuthContainer';
import { AuthLogo } from '../../modules/auth/components/AuthLogo';
import ConfirmEmailContainer from '../../modules/auth/containers/ConfirmEmailContainer';
import ConfirmEmailResend from '../../modules/auth/components/emailConfirmation/ConfirmEmailResend';

export const ResendConfirmationEmailView = () => (
  <div>
    <AuthContainer>
      <ConfirmEmailContainer>
        <AuthLogo />
        <ConfirmEmailResend />
      </ConfirmEmailContainer>
    </AuthContainer>
  </div>
);
