import { AttachmentsServiceType, IAttachmentsService } from '../attachments.service';
import { IUserStore, UserStoreType } from '../../user/user.store';
import { IWorkspacesStore, WorkspaceRole, WorkspacesStoreType } from '../../workspaces/workspaces.store';
import { as, injectProps } from '../../../helpers/react.helpers';

import { Button } from 'reactstrap';
import { EnableForRole } from '../../../containers/EnableForRole';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  attachmentsService: IAttachmentsService;
  workspacesStore: IWorkspacesStore;
  userStore: IUserStore;
}

library.add(faTrashAlt);

@injectProps({
  attachmentsService: AttachmentsServiceType,
  workspacesStore: WorkspacesStoreType,
  userStore: UserStoreType,
})
@observer
class AttachmentDeleteContainerPure extends React.Component<IInjectedProps & ITranslatable> {
  constructor(props: IInjectedProps & ITranslatable) {
    super(props);
  }

  onDelete = () => this.props.attachmentsService.toggleDeleteAttachmentModal();

  render() {
    const isDisabled = !this.props.attachmentsService.store.attachments.length ||
      (!this.props.attachmentsService.store.selectedAttachments.isAllSelected &&
        this.props.attachmentsService.store.selectedAttachments.ids &&
        !this.props.attachmentsService.store.selectedAttachments.ids.length);
    const canView = this.props.attachmentsService.canUseAttachments();

    return (
      <EnableForRole
        workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager]}
        projectRoles={[]}
      >
        <Button
          className={'attachments-button delete'}
          color={'primary'}
          onClick={this.onDelete}
          title={canView ? '' : this.props.t('you_do_not_have_permission_to_perform_this_operation')}
          disabled={!canView || isDisabled}
        >
          <FontAwesomeIcon icon={faTrashAlt} /> {this.props.t('delete_attachments')}
        </Button>
      </EnableForRole>
    );
  }
}

export const AttachmentDeleteContainer = as<React.ComponentClass>(withNamespaces('attachment', { wait: true })(AttachmentDeleteContainerPure));
