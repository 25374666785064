import * as React from 'react';

import { AttachmentUploadServiceType, IAttachmentUploadService } from '../attachmentUploadService';
import { as, injectProps } from '../../../helpers/react.helpers';

import { AttachmentUploadDropdown } from '../components/AttachmentsUploadDropdown';
import { observer } from 'mobx-react';

interface IInjectedProps {
  attachmentUploadService: IAttachmentUploadService;
}

interface IState {
  isModalOpen: boolean;
  attachmentUploadToCancel: string;
}

@injectProps({
  attachmentUploadService: AttachmentUploadServiceType,
})
@observer
class AttachmentUploadDropdownContainer extends React.Component<IInjectedProps, IState> {

  state: IState = {
    isModalOpen: false,
    attachmentUploadToCancel: '',
  };

  handleCancelUpload = (attachmentId: string) => {
    this.setState({ isModalOpen: true, attachmentUploadToCancel: attachmentId });
  }

  handleCancelUploadConfirm = () => {
    this.props.attachmentUploadService.cancelUpload(this.state.attachmentUploadToCancel);
    this.handleToggleConfirmationModal();
  }

  handleToggleConfirmationModal = () => {
    this.setState({ isModalOpen: false, attachmentUploadToCancel: '' });
  }

  render() {
    return (
      <AttachmentUploadDropdown
        progressData={this.props.attachmentUploadService.data.progressData}
        showConfirmModal={this.state.isModalOpen}
        onCancelUpload={this.handleCancelUpload}
        onCancelUploadConfirm={this.handleCancelUploadConfirm}
        onToggleConfirmModal={this.handleToggleConfirmationModal}
      />
    );
  }
}

export default as<React.ComponentClass>(AttachmentUploadDropdownContainer);
