import * as React from 'react';

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../workspaces/currentWorkspace/CurrentWorkspace.store';
import { IProjectOverviewBl, ProjectOverviewBlType } from './projectOverview.bl';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../../__legacy__/helpers/react.helpers';

import { AnnotatingOverviewWidget } from './AnnotatingOverview';
import { WithLoaderComponentBase } from '../../../../__legacy__/helpers/loader.helpers';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ projectId: string }> {
  projectOverviewBl: IProjectOverviewBl;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

@injectProps({
  projectOverviewBl: ProjectOverviewBlType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
@observer
class AnnotatingOverviewContainerPure extends WithLoaderComponentBase<IInjectedProps> {
  componentDidMount() {
    this.withLoaderAsync(async () => {
      await this.props.projectOverviewBl.getProjectDetailsAnnotatingAsync(this.props.match.params.projectId);
    }, 'project-overview');
  }

  render() {
    const store = this.props.projectOverviewBl.store;

    return (
      <AnnotatingOverviewWidget
        isLoading={this.state.isLoading}
        projectId={this.props.match.params.projectId}
        acceptedCount={store.acceptedCount}
        draftsCount={store.draftsCount}
        totalImageCount={store.totalImageCount}
        waitingForAnnotationCount={store.waitingForAnnotationCount}
        toReviewCount={store.toReviewCount}
        workspaceId={this.props.currentWorkspaceStore.currentWorkspace!.id}
      />
    );
  }
}

export const AnnotatingOverviewContainer = as<React.ComponentClass>(withRouter(AnnotatingOverviewContainerPure));
