import { ContainerModule, interfaces } from 'inversify';
import { IRejectionReasonService, RejectionReasonService, RejectionReasonServiceType } from './services/rejectionReason.service';
import { IReviewRejectReasonsStore, ReviewRejectReasonsStore, ReviewRejectReasonsStoreType } from './reviewRejectReasons.store';
import { IReviewRejectionReasonBl, ReviewRejectionReasonBl, ReviewRejectionReasonBlType } from './reviewRejectionReason.bl';

export const reviewRejectReasonsModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IReviewRejectReasonsStore>(ReviewRejectReasonsStoreType)
    .to(ReviewRejectReasonsStore)
    .inSingletonScope();

  bind<IRejectionReasonService>(RejectionReasonServiceType).to(RejectionReasonService).inSingletonScope();

  bind<IReviewRejectionReasonBl>(ReviewRejectionReasonBlType).to(ReviewRejectionReasonBl).inSingletonScope();
});
