import * as React from 'react';

import { ITranslatable } from '../../../helpers/translations.helpers';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  score: number | null;
}

const PasswordScoreMeterPure = (props: IProps & ITranslatable) => {
  function comment() {
    switch (props.score) {
      case null:
        return null;
      case 0:
        return <span className="password-comment very-weak">{props.t('very_weak')}</span>;
      case 1:
        return <span className="password-comment weak">{props.t('weak')}</span>;
      case 2:
        return <span className="password-comment acceptable">{props.t('acceptable')}</span>;
      case 3:
        return <span className="password-comment good">{props.t('good')}</span>;
      default:
        return <span className="password-comment very-good">{props.t('very_good')}</span>;
    }
  }

  return (
    <div className="password-score-meter-container">
      <span className={`password-score-meter-item ${props.score !== null ? ' very-weak' : ''}`} />
      <span className={`password-score-meter-item ${props.score && props.score > 0 ? ' weak' : ''}`} />
      <span className={`password-score-meter-item ${props.score && props.score > 1 ? ' acceptable' : ''}`} />
      <span className={`password-score-meter-item ${props.score && props.score > 2 ? ' good' : ''}`} />
      <span className={`password-score-meter-item ${props.score && props.score > 3 ? ' very-good' : ''}`} />
      {comment()}
    </div>
  );
};

export const PasswordScoreMeter = as<React.SFC<IProps>>(withNamespaces('auth')(PasswordScoreMeterPure));
