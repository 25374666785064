import { AlertBarStoreType, IAlertBarStore } from '../../../../modules/alertBar/AlertBar.store';
import { AnnotationCreationServiceType, IAnnotationCreationService } from '../annotationCreation.service';
import { AnnotationImagesQueueService, AnnotationImagesQueueServiceType } from '../annotationImagesQueue.service';
import { AnnotationUiStoreType, IAnnotationUiStore } from '../annotationUi.store';
import { AnnotationsStoreType, IAnnotationsStore } from '../annotations.store';
import { IImage, NoImageReason } from '../annotations.interface';
import { IWorkspacesStore, WorkspacesStoreType } from '../../workspaces/workspaces.store';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import React from 'react';
import { alertBarHeight } from '../../../../modules/alertBar/ui/AlertBar';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

export interface ICreateAnnotationRenderProps {
  noImage: boolean;
  noImageReason: NoImageReason;
  marginTop: number;
  image?: IImage;
  handleRefresh(): Promise<void>;
}
interface ICreateAnnotationContainerProps {
  children(props: ICreateAnnotationRenderProps): React.ReactNode;
}

interface IInjectedProps extends RouteComponentProps<{ projectId: string }> {
  annotationUiStore: IAnnotationUiStore;
  annotationCreationService: IAnnotationCreationService;
  imagesQueueService: AnnotationImagesQueueService;
  workspaceStore: IWorkspacesStore;
  annotationsStore: IAnnotationsStore;
  alertsBarStore: IAlertBarStore;
}

@injectProps({
  annotationUiStore: AnnotationUiStoreType,
  annotationCreationService: AnnotationCreationServiceType,
  imagesQueueService: AnnotationImagesQueueServiceType,
  workspaceStore: WorkspacesStoreType,
  annotationsStore: AnnotationsStoreType,
  alertsBarStore: AlertBarStoreType,
})
@observer
class CreateAnnotationContainerPure extends React.Component<ICreateAnnotationContainerProps & IInjectedProps> {
  get noImage() {
    return this.props.annotationsStore.image === undefined && !this.props.annotationUiStore.isImageLoading && !this.props.imagesQueueService.isAnyRequestInProgress;
  }

  async componentDidMount() {
    await this.setupService();
  }

  componentWillUnmount() {
    this.props.annotationCreationService.dispose();
  }

  @autobind
  async handleRefresh() {
    await this.setupService();
  }

  private async setupService() {
    const projectId = this.props.match.params.projectId;
    this.props.imagesQueueService.setupAsync(projectId);
    await this.props.annotationCreationService.setupAsync(projectId, this.props.imagesQueueService);
  }

  render() {
    return this.props.children({
      noImage: this.noImage,
      noImageReason: this.props.annotationsStore.noImageReason,
      marginTop: alertBarHeight(this.props.alertsBarStore.alerts.length),
      image: this.props.annotationsStore.image,
      handleRefresh: this.handleRefresh,
    });
  }
}

export const CreateAnnotationContainer = as<React.ComponentClass<ICreateAnnotationContainerProps>>(withRouter(CreateAnnotationContainerPure));
