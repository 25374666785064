import { EditMarkingToolBl, EditMarkingToolBlType } from '../../EditMarkingTool.bl';
import { EditMarkingToolPermissions, EditMarkingToolPermissionsType } from '../../EditMarkingTool.permissions';
import { EditMarkingToolStore, EditMarkingToolStoreType } from '../../EditMarkingTool.store';
import { as, injectProps } from '../../../../../../../../__legacy__/helpers/react.helpers';

import { NameField } from '../../../commons/ui/fields/NameField';
import React from 'react';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: EditMarkingToolBl;
  store: EditMarkingToolStore;
  permissions: EditMarkingToolPermissions;
}

@injectProps({
  bl: EditMarkingToolBlType,
  store: EditMarkingToolStoreType,
  permissions: EditMarkingToolPermissionsType,
})
@observer
class NameFieldContainerPure extends React.Component<InjectedProps> {
  render() {
    return (
      <NameField changeName={this.props.bl.changeName} name={this.props.store.name} nameStatus={this.props.store.nameStatus} disabled={!this.props.permissions.canChangeName} />
    );
  }
}

export const NameFieldContainer = as<React.ComponentClass>(NameFieldContainerPure);
