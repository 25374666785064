import { IEvaluationConfig } from './evaluationDetails.store';
import { ImageSetType } from '../../__legacy__/modules/projectDetails/sub/images/projectDetailsImages.model';
import { EVALUATION_STATUS } from '../evaluations/evaluations.model';
import { GROUND_TRUTH } from '../../__legacy__/models/predictions.model';
import { IParameterDto } from '../../__legacy__/helpers/parameters.helpers';
import { MODEL_VARIANTS } from '../../__legacy__/models/metrics.model';

export interface IEvaluationDetailsDto {
  id: string;
  inference_name: string;
  inference_description: string | null;
  date_inference: string;
  status: EVALUATION_STATUS;
  failure_reason: string | null;
  datasets: {
    project_id: string;
    datasets_ids: string[];
  };
  inference_config_: IEvaluationConfig;
  training_job_id: string;
  is_owner: boolean;
  is_training_owner: boolean;
  model_variant: MODEL_VARIANTS;
}

export interface IEvaluationImageDto {
  id: string;
  name: string;
  imageSet: ImageSetType;
  url: string;
}

export interface IEvaluationImageMetricsDto {
  confusion_matrix: IConfusionMatrix[] | null;
  recall: number | null;
  precision: number | null;
  f1: number | null;
  auc: number | null;
  count: number | null;
  precision_recall_curve: Point2D[] | null;
  score: number | null;
}

export interface IEvaluationImagePredictionDto {
  ground_truth: GROUND_TRUTH;
  heatmap_url: string;
  metrics: IEvaluationImageMetricsDto;
}

export interface IEvaluationImagePredictionsDto {
  [k: string]: IEvaluationImagePredictionDto;
}

export interface IEvaluationSettingsParamsDto {
  params: IParameterDto[];
}

export type SelectOption = {
  label: string;
  value: string;
};

export type ModelVariantOption = {
  label: string;
  value: MODEL_VARIANTS;
};

export type BooleanOption = {
  label: string;
  value: boolean;
};

export interface Point2D {
  x: number;
  y: number;
}

export interface IConfusionMatrix {
  id: number;
  name: string;
  good: number;
  weak: number;
  bad: number;
}

export interface IResult {
  name: string;
  train: number;
  validation: number;
  test: number;
}

export interface IEvaluationMetricsDto {
  metrics: {
    confusion_matrix: IConfusionMatrix[];
    precision_recall_curve: Point2D[];
    results: IResult[];
    auc: number;
  };
}

export const EVALUATION_RESULTS_INTEGERS = ['count'];

export interface IEvaluationStatusDto {
  status: EVALUATION_STATUS;
  starting_progress: '1/4' | '2/4' | '3/4' | null;
}
