import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { IUserStore, Plan, UserStoreType } from '../../../../user/user.store';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { LegacyInformationRow } from '../components/LegacyInformationRow.container';
import { OverallStatistics } from '../components/OverallStatistics';
import { PlanUpgradeRequiredMessage } from '../../../../../components/PlanUpgradeRequiredMessage';
import React from 'react';
import { WorkspaceRole } from '../../../../workspaces/workspaces.store';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IProps {
  onChangeViewClicked(): void;
}

interface IInjectedProps extends ITranslatable {
  userStore: IUserStore;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

@injectProps({
  userStore: UserStoreType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
@observer
class LegacyStatisticsContainerPure extends React.Component<IProps & IInjectedProps> {
  render() {
    const workspace = this.props.currentWorkspaceStore.currentWorkspace;
    const isOwner = workspace?.role === WorkspaceRole.Owner;
    const canViewStatistics = workspace?.ownerPlanTier === Plan.Enterprise || workspace?.ownerPlanTier === Plan.Admin || false;

    return canViewStatistics ? (
      <>
        <LegacyInformationRow onClick={this.props.onChangeViewClicked} />
        <OverallStatistics />
      </>
    ) : (
      <PlanUpgradeRequiredMessage
        message={this.props.t('detailed_statistics_are_only_available_in_enterprise_plan')}
        linkText={isOwner ? this.props.t('upgrade_now') : this.props.t('ask_your_workspace_owner_to_upgrade_now')}
      />
    );
  }
}

export const LegacyStatisticsContainer = as<React.ComponentClass<IProps>>(withNamespaces('common')(LegacyStatisticsContainerPure));
