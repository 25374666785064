import { faBullseye, faDrawPolygon, faLongArrowAltRight, faPaintBrush, faQuestionCircle, faSlash, faTag, faVectorSquare } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';

export const enum IconType {
  MarkingToolPoint = 'MarkingToolPoint',
  MarkingToolRectangle = 'MarkingToolRectangle',
  MarkingToolVector = 'MarkingToolVector',
  MarkingToolPolyline = 'MarkingToolPolyline',
  MarkingToolPolygon = 'MarkingToolPolygon',
  MarkingToolRotatedRectangle = 'MarkingToolRotatedRectangle',
  MarkingToolBrush = 'MarkingToolBrush',
  Attribute = 'Attribute',
  Question = 'Question',
}

library.add(faBullseye, faVectorSquare, faLongArrowAltRight, faSlash, faDrawPolygon, faVectorSquare, faPaintBrush, faTag, faQuestionCircle);

export const AnnotationToolIcon: React.FC<{ iconType: IconType; tooltip: string; color?: string }> = ({ iconType, tooltip, color }) => (
  <>
    {iconType === IconType.MarkingToolPoint && <FontAwesomeIcon className="annotation-tool-icon marking-tool-icon" icon={faBullseye} style={{ color: `${color}` }} title={tooltip} />}
    {iconType === IconType.MarkingToolRectangle && <FontAwesomeIcon className="annotation-tool-icon marking-tool-icon" icon={faVectorSquare} style={{ color }} title={tooltip} />}
    {iconType === IconType.MarkingToolVector && <FontAwesomeIcon className="annotation-tool-icon marking-tool-icon" icon={faLongArrowAltRight} style={{ color }} title={tooltip} />}
    {iconType === IconType.MarkingToolPolyline && <FontAwesomeIcon className="annotation-tool-icon marking-tool-icon" icon={faSlash} style={{ color }} title={tooltip} />}
    {iconType === IconType.MarkingToolPolygon && <FontAwesomeIcon className="annotation-tool-icon marking-tool-icon" icon={faDrawPolygon} style={{ color }} title={tooltip} />}
    {iconType === IconType.MarkingToolRotatedRectangle && (
      <FontAwesomeIcon className="annotation-tool-icon marking-tool-icon rotated-rectangle" icon={faVectorSquare} style={{ color }} title={tooltip} />
    )}
    {iconType === IconType.MarkingToolBrush && <FontAwesomeIcon className="annotation-tool-icon marking-tool-icon" icon={faPaintBrush} style={{ color }} title={tooltip} />}
    {iconType === IconType.Attribute && <FontAwesomeIcon className="annotation-tool-icon attribute-icon" icon={faTag} title={tooltip} />}
    {iconType === IconType.Question && <FontAwesomeIcon className="annotation-tool-icon question-icon" icon={faQuestionCircle} title={tooltip} />}
  </>
);
