import * as React from 'react';

import { Col, Container, Row } from 'reactstrap';
import { faAngleLeft, faArrowLeft, faRedo, faUndo } from '@fortawesome/free-solid-svg-icons';

import { AnnotationPreviewContainer } from '../../../modules/projectDetails/containers/AnnotationPreview.container';
import { AnnotationTopBarItem } from '../../../modules/annotation/components/AnnotationTopBarItem';
import { AnnotationTypesContainer } from '../../../modules/annotation/submodules/annotationTypes/containers/AnnotationTypes.container';
import { AnnotationViewOptions } from '../../../modules/annotation/components/AnnotationViewOptions';
import { DrawingContainer } from '../../../modules/annotation/containers/Drawing.container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HiddenAnnotationsToastContainer } from '../../../containers/HiddenAnnotationsToast.container';
import { Home } from '../../../routes/config/Home';
import { ITranslatable } from '../../../helpers/translations.helpers';
import Image from '../../../modules/annotation/components/Image';
import ImageContainer from '../../../modules/annotation/containers/Image.container';
import { ImageSegmentationsContextProvider } from '../../../modules/annotation/submodules/segmentations/segmentationContextMenu/imageSegmentations.context';
import { LeftMenuContainer } from '../../../modules/annotation/submodules/segmentations/containers/LeftMenu.container';
import { Link } from 'react-router-dom';
import PreviewControlsContainer from '../../../modules/annotation/containers/PreviewControls.container';
import QuestionsContainer from '../../../modules/annotation/containers/Questions.container';
import QuestionsList from '../../../modules/annotation/components/annotations/QuestionsList';
import ReturnToToolsContainer from '../../../modules/annotation/containers/ReturnToTools.container';
import { RouteComponentProps } from 'react-router';
import { SegmentationsContainer } from '../../../modules/annotation/containers/Segmentations.container';
import SegmentationsSaveContainer from '../../../modules/annotation/containers/SegmentationsSave.container';
import { UndoRedoAnnotationsShortcutsContainer } from '../../../modules/annotation/containers/UndoRedoAnnotationsShortcuts.container';
import ViewAttributesButton from '../../../modules/annotation/components/ViewAttributesButton';
import ViewAttributesContainer from '../../../modules/annotation/containers/ViewAttributesContainer';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';

library.add(faUndo, faRedo, faAngleLeft);

const AnnotationPreviewPure = (props: ITranslatable & RouteComponentProps<{ workspaceId: string; projectId: string }>) => (
  <div className="annotation-view">
    <AnnotationPreviewContainer>
      {annotationPreviewProps => (
        <ImageSegmentationsContextProvider imageId={annotationPreviewProps.image?.id}>
          <div>
            <div className="annotation-view-top-bar" style={{ marginTop: annotationPreviewProps.marginTop }}>
              <Container fluid>
                <AnnotationTopBarItem variation="labeled-icon">
                  <Link
                    to={Home.Projects.Details.AnnotationView.withParams({ workspaceId: props.match.params.workspaceId, projectId: props.match.params.projectId })}
                    className="btn btn-link"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} /> {props.t('back')}
                  </Link>
                </AnnotationTopBarItem>
                <AnnotationTopBarItem>
                  <UndoRedoAnnotationsShortcutsContainer undo={true} />
                </AnnotationTopBarItem>
                <AnnotationTopBarItem>
                  <UndoRedoAnnotationsShortcutsContainer undo={false} />
                </AnnotationTopBarItem>
                <AnnotationTopBarItem variation="labeled-icon">
                  <AnnotationViewOptions disableCursorConfig={false} />
                </AnnotationTopBarItem>
                <AnnotationTopBarItem variation="show-attributes">
                  <ViewAttributesContainer>
                    <ViewAttributesButton />
                  </ViewAttributesContainer>
                </AnnotationTopBarItem>
                <AnnotationTopBarItem variation="preview-mode">
                  <p>
                    <FontAwesomeIcon icon="info-circle" /> {props.t('your_are_in_preview_mode')}
                  </p>
                </AnnotationTopBarItem>
              </Container>
            </div>
            <div className="annotation-view-inner" style={{ marginTop: annotationPreviewProps.marginTop + 70, height: `calc(100vh - ${70 + annotationPreviewProps.marginTop}px)` }}>
              <Container fluid>
                <Row>
                  <LeftMenuContainer currentProjectId={props.match.params.projectId} isReadOnly={true} showObjectsList={true} isReview={false} />
                  <Col className="px-0">
                    <HiddenAnnotationsToastContainer />
                    <ImageContainer>
                      <Image>
                        <SegmentationsContainer readonly={false} />
                        <DrawingContainer disableCursorGuides={false} />
                      </Image>
                    </ImageContainer>
                  </Col>
                  <div className="right-column">
                    <div className="scrollable-container">
                      <ReturnToToolsContainer />
                      <div className="scrollable-container-content">
                        <AnnotationTypesContainer />
                        <QuestionsContainer>
                          <QuestionsList />
                        </QuestionsContainer>
                        <SegmentationsSaveContainer />
                      </div>
                    </div>
                    <div className="set-navigation-container text-center">
                      <PreviewControlsContainer>
                        <Link
                          className="back"
                          to={Home.Projects.Details.AnnotationView.withParams({ workspaceId: props.match.params.workspaceId, projectId: props.match.params.projectId })}
                        >
                          <FontAwesomeIcon icon="angle-left" /> {props.t('back_to_wizard')}
                        </Link>
                      </PreviewControlsContainer>
                    </div>
                  </div>
                </Row>
              </Container>
            </div>
          </div>
        </ImageSegmentationsContextProvider>
      )}
    </AnnotationPreviewContainer>
  </div>
);

export const AnnotationPreview = withNamespaces('annotation')(AnnotationPreviewPure) as any;
