import { IPagingInfo, PaginationInfoDefault } from '../../../../models/paginationInfo.model';

import { DatasetStatus } from '../../../datesets/datasetStatus.model';

export const ImagesDefaultPaging = {
  ...PaginationInfoDefault,
  totalImagesCount: 0,
  unlockedImagesCount: 0,
};

export interface IImagesPagingInfo extends IPagingInfo {
  totalImagesCount: number;
  unlockedImagesCount: number;
}

export interface IProjectDetailsImage {
  id: string;
  name: string;
  datasetName: string;
  datasetStatus: DatasetStatus;
  status: ImageAnnotationStatus;
  url: string;
  annotatedBy: string | null;
  reviewedBy: string | null;
  wasImageCorrected: boolean | null;
  dateOfMarking: string | null;
  dateOfApproval: string | null;
  isLocked: boolean;
  imageSet: ImageSetType;
  workTime: string;
}

export type ImageSelectionMode = 'Select' | 'Deselect';

export enum ImageSetType {
  NotSet = 'NotSet',
  Train = 'Train',
  Validation = 'Validation',
  Test = 'Test',
  Private = 'Private',
  Ignore = 'Ignore',
}

export enum ImageAnnotationStatus {
  NONE = 'None',
  CLARIFICATION = 'Clarification',
  DRAFT = 'Draft',
  REJECTED = 'Rejected',
  TOREVIEW = 'ToReview',
  ACCEPTED = 'Accepted',
  DISCARDED = 'Discarded',
}
