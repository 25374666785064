import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faArrowUp, faArrowDown);

export interface IGHeaderProps {
  name: string;
  width: number;
  className?: string;
  tooltip?: string;
  orderType?: string;
  sortingOrderTypes?: string[];
  onSortChanged?(orderType: string): void;
  headerRenderer?(): JSX.Element;
}

interface IGHeaderState {
  sortingOrderTypes: string[];
}

export class GHeader extends React.Component<IGHeaderProps, IGHeaderState> {
  constructor(props: IGHeaderProps) {
    super(props);
    this.state = {
      sortingOrderTypes: ['', 'asc', 'desc'],
    };
  }

  handleSortingClick = () => {
    if (!this.props.onSortChanged) return;

    let index = this.state.sortingOrderTypes!.findIndex(o => o === this.props.orderType);

    if (index === -1) index = 0;

    const order = this.state.sortingOrderTypes![(index + 1) % 3];

    this.props.onSortChanged!(order);
  }

  render() {
    const orderTypeLower = this.props.orderType?.toLocaleLowerCase();
    return (
      <div
        className={`g-header ${this.props.className !== undefined ? this.props.className : ''} ${this.props.onSortChanged ? 'sortable' : ''}`}
        style={{ width: this.props.width }}
        title={this.props.tooltip}
        onClick={this.handleSortingClick}
      >
        {this.props.headerRenderer ? this.props.headerRenderer() : this.props.name}
        {orderTypeLower === 'asc' && <FontAwesomeIcon icon={faArrowUp} />}
        {orderTypeLower === 'desc' && <FontAwesomeIcon icon={faArrowDown} />}
      </div>
    );
  }
}
