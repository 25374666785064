import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../../../helpers/react.helpers';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

library.add(faChartBar);

@observer
export class EmptyStatisticsPure extends React.Component<ITranslatable> {
  render() {
    return (
      <div className="empty-statistics">
        <div className="please-select-statistic-message">
          <div className="chart-icon">
            <FontAwesomeIcon icon={faChartBar} />
          </div>
          <h5>{this.props.t('please_select_statistics')}</h5>
        </div>
      </div>
    );
  }
}

export const EmptyStatistics = as<React.ComponentClass>(withNamespaces('common', { wait: true })(EmptyStatisticsPure));
