import * as React from 'react';

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { Datasets, IDatasetsContext } from '../datasets.context';
import { DatasetsPermissionsType, IDatasetsPermissions } from '../datasets.permissions';
import { INotificationsService, NotificationsServiceType } from '../../notifications/services/notifications.service';
import { IRouterStore, RouterStoreType } from '../../../stores/router.store';
import { IUserService, UserServiceType } from '../../user/user.service';
import { InputStatus, StickerError } from '../../../models/error.model';
import { NotificationLevel, ToastNotification } from '../../notifications/models/notification.model';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { DatasetDetails } from '../datasetDetails.context';
import { DatasetDetailsPreviewHeader } from '../components/DatasetDetailsPreviewHeader';
import { Home } from '../../../routes/config/Home';
import { Observer } from 'mobx-react';
import { WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import autobind from 'autobind-decorator';
import { debounce } from 'lodash';
import { runInAction } from 'mobx';

export interface IInjectedProps extends RouteComponentProps<{ datasetId: string; imageId: string }> {
  userService: IUserService;
  routerStore: IRouterStore;
  notificationsService: INotificationsService;
  currentWorkspaceStore: ICurrentWorkspaceStore;
  datasetsPermissions: IDatasetsPermissions;
}

interface IState {
  datasetId: string;
  total: number;
  imageId: string;
  name: string;
  current: number;
  previousId: string;
  nextId: string;
  isLoading: boolean;
  imageSize: number;
  imageWidth: number;
  imageHeight: number;
  createdDate: string;
  hasAnnotation: boolean;
  hasLock: boolean;
  isDeleteModalShown: boolean;
  nameStatus: InputStatus;
  hasUrl: boolean;
  newName: string;
}

@injectProps({
  userService: UserServiceType,
  routerStore: RouterStoreType,
  notificationsService: NotificationsServiceType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  datasetsPermissions: DatasetsPermissionsType,
})
class DatasetDetailsPreviewHeaderContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  static contextType = DatasetDetails;
  declare context: React.ContextType<typeof DatasetDetails>;

  constructor(props: IInjectedProps) {
    super(props);

    const { datasetId, imageId } = props.match.params;

    this.state = {
      datasetId,
      imageId,
      total: 0,
      name: '',
      current: 0,
      previousId: '',
      nextId: '',
      isLoading: false,
      imageSize: 0,
      imageHeight: 0,
      imageWidth: 0,
      createdDate: '',
      hasAnnotation: false,
      hasLock: false,
      isDeleteModalShown: false,
      nameStatus: InputStatus.empty(),
      hasUrl: false,
      newName: '',
    };
  }

  async componentDidMount() {
    const { datasetId } = this.props.match.params;
    if (this.context.store.details.id !== datasetId) {
      await this.context.service.getDatasetDetailsAsync(datasetId);
    }
    await this.showImageAsync(this.props);
  }

  async componentDidUpdate(prevProps: IInjectedProps) {
    if (prevProps.match.params.imageId !== this.props.match.params.imageId) await this.showImageAsync(this.props);
  }

  @autobind
  handleImageDownload() {
    this.context.service.downloadImageAsync(this.props.match.params.imageId).then(result => {
      if (result instanceof StickerError) this.props.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'common:cant_download_image'));
    });
  }

  @autobind
  toggleDeleteImageModal() {
    this.setState((prevState: IState) => ({ isDeleteModalShown: !prevState.isDeleteModalShown }));
  }

  handleDeleteImage = (datasets: IDatasetsContext) => async () => {
    const workspaceId = this.props.currentWorkspaceStore.currentWorkspace!.id;
    const result = await this.context.service.deleteImageAsync(this.props.match.params.imageId);
    if (result instanceof StickerError) {
      this.props.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'common:cant_delete_image'));
    } else {
      if (this.state.previousId) {
        this.props.routerStore.push(Home.Datasets.Details.Preview.withParams({ workspaceId, datasetId: this.state.datasetId, imageId: this.state.previousId }));
      } else if (this.state.nextId) {
        this.props.routerStore.push(Home.Datasets.Details.Preview.withParams({ workspaceId, datasetId: this.state.datasetId, imageId: this.state.nextId }));
      } else {
        this.props.routerStore.push(Home.Datasets.Details.Images.withParams({ workspaceId, datasetId: this.state.datasetId }));
      }

      this.setState({ isDeleteModalShown: false });

      datasets.store.refreshFromDetails(this.context.store.details);
    }
  };

  validateName = async () => {
    const inputStatus = await this.context.service.validateImageNameAsync(this.state.newName, this.state.imageId, this.state.datasetId);
    this.setState({ nameStatus: inputStatus });
  };

  validateNameDebounced = debounce(async () => {
    await this.validateName();
  }, 300);

  @autobind
  async handleImageNameBlurAsync() {
    await this.validateName();

    if (this.state.nameStatus.isValid === true) {
      const result = await this.context.service.renameImageAsync(this.state.imageId, this.state.newName, this.state.datasetId);

      if (result instanceof StickerError) {
        this.setState(
          (prevState: IState): IState => ({
            ...prevState,
            nameStatus: InputStatus.buildFrom(result.apiErrorResponse!.errorCodes),
            newName: this.state.name,
          }),
        );
      } else {
        this.setState(
          (prevState: IState): IState => ({
            ...prevState,
            nameStatus: InputStatus.empty(),
            name: this.state.newName,
          }),
        );
      }
    }
  }

  @autobind
  handleNameChange(imageName: string) {
    this.setState({ newName: imageName });
    this.validateNameDebounced();
  }

  @autobind
  handleCancelImageRename() {
    this.setState({ newName: this.state.name, nameStatus: InputStatus.empty() });
  }

  @autobind
  async showImageAsync(props: IInjectedProps) {
    const { datasetId, imageId } = props.match.params;
    const store = this.context.store;

    runInAction(() => {
      store.isPreviewImageLoading = true;
      store.lowQualityImagePreviewUrl = '';
      store.imagePreviewUrl = '';
    });

    const [paging, image] = await Promise.all([
      this.context.service.getPreviewPaging(datasetId, store.previewPaging.checkDate),
      this.context.service.getDatasetImageInfoAsync(datasetId, imageId),
    ]);

    if (image instanceof StickerError || paging instanceof StickerError) return;

    const oldPaging = { ...store.previewPaging };
    if (paging.wasChanged) {
      runInAction(() => {
        store.previewPaging.checkDate = paging.checkDate;
        store.previewPaging.items = paging.items;
        store.previewPaging.checkDate = paging.checkDate;
      });
    }

    const workspaceId = this.props.currentWorkspaceStore.currentWorkspace!.id;
    let currentIndex = 1;
    const current = store.previewPaging.items.find(x => x.imageId === image.id);
    if (current !== undefined) {
      currentIndex = current.index;
    } else {
      const oldcurrent = oldPaging.items.find(x => x.imageId === image.id);
      if (oldcurrent !== undefined) {
        const newImage = store.previewPaging.items.find(x => x.index === oldcurrent.index);
        if (newImage !== undefined) {
          this.props.routerStore.push(Home.Datasets.Details.Preview.withParams({ workspaceId, datasetId, imageId: newImage.imageId }));
          return;
        }
        if (store.previewPaging.items.length > 0) {
          const newImage = store.previewPaging.items[store.previewPaging.items.length - 1];
          this.props.routerStore.push(Home.Datasets.Details.Preview.withParams({ workspaceId, datasetId, imageId: newImage.imageId }));
          return;
        }
      }
      this.props.routerStore.push(Home.Datasets.Details.Images.withParams({ workspaceId, datasetId }));
      return;
    }

    const next = store.previewPaging.items.find(x => x.index === currentIndex + 1);
    const previous = store.previewPaging.items.find(x => x.index === currentIndex - 1);

    await this.context.service.showImageAsync(datasetId, imageId, image.size);

    const imageUsageInfo = await this.context.service.getImageUsageInfo(datasetId, imageId);
    const hasAnnotation = imageUsageInfo instanceof StickerError ? false : imageUsageInfo.hasAnnotation;
    const hasLock = imageUsageInfo instanceof StickerError ? false : imageUsageInfo.hasLock;

    this.setState({
      hasAnnotation,
      hasLock,
      total: store.previewPaging.items.length,
      name: image.name,
      current: currentIndex,
      previousId: previous ? previous.imageId : '',
      nextId: next ? next.imageId : '',
      imageSize: image.size,
      imageHeight: image.height,
      imageWidth: image.width,
      createdDate: image.createdDate,
      hasUrl: image.hasUrl,
      newName: image.name,
      nameStatus: InputStatus.empty(),
      imageId: image.id,
    });
  }

  render() {
    return (
      <Datasets.Consumer>
        {datasets => (
          <Observer>
            {() => {
              const store = this.context.service.store;
              const userRole = this.props.currentWorkspaceStore.currentWorkspace!.role;
              const workspaceId = this.props.currentWorkspaceStore.currentWorkspace!.id;

              return (
                <DatasetDetailsPreviewHeader
                  isLoading={store.isPreviewImageLoading}
                  current={this.state.current}
                  name={this.state.name}
                  total={this.state.total}
                  imageSize={this.state.imageSize}
                  channels={store.imageAttributes?.channels || 0}
                  createdDate={this.state.createdDate}
                  imageHeight={this.state.imageHeight}
                  imageWidth={this.state.imageWidth}
                  isCached={store.imageAttributes?.isCached || false}
                  downloadSpeed={store.imageAttributes?.downloadSpeed}
                  isHighResImageLoading={store.imagePreviewUrl === ''}
                  // TODO: This link works only when user was before on image list, This needs to be redesign.
                  closeButtonLink={Home.Datasets.Details.Images.withParams({ workspaceId, datasetId: this.state.datasetId, page: store.detailsImagesPaging.pageNumber })}
                  nextLink={Home.Datasets.Details.Preview.withParams({ workspaceId, datasetId: this.state.datasetId, imageId: this.state.nextId })}
                  previousLink={Home.Datasets.Details.Preview.withParams({ workspaceId, datasetId: this.state.datasetId, imageId: this.state.previousId })}
                  datasetName={this.context.service.store.details.name}
                  datasetStatus={this.context.service.store.details.status}
                  isDeleteModalShown={this.state.isDeleteModalShown}
                  onDownloadImage={this.handleImageDownload}
                  onToggleDeleteModal={this.toggleDeleteImageModal}
                  onDeleteImage={this.handleDeleteImage(datasets)}
                  planAllowsDownload={this.props.userService.getUserPermissions().planAllowsDownloadImageInDataset}
                  canDelete={
                    (!this.state.hasAnnotation && !this.state.hasLock) ||
                    (userRole !== WorkspaceRole.Developer && userRole !== WorkspaceRole.Trainer && userRole !== WorkspaceRole.Collaborator)
                  }
                  hasAnnotations={this.state.hasAnnotation}
                  isLocked={this.state.hasLock}
                  onImageNameBlur={this.handleImageNameBlurAsync}
                  onImageNameChange={this.handleNameChange}
                  nameStatus={this.state.nameStatus}
                  canRenameImages={this.props.datasetsPermissions.canRenameImages(store.details.status, store.details.createdById)}
                  newName={this.state.newName}
                  onCancelImageRename={this.handleCancelImageRename}
                />
              );
            }}
          </Observer>
        )}
      </Datasets.Consumer>
    );
  }
}

export const DatasetDetailsPreviewHeaderContainer = as<React.ComponentClass>(withRouter(DatasetDetailsPreviewHeaderContainerPure));
