import * as React from 'react';

import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { faDownload, faList, faSortAmountDown, faTh, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IProjectListItem } from '../projects.store';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { IconedButton } from '../../../components/IconedButton';
import { ListViewMode } from '../../user/user.store';
import { as } from '../../../helpers/react.helpers';
import { handleClickAndPassData } from '../../../helpers/formHelpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';

library.add(faSortAmountDown, faTh, faList, faTrashAlt, faDownload);

export interface IProjectsListControlsProps {
  viewMode: ListViewMode;
  orderBy: string;
  orderDirection: string;
  onViewModeChange(mode: ListViewMode): void;
  onOrderByChange(order: string): void;
  onOrderDirectionChange(direction: string): void;
}

const FilterItem = (pp: IProjectsListControlsProps & ITranslatable, fieldName: keyof IProjectListItem, label: string) => (
  <DropdownItem
    onClick={handleClickAndPassData(pp.onOrderByChange)(fieldName)}
    className={pp.orderBy === fieldName ? 'selected' : ''}
  >
    {pp.t(label)}
  </DropdownItem>
);

const ProjectsListControlsPure = (props: IProjectsListControlsProps & ITranslatable) => {
  return (

    <div className={'project-view-controls-container'}>
      {props.viewMode === ListViewMode.Tiles && <UncontrolledDropdown>
        <DropdownToggle caret color="secondary" id="sorting-dropdown">
          <FontAwesomeIcon icon={faSortAmountDown} />
        </DropdownToggle>
        <DropdownMenu right={true}>
          {FilterItem(props, 'name', 'name')}
          {FilterItem(props, 'modifiedDate', 'modified')}
          {FilterItem(props, 'status', 'status')}
          {FilterItem(props, 'imagesCount', 'images')}
          {FilterItem(props, 'awaitingCount', 'awaiting')}
          {FilterItem(props, 'annotatedCount', 'annotated')}
          {FilterItem(props, 'reviewedCount', 'reviewed')}
          {FilterItem(props, 'progress', 'progress')}
          {FilterItem(props, 'usersCount', 'workers')}
          <DropdownItem divider />
          <DropdownItem onClick={handleClickAndPassData(props.onOrderDirectionChange)('ASC')} className={props.orderDirection.toUpperCase() === 'ASC' ? 'selected' : ''}>
            {props.t('ascending')}
          </DropdownItem>
          <DropdownItem onClick={handleClickAndPassData(props.onOrderDirectionChange)('DESC')} className={props.orderDirection.toUpperCase() === 'DESC' ? 'selected' : ''}>
            {props.t('descending')}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>}
      <div className="image-view-control">
        <IconedButton
          color="primary"
          className="project-list-button list-mode-button"
          icon={props.viewMode === ListViewMode.Tiles ? faList : faTh}
          onClick={handleClickAndPassData(props.onViewModeChange)(props.viewMode === ListViewMode.Tiles ? ListViewMode.List : ListViewMode.Tiles)}
        />
      </div>
    </div>
  );
};

export const ProjectsListControls = as<React.StatelessComponent<IProjectsListControlsProps>>(withNamespaces('project')(ProjectsListControlsPure));
