import { inject, injectable } from 'inversify';
import { StickerError } from '../../../__legacy__/models/error.model';
import { IEvaluationDetailsDto, IEvaluationImageDto, IEvaluationMetricsDto, IEvaluationSettingsParamsDto, IEvaluationImagePredictionsDto } from '../evaluationDetails.model';
import { IApiService } from '../../../__legacy__/services/api.service.base';
import { ApiServiceType, IPagedResult } from '../../../__legacy__/services/api.service';
import { ApiServiceTrainingType } from '../../../__legacy__/services/api.service.training';
import { IEvaluationConfig } from '../evaluationDetails.store';
import { IModelMetricsDto } from '../../modelDetails/modelDetails.model';
import { IEvaluationStatusDto } from '../evaluationDetails.model';
import { MODEL_VARIANTS } from '../../../__legacy__/models/metrics.model';

export interface GetModelProjectsRequest {
  workspaceId: string;
}

export interface IEvaluationProjectsDto {
  id: string;
  name: string;
}

export interface IGetEvaluationDatasets {
  projectId: string;
}

export interface IPostEvaluationStartRequest {
  inference_name: string;
  inference_description?: string;
  inference_config_: IEvaluationConfig;
  datasets: {
    project_id: string;
    datasets_ids: string[];
  };
}

export interface IGetEvaluationImagesRequest {
  pageNumber: number;
  pageSize: number;
  orderBy: string;
  orderType: string;
  search?: string;
  projectId: string;
  datasetsIds: string[];
}

export interface IPostRestartEvaluationDto {
  'job-id': string;
}

export interface IPostStartEvaluationDto {
  'inference-id': string;
}

export interface IEvaluationModelsDto {
  jobs: { training_job_id: string; model_name: string }[];
}

export interface IGetModelDownloadUrlDto {
  url: string;
}

export const EvaluationDetailsApiServiceType = Symbol('EVALUATION_DETAILS_API_SERVICE');

export interface IEvaluationDetailsApiService {
  getEvaluationDetailsAsync(workspaceId: string, modelId: string): Promise<IEvaluationDetailsDto | StickerError>;
  getProjectsInWorkspaceAsync(request: GetModelProjectsRequest): Promise<IEvaluationProjectsDto[] | StickerError>;
  getEvaluationDatasetsAsync(request: IGetEvaluationDatasets): Promise<any[] | StickerError>;
  getEvaluationSettingsParamsAsync(workspaceId: string, modelVariant: MODEL_VARIANTS): Promise<IEvaluationSettingsParamsDto | StickerError>;
  postEvaluationStartAsync(workspaceId: string, trainingId: string, request: IPostEvaluationStartRequest): Promise<IPostStartEvaluationDto | StickerError>;
  postEvaluationRestartAsync(workspaceId: string, evaluationId: string, request: IPostEvaluationStartRequest): Promise<IPostRestartEvaluationDto | StickerError>;
  postEvaluationStopAsync(workspaceId: string, evaluationId: string): Promise<string | StickerError>;
  getEvaluationImagesAsync(request: IGetEvaluationImagesRequest): Promise<IPagedResult<IEvaluationImageDto> | StickerError>;
  getEvaluationImagesPredictionsAsync(workspaceId: string, evaluationId: string, imagesIds: string[]): Promise<IEvaluationImagePredictionsDto | StickerError>;
  getEvaluationModelsAsync(workspaceId: string, modelVariant: MODEL_VARIANTS): Promise<IEvaluationModelsDto | StickerError>;
  getEvaluationProgressAsync(workspaceId: string, evaluationId: string): Promise<IEvaluationMetricsDto | StickerError>;
  getEvaluationStatusAsync(workspaceId: string, evaluationId: string): Promise<IEvaluationStatusDto | StickerError>;
  getDownloadModelAsync(workspaceId: string, modelId: string): Promise<IGetModelDownloadUrlDto | StickerError>;
}

@injectable()
export class EvaluationDetailsApiService implements IEvaluationDetailsApiService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService, @inject(ApiServiceTrainingType) private readonly apiTrainingService: IApiService) {}

  async getEvaluationDetailsAsync(workspaceId: string, modelId: string): Promise<IEvaluationDetailsDto | StickerError> {
    return await this.apiTrainingService.getAsync(`/workspaces/${workspaceId}/inference-jobs/${modelId}`);
  }

  async getProjectsInWorkspaceAsync(request: GetModelProjectsRequest): Promise<IEvaluationProjectsDto[] | StickerError> {
    return await this.apiService.getAsync('/Models/Projects', { params: request });
  }

  async getEvaluationDatasetsAsync(request: IGetEvaluationDatasets): Promise<any[] | StickerError> {
    return await this.apiService.getAsync('/Models/Datasets', { params: request });
  }

  async getEvaluationModelsAsync(workspaceId: string, modelVariant: MODEL_VARIANTS): Promise<IEvaluationModelsDto | StickerError> {
    return await this.apiTrainingService.getAsync(`/workspaces/${workspaceId}/model-variants/${modelVariant}/training-jobs`);
  }

  async getEvaluationSettingsParamsAsync(workspaceId: string, modelVariant: MODEL_VARIANTS): Promise<IEvaluationSettingsParamsDto | StickerError> {
    return await this.apiTrainingService.getAsync<IEvaluationSettingsParamsDto | StickerError>(`/workspaces/${workspaceId}/model-variants/${modelVariant}/schema/inference`);
  }

  async postEvaluationStartAsync(workspaceId: string, trainingId: string, request: IPostEvaluationStartRequest): Promise<IPostStartEvaluationDto | StickerError> {
    return await this.apiTrainingService.postAsync(`/workspaces/${workspaceId}/inference-jobs/start`, request, { params: { training_job_id: trainingId } });
  }

  async postEvaluationRestartAsync(workspaceId: string, evaluationId: string, request: IPostEvaluationStartRequest): Promise<IPostRestartEvaluationDto | StickerError> {
    return await this.apiTrainingService.postAsync(`/workspaces/${workspaceId}/inference-jobs/${evaluationId}/start`, request);
  }

  async postEvaluationStopAsync(workspaceId: string, evaluationId: string): Promise<string | StickerError> {
    return await this.apiTrainingService.postAsync(`/workspaces/${workspaceId}/inference-jobs/${evaluationId}/stop`);
  }

  async getEvaluationImagesAsync(request: IGetEvaluationImagesRequest): Promise<IPagedResult<IEvaluationImageDto> | StickerError> {
    return await this.apiService.postAsync('/Models/Images', request);
  }

  async getEvaluationImagesPredictionsAsync(workspaceId: string, evaluationId: string, imagesIds: string[]): Promise<IEvaluationImagePredictionsDto | StickerError> {
    return await this.apiTrainingService.postAsync(`/workspaces/${workspaceId}/inference-jobs/${evaluationId}/image-metrics`, imagesIds);
  }

  async getEvaluationProgressAsync(workspaceId: string, evaluationId: string): Promise<IModelMetricsDto | StickerError> {
    return await this.apiTrainingService.getAsync(`/workspaces/${workspaceId}/inference-jobs/${evaluationId}/progress`);
  }

  async getEvaluationStatusAsync(workspaceId: string, evaluationId: string): Promise<IEvaluationStatusDto | StickerError> {
    return await this.apiTrainingService.getAsync(`/workspaces/${workspaceId}/inference-jobs/${evaluationId}/status`);
  }

  async getDownloadModelAsync(workspaceId: string, modelId: string): Promise<IGetModelDownloadUrlDto | StickerError> {
    return this.apiTrainingService.getAsync(`/workspaces/${workspaceId}/training-jobs/${modelId}/model`);
  }
}
