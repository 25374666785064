import { WithNamespaces, withNamespaces } from 'react-i18next';

import React from 'react';
import { S_Action } from './S_Action';
import { S_ButtonProps } from '../buttons/S_ButtonProps';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

interface S_ActionSelectProps extends S_ButtonProps {
}

const S_ActionSelectPure: React.FC<S_ActionSelectProps & WithNamespaces> = props => <S_Action {...props} title={props.t('select')} icon={faCheck}/>;

export const S_ActionSelect = withNamespaces('design')(S_ActionSelectPure);
