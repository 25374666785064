import './S_Checkbox.scss';

import { CustomInput, FormGroup } from 'reactstrap';

import React from 'react';

interface Props {
  id: string | number;
  label: React.ReactNode;
  checked?: boolean;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
}

export const S_Checkbox: React.FC<Props> = props => (
  <FormGroup className="s-checkbox-form-group">
    <CustomInput type="checkbox" id={props.id} label={props.label} onChange={props.onChange} checked={props.checked} />
  </FormGroup>
);
