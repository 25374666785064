import { AnnotationUiStoreType, IAnnotationUiStore } from '../annotationUi.store';
import { as, injectProps } from '../../../helpers/react.helpers';

import React from 'react';
import { ZoomControl } from '../components/ZoomControl';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps {
  annotationUiStore: IAnnotationUiStore;
}

export interface IZoomControlProps {
  onZoomLevelChange(zoomLevel: number): void;
  zoomValue: number;
  minZoomLevel: number;
  maxZoomLevel: number;
}

@injectProps({
  annotationUiStore: AnnotationUiStoreType,
})
@observer
class ZoomContainerPure extends React.Component<IInjectedProps> {
  @autobind
  handleZoomLevelChange(zoomLevel: number) {
    this.props.annotationUiStore.changeZoomLevel(zoomLevel);
  }

  render() {
    return (
      <ZoomControl
        onZoomLevelChange={this.handleZoomLevelChange}
        zoomValue={this.props.annotationUiStore.zoomLevel}
        minZoomLevel={this.props.annotationUiStore.minZoomLevel}
        maxZoomLevel={this.props.annotationUiStore.maxZoomLevel}
      />
    );
  }
}

export const ZoomContainer = as<React.ComponentClass>(ZoomContainerPure);
