import './ObjectsList.scss';

import { AnnotationServiceType, IAnnotationService } from '../../../../annotation.service';
import { AnnotationTypeBlType, IAnnotationTypeBl } from '../../../annotationTypes/annotationType.bl';
import { AnnotationTypeStoreType, IAnnotationTypeStore } from '../../../annotationTypes/annotationType.store';
import { AnnotationUiStoreType, IAnnotationUiStore } from '../../../../annotationUi.store';
import { AnnotationsStoreType, IAnnotationsStore } from '../../../../annotations.store';
import { FREE_DRAW_TOOLS, ISegmentation } from '../../../../annotations.interface';
import { FreeDrawSegmentationServiceType, IFreeDrawSegmentationService } from '../../../../freeDrawSegmentation.service';
import { ISegmentationOrderBl, SegmentationOrderBlType } from '../../segmentationContextMenu/segmentationsOrder.Bl';
import { IUndoRedoHistory, UndoRedoHistoryType } from '../../../../undoRedoHistory.service';
import { IUserStore, UserStoreType } from '../../../../../user/user.store';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import _, { cloneDeep } from 'lodash';
import { as, injectProps } from '../../../../../../helpers/react.helpers';

import { AnnotationReviewStoreType } from '../../../review/annotationReview.store';
import { AnnotationToolType } from '../../../annotationTypes/models/annotationToolType';
import { ChangeSegmentationWithAnswersCommand } from '../../../../undoRedoCommands/changeSegmentationWithAnswersCommand';
import { Col } from 'reactstrap';
import { DeleteAnnotationTypeConfirmationModal } from './modals/DeleteAnnotationTypeConfirmationModal';
import { DeleteSegmentationConfirmationModal } from './modals/DeleteSegmentationConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QuestionModel } from '../../../../question.model';
import React from 'react';
import { SegmentationRow } from './SegmentationRow';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import { transaction } from 'mobx';

export interface IProject {
  id: string;
  name: string;
  count: number;
  questions: QuestionModel[];
}

export interface IObjectsListProps {
  isReadOnly: boolean;
  onClose?(): void;
}

interface IInjectedProps {
  annotationService: IAnnotationService;
  annotationUiStore: IAnnotationUiStore;
  annotationTypeBl: IAnnotationTypeBl;
  annotationTypeStore: IAnnotationTypeStore;
  undoRedoHistoryService: IUndoRedoHistory;
  freeDrawSegmentationService: IFreeDrawSegmentationService;
  userStore: IUserStore;
  undoRedoHistory: IUndoRedoHistory;
  annotationsStore: IAnnotationsStore;
  segmentationOrderBl: ISegmentationOrderBl;
}

export interface IObjectListSegmentation extends ISegmentation {
  isVisible: boolean;
  isMarkingToolTypeVisible: boolean;
  name: string;
  order: number;
  isSelected: boolean;
  priority: number;
}

type IProps = IInjectedProps & IObjectsListProps & WithNamespaces & RouteComponentProps<{ projectId: string }>;

interface IState {
  showDeleteAnnotationTypeModal: boolean;
  showDeleteSegmentationModal: boolean;
  annotationTypeIdToDelete: string;
  segmentationIdToDelete: string;
}

@injectProps({
  annotationService: AnnotationServiceType,
  annotationUiStore: AnnotationUiStoreType,
  annotationTypeBl: AnnotationTypeBlType,
  annotationTypeStore: AnnotationTypeStoreType,
  undoRedoHistoryService: UndoRedoHistoryType,
  freeDrawSegmentationService: FreeDrawSegmentationServiceType,
  userStore: UserStoreType,
  undoRedoHistory: UndoRedoHistoryType,
  annotationsStore: AnnotationsStoreType,
  segmentationOrderBl: SegmentationOrderBlType,
  annotationReviewStore: AnnotationReviewStoreType,
})
@observer
class ObjectsListContainerPure extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showDeleteSegmentationModal: false,
      showDeleteAnnotationTypeModal: false,
      annotationTypeIdToDelete: '',
      segmentationIdToDelete: '',
    };
  }

  showDeleteAnnotationTypeModal = (annotationTypeId: string) => this.setState({ showDeleteAnnotationTypeModal: true, annotationTypeIdToDelete: annotationTypeId });
  hideDeleteAnnotationTypeModal = () => this.setState({ showDeleteAnnotationTypeModal: false, annotationTypeIdToDelete: '' });

  handleConfirmDeleteAnnotationType = (): void => {
    const segmentations = this.props.annotationsStore.segmentations.filter(s => s.feature.properties!.annotationTypeId !== this.state.annotationTypeIdToDelete);
    this.props.undoRedoHistoryService.addNewChangeStateCommand(segmentations);
    this.props.annotationService.deleteSegmentations(segmentations);
    this.hideDeleteAnnotationTypeModal();
  };

  showDeleteSegmentationModal = (segmentation: IObjectListSegmentation) => this.setState({ showDeleteSegmentationModal: true, segmentationIdToDelete: segmentation.feature.id });
  hideDeleteSegmentationModal = () => this.setState({ showDeleteSegmentationModal: false, segmentationIdToDelete: '' });

  handleConfirmDeleteSegmentation = (): void => {
    const segmentation = cloneDeep(this.props.annotationsStore.segmentations.find(s => s.feature.id === this.state.segmentationIdToDelete));

    if (segmentation?.feature) this.props.annotationService.deleteSegmentation(segmentation.feature.id);
    this.props.annotationService.deselectSegmentation();
    this.props.freeDrawSegmentationService.clear();
    this.props.annotationTypeBl.handleSelectedAnnotationDeleteInAnnotationTypeContainer();

    this.props.undoRedoHistoryService.addCommand(
      new ChangeSegmentationWithAnswersCommand(this.props.annotationService, this.props.annotationsStore, this.props.freeDrawSegmentationService, segmentation, undefined),
    );
    this.hideDeleteSegmentationModal();
  };

  handleHideShowAnnotationType = (markingToolId: string): void => {
    const projectId = this.props.match.params.projectId;
    const options = this.props.annotationTypeStore.annotationTypesOptions.find(o => o.id === markingToolId)!;
    this.props.annotationTypeBl.setAnnotationTypeOptions(projectId, { ...options, isVisible: !options.isVisible });

    const featureIdsToShow = this.props.annotationsStore.segmentations
      .filter(s => markingToolId === s.feature.properties!['annotationTypeId'])
      .map(s => ({ segmentationId: s.id, featureId: s.feature.id }));
    featureIdsToShow.forEach(s => this.props.annotationService.showSegmentation(s.featureId, s.segmentationId));
  };

  handleHideShowSegmentation = (segmentation: IObjectListSegmentation): void => {
    const segmentationIsVisible: boolean = segmentation.isMarkingToolTypeVisible && segmentation.isVisible;
    const markingToolIsVisible: boolean = segmentation.isMarkingToolTypeVisible;

    if (segmentationIsVisible) {
      this.props.annotationService.hideSegmentation(segmentation.feature.id, segmentation.id);

      const segmentationsCreatedWithSameTool = this.props.annotationsStore.segmentations.filter(
        s => s.feature.properties!['annotationTypeId'] === segmentation.feature.properties!['annotationTypeId'],
      );

      const allSegmentationsAreHidden = segmentationsCreatedWithSameTool.every(s =>
        this.props.annotationsStore.hiddenSegmentations.some(
          hiddenSegmentation =>
            (hiddenSegmentation.segmentationId !== undefined && s.id !== undefined && hiddenSegmentation.segmentationId === s.id) ||
            (hiddenSegmentation.featureId !== undefined && s.feature.id !== undefined && hiddenSegmentation.featureId === s.feature.id),
        ),
      );

      if (allSegmentationsAreHidden) {
        segmentationsCreatedWithSameTool.forEach(s => this.props.annotationService.showSegmentation(s.feature.id, s.id));
        this.handleHideShowAnnotationType(segmentation.feature.properties!['annotationTypeId']);
      }
    }

    if (!segmentationIsVisible) {
      this.props.annotationService.showSegmentation(segmentation.feature.id, segmentation.id);

      if (!markingToolIsVisible) {
        const projectId = this.props.match.params.projectId;
        const options = this.props.annotationTypeStore.annotationTypesOptions.find(o => o.id === segmentation.feature.properties!['annotationTypeId'])!;
        this.props.annotationTypeBl.setAnnotationTypeOptions(projectId, { ...options, isVisible: true });

        const segmentationsToHide = this.props.annotationsStore.segmentations
          .filter(s => s.feature.properties!['annotationTypeId'] === segmentation.feature.properties!['annotationTypeId'] && segmentation.feature.id !== s.feature.id)
          .map(s => ({ segmentationId: s.id, featureId: s.feature.id }));

        this.props.annotationService.hideSegmentations(segmentationsToHide);
      }
    }
  };

  handleSelectSegmentationAsync = async (segmentation: IObjectListSegmentation) => {
    if (this.props.isReadOnly) {
      this.props.annotationsStore.drawFocusFrameForId = segmentation.feature.id;
      this.props.annotationService.selectSegmentation(segmentation.feature.id!);
      if (!segmentation.isVisible || !segmentation.isMarkingToolTypeVisible) this.handleHideShowSegmentation(segmentation);
      return;
    }

    if (await this.props.freeDrawSegmentationService.clearAsync()) {
      this.props.annotationService.deselectSegmentation();
      await this.props.annotationTypeBl.handleAnnotationTypeSelectedInAnnotationTypeContainer(AnnotationToolType.SELECT);

      transaction(() => {
        this.props.annotationService.selectSegmentation(segmentation.feature.id);
        if (this.props.annotationsStore.selectedSegmentation) {
          this.props.annotationUiStore.isInValidation = false;

          if (FREE_DRAW_TOOLS.includes(this.props.annotationsStore.selectedSegmentation.feature.featureType)) {
            this.props.freeDrawSegmentationService.setFreeDrawFeature(this.props.annotationsStore.selectedSegmentation!.feature);
            this.props.annotationsStore.drawFocusFrameForId = segmentation.feature.id;
            const previousAnnotationTypeId = this.props.annotationTypeStore.activeAnnotationTypeId;
            const annotationTypeId = this.props.annotationsStore.selectedSegmentation.feature.properties?.annotationTypeId;
            if (annotationTypeId !== previousAnnotationTypeId) {
              this.props.annotationTypeBl.handleSegmentationClickedInSegmentationContainer(annotationTypeId).then(succeeded => {
                if (succeeded && this.props.freeDrawSegmentationService.freeDrawFeature) {
                  this.props.freeDrawSegmentationService.selectFeature();
                }
              });
            }
          } else {
            this.props.freeDrawSegmentationService.clear();
            this.props.annotationsStore.drawFocusFrameForId = undefined;
          }
        }
      });

      if (!segmentation.isVisible || !segmentation.isMarkingToolTypeVisible) this.handleHideShowSegmentation(segmentation);
    }
  };

  render() {
    const { t } = this.props;

    const updated: IObjectListSegmentation[] = _.map<ISegmentation, IObjectListSegmentation>(
      this.props.annotationsStore.segmentations,
      (segmentation: ISegmentation): IObjectListSegmentation => {
        const annotationType = this.props.annotationTypeStore.annotationTypes.find(at => at.id === segmentation.feature.properties!['annotationTypeId']);
        const visibilities = this.props.annotationTypeStore.hiddenAnnotationTypes.find(at => at.projectId === annotationType?.projectId)?.annotationTypes ?? [];
        return {
          ...segmentation,
          isVisible: !this.props.annotationsStore.hiddenSegmentations.some(
            hs => (segmentation.id !== undefined && hs.segmentationId === segmentation.id) || hs.featureId === segmentation.feature.id,
          ),
          isMarkingToolTypeVisible: visibilities.findIndex(id => id === annotationType?.id) === -1,
          name: annotationType?.name ?? '',
          order: annotationType?.order ?? 0,
          isSelected: this.props.annotationsStore.selectedSegmentation?.feature.id === segmentation.feature.id,
        };
      },
    );

    const ordered: IObjectListSegmentation[] = _.sortBy<IObjectListSegmentation>(
      updated,
      [
        function (s: IObjectListSegmentation): number {
          return s.order;
        },
      ],
      ['asc'],
    );

    const grouped = _.groupBy(ordered, (s: ISegmentation) => s.feature.properties!['annotationTypeId']);

    return (
      <Col xs={2} className="object-list">
        <div>
          <h5 className="object-list-title">
            <FontAwesomeIcon icon={faBars} /> {t('list_of_objects')}
            {this.props.onClose && (
              <button onClick={this.props.onClose} className="close">
                <span>&times;</span>
              </button>
            )}
          </h5>
          <div className="marking-tools-items-container">
            {_.map(grouped, (segmentations: IObjectListSegmentation[], markingToolTypeId: string) => (
              <SegmentationRow
                isReadOnly={this.props.isReadOnly}
                segmentations={_.orderBy(segmentations, s => s.priority)}
                key={markingToolTypeId}
                onShowHideAnnotationTypeClick={this.handleHideShowAnnotationType}
                onShowHideSegmentationClick={this.handleHideShowSegmentation}
                onDeleteSegmentationClick={this.showDeleteSegmentationModal}
                onDeleteAnnotationTypeClick={this.showDeleteAnnotationTypeModal}
                onSegmentationClick={this.handleSelectSegmentationAsync}
              />
            ))}
          </div>
        </div>
        <DeleteAnnotationTypeConfirmationModal
          onAnnotationTypeDeleteCancel={this.hideDeleteAnnotationTypeModal}
          showAnnotationTypeDeleteConfirmationModal={this.state.showDeleteAnnotationTypeModal}
          onAnnotationTypeDeleteConfirm={this.handleConfirmDeleteAnnotationType}
        />
        <DeleteSegmentationConfirmationModal
          onSegmentationDeleteCancel={this.hideDeleteSegmentationModal}
          showSegmentationDeleteConfirmationModal={this.state.showDeleteSegmentationModal}
          onSegmentationDeleteConfirm={this.handleConfirmDeleteSegmentation}
        />
      </Col>
    );
  }
}

export const ObjectsListContainer = as<React.ComponentClass<IObjectsListProps>>(withRouter(withNamespaces('common')(ObjectsListContainerPure)));
