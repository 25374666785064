import { ContainerModule, interfaces } from 'inversify';
import { ErrorsService, ErrorsServiceType, IErrorsService } from './errors.service';
import { ErrorsStore, ErrorsStoreSetterType, ErrorsStoreType, IErrorsStore, IErrorsStoreSetter } from './errors.store';

export const errorsModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IErrorsStore>(ErrorsStoreType)
    .to(ErrorsStore)
    .inSingletonScope();
  bind<IErrorsStoreSetter>(ErrorsStoreSetterType)
    .toService(ErrorsStoreType);

  bind<IErrorsService>(ErrorsServiceType)
    .to(ErrorsService)
    .inSingletonScope();
});
