import autobind from 'autobind-decorator';
import { inject, injectable } from 'inversify';
import { debounce } from 'lodash';
import { InputStatus } from '../../../__legacy__/models/error.model';
import { ReservedFileNames } from '../../../__legacy__/modules/datesets/datasetDetails.service';
import { IRenameAttachmentStoreSetter, RenameAttachmentStoreSetterType } from './RenameAttachment.store';
import {
  CheckAttachmentNameUniquenessServiceType,
  ICheckAttachmentNameUniquenessRequest,
  ICheckAttachmentNameUniquenessService,
} from './services/CheckAttachmentNameUniqueness.service';
import * as path from 'path';
import { IRenameAttachmentRequest, IRenameAttachmentService, RenameAttachmentServiceType } from './services/RenameAttachment.service';
import { NotificationsServiceType, INotificationsService } from '../../../__legacy__/modules/notifications/services/notifications.service';
import { NotificationLevel, ToastNotification } from '../../../__legacy__/modules/notifications/models/notification.model';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../workspaces/currentWorkspace/CurrentWorkspace.store';
import { EventBusType, IEventBus } from '../../../__legacy__/services/eventBus.service';
import { AttachmentRenamedEvent } from './events/AttachmentRenamedEvent';
import { CacheManager } from '../../../__legacy__/services/cacheManager';
import { IAttachmentPreviewImageInfo } from '../../../__legacy__/modules/attachments/attachmentsPreview.store';

export const RenameAttachmentBlType = Symbol('RENAME_ATTACHMENT_BL');

export interface IRenameAttachmentBl {
  renameAttachmentAsync(): Promise<void>;
  changeName(name: string): void;
  showModal(): void;
  hideModal(): void;
  initialize(attachmentId: string, attachmentName: string, parentId: string | undefined): void;
}

@autobind
@injectable()
export class RenameAttachmentBl implements IRenameAttachmentBl {
  constructor(
    @inject(RenameAttachmentStoreSetterType) private readonly store: IRenameAttachmentStoreSetter,
    @inject(CheckAttachmentNameUniquenessServiceType) private readonly checkUniquenessService: ICheckAttachmentNameUniquenessService,
    @inject(RenameAttachmentServiceType) private readonly renameAttachmentService: IRenameAttachmentService,
    @inject(NotificationsServiceType) private readonly notificationService: INotificationsService,
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore,
    @inject(EventBusType) private readonly eventBus: IEventBus,
  ) {}

  hideModal(): void {
    this.store.setShowModal(false);
  }

  showModal(): void {
    this.store.setShowModal(true);
  }

  initialize(attachmentId: string, attachmentName: string, parentId: string | undefined): void {
    this.store.initialize(attachmentId, attachmentName, parentId);
  }

  public changeName(name: string): void {
    this.store.setName(name);
    this.validateNameDebounced(name);
  }

  async renameAttachmentAsync(): Promise<void> {
    await this.validateNameAsync(this.store.name);
    if (!this.store.nameStatus.isValid) return;

    try {
      const request: IRenameAttachmentRequest = {
        name: this.store.name,
        attachmentId: this.store.id,
      };
      await this.renameAttachmentService.renameAttachmentAsync(request);
      this.eventBus.sendEvent(new AttachmentRenamedEvent());

      await this.updateCacheAsync();
    } catch {
      this.notificationService.push(new ToastNotification(NotificationLevel.ERROR, 'renaming_attachment_failed'));
    }

    this.hideModal();
  }

  private async updateCacheAsync() {
    const cacheAvailable = await CacheManager.checkCacheAvailabilityAsync();
    if (!cacheAvailable) return;

    const request = new Request(`/home/${this.currentWorkspaceStore.currentWorkspace!.id}/attachments/${this.store.id}/preview/${this.store.id}`);

    const imagePreviewCache = await caches.open('image-preview-cache');
    const imagePreviewMatch = await imagePreviewCache.match(request);

    if (imagePreviewMatch) {
      const cachedPreviewImageData = await imagePreviewMatch.json();
      const result = {} as IAttachmentPreviewImageInfo;
      Object.assign(result, cachedPreviewImageData) as IAttachmentPreviewImageInfo;
      result.name = this.store.name;
      imagePreviewCache.put(request, new Response(JSON.stringify(result)));
    }
  }

  private async validateNameAsync(name: string) {
    const nameStatus = InputStatus.valid();

    if (name.trim() === '') {
      nameStatus.addErrorCode('field_cant_be_empty');
    }

    const format = /[*:"\\|<>\/?]+/;
    if (format.test(name)) nameStatus.addErrorCode('no_special_characters');

    const baseName = path.basename(name, path.extname(name));
    if (ReservedFileNames.some(fn => baseName.toUpperCase() === fn)) nameStatus.addErrorCode('forbidden_attachment_name');
    if (/^\.+$/.test(name)) nameStatus.addErrorCode('forbidden_attachment_name');

    const request: ICheckAttachmentNameUniquenessRequest = {
      name,
      attachmentId: this.store.id,
      workspaceId: this.currentWorkspaceStore.currentWorkspace!.id,
      parentId: this.store.parentId,
    };

    if (!(await this.checkUniquenessService.checkAttachmentNameUniqueness(request))) {
      nameStatus.addErrorCode('attachment_name_must_be_unique');
    }

    this.store.setNameStatus(nameStatus);
  }

  private validateNameDebounced = debounce(async (name: string) => await this.validateNameAsync(name), 300);
}
