import * as React from 'react';

import { FormGroup, Input } from 'reactstrap';
import { drawingScreenDisables, keyboardShortcutsServiceInstance } from '../../../../services/keyboardShortcuts.service';

import { ITextAnswerProps } from '../../question.model';
import { ITranslatable } from '../../../../helpers/translations.helpers';
import { as } from '../../../../helpers/react.helpers';
import { handleChange } from '../../../../helpers/formHelpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IState {
  answer: string;
}

@observer
class TextAnswerPure extends React.Component<ITextAnswerProps & ITranslatable, IState> {
  constructor(props: ITextAnswerProps & ITranslatable) {
    super(props);
    this.state = { answer: '' };
  }

  componentDidMount() {
    this.setState({ answer: this.props.answer });
  }

  // tslint:disable-next-line:function-name
  UNSAFE_componentWillUpdate(newProps: ITextAnswerProps) {
    if (this.props.answer !== newProps.answer) {
      this.setState({ answer: newProps.answer });
    }
  }

  handleFocus = () => keyboardShortcutsServiceInstance.disableShortcuts(drawingScreenDisables);

  handleBlur = () => {
    keyboardShortcutsServiceInstance.enableShortcuts(drawingScreenDisables);
    this.props.onAnswered(this.state.answer);
  }

  handleAnswer = (answer: string) => this.setState({ answer });

  render() {
    return (
      <FormGroup check>
        <Input
          className={this.props.className}
          value={this.state.answer}
          type={'textarea'}
          onChange={handleChange(this.handleAnswer)}
          maxLength={1000}
          placeholder={this.props.t('open_answer_placeholder')}
          disabled={this.props.disabled}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        />
      </FormGroup>
    );
  }
}

export const TextAnswer = as<React.ComponentClass<ITextAnswerProps>>(withNamespaces('annotation', { wait: true })(TextAnswerPure));
