import { FilterHeader } from '../FilterHeader';
import { FilterTabType } from '../../../imageFilters.model';
import { ITranslatable } from '../../../../../../../helpers/translations.helpers';
import { ImageFiltersFormProps } from '../ImageFiltersForm';
import React from 'react';
import { as } from '../../../../../../../helpers/react.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

@observer
class AnnotatedByHeaderPure extends React.Component<ImageFiltersFormProps & ITranslatable> {
  handleClear = () => this.props.onSetAnnotatedBy([]);

  render() {
    const isSelected = this.props.activeFilterType === FilterTabType.AnnotatedBy;
    const count = this.props.imageFilters.annotatedBy.length;

    return (
      <FilterHeader
        disabled={!this.props.canSeeUsers}
        filterType={FilterTabType.AnnotatedBy}
        isSelected={isSelected}
        labelKey={'annotated_by'}
        count={count}
        showClear={count > 0}
        onClear={this.handleClear}
        onSelect={this.props.onFilterTabSelect}
      />
    );
  }
}

export const AnnotatedByHeader = as<React.ComponentClass<ImageFiltersFormProps>>(withNamespaces('common')(AnnotatedByHeaderPure));
