import * as React from 'react';

import { Col, Container, Row } from 'reactstrap';
import { ModelDetailsTabsContainer } from '../containers/ModelDetailsTabs.contrainer';
import { ModelDetailsInfoForm } from './ModelDetailsInfoForm';
import { ModelDetailsHeaderButtons } from './ModelDetailsHeaderButtons';
import { IModelDetailsValidationErrors, IModelProject } from '../modelDetails.store';
import { ValueType } from 'react-select/lib/types';
import { IModelStatusDto, SelectOption } from '../modelDetails.model';
import { MODEL_STATUS } from '../../models/models.model';
import { ModelDetailsInfo } from './ModelDetailsInfo';
import { GlobalValidationErrorsContainer } from '../containers/GlobalValidationErrors.container';
import { MODEL_VARIANTS } from '../../../__legacy__/models/metrics.model';

interface IProps {
  isCreate: boolean;
  workspaceId: string;
  id: string;
  name: string;
  description: string;
  projectId: string;
  status: MODEL_STATUS;
  startingProgress: IModelStatusDto['starting_progress'];
  failureReason: string | null;
  variant: MODEL_VARIANTS;
  createdAt: string;
  projects: IModelProject[];
  onChangeName: (name: string) => void;
  onChangeDescription: (description: string) => void;
  onChangeProject: (value: ValueType<SelectOption>) => void;
  startTrainingAsync(): void;
  downloadModelAsync(): void;
  stopTrainingAsync(): void;
  handleBlurName(name: string): void;
  handleBlurProject(projectId: string): void;
  handleBlurDescription(description: string): void;
  errors: IModelDetailsValidationErrors;
  isOwner: boolean;
  isFormDisabled: boolean;
  handleChangeVariant: (value: ValueType<SelectOption>) => void;
  handleBlurVariant: (modelVariant: MODEL_VARIANTS) => void;
}

export const ModelDetailsHeader = (props: IProps) => {
  const {
    isCreate,
    id,
    workspaceId,
    name,
    description,
    projectId,
    status,
    startingProgress,
    failureReason,
    variant,
    createdAt,
    projects,
    onChangeProject,
    onChangeName,
    onChangeDescription,
    startTrainingAsync,
    stopTrainingAsync,
    downloadModelAsync,
    handleBlurName,
    handleBlurProject,
    handleBlurDescription,
    errors,
    isOwner,
    isFormDisabled,
    handleBlurVariant,
    handleChangeVariant,
  } = props;

  return (
    <Container fluid>
      <Row>
        <Col>
          <ModelDetailsInfoForm
            isFormDisabled={isFormDisabled}
            name={name}
            description={description}
            projectId={projectId}
            status={status}
            variant={variant}
            projects={projects}
            onChangeProject={onChangeProject}
            onChangeName={onChangeName}
            onChangeDescription={onChangeDescription}
            onChangeVariant={handleChangeVariant}
            handleBlurName={handleBlurName}
            handleBlurProject={handleBlurProject}
            handleBlurDescription={handleBlurDescription}
            errors={errors}
            handleBlurVariant={handleBlurVariant}
          />
          {isCreate && <GlobalValidationErrorsContainer />}
        </Col>
        <Col xs={3}>{!isCreate && <ModelDetailsInfo failureReason={failureReason} status={status} startingProgress={startingProgress} createdAt={createdAt} />}</Col>
        <Col xs={2}>
          <ModelDetailsHeaderButtons
            isOwner={isOwner}
            id={id}
            workspaceId={workspaceId}
            status={status}
            startTrainingAsync={startTrainingAsync}
            stopTrainingAsync={stopTrainingAsync}
            downloadModelAsync={downloadModelAsync}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ModelDetailsTabsContainer />
        </Col>
      </Row>
    </Container>
  );
};
