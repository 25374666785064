import { WithNamespaces, withNamespaces } from 'react-i18next';

import { FieldStatus } from '../../../../../../../../design/fields/FieldStatus';
import { InputStatus } from '../../../../../../../../__legacy__/models/error.model';
import React from 'react';
import { S_TextField } from '../../../../../../../../design/fields/textField/S_TextField';

interface Props {
  name: string;
  changeName(name: string): void;
  nameStatus: InputStatus;
  disabled?: boolean;
}

const NameFieldPure: React.FC<Props & WithNamespaces> = (props: Props & WithNamespaces) =>
  <S_TextField
    id="annotation-tool-name-input"
    labelText={props.t('name')}
    placeholder={props.t('projects.details.annotation_tools.marking_tools.name')}
    value={props.name}
    onChange={props.changeName}
    fieldStatus={new FieldStatus(props.nameStatus.isValid, props.nameStatus.errorCodes.map((ec: string) => props.t(ec)))}
    disabled={props.disabled}
  />;

export const NameField = withNamespaces('new')(NameFieldPure);
