import { ExportProjectStatsBlType, IExportProjectStatsBl } from '../ExportProjectStats.bl';
import { as, injectProps } from '../../../../../../__legacy__/helpers/react.helpers';

import { ExportProjectStatsButton } from './ExportProjectStatsButton';
import React from 'react';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: IExportProjectStatsBl;
}

@injectProps({
  bl: ExportProjectStatsBlType,
})
@observer
class ExportProjectStatsButtonContainerPure extends React.Component<InjectedProps> {
  handleExportStats = async () => {
    await this.props.bl.exportStatsAsync();
  };

  render(): React.ReactNode {
    return <ExportProjectStatsButton onClickExportButton={this.handleExportStats} />;
  }
}

export const ExportProjectStatsButtonContainer = as<React.ComponentClass>(ExportProjectStatsButtonContainerPure);
