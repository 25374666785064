import { Geometry, Polygon } from 'geojson';
import { ISegmentationFeature } from '../../modules/annotation/annotations.interface';
import { AnnotationToolType } from '../../modules/annotation/submodules/annotationTypes/models/annotationToolType';

export const fixPseudoRectangle = (feature: ISegmentationFeature): ISegmentationFeature => {
  switch (feature.featureType) {
    case AnnotationToolType.RECTANGLE:
      return { ...feature, geometry: fixPseudoRectangleGeometry(feature.geometry) };
    case AnnotationToolType.POLYGON:
    case AnnotationToolType.ROTATEDRECTANGLE:
      return { ...feature, geometry: fixPseudoPolygonGeometry(feature.geometry) };
    default: {
      return feature;
    }
  }
};

export const fixPseudoRectangleGeometry = (geometry: Geometry): Geometry => {
  const polygon: Polygon = <Polygon>geometry;
  const xs = polygon.coordinates[0].map(c => c[0]);
  const lats = polygon.coordinates[0].map(c => c[1]);
  const xMin = Math.min(...xs);
  const xMax = Math.max(...xs);
  const yMin = Math.min(...lats);
  const yMax = Math.max(...lats);

  polygon.coordinates = [[[xMin, yMin], [xMax, yMin], [xMax, yMax], [xMin, yMax], [xMin, yMin]]];

  return polygon;
};

const fixPseudoPolygonGeometry = (geometry: Geometry): Geometry => {
  const polygon: Polygon = <Polygon>geometry;

  if (polygon.coordinates.length && polygon.coordinates[0].length) {
    const first = polygon.coordinates[0][0];
    polygon.coordinates[0][polygon.coordinates.length - 1] = first;
  }

  return polygon;
};
