import { EmptyAnnotationToolFilter, FilterTabType } from '../../../imageFilters.model';

import { FilterHeader } from '../FilterHeader';
import { ITranslatable } from '../../../../../../../helpers/translations.helpers';
import { ImageFiltersFormProps } from '../ImageFiltersForm';
import React from 'react';
import { as } from '../../../../../../../helpers/react.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

@observer
class AnnotationToolsHeaderPure extends React.Component<ImageFiltersFormProps & ITranslatable> {
  handleClear = () => this.props.onSetAnnotationTools([{ ...EmptyAnnotationToolFilter }]);

  render() {
    const isSelected = this.props.activeFilterType === FilterTabType.AnnotationTools;
    const count = this.props.imageFilters.annotationTools.filter(x => x.l1FilterType !== undefined).length;

    return (
      <FilterHeader
        filterType={FilterTabType.AnnotationTools}
        isSelected={isSelected}
        labelKey={'annotation_tools'}
        count={count}
        showClear={count > 0}
        onClear={this.handleClear}
        onSelect={this.props.onFilterTabSelect}
      />
    );
  }
}

export const AnnotationToolsHeader = as<React.ComponentClass<ImageFiltersFormProps>>(withNamespaces('common')(AnnotationToolsHeaderPure));
