import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import React, { useState } from 'react';
import { faBars, faFilter, faKeyboard, faMagic } from '@fortawesome/free-solid-svg-icons';

import { BackToProjectContainer } from '../containers/BackToProject.container';
import { FiltersContainer } from '../../projectDetails/sub/images/containers/filters/FiltersDropdown.container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GlobalHotKeys } from 'react-hotkeys';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { ShortcutsGuide } from './ShortcutsGuide';
import { as } from '../../../helpers/react.helpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';

library.add(faMagic);

export interface IAnnotationMoreOptionsProps {
  globalShortcuts: { label: string; keys: string[] }[];
  create?: boolean;
  review?: boolean;
  correct?: boolean;
  edit?: boolean;
  isFreeAccess?: boolean;
}

const AnnotationMoreOptionsPure = (props: IAnnotationMoreOptionsProps & ITranslatable) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(prevState => !prevState);

  const [isShortcutsGuideOpen, setIsShortcutsGuideOpen] = useState(false);
  const toggleShortcuts = () => setIsShortcutsGuideOpen(prevState => !prevState);

  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const toggleFilters = () => setIsFiltersModalOpen(prevState => !prevState);

  const t = props.t;
  return (
    <>
      <Dropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle tabIndex={-1}>
          <FontAwesomeIcon icon={faBars} size="lg" title={t('more_options')} />
        </DropdownToggle>
        <DropdownMenu right>
          <BackToProjectContainer/>
          {props.isFreeAccess && (
            <DropdownItem onClick={toggleFilters}>
              <FontAwesomeIcon icon={faFilter} /> {t('image_filters.active_filters')}
            </DropdownItem>
          )}
          <DropdownItem onClick={toggleShortcuts}>
            <FontAwesomeIcon icon={faKeyboard} /> {t('shortcuts')}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      {props.isFreeAccess && <FiltersContainer isOpen={isFiltersModalOpen} toggle={toggleFilters} />}
      <ShortcutsGuide
        isOpen={isShortcutsGuideOpen}
        toggle={toggleShortcuts}
        edit={props.edit}
        review={props.review}
        create={props.create}
        correct={props.correct}
        globalShortcuts={props.globalShortcuts}
        isFreeAccess={props.isFreeAccess}
      />

      <GlobalHotKeys
        keyMap={{ HELP: ['shift+/'] }}
        handlers={{
          HELP: () => {
            toggleShortcuts();
          },
        }}
      />
    </>
  );
};

export const AnnotationMoreOptions = as<React.FunctionComponent<IAnnotationMoreOptionsProps>>(withNamespaces('common')(AnnotationMoreOptionsPure));
