import { FilterContent } from '../FilterContent';
import { IDatasetFilterOption } from '../../../imageFilters.model';
import { ITranslatable } from '../../../../../../../helpers/translations.helpers';
import { ImageFiltersFormProps } from '../ImageFiltersForm';
import { InDatasetsCheckboxDropdownMultiSelect } from './InDatasetsCheckboxDropdownMultiSelect';
import React from 'react';
import { as } from '../../../../../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

@observer
class InDatasetsBodyPure extends React.Component<ImageFiltersFormProps & ITranslatable> {

  @autobind
  handleSelectedChanged(selected: IDatasetFilterOption[]) {
    this.props.onSetInDatasets(selected.map(x => x.id));
  }

  render() {
    const options = this.props.availableFilters.datasets;
    const selected = options.filter(d => this.props.imageFilters.datasets.some(x => x === d.id));

    return (
      <FilterContent labelKey={'datasets'}>
        <InDatasetsCheckboxDropdownMultiSelect
          className={'combo-container'}
          options={options}
          values={selected}
          labelField={'name'}
          valueField={'id'}
          onSelectChange={this.handleSelectedChanged}
          t={this.props.t}
        />
      </FilterContent>
    );
  }
}

export const InDatasetsBody = as<React.ComponentClass<ImageFiltersFormProps>>(withNamespaces('common')(InDatasetsBodyPure));
