export class Alert {
  id: string = '';
  text: string = '';
  type: AlertType = AlertType.RESTART_COUNTDOWN;
  validUntil: Date = new Date();
}

export enum AlertType {
  RESTART_COUNTDOWN = 'RestartCountdown',
  TEXT = 'Text',
}
