import { IImportAnnotationsReport, importAnnotationsRejectionReasonTranslation } from '../../../../modules/projects/details/images/importAnnotations/models/ImportAnnotationsReport';

import { CollapsibleImportSummary } from '../../../components/CollapsibleSummary';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IImportAnnotationsSummaryProps extends IImportAnnotationsReport {
  isLastSummaryItem?: boolean;
}

interface IState {
  isPanelOpen: boolean;
}

class ImportAnnotationsSummaryPure extends React.Component<IImportAnnotationsSummaryProps & ITranslatable, IState> {
  constructor(props: IImportAnnotationsSummaryProps & ITranslatable) {
    super(props);
    this.state = { isPanelOpen: false };
  }

  handlePanelToggle = () =>
    this.setState(prevState => {
      return { isPanelOpen: !prevState.isPanelOpen };
    });

  getRejectionReasons = (): string[] =>
    this.props.rejectedAnnotations.map(ra => `${ra.datasetName} - ${ra.imageName} - ${this.props.t(importAnnotationsRejectionReasonTranslation(ra.reason))}`);

  render() {
    return (
      <>
        <CollapsibleImportSummary
          importedItemsCount={this.props.importedAnnotationsCount}
          skippedItemsCount={this.props.skippedAnnotationsCount}
          rejectionReasons={this.getRejectionReasons()}
          isPanelOpen={this.state.isPanelOpen}
          togglePanel={this.handlePanelToggle}
          isLastSummaryItem={this.props.isLastSummaryItem}
          importedMessage={this.props.t('annotations_imported', { count: this.props.importedAnnotationsCount })}
          skippedMessage={this.props.t('annotations_skipped', { count: this.props.skippedAnnotationsCount })}
        />
      </>
    );
  }
}

export const ImportAnnotationsSummary = as<React.ComponentClass<IImportAnnotationsSummaryProps>>(withNamespaces('project')(ImportAnnotationsSummaryPure));
