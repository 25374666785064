import * as React from 'react';

import { DatasetsHeaderContainer } from '../../../modules/datesets/containers/DatasetHeader.container';
import { DatasetsListContainer } from '../../../modules/datesets/containers/DatasetsList.container';
import { ViewContainer } from '../../../containers/ViewContainer';

export const DatasetsListView = () => (
  <ViewContainer header={DatasetsHeaderContainer}>
    <DatasetsListContainer />
  </ViewContainer>
);
