import * as React from 'react';

import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IDeleteAnnotationsConfirmationModalProps {
  showDiscardModal: boolean;
  onDiscardConfirm(): void;
  onDiscardCancel(): void;
}

class DiscardAnnotationConfirmationModalPure extends React.Component<IDeleteAnnotationsConfirmationModalProps & ITranslatable> {
  render() {
    return (
      <ConfirmationModal
        confirmationHeader={this.props.t('discard_annotation')}
        confirmationQuestion={this.props.t('are_you_sure_you_want_to_completely_remove_this_annotation')}
        onCancel={this.props.onDiscardCancel}
        onConfirm={this.props.onDiscardConfirm}
        showModal={this.props.showDiscardModal}
        confirmationYes={this.props.t('discard')}
        loaderKey={"discard-annotation-button"}
      />);
  }
}

export const DiscardAnnotationConfirmationModal = as<React.StatelessComponent<IDeleteAnnotationsConfirmationModalProps>>(
  withNamespaces('annotation', { wait: true })(DiscardAnnotationConfirmationModalPure),
);
