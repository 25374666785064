import { ButtonWithLoader } from '../../../components/ButtonWithLoader';
import { IPublishButtonProps } from '../containers/PublishButton.container';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IPublishButtonOwnProps extends IPublishButtonProps {
  color?: string;
  className?: string;
  projectId?: string
}

class PublishButtonPure extends React.Component<IPublishButtonOwnProps & ITranslatable> {
  render() {
    const loaderKey = 'publish-button-loader';
    return (
      <ButtonWithLoader
        onClick={this.props.onClick}
        loaderKey={this.props.projectId ? `${loaderKey}-${this.props.projectId}` : loaderKey}
        disabled={this.props.disabled}
        color={this.props.color}
        className={this.props.className}
        title={this.props.t('publish')}
      >
        {this.props.t('publish')}
      </ButtonWithLoader>
    );
  }
}

export const PublishButton = as<React.ComponentClass<IPublishButtonOwnProps>>(withNamespaces('project', { wait: true })(PublishButtonPure));
