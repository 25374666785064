import './S_PaginationSelect.scss';

import React from 'react';
import { S_Select } from '../../../inputs/select/S_Select';

interface Props {
  selected: number;
  options: number[];
  onChange(selected: number): void;
}

export const S_PaginationSelect: React.FC<Props> = ({ selected, options, onChange }) => {
  const handleChange = (value: string) => {
    const pageSize = Number.parseInt(value, 10);
    if (!Number.isNaN(pageSize)) {
      onChange(pageSize!);
    }
  };

  return <S_Select className='pagination-select' options={options.map(o => o.toString())} selected={selected.toString()} onChange={handleChange} />;
};
