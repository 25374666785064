import { inject, injectable } from 'inversify';
import { IProjectStatsFiltersStoreSetter, IWorker, ProjectStatsFiltersStoreSetterType } from './ProjectStatsFilters.store';
import { IProjectStatsBl, ProjectStatsBlType } from '../ProjectStats.bl';
import { ProjectDetailsStoreType, IProjectDetailsStore } from '../../../../../__legacy__/modules/projectDetails/projectDetails.store';
import { TimePeriod } from '../../../../../design/selects/timePeriodSelector/S_TimePeriodSelector';
import moment from 'moment';
import _ from 'lodash';
import { IProjectStatsStore, ProjectStatsStoreType } from '../ProjectStats.store';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../workspaces/currentWorkspace/CurrentWorkspace.store';

export const ProjectStatsFiltersBlType = Symbol('PROJECT_STATS_FILTERS_BL_TYPE');

export interface IProjectStatsFiltersBl {
  canGoToNextDate(): boolean;
  canGoToPreviousDate(): boolean;
  goToNextDateAsync(): Promise<void>;
  goToPreviousDateAsync(): Promise<void>;
  hideWorkers(workers: IWorker[]): void;
  load(projectId: string, workers: IWorker[], startDate: moment.Moment, endDate: moment.Moment): void;
  selectDatesAsync(startDate: Date, endDate: Date): Promise<void>;
  selectStartDateAsync(startDate: Date): Promise<void>;
  selectTimePeriodAsync(timePeriod: TimePeriod): Promise<void>;
}

@injectable()
export class ProjectStatsFiltersBl implements IProjectStatsFiltersBl {
  constructor(
    @inject(ProjectStatsFiltersStoreSetterType) private readonly filtersStore: IProjectStatsFiltersStoreSetter,
    @inject(ProjectStatsBlType) private readonly projectStatsBl: IProjectStatsBl,
    @inject(ProjectDetailsStoreType) private readonly projectDetailsStore: IProjectDetailsStore,
    @inject(ProjectStatsStoreType) private readonly statsStore: IProjectStatsStore,
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore,
  ) {}

  hideWorkers(selectedWorkers: IWorker[]): void {
    const workersToHide = this.filtersStore.availableWorkers
      .filter(w => !selectedWorkers.map(sw => sw.id).includes(w.id))
      .concat(this.filtersStore.hiddenWorkers.filter(w => !selectedWorkers.map(sw => sw.id).includes(w.id)));

    this.filtersStore.setHiddenWorkers(this.filtersStore.availableWorkers.filter(w => _.uniq(workersToHide.map(wth => wth.id)).includes(w.id)));
  }

  load(projectId: string, workers: IWorker[], startDate: moment.Moment, endDate: moment.Moment): void {
    this.filtersStore.load(projectId, new Date(this.projectDetailsStore.createdDate), workers, startDate, endDate);
  }

  async selectTimePeriodAsync(timePeriod: TimePeriod): Promise<void> {
    this.filtersStore.setTimePeriod(timePeriod);
    const currentDate = moment(this.currentWorkspaceStore.currentWorkspaceTime).utcOffset(0, true);

    switch (timePeriod) {
      case TimePeriod.All:
        this.filtersStore.setDates(this.filtersStore.projectCreatedDate.clone(), currentDate);
        break;

      case TimePeriod.Month:
        this.filtersStore.setDates(moment(currentDate).startOf('month'), currentDate.endOf('month'));
        break;

      case TimePeriod.Day:
      case TimePeriod.DateRange:
        this.filtersStore.setDates(moment(currentDate).startOf('day'), currentDate.endOf('day'));
        break;

      case TimePeriod.Week:
        this.filtersStore.setDates(moment(currentDate).startOf('isoWeek'), currentDate.endOf('isoWeek'));
        break;
    }

    await this.loadProjectStatsAsync();
  }

  async selectStartDateAsync(newStartDate: Date): Promise<void> {
    const startDate = moment(newStartDate.toDateString()).utcOffset(0, true);
    const endDate = this.getEndDate(startDate);

    this.filtersStore.setDates(startDate, endDate);
    await this.loadProjectStatsAsync();
  }

  async selectDatesAsync(newStartDate: Date, newEndDate: Date): Promise<void> {
    const startDate = moment(newStartDate.toDateString()).utcOffset(0, true);
    const endDate = moment(newEndDate.toDateString()).endOf('day').utcOffset(0, true);

    this.filtersStore.setDates(startDate, endDate);
    await this.loadProjectStatsAsync();
  }

  async goToNextDateAsync(): Promise<void> {
    const startDate = this.getNextStartDate();
    const endDate = this.getEndDate(startDate);

    this.filtersStore.setDates(startDate, endDate);

    await this.loadProjectStatsAsync();
  }

  async goToPreviousDateAsync(): Promise<void> {
    const startDate = this.getPreviousStartDate();
    const endDate = this.getEndDate(startDate);

    this.filtersStore.setDates(startDate, endDate);

    await this.loadProjectStatsAsync();
  }

  canGoToNextDate(): boolean {
    return this.getNextStartDate() <= moment(this.currentWorkspaceStore.currentWorkspaceTime).utcOffset(0, true);
  }

  canGoToPreviousDate(): boolean {
    const projectCreatedDate = this.filtersStore.projectCreatedDate.clone();
    const firstAvailableDate = projectCreatedDate.startOf(
      this.filtersStore.timePeriod === TimePeriod.Month ? 'month' : this.filtersStore.timePeriod === TimePeriod.Week ? 'week' : 'day'
    );
    return this.getPreviousStartDate() >= firstAvailableDate;
  }

  private async loadProjectStatsAsync(): Promise<void> {
    await this.projectStatsBl.displayStatsAsync(this.filtersStore.projectId, this.filtersStore.timePeriod, this.filtersStore.startDate, this.filtersStore.endDate);
    this.filtersStore.setAvailableWorkers(this.statsStore.generalStats.map(gs => ({ id: gs.id, email: gs.email })));
  }

  private getNextStartDate(): moment.Moment {
    const nextDate = moment(this.filtersStore.startDate);
    switch (this.filtersStore.timePeriod) {
      case TimePeriod.Month:
        nextDate.add(1, 'month');
        break;

      case TimePeriod.Day:
        nextDate.add(1, 'day');
        break;

      case TimePeriod.Week:
        nextDate.add(1, 'week');
        break;
    }
    return nextDate;
  }

  private getPreviousStartDate(): moment.Moment {
    const previousDate = moment(this.filtersStore.startDate);
    switch (this.filtersStore.timePeriod) {
      case TimePeriod.Month:
        previousDate.subtract(1, 'month');
        break;

      case TimePeriod.Day:
        previousDate.subtract(1, 'day');
        break;

      case TimePeriod.Week:
        previousDate.subtract(1, 'week');
        break;
    }
    return previousDate;
  }

  private getEndDate(startDate: moment.Moment): moment.Moment {
    const endDate = moment(startDate);
    switch (this.filtersStore.timePeriod) {
      case TimePeriod.Month:
        endDate.endOf('month');
        break;

      case TimePeriod.Week:
        endDate.endOf('isoWeek');
        break;

      default:
        endDate.endOf('day');
        break;
    }

    return endDate;
  }
}
