import { WithNamespaces, withNamespaces } from 'react-i18next';

import { InputStatus } from '../../../../../__legacy__/models/error.model';
import { MAX_FILE_NAME_LENGTH } from '../../../../../__legacy__/helpers/global.constants';
import React from 'react';
import { S_TwoButtonModal } from '../../../../../design/modals/twoButtonModal/S_TwoButtonModal';
import { ValidatedTextInput } from '../../../../../__legacy__/components/ValidatedTextInput';

interface IProps {
  nameStatus: InputStatus;
  name: string;
  show: boolean;
  onSubmit(): void;
  onHide(): void;
  onNameChange(name: string): void;
}

const RenameAttachmentModalPure: React.FC<IProps & WithNamespaces> = (props: IProps & WithNamespaces) => {
  const { show, onHide, onSubmit, t, name, nameStatus, onNameChange } = props;

  return (
    <S_TwoButtonModal
      submitText={t('attachments.modal.submit_text')}
      cancelText={t('attachments.modal.cancel_text')}
      onHide={onHide}
      onSubmit={onSubmit}
      onCancel={onHide}
      headerText={t('attachments.modal.header')}
      show={show}
      disableSubmitButton={!props.nameStatus.isValid}
      loaderKey="rename-attachment-key"
    >
      <ValidatedTextInput
        onPressEnter={onSubmit}
        labelText={t('attachments.modal.label')}
        placeholder={t('attachments.modal.placeholder')}
        value={name}
        onChange={onNameChange}
        isValid={nameStatus.isValid}
        feedbacks={nameStatus.errorCodes.map(ec => props.t(`attachments.validation.${ec}`))}
        feedbacksParams={{ nsSeparator: '_' }}
        maxLength={MAX_FILE_NAME_LENGTH}
      />
    </S_TwoButtonModal>
  );
};

export const RenameAttachmentModal = withNamespaces('new')(RenameAttachmentModalPure);
