import { IGeneralStats, IStatsBase } from './general/models/GeneralStats';

import { IAnnotationToolsStats } from './annotationTools/models/AnnotationToolsStats';
import { IWorkPerformanceStats } from './workPerformance/models/WorkPerformance';
import { SortingDirection } from '../../../../__legacy__/models/sortingDirection.model';
import { injectable } from 'inversify';
import { nameOf } from '../../../../__legacy__/helpers/object.helpers';
import { observable } from 'mobx';

export const ProjectStatsStoreType = Symbol('PROJECT_STATS_STORE');
export const ProjectStatsStoreSetterType = Symbol('PROJECT_STATS_STORE_SETTER');

export interface IProjectStatsStore {
  readonly generalStats: IGeneralStats[];
  readonly generalStatsOrderBy: string;
  readonly generalStatsOrderDirection: SortingDirection | undefined;

  readonly annotationToolsStats: IAnnotationToolsStats[];
  readonly annotationToolsStatsOrderBy: string;
  readonly annotationToolsStatsOrderDirection: SortingDirection | undefined;

  readonly workPerformanceStats: IWorkPerformanceStats[];
  readonly workPerformanceStatsOrderBy: string;
  readonly workPerformanceStatsOrderDirection: SortingDirection | undefined;

  readonly usersEmails: string[];

  readonly isLoading: boolean;
}

export const defaultSortingBy: string = nameOf<IStatsBase>('email');
export const defaultSortingDirection: SortingDirection = SortingDirection.ASC;

export interface IProjectStatsStoreSetter extends IProjectStatsStore {
  setAnnotationToolsStats(annotationToolsStats: IAnnotationToolsStats[]): void;
  setAnnotationToolsStatsSorting(orderBy: string, orderDirection: SortingDirection): void;

  setGeneralStats(generalStats: IGeneralStats[]): void;
  setGeneralStatsSorting(orderBy: string, orderDirection: SortingDirection): void;

  setWorkPerformanceStats(workPerformanceStats: IWorkPerformanceStats[]): void;
  setWorkPerformanceStatsSorting(orderBy: string, orderDirection: SortingDirection): void;

  setIsLoading(isLoading: boolean): void;
}

@injectable()
export class ProjectStatsStore implements IProjectStatsStoreSetter {
  @observable
  generalStats: IGeneralStats[] = [];

  @observable
  generalStatsOrderBy: string = defaultSortingBy;

  @observable
  generalStatsOrderDirection: SortingDirection | undefined = defaultSortingDirection;

  @observable
  annotationToolsStats: IAnnotationToolsStats[] = [];

  @observable
  annotationToolsStatsOrderBy: string = defaultSortingBy;

  @observable
  annotationToolsStatsOrderDirection: SortingDirection | undefined = defaultSortingDirection;

  @observable
  workPerformanceStats: IWorkPerformanceStats[] = [];

  @observable
  workPerformanceStatsOrderBy: string = defaultSortingBy;

  @observable
  workPerformanceStatsOrderDirection: SortingDirection | undefined = defaultSortingDirection;

  @observable
  isLoading: boolean = false;

  setGeneralStats(generalStats: IGeneralStats[]): void {
    generalStats.forEach(s => s.dailyGeneralStats.sort((a, b) => (a.day > b.day ? 1 : -1)));
    this.generalStats = generalStats;
  }

  setAnnotationToolsStats(annotationToolsStats: IAnnotationToolsStats[]) {
    this.annotationToolsStats = annotationToolsStats;
  }

  setWorkPerformanceStats(workPerformanceStats: IWorkPerformanceStats[]): void {
    this.workPerformanceStats = workPerformanceStats;
  }

  setGeneralStatsSorting(orderBy: string, orderDirection: SortingDirection) {
    this.generalStatsOrderBy = orderBy;
    this.generalStatsOrderDirection = orderDirection;
  }

  setAnnotationToolsStatsSorting(orderBy: string, orderDirection: SortingDirection) {
    this.annotationToolsStatsOrderBy = orderBy;
    this.annotationToolsStatsOrderDirection = orderDirection;
  }

  setWorkPerformanceStatsSorting(orderBy: string, orderDirection: SortingDirection) {
    this.workPerformanceStatsOrderBy = orderBy;
    this.workPerformanceStatsOrderDirection = orderDirection;
  }

  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  get usersEmails(): string[] {
    return this.generalStats.map(gs => gs.email);
  }
}
