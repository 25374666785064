import { Polygon, PolygonProps } from 'react-leaflet';

import { AnnotationToolType } from '../../submodules/annotationTypes/models/annotationToolType';
import { ISegmentationFeature } from '../../annotations.interface';
import { ISegmentationPolygonProps } from '../SegmentationLayerFeatures';
import { LatLng } from 'leaflet';
import React from 'react';
import { getOrientationMarkerCoords } from '../../../../helpers/geometry/orientedRectangle.helpers';

export interface IRotatedRectangleFeatureProps {
  isActive: boolean;
  feature: ISegmentationFeature;
  latlngs: LatLng[];
  opacity: number;
  fillOpacity: number;
  zoomLevel: number;
  readonly?: boolean;
  className?: string;
  onSegmentationSelected(id: string): void;
  onSegmentationOver(e: L.LeafletMouseEvent): void;
  onSegmentationOut(e: L.LeafletMouseEvent): void;
  onClick(e: any): void;
  onContextMenu(e: any): void;
}

export class RotatedRectangleFeature extends React.PureComponent<IRotatedRectangleFeatureProps> {
  handleRef = (element: any) => {
    if (this.props.isActive && element) {
      element.leafletElement.enableEdit();
    }
  };

  render() {
    return (
      <div key={`${this.props.feature.id}-orientation-marker-container`} className={'rotated-rectange'}>
        <Polygon<ISegmentationPolygonProps & PolygonProps>
          key={`${this.props.feature.id}-orientation-marker`}
          storeKey={`${this.props.feature.id}-orientation-marker`}
          fillOpacity={this.props.opacity}
          color={this.props.feature.color}
          type={AnnotationToolType.ROTATEDRECTANGLE}
          positions={getOrientationMarkerCoords(this.props.latlngs[3], this.props.latlngs[0], this.props.latlngs[1], this.props.zoomLevel)}
          canRotate={false}
          opacity={this.props.opacity}
          className="orientation-marker"
          onclick={this.props.onClick}
          onmouseover={this.props.onSegmentationOver}
          onmouseout={this.props.onSegmentationOut}
        />
        <Polygon<ISegmentationPolygonProps & PolygonProps>
          ref={this.handleRef}
          key={this.props.feature.id}
          storeKey={this.props.feature.id}
          fillOpacity={this.props.fillOpacity}
          color={this.props.feature.color}
          type={AnnotationToolType.ROTATEDRECTANGLE}
          positions={this.props.latlngs}
          onclick={this.props.onClick}
          onmouseover={this.props.onSegmentationOver}
          onmouseout={this.props.onSegmentationOut}
          oncontextmenu={this.props.onContextMenu}
          canRotate={true}
          opacity={this.props.opacity}
          className={this.props.className}
        />
      </div>
    );
  }
}
