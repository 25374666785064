import { inject, injectable } from 'inversify';
import { StickerError } from '../../../../__legacy__/models/error.model';
import { ApiService } from '../../../../__legacy__/services/api.service';
import { ApiServiceImageUploadType } from '../../../../__legacy__/services/api.service.imageUpload';

export const CheckAttachmentNameUniquenessServiceType = Symbol('CHECK_ATTACHMENT_NAME_UNIQUENESS_SERVICE');

export interface ICheckAttachmentNameUniquenessRequest {
  workspaceId: string;
  attachmentId: string;
  name: string;
  parentId?: string;
}

export interface ICheckAttachmentNameUniquenessService {
  checkAttachmentNameUniqueness(request: ICheckAttachmentNameUniquenessRequest): Promise<boolean>;
}

@injectable()
export class CheckAttachmentNameUniquenessService implements ICheckAttachmentNameUniquenessService {
  constructor(@inject(ApiServiceImageUploadType) private readonly apiServiceImageUpload: ApiService) {}

  async checkAttachmentNameUniqueness(request: ICheckAttachmentNameUniquenessRequest): Promise<boolean> {
    const result = await this.apiServiceImageUpload.getAsync<boolean>('/Attachments/CheckAttachmentNameUniqueness', { params: request });

    if (result instanceof StickerError) throw result;

    return result;
  }
}
