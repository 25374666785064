import * as React from 'react';

import { Col, Row } from 'reactstrap';
import { Route, RouteComponentProps } from 'react-router';

import { DatasetDetailsImageListControlsContainer } from '../containers/DatasetDetailsImageListControls.container';
import { DatasetStatus } from '../datasetStatus.model';
import { Home } from '../../../routes/config/Home';
import { IDatasetDetails } from '../datasetsDetails.store';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { Observer } from 'mobx-react';
import { TabsContainer } from '../../../containers/TabsContainer';
import { TabsSingleItem } from '../../../components/TabsSingleItem';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IProps {
  workspaceId: string;
  details: IDatasetDetails;
  imageClickCallback(): void;
}

interface ITabProps {
  link: string;
  label: string;
  currentTab: string;
  callback?(): void;
}

const DatasetDetailsTabsPure = (props: IProps & ITranslatable) => {
  const Tab = ({ link, label, currentTab, callback }: ITabProps) => (
    <TabsSingleItem
      text={label}
      link={Home.Datasets.Details.Paths.withParams({ datasetId: props.details.id, tab: link, workspaceId: props.workspaceId })}
      state={currentTab === link ? 'active' : 'passive'}
      callback={callback}
    />
  );

  return (
    <>
      <Row>
        <Col>
          <span
            className="actions-comment"
            dangerouslySetInnerHTML={{
              __html:
                props.details.status === DatasetStatus.PUBLISHED || props.details.status === DatasetStatus.PUBLISHEDUPLOADING
                  ? props.t('this_dataset_has_been_published')
                  : props.t('dataset_must_be_published'),
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Route path={Home.Datasets.Details.Paths.Path}>
            {({ match }: RouteComponentProps<{ tab: string }>) => (
              <Observer>
                {() => (
                  <TabsContainer variant="default">
                    <Tab
                      link="images"
                      label={`${props.t('images')} (${props.details.imagesCount})`}
                      currentTab={match.params.tab || 'images'}
                      callback={props.imageClickCallback}
                    />
                    <Tab link="projects" label={`${props.t('projects')} (${props.details.projectsCount})`} currentTab={match.params.tab || 'images'} />
                  </TabsContainer>
                )}
              </Observer>
            )}
          </Route>
        </Col>
        <Col sm="4">{window.location.pathname.includes('images') && <DatasetDetailsImageListControlsContainer />}</Col>
      </Row>
    </>
  );
};

export const DatasetDetailsTabs = as<React.FunctionComponent<IProps>>(withNamespaces('datasets')(DatasetDetailsTabsPure));
