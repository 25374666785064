import { injectable, inject } from 'inversify';
import { ApiServiceType, IPagedResult } from '../../../../../services/api.service';
import { StickerError } from '../../../../../models/error.model';
import { IApiService } from '../../../../../services/api.service.base';
import { IProjectDetailsImage } from '../projectDetailsImages.model';
import { IImageFilters, IActiveFilterDto } from '../imageFilters.model';

export const ImageFiltersApiServiceType = Symbol('IMAGE_FILTERS_API_SERVICE');

export interface IGetImagesRequest {
  projectId: string;
  pageSize: number;
  pageNumber: number;
  filters: IImageFilters;
}

export interface IGetActiveFiltersRequest {
  projectId: string;
  filterId: string | undefined;
}

export interface IImagesPagingResult<T> extends IPagedResult<T> {
  totalImagesCount: number;
  unlockedImagesCount: number;
  filterId: string;
}

export interface ISaveFiltersRequest {
  name: string;
  projectId: string;
  filters: IImageFilters;
}

export interface IDeleteFiltersRequest {
  filterId: string;
}

export interface IUpsertFiltersRequest {
  projectId: string;
  filters: IImageFilters;
}

export interface IImageFiltersApiService {
  getProjectImagesAsync(request: IGetImagesRequest): Promise<IImagesPagingResult<IProjectDetailsImage> | StickerError>;
  getActiveFilters(request: IGetActiveFiltersRequest): Promise<IActiveFilterDto | StickerError>;
  saveFiltersAsync(request: ISaveFiltersRequest): Promise<void | StickerError>;
  deleteFiltersAsync(request: IDeleteFiltersRequest): Promise<void | StickerError>;
  upsertFiltersAsync(request: IUpsertFiltersRequest): Promise<string | StickerError>
}

@injectable()
export class ImageFiltersApiService implements IImageFiltersApiService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService) { }

  async getActiveFilters(request: IGetActiveFiltersRequest): Promise<IActiveFilterDto | StickerError> {
    return this.apiService.getAsync<IActiveFilterDto>('/Projects/GetActiveFilters', { params: request });
  }

  async getProjectImagesAsync(request: IGetImagesRequest) {
    return await this.apiService.postAsync<IGetImagesRequest, IImagesPagingResult<IProjectDetailsImage>>('/Projects/Images', request);
  }

  async saveFiltersAsync(request: ISaveFiltersRequest): Promise<void | StickerError> {
    return await this.apiService.postAsync<{}, void>('/Projects/SaveFilters', request);
  }

  async deleteFiltersAsync(request: IDeleteFiltersRequest): Promise<void | StickerError> {
    return await this.apiService.postAsync<{}, void>('/Projects/DeleteFilters', request);
  }

  async upsertFiltersAsync(request: IUpsertFiltersRequest): Promise<string | StickerError> {
    return await this.apiService.postAsync<IUpsertFiltersRequest, string>('/Projects/UpsertImagesFilter', request);
  }
}
