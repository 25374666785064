import { FormGroup, Input, Label } from 'reactstrap';

import { ITranslatable } from '../../../../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../../../../helpers/react.helpers';
import { handleChange } from '../../../../../../helpers/formHelpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IProps {
  searchText: string;
  onSearchChange(text: string): void;
}

@observer
class ImageNameSearchPure extends React.Component<IProps & ITranslatable> {
  render() {
    return (
      <div>
        <FormGroup>
          <Label>{this.props.t('image_name')}</Label>
          <Input
            value={this.props.searchText}
            type={'text'}
            placeholder={`${this.props.t('search')}...`}
            onChange={handleChange(this.props.onSearchChange)}
          />
        </FormGroup>
      </div>
    );
  }
}

export const ImageNameSearch = as<React.ComponentClass<IProps>>(withNamespaces('common')(ImageNameSearchPure));
