import { S_Modal, S_ModalPropsBase } from '../modal/S_Modal';

import { PrimaryButtonWithLoaderContainer } from '../../../modules/common/PrimaryButtonWithLoader.container';
import React from 'react';
import { S_CancelButton } from '../../buttons/textButton/cancelButton/S_CancelButton';
import { S_ClassNameProps } from '../../CommonProps';

interface S_TwoButtonModalProps extends S_ModalPropsBase, S_ClassNameProps {
  cancelText: string;
  disableSubmitButton?: boolean;
  submitText: string;
  onHide(): void;
  onSubmit(): void;
  onCancel(): void;
}

export const S_TwoButtonModal: React.FC<S_TwoButtonModalProps> = (props: S_TwoButtonModalProps) => (
  <S_Modal
    {...props}
    buttons={[
      <PrimaryButtonWithLoaderContainer
        loaderKey={props.loaderKey}
        onClick={props.onSubmit}
        text={props.submitText}
        key={'twoButtonModal-primary'}
        disabled={props.disableSubmitButton}
      />,
      <S_CancelButton onClick={props.onCancel} text={props.cancelText} key={'twoButtonModal-cancel'} />,
    ]}
  />
);
