import { inject, injectable } from 'inversify';
import { ForbiddenError, InputStatus, StickerError } from '../../__legacy__/models/error.model';
import { INotificationsService, NotificationsServiceType } from '../../__legacy__/modules/notifications/services/notifications.service';
import { NotificationLevel, ToastNotification } from '../../__legacy__/modules/notifications/models/notification.model';
import { IModelDetailsStore, MODEL_DETAILS_STORE_INITIAL_STATE, ModelDetailsStoreType } from './modelDetails.store';
import { IGetModelImagesRequest, IModelDetailsApiService, IPostModelTrainingStartRequest, ModelDetailsApiServiceType } from './services/modelDetailsApi.service';
import { action } from 'mobx';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../workspaces/currentWorkspace/CurrentWorkspace.store';
import { MODEL_RESULTS_INTEGERS } from './modelDetails.model';
import { MODEL_STATUS } from '../models/models.model';
import { IRouterStore, RouterStoreType } from '../../__legacy__/stores/router.store';
import { Home } from '../../__legacy__/routes/config/Home';
import { OverlayLoaderStoreType, IOverlayLoaderStore } from '../common/OverlayLoader.store';
import { PaginationInfoDefault } from '../../__legacy__/models/paginationInfo.model';
import { PARAMETER_TYPE_INTERNAL, parseParameters } from '../../__legacy__/helpers/parameters.helpers';
import { downloadFile } from '../../__legacy__/helpers/fileDownload.helpers';
import { MODEL_VARIANTS } from '../../__legacy__/models/metrics.model';

export const ModelDetailsServiceType = Symbol('MODEL_DETAILS_SERVICE');

export interface IModelDetailsService {
  store: IModelDetailsStore;

  validateInitialEmptyFields(): void;
  hasHeaderErrors(): boolean;
  notifyAboutApiError(result: any, errorCode: string, dontShowBadRequest?: boolean): void;

  // overview
  getModelDetailsAsync(modelId: string): Promise<void | StickerError>;
  getModelProjectsAsync(): Promise<void | StickerError>;
  getModelDatasetsAsync(): Promise<void | StickerError>;
  getModelSettingsSchemeAsync(setValues?: boolean): Promise<void | StickerError>;
  getModelImagesAsync(): Promise<void | StickerError>;
  getModelImagesPredictionsAsync(): Promise<void | StickerError>;
  getModelTrainingProgressAsync(modelId: string): Promise<void | StickerError>;
  getModelStatusAsync(modelId: string): Promise<MODEL_STATUS>;

  // actions
  changeDatasetSelection(datasetId: string): void;
  changeAllDatasetsSelection(): void;
  deselectAllDatasets(): void;
  selectAllDatasets(): void;
  handleChangeNumericParam(label: string, value: string): void;
  handleBlurNumericParam(label: string, value: string): void;
  handleChangeBooleanParam(label: string, value: boolean): void;
  handleBlurBooleanParam(label: string, value: boolean): void;
  handleChangeName(name: string): void;
  handleChangeDescription(description: string): void;
  handleChangeProject(projectId: string): void;
  handleChangeVariant(variant: MODEL_VARIANTS): void;
  startTrainingAsync(): Promise<void | StickerError>;
  stopTrainingAsync(): Promise<void | StickerError>;
  downloadModelAsync(): Promise<void | StickerError>;
  changePagination(pageNumber: number, pageSize: number): Promise<void>;
  getTabsValidationErrors(): Map<string, InputStatus>;
  handleBlurName(name: string): void;
  handleBlurProject(projectId: string): void;
  handleBlurVariant(variant: MODEL_VARIANTS): void;
  togglePredictions(): Promise<void>;
  handleBlurDescription(description: string): void;
}

@injectable()
export class ModelDetailsService implements IModelDetailsService {
  constructor(
    @inject(ModelDetailsStoreType) public readonly store: IModelDetailsStore,
    @inject(NotificationsServiceType) private readonly notificationsService: INotificationsService,
    @inject(ModelDetailsApiServiceType) private readonly modelDetailsApiService: IModelDetailsApiService,
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore,
    @inject(RouterStoreType) private readonly routerStore: IRouterStore,
    @inject(OverlayLoaderStoreType) private readonly overlayLoaderStore: IOverlayLoaderStore,
  ) {}

  @action
  async getModelDetailsAsync(modelId: string): Promise<void | StickerError> {
    const { currentWorkspace } = this.currentWorkspaceStore;

    if (!currentWorkspace) return;

    const result = await this.modelDetailsApiService.getModelDetailsAsync(currentWorkspace.id, modelId);

    if (result instanceof StickerError) return;

    this.store.id = modelId;
    this.store.isOwner = result.is_owner;
    this.store.name = result.model_name;
    this.store.description = result.model_description;
    this.store.createdAt = `${result.date_created}Z`; // Adding Z to mark this time as UTC (backend sends time without it)
    this.store.variant = result.model_variant;
    this.store.status = result.status === MODEL_STATUS.FAILING ? MODEL_STATUS.STOPPING : result.status;
    this.store.failureReason = result.failure_reason;
    this.store.projectId = result.datasets.project_id;
    this.store.datasetIds = result.datasets.datasets_ids;
    this.store.modelConfig = { ...result.model_config_ };

    await this.getModelSettingsSchemeAsync();
    await this.getModelProjectsAsync();
    await this.getModelDatasetsAsync();
    await this.getModelImagesAsync();
  }

  @action
  async getModelProjectsAsync(): Promise<void | StickerError> {
    const { currentWorkspace } = this.currentWorkspaceStore;

    if (!currentWorkspace) return;

    const result = await this.modelDetailsApiService.getProjectsInWorkspaceAsync({ workspaceId: currentWorkspace.id });

    if (result instanceof StickerError) return;

    this.store.projects = result.map(project => ({
      value: project.id,
      label: project.name,
    }));
  }

  @action
  async getModelDatasetsAsync(): Promise<void | StickerError> {
    const { projectId } = this.store;

    await this.overlayLoaderStore.withLoaderAsync('model-datasets-list', async () => {
      const result = await this.modelDetailsApiService.getModelDatasetsAsync({ projectId });

      if (result instanceof StickerError) {
        return;
      }

      this.store.datasets = result;
    });
  }

  @action
  async getModelImagesAsync(): Promise<void | StickerError> {
    const { pageNumber, pageSize, orderBy, orderType, search } = this.store.modelImagesPaging;
    const { projectId, datasetIds } = this.store;

    const request: IGetModelImagesRequest = {
      pageNumber,
      pageSize,
      orderBy,
      orderType,
      search,
      projectId,
      datasetsIds: datasetIds,
    };

    await this.overlayLoaderStore.withLoaderAsync('model-images-loader', async () => {
      const result = await this.modelDetailsApiService.getModelImagesAsync(request);

      if (result instanceof StickerError) return;

      if (result.pagesCount < result.pageNumber && result.pagesCount !== 0) {
        this.store.modelImagesPaging.pageNumber = result.pagesCount;
        await this.getModelImagesAsync();
      } else {
        this.store.images = result.data;
        this.store.modelImagesPaging = {
          ...this.store.modelImagesPaging,
          orderType,
          orderBy,
          pageNumber: result.pageNumber,
          pagesCount: result.pagesCount,
          totalCount: result.totalCount,
        };
        if (this.store.modelImagesPaging.fetchPredictions) {
          this.getModelImagesPredictionsAsync();
        }
      }
    });
  }

  @action
  async getModelImagesPredictionsAsync(): Promise<void | StickerError> {
    const { id, images } = this.store;
    const { currentWorkspace } = this.currentWorkspaceStore;

    if (!currentWorkspace) return;

    await this.overlayLoaderStore.withLoaderAsync('model-images-predictions-loader', async () => {
      const imagesIds = images.map(image => image.id);
      const result = await this.modelDetailsApiService.getModelImagesPredictionsAsync(currentWorkspace.id, id, imagesIds);

      if (result instanceof StickerError) {
        this.store.modelImagesPaging.fetchPredictions = false;
        this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'models:predictions_fetch_error'));
        return;
      }

      this.store.images = images.map(image => ({
        ...image,
        prediction: {
          confusion_matrix: null,
          recall: null,
          precision: null,
          f1: null,
          auc: null,
          count: null,
          precision_recall_curve: null,
          score: null,
          ground_truth: result[image.id]?.ground_truth || null,
          ...(result[image.id]?.metrics || {}),
        },
      }));
    });
  }

  @action
  async getModelSettingsSchemeAsync(setValues?: boolean): Promise<void | StickerError> {
    const { currentWorkspace } = this.currentWorkspaceStore;

    if (!currentWorkspace?.id) return;

    const { variant } = this.store;

    const result = await this.modelDetailsApiService.getModelSettingsParamsAsync(currentWorkspace.id, variant);

    if (result instanceof StickerError) return;

    const { parsedParameters, preselectedValues } = parseParameters(result.params);
    this.store.params = parsedParameters;
    if (setValues) {
      this.store.modelConfig = preselectedValues;
    }
  }

  @action
  async getModelTrainingProgressAsync(modelId: string): Promise<void> {
    const { currentWorkspace } = this.currentWorkspaceStore;

    if (!currentWorkspace) return;

    const result = await this.modelDetailsApiService.getModelTrainingProgressAsync(currentWorkspace.id, modelId);

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'models:get_model_progress_failed');
      return;
    }

    if (result['train_loss']?.length) {
      this.store.train = result['train_loss'];
    }

    if (result['validation_loss']?.length) {
      this.store.validation = result['validation_loss'];
    }

    if (!result.metrics) return;

    this.store.results = result.metrics.results.map((result, idx) =>
      MODEL_RESULTS_INTEGERS.includes(result.name)
        ? { ...result, id: idx.toString() }
        : {
            ...result,
            id: idx.toString(),
            // Using != to check for null and undefined
            train: result.train != null ? result.train.toFixed(3) : null,
            test: result.test != null ? result.test.toFixed(3) : null,
            validation: result.validation != null ? result.validation.toFixed(3) : null,
          },
    );

    if (result.metrics['confusion_matrix'].length) {
      this.store.matrix = result.metrics['confusion_matrix'].map((item, idx) => ({ ...item, id: idx.toString() }));
    }

    this.store.metrics = {
      // Using != to check for null and undefined
      auc: result.metrics.auc != null ? result.metrics.auc.toFixed(3) : null,
    };

    this.store.curve = result.metrics['precision_recall_curve'];
  }

  @action
  async getModelStatusAsync(modelId: string): Promise<MODEL_STATUS> {
    const { currentWorkspace } = this.currentWorkspaceStore;

    if (!currentWorkspace) return MODEL_STATUS.STARTING;

    const result = await this.modelDetailsApiService.getModelTrainingStatusAsync(currentWorkspace.id, modelId);

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'models:get_model_status_failed');
      return MODEL_STATUS.STARTING;
    }

    const { status, starting_progress } = result;
    this.store.status = status === MODEL_STATUS.FAILING ? MODEL_STATUS.STOPPING : status;
    this.store.startingProgress = starting_progress;

    return status;
  }

  @action.bound
  async startTrainingAsync() {
    const { currentWorkspace } = this.currentWorkspaceStore;

    if (!currentWorkspace) return;

    this.validateInitialEmptyFields();
    const validationErrors = this.getTabsValidationErrors();

    const { id, projectId, datasetIds, modelConfig, name, description, variant, modelDetailsValidationErrors } = this.store;
    this.store.modelDetailsValidationErrors = { ...modelDetailsValidationErrors, showAll: true };

    if (validationErrors.size > 0 || this.hasHeaderErrors()) return;

    const request: IPostModelTrainingStartRequest = {
      model_description: description,
      model_config_: modelConfig,
      model_variant: variant,
      model_name: name,
      datasets: {
        project_id: projectId,
        datasets_ids: datasetIds,
      },
    };

    if (id) {
      const result = await this.modelDetailsApiService.postModelTrainingRestartAsync(currentWorkspace.id, id, request);

      if (result instanceof StickerError) {
        this.notifyAboutApiError(result, 'models:restart_training.failed');
        return;
      }
      this.store.status = MODEL_STATUS.STARTING;

      this.routerStore.push(Home.Models.Details.Metrics.withParams({ workspaceId: currentWorkspace.id, jobId: result['job-id'] }));

      this.notificationsService.push(new ToastNotification(NotificationLevel.SUCCESS, 'models:restart_training.success'));
    } else {
      const result = await this.modelDetailsApiService.postModelTrainingStartAsync(currentWorkspace.id, request);

      if (result instanceof StickerError) {
        this.notifyAboutApiError(result, 'models:start_training.failed');
        return;
      }
      this.store.status = MODEL_STATUS.STARTING;

      this.routerStore.push(Home.Models.Details.Metrics.withParams({ workspaceId: currentWorkspace.id, jobId: result['job-id'] }));

      this.notificationsService.push(new ToastNotification(NotificationLevel.SUCCESS, 'models:start_training.success'));
    }

    // Reset validation errors after success
    this.store.modelDetailsValidationErrors = MODEL_DETAILS_STORE_INITIAL_STATE.modelDetailsValidationErrors;
  }

  @action.bound
  async stopTrainingAsync() {
    const { currentWorkspace } = this.currentWorkspaceStore;
    const { id } = this.store;

    if (!currentWorkspace) return;

    const result = await this.modelDetailsApiService.postModelTrainingStopAsync(currentWorkspace.id, id);

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'models:stop_training.failed');
      return;
    }

    this.notificationsService.push(new ToastNotification(NotificationLevel.SUCCESS, 'models:stop_training.success'));

    this.store.status = MODEL_STATUS.STOPPING;
  }

  @action.bound
  async downloadModelAsync() {
    const { currentWorkspace } = this.currentWorkspaceStore;

    if (!currentWorkspace) return;

    const { id } = this.store;

    const result = await this.modelDetailsApiService.getDownloadModelAsync(currentWorkspace.id, id);

    if (result instanceof StickerError) {
      this.notifyAboutApiError(result, 'models:generate_download_url.failed');
      return;
    }

    downloadFile(result.url);
  }

  @action.bound
  changeDatasetSelection(datasetId: string) {
    const { datasetIds, modelDetailsValidationErrors } = this.store;

    if (datasetIds.includes(datasetId)) {
      this.store.datasetIds = datasetIds.filter(id => id !== datasetId);
    } else {
      this.store.datasetIds = [...datasetIds, datasetId];
    }

    modelDetailsValidationErrors.datasets = this.store.datasetIds.length === 0;
    modelDetailsValidationErrors.images = this.store.datasetIds.length === 0;
    this.store.modelDetailsValidationErrors = { ...modelDetailsValidationErrors };

    this.changePagination(PaginationInfoDefault.pageNumber, this.store.modelImagesPaging.pageSize);
  }

  @action.bound
  changeAllDatasetsSelection() {
    if (this.store.datasets.length === this.store.datasetIds.length) {
      this.deselectAllDatasets();
    } else {
      this.selectAllDatasets();
    }

    const { modelDetailsValidationErrors } = this.store;
    modelDetailsValidationErrors.datasets = this.store.datasetIds.length === 0;
    modelDetailsValidationErrors.images = this.store.datasetIds.length === 0;
    this.store.modelDetailsValidationErrors = { ...modelDetailsValidationErrors };
  }

  @action.bound
  deselectAllDatasets() {
    this.store.datasetIds = [];
    this.changePagination(PaginationInfoDefault.pageNumber, this.store.modelImagesPaging.pageSize);
  }

  @action.bound
  selectAllDatasets() {
    this.store.datasetIds = this.store.datasets.map(dataset => dataset.id);
    this.changePagination(PaginationInfoDefault.pageNumber, this.store.modelImagesPaging.pageSize);
  }

  @action.bound
  handleChangeNumericParam(label: string, value: string) {
    const { modelConfig, params } = this.store;

    const param = params.find(param => param.label === label);

    if (!param) return;

    const { type } = param;
    const asInt = parseInt(value, 10);
    const asFloat = parseFloat(value);

    if (isNaN(asInt) || isNaN(asFloat)) {
      this.store.modelConfig = { ...modelConfig, [label]: 0 };
      return;
    }

    if (type === PARAMETER_TYPE_INTERNAL.INT) {
      this.store.modelConfig = { ...modelConfig, [label]: asInt };
    } else if (type === PARAMETER_TYPE_INTERNAL.FLOAT) {
      this.store.modelConfig = { ...modelConfig, [label]: asFloat };
    }
  }

  @action.bound
  handleBlurNumericParam(label: string, value: string) {
    const { modelDetailsValidationErrors, params } = this.store;
    const param = params.find(param => param.label === label);
    if (!param || (param.type !== PARAMETER_TYPE_INTERNAL.FLOAT && param.type !== PARAMETER_TYPE_INTERNAL.INT)) return;

    const validationResult = param.config.validator(value);
    if (validationResult) {
      modelDetailsValidationErrors.settings.set(param.label, null);
    } else {
      modelDetailsValidationErrors.settings.set(param.label, {
        message: 'models:params.numeric_validation_error',
        messageParameters: {
          rangeStart: param.config.min,
          rangeEnd: param.config.max,
        },
      });
    }

    this.store.modelDetailsValidationErrors = { ...modelDetailsValidationErrors };
  }

  @action.bound
  handleChangeBooleanParam(label: string, value: boolean) {
    const { modelConfig } = this.store;

    this.store.modelConfig = { ...modelConfig, [label]: value };
  }

  @action.bound
  handleBlurBooleanParam(label: string, _: boolean) {
    const { modelDetailsValidationErrors } = this.store;

    modelDetailsValidationErrors.settings.set(label, null);

    this.store.modelDetailsValidationErrors = { ...modelDetailsValidationErrors };
  }

  @action.bound
  handleChangeName(name: string) {
    this.store.name = name;
  }

  @action.bound
  handleBlurName(name: string) {
    const { modelDetailsValidationErrors } = this.store;

    if (!name) {
      modelDetailsValidationErrors.header.set('name', {
        message: 'models:fields.text_validation_error',
      });
    } else {
      modelDetailsValidationErrors.header.set('name', null);
    }

    this.store.modelDetailsValidationErrors = { ...modelDetailsValidationErrors };
  }

  @action.bound
  handleBlurProject(projectId: string) {
    const { modelDetailsValidationErrors, datasetIds } = this.store;

    if (!projectId) {
      modelDetailsValidationErrors.header.set('project', {
        message: 'models:fields.text_validation_error',
      });
      modelDetailsValidationErrors.datasets = true;
    } else {
      modelDetailsValidationErrors.header.set('project', null);
    }

    if (datasetIds.length) {
      modelDetailsValidationErrors.datasets = false;
      modelDetailsValidationErrors.images = false;
    }

    this.store.modelDetailsValidationErrors = { ...modelDetailsValidationErrors };
  }

  @action.bound
  handleBlurVariant(variant: MODEL_VARIANTS) {
    const { modelDetailsValidationErrors } = this.store;

    if (!variant) {
      modelDetailsValidationErrors.header.set('variant', {
        message: 'models:fields.text_validation_error',
      });
    } else {
      modelDetailsValidationErrors.header.set('variant', null);
    }

    this.store.modelDetailsValidationErrors = { ...modelDetailsValidationErrors };
  }

  @action.bound
  handleChangeDescription(description: string) {
    this.store.description = description;
  }

  @action.bound
  handleBlurDescription(_: string) {
    const { modelDetailsValidationErrors } = this.store;

    modelDetailsValidationErrors.header.set('description', null);

    this.store.modelDetailsValidationErrors = { ...modelDetailsValidationErrors };
  }

  @action
  async togglePredictions(): Promise<void> {
    this.store.modelImagesPaging.fetchPredictions = !this.store.modelImagesPaging.fetchPredictions;

    const arePredictionsFetched = this.store.images.some(image => image.prediction !== undefined);

    if (this.store.modelImagesPaging.fetchPredictions && !arePredictionsFetched) {
      await this.getModelImagesPredictionsAsync();
    }
  }

  @action
  async changePagination(pageNumber: number, pageSize: number): Promise<void> {
    this.store.modelImagesPaging.pageNumber = pageNumber;
    this.store.modelImagesPaging.pageSize = pageSize;

    await this.getModelImagesAsync();
  }

  @action.bound
  async handleChangeProject(projectId: string) {
    this.store.projectId = projectId;

    await this.getModelDatasetsAsync();
    this.selectAllDatasets();
    // Revalidate datasets after data is fetched
    this.handleBlurProject(projectId);
  }

  @action.bound
  async handleChangeVariant(variant: MODEL_VARIANTS) {
    this.store.variant = variant;

    await this.overlayLoaderStore.withLoaderAsync('model-settings-list', async () => {
      await this.getModelSettingsSchemeAsync(true);

      const { modelDetailsValidationErrors } = this.store;
      modelDetailsValidationErrors.settings.clear();
    });
    // Revalidate datasets after data is fetched
    this.handleBlurVariant(variant);
  }

  notifyAboutApiError(result: any, errorCode: string, dontShowBadRequest: boolean = false) {
    if (result instanceof StickerError && !(result instanceof ForbiddenError) && (!dontShowBadRequest || !result.isBadRequest())) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, errorCode));
    }
  }

  @action.bound
  getTabsValidationErrors() {
    const { datasetIds, modelDetailsValidationErrors } = this.store;
    const errors: Map<string, InputStatus> = new Map();

    // Add errors based on the state
    if (modelDetailsValidationErrors.settings.size > 0 && Array.from(modelDetailsValidationErrors.settings.values()).some(error => error !== null)) {
      errors.set('settings', InputStatus.buildFrom(['start_training.validation_error']));
    }
    if (datasetIds.length === 0) {
      errors.set('datasets', InputStatus.buildFrom(['start_training.no_chosen_datasets']));
    }

    return errors;
  }

  validateInitialEmptyFields() {
    const { datasetIds, projectId, modelDetailsValidationErrors } = this.store;

    if (datasetIds.length === 0) {
      modelDetailsValidationErrors.datasets = true;
      modelDetailsValidationErrors.images = true;
    }
    if (!projectId) {
      modelDetailsValidationErrors.header.set('project', {
        message: 'models:fields.text_validation_error',
      });
    }
  }

  hasHeaderErrors() {
    const { modelDetailsValidationErrors } = this.store;

    return modelDetailsValidationErrors.header.size > 0 && Array.from(modelDetailsValidationErrors.header.values()).some(error => error !== null);
  }
}
