import React from 'react';
import { withNamespaces } from 'react-i18next';
import ModelsListTable, { IModelsListTableProps } from './ModelsListTable';
import { as } from '../../../__legacy__/helpers/react.helpers';

const ModelsListPure = (props: IModelsListTableProps) => {
  return (
    <ModelsListTable
      workspaceId={props.workspaceId}
      models={props.models}
      pagination={props.pagination}
      sorting={props.sorting}
      downloadModelAsync={props.downloadModelAsync}
      onRestartTraining={props.onRestartTraining}
      onStopTraining={props.onStopTraining}
      onDeleteModel={props.onDeleteModel}
      onOrderingChange={props.onOrderingChange}
      onEditModel={props.onEditModel}
    />
  );
};

export const ModelsList = as<React.FC<IModelsListTableProps>>(withNamespaces('common', { wait: true })(ModelsListPure));
