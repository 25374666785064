import { IGetAllImages, IPredictionImagesPreviewApiService, PredictionImagesPreviewApiServiceType } from './predictionImagesPreviewApi.service';
import { IImageWithDataset, PredictionImagesPreviewStore, PredictionImagesPreviewStoreType } from './predictionImagesPreview.store';
import { inject, injectable } from 'inversify';
import { BaseAnnotationControl } from '../../modules/annotation/baseAnnotationControl';
import { IImage, IQuestionModelDto, ISegmentation, ISegmentationDto } from '../../modules/annotation/annotations.interface';
import { AnnotationApiServiceType, IAnnotationApiService } from '../../modules/annotation/annotationApi.service';
import { AnnotationServiceType, IAnnotationService } from '../../modules/annotation/annotation.service';
import { IProjectHubService, ProjectHubServiceType } from '../../services/projectHub.service';
import { INotificationsService, NotificationsServiceType } from '../../modules/notifications/services/notifications.service';
import { AnnotationUiStoreType, IAnnotationUiStore } from '../../modules/annotation/annotationUi.store';
import { AnnotationsStoreType, IAnnotationsStore } from '../../modules/annotation/annotations.store';
import { action } from 'mobx';
import { DatasetStatus } from '../../modules/datesets/datasetStatus.model';
import { IAnswerModel, IQuestionModel, QuestionModel, QuestionType } from '../../modules/annotation/question.model';
import { StickerError } from '../../models/error.model';
import uuid from 'uuid';
import { getLatLngsForGeojson } from '../../helpers/geometry/polygon.helpers';
import { LatLngBounds } from 'leaflet';
import { ApiServiceType } from '../../services/api.service';
import { IApiService } from '../../services/api.service.base';
import { IDatasetDetails, IDatasetPreviewImage } from '../../modules/datesets/datasetsDetails.store';
import { AnnotationTypeBlType, AnnotationTypeBl } from '../../modules/annotation/submodules/annotationTypes/annotationType.bl';
import { NotificationLevel, ToastNotification } from '../../modules/notifications/models/notification.model';
import { ApiServiceTrainingType } from '../../services/api.service.training';

export const PredictionImagesPreviewServiceType = Symbol('PREDICTION_IMAGES_PREVIEW_SERVICE');

interface IPredictionImagePreviewService extends BaseAnnotationControl {
  predictionImagesPreviewStore: PredictionImagesPreviewStore;
}

@injectable()
export class PredictionImagesPreviewService extends BaseAnnotationControl implements IPredictionImagePreviewService {
  predictionImagesPreviewStore: PredictionImagesPreviewStore;

  constructor(
    @inject(PredictionImagesPreviewStoreType) predictionImagesPreviewStore: PredictionImagesPreviewStore,
    @inject(NotificationsServiceType) notificationsService: INotificationsService,
    @inject(AnnotationUiStoreType) uiStore: IAnnotationUiStore,
    @inject(AnnotationsStoreType) annotationsStore: IAnnotationsStore,
    @inject(AnnotationTypeBlType) private readonly annotationTypeBl: AnnotationTypeBl,
    @inject(AnnotationApiServiceType) private readonly annotationApiService: IAnnotationApiService,
    @inject(AnnotationServiceType) private readonly annotationService: IAnnotationService,
    @inject(PredictionImagesPreviewApiServiceType) private readonly predictionImagesPreviewApiService: IPredictionImagesPreviewApiService,
    @inject(ApiServiceType) private readonly apiService: IApiService,
    @inject(ApiServiceTrainingType) private readonly apiServiceTraining: IApiService,
    @inject(ProjectHubServiceType) private readonly projectHubService: IProjectHubService,
  ) {
    super(uiStore, notificationsService, annotationsStore);
    this.predictionImagesPreviewStore = predictionImagesPreviewStore;
  }

  setupAsync(): Promise<void> {
    return Promise.resolve();
  }

  handleImageDisplayed = (image: IImage) => {};

  async updateImageId(workspaceId:string, imageId: string) {
    if (imageId === this.predictionImagesPreviewStore.imageId) {
      return;
    }

    this.uiStore.changeIsImageLoading(true);
    this.annotationService.deselectSegmentation();
    this.annotationsStore.setSegmentations([]);
    await this.refreshQuestionsIfNeededAsync();
    this.predictionImagesPreviewStore.imageId = imageId;
    this.uiStore.changeIsImageLoading(true);
    this.annotationsStore.setImageName('', '', DatasetStatus.None);
    this.annotationsStore.image = undefined;
    this.cleanupAnnotations();

    await this.showImage(workspaceId);

    this.uiStore.changeIsImageLoading(false);
  }

  async getDatasetInfo(datasetId: string) {
    if (this.predictionImagesPreviewStore.datasets.has(datasetId)) {
      return this.predictionImagesPreviewStore.datasets.get(datasetId) as IDatasetDetails;
    }

    const datasetInfo = await this.predictionImagesPreviewApiService.getDatasetDetails(datasetId);

    if (datasetInfo instanceof StickerError) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'common:something_went_wrong'));
      return null;
    }

    this.predictionImagesPreviewStore.datasets.set(datasetId, datasetInfo);
    return datasetInfo;
  }

  async initializeStore(workspaceId: string, jobId: string, jobType: PredictionImagesPreviewStore['jobType'], imageId: string) {
    this.predictionImagesPreviewStore.jobType = jobType;
    this.updateImageId(workspaceId, imageId);

    let result: PredictionImagesPreviewStore['jobInfo'] | StickerError | null = null;

    if (jobType === 'EVALUATION') {
      result = await this.predictionImagesPreviewApiService.getEvaluationDetailsAsync(workspaceId, jobId);
    } else if (jobType === 'TRAINING') {
      result = await this.predictionImagesPreviewApiService.getModelDetailsAsync(workspaceId, jobId);
    }

    if (result instanceof StickerError || result === null) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'common:something_went_wrong'));
      return;
    }

    this.predictionImagesPreviewStore.jobInfo = result;
    const datasetIds = result.datasets.datasets_ids;
    // Fetch images for each dataset
    const imagesPromises = datasetIds.map(dataset => this.predictionImagesPreviewApiService.getDatasetImages(dataset));
    const awaitedImages = await Promise.all(imagesPromises);

    if (awaitedImages.some(result => result instanceof StickerError)) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'common:something_went_wrong'));
      return;
    }

    this.predictionImagesPreviewStore.images = datasetIds.reduce<IImageWithDataset[]>((acc, datasetId, index) => {
      const images = awaitedImages[index] as IGetAllImages;
      const imagesWithDataset: IImageWithDataset[] = images.map(image => ({ ...image, datasetId }));

      return acc.concat(imagesWithDataset);
    }, []);
  }

  // TODO: Add initalize when workspace changes
  async initializeAsync(workspaceId: string, jobId: string, imageId: string, jobType: PredictionImagesPreviewStore['jobType']) {
    // Initialize when job changed
    await this.initializeStore(workspaceId, jobId, jobType, imageId);
    // Using != null to check for null and undefined
    const projectId = this.predictionImagesPreviewStore.jobInfo?.datasets.project_id;
    if (projectId != null) {
      this.annotationsStore.projectId = projectId;
      await this.annotationTypeBl.freeAccessStarted(projectId, false);
    }
    await this.requestQuestionsAsync();
    await this.projectHubService.initializeAsync();
    await this.showImage(workspaceId);
  }

  @action
  async showImage(workspaceId: string) {
    const image = this.predictionImagesPreviewStore.image;

    // Using != null to check for null and undefined
    if (image == null) return;

    const imageInfoPromises: [Promise<IDatasetPreviewImage | StickerError>, Promise<IDatasetDetails | null>] = [
      this.predictionImagesPreviewApiService.getImageDetails(image.datasetId, image.id),
      this.getDatasetInfo(image.datasetId),
    ];
    const imageInfos: [IDatasetPreviewImage | StickerError, IDatasetDetails | null] = await Promise.all(imageInfoPromises);
    const imageInfo = imageInfos[0];
    const datasetInfo = imageInfos[1];

    if (imageInfo instanceof StickerError || datasetInfo === null) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'common:something_went_wrong'));
      return;
    }

    const imageFilePromises = [
      this.apiService.getImageAsync(this.apiService.getUrl(`/Images/GetImageThumbnail/${image.id}`)),
      this.apiService.getImageAsync(this.apiService.getUrl(`/Images/GetImage/${image.id}`)),
    ];
    const imageFiles = await Promise.all(imageFilePromises);
    const imageThumbnail = imageFiles[0];
    const imageFull = imageFiles[1];

    if (imageThumbnail instanceof StickerError || imageFull instanceof StickerError) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'common:something_went_wrong'));
      return;
    }

    // Get annotations
    const annotationsData = await this.predictionImagesPreviewApiService.peekImageAnnotations(imageInfo.id);
    if (annotationsData instanceof StickerError) {
      this.notificationsService.push(new ToastNotification(NotificationLevel.ERROR, 'common:something_went_wrong'));
      return;
    }

    const annotations = annotationsData.annotations.find(annotation => annotation.projectId === this.annotationsStore.projectId);

    const annotationImage: IImage = {
      lowQualityUrl: URL.createObjectURL(imageThumbnail.blob),
      id: imageInfo.id,
      name: imageInfo.name,
      url: URL.createObjectURL(imageFull.blob),
      height: imageInfo.height,
      width: imageInfo.width,
      size: imageInfo.size,
      dataSetName: datasetInfo.name,
      datasetStatus: datasetInfo.status,
      lockedByName: '',
      annotations,
      canAddAnnotations: false,
    };

    if (this.annotationsStore.image && this.annotationsStore.image.id === image.id) {
      this.parseAnnotations(annotationImage);
      return;
    }

    this.uiStore.changeIsImageLoading(true);
    this.annotationsStore.setImageName(annotationImage.name, annotationImage.dataSetName, annotationImage.datasetStatus);


    // Get image evaluation
    let evaluationData;
    const { jobType, jobInfo } = this.predictionImagesPreviewStore;

    if(jobInfo?.id) {
      if (jobType === 'TRAINING') {
        evaluationData = await this.predictionImagesPreviewApiService.getTrainingImageDetailsAsync(workspaceId, jobInfo.id, [image.id]);
      }

      if (jobType === 'EVALUATION') {
        evaluationData = await this.predictionImagesPreviewApiService.getEvaluationImageDetailsAsync(workspaceId, jobInfo.id, [image.id]);
      }

      if (!(evaluationData instanceof StickerError) && evaluationData?.[image.id]) {
        let evaluationImageDetails = evaluationData[image.id]

        if (evaluationImageDetails?.heatmap_url) {
          const heatmapFullImage = await this.apiServiceTraining.getImageAsync(evaluationImageDetails?.heatmap_url)

          if (!(heatmapFullImage instanceof StickerError)) {
            evaluationImageDetails = { ...evaluationImageDetails, heatmap_url: URL.createObjectURL(heatmapFullImage.blob) };
          }
        }
        this.predictionImagesPreviewStore.evaluationImageDetails = evaluationImageDetails
      }
    }

    setTimeout(
      action(() => {
        this.annotationsStore.image = annotationImage;
        this.parseAnnotations(annotationImage);
      }),
      0,
    );
  }

  cleanupAnnotations() {
    this.annotationsStore.id = undefined;
    this.annotationsStore.setSegmentations([]);
    this.annotationsStore.questions.forEach((q: IQuestionModel) => {
      q.answers.forEach(a => (a.selected = false));
      q.answer = undefined;
    });
  }

  @action
  parseAnnotations(image: IImage): void {
    const annotationInfoResult = image.annotations;

    if (!annotationInfoResult) {
      this.cleanupAnnotations();
      return;
    }

    const annotations = annotationInfoResult.annotations;
    if (annotations === undefined) throw new StickerError('No annotations found', undefined);
    this.annotationsStore.id = annotationInfoResult.id;
    const segmentations = annotations.segmentations.map((s: ISegmentationDto) => {
      const attributes = this.mapQuestions(s.feature.properties!.annotationTypeId, s.questions);
      const feature = {
        ...s.feature,
        id: uuid.v4(),
        color: this.annotationService.getAnnotationTypeColor(s.feature.properties!.annotationTypeId),
        featureType: s.feature.properties!.featureType,
      };

      const latlngs = getLatLngsForGeojson(feature);

      return {
        feature,
        latlngs,
        id: s.id,
        questions: attributes,
        bbox: new LatLngBounds(latlngs),
        priority: s.priority,
      } as ISegmentation;
    });

    this.annotationsStore.setSegmentations(segmentations.filter(s => s.feature.color !== ''));
    this.annotationsStore.questions = this.mapQuestions(undefined, annotationInfoResult.annotations.questions);
    this.annotationService.setImageQuestionsAsCurrent();
  }

  mapQuestions(annotationTypeId: string | undefined, questionsDtos: IQuestionModelDto[]): IQuestionModel[] {
    const currentQuestions = annotationTypeId ? this.annotationsStore.questions.filter(q => q.scopes.includes(annotationTypeId)) : this.annotationsStore.questions;

    const models: IQuestionModel[] = [];
    for (const currentQuestion of currentQuestions) {
      const existingAnswer = questionsDtos.find(x => x.id === currentQuestion.id);

      const questionModel = new QuestionModel(
        currentQuestion.projectId,
        currentQuestion.id,
        currentQuestion.type as QuestionType,
        currentQuestion.isRequired,
        currentQuestion.text,
        currentQuestion.answers.map((x: IAnswerModel) => {
          return { id: x.id, selected: x.selected, text: x.text };
        }),
        currentQuestion.scopes,
        currentQuestion.answer,
      );

      questionModel.answers.forEach((x: IAnswerModel) => {
        const existing = existingAnswer?.answers.find(y => y.id === x.id);
        x.selected = existing?.selected || false;
      });
      questionModel.answer = existingAnswer?.answer;

      models.push(questionModel);
    }

    return models;
  }

  async refreshQuestionsIfNeededAsync() {
    if (this.annotationsStore.projectId.length === 0) return;

    if (this.projectHubService.didQuestionsChangedFor(this.annotationsStore.projectId)) {
      const result = this.projectHubService.popNewQuestionsFor(this.annotationsStore.projectId);
      this.annotationsStore.questions = [...result];
      this.annotationService.setImageQuestionsAsCurrent();
    }
  }

  @action
  async requestQuestionsAsync() {
    if (this.annotationsStore.projectId.length === 0) return;

    const result = await this.annotationApiService.requestQuestionsAsync(this.annotationsStore.projectId);
    if (result instanceof Error) throw result;
    this.annotationsStore.questions = [...result];
    this.annotationService.setImageQuestionsAsCurrent();
  }

  dispose() {
    this.annotationService.deselectSegmentation();
    this.annotationsStore.setSegmentations([]);
    this.annotationsStore.setImageName('', '', DatasetStatus.None);
    this.annotationsStore.image = undefined;
    this.predictionImagesPreviewStore.clearStore();
    this.annotationTypeBl.freeAccessFinished();
    super.dispose();
  }
}
