import * as React from 'react';

import { Alert, Card, CardBody, Form } from 'reactstrap';

import { Auth } from '../../../routes/config/Auth';
import { AuthFormTabs } from './AuthFormTabs';
import { ButtonWithLoader } from '../../../components/ButtonWithLoader';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { InputStatus } from '../../../models/error.model';
import { Link } from 'react-router-dom';
import { ValidatedTextInput } from '../../../components/ValidatedTextInput';
import { as } from '../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { formAlertWithTranslations } from '../../../components/FormAlert';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

export interface ILoginFormProps {
  handleEmailChange(value: string): void;
  handlePasswordChange(value: string): void;
  validateEmail(value: string): void;
  validatePassword(value: string): void;
  handleLogin(): void;
  emailStatus: InputStatus;
  passwordStatus: InputStatus;
  externalStatus: InputStatus;
  email: string;
  isEmailLocked: boolean;
  isLoginInProgress: boolean;
  lockoutTime: number;
}

@observer
class LoginFormPure extends React.Component<ILoginFormProps & ITranslatable> {
  @autobind
  onSubmit(event: React.FormEvent) {
    event.preventDefault();
    this.props.handleLogin();
  }

  render() {
    const loginLocked =
      this.props.emailStatus.isValid === false ||
      this.props.passwordStatus.isValid === false ||
      this.props.isLoginInProgress ||
      this.props.lockoutTime > 0;

    const Errors = formAlertWithTranslations('auth');

    return (
      <Card>
        <CardBody>
          <AuthFormTabs />
          {this.props.lockoutTime === 0 && <Errors id="login-error" errors={this.props.externalStatus} useDefaultMessageWhenCodeNotFound={true} />}
          {this.props.lockoutTime > 0 && <Alert color="warning">
            {this.props.t('account_locked', { timer: this.props.lockoutTime })}
          </Alert>}
          <Form onSubmit={this.onSubmit}>
            <ValidatedTextInput
              labelText={this.props.t('email_address')}
              type={'text'}
              id="login-email-input"
              value={this.props.email}
              placeholder={this.props.t('enter_email')}
              onChange={this.props.handleEmailChange}
              onBlur={this.props.validateEmail}
              isValid={this.props.emailStatus.isValid}
              feedbacks={this.props.emailStatus.errorCodes.map(this.props.t)}
              readOnly={this.props.isEmailLocked}
            />
            <ValidatedTextInput
              labelText={this.props.t('password')}
              type={'password'}
              id="login-password-input"
              placeholder={this.props.t('enter_password')}
              onChange={this.props.handlePasswordChange}
              onBlur={this.props.validatePassword}
              isValid={this.props.passwordStatus.isValid}
              feedbacks={this.props.passwordStatus.errorCodes.map(this.props.t)}
            />
            <ButtonWithLoader
              block
              id="login-button"
              loaderKey="login-button"
              type="submit"
              color="primary"
              disabled={loginLocked}
              onClick={this.onSubmit}
              isLoading={this.props.isLoginInProgress}
            >
              {this.props.t('login')}
            </ButtonWithLoader>
          </Form>
          <p>
            <span>{this.props.t('forgot_password')} </span>
            <Link to={Auth.ForgotPassword.Path}>{this.props.t('forgot_password_with_link')}</Link>.
          </p>
        </CardBody>
      </Card>
    );
  }
}
export const LoginForm = as<React.StatelessComponent<ILoginFormProps>>(withNamespaces('auth')(LoginFormPure));
