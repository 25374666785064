import { AttachmentType, IAttachment } from '../attachments.store';
import AttachmentsBreadcrumbs, { IOuterProps as IAttachmentsBreadcrumbsProps } from './AttachmentsBreadcrumbs';
import { AttachmentsListTable, IOuterProps as IAttachmentsListTableProps } from './AttachmentsListTable';
import { AttachmentsListThumbs, IAttachmentsListThumbsProps } from './AttachmentsListThumbs';
import { Trans, WithNamespaces, withNamespaces } from 'react-i18next';

import { AttachmentAddFolderContainer } from '../containers/AttachmentAddFolder.container';
import AttachmentConfirmDeleteModalContainer from '../containers/AttachmentConfirmDeleteModal.container';
import { AttachmentDeleteContainer } from '../containers/AttachmentDelete.container';
import { AttachmentDownloadContainer } from '../containers/AttachmentDownload.container';
import { AttachmentSelectAllContainer } from '../containers/AttachmentSelectAll.container';
import { AttachmentUploadContainer } from '../containers/AttachmentUpload.container';
import { AttachmentsListControlsContainer } from '../containers/AttachmentsListControls.container';
import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { ConfirmationModalWithTextBox } from '../../../components/ConfirmationModalWithTextBox';
import { DecryptionScriptSampleContainer } from '../../../containers/DecryptionScriptSample.container';
import { ListViewMode } from '../../user/user.store';
import { PlanUpgradeRequiredMessage } from '../../../components/PlanUpgradeRequiredMessage';
import React from 'react';
import { RenameAttachmentModalContainer } from '../../../../modules/attachments/rename/ui/modal/RenameAttachmentModal.container';

export interface IAttachmentsListProps extends IAttachmentsBreadcrumbsProps, IAttachmentsListTableProps, IAttachmentsListThumbsProps {
  attachmentsListViewMode: ListViewMode;
  canAddFolder: boolean;
  canView: boolean;
  isOwner: boolean;
  onDeleteCanceled(): void;
  onDeleteConfirmed(): void;
  isDeleteConfirmationModalOpen: boolean;
  attachmentToDelete?: IAttachment;
}

class AttachmentsListPure extends React.Component<IAttachmentsListProps & WithNamespaces> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const confirmationFolderDeleteQuestion = (
      <Trans
        i18nKey="attachment:delete_attachment_confirmation_header"
        values={{ name: this.props.attachmentToDelete?.name || '' }}
        // tslint:disable-next-line:jsx-key
        components={[<strong>0</strong>]}
      />);

    return (
      <>{!this.props.canView &&
        <PlanUpgradeRequiredMessage
          message={this.props.t('this_feature_is_available_only_in_professional_and_enterprise_plans')}
          linkText={this.props.isOwner ? this.props.t('upgrade_now') : this.props.t('ask_your_workspace_owner_to_upgrade_now')}
        />}
        <AttachmentConfirmDeleteModalContainer />
        <div className="attachments-controls">
          {this.props.canAddFolder && <AttachmentAddFolderContainer />}
          <AttachmentUploadContainer />
          <div className="download-controls">
            <DecryptionScriptSampleContainer />
            <AttachmentDownloadContainer />
          </div>
          <AttachmentDeleteContainer />
          <AttachmentSelectAllContainer />
          <AttachmentsListControlsContainer />
        </div>
        <AttachmentsBreadcrumbs
          breadcrumbs={this.props.breadcrumbs}
          onFolderSelect={this.props.onFolderSelect}
        />
        {this.props.attachmentsListViewMode === ListViewMode.List ?
          <AttachmentsListTable
            canView={this.props.canView}
            parentId={this.props.parentId}
            attachments={this.props.attachments}
            currentDownloadAttachments={this.props.currentDownloadAttachments}
            isLoading={this.props.isLoading}
            onFolderSelect={this.props.onFolderSelect}
            onDownloadAttachments={this.props.onDownloadAttachments}
            onAttachmentsSelectionChanged={this.props.onAttachmentsSelectionChanged}
            onDeleted={this.props.onDeleted}
            onOrderingChange={this.props.onOrderingChange}
            onPaginationChange={this.props.onPaginationChange}
            onSelectAttachment={this.props.onSelectAttachment}
            onSelectAllAttachments={this.props.onSelectAllAttachments}
            workspaceId={this.props.workspaceId}
            pagination={this.props.pagination}
            sorting={this.props.sorting}
            selectedAttachments={this.props.selectedAttachments}
            isDownloadDisabled={this.props.isDownloadDisabled}
            isDeleteRequestInProgress={this.props.isDeleteRequestInProgress}
          />
          :
          <AttachmentsListThumbs
            canView={this.props.canView}
            isDownloadDisabled={this.props.isDownloadDisabled}
            workspaceId={this.props.workspaceId}
            attachments={this.props.attachments}
            authToken={this.props.authToken}
            isLoading={this.props.isLoading}
            selectedAttachments={this.props.selectedAttachments}
            pagination={this.props.pagination}
            onPaginationChange={this.props.onPaginationChange}
            onThumbCheck={this.props.onThumbCheck}
            onThumbClick={this.props.onThumbClick}
            onThumbDelete={this.props.onThumbDelete}
            getThumbnailUrl={this.props.getThumbnailUrl}
          />}
        <ConfirmationModal
          showModal={this.props.isDeleteConfirmationModalOpen && this.props.attachmentToDelete?.type !== AttachmentType.FOLDER}
          confirmationHeader="attachment:delete_attachment_header"
          confirmationQuestion="attachment:delete_attachment_text_single"
          onCancel={this.props.onDeleteCanceled}
          onConfirm={this.props.onDeleteConfirmed}
          showSpinner={this.props.isDeleteRequestInProgress}
          confirmationYes={this.props.t('delete')}
        />
        <ConfirmationModalWithTextBox
          showModal={this.props.isDeleteConfirmationModalOpen && this.props.attachmentToDelete?.type === AttachmentType.FOLDER}
          confirmationHeader={this.props.t('attachment:delete_attachment_header')}
          confirmationQuestion={this.props.t('attachment:delete_attachment_text_single')}
          confirmationTextHeader={confirmationFolderDeleteQuestion}
          confirmationText={this.props.attachmentToDelete?.name || ''}
          onCancel={this.props.onDeleteCanceled}
          onConfirm={this.props.onDeleteConfirmed}
          showSpinner={this.props.isDeleteRequestInProgress}
          confirmationYes={this.props.t('delete')}
        />
        <RenameAttachmentModalContainer />
      </>
    );
  }
}

export const AttachmentsList = withNamespaces('common', { wait: true })(AttachmentsListPure);
