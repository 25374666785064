import './S_WorkspaceOwnerIcon.scss';

import { WithNamespaces, withNamespaces } from 'react-i18next';
import { faShareAlt, faUser } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface Props {
  isMy: boolean;
  notOwnerTitle: string;
}

const S_WorkspaceOwnerIconPure: React.FC<Props & WithNamespaces> = ({ isMy, notOwnerTitle, t }: Props & WithNamespaces) => (
  <div className="workspace-owner-icon-container">
    {isMy && <FontAwesomeIcon className="my-workspace" icon={faUser} title={t('workspace_owner_icon.you_are_the_owner')} />}
    {!isMy && <FontAwesomeIcon className="not-my-workspace" icon={faShareAlt} title={notOwnerTitle} />}
  </div>
);

export const S_WorkspaceOwnerIcon = withNamespaces('design')(S_WorkspaceOwnerIconPure);
