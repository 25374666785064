import { ImportAnnotationsBl, ImportAnnotationsBlType } from '../../importAnnotations.bl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ImportAnnotationsButton } from './ImportAnnotationsButton';
import React from 'react';
import { as } from '../../../../../../../common/dependencyInjection/as';
import { injectProps } from '../../../../../../../__legacy__/helpers/react.helpers';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: ImportAnnotationsBl;
}

@injectProps({
  bl: ImportAnnotationsBlType,
})
@observer
class ImportAnnotationsButtonContainerPure extends React.Component<InjectedProps & RouteComponentProps<{ projectId: string }>> {
  handleStartImport = () => {
    const projectId = this.props.match.params.projectId;
    this.props.bl.startImport(projectId);
  };

  render(): React.ReactNode {
    return <ImportAnnotationsButton onStartImport={this.handleStartImport} />;
  }
}

export const ImportAnnotationsButtonContainer = as<React.ComponentClass>(withRouter(ImportAnnotationsButtonContainerPure));
