import { injectable, inject } from 'inversify';
import { action, runInAction } from 'mobx';
import { ApiServiceType, IPagedResult } from '../../services/api.service';
import { StickerError } from '../../models/error.model';
import { IApiService } from '../../services/api.service.base';
import { DatasetsStoreType, IDatasetListItemViewModel, IDatasetsStore } from './datasets.store';
import { ICurrentWorkspaceStore, CurrentWorkspaceStoreType } from '../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { EventBusType, EventListeningDisposer, IEventBus } from '../../services/eventBus.service';
import { CurrentWorkspaceChangedEventType } from '../../../modules/workspaces/currentWorkspace/events/CurrentWorkspaceChangedEvent';
import { IWorkspacesPermissions, WorkspacesPermissionsType } from '../workspaces/workspaces.permissions';

export const DatasetsServiceType = Symbol('DATASETS_SERVICE');

export interface IDatasetsService {
  init(initialPageSize?: number): void;
  dispose(): void;
  loadUserDatasetAsync(): Promise<void>;
  deleteDatasetAsync(datasetId: string): Promise<void>;
  changeOrder(orderBy: string | undefined, orderType: string | undefined): Promise<void>;
  paginationChange(pageNumber: number, pageSize: number): Promise<void>;
  markStale(): void;
  store: IDatasetsStore;
}

@injectable()
export class DatasetsService implements IDatasetsService {
  disposers: EventListeningDisposer[] = [];
  constructor(
    @inject(ApiServiceType) private readonly apiService: IApiService,
    @inject(DatasetsStoreType) readonly store: IDatasetsStore,
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore,
    @inject(WorkspacesPermissionsType) private readonly workspacePermissions: IWorkspacesPermissions,
    @inject(EventBusType) private readonly eventBus: IEventBus,
  ) {}

  @action
  init(initialPageSize?: number) {
    if (initialPageSize) {
      this.store.datasetsPaging.pageSize = initialPageSize;
    }

    this.loadUserDatasetAsync();
    this.disposers = [this.eventBus.addListener(this.currentWorkspaceChangedListener, CurrentWorkspaceChangedEventType)];
  }

  @action
  dispose(): void {
    this.disposers.forEach(d => d());
    this.disposers = [];
  }

  @action
  markStale() {
    this.store.stale = true;
  }

  @action
  async changeOrder(orderBy: string | undefined, orderType: string | undefined): Promise<void> {
    this.store.datasetsPaging.orderBy = orderBy || '';
    this.store.datasetsPaging.orderType = orderType || '';
    await this.loadUserDatasetAsync();
  }

  @action
  async paginationChange(pageNumber: number, pageSize: number): Promise<void> {
    this.markStale();
    this.store.datasetsPaging.pageNumber = pageNumber;
    this.store.datasetsPaging.pageSize = pageSize;
    await this.loadUserDatasetAsync();
  }

  @action
  async loadUserDatasetAsync() {
    const workspaceId = this.currentWorkspaceStore.currentWorkspace?.id;
    if (!workspaceId) return;
    if (!this.workspacePermissions.canAccessDatasets(workspaceId)) return;

    this.markStale();

    const { pageNumber, pageSize, orderBy, orderType } = this.store.datasetsPaging;

    const params = {
      workspaceId,
      pageNumber,
      pageSize,
      orderBy,
      orderType,
    };

    const result = await this.apiService.getAsync<IPagedResult<IDatasetListItemViewModel>>('/datasets', { params });

    if (result instanceof StickerError) {
      return;
    }

    if (result.pagesCount < result.pageNumber) {
      this.store.datasetsPaging.pageNumber = result.pagesCount;
      await this.loadUserDatasetAsync();
      return;
    }

    runInAction(() => {
      this.store.datasets = result.data;
      this.store.workspaceId = workspaceId;
      this.store.datasetsPaging = {
        ...this.store.datasetsPaging,
        pageNumber: result.pageNumber,
        pagesCount: result.pagesCount,
        totalCount: result.totalCount,
      };
      this.store.stale = false;
    });
  }

  @action
  async deleteDatasetAsync(datasetId: string) {
    this.store.stale = true;
    const url = `/datasets/remove?DatasetId=${datasetId}`;
    const result = await this.apiService.postAsync(url);
    if (result instanceof StickerError) throw result;
    await this.loadUserDatasetAsync();
  }

  private currentWorkspaceChangedListener = () => this.loadUserDatasetAsync();
}
