﻿import * as React from 'react';

import { Button, CustomInput, FormGroup } from 'reactstrap';
import { IAnnotationType, MAX_TEXT_LENGTH } from '../projectDetailsTools.models';
import { handleClickAndPassData, handleSelectChange } from '../../../../../helpers/formHelpers';

import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { IconedButton } from '../../../../../components/IconedButton';
import { InputStatus } from '../../../../../models/error.model';
import { QuestionType } from '../../../../annotation/question.model';
import Select from 'react-select';
import { ValidatedSelectInput } from '../../../../../components/ValidatedSelectInput';
import { ValidatedTextInput } from '../../../../../components/ValidatedTextInput';
import { as } from '../../../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

library.add(faTrash);

const handleChange = (cb: any) => (data: any) => (value: any) => cb(value, data);

export type IAnswerModel = {
  text: string;
  id: string;
  status: InputStatus;
};

interface IQuestionFormProps {
  id: string;
  questionType: QuestionType;
  questionText: string;
  answers: IAnswerModel[];
  isRequired: boolean;
  questionTextStatus: InputStatus;
  answersStatus: InputStatus;
  isValid: boolean;
  isAttribute: boolean;
  isInUse: boolean;
  isReadonly: boolean;

  annotationTypes: IAnnotationType[];
  questionTypes: QuestionType[];
  selectedQuestionTypes: QuestionType[];
  scopes: string[];
  scopesStatus: InputStatus;
  onQuestionTypeChange(questionType: QuestionType | any): void;
  onQuestionTextChange(text: string): void;
  onRequiredChange(): void;
  onAnswerAdd(): void;
  onAnswerChange(answer: string, id: string): void;
  onAnswerRemove(id: string): void;
  onScopeChange(toolId: any): void;
}

@observer
class QuestionFormPure extends React.Component<IQuestionFormProps & ITranslatable> {
  @autobind
  renderFeedbacks(feedbacks: string[]): JSX.Element[] {
    const uniqueFeedbacks = feedbacks ? Array.from(new Set(feedbacks)) : [];
    return uniqueFeedbacks.map((feedback, i) => {
      return <li key={i}>{this.props.t(feedback)}</li>;
    });
  }

  @autobind
  renderAnswers() {
    return this.props.answers.map((v, i) => (
      <div className="single-answer" key={i}>
        <div className="field-label">
          {this.props.t('answer')} {i + 1}
        </div>
        <ValidatedTextInput
          type={'text'}
          value={v.text}
          isValid={v.status.isValid}
          disabled={this.props.isReadonly}
          feedbacks={v.status.errorCodes}
          onChange={handleChange(this.props.onAnswerChange)(v.id)}
          placeholder={this.props.t('type_answer_here')}
          maxLength={MAX_TEXT_LENGTH}
          isRequired
        />
        {!this.props.isReadonly && (
          <IconedButton className="trash-icon delete-button" onClick={handleClickAndPassData(this.props.onAnswerRemove)(v.id)} icon={faTrash} color="red" />
        )}
      </div>
    ));
  }

  @autobind
  renderQuestionBody(): JSX.Element | null {
    switch (this.props.questionType) {
      case QuestionType.MULTISELECT:
      case QuestionType.ALTERNATIVE:
        return (
          <>
            <div className="answers">{this.renderAnswers()}</div>
            {!this.props.isReadonly && (
              <div className="add-answer">
                <Button color="primary" className="btn-as-label field-label add-new-option-button" onClick={this.props.onAnswerAdd}>
                  + {this.props.t('project:add_new_option')}
                </Button>
                {this.props.answersStatus.isValid === false && (
                  <ul className="custom-invalid-feedback add-new-option-feedback">{this.renderFeedbacks(this.props.answersStatus.errorCodes)}</ul>
                )}
              </div>
            )}
          </>
        );
      default:
        return null;
    }
  }

  @autobind
  mapToTranslatedOption(type: QuestionType) {
    return { value: type, label: this.props.t(`${type.toLocaleLowerCase()}_question_type`) };
  }

  @autobind
  isOptionDisabled(option: { value: QuestionType; label: string }): boolean {
    return (
      (option.value === QuestionType.STATUS_PASS_FAIL && this.props.selectedQuestionTypes.some(qt => qt === QuestionType.STATUS_PASS_FAIL)) ||
      (option.value === QuestionType.SET_TRAIN_TEST && this.props.selectedQuestionTypes.some(qt => qt === QuestionType.SET_TRAIN_TEST))
    );
  }

  render() {
    const selectedTypeValue = this.mapToTranslatedOption(this.props.questionType);
    const selectedScopes = this.props.scopes.map(s => ({ value: s, label: this.props.annotationTypes.find(t => t.id.toUpperCase() === s.toUpperCase())?.name || '' }));
    const typeOptions = this.props.questionTypes.map(qt => this.mapToTranslatedOption(qt));
    const availableAnnotationTypes = this.props.annotationTypes.filter(x => !this.props.scopes.some(s => s.toLowerCase() === x.id.toLowerCase()));
    const toolsOptions = availableAnnotationTypes.map(at => ({ value: at.id, label: at.name }));

    return (
      <div className={'question-form question-form-edit'}>
        <div className="question-form-upper-part">
          <div className="form-group upper-part-element-1">
            <div className="field-label">Name</div>
            <ValidatedTextInput
              type={'text'}
              id="question-input"
              value={this.props.questionText}
              disabled={this.props.isReadonly}
              feedbacks={this.props.questionTextStatus.errorCodes}
              isValid={this.props.questionTextStatus.isValid}
              onChange={handleChange(this.props.onQuestionTextChange)('')}
              placeholder={this.props.t('type_question_here')}
              maxLength={MAX_TEXT_LENGTH}
              isRequired
            />
          </div>
          <div className="form-group upper-part-element-2">
            <div className="field-label">Type</div>
            <Select
              id="question-type-dropdown"
              value={selectedTypeValue}
              onChange={handleSelectChange(this.props.onQuestionTypeChange)}
              options={typeOptions}
              classNamePrefix="custom-select"
              isDisabled={this.props.isReadonly || this.props.isInUse}
              isOptionDisabled={this.isOptionDisabled}
            />
          </div>
          {this.props.isAttribute && (
            <div className="form-group upper-part-element-3">
              <ValidatedSelectInput
                id="user-dropdown"
                name="select"
                value={selectedScopes || null}
                onChange={this.props.onScopeChange}
                options={toolsOptions}
                label={this.props.t('Scope')}
                isValid={this.props.scopesStatus.isValid}
                feedbacks={this.props.scopesStatus.errorCodes}
                disabled={this.props.isReadonly}
                isMulti={true}
                closeMenuOnSelect={false}
              />
            </div>
          )}
        </div>
        <div className="question-form-middle-part">
          <FormGroup className="checkbox mb-0">
            <CustomInput
              disabled={this.props.isReadonly}
              onChange={this.props.onRequiredChange}
              checked={this.props.isRequired}
              type="checkbox"
              label={this.props.t('is_required')}
              id={this.props.id}
            />
          </FormGroup>
        </div>
        <div className="question-form-bottom-part">{this.renderQuestionBody()}</div>
      </div>
    );
  }
}

export const QuestionForm = as<React.ComponentClass<IQuestionFormProps>>(withNamespaces('project')(QuestionFormPure));
