import { IProjectStatsFiltersBl, ProjectStatsFiltersBlType } from '../../ProjectStatsFilters.bl';
import { IProjectStatsFiltersStore, IWorker, ProjectStatsFiltersStoreType } from '../../ProjectStatsFilters.store';
import { as, injectProps } from '../../../../../../../__legacy__/helpers/react.helpers';

import React from 'react';
import { WorkerSelector } from './WorkerSelector';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: IProjectStatsFiltersBl;
  store: IProjectStatsFiltersStore;
}

@injectProps({
  bl: ProjectStatsFiltersBlType,
  store: ProjectStatsFiltersStoreType,
})
@observer
class WorkerSelectorContainerPure extends React.Component<InjectedProps> {
  handleWorkersChange = (workers: IWorker[]) => {
    this.props.bl.hideWorkers(workers);
  };

  render(): React.ReactNode {
    return <WorkerSelector availableWorkers={this.props.store.availableWorkers} selectedWorkers={this.props.store.visibleWorkers} onWorkersChange={this.handleWorkersChange} />;
  }
}

export const WorkerSelectorContainer = as<React.ComponentClass>(WorkerSelectorContainerPure);
