import { WithNamespaces, withNamespaces } from 'react-i18next';

import React from 'react';
import { S_Checkbox } from '../../../../../../../design/inputs/checkbox/S_Checkbox';

interface Props {
  checked: boolean;
  onChange(value: boolean): void;
}

export const ProjectPickerSelectAllOptionPure: React.FC<Props & WithNamespaces> = props => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.target.checked);

  return <S_Checkbox id="project-picker-select-all" label={<span className="ellipsisAndHidden">{props.t('select_all')}</span>} onChange={handleChange} checked={props.checked} />;
};

export const ProjectPickerSelectAllOption = withNamespaces('common')(ProjectPickerSelectAllOptionPure);
