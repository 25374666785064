import { ISegmentationOrderBl, SegmentationOrderBlType } from '../segmentationsOrder.Bl';
import { as, injectProps } from '../../../../../../helpers/react.helpers';

import { BringToFrontContextMenuItemContainer } from './menuItems/BringToFrontContextMenuItem.container';
import { DeleteSegmentationContextMenuItemContiner } from './menuItems/DeleteSegmentationContextMenuItem.container';
import { Point } from 'leaflet';
import React from 'react';
import { SegmentationContextMenu } from '../components/SegmentationContextMenu';
import { SendToBackContextMenuItemContainer } from './menuItems/SendToBackContextMenuItem.container';

interface IProps {
  children(
    openContextMenu: (position: Point, segmentationId: string) => void,
    contextMenuSegmentationId: string | undefined,
  ): React.ReactNode;
}

interface IInjectProps {
  segmentationOrderBl: ISegmentationOrderBl;
}

interface IState {
  contextMenuPosition?: Point;
  contextMenuSegmentationId?: string;
}

@injectProps({
  segmentationOrderBl: SegmentationOrderBlType,
})
class SegmentationContextMenuContainerPure extends React.Component<IProps & IInjectProps, IState> {

  constructor(props: IProps & IInjectProps) {
    super(props);
    this.state = {};
  }

  handleMenuOpen = (position: Point, segmentationId: string) => {
    this.setState({ contextMenuPosition: position, contextMenuSegmentationId: segmentationId });
  }

  handleMenuClose = () => {
    this.setState({ contextMenuPosition: undefined, contextMenuSegmentationId: undefined });
  }

  render() {
    return (
      <>
        <SegmentationContextMenu
          position={this.state.contextMenuPosition}
          segmentationId={this.state.contextMenuSegmentationId}
          onMenuClose={this.handleMenuClose}
        >
          {menuItemProps =>
            <>
              <DeleteSegmentationContextMenuItemContiner {...menuItemProps} />
              <BringToFrontContextMenuItemContainer {...menuItemProps} />
              <SendToBackContextMenuItemContainer {...menuItemProps} />
            </>
          }
        </SegmentationContextMenu>
        {this.props.children(this.handleMenuOpen, this.state.contextMenuSegmentationId)}
      </>
    );
  }
}

export const SegmentationContextMenuContainer = as<React.ComponentClass<IProps>>(SegmentationContextMenuContainerPure);
