import { DropdownItem } from 'reactstrap';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  onClick(): void;
}

class UpdateWorkspaceTimeZoneDropdownItemPure extends React.Component<IProps & ITranslatable> {
  render() {
    return <DropdownItem onClick={this.props.onClick}>{this.props.t('update_workspace_timezone')}</DropdownItem>;
  }
}

export const UpdateWorkspaceTimeZoneDropdownItem = as<React.ComponentClass<IProps>>(withNamespaces('workspace')(UpdateWorkspaceTimeZoneDropdownItemPure));
