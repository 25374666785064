import * as React from 'react';

import { DashboardContainer } from '../../containers/DashboardContainer';
import { PageHeader } from '../../components/PageHeader';
import { WorkspaceListSearchContainer } from '../../modules/workspaces/containers/WorkspaceListSearch.container';
import { WorkspacesListContainer } from '../../modules/workspaces/containers/WorkspacesList.container';

const Header = () => <PageHeader headerText="Workspaces" headerDescription="" tabNavPresent="clean" />;

export const WorkspacesView = () => (
  <DashboardContainer header={Header}>
    <WorkspaceListSearchContainer />
    <WorkspacesListContainer />
  </DashboardContainer>
);
