import { ITranslatable } from '../../../../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  totalFilteredCount: number;
  totalImagesCount: number;
}

const FilteredImagesCounterPure = (props: ITranslatable & IProps) => (
  <div>
    {props.totalImagesCount > 0 && props.t('showing_x_images_of_y', { ...props })}
  </div>
);

export const FilteredImagesCounter = as<React.FunctionComponent<IProps>>(withNamespaces('common')(FilteredImagesCounterPure));
