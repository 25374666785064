import './WeekPicker.scss';

import React, { useState } from 'react';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormGroup } from 'reactstrap';
import { IDatePickerProps } from '../DayPicker';
import { NavigationButton } from '../../buttons/NavigationButton';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';

interface IWeekPickerProps extends IDatePickerProps {
  selectedEndDate: Date;
}

export const WeekPicker: React.FC<IWeekPickerProps> = (props: IWeekPickerProps) => {
  const selectedStartDate = props.selectedDate;
  const { selectedEndDate, onSetDate, onClickNext, onClickPrevious, minDate, maxDate, canClickNext, canClickPrevious } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(prevState => !prevState);
  };

  const onChange = (date: Date) => {
    onSetDate(moment(date).startOf('isoWeek').toDate());
    toggle();
  };

  const renderLabel = (): React.ReactNode => (
    <div className="date-label-container" onClick={toggle}>
      <div>
        <label onClick={toggle}>{`${selectedStartDate.toLocaleDateString()} - ${selectedEndDate!.toLocaleDateString()}`}</label>
      </div>
      <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} onClick={toggle} />
    </div>
  );

  return (
    <FormGroup className="date-range-field week-picker">
      <NavigationButton onClick={onClickPrevious} disabled={!canClickPrevious} left />
      <ReactDatePicker
        minDate={minDate}
        maxDate={maxDate}
        selected={selectedStartDate}
        onChange={onChange}
        open={isOpen}
        customInput={renderLabel()}
        onBlur={toggle}
        onClickOutside={toggle}
        calendarStartDay={1}
        disabledKeyboardNavigation
      />
      <NavigationButton onClick={onClickNext} disabled={!canClickNext} />
    </FormGroup>
  );
};
