import { injectable, inject } from 'inversify';
import { ApiServiceType } from '../../../../../services/api.service';
import { StickerError } from '../../../../../models/error.model';
import { IApiService } from '../../../../../services/api.service.base';
import { IQuestionModel } from '../../../../annotation/question.model';
import { ImageSelectionMode, ImageSetType } from '../projectDetailsImages.model';

export const ProjectDetailsImagesApiServiceType = Symbol('PROJECT_DETAILS_IMAGES_API_SERVICE');

export interface IBatchAnswerQuestionsRequest {
  projectId: string;
  filterId: string;
  toggledImages: string[];
  selectionMode: ImageSelectionMode;
  questions: IQuestionModel[];
  sessionId: string;
}

export interface IUpdateProjectImageSetRequest {
  projectId: string;
  filterId: string;
  toggledImages: string[];
  selectionMode: ImageSelectionMode;
  projectImageSet: ImageSetType;
}

export interface IGetQuestionsRequest {
  projectId: string;
}

export interface IProjectDetailsImagesApiService {
  batchAnswerQuestionsAsync(request: IBatchAnswerQuestionsRequest): Promise<void | StickerError>;
  updateProjectImageSetAsync(request: IUpdateProjectImageSetRequest): Promise<void | StickerError>;
  getQuestions(request: IGetQuestionsRequest): Promise<IQuestionModel[] | StickerError>;
}

@injectable()
export class ProjectDetailsImagesApiService implements IProjectDetailsImagesApiService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService) { }

  async getQuestions(request: IGetQuestionsRequest): Promise<IQuestionModel[] | StickerError> {
    return this.apiService.getAsync<IQuestionModel[]>('/Questions/GetBatchAnswerQuestions', { params: request });
  }

  async batchAnswerQuestionsAsync(request: IBatchAnswerQuestionsRequest): Promise<void | StickerError> {
    return await this.apiService.postAsync<{}, void>('/Annotation/BatchAnswerQuestions', request);
  }

  async updateProjectImageSetAsync(request: IUpdateProjectImageSetRequest): Promise<void | StickerError> {
    return await this.apiService.postAsync<{}, void>('/Projects/UpdateProjectImagesSet', request);
  }
}
