import './S_PaginationInput.scss';

import React, { ChangeEvent } from 'react';

interface Props {
  id?: string;
  value?: string;
  onChange(value: string): void;
  onSubmit(): void;
}

export const S_PaginationInput: React.FC<Props> = ({ id, value, onChange, onSubmit }) => {
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') return;

    event.preventDefault();
    onSubmit();
  };

  return <input id={id} className="pagination-input page-link" onChange={handleOnChange} value={value} onKeyDown={handleKeyDown} />;
};
