import uuid from 'uuid';
import { injectable, inject } from 'inversify';
import { IClarificationsApiService, ClarificationsApiServiceType } from './ClarificationsApi.service';
import { IClarificationsStore, IClarificationsStoreSetter, ClarificationsStoreSetterType } from '../Clarifications.store';
import { GetClarificationsRequest, ClarifyRequest, IRequestClarificationRequest } from '../models/Clarifications.requests';
import { StickerError } from '../../../../../models/error.model';
import { AnnotationsStoreType, IAnnotationsStore } from '../../../annotations.store';
import { EventBusType, IEventBus } from '../../../../../services/eventBus.service';
import { ClarificationAddedEvent } from '../events/ClarificationAddedEvent';
import { SortingDirection } from '../../../../../models/sortingDirection.model';
import { IAnnotationDto } from '../../../annotations.interface';
import { ITimerService, TimerServiceType } from '../../../../../services/timer.service';
import { ImageScopeName } from '../../../question.model';
import { BatchAnnotationApiServiceType, IBatchAnnotationApiService } from '../../../../../../modules/editor/services/BatchAnnotationApi.service';
import { FreeAccessAnnotationApiServiceType, IFreeAccessAnnotationApiService } from '../../../../../../modules/editor/services/FreeAccessAnnotationApi.service';

import {
  IRequestClarificationDuringAnnotationRequest,
  IRequestClarificationDuringFreeAccessEditRequest,
  IRequestClarificationDuringReviewEditRequest,
  IRequestClarificationDuringReviewRequest,
} from '../../../../../../modules/editor/models/Requests';
import { IUndoRedoHistory, UndoRedoHistoryType } from '../../../undoRedoHistory.service';

export const ClarificationsBlType = Symbol('CLARIFICATIONS_BL');

export interface IClarificationsBl {
  getClarificationsAsync(projectId: string, imageId: string): void;

  batchRequestClarificationDuringAnnotationAsync(): Promise<void>;
  batchRequestClarificationDuringReviewAsync(): Promise<void>;
  batchRequestClarificationDuringReviewEditAsync(): Promise<void>;
  freeAccessRequestClarificationDuringAnnotationAsync(): Promise<void>;
  freeAccessRequestClarificationDuringEditAsync(): Promise<void>;
  freeAccessRequestClarificationDuringReviewAsync(): Promise<void>;
  freeAccessRequestClarificationDuringReviewEditAsync(): Promise<void>;

  clarifyAsync(projectId: string, clarificationId: string, answer: string): void;
  setClarificationsListSortDirection(sortDirection: SortingDirection): void;
  setClarificationQuestion(question: string): void;
  dispose(): void;
}

@injectable()
export class ClarificationsBl implements IClarificationsBl {
  constructor(
    @inject(AnnotationsStoreType) private readonly annotationsStore: IAnnotationsStore,
    @inject(ClarificationsStoreSetterType) private readonly clarificationsStore: IClarificationsStore,
    @inject(ClarificationsStoreSetterType) private readonly clarificationsStoreSetter: IClarificationsStoreSetter,
    @inject(EventBusType) private readonly eventBus: IEventBus,
    @inject(TimerServiceType) public readonly timer: ITimerService,
    @inject(BatchAnnotationApiServiceType) private readonly batchAnnotationApiService: IBatchAnnotationApiService,
    @inject(FreeAccessAnnotationApiServiceType) private readonly freeAccessAnnotationApiService: IFreeAccessAnnotationApiService,
    @inject(ClarificationsApiServiceType) private readonly clarificationsApiService: IClarificationsApiService,
    @inject(UndoRedoHistoryType) private readonly undoRedoHistory: IUndoRedoHistory,
  ) {}

  async getClarificationsAsync(projectId: string, imageId: string): Promise<void> {
    const request = new GetClarificationsRequest(projectId, imageId);
    const result = await this.clarificationsApiService.getClarificationsAsync(request);

    if (result instanceof StickerError) throw result;

    this.clarificationsStoreSetter.setArchiveClarifications(result);
  }

  async batchRequestClarificationDuringAnnotationAsync(): Promise<void> {
    if (!this.annotationsStore.id && !this.undoRedoHistory.canUndo) {
      await this.clarificationsApiService.requestClarificationAsync(this.createRequestClarificationRequest());
    } else {
      await this.batchAnnotationApiService.requestClarificationDuringAnnotationAsync(this.createRequestClarificationDuringAnnotationRequest());
    }

    this.eventBus.sendEvent(new ClarificationAddedEvent());
  }

  async batchRequestClarificationDuringReviewAsync(): Promise<void> {
    await this.batchAnnotationApiService.requestClarificationDuringReviewAsync(this.createRequestClarificationDuringReviewRequest());
    this.eventBus.sendEvent(new ClarificationAddedEvent());
  }

  async batchRequestClarificationDuringReviewEditAsync(): Promise<void> {
    await this.batchAnnotationApiService.requestClarificationDuringReviewEditAsync(this.createRequestClarificationDuringReviewEditRequest());
    this.eventBus.sendEvent(new ClarificationAddedEvent());
  }

  async freeAccessRequestClarificationDuringAnnotationAsync(): Promise<void> {
    if (!this.annotationsStore.id && !this.undoRedoHistory.canUndo) {
      await this.clarificationsApiService.requestClarificationAsync(this.createRequestClarificationRequest());
    } else {
      await this.freeAccessAnnotationApiService.requestClarificationDuringAnnotationAsync(this.createRequestClarificationDuringAnnotationRequest());
    }

    this.eventBus.sendEvent(new ClarificationAddedEvent());
  }

  async freeAccessRequestClarificationDuringReviewAsync(): Promise<void> {
    await this.freeAccessAnnotationApiService.requestClarificationDuringReviewAsync(this.createRequestClarificationDuringReviewRequest());
    this.eventBus.sendEvent(new ClarificationAddedEvent());
  }

  async freeAccessRequestClarificationDuringReviewEditAsync(): Promise<void> {
    await this.freeAccessAnnotationApiService.requestClarificationDuringReviewEditAsync(this.createRequestClarificationDuringReviewEditRequest());
    this.eventBus.sendEvent(new ClarificationAddedEvent());
  }

  async freeAccessRequestClarificationDuringEditAsync(): Promise<void> {
    await this.freeAccessAnnotationApiService.requestClarificationDuringEditAsync(this.createRequestClarificationDuringFreeAccessEditRequest());
    this.eventBus.sendEvent(new ClarificationAddedEvent());
  }

  async clarifyAsync(projectId: string, clarificationId: string, answer: string): Promise<void> {
    const imageId = this.annotationsStore.image!.id;
    const request = new ClarifyRequest(clarificationId, answer);

    await this.clarificationsApiService.clarifyAsync(request);

    this.getClarificationsAsync(projectId, imageId);
  }

  setClarificationQuestion(question: string): void {
    this.clarificationsStoreSetter.setQuestion(question);
  }

  setClarificationsListSortDirection(sortDirection: SortingDirection): void {
    this.clarificationsStoreSetter.setClarificationsListSortDirection(sortDirection);
  }

  dispose(): void {
    this.clarificationsStoreSetter.clear();
  }

  private createRequestClarificationDuringAnnotationRequest(): IRequestClarificationDuringAnnotationRequest {
    const id = this.annotationsStore.id || uuid.v4();
    return {
      id,
      projectId: this.annotationsStore.projectId,
      imageId: this.annotationsStore.image!.id,
      duration: this.timer.duration,
      annotation: {
        id,
        questions: this.annotationsStore.questions
          .filter(q => q.scopes.includes(ImageScopeName))
          .map(q => ({ id: q.id, answer: q.answer, answers: q.answers.map(a => ({ id: a.id, selected: a.selected })) })),
        segmentations: this.annotationsStore.segmentations.map(s => ({
          id: s.id,
          questions: s.questions,
          feature: {
            type: s.feature.type,
            properties: s.feature.properties,
            geometry: s.feature.geometry,
          },
          priority: s.priority,
        })),
      } as IAnnotationDto,
      question: this.clarificationsStore.question,
    };
  }

  private createRequestClarificationRequest(): IRequestClarificationRequest {
    return {
      projectId: this.annotationsStore.projectId,
      imageId: this.annotationsStore.image!.id,
      question: this.clarificationsStore.question,
      duration: this.timer.duration,
    };
  }

  private createRequestClarificationDuringReviewRequest(): IRequestClarificationDuringReviewRequest {
    const id = this.annotationsStore.id || uuid.v4();
    return {
      id,
      projectId: this.annotationsStore.projectId,
      imageId: this.annotationsStore.image!.id,
      duration: this.timer.duration,
      question: this.clarificationsStore.question,
    };
  }

  private createRequestClarificationDuringReviewEditRequest(): IRequestClarificationDuringReviewEditRequest {
    const id = this.annotationsStore.id || uuid.v4();
    return {
      id,
      projectId: this.annotationsStore.projectId,
      imageId: this.annotationsStore.image!.id,
      duration: this.timer.duration,
      annotation: {
        id,
        questions: this.annotationsStore.questions
          .filter(q => q.scopes.includes(ImageScopeName))
          .map(q => ({ id: q.id, answer: q.answer, answers: q.answers.map(a => ({ id: a.id, selected: a.selected })) })),
        segmentations: this.annotationsStore.segmentations.map(s => ({
          id: s.id,
          questions: s.questions,
          feature: {
            type: s.feature.type,
            properties: s.feature.properties,
            geometry: s.feature.geometry,
          },
          priority: s.priority,
        })),
      } as IAnnotationDto,
      question: this.clarificationsStore.question,
    };
  }

  private createRequestClarificationDuringFreeAccessEditRequest(): IRequestClarificationDuringFreeAccessEditRequest {
    const id = this.annotationsStore.id || uuid.v4();
    return {
      id,
      projectId: this.annotationsStore.projectId,
      imageId: this.annotationsStore.image!.id,
      duration: this.timer.duration,
      annotation: {
        id,
        questions: this.annotationsStore.questions
          .filter(q => q.scopes.includes(ImageScopeName))
          .map(q => ({ id: q.id, answer: q.answer, answers: q.answers.map(a => ({ id: a.id, selected: a.selected })) })),
        segmentations: this.annotationsStore.segmentations.map(s => ({
          id: s.id,
          questions: s.questions,
          feature: {
            type: s.feature.type,
            properties: s.feature.properties,
            geometry: s.feature.geometry,
          },
          priority: s.priority,
        })),
      } as IAnnotationDto,
      question: this.clarificationsStore.question,
    };
  }
}
