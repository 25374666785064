import * as React from 'react';

import { DatasetsServiceType, IDatasetsService } from './datasets.service';
import { IUpdateDatasetService, UpdateDatasetServiceType } from './updateDataset.service';

import { IDatasetsStore } from './datasets.store';
import { container } from '../../diContainer';

export interface IDatasetsContext {
  service: IDatasetsService;
  store: IDatasetsStore;
}

const service: IDatasetsService = container.get(DatasetsServiceType);
export const Datasets = React.createContext<IDatasetsContext>({
  service,
  store: service.store,
});

export function makeDatasetsContext(): IDatasetsContext {
  const service: IDatasetsService = container.get(DatasetsServiceType);
  const ctx: IDatasetsContext = {
    service,
    store: service.store,
  };

  // TODO: This service might be replaced with a generic event bus
  const updater: IUpdateDatasetService = container.get(UpdateDatasetServiceType);
  updater.datasetsStore = ctx.store;

  return ctx;
}
