import * as React from 'react';

import { faList, faSortAmountDown, faTh } from '@fortawesome/free-solid-svg-icons';

import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { IconedButton } from '../../../../../components/IconedButton';
import { ListViewMode } from '../../../../user/user.store';
import { as } from '../../../../../helpers/react.helpers';
import { handleClickAndPassData } from '../../../../../helpers/formHelpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

library.add(faSortAmountDown, faTh, faList);

interface IProjectDetailsImageListControlsProps {
  viewMode: ListViewMode;
  showAnnotatedReviedColumns: boolean;
  onViewModeChange(mode: ListViewMode): void;
}
@observer
class ProjectDetailsImageListControlsPure extends React.Component<IProjectDetailsImageListControlsProps & ITranslatable> {
  render() {
    return (
      <div className="image-view-controls-container">
        <div className="image-view-control">
          <IconedButton
            color="primary"
            className="image-list-mode-button image-list-button"
            icon={this.props.viewMode === ListViewMode.Tiles ? faList : faTh}
            onClick={handleClickAndPassData(this.props.onViewModeChange)(this.props.viewMode === ListViewMode.Tiles ? ListViewMode.List : ListViewMode.Tiles)}
          />
        </div>
      </div>
    );
  }
}

export const ProjectDetailsImageListControls = as<React.StatelessComponent<IProjectDetailsImageListControlsProps>>(withNamespaces('project')(ProjectDetailsImageListControlsPure));
