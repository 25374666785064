import { inject, injectable } from 'inversify';
import { StickerError } from '../../../../../models/error.model';
import { ApiServiceType } from '../../../../../services/api.service';
import { IApiService } from '../../../../../services/api.service.base';
import { IAnnotationResponse } from '../../../annotations.interface';
import { IQuestionModel } from '../../../question.model';
import { IAnnotationType } from '../../annotationTypes/models/annotationType';

export const SegmentationsApiServiceType = Symbol('SEGMENTATIONS_API_SERVICE');

export interface IImageAnnotationsResponse {
  projects: { id: string; name: string; }[];
  emptyProjects: { id: string; name: string; }[];
  annotations: IAnnotationResponse[];
  annotationTypes: IAnnotationType[];
  questions: IQuestionModel[];
}

export interface ISegmentationsApiService {
  peekImageAnnotations(imageId: string): Promise<IImageAnnotationsResponse | StickerError>;
}

@injectable()
export class SegmentationsApiService implements ISegmentationsApiService {
  constructor(@inject(ApiServiceType) private readonly apiService: IApiService) {}

  peekImageAnnotations = (imageId: string): Promise<IImageAnnotationsResponse | StickerError> => this.apiService.getAsync(`/Images/PeekImageAnnotations?imageId=${imageId}`);
}
