import { AnnotationTypesApiService, AnnotationTypesApiServiceType, IAnnotationTypesApiService } from './sub/tools/services/annotationTypesApi.service';
import { ContainerModule, interfaces } from 'inversify';
import { IImageFilterService, ImageFilterService, ImageFilterServiceType } from './sub/images/services/imageFilters.service';
import { IImageFiltersApiService, ImageFiltersApiService, ImageFiltersApiServiceType } from './sub/images/services/imageFiltersApi.service';
import {
  IProjectDetailsActivitiesApiService,
  ProjectDetailsActivitiesApiService,
  ProjectDetailsActivitiesApiServiceType,
} from './sub/activityLog/services/projectDetailsActivitiesApi.service';
import { IProjectDetailsActivitiesBl, ProjectDetailsActivitiesBl, ProjectDetailsActivitiesBlType } from './sub/activityLog/projectDetailsActivities.bl';
import { IProjectDetailsActivitiesStore, ProjectDetailsActivitiesStore, ProjectDetailsActivitiesStoreType } from './sub/activityLog/projectDetailsActivities.store';
import { IProjectDetailsApiService, ProjectDetailsApiService, ProjectDetailsApiServiceType } from './services/projectDetailsApi.service';
import { IProjectDetailsBl, ProjectDetailsBl, ProjectDetailsBlType } from './projectDetails.bl';
import { IProjectDetailsImagesApiService, ProjectDetailsImagesApiService, ProjectDetailsImagesApiServiceType } from './sub/images/services/projectDetailsImagesApi.service';
import { IProjectDetailsImagesBl, ProjectDetailsImagesBl, ProjectDetailsImagesBlType } from './sub/images/projectDetailsImages.bl';
import {
  IProjectDetailsImagesStore,
  IProjectDetailsImagesStoreSetter,
  ProjectDetailsImagesStore,
  ProjectDetailsImagesStoreSetterType,
  ProjectDetailsImagesStoreType,
} from './sub/images/projectDetailsImages.store';
import { IProjectDetailsPermissions, ProjectDetailsPermissions, ProjectDetailsPermissionsType } from './projectDetails.permissions';
import {
  IProjectDetailsStatisticsApiService,
  ProjectDetailsStatisticsApiService,
  ProjectDetailsStatisticsApiServiceType,
} from './sub/statistics/services/projectDetailsStatisticsApi.service';
import { IProjectDetailsStatisticsBl, ProjectDetailsStatisticsBl, ProjectDetailsStatisticsBlType } from './sub/statistics/projectDetailsStatistics.bl';
import { IProjectDetailsStatisticsStore, ProjectDetailsStatisticsStore, ProjectDetailsStatisticsStoreType } from './sub/statistics/projectDetailsStatistics.store';
import { IProjectDetailsStore, ProjectDetailsStore, ProjectDetailsStoreType } from './projectDetails.store';
import { IProjectDetailsSubmodule, ProjectDetailsSubmoduleType } from './services/IProjectDetailsSubmodule';
import { IProjectDetailsToolsBl, IProjectDetailsToolsBlType, ProjectDetailsToolsBl } from './sub/tools/projectDetailsTools.bl';
import {
  IProjectDetailsToolsStore,
  IProjectDetailsToolsStoreSetter,
  ProjectDetailsToolsStore,
  ProjectDetailsToolsStoreSetterType,
  ProjectDetailsToolsStoreType,
} from './sub/tools/projectDetailsTools.store';
import { IProjectPreviewService, ProjectPreviewService, ProjectPreviewServiceType } from './services/projectPreview.service';
import { IQuestionsApiService, QuestionsApiService, QuestionsApiServiceType } from './sub/tools/services/questionsApi.service';

export const projectDetailsModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IProjectDetailsApiService>(ProjectDetailsApiServiceType)
    .to(ProjectDetailsApiService)
    .inSingletonScope();
  bind<IProjectDetailsStore>(ProjectDetailsStoreType)
    .to(ProjectDetailsStore)
    .inSingletonScope();
  bind<IProjectDetailsActivitiesStore>(ProjectDetailsActivitiesStoreType)
    .to(ProjectDetailsActivitiesStore)
    .inSingletonScope();
  bind<IProjectDetailsStatisticsStore>(ProjectDetailsStatisticsStoreType)
    .to(ProjectDetailsStatisticsStore)
    .inSingletonScope();
  bind<IProjectDetailsBl>(ProjectDetailsBlType)
    .to(ProjectDetailsBl)
    .inSingletonScope();

  bind<IProjectDetailsImagesBl>(ProjectDetailsImagesBlType)
    .to(ProjectDetailsImagesBl)
    .inSingletonScope();
  bind<IProjectDetailsSubmodule>(ProjectDetailsSubmoduleType)
    .toService(ProjectDetailsImagesBlType);

  bind<IProjectDetailsStatisticsBl>(ProjectDetailsStatisticsBlType)
    .to(ProjectDetailsStatisticsBl)
    .inSingletonScope();
  bind<IProjectDetailsSubmodule>(ProjectDetailsSubmoduleType)
    .toService(ProjectDetailsStatisticsBlType);

  bind<IProjectDetailsActivitiesBl>(ProjectDetailsActivitiesBlType)
    .to(ProjectDetailsActivitiesBl)
    .inSingletonScope();
  bind<IProjectDetailsSubmodule>(ProjectDetailsSubmoduleType)
    .toService(ProjectDetailsActivitiesBlType);

  bind<IProjectDetailsToolsBl>(IProjectDetailsToolsBlType)
    .to(ProjectDetailsToolsBl)
    .inSingletonScope();
  bind<IProjectDetailsSubmodule>(ProjectDetailsSubmoduleType)
    .toService(IProjectDetailsToolsBlType);

  bind<IProjectDetailsStatisticsApiService>(ProjectDetailsStatisticsApiServiceType)
    .to(ProjectDetailsStatisticsApiService)
    .inSingletonScope();
  bind<IProjectDetailsImagesApiService>(ProjectDetailsImagesApiServiceType)
    .to(ProjectDetailsImagesApiService)
    .inSingletonScope();
  bind<IProjectDetailsActivitiesApiService>(ProjectDetailsActivitiesApiServiceType)
    .to(ProjectDetailsActivitiesApiService)
    .inSingletonScope();
  bind<IQuestionsApiService>(QuestionsApiServiceType)
    .to(QuestionsApiService)
    .inSingletonScope();
  bind<IAnnotationTypesApiService>(AnnotationTypesApiServiceType)
    .to(AnnotationTypesApiService)
    .inSingletonScope();
  bind<IProjectPreviewService>(ProjectPreviewServiceType)
    .to(ProjectPreviewService)
    .inSingletonScope();
  bind<IProjectDetailsPermissions>(ProjectDetailsPermissionsType)
    .to(ProjectDetailsPermissions)
    .inSingletonScope();

  bind<IProjectDetailsToolsStore>(ProjectDetailsToolsStoreType)
    .to(ProjectDetailsToolsStore)
    .inSingletonScope();
  bind<IProjectDetailsToolsStoreSetter>(ProjectDetailsToolsStoreSetterType)
    .toService(ProjectDetailsToolsStoreType);

  bind<IProjectDetailsImagesStore>(ProjectDetailsImagesStoreType)
    .to(ProjectDetailsImagesStore)
    .inSingletonScope();
  bind<IProjectDetailsImagesStoreSetter>(ProjectDetailsImagesStoreSetterType)
    .toService(ProjectDetailsImagesStoreType);

  bind<IImageFilterService>(ImageFilterServiceType)
    .to(ImageFilterService)
    .inSingletonScope();

  bind<IImageFiltersApiService>(ImageFiltersApiServiceType)
    .to(ImageFiltersApiService)
    .inSingletonScope();
});
