export interface IPollingState {
  alreadyLoading: boolean;
}

export function withPolling(pollingFunction: () => Promise<void>, interval: number = 5000) {
  let alreadyLoading = false;
  let shouldBreak = false;

  // Using recursive calls instead of setInterval to prevent issues with long running tasks
  async function asyncInterval(asyncFunction: () => Promise<void>, timeout: number) {
    if (shouldBreak) return;

    if (alreadyLoading) {
      // Check again after timeout
      setTimeout(() => asyncInterval(asyncFunction, timeout), timeout);
    }

    try {
      alreadyLoading = true;
      await asyncFunction();
    } finally {
      alreadyLoading = false;
      setTimeout(() => asyncInterval(asyncFunction, timeout), timeout);
    }
  }

  asyncInterval(async () => await pollingFunction(), interval);

  return () => {
    shouldBreak = true;
  };
}
