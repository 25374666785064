import { AttachmentUploadServiceType, IAttachmentUploadService } from '../attachmentUploadService';
import { AttachmentsServiceType, IAttachmentsService } from '../attachments.service';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { IUserStore, UserStoreType } from '../../user/user.store';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { AttachmentUpload } from '../components/AttachmentsUpload';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps extends RouteComponentProps<{ attachmentId: string }> {
  uploadService: IAttachmentUploadService;
  attachmentsService: IAttachmentsService;
  userStore: IUserStore;
}

interface IState extends ILoaderState {
  isModalOpen: boolean;
  isProcessingImages: boolean;
}

@injectProps({
  uploadService: AttachmentUploadServiceType,
  attachmentsService: AttachmentsServiceType,
  userStore: UserStoreType,
})
@observer
class AttachmentUploadContainerPure extends WithLoaderComponentBase<IInjectedProps & ITranslatable, IState> {
  constructor(props: IInjectedProps & ITranslatable) {
    super(props);
  }

  state = {
    isModalOpen: false,
    isProcessingImages: false,
    isLoading: false,
  };

  handleFileDropped = async (files: File[]) => {
    this.setState({ isProcessingImages: true });
    await this.props.uploadService.prepareFilesAsync(files, this.props.match.params.attachmentId);
    this.setState({ isProcessingImages: false });
  };

  onFileUpload = async () => {
    const attachmentId = this.props.match.params.attachmentId;
    const callback = () =>
      this.withLoaderAsync(() => {
        if (attachmentId === this.props.match.params.attachmentId) {
          this.props.attachmentsService.getAttachmentsAsync(attachmentId);
        }
      }, 'attachment-list-loader');
    this.props.uploadService.startUpload(callback);
    this.toggleModal();
  };

  toggleModal = () => {
    this.setState((prev: IState) => ({ isModalOpen: !prev.isModalOpen }));
    this.props.uploadService.data.preparedFiles = [];
  };

  render() {
    const canView = this.props.attachmentsService.canUseAttachments();
    return (
      <AttachmentUpload
        canView={canView}
        isModalOpen={this.state.isModalOpen}
        isProcessingImages={this.state.isProcessingImages}
        preparedFiles={this.props.uploadService.data.preparedFiles}
        willExceedLimit={this.props.uploadService.data.willExceedsLimit}
        toggleModal={this.toggleModal}
        onFileUpload={this.onFileUpload}
        handleFileDropped={this.handleFileDropped}
      />
    );
  }
}

export const AttachmentUploadContainer = as<React.ComponentClass>(withRouter(withNamespaces('common', { wait: true })(AttachmentUploadContainerPure)));
