import { Dictionary, sortBy } from 'lodash';
import { IObservableArray, observable, set } from 'mobx';
import { ISegmentationsApiService, SegmentationsApiServiceType } from './segmentationsApi.service';
import { fromPairs, keyBy } from 'lodash/fp';

import { ISegmentation } from '../../../annotations.interface';
import { LatLngBounds } from 'leaflet';
import { QuestionModel } from '../../../question.model';
import React from 'react';
import { StickerError } from '../../../../../models/error.model';
import { VerticalMenuItem } from '../components/verticalMenu/VerticalMenu';
import autobind from 'autobind-decorator';
import { container } from '../../../../../diContainer';
import { getLatLngsForGeojson } from '../../../../../helpers/geometry/polygon.helpers';
import { outerJoinOn } from '../../../../../helpers/array.helpers';
import uuid from 'uuid';

export interface IImageSegmentationsStore {
  segmentations: Dictionary<ISegmentation[]>;
  answeredQuestions: Dictionary<QuestionModel[]>;
  projects: IObservableArray<{ id: string; name: string }>;
  selectedProjects: IObservableArray<string>;
  openedProjects: IObservableArray<string>;
  isLoaded: boolean;
  clear(): void;
  activeTab?: VerticalMenuItem;
}

export interface IImageSegmentationsContext {
  store: IImageSegmentationsStore;
  loadAsync(imageId: string): Promise<void>;
  toggleTab(menuItem?: VerticalMenuItem): void;
}

class ImageSegmentationsContext implements IImageSegmentationsContext {
  public store: IImageSegmentationsStore;

  constructor(private api: ISegmentationsApiService, enabled = false) {
    this.store = observable(
      {
        segmentations: {},
        answeredQuestions: {},
        projects: observable.array([]),
        selectedProjects: observable.array([]),
        openedProjects: observable.array([]),
        isLoaded: false,
        activeTab: undefined,
        clear(): void {
          this.segmentations = {};
          this.projects = observable.array([]);
          this.isLoaded = false;
        },
      },
      undefined,
      { deep: false },
    );
  }

  @autobind
  async loadAsync(imageId: string) {
    if (this.store.projects || this.store.segmentations) this.store.clear();

    const result = await this.api.peekImageAnnotations(imageId);
    if (result instanceof StickerError) throw result;

    const annotationTypes = keyBy('id', result.annotationTypes);

    set(this.store, {
      segmentations: fromPairs(
        result.annotations
          .map(({ projectId, annotations }) => [
            projectId,
            annotations.segmentations.map(s => {
              const feature = {
                ...s.feature,
                id: uuid.v4(),
                color: annotationTypes[s.feature.properties!.annotationTypeId].color,
                featureType: s.feature.properties!.featureType,
              };

              const latlngs = getLatLngsForGeojson(feature);

              return {
                feature,
                latlngs,
                questions: result.questions
                  .filter(q => q.scopes.includes(s.feature.properties!.annotationTypeId))
                  .map(question => {
                    const q = s.questions.find(sq => sq.id === question.id);
                    const answers = q ? outerJoinOn('id', 'id', question.answers, q.answers) : [];

                    return new QuestionModel(projectId, question.id, question.type, question.isRequired, question.text, answers, question.scopes, q?.answer);
                  }),
                bbox: new LatLngBounds(latlngs),
                priority: 0,
              } as ISegmentation;
            }),
          ])
          .concat(result.emptyProjects.map(ep => [ep.id, []])),
      ),
      answeredQuestions: fromPairs(
        result.annotations
          .map(({ projectId, annotations }) => [
            projectId,
            sortBy(annotations.questions, 'order').map(aq => {
              const question = result.questions.find(q => q.id === aq.id)!;
              return new QuestionModel(
                projectId,
                aq.id,
                question.type,
                question.isRequired,
                question.text,
                question.answers.map(a => ({ ...a, selected: aq.answers.findIndex(answer => answer.id === a.id) !== -1 })),
                [],
                aq.answer,
              );
            }),
          ])
          .concat(result.emptyProjects.map(ep => [ep.id, []])),
      ),
      projects: observable.array(result.projects.concat(result.emptyProjects)),
      isLoaded: true,
    });
  }

  @autobind
  toggleTab(menuItem?: VerticalMenuItem | undefined): void {
    set(this.store, { activeTab: this.store.activeTab === menuItem ? undefined : menuItem });
  }
}

const defaultSegmentations = new ImageSegmentationsContext(container.get(SegmentationsApiServiceType));

export const ImageSegmentations = React.createContext<IImageSegmentationsContext>(defaultSegmentations);

interface IImageSegmentationsContextProviderProps {
  enabled?: boolean;
  imageId?: string;
}

export class ImageSegmentationsContextProvider extends React.Component<IImageSegmentationsContextProviderProps, { ctx: IImageSegmentationsContext }> {
  state = { ctx: defaultSegmentations };

  async componentDidMount() {
    const ctx = new ImageSegmentationsContext(container.get(SegmentationsApiServiceType), this.props.enabled);
    this.setState({ ctx });
    if (this.props.imageId) await ctx.loadAsync(this.props.imageId);
  }

  async componentDidUpdate(prevProps: IImageSegmentationsContextProviderProps) {
    if (this.props.imageId && prevProps.imageId !== this.props.imageId) await this.state.ctx.loadAsync(this.props.imageId);
  }

  render() {
    return <ImageSegmentations.Provider value={this.state.ctx}>{this.props.children}</ImageSegmentations.Provider>;
  }
}
