import * as React from 'react';

import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { ButtonWithLoader } from '../../../components/ButtonWithLoader';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { InputStatus } from '../../../models/error.model';
import { ValidatedTextInput } from '../../../components/ValidatedTextInput';
import { as } from '../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { withNamespaces } from 'react-i18next';

interface IChangeEmailModalProps {
  show: boolean;
  passwordStatus: InputStatus;
  emailStatus: InputStatus;
  onConfirm(): void;
  onCancel(): void;
  onPasswordChange(password: string): void;
  onPasswordBlur(): void;
  onEmailChange(email: string): void;
  onEmailBlur(): void;
}

class ChangeEmailModalPure extends React.Component<IChangeEmailModalProps & ITranslatable> {
  @autobind
  handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    this.props.onConfirm();
  }

  get valid() {
    return this.props.emailStatus.isValid && this.props.passwordStatus.isValid;
  }

  render() {
    return (
      <Modal className="modal-width-600" isOpen={this.props.show} toggle={this.props.onCancel}>
        <ModalHeader toggle={this.props.onCancel}>{this.props.t('change_email')}</ModalHeader>
        <Form onSubmit={this.handleSubmit}>
          <ModalBody>
            <Row>
              <Col>
                <ValidatedTextInput
                  labelText={`${this.props.t('email')} *`}
                  type="email"
                  placeholder={this.props.t('enter_email')}
                  isValid={this.props.emailStatus.isValid}
                  feedbacks={this.props.emailStatus.errorCodes}
                  onChange={this.props.onEmailChange}
                  onBlur={this.props.onEmailBlur}
                />
                <ValidatedTextInput
                  labelText={`${this.props.t('password')} *`}
                  type="password"
                  placeholder={this.props.t('enter_password')}
                  enablePreview={true}
                  isValid={this.props.passwordStatus.isValid}
                  feedbacks={this.props.passwordStatus.errorCodes}
                  onChange={this.props.onPasswordChange}
                  onBlur={this.props.onPasswordBlur}
                />
              </Col>
            </Row>
            <Row>
              <Col>
              <div className="error-feedback">
                {this.props.t('change_email_assignments_delete_notification')}
               </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <ButtonWithLoader className="mr-2" color="success" disabled={!this.valid} loaderKey="change_email_loader">
              {this.props.t('change_email')}
            </ButtonWithLoader>
            <Button color="primary" className="btn-outline-primary" onClick={this.props.onCancel}>
              {this.props.t('cancel')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export const ChangeEmailModal = as<React.ComponentClass<IChangeEmailModalProps>>(withNamespaces('user', { wait: true })(ChangeEmailModalPure));
