import { ContainerModule, interfaces } from 'inversify';
import { ILocalizationStore, LocalizationStore, LocalizationStoreType } from './localization.store';
import { IRouterStore, RouterStore, RouterStoreType } from './router.store';

export const storesModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IRouterStore>(RouterStoreType)
    .to(RouterStore)
    .inSingletonScope();
  bind<ILocalizationStore>(LocalizationStoreType)
    .to(LocalizationStore)
    .inSingletonScope();
});
