import { ContainerModule, interfaces } from 'inversify';
import { IUserService, UserService, UserServiceType } from './user.service';
import { IUserStore, UserStore, UserStoreType } from './user.store';

export const userModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IUserStore>(UserStoreType)
    .to(UserStore)
    .inSingletonScope();
  bind<IUserService>(UserServiceType)
    .to(UserService)
    .inSingletonScope();
});
