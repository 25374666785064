import { IProjectDetailsStatisticsBl, ProjectDetailsStatisticsBlType } from '../projectDetailsStatistics.bl';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../../../helpers/react.helpers';
import { currentTimeZone, timeZones } from '../../../../../models/timeZones/timeZones.model';

import { Loader } from '../../../../../components/Loader';
import React from 'react';
import { Settings } from '../../../../../../modules/settings/SettingsContext';
import { StatisticsList } from '../components/StatisticsList';
import { WithLoaderComponentBase } from '../../../../../helpers/loader.helpers';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps {
  projectDetailsStatisticsBl: IProjectDetailsStatisticsBl;
}

type Props = IInjectedProps & RouteComponentProps<{ projectId: string }>;

@injectProps({
  projectDetailsStatisticsBl: ProjectDetailsStatisticsBlType,
})
@observer
class StatisticsListContainerPure extends WithLoaderComponentBase<Props> {
  static contextType = Settings;
  declare context: React.ContextType<typeof Settings>;
  
  async componentDidMount() {
    await this.withLoaderAsync(async () => {
      const timezone = timeZones.find(tz => tz.value === this.props.projectDetailsStatisticsBl.store.offset);

      if (timezone) {
        this.props.projectDetailsStatisticsBl.store.selectedTimeZone = timezone;
      } else if (currentTimeZone) {
        this.props.projectDetailsStatisticsBl.store.selectedTimeZone = currentTimeZone;
      }

      await this.props.projectDetailsStatisticsBl.getStatisticsAsync(this.props.match.params.projectId);
    });
  }

  @autobind
  async handlePaginationChange(pageNumber: number, pageSize: number) {
    await this.withLoaderAsync(async () => {
      this.props.projectDetailsStatisticsBl.store.statisticsPaging.pageNumber = pageNumber;
      this.props.projectDetailsStatisticsBl.store.statisticsPaging.pageSize = pageSize;
      this.context.setStatisticsPageSize(pageSize);

      await this.props.projectDetailsStatisticsBl.getStatisticsAsync(this.props.match.params.projectId);
    });
  }

  @autobind
  async handleOrderingChange(orderBy: string, orderType: string) {
    await this.withLoaderAsync(async () => {
      this.props.projectDetailsStatisticsBl.store.statisticsPaging.orderBy = orderBy;
      this.props.projectDetailsStatisticsBl.store.statisticsPaging.orderType = orderType;

      await this.props.projectDetailsStatisticsBl.getStatisticsAsync(this.props.match.params.projectId);
    });
  }

  render() {
    return (
      <Loader isLoading={this.state.isLoading}>
        <StatisticsList
          statistics={this.props.projectDetailsStatisticsBl.store.statistics}
          paging={{...this.props.projectDetailsStatisticsBl.store.statisticsPaging, pageSize: this.context.store.statisticsPageSize}}
          onPaginationChange={this.handlePaginationChange}
          onOrderingChange={this.handleOrderingChange}
          showLoader={this.props.projectDetailsStatisticsBl.store.statisticsLoading}
        />
      </Loader>
    );
  }
}

export const StatisticsListContainer = as<React.ComponentClass>(withRouter(StatisticsListContainerPure));
