import * as React from 'react';

import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';

import { ITranslatable } from '../../../helpers/translations.helpers';
import { LogoSignet } from '../../../components/StaticImages';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IIncreaseLimitThanksModalProps {
  showModal: boolean;
  onOk(): void;
}

type IProps = IIncreaseLimitThanksModalProps & ITranslatable;

class IncreaseLimitThanksModal extends React.Component<IProps> {
  render() {
    const { t } = this.props;
    return (
      <Modal isOpen={this.props.showModal} toggle={this.props.onOk}>
        <ModalBody>
          <div className="text-center">
            <p>
              <img src={LogoSignet} alt="Logo" />
            </p>
            <p>{t('increase_limit_thanks_modal_text_1')}</p>
            <p>{t('increase_limit_thanks_modal_text_2')}</p>
            <Row>
              <Col>
                <Button outline color="primary" onClick={this.props.onOk} className="mx-auto">
                  {t('Ok')}
                </Button>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default as<React.StatelessComponent<IIncreaseLimitThanksModalProps>>(withNamespaces('billing', { wait: true })(IncreaseLimitThanksModal));
