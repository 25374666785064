import { IUserStore, UserStoreType } from '../../user/user.store';
import { as, injectProps } from '../../../helpers/react.helpers';

import { IViewAttributesButtonProps } from '../components/ViewAttributesButton';
import React from 'react';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps {
  userStore: IUserStore;
}

@injectProps({
  userStore: UserStoreType,
})
@observer
class ViewAttributesContainer extends React.Component<IInjectedProps> {

  @autobind
  handleClick() {
    this.props.userStore.areAttributesShown = !this.props.userStore.areAttributesShown;
  }

  render() {
    return React.Children.map(this.props.children, (child: any) =>
      React.cloneElement(child, {
        handleClick: this.handleClick,
        isShown: this.props.userStore.areAttributesShown,
      } as IViewAttributesButtonProps),
    );
  }
}

export default as<React.ComponentClass>(ViewAttributesContainer);
