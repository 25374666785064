import { computed, observable } from 'mobx';

import { IImageFile } from './imagesUpload.service';
import { IImageUrl } from './imagesFromUrlsUpload.service';
import { IRejectedFile } from './models/IRejectedFile';
import { InputStatus } from '../../models/error.model';
import { injectable } from 'inversify';

export const DatasetDraftStoreType = Symbol('DATASET_DRAFTS_STORE');

export interface IDatasetDraft {
  id: string;
  externalStatus: InputStatus;
  workspaceId: string;
  name: string;
  description: string;
  nameStatus: InputStatus;
  images: IImageFile[];
  imagesToUploadFromUrl: IImageUrl[];
  imagesToUploadFromFile: IImageFile[];
  imagesRejectedFromFile: IRejectedFile[];
  willExceedsUploadImageSizeLimit: boolean;
  imagePreValidationWasSuccessful: boolean;
  ownerPlan: string;
  termsOfUse: string;
  author: string;
}

export interface IDatasetDraftsStore {
  draft: IDatasetDraft;
  isValid: boolean;
}

@injectable()
export class DatasetDraftsStore implements IDatasetDraftsStore {
  @observable
  draft!: IDatasetDraft;

  @computed
  get isValid(): boolean {
    return (
      (this.draft.imagesToUploadFromFile.length > 0 ||
        this.draft.imagesToUploadFromUrl.filter(i => i.isValid).length > 0) &&
      this.draft.nameStatus.errorCodes.length === 0
    );
  }
}
