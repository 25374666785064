import * as React from 'react';

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../workspaces/currentWorkspace/CurrentWorkspace.store';
import { EventBusType, EventListeningDisposer, IEventBus } from '../../../__legacy__/services/eventBus.service';
import { ILoaderState, WithLoaderComponentBase } from '../../../__legacy__/helpers/loader.helpers';
import { INotificationsService, NotificationsServiceType } from '../../../__legacy__/modules/notifications/services/notifications.service';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../common/OverlayLoader.store';
import { IRouterStore, RouterStoreType } from '../../../__legacy__/stores/router.store';
import { IUserService, UserServiceType } from '../../../__legacy__/modules/user/user.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../__legacy__/helpers/react.helpers';

import { CurrentWorkspaceChangedEventType } from '../../workspaces/currentWorkspace/events/CurrentWorkspaceChangedEvent';
import { Home } from '../../../__legacy__/routes/config/Home';
import { Loader } from '../../../__legacy__/components/Loader';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { ModelsList } from '../components/ModelsList';
import { IModelsService, ModelsServiceType } from '../models.service';
import { IModelListItem, ModelOwnership } from '../models.store';
import { ConfirmationModalWithTextBox } from '../../../__legacy__/components/ConfirmationModalWithTextBox';
import { Trans, withNamespaces } from 'react-i18next';
import { ITranslatable } from '../../../__legacy__/helpers/translations.helpers';
import { S_TwoButtonModal } from '../../../design/modals/twoButtonModal/S_TwoButtonModal';
import { withPolling } from '../../../__legacy__/helpers/polling.helpers';
import { ModelPermissions } from '../../../__legacy__/modules/workspaces/workspaces.store';

interface IProps extends RouteComponentProps, ITranslatable {
  modelsService: IModelsService;
  currentWorkspaceStore: ICurrentWorkspaceStore;
  overlayLoader: IOverlayLoaderStore;
  notificationService: INotificationsService;
  userService: IUserService;
  routerStore: IRouterStore;
  eventBus: IEventBus;
}

interface IState extends ILoaderState {
  showRestartModal: boolean;
  modelIdToRestart?: string;
  showStopModal: boolean;
  modelIdToStop?: string;
  showDeleteModal: boolean;
  modelToDelete?: IModelListItem;
}

@injectProps({
  modelsService: ModelsServiceType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  overlayLoader: OverlayLoaderStoreType,
  notificationService: NotificationsServiceType,
  userService: UserServiceType,
  routerStore: RouterStoreType,
  eventBus: EventBusType,
})
@observer
class ModelsListContainer extends WithLoaderComponentBase<IProps, IState> {
  currentWorkspaceChangeListenerDisposer: EventListeningDisposer;
  clearPolling?: () => void;
  state: IState = {
    isLoading: false,
    showRestartModal: false,
    modelIdToRestart: undefined,
    showStopModal: false,
    modelIdToStop: undefined,
    showDeleteModal: false,
    modelToDelete: undefined,
  };

  constructor(props: IProps) {
    super(props);
    this.currentWorkspaceChangeListenerDisposer = props.eventBus.addListener(this.refreshAsync, CurrentWorkspaceChangedEventType);
  }

  componentDidMount() {
    if (!this.checkAccess()) {
      return;
    }

    this.refreshAsync();
  }

  componentWillUnmount() {
    if (this.clearPolling !== undefined) this.clearPolling();
    this.currentWorkspaceChangeListenerDisposer();
  }

  componentDidUpdate() {
    let ownership: ModelOwnership = ModelOwnership.All;
    const workspaceId = this.props.currentWorkspaceStore.currentWorkspace!.id;

    switch (this.props.location.pathname) {
      case Home.Models.List.All.withParams({ workspaceId }):
        ownership = ModelOwnership.All;
        break;
      case Home.Models.List.Owned.withParams({ workspaceId }):
        ownership = ModelOwnership.My;
        break;
      case Home.Models.List.Others.withParams({ workspaceId }):
        ownership = ModelOwnership.Others;
        break;
    }

    this.props.modelsService.changeOwnershipFilter(ownership);
  }

  @autobind
  async handleOrderChange(orderBy: string, orderType: string) {
    this.props.modelsService.changeOrder(orderBy, orderType);
  }

  @autobind
  async handlePaginationChange(pageNumber: number, pageSize: number) {
    this.props.modelsService.changePagination(pageNumber, pageSize);
  }

  @autobind
  handleModelRestart(modelId: string): void {
    this.setState(state => ({
      ...state,
      showRestartModal: true,
      modelIdToRestart: modelId,
    }));
  }

  @autobind
  handleModelStop(modelId: string): void {
    this.setState(state => ({
      ...state,
      showStopModal: true,
      modelIdToStop: modelId,
    }));
  }

  @autobind
  handleModelDelete(model: IModelListItem): void {
    this.setState(state => ({
      ...state,
      showDeleteModal: true,
      modelToDelete: model,
    }));
  }

  @autobind
  handleModelEdit(model: IModelListItem): void {
    this.props.history.push(
      Home.Models.Details.Settings.withParams({
        workspaceId: this.props.currentWorkspaceStore.currentWorkspace!.id,
        jobId: model.id,
      }),
    );
  }

  @autobind
  async handleModelRestartConfirmed(): Promise<void> {
    this.setState(state => ({ ...state, showRestartModal: false, modelIdToRestart: undefined }));
    await this.props.modelsService.restartTrainingAsync(this.state.modelIdToRestart!);
  }

  @autobind
  async handleModelStopConfirmed(): Promise<void> {
    this.setState(state => ({ ...state, showStopModal: false, modelIdToStop: undefined }));
    await this.props.modelsService.stopTrainingAsync(this.state.modelIdToStop!);
  }

  @autobind
  async handleModelDeleteConfirmed(): Promise<void> {
    this.setState(state => ({ ...state, showDeleteModal: false, modelToDelete: undefined }));
    await this.props.modelsService.deleteModelAsync(this.state.modelToDelete!.id);
  }

  @autobind
  handleModelRestartCanceled(): void {
    this.setState(state => ({ ...state, showRestartModal: false, modelIdToRestart: undefined }));
  }

  @autobind
  handleModelStopCanceled(): void {
    this.setState(state => ({ ...state, showStopModal: false, modelIdToStop: undefined }));
  }

  @autobind
  handleModelDeleteCanceled(): void {
    this.setState(state => ({ ...state, showDeleteModal: false, modelToDelete: undefined }));
  }

  checkAccess() {
    const canViewModels = this.props.currentWorkspaceStore.isInRole(ModelPermissions.listModels) && !this.props.currentWorkspaceStore.currentWorkspace?.encryption.encrypted;

    if (!canViewModels) {
      this.props.routerStore.push(Home.Projects.List.All.withParams({ workspaceId: this.props.currentWorkspaceStore.currentWorkspace?.id! }));
      return false;
    }

    return true;
  }

  refreshAsync = async () => {
    if (this.clearPolling !== undefined) this.clearPolling();

    const workspaceId = this.props.currentWorkspaceStore.currentWorkspace?.id;

    if (!this.checkAccess() || !workspaceId) {
      return;
    }
    await Promise.all([this.props.modelsService.refreshAsync(), this.props.userService.getUserInfoAsync()]);
    this.clearPolling = withPolling(async () => {
      await this.props.modelsService.getModelsAsync(workspaceId);
    }, 10000);
  };

  render() {
    const { t } = this.props;
    const confirmationModelDeleteQuestion = (
      <Trans
        i18nKey="models:delete_model.confirmation"
        values={{ name: this.state.modelToDelete?.modelName || '' }}
        // tslint:disable-next-line:jsx-key
        components={[<strong>0</strong>]}
      />
    );

    const { store, downloadModelAsync } = this.props.modelsService;

    const { modelsList, modelsPaging } = store;

    const { pageNumber, pageSize, totalCount, orderType, orderBy } = modelsPaging;

    return (
      <Loader isLoading={this.props.overlayLoader.isSpinnerVisible('models-list')} message={t('list_loader')} solid>
        <ModelsList
          workspaceId={this.props.currentWorkspaceStore.currentWorkspace!.id}
          models={modelsList}
          pagination={{
            pageNumber,
            pageSize,
            totalCount,
            onChange: this.handlePaginationChange,
          }}
          sorting={{
            orderBy: orderBy || '',
            orderType: orderType || '',
          }}
          downloadModelAsync={downloadModelAsync}
          onRestartTraining={this.handleModelRestart}
          onStopTraining={this.handleModelStop}
          onDeleteModel={this.handleModelDelete}
          onEditModel={this.handleModelEdit}
          onOrderingChange={this.handleOrderChange}
        />
        <S_TwoButtonModal
          show={this.state.showRestartModal}
          headerText={t('restart_training.text')}
          submitText={t('confirm')}
          cancelText={t('cancel')}
          onCancel={this.handleModelRestartCanceled}
          onHide={this.handleModelStopCanceled}
          onSubmit={this.handleModelRestartConfirmed}
        >
          <p>{t('restart_training.info')}</p>
        </S_TwoButtonModal>
        <S_TwoButtonModal
          show={this.state.showStopModal}
          headerText={t('stop_training.text')}
          submitText={t('confirm')}
          cancelText={t('cancel')}
          onCancel={this.handleModelStopCanceled}
          onHide={this.handleModelStopCanceled}
          onSubmit={this.handleModelStopConfirmed}
        >
          <p>{t('restart_training.info')}</p>
        </S_TwoButtonModal>
        <ConfirmationModalWithTextBox
          showModal={this.state.showDeleteModal}
          confirmationHeader={t('delete_model.text')}
          confirmationQuestion={t('delete_model.info')}
          confirmationTextHeader={confirmationModelDeleteQuestion}
          confirmationText={this.state.modelToDelete?.modelName || ''}
          onCancel={this.handleModelDeleteCanceled}
          onConfirm={this.handleModelDeleteConfirmed}
          confirmationYes={t('delete')}
        />
      </Loader>
    );
  }
}

export default as<React.ComponentClass>(withNamespaces('models')(withRouter(ModelsListContainer)));
