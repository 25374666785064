import React from 'react';
import ReactDOM from 'react-dom';

interface IProps {
  parentElement: string;
  selectors: string[];
}

export class FocusLoop extends React.Component<IProps> {
  private _firstElement: HTMLElement | null = null;
  private _lastElement: HTMLElement | null = null;
  private _isFirstSelection: boolean = true;

  componentDidMount() {
    this.findElements();
  }

  componentDidUpdate() {
    this.findElements();
    this._isFirstSelection = true;
  }

  findElements() {
    const node = ReactDOM.findDOMNode(this);
    const parentElements = (node as HTMLElement).querySelectorAll(`.${this.props.parentElement}`);

    if (parentElements[0] instanceof HTMLElement) {
      for (let i = 0; i < this.props.selectors.length; i += 1) {
        this._firstElement = parentElements[0].querySelector(this.props.selectors[i]);
        if (this._firstElement !== null) break;
      }
    }

    if (parentElements[parentElements.length - 1] instanceof HTMLElement) {
      for (let i = 0; i < this.props.selectors.length; i += 1) {
        this._lastElement = parentElements[parentElements.length - 1].querySelector(this.props.selectors[i]);
        if (this._lastElement !== null) break;
      }
    }
  }

  handleFocusUpperSpan = () => {
    if (this._isFirstSelection) {
      this._isFirstSelection = false;
      return this.focusFirstElement();
    }

    return this.focusLastElement();
  };

  focusFirstElement = () => {
    this._firstElement?.focus();
  };

  focusLastElement = () => {
    this._lastElement?.focus();
  };

  render() {
    return (
      <div>
        <span tabIndex={0} onFocus={this.handleFocusUpperSpan} />
        {this.props.children}
        <span tabIndex={0} onFocus={this.focusFirstElement} />
      </div>
    );
  }
}
