import * as React from 'react';

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../workspaces/currentWorkspace/CurrentWorkspace.store';
import { EventBusType, EventListeningDisposer, IEventBus } from '../../../__legacy__/services/eventBus.service';
import { ILoaderState, WithLoaderComponentBase } from '../../../__legacy__/helpers/loader.helpers';
import { INotificationsService, NotificationsServiceType } from '../../../__legacy__/modules/notifications/services/notifications.service';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../common/OverlayLoader.store';
import { IRouterStore, RouterStoreType } from '../../../__legacy__/stores/router.store';
import { IUserService, UserServiceType } from '../../../__legacy__/modules/user/user.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../__legacy__/helpers/react.helpers';

import { CurrentWorkspaceChangedEventType } from '../../workspaces/currentWorkspace/events/CurrentWorkspaceChangedEvent';
import { Home } from '../../../__legacy__/routes/config/Home';
import { Loader } from '../../../__legacy__/components/Loader';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { EvaluationsList } from '../components/EvaluationsList';
import { IEvaluationsService, EvaluationsServiceType } from '../evaluations.service';
import { IEvaluationListItem, EvaluationOwnership } from '../evaluations.store';
import { ConfirmationModalWithTextBox } from '../../../__legacy__/components/ConfirmationModalWithTextBox';
import { Trans, withNamespaces } from 'react-i18next';
import { ITranslatable } from '../../../__legacy__/helpers/translations.helpers';
import { S_TwoButtonModal } from '../../../design/modals/twoButtonModal/S_TwoButtonModal';
import { withPolling } from '../../../__legacy__/helpers/polling.helpers';
import { ModelPermissions } from '../../../__legacy__/modules/workspaces/workspaces.store';

interface IProps extends RouteComponentProps, ITranslatable {
  evaluationsService: IEvaluationsService;
  currentWorkspaceStore: ICurrentWorkspaceStore;
  overlayLoader: IOverlayLoaderStore;
  notificationService: INotificationsService;
  userService: IUserService;
  routerStore: IRouterStore;
  eventBus: IEventBus;
}

interface IState extends ILoaderState {
  showRestartModal: boolean;
  evaluationIdToRestart?: string;
  showStopModal: boolean;
  evaluationIdToStop?: string;
  showDeleteModal: boolean;
  evaluationToDelete?: IEvaluationListItem;
}

@injectProps({
  evaluationsService: EvaluationsServiceType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  overlayLoader: OverlayLoaderStoreType,
  notificationService: NotificationsServiceType,
  userService: UserServiceType,
  routerStore: RouterStoreType,
  eventBus: EventBusType,
})
@observer
class EvaluationsListContainer extends WithLoaderComponentBase<IProps, IState> {
  currentWorkspaceChangeListenerDisposer: EventListeningDisposer;
  clearPolling?: () => void;
  state: IState = {
    isLoading: false,
    showRestartModal: false,
    evaluationIdToRestart: undefined,
    showStopModal: false,
    evaluationIdToStop: undefined,
    showDeleteModal: false,
    evaluationToDelete: undefined,
  };

  constructor(props: IProps) {
    super(props);
    this.currentWorkspaceChangeListenerDisposer = props.eventBus.addListener(this.refreshAsync, CurrentWorkspaceChangedEventType);
  }

  componentDidMount() {
    if (!this.checkAccess()) {
      return;
    }

    this.refreshAsync();
  }

  componentWillUnmount() {
    if (this.clearPolling !== undefined) this.clearPolling();
    this.currentWorkspaceChangeListenerDisposer();
  }

  componentDidUpdate() {
    let ownership: EvaluationOwnership = EvaluationOwnership.All;
    const workspaceId = this.props.currentWorkspaceStore.currentWorkspace!.id;

    switch (this.props.location.pathname) {
      case Home.Evaluations.List.All.withParams({ workspaceId }):
        ownership = EvaluationOwnership.All;
        break;
      case Home.Evaluations.List.Owned.withParams({ workspaceId }):
        ownership = EvaluationOwnership.My;
        break;
      case Home.Evaluations.List.Others.withParams({ workspaceId }):
        ownership = EvaluationOwnership.Others;
        break;
    }

    this.props.evaluationsService.changeOwnershipFilter(ownership);
  }

  @autobind
  async handleOrderChange(orderBy: string, orderType: string) {
    this.props.evaluationsService.changeOrder(orderBy, orderType);
  }

  @autobind
  async handlePaginationChange(pageNumber: number, pageSize: number) {
    this.props.evaluationsService.changePagination(pageNumber, pageSize);
  }

  @autobind
  handleEvaluationRestart(evaluationId: string): void {
    this.setState(state => ({
      ...state,
      showRestartModal: true,
      evaluationIdToRestart: evaluationId,
    }));
  }

  @autobind
  handleEvaluationStop(evaluationId: string): void {
    this.setState(state => ({
      ...state,
      showStopModal: true,
      evaluationIdToStop: evaluationId,
    }));
  }

  @autobind
  handleEvaluationDelete(evaluation: IEvaluationListItem): void {
    this.setState(state => ({
      ...state,
      showDeleteModal: true,
      evaluationToDelete: evaluation,
    }));
  }

  @autobind
  async handleEvaluationRestartConfirmed(): Promise<void> {
    this.setState(state => ({ ...state, showRestartModal: false, evaluationIdToRestart: undefined }));
    await this.props.evaluationsService.restartEvaluationAsync(this.state.evaluationIdToRestart!);
  }

  @autobind
  async handleEvaluationStopConfirmed(): Promise<void> {
    this.setState(state => ({ ...state, showStopModal: false, evaluationIdToStop: undefined }));
    await this.props.evaluationsService.stopEvaluationAsync(this.state.evaluationIdToStop!);
  }

  @autobind
  async handleEvaluationDeleteConfirmed(): Promise<void> {
    this.setState(state => ({ ...state, showDeleteModal: false, evaluationToDelete: undefined }));
    await this.props.evaluationsService.deleteEvaluationAsync(this.state.evaluationToDelete!.id);
  }

  @autobind
  handleEvaluationRestartCanceled(): void {
    this.setState(state => ({ ...state, showRestartModal: false, evaluationIdToRestart: undefined }));
  }

  @autobind
  handleEvaluationStopCanceled(): void {
    this.setState(state => ({ ...state, showStopModal: false, evaluationIdToStop: undefined }));
  }

  @autobind
  handleEvaluationDeleteCanceled(): void {
    this.setState(state => ({ ...state, showDeleteModal: false, evaluationToDelete: undefined }));
  }

  checkAccess() {
    const canViewEvaluations =
      this.props.currentWorkspaceStore.isInRole(ModelPermissions.listInferences) && !this.props.currentWorkspaceStore.currentWorkspace?.encryption.encrypted;

    if (!canViewEvaluations) {
      this.props.routerStore.push(Home.Projects.List.All.withParams({ workspaceId: this.props.currentWorkspaceStore.currentWorkspace?.id! }));
      return false;
    }

    return true;
  }

  refreshAsync = async () => {
    if (this.clearPolling !== undefined) this.clearPolling();
    const workspaceId = this.props.currentWorkspaceStore.currentWorkspace?.id;

    if (!this.checkAccess() || !workspaceId) {
      return;
    }

    await Promise.all([this.props.evaluationsService.refreshAsync(), this.props.userService.getUserInfoAsync()]);
    this.clearPolling = withPolling(async () => {
      await this.props.evaluationsService.getEvaluationsAsync(workspaceId);
    }, 10000);
  };

  render() {
    const { t } = this.props;
    const confirmationEvaluationDeleteQuestion = (
      <Trans
        i18nKey="evaluations:delete_evaluation.confirmation"
        values={{ name: this.state.evaluationToDelete?.name || '' }}
        // tslint:disable-next-line:jsx-key
        components={[<strong>0</strong>]}
      />
    );

    const { store, downloadModelAsync } = this.props.evaluationsService;

    const { evaluationsList, evaluationsPaging } = store;

    const { pageNumber, pageSize, totalCount, orderType, orderBy } = evaluationsPaging;

    return (
      <Loader isLoading={this.props.overlayLoader.isSpinnerVisible('evaluations-list')} message={t('list_loader')} solid>
        <EvaluationsList
          workspaceId={this.props.currentWorkspaceStore.currentWorkspace!.id}
          evaluations={evaluationsList}
          pagination={{
            pageNumber,
            pageSize,
            totalCount,
            onChange: this.handlePaginationChange,
          }}
          sorting={{
            orderBy: orderBy || '',
            orderType: orderType || '',
          }}
          downloadModelAsync={downloadModelAsync}
          onRestartEvaluation={this.handleEvaluationRestart}
          onStopEvaluation={this.handleEvaluationStop}
          onDeleteEvaluation={this.handleEvaluationDelete}
          onOrderingChange={this.handleOrderChange}
        />
        <S_TwoButtonModal
          show={this.state.showRestartModal}
          headerText={t('restart_evaluation.text')}
          submitText={t('confirm')}
          cancelText={t('cancel')}
          onCancel={this.handleEvaluationRestartCanceled}
          onHide={this.handleEvaluationStopCanceled}
          onSubmit={this.handleEvaluationRestartConfirmed}
        >
          <p>{t('restart_evaluation.info')}</p>
        </S_TwoButtonModal>
        <S_TwoButtonModal
          show={this.state.showStopModal}
          headerText={t('stop_evaluation.text')}
          submitText={t('confirm')}
          cancelText={t('cancel')}
          onCancel={this.handleEvaluationStopCanceled}
          onHide={this.handleEvaluationStopCanceled}
          onSubmit={this.handleEvaluationStopConfirmed}
        >
          <p>{t('stop_evaluation.info')}</p>
        </S_TwoButtonModal>
        <ConfirmationModalWithTextBox
          showModal={this.state.showDeleteModal}
          confirmationHeader={t('delete_evaluation.text')}
          confirmationQuestion={t('delete_evaluation.info')}
          confirmationTextHeader={confirmationEvaluationDeleteQuestion}
          confirmationText={this.state.evaluationToDelete?.name || ''}
          onCancel={this.handleEvaluationDeleteCanceled}
          onConfirm={this.handleEvaluationDeleteConfirmed}
          confirmationYes={t('delete')}
        />
      </Loader>
    );
  }
}

export default as<React.ComponentClass>(withNamespaces('evaluations')(withRouter(EvaluationsListContainer)));
