import React from 'react';
import { observer } from 'mobx-react';

interface IProps {
  hasAccess: boolean;
}

@observer
export class RestrictAccessTo2 extends React.Component<IProps> {
  render() {
    return (
      this.props.hasAccess
        ? this.props.children
        : null);
  }
}
