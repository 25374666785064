import * as React from 'react';
import { as } from '../../../../__legacy__/helpers/react.helpers';
import { GTable } from '../../../../__legacy__/components/table/GTable';
import { toLocaleDateTimeString } from '../../../../__legacy__/helpers/date.helpers';
import { IModelDataset } from '../../modelDetails.store';
import { MODEL_STATUS } from '../../../models/models.model';
import { withNamespaces } from 'react-i18next';
import { ITranslatable } from '../../../../__legacy__/helpers/translations.helpers';

interface IProps {
  datasets: IModelDataset[];
  datasetIds: string[];
  status: MODEL_STATUS;
  changeDatasetSelection(e: React.ChangeEvent<HTMLInputElement>): void;
  changeAllDatasetsSelection(): void;
  isFormDisabled: boolean
}

const ModelDatasetsListTablePure = (props: IProps & ITranslatable) => {
  const { t,isFormDisabled, datasets, datasetIds, status, changeDatasetSelection, changeAllDatasetsSelection } = props;

  const isDisabled = isFormDisabled || (status !== MODEL_STATUS.DRAFT && status !== MODEL_STATUS.STOPPED && status !== MODEL_STATUS.FAILED)

  const columns = React.useMemo(() => {
    return [
      {
        field: '',
        headerName: '',
        cellClass: 'd-flex align-items-center',
        renderer: (item: IModelDataset) => (
          <>
            <input
              className="checkbox-primary"
              type="checkbox"
              checked={datasetIds.includes(item.id)}
              value={item.id}
              onChange={changeDatasetSelection}
              disabled={isDisabled}
            />
          </>
        ),
        headerRenderer: () => (
          <>
            <input
              className="checkbox-primary"
              type="checkbox"
              disabled={isDisabled}
              checked={datasets.length === datasetIds.length && datasets.length > 0}
              onChange={changeAllDatasetsSelection}
            />
          </>
        ),
        sortable: false,
        width: 50,
      },
      {
        field: 'name',
        headerName: 'Name',
        sortable: true,
        width: 300,
      },
      {
        field: 'description',
        headerName: 'Description',
        sortable: true,
        minWidth: 300,
      },
      {
        field: 'imagesCount',
        headerName: 'Images',
        sortable: true,
        width: 200,
      },
      {
        field: 'Images',
        headerName: 'Split',
        renderer: ({ testImagesCount, trainImagesCount, validationImagesCount }: IModelDataset) => (
          <>
            {trainImagesCount}/{validationImagesCount}/{testImagesCount}
          </>
        ),
        sortable: true,
        width: 200,
      },
      {
        field: 'created',
        headerName: 'Created',
        renderer: (item: IModelDataset) => <>{toLocaleDateTimeString(item.createdDate)}</>,
        sortable: true,
        width: 200,
      },
    ];
  }, [datasets, datasetIds]);

  return (
    <div>
      <GTable columns={columns} items={datasets} noItemsPlaceholder={t('no_datasets_in_project')} />
    </div>
  );
};

export const ModelDatasetsListTable = as<React.FC<IProps>>(withNamespaces('models')(ModelDatasetsListTablePure));
