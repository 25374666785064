// tslint:disable:function-name
// tslint:disable:variable-name

import 'leaflet-draw';

import { DRAWING_STATES, MOUSE_BUTTON, MOUSE_EVENTS, MouseLeafletEvent } from '../../annotations.interface';
import leaflet, { DrawEvents, DrawOptions, LatLng, LeafletEvent, LeafletEventHandlerFn, Map } from 'leaflet';

import { AddRectangleVertexCommand } from '../../undoRedoCommands/undoRedoCommands';
import { Feature } from 'geojson';
import { IUndoRedoHistory } from '../../undoRedoHistory.service';
import area from '@turf/area';
import autobind from 'autobind-decorator';
import { getSimplifiedGeoJSON } from '../../../../helpers/geometry/polygon.helpers';

@autobind
export class DrawRectangle extends leaflet.Draw.Rectangle implements IDrawer {
  private _startLatLng?: LatLng;
  private _isCurrentlyTwoClickDrawing?: boolean;
  readonly _map!: Map;

  private drawState: DRAWING_STATES = DRAWING_STATES.NOT_DRAWING;
  private readonly onDrawCreated: (geojson: Feature) => void;
  private readonly undoRedoHistory: IUndoRedoHistory;
  public lastMouseMoveEvent: any;

  constructor(
    map: Map,
    onDrawCreated: (geojson: Feature) => void,
    undoRedoHistory: IUndoRedoHistory,
    options?: DrawOptions.RectangleOptions,
  ) {
    super(map, { ...options, repeatMode: true });
    this.onDrawCreated = onDrawCreated;
    this.undoRedoHistory = undoRedoHistory;
  }

  public enable() {
    super.enable();
    this._isDrawing = true;
    this._startLatLng = undefined;
    return this;
  }

  public disable() {
    super.disable();
    this._startLatLng = undefined;
    this._isDrawing = true;
    this.undoRedoHistory.clearDrawingHistory();
    return this;
  }

  public disableMouseMarkerFocus() {}

  public addHandlers() {
    this._map.on(MOUSE_EVENTS.MOUSE_DOWN, this.handleMouseDown as LeafletEventHandlerFn);
    this._map.on(MOUSE_EVENTS.MOUSE_UP, this.handleMouseUp as LeafletEventHandlerFn);

    this._map.on(leaflet.Draw.Event.DRAWSTOP, this.handleDrawStop);
    this._map.on(leaflet.Draw.Event.CREATED, this.handleDrawCreated as LeafletEventHandlerFn);
  }

  public removeHandlers() {
    this._map.off(MOUSE_EVENTS.MOUSE_DOWN, this.handleMouseDown as LeafletEventHandlerFn);
    this._map.off(MOUSE_EVENTS.MOUSE_UP, this.handleMouseUp as LeafletEventHandlerFn);

    this._map.off(leaflet.Draw.Event.DRAWSTOP, this.handleDrawStop);
    this._map.off(leaflet.Draw.Event.CREATED, this.handleDrawCreated as LeafletEventHandlerFn);
  }

  private handleMouseUp(e: MouseLeafletEvent) {
    if (e.originalEvent.button !== MOUSE_BUTTON.LEFT) return;
    switch (this.drawState) {
      case DRAWING_STATES.DRAWING_BY_DRAGGING:
        this.addVertexWrapper(e.latlng);
        this.handleDrawStop();
        break;
    }
  }

  private handleMouseDown(e: MouseLeafletEvent) {
    if (e.originalEvent.button !== MOUSE_BUTTON.LEFT) return;
    this.addVertexWrapper(e.latlng);
    this.drawState = DRAWING_STATES.DRAWING_BY_DRAGGING;
  }

  private handleDrawStop() {
    this.drawState = DRAWING_STATES.NOT_DRAWING;
  }

  private addVertexWrapper(latlng: LatLng) {
    if (!this._isCurrentlyTwoClickDrawing) {
      this.undoRedoHistory.addCommand(new AddRectangleVertexCommand(this, latlng));
    }

    this.addVertex(latlng);
  }

  public addVertex(latlng: LatLng) {
    if (this._shape) {
      this._fireCreatedEvent();
    }
    if (this._startLatLng) return;
    this._isCurrentlyTwoClickDrawing = true;
    this._isDrawing = true;
    this._startLatLng = latlng;
  }

  _onMouseMove(e: any) {
    this.lastMouseMoveEvent = e;
    if (!this._isCurrentlyTwoClickDrawing) return;
    leaflet.Draw.SimpleShape.prototype._onMouseMove.call(this, e);
  }

  handleDrawCreated(e: DrawEvents.Created & LeafletEvent): void {
    const simplifiedGeojson = getSimplifiedGeoJSON(e.layer);
    if (!simplifiedGeojson) return;
    if (area(simplifiedGeojson) < 1) {
      return;
    }
    this.onDrawCreated(simplifiedGeojson);
  }

  // FIXES some bugs with contextmenu click or different click behavior in browsers
  // See S20-120, S20-133 bugs on JIRA
  _onTouch() {}
  _onMouseDown() {}
  _onMouseUp() {}
}
