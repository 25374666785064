import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../../../workspaces/currentWorkspace/CurrentWorkspace.store';
import { IProjectStatsFiltersBl, ProjectStatsFiltersBlType } from '../../ProjectStatsFilters.bl';
import { IProjectStatsFiltersStore, ProjectStatsFiltersStoreType } from '../../ProjectStatsFilters.store';
import { S_TimePeriodSelector, TimePeriod } from '../../../../../../../design/selects/timePeriodSelector/S_TimePeriodSelector';
import { as, injectProps } from '../../../../../../../__legacy__/helpers/react.helpers';

import { DateRangePicker } from './datePickers/DateRangePicker';
import { DayPicker } from './datePickers/DayPicker';
import { MonthPicker } from './datePickers/monthPicker/MonthPicker';
import React from 'react';
import { WeekPicker } from './datePickers/weekPicker/WeekPicker';
import moment from 'moment';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: IProjectStatsFiltersBl;
  store: IProjectStatsFiltersStore;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

@injectProps({
  bl: ProjectStatsFiltersBlType,
  store: ProjectStatsFiltersStoreType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
@observer
class TimePeriodSelectorContainerPure extends React.Component<InjectedProps> {
  handleClickNext = async () => {
    await this.props.bl.goToNextDateAsync();
  };

  handleClickPrevious = async () => {
    await this.props.bl.goToPreviousDateAsync();
  };

  handleTimePeriodChange = async (timePeriod: TimePeriod) => {
    await this.props.bl.selectTimePeriodAsync(timePeriod);
  };

  handleSetDate = async (date: Date) => {
    await this.props.bl.selectStartDateAsync(date);
  };

  handleSetDates = async (startDate: Date, endDate: Date) => {
    await this.props.bl.selectDatesAsync(startDate, endDate);
  };

  getMinDate = (): Date => {
    const projectCreatedDate = this.props.store.projectCreatedDate.clone();

    let minDate = new Date();
    switch (this.props.store.timePeriod) {
      case TimePeriod.Day:
      case TimePeriod.DateRange:
        minDate = new Date(projectCreatedDate.startOf('day').utcOffset(0, true).format());
        break;

      case TimePeriod.Week:
        minDate = new Date(projectCreatedDate.startOf('week').add(1, 'day').utcOffset(0, true).format());
        break;

      case TimePeriod.Month:
        minDate = new Date(projectCreatedDate.startOf('month').utcOffset(0, true).format());
        break;
    }

    minDate.setMinutes(minDate.getMinutes() + minDate.getTimezoneOffset());
    return minDate;
  };

  getMaxDate = (): Date => {
    const maxDateMoment = moment(this.props.currentWorkspaceStore.currentWorkspaceTime).utcOffset(0, true);

    if (this.props.store.timePeriod === TimePeriod.Week) maxDateMoment.endOf('week').add(1, 'day');
    const maxDate = new Date(maxDateMoment.format());
    maxDate.setMinutes(maxDate.getMinutes() + maxDate.getTimezoneOffset());
    return maxDate;
  };

  renderTimePicker = (): React.ReactNode => {
    const selectedStartDate = new Date(this.props.store.startDate.format());
    selectedStartDate.setMinutes(selectedStartDate.getMinutes() + selectedStartDate.getTimezoneOffset());

    const selectedEndDate = new Date(this.props.store.endDate.format());
    selectedEndDate.setMinutes(selectedEndDate.getMinutes() + selectedEndDate.getTimezoneOffset());

    const minDate = this.getMinDate();
    const maxDate = this.getMaxDate();
    switch (this.props.store.timePeriod) {
      case TimePeriod.Day:
        return (
          <DayPicker
            selectedDate={selectedStartDate}
            onSetDate={this.handleSetDate}
            minDate={minDate}
            maxDate={maxDate}
            onClickNext={this.handleClickNext}
            onClickPrevious={this.handleClickPrevious}
            canClickNext={this.props.bl.canGoToNextDate()}
            canClickPrevious={this.props.bl.canGoToPreviousDate()}
          />
        );

      case TimePeriod.Month:
        return (
          <MonthPicker
            selectedDate={selectedStartDate}
            onSetDate={this.handleSetDate}
            minDate={minDate}
            maxDate={maxDate}
            onClickNext={this.handleClickNext}
            onClickPrevious={this.handleClickPrevious}
            canClickNext={this.props.bl.canGoToNextDate()}
            canClickPrevious={this.props.bl.canGoToPreviousDate()}
          />
        );

      case TimePeriod.DateRange:
        return <DateRangePicker selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} onSetDates={this.handleSetDates} minDate={minDate} maxDate={maxDate} />;

      case TimePeriod.Week:
        return (
          <WeekPicker
            selectedDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            onSetDate={this.handleSetDate}
            minDate={minDate}
            maxDate={maxDate}
            onClickNext={this.handleClickNext}
            onClickPrevious={this.handleClickPrevious}
            canClickNext={this.props.bl.canGoToNextDate()}
            canClickPrevious={this.props.bl.canGoToPreviousDate()}
          />
        );

      default:
        return <div className="date-range-field" />;
    }
  };

  render(): React.ReactNode {
    return (
      <>
        <S_TimePeriodSelector selectedTimePeriod={this.props.store.timePeriod} onPeriodChange={this.handleTimePeriodChange} includeCustomRangeOption />
        {this.renderTimePicker()}
      </>
    );
  }
}

export const TimePeriodSelectorContainer = as<React.ComponentClass>(TimePeriodSelectorContainerPure);
