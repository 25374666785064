import { AsyncQueue } from 'async';
import { CancelTokenSource } from 'axios';
import { injectable } from 'inversify';
import { observable } from 'mobx';

export const AttachmentUploadStoreType = Symbol('ATTACHMENT_UPLOAD_STORE');

export interface IAttachmentUploadData {
  id: string;
  workspaceId: string;
  parentId: string | undefined;
  file: File;
}

export interface IAttachmentUploadProgressData {
  progress: number;
  id: string;
  name: string;
  isSuccess: boolean;
}

export enum UploadStatus {
  Valid = 'valid',
  InvalidSize = 'invalid_size',
  DuplicatedName = 'duplicated_file_name',
}

export interface IPreparedFile {
  file: File;
  status: UploadStatus;
  workspaceId: string;
  parentId: string | undefined;
}

export interface IWillAttachmentUploadExceedsLimitDto {
  willExceedsAvailableSpace: boolean;
  ownerPlan: string;
  areAllSuccessful: boolean;
}

export interface ICurrentUpload {
  id: string;
  cancelToken: CancelTokenSource;
}

export interface ICurrentDownloadAttachment {
  id: string;
  isBatch: boolean;
}

export interface IAttachmentUploadStore {
  progressData: IAttachmentUploadProgressData[];
  preparedFiles: IPreparedFile[];
  willExceedsLimit: IWillAttachmentUploadExceedsLimitDto;
  currentUploads: ICurrentUpload[];
  uploadQueue: AsyncQueue<IAttachmentUploadData>;
  currentDownloadAttachment: ICurrentDownloadAttachment[];
}

@injectable()
export class AttachmentUploadStore implements IAttachmentUploadStore {

  @observable
  progressData: IAttachmentUploadProgressData[] = [];

  @observable
  preparedFiles: IPreparedFile[] = [];

  @observable
  willExceedsLimit: IWillAttachmentUploadExceedsLimitDto = {
    areAllSuccessful: true,
    ownerPlan: '',
    willExceedsAvailableSpace: false,
  };

  @observable
  currentDownloadAttachment: ICurrentDownloadAttachment[] = [];

  @observable
  currentUploads: ICurrentUpload[] = [];

  uploadQueue!: AsyncQueue<IAttachmentUploadData>;
}
