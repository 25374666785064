import { ValueType } from 'react-select/lib/types';

export function handleChange(callback?: (s: string) => void) {
  return function (e: React.ChangeEvent<HTMLInputElement>) {
    if (callback) callback(e.target.value);
  };
}

export const handleClickAndPassData = (callback: any) => (data: any) => (e: any) => {
  if (e.stopPropagation) e.stopPropagation();

  callback(data, e);
};

export const handleCheck = (cb: (value: boolean) => void) => (e: React.FormEvent<HTMLInputElement>) => cb(e.currentTarget.checked);

export interface ISelectOption<T, P = {}> {
  value: T;
  label: string;
  additionalData?: P;
}

export function handleSelectChange<T>(callback: (data: T) => void) {
  return function (option: ValueType<ISelectOption<T>>) {
    const selected = option as ISelectOption<T>;
    callback(selected?.value);
  };
}

export function handleMultiSelectChange<T>(callback: (data: T[]) => void) {
  return function (options: ValueType<ISelectOption<T>>) {
    const selected = options as ISelectOption<T>[];
    callback(selected?.map(x => x.value) || []);
  };
}

export function handleNumberChange(callback: (data: number) => void) {
  return function (text: string) {
    callback(parseInt(text.replace(/[^0-9]/g, ''), 10));
  };
}

export const nonZeroIntegerRegex = /^[-]?[1-9]\d*$/g;

export function handleNonZeroIntegerChange(callback: (data: string) => void) {
  return function (text: string) {
    if (text.match(nonZeroIntegerRegex) || text === '' || text === '-') callback(text);
  };
}

/// Valid windows file name, linux is less restrictive
export const folderNameInvalidCharactersRegex = /^(?!\.)(?!com[0-9]$)(?!con$)(?!lpt[0-9]$)(?!nul$)(?!prn$)[^\|\*\?\\:<>/$"]*[^\.\|\*\?\\:<>/$"]*$/;

export function handleFolderNameChange(callback: (data: string) => void) {
  return function (text: string) {
    if (text.match(folderNameInvalidCharactersRegex)) callback(text);
  };
}
