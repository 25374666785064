import * as React from 'react';

import { Button, Card, CardBody, Col, Row } from 'reactstrap';

import { Home } from '../../../routes/config/Home';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { Link } from 'react-router-dom';
import { NoImageReason } from '../annotations.interface';
import { as } from '../../../helpers/react.helpers';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';

library.add(faCheckCircle, faCircle);

interface INoImagePlaceholderProps {
  onRefresh(): void;
  workspaceId: string;
  noImageReason: NoImageReason;
  mode: 'Review' | 'Annotate';
}

class NoImagePlaceholderPure extends React.Component<INoImagePlaceholderProps & ITranslatable>
{
  getTranslationKey() {
    switch (this.props.noImageReason) {
      case NoImageReason.Credits:
        return 'no_more_credits_available';
      case NoImageReason.NoMoreImages:
        return this.props.mode === 'Annotate' ? 'no_more_images_to_annotate' : 'no_more_images_to_review';
      case NoImageReason.Locked:
        return this.props.mode === 'Annotate' ? 'not_annotated_images_are_locked' : 'not_reviewed_images_are_locked';
      default:
        return 'no_more_images_in_queue';
    }
  }

  render() {
    return (
      <div className="no-image-ph" >
        <div className="center">
          <Col xl="12">
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h5>{this.props.t(this.getTranslationKey())}</h5>
                  </Col>
                </Row>
                <Row className="btn-content">
                  <Col lg="12" xl="6">
                    <Button color="link" onClick={this.props.onRefresh}>
                      {this.props.t('refresh_view')}
                    </Button>
                  </Col>
                  <Col lg="12" xl="6">
                    <Link className="btn btn-primary btn-outline-primary" to={Home.Projects.List.All.withParams({ workspaceId: this.props.workspaceId })}>
                      {this.props.t('ok_move_to_dashboard')}
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    );
  }
}

export const NoImagePlaceholder = as<React.ComponentClass<INoImagePlaceholderProps>>(withNamespaces('common', { wait: true })(NoImagePlaceholderPure));
