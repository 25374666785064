import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { S_ButtonProps } from '../S_ButtonProps';
import { S_TitleProps } from '../../CommonProps';

interface S_IconButtonProps extends S_ButtonProps, S_TitleProps{
  icon: IconProp;
  iconClassName?: string;
}

export const S_IconButton = (props: S_IconButtonProps) => (
  <button className={`btn btn-icon ${props.className} ${props.disabled ? 'icon-disabled' : ''}`} onClick={props.onClick} title={props.title} disabled={props.disabled}>
    <FontAwesomeIcon className={props.iconClassName} icon={props.icon} />
  </button>
);
