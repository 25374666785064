import * as React from 'react';

import { CryptoServiceType, ICryptoService } from '../../../services/crypto.service';
import { EventBusType, IEventBus } from '../../../services/eventBus.service';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { IRouterStore, RouterStoreType } from '../../../stores/router.store';
import { IUserStore, Plan, UserStoreType } from '../../user/user.store';
import { IWorkspaceService, WorkspaceServiceType } from '../workspaces.service';
import { InputStatus, StickerError } from '../../../models/error.model';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { CreateWorkspaceButton } from '../components/CreateWorkspaceButton';
import { CreateWorkspaceModal } from '../components/CreateWorkspaceModal';
import { KeyDownloadModal } from '../components/KeyDownloadModal';
import { WorkspaceCreatedEvent } from '../events/workspaceCreatedEvent';
import autobind from 'autobind-decorator';
import { currentTimeZone } from '../../../models/timeZones/timeZones.model';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ workspaceId?: string }> {
  workspaceService: IWorkspaceService;
  cryptoService: ICryptoService;
  userStore: IUserStore;
  router: IRouterStore;
  eventBus: IEventBus;
}

interface IState extends ILoaderState {
  newWorkspaceName: string;
  newWorkspaceInputStatus: InputStatus;
  newWorkspaceModalShown: boolean;
  newWorkspaceEncryption: boolean;
  keyDownloadModalShown: boolean;
  encryptionKeyUrl?: string;
  newWorkspaceId?: string;
  newWorkspaceTimeZone: number;
}

@injectProps({
  workspaceService: WorkspaceServiceType,
  cryptoService: CryptoServiceType,
  userStore: UserStoreType,
  router: RouterStoreType,
  eventBus: EventBusType,
})
@observer
class CreateWorkspaceContainerPure extends WithLoaderComponentBase<IInjectedProps, IState> {
  private readonly initState: IState = {
    newWorkspaceModalShown: false,
    newWorkspaceName: '',
    newWorkspaceInputStatus: InputStatus.empty(),
    newWorkspaceEncryption: false,
    keyDownloadModalShown: false,
    newWorkspaceTimeZone: currentTimeZone ? currentTimeZone.value : 0,
    isLoading: false,
  };

  constructor(props: IInjectedProps) {
    super(props);

    this.state = this.initState;
  }

  @autobind
  private toggleCreateWorkspaceModal() {
    if (this.state.isLoading) return;
    this.setState((prevState: IState) => ({ ...this.initState, newWorkspaceModalShown: !prevState.newWorkspaceModalShown }));
  }

  @autobind
  private toggleKeyDownload() {
    this.setState(
      (prevState: IState) => ({ ...prevState, keyDownloadModalShown: !prevState.keyDownloadModalShown }),
      () => {
        if (this.state.newWorkspaceId) {
          this.notifyThatWorkspaceWasCreated(this.state.newWorkspaceId);
        }
      },
    );
  }

  @autobind
  private handleNewWorkspaceNameChange(value: string) {
    this.setState({
      newWorkspaceName: value,
      newWorkspaceInputStatus: this.validateWorkspaceName(value),
    });
  }

  @autobind
  private handleEncryptionChange() {
    this.setState((state: IState) => ({ ...state, newWorkspaceEncryption: !state.newWorkspaceEncryption }));
  }

  @autobind
  private handleTimeZoneChange(newWorkspaceTimeZone: number) {
    this.setState({ newWorkspaceTimeZone });
  }

  @autobind
  async handleSubmitCreate() {
    if (this.state.isLoading) return;

    await this.withLoaderAsync(async () => {
      if (this.state.newWorkspaceInputStatus.isValid !== true) return;
      const result = await this.props.workspaceService.createWorkspaceAsync(this.state.newWorkspaceName, this.state.newWorkspaceEncryption, this.state.newWorkspaceTimeZone);

      if (result instanceof StickerError) {
        if (result.isBadRequestWithCode(['CAN_NOT_CREATE_MORE_WORKSPACES'])) {
          this.props.workspaceService.store.canAddWorkspace = false;
          return;
        }

        this.setState(
          (prevState: IState): IState => ({
            ...prevState,
            newWorkspaceInputStatus: InputStatus.buildFrom(result.apiErrorResponse!.errorCodes),
          }),
        );
      } else {
        this.setState(
          (prevState: IState): IState => ({
            ...prevState,
            newWorkspaceInputStatus: InputStatus.empty(),
            newWorkspaceModalShown: false,
            newWorkspaceId: result,
          }),
          async () => {
            if (this.state.newWorkspaceEncryption && this.state.newWorkspaceId) {
              const encryptionKeyUrl = await this.props.cryptoService.exportKey(this.state.newWorkspaceId);
              this.setState({ encryptionKeyUrl, keyDownloadModalShown: true });
            } else {
              if (this.state.newWorkspaceId) {
                this.notifyThatWorkspaceWasCreated(this.state.newWorkspaceId);
              }
            }
          },
        );
      }
    }, 'create-workspace-button');
  }

  private validateWorkspaceName(value: string): InputStatus {
    const errorCodes: string[] = [];

    if (value.trim() === '') {
      errorCodes.push('field_cant_be_empty');
    }

    const isNotUnique = this.props.workspaceService.store.workspaces.map(w => w.name.toLocaleUpperCase()).includes(value.trim().toLocaleUpperCase());

    if (isNotUnique) {
      errorCodes.push('DUPLICATE_WORKSPACE_NAME');
    }

    return InputStatus.buildFrom(errorCodes);
  }
  private notifyThatWorkspaceWasCreated(workspaceId: string) {
    this.props.eventBus.sendEvent(new WorkspaceCreatedEvent(workspaceId));
  }

  render() {
    const { plan } = this.props.userStore.planInfo;

    return (
      <>
        <CreateWorkspaceButton onCreateButtonClicked={this.toggleCreateWorkspaceModal} />
        <CreateWorkspaceModal
          plan={plan}
          showCreateWorkspace={this.state.newWorkspaceModalShown}
          newWorkspaceName={this.state.newWorkspaceName}
          newWorkspaceNameStatus={this.state.newWorkspaceInputStatus}
          newWorkspaceEncryption={this.state.newWorkspaceEncryption}
          newWorkspaceTimeZone={this.state.newWorkspaceTimeZone}
          onCloseModal={this.toggleCreateWorkspaceModal}
          onCreateNameChange={this.handleNewWorkspaceNameChange}
          onEncryptionChange={this.handleEncryptionChange}
          onTimeZoneChanged={this.handleTimeZoneChange}
          onSubmitCreate={this.handleSubmitCreate}
          canAddWorkspace={this.props.workspaceService.store.canAddWorkspace}
          canEncrypt={plan === Plan.Enterprise || plan === Plan.Admin}
        />
        <KeyDownloadModal
          newWorkspaceName={this.state.newWorkspaceName}
          showKeyDownload={this.state.keyDownloadModalShown}
          encryptionKeyUrl={this.state.encryptionKeyUrl || ''}
          toggleKeyDownload={this.toggleKeyDownload}
        />
      </>
    );
  }
}

export const CreateWorkspaceContainer = as<React.ComponentClass>(withRouter(CreateWorkspaceContainerPure));
