import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../helpers/translations.helpers';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { Link } from '../containers/Link';
import { as } from '../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  id?: string;
  name: string;
  icon: IconName;
  link: string;
  highlightLink: string;
  disabled: boolean;
  beforeClick?: Function;
}

class SideNavMenuItemPure extends React.Component<IProps & RouteComponentProps & ITranslatable> {
  render() {
    const isActive = this.props.match.path.includes(this.props.highlightLink);
    const isDisabled = this.props.disabled;
    return (
      <li
        id={this.props.id}
        className={`nav-list-item ${isActive ? 'active' : 'passive'} ${isDisabled ? 'disabled' : ''}`}
        title={this.props.disabled ? this.props.t('you_do_not_have_permission_to_view_this_page') : ''}
      >
        <Link to={`${this.props.link}`} disabled={this.props.disabled} beforeClick={this.props.beforeClick}>
          <FontAwesomeIcon icon={this.props.icon} />
          <p>{this.props.name}</p>
        </Link>
      </li>
    );

  }
}

export const SideNavMenuItem = as<React.ComponentClass<IProps>>(withRouter(withNamespaces('common', { wait: true })(SideNavMenuItemPure)));
