import { Bar } from 'react-chartjs-2';
import { CustomScrollbar } from '../../../../../components/CustomScrollbar';
import { IAnnotationStatusStatisticsDto } from '../projectDetailsStatistics.model';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../../../helpers/react.helpers';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

library.add(faChartBar);

interface IAnnotationProgressChartProps {
  title: string;
  annotationStatuses?: IAnnotationStatusStatisticsDto[];
  translateNames?: boolean;
}

@observer
class AllStatusChartPure extends React.Component<IAnnotationProgressChartProps & ITranslatable> {
  render() {
    const { t, annotationStatuses, title } = { ...this.props };

    if (!annotationStatuses) return <></>;

    const sorted = annotationStatuses.slice();

    const attributeData = {
      labels: sorted.map(a => (this.props.translateNames ? this.props.t(a.name) : a.name)),
      datasets: [
        {
          label: t('awaiting_review'),
          data: sorted.map(a => a.toReview),
          backgroundColor: '#00478d',
          hoverBackgroundColor: '#00478d',
        },
        {
          label: t('accepted'),
          data: sorted.map(a => a.accepted),
          backgroundColor: '#70D499',
          hoverBackgroundColor: '#70D499',
        },
        {
          label: t('rejected'),
          data: sorted.map(a => a.rejected),
          backgroundColor: '#db5230',
          hoverBackgroundColor: '#db5230',
        },
      ],
    };

    return (
      <div className="statistic-wrapper">
        <h5>{t(title)}</h5>
        <div className="statistics-body">
          <div className="statistics-summary">
            <CustomScrollbar autoHide>
              <div className="list">
                {sorted.map(a => (
                  <span key={a.id}>
                    {this.props.translateNames ? this.props.t(a.name) : a.name}: {a.accepted + a.toReview + a.rejected}
                  </span>
                ))}
              </div>
            </CustomScrollbar>
          </div>
          <div className="chart-wrapper">
            <div className="statistics-chart wide">
              <Bar
                data={attributeData}
                options={{
                  maintainAspectRatio: false,
                  legend: {
                    display: true,
                    position: 'right',
                    align: 'center',
                  },
                  tooltips: {
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                      label(tooltipItem: any, data: any) {
                        return `${data.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.yLabel}`;
                      },
                    },
                  },
                  scales: {
                    xAxes: [
                      {
                        stacked: true,
                        gridLines: { display: false },
                      },
                    ],
                    yAxes: [
                      {
                        stacked: true,
                        ticks: {
                          beginAtZero: true,
                          suggestedMin: 0,
                        },
                      },
                    ],
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const AllStatusChart = as<React.ComponentClass<IAnnotationProgressChartProps>>(withNamespaces('common', { wait: true })(AllStatusChartPure));
