import { SliderWithLabel } from './SliderWithLabel';
import React from 'react';
import { as } from '../../helpers/react.helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../helpers/translations.helpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  handleThresholdChange(value: number): void;
  threshold: number;
}

const PredictionImagesSettingsPure = (props: IProps & ITranslatable) => {
  return (
    <div className="predictions-view">
        <div className="header">
          <FontAwesomeIcon icon="cog" /> {props.t('parameters')}
        </div>
        <div className="predictions-view-inner">
          <SliderWithLabel label={props.t('threshold')} min={0} max={255} step={1} value={props.threshold} onChange={props.handleThresholdChange} fullWidth mbNone/>
        </div>
  </div>
  )
}


export const PredictionImagesSettings = as<React.FC<IProps>>(withNamespaces('evaluations')(PredictionImagesSettingsPure))