import { AddMarkingToolBl, AddMarkingToolBlType } from '../../AddMarkingTool.bl';
import { AddMarkingToolStore, AddMarkingToolStoreType } from '../../AddMarkingTool.store';
import { as, injectProps } from '../../../../../../../../__legacy__/helpers/react.helpers';

import { NameField } from '../../../commons/ui/fields/NameField';
import React from 'react';
import { observer } from 'mobx-react';

interface InjectedProps {
  bl: AddMarkingToolBl;
  store: AddMarkingToolStore;
}

@injectProps({
  bl: AddMarkingToolBlType,
  store: AddMarkingToolStoreType,
})
@observer
class NameFieldContainerPure extends React.Component<InjectedProps> {
  render() {
    return <NameField changeName={this.props.bl.changeName} name={this.props.store.name} nameStatus={this.props.store.nameStatus} />;
  }
}

export const NameFieldContainer = as<React.ComponentClass>(NameFieldContainerPure);
