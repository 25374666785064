import * as React from 'react';

import { Card, CardBody } from 'reactstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { ITranslatable } from '../../../helpers/translations.helpers';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  text: string;
  textWithLink: string;
  link: string;
  withQueryString?: boolean;
}

const AuthLinkCardTranslated = withNamespaces('auth')(
  (props: IProps & ITranslatable & RouteComponentProps) => {
    const query = props.withQueryString && props.location.search !== '' ? `/${props.location.search}` : '';
    function handleMouseDown(event: any) {
      event.preventDefault();
    }
    return (
      <div className="auth-link-card">
        <Card>
          <CardBody>
            <p>
              {props.t(props.text)}
              <Link
                onMouseDown={handleMouseDown}
                to={`${props.link}${query}`}
              >
                {props.t(props.textWithLink)}
              </Link>
            </p>
          </CardBody>
        </Card>
      </div>
    );
  },
);

export const AuthLinkCard = as<React.SFC<IProps>>(withRouter(AuthLinkCardTranslated));
