import { MODEL_VARIANTS } from '../../__legacy__/models/metrics.model';

export enum EVALUATION_STATUS {
  UNKNOWN = 'Unknown',
  DRAFT = 'Draft',
  FAILED = 'Failed',
  FAILING = 'Failing',
  FINISHED = 'Finished',
  IMPORTED = 'Imported',
  STARTING = 'Starting',
  RUNNING = 'Running',
  STOPPING = 'Stopping',
  STOPPED = 'Stopped',
}

export interface IEvaluationListDto {
  inference_name: string;
  inference_at: string;
  inference_job_id: string;
  model_name: string;
  model_variant: MODEL_VARIANTS;
  project_name: string;
  score: number | null;
  status: EVALUATION_STATUS;
  starting_progress: '1/4' | '2/4' | '3/4' | null;
  training_job_id: string;
  user_id: string;
  is_owner: boolean;
  is_training_owner: boolean;
  failure_reason: string | null;
}
