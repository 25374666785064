import { CreateDatasetView } from '../../views/home/datasets/Create.view';
import { DatasetDetailsView } from '../../views/home/datasetsDetails/DatasetDetails.view';
import { DatasetsListView } from '../../views/home/datasets/List.view';
import { Home } from '../config/Home';
import { NotFoundView } from '../../views/NotFound.view';
import React from 'react';
import { Route } from 'react-router';
import { SwitchWithNotFound } from '../../containers/route.containers';

export const DatasetsRoute = () => (
  <SwitchWithNotFound notFound={NotFoundView}>
    <Route path={Home.Datasets.List.Path} component={DatasetsListView} />
    <Route path={Home.Datasets.Create.Path} component={CreateDatasetView} />
    <Route path={Home.Datasets.Details.Path} component={DatasetDetailsView} />
  </SwitchWithNotFound>
);
