import * as React from 'react';
import { as, injectProps } from '../../../../__legacy__/helpers/react.helpers';
import { observer } from 'mobx-react';
import autobind from 'autobind-decorator';
import { AuthStoreType, IAuthStore } from '../../../../__legacy__/modules/auth/auth.store';
import { IOverlayLoaderStore, OverlayLoaderStoreType } from '../../../common/OverlayLoader.store';
import { Settings } from '../../../settings/SettingsContext';
import { EvaluationDetailsServiceType, IEvaluationDetailsService } from '../../evaluationDetails.service';
import { predictionImagesWithTranslation } from '../../../../__legacy__/components/predictionImages/PredictionImages';
import { EVALUATION_STATUS } from '../../../evaluations/evaluations.model';
import { Home } from '../../../../__legacy__/routes/config/Home';
import { RouteComponentProps, matchPath, withRouter } from 'react-router-dom';

const PredictionImages = predictionImagesWithTranslation('evaluations');

interface IInjectedProps extends RouteComponentProps {
  evaluationDetailsService: IEvaluationDetailsService;
  authStore: IAuthStore;
  overlayLoader: IOverlayLoaderStore;
}

@injectProps({
  evaluationDetailsService: EvaluationDetailsServiceType,
  authStore: AuthStoreType,
  overlayLoader: OverlayLoaderStoreType,
})
@observer
class ModelImagesContainerPure extends React.Component<IInjectedProps> {
  static contextType = Settings;
  declare context: React.ContextType<typeof Settings>;

  @autobind
  async handlePaginationChange(pageNumber: number, pageSize: number) {
    this.props.evaluationDetailsService.changePagination(pageNumber, pageSize);
    this.context.setModelsImagesPageSize(pageSize);
  }

  @autobind
  async handleSwitchChange() {
    await this.props.evaluationDetailsService.togglePredictions();
  }

  render() {
    const { evaluationImagesPaging, images, status } = this.props.evaluationDetailsService.store;
    const isLoading =
      !!this.props.overlayLoader.isSpinnerVisible('evaluation-images-loader') || !!this.props.overlayLoader.isSpinnerVisible('evaluation-images-predictions-loader');
    const showSwitchGroup = !matchPath(this.props.location.pathname, Home.Evaluations.Create.Images.Path)?.isExact;
    const disableSwitch = ![EVALUATION_STATUS.FINISHED, EVALUATION_STATUS.IMPORTED].includes(status);

    return (
      <PredictionImages
        jobType={'EVALUATION'}
        allowImageDetails={this.props.evaluationDetailsService.store.status !== EVALUATION_STATUS.DRAFT}
        isLoading={isLoading}
        pagination={{
          pageNumber: evaluationImagesPaging.pageNumber,
          pageSize: evaluationImagesPaging.pageSize,
          totalCount: evaluationImagesPaging.totalCount,
          onChange: this.handlePaginationChange,
        }}
        showPredictions={this.props.evaluationDetailsService.store.evaluationImagesPaging.fetchPredictions}
        onSwitchChange={this.handleSwitchChange}
        detailsImages={images}
        authToken={this.props.authStore.token}
        showSwitchGroup={showSwitchGroup}
        disableSwitch={disableSwitch}
      />
    );
  }
}

export const EvaluationImagesContainer = as<React.ComponentClass>(withRouter(ModelImagesContainerPure));
