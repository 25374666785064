import * as React from 'react';

import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { faSortAmountDown, faSortAmountUp } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IClarification } from '../models/Clarifications.models';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { SortingDirection } from '../../../../../models/sortingDirection.model';
import { as } from '../../../../../helpers/react.helpers';
import { toLocaleDateTimeString } from '../../../../../helpers/date.helpers';
import { withNamespaces } from 'react-i18next';

export interface ClarificationsListModalProps {
  showModal: boolean;
  sortDirection: SortingDirection;
  clarificationsList: IClarification[];
  onToggleChange(): void;
  onSortDirectionChange(): void;
}

class ClarificationsListModalPure extends React.Component<ClarificationsListModalProps & ITranslatable> {
  render() {
    return (
      <Modal className="modal-width-600 image-clarifications-list-modal" isOpen={this.props.showModal} toggle={this.props.onToggleChange}>
        <ModalHeader toggle={this.props.onToggleChange}>{this.props.t('clarifications.list_modal.header')}</ModalHeader>
        <Form className="custom-inline-form">
          <ModalBody>
            <Row>
              <Col>
                <span className="clarifications-sorting" onClick={this.props.onSortDirectionChange}>
                    {this.props.sortDirection === SortingDirection.ASC && <> {this.props.t('clarifications.common.oldest')} <FontAwesomeIcon icon={faSortAmountUp} /></>}
                    {this.props.sortDirection === SortingDirection.DESC && <>{this.props.t('clarifications.common.newest')} <FontAwesomeIcon icon={faSortAmountDown} /></>}
                  </span>
              </Col>
            </Row>
            {this.props.clarificationsList.map((clarification: IClarification, index: number) => {
              return (
                <div key={`image-clarification-${index}`}>
                  <Row>
                    <Col>
                      <p className="clarification-item">
                        <span className="bold-text">{clarification.askedBy}</span> {toLocaleDateTimeString(clarification.askedDate)}<br />
                        <span className="bold-text">{this.props.t('clarifications.common.question')}: </span>{clarification.question}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                      <Col>
                        <p className="clarification-item">
                          <span className="bold-text">{clarification.answeredBy}</span> {toLocaleDateTimeString(clarification.answeredDate)}<br />
                          <span className="bold-text">{this.props.t('clarifications.common.answer')}: </span>{clarification.answer}
                        </p>
                      </Col>
                    </Row>
                    {this.props.clarificationsList.length - 1 > index && <hr/>}
                  </div>
              );
            })}
          </ModalBody>
          <ModalFooter>
            <Button id="clarification-list-button-close" color="primary" className="btn-outline-primary" onClick={this.props.onToggleChange}>
              {this.props.t('close')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export const ClarificationsListModal = as<React.FunctionComponent<ClarificationsListModalProps>>(
  withNamespaces('common', { wait: true })(ClarificationsListModalPure),
);
