import * as React from 'react';

import { IRouterStore, RouterStoreType } from '../stores/router.store';
import { as, injectProps } from '../helpers/react.helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IOuterProps {
  to: string;
  disabled?: boolean;
  className?: string;
  icon: any;
  color?: string;
  float?: string;
  title?: string;
}

interface IInjectedProps {
  router: IRouterStore;
}

interface IProps extends IInjectedProps, IOuterProps { }

@injectProps({ router: RouterStoreType })
class IconedLinkButtonPure extends React.Component<IProps> {
  handleLinkClick = (e: any) => {
    e.preventDefault();
    if (!this.props.disabled) this.props.router.push(this.props.to);
  };

  render() {
    return (
      <button
        className={`btn btn-icon ${this.props.color} ${this.props.float} ${this.props.disabled ? 'icon-disabled' : ''}`}
        onClick={this.handleLinkClick}
        title={this.props.title}
        disabled={this.props.disabled}
      >
        <FontAwesomeIcon icon={this.props.icon} />
      </button>
    );
  }
}

export const IconedLinkButton = as<React.ComponentClass<IOuterProps>>(IconedLinkButtonPure);
