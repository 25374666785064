import { ISegmentation, ISegmentationFeature } from './annotations.interface';
import { AnnotationUiStoreType, IAnnotationUiStore } from './annotationUi.store';
import { IQuestionModel, ImageScopeName, QuestionModel } from './question.model';
import { action, toJS, when } from 'mobx';
import { inject, injectable } from 'inversify';

import { Feature } from 'geojson';
import { InputStatus } from '../../models/error.model';
import { LatLngBounds } from 'leaflet';
import { cloneDeep } from 'lodash/fp';
import uuid from 'uuid';
import { AnnotationToolType } from './submodules/annotationTypes/models/annotationToolType';
import { IAnnotationType } from './submodules/annotationTypes/models/annotationType';
import { fixPseudoRectangle, fixPseudoRectangleGeometry } from '../../helpers/geometry/geometryFixes.helpers';
import { getLatLngsForGeojson } from '../../helpers/geometry/polygon.helpers';
import { IAnnotationsStore, AnnotationsStoreType, IHiddenSegmentation } from './annotations.store';
import { AnnotationTypeStoreType, IAnnotationTypeStore } from './submodules/annotationTypes/annotationType.store';
import { AnnotationTypeBlType, IAnnotationTypeBl } from './submodules/annotationTypes/annotationType.bl';
import { EventBusType, IEventBus } from '../../services/eventBus.service';
import { SegmentationSelectedEvent } from './submodules/segmentations/events/segmentationSelectedEvent';
import { SegmentationDeselectedEvent } from './submodules/segmentations/events/segmentationDeselectedEvent';
import { SegmentationDeletedEvent } from './submodules/segmentations/events/sementationDeletedEvent';
import { AnnotationApiServiceType, IAnnotationApiService } from './annotationApi.service';

export const AnnotationServiceType = Symbol('ANNOTATION_SERVICE');

export interface IAnnotationService {
  changedModeFromReviewToCorrect(): void;
  setImageQuestionsAsCurrent(): void;
  setQuestionsAsCurrent(questions: IQuestionModel[]): void;
  createNewSegmentation(activeAnnotationTypeId: string, annotation: Feature<any>): ISegmentation;
  addNewSegmentation(annotation: ISegmentation): void;
  selectSegmentation(id: string): void;
  deselectSegmentation(): void;
  getNextSegmentation(): ISegmentation | undefined;
  getPreviousSegmentation(): ISegmentation | undefined;
  moveToNextInvalidSegmentation(): void;
  copySegmentation(id: string): void;
  pasteSegmentation(annotation: Feature, id: string): void;
  updateSegmentation(id: string, annotation: Feature): void;
  deleteSegmentation(id: string): void;
  deleteSegmentations(annotations: ISegmentation[]): void;
  getAnnotationType(id: string): IAnnotationType | undefined;
  getAnnotationTypeColor(annotationTypeId: string): string;
  getSegmentations(): ISegmentation[];
  setSegmentations(segmentations: ISegmentation[]): void;
  clear(): void;
  clearAnnotations(): void;
  clearAnswers(): void;
  validateAnnotations(): boolean;
  fixRectangles(): void;
  enableEdit(id: string): void;
  getProjectStatusAsync(projectId: string): Promise<void>;
  showSegmentation(featureId: string, segmentationId?: string): void;
  hideSegmentation(featureId: string, segmentationId?: string): void;
  hideSegmentations(segmentationsToHide: IHiddenSegmentation[]): void
}

export interface ISegmentationFeatureWithProperties extends ISegmentationFeature {
  properties: {
    annotationTypeId: string;
    featureType: AnnotationToolType;
  };
}

@injectable()
export class AnnotationService implements IAnnotationService {
  constructor(
    @inject(AnnotationUiStoreType) public readonly uiStore: IAnnotationUiStore,
    @inject(AnnotationTypeStoreType) private readonly annotationTypeStore: IAnnotationTypeStore,
    @inject(AnnotationTypeBlType) private readonly annotationTypeBl: IAnnotationTypeBl,
    @inject(EventBusType) private readonly eventBus: IEventBus,
    @inject(AnnotationsStoreType) private readonly annotationsStore: IAnnotationsStore,
    @inject(AnnotationApiServiceType) private annotationApiService: IAnnotationApiService,
  ) {
    this.annotationTypeBl.onDeselectSegmentationInKeyBinding = this.deselectSegmentation;
    this.annotationTypeBl.whenImageIsLoaded = async () => {
      await when(() => !!this.annotationsStore.image);
      return this.annotationsStore.image!;
    };
  }

  @action.bound
  setImageQuestionsAsCurrent() {
    this.annotationsStore.currentQuestions = this.annotationsStore.questions.filter(q => q.scopes.includes(ImageScopeName));
  }

  @action.bound
  setQuestionsAsCurrent(questions: IQuestionModel[]) {
    this.annotationsStore.currentQuestions = questions;
  }

  @action.bound
  createNewSegmentation(activeAnnotationTypeId: string, annotation: Feature<any>): ISegmentation {
    const annotationType = this.getAnnotationType(activeAnnotationTypeId as string);

    annotation.properties = {
      annotationTypeId: activeAnnotationTypeId,
      featureType: annotationType!.selectorType,
    };

    const segmentationFeature = {
      ...annotation,
      color: this.getAnnotationTypeColor(activeAnnotationTypeId),
      featureType: annotationType!.selectorType,
      id: uuid.v4(),
    } as ISegmentationFeatureWithProperties;

    const annotationTypeQuestions = this.getAnnotationTypeQuestions(activeAnnotationTypeId);

    annotationTypeQuestions.forEach(q => {
      q.answers.forEach(a => (a.selected = false));
      q.answer = undefined;
    });

    const questionsModels = annotationTypeQuestions.map(q => new QuestionModel(q.projectId, q.id, q.type, q.isRequired, q.text, q.answers, q.scopes, q.answer));
    const feature = fixPseudoRectangle(segmentationFeature);
    const latlngs = getLatLngsForGeojson(feature);

    return {
      feature,
      latlngs,
      questions: questionsModels,
      bbox: new LatLngBounds(latlngs),
      priority: this.annotationsStore.segmentations.length + 1,
    };
  }

  @action.bound
  addNewSegmentation(segmentation: ISegmentation) {
    segmentation.feature = fixPseudoRectangle(segmentation.feature);
    segmentation.latlngs = getLatLngsForGeojson(segmentation.feature);
    segmentation.bbox = new LatLngBounds(segmentation.latlngs as any);

    this.annotationsStore.setSegmentations([...this.annotationsStore.segmentations, segmentation]);
    if (segmentation.questions.length > 0) {
      this.selectSegmentation(segmentation.feature.id);
      this.annotationsStore.drawFocusFrameForId = segmentation.feature.id;
    }

    this.showSegmentation(segmentation.feature.id, segmentation.id);
    this.resetValidateAnnotations();
  }

  @action.bound
  updateSegmentation(id: string, feature: Feature) {
    const segmentation = this.annotationsStore.segmentations.find(s => s.feature.id === id);
    if (!segmentation) return;

    segmentation.feature.geometry = feature.geometry;
    segmentation.feature = fixPseudoRectangle(segmentation.feature);
    segmentation.latlngs = getLatLngsForGeojson(segmentation.feature);
    segmentation.bbox = new LatLngBounds(segmentation.latlngs as any);
  }

  @action.bound
  enableEdit(id: string) {
    this.uiStore.isInValidation = false;
    this.annotationsStore.drawFocusFrameForId = undefined;
  }

  @action.bound
  selectSegmentation(id: string) {
    const segmentation = this.annotationsStore.segmentations.find(s => s.feature.id === id);

    if (segmentation) {
      if (this.annotationsStore.selectedSegmentation?.feature.id === segmentation.feature.id) {
        return;
      }
      this.annotationsStore.selectedSegmentation = cloneDeep(segmentation);

      if (this.annotationsStore.isSelectedSegWithQuestions || this.annotationsStore.isSelectedSegOfFreeDrawType) {
        this.setQuestionsAsCurrent(this.annotationsStore.selectedSegmentation.questions);
      } else {
        this.setImageQuestionsAsCurrent();
      }
      this.eventBus.sendEvent(new SegmentationSelectedEvent(this.annotationsStore.selectedSegmentation));
    } else {
      this.deselectSegmentation();
    }
    this.annotationsStore.invalidSegmentation = undefined;
  }

  @action.bound
  deselectSegmentation() {
    this.annotationsStore.selectedSegmentation = undefined;
    this.annotationsStore.invalidSegmentation = undefined;
    this.setImageQuestionsAsCurrent();
    this.eventBus.sendEvent(new SegmentationDeselectedEvent());
  }

  @action.bound
  getNextSegmentation(): ISegmentation | undefined {
    const segmentationsWithQuestions = this.annotationsStore.segmentations
      .filter(s => s.questions.length > 0)
      .filter(s => this.annotationTypeStore.annotationTypesOptions.some(a => a.id === s.feature.properties!.annotationTypeId && a.isVisible));

    if (segmentationsWithQuestions.length === 0) return;

    if (this.annotationsStore.selectedSegmentation === undefined) {
      return segmentationsWithQuestions[0];
    }

    const nextSegmentationIndex =
      (segmentationsWithQuestions.findIndex(s => s.feature.id === this.annotationsStore.selectedSegmentation!.feature.id) + 1) % segmentationsWithQuestions.length;
    return segmentationsWithQuestions[nextSegmentationIndex];
  }

  @action.bound
  getPreviousSegmentation(): ISegmentation | undefined {
    const segmentationsWithQuestions = this.annotationsStore.segmentations
      .filter(s => s.questions.length > 0)
      .filter(s => this.annotationTypeStore.annotationTypesOptions.some(a => a.id === s.feature.properties!.annotationTypeId && a.isVisible));

    if (segmentationsWithQuestions.length === 0) return;

    if (this.annotationsStore.selectedSegmentation === undefined) {
      return segmentationsWithQuestions[0];
    }

    let previousSegmentationIndex = segmentationsWithQuestions.findIndex(s => s.feature.id === this.annotationsStore.selectedSegmentation!.feature.id) - 1;
    if (previousSegmentationIndex === -1) previousSegmentationIndex = segmentationsWithQuestions.length - 1;

    return segmentationsWithQuestions[previousSegmentationIndex];
  }

  @action.bound
  moveToNextInvalidSegmentation() {
    this.annotationTypeBl.handleMoveToNextSegmentation();
    const invalidSegmentation = this.annotationsStore.segmentations.find(s => s.questions.some(q => q.status.isValid === false));

    if (!invalidSegmentation) {
      return;
    }

    this.annotationsStore.drawFocusFrameForId = invalidSegmentation.feature.id;
    this.selectSegmentation(invalidSegmentation.feature.id);
    this.annotationsStore.invalidSegmentation = invalidSegmentation;
  }

  @action.bound
  copySegmentation(id: string) {
    this.annotationsStore.copiedSegmentation = toJS(this.annotationsStore.segmentations.find(s => s.feature.id === id));
  }

  @action.bound
  pasteSegmentation(annotation: Feature, id: string) {
    if (!this.annotationsStore.copiedSegmentation) return;
    if (this.annotationTypeStore.annotationTypes.find(a => a.id === this.annotationTypeStore.activeAnnotationTypeId)?.selectorType === AnnotationToolType.BRUSH) return;

    const feature = {
      ...annotation,
      id,
      properties: { ...this.annotationsStore.copiedSegmentation.feature.properties },
      color: this.annotationsStore.copiedSegmentation.feature.color,
      featureType: this.annotationsStore.copiedSegmentation.feature.featureType,
    };

    const latlngs = getLatLngsForGeojson(feature);

    this.annotationsStore.setSegmentations([
      ...this.annotationsStore.segmentations,
      {
        feature,
        latlngs,
        questions: this.annotationsStore.copiedSegmentation.questions.map(x => new QuestionModel(x.projectId, x.id, x.type, x.isRequired, x.text, x.answers, x.scopes, x.answer)),
        bbox: new LatLngBounds(latlngs),
        priority: this.annotationsStore.segmentations.length + 1,
      },
    ]);

    this.selectSegmentation(id);
  }

  @action.bound
  deleteSegmentation(id: string): void {
    if (this.annotationsStore.selectedSegmentation && this.annotationsStore.selectedSegmentation.feature.id === id) this.deselectSegmentation();
    this.annotationsStore.setSegmentations(this.annotationsStore.segmentations.filter(s => s.feature.id !== id));
    this.eventBus.sendEvent(new SegmentationDeletedEvent(id));
  }

  @action.bound
  deleteSegmentations(annotations: ISegmentation[]): void {
    this.setSegmentations(this.annotationsStore.segmentations.filter(s => annotations.includes(s)));
  }

  changedModeFromReviewToCorrect(): void {
    this.deselectSegmentation();
  }

  @action.bound
  validateAnnotations() {
    this.uiStore.isInValidation = true;
    const imageQuestions = this.annotationsStore.questions.filter(q => q.scopes.includes(ImageScopeName));
    const segmentationsQuestions = this.annotationsStore.segmentations
      .map(s => s.questions)
      .reduce((previousQuestions: IQuestionModel[], currentQuestions: IQuestionModel[]) => previousQuestions.concat(currentQuestions), []);

    imageQuestions.forEach(q => q.validate());
    segmentationsQuestions.forEach(q => q.validate());

    const imageQuestionsAreValid = imageQuestions.every(q => q.status.isValid !== false);
    const segmentationsQuestionsAreValid = segmentationsQuestions.every(q => q.status.isValid !== false);

    if (!imageQuestionsAreValid) {
      this.deselectSegmentation();
    } else if (!segmentationsQuestionsAreValid) {
      this.moveToNextInvalidSegmentation();
    }

    return imageQuestionsAreValid && segmentationsQuestionsAreValid && !!this.annotationsStore.image;
  }

  @action.bound
  resetValidateAnnotations() {
    const imageQuestions = this.annotationsStore.questions.filter(q => q.scopes.includes(ImageScopeName) && q.status.isValid === false);
    const segmentationsQuestions = this.annotationsStore.segmentations
      .map(s => s.questions)
      .reduce((previousQuestions: IQuestionModel[], currentQuestions: IQuestionModel[]) => previousQuestions.concat(currentQuestions), [])
      .filter(q => q.status.isValid === false);

    imageQuestions.forEach(q => q.resetStatus());
    segmentationsQuestions.forEach(q => q.resetStatus());
    this.uiStore.isInValidation = false;
  }

  getAnnotationType(id: string): IAnnotationType | undefined {
    return this.annotationTypeStore.annotationTypes.find(t => t.id === id);
  }

  getAnnotationTypeColor(annotationTypeId: string): string {
    const type = this.annotationTypeStore.annotationTypes.find(m => m.id === annotationTypeId);
    if (type) return type.color;
    return 'black';
  }

  getAnnotationTypeQuestions(id: string): IQuestionModel[] {
    return toJS(
      this.annotationsStore.questions.filter(q => q.scopes.map(s => s.toUpperCase()).includes(id.toUpperCase())),
      { recurseEverything: true },
    );
  }

  @action.bound
  getSegmentations(): ISegmentation[] {
    return cloneDeep(this.annotationsStore.segmentations.concat());
  }

  @action.bound
  setSegmentations(segmentations: ISegmentation[]): void {
    this.annotationsStore.setSegmentations(segmentations);
    if (this.annotationsStore.selectedSegmentation && !segmentations.find(s => s.feature.id === this.annotationsStore.selectedSegmentation?.feature.id)) {
      this.deselectSegmentation();
    }
    this.uiStore.forceReRender();
  }

  @action
  clear(): void {
    this.annotationsStore.id = undefined;
    this.annotationsStore.image = undefined;
    this.annotationsStore.selectedSegmentation = undefined;
    this.annotationsStore.invalidSegmentation = undefined;
    this.annotationsStore.questions.length = 0;
    this.annotationsStore.setSegmentations([]);
  }

  @action
  clearAnnotations(): void {
    this.annotationsStore.id = undefined;
    this.annotationsStore.selectedSegmentation = undefined;
    this.annotationsStore.invalidSegmentation = undefined;
    this.annotationsStore.setSegmentations([]);
  }

  @action
  clearAnswers() {
    this.annotationsStore.questions.forEach(q => {
      q.answers.forEach(a => (a.selected = false));
      q.answer = undefined;
      q.status = InputStatus.empty();
    });
  }

  @action
  fixRectangles() {
    this.annotationsStore.segmentations.forEach(s => {
      if (s.feature.featureType === AnnotationToolType.RECTANGLE) s.feature.geometry = fixPseudoRectangleGeometry(s.feature.geometry);
    });
  }

  @action
  async getProjectStatusAsync(projectId: string): Promise<void> {
    const result = await this.annotationApiService.getProjectStatusAsync(projectId);
    this.annotationsStore.setProjectStatus(result.projectStatus);
  }

  @action
  showSegmentation(featureId: string, segmentationId?: string): void {
    let newHiddenSegmentations: IHiddenSegmentation[] = this.annotationsStore.hiddenSegmentations.slice();

    newHiddenSegmentations = newHiddenSegmentations.filter(s => s.featureId === undefined || s.featureId !== featureId);

    if (segmentationId !== undefined) {
      newHiddenSegmentations = newHiddenSegmentations.filter(s => s.segmentationId === undefined || s.segmentationId !== segmentationId);
    }

    this.annotationsStore.setHiddenSegmentations(newHiddenSegmentations);
  }

  @action
  hideSegmentation(featureId: string, segmentationId?: string): void {
    const newItems: IHiddenSegmentation[] = this.annotationsStore.hiddenSegmentations.slice();
    newItems.push({ segmentationId, featureId });
    this.annotationsStore.setHiddenSegmentations(newItems);
  }

  @action
  hideSegmentations(segmentationsToHide: IHiddenSegmentation[]): void {
    const newItems: IHiddenSegmentation[] = this.annotationsStore.hiddenSegmentations.slice().concat(segmentationsToHide);
    this.annotationsStore.setHiddenSegmentations(newItems);
  }
}
