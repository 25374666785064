import React from 'react';

export interface StatsProgressProps {
  header?: string;
  title?: string;
  stats: { value: string, label: string }[];
}

const statsProgress = (props: StatsProgressProps) => (
  <div className="annotation-stats-pane">
    <span title={props.title}>
      {props.header && <span className="title">{props.header}: </span>}
      <span>
        {props.stats.map(({ value, label }, index) => (
          <span key={`stat-${index}`} className="stat">{label}: {value} {index < props.stats.length - 1 && <span> | </span>}</span>
        ))}
      </span>
    </span>
  </div>
);

export const StatsProgress = statsProgress;
