import * as React from 'react';

import { SwitchWithNotFound } from '../../containers/route.containers';
import { NotFoundView } from '../../views/NotFound.view';
import { Route } from 'react-router';
import { Home } from '../config/Home';
import { ModelDetailsSettingsView } from '../../views/home/modelDetails/ModelDetailsSettings.view';
import { ModelDetailsMetricsView } from '../../views/home/modelDetails/ModelDetailsMetrics.view';
import { ModelDetailsImagesView } from '../../views/home/modelDetails/ModelDetailsImages.view';
import { ModelDetailsDatasetsView } from '../../views/home/modelDetails/ModelDetailsDatasets.view';

export const ModelDetailsRoute = () => (
  <SwitchWithNotFound notFound={NotFoundView}>
    <Route path={Home.Models.Details.Settings.Path} component={ModelDetailsSettingsView} />
    <Route path={Home.Models.Details.Datasets.Path} component={ModelDetailsDatasetsView} />
    <Route path={Home.Models.Details.Images.Path} component={ModelDetailsImagesView} />
    <Route path={Home.Models.Details.Metrics.Path} component={ModelDetailsMetricsView} />
  </SwitchWithNotFound>
);

export const ModelDetailsCreateRoute = () => (
  <SwitchWithNotFound notFound={NotFoundView}>
    <Route path={Home.Models.Create.Settings.Path} component={ModelDetailsSettingsView} />
    <Route path={Home.Models.Create.Datasets.Path} component={ModelDetailsDatasetsView} />
    <Route path={Home.Models.Create.Images.Path} component={ModelDetailsImagesView} />
  </SwitchWithNotFound>
);
