import { inject, injectable } from 'inversify';
import { IUserStore, UserStoreType } from '../../../__legacy__/modules/user/user.store';

export const CursorControlBlType = Symbol('CURSOR_CONTROL_BL_TYPE');

export interface ICursorControlBl {
  toggleCursorGuides(): void;
  toggleImprovedVisibilityCursor(): void;
}

@injectable()
export class CursorControlBl implements ICursorControlBl {
  constructor(@inject(UserStoreType) private readonly userStore: IUserStore) {}

  toggleCursorGuides(): void {
    this.userStore.areCursorGuidesEnabled = !this.userStore.areCursorGuidesEnabled;
  }
  
  toggleImprovedVisibilityCursor(): void {
    this.userStore.isImprovedVisibilityCursorEnabled = !this.userStore.isImprovedVisibilityCursorEnabled;
  }
}
