import * as React from 'react';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { IUndoRedoAnnotationsShortcutsProps } from '../containers/UndoRedoAnnotationsShortcuts.container';
import { as } from '../../../helpers/react.helpers';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

library.add(faSearch);

@observer
class UndoRedoAnnotationsShortcutsPure extends React.Component<IUndoRedoAnnotationsShortcutsProps & ITranslatable> {
  render() {
    return (
      <Button onClick={this.props.onClick} className="btn primary undo element" disabled={!this.props.enabled} tabIndex={-1}>
        <FontAwesomeIcon icon={this.props.undo ? 'undo' : 'redo'} />
        <p>{this.props.t(this.props.undo ? 'undo' : 'redo')}</p>
      </Button>
    );
  }
}
export const UndoRedoAnnotationsShortcuts = as<React.ComponentClass<IUndoRedoAnnotationsShortcutsProps>>(withNamespaces('common')(UndoRedoAnnotationsShortcutsPure));
