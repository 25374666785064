import './WorkPointsRulesModal.scss';

import { WithNamespaces, withNamespaces } from 'react-i18next';

import React from 'react';
import { S_OneButtonModal } from '../../../../../../design/modals/oneButtonModal/S_OneButtonModal';

interface IProps {
  show: boolean;
  toggle(): void;
}

const WorkPointsRulesModalPure: React.FC<IProps & WithNamespaces> = ({ show, t, toggle }: IProps & WithNamespaces) => {
  const header = t('projects.details.stats.annotation_tools.rules_modal.header');
  const ok = t('projects.details.stats.annotation_tools.rules_modal.ok');

  return (
    <S_OneButtonModal show={show} buttonText={ok} headerText={header} onClick={toggle} onHide={toggle}>
      <p>
        <span>{t('projects.details.stats.annotation_tools.rules_modal.description')}</span>
        <br />
        <span>{t('projects.details.stats.annotation_tools.rules_modal.description2')}</span>
      </p>
      <h5 className="rules-modal-header">{t('projects.details.stats.annotation_tools.rules_modal.marking_tools')}</h5>
      <ul>
        <li>{t('projects.details.stats.annotation_tools.rules_modal.bounding_box')}</li>
        <li>{t('projects.details.stats.annotation_tools.rules_modal.brush')}</li>
        <li>{t('projects.details.stats.annotation_tools.rules_modal.line')}</li>
        <li>{t('projects.details.stats.annotation_tools.rules_modal.oriented_rectangle')}</li>
        <li>{t('projects.details.stats.annotation_tools.rules_modal.point')}</li>
        <li>{t('projects.details.stats.annotation_tools.rules_modal.polygon')}</li>
        <li>{t('projects.details.stats.annotation_tools.rules_modal.vector')}</li>
      </ul>
      <h5 className="rules-modal-header">{t('projects.details.stats.annotation_tools.rules_modal.attributes_and_questions')}</h5>
      <ul>
        <li>{t('projects.details.stats.annotation_tools.rules_modal.yes_no')}</li>
        <li>{t('projects.details.stats.annotation_tools.rules_modal.radio')}</li>
        <li>{t('projects.details.stats.annotation_tools.rules_modal.checkbox')}</li>
        <li>{t('projects.details.stats.annotation_tools.rules_modal.text')}</li>
        <li>{t('projects.details.stats.annotation_tools.rules_modal.number')}</li>
        <li>{t('projects.details.stats.annotation_tools.rules_modal.pass_fail')}</li>
        <li>{t('projects.details.stats.annotation_tools.rules_modal.train_test')}</li>
      </ul>
    </S_OneButtonModal>
  );
};

export const WorkPointsRulesModal = withNamespaces('new')(WorkPointsRulesModalPure);
