import * as React from 'react';
import { Line } from 'react-chartjs-2';
import { Row, Col, Card, CardTitle, CardBody } from 'reactstrap';
import { GTable, IGColumnProps } from '../../table/GTable';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { IChartPoint, ITrainingMatrix, ITrainingMetrics, ITrainingResult } from '../../../models/metrics.model';

interface IProps {
  curve: IChartPoint[];
  matrix: ITrainingMatrix[];
  metrics: ITrainingMetrics;
  results: ITrainingResult[];
}

const ResultMetricsPure = ({ curve, matrix, metrics, results, t }: IProps & ITranslatable) => {
  const roundDecimal = (points: IChartPoint[]) => points.map(({ x, y }) => ({ x: x.toFixed(3), y: y.toFixed(3) }));

  const options = {
    scales: {
      xAxes: [
        {
          type: 'linear',
          position: 'bottom',
          scaleLabel: {
            fontSize: 18,
            display: true,
            labelString: t('metrics_chart.xAxis'),
          },
          ticks: {
            min: 0,
            max: 1,
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            fontSize: 18,
            display: true,
            labelString: t('metrics_chart.yAxis'),
          },
          ticks: {
            min: 0,
            max: 1,
          },
        },
      ],
    },
  };

  const data = {
    datasets: [
      {
        label: t('metrics_chart.train'),
        data: roundDecimal(curve),
        fill: false,
        borderColor: '#4bc0c0',
        tension: 0.1,
      },
    ],
  };

  const gResults: IGColumnProps<ITrainingResult>[] = [
    {
      field: 'name',
      headerName: t('metrics_results.name'),
      renderer(item: ITrainingResult): JSX.Element {
        return <div>{t(`metrics_results.metric.${item.name}`)}</div>;
      },
      sortable: false,
      minWidth: 100,
    },
    {
      field: 'train',
      headerName: t('metrics_results.train'),
      renderer(item: ITrainingResult): JSX.Element {
        return <div>{item.train !== null ? item.train : t('na')}</div>;
      },
      sortable: false,
      width: 100,
    },
    {
      field: 'validation',
      headerName: t('metrics_results.validation'),
      renderer(item: ITrainingResult): JSX.Element {
        return <div>{item.validation !== null ? item.validation : t('na')}</div>;
      },
      sortable: false,
      width: 100,
    },
    {
      field: 'test',
      headerName: t('metrics_results.test'),
      renderer(item: ITrainingResult): JSX.Element {
        return <div>{item.test !== null ? item.test : t('na')}</div>;
      },
      sortable: false,
      width: 100,
    },
  ];

  const gMatrix: IGColumnProps<ITrainingMatrix>[] = [
    {
      field: 'name',
      headerName: t('confusion_matrix.title'),
      sortable: false,
      cellClass: 'header-table-text-style',
      minWidth: 100,
    },
    {
      field: 'good',
      headerName: t('confusion_matrix.good'),
      headerClassName: 'text-center pl-0 h-auto',
      cellClass: 'text-center',
      sortable: false,
      minWidth: 100,
    },
    {
      field: 'weak',
      headerName: t('confusion_matrix.weak'),
      headerClassName: 'text-center pl-0 h-auto',
      cellClass: 'text-center',
      sortable: false,
      minWidth: 100,
    },
    {
      field: 'bad',
      headerName: t('confusion_matrix.bad'),
      headerClassName: 'text-center pl-0 h-auto',
      cellClass: 'text-center',
      sortable: false,
      minWidth: 100,
    },
  ];

  return (
    <div>
      <Card className="flex-fill slim-margin bg-transparent shadow-none">
        <CardBody>
          <CardTitle tag="h4">{t('metrics_chart.title')}</CardTitle>
          <p>{t('available_after')}</p>
          <Row>
            <Col xs={6}>
              <Line data={data} options={options} height={200} />
              <Row className="mt-4">
                <h5>
                  {t('metrics_results.auc')}: {metrics.auc === null ? t('na') : metrics.auc}
                </h5>
              </Row>
            </Col>
            <Col xs={6}>
              <GTable columns={gMatrix} items={matrix} noItemsPlaceholder={t('data_unavailable_in_progress')} />
              <div className="mb-lg-4" />
              <GTable columns={gResults} items={results} noItemsPlaceholder={t('data_unavailable_in_progress')} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export const resultMetricsWithTranslation = (ns: 'models' | 'evaluations') => as<React.FC<IProps>>(withNamespaces(ns)(ResultMetricsPure));
