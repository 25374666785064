import { Datasets, IDatasetsContext, makeDatasetsContext } from '../../modules/datesets/datasets.context';

import { DatasetsRoute } from '../../routes/datasets/datasets.route';
import { MainNavContainer } from '../../containers/MainNavContainer';
import { Settings } from '../../../modules/settings/SettingsContext';
import React, { useContext } from 'react';
interface IState {
  context: IDatasetsContext;
}

interface IProps {
  initialPageSize: number;
}

class DatasetsViewPure extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { context: makeDatasetsContext() };
  }

  componentDidMount() {
    this.state.context.service.init(this.props.initialPageSize);
  }

  componentWillUnmount() {
    this.state.context.service.dispose();
  }

  render() {
    return (
      <div>
        <MainNavContainer>
          <Datasets.Provider value={this.state.context}>
            <DatasetsRoute />
          </Datasets.Provider>
        </MainNavContainer>
      </div>
    );
  }
}

const DatasetsViewWrapper: React.FC = () => {
  const settingsContext = useContext(Settings);

  return <DatasetsViewPure initialPageSize={settingsContext.store.datasetsPageSize} />;
};

export const DatasetsView = DatasetsViewWrapper;
