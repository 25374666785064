import * as React from 'react';

import { AnnotationsStoreType, IAnnotationsStore } from '../annotations.store';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { IRouterStore, RouterStoreType } from '../../../stores/router.store';
import { IUndoRedoHistory, UndoRedoHistoryType } from '../undoRedoHistory.service';
import { Prompt, RouteComponentProps, withRouter } from 'react-router-dom';
import { as, injectProps } from '../../../helpers/react.helpers';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Home } from '../../../routes/config/Home';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { QuitAnnotationsModal } from '../components/QuitAnnotationsModal';
import autobind from 'autobind-decorator';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IState {
  showModal: boolean;
  confirmedNavigation: boolean;
}

interface IInjectedProps extends ITranslatable, RouteComponentProps<{ projectId: string }> {
  routerStore: IRouterStore;
  historyService: IUndoRedoHistory;
  currentWorkspaceStore: ICurrentWorkspaceStore;
  annotationsStore: IAnnotationsStore;
}

@injectProps({
  routerStore: RouterStoreType,
  historyService: UndoRedoHistoryType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  annotationsStore: AnnotationsStoreType,
})
@observer
class BackToProjectsButtonContainerPure extends React.Component<IInjectedProps, IState> {

  constructor(props: IInjectedProps) {
    super(props);
    this.state = {
      showModal: false,
      confirmedNavigation: false,
    };
  }

  @autobind
  handleClick(event: React.MouseEvent) {
    event.preventDefault();
    if (this.props.annotationsStore.image === undefined || !this.props.historyService.canUndo) {
      this.handleConfirm();
    } else {
      this.setState({ showModal: true, confirmedNavigation: false });
    }
  }

  @autobind
  handleCancel() {
    this.setState({ showModal: false });
  }

  @autobind
  handleConfirm() {
    this.props.historyService.clearHistory();
    this.setState(
      { showModal: false, confirmedNavigation: true },
      () => this.props.routerStore.push(Home.Projects.List.All.withParams({ workspaceId: this.props.currentWorkspaceStore.currentWorkspace!.id })));
  }

  handleBlockedNavigation = (): boolean => {
    if (!this.state.confirmedNavigation && this.props.historyService.canUndo) {
      this.setState({ showModal: true });
      return false;
    }

    return true;
  }

  render() {
    return (
      <>
        <Button onClick={this.handleClick} tabIndex={-1}>
          <FontAwesomeIcon icon={faArrowLeft} />
          {this.props.t('back')}
        </Button>
        <Prompt
          when={true}
          message={this.handleBlockedNavigation}
        />
        <QuitAnnotationsModal
          isOpen={this.state.showModal}
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
        />
      </>
    );
  }
}

export const BackToProjectsButtonContainer = as<React.ComponentClass>(withRouter((withNamespaces('annotation')(BackToProjectsButtonContainerPure))));
