export interface IImportAnnotationsReport {
  importedAnnotationsCount: number;
  skippedAnnotationsCount: number;
  rejectedAnnotations: IImportAnnotationRejection[];
  unregisteredUsers: IImportAnnotationUnregisteredUser[];
}

export interface IImportAnnotationUnregisteredUser {
  email: string;
  roles: string[];
}

export interface IImportAnnotationRejection {
  datasetName: string;
  imageName: string;
  reason: ImportAnnotationRejectionReason;
}

export enum ImportAnnotationRejectionReason {
  DATASET_NOT_FOUND = 'DatasetNotFound',
  IMAGE_NOT_FOUND = 'ImageNotFound',
  ANNOTATIONS_DUPLICATED = 'AnnotationDuplicated',
  ANNOTATION_TYPE_NOT_FOUND = 'AnnotationTypeNotFound',
  KEEP_ORIGINAL = 'KeepOriginal',
  IMAGE_ALREADY_LOCKED = 'ImageAlreadyLocked',
  QUESTION_NOT_FOUND = 'QuestionNotFound',
  SELECTION_TYPE_MISMATCH = 'SelectionTypeMismatch',
  SELECTION_INVALID_FORMAT = 'SelectionInvalidFormat',
  INVALID_ANSWER = 'InvalidAnswer',
  INCORRECT_STATUS = 'IncorrectStatus',
  MISSING_ANNOTATION_DATE_TIME = 'MissingAnnotationDateTime',
  MISSING_REVIEW_DATE_TIME = 'MissingReviewDateTime',
  ANNOTATION_DATE_TIME_GREATER_THAN_REVIEW_DATE_TIME = 'AnnotationDateTimeGreaterThenReviewDateTime',
}

export const importAnnotationsRejectionReasonTranslation = (reason: ImportAnnotationRejectionReason): string => {
  switch (reason) {
    case ImportAnnotationRejectionReason.DATASET_NOT_FOUND:
      return 'dataset_not_found';
    case ImportAnnotationRejectionReason.IMAGE_NOT_FOUND:
      return 'image_not_found_in_dataset';
    case ImportAnnotationRejectionReason.ANNOTATIONS_DUPLICATED:
      return 'annotations_duplicated';
    case ImportAnnotationRejectionReason.ANNOTATION_TYPE_NOT_FOUND:
      return 'annotation_type_not_found';
    case ImportAnnotationRejectionReason.KEEP_ORIGINAL:
      return 'original_kept';
    case ImportAnnotationRejectionReason.IMAGE_ALREADY_LOCKED:
      return 'image_locked';
    case ImportAnnotationRejectionReason.QUESTION_NOT_FOUND:
      return 'question_not_found';
    case ImportAnnotationRejectionReason.SELECTION_TYPE_MISMATCH:
      return 'selection_type_mismatch';
    case ImportAnnotationRejectionReason.SELECTION_INVALID_FORMAT:
      return 'selection_invalid_format';
    case ImportAnnotationRejectionReason.INVALID_ANSWER:
      return 'invalid_answer';
    case ImportAnnotationRejectionReason.INCORRECT_STATUS:
      return 'incorrect_status';
    case ImportAnnotationRejectionReason.MISSING_ANNOTATION_DATE_TIME:
      return 'missing_annotation_date_time';
    case ImportAnnotationRejectionReason.MISSING_REVIEW_DATE_TIME:
      return 'missing_review_date_time';
    case ImportAnnotationRejectionReason.ANNOTATION_DATE_TIME_GREATER_THAN_REVIEW_DATE_TIME:
      return 'annotation_date_time_greater_than_review_date_time';
    default:
      return 'unknown';
  }
};
