import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { IUserService, UserServiceType } from '../modules/user/user.service';
import { as, injectProps } from '../helpers/react.helpers';

import { ITranslatable } from '../helpers/translations.helpers';
import { ProjectRole } from '../models/userRole.model';
import React from 'react';
import { WorkspaceRole } from '../modules/workspaces/workspaces.store';
import { withNamespaces } from 'react-i18next';
import { Plan } from '../modules/user/user.store';

interface IOuterProps {
  projectRoles: ProjectRole[];
  workspaceRoles: WorkspaceRole[];
  workspaceOwnerPlans?: Plan[];
  inProject?: string;
  title?: string;
  disableForEncryption?: boolean;
}

interface IInjectedProps {
  userService: IUserService;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

@injectProps({
  userService: UserServiceType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
class EnableForRolePure extends React.Component<IOuterProps & IInjectedProps & ITranslatable> {
  render() {
    const projectRoles = this.props.projectRoles || [];
    const workspaceRoles = this.props.workspaceRoles || [];
    const workspaceOwnerPlans = this.props.workspaceOwnerPlans || [];

    const { currentWorkspace } = this.props.currentWorkspaceStore;

    if (!currentWorkspace) {
      return null;
    }

    const hasPlanAccess = workspaceOwnerPlans.length ? workspaceOwnerPlans.includes(currentWorkspace.ownerPlanTier) : true;
    const hasRoleAccess = this.props.userService.isInRole(this.props.inProject, projectRoles) || this.props.currentWorkspaceStore.isInRole(workspaceRoles);
    const hasEncryptionAccess = this.props.disableForEncryption ? !this.props.currentWorkspaceStore.currentWorkspace?.encryption.encrypted : true;

    const hasAccess = hasPlanAccess && hasRoleAccess && hasEncryptionAccess;

    return React.Children.map(this.props.children, (child: any) => {
      return React.cloneElement(child, {
        disabled: child.props.disabled || !hasAccess,
        title: hasAccess
          ? child.props.title
          : hasPlanAccess
          ? this.props.title || this.props.t('you_do_not_have_permission_to_perform_this_operation')
          : this.props.t('available_only_for_plans', { plans: workspaceOwnerPlans.join(', ') }),
      });
    });
  }
}

export const EnableForRole = as<React.ComponentClass<IOuterProps>>(withNamespaces('common', { wait: true })(EnableForRolePure));
