import { IImportImageSetRejection, ImportImageSetRejectionReason } from '../projects.model';

import { CollapsibleImportSummary } from '../../../components/CollapsibleSummary';
import { ITranslatable } from '../../../helpers/translations.helpers';
import React from 'react';
import { as } from '../../../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

export interface IProps {
  isLastSummaryItem?: boolean;
  isPanelOpen: boolean;
  handlePanelToggle(): void;
  rejectedImageSets: IImportImageSetRejection[];
  importedImageSetsCount: number;
  skippedImageSetsCount: number;
}

class ImportImageSetsSummaryPure extends React.Component<IProps & ITranslatable> {
  
  mapImageSetRejectionReason(reason: ImportImageSetRejectionReason) {
    switch (reason) {
      case ImportImageSetRejectionReason.DATASET_NOT_FOUND:
        return this.props.t('dataset_not_found_in_workspace');
      case ImportImageSetRejectionReason.IMAGE_NOT_FOUND:
        return this.props.t('image_not_found_in_dataset');
      case ImportImageSetRejectionReason.IMAGE_DUPLICATED:
        return this.props.t('image_duplicated');
      case ImportImageSetRejectionReason.INVALID_SET_TYPE:
        return this.props.t('invalid_set_type');
      default:
        return this.props.t('unknown');
    }
  }

  getRejectionReasons = (): string[] => {
    return this.props.rejectedImageSets.map(x => {
      return `${x.datasetName} - ${x.imageName} - ${this.mapImageSetRejectionReason(x.reason)}`;
    });
  };

  render() {
    return (
      <>
        <CollapsibleImportSummary
          importedItemsCount={this.props.importedImageSetsCount}
          skippedItemsCount={this.props.skippedImageSetsCount}
          rejectionReasons={this.getRejectionReasons()}
          isPanelOpen={this.props.isPanelOpen}
          togglePanel={this.props.handlePanelToggle}
          isLastSummaryItem={this.props.isLastSummaryItem}
          importedMessage={this.props.t('sets_imported', { count: this.props.importedImageSetsCount })}
          skippedMessage={this.props.t('sets_skipped', { count: this.props.skippedImageSetsCount })}
        />
      </>
    );
  }
}

export const ImportImageSetsSummary = as<React.ComponentClass<IProps>>(withNamespaces('project')(ImportImageSetsSummaryPure));
