import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface IProps {
  disabled?: boolean;
  left?: boolean;
  onClick(): void;
}

export const NavigationButton: React.FC<IProps> = (props: IProps) => {
  const { onClick, disabled, left } = props;
  return (
    <div className="date-navigation-button">
      <Button className="btn-sm" disabled={disabled} color="primary" onClick={onClick}>
        <FontAwesomeIcon icon={left ? faAngleLeft : faAngleRight} />
      </Button>
    </div>
  );
};
