import './AnnotationTypesListItem.scss';

import * as React from 'react';

import { BRUSH_MODE, IBrushTypeOptions } from '../../../annotations.interface';
import {
  faBrush,
  faBullseye,
  faCircle,
  faDrawPolygon,
  faEraser,
  faEye,
  faEyeSlash,
  faHandPointer,
  faMousePointer,
  faSlash,
  faTags,
  faTimes,
  faTrashAlt,
  faVectorSquare,
} from '@fortawesome/free-solid-svg-icons';

import { AnnotationToolType } from '../models/annotationToolType';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { IAnnotationType } from '../models/annotationType';
import { IAnnotationTypeOptions } from '../models/annotationTypeOptions';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { IconedButton } from '../../../../../components/IconedButton';
import { IconedButtonWithSlider } from '../../../../../components/IconedButtonWithSlider';
import { ToolTypeIcon } from '../../../../../components/ToolTypeIcon';
import { action } from 'mobx';
import { as } from '../../../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { handleClickAndPassData } from '../../../../../helpers/formHelpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';

library.add(faBullseye, faTags, faDrawPolygon, faEye, faEyeSlash, faVectorSquare, faHandPointer, faSlash, faMousePointer, faTrashAlt, faBrush, faEraser, faCircle, faTimes);

export interface IProps extends IAnnotationType {
  projectId: string;
  active?: boolean;
  readonly: boolean;
  deleteSelected?: boolean;
  options?: IAnnotationTypeOptions;
  onAnnotationTypeClicked(id: string): void;
  onAnnotationTypeOptionsChanged(projectId: string, annotationTypeOptions: IAnnotationTypeOptions, handleSegmentationVisibility?: boolean): void;
  onSelectedAnnotationDelete(): void;
  getShortcutKey(order: number): string | undefined;
}

const displayShortcut = (props: IProps) => {
  if (props.getShortcutKey) {
    const key = () => props.getShortcutKey!(props.order);
    if (key) return <span className="shortcut">{key()}</span>;
  }
  return <span className="shortcut">{' '}</span>;
};

interface IState {
  showModal: boolean;
}

class AnnotationTypeListItem extends React.Component<IProps & ITranslatable, IState> {
  constructor(props: IProps & ITranslatable) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  @action.bound
  handleRadiusChange(value: number) {
    this.props.onAnnotationTypeOptionsChanged(this.props.projectId, { ...this.props.options!, brushDiameter: value } as IBrushTypeOptions);
  }

  @autobind
  toggleModal() {
    this.setState((prev: IState) => {
      return { showModal: !prev.showModal };
    });
  }

  @autobind
  handleOptionsChange(options: IAnnotationTypeOptions) {
    this.props.onAnnotationTypeOptionsChanged(this.props.projectId, options);
  }

  @autobind
  handleOptionsChangeWithVisibilityChange(options: IAnnotationTypeOptions) {
    this.props.onAnnotationTypeOptionsChanged(this.props.projectId, options, true);
  }

  render() {
    const { ...props } = this.props;
    return (
      <div className="annotation-types-single-item">
        <div
          className={`annotation-tool ${props.active} ${props.readonly ? 'readonly' : ''}`}
          style={{ outlineColor: props.color }}
          onClick={handleClickAndPassData(props.onAnnotationTypeClicked)(props.id)}
        >
          <div className="rectangle" style={{ backgroundColor: props.color }}>
            {displayShortcut(props)}
          </div>{' '}
          <ToolTypeIcon toolType={props.selectorType} />
          <div className="name">
            <span title={props.name}>{props.name}</span>
          </div>
          <div className="tool-options">
            {props.options && (
              <>
                {props.active && props.options.toolType === AnnotationToolType.BRUSH && (
                  <>
                    <IconedButton
                      icon={faBrush}
                      title={this.props.t('options_brush')}
                      className={(props.options as IBrushTypeOptions).brushMode === BRUSH_MODE.DRAW ? 'selected' : ''}
                      onClick={handleClickAndPassData(this.handleOptionsChange)({ ...props.options!, brushMode: BRUSH_MODE.DRAW })}
                      tabIndex={-1}
                    />
                    {(props.options as IBrushTypeOptions).eraserAvailable && (
                      <IconedButton
                        icon={faEraser}
                        title={this.props.t('options_eraser')}
                        className={(props.options as IBrushTypeOptions).brushMode === BRUSH_MODE.ERASE ? 'selected' : ''}
                        onClick={handleClickAndPassData(this.handleOptionsChange)({ ...props.options!, brushMode: BRUSH_MODE.ERASE })}
                        tabIndex={-1}
                      />
                    )}
                    <IconedButtonWithSlider
                      icon={faCircle}
                      title={this.props.t('options_radius')}
                      className="btn-slider"
                      minRange={1}
                      maxRange={200}
                      value={(props.options as IBrushTypeOptions).brushDiameter}
                      onChange={this.handleRadiusChange}
                      tabIndex={-1}
                    />
                  </>
                )}
                {!props.active && (
                  <IconedButton
                    className="annotations-types-list-toggle-icon"
                    icon={props.options.isVisible ? faEye : faEyeSlash}
                    title={this.props.t(props.options.isVisible ? 'hide_all_annotations' : 'show_all_annotations')}
                    onClick={handleClickAndPassData(this.handleOptionsChangeWithVisibilityChange)({ ...props.options, isVisible: !props.options.isVisible })}
                    tabIndex={-1}
                  />
                )}
              </>
            )}
            {props.deleteSelected && <IconedButton icon={faTimes} title={this.props.t('options_remove')} onClick={this.toggleModal} tabIndex={-1} />}
          </div>
        </div>

        <ConfirmationModal
          showModal={this.state.showModal}
          onConfirm={props.onSelectedAnnotationDelete}
          onCancel={this.toggleModal}
          confirmationHeader="annotation:delete_annotation_type_confirmation"
          confirmationQuestion="are_you_sure_you_want_to_delete_bursh_marking"
          confirmationYes={this.props.t('delete')}
        />
      </div>
    );
  }
}

export default as<React.ComponentClass<IProps>>(withNamespaces('annotation', { wait: true })(AnnotationTypeListItem));
