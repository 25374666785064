﻿import * as React from 'react';

import { Button, ListGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import { faBackspace, faKeyboard } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { as } from '../../../helpers/react.helpers';
import is from 'is_js';
import { withNamespaces } from 'react-i18next';

library.add(faKeyboard, faBackspace);

const KeyboardShortcut = ({ children }: { children: string | IconDefinition }) => (
  <div className="keyboard-shortcut">{is.string(children) ? children : <FontAwesomeIcon icon={children as IconDefinition} />}</div>
);

const ShortcutListItem = ({ left, right }: { left: string; right: React.ReactElement<any> }) => (
  <div className="shortcut-item">
    <div className="shortcut-title">{left}</div>
    <div className="shortcut-description">{right}</div>
  </div>
);
const ShortcutTitleListItem = ({ title }: { title: string }) => (
  <div className="shortcut-section-title">
    <div className="shortcut-title">{title}</div>
  </div>
);
export interface IShortcutsGuideProps {
  isOpen: boolean;
  globalShortcuts: { label: string; keys: string[] }[];
  create?: boolean;
  review?: boolean;
  correct?: boolean;
  edit?: boolean;
  isFreeAccess?: boolean;
  toggle(): void;
}
class ShortcutsGuidePure extends React.PureComponent<IShortcutsGuideProps & ITranslatable> {
  render() {
    const { isOpen, toggle, t } = this.props;

    const globalShortcuts = [...this.props.globalShortcuts, { label: 'show_shortcut_help', keys: ['?'] }];
    return (
      <Modal isOpen={isOpen} toggle={toggle} className="shortcuts-modal">
        <ModalHeader toggle={toggle}>{t('shortcuts')}</ModalHeader>
        <ModalBody>
          <ListGroup className="shortcuts-list">
            {globalShortcuts.length !== 0 && (
              <>
                <ShortcutTitleListItem title={t('shortcuts_global')} />
                {globalShortcuts.map(shortcut => (
                  <ShortcutListItem
                    key={shortcut.label}
                    left={t(shortcut.label)}
                    right={
                      <>
                        {shortcut.keys.map((key, index) => (
                          <React.Fragment key={key}>
                            {index !== 0 && <>&nbsp;+&nbsp; </>}
                            <KeyboardShortcut>{key}</KeyboardShortcut>
                          </React.Fragment>
                        ))}
                      </>
                    }
                  />
                ))}
              </>
            )}
            {this.props.isFreeAccess && (
              <>
                <ShortcutTitleListItem title={t('shortcuts_navigation')} />
                <ShortcutListItem left={t('previous')} right={<KeyboardShortcut>&larr;</KeyboardShortcut>} />
                <ShortcutListItem left={t('next')} right={<KeyboardShortcut>&rarr;</KeyboardShortcut>} />
              </>
            )}
            {(this.props.create || this.props.correct) && (
              <>
                <ShortcutTitleListItem title={t('shortcuts_annotation')} />
                <ShortcutListItem left={t('select_tool')} right={<KeyboardShortcut>͠ˎ</KeyboardShortcut>} />
                <ShortcutListItem
                  left={t('annotation_tools')}
                  right={
                    <>
                      <KeyboardShortcut>1</KeyboardShortcut>
                      &nbsp;...&nbsp;
                      <KeyboardShortcut>0</KeyboardShortcut>
                    </>
                  }
                />
                <ShortcutListItem
                  left={t('copy_marking')}
                  right={
                    <>
                      <KeyboardShortcut>ctrl</KeyboardShortcut>
                      &nbsp;+&nbsp;
                      <KeyboardShortcut>c</KeyboardShortcut>
                    </>
                  }
                />
                <ShortcutListItem
                  left={t('paste_marking')}
                  right={
                    <>
                      <KeyboardShortcut>ctrl</KeyboardShortcut>
                      &nbsp;+&nbsp;
                      <KeyboardShortcut>v</KeyboardShortcut>
                    </>
                  }
                />
                <ShortcutListItem
                  left={t('undo_last_action')}
                  right={
                    <>
                      <KeyboardShortcut>ctrl</KeyboardShortcut>
                      &nbsp;+&nbsp;
                      <KeyboardShortcut>z</KeyboardShortcut>
                    </>
                  }
                />
                <ShortcutListItem
                  left={t('redo_last_action')}
                  right={
                    <>
                      <KeyboardShortcut>ctrl</KeyboardShortcut>
                      &nbsp;+&nbsp;
                      <KeyboardShortcut>y</KeyboardShortcut>
                    </>
                  }
                />
                {is.mac() ? (
                  <ShortcutListItem
                    left={t('delete_marking')}
                    right={
                      <>
                        <KeyboardShortcut>fn</KeyboardShortcut>
                        &nbsp;+&nbsp;
                        <KeyboardShortcut>{faBackspace}</KeyboardShortcut>
                      </>
                    }
                  />
                ) : (
                  <ShortcutListItem left={t('delete_marking')} right={<KeyboardShortcut>del</KeyboardShortcut>} />
                )}
              </>
            )}
            {(this.props.correct || (this.props.create && !this.props.edit)) && (
              <>
                <ShortcutListItem
                  left={t('submit_annotation')}
                  right={
                    <>
                      <KeyboardShortcut>ctrl</KeyboardShortcut>
                      &nbsp;+&nbsp;
                      <KeyboardShortcut>shift</KeyboardShortcut>
                      &nbsp;+&nbsp;
                      <KeyboardShortcut>enter</KeyboardShortcut>
                    </>
                  }
                />
              </>
            )}
            {this.props.correct && (
              <>
                <ShortcutListItem
                  left={t('reject')}
                  right={
                    <>
                      <KeyboardShortcut>ctrl</KeyboardShortcut>
                      &nbsp;+&nbsp;
                      <KeyboardShortcut>shift</KeyboardShortcut>
                      &nbsp;+&nbsp;
                      <KeyboardShortcut>backspace</KeyboardShortcut>
                    </>
                  }
                />
              </>
            )}
            {this.props.edit && (
              <>
                <ShortcutListItem
                  left={t('save_annotation')}
                  right={
                    <>
                      <KeyboardShortcut>ctrl</KeyboardShortcut>
                      &nbsp;+&nbsp;
                      <KeyboardShortcut>enter</KeyboardShortcut>
                    </>
                  }
                />
              </>
            )}
            {(this.props.create || this.props.correct) && (
              <>
                <ShortcutTitleListItem title={t('shortcuts_annotation_question')} />
                <ShortcutListItem
                  left={t('shortcuts_annotation_question_navigation')}
                  right={
                    <>
                      <KeyboardShortcut>tab</KeyboardShortcut>
                      &nbsp;/&nbsp;
                      <KeyboardShortcut>shift</KeyboardShortcut>
                      &nbsp;+&nbsp;
                      <KeyboardShortcut>tab</KeyboardShortcut>
                    </>
                  }
                />
                <ShortcutListItem
                  left={t('shortcuts_annotation_question_checkbox_up_down')}
                  right={
                    <>
                      <KeyboardShortcut>tab</KeyboardShortcut>
                      &nbsp;/&nbsp;
                      <KeyboardShortcut>shift</KeyboardShortcut>
                      &nbsp;+&nbsp;
                      <KeyboardShortcut>tab</KeyboardShortcut>
                    </>
                  }
                />
                <ShortcutListItem
                  left={t('shortcuts_annotation_question_checkbox_selection')}
                  right={
                    <>
                      <KeyboardShortcut>space</KeyboardShortcut>
                    </>
                  }
                />
                <ShortcutListItem
                  left={t('shortcuts_annotation_question_radiobutton')}
                  right={
                    <>
                      <KeyboardShortcut>↑</KeyboardShortcut>
                      &nbsp;/&nbsp;
                      <KeyboardShortcut>↓</KeyboardShortcut>
                    </>
                  }
                />
                <ShortcutListItem
                  left={t('shortcuts_annotation_question_dropdown')}
                  right={
                    <>
                      <KeyboardShortcut>↑</KeyboardShortcut>
                      &nbsp;/&nbsp;
                      <KeyboardShortcut>↓</KeyboardShortcut>
                    </>
                  }
                />
              </>
            )}
            {this.props.review && (
              <>
                <ShortcutTitleListItem title={t('shortcuts_review')} />
                <ShortcutListItem
                  left={t('accept')}
                  right={
                    <>
                      <KeyboardShortcut>ctrl</KeyboardShortcut>
                      &nbsp;+&nbsp;
                      <KeyboardShortcut>shift</KeyboardShortcut>
                      &nbsp;+&nbsp;
                      <KeyboardShortcut>enter</KeyboardShortcut>
                    </>
                  }
                />
                <ShortcutListItem
                  left={t('reject')}
                  right={
                    <>
                      <KeyboardShortcut>ctrl</KeyboardShortcut>
                      &nbsp;+&nbsp;
                      <KeyboardShortcut>shift</KeyboardShortcut>
                      &nbsp;+&nbsp;
                      <KeyboardShortcut>backspace</KeyboardShortcut>
                    </>
                  }
                />
                <ShortcutListItem left={t('fix')} right={<KeyboardShortcut>e</KeyboardShortcut>} />
              </>
            )}
          </ListGroup>
        </ModalBody>
        <ModalFooter>
          <Button outline color="primary" onClick={this.props.toggle}>
            {this.props.t('close_window')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export const ShortcutsGuide = as<React.ComponentClass<IShortcutsGuideProps>>(withNamespaces('annotation')(ShortcutsGuidePure));
