import * as React from 'react';

import { AuthServiceType, IAuthService } from '../auth.service';
import { AuthStoreType, IAuthStore } from '../auth.store';
import { ConfigurationType, IConfiguration } from '../../../../configuration';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { IUserService, UserServiceType } from '../../user/user.service';
import { IWorkspacesStore, WorkspacesStoreType } from '../../workspaces/workspaces.store';
import { InputStatus, StickerError } from '../../../models/error.model';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import { AuthLogo } from '../components/AuthLogo';
import { AuthPrivacyPolicyCard } from '../components/AuthPrivacyPolicyCard';
import { GLOBAL_ERROR_CODES } from '../../../helpers/global.constants';
import { PasswordWithScoreInputsContainer } from './PasswordWithScoreInputsContainer';
import ReCAPTCHA from 'react-google-recaptcha';
import { RegistrationForm } from '../components/RegistrationForm';
import { ResolutionWarningCard } from '../components/ResolutionWarningCard';
import { TranslateServerErrorCodes } from '../../../helpers/serverErrorsTranslator.helper';
import autobind from 'autobind-decorator';
import { currentTimeZone } from '../../../models/timeZones/timeZones.model';
import { getEmailStatus } from '../../../helpers/email.helpers';
import qs from 'qs';

interface IInjectedProps extends RouteComponentProps {
  authService: IAuthService;
  authStore: IAuthStore;
  userService: IUserService;
  configuration: IConfiguration;
  workspaceStore: IWorkspacesStore;
}

interface IState extends ILoaderState {
  email: string;
  password: string;
  company: string;
  country: string;
  isAuthenticated: boolean;
  emailStatus: InputStatus;
  passwordStatus: InputStatus;
  externalStatus: InputStatus;
  isRegistrationPending: boolean;
  passwordControlRef: React.RefObject<PasswordWithScoreInputsContainer>;
  captchaRef: React.RefObject<ReCAPTCHA>;
  areRegulationsAccepted: boolean;
  regulationsStatus: InputStatus;
  isNewsletterAccepted: boolean;
}

type Props = IInjectedProps & RouteComponentProps;

@injectProps({
  authService: AuthServiceType,
  authStore: AuthStoreType,
  userService: UserServiceType,
  configuration: ConfigurationType,
  workspaceStore: WorkspacesStoreType,
})
@autobind
class RegistrationContainerPure extends WithLoaderComponentBase<Props, IState> {
  state: IState = {
    email: '',
    password: '',
    company: '',
    country: '',
    areRegulationsAccepted: false,
    isLoading: false,
    isAuthenticated: this.props.authStore.isAuthenticated,
    emailStatus: InputStatus.empty(),
    passwordStatus: InputStatus.empty(),
    externalStatus: InputStatus.empty(),
    regulationsStatus: InputStatus.empty(),
    isRegistrationPending: false,
    passwordControlRef: React.createRef(),
    captchaRef: React.createRef(),
    isNewsletterAccepted: false,
  };

  handleEmailChange = (value: string) => {
    this.setState({
      email: value,
      emailStatus: InputStatus.empty(),
      externalStatus: InputStatus.empty(),
    });
  };

  handlePasswordChange = (value: string) => {
    this.setState({
      password: value,
      externalStatus: InputStatus.empty(),
    });
  };

  handleCompanyChange = (value: string) => {
    this.setState({
      company: value,
      externalStatus: InputStatus.empty(),
    });
  };

  handleCountryChange = (value: string) => {
    this.setState({
      country: value,
      externalStatus: InputStatus.empty(),
    });
  };

  handlePasswordStatusChange = (status: InputStatus) => {
    this.setState({
      passwordStatus: status,
    });
  };

  getRegulationsStatus(value: boolean): InputStatus {
    return value ? InputStatus.valid() : InputStatus.buildFrom(['field_is_required']);
  }

  handleAcceptRegulationsChange = () => {
    this.setState((oldState: IState) => {
      return {
        regulationsStatus: this.getRegulationsStatus(!oldState.areRegulationsAccepted),
        areRegulationsAccepted: !oldState.areRegulationsAccepted,
      };
    });
  };

  handleAcceptNewsletterChange = () => {
    this.setState((oldState: IState) => {
      return {
        isNewsletterAccepted: !oldState.isNewsletterAccepted,
      };
    });
  };

  validateEmail(value: string): boolean | undefined {
    const emailStatus = getEmailStatus(value, []);
    this.setState({ emailStatus });
    return emailStatus.isValid;
  }

  validateRegulations(): boolean {
    const regulationsStatus = this.getRegulationsStatus(this.state.areRegulationsAccepted);
    this.setState({ regulationsStatus });
    return regulationsStatus.isValid === true;
  }

  async handleRegister() {
    const isEmailValid = this.validateEmail(this.state.email);
    const isPasswordValid = this.state.passwordControlRef.current !== null && this.state.passwordControlRef.current.validate();
    const areRegulationsValid = this.validateRegulations();
    if (!isEmailValid || !isPasswordValid || !areRegulationsValid) {
      return;
    }

    this.setState({ isRegistrationPending: true });

    if (this.props.configuration.appConfig!.enableCaptcha && this.state.captchaRef.current !== null) {
      this.state.captchaRef.current.execute();
    } else {
      await this.withLoaderAsync(() => this.registerUser(''), 'create-account-button');
    }
  }

  async handleCaptchaVerified(captchaValue: string) {
    await this.withLoaderAsync(() => this.registerUser(captchaValue), 'create-account-button');
  }

  async registerUser(captchaValue: string) {
    const search = this.props.location.search.slice(1);
    const parsedSearch = qs.parse(search);
    let registrationQueryString = '';
    if (!(Object.keys(parsedSearch).length === 1 && parsedSearch.workspaceId !== undefined)) {
      registrationQueryString = search;
    }
    const currentTimeZoneOffset = currentTimeZone?.value || 0;
    const result = await this.props.authService.register({
      registrationQueryString,
      email: this.state.email,
      password: this.state.password,
      company: this.state.company,
      country: this.state.country,
      captcha: captchaValue,
      newsletterAccepted: this.state.isNewsletterAccepted,
      timeZoneOffset: currentTimeZoneOffset,
    });

    if (result instanceof StickerError) {
      const errorCodes: string[] = !!result.apiErrorResponse ? TranslateServerErrorCodes(result.apiErrorResponse.errorCodes) : [GLOBAL_ERROR_CODES.FATAL_EXCEPTION];

      this.setState({
        externalStatus: new InputStatus(false, errorCodes),
        isRegistrationPending: false,
      });

      this.state.captchaRef.current !== null && this.state.captchaRef.current.reset();
    } else {
      this.props.history.push(`/resendConfirmationEmail?email=${encodeURIComponent(this.state.email)}`);
    }
  }

  render() {
    if (this.state.isAuthenticated) {
      return <Redirect to={this.props.authService.getAndClearReferrer()} />;
    }
    return (
      <>
        <AuthLogo />
        <RegistrationForm
          onEmailChange={this.handleEmailChange}
          onPasswordChange={this.handlePasswordChange}
          onPasswordStatusChange={this.handlePasswordStatusChange}
          onAcceptRegulationsChange={this.handleAcceptRegulationsChange}
          validateEmail={this.validateEmail}
          onRegister={this.handleRegister}
          onCaptchaVerified={this.handleCaptchaVerified}
          emailStatus={this.state.emailStatus}
          passwordStatus={this.state.passwordStatus}
          externalStatus={this.state.externalStatus}
          isDisabledSubmit={this.state.isRegistrationPending}
          passwordControlRef={this.state.passwordControlRef}
          email={this.state.email}
          areRegulationsAccepted={this.state.areRegulationsAccepted}
          regulationsStatus={this.state.regulationsStatus}
          captchaRef={this.state.captchaRef}
          enableCaptcha={this.props.configuration.appConfig!.enableCaptcha}
          captchaSecret={this.props.configuration.appConfig!.captchaSecret}
          onCompanyChange={this.handleCompanyChange}
          company={this.state.company}
          onCountryChange={this.handleCountryChange}
          country={this.state.country}
          isNewsletterAccepted={this.state.isNewsletterAccepted}
          onAcceptNewsletterChange={this.handleAcceptNewsletterChange}
        />
        <ResolutionWarningCard />
        <AuthPrivacyPolicyCard />
      </>
    );
  }
}

export const RegistrationContainer = as<React.ComponentClass>(withRouter(RegistrationContainerPure));
