// tslint:disable:function-name
// tslint:disable:variable-name

import leaflet, { LatLng, Point, Polyline, PolylineOptions } from 'leaflet';

import { MOUSE_BUTTONS } from '../../annotations.interface';
import { ROTATION_HANDLER_RADIUS } from '../../../../helpers/global.constants';
import { distance } from '../../../../helpers/geometry/vector.helpers';
import { inMapBounds } from '../../../../helpers/geometry/mapExtensions.helpers';

enum HandlePositon {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export class PathTransformEx extends leaflet.Handler.PathTransform {
  _previousAngle: number = 0;
  _previousHandlePosition: HandlePositon = HandlePositon.TOP;
  _rotationHandlerFrame!: L.CircleMarker;
  _orientationMarker: any;

  constructor(
    polygon: any,
  ) {
    super(polygon);
    if (polygon.options.storeKey) {
      polygon._map.eachLayer((layer: any) => {
        if (layer.options.storeKey === `${polygon.options.storeKey}-orientation-marker`) this._orientationMarker = layer;
      });
    }
  }

  addHooks() {
    this._createHandlers();
    this._map.on('zoomend', this.handleZoomChange, this);
  }

  transform(angle: any, scale: any, rotationOriginParam: any, scaleOriginParam: any) {
    const center = this._path.getCenter();
    const rotationOrigin = rotationOriginParam || center;
    const scaleOrigin = scaleOriginParam || center;
    this._map = this._path._map;
    this._transformPoints(this._path, angle, scale, rotationOrigin, scaleOrigin);
    this._transformPoints(this._orientationMarker, angle, scale, rotationOrigin, scaleOrigin);
    this._transformPoints(this._rect, angle, scale, rotationOrigin, scaleOrigin);
    this._transformPoints(this._handleLine, angle, scale, rotationOrigin, scaleOrigin);
    this._updateHandlers();
    return this;
  }

  _update() {
    let matrix = this._matrix;

    // update handlers
    for (let i = 0, len = this._handlers.length; i < len; i += 1) {
      const handler = this._handlers[i];
      if (handler !== this._originMarker) {
        handler._point = matrix.transform(handler._initialPoint);
        handler._updatePath();
      }
    }

    matrix = matrix.clone().flip();

    this._applyTransform(matrix);
    this._path.fire('transform', { layer: this._path });
    this._orientationMarker.fire('transform', { layer: this._orientationMarker });
  }

  _applyTransform(matrix: any) {
    this._path._transform(matrix._matrix);
    this._orientationMarker._transform(matrix._matrix);
    this._rect._transform(matrix._matrix);

    if (this.options.rotation) {
      this._handleLine._transform(matrix._matrix);
    }
  }

  _apply() {
    const map = this._map;
    const matrix = this._matrix.clone();
    const angle = this._angle;
    const scale = this._scale.clone();

    this._transformGeometries();

    // update handlers
    for (let i = 0, len = this._handlers.length; i < len; i += 1) {
      const handler = this._handlers[i];
      handler._latlng = map.layerPointToLatLng(handler._point);
      delete handler._initialPoint;
      handler.redraw();
    }
    const L: any = leaflet;
    this._matrix = L.matrix(1, 0, 0, 1, 0, 0);
    this._scale = L.point(1, 1);
    this._angle = 0;

    this._updateHandlers();

    map.dragging.enable();
    this._path.fire('transformed', {
      matrix,
      scale,
      rotation: angle,
      layer: this._path,
    });
    this._path.fire('transformed', {
      matrix,
      scale,
      rotation: angle,
      layer: this._orientationMarker,
    });
  }

  _updateHandlers() {
    const handlersGroup = this._handlersGroup;

    this._rectShape = this._rect.toGeoJSON();

    if (this._handleLine) {
      this._handlersGroup.removeLayer(this._handleLine);
    }

    if (this._rotationMarker) {
      this._handlersGroup.removeLayer(this._rotationMarker);
    }

    if (this._rotationHandlerFrame) {
      this._rotationHandlerFrame.remove();
    }

    this._handleLine = this._rotationMarker = null;

    for (let i = this._handlers.length - 1; i >= 0; i -= 1) {
      handlersGroup.removeLayer(this._handlers[i]);
    }

    this._createHandlers();
  }

  _transformGeometries() {
    this._path._transform(null);
    this._transformPoints(this._path);

    this._orientationMarker._transform(null);
    this._transformPoints(this._orientationMarker);

    if (this._enabled) {
      this._rect._transform(null);
      this._transformPoints(this._rect);

      if (this.options.rotation) {
        this._handleLine._transform(null);
        this._transformPoints(this._handleLine, this._angle, null, this._origin);
      }
    }
  }

  _createRotationHandlers() {
    const latlngs = this._rect._latlngs[0];

    const bottomPoint = new LatLng(
      (latlngs[0].lat + latlngs[3].lat) / 2,
      (latlngs[0].lng + latlngs[3].lng) / 2);

    const topPoint = new LatLng(
      (latlngs[1].lat + latlngs[2].lat) / 2,
      (latlngs[1].lng + latlngs[2].lng) / 2);

    const { anchorPoint, handlerPosition } = this.calculateHandlePosition(topPoint, bottomPoint);

    this._handleLine = new Polyline([anchorPoint, handlerPosition], this.options.rotateHandleOptions)
      .addTo(this._handlersGroup);

    const RotateHandleClass = this.options.rotateHandleClass;

    this._rotationMarker = new RotateHandleClass(handlerPosition, { ... this.options.handlerOptions, pane: 'markerPane' })
      .addTo(this._handlersGroup)
      .on('mousedown', this._onRotateStart, this);

    this.addRotationHandlerFrame(handlerPosition);

    this._rotationOrigin = new LatLng(
      (topPoint.lat + bottomPoint.lat) / 2,
      (topPoint.lng + bottomPoint.lng) / 2,
    );

    this._handlers.push(this._rotationMarker);
  }

  _onRotateStart(evt: any) {
    evt.originalEvent.preventDefault();
    this._previousAngle = 0;

    this._map.dragging.disable();
    this._originMarker = null;
    this._rotationOriginPt = this._map.latLngToLayerPoint(this._getRotationOrigin());
    this._rotationStart = evt.layerPoint;
    this._initialMatrix = this._matrix.clone();

    this._angle = 0;
    this._path._map
      .on('mousemove', this._onRotate, this)
      .on('mouseup', this._onRotateEnd, this);

    this._cachePoints();
    this._path
      .fire('transformstart', { layer: this._path })
      .fire('rotatestart', { layer: this._path, rotation: 0 });

    this._orientationMarker
      .fire('transformstart', { layer: this._orientationMarker })
      .fire('rotatestart', { layer: this._orientationMarker, rotation: 0 });
  }

  _onRotate(evt: any) {
    if (!(evt.originalEvent.buttons & MOUSE_BUTTONS.LEFT)) {
      this._onRotateEnd();
      return;
    }

    const pos = evt.layerPoint;
    const previous = this._rotationStart;
    const origin = this._rotationOriginPt;

    // rotation step angle
    this._angle = this.CalculateAngle(pos, origin, previous);

    this._matrix = this._initialMatrix
      .clone()
      .rotate(this._angle, origin)
      .flip();

    this._update();
    this._path.fire('rotate', { layer: this._path, rotation: this._angle });
    this._orientationMarker.fire('rotate', { layer: this._orientationMarker, rotation: this._angle });
  }

  _onRotateEnd() {
    this._path._map
      .off('mousemove', this._onRotate, this)
      .off('mouseup', this._onRotateEnd, this);

    const angle = this._angle;
    this._apply();
    this._path.fire('rotateend', { layer: this._path, rotation: angle });
    this._orientationMarker.fire('rotateend', { layer: this._orientationMarker, rotation: angle });
  }

  addRotationHandlerFrame(handlerPosition: { lat: any, lng: any }) {
    const frameOptions: PolylineOptions = {
      color: 'red',
      opacity: 0,
      fillOpacity: 0,
      fill: true,
      className: 'custom-rotation-handler',
    };

    const circleMarker = leaflet.circleMarker([handlerPosition.lat, handlerPosition.lng], { ...frameOptions, radius: ROTATION_HANDLER_RADIUS });
    this._rotationHandlerFrame = circleMarker;
    this._rotationHandlerFrame.addTo(this._handlersGroup).on('mousedown', this._onRotateStart, this);
  }

  calculateHandlePosition(topPoint: LatLng, bottomPoint: LatLng): { anchorPoint: LatLng, handlerPosition: LatLng } {
    const convertedZoom = this.convertZoom(this._map.getZoom());
    const handleLength = this.options.handleLength / convertedZoom;

    let handlerPosition = this._previousHandlePosition === HandlePositon.TOP ?
      this.pointOnLine(bottomPoint, topPoint, handleLength) :
      this.pointOnLine(topPoint, bottomPoint, handleLength);

    if (!inMapBounds(handlerPosition, this._map)) {
      this._previousHandlePosition = this._previousHandlePosition === HandlePositon.TOP ? HandlePositon.BOTTOM : HandlePositon.TOP;
      handlerPosition = this._previousHandlePosition === HandlePositon.TOP ?
        this.pointOnLine(bottomPoint, topPoint, handleLength) :
        this.pointOnLine(topPoint, bottomPoint, handleLength);
    }

    const anchorPoint = this._previousHandlePosition === HandlePositon.TOP ? topPoint : bottomPoint;

    return { anchorPoint, handlerPosition };
  }

  pointOnLine(start: LatLng, final: LatLng, handleLength: number): LatLng {
    const edgeLength = distance(start, final);

    if (edgeLength > 0) {
      const ratio = 1 + handleLength / edgeLength;
      return new LatLng(
        start.lat + (final.lat - start.lat) * ratio,
        start.lng + (final.lng - start.lng) * ratio,
      );
    }

    return new LatLng(
      start.lat + handleLength,
      start.lng + handleLength,
    );
  }

  convertZoom(zoom: number): number {
    return Math.pow(2, zoom) + 1;
  }

  handleZoomChange() {
    if (!this._enabled || this._rect === undefined) return;
    this._updateHandlers();
  }

  _hideHandlers() {
    if (this._handlersGroup) {
      this._map.removeLayer(this._handlersGroup);
    }
  }

  _getBoundingPolygon() {
    // @ts-ignore
    const bounds = new leaflet.LatLngBounds();
    this._path.eachLatLng((x: LatLng) => bounds.extend(x), null);

    return new leaflet.Rectangle(bounds, this.options.boundsOptions);
  }

  private CalculateAngle(pos: Point, origin: Point, previous: Point) {
    const x = Math.atan2(pos.y - origin.y, pos.x - origin.x) - Math.atan2(previous.y - origin.y, previous.x - origin.x);
    return x < 0 ? x + (2 * Math.PI) : x;
  }
}
