import { IPagingInfoWithOrder, PaginationInfoDefault } from '../../models/paginationInfo.model';
import { IProjectImportReport, ImportStage, ProjectStatus } from './projects.model';

import { ProjectRole } from '../../models/userRole.model';
import { injectable } from 'inversify';
import { observable } from 'mobx';

export const ProjectsStoreType = Symbol('PROJECTS_STORE');

export interface IProjectListItem {
  id: string;
  name: string;
  description: string;
  modifiedDate: string;
  status: ProjectStatus;
  imagesCount: number;
  awaitingCount: number;
  annotatedCount: number;
  reviewedCount: number;
  myRejectedCount: number;
  progress: number;
  usersCount: number;
  role: ProjectRole;
  isOwner: string;
  canBePublished: boolean;
  unansweredClarificationsCount: number;
  unansweredClarificationImageId: string;
  canAnnotate: boolean;
  canDelete: boolean;
  canEdit: boolean;
  canReview: boolean;
  canView: boolean;
  canExport: boolean;
}

export interface IProjectsPaging extends IPagingInfoWithOrder { }

export const defaultProjectsPaging: IProjectsPaging = {
  ...PaginationInfoDefault,
  orderBy: '',
  orderType: '',
};

export enum ProjectOwnership {
  All = 10,
  My = 20,
  Others = 30,
}

export interface IProjectsStore {
  projectOwnership: ProjectOwnership;
  projectsList: IProjectListItem[];
  projectsPaging: IProjectsPaging;

  importReport?: IProjectImportReport;
  importStage: ImportStage;
  importErrorCodes?: string[];
}

@injectable()
export class ProjectsStore implements IProjectsStore {
  @observable
  importStage: ImportStage = ImportStage.STEP1;

  @observable
  importReport?: IProjectImportReport = undefined;

  @observable
  projectOwnership: ProjectOwnership = ProjectOwnership.All;

  @observable
  projectsList: IProjectListItem[] = [];

  @observable
  projectsPaging: IProjectsPaging = defaultProjectsPaging;

  @observable
  importErrorCodes: string[] = [];
}
