import { Polygon, PolygonProps } from 'react-leaflet';

import { ISegmentationPolygonProps } from '../SegmentationLayerFeatures';
import React from 'react';

interface ITempRectangleProps {
  isActive: boolean;
}

export class RectangleFeature extends React.Component<ISegmentationPolygonProps & PolygonProps & ITempRectangleProps> {
  handleRef = (element: any) => {
    if (this.props.isActive && element) {
      element.leafletElement.enableEdit();
    }
  }

  render() {
    return <Polygon<ISegmentationPolygonProps & PolygonProps> {...this.props} ref={this.handleRef} />;
  }
}
