import { inject, injectable } from 'inversify';
import { WorkspaceRole } from '../../__legacy__/modules/workspaces/workspaces.store';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../workspaces/currentWorkspace/CurrentWorkspace.store';

export const AttachmentsPermissionsType = Symbol('ATTACHMENTS_PERMISSIONS');

export interface IAttachmentsPermissions {
  canRenameAttachment(): boolean;
}

@injectable()
export class AttachmentsPermissions implements IAttachmentsPermissions {
  constructor(@inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore) {}

  canRenameAttachment(): boolean {
    const userRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;

    return [WorkspaceRole.Manager, WorkspaceRole.Owner].includes(userRole);
  }
}
