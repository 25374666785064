import './InformationModal.scss';

import { WithNamespaces, withNamespaces } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { S_OneButtonModal } from '../../../../../../design/modals/oneButtonModal/S_OneButtonModal';
import { faBell } from '@fortawesome/free-solid-svg-icons';

interface Props {
  show: boolean;
  onHide(): void;
}

export const InformationModalPure: React.FC<Props & WithNamespaces> = (props: Props & WithNamespaces) => {
  const { t, show, onHide } = props;
  return (
    <S_OneButtonModal buttonText={t('ok')} onHide={onHide} onClick={onHide} headerText={t('new.projects.details.export_data.export_modal.modal_header')} show={show} className='export-information-modal'>
      <p>
        {t('new.projects.details.export_data.export_modal.modal_body')} <FontAwesomeIcon icon={faBell} />
      </p>
    </S_OneButtonModal>
  );
};

export const InformationModal = withNamespaces('common')(InformationModalPure);
