import { EmptySorting, FilterTabType } from '../../../imageFilters.model';

import { FilterHeader } from '../FilterHeader';
import { ITranslatable } from '../../../../../../../helpers/translations.helpers';
import { ImageFiltersFormProps } from '../ImageFiltersForm';
import React from 'react';
import { as } from '../../../../../../../helpers/react.helpers';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

@observer
class SortingHeaderPure extends React.Component<ImageFiltersFormProps & ITranslatable> {
  handleClear = () => this.props.onSetSorting([{ ...EmptySorting }]);

  render() {
    const isSelected = this.props.activeFilterType === FilterTabType.Sorting;
    const count = this.props.imageFilters.sorting.filter(x => x.by !== undefined).length;

    return (
      <FilterHeader
        filterType={FilterTabType.Sorting}
        isSelected={isSelected}
        labelKey={'sorting'}
        count={count}
        showClear={count > 0}
        onClear={this.handleClear}
        onSelect={this.props.onFilterTabSelect}
      />
    );
  }
}

export const SortingHeader = as<React.ComponentClass<ImageFiltersFormProps>>(withNamespaces('common')(SortingHeaderPure));
