import { BillingService, BillingServiceType, IBillingService } from './billing.service';
import { BillingStore, BillingStoreType, IBillingStore } from './billing.store';
import { ContainerModule, interfaces } from 'inversify';

export const billingModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IBillingStore>(BillingStoreType)
    .to(BillingStore)
    .inSingletonScope();
  bind<IBillingService>(BillingServiceType)
    .to(BillingService)
    .inSingletonScope();
});
