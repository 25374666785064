import * as React from 'react';

import { Button, Col, Row } from 'reactstrap';
import { ILoaderState, WithLoaderComponentBase } from '../../../../../helpers/loader.helpers';
import { IProjectDetailsToolsBl, IProjectDetailsToolsBlType } from '../projectDetailsTools.bl';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { IQuestion } from '../projectDetailsTools.models';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { ImageScopeName } from '../../../../annotation/question.model';
import { QuestionModalContainer } from './QuestionModal.container';
import { RestrictAccessTo } from '../../../../../containers/RestrictAccessTo';
import { WorkspaceRole } from '../../../../workspaces/workspaces.store';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  projectDetailsToolsBl: IProjectDetailsToolsBl;
}

interface IState extends ILoaderState {
  showModal: boolean;
}

@injectProps({ projectDetailsToolsBl: IProjectDetailsToolsBlType })
@observer
class ProjectAttributeFormContainerPure extends WithLoaderComponentBase<IInjectedProps & ITranslatable, IState> {
  state: IState = {
    showModal: false,
    isLoading: false,
  };

  @autobind
  async handleSubmit(questionModel: IQuestion) {
    await this.withLoaderAsync(async () => {
      await this.props.projectDetailsToolsBl.addQuestionAsync(questionModel);
      this.setState({ showModal: false });
    }, 'submit-question-form-button');
  }

  @autobind
  handleModalToggle() {
    this.setState(prev => ({ showModal: !prev.showModal }));
  }

  render() {
    const questions = this.props.projectDetailsToolsBl.store.questions;

    return (
      <Row className="annotation-tools-add-button">
        <Col>
          <RestrictAccessTo workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Trainer]} projectRoles={[]}>
            <DisabledWhenWorkspaceOwnerPolicyExceeded>
              <Button color="primary" size="sm" onClick={this.handleModalToggle}>
                {this.props.t('add')}
              </Button>
            </DisabledWhenWorkspaceOwnerPolicyExceeded>
          </RestrictAccessTo>
          <QuestionModalContainer
            question={undefined}
            questionsCount={questions.length}
            selectedQuestionTypes={questions.filter(q => q.scopes.includes(ImageScopeName)).map(q => q.type)}
            existingQuestionsAndAttributes={questions.map(q => ({ text: q.questionText, type: q.type }))}
            onSubmit={this.handleSubmit}
            onCancel={this.handleModalToggle}
            isReadonly={false}
            isAttribute={true}
            isLoading={this.state.isLoading}
            showModal={this.state.showModal}
            modalHeader={this.props.t('add_new_attribute')}
          />
        </Col>
      </Row>
    );
  }
}

export const ProjectAttributeFormContainer = as<React.ComponentClass>(withNamespaces('project', { wait: true })(ProjectAttributeFormContainerPure));
