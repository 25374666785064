import { ClarificationsBlType, IClarificationsBl } from '../services/Clarifications.bl';
import { ClarificationsPermissionsType, IClarificationsPermissions } from '../Clarifications.permissions';
import { ClarificationsStoreType, IClarificationsStore } from '../Clarifications.store';
import { as, injectProps } from '../../../../../helpers/react.helpers';

import { Button } from 'reactstrap';
import ClarifyModalContainer from './ClarifyModal.container';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import React from 'react';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

interface IState {
  showModal: boolean;
}

interface IInjectedProps {
  clarificationsPermissions: IClarificationsPermissions;
  clarificationsStore: IClarificationsStore;
  clarificationsBl: IClarificationsBl;
}

@injectProps({
  clarificationsPermissions: ClarificationsPermissionsType,
  clarificationsStore: ClarificationsStoreType,
  clarificationsBl: ClarificationsBlType,
})
@observer
class ClarifyButtonContainer extends React.Component<IInjectedProps & ITranslatable, IState> {
  state: IState = {
    showModal: false,
  };

  @autobind
  handleToggle() {
    this.setState(prevState => ({ showModal: !prevState.showModal }));
  }

  render() {
    const showButton = this.props.clarificationsStore.canShowClarify &&
      this.props.clarificationsPermissions.canClarify();
      
    return (
      <>
        {showButton &&
        <Button
          color="primary"
          onClick={this.handleToggle}
        >
          {this.props.t('clarifications.answer_clarification_button')}
        </Button>}
        <ClarifyModalContainer
          showModal={this.state.showModal}
          onToggle={this.handleToggle}
         />
      </>
    );
  }
}

export default as<React.ComponentClass>(withNamespaces('common', { wait: true })(ClarifyButtonContainer))
