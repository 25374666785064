import leaflet, { LatLng, Map } from 'leaflet';
import { projection, subtract, sum } from '../../../../helpers/geometry/vector.helpers';

import { AnnotationToolType } from '../../submodules/annotationTypes/models/annotationToolType';
import { EditVertexMarker } from './Edit.VertexMarker';
import autobind from 'autobind-decorator';
import { getOrientationMarkerCoords } from '../../../../helpers/geometry/orientedRectangle.helpers';
import { scaleToZoom } from '../../../../helpers/geometry/mapExtensions.helpers';

export class PolygonEditorEx extends leaflet.Editable.PolygonEditor {
  private orientationMarker!: any;

  initialize(map: Map, feature: any, options: any) {
    super.initialize(map, feature, options);
    if (feature.options.storeKey) {
      map.eachLayer((layer: any) => {
        if (layer.options.storeKey === `${feature.options.storeKey}-orientation-marker`) this.orientationMarker = layer;
      });
    }

    if (this.isRotatedRectangle()) {
      this.feature.on('rotateend', this.handleRotatedRectangleEdit);
      this.feature.on('editable:dragend', this.handleRotatedRectangleEdit);
      this.feature.on('editable:vertex:dragend', this.handleRotatedRectangleEdit);
    }
  }

  isRotatedRectangle() {
    return this.feature.options.type === AnnotationToolType.ROTATEDRECTANGLE;
  }

  @autobind
  handleRotatedRectangleEdit() {
    this.ensureClockwise();
  }

  vertexCanBeDeleted(vertex: EditVertexMarker) {
    if (this.isRotatedRectangle()) return false;
    return super.vertexCanBeDeleted(vertex);
  }

  hasMiddleMarkers() {
    return !this.isRotatedRectangle();
  }

  extendBounds(e: leaflet.Editable.VertexMouseEvent) {
    if (this.isRotatedRectangle()) {
      if (e.vertex.latlngs.length < 4) return;
      this.movePoint(e.editTools.map, e.vertex, e.latlng, e.oldLatLng);
      // move orientation marker
      e.latlng = e.vertex.latlng;
    } else {
      super.extendBounds(e);
    }
  }

  movePoint(map: leaflet.Map, currentVertex: EditVertexMarker, newPoint: LatLng, oldPoint: LatLng) {
    const nextVertex = currentVertex.getNext();
    const previousVertex = currentVertex.getPrevious();
    const oppositeVertex = currentVertex.getNext().getNext();

    const points = this.calculatePoints(oppositeVertex.latlng, previousVertex.latlng, nextVertex.latlng, newPoint, oldPoint);

    oppositeVertex.latlng.update(points.opposite);
    nextVertex.latlng.update(points.next);
    previousVertex.latlng.update(points.previous);
    currentVertex.latlng.update(points.current);

    leaflet.DomUtil.setPosition(currentVertex._icon, map.latLngToLayerPoint(points.current));
    this.refreshVertexMarkers(null);

    if (this.isRotatedRectangle()) {
      this.moveOrientationMarker(currentVertex.latlngs[3], currentVertex.latlngs[0], currentVertex.latlngs[1]);
    }
  }

  ensureClockwise() {
    const poly = this.feature as L.Polygon;
    const [left, right, bottomRight, bottomLeft] = poly._rings[0];
    if (left.x > right.x !== left.y > bottomLeft.y) {
      poly._rings[0] = [right, left, bottomLeft, bottomRight];
      poly._parts[0] = [right, left, bottomLeft, bottomRight];
      const [leftLL, rightLL, bottomRightLL, bottomLeftLL] = poly._latlngs[0];
      poly._latlngs[0] = [rightLL, leftLL, bottomLeftLL, bottomRightLL];
    }
  }

  moveOrientationMarker(p1: LatLng, p2: LatLng, p3: LatLng) {
    if (!this.orientationMarker) return;
    this.map.removeLayer(this.orientationMarker);
    const zoom = scaleToZoom(this.map.getZoom());
    this.orientationMarker._latlngs = getOrientationMarkerCoords(p1, p2, p3, zoom);
    this.orientationMarker.removeFrom(this.map);
    this.orientationMarker.addTo(this.map);
  }

  calculatePoints(opositeLatLng: LatLng, prevLatLng: LatLng, nextLatLng: LatLng, targetLatLng: LatLng, oldPoint: LatLng): ICalculationResult {
    const sideTransversal = subtract(targetLatLng, opositeLatLng);

    let sidePrevious = subtract(prevLatLng, oldPoint);
    let sideNext = subtract(nextLatLng, oldPoint);

    sideNext = new leaflet.LatLng(-sidePrevious.lng, sidePrevious.lat);
    sidePrevious = new leaflet.LatLng(-sideNext.lng, sideNext.lat);

    if (sideNext.lat === 0 && sideNext.lng === 0) {
      sideNext = new leaflet.LatLng(1, 1);
    }
    if (sidePrevious.lat === 0 && sidePrevious.lng === 0) {
      sidePrevious = new leaflet.LatLng(1, 1);
    }

    const previousProjection = projection(sideTransversal, sidePrevious);
    const nextProjection = projection(sideTransversal, sideNext);

    const newPreviousLatLng = sum(opositeLatLng, previousProjection);
    const newNextLatLng = sum(opositeLatLng, nextProjection);

    return {
      opposite: opositeLatLng,
      next: newPreviousLatLng,
      previous: newNextLatLng,
      current: targetLatLng,
    };
  }
}

interface ICalculationResult {
  opposite: leaflet.LatLng;
  next: leaflet.LatLng;
  previous: leaflet.LatLng;
  current: leaflet.LatLng;
}
