import * as React from 'react';
import { Trans, withNamespaces } from 'react-i18next';
import { ITranslatable } from '../../../../__legacy__/helpers/translations.helpers';
import { as } from '../../../../__legacy__/helpers/react.helpers';
import { EvaluationDatasetsListTable } from './EvaluationDatasetsListTable';
import { Loader } from '../../../../__legacy__/components/Loader';
import { IEvaluationDataset } from '../../evaluationDetails.store';
import { EVALUATION_STATUS } from '../../../evaluations/evaluations.model';
import { ModelPermissions, WorkspaceRole } from '../../../../__legacy__/modules/workspaces/workspaces.store';

interface IProps {
  workspaceRole: WorkspaceRole;
  isOwner: boolean;
  datasets: IEvaluationDataset[];
  datasetIds: string[];
  status: EVALUATION_STATUS;
  changeDatasetSelection(e: React.ChangeEvent<HTMLInputElement>): void;
  changeAllDatasetsSelection(): void;
  isLoading: boolean;
}

export const EvaluationDatasetsListPure = (props: IProps & ITranslatable) => {
  const { t, datasets, datasetIds, status, changeDatasetSelection, changeAllDatasetsSelection, isLoading, workspaceRole, isOwner } = props;

  return (
    <div>
      <div className="mb-4">
        <h1>{t('select_from_published_datasets')}</h1>
        <Trans
          i18nKey="select_from_published_datasets_description"
          // tslint:disable-next-line:jsx-key
          components={[<strong>0</strong>]}
        />
      </div>
      <div>
        <Loader isLoading={isLoading} class="evaluation-datasets-list">
          <EvaluationDatasetsListTable
            disableAll={isOwner ? !ModelPermissions.updateInferenceWhenOwner.includes(workspaceRole) : !ModelPermissions.updateInference.includes(workspaceRole)}
            datasets={datasets}
            datasetIds={datasetIds}
            status={status}
            changeDatasetSelection={changeDatasetSelection}
            changeAllDatasetsSelection={changeAllDatasetsSelection}
          />
        </Loader>
      </div>
    </div>
  );
};

export const EvaluationDatasetsList = as<React.FC<IProps>>(withNamespaces('evaluations')(EvaluationDatasetsListPure));
