import * as React from 'react';

import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { faDownload, faList, faSortAmountDown, faTh, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IAttachment } from '../attachments.store';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { IconedButton } from '../../../components/IconedButton';
import { ListViewMode } from '../../user/user.store';
import { as } from '../../../helpers/react.helpers';
import { handleClickAndPassData } from '../../../helpers/formHelpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { nameOf } from '../../../helpers/object.helpers';
import { withNamespaces } from 'react-i18next';

library.add(faSortAmountDown, faTh, faList, faTrashAlt, faDownload);

export interface IAttachmentsListControlsProps {
  viewMode: ListViewMode;
  orderBy: string;
  orderDirection: string;
  onViewModeChange(mode: ListViewMode): void;
  onOrderByChange(order: string): void;
  onOrderDirectionChange(direction: string): void;
}

const AttachmentsListControlsPure = (props: IAttachmentsListControlsProps & ITranslatable) => {
  return (
    <div className={'attachment-view-controls-container'}>
      <UncontrolledDropdown>
        <DropdownToggle caret color="secondary" id="sorting-dropdown">
          <FontAwesomeIcon icon={faSortAmountDown} />
        </DropdownToggle>
        <DropdownMenu right={true}>
          <DropdownItem
            onClick={handleClickAndPassData(props.onOrderByChange)(nameOf<IAttachment>('name'))}
            className={props.orderBy === nameOf<IAttachment>('name') ? 'selected' : ''}
          >
            {props.t('name')}
          </DropdownItem>
          <DropdownItem
            onClick={handleClickAndPassData(props.onOrderByChange)(nameOf<IAttachment>('createdDate'))}
            className={props.orderBy === nameOf<IAttachment>('createdDate') ? 'selected' : ''}
          >
            {props.t('createdDate')}
          </DropdownItem>
          <DropdownItem
            onClick={handleClickAndPassData(props.onOrderByChange)(nameOf<IAttachment>('modifiedDate'))}
            className={props.orderBy === nameOf<IAttachment>('modifiedDate') ? 'selected' : ''}
          >
            {props.t('modified_date')}
          </DropdownItem>
          <DropdownItem
            onClick={handleClickAndPassData(props.onOrderByChange)(nameOf<IAttachment>('author'))}
            className={props.orderBy === nameOf<IAttachment>('author') ? 'selected' : ''}
          >
            {props.t('author')}
          </DropdownItem>
          <DropdownItem
            onClick={handleClickAndPassData(props.onOrderByChange)(nameOf<IAttachment>('size'))}
            className={props.orderBy === nameOf<IAttachment>('size') ? 'selected' : ''}
          >
            {props.t('size')}
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={handleClickAndPassData(props.onOrderDirectionChange)('ASC')} className={props.orderDirection.toUpperCase() === 'ASC' ? 'selected' : ''}>
            {props.t('ascending')}
          </DropdownItem>
          <DropdownItem onClick={handleClickAndPassData(props.onOrderDirectionChange)('DESC')} className={props.orderDirection.toUpperCase() === 'DESC' ? 'selected' : ''}>
            {props.t('descending')}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <IconedButton
        color="primary"
        className="attachment-list-button list-mode-button"
        icon={props.viewMode === ListViewMode.Tiles ? faList : faTh}
        onClick={handleClickAndPassData(props.onViewModeChange)(props.viewMode === ListViewMode.Tiles ? ListViewMode.List : ListViewMode.Tiles)}
      />
    </div>
  );
};

export const AttachmentsListControls = as<React.StatelessComponent<IAttachmentsListControlsProps>>(withNamespaces('attachment')(AttachmentsListControlsPure));
