import { CustomInput, FormGroup, Label } from 'reactstrap';

import { Picky } from 'react-picky';
import React from 'react';
import { RenderSelectAllProps } from 'react-picky/dist/types';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

export interface ICheckboxMultiSelectProps<T> {
  label?: string;
  className?: string;
  options: T[];
  values: T[];
  valueField: keyof T;
  labelField: keyof T;
  onSelectChange(selected: T[]): void;
  t: ((str: string) => string);
}

@observer
export class CheckboxDropdownMultiSelect<T> extends React.Component<ICheckboxMultiSelectProps<T>>{

  handleOnChange = (selected: any) => this.props.onSelectChange(selected);

  renderOption = ({
    style,
    isSelected,
    item,
    selectValue,
    labelKey,
    valueKey,
  }: any) => {
    const onClick = (e: any) => { selectValue(item); e.preventDefault(); };
    return (
      <li
        style={style}
        className={`picky-option ${isSelected ? 'selected' : 'not-selected'}`}
        key={`${item[valueKey]}-li`}
        onClick={onClick}
      >
        <CustomInput
          id={`${item[valueKey]}-custom-input`}
          key={item[valueKey]}
          type="checkbox"
          checked={isSelected}
          label={item[labelKey]}
          disabled={false}
          readOnly={true}
        />
      </li>
    );
  };

  renderSelectAll = ({
    filtered,
    tabIndex,
    allSelected,
    toggleSelectAll,
    multiple,
  }: RenderSelectAllProps) => {
    if (multiple && !filtered) {
      const checked = allSelected === 'all';
      return (
        <div className={`picky-select-all picky-option ${checked ? 'selected' : 'not-selected'}`}>
          <CustomInput
            id="select-all"
            type="checkbox"
            checked={checked}
            label={checked ? this.props.t('deselect_all') : this.props.t('select_all')}
            disabled={false}
            readOnly={true}
            onClick={toggleSelectAll}
          />
        </div>
      );
    }
  };

  render() {
    return (
      <FormGroup className={this.props.className}>
        {this.props.label && <Label>{this.props.label}</Label>}
        <Picky
          numberDisplayed={0}
          options={toJS(this.props.options)}
          value={toJS(this.props.values)}
          multiple={true}
          includeSelectAll={true}
          includeFilter={true}
          filterPlaceholder={`${this.props.t('search')}...`}
          onChange={this.handleOnChange}
          labelKey={this.props.labelField.toString()}
          valueKey={this.props.valueField.toString()}
          render={this.renderOption}
          renderSelectAll={this.renderSelectAll}
          open={true}
          keepOpen={true}
        />
      </FormGroup>
    );
  }
}
